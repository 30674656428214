import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  Button,
  Drawer,
  Grid,
  Snackbar,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import debounce from "lodash.debounce";
import type { TableColumnsType } from "antd";
import { Spin, Table, Tooltip } from "antd";
import moment from "moment";
import { ArrowBackIos } from "@mui/icons-material";
import SearchBar from "@mkyy/mui-search-bar";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useFetch";
import {
  getEditSalesReturnData,
  getPendingAcceptedConfirm,
  getProceedAcceptedSalesReturn,
  getSalesReturnReasonList,
} from "../../../../../Redux/XDM/Sales/salesReturnReducer";
import { IoClose } from "react-icons/io5";
import { MdRMobiledata } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CheckRingLight from "../../../../../assets/images/icons/Check_ring_lightLin.png";
import view_line from "../../../../../assets/images/icons/view_line.png";
import vector_edit from "../../../../../assets/images/icons/Vector_edit.png";
import InvoiceDetails from "../../InvoiceReviewOrder";
import ImageModal from "./ImageModal";
import EditReturnSales from "./EditReturnPopUp";
interface RequestData {
  returnId: string;
  outletName: string;
  invoiceNo: string;
  salesmanName: string;
  date: string;
  noOfLineItem: number;
  returnValue: number;
  status: string;
}
interface ItemStatusCardProps {
  count: number;
  label: string;
  status: string;
  bgColor: string;
  textColor: string;
}

interface ReviewOrderDetailsProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
  invoiceRequestData: invoiceRequestData;
}
interface AcceptedDataType {
  id: number;
  name: string;
  quantity: number;
  price: number;
}
interface ReviewOrderDetails {
  productCode: string;
  productName: string;
  batchCode: string;
  expiryDate: string;
  quantity: string;
  uom: string;
  mrp: number;
  ptr: string;
  grossAmount: number;
  reason: string;
  image: string[];
  approvedQty: number;
  approvedValue: number;
  returnReason: string;
  status: string;
}
interface FooterDetails {
  totalApprovedQty: string;
  totalAcceptedQtyValue: number;
}
interface invoiceRequestData {
  invoiceNumber: string;
  orderId: string;
  date: string;
  outlet: string;
  route: string;
  salesman: string;
  netAmount: number;
  salesmanMobile: string;
}
export default function PendingConfirmReview(props: ReviewOrderDetailsProps) {
  const navigate = useNavigate();
  const { onClose, requestData, invoiceRequestData } = props; // Destructure the props
  const dispatch = useAppDispatch();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [commonLoader, setCommonLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [isShow, setIsShow] = React.useState(false);
  const [editIsShow, setEditIsShow] = React.useState(false);

  const [saleable, setSaleable] = useState(0);
  const [rejected, setRejected] = useState("");
  const [nonSaleable, setNonSaleable] = useState(0);
  const [totalApprovedQty, setTotalApprovedQty] = useState(0);
  const [totalApprovedValue, setTotalApprovedValue] = useState(0);
  const [isShowDetails, setIsShowDetails] = React.useState(false);
  const [openImage, setOpenImage] = React.useState(false);
  const [textFieldValue, setTextFieldValue] = React.useState("");
  const [imageList, setImageList] = React.useState<string[]>([]);
  const handleImageOpen = (record: any) => {
    setImageList(record?.image?.fileUri); // Set the image list from the record
    setOpenImage(true);
  };

  let salesreturnData = useAppSelector((state) => state.salesReturnData);

  const [dataRecordOrders, setDataRecordOrders] = useState<
    ReviewOrderDetails[]
  >([
    // Add more records as needed
  ]);
  
  const filteredDataToShow = dataRecordOrders.filter((item) => {
    return (
      (item.productName?.toLowerCase().includes(textFieldValue.toLowerCase()) || '') ||
      (item.productCode?.toLowerCase().includes(textFieldValue.toLowerCase()) || '') ||
      (item.batchCode?.toLowerCase().includes(textFieldValue.toLowerCase()) || '') ||
      (item.returnReason?.toLowerCase().includes(textFieldValue.toLowerCase()) || '') ||
      (item.reason?.toLowerCase().includes(textFieldValue.toLowerCase()) || '')
    );
  });
  

  const [dataFooter, setDataFooter] = useState<FooterDetails | null>(null);
  const tableHeightRef = useRef(160);
  const tableHeightloadRef = useRef(180);

  const filterData = {
    returnId: requestData.returnId,
  };
  const handleInvoiceClick = () => {
    setIsShowDetails(!isShowDetails);
  };
  // useEffect(() => {
  //   const handlePopState = (event: PopStateEvent) => {
  //     event.preventDefault();
  //     window.history.go(1);
  //   };
  //   window.history.pushState(null, "", window.location.href);
  //   window.addEventListener("popstate", handlePopState);
  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, []);
  // useEffect(() => {
  //   const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  //     const message =
  //       "Are you sure you want to leave this page? You have unsaved changes.";
  //     event.preventDefault();
  //     event.returnValue = message;
  //     return message;
  //   };
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  //   useEffect(() => {
  //     const handleVisibilityChange = () => {
  //       if (document.visibilityState === "hidden") {
  //         alert("You are switching tabs. Please be aware of unsaved changes.");
  //       }
  //     };
  //     document.addEventListener("visibilitychange", handleVisibilityChange);
  //     return () => {
  //       document.removeEventListener(
  //         "visibilitychange",
  //         handleVisibilityChange
  //       );
  //     };
  //   }, []);

  useEffect(() => {
    if (!editIsShow) {
      loadMoreData(true);
      loadFooter();
    }

  }, [editIsShow]);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 353;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const tableHeightLoad = () => {
    const headerHeight = 0;
    const footerHeoght = 300;
    const availableHeight = window.innerHeight - headerHeight - footerHeoght;
    tableHeightloadRef.current = availableHeight;
  };

  const dateObj = moment(requestData?.date, "YYYY-MM-DD");

  const formattedDate = dateObj.format("DD MMM YYYY");

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    tableHeightLoad();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    dispatch(
      getPendingAcceptedConfirm({
        params: { ...filterData },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          
          const newOrders = response.payload || [];
          console.log("res"+response.payload);
          // Calculate the sums
          const totalApprovedQty = newOrders.reduce(
            (count: number, order: { status: string }) => {
              if (order.status !== "Rejected") {
                return count + 1;
              }
              return count;
            },
            0
          );

          const totalApprovedValue = newOrders.reduce(
            (sum: number, order: { approvedValue: number }) =>
              sum + (order?.approvedValue ? Number(order.approvedValue.toFixed(2)) : 0),
            0
          );
          // Update sums based on whether it's a reset or append
          setTotalApprovedQty((prevQty) =>
            resetPage ? totalApprovedQty : prevQty + totalApprovedQty
          );
          setTotalApprovedValue((prevValue) =>
            resetPage ? totalApprovedValue : prevValue + totalApprovedValue
          );

          setDataRecordOrders((prevOrders) =>
            resetPage ? newOrders : [...prevOrders, ...newOrders]
          );
          if (newOrders.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        } else {
          // setHasMore(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };
  const loadFooter = () => {
    const abortController = new AbortController();
    const invoiceNo = requestData.invoiceNo;
    // dispatch(
    //   getAcceptedInvoiceDetails({
    //     params: invoiceNo,
    //     signal: abortController.signal,
    //   })
    // )
    //   .then((response) => {
    //     if (response.payload) {
    //       const newOrders = response.payload || [];
    //       setDataFooter(newOrders);
    //     } else {
    //       setDataFooter(null);
    //     }
    //   })
    //   .catch((error) => {
    //     if (error.name === "AbortError") {
    //       console.log("Fetch aborted");
    //     } else {
    //       console.error("Failed to fetch data:", error);
    //     }
    //   });
  };

  const handleEdit = (record: any) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const editRequest = {
      returnId: requestData.returnId,
      productCode: record.productCode,
      productReturnNo: record.productReturnNumber,
    };
    dispatch(
      getEditSalesReturnData({
        params: { ...editRequest },
        signal: abortController.signal,
      })
    );
    dispatch(getSalesReturnReasonList());

    setEditIsShow(!editIsShow);
  };
  const handleSearch = () => {
    setTextFieldValue(textFieldValue);
  };
  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [proceedSuccess, setProceedSuccess] = useState(false);

  const reviewOrderColumns: TableColumnsType<ReviewOrderDetails> = [
    {
      dataIndex: "productName",
      title: "Product Name",
      width: "9%",

      render: (text: string) => {
        // const displayText =
        //   text.length > 15 ? `${text.substring(0, 15)}...` : text;
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "--"}</div>
          </Tooltip>
        );
      },

    },

    {
      dataIndex: "productCode",
      title: "Product Code",
      width: "8%",

      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "--"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "batchCode",
      title: "Batch Code",
      width: "8%",

      render: (text: string) => {
        // const displayText =
        //   text.length > 15 ? `${text.substring(0, 15)}...` : text;
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "--"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "expiryDate",
      title: "Expired Date",
      width: "8%",
      render: (_: any, record: ReviewOrderDetails) => {
        if (record.expiryDate) {
          const dateObj = moment(record.expiryDate, "YYYY-MM-DD");
          const formattedDate = dateObj.format("DD MMM YYYY");
          return <div>{formattedDate}</div>;
        }
        return "-";
      },
    },
    {
      dataIndex: "quantity",
      title: "Quantity",
      width: "6%",

    },
    {
      dataIndex: "uom",
      title: "UOM",
      width: "4%",

    },

    {
      dataIndex: "mrp",
      title: "MRP / PC",
      width: "5%",

      render: (text: number, record: ReviewOrderDetails) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>₹{text ? text.toFixed(2) : "0.00"}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "ptr",
      title: "PTR / PC",
      width: "5%",

      render: (text: string | number, record: ReviewOrderDetails) => {
        const numberValue = parseFloat(record?.ptr);
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>₹{text ? Number(text).toFixed(2) : "0.00"}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "grossAmount",
      title: "Value",
      width: "5%",
      render: (_: any, record: ReviewOrderDetails) => {
        return (
          <span>
            ₹{record?.grossAmount ? record?.grossAmount.toFixed(2) : "0.00"}
          </span>
        );
      },
    },
    {
      dataIndex: "reason",
      title: "Reason",
      width: "5%",
      render: (text: string) => {
        // const displayText =
        //   text.length > 15 ? `${text.substring(0, 15)}...` : text;
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "--"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "image",
      title: "Image",
      align: "center",
      width: "5%",

      render: (_: any, record: ReviewOrderDetails, index: number) => {
        return (
          <Grid
            container
            sx={{
              justifyContent: "center",
              pointerEvents: "auto",
            }}
          >
            {record?.image?.length > 0 ? (
              <Grid item onClick={() => handleImageOpen(record)}>
                <img
                  src={view_line}
                  alt="view-line"
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
            ) : (
              <>--</>
            )}
          </Grid>
        );
      },
    },
    {
      dataIndex: "approvedQty",
      title: "Approved Qty.",
      align: "center",
      width: "8%",

    },
    {
      dataIndex: "acceptedValue",
      title: "Approved Value",
      width: "8%",

      render: (_: any, record: ReviewOrderDetails) => {
        return (
          <span>
            ₹{record?.approvedValue ? record?.approvedValue.toFixed(2) : "0"}
          </span>
        );
      },
    },
    ...(dataRecordOrders.some(
      (item) =>
        item.status === "Partially Confirmed" || item.status === "Rejected"
    )
      ? [
        {
          dataIndex: "returnReason",
          title: "Rejected Reason",
          width: "8%",

          render: (_: any, record: ReviewOrderDetails) => {
            return (
              <Tooltip placement="top" title={record?.returnReason} zIndex={999999}>
                <div className="ellipseclass">{record?.returnReason ? record?.returnReason : "-"}</div>
              </Tooltip>

            );
          },
        },
      ]
      : []),
    {
      dataIndex: "status",
      title: "Status",
      align: "center",
      width: "10%",

      render: (_: any, record: ReviewOrderDetails, index: number) => {
        if (record?.status === "Confirmed") {
          return (
            <Grid container className="action_edit_grid">
              <Button className="Accepted_button_pen">{" Approved"}</Button>
              <Grid
                item
                className="edit_button_pen"
                onClick={() => handleEdit(record)}
              >
                <img
                  src={vector_edit}
                  alt="Vector-edit"
                  style={{ width: "8px", height: "8px" }}
                />
              </Grid>
            </Grid>
          );
        } else if (record?.status === "Partially Confirmed") {
          return (
            <Grid container className="action_edit_grid">
              <Button className="Partiatially_button_pen">
                {" Approved (" + record?.approvedQty + ")"}
              </Button>
              <Grid
                item
                className="edit_button_pen"
                onClick={() => handleEdit(record)}
              >
                <img
                  src={vector_edit}
                  alt="Vector-edit"
                  style={{ width: "8px", height: "8px" }}
                />
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Grid container className="action_edit_grid">
              <Button className="Rejected_button_pen">{"Rejected"}</Button>
              <Grid item className="edit_button_pen" onClick={() => handleEdit(record)}>
                <img
                  src={vector_edit}
                  alt="Vector-edit"
                  style={{ width: "8px", height: "8px" }}
                />
              </Grid>
            </Grid>
          );
        }
      },
    },
  ];

  const ItemStatusCard: React.FC<ItemStatusCardProps> = ({
    count,
    label,
    status,
    bgColor,
    textColor,
  }) => {
    return (
      <Card
        sx={{
          backgroundColor: bgColor,
          borderRadius: 2,
          boxShadow: "none",
          height: "60px",
        }}
      >
        <CardContent style={{ padding: "15px" }}>
          <Typography
            variant="h6"
            sx={{ color: textColor, fontWeight: "bold" }}
          >
            {count} {count > 1 ? "Items" : "Item"}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: textColor, fontSize: "10px" }}
          >
            {status}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const dataToShow: ReviewOrderDetails[] =
    dataRecordOrders.length > 0
      ? hasMore
        ? dataRecordOrders
        : [...dataRecordOrders]
      : [];
  const [acceptedCount, setAcceptedCount] = useState(0);
  const [partiallyAcceptedCount, setPartiallyAcceptedCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);
  useEffect(() => {
    setAcceptedCount(
      dataRecordOrders.filter((item) => item.status === "Confirmed").length
    );
    setPartiallyAcceptedCount(
      dataRecordOrders.filter((item) => item.status === "Partially Confirmed")
        .length
    );
    setRejectedCount(
      dataRecordOrders.filter((item) => item.status === "Rejected").length
    );
  }, [dataRecordOrders]);
  const handleCancelAccept = (record: any) => {
    onClose();
    setConfirmModalShow(false);
  };
  const handleAcceptConfirm = (record: any) => {
    setConfirmModalShow(true);
  };
  const handleCancel = () => {
    setConfirmModalShow(false);
  };
  const handleConfirm = () => {
    setLoadingModal(true);
    const abortController = new AbortController();
    const returnId = requestData.returnId;
    dispatch(
      getProceedAcceptedSalesReturn({
        params: returnId,
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          setSaleable(response.payload.saleable);
          setNonSaleable(response.payload.nonSaleable);
          setRejected(response.payload.rejected);
          setTimeout(() => {
            setLoadingModal(false);
            setProceedSuccess(true);
          }, 2000);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
      });
  };
  const handleBackGRN = () => {
    navigate(0);
  };
  const itemCount = [
    acceptedCount,
    partiallyAcceptedCount,
    rejectedCount,
  ].filter((count) => count > 0).length;
  const gridSize = itemCount === 2 ? 6 : itemCount === 1 ? 12 : 4;
  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer anchor="right" open={true} className="drawer_outlet w-100px">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            // mt: 2,
          }}
        >
          <Grid item sx={{ width: "100%" }}>
            <Grid
              container
              sx={{ borderBottom: "1px solid #0000000D", padding: "12px 24px" }}
            >
              <Grid
                item
                sm={0.3}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ArrowBackIos
                  sx={{
                    fontSize: "10px",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    onClose();
                  }}
                />
              </Grid>
              <Grid item xs={6.7} sm={7.7} md={8.7} lg={8.7}>
                <Grid item>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#000000",
                      fontFamily: "Montserrat",
                      fontSize: "13px",
                    }}
                  >
                    Return ID - {requestData?.returnId}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={5}
                sm={4}
                md={3}
                lg={3}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <SearchBar
                  className="search_box_pending w-100px"
                  placeholder="Search Product Name, Code...."
                  value={textFieldValue}
                  onChange={(newValue) => {
                    setTextFieldValue(newValue);
                  }}
                  onCancelResearch={() => setTextFieldValue("")}
                  onSearch={(newValue) => {
                    handleSearch();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form saleable-products-box"
          >
            {" "}
            <Grid
              item
              sx={{
                width: "100%",
                margin: "auto",
                marginBottom: "90px",
                padding: "0px 24px",
              }}
            >
              <Grid container pl={3.6} mt={2}>
                <Grid item sm={2.2}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Invoice No
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography
                      className="InvoiceNo_style"
                      sx={{
                        fontWeight: "700",
                        color: "#000000",
                        fontFamily: "Montserrat",
                        fontSize: "13px",
                        cursor: "pointer",
                      }}
                      onClick={handleInvoiceClick}
                    >
                      {requestData?.invoiceNo}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={2.2}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Outlet Name
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                    {requestData?.outletName ? requestData?.outletName : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={2.2}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Salesman Name
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                     {requestData?.salesmanName? requestData?.salesmanName : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={2.2}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Date
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {requestData?.date? requestData?.date:"-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={2.2}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Return Value
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
                      {requestData?.returnValue
                        ? requestData?.returnValue.toFixed(2)
                        : "0.00"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="pad-top-0px"
                  sx={{ height: tableHeightloadRef.current }}
                >
                  <div className="saleable-table-scroll">
                    <Table
                      className={`Header-table saleable-table sale_last_hide scroll_cus_table Header-salesReturn-table  ${salesreturnData.loading
                        ? " nodataloader"
                        : ""
                        }`}
                      columns={reviewOrderColumns}
                      dataSource={filteredDataToShow}
                      pagination={false}
                      scroll={{ y: tableHeightRef.current }}
                      loading={
                        !page &&
                        salesreturnData.loading
                      }
                      showSorterTooltip={false}
                      summary={() => {
                        // return (
                        //   <>
                        //     {page !== 0 && productListGrnData.ProductDetailsGrnAcceptedLoading && (
                        //       <Table.Summary.Row>
                        //         <Table.Summary.Cell
                        //           index={1}
                        //           colSpan={7}
                        //           align="center"
                        //         >
                        //           <Spin />
                        //         </Table.Summary.Cell>
                        //       </Table.Summary.Row>
                        //     )}
                        //   </>
                        // );

                        if (!hasMore && dataRecordOrders.length > 0) {
                          return (
                            <Table.Summary.Row>
                              <Table.Summary.Cell
                                index={1}
                                colSpan={14}
                                align="center"
                                className="noMore"
                              >
                                No more data found
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        } else if (
                          page !== 0 &&
                          salesreturnData.loading
                        ) {
                          return (
                            <Table.Summary.Row>
                              <Table.Summary.Cell
                                index={1}
                                colSpan={8}
                                align="center"
                              >
                                <Spin />
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        }
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <hr className="hr-revieworder"></hr>
              <Grid container>
                <Grid item sm={8}></Grid>
                <Grid
                  item
                  sm={4}
                  mt={1.5}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Grid item>
                    <Grid item>
                      <Typography
                        className="Pending_review_label"
                        sx={{ fontWeight: 1000 }}
                        style={{ fontSize: "12px" }}
                      >
                        Approved Line Items :{totalApprovedQty}
                      </Typography>
                    </Grid>
                    <Grid item mt={0.7}>
                      <Typography
                        className="Pending_review_Sublabel"
                        sx={{ fontWeight: 1000 }}
                        style={{ fontSize: "12px" }}
                      >
                        Approved Value :₹ {totalApprovedValue.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item sx={{ display: "flex" }}>
                    <Button
                      className="Cancle_btn_des"
                      sx={{
                        height: "34px",
                        width: { sm: "15%", md: "13%", lg: "10%" },
                      }}
                      onClick={handleCancelAccept}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="Save_btn_des"
                      sx={{
                        height: "34px",
                        width: { sm: "15%", md: "11%", lg: "9%" },
                        mr: 2,
                      }}
                      onClick={handleAcceptConfirm}
                    >
                      Confirm
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Dialog
          open={confirmModalShow}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {loadingModal ? (
            <DialogContent
              sx={{
                width: "350px",
                padding: "12px 16px",
                height: "230px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <div className="loader"></div>
                <Typography
                  sx={{
                    marginTop: "20px",
                    color: "#344054",
                    fontSize: "16px",
                    fontWeight: 500,
                    fontFamily: "var(--Fontfamilyfont-family-body)",
                    lineHeight: "var(--Lineheighttext-lg)",
                  }}
                >
                  Loading...
                </Typography>
              </Box>
            </DialogContent>
          ) : (
            <>
              {proceedSuccess ? (
                <DialogContent
                  sx={{
                    width: "350px",
                    minheight: "200px",
                    padding: "12px 16px",
                  }}
                >
                  <Box
                    className="close_icon_btn cursor_pointer"
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                    onClick={proceedSuccess ? handleBackGRN : handleCancel}
                  >
                    <IoClose className="ClosePop" />
                  </Box>
                  <DialogContentText
                    className="dialog-title-remove"
                    id="alert-dialog-description"
                  >
                    {rejected?(<Grid container alignItems="center">
                      <Grid item xs={1}>
                        <img
                          src={CheckRingLight}
                          alt="Check Ring Light"
                          style={{
                            width: "37.5px",
                            height: "37.5px",
                            marginTop: "6.25px",
                            marginLeft: "6.25px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 900,
                          lineHeight: "30px",
                          textAlign: "left",
                          ml: 3,
                        }}
                      >
                        {rejected} .
                      </Grid>
                    </Grid>):([
                      { count: saleable, text: "Saleable" },
                      { count: nonSaleable, text: "Non-Saleable" },
                    ].map((item, index) => (
                      item.count > 0 && (
                        <Grid container alignItems="center" key={index}>
                          <Grid item xs={1}>
                            <img
                              src={CheckRingLight}
                              alt="Check Ring Light"
                              style={{
                                width: "37.5px",
                                height: "37.5px",
                                marginTop: "6.25px",
                                marginLeft: "6.25px",
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            sx={{
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: 900,
                              lineHeight: "30px",
                              textAlign: "left",
                              ml: 3,
                            }}
                          >
                            {item.count} Items updated successfully in the {item.text} Inventory.
                          </Grid>
                        </Grid>
                      )
                    )))}
                    
                    
                  </DialogContentText>
                </DialogContent>
              ) : (
                <DialogContent sx={{ width: "450px", padding: "12px 16px" }}>
                  <Box
                    className="close_icon_btn cursor_pointer"
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                    onClick={handleCancel}
                  >
                    <IoClose className="ClosePop" />
                  </Box>
                  <DialogContentText
                    className="dialog-title-remove"
                    id="alert-dialog-description"
                    sx={{ paddingBottom: "10px" }}
                  >
                    Are you sure you want to confirm?
                  </DialogContentText>
                  <Grid container spacing={1}>
                    {acceptedCount > 0 ? (
                      <Grid
                        item
                        sm={gridSize}
                        lg={gridSize}
                        xl={gridSize}
                        md={gridSize}
                      >
                        <ItemStatusCard
                          count={acceptedCount}
                          label="Items"
                          status="Approved"
                          bgColor="#E6F4EA"
                          textColor="#388E3C"
                        />
                      </Grid>
                    ) : (
                      ""
                    )}
                    {partiallyAcceptedCount > 0 ? (
                      <Grid
                        item
                        sm={gridSize}
                        lg={gridSize}
                        xl={gridSize}
                        md={gridSize}
                      >
                        <ItemStatusCard
                          count={partiallyAcceptedCount}
                          label="Item"
                          status="Partially Approved"
                          bgColor="#FFF3E0"
                          textColor="#F57C00"
                        />
                      </Grid>
                    ) : (
                      ""
                    )}
                    {rejectedCount > 0 ? (
                      <Grid
                        item
                        sm={gridSize}
                        lg={gridSize}
                        xl={gridSize}
                        md={gridSize}
                      >
                        <ItemStatusCard
                          count={rejectedCount}
                          label="Item"
                          status="Rejected"
                          bgColor="#FFEBEE"
                          textColor="#D32F2F"
                        />
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </DialogContent>
              )}

              <DialogActions>
                {proceedSuccess ? (
                  <Grid container>
                    <Grid item xs={12} sx={{ mr: 3, ml: 3 }}>
                      <Button
                        variant="contained"
                        className="crt-btn-grn"
                        fullWidth
                        style={{
                          height: "35px",
                          fontSize: "14px",
                        }}
                        onClick={handleBackGRN}
                      >
                        Back to Sales return
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      className="crt-btn-cancel"
                      style={{
                        height: "30px",
                        width: "23%",
                        marginRight: "5px",
                        fontSize: "12px",
                      }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      className="crt-btn-colr"
                      style={{
                        height: "30px",
                        width: "23%",
                        fontSize: "12px",
                        marginRight: "5px",
                      }}
                      onClick={handleConfirm}
                    >
                      Proceed
                    </Button>
                  </>
                )}
              </DialogActions>
            </>
          )}
        </Dialog>
      </Drawer>
      {openImage ? (
        <ImageModal
          open={openImage}
          handleImageClose={() => setOpenImage(false)}
          imageList={imageList}
        />
      ) : (
        <></>
      )}
      {isShowDetails && (
        <InvoiceDetails
          onClose={() => setIsShowDetails(false)}
          requestData={invoiceRequestData}
        />
      )}

      {editIsShow ? (
        <EditReturnSales
          onClose={() => setEditIsShow(false)}
          requestData={requestData}
        />
      ) : (
        <></>
      )}
    </Box>
  );
}
