import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Alert, Button, Grid, Typography } from "@mui/material";
import "react-phone-input-2/lib/style.css";
import { ReactComponent as ErrorIcon } from "../../assets/images/icons/errorIcon.svg";
import { requestOtp, verifyOtp } from "../../Redux/XDM/Login/OtpSlice";
import { useAppDispatch } from "../../hooks/useFetch";
import { ReactComponent as SuccessIcon } from "../../assets/images/icons/successIcon.svg";
import { useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";

interface OtpReceiveProps {
  onStatusChange: (status: boolean) => void;
  onOtpChange: (status: string) => void;
  phone: string;
}

const OtpReceive: React.FC<OtpReceiveProps> = ({
  onStatusChange,
  onOtpChange,
  phone,
}) => {
  const [otp, setOtp] = useState<string[]>(Array(6).fill(""));
  const [timer, setTimer] = useState<number>(50);

  const [errors, setErrors] = useState({
    otp: "",
  });
  const formattedPhone = `${phone.slice(0, 2)}XXX XXX${phone.slice(-2)}`;
  const [inputsDisabled, setInputsDisabled] = useState<boolean>(false);
  const [loadingbtn, setLoadingbtn] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string>("");
  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    } else {
      setInputsDisabled(true);
    }
  }, [timer]);
  const dispatch = useAppDispatch();
  const apiError = useSelector((state: any) => state.OtpRequest?.error);

  const handleOtpChange = (index: number, value: string) => {
    if (value.match(/^[0-9]$/) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      // Automatically focus next input
      if (value !== "" && index < otp.length - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) {
          (nextInput as HTMLInputElement).focus();
        }
      }
    }
  };
  const handleResend = async () => {
    const resultAction = await dispatch(requestOtp({ mobileNo: phone }));
    if (requestOtp.fulfilled.match(resultAction)) {
      setSuccessMsg("OTP send Successfully");
      setOtp(new Array(6).fill(""));
      setInputsDisabled(false);
      setTimer(50);
      setTimeout(() => {
        setSuccessMsg("");
      }, 3000);
    }
  };

  const validateForm = () => {
    const otpValue = otp.join("");
    let isValid = true;
    let errors = { otp: "" };
    if (!otpValue) {
      errors.otp = "OTP is required.";
      isValid = false;
    } else if (otpValue.length < 6) {
      errors.otp = "Invalid OTP number.";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoadingbtn(true);
    event.preventDefault();
    if (validateForm()) {
      const otpValue = otp.join("");
      const resultAction = await dispatch(
        verifyOtp({ mobileNo: phone, otp: otpValue })
      );
      if (verifyOtp.fulfilled.match(resultAction)) {
        setLoadingbtn(false);
        setSuccessMsg("OTP Verified Successfully");
        setTimeout(() => {
          setSuccessMsg("");
          onStatusChange(false);
          onOtpChange(otpValue);
        }, 3000);
      } else {
        setLoadingbtn(false);
      }
    } else {
      setLoadingbtn(false);
    }
  };
  return (
    <Grid
      item
      xs={12}
      sm={8}
      md={4.5}
      lg={3.5}
      xl={3.5}
      component={Paper}
      elevation={6}
      className="sigintop"
      square
    >
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "39%",
          //   width: "min-content",
        }}
      >
        <img
          src="assets/Images/LoginImage/Altec Logo.png"
          alt="Altec Logo"
          height="70px"
        ></img>
        {errors.otp && (
          <Alert
            icon={<ErrorIcon />}
            severity="error"
            className="w-100px mt-10 error_alert"
            color="error"
          >
            {errors.otp}
          </Alert>
        )}
        {apiError && (
          <Alert
            icon={<ErrorIcon />}
            severity="error"
            className="w-100px mt-10 error_alert"
            color="error"
          >
            {apiError}
          </Alert>
        )}
        {successMsg != "" && (
          <Alert
            severity="success"
            color="success"
            icon={<SuccessIcon height={22} width={22} />}
            className="w-100px  mt-10 success_alert"
          >
            {successMsg}
          </Alert>
        )}
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            marginTop:
              apiError || errors.otp || successMsg != "" ? "2%" : "16%",
          }}
        >
          <Box style={{ marginTop: "19px" }} className="mtsize">
            <Typography
              style={{
                display: "flex",
                fontSize: "13px",
                fontWeight: "700",
              }}
              sx={{
                mt: 3,
                mb: 2,
              }}
              id="DisText"
            >
              Enter OTP
            </Typography>
            <Typography
              style={{
                // display: "flex",
                fontSize: "11px",
                fontWeight: "600",
                color: "grey",
              }}
              sx={{
                mb: 2,
              }}
              id="DisText"
            >
              Enter OTP code sent to {formattedPhone} to verify your account.
            </Typography>
            <Box sx={{ display: "ruby-text" }}>
              {otp.map((value, index) => (
                <TextField
                  key={index}
                  id={`otp-input-${index}`}
                  value={value}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  variant="outlined"
                  size="small"
                  className={
                    value
                      ? "otptextdefault otptext"
                      : "otptextdefault otpdefault"
                  }
                  //   style={{ marginRight: "8px", width: "40px" }}
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: "center", border: "none" },
                  }}
                  disabled={inputsDisabled}
                />
              ))}
            </Box>
            <Box style={{ marginTop: "16px", textAlign: "center" }}>
              {timer > 0 ? (
                <span>Resend code in {timer} seconds</span>
              ) : (
                <b
                  className="cursor_pointer textDecoration_underline font-size12"
                  onClick={handleResend}
                >
                  Resend Code
                </b>
              )}
            </Box>
          </Box>
          <LoadingButton
            type="submit"
            fullWidth
            loading={loadingbtn}
            loadingPosition="start"
            variant="contained"
            disabled={inputsDisabled}
            sx={{
              mt: 3,
              mb: 2,
              background: "linear-gradient(90deg, #614FB3 0%, #944986 100%)",
              marginTop: "42px",
              textTransform: "none",
            }}
          >
            Verify
          </LoadingButton>
          <Grid container sx={{ marginTop: "60px" }}>
            <Grid item xs>
              <Typography
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  opacity: "50%",
                }}
              >
                Copyright © 2024 CITPL. All right reserved.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};
export default OtpReceive;
