import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import { Checkbox, Input, Space, Button } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import CommonTable from "../../../../components/Tables/CommonTable";
import GeoHierarchyCreate from "../GeoHierarchy/GeoHierarchyCreate";
import vector from "../../../../assets/images/icons/Vector.png";
import Plus from "../../../../assets/images/icons/Group.png";
import Search from "../../../../assets/images/icons/Icon 24px.png";
import { IoCloseOutline } from "react-icons/io5";
import SearchBar from "@mkyy/mui-search-bar";
interface DataType {
  key: React.Key;
  country: string;
  region: string;
  state: string;
  district: string;
  city: string;
}

export default function GeoHierarchy() {
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleShowClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setIsShow(!isShow);
    toggleDrawer(true);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const filteredOptions = [
    "Beverages",
    "Food",
    "Personal Care",
    "Snacks",
    "Lunch",
    "Dinner",
  ].filter((option) => option.toLowerCase().includes(searchText.toLowerCase()));

  const handleCheckboxChange = (selected: string[]) => {
    setSelectedItems(selected);
  };

  const getColumnFilterProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: {
      setSelectedKeys: (keys: string[]) => void;
      selectedKeys: string[];
      confirm: () => void;
      clearFilters: () => void;
    }) => (
      <Box style={{ padding: 8 }}>
        <Grid item container sx={{ flexWrap: "nowrap" }} columnSpacing={2}>
          <Grid item container sx={{ flexDirection: "column" }}>
            <Grid item>
              <Typography>
                <b style={{ fontSize: "12px" }}>Business Level Filters</b>
              </Typography>
              <Input
                placeholder="Search Filters"
                onChange={handleSearch}
                value={searchText}
                prefix={
                  <img
                    src={Search}
                    alt="search icon"
                    style={{ width: "14px" }}
                  />
                }
                className="filter_ser"
              />
            </Grid>
            <Grid item className="scroll_cbox">
              <Checkbox.Group
                onChange={handleCheckboxChange}
                className="filter_checkbox "
              >
                <Space direction="vertical" style={{ rowGap: "0px" }}>
                  {filteredOptions.map((option) => (
                    <Checkbox key={option} value={option}>
                      {option}
                    </Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>
            </Grid>
          </Grid>
          <Grid item container sx={{ flexDirection: "column" }}>
            <Grid item className="filter_dis" style={{ gap: "10px" }}>
              <Grid item>
                <Typography>
                  <b className="filter_text">
                    {" "}
                    {selectedItems.length} Filter Selected
                  </b>
                </Typography>
              </Grid>
              <Grid item className="filter_dis">
                <IoCloseOutline style={{ color: "red" }} />
                <Typography className="clr_text">Clear All</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Space direction="vertical" className="filter_checkbox">
                {selectedItems.map((item, index) => (
                  <Checkbox key={index} checked={true}>
                    {item}
                  </Checkbox>
                ))}
              </Space>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="btn_filter">
          <Button size="small" style={{ width: 80, fontSize: "10px" }}>
            Close
          </Button>
          <Button
            type="primary"
            size="small"
            style={{ width: 80, fontSize: "10px" }}
            className="btn-colr"
          >
            Apply
          </Button>
        </Grid>
      </Box>
    ),
    filterIcon: () => (
      <Box>
        <img
          src={vector}
          alt="filter icon"
          style={{ height: "14px", color: "#944986" }}
        />
      </Box>
    ),
    render: (text: string) => (searchedColumn === dataIndex ? <></> : text),
  });

  const columns = [
    {
      dataIndex: "country",
      title: (
        <Grid item className="filter_spacing">
          <Grid item className="table_title">
            Country (100)
            <Grid item sx={{ marginRight: "-10%" }}>
              <img
                src={Plus}
                alt="plus icon"
                style={{ height: "10px" }}
                onClick={handleShowClick}
              />
            </Grid>
          </Grid>
          <Grid className="table_search">
            <Input
              placeholder="Search Country..."
              prefix={
                <img src={Search} alt="search icon" style={{ width: "17px" }} />
              }
            />
          </Grid>
        </Grid>
      ),
      ...getColumnFilterProps("country"),
    },
    {
      dataIndex: "region",
      title: (
        <Grid item className="filter_spacing">
          <Grid className="table_title">
            Region (200)
            <Grid item sx={{ marginRight: "-10%" }}>
              <img
                src={Plus}
                alt="plus icon"
                style={{ height: "10px" }}
                onClick={handleShowClick}
              />
            </Grid>
          </Grid>
          <Grid className="table_search">
            <Input
              placeholder="Search Region..."
              prefix={
                <img src={Search} alt="search icon" style={{ width: "17px" }} />
              }
            />
          </Grid>
        </Grid>
      ),
      ...getColumnFilterProps("region"),
    },
    {
      dataIndex: "state",
      title: (
        <Grid item className="filter_spacing">
          <Grid className="table_title">
            State (300)
            <Grid item sx={{ marginRight: "-10%" }}>
              <img
                src={Plus}
                alt="plus icon"
                style={{ height: "10px" }}
                onClick={handleShowClick}
              />
            </Grid>
          </Grid>
          <Grid className="table_search">
            <Input
              placeholder="Search State..."
              prefix={
                <img src={Search} alt="search icon" style={{ width: "17px" }} />
              }
            />
          </Grid>
        </Grid>
      ),
      ...getColumnFilterProps("state"),
    },
    {
      dataIndex: "district",
      title: (
        <Grid item className="filter_spacing">
          <Grid className="table_title">
            District (400)
            <Grid sx={{ mr: "-10%" }}>
              <img
                src={Plus}
                alt="plus icon"
                style={{ height: "10px" }}
                onClick={handleShowClick}
              />
            </Grid>
          </Grid>
          <Grid className="table_search">
            <Input
              placeholder="Search District..."
              prefix={
                <img src={Search} alt="search icon" style={{ width: "17px" }} />
              }
            />
          </Grid>
        </Grid>
      ),
      ...getColumnFilterProps("district"),
    },
    {
      dataIndex: "city",
      title: (
        <Grid item className="filter_spacing">
          <Grid className="table_title">
            City/Town (500)
            <Grid item sx={{ marginRight: "-10%" }}>
              <img
                src={Plus}
                alt="plus icon"
                style={{ height: "10px" }}
                onClick={handleShowClick}
              />
            </Grid>
          </Grid>
          <Grid item className="table_search">
            <Input
              placeholder="Search City..."
              prefix={
                <img src={Search} alt="search icon" style={{ width: "17px" }} />
              }
            />
          </Grid>
        </Grid>
      ),
      ...getColumnFilterProps("city"),
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      country: "India",
      region: "North",
      state: "TamilNadu",
      district: "Chennai",
      city: "Adyar",
    },
    {
      key: 2,
      country: "",
      region: "South",
      state: "Pondicherry",
      district: "Pondicherry",
      city: "White Town",
    },
  ];

  return (
    <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 2, sm: 3 } }}>
      <Grid item container>
        <Grid item xs={12} sm={6} className="mar-tb">
          <Typography>
            <b className="header-lable">Geo Hierarchy Level</b>
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sm={6}
          item
          sx={{ display: "flex", justifyContent: "end", mb: 2 }}
        >
          <SearchBar
            className="search-table"
            // value={textFieldValue}
            // onChange={(newValue) => {
            //   setTextFieldValue(newValue);
            //   handleSearch();
            // }}
          />
        </Grid>
        <Grid
          item
          container
          xs={6}
          sm={6}
          md={6}
          className="mar-tb"
          sx={{ display: "flex", justifyContent: "end" }}
        ></Grid>
      </Grid>
      <Box id="TableScroll" className="table-container">
        <Grid item>
          <CommonTable data={data} columns={columns} />
        </Grid>
      </Box>
      {isShow ? <GeoHierarchyCreate onClose={() => setIsShow(false)} /> : <></>}
    </Box>
  );
}
