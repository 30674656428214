import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  Tab,
  Tabs,
  Typography,
  Card,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
  Divider,
} from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../hooks/useFetch";
import { useTheme } from "@mui/material/styles";
import MiniDrawerStyled from "../../../../../../Layout/MainLayout/Drawer/MiniDrawerStyled";
import DrawerHeader from "../../../../../../Layout/MainLayout/Drawer/DrawerHeader";
import SalesReturnLeftTable from "./SalesReturnLeftTable";
import WithoutInvoiceSidebar from "./WithoutInvoiceSidebar";
import AddSalesReturn from "./AddSalesReturnPage";
import {
  getDivisionWithoutInvoice,
  setAddedSalesReturnData,
} from "../../../../../../Redux/XDM/Sales/salesReturnReducer";
const useStyles = makeStyles((theme) => ({
  gradientText: {
    background: "linear-gradient(90.19deg, #614FB3 0%, #944986 100%)",
    border: "1px solid",
    borderRadius: "30px",
    alignItems: "center",
    display: "flex",
    padding: "0px 12px",
    justifyContent: "center",
    height: "27px",
    gap: "12px",
    color: "#FFF",
  },
  customTab: {
    border: "1px solid #e1e7ed",
    fontSize: "11px",
    padding: "0px 12px",
    borderRadius: "30px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "27px",
    backgroundColor: "", // Adjust the color as needed
  },
}));

export default function WithoutInvoiceReviewDetails() {
  const theme = useTheme();
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const classes = useStyles();
  const navigator = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();
  const getQueryParams = (query: any) => {
    return new URLSearchParams(query);
  };

  const queryParams = getQueryParams(location.search);
  const outletName = queryParams.get("outletName");
  const outletCode = queryParams.get("outletcode");

  const previousData = useAppSelector(
    (state) => state.salesReturnData?.addedData
  );
  const OrderTabs = useAppSelector(
    (state) => state.salesReturnData.divisionList
  );

  useEffect(() => {
    dispatch(getDivisionWithoutInvoice());
  }, []);

  useEffect(() => {
    if (OrderTabs?.length > 0) {
      const defaultTab = OrderTabs.find((tab) => tab.division === "All");
      // Set default to "All" or the first tab if "All" is not available
      setSubTabActive(defaultTab ? defaultTab.division : OrderTabs[0].division);
    }
  }, [OrderTabs]);

  // Initialize subTabActive with "All" division or the first available division
  const [subTabActive, setSubTabActive] = useState<string>("");
  const backtoMannualOrder = () => {
    if (previousData.length == 0) {
      navigator("/xdm/sales/sales-return?tab=true");
    } else {
      setDeleteIndex(previousData.length);
    }
  };

  const handleTabChange = (event: any, newValue: any) => {
    setSubTabActive(newValue);
  };
  const handleDeleteCancel = () => {
    setDeleteIndex(null);
  };
  const handleRemove = () => {
    navigator("/xdm/sales/sales-return");
    dispatch(setAddedSalesReturnData([]));
  };

  return (
    <Grid item>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        bgcolor="#eee"
      >
        <Box flex={1} p={0}>
          <MiniDrawerStyled variant="permanent" open={true} theme={theme}>
            <DrawerHeader />
            <WithoutInvoiceSidebar />
          </MiniDrawerStyled>
        </Box>
        <Box
          flex={8}
          className="margin-left-0px"
          sx={{ overflowY: "auto", height: "100vh", marginBottom: "100px" }}
        >
          <Card
            className="Mannual_Card "
            sx={{
              overflowY: {
                xs: "auto",
                sm: "hidden",
                md: "hidden",
                lg: "hidden",
                xl: "hidden",
              },
              borderRadius: "10px",
            }}
          >
            <Grid container spacing={3} justifyContent="start" pl={2} pr={2}>
              <Grid item sx={{ width: "100%" }} xs={12} sm={12} md={8} lg={7.4}>
                <Grid
                  container
                  className="crt-header"
                  mt={1}
                  mb={0}
                  pt={2}
                  pb={0}
                >
                  <Typography gutterBottom className="outlet-header-lable">
                    <ArrowBackIos
                      sx={{
                        fontSize: "10px",
                        cursor: "pointer",
                        marginRight: "5px",
                      }}
                      onClick={backtoMannualOrder}
                    />
                    Sales Return without Invoice ({outletName} / {outletCode})
                  </Typography>
                </Grid>
                <Divider />
                <Grid container>
                  {/* <Grid item > */}
                  <Grid
                    item
                    className="Mannual_SubTab_Card  withoutSr_tab_Card"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      boxShadow: "none",
                      borderRadius: "0px",
                      alignItems: "center",
                      paddingLeft: { sm: 0, md: 0, lg: 0, xl: "16px" },
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <Tabs
                      className="Mannual_subtab mannual_tab"
                      value={subTabActive}
                      onChange={handleTabChange}
                      variant="scrollable"
                      TabIndicatorProps={{
                        style: { display: "none" },
                      }}
                    >
                      {OrderTabs.map((subTab, index) => (
                        <Tab
                          value={subTab.division}
                          className="tab_hover"
                          key={index}
                          label={
                            <Typography
                              className={`${
                                subTabActive === subTab.division
                                  ? classes.gradientText
                                  : classes.customTab
                              } ${classes.customTab}`}
                            >
                              {subTab.description}
                            </Typography>
                          }
                          iconPosition="start"
                        />
                      ))}
                    </Tabs>
                  </Grid>
                  <Grid item xs={12}>
                    <SalesReturnLeftTable type={subTabActive} />
                  </Grid>
                  <Dialog
                    open={deleteIndex !== null}
                    onClose={handleDeleteCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent sx={{ width: "350px" }}>
                      <DialogContentText
                        className="dialog-title-remove"
                        id="alert-dialog-description"
                        style={{ paddingBottom: "10px" }}
                      >
                        Are you sure you want to discard the Sales return?
                      </DialogContentText>
                      <DialogContentText
                        id="alert-dialog-description"
                        style={{ fontSize: "12px", color: "black" }}
                      >
                        Cancel to return to the order without discarding, or
                        discard to proceed with discarding the sales return.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        className="crt-btn-cancel"
                        style={{
                          height: "30px",
                          width: "25%",
                          marginRight: "3px",
                          fontSize: "12px",
                        }}
                        onClick={handleDeleteCancel}
                      >
                        {" "}
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        className="crt-btn-colr"
                        style={{
                          height: "30px",
                          width: "25%",
                          fontSize: "12px",
                        }}
                        type="submit"
                        sx={{ mr: 2 }}
                        onClick={handleRemove}
                      >
                        {" "}
                        Discard
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>

                {/* </Grid> */}
              </Grid>
              <Grid item mt={1} xs={12} sm={12} md={4} lg={4.6}>
                <AddSalesReturn />
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Stack>
    </Grid>
  );
}
