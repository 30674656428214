import * as React from 'react';
import Box from '@mui/material/Box';
import {
    Button,
    Grid,
    Paper,
    Typography,
    MenuItem,
    FormControl,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import ViewJc from '../../Configurations/JcCalendar/ViewJc';
import CommonTable from '../../../components/Tables/CommonTable';
import SearchBar from '@mkyy/mui-search-bar';
import { TableColumnsType } from 'antd';
import FocusBrandView from './FocusBrandview/FocusbrandView';


interface DataType {
    key: React.Key;
    ReferenceNo: string;
    CreatedDate: string;
    ValidFrom: string;
    ValidTill:string;
    Status:string;
    action: string;
  }
const FocusBrand: React.FC = () => {
    const columns: TableColumnsType<DataType> = [
        {
          title: 'Reference No',
          dataIndex: 'ReferenceNo',
        },
        {
          title: 'Created Date',
          dataIndex: 'CreatedDate',
        //   sorter: (a, b) => a.CreatedDate.length - b.CreatedDate.length,
        },
        {
          title: 'Valid From',
          dataIndex: 'ValidFrom',
        //   sorter: (a, b) => a.ValidFrom.length - b.ValidFrom.length,
        },
        {
            title: 'Valid Till',
            dataIndex: 'ValidTill',
            // sorter: (a, b) => a.ValidFrom.length - b.ValidFrom.length,
          },
          {
            title: 'Status',
            dataIndex: 'Status',
            sorter: (a, b) => a.ValidFrom.length - b.ValidFrom.length,
          },
        {
          title: 'Action',
          dataIndex: 'action',
          render: (_: any, record: DataType) => (
            <>
              <VisibilityIcon  style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}  onClick={(event) => handleViewClick(event)} />
              {/* <EditIcon 
               style={{
                cursor: "pointer",
                marginLeft: "8px",
                fontSize: "1rem",
                color: "#928282de",
              }} 
              onClick={(event) => handleEditClick(event)}
              />
              <DeleteIcon 
               style={{
                cursor: "pointer",
                marginLeft: "8px",
                fontSize: "1rem",
                color: "#928282de",
              }} 
              onClick={(event) => handleDeleteClick(event)}
              /> */}
            </>
          ),
        }
      ];

    const data: DataType[] = [
        { key: '1', ReferenceNo: "FBRFMC160000025", CreatedDate: "22/06/2023", ValidFrom: '22/06/2023',ValidTill:"31/12/2030",Status:'Y', action: '' },
        { key: '2', ReferenceNo: "FBRFMC160000024", CreatedDate: "18/09/2021", ValidFrom: '18/09/2021',ValidTill:"13/10/2021",Status:'Y', action: '' },
        { key: '3', ReferenceNo: "FBRFMC160000023", CreatedDate:"18/09/2021", ValidFrom: '18/09/2021',ValidTill:"13/10/2021",Status:'Y', action: '' },
        { key: '4', ReferenceNo: "FBRFMC160000022", CreatedDate: "21/08/2021", ValidFrom: '21/08/2021',ValidTill:"15/09/2021",Status:'N', action: '' },
        { key: '5', ReferenceNo: "FBRFMC160000021", CreatedDate: "26/07/2021", ValidFrom: '26/07/2021',ValidTill:"18/08/2021",Status:'Y', action: '' },
        { key: '6', ReferenceNo: "FBRFMC160000020", CreatedDate: "29/06/2021", ValidFrom: '29/06/2021',ValidTill:"21/07/2021",Status:'N', action: '' },

      ];

    const navigator = useNavigate();
    const [isViewing, setIsViewing] = React.useState(false);
    const [hideTable, setIsHideTable] = React.useState(false);
    const [Year, setYear] = React.useState('');
    const [isSelectValid, setIsSelectValid] = React.useState(false);

    const handleChangeSelectBox = (event: SelectChangeEvent<string>) => {
        const selectedAge = event.target.value as string;
        setYear(selectedAge);
        setIsSelectValid(selectedAge !== '');
    };
    const handleHideClick = () => {
        setIsHideTable(!hideTable)
    }
    const handleViewClick = (event: React.MouseEvent<unknown>) => {
        setIsViewing(!isViewing);
    };


    const [textFieldValue, setTextFieldValue] = React.useState('');
 
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    //search function logic here
  };
 
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
};


    return (
      <>
        <Box
          sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}
        >
          <Paper elevation={0} className="pad-10 mar-tb">
            <Grid item xs={12}>
              <Typography className="cursor_pointer">
                <span onClick={() => navigator("/Configuration")}>
                  Product & Price
                </span>{" "}
                &nbsp;/
                <span>
                  <b>&nbsp;Focus Brand</b>
                </span>
              </Typography>
            </Grid>
          </Paper>
          {isViewing ? (
            <FocusBrandView onBack={handleViewClick} />
          ) : (
            <>
              <Grid container justifyContent="end">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    id="demo-simple-select"
                    value={Year}
                    onChange={handleChangeSelectBox}
                  >
                    <MenuItem value={2021}>2021</MenuItem>
                    <MenuItem value={2022}>2022</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  className="btn-apply"
                  onClick={handleHideClick}
                  disabled={!isSelectValid}
                >
                  ViewAll
                </Button>
              </Grid>
              {hideTable ? (
                <>
                  {isViewing ? null : (
                    <Box>
                      <Grid item sx={{ mt: 2 }}>
                        <Box>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              mb: 2,
                            }}
                          >
                            <SearchBar
                              className="search-table"
                              value={textFieldValue}
                              onChange={(newValue) => {
                                setTextFieldValue(newValue);
                                handleSearch();
                              }}
                            />
                          </Grid>
                          <CommonTable
                            // rowSelection={rowSelection}
                            data={data}
                            columns={columns}
                          />
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </>
              ) : null}
            </>
          )}
        </Box>
      </>
    );
}
export default FocusBrand;