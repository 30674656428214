import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Alert, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { requestOtp } from "../../Redux/XDM/Login/OtpSlice";
import { ReactComponent as ErrorIcon } from "../../assets/images/icons/errorIcon.svg";
import { useAppDispatch } from "../../hooks/useFetch";
import { useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { ReactComponent as SuccessIcon } from "../../assets/images/icons/successIcon.svg";

interface ForgetPasswordComponentProps {
  onStatusChange: (status: boolean) => void;
  onMobileChange: (status: string) => void;
}
const ForgetPasswordComponent: React.FC<ForgetPasswordComponentProps> = ({
  onStatusChange,
  onMobileChange,
}) => {
  const navigator = useNavigate();
  const [phone, setPhone] = useState<string>("");
  const [loadingbtn, setLoadingbtn] = useState(false);
  const [successAlert, setSuccessAlert] = useState<boolean>(false);
  const handleStatusChange = (status: boolean) => {
    onStatusChange(status);
  };
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState({
    phone: "",
  });
  const validatePhoneNumber = (phone: string): boolean => {
    // Basic validation for an international phone number format
    const phoneRegex = /^\d{10,15}$/; // Adjust the regex based on your needs
    console.log(phoneRegex.test(phone));
    return phoneRegex.test(phone);
  };
  const handleBackToSignin = () => {
    navigator("/distributor/login");
  };
  const apiError = useSelector((state: any) => state.OtpRequest?.error);
  const validateForm = () => {
    let isValid = true;
    let errors = { phone: "" };
    if (!phone) {
      errors.phone = "Mobile Number is required.";
      isValid = false;
    } else if (!validatePhoneNumber(phone)) {
      errors.phone = "Invalid mobile number.";
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };
  useEffect(() => {}, []);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoadingbtn(true);
    event.preventDefault();
    if (validateForm()) {
      const resultAction = await dispatch(
        requestOtp({ mobileNo: phone.slice(2) })
      );
      if (requestOtp.fulfilled.match(resultAction)) {
        setSuccessAlert(true);
        setLoadingbtn(false);
        setTimeout(() => {
          onStatusChange(false);
          onMobileChange(phone.slice(2));
          setSuccessAlert(false);
        }, 1000);
      } else {
        setLoadingbtn(false);
      }
    } else {
      setLoadingbtn(false);
    }
  };
  return (
    <Grid
      item
      xs={12}
      sm={8}
      md={4.5}
      lg={3.5}
      xl={3.5}
      component={Paper}
      elevation={6}
      className="sigintop"
      square
    >
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "39%",
          width: "min-content",
        }}
      >
        <img
          src="assets/Images/LoginImage/Altec Logo.png"
          alt="Altec Logo"
          height="70px"
        ></img>
        {errors.phone && (
          <Alert
            icon={<ErrorIcon />}
            severity="error"
            className="w-100px mt-10 error_alert"
            color="error"
          >
            {errors.phone}
          </Alert>
        )}
        {apiError && (
          <Alert
            icon={<ErrorIcon />}
            severity="error"
            className="w-100px mt-10 error_alert"
            color="error"
          >
            {apiError}
          </Alert>
        )}
        {successAlert && (
          <Alert
            severity="success"
            color="success"
            icon={<SuccessIcon height={22} width={22} />}
            className="w-100px  mt-10 success_alert"
          >
            OTP sent successFully
          </Alert>
        )}
        <Box
          component="form"
          noValidate
          sx={{
            marginTop: apiError || successAlert || errors.phone ? "2%" : "16%",
          }}
          onSubmit={handleSubmit}
        >
          <Box style={{ marginTop: "19px" }} className="mtsize">
            <Typography
              style={{
                display: "flex",
                fontSize: "13px",
                fontWeight: "700",
              }}
              sx={{
                mb: 2,
              }}
              id="DisText"
            >
              Forget your password?
            </Typography>
            <Typography
              style={{
                // display: "flex",
                fontSize: "11px",
                fontWeight: "600",
                color: "grey",
              }}
              sx={{
                mb: 2,
              }}
              id="DisText"
            >
              Enter the mobile number associate with your account and we'll send
              you an OTP for verification.{" "}
              <b
                className="cursor_pointer textDecoration_underline"
                onClick={handleBackToSignin}
              >
                Back to Sign In
              </b>
            </Typography>
            <PhoneInput
              country={"in"}
              value={phone}
              disableDropdown={true}
              countryCodeEditable={false}
              onChange={(phone) => setPhone(phone)}
            />
            <span
              id="passError"
              style={{ color: "red" }}
              className="error"
            ></span>
          </Box>
          <LoadingButton
            type="submit"
            fullWidth
            loadingPosition="start"
            loading={loadingbtn}
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              background: "linear-gradient(90deg, #614FB3 0%, #944986 100%)",
              marginTop: "42px",
              textTransform: "none",
            }}
          >
            Send OTP
          </LoadingButton>
          <Grid container sx={{ marginTop: "91px" }}>
            <Grid item xs>
              <Typography
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  opacity: "50%",
                }}
              >
                Copyright © 2024 CITPL. All right reserved.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};
export default ForgetPasswordComponent;
