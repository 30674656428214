import { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Drawer,
  FormControl,
  Grid,
  InputBase,
  Skeleton,
  Typography,
} from "@mui/material";
import Close from "../../../assets/images/icons/Close_icon.png";
import remove_line from "../../../assets/images/icons/remove_line.png";
import { Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import {
  getAddDiscrepancyReasonList,
  saveAddDiscrepancy,
} from "../../../Redux/XDM/Purchase/GrnReducer";
import { IoClose } from "react-icons/io5";
import LoadingButton from "@mui/lab/LoadingButton";

interface RequestData {
  invoiceNo: string;
  orderNo: string;
  invoiceDate: string;
  totNetAmt: number;
  noOfLineItem: string;
}

interface DiscrepancyProps {
  onClose: () => void;
  requestData: RequestData;
}
interface AddDiscrepancyListItem {
  prodCode: string;
  prodName: string;
  matDesc: string;
  uomCode: string;
  slno: string;
  mrp: string;
  invQty: number;
  netAmt: number;
}
interface CardState {
  selectedSalesmanIds: string[];
  showSelectReason: boolean;
  discrepancyQty: number;
  reasonQuantities: Record<string, number>;
  discrepancyQtyError?: string; // For error message on discrepancy quantity
  selectReasonError?: string; // For error message on select reason
  discrepancyQtyCompareError?: string; // For error message on discrepancy quantity compare
  discrepancyMatchQtyError?: string; // For error message when reason quantities do not match discrepancy quantity
}

const { Option } = Select;

export default function Discrepancy(props: DiscrepancyProps) {
  const { onClose, requestData } = props; // Destructure the props
  const dispatch = useAppDispatch();
  const grnData = useAppSelector((state) => state.pendingGrnData);
  const addDiscrepancyList = useAppSelector(
    (state) => state.pendingGrnData?.addDiscrepancyList
  );

  const addDiscrepancyReasonList = useAppSelector(
    (state) => state.pendingGrnData?.addDiscrepancyReasonList
  );

  const [cardStates, setCardStates] = useState<Record<number, CardState>>({});
  const [addDiscrepancyListData, setAddDiscrepancyListData] = useState<
    AddDiscrepancyListItem[]
  >([]);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [serialNo, setSerialNo] = useState("");
  const [discrepancyTotal, setDiscrepancyTotal] = useState(0);
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    dispatch(getAddDiscrepancyReasonList([]));
    if (addDiscrepancyList) {
      setAddDiscrepancyListData(addDiscrepancyList);
    }
  }, [dispatch, addDiscrepancyList]);

  const handleDeleteClick = (index: number) => {
    setDeleteIndex(index);
    setSerialNo(addDiscrepancyList[index]?.slno);
  };

  const handleDeleteCancel = () => {
    setDeleteIndex(null);
  };

  const handleDeleteConfirm = () => {
    if (deleteIndex !== null) {
      setAddDiscrepancyListData((prevList) => {
        const updatedList = prevList.filter(
          (_, index) => index !== deleteIndex
        );

        if (updatedList.length === 0) {
          onClose();
        }

        return updatedList;
      });

      setDeleteIndex(null);
      setSerialNo("");
    }
  };
  const handleSalesmanSelect = (index: number, value: string[]) => {
    setCardStates((prevState) => {
      const existingCardState = prevState[index] || {
        selectedSalesmanIds: [],
        reasonQuantities: {},
        discrepancyQty: 0,
        showSelectReason: false,
      };

      const reasonQuantities = existingCardState.reasonQuantities || {};

      const newReasonQuantities = value.reduce((acc, id) => {
        acc[id] = reasonQuantities[id] || 0;
        return acc;
      }, {} as Record<string, number>);

      let selectReasonError = "";
      if (value.length === 0) {
        selectReasonError = "Please select a reason";
      }

      return {
        ...prevState,
        [index]: {
          ...existingCardState,
          selectedSalesmanIds: value,
          reasonQuantities: newReasonQuantities,
          selectReasonError,
        },
      };
    });
  };

  const handleDiscrepancyQtyChange = (
    index: number,
    value: string,
    invQty: number
  ) => {
    const qty = parseInt(value, 10);
    let discrepancyQtyError = "";
    let discrepancyQtyCompareError = "";

    if (!qty || qty <= 0) {
      discrepancyQtyError = "Please enter Discrepancy Quantity";
    } else if (qty > invQty) {
      discrepancyQtyCompareError = `Discrepancy quantity cannot be greater than invoice quantity.`;
    }

    setCardStates((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        showSelectReason: true,
        discrepancyQty: qty,
        discrepancyQtyError,
        discrepancyQtyCompareError,
      },
    }));
  };

  const handleReasonQtyChange = (
    index: number,
    reasonId: string,
    value: string
  ) => {
    const qty = parseInt(value, 10) || 0;

    setCardStates((prevState) => {
      const existingCardState = prevState[index] || {
        selectedSalesmanIds: [],
        reasonQuantities: {},
        discrepancyQty: 0,
        showSelectReason: false,
      };

      const updatedReasonQuantities = {
        ...existingCardState.reasonQuantities,
        [reasonId]: qty,
      };

      // Calculate the sum of reason quantities
      const totalReasonQty = Object.values(updatedReasonQuantities).reduce(
        (acc, quantity) => acc + quantity,
        0
      );

      // Determine if there's an error
      let discrepancyMatchQtyError = "";
      if (totalReasonQty !== existingCardState.discrepancyQty) {
        discrepancyMatchQtyError =
          "Please enter the quantities to match the discrepancy quantity.";
      }

      return {
        ...prevState,
        [index]: {
          ...existingCardState,
          reasonQuantities: updatedReasonQuantities,
          discrepancyMatchQtyError,
        },
      };
    });
  };

  const calculateDiscrepancyValue = (
    netAmt: number,
    invQty: number,
    discrepancyQty: number
  ) => {
    // const roundedNetAmt = Math.floor(netAmt * 100) / 100;
    const roundedDisAmt = (netAmt / invQty) * discrepancyQty;
    return roundedDisAmt;
  };

  useEffect(() => {
    const total = Object.keys(cardStates).reduce((sum, key) => {
      const index = parseInt(key, 10);
      const item = addDiscrepancyListData[index];
      if (item && cardStates[index]) {
        const discrepancyValue = calculateDiscrepancyValue(
          item.netAmt,
          item.invQty,
          cardStates[index].discrepancyQty
        );
        const value = parseFloat(discrepancyValue.toFixed(2)); // Convert the string back to a number
        return sum + value;
      }
      return sum;
    }, 0);

    setDiscrepancyTotal(total); // No need for parseFloat here
  }, [cardStates, addDiscrepancyListData]);

  const handleSaveQuantity = async () => {
    let hasError = false;
    const newCardStates = { ...cardStates };

    addDiscrepancyListData.forEach((item, index) => {
      let cardState = newCardStates[index];

      // Initialize cardState if it doesn't exist
      if (!cardState) {
        cardState = {
          selectedSalesmanIds: [],
          showSelectReason: false,
          discrepancyQty: 0,
          reasonQuantities: {},
        };
        newCardStates[index] = cardState;
      }

      let discrepancyQtyError = "";
      let selectReasonError = "";
      let discrepancyMatchQtyError = "";

      if (!cardState.discrepancyQty || cardState.discrepancyQty <= 0) {
        discrepancyQtyError = "Please enter Discrepancy Quantity";
        hasError = true;
      }

      // Validate selected reasons
      if (
        !cardState.selectedSalesmanIds ||
        cardState.selectedSalesmanIds.length === 0
      ) {
        selectReasonError = "Please select a reason";
        hasError = true;
      }

      // Check if discrepancyQtyCompareError or discrepancyMatchQtyError is present
      if (
        cardState.discrepancyQtyCompareError ===
          "Discrepancy quantity cannot be greater than invoice quantity." ||
        cardState.discrepancyMatchQtyError ===
          "Please enter the quantities to match the discrepancy quantity."
      ) {
        hasError = true;
      }

      // Ensure reasonQuantities is initialized as an empty object if it doesn't exist
      if (cardStates[index]?.selectedSalesmanIds?.length > 1) {
        const reasonQuantities = cardState.reasonQuantities || {};

        const anyValueIsZero = Object.values(reasonQuantities).some(
          (value) => value === 0
        );

        if (anyValueIsZero) {
          discrepancyMatchQtyError =
            "Please enter the quantities or remove the reason";
          hasError = true;
        }

        const reasonQuantitiesSum = Object.values(reasonQuantities).reduce(
          (sum, qty) => sum + qty,
          0
        );
        if (reasonQuantitiesSum !== cardState.discrepancyQty) {
          discrepancyMatchQtyError =
            "Please enter the quantities to match the discrepancy quantity.";
          hasError = true;
        }
      }

      // Update the state with errors if any
      newCardStates[index] = {
        ...cardState,
        discrepancyQtyError,
        selectReasonError,
        discrepancyMatchQtyError,
      };
    });

    setCardStates(newCardStates); // Update the state with error messages

    if (hasError) {
      return; // Exit if there are errors
    }
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const discrepancyItems = addDiscrepancyListData.map((item, index) => {
      const reasonQuantities = cardStates[index]?.reasonQuantities || {};
      const discrepancyQty = Object.values(reasonQuantities);

      // Calculate discrepancy values based on reason quantities
      const reasonbasedValue = discrepancyQty.map((qty) =>
        calculateDiscrepancyValue(item.netAmt, item.invQty, qty)
      );

      // Calculate single discrepancy value based on the main discrepancy quantity
      const singleReasonDisValue = [
        calculateDiscrepancyValue(
          item.netAmt,
          item.invQty,
          cardStates[index]?.discrepancyQty || 0
        ),
      ];

      // Check if a single reason is selected or multiple reasons
      const isSingleReason =
        cardStates[index]?.selectedSalesmanIds?.length === 1;

      return {
        productCode: item.prodCode,
        slNo: item.slno,
        discrepancyReason: cardStates[index]?.selectedSalesmanIds || [],
        discrepancyQty: isSingleReason
          ? [cardStates[index]?.discrepancyQty || 0]
          : discrepancyQty,
        discrepancyValue: isSingleReason
          ? singleReasonDisValue
          : reasonbasedValue,
        invoicedQty: item.invQty,
        invoicedValue: item.netAmt,
      };
    });

    const totalDiscrepancyQty = discrepancyItems.reduce(
      (total, item) =>
        total + item.discrepancyQty.reduce((sum, qty) => sum + qty, 0),
      0
    );

    const saveRequestData = {
      invoiceNo: requestData.invoiceNo,
      productDetails: discrepancyItems,
      totalDiscrepancyQty: totalDiscrepancyQty,
      totalDiscrepancyValue: discrepancyTotal,
    };

    try {
      const response = await dispatch(
        saveAddDiscrepancy({
          params: saveRequestData,
          signal: abortController.signal,
        })
      );
      if (response?.meta?.requestStatus === "fulfilled") {
        onClose();
      }
    } catch (error) {
      console.error("Save error", error);
    }
  };

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="pen_drawer_stl"
        // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 500,
              md: 460,
              sm: 400,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
            backgroundColor: "#F6F6F6",
          }}
        >
          <Grid item sx={{ height: "100%" }}>
            <Grid
              container
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                background: "white",
                height: "60px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
              }}
            >
              <Grid item sm={7}>
                <Typography>
                  <b>
                    {addDiscrepancyListData?.length > 1
                      ? `Discrepancy Items (${addDiscrepancyListData.length})`
                      : "Discrepancy Item"}
                  </b>
                </Typography>
              </Grid>
              <Grid item sm={5} sx={{ display: "flex", justifyContent: "end" }}>
                <img
                  onClick={onClose}
                  src={Close}
                  alt="Close"
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{ overflowY: "auto", height: "70%", padding: "20px" }}
              className="discrepancy_card"
            >
              {addDiscrepancyListData.length !== 0 ? (
                <>
                  {addDiscrepancyListData?.map(
                    (item: AddDiscrepancyListItem, index: number) => (
                      <Card
                        key={index}
                        sx={{
                          border: "1px solid #DCDCDE",
                          padding: "15px",
                          borderRadius: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <Grid container sx={{ alignItems: "center" }}>
                          <Grid item sm={10}>
                            <Grid item>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: "13px",
                                  fontFamily: "Montserrat",
                                  fontWeight: 600,
                                }}
                              >
                                {item.matDesc ? item.matDesc : item.prodName}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: "13px",
                                  fontFamily: "Montserrat",
                                  fontWeight: 600,
                                }}
                              >
                                {item.prodCode}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            sm={2}
                            sx={{ display: "flex", justifyContent: "end" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteClick(index);
                            }}
                          >
                            <img
                              src={remove_line}
                              alt="remove_line"
                              style={{ width: "20px", height: "20px" }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container mt={2} sx={{ gap: "10px" }}>
                          <Grid item>
                            <Typography
                              sx={{
                                color: "#000000",
                                fontSize: "10px",
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                              }}
                            >
                              Invoice Quantity : {item.invQty}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{
                                color: "#000000",
                                fontSize: "10px",
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                              }}
                            >
                              Invoice Value : ₹{" "}
                              {(Math.floor(item.netAmt * 100) / 100).toFixed(2)}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{
                                color: "#000000",
                                fontSize: "10px",
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                              }}
                            >
                              UOM : {item.uomCode}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{
                                color: "#000000",
                                fontSize: "10px",
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                              }}
                            >
                              MRP / PC : ₹ {item.mrp}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item mt={2}>
                          <Typography
                            sx={{
                              color: "#44464C",
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            Enter Discrepancy Quantity
                            <span style={{ color: "red" }}>&nbsp;*</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} mt={1}>
                          <InputBase
                            sx={{
                              border: "1px solid #DCDCDE",
                              width: "100%",
                              height: "38px",
                              paddingLeft: "10px",
                              color: "#505259",
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: 500,
                              borderRadius: "4px",
                            }}
                            inputProps={{
                              maxLength: 10,
                            }}
                            onInput={(e) => {
                              const target = e.target as HTMLInputElement;
                              target.value = target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*\.?\d*$/.test(value)) {
                                handleDiscrepancyQtyChange(
                                  index,
                                  value,
                                  item.invQty
                                );
                              }
                            }}
                          />
                          {cardStates[index]?.discrepancyQtyError && (
                            <Typography color="error" variant="caption">
                              {cardStates[index].discrepancyQtyError}
                            </Typography>
                          )}
                          {cardStates[index]?.discrepancyQtyCompareError && (
                            <Typography color="error" variant="caption">
                              {cardStates[index].discrepancyQtyCompareError}
                            </Typography>
                          )}
                        </Grid>
                        {cardStates[index]?.showSelectReason && (
                          <Grid item>
                            <Grid item mt={2}>
                              <Typography
                                sx={{
                                  color: "#44464C",
                                  fontFamily: "Montserrat",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                }}
                              >
                                Select Reason
                                <span style={{ color: "red" }}>&nbsp;*</span>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <FormControl
                                fullWidth
                                sx={{ marginTop: "9px", marginBottom: "2px" }}
                              >
                                <Select
                                  className="Quentity_select_box"
                                  mode="multiple"
                                  showSearch
                                  value={
                                    cardStates[index]?.selectedSalesmanIds || []
                                  }
                                  placeholder="Add Reason"
                                  filterOption={(input, option) => {
                                    const optionText =
                                      option?.children
                                        ?.toString()
                                        .toLowerCase() || "";
                                    return optionText.includes(
                                      input.toLowerCase()
                                    );
                                  }}
                                  dropdownStyle={{ zIndex: 2000 }}
                                  onChange={(value) =>
                                    handleSalesmanSelect(index, value)
                                  }
                                  style={{ width: "100%", fontSize: "12px" }} // Adjust the width as necessary
                                >
                                  {addDiscrepancyReasonList?.map((salesman) => (
                                    <Option
                                      key={salesman.id}
                                      value={salesman.id}
                                    >
                                      <div style={{ fontSize: "11px" }}>
                                        {salesman.discrepancyName}
                                      </div>
                                    </Option>
                                  ))}
                                </Select>
                              </FormControl>
                              {cardStates[index]?.selectReasonError && (
                                <Typography color="error" variant="caption">
                                  {cardStates[index].selectReasonError}
                                </Typography>
                              )}
                            </Grid>

                            {cardStates[index]?.selectedSalesmanIds?.length >
                              1 &&
                              cardStates[index].selectedSalesmanIds?.map(
                                (id, i) => {
                                  const selectedSalesman =
                                    addDiscrepancyReasonList?.find(
                                      (salesman) => salesman.id === id
                                    );

                                  return (
                                    <Grid container mt={1} key={i}>
                                      <Grid item>
                                        <Typography
                                          sx={{
                                            color: "#44464C",
                                            fontFamily: "Montserrat",
                                            fontSize: "12px",
                                            fontWeight: 500,
                                          }}
                                        >
                                          Enter{" "}
                                          {selectedSalesman?.discrepancyName}{" "}
                                          Quantity
                                          <span style={{ color: "red" }}>
                                            &nbsp;*
                                          </span>
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} mt={1}>
                                        <InputBase
                                          value={
                                            cardStates[index]?.reasonQuantities[
                                              id
                                            ] || ""
                                          }
                                          sx={{
                                            border: "1px solid #DCDCDE",
                                            width: "100%",
                                            height: "38px",
                                            paddingLeft: "10px",
                                            color: "#505259",
                                            fontSize: "10px",
                                            fontFamily: "Montserrat",
                                            fontWeight: 500,
                                            borderRadius: "4px",
                                          }}
                                          inputProps={{
                                            maxLength: 10,
                                          }}
                                          onInput={(e) => {
                                            const target =
                                              e.target as HTMLInputElement;
                                            target.value = target.value.replace(
                                              /[^0-9.]/g,
                                              ""
                                            );
                                          }}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*\.?\d*$/.test(value)) {
                                              handleReasonQtyChange(
                                                index,
                                                id,
                                                value
                                              );
                                            }
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  );
                                }
                              )}
                            {cardStates[index]?.discrepancyMatchQtyError && (
                              <Typography color="error" variant="caption">
                                {cardStates[index].discrepancyMatchQtyError}
                              </Typography>
                            )}
                          </Grid>
                        )}

                        <Grid container mt={1}>
                          <Grid item sm={6}>
                            <Typography
                              sx={{
                                color: "#000000",
                                fontSize: "11px",
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                              }}
                            >
                              Discrepancy Quantity :{" "}
                              {cardStates[index]?.discrepancyQty || 0}
                            </Typography>
                          </Grid>
                          <Grid item sm={6}>
                            <Typography
                              sx={{
                                color: "#000000",
                                fontSize: "11px",
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                              }}
                            >
                              Discrepancy Value : ₹{" "}
                              {calculateDiscrepancyValue(
                                item.netAmt,
                                item.invQty,
                                cardStates[index]?.discrepancyQty || 0
                              ).toFixed(2)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    )
                  )}
                </>
              ) : (
                <Grid item sm={12} height={300}>
                  <Typography
                    sx={{
                      color: "#44464C",
                      fontSize: "14px",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      textAlign: "center",
                      height: "60vh",
                    }}
                  >
                    {grnData.addDiscrepancyListLoading ? (
                      <Skeleton
                        variant="rectangular"
                        height={250}
                        sx={{
                          borderRadius: 2,
                          width: {
                            lg: 450,
                            md: 430,
                            sm: 350,
                            xs: 350,
                          },
                        }}
                      />
                    ) : (
                      <b>No discrepancies found.</b>
                    )}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              sx={{
                position: "sticky",
                bottom: 85,
                height: "60px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
                background: "white",
              }}
            >
              <Grid item sm={6}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "13px",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                  }}
                >
                  Discrepancy Total : ₹{" "}
                  {discrepancyTotal ? discrepancyTotal.toFixed(2) : "0.00"}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Button className="Cancle_btn_des" onClick={onClose}>
                  Cancel
                </Button>
                {/* <Button className="Save_btn_des" onClick={handleSaveQuantity}>
                  Save
                </Button> */}
                <LoadingButton
                  className="Save_btn_des"
                  onClick={handleSaveQuantity}
                  loading={grnData.discrepancyResponseDataLoading}
                  variant="contained"
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
      <Dialog
        open={deleteIndex !== null}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: "333px", padding: "12px 16px" }}>
          <Box
            className="close_icon_btn cursor_pointer"
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
            onClick={handleDeleteCancel}
          >
            <IoClose className="ClosePop" />
          </Box>
          <DialogContentText
            className="dialog-title-remove"
            id="alert-dialog-description"
            style={{ paddingBottom: "10px" }}
          >
            Are you sure you want to remove?
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "black", fontSize: "12px", marginTop: "4px" }}
          >
            Note:The following{" "}
            <span className="dialog-title-remove" style={{ fontSize: "12px" }}>
              Product Slno:{serialNo}{" "}
            </span>
            will be removed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="crt-btn-cancel"
            style={{
              height: "30px",
              width: "25%",
              marginRight: "3px",
              fontSize: "12px",
            }}
            onClick={handleDeleteCancel}
          >
            {" "}
            Cancel
          </Button>
          <Button
            variant="contained"
            className="crt-btn-colr"
            style={{ height: "30px", width: "25%", fontSize: "12px" }}
            type="submit"
            sx={{ mr: 2 }}
            onClick={handleDeleteConfirm}
          >
            {" "}
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
