// third-party
import { configureStore } from '@reduxjs/toolkit';

// project import
import reducers from '../Redux/index';


// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
  reducer: reducers,
});

// const { dispatch } = store;

// export { store, dispatch };


export default store;

export type RootState = ReturnType<typeof reducers>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
