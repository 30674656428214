import * as React from "react";
import Box from "@mui/material/Box";
import { Grid, Button, Typography, TextField } from "@mui/material";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import CommonTable from "../../../components/Tables/CommonTable";
import AddIcon from "@mui/icons-material/Add";

interface DataType {
  key: React.Key;
  code: number;
  name: string;
  action: string;
}

export default function UOMMaster() {
  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // search function logic here
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "code",
      title: "UOM Code",
    },
    {
      dataIndex: "name",
      title: "UOM Name",
      sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      dataIndex: "action",
      title: "Actions",
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      code: 55,
      name: "Supplier1",
      action: "",
    },
    {
      key: 1,
      code: 55445,
      name: "Supplier2",

      action: "",
    },
    {
      key: 3,
      code: 5565,
      name: "Supplier3",

      action: "",
    },
    {
      key: 4,
      code: 5548,
      name: "Supplier4",
      action: "",
    },
    {
      key: 5,
      code: 55121,
      name: "Supplier5",
      action: "",
    },
    {
      key: 6,
      code: 5565,
      name: "Supplier6",
      action: "",
    },
    {
      key: 7,
      code: 55789,
      name: "Supplier7",
      action: "",
    },
    {
      key: 8,
      code: 55656,
      name: "Supplier8",
      action: "",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className=" mar-tb">
          <Typography>
            <b>UOM Master</b>
          </Typography>
        </Grid>
        <Grid
          container
          xs={12}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Grid item xs={12} sm={6} mb={2}>
            <Typography className="fnt-lable"> UOM Code </Typography>
            <TextField placeholder="  " className="Txt-box" />
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} mb={2}>
              <Typography className="fnt-lable"> UOM Name </Typography>
              <TextField placeholder="  " className="Txt-box" />
            </Grid>
            <Grid item>
              <Button sx={{ color: "#fff", ml: 3, mt: 3 }} className="btn-colr">
                <AddIcon /> Add
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Box>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "end", mb: 2 }}
          >
            <SearchBar
              className="search-table"
              value={textFieldValue}
              onChange={(newValue) => {
                setTextFieldValue(newValue);
                handleSearch();
              }}
            />
          </Grid>
          <CommonTable
            // rowSelection={rowSelection}
            data={data}
            columns={columns}
          />
        </Box>
        <Grid item xs={12} mt={2} display="flex" justifyContent="center">
          <Button variant="outlined" color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            className="btn-colr"
            style={{ marginLeft: "10px" }}
          >
            Save
          </Button>
        </Grid>
      </Box>
    </>
  );
}
