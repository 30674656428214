import React from "react";
import {
  Button,
  Grid,
  TextField,
  Box,
  MenuItem,
  Select,
  FormControl,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { TableColumnsType } from "antd";
import CommonTable from "../../../../components/Tables/CommonTable";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../../../components/Modal/DeleteSupplier";
import GeographyInfo from "./GeographyInfo";

// import ProductInfoView from "./ProductInfo";

// import DistributorSales from "./DistributorSales";
interface DataType {
  key: React.Key;
  UOMdescription: string;
  Conversion: string;
  action: string;
}

interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const ProdcutInfoview: React.FC<DistributorTabProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };

  const [isDelete, setIsDelete] = React.useState(false);

  const Options = ["Gram", "", ""];
  const option = ["Normal", "", ""];

  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };

  //table
  const columns: TableColumnsType<DataType> = [
    {
      title: "UOM description",
      dataIndex: "UOMdescription",
    },
    {
      title: "Conversion Factor ",
      dataIndex: "Conversion",
      render: (_: any, record: DataType) => (
        <>
          <TextField></TextField>
        </>
      ),
      //   sorter: (a, b) => a.CreatedDate.length - b.CreatedDate.length,
    },
    {
      dataIndex: "action",
      title: "Action",

      render: (_: any, record: DataType) => (
        <>
          <DeleteIcon
            style={{
              cursor: "pointer",
              marginLeft: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
            onClick={(event) => handleDeleteClick(event)}
          />
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      UOMdescription: "PC",
      Conversion: "",
      action: "",
    },
    {
      key: "2",
      UOMdescription: "PC",
      Conversion: "",
      action: "",
    },
  ];
  const handleDeleteClick = (event: React.MouseEvent<unknown>) => {
    setIsDelete(!isDelete);
  };

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <>
      {show ? (
        <GeographyInfo onBack={handleclick} />
      ) : (
        <Box>
          <form noValidate>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">
                  Product Hierarchy Least Level Value
                </Typography>
                <TextField placeholder="" className="Txt-box" />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">Product code</Typography>
                <TextField placeholder="Product code" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable"> Product Name</Typography>
                <TextField placeholder=" Product Name " className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">
                  Shelf Life(in Months)
                </Typography>
                <TextField placeholder="" className="Txt-box" />
              </Grid>
            </Grid>
            <Grid
              container
              mt={3}
              rowSpacing={3}
              columnSpacing={{ xs: 3, sm: 3 }}
            >
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">Short Name </Typography>
                <TextField placeholder="Short Name  " className="Txt-box" />
              </Grid>

         

              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">EAN Code </Typography>
                <TextField placeholder="EAN Code  " className="Txt-box" />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">Net Wgt</Typography>
                <TextField placeholder="" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">Weight Type</Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    className="selct-box"
                  >
                    {Options.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">Product Type</Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    className="selct-box"
                  >
                    {option.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className="fnt-lable">Drug Product</Typography>

                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Yes	"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="Yes	"
                    />
                    <FormControlLabel
                      value="No	"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="No	"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className="fnt-lable">Status</Typography>

                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Action	"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="Action	"
                    />
                    <FormControlLabel
                      value="InAction	"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="InAction	"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item mt={3}>
              <CommonTable
                // rowSelection={rowSelection}
                data={data}
                columns={columns}
              />
            </Grid>
            {isDelete && (
              <DeleteModal onBack={(event) => handleDeleteClick(event)} />
            )}

            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", mt: 4 }}
            >
              <Grid item sx={{ mr: 2 }}>
                <Button variant="outlined" onClick={handleBackClick}>
                  <CancelIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />{" "}
                  Cancel
                </Button>
              </Grid>
              <Grid>
                <Button
                  className="btn-colr "
                  variant="contained"
                  onClick={handleclick}
                >
                  <RedoOutlinedIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />
                  Next
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </>
  );
};
export default ProdcutInfoview;
