import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import { Drawer, Grid, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import type { TableColumnsType } from "antd";
import { Spin, Table, Tooltip } from "antd";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import { ArrowBackIos } from "@mui/icons-material";
import { getOrderStatusDetails } from "../../../Redux/XDM/Sales/orderBillingReducer";
import debounce from "lodash.debounce";
import moment from "moment";
import SearchBar from "@mkyy/mui-search-bar";
interface OutletDetailsProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
  status: string;
}
interface RequestData {
  orderId: string;
  invoiceNo: string;
  date: string;
  outletName: string;
  route: string;
  salesMan: string;
  noOfLineItem: number;
  orderValue: string;
  billedValue: string;
  status: string;
}
interface orderStatusDetailsData {
  productName: string;
  uom: string;
  orderQty: number;
  billedValue: number;
  invoiceStatus: string;
  orderValue: number;
  billedQty: number;
  productCode: string;
}
interface OrderBillingData {
  productName: string;
  productCode: string;
  uom: string;
  orderQuantity: string;
  orderValue: number;
  billedQuantity: number;
  billedValue: number;
  status: string;
}

export default function OrderBillingStatusDetails(props: OutletDetailsProps) {
  const { onClose, requestData, status } = props; // Destructure the props
  const [hasMore, setHasMore] = useState(true);
  const [commonLoader, setCommonLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(3);
  const tableHeightRef = useRef(140);
  const tableHeightloadRef = useRef(160);
  const [textFieldValue, setTextFieldValue] = React.useState("");
  let orderBillingData = useAppSelector((state) => state.orderBillingData);

  const formatDate = (date: string) => {
    if (date) {
      const dateObj = moment(date, "YYYY-MM-DD");
      return dateObj.format("DD MMM YYYY");
    }
    return "-";
  };
  const dispatch = useAppDispatch();

  const [orders, setOrders] = React.useState<orderStatusDetailsData[]>([]);
  const filterData = {
    page: 0,
    orderId: requestData.orderId,
    size: 15,
  };
  const abortControllerRef = useRef<AbortController | null>(null);

  const filteredDataToShow = orders.filter((item) => {
    return (
      (item.productName?.toLowerCase().includes(textFieldValue.toLowerCase()) || '') ||
      (item.productCode?.toLowerCase().includes(textFieldValue.toLowerCase()) || '') 
    );
  });

  const handleSearch = () => {
    setTextFieldValue(textFieldValue);
  };
  

  useEffect(() => {
    setPage(0);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setHasMore(true);
    setOrders([]);
    loadMoreData(true);
  }, [dispatch, requestData]);

  useEffect(() => {
    if (status === "Confirmed") {
      setSize(3);
    } else {
      setSize(4);
    }
  }, [status]);
  type TableRowType = orderStatusDetailsData | { noMoreData: boolean };
  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 373;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const tableHeightLoad = () => {
    const headerHeight = 0;
    const footerHeoght = 320;
    const availableHeight = window.innerHeight - headerHeight - footerHeoght;
    tableHeightloadRef.current = availableHeight;
  };
  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    tableHeightLoad();
    window.addEventListener("resize", updateTableHeight);


    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;
    filterData.page = nextPage;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;

    dispatch(getOrderStatusDetails(filterData)).then((response: any) => {
      if (response.payload) {
        const newProducts = response.payload || [];
        setOrders((prevProducts) =>
          resetPage ? newProducts : [...prevProducts, ...newProducts]
        );
        setPage(nextPage + 1);
        if (newProducts.length < 15) {
          setHasMore(false);
        }
        setCommonLoader(false);
      } else {
        // setHasMore(false);
      }
    });


  };
  const tableBodyRef = useRef<HTMLElement | null>(null);

  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );
  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const columns1: TableColumnsType<orderStatusDetailsData> = [
    {
      dataIndex: "productName",
      title: "Product Name",
      width: "20%",
      ellipsis: true,
      // sorter: (a, b) => a.productName.localeCompare(b.productName),
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };

        return <div>{record.productName ? record.productName : "--"}</div>;
      },
    },

    {
      dataIndex: "uom",
      ellipsis: true,
      width: "15%",
      title: "UOM",
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return <div>{record.uom ? record.uom : "--"}</div>;
      },
    },
    {
      dataIndex: "orderQty",
      width: "15%",
      ellipsis: true,
      title: "Order Quantity",
      // sorter: (a, b) => a.orderQty.localeCompare(b.orderQty),
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };

        return <div>{record.orderQty ? record.orderQty : "--"}</div>;
      },
    },
    {
      dataIndex: "orderValue",
      width: "15%",
      ellipsis: true,
      title: "Order Value",
      render: (text: string | number, record: TableRowType) => {
        if ("noMoreData" in record) {
          return {
            children: (
              <span
                className="fs-12 noMore"
                style={{ display: "block", margin: "10px" }}
              >
                <b>No more data found...</b>
              </span>
            ),
            props: {
              colSpan: status === "Confirmed" ? 8 : 5, // Adjust this number based on the total number of columns to span
            },
          };
        }
        return (
          <span>
            <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
            {record.orderValue ? record.orderValue.toFixed(2) : "0.00"}
          </span>
        );
      },
      //   sorter: (a, b) => a.orderValue.localeCompare(b.orderValue),
    },

    {
      title: "Status",
      dataIndex: "invoiceStatus",
      ellipsis: true,
      width: "15%",
      key: "invoiceStatus",
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        const color =
          record.invoiceStatus?.toLowerCase() === "confirmed"
            ? "green"
            : status?.toLowerCase() === "rejected" ||
              record.invoiceStatus?.toLowerCase() === "rejected" ||
              record.invoiceStatus?.toLowerCase() === "cancelled" ||
              status?.toLowerCase() === "cancelled"
              ? "red"
              : record.invoiceStatus?.toLowerCase() === "partially confirmed" ?"orange"
              : "black";
        const statusVal =
          status?.toLowerCase() === "rejected"
            ? "Rejected"
            : status?.toLowerCase() === "cancelled"
              ? "Cancelled"
              : record.invoiceStatus;
        return <b style={{ color }}>{statusVal ? statusVal : "--"}</b>;
      },
    },
  ];
  if (status === "Confirmed") {
    columns1.splice(1, 0, {
      dataIndex: "productCode",
      ellipsis: true,
      title: "Product Code",
      width: "20%",
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };

        return <div>{record.productCode ? record.productCode : "--"}</div>;
      },
    });
    columns1.splice(5, 0, {
      dataIndex: "billedQty",
      width: "15%",
      ellipsis: true,
      title: "Billed Quantity",
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };

        return (
          <div>
            {record.billedQty &&
              record.invoiceStatus !== "Rejected" &&
              record.invoiceStatus !== "Cancelled"
              ? record.billedQty
              : "--"}
          </div>
        );
      },
      //   sorter: (a, b) => a.orderValue.localeCompare(b.orderValue),
    });
    columns1.splice(6, 0, {
      dataIndex: "billedValue",
      width: "15%",
      ellipsis: true,
      title: "Billed Value",
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };

        return (
          <span>
            {record.billedValue &&
              record.invoiceStatus !== "Rejected" &&
              record.invoiceStatus !== "Cancelled"
              ? "₹" + record.billedValue.toFixed(2)
              : "--"}
          </span>
        );
      },
      //   sorter: (a, b) => a.orderValue.localeCompare(b.orderValue),
    });
  }
  const dataToShow: orderStatusDetailsData[] =
    orders.length > 0
      ? hasMore
        ? orders
        : [...orders]
      : [];
  // const dataToShow: orderStatusDetailsData[] = hasMore ? orders : [...orders];
  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_outlet w-100px"
      // variant="persistent"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          {/* <Grid item role="presentation"> */}
            <Grid
              container
              className="crt-header"
              mt={1}
            sx={{ padding: "10px 36px 0px 36px" }}
            >
              <Grid item xs={12} sm={8} md={9}>
              <Typography gutterBottom className="outlet-header-lable">
                <ArrowBackIos
                  sx={{
                    fontSize: "10px",
                    cursor: "pointer",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    onClose();
                  }}
                />
                { }
                Review Order ({requestData.orderId})<br />
                <span
                  style={{
                    color: requestData.status === "Confirmed" ? "green" 
                    :requestData.status === "Partially Confirmed"?
                     "orange"
                    : "red",
                    marginLeft: "20px",
                  }}
                >
                  ({requestData.status})
                </span>
              </Typography>
              </Grid>
              <Grid item xs={6} sm={4} md={3} >
              <SearchBar 
                  className="search_box_pending w-100px"
                  placeholder="Search Product Name, Code...."
                  value={textFieldValue}
                  onChange={(newValue) => {
                    setTextFieldValue(newValue);
                  }}
                  onCancelResearch={() => setTextFieldValue("")}
                  onSearch={(newValue) => {
                    handleSearch();
                  }}
                />
                </Grid>
            </Grid>
          {/* </Grid>  */}
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form saleable-products-box"
          >
            {" "}
            <Grid
              item
              sx={{
                width: "95%",
                margin: "auto",
                marginBottom: "90px",
              }}
            >
              {" "}
              <Grid container mt={1}>
                {status === "Confirmed" && (
                  <Grid item xs={12} sm={6} md={4} lg={size}>
                    <Typography className="crt-fnt-lable-light" mt={1}>
                      Invoice No1
                    </Typography>
                    <Typography gutterBottom className="crt-lable-input" mt={1}>
                      {requestData.invoiceNo ? requestData.invoiceNo : "--"}
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12} sm={6} md={4} lg={size}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Date
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {requestData.date ? formatDate(requestData.date) : "--"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={size}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Outlet
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {requestData.outletName ? requestData.outletName : "--"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={size}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Route
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {requestData.route ? requestData.route : "--"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={size}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    SalesMan
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {requestData.salesMan ? requestData.salesMan : "--"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={size}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    No of Line Item
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {requestData.noOfLineItem ? requestData.noOfLineItem : "0"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={size}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Order Value
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
                    {requestData.orderValue
                      ? Number(requestData.orderValue).toFixed(2)
                      : "0"}
                  </Typography>
                </Grid>
                {status === "Confirmed" && (
                  <Grid item xs={12} sm={6} md={4} lg={size}>
                    <Typography className="crt-fnt-lable-light" mt={1}>
                      Billed Value
                    </Typography>
                    <Typography gutterBottom className="crt-lable-input" mt={1}>
                      <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
                      {requestData.billedValue
                        ? Number(requestData.billedValue).toFixed(2)
                        : "0"}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Divider />
              <Grid container mt={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="pad-top-0px"
                  sx={{ height: tableHeightloadRef.current }}
                >
                  <div className="saleable-table-scroll"
                  >
                    <Table
                      className={`Header-table saleable-table sale_last_hide scroll_cus_table elipsTable pending_grn ${orderBillingData?.orderDetailsDataStatusDataLoading
                        ? " nodataloader" : ""
                        }`}
                      columns={columns1}
                      dataSource ={filteredDataToShow}
                      // dataSource={dataToShow}
                      pagination={false}
                      loading={orderBillingData?.orderDetailsDataStatusDataLoading}
                      showSorterTooltip={false}
                      // summary={() => {
                      //   return (
                      //     <>
                      //       {page !== 0 && orderBillingData?.orderDetailsDataStatusDataLoading ? (
                      //         <Table.Summary.Row>
                      //           <Table.Summary.Cell
                      //             index={1}
                      //             colSpan={status === "Confirmed" ? 8 : 5}
                      //             align="center"
                      //           >
                      //             <Spin />
                      //           </Table.Summary.Cell>
                      //         </Table.Summary.Row>
                      //       ) : (
                      //         !hasMore && (
                      //           <Table.Summary.Row>
                      //             <Table.Summary.Cell
                      //               index={1}
                      //               colSpan={status === "Confirmed" ? 8 : 5}
                      //               align="center"
                      //               className="fs-12 noMore btm_boreder"
                      //             >
                      //               <b>No more data found...</b>
                      //             </Table.Summary.Cell>
                      //           </Table.Summary.Row>
                      //         )
                      //       )}
                      //     </>
                      //   );
                      // }}
                      summary={() => {
                        if (!hasMore && orders.length > 0) {
                          return (
                            <Table.Summary.Row>
                              <Table.Summary.Cell
                                index={1}
                                colSpan={status === "Confirmed" ? 8 : 5}
                                align="center"
                                className="fs-12 noMore btm_boreder"
                              >
                                <b>No more data found...</b>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        } else if (
                          page !== 0 &&
                          orderBillingData?.orderDetailsDataStatusDataLoading
                        ) {
                          return (
                            <Table.Summary.Row>
                              <Table.Summary.Cell
                                index={1}
                                colSpan={status === "Confirmed" ? 8 : 5}
                                align="center"
                              >
                                <Spin />
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        }
                      }}
                      scroll={{ y: tableHeightRef.current }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
