import { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import styles from "./Slider.module.css";

interface ModalProps {
  handleImageClose: () => void;
  open: boolean;
  imageList: (string | File)[]; // Allow both URLs (strings) and File objects
}

export default function ImageModal(props: ModalProps) {
  const { handleImageClose, open, imageList } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageURLs, setImageURLs] = useState<string[]>([]);

  // Create a ref to control the Slide component
  const slideRef = useRef<any>(null);

  // Generate blob URLs for File objects when imageList changes
  useEffect(() => {
    const urls = imageList?.map((image) =>
      typeof image === "string" ? image : URL.createObjectURL(image)
    );

    setImageURLs(urls);

    // Cleanup the blob URLs when the component unmounts or imageList changes
    return () => {
      urls?.forEach((url) => {
        if (url.startsWith("blob:")) {
          URL.revokeObjectURL(url);
        }
      });
    };
  }, [imageList]);

  // Function to change slide
  const handleThumbnailClick = (index: number) => {
    setCurrentIndex(index);
    if (slideRef.current) {
      slideRef.current.goTo(index); // Use goTo method to jump to the clicked slide
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropProps={{ timeout: 500 }}
      className="modal_image"
    >
      <>
        <IconButton
          aria-label="close"
          onClick={handleImageClose}
          sx={{
            position: "fixed",
            right: 7,
            top: 50,
            color: "white",
            zIndex: 1300,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box className={styles.boxPad}>
          {imageURLs?.length > 1 ? (
            <Slide
              ref={slideRef} // Attach the ref to the Slide component
              easing="ease"
              indicators={true}
              autoplay={false}
              defaultIndex={currentIndex}
              onChange={(oldIndex, newIndex) => setCurrentIndex(newIndex)} // Sync currentIndex on slide change
            >
              {imageURLs.map((src, index) => (
                <div className={styles.slide} key={index}>
                  <img
                    src={src}
                    alt={`Slide ${index + 1}`}
                    style={{ maxWidth: "165px", maxHeight: "100%" }}
                  />
                </div>
              ))}
            </Slide>
          ) : (
            <div className={styles.singleImage}>
              <img src={imageURLs[0]} alt="Single Image" />
            </div>
          )}

          {imageURLs.length > 1 && (
            <div className={styles.thumbnailContainer}>
              {imageURLs.map((src, index) => (
                <div
                  className={styles.thumbnail}
                  key={index}
                  onClick={() => handleThumbnailClick(index)} // Handle thumbnail click
                >
                  <img
                    src={src}
                    alt={`Thumbnail ${index + 1}`}
                    className={`${styles.thumbnailImage} ${
                      index === currentIndex ? styles.activeImage : ""
                    }`}
                  />
                </div>
              ))}
            </div>
          )}
        </Box>
      </>
    </Modal>
  );
}
