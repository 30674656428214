import {
  GST_STATE_TAX_SUBMIT_FORM,
  GST_STATE_TAX_Submit_ERROR,
  GST_STATE_TAX_FETCH_DATA,
  GST_State_Tax_ACTIVE_STATUS,
  GST_STATE_TAX_EDIT_DATA,
  GST_STATE_TAX_UPDATE_DATA,
  GST_STATE_TAX_UPDATE_ERROR,
} from "../../../actionType";
import { Dispatch } from "redux";
import createAxiosInstance from "../../../../hooks/useFetch";
import { GstStateTaxFormValues } from "../../../../typescript/product";
const axiosInstance = createAxiosInstance();

export interface GstStateTaxSubmitAction {
  type: typeof GST_STATE_TAX_SUBMIT_FORM;
  payload: any;
}

export interface GstStateTaxSubmitErrorAction {
  type: typeof GST_STATE_TAX_Submit_ERROR;
  payload: string;
}
export interface GstStateTaxGetAction {
  type: typeof GST_STATE_TAX_FETCH_DATA;
  payload: any;
}
export interface GstStateTaxActiveStatus {
  type: typeof GST_State_Tax_ACTIVE_STATUS;
  payload: any;
}
export interface GstStateTaxEdit {
  type: typeof GST_STATE_TAX_EDIT_DATA;
  payload: any;
}
export interface GstStateUpdate {
  type: typeof GST_STATE_TAX_UPDATE_DATA;
  payload: any;
}
export interface GstStateUpdateErrorAction {
  type: typeof GST_STATE_TAX_UPDATE_ERROR;
  payload: string;
}

export const GstStateTaxSubmitForm =
  (formData: GstStateTaxFormValues) =>
  async (
    dispatch: Dispatch<GstStateTaxSubmitAction | GstStateTaxSubmitErrorAction>
  ) => {
    try {
      const response = await axiosInstance.post(`product-gst/create`, formData);
      dispatch({
        type: GST_STATE_TAX_SUBMIT_FORM,
        payload: response.data, // Assuming response contains data
      });
      return response.data;
    } catch (error: any) {
      dispatch({
        type: GST_STATE_TAX_Submit_ERROR,
        payload: error.response.data.data,
      });
      throw error;
    }
  };

export const GstStateTaxFetchData = (requestData: {
  page: number;
  size: number;
}) => {
  return (dispatch: Dispatch<GstStateTaxGetAction>) => {
    axiosInstance
      .get(`product-gst/get`, {
        params: requestData, // Pass requestData as query parameters
      })
      .then((res) => {
        dispatch({
          type: GST_STATE_TAX_FETCH_DATA, // Change this to COMPANY_FETCH_DATA
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};

export const GstStateTaxActiveStatus = ({
  id,
  status,
}: {
  id: string;
  status: boolean;
}) => {
  return (dispatch: Dispatch<GstStateTaxActiveStatus>) => {
    axiosInstance
      .put(
        `product-gst/active-in-active`,
        { id, status } // Pass requestData as query parameters
      )
      .then((res) => {
        dispatch({
          type: GST_State_Tax_ACTIVE_STATUS, // Change this to COMPANY_FETCH_DATA
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};

export const GstStateTaxEdit = (id: string) => {
  return (dispatch: Dispatch<GstStateTaxEdit>) => {
    axiosInstance
      .get(`product-gst/get-byid/${id}`)
      .then((res) => {
        dispatch({
          type: GST_STATE_TAX_EDIT_DATA, // Dispatch the type of action
          payload: res.data.data, // Pass any data received from the API response
        });
      })
      .catch((error) => {
        // Handle errors if any
      });
  };
};

export const GstStateTaxUpdate =
  ({ id, formData }: { id: string; formData: GstStateTaxFormValues }) =>
  async (dispatch: Dispatch<GstStateUpdate | GstStateUpdateErrorAction>) => {
    try {
      const response = await axiosInstance.put(
        `product-gst/update/${id}`,
        formData
      );
      dispatch({
        type: GST_STATE_TAX_UPDATE_DATA,
        payload: response.data, // Assuming response contains data
      });
      return response.data;
    } catch (error: any) {
      dispatch({
        type: GST_STATE_TAX_UPDATE_ERROR,
        payload: error.response.data.data,
      });
      throw error;
    }
  };
