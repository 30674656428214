import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import { Checkbox, Input, Space, Button, Card } from "antd";
import CommonTable from "../../../../components/Tables/CommonTable";
import { SearchOutlined } from "@ant-design/icons";
import { LuPanelRightClose } from "react-icons/lu";
import { hierarchyCardWidth } from "../../../../config";
import vector from "../../../../assets/images/icons/Vector.png";
import Plus from "../../../../assets/images/icons/Group.png";
import Search from "../../../../assets/images/icons/Icon 24px.png";
import { IoCloseOutline } from "react-icons/io5";
import SearchBar from "@mkyy/mui-search-bar";
import ProductHierarchyCreate from "./ProductHierchyCreate";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useFetch";
import { ProductHierarchyFetchData } from "../../../../Redux/Masters/Products/ProductHierarchy/action";
import { sidebarFilter } from "../../../../Redux/AuthLogin/action";
interface DataType {
  key: React.Key;
  business: string;
  catlevel: string;
  brandlevel: string;
  brandcat: string;
  productlevel: string;
  sku: string;
  grouplevel: string;
}

export default function MasterProductHierachy() {
  const dispatch = useAppDispatch();
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [isRotated, setIsRotated] = useState(false);
  const [requestData, setRequestData] = useState({
    search: "",
    businessLevel: "",
    categoryLevel: "",
    brandLevel: "",
    brandCategory: "",
    productLevel: "",
    skuGroup: "",
    skuLevel: "",
    page: 0,
    size: 3,
  });
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    dispatch(ProductHierarchyFetchData(requestData));
  }, [dispatch, requestData]);

  useEffect(() => {
    dispatch(sidebarFilter(true, "Product Hierarchy"));
  });

  const productHierarchyFetchDatas = useAppSelector(
    (state: any) => state.ProductHierarchyFetchData.ProductHierarchyFetch
  );

  const handleShowClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setIsShow(!isShow);
    toggleDrawer(true);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const filteredOptions = [
    "Beverages",
    "Food",
    "Personal Care",
    "Snacks",
    "Lunch",
    "Dinner",
  ].filter((option) => option.toLowerCase().includes(searchText.toLowerCase()));

  const handleCheckboxChange = (selected: string[]) => {
    setSelectedItems(selected);
  };

  const getColumnFilterProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: {
      setSelectedKeys: (keys: string[]) => void;
      selectedKeys: string[];
      confirm: () => void;
      clearFilters: () => void;
    }) => (
      <Box style={{ padding: 8 }}>
        <Grid item container sx={{ flexWrap: "nowrap" }} columnSpacing={2}>
          <Grid item container sx={{ flexDirection: "column" }}>
            <Grid item>
              <Typography>
                <b style={{ fontSize: "12px" }}>Business Level Filters</b>
              </Typography>
              <Input
                placeholder="Search Filters"
                onChange={handleSearch}
                value={searchText}
                prefix={
                  <img
                    src={Search}
                    alt="search icon"
                    style={{ width: "14px" }}
                  />
                }
                className="filter_ser"
              />
            </Grid>
            <Grid item className="scroll_cbox">
              <Checkbox.Group
                onChange={handleCheckboxChange}
                className="filter_checkbox "
              >
                <Space direction="vertical" style={{ rowGap: "0px" }}>
                  {filteredOptions.map((option) => (
                    <Checkbox key={option} value={option}>
                      {option}
                    </Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>
            </Grid>
          </Grid>
          <Grid item container sx={{ flexDirection: "column" }}>
            <Grid item className="filter_dis" style={{ gap: "10px" }}>
              <Grid item>
                <Typography>
                  <b className="filter_text">
                    {" "}
                    {selectedItems.length} Filter Selected
                  </b>
                </Typography>
              </Grid>
              <Grid item className="filter_dis">
                <IoCloseOutline style={{ color: "red" }} />
                <Typography className="clr_text">Clear All</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Space direction="vertical" className="filter_checkbox">
                {selectedItems.map((item, index) => (
                  <Checkbox key={index} checked={true}>
                    {item}
                  </Checkbox>
                ))}
              </Space>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="btn_filter">
          <Button size="small" style={{ width: 80, fontSize: "10px" }}>
            Close
          </Button>
          <Button
            type="primary"
            size="small"
            style={{ width: 80, fontSize: "10px" }}
            className="btn-colr"
          >
            Apply
          </Button>
        </Grid>
      </Box>
    ),
    filterIcon: () => (
      <Box>
        <img
          src={vector}
          alt="filter icon"
          style={{ height: "14px", color: "#944986" }}
        />
      </Box>
    ),
    render: (text: string) => (searchedColumn === dataIndex ? <></> : text),
  });

  const columns = [
    {
      dataIndex: "businessLevelName",
      title: (
        <Grid item className="filter_spacing">
          <Grid item sx={{ display: "flex" }}>
            <Grid
              item
              className="table_title"
              sx={{ width: { sm: "194px", lg: "150px" } }}
            >
              BUSINESS LEVEL NAME (100)
            </Grid>
            <Grid
              item
              sx={{ marginRight: "-10%", pl: { sm: "0px", lg: "60px" } }}
            >
              <img
                src={Plus}
                alt="plus icon"
                style={{ height: "10px" }}
                onClick={handleShowClick}
              />
            </Grid>
          </Grid>
          <Grid className="table_search">
            <Input
              placeholder="Search Country..."
              prefix={
                <img src={Search} alt="search icon" style={{ width: "17px" }} />
              }
              style={{ width: "85%" }}
            />
          </Grid>
        </Grid>
      ),
      ...getColumnFilterProps("businessLevelName"),
    },
    {
      dataIndex: "categoryLevel",
      title: (
        <Grid item className="filter_spacing">
          <Grid item sx={{ display: "flex" }}>
            <Grid
              className="table_title"
              sx={{ width: { sm: "194px", lg: "150px" } }}
            >
              CATEGORY LEVEL (200){" "}
            </Grid>
            <Grid
              item
              sx={{ marginRight: "-10%", pl: { sm: "0px", lg: "60px" } }}
            >
              <img
                src={Plus}
                alt="plus icon"
                style={{ height: "10px" }}
                onClick={handleShowClick}
              />
            </Grid>
          </Grid>
          <Grid item className="table_search">
            <Input
              placeholder="Search Region..."
              prefix={
                <img src={Search} alt="search icon" style={{ width: "17px" }} />
              }
              style={{ width: "85%" }}
            />
          </Grid>
        </Grid>
      ),
      ...getColumnFilterProps("categoryLevel"),
    },
    {
      dataIndex: "brandLevel",
      title: (
        <Grid item className="filter_spacing">
          <Grid item sx={{ display: "flex" }}>
            <Grid
              className="table_title"
              sx={{ width: { sm: "194px", lg: "150px" } }}
            >
              {" "}
              BRAND LEVEL(300)
            </Grid>

            <Grid
              item
              sx={{ marginRight: "-10%", pl: { sm: "0px", lg: "60px" } }}
            >
              <img
                src={Plus}
                alt="plus icon"
                style={{ height: "10px" }}
                onClick={handleShowClick}
              />
            </Grid>
          </Grid>
          <Grid item className="table_search">
            <Input
              placeholder="Search State..."
              prefix={
                <img src={Search} alt="search icon" style={{ width: "17px" }} />
              }
              style={{ width: "85%" }}
            />
          </Grid>
        </Grid>
      ),
      ...getColumnFilterProps("brandLevel"),
    },
    {
      dataIndex: "brandCategory",
      title: (
        <Grid item className="filter_spacing">
          <Grid item sx={{ display: "flex" }}>
            <Grid
              className="table_title"
              sx={{ width: { sm: "194px", lg: "150px" } }}
            >
              BRAND CATEGORY(400)
            </Grid>
            <Grid
              item
              sx={{ marginRight: "-10%", pl: { sm: "0px", lg: "60px" } }}
            >
              <img
                src={Plus}
                alt="plus icon"
                style={{ height: "10px" }}
                onClick={handleShowClick}
              />
            </Grid>
          </Grid>
          <Grid item className="table_search">
            <Input
              placeholder="Search District..."
              prefix={
                <img src={Search} alt="search icon" style={{ width: "17px" }} />
              }
              style={{ width: "85%" }}
            />
          </Grid>
        </Grid>
      ),
      ...getColumnFilterProps("brandCategory"),
    },
    {
      dataIndex: "productLevel",
      title: (
        <Grid item className="filter_spacing">
          <Grid item sx={{ display: "flex" }}>
            <Grid
              className="table_title"
              sx={{ width: { sm: "194px", lg: "150px" } }}
            >
              PRODUCT/VARIANT LEVEL(500)
            </Grid>
            <Grid
              item
              sx={{ marginRight: "-10%", pl: { sm: "0px", lg: "60px" } }}
            >
              <img
                src={Plus}
                alt="plus icon"
                style={{ height: "10px" }}
                onClick={handleShowClick}
              />
            </Grid>
          </Grid>
          <Grid item className="table_search">
            <Input
              placeholder="Search District..."
              prefix={
                <img src={Search} alt="search icon" style={{ width: "17px" }} />
              }
              style={{ width: "85%" }}
            />
          </Grid>
        </Grid>
      ),
      ...getColumnFilterProps("productLevel"),
    },
    {
      dataIndex: "skuLevel",
      title: (
        <Grid item className="filter_spacing">
          <Grid item sx={{ display: "flex" }}>
            <Grid
              className="table_title"
              sx={{ width: { sm: "194px", lg: "150px" } }}
            >
              SKU LEVEL(600)
            </Grid>
            <Grid
              item
              sx={{ marginRight: "-10%", pl: { sm: "0px", lg: "60px" } }}
            >
              <img
                src={Plus}
                alt="plus icon"
                style={{ height: "10px" }}
                onClick={handleShowClick}
              />
            </Grid>
          </Grid>
          <Grid item className="table_search">
            <Input
              placeholder="Search District..."
              prefix={
                <img src={Search} alt="search icon" style={{ width: "17px" }} />
              }
              style={{ width: "85%" }}
            />
          </Grid>
        </Grid>
      ),
      ...getColumnFilterProps("skuLevel"),
    },
    {
      dataIndex: "skuGroup",
      title: (
        <Grid item className="filter_spacing">
          <Grid item sx={{ display: "flex" }}>
            <Grid
              className="table_title"
              sx={{ width: { sm: "194px", lg: "150px" } }}
            >
              {" "}
              GROUP LEVEL(700)
            </Grid>
            <Grid
              item
              sx={{ marginRight: "-10%", pl: { sm: "0px", lg: "60px" } }}
            >
              <img
                src={Plus}
                alt="plus icon"
                style={{ height: "10px" }}
                onClick={handleShowClick}
              />
            </Grid>
          </Grid>
          <Grid item className="table_search">
            <Input
              placeholder="Search District..."
              prefix={
                <img src={Search} alt="search icon" style={{ width: "17px" }} />
              }
              style={{ width: "85%" }}
            />
          </Grid>
        </Grid>
      ),
      ...getColumnFilterProps("skuGroup"),
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      business: "beverages",
      catlevel: "beverage",
      brandlevel: "chillout",
      brandcat: "carbonated soft drink",
      productlevel: "city1",
      sku: "defaultCompany1",
      grouplevel: "",
    },
    {
      key: 2,
      business: "",
      catlevel: "beverage",
      brandlevel: "chillout",
      brandcat: "carbonated soft drink",
      productlevel: "",
      sku: "",
      grouplevel: "",
    },
  ];
  const toggleCardVisibility = () => {
    setIsCardVisible(!isCardVisible);
    setIsRotated(!isRotated);
  };
  return (
    <>
      <Box sx={{ pr: { xs: 3 }, pl: { xs: 2 } }}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sm={4}

            // className="mar-tb"
          >
            <Typography>
              <b className="header-lable">Product</b>
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SearchBar className="search_Product" value={""} />
          </Grid>
          <Grid
            item
            container
            xs={6}
            sm={1}
            md={1}
            // className="mar-tb"
            sx={{ display: "flex", justifyContent: "end",  }}
          >
            <LuPanelRightClose
              onClick={toggleCardVisibility}
              className={isRotated ? "rotate-180" : ""}
              style={{
                color: "#864179",
                fontSize: "17px",
                transition: "transform 0.3s ease",
              }}
            />
          </Grid>
        </Grid>
        <Box
          sx={{ mt: 3 }}
          id="TableScroll"
          className="table-container"
          style={{
            position: "relative",
            width: isCardVisible
              ? `calc(100% - ${hierarchyCardWidth}px)`
              : "100%",
            // width: `calc(100% - ${hierarchyCardWidth}px)`
          }}
        >
          <Grid item className="prd_hier_table">
            <CommonTable data={productHierarchyFetchDatas} columns={columns} />
          </Grid>
        </Box>
        {isCardVisible && (
          <Card
            title={
              <div className="title_search">
                Product Details
                <SearchOutlined style={{ fontSize: "12px" }} />
              </div>
            }
            className="card_hed"
            style={{ width: hierarchyCardWidth }}
          >
            <span>BudsnBerries C.Bers</span>
            <span>BudsnBerries Td.Coconut</span>
          </Card>
        )}
      </Box>
      {isShow ? (
        <ProductHierarchyCreate onClose={() => setIsShow(false)} />
      ) : (
        <></>
      )}
    </>
  );
}
