import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";

// import RoutePlanForm from './RoutePlanForm';
import Modal from "@mui/material/Modal";
import { Close, Padding } from "@mui/icons-material";
import GSTPlanForm from "./GSTPlanForm";
import GSTExcel from "./GSTExcelForm";
import DeleteModal from "../../../components/Modal/DeleteSupplier";
import GSTTaxstructureview from "./GSTTaxstructureview";
import CommonTable from "../../../components/Tables/CommonTable";
import { TableColumnsType } from "antd";
// import RouteExcelExport from './ExcelForm';
const GSTTaxstructure = () => {
  const navigator = useNavigate();

  const [showForm, setShowForm] = React.useState(false);
  const [excelForm, setExcelForm] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState("");
  const [isViewing, setIsViewing] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);

  interface DataType {
    key: React.Key;
    State: string;
    TaxType: string;
    TaxCode: string;
    EffectiveDate: string;

    action: string;
  }

  const handleViewClick = (event: React.MouseEvent<unknown>) => {
    setIsViewing(!isViewing);
  };
  const handleDeleteClick = (event: React.MouseEvent<unknown>) => {
    setIsDelete(!isDelete);
  };
  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "State",
      title: "State",
    },
    {
      dataIndex: "TaxType",
      title: "Tax Type",
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      dataIndex: "TaxCode",
      title: "Tax Code",
      // sorter: (a, b) => a.businessName.length - b.businessName.length,
    },
    {
      dataIndex: "EffectiveDate",
      title: "Effective Date",
      // sorter: (a, b) => a.state.length - b.state.length,
    },

    {
      dataIndex: "action",
      title: "Action",

      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            onClick={(event) => handleViewClick(event)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
          <DeleteIcon
            onClick={(event) => handleDeleteClick(event)}
            style={{
              cursor: "pointer",
              marginRight: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      State: "ANDHRA PRADESH",
      TaxType: "IGST",
      TaxCode: "IGST3",
      EffectiveDate: "27/10/2021",

      action: "",
    },
    {
      key: 1,
      State: "ANDHRA PRADESH",
      TaxType: "CGST",
      TaxCode: "CGST3",
      EffectiveDate: "27/10/2021",

      action: "",
    },
    {
      key: 3,
      State: "Telangana",
      TaxType: "IGST",
      TaxCode: "IGST3",
      EffectiveDate: "27/10/2021",

      action: "",
    },
    {
      key: 4,
      State: "Telangana",
      TaxType: "CGST",
      TaxCode: "CGST3",
      EffectiveDate: "27/10/2021",

      action: "",
    },
    {
      key: 5,
      State: "Karnataka",
      TaxType: "IGST",
      TaxCode: "IGST3",
      EffectiveDate: "27/10/2021",

      action: "",
    },
    {
      key: 6,
      State: "Karnataka",
      TaxType: "IGST",
      TaxCode: "CGST3",
      EffectiveDate: "27/10/2021",

      action: "",
    },
    {
      key: 7,
      State: "Karnataka",
      TaxType: "	CGST",
      TaxCode: "IGST3",
      EffectiveDate: "27/10/2021",

      action: "",
    },
    {
      key: 8,
      State: "Kerala",
      TaxType: "	CGST",
      TaxCode: "CGST3",
      EffectiveDate: "27/10/2021",

      action: "",
    },
  ];

  const handleCreateNew = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleGoChange = () => {
    if (selectedOption == "manual") {
      setIsModalOpen(!isModalOpen);
      setShowForm(true);
    } else if (selectedOption == "excel") {
      setIsModalOpen(!isModalOpen);
      setExcelForm(true);
    }
  };
  const handleShowClose = () => {
    setShowForm(false);
  };
  const handleExcelClose = () => {
    setExcelForm(false);
  };

  const RadiohandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className=" mar-tb">
          <Typography>
            <b className="header-lable"> GST Tax Structure</b>
          </Typography>
        </Grid>

        {showForm ? (
          <GSTPlanForm onBack={handleShowClose} />
        ) : excelForm ? (
          <GSTExcel onBack={handleExcelClose} />
        ) : isViewing ? (
          <GSTTaxstructureview onBack={(event) => handleViewClick(event)} />
        ) : (
          <Box>
            <Button
              variant="contained"
              className="btn-colr"
              onClick={handleCreateNew}
            >
              {" "}
              <AddIcon /> Create new
            </Button>
            <Grid item container sx={{ marginTop: "16px" }}>
              <Grid item xs={12} lg={6}>
                <Grid item sx={{ display: "flex", mt: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item>
                      <DatePicker name="startDate" />
                    </Grid>
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ mt: 2 }}>
              <Box sx={{ marginTop: "2px" }}>
                {showForm ? (
                  <></>
                ) : (
                  <Box>
                    <Grid item sx={{ mt: 2 }}>
                      <Box>
                        <CommonTable
                          // rowSelection={rowSelection}
                          data={data}
                          columns={columns}
                        />

                        {isDelete && (
                          <DeleteModal
                            onBack={(event) => handleDeleteClick(event)}
                          />
                        )}
                      </Box>
                    </Grid>
                  </Box>
                )}
              </Box>
            </Grid>
          </Box>
        )}
        <Modal
          open={isModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid>Choose Entry Mode</Grid>
            <Box sx={{ flex: "none", marginBottom: 1 }}>
              <IconButton
                aria-label="close"
                style={{ position: "absolute", top: 10, right: 10 }}
                onClick={handleCreateNew}
              >
                <Close />
              </IconButton>
            </Box>
            <Divider />
            <Grid item sx={{ mt: 2 }}>
              <RadioGroup
                row
                aria-label="mandatory"
                name="mandatory"
                value={selectedOption}
                onChange={RadiohandleChange}
              >
                <FormControlLabel
                  value="manual"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Manual Entry"
                />
                <FormControlLabel
                  value="excel"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Excel Entry"
                />
              </RadioGroup>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
              <Button
                variant="contained"
                className="btn-colr"
                onClick={handleGoChange}
              >
                Go
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default GSTTaxstructure;
