import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Drawer,
  FormControl,
  Grid,
  InputBase,
  Skeleton,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { Select } from "antd";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../hooks/useFetch";
import {
  mobilePlatformReasonList,
  productWithOutInvoiceReturn,
  setAddedSalesReturnData,
} from "../../../../../../Redux/XDM/Sales/salesReturnReducer";
import { useLocation, useNavigate } from "react-router-dom";
import Close from "../../../../../../assets/images/icons/Close_icon.png";
import remove_line from "../../../../../../assets/images/icons/remove_line.png";
import upload_file from "../../../../../../assets/images/icons/upload-file.png";
import Close_round_fill from "../../../../../../assets/images/icons/Close_round_fill.png";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDropzone } from "react-dropzone";
import { idID } from "@mui/material/locale";
import WithoutInvoiceConfirmReview from "./WithoutConfirmOrder";
import cartImage from "../../../../../../assets/images/icons/Obj_png.png";
import { IoClose } from "react-icons/io5";

interface ImageFile extends File {
  preview: string;
}

interface ReviewOrderDetails {
  productCode: string;
  productName: string;
  batchCode: string;
  expiryDate: string;
  quantity: string;
  uom: string;
  mrp: number;
  ptr: string;
  grossAmount: number;
  reason: string;
  image: string[];
  approvedQty: number;
  approvedValue: number;
  returnReason: string;
  status: string;
}
interface ImageUploadProps {
  index: number;
  id: string;
  setCardStates: React.Dispatch<
    React.SetStateAction<Record<number, CardState>>
  >;
}
interface CardState {
  selectedSalesmanIds: string[];
  showSelectReason: boolean;
  returnQty: number;
  productHierarchy: string;
  reasonDetails: Record<
    string,
    {
      type: string;
      reasonName: string;
      qty: string;
      mrp: string;
      expiryDate: Date | null;
      images: ImageFile[];
      productHierarchy: string;
    }
  >;
  returnQtyError?: string;
  selectReasonError?: string;
  returnQtyCompareError?: string;
  returnMatchQtyError?: string;
  returnAPIError?: string;
  expiryDateError?: string; // New field for expiry date error
  mrpError?: string;
}

const ImageUploadComponent: React.FC<ImageUploadProps> = ({
  index,
  id,
  setCardStates,
}) => {
  const [snackbarOpenImg, setSnackbarOpenImg] = useState(false);
  const [snackbarMessageImg, setSnackbarMessageImg] = useState("");
  const [snackbarSeverityImg, setSnackbarSeverityImg] = useState<
    "success" | "error" | "warning"
  >("success");
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setCardStates((prevState: any) => {
        const existingCardState = prevState[index] || {
          selectedSalesmanIds: [],
          reasonDetails: {},
          returnQty: 0,
          showSelectReason: false,
        };
        const currentImages = existingCardState.reasonDetails[id]?.images || [];

        // Check if the total number of images exceeds the limit of 5
        if (currentImages.length + acceptedFiles.length > 5) {
          setSnackbarMessageImg("You can only upload a maximum of 5 images.");
          setSnackbarSeverityImg("warning");
          setSnackbarOpenImg(true);
          setTimeout(() => {
            setSnackbarOpenImg(false);
          }, 3000);
          return prevState; // Return the current state without updating
        }

        const newImages = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );

        const updatedImages = [...currentImages, ...newImages];

        return {
          ...prevState,
          [index]: {
            ...existingCardState,
            reasonDetails: {
              ...existingCardState.reasonDetails,
              [id]: {
                ...existingCardState.reasonDetails[id],
                images: updatedImages,
              },
            },
          },
        };
      });
    },
    [index, setCardStates, id]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    onDrop,
    onDropRejected: () => {
      alert("Only image files are accepted!");
    },
  });

  return (
    <>
      <Snackbar
        open={snackbarOpenImg}
        autoHideDuration={100000}
        color="green"
        className="new-coll-alert"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setSnackbarOpenImg(false)}
      >
        <Alert
          onClose={() => setSnackbarOpenImg(false)}
          severity={snackbarSeverityImg}
          sx={{ width: "100%" }}
        >
          {snackbarMessageImg}
        </Alert>
      </Snackbar>
      <Box
        {...getRootProps()}
        sx={{
          border: "1px solid #DCDCDE",
          borderRadius: "9px",
          p: 2,
          bgcolor: isDragActive ? "action.hover" : "background.paper",
        }}
      >
        <input {...getInputProps()} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={upload_file} alt="upload-file" width={30} />
        </div>

        <Typography
          variant="body2"
          className="textcolorupload"
          sx={{
            fontStyle: "Montserrat",
            fontSize: "9px",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          Add image
        </Typography>
      </Box>
    </>
  );
};
const { Option } = Select;

export default function AddSalesReturn() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tableHeightLoad = useRef(453);
  const [returnTotal, setReturnTotal] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [cardStates, setCardStates] = useState<Record<number, CardState>>({});

  let salesreturnData = useAppSelector((state) => state.salesReturnData);

  const [dataRecordOrders, setDataRecordOrders] = useState<
    ReviewOrderDetails[]
  >([
    // Add more records as needed
  ]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [productHierarchy, setproductHierarchy] = useState<string>("");
  const [productName, setproductName] = useState<string>("");

  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  useEffect(() => {
    const headerHeightload = 0;
    const footerHeightload = 180;

    const availableHeightLoad =
      window.innerHeight - headerHeightload - footerHeightload;
    tableHeightLoad.current = availableHeightLoad;
  }, []);

  // const formatExpiryDate = (dateObj: any) => {
  //   return new Date(dateObj.$d).toLocaleDateString("en-US", {
  //     year: "numeric",
  //     month: "long",
  //     day: "numeric",
  //   });
  // };

  const formatExpiryDate = (expiryDate: any) => {
    // Create a new Date object from the current expiryDate
    const date = new Date(expiryDate);

    // Get the local date in 'YYYY-MM-DD' format
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Month is 0-based, so we add 1
    const day = ("0" + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`; // Return the formatted date
  };

  const location = useLocation();
  const getQueryParams = (query: any) => {
    return new URLSearchParams(query);
  };

  const queryParams = getQueryParams(location.search);
  const outletCode = queryParams.get("outletcode")?.toString();

  const updateExpiryDateError = (
    errorMessageKey: string,
    errorMessageValue: string
  ) => {
    setCardStates((prevCardStates) => {
      const updatedCardStates = { ...prevCardStates };

      // Iterate over the cardStates to find the matching productHierarchy
      Object.keys(updatedCardStates).forEach((cardKey) => {
        const reasonDetails =
          updatedCardStates[parseInt(cardKey)].reasonDetails;

        // Find the matching productHierarchy in reasonDetails
        Object.values(reasonDetails).forEach((reasonDetail) => {
          if (reasonDetail?.productHierarchy === errorMessageKey) {
            updatedCardStates[parseInt(cardKey)].returnAPIError =
              errorMessageValue;
          }
        });
      });

      return updatedCardStates;
    });
  };
  useEffect(() => {
    if (addedSalesReturnData) {
      const initialCardStates = addedSalesReturnData.reduce(
        (acc: any, item: any, index: number) => {
          acc[index] = {
            productHierarchy: item.productHierarchy,
            showSelectReason: false,
            returnQty: 0,
            reasonDetails: { productHierarchy: item.productHierarchy },
            selectedSalesmanIds: [],
          };
          return acc;
        },
        {} as Record<number, CardState>
      );
      setCardStates(initialCardStates);
    }
  }, []);
  const handleNextClick = () => {
    setCardStates((prevCardStates) => {
      const updatedCardStates = { ...prevCardStates };
      Object.keys(updatedCardStates).forEach((cardKey) => {
        updatedCardStates[parseInt(cardKey)].returnAPIError = "";
      });

      return updatedCardStates;
    });
    const formData = new FormData();
    const updatedReturnDetails = Object.values(cardStates).flatMap(
      (data: any) => {
        return Object.values(data.reasonDetails)
          .map((reason: any) => {
            const matchingRequestData = addedSalesReturnData?.find(
              (request: any) =>
                request.productHierarchy === reason.productHierarchy
            );

            if (matchingRequestData) {
              if (Object.values(data.reasonDetails).length === 1) {
                reason.qty = data.returnQty;
              }
              return {
                reasonType: reason.type,
                returnReason: reason.reasonName,
                returnQty: reason.qty,
                expiryDate: formatExpiryDate(reason.expiryDate),
                image: reason.images,
                productHierarchy: reason.productHierarchy,
                productName: matchingRequestData.productName,
                uom: matchingRequestData.uom,
                mrp: reason.mrp,
              };
            }
            return null;
          })
          .filter((detail: any) => detail !== null);
      }
    );

    // Set up formData
    formData.append("outletCode", outletCode || "");

    updatedReturnDetails.forEach((item: any, index: number) => {
      formData.append(
        `productReturnRequests[${index}].returnQty`,
        item.returnQty
      );
      formData.append(
        `productReturnRequests[${index}].productName`,
        item.productName
      );
      formData.append(
        `productReturnRequests[${index}].productHierarchy`,
        item.productHierarchy
      );
      formData.append(`productReturnRequests[${index}].uom`, item.uom);
      formData.append(
        `productReturnRequests[${index}].returnReason`,
        item.returnReason
      );
      formData.append(
        `productReturnRequests[${index}].expiryDate`,
        item.expiryDate
      );
      formData.append(
        `productReturnRequests[${index}].returnType`,
        item.reasonType
      );
      formData.append(`productReturnRequests[${index}].mrp`, item.mrp);

      // Append each image file (make sure item.image contains File objects)
      if (item.image && Array.isArray(item.image)) {
        item.image.forEach((img: File) => {
          formData.append(`productReturnRequests[${index}].image`, img);
        });
      } else if (item.image instanceof File) {
        formData.append(`productReturnRequests[${index}].image`, item.image);
      }
    });
    setButtonDisabled(true);

    const abortController = new AbortController();
    dispatch(
      productWithOutInvoiceReturn({
        formData,
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response?.meta?.requestStatus === "fulfilled") {
          setCardStates((prevCardStates) => {
            const updatedCardStates = { ...prevCardStates };
            Object.keys(updatedCardStates).forEach((cardKey) => {
              updatedCardStates[parseInt(cardKey)].returnAPIError = "";
            });

            return updatedCardStates;
          });
          setButtonDisabled(false);
          
          setOpenConfirm(true);
        } else if (response?.meta?.requestStatus === "rejected") {
          const errorData = response?.payload?.data;
          if (errorData && typeof errorData === "object") {
            const consolidatedErrors: Record<string, string> = {};

            Object.entries(errorData).forEach(
              ([errorMessageKey, errorMessageValue]) => {
                const messageString = Array.isArray(errorMessageValue)
                  ? errorMessageValue.join(", ")
                  : (errorMessageValue as string);

                if (consolidatedErrors[errorMessageKey]) {
                  // Append with a comma and space if there's already an existing message
                  consolidatedErrors[errorMessageKey] += `, ${messageString}`;
                } else {
                  consolidatedErrors[errorMessageKey] = messageString;
                }
              }
            );

            Object.entries(consolidatedErrors).forEach(([key, message]) => {
              updateExpiryDateError(key, message); // Now passing the concatenated messages
            });

          }
        }
        setButtonDisabled(false);
      })
      .catch((err) => {
        setSnackbarMessage("An unexpected error occurred");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
        }, 2000);
        setButtonDisabled(false);
      });
  };

  const addReasonList = salesreturnData.reasonList;

  const addedSalesReturnData = useAppSelector(
    (state) => state.salesReturnData?.addedData
  );

  const clearAddedRecords = () => {
    dispatch(setAddedSalesReturnData([])); // Dispatch the action to clear records
    setCardStates({});
  };

  const handleDeleteClick = (
    index: number,
    productHierarchy: string,
    productName: string
  ) => {
    setDeleteIndex(index);
    setproductHierarchy(productHierarchy);
    setproductName(productName);
  };
  const handleDeleteCancel = () => {
    setDeleteIndex(null);
  };
  const handleDeleteConfirm = () => {
    const updatedData = addedSalesReturnData.filter(
      (item: any) => item.productHierarchy !== productHierarchy
    );
    dispatch(setAddedSalesReturnData(updatedData));
    setCardStates((prevState) => {
      const filteredStatesArray = Object.values(prevState).filter(
        (state) => state.productHierarchy !== productHierarchy
      );

      const newCardStates = filteredStatesArray.reduce((acc, state, index) => {
        acc[index] = state;
        return acc;
      }, {} as Record<number, CardState>);

      return newCardStates;
    });
    setDeleteIndex(null);
  };

  useEffect(() => {
    dispatch(mobilePlatformReasonList());
  }, [dispatch]);

  useEffect(() => {
    const total = Object.keys(cardStates).reduce((sum, key) => {
      const index = parseInt(key, 10);
      const item = addedSalesReturnData[index];
      if (item && cardStates[index]) {
        const discrepancyValue = calculateReturnValue(
          cardStates[index]?.reasonDetails || {},
          cardStates[index]?.returnQty || 0
        );
        const value = parseFloat(discrepancyValue.toFixed(2)); // Convert the string back to a number
        return sum + value;
      }
      return sum;
    }, 0);

    setReturnTotal(total); // No need for parseFloat here
  }, [cardStates]);

  const handleReturnQuantity = (
    index: number,
    value: string,
    invQty: number
  ) => {
    if (!value) {
      // Reset the data to default values if value is empty
      setCardStates((prevState) => ({
        ...prevState,
        [index]: {
          selectedSalesmanIds: [],
          reasonDetails: {},
          returnQty: 0,
          productHierarchy: addedSalesReturnData[index].productHierarchy,
          showSelectReason: false,
        },
      }));
      return; // Exit early as no further processing is needed
    }
    const qty = parseInt(value, 10);
    let returnQtyError = "";
    let returnQtyCompareError = "";

    if (!qty || qty <= 0) {
      returnQtyError = "Please enter return Quantity";
    }
    setCardStates((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        showSelectReason: true,
        reasonDetails: {
          productHierarchy: addedSalesReturnData[index].productHierarchy,
        },
        returnQty: qty,
        returnQtyError,
        returnQtyCompareError,
        productHierarchy: addedSalesReturnData[index].productHierarchy,
      },
    }));
  };

  const handleReasonSelect = (index: number, value: string[]) => {
    setCardStates((prevState: any) => {
      const existingCardState = prevState[index] || {
        selectedSalesmanIds: [],
        reasonDetails: {},
        returnQty: 0,
        showSelectReason: false,
      };

      const reasonDetails = existingCardState.reasonDetails || {};
      const returnQtyValue = existingCardState.returnQty;

      const newReasonDetails = value.reduce((acc, id) => {
        const reason = addReasonList.find((r: any) => r.id === id);
        if (reason) {
          acc[id] = {
            type: reason.type || "",
            reasonName: reason.returnReason || "",
            qty: reasonDetails[id]?.qty || "",
            expiryDate: reasonDetails[id]?.expiryDate || null,
            images: reasonDetails[id]?.images || [],
            productHierarchy: addedSalesReturnData[index].productHierarchy,
          };
        }
        return acc;
      }, {} as Record<string, { type: string; reasonName: string; qty: string; expiryDate: Date | null; images: []; productHierarchy: string }>);

      let selectReasonError = "";
      if (value.length === 0) {
        selectReasonError = "Please select a reason";
      }

      return {
        ...prevState,
        [index]: {
          ...existingCardState,
          selectedSalesmanIds: value,
          reasonDetails: newReasonDetails,
          selectReasonError,
        },
      };
    });
  };

  const handleReasonQtyChange = (
    index: number,
    reasonId: string,
    value: string
  ) => {
    const qty = parseInt(value, 10) || 0;

    setCardStates((prevState) => {
      const existingCardState = prevState[index] || {
        selectedSalesmanIds: [],
        reasonDetails: {},
        returnQty: 0,
        showSelectReason: false,
      };

      // Update only the qty for the specific reason, while preserving type and reasonName
      const updatedReasonDetails = {
        ...existingCardState.reasonDetails,
        [reasonId]: {
          ...existingCardState.reasonDetails[reasonId],
          qty: qty.toString(),
          productHierarchy: addedSalesReturnData[index].productHierarchy,
        },
      };

      // Calculate the sum of reason quantities
      const totalReasonQty = Object.values(updatedReasonDetails).reduce(
        (acc, { qty }) => acc + parseInt(qty || "0", 10),
        0
      );

      // Determine if there's an error
      let returnMatchQtyError = "";
      if (totalReasonQty !== existingCardState.returnQty) {
        returnMatchQtyError =
          "Please enter the quantities to match the return quantity.";
      }

      return {
        ...prevState,
        [index]: {
          ...existingCardState,
          reasonDetails: updatedReasonDetails,
          returnMatchQtyError,
        },
      };
    });
  };

  const handleMrpChange = (index: number, reasonId: string, value: string) => {
    // const mrp = parseInt(value);

    setCardStates((prevState) => {
      const existingCardState = prevState[index] || {
        selectedSalesmanIds: [],
        reasonDetails: {},
        returnQty: 0,
        showSelectReason: false,
      };

      // Update only the mrp for the specific reason, while preserving type and reasonName
      const updatedReasonDetails = {
        ...existingCardState.reasonDetails,
        [reasonId]: {
          ...existingCardState.reasonDetails[reasonId],
          mrp: value.toString() || "", // Ensure qty is stored as a string
        },
      };
      let mrpError = "";
      if (!value) {
        mrpError = "Please enter mrp.";
      }

      return {
        ...prevState,
        [index]: {
          ...existingCardState,
          reasonDetails: updatedReasonDetails,
          mrpError,
        },
      };
    });
  };

  const handleExpiryDateChange = (
    index: number,
    reasonId: string,
    date: Date | null
  ) => {
    setCardStates((prevState) => {
      const existingCardState = prevState[index] || {
        selectedSalesmanIds: [],
        reasonDetails: {},
        returnQty: 0,
        showSelectReason: false,
      };

      const updatedReasonDetails = {
        ...existingCardState.reasonDetails,
        [reasonId]: {
          ...existingCardState.reasonDetails[reasonId],
          expiryDate: date, // Store the selected date
        },
      };

      // Validate if expiry date is not selected
      let expiryDateError = "";
      if (!date) {
        expiryDateError = "Please select an expiry date.";
      }

      return {
        ...prevState,
        [index]: {
          ...existingCardState,
          reasonDetails: updatedReasonDetails,
          expiryDateError, // Update error state
        },
      };
    });
  };

  function calculateReturnValue(
    reasonDetails: Record<string, { qty: string; mrp: string }>,
    returnQty: number
  ): number {
    // Check if reasonDetails has more than one item
    if (Object.keys(reasonDetails).length > 1) {
      return Object.values(reasonDetails).reduce((total, detail) => {
        const qty = parseFloat(detail.qty) || 0;
        const mrp = parseFloat(detail.mrp) || 0;
        return total + qty * mrp;
      }, 0);
    } else {
      // If reasonDetails length is 1 or less, use returnQty
      const [key] = Object.keys(reasonDetails);
      const mrp = parseFloat(reasonDetails[key]?.mrp) || 0;
      const qty = returnQty || 0; // Use returnQty when length is 1 or less
      return qty * mrp;
    }
  }

  const validateReasonDetails = () => {
    if (Object.keys(cardStates).length === 0) {
      return false;
    }

    if (addedSalesReturnData?.length !== Object.keys(cardStates).length) {
      return false;
    }

    return Object.values(cardStates).every((cardState) => {
      if (
        cardState.returnQtyError ||
        cardState.selectReasonError ||
        cardState.returnQtyCompareError ||
        cardState.returnMatchQtyError ||
        cardState.expiryDateError ||
        cardState.mrpError
      ) {
        return false;
      }

      if (!cardState.returnQty) {
        return false;
      }

      const reasonDetails = cardState.reasonDetails || {};
      const reasonDetailsValues = Object.values(reasonDetails);
      if (
        typeof reasonDetails !== "object" ||
        Object.keys(reasonDetails).length === 0
      ) {
        return false;
      }
      if (reasonDetailsValues.length === 1) {
        return reasonDetailsValues.every(
          (reasonDetail) =>
            reasonDetail?.type &&
            reasonDetail?.reasonName &&
            reasonDetail?.expiryDate &&
            reasonDetail?.mrp
        );
      }
      let returnData = reasonDetailsValues.every(
        (reasonDetail) =>
          reasonDetail?.type &&
          reasonDetail?.reasonName &&
          reasonDetail?.expiryDate &&
          reasonDetail?.qty &&
          reasonDetail?.mrp
      );
      return returnData;
    });
  };

  const handleRemoveImage = (imageIndex: number, index: number, id: string) => {
    setCardStates((prevState: any) => {
      const existingCardState = prevState[index];
      const currentImages = existingCardState.reasonDetails[id]?.images || [];

      // Remove the image at the specified index
      const updatedImages = currentImages.filter(
        (_: any, i: number) => i !== imageIndex
      );

      // Update the reasonDetails with the new images array
      const updatedReasonDetails = {
        ...existingCardState.reasonDetails[id],
        images: updatedImages,
      };

      let result = {
        ...prevState,
        [index]: {
          ...existingCardState,
          reasonDetails: {
            ...existingCardState.reasonDetails,
            [id]: updatedReasonDetails,
          },
        },
      };

      return result;
    });
  };

  return (
    <Box sx={{ marginTop: "2px", height: "100%" }}>
      <Grid item sx={{ height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            backgroundColor: "#F6F6F6",
          }}
        >
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={100000}
            color="green"
            className="new-coll-alert"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={() => setSnackbarOpen(false)}
          >
            <Alert
              onClose={() => setSnackbarOpen(false)}
              severity={snackbarSeverity}
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <Grid item sx={{ height: tableHeightLoad.current }}>
            <Grid
              container
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                background: "white",
                height: "47px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
              }}
            >
              <Grid item xs={7}>
                <Typography
                  sx={{
                    fontWeight: "700",
                    color: "#000000",
                    fontFamily: "Montserrat",
                    fontSize: "13px",
                  }}
                >
                  <b>
                    Return Product List ({addedSalesReturnData?.length || 0})
                  </b>
                </Typography>
              </Grid>
              <Grid item sm={5} sx={{ display: "flex", justifyContent: "end" }}>
                <img
                  onClick={() => {
                    clearAddedRecords();
                    // onClose();
                  }}
                  src={Close}
                  alt="Close"
                  style={{ width: "20px", height: "20px" }}
                  className="cursor_pointer"
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{ overflowY: "auto", height: "100%", padding: "20px" }}
              className="discrepancy_card"
            >
              {addedSalesReturnData?.length > 0 ? (
                <>
                  {addedSalesReturnData?.map((item: any, index: number) => (
                    <Card
                      key={index}
                      sx={{
                        border: "1px solid #DCDCDE",
                        padding: "15px",
                        borderRadius: "6px",
                        marginBottom: "10px",
                      }}
                    >
                      <Grid container sx={{ alignItems: "center" }}>
                        <Grid item sm={10}>
                          <Grid item>
                            <Typography
                              sx={{
                                color: "#000000",
                                fontSize: "13px",
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                              }}
                            >
                              {item?.productName}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          sm={2}
                          sx={{ display: "flex", justifyContent: "end" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteClick(
                              index,
                              item?.productHierarchy,
                              item?.productName
                            );
                          }}
                        >
                          <img
                            src={remove_line}
                            alt="remove_line"
                            style={{ width: "20px", height: "20px" }}
                            className="cursor_pointer"
                          />
                        </Grid>
                      </Grid>
                      <Grid container mt={2} sx={{ gap: "10px" }}>
                        <Grid item>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            UOM :{item?.uom}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} mt={2}>
                        <Grid item mb={1.5}>
                          <Typography
                            sx={{
                              color: "#44464C",
                              fontSize: "12px",
                              fontFamily: "Montserrat",
                              fontWeight: 500,
                            }}
                          >
                            Enter Return Quantity
                            <span style={{ color: "red" }}>&nbsp;*</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} mt={1}>
                          <InputBase
                            sx={{
                              border: "1px solid #DCDCDE",
                              width: "100%",
                              height: "38px",
                              paddingLeft: "10px",
                              color: "#505259",
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: 500,
                              borderRadius: "4px",
                            }}
                            inputProps={{
                              maxLength: 10,
                            }}
                            onInput={(e) => {
                              const target = e.target as HTMLInputElement;
                              target.value = target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*\.?\d*$/.test(value)) {
                                handleReturnQuantity(index, value, item.invQty);
                              }
                            }}
                            value={cardStates[index]?.returnQty}
                          />
                          {cardStates[index]?.returnQtyError && (
                            <Typography color="error" variant="caption">
                              {cardStates[index].returnQtyError}
                            </Typography>
                          )}
                          {cardStates[index]?.returnQtyCompareError && (
                            <Typography color="error" variant="caption">
                              {cardStates[index].returnQtyCompareError}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      {cardStates[index]?.showSelectReason && (
                        <Grid item>
                          <Grid item mt={2}>
                            <Typography
                              sx={{
                                color: "#44464C",
                                fontFamily: "Montserrat",
                                fontSize: "12px",
                                fontWeight: 500,
                              }}
                            >
                              Select Reason
                              <span style={{ color: "red" }}>&nbsp;*</span>
                            </Typography>
                          </Grid>
                          <Grid item>
                            <FormControl fullWidth sx={{ marginBottom: "2px" }}>
                              <Select
                                className="Quentity_select_box"
                                mode="multiple"
                                showSearch
                                value={
                                  cardStates[index]?.selectedSalesmanIds || []
                                }
                                placeholder="Add Reason"
                                filterOption={(input: any, option: any) => {
                                  const optionText =
                                    option?.children
                                      ?.toString()
                                      .toLowerCase() || "";
                                  return optionText.includes(
                                    input.toLowerCase()
                                  );
                                }}
                                dropdownStyle={{ zIndex: 2000 }}
                                onChange={(value) =>
                                  handleReasonSelect(index, value)
                                }
                                style={{ width: "100%", fontSize: "12px" }}
                              >
                                {addReasonList?.map((reason: any) => (
                                  <Option key={reason.id} value={reason.id}>
                                    <div style={{ fontSize: "11px" }}>
                                      {reason.returnReason}
                                    </div>
                                  </Option>
                                ))}
                              </Select>
                            </FormControl>
                            {cardStates[index]?.selectReasonError && (
                              <Typography color="error" variant="caption">
                                {cardStates[index].selectReasonError}
                              </Typography>
                            )}
                          </Grid>

                          {cardStates[index]?.selectedSalesmanIds?.map((id, i) => {
                            const selectedSalesman = addReasonList?.find((reason :any) => reason.id === id);

                            // Determine if there's more than one selected salesman
                            const isSingleSelected = cardStates[index]?.selectedSalesmanIds?.length === 1;

                            return (
                              <div key={id}>
                                {/* Show Quantity input only if more than one salesman is selected */}
                                {!isSingleSelected && (
                                  <Grid container mt={1}>
                                    <Grid item>
                                      <Typography
                                        sx={{
                                          color: "#44464C",
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        Enter {selectedSalesman?.returnReason} Quantity
                                        <span style={{ color: "red" }}>&nbsp;*</span>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} mt={1}>
                                      <InputBase
                                        value={cardStates[index]?.reasonDetails[id]?.qty || ""}
                                        sx={{
                                          border: "1px solid #DCDCDE",
                                          width: "100%",
                                          height: "38px",
                                          paddingLeft: "10px",
                                          color: "#505259",
                                          fontSize: "10px",
                                          fontFamily: "Montserrat",
                                          fontWeight: 500,
                                          borderRadius: "4px",
                                        }}
                                        inputProps={{
                                          maxLength: 10,
                                        }}
                                        onInput={(e) => {
                                          const target =
                                            e.target as HTMLInputElement;
                                          target.value = target.value.replace(
                                            /[^0-9.]/g,
                                            ""
                                          );
                                        }}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          if (/^\d*\.?\d*$/.test(value)) {
                                            handleReasonQtyChange(index, id, value);
                                          }
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                )}
                                {/* Show MRP input for all reasons */}
                                <Grid container mt={1}>
                                  <Grid item>
                                    <Typography
                                      sx={{
                                        color: "#44464C",
                                        fontFamily: "Montserrat",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      Enter MRP / PC{" "}
                                      {selectedSalesman?.returnReason ? `for ${selectedSalesman?.returnReason}` : ""}
                                      <span style={{ color: "red" }}>&nbsp;*</span>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} mt={1}>
                                    <InputBase
                                      value={cardStates[index]?.reasonDetails[id]?.mrp || ""}
                                      sx={{
                                        border: "1px solid #DCDCDE",
                                        width: "100%",
                                        height: "38px",
                                        paddingLeft: "10px",
                                        color: "#505259",
                                        fontSize: "10px",
                                        fontFamily: "Montserrat",
                                        fontWeight: 500,
                                        borderRadius: "4px",
                                      }}
                                      inputProps={{
                                        maxLength: 10,
                                      }}
                                      onInput={(e) => {
                                        const target =
                                          e.target as HTMLInputElement;
                                        target.value = target.value.replace(
                                          /[^0-9.]/g,
                                          ""
                                        );

                                        // Limit to only one decimal point
                                        if (
                                          (target.value.match(/\./g) || [])
                                            .length > 1
                                        ) {
                                          target.value = target.value.slice(
                                            0,
                                            -1
                                          );
                                        }
                                      }}
                         
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        if (/^\d*\.?\d*$/.test(value)) {
                                          handleMrpChange(index, id, value);
                                        }
                                      }}
                                    />
                                    {cardStates[index]?.mrpError && (
                                      <Typography color="error" variant="caption">
                                        {cardStates[index].mrpError}
                                      </Typography>
                                    )}
                                  </Grid>
                                </Grid>
                                {/* Show Expiry Date input for all reasons */}
                                <Grid container mt={1}>
                                  <Grid item>
                                    <Typography
                                      sx={{
                                        color: "#44464C",
                                        fontFamily: "Montserrat",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      Select Expiry Date{" "}
                                      {selectedSalesman?.returnReason ? `for ${selectedSalesman?.returnReason}` : ""}
                                      <span style={{ color: "red" }}>&nbsp;*</span>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} mt={1}>
                                    <FormControl fullWidth>
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                          sx={{
                                            fontSize: "12px",
                                          }}
                                          value={cardStates[index]?.reasonDetails[id]?.expiryDate || null}
                                          onChange={(date) => handleExpiryDateChange(index, id, date)}
                                        />
                                        {cardStates[index]?.expiryDateError && (
                                          <Typography color="error" variant="caption">
                                            {cardStates[index].expiryDateError}
                                          </Typography>
                                        )}
                                      </LocalizationProvider>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                {/* Show Image upload for all reasons */}
                                <Grid container mt={1}>
                                  <Grid item>
                                    <Typography
                                      sx={{
                                        color: "#44464C",
                                        fontFamily: "Montserrat",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      Upload Image{" "}
                                      {selectedSalesman?.returnReason ? `for ${selectedSalesman?.returnReason}` : ""}
                                      (Optional)
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} mt={1} gap={1} sx={{ display: "flex" }}>
                                    <Grid item xs={2.7}>
                                      <ImageUploadComponent index={index} id={id} setCardStates={setCardStates} />
                                    </Grid>
                                    <Grid item xs={8.3}>
                                      <Grid
                                       item
                                       sx={{
                                         display: "flex",
                                         overflowX: "auto",
                                         whiteSpace: "nowrap",
                                         scrollbarWidth: "thin", // For Firefox
                                         msOverflowStyle: "none", // For Internet Explorer and Edge
                                       }}
                                       className="image_container onwheel_scroll"
                                       onWheel={(e) => {
                                         // Prevents default behavior and allows horizontal scrolling with the mouse wheel
                                         if (e.deltaY !== 0) {
                                           e.preventDefault();
                                           e.currentTarget.scrollLeft += e.deltaY; // Scrolls horizontally based on vertical scroll
                                         }
                                       }}
                                      >
                                        {cardStates[index]?.reasonDetails[id]?.images?.map((thumbnail, imageIndex) => (
                                          <Grid className="image_thumbnail" key={imageIndex} item mr={1} position="relative">
                                            <Grid
                                              sx={{
                                                position: "absolute",
                                                top: "-3px",
                                                right: "-6px",
                                                zIndex: 2,
                                              }}
                                              onClick={() => handleRemoveImage(imageIndex, index, id)}
                                            >
                                              <img src={Close_round_fill} alt="close" className="close-icon" />
                                            </Grid>
                                            <img
                                              src={thumbnail.preview}
                                              alt={`Thumbnail ${imageIndex + 1}`}
                                              className="image_thumbnail_img"
                                              style={{
                                                borderRadius: "4px",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </Grid>
                                        ))}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </div>
                            );
                          })}


                          {cardStates[index]?.returnMatchQtyError && (
                            <Typography color="error" variant="caption">
                              {cardStates[index].returnMatchQtyError}
                            </Typography>
                          )}
                          {cardStates[index]?.returnAPIError && (
                            <Typography color="error" variant="caption">
                              {cardStates[index].returnAPIError}
                            </Typography>
                          )}
                        </Grid>
                      )}
                      <Grid container mt={1}>
                        <Grid item sm={4}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "11px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            Return Quantity :{" "}
                            {cardStates[index]?.returnQty || 0}
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "11px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            {/* Sell Price : ₹ {item.sellRate} */}
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "11px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            Return Value : ₹
                            {calculateReturnValue(
                              cardStates[index]?.reasonDetails || {},
                              cardStates[index]?.returnQty || 0
                            ).toFixed(2)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </>
              ) : (
                <Grid
                  item
                  sx={{
                    overflowY: "auto",
                    height: "100%",
                    padding: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box className="sales_return_dis">
                    <Grid item>
                      <img src={cartImage} width={"130px"} alt="object" />
                    </Grid>
                    <Grid
                      item
                      className="emty_manual"
                      style={{ width: "250px" }}
                    >
                      <Typography
                        style={{ fontSize: "12px", fontWeight: "700" }}
                      >
                        Start Your Return List
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "10px",
                          color: "#73757A",
                          textAlign: "center",
                        }}
                      >
                        Search and select products to begin the return process.
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              sx={{
                position: "sticky",
                bottom: 0,
                height: "60px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
                background: "white",
              }}
            >
              <Grid item sm={7}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "12px",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                  }}
                >
                  Total Return Value : ₹{" "}
                  {returnTotal ? returnTotal.toFixed(2) : "0.00"}
                </Typography>
              </Grid>
              <Grid
                item
                sm={5}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  className="next_btn_inv"
                  type="submit"
                  disabled={!validateReasonDetails() || buttonDisabled}
                  onClick={handleNextClick}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Dialog
        open={deleteIndex !== null}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: "333px", padding: "12px 16px" }}>
          <Box
            className="close_icon_btn cursor_pointer"
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
            onClick={handleDeleteCancel}
          >
            <IoClose className="ClosePop" />
          </Box>
          <DialogContentText
            className="dialog-title-remove"
            id="alert-dialog-description"
            style={{ paddingBottom: "10px" }}
          >
            Are you sure you want to remove?
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "black", fontSize: "12px", marginTop: "4px" }}
          >
            Note:The following{" "}
            <span className="dialog-title-remove" style={{ fontSize: "12px" }}>
              Product Name: {productName}{" "}
            </span>
            will be removed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="crt-btn-cancel cursor_pointer"
            style={{
              height: "30px",
              width: "25%",
              marginRight: "3px",
              fontSize: "12px",
            }}
            onClick={handleDeleteCancel}
          >
            {" "}
            Cancel
          </Button>
          <Button
            variant="contained"
            className="crt-btn-colr cursor_pointer"
            style={{ height: "30px", width: "25%", fontSize: "12px" }}
            type="submit"
            sx={{ mr: 2 }}
            onClick={handleDeleteConfirm}
          >
            {" "}
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      {openConfirm && (
        <WithoutInvoiceConfirmReview
          onClose={() => setOpenConfirm(false)}
        // requestData={requestData}
        />
      )}
    </Box>
  );
}
