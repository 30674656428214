import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  Button,
  Drawer,
  Grid,
  Snackbar,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import debounce from "lodash.debounce";
import type { TableColumnsType } from "antd";
import { Spin, Table, Tooltip } from "antd";
import moment from "moment";
import { ArrowBackIos } from "@mui/icons-material";
import SearchBar from "@mkyy/mui-search-bar";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useFetch";
import {
  getPendingAcceptedGrn,
  getAcceptedInvoiceDetails,
  getProceedAcceptedGrn,
} from "../../../../Redux/XDM/Purchase/GrnReducer";
import { IoClose } from "react-icons/io5";
import { MdRMobiledata } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CheckRingLight from "../../../../assets/images/icons/Check_ring_lightLin.png";

interface RequestData {
  invoiceNo: string;
  orderNo: string;
  invoiceDate: string;
  totNetAmt: number;
  noOfLineItem: string;
}
interface ItemStatusCardProps {
  count: number;
  label: string;
  status: string;
  bgColor: string;
  textColor: string;
}

interface ReviewOrderDetailsProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
  // acceptedData: AcceptedDataType[];
}
interface AcceptedDataType {
  id: number;
  name: string;
  quantity: number;
  price: number;
}
interface ReviewOrderDetails {
  productCode: string;
  productName: string;
  uom: string;
  slno: string;
  mrp: string;
  invoiceQuantity: string;
  invoiceValue: number;
  acceptedQty: string;
  acceptedValue: number;
  status: string;
  discrepancyQty: string;
  discrepancyValue: number;
  reason: string[];
}
interface FooterDetails {
  totalInvoiceValue: number;
  totalInvoiceQty: string;
  totalAcceptedQtyValue: number;
  totalAcceptedQty: string;
  invoiceNo: string;
  orderId: string;
  invoiceDate: number;
  noItems: string;
  totalDiscrepancyQty: string;
  totalDiscrepancyValue: number;
}
type AlignType = 'left' | 'center' | 'right';

export default function PendingReview(props: ReviewOrderDetailsProps) {
  const navigate = useNavigate();
  const { onClose, requestData } = props; // Destructure the props
  const dispatch = useAppDispatch();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [commonLoader, setCommonLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [isShow, setIsShow] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [qty, setQty] = useState(0);
  const [price, setPrice] = useState(0);
  let productListGrnData = useAppSelector((state) => state.pendingGrnData);

  const [dataRecordOrders, setDataRecordOrders] = useState<
    ReviewOrderDetails[]
  >([
    // Add more records as needed
  ]);

  const [dataFooter, setDataFooter] = useState<FooterDetails | null>(null);
  const tableHeightRef = useRef(160);
  const tableHeightloadRef = useRef(180);

  const filterData = {
    page: 0,
    size: 0,
    invNo: requestData.invoiceNo,
  };

  useEffect(() => {
    loadMoreData(true);
    loadFooter();
  }, []);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 353;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const tableHeightLoad = () => {
    const headerHeight = 0;
    const footerHeoght = 300;
    const availableHeight = window.innerHeight - headerHeight - footerHeoght;
    tableHeightloadRef.current = availableHeight;
  };

  const dateObj = moment(requestData.invoiceDate, "YYYY-MM-DD");

  const formattedDate = dateObj.format("DD MMM YYYY");

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    tableHeightLoad();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;

    dispatch(
      getPendingAcceptedGrn({
        params: { ...filterData, page: nextPage },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const newOrders = response.payload || [];
          setDataRecordOrders((prevOrders) =>
            resetPage ? newOrders : [...prevOrders, ...newOrders]
          );
          setPage(nextPage + 1);
          if (newOrders.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        } else {
          // setHasMore(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };
  const loadFooter = () => {
    const abortController = new AbortController();
    const invoiceNo = requestData.invoiceNo;
    dispatch(
      getAcceptedInvoiceDetails({
        params: invoiceNo,
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const newOrders = response.payload || [];
          setDataFooter(newOrders);
        } else {
          setDataFooter(null);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
      });
  };

  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [proceedSuccess, setProceedSuccess] = useState(false);

  const hasPartiallyAccepted = dataRecordOrders.some(
    (record) => record.status === "Partially Accepted" || "Rejected"
  );
  const reviewOrderColumns: TableColumnsType<ReviewOrderDetails> = [
    {
      dataIndex: "productName",
      title: "Product Name",
      // width: "15%",
      ellipsis: true,
      render: (_: any, record: ReviewOrderDetails) => {
        const productName = record?.productName;
        return productName;
      },
    },

    {
      dataIndex: "productCode",
      title: "Product Code",
      // width: "10%",
      ellipsis: true,
    },
    {
      dataIndex: "uom",
      title: "UOM",
      // width: "10%",
      ellipsis: true,
      align: "center",

    },

    {
      dataIndex: "mrp",
      title: "MRP / PC",
      // width: "10%",
      ellipsis: true,
      align: "end",
      render: (text: number, record: ReviewOrderDetails) => {
        return (
          <span title={String(text)}>₹{text ? text.toFixed(2) : "0.00"}</span>
        );
      },
    },
    {
      dataIndex: "invoiceQuantity",
      title: "Invoice Quantity",
      // width: "10%",
      align: "center",
      ellipsis: true,
      render: (text: number, record: ReviewOrderDetails) => {
        return (
          <span title={String(text)}>{text ? text : "0"}</span>
        );
      },
    },
    {
      dataIndex: "invoiceValue",
      title: "Invoice Value",
      // width: "10%",
      ellipsis: true,
      align: "end",

      render: (_: any, record: ReviewOrderDetails) => {
        return (
          <span>
            ₹
            {record?.invoiceValue ? record?.invoiceValue.toFixed(2) : "0.00"}
          </span>
        );
      },
    },
    {
      dataIndex: "acceptedQty",
      title: "Accepted Qty.",
      // width: "10%",
      ellipsis: true,
      align: "center",

    },
    {
      dataIndex: "acceptedValue",
      title: "Accepted Value",
      // width: "15%",
      ellipsis: true,
      align: "end",

      render: (_: any, record: ReviewOrderDetails) => {
        return (
          <span>
            ₹
            {record?.acceptedValue ? record?.acceptedValue.toFixed(2) : "0"}
          </span>
        );
      },
    },
    ...(hasPartiallyAccepted
      ? [
        {
          dataIndex: "discrepancyQty",
          title: "Discrepancy Qty.",
          align: "center" as AlignType,
          render: (text: number) => (
            <span>{text ? text.toFixed(2) : "-"}</span>
          ),
        },
        {
          dataIndex: "discrepancyValue",
          title: "Discrepancy Value",
          align: "right" as AlignType,
          render: (text: number) => (
            <span>{text ? `₹${text.toFixed(2)}` : "-"}</span>
          ),
        },
        {
          dataIndex: "reason",
          title: "Reason",
          ellipsis: true,
          align: "center" as AlignType,
          render: (reasons: string[] | undefined) => (
            <span>
              {reasons && reasons.length > 0 ? reasons.join(", ") : "-"}
            </span>
          ),
        },
      ]
      : []),
    {
      dataIndex: "status",
      title: "Status",
      align: "center" as AlignType,
      width: "15%",
      render: (_: any, record: ReviewOrderDetails, index: number) => {
        if (record?.status === "Accepted") {
          return <Button className="Accepted_button_pen">Accepted</Button>;
        } else if (record?.status === "Partially Accepted") {
          return (
            <Button className="PartialAccepted_button_pen">
              Partially Accepted
            </Button>
          );
        } else {
          return <Button className="Rejected_button_pen">Rejected</Button>;
        }
      },
    },
  ];

  const ItemStatusCard: React.FC<ItemStatusCardProps> = ({
    count,
    label,
    status,
    bgColor,
    textColor,
  }) => {
    return (
      <Card sx={{ backgroundColor: bgColor, borderRadius: 2, boxShadow: "none", height: "60px" }}>
        <CardContent style={{ padding: "15px" }}>
          <Typography
            variant="h6"
            sx={{ color: textColor, fontWeight: "bold" }}
          >
            {count} {count > 1 ? "Items" : "Item"}
          </Typography>
          <Typography variant="body2" sx={{ color: textColor, fontSize: "10px" }}>
            {status}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const filteredDataToShow = dataRecordOrders.filter((item) =>
    item.productCode?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.productName?.toLowerCase().includes(searchTerm.toLowerCase()) 
  );

  const dataToShow: ReviewOrderDetails[] =
    filteredDataToShow.length > 0
      ? hasMore
        ? filteredDataToShow
        : [...filteredDataToShow]
      : [];
  const [acceptedCount, setAcceptedCount] = useState(0);
  const [partiallyAcceptedCount, setPartiallyAcceptedCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);
  useEffect(() => {
    setAcceptedCount(
      dataRecordOrders.filter((item) => item.status === "Accepted").length
    );
    setPartiallyAcceptedCount(
      dataRecordOrders.filter((item) => item.status === "Partially Accepted")
        .length
    );
    setRejectedCount(
      dataRecordOrders.filter((item) => item.status === "Rejected").length
    );
  }, [dataRecordOrders]);
  const handleCancelAccept = (record: any) => {
    onClose();
    setConfirmModalShow(false);
  };
  const handleAcceptConfirm = (record: any) => {
    setConfirmModalShow(true);
  };
  const handleCancel = () => {
    setConfirmModalShow(false);
  };
  const handleConfirm = () => {
    setLoadingModal(true);
    const abortController = new AbortController();
    const invoiceNo = requestData.invoiceNo;
    dispatch(
      getProceedAcceptedGrn({
        params: invoiceNo,
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          setQty(response.payload.totalAcceptedQty);
          setPrice(response.payload.totalAcceptedQtyValue);
          setTimeout(() => {
            setLoadingModal(false);
            setProceedSuccess(true);
          }, 2000);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
      });
  };
  const handleBackGRN = () => {
    window.location.reload();
  };
   const handleSearch = () => {
    setSearchTerm(searchTerm);
  };
  const itemCount = [acceptedCount, partiallyAcceptedCount, rejectedCount].filter(
    (count) => count > 0
  ).length;
  const gridSize = itemCount === 2 ? 6 : itemCount === 1 ? 12 : 4;
  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer anchor="right" open={true} className="drawer_outlet w-100px">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            // mt: 2,
          }}
        >
          <Grid item sx={{ width: "100%" }}>
            <Grid
              container
              sx={{ borderBottom: "1px solid #0000000D", padding: "12px 24px" }}
            >
              <Grid
                item
                sm={0.3}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ArrowBackIos
                  sx={{
                    fontSize: "10px",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    onClose();
                  }}
                />
              </Grid>
              <Grid item xs={6.7} sm={7.7} md={8.7} lg={8.7}>
                <Grid item>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#000000",
                      fontFamily: "Montserrat",
                      fontSize: "13px",
                    }}
                  >
                    Pending GRN
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      color: "#6ec358fa",
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    Delivered
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={5}
                sm={4}
                md={3}
                lg={3}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <SearchBar
                  className="search_box_pending w-100px"
                  placeholder="Search"
                    value={searchTerm}
                    onChange={(newValue) => {
                      setSearchTerm(newValue);
                    }}
                    onCancelResearch={() => setSearchTerm("")}
                    onSearch={(newValue) => {
                      handleSearch();
                    }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form saleable-products-box"
          >
            {" "}
            <Grid
              item
              sx={{
                width: "100%",
                margin: "auto",
                marginBottom: "90px",
                padding: "0px 24px",
              }}
            >
              <Grid container pl={3.6} mt={2}>
                <Grid item sm={2}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Invoice No
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {requestData?.invoiceNo}
                    </Typography>
                  </Grid>
                </Grid>
                {/* <Grid item sm={2}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Order ID
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {requestData?.orderNo}
                    </Typography>
                  </Grid>
                </Grid> */}
                <Grid item sm={2}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Invoice Date
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {formattedDate}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={2}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      No of Line item
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {requestData?.noOfLineItem}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={2}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Value
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
                      {requestData?.totNetAmt
                        ? requestData?.totNetAmt.toFixed(2)
                        : "0"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="pad-top-0px"
                  sx={{ height: tableHeightloadRef.current }}
                >
                  <div className="saleable-table-scroll">
                    <Table
                      className={`Header-table saleable-table sale_last_hide scroll_cus_table  ${productListGrnData.ProductDetailsGrnAcceptedLoading
                        ? " nodataloader"
                        : ""
                        }`}
                      columns={reviewOrderColumns}
                      dataSource={dataToShow}
                      pagination={false}
                      scroll={{ y: tableHeightRef.current }}
                      loading={
                        !page &&
                        productListGrnData.ProductDetailsGrnAcceptedLoading
                      }
                      showSorterTooltip={false}
                      summary={() => {
                        // return (
                        //   <>
                        //     {page !== 0 && productListGrnData.ProductDetailsGrnAcceptedLoading && (
                        //       <Table.Summary.Row>
                        //         <Table.Summary.Cell
                        //           index={1}
                        //           colSpan={7}
                        //           align="center"
                        //         >
                        //           <Spin />
                        //         </Table.Summary.Cell>
                        //       </Table.Summary.Row>
                        //     )}
                        //   </>
                        // );

                        if (!hasMore && dataRecordOrders.length > 0) {
                          return (
                            <Table.Summary.Row>
                              <Table.Summary.Cell
                                index={1}
                                colSpan={hasPartiallyAccepted ? 12 :9}
                                align="center"
                                className="noMore"
                              >
                                No more data found
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        } else if (
                          page !== 0 &&
                          productListGrnData.ProductDetailsGrnAcceptedLoading
                        ) {
                          return (
                            <Table.Summary.Row>
                              <Table.Summary.Cell
                                index={1}
                               colSpan={hasPartiallyAccepted ? 12 :9}
                                align="center"
                              >
                                <Spin />
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        }
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <hr className="hr-revieworder"></hr>
              <Grid container>
                <Grid
                  item
                  mt={1.5}
                  sx={{ display: "flex", justifyContent: "start" }}
                  sm={7}
                >
                  <Grid item sm={4}>
                    <Grid item>
                      <Typography
                        className="Pending_review_label"
                        style={{ fontSize: "12px" }}
                      >
                        Total Invoice quantity: {dataFooter?.totalInvoiceQty}
                      </Typography>
                    </Grid>
                    <Grid item mt={0.7}>
                      <Typography
                        className="Pending_review_Sublabel"
                        sx={{ fontWeight: 1000 }}
                        style={{ fontSize: "12px" }}
                      >
                        Total Invoice value:₹{" "}
                        {dataFooter?.totalInvoiceValue.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item sm={4}>
                    <Grid item>
                      <Typography
                        className="Pending_review_label"
                        style={{ fontSize: "12px" }}
                      >
                        Total Accepted quantity :{dataFooter?.totalAcceptedQty}
                      </Typography>
                    </Grid>
                    <Grid item mt={0.7}>
                      <Typography
                        className="Pending_review_Sublabel"
                        sx={{ fontWeight: 1000 }}
                        style={{ fontSize: "12px" }}
                      >
                        Total Accepted value :₹{" "}
                        {dataFooter?.totalAcceptedQtyValue.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                  {hasPartiallyAccepted && (
                    <Grid item sm={4}>
                      <Grid item>
                        <Typography
                          className="Pending_review_label"
                          style={{ fontSize: "12px" }}
                        >
                          Total Discrepancy Quantity: {dataFooter?.totalDiscrepancyQty}
                        </Typography>
                      </Grid>
                      <Grid item mt={0.7}>
                        <Typography
                          className="Pending_review_Sublabel"
                          sx={{ fontWeight: 1000 }}
                          style={{ fontSize: "12px" }}
                        >
                          Total Discrepancy Value: ₹ {dataFooter?.totalDiscrepancyValue.toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item sm={4}></Grid>
                <Grid
                  item
                  mt={1.5}
                  sx={{ display: "flex", justifyContent: "end" }}
                  sm={1}
                >
                  <Button
                    className="Cancle_btn_des"
                    sx={{
                      height: "34px",
                      width: { sm: "15%", md: "13%", lg: "10%" },
                    }}
                    onClick={handleCancelAccept}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="Save_btn_des"
                    sx={{
                      height: "34px",
                      width: { sm: "15%", md: "11%", lg: "9%" },
                      mr: 2,
                    }}
                    onClick={handleAcceptConfirm}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Dialog
          open={confirmModalShow}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {loadingModal ? (
            <DialogContent
              sx={{
                width: "350px",
                padding: "12px 16px",
                height: "230px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <div className="loader"></div>
                <Typography
                  sx={{
                    marginTop: "20px",
                    color: "#344054",
                    fontSize: "16px",
                    fontWeight: 500,
                    fontFamily: "var(--Fontfamilyfont-family-body)",
                    lineHeight: "var(--Lineheighttext-lg)",
                  }}
                >
                  Loading...
                </Typography>
              </Box>
            </DialogContent>
          ) : (
            <>
              {proceedSuccess ? (
                <DialogContent
                  sx={{
                    width: "350px",
                    height: "108px",
                    padding: "12px 16px",
                  }}
                >
                  <Box
                    className="close_icon_btn cursor_pointer"
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                    onClick={proceedSuccess ? handleBackGRN : handleCancel}
                  >
                    <IoClose className="ClosePop" />
                  </Box>
                  <DialogContentText
                    className="dialog-title-remove"
                    id="alert-dialog-description"
                  >
                    <Grid container alignItems="center">
                      <Grid item xs={1}>
                        <img
                          src={CheckRingLight}
                          alt="Check Ring Light"
                          style={{
                            position: "relative",
                            width: "37.5px",
                            height: "37.5px",
                            top: "6.25px",
                            left: "6.25px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 900,
                          lineHeight: "30px",
                          textAlign: "left",

                          ml: 3,
                        }}
                      >
                        {acceptedCount + partiallyAcceptedCount} items of value
                        ₹ {price.toFixed(2)} updated successfully in the
                        inventory.
                      </Grid>
                    </Grid>
                  </DialogContentText>
                </DialogContent>
              ) : (
                <DialogContent sx={{ width: "450px", padding: "12px 16px" }}>
                  <Box
                    className="close_icon_btn cursor_pointer"
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                    onClick={handleCancel}
                  >
                    <IoClose className="ClosePop" />
                  </Box>
                  <DialogContentText
                    className="dialog-title-remove"
                    id="alert-dialog-description"
                    sx={{ paddingBottom: "10px" }}
                  >
                    Are you sure you want to update the inventory and onboard
                    the invoice?
                  </DialogContentText>
                  <Grid container spacing={1}>
                    {acceptedCount > 0 ? (
                      <Grid
                        item
                        sm={gridSize}
                        lg={gridSize}
                        xl={gridSize}
                        md={gridSize}
                      >
                        <ItemStatusCard
                          count={acceptedCount}
                          label="Items"
                          status="Accepted"
                          bgColor="#E6F4EA"
                          textColor="#388E3C"
                        />
                      </Grid>
                    ) : (
                      ""
                    )}
                    {partiallyAcceptedCount > 0 ? (
                      <Grid
                        item
                        sm={gridSize}
                        lg={gridSize}
                        xl={gridSize}
                        md={gridSize}
                      >
                        <ItemStatusCard
                          count={partiallyAcceptedCount}
                          label="Item"
                          status="Partially Accepted"
                          bgColor="#FFF3E0"
                          textColor="#F57C00"
                        />
                      </Grid>
                    ) : (
                      ""
                    )}
                    {rejectedCount > 0 ? (
                      <Grid
                        item
                        sm={gridSize}
                        lg={gridSize}
                        xl={gridSize}
                        md={gridSize}
                      >
                        <ItemStatusCard
                          count={rejectedCount}
                          label="Item"
                          status="Rejected"
                          bgColor="#FFEBEE"
                          textColor="#D32F2F"
                        />
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </DialogContent>
              )}

              <DialogActions>
                {proceedSuccess ? (
                  <Grid container>
                    <Grid item xs={12} sx={{ mr: 3, ml: 3 }}>
                      <Button
                        variant="contained"
                        className="crt-btn-grn"
                        fullWidth
                        style={{
                          height: "35px",
                          fontSize: "14px",
                        }}
                        onClick={handleBackGRN}
                      >
                        Back to GRN
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      className="crt-btn-cancel"
                      style={{
                        height: "30px",
                        width: "23%",
                        marginRight: "5px",
                        fontSize: "12px",
                      }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      className="crt-btn-colr"
                      style={{
                        height: "30px",
                        width: "23%",
                        fontSize: "12px",
                        marginRight: "5px",
                      }}
                      onClick={handleConfirm}
                    >
                      Proceed
                    </Button>
                  </>
                )}
              </DialogActions>
            </>
          )}
        </Dialog>
      </Drawer>
    </Box>
  );
}
