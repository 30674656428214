import PropTypes from "prop-types";
// material-ui
import { useTheme } from "@mui/material/styles";
import { AppBar, useMediaQuery } from "@mui/material";
// project import
import AppBarStyled from "./AppBarStyled";
import HeaderContent from "./HeaderContent";
// ==============================|| MAIN LAYOUT - HEADER ||============================== //

interface HeaderProps {
  open?: boolean;
  handleDrawerToggle?: () => void;
}

const Header: React.FC<HeaderProps> = ({
  open = false,
  handleDrawerToggle,
}) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

  // common header
  const mainHeader = (
    <>
      <HeaderContent />
    </>
  );

  // app-bar params
  const appBar = {
    position: "fixed",
    color: "inherit",
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      // boxShadow: theme.customShadows.z1
    },
  };

  return (
    <>
      {!matchDownMD ? (
        <>
          <AppBarStyled open={open} className="no-padding purchase_Grn">
            {mainHeader}
          </AppBarStyled>
        </>
      ) : (
        <AppBar
      
          className="AppBar_content"
          style={{
            background: " linear-gradient(90deg, #614FB3 0%, #944986 100%)",
          }}
        >
          {mainHeader}
        </AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

export default Header;
