import * as React from 'react';
import Box from '@mui/material/Box';
import {
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import type { TableColumnsType } from 'antd';
import SearchBar from '@mkyy/mui-search-bar';

import VisibilityIcon from '@mui/icons-material/Visibility';
import GeoHierarchyViewForm from './GeoHighViewForm';
import { useNavigate } from 'react-router-dom';
import CommonTable from '../../../components/Tables/CommonTable';

interface DataType {
  key: React.Key;
  code: number;
  name: string;
  action: string;
}
export default function GeographyHierarchyLevel() {
  const navigator = useNavigate()

  const [isViewing, setIsViewing] = React.useState(false);
  const [textFieldValue, setTextFieldValue] = React.useState('');
 
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
   // search function logic 
  };
 
 

  const columns: TableColumnsType<DataType> = [
    { dataIndex: 'code', title: 'Code' },
    { 
     dataIndex: 'name',
     title: 'Name' ,
     
     sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      dataIndex: 'action',
      title: 'Action',
      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon onClick={(event) => handleViewClick(event)} style={{ cursor: 'pointer', fontSize: '1rem', color: '#928282de' }} />
        </>
      )
    },
  ];
  const data: DataType[] = [
    { key: 1, code: 100, name: 'Country', action: '' },
    { key: 2, code: 200, name: 'State', action: '' },
    { key: 3, code: 300, name: 'Town', action: '' },
    { key: 4, code: 400, name: 'District', action: '' },
    { key: 5, code: 500, name: 'Country1', action: '' },
    { key: 6, code: 600, name: 'Supplier6', action: '' },
    { key: 7, code: 700, name: 'Supplier7', action: '' },
    { key: 8, code: 800, name: 'Supplier8', action: '' },
  ];



  const handleViewClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setIsViewing(!isViewing);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
};
  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className=" mar-tb">
          <Typography>
            <b className="header-lable">Geography Hierarchy Level </b>
          </Typography>
        </Grid>

        {isViewing ? (
          <GeoHierarchyViewForm onBack={(event) => handleViewClick(event)} />
        ) : (
          <Box>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "end", mb: 2 }}
            >
              <SearchBar
                className="search-table"
                value={textFieldValue}
                onChange={(newValue) => {
                  setTextFieldValue(newValue);
                  handleSearch();
                }}
              />
            </Grid>
            <CommonTable
              //  rowSelection={rowSelection}
              data={data}
              columns={columns}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
