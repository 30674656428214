import React from "react";

import { Box } from "@mui/material";

const Sales: React.FC = () => {

  return <Box>
    <p>Sales Company</p>
  </Box>;
};
export default Sales;
