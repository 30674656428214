import { useState, startTransition } from 'react';
// material-ui
import {
    CardHeader,
    Card,
    CardContent,
    CardActions,
    Avatar,
    AvatarGroup,
    Box,
    Grid,
    Typography,
 
 
} from '@mui/material';

import { useNavigate } from 'react-router-dom'
import { Business } from '@material-ui/icons';
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { FaRegBuilding } from 'react-icons/fa';
 
 
 
interface CardData {
    [key: string]: string | JSX.Element|undefined;
 
}
 
const CompanyMainCard: React.FC = () => {
    const cardData: CardData[] = [
        { company: "Company",Icons: <FaRegBuilding /> },
        { company: "Supplier",Icons: <AiOutlineUsergroupAdd /> },
 
    ];
 
    const navigator = useNavigate();
    const handleCardClick = (index: number) => {
            const paths = ["/Company", "/Supplier"]; // Paths for each index
            navigator(paths[index]);
    }
   
 
    return (
        <>
            <Grid container >
                <Grid item xs={12} >
                    <Typography style={{ fontSize: "14px", fontWeight: "800" }}>Company</Typography>
                </Grid>
                <Grid >
                    <Typography className="cursor_pointer" style={{ color: "#b6b0b0" }}><span onClick={() => navigator("/Dashboard")}>Dashboard
                    </span>/<span >Company</span></Typography>
                </Grid>
            </Grid>
            <Box
                sx={{
                    width: '70%',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
                    gap: 2,
                    marginTop: 2,
                }}
            >
                <Card  >
                    <CardHeader
                        avatar={
                            <Avatar aria-label="recipe" style={{
                                background: " linear-gradient(90deg, rgb(97, 79, 179) 0%, rgb(148, 73, 134) 100%)"
                           }}>
                                M
                            </Avatar>
                        }
                        title="Modules"
                        subheader="Company"
                       
                    />
                    <CardContent>
                    <Grid container spacing={2}>
                            {cardData.map((item, index) => (
                                <>
                                    <Grid item xs={12}>
                                        <Grid container >
                                            <Grid item xs={1} style={{
                                            fontSize:
                                                "1rem", color: "#614fb3"
                                        }}>
                                               {item.Icons}
                                            </Grid>
                                            <Grid item xs={4} onClick={() => handleCardClick(index)}>
                                                <Typography className="hover-card">
                                                    {item.company}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            ))}
                            </Grid>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
}
export default CompanyMainCard