import React, { useRef } from 'react';
import { Button, Grid, TextField, Card, Box } from '@mui/material';
import * as XLSX from 'xlsx';
import { Typography } from '@material-ui/core';

function convertHeadersToXLS(headers: any) {
  const worksheet = XLSX.utils.aoa_to_sheet([headers]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  return blob;
}

// Function to download XLS file with headers only
function downloadXLSWithHeaders(headers: any) {
  const xlsBlob = convertHeadersToXLS(headers);
  const url = URL.createObjectURL(xlsBlob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'exported_headers.xlsx');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

interface InventoryFormProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const InventoryExcel: React.FC<InventoryFormProps> = ({ onBack }) => {

  const [fileName, setFileName] = React.useState();
  const [hideCard, setHideCard] = React.useState(true);



  // Your data
  const headers = ['ID', 'Commodity', 'Price', 'Quantity'];
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      // Do something with the selected file
    }
  };
  const hideandshow = () => {
    setHideCard(!hideCard);
  }

  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };



  return (
    <>
      <Card className='card-form' sx={{ border: "1px solid #EEE" }}>
        <Grid item className='pad-10'>
          <Grid className='header-lable' >Export</Grid>
          <span className='font-style-fs'>1. <a className='link-import' onClick={() => downloadXLSWithHeaders(headers)}>Click here</a> to download the sample format.</span>
          <input
            ref={fileInputRef}
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <Typography className='font-style-fs'>2. Copy data values from your existing file.</Typography>
          <Typography className='font-style-fs'>3. Paste the values in the approriate coloums in the downloaded excel file</Typography>
          <Typography className='font-style-fs'>4. Save excel file in your desktop.</Typography>
          <Typography className='font-style-fs'>5. Export the saved file to complete the upload process.</Typography>
        </Grid>
        <Grid item className='pad-10'>
          <Grid className='header-lable' >Import</Grid>
          <Grid item xs={5}>
            <span className='font-style-fs'>1. <a className='link-import'  onClick={handleButtonClick}>Click here</a> to browse and Upload the .xls file.</span>
          </Grid>
        </Grid>
        <Grid item className='pad-10'>
          <Grid className='header-lable' >Note</Grid>
          <Grid item xs={5}>
          <Typography className='font-style-fs'> <span>--&gt;</span> All columns are mandatory and no special characters are allowed.</Typography>
          <Typography className='font-style-fs'> <span>--&gt;</span> In case of upload failure please refer to the error messages inside excel sheet and re upload with the errors rectified.</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2} display="flex" justifyContent="center">
          <Button variant="contained" className='btn-colr' onClick={handleBackClick}>
            Back
          </Button>
        </Grid>
      </Card>

    </>


  );
}
export default InventoryExcel

