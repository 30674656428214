import * as React from "react";
import Box from "@mui/material/Box";
import { Grid, Paper, Typography } from "@mui/material";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  code: number;
  name: string;
  action: string;
}

export default function ProductHierarchyLevel() {
  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // search function logic here
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "code",
      title: "Level Code",
    },
    {
      dataIndex: "name",
      title: "Level Name",
      sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      dataIndex: "action",
      title: "Action",
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      code: 55,
      name: "Supplier1",
      action: "",
    },
    {
      key: 1,
      code: 55445,
      name: "Supplier2",

      action: "",
    },
    {
      key: 3,
      code: 5565,
      name: "Supplier3",

      action: "",
    },
    {
      key: 4,
      code: 5548,
      name: "Supplier4",
      action: "",
    },
    {
      key: 5,
      code: 55121,
      name: "Supplier5",
      action: "",
    },
    {
      key: 6,
      code: 5565,
      name: "Supplier6",
      action: "",
    },
    {
      key: 7,
      code: 55789,
      name: "Supplier7",
      action: "",
    },
    {
      key: 8,
      code: 55656,
      name: "Supplier8",
      action: "",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className="pad-10 mar-tb">
          <Typography>
            <b>Product Hierarchy Level</b>
          </Typography>
        </Grid>

        <Box>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "end", mb: 2 }}
          >
            <SearchBar
              className="search-table"
              value={textFieldValue}
              onChange={(newValue) => {
                setTextFieldValue(newValue);
                handleSearch();
              }}
            />
          </Grid>
          <CommonTable
            // rowSelection={rowSelection}
            data={data}
            columns={columns}
          />
        </Box>
      </Box>
    </>
  );
}
