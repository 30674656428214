import React from "react";
import {
  Button,
  Grid,
  TextField,
  Box,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  InputLabel,
  Typography,
} from "@mui/material";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DistributorSales from "../DistributorSales";

    interface DistributorTabProps {
        onBack: (event: React.MouseEvent<unknown>) => void;
      }
      
      const EditContact: React.FC<DistributorTabProps> = ({onBack}) => {
     
      
        const handleBackClick = (event: React.MouseEvent<unknown>) => {
          onBack(event); // Pass the event argument to onBack function
        };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        //   width: 250,
      },
    },
  };
  const handleChanges = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const Country = ["India", "China"];
  const State = ["Tamil Nadu", "Delhi"];
  const City = ["Select City", ""];
  const Option = ["DMS", ""];

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };
  return (
    <>
       {show ? (
  <DistributorSales onBack={handleclick}/>
      ) : (
    <Box>
      <form noValidate>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 10 }}>
          <Grid item xs={12} sm={6}>
            <Typography>Distributor Type</Typography>
            <TextField placeholder="Distributor Type" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel>Distributor Code</InputLabel>
            <TextField placeholder="Distributor Code" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel size="normal">Branch Name</InputLabel>
            <TextField placeholder="Branch Name " fullWidth />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputLabel size="normal">Address</InputLabel>
            <Grid className="dis-address">
              <TextField placeholder="Address Line 1" fullWidth />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid className="dis-address">
              <TextField placeholder="Address Line 2" fullWidth />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid className="dis-address">
              <TextField placeholder="Address Line 3" fullWidth />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography>Country</Typography>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label">
                {Country.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>State</Typography>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label">
                {State.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>City</Typography>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label">
                {City.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Postal Code</Typography>
            <TextField placeholder="Postal Code" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Phone Number</Typography>
            <TextField placeholder="Phone Number" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Contact Person</Typography>
            <TextField placeholder="Contact Person" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Email Id</Typography>
            <TextField placeholder="Email Id" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>FSSAI No</Typography>
            <TextField placeholder="FSSAI No" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Drug Licence No</Typography>
            <TextField placeholder="Drug Licence No" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Tin No</Typography>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item container sx={{ marginTop: "16px" }}>
            <Grid item sx={{ display: "flex" }}>
              <Typography mt={1}>Weekly off : </Typography>
              <Grid item sx={{ mr: 2 }}>
                <Checkbox {...label} defaultChecked className="check-box" />
                Monday
              </Grid>
              <Grid item sx={{ mr: 2 }}>
                <Checkbox {...label} className="check-box" />
                Tuesday
              </Grid>
              <Grid item sx={{ mr: 2 }}>
                <Checkbox {...label} className="check-box" />
                Wednesday
              </Grid>
              <Grid item sx={{ mr: 2 }}>
                <Checkbox {...label} className="check-box" />
                Thursday
              </Grid>
              <Grid item sx={{ mr: 2 }}>
                <Checkbox {...label} className="check-box" />
                Friday
              </Grid>
              <Grid item sx={{ mr: 2 }}>
                <Checkbox {...label} className="check-box" />
                Saturday
              </Grid>
              <Grid item sx={{ mr: 2 }}>
                <Checkbox {...label} className="check-box" />
                Sunday
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Change Code</Typography>
            <TextField placeholder="Change Code" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Category Type</Typography>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label">
                {Option.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Null Of Sales Mans</Typography>
            <TextField placeholder="" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Salary Budget</Typography>
            <TextField placeholder="" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Latitude</Typography>
            <TextField placeholder="Latitude" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Longitude</Typography>
            <TextField placeholder="Longitude" fullWidth />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", mt: 4 }}
          >
            <Grid item sx={{ mr: 2 }}>
              <Button variant="outlined" onClick={handleBackClick}>
                <CancelIcon
                  sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                />{" "}
                Cancel
              </Button>
            </Grid>
            <Grid>
              <Button className="btn-colr " variant="contained" onClick={handleclick}>
                <RedoOutlinedIcon
                  sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                />
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
      )}
    </>
  );
};
export default EditContact;
