import {
  HSN_CODE_SUBMIT_FORM,
  HSN_CODE_Submit_ERROR,
  HSN_CODE_FETCH_DATA,
  HSN_CODE_EDIT_DATA,
  HSN_CODE_UPDATE_DATA,
  HSN_CODE_UPDATE_ERROR,
} from "../../../actionType";
import { Dispatch } from "redux";
import createAxiosInstance from "../../../../hooks/useFetch";
import { HSNcodeFormValues } from "../../../../typescript/product";
const axiosInstance = createAxiosInstance();

export interface HSNcodeSubmitAction {
  type: typeof HSN_CODE_SUBMIT_FORM;
  payload: any;
}

export interface HSNcodeSubmitErrorAction {
  type: typeof HSN_CODE_Submit_ERROR;
  payload: string;
}
export interface HSNcodeGetAction {
  type: typeof HSN_CODE_FETCH_DATA;
  payload: any;
}
// export interface HSNcodeActiveStatus{
//   type: typeof HSNcode_ACTIVE_STATUS;
//   payload: any;
// }
export interface HSNcodeEdit {
  type: typeof HSN_CODE_EDIT_DATA;
  payload: any;
}
export interface HSNcodeUpdate {
  type: typeof HSN_CODE_UPDATE_DATA;
  payload: any;
}
export interface HSNcodeUpdateErrorAction {
  type: typeof HSN_CODE_UPDATE_ERROR;
  payload: string;
}

export const HSNcodeSubmitForm =
  (formData: HSNcodeFormValues) =>
  async (
    dispatch: Dispatch<HSNcodeSubmitAction | HSNcodeSubmitErrorAction>
  ) => {
    try {
      const response = await axiosInstance.post(
        `product-hsnCode/create`,
        formData
      );
      dispatch({
        type: HSN_CODE_SUBMIT_FORM,
        payload: response.data, // Assuming response contains data
      });
      return response.data;
    } catch (error: any) {
      dispatch({
        type: HSN_CODE_Submit_ERROR,
        payload: error.response.data.data,
      });
      throw error;
    }
  };

export const HSNcodeFetchData = (requestData: {
  page: number;
  size: number;
  hsnType: string;
}) => {
  return (dispatch: Dispatch<HSNcodeGetAction>) => {
    axiosInstance
      .get(`product-hsnCode/get`, {
        params: requestData, // Pass requestData as query parameters
      })
      .then((res) => {
        dispatch({
          type: HSN_CODE_FETCH_DATA, // Change this to COMPANY_FETCH_DATA
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};

// export const HSNcodeActiveStatus = ({ id, value }: { id: string; value: boolean }) => {
//   return (dispatch: Dispatch<HSNcodeActiveStatus>) => {
//     axiosInstance.put(`HSNcode/active-in-active`,
//     { id, value }  // Pass requestData as query parameters
//     )
//       .then((res) => {
//         dispatch({
//           type: HSNcode_ACTIVE_STATUS, // Change this to COMPANY_FETCH_DATA
//           payload: res.data.data,
//         });
//       })
//       .catch((error) => {
//       });
//   };
// };

export const HSNcodeEdit = (id: string) => {
  return (dispatch: Dispatch<HSNcodeEdit>) => {
    axiosInstance
      .get(`product-hsnCode/get-byid//${id}`)
      .then((res) => {
        dispatch({
          type: HSN_CODE_EDIT_DATA, // Dispatch the type of action
          payload: res.data.data, // Pass any data received from the API response
        });
      })
      .catch((error) => {
        // Handle errors if any
      });
  };
};

export const HSNcodeUpdate =
  ({ id, formData }: { id: string; formData: HSNcodeFormValues }) =>
  async (dispatch: Dispatch<HSNcodeUpdate | HSNcodeUpdateErrorAction>) => {
    try {
      const response = await axiosInstance.put(
        `product-hsnCode/update/${id}`,
        formData
      );
      dispatch({
        type: HSN_CODE_UPDATE_DATA,
        payload: response.data, // Assuming response contains data
      });
      return response.data;
    } catch (error: any) {
      dispatch({
        type: HSN_CODE_UPDATE_ERROR,
        payload: error.response.data.data,
      });
      throw error;
    }
  };
