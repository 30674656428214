import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 200, // Adjust as needed
    height: 110, // Adjust as needed
    backgroundColor: "transparent", // Set background color to transparent
    margin: "5px",
    border: "1px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "none",

    "&:hover": {
      backgroundColor: "rgba(148, 73, 134, 0.1)",
      boxShadow: "0 0px 3px rgba(0, 0, 0, 0.3)", // Add box shadow
      transition: "box-shadow 0.3s ease", // Change background color on hover
      cursor: "pointer",
      "& $subTitle": {
        color: "#944986", // Change subTitle color on hover
      },
    },
  },
  hoverCard: {
    backgroundColor: "rgba(148, 73, 134, 0.1)",
    boxShadow: "0 0px 3px rgba(0, 0, 0, 0.3)", // Add box shadow
    transition: "box-shadow 0.3s ease", // Change background color on hover
  },
  title: {
    color: "#944986", // Default title color
  },
  subTitle: {
    "&:hover": {
      color: "rgba(148, 73, 134, 1.1)", // Change color on hover
    }, // Default title color
  },
  hiddenCard: {
    visibility: "hidden",
  },
}));

interface MasterProductDashboardProps {
  title: string;
  description: string;
  index: number;
  hidden?: boolean;
}

const MasterProductDashboard: React.FC<MasterProductDashboardProps> = ({
  title,
  description,
  index,
}) => {
  const classes = useStyles();
  const navigator = useNavigate();

  return (
    // <Card className={`${classes.card} ${index === 0 ? classes.hoverCard : ''}`}>
    <CardContent
      style={{ padding: "12px" }}
      onClick={() => navigator("/master/company_master")}
      className={`${classes.card} ${index === 0 ? classes.hoverCard : ""}`}
    >
      <span
        className={`${classes.subTitle} ${index === 0 ? classes.title : ""}`}
        style={{
          fontSize: "13px",
          fontWeight: "600",
          padding: "0px 0px 8px 0px",
        }}
      >
        {title}
      </span>
      <span style={{ fontSize: "10px", fontWeight: "600", color: "#000000" }}>
        {description}
      </span>
    </CardContent>
    // </Card>
  );
};

const CompanyDashboard: React.FC = () => {
  return (
    <Grid
      container
      spacing={3}
      className="company_card"
      sx={{
        backgroundColor: "#FFFFFF",
      }}
    >
      {/* First line with 6 cards */}
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <MasterProductDashboard
          index={0}
          title="Company Master"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
    </Grid>
  );
};

export default CompanyDashboard;
