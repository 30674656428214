import { Box, TextField, Button, Grid, InputLabel } from '@mui/material';

interface ReasonViewForm {
    onBack: (event: React.MouseEvent<unknown>) => void;
}
const ReasonView: React.FC<ReasonViewForm> = ({ onBack }) => {
    const handleBackClick = (event: React.MouseEvent<unknown>) => {
        onBack(event); // Pass the event argument to onBack function
    };

    return (
        <>  <Box className='card-form'>
            <form noValidate>
                <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 10 }}>
                    <Grid item xs={12} sm={6}>
                        <InputLabel size="normal" >Module Name</InputLabel>
                        <TextField placeholder="Module Name" fullWidth />

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel size="normal" >Screen Name</InputLabel>
                        <TextField placeholder="Screen Name" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel size="normal" >Code</InputLabel>
                        <TextField placeholder="Code" fullWidth />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <InputLabel size="normal" >Reason Name</InputLabel>
                        <TextField placeholder="reason Name" fullWidth />
                    </Grid>
                    <Grid item xs={12} mt={2} display="flex" justifyContent="center">
                        <Button variant="contained" className='btn-colr' onClick={handleBackClick} >
                            back
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
        </>
    );
}
export default ReasonView