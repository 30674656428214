import * as React from 'react';
import Box from '@mui/material/Box';
import {
    Button,
    Grid,
    InputLabel,
    TextField,
} from '@mui/material';

interface RetailerClassFormProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
}
const RetailerGroupForm: React.FC<RetailerClassFormProps> = ({ onBack }) => {
    const handleBackClick = (event: React.MouseEvent<unknown>) => {
        onBack(event); // Pass the event argument to onBack function
    };
    return (
        <>
        <Box className='card-form'>
                <form noValidate>
                    <Grid container rowSpacing={3} columnGap={4}>

                        <Grid item xs={12} sm={6}>
                            <InputLabel size="normal"  >Channel Name *</InputLabel>
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel size="normal" >
                            Group Code *</InputLabel>
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel size="normal" >
                               Group Name *</InputLabel>
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        
                        <Grid item xs={12} mt={2} display="flex" justifyContent="center">
                            <Button className='btn-apply' variant='contained' onClick={handleBackClick}>Back</Button>

                        </Grid>
                    </Grid>
                </form>

            </Box>
        </>
    );
}
export default RetailerGroupForm