import React, { useRef } from "react";

import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import type { TableColumnsType } from 'antd';
import SearchBar from '@mkyy/mui-search-bar';
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
// import DeleteModal from "../../../components/Modal/DeleteSupplier";
import AddIcon from "@mui/icons-material/Add";
import CommonTable from "../../../components/Tables/CommonTable";
import DeleteModal from "../../../components/Modal/DeleteSupplier";
import Productview from "./Productview/Productview";
import ProductEdit from "./ProductEdit/ProductEdit";
// import Distributorcreate from "./Createdistributor";
// import DistributorView from "./DistributorView/DistributorView";
// import DistributorEdit from "./DistributorEdit/DistributorEdit";
// import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  ProductCode: string;
  ProductName: string;
  ShortName: string;

}

const Product: React.FC = () => {

  const [isViewing, setIsViewing] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);

  const [textFieldValue, setTextFieldValue] = React.useState('');
 
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    //search function logic here
  };
 

  const handleCreateNew = () => {
    setShowForm(!showForm);
  };

  const handleEditClick = (event: React.MouseEvent<unknown>) => {
    setIsEditing(!isEditing);
  };


  const handleViewClick = (event: React.MouseEvent<unknown>) => {
    setIsViewing(!isViewing);
  };
  const handleDeleteClick = (event: React.MouseEvent<unknown>) => {
    setIsDelete(!isDelete);
  };

  const columns: TableColumnsType<DataType>= [

    {
      dataIndex: "ProductCode",
      title: "Product Code",
      // sorter: (a, b) => a.Distributor.length - b.Distributor.length,
    },
    {
      dataIndex: "ProductName",
      title: "Product Name",
      sorter: (a, b) => a.ProductName.length - b.ProductName.length,
    },
    {
      dataIndex: "ShortName",
      title: "Short Name",
      sorter: (a, b) => a.ShortName.length - b.ShortName.length,
    },
  

    {
      dataIndex: "action",
      title: "Action",

      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            onClick={(event) => handleViewClick(event)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
          <EditIcon
            onClick={(event) => handleEditClick(event)}
            style={{
              cursor: "pointer",
              marginLeft: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />
          <DeleteIcon
            onClick={(event) => handleDeleteClick(event)}
            style={{
              cursor: "pointer",
              marginRight: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      ProductCode: "KS0004DRS02S",
      ProductName: "KARTHIKA DRS 4M+2ML 1920+480P 8825",
      ShortName: "KARTHIKA DRYNS SHIELD 4-7 ML OR RE.1",
    
    },
    {
      key: 2,
      ProductCode: "SD0150BB01S",
      ProductName: "SPINZ DEO LLF BLUE BOUNCE B3G1 8SET 24+8",
      ShortName: "Spinz Deo Blue Bounce 150Ml",
   
    },
    {
      key: 3,
      ProductCode: "CNMS170BSH01TPS",
      ProductName: "CAVIN'S MS BSH 170mL 30 PCS RS35 TPS",
      ShortName: "	Cavins Milkshake Butterscotch 170Ml",
    
    },
    {
      key: 4,
      ProductCode: "SD0150YB01S",
      ProductName: "SPINZ DEO YELLOW BLOSSOM B3G1 8SET 24+8",
      ShortName: "	Spinz Deo Yellow Blossom 150Ml",
     
    },
    {
      key: 5,
      ProductCode: "	SD0150PG01S",
      ProductName: "	SPINZ DEO PRISTINEGREEN B3G1 8SET 24+8",
      ShortName: "Spinz Deo Pristine Green 150Ml",
      
    },
    {
      key: 6,
      ProductCode: "	SD0150OZ01S",
      ProductName: "SPINZ DEO LLF ORANGE ZEST B3G1 8SET 24+8",
      ShortName: "	Spinz Deo Orange Zest 150Ml ",
  
    },
  ];
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
};
  return (
    <Box sx={{ marginTop: '2px' }}>
  
        <Grid item xs={12} className=' mar-tb'>
            <Typography className="cursor_pointer" >
            <b className="header-lable">    Product
</b></Typography>
        </Grid>

      {showForm ? (
        <></>
    //    
      ) : isEditing ? (
        <ProductEdit onBack={(event) => handleEditClick(event)} />
      ) : isViewing ? (
        <Productview onBack={(event) => handleViewClick(event)} />
      ) : (
        <Box>
      
          <Grid item sx={{ mt: 2 }}>
            <Box>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', mb: 2 }} >
            <SearchBar
              className='search-table'
              value={textFieldValue}
              onChange={newValue => {
                setTextFieldValue(newValue);
                handleSearch();
              }}
            />
          </Grid>
          <CommonTable 
          // rowSelection={rowSelection} 
          data={data} columns={columns} />

              {isDelete && (
                <DeleteModal onBack={(event) => handleDeleteClick(event)} />
              )}
            </Box>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
export default Product;
