import React from "react";
import {
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Grid,
  Checkbox,
  InputLabel,
} from "@mui/material";

interface ViewGeoValueFormProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const GeoValueViewForm: React.FC<ViewGeoValueFormProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const companyOptions = ["Company A", "Company B", "Company C"];
  const gstStateOptions = ["State A", "State B", "State C"];
  const geoHierarchyValueOptions = ["Value A", "Value B", "Value C"];
  return (
    <>
      <Box className="card-form">
        <form noValidate>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Level Name
              </InputLabel>
              <TextField className="Txt-box" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Code
              </InputLabel>
              <TextField placeholder="Code" className="Txt-box" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Value(Name)
              </InputLabel>
              <TextField placeholder="Name" className="Txt-box" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Reporting To
              </InputLabel>

              <TextField className="Txt-box" />
            </Grid>
            <Grid item xs={12} mt={2} display="flex" justifyContent="center">
              <Button
                variant="contained"
                className="btn-colr"
                onClick={handleBackClick}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};
export default GeoValueViewForm;
