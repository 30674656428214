import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useFetch";
//icons
import Close from "../../../../assets/images/icons/Close_icon.png";
import Editicon from "../../../../assets/images/icons/edit_icon.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  ProductTaxFetchData,
  ProductTaxFetchProductName,
  ProductTaxUpdate,
} from "../../../../Redux/Masters/Products/ProductTaxMaster/action";
import { ProductTaxFormValues } from "../../../../typescript/product";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface RequestData {
  page: number;
  size: number;
  hsnType: string;
  category: string;
}
interface ProductCodeDetails {
  id: number;
  productCode: string;
}
interface HsnCode {
  id: number;
  hsnCode: string;
}
interface EditProductTaxProps {
  onClose: () => void;
  requestData: RequestData;
  product_Code: ProductCodeDetails;
  hsn_Code: HsnCode;
}
export default function EditProductTax(props: EditProductTaxProps) {
  // const dispatch = useAppDispatch();
  const { onClose, requestData, product_Code, hsn_Code } = props; // Destructure the props
  const [open, setOpen] = React.useState(false);
  const [isEditEnabled, setIsEditEnabled] = React.useState(true);
  const [FormError, setFormError] = useState<boolean>(false);
  const [sucessMessage, setSucessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const dispatch = useAppDispatch();

  const ProductTaxEditDatas = useAppSelector(
    (state: any) => state.ProductTaxFetchData.ProductTaxEdit
  );

  const [formData, setFormData] = useState<ProductTaxFormValues>({
    productCode: "",
    hsnCode: "",
    gstCode: "",
    productName: " ",
  });
  const handleChangeSelectProCode = (event: SelectChangeEvent) => {
    const selectedProductCode = event.target.value as string; // Assuming the value is a string
    setFormData({ ...formData, productCode: selectedProductCode });
    dispatch(ProductTaxFetchProductName(event.target.value));
  };
  const ProductNameDatas = useAppSelector(
    (state: any) => state.ProductTaxFetchData.ProductTaxProName
  );

  useEffect(() => {
    setFormData({ ...formData, productName: ProductNameDatas });
  }, [ProductNameDatas]);

  const handleChangeSelectHsnCode = (event: SelectChangeEvent) => {
    const selectedhsnCode = event.target.value as string; // Assuming the value is a string
    setFormData({ ...formData, hsnCode: selectedhsnCode });
  };

  // const [errors, setErrors] = useState<FormErrors>({
  //   productCode: "",
  //   name: "",
  //   hsnCode: "",
  //   gstno: "",
  //
  // });

  // const validateFormData = (data: CreateCompanyFormValues): FormErrors => {
  //   const errors: FormErrors = {};

  //   const fieldsToValidate: (keyof CreateCompanyFormValues)[] = [
  //     'productCode',
  //     'name',
  //     'hsnCode',
  //     'gstno',
  //
  //   ];

  //   // Validate fields other than address

  //   fieldsToValidate.forEach(field => {
  //     if (!data[field]) {
  //       errors[field] = `${field} is required`;
  //     }
  //   });

  //   // Validate address fields

  //   if (!data.address) {
  //     errors.address = { doorNo: 'Door number is required', streetName: 'Street name is required', area: 'Area is required' };
  //   } else {
  //     if (!data.address.doorNo) {
  //       errors.address = { ...errors.address, doorNo: 'Door number is required' };
  //     }
  //     if (!data.address.streetName) {
  //       errors.address = { ...errors.address, streetName: 'Street name is required' };
  //     }
  //     if (!data.address.area) {
  //       errors.address = { ...errors.address, area: 'Area is required' };
  //     }
  //   }

  //   return errors;
  // };

  useEffect(() => {
    if (ProductTaxEditDatas) {
      setFormData((prevData) => ({
        ...prevData,
        productCode: ProductTaxEditDatas.productCode || "",
        productName: ProductTaxEditDatas.productName || "",
        hsnCode: ProductTaxEditDatas.hsnCode || "",
        gstCode: ProductTaxEditDatas.gst || "",
      }));
    }
  }, [ProductTaxEditDatas]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleEditClick = () => {
    setIsEditEnabled(false); // Disable edit when clicked
  };

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   let newFormData: CreateCompanyFormValues = { ...formData }; // Make a copy of formData

  //     // If it's not part of the address object, update it normally
  //     newFormData = {
  //       ...newFormData,
  //       [name]: value,
  //     };
  //   }

  //   // Email regex pattern
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //   // Check if the changed field is the email field and validate against the regex pattern
  //   if (name === "email" && !emailRegex.test(value)) {
  //     setErrors({ ...errors, email: "Please Enter a Valid email Address(Ex:example@domain.com)" });
  //   } else {
  //     setErrors({ ...errors, email: "" }); // Clear email error if it's valid
  //   }
  //   setFormData(newFormData); // Update formData
  // };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    formData: ProductTaxFormValues
  ): Promise<void> => {
    event.preventDefault();
    // const validationErrors = validateFormData(formData);
    // if (Object.keys(validationErrors).length === 0) {
    try {
      const response = await dispatch(
        ProductTaxUpdate({ id: ProductTaxEditDatas.id, formData })
      );
      const successMessage = response.message;
      setFormError(true);
      setSucessMessage(successMessage);
      dispatch(ProductTaxFetchData(requestData));
      setTimeout(() => onClose(), 2000);
    } catch (error: any) {
      if (error.response.data.data) {
        const errorMessage = error.response.data.data;
      } else {
        setErrorMessage("something went Wrong");
      }
    }
  };
  // else {
  //   // Form is invalid, display errors
  //   setErrors(validationErrors);
  // }

  const hsnCode = ["aaa", "bb"];
  const [selectedOption, setSelectedOption] = useState("");

  const handleChangeSelectHSc = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value);
  };
  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_stl"
        // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 533,
              md: 536,
              sm: 600,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid
            item
            sx={{ width: "95%", paddingLeft: "14px" }}
            role="presentation"
          >
            <Grid container className="crt-header" mt={5}>
              <Typography gutterBottom className="crt-header-lable">
                Product Tax
              </Typography>

              <Grid item>
                <img
                  src={Editicon}
                  alt={Editicon}
                  onClick={handleEditClick}
                  //   onClick={onClose}
                  style={{ width: "24px", height: "24px", marginRight: "15px" }}
                />
                <img
                  src={Close}
                  alt={Close}
                  onClick={onClose}
                  style={{ width: "24px", height: "24px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Content */}
          <Box
            mt={3}
            sx={{
              overflowY: "auto",
              marginBottom: "70px",
            }}
            className="create-form"
          >
            {/* Your scrollable content */}
            <form onSubmit={(event) => handleSubmit(event, formData)}>
              <Grid
                item
                sx={{ width: "95%", margin: "auto", marginBottom: "100px" }}
              >
                <Grid item>
                  <Typography className="crt-fnt-lable">
                    Product Code
                  </Typography>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Select
                        className="prd_select"
                        placeholder="Select HSN Code"
                        IconComponent={KeyboardArrowDownIcon}
                        disabled={isEditEnabled}
                        displayEmpty
                        value={formData.productCode}
                        onChange={handleChangeSelectProCode}
                      >
                        <MenuItem disabled value="">
                          <em style={{ color: "#bababc" }}>
                            Select Product Code
                          </em>
                        </MenuItem>
                        {product_Code &&
                          Array.isArray(product_Code) &&
                          product_Code.map((option: any, index: number) => (
                            <MenuItem key={index} value={option.id}>
                              {option.productCode}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">
                    Product Name
                  </Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Enter Name"
                      name={"productName"}
                      disabled={isEditEnabled}
                      // onChange={handleInputChange}
                      value={formData.productName}
                      // error={errors.name ? true : false}
                      // helperText={errors.name && errors.name}
                    />
                  </Grid>
                </Grid>

                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable"> HSN Code</Typography>
                  <Grid item xs={12} mt={1}>
                    <FormControl fullWidth>
                      <Select
                        name="hsnCode"
                        className="prd_select"
                        placeholder="Select Business Level"
                        disabled={isEditEnabled}
                        IconComponent={KeyboardArrowDownIcon}
                        displayEmpty
                        value={formData.hsnCode}
                        onChange={handleChangeSelectHsnCode}
                      >
                        <MenuItem disabled value="">
                          <em style={{ color: "#bababc" }}>Select HSN Code</em>
                        </MenuItem>
                        {hsn_Code &&
                          Array.isArray(hsn_Code) &&
                          hsn_Code.map((option: HsnCode, index: number) => (
                            <MenuItem key={index} value={option.id}>
                              {option.hsnCode}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">GST %</Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Enter GST%"
                      name={"gstno"}
                      disabled={isEditEnabled}
                      // onChange={handleInputChange}
                      value={formData.gstCode}
                      // error={errors.name ? true : false}
                      // helperText={errors.name && errors.name}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">CGST % </Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Enter CGST%"
                      name={"CGST"}
                      disabled={isEditEnabled}
                      // onChange={handleInputChange}
                      value={"9%"}
                      // error={errors.name ? true : false}
                      // helperText={errors.name && errors.name}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">SGST % </Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Enter SGST%"
                      name={"SGST"}
                      disabled={isEditEnabled}
                      // onChange={handleInputChange}
                      value={"9%"}
                      // error={errors.name ? true : false}
                      // helperText={errors.name && errors.name}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">IGST % </Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Enter IGST%"
                      name={"IGST"}
                      disabled={isEditEnabled}
                      // onChange={handleInputChange}
                      value={"18%"}
                      // error={errors.name ? true : false}
                      // helperText={errors.name && errors.name}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item mt={2} className="product_Create_footer">
                <Button className="crt-btn-cancel" onClick={onClose}>
                  {" "}
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="crt-btn-colr"
                  sx={{ mr: 4 }}
                  type="submit"
                >
                  {" "}
                  Update
                </Button>
              </Grid>
            </form>

            {/* More content... */}
            {FormError ? (
              sucessMessage ? (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="success">{sucessMessage}</Alert>
                </Snackbar>
              ) : (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="error">{errorMessage}</Alert>
                </Snackbar>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
