import React, { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { useCookies } from "react-cookie";
import { AES, enc } from "crypto-js";

interface ProtectedRouteProps {
  isAllowed: boolean;
  redirectPath?: string;
  permissions?: string[];
  children?: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAllowed,
  redirectPath = "/distributor/login",
  permissions = [],
  children,
}) => {
  const cookiesauth = useAuth();
  const [cookies, setCookie, removeCookie] = useCookies([
    "token",
    "permissions",
  ]);
  const users = getDecodeToken();
  const currentTime = new Date().getTime();

  useEffect(() => {
    if (users) {
      const isTokenExpired = (users?.exp ?? 0) < currentTime / 1000;
      if (isTokenExpired) {
        // toast.error("Session is expired");
        removeCookie("token");
        removeCookie("permissions");
      }
    } else {
      removeCookie("token");
      removeCookie("permissions");
    }
  }, [currentTime, removeCookie, users, cookiesauth]);

  const encryptedPermissions = decryptData(
    cookiesauth.cookies?.permissions || []
  );

  if (
    localStorage.getItem("token") === null ||
    !localStorage.getItem("token")
  ) {
    return <Navigate to="/distributor/login" replace />;
  }
  if (cookiesauth.cookies.token === "undefined" || !cookiesauth.cookies.token) {
    return <Navigate to="/distributor/login" replace />;
  }

  if (
    cookiesauth.cookies.token === "undefined" ||
    !cookiesauth.cookies.token ||
    !permissions.some((permission) => encryptedPermissions.includes(permission))
  ) {
    return <Navigate to="/distributor/login" replace />;
  }

  if (!isAllowed || !cookiesauth.cookies.permissions) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};

const getDecodeToken = () => {
  const token = localStorage.getItem("token") || "";

  interface JwtPayload {
    sub?: string;
    role?: string;
    empId?: string;
    CompanyId?: string;
    Name?: string;
    Email?: string;
    id?: string;
    iat?: number;
    exp?: number;
  }

  let decoded: JwtPayload = {}; // Initialize decoded with an empty object

  if (token !== "") {
    decoded = jwtDecode<JwtPayload>(token); // Update decoded if token exists
  }

  return decoded;
};

function decryptData(encryptedData: string | string[]): string | string[] {
  if (Array.isArray(encryptedData)) {
    return encryptedData.map((item) =>
      AES.decrypt(item, "yourEncryptionKey").toString(enc.Utf8)
    );
  } else {
    return AES.decrypt(encryptedData, "yourEncryptionKey").toString(enc.Utf8);
  }
}

export default ProtectedRoute;
