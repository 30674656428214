import {
    PRODUCT_TAX_Submit_ERROR,
    PRODUCT_TAX_FETCH_DATA,
    PRODUCT_TAX_EDIT_DATA,
    PRODUCT_TAX_FETCH_PRODUCT_NAME,
    } from '../../../actionType'
    interface State {
      ProductTaxFetch: [];
      ProductTaxEdit:[];
      ProductTaxProName:[];
    
    }
    
    
    const initialState: State = {
      ProductTaxFetch: [],
      ProductTaxEdit:[],
      ProductTaxProName:[],
    
    
    };
    
    
    const ProductTaxReducer = (state = initialState, action: { type: any; payload: any; }) => {
      switch (action.type) {
        case PRODUCT_TAX_FETCH_DATA:
          return {
            ...state,
           ProductTaxFetch: action.payload,
          };
          case PRODUCT_TAX_EDIT_DATA:
            return {
              ...state,
             ProductTaxEdit: action.payload,
            };
        case PRODUCT_TAX_Submit_ERROR:
          return {
            ...state,
           ProductTaxToast: action.payload,
          };
          case PRODUCT_TAX_FETCH_PRODUCT_NAME:
            return {
             ...state,
              ProductTaxProName: action.payload,
            };
    
        default:
          return state;
      }
    };
    
    export default ProductTaxReducer;
    
    