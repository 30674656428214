import React, { useState } from 'react';
import { Button, CardHeader, IconButton, CardContent, Card } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ImageSwitcherProps {
  onClose: () => void;
}

const ImageSwitcher: React.FC<ImageSwitcherProps> = ({ onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
    'https://via.placeholder.com/200', // Placeholder image 1
    'https://via.placeholder.com/201', // Placeholder image 2
    'https://via.placeholder.com/203',
  ];

  const handleClick = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };
  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    // If an image fails to load, we can set a default image or handle the error as needed
    event.currentTarget.src = 'https://via.placeholder.com/200'; // Placeholder image URL
  };
  return (
    <Card>
      <CardHeader
        title="Sales Hierarchy Level Value"
        action={
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        }
        style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} 
      />
      <CardContent>
        <img
          src={images[currentIndex]}
          alt={`Image ${currentIndex + 1}`}
          style={{ width: 500, height: 300, cursor: 'pointer' }}
          onClick={handleClick}
          onError={handleImageError}
        />
      </CardContent>
    </Card>
  );
};
export default ImageSwitcher