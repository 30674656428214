import * as React from "react";
import Box from "@mui/material/Box";
import { Grid, Paper, Typography } from "@mui/material";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GeoValueViewForm from "./GeoHighValueViewForm";
import { useNavigate } from "react-router-dom";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  levelname: string;
  code: number;
  levelvalue: number;
  reportingTo: string;
  action: string;
}

export default function GeographyHierarchyLevelValue() {
  const navigator = useNavigate();

  const [isViewing, setIsViewing] = React.useState(false);

  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // search function logic
  };

  const columns: TableColumnsType<DataType> = [
    { dataIndex: "levelname", title: "Level Name" },
    { dataIndex: "code", title: "Code" },
    { dataIndex: "levelvalue", title: "Level Value" },
    { dataIndex: "reportingTo", title: "Reporting To" },
    {
      dataIndex: "action",
      title: "Action",
      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            onClick={(event) => handleViewClick(event)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      levelname: "Town",
      code: 100,
      levelvalue: 123,
      reportingTo: "person",
      action: "",
    },
    {
      key: 2,
      levelname: "Town",
      code: 200,
      levelvalue: 25,
      reportingTo: "person",
      action: "",
    },
    {
      key: 3,
      levelname: "Town",
      code: 300,
      levelvalue: 25,
      reportingTo: "person",
      action: "",
    },
    {
      key: 4,
      levelname: "Town",
      code: 400,
      levelvalue: 35,
      reportingTo: "person",
      action: "",
    },
    {
      key: 5,
      levelname: "Town",
      code: 500,
      levelvalue: 45,
      reportingTo: "person",
      action: "",
    },
    {
      key: 6,
      levelname: "Town",
      code: 600,
      levelvalue: 66,
      reportingTo: "person",
      action: "",
    },
    {
      key: 7,
      levelname: "Town",
      code: 700,
      levelvalue: 200,
      reportingTo: "person",
      action: "",
    },
    {
      key: 8,
      levelname: "Town",
      code: 800,
      levelvalue: 500,
      reportingTo: "person",
      action: "",
    },
  ];

  const handleViewClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setIsViewing(!isViewing);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid container>
          <Grid item xs={12} sm={6} className=" mar-tb">
            <Typography>
              <b className="header-lable">Geography Hierarchy Level Value </b>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "end", mb: 2 }}
          >
            <SearchBar
              className="search-table"
              value={textFieldValue}
              onChange={(newValue) => {
                setTextFieldValue(newValue);
                handleSearch();
              }}
            />
          </Grid>
        </Grid>

        {isViewing ? (
          <GeoValueViewForm onBack={(event) => handleViewClick(event)} />
        ) : (
          <Box>
            <CommonTable
              // rowSelection={rowSelection}
              data={data}
              columns={columns}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
