import * as React from "react";
import Box from "@mui/material/Box";
import { Grid, Paper, Typography } from "@mui/material";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  name: string;
  lvCode: number;
  lvName: string;
  report: string;
  action: string;
}

export default function ProductHierarchyValue() {
  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // search function logic here
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "name",
      title: "Level Name",
    },
    {
      dataIndex: "lvCode",
      title: "Level Value Code",
    },
    {
      dataIndex: "lvName",
      title: "Level Value Name",
      sorter: (a, b) => a.lvName.length - b.lvName.length,
    },
    {
      dataIndex: "report",
      title: "Reporting Level Name",
      sorter: (a, b) => a.report.length - b.report.length,
    },

    {
      dataIndex: "action",
      title: "Action",
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      name: "Supplier1",
      lvCode: 55,
      lvName: "levelname1",
      report: "repor1",
      action: "",
    },
    {
      key: 2,
      name: "Supplier2",
      lvName: "levelname1",
      report: "repor1",
      lvCode: 55445,

      action: "",
    },
    {
      name: "Supplier3",
      key: 3,
      lvCode: 5565,
      lvName: "levelname1",
      report: "repor1",
      action: "",
    },
    {
      key: 4,
      name: "Supplier4",
      lvCode: 5548,
      lvName: "levelname1",
      report: "repor1",
      action: "",
    },
    {
      key: 5,
      name: "Supplier5",
      lvCode: 55121,
      lvName: "levelname1",
      report: "repor1",
      action: "",
    },
    {
      key: 6,
      name: "Supplier6",
      lvCode: 5565,
      lvName: "levelname1",
      report: "repor1",
      action: "",
    },
    {
      name: "Supplier7",
      key: 7,
      lvCode: 55789,
      lvName: "levelname1",
      report: "repor1",
      action: "",
    },
    {
      key: 8,
      name: "Supplier8",
      lvCode: 55656,
      lvName: "levelname1",
      report: "repor1",
      action: "",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className="pad-10 mar-tb">
          <Typography>
            <b>Product Hierarchy Level</b>
          </Typography>
        </Grid>

        <Box>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "end", mb: 2 }}
          >
            <SearchBar
              className="search-table"
              value={textFieldValue}
              onChange={(newValue) => {
                setTextFieldValue(newValue);
                handleSearch();
              }}
            />
          </Grid>
          <CommonTable
            // rowSelection={rowSelection}
            data={data}
            columns={columns}
          />
        </Box>
      </Box>
    </>
  );
}
