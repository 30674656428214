import {
  SUPPLIER_FETCH_DATA,
  SUPPLIER_EDIT_DATA,
  SUPPLIER_UPDATE_DATA,
  SUPPLIER_Submit_ERROR,
  SUPPLIER_UPDATE_ERROR,
  GET_DEFAULT_COMPANY
} from '../../actionType'
interface State {
  companyFetch: [];
  SupplierEdit: [];
  SupplierUpdate: [];
  SupplierToast: [];
  SupplierUpdateToast: [];
  GetDefaultCompany: [];
  loading: boolean;
  error: string | null;
}


const initialState: State = {
  companyFetch: [],
  SupplierEdit: [],
  SupplierUpdate: [],
  SupplierToast: [],
  SupplierUpdateToast: [],
  GetDefaultCompany:[],
  loading: false,
  error: null,
};


const SupplierReducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case SUPPLIER_FETCH_DATA:
      return {
        ...state,
        supplierFetch: action.payload,
      };
    case SUPPLIER_EDIT_DATA:
      return {
        ...state,
        SupplierEdit: action.payload,
      };
    case SUPPLIER_UPDATE_DATA:
      return {
        ...state,
        SupplierUpdate: action.payload,
      };
    case SUPPLIER_Submit_ERROR:
      return {
        ...state,
        SupplierToast: action.payload,
      };
    case SUPPLIER_UPDATE_ERROR:
      return {
        ...state,
        SupplierUpdateToast: action.payload,
      };
    case GET_DEFAULT_COMPANY:
      return {
        ...state,
        GetDefaultCompany: action.payload,
      };
    default:
      return state;
  }
};

export default SupplierReducer;

