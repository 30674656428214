import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, Drawer, Grid, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
//icons
import Close from "../../../assets/images/icons/Close_icon.png";
import Editicon from "../../../assets/images/icons/edit_icon.png";
import {
  companyFetchData,
  companyUpdate,
} from "../../../Redux/Masters/Company/action";
import {
  CreateCompanyFormValues,
  FormErrors,
} from "../../../typescript/Company";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface RequestData {
  page: number;
  size: number;
}
interface MasterCompanyEditProps {
  onClose: () => void;
  requestData: RequestData;
}
export default function MasterCompanyEdit(props: MasterCompanyEditProps) {
  const dispatch = useAppDispatch();
  const { onClose, requestData } = props; // Destructure the props
  const [open, setOpen] = React.useState(false);
  const [isEditEnabled, setIsEditEnabled] = React.useState(true);
  const [FormError, setFormError] = useState<boolean>(false);
  const [sucessMessage, setSucessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const companyEditData = useAppSelector(
    (state: any) => state.companyFetch.CompanyEdit
  );

  const [formData, setFormData] = useState<CreateCompanyFormValues>({
    name: "",
    code: "",
    businessVertical: "",
    address: {
      doorNo: "",
      streetName: "",
      area: "",
    },
    city: "",
    state: "",
    country: "",
    postalCode: "",
    email: "",
    gstNo: "",
  });

  const [errors, setErrors] = useState<FormErrors>({
    name: "",
    code: "",
    businessVertical: "",
    address: {
      doorNo: "",
      streetName: "",
      area: "",
    },
    city: "",
    state: "",
    country: "",
    postalCode: "",
    email: "",
    gstNo: "",
  });

  const validateFormData = (data: CreateCompanyFormValues): FormErrors => {
    const errors: FormErrors = {};

    const fieldsToValidate: (keyof CreateCompanyFormValues)[] = [
      "name",
      "code",
      "businessVertical",
      "city",
      "state",
      "country",
      "postalCode",
      "email",
      "gstNo",
    ];

    // Validate fields other than address

    fieldsToValidate.forEach((field) => {
      if (!data[field]) {
        errors[field] = `${field} is required`;
      }
    });

    // Validate address fields

    if (!data.address) {
      errors.address = {
        doorNo: "Door number is required",
        streetName: "Street name is required",
        area: "Area is required",
      };
    } else {
      if (!data.address.doorNo) {
        errors.address = {
          ...errors.address,
          doorNo: "Door number is required",
        };
      }
      if (!data.address.streetName) {
        errors.address = {
          ...errors.address,
          streetName: "Street name is required",
        };
      }
      if (!data.address.area) {
        errors.address = { ...errors.address, area: "Area is required" };
      }
    }

    return errors;
  };

  useEffect(() => {
    if (companyEditData && companyEditData.address) {
      // Parse the JSON string in the address field into an object
      const addressObject = JSON.parse(companyEditData.address);

      // Update the formData state
      setFormData((prevData) => ({
        ...prevData,
        name: companyEditData.name || "",
        code: companyEditData.code || "",
        businessVertical: companyEditData.businessVertical || "",
        address: {
          doorNo: addressObject.doorNo || "",
          streetName: addressObject.streetName || "",
          area: addressObject.area || "",
        },
        city: companyEditData.city || "",
        state: companyEditData.state || "",
        country: companyEditData.country || "",
        postalCode: companyEditData.postalCode || "",
        email: companyEditData.email || "",
        gstNo: companyEditData.gstNo || "",
      }));
    }
  }, [companyEditData]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleEditClick = () => {
    setIsEditEnabled(false); // Disable edit when clicked
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newFormData: CreateCompanyFormValues = { ...formData }; // Make a copy of formData

    // If the changed field is part of the address object, update it properly
    if (name === "doorNo" || name === "streetName" || name === "area") {
      newFormData = {
        ...newFormData,
        address: {
          ...newFormData.address,
          [name]: value,
        },
      };
    } else {
      // If it's not part of the address object, update it normally
      newFormData = {
        ...newFormData,
        [name]: value,
      };
    }
    // Email regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the changed field is the email field and validate against the regex pattern
    if (name === "email" && !emailRegex.test(value)) {
      setErrors({
        ...errors,
        email: "Please Enter a Valid email Address(Ex:example@domain.com)",
      });
    } else {
      setErrors({ ...errors, email: "" }); // Clear email error if it's valid
    }
    setFormData(newFormData); // Update formData
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    formData: CreateCompanyFormValues
  ): Promise<void> => {
    event.preventDefault();
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await dispatch(
          companyUpdate({ id: companyEditData.id, formData })
        );
        const successMessage = response.message;
        setFormError(true);
        setSucessMessage(successMessage);
        dispatch(companyFetchData(requestData));
        setTimeout(() => onClose(), 2000);
      } catch (error: any) {
        if (error.response.data.data) {
          const errorMessage = error.response.data.data;
          setErrors(errorMessage);
        } else {
          setErrorMessage("something went Wrong");
        }
      }
    } else {
      // Form is invalid, display errors
      setErrors(validationErrors);
    }
  };
  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_stl"
        // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 533,
              md: 536,
              sm: 600,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "95%", margin: "auto" }} role="presentation">
            <Grid container className="crt-header" mt={5}>
              <Typography gutterBottom className="crt-header-lable">
                Company 1
              </Typography>

              <Grid item>
                <img
                  src={Editicon}
                  alt={Editicon}
                  onClick={handleEditClick}
                  //   onClick={onClose}
                  style={{ width: "24px", height: "24px", marginRight: "15px" }}
                />
                <img
                  src={Close}
                  alt={Close}
                  onClick={onClose}
                  style={{ width: "24px", height: "24px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Content */}
          <Box
            mt={3}
            sx={{
              overflowY: "auto",
              marginBottom: "70px",
            }}
            className="create-form"
          >
            {/* Your scrollable content */}
            <form onSubmit={(event) => handleSubmit(event, formData)}>
              <Grid
                item
                sx={{ width: "95%", margin: "auto", marginBottom: "100px" }}
              >
                <Grid item>
                  <Typography className="crt-fnt-lable">Name</Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Company Name"
                      name="name"
                      disabled={isEditEnabled}
                      onChange={handleInputChange}
                      value={formData.name}
                      error={errors.name ? true : false}
                      helperText={errors.name && errors.name}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">Code</Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Company Code"
                      name="code"
                      disabled={isEditEnabled}
                      onChange={handleInputChange}
                      value={formData.code}
                      error={errors.code ? true : false}
                      helperText={errors.code && errors.code}
                    />
                  </Grid>
                </Grid>

                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">
                    Business Vertical
                  </Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      disabled={isEditEnabled}
                      placeholder="Business Vertical "
                      name="businessVertical"
                      onChange={handleInputChange}
                      value={formData.businessVertical}
                      error={errors.businessVertical ? true : false}
                      helperText={
                        errors.businessVertical && errors.businessVertical
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">Address</Typography>
                  <Grid item xs={12}>
                    <Grid item sx={{ mt: 1 }}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Door No  "
                        disabled={isEditEnabled}
                        name="doorNo"
                        onChange={handleInputChange}
                        value={formData.address.doorNo}
                        error={
                          errors.address && errors.address.doorNo ? true : false
                        }
                        helperText={
                          errors.address && errors.address.doorNo
                            ? errors.address.doorNo
                            : null
                        }
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        className="area-Crt-txt-box"
                        placeholder="Street Name  "
                        disabled={isEditEnabled}
                        name="streetName"
                        onChange={handleInputChange}
                        value={formData.address.streetName}
                        error={
                          errors.address && errors.address.streetName
                            ? true
                            : false
                        }
                        helperText={
                          errors.address && errors.address.streetName
                            ? errors.address.streetName
                            : null
                        }
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        className="area-Crt-txt-box"
                        placeholder="Area "
                        disabled={isEditEnabled}
                        name="area"
                        onChange={handleInputChange}
                        value={formData.address.area}
                        error={
                          errors.address && errors.address.area ? true : false
                        }
                        helperText={
                          errors.address && errors.address.area
                            ? errors.address.area
                            : null
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">City </Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Select City  "
                      disabled={isEditEnabled}
                      name="city"
                      onChange={handleInputChange}
                      value={formData.city}
                      error={errors.city ? true : false}
                      helperText={errors.city && errors.city}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">State </Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Select State  "
                      disabled={isEditEnabled}
                      name="state"
                      onChange={handleInputChange}
                      value={formData.state}
                      error={errors.state ? true : false}
                      helperText={errors.state && errors.state}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">Country </Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Country  "
                      disabled={isEditEnabled}
                      name="country"
                      onChange={handleInputChange}
                      value={formData.country}
                      error={errors.country ? true : false}
                      helperText={errors.country && errors.country}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">
                    Postal Code{" "}
                  </Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Enter Zip Code  "
                      name="postalCode"
                      disabled={isEditEnabled}
                      onChange={handleInputChange}
                      value={formData.postalCode}
                      error={errors.postalCode ? true : false}
                      helperText={errors.postalCode && errors.postalCode}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">GST No </Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Enter GST No  "
                      name={"gstNo"}
                      disabled={isEditEnabled}
                      value={formData.gstNo}
                      onChange={handleInputChange}
                      error={errors.gstNo ? true : false}
                      helperText={errors.gstNo && errors.gstNo}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">Email ID </Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Mail ID   "
                      name={"email"}
                      disabled={isEditEnabled}
                      value={formData.email}
                      onChange={handleInputChange}
                      error={errors.email ? true : false}
                      helperText={errors.email && errors.email}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item mt={2} className="product_Create_footer">
                <Button className="crt-btn-cancel" onClick={onClose}>
                  {" "}
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="crt-btn-colr"
                  sx={{ mr: 4 }}
                  type="submit"
                >
                  {" "}
                  Update
                </Button>
              </Grid>
            </form>

            {/* More content... */}
            {FormError ? (
              sucessMessage ? (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="success">{sucessMessage}</Alert>
                </Snackbar>
              ) : (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="error">{errorMessage}</Alert>
                </Snackbar>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
