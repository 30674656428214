import React, { useRef } from "react";

import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CommonTable from "../../../components/Tables/CommonTable";
import { TableColumnsType } from "antd";
import PriceDiscountCreate from "./PriceDiscountCreate";

interface DataType {
  key: React.Key;
  ReferenceNo: string;
  ContractDescription: string;
  DistCode: string;
  DistName: string;
  ChannelSubChannel: string;
  Group: string;
  Class: string;
  LevelCode: number;
  LevelName: string;
  DiscType: string;
  DiscValue: number;
  Created: string;
}
const PriceDiscount: React.FC = () => {
  const navigator = useNavigate();
  const [showForm, setShowForm] = React.useState(false);
  const [showTable, setShowTable] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleCreateNew = () => {
    setShowForm(!showForm);
  };

  const Options = ["Specific SKU", "Reference Number"];
  const EntityType = [" Super Stockiest", "Sub Stockiest", "Distributor"];
  const Entity = [" SRI'S", "P S ENTERPRISES", "SRI SAI HANUMAN AGENCIES"];
  const RetailerChannel = [
    " COLD CHAIN/VD-TAMIL NADU",
    "FMCG/FMCG-GENERAL TRADE",
    "FMCG/FMCG-RAAGA",
  ];

  const RetailerGroup = [
    " RA-GENTS SALOON'S",
    "RA-LADIES SALOON",
    "RA-PARLOUR",
  ];
  const RetailerClass = [" A", "B", "C", "D", "HVO"];

  const handleShowTable = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setShowTable(!showTable);
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: "Ref. No",
      dataIndex: "ReferenceNo",
    },
    {
      title: "Contract Description",
      dataIndex: "ContractDescription",
      //   sorter: (a, b) => a.CreatedDate.length - b.CreatedDate.length,
    },
    {
      title: "Dist Code",
      dataIndex: "DistCode",
      //   sorter: (a, b) => a.ValidFrom.length - b.ValidFrom.length,
    },
    {
      title: "Channel/Sub Channel",
      dataIndex: "ChannelSubChannel",
      // sorter: (a, b) => a.ValidFrom.length - b.ValidFrom.length,
    },
    {
      title: "Status",
      dataIndex: "Status",
      // sorter: (a, b) => a.ValidFrom.length - b.ValidFrom.length,
    },
    {
      title: "Group",
      dataIndex: "Group",
      // sorter: (a, b) => a.ValidFrom.length - b.ValidFrom.length,
    },
    {
      title: "Class",
      dataIndex: "Class",
      // sorter: (a, b) => a.ValidFrom.length - b.ValidFrom.length,
    },
    {
      title: "Level Code",
      dataIndex: "LevelCode",
      // sorter: (a, b) => a.ValidFrom.length - b.ValidFrom.length,
    },
    {
      title: "Level Name",
      dataIndex: "LevelName",
      // sorter: (a, b) => a.ValidFrom.length - b.ValidFrom.length,
    },
    {
      title: "Disc Type",
      dataIndex: "DiscType",
      // sorter: (a, b) => a.ValidFrom.length - b.ValidFrom.length,
    },
    {
      title: "Disc Value",
      dataIndex: "DiscValue",
      // sorter: (a, b) => a.ValidFrom.length - b.ValidFrom.length,
    },
    {
      title: "Created",
      dataIndex: "Created",
      // sorter: (a, b) => a.ValidFrom.length - b.ValidFrom.length,
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      ReferenceNo: "PDFMC160003977",
      ContractDescription: "P",
      DistCode: "GT0100020072200002007220	",
      DistName: "SRI SAI HANUMAN AGENCIES",
      ChannelSubChannel: "FMCG/FMCG-RAAGA",
      Group: "FMCG - Bus Stand Outlets",
      Class: "HVO",
      LevelCode: 1010603,
      LevelName: "Cavin's",
      DiscType: "Disc %",
      DiscValue: 2.0,
      Created: "25/05/2022",
    },
    {
      key: "2",
      ReferenceNo: "PDFMC160003977",
      ContractDescription: "P",
      DistCode: "GT0100020072200002007220",
      DistName: "SRI SAI HANUMAN AGENCIES",
      ChannelSubChannel: "FMCG/FMCG-RAAGA",
      Group: "RA-PARLOUR",
      Class: "HVO",
      LevelCode: 5010603,
      LevelName: "Cavin's",
      DiscType: "Disc %",
      DiscValue: 2.0,
      Created: "25/05/2022",
    },
    {
      key: "3",
      ReferenceNo: "PDFMC160003977",
      ContractDescription: "P",
      DistCode: "GT0100020072200002007220",
      DistName: "	SRI SAI HANUMAN AGENCIES",
      ChannelSubChannel: "	FMCG/FMCG-RAAGA",
      Group: "FMCG - Bus Stand Outlets",
      Class: "HVO",
      LevelCode: 3010640,
      LevelName: "Lovya",
      DiscType: "Disc %",
      DiscValue: 2.0,
      Created: "25/05/2022",
    },
    {
      key: "4",
      ReferenceNo: "PDFMC160003977",
      ContractDescription: "P",
      DistCode: "GT0100020072200002007220",
      DistName: "SRI SAI HANUMAN AGENCIES",
      ChannelSubChannel: "FMCG/FMCG-RAAGA",
      Group: "RA-PARLOUR",
      Class: "HVO",
      LevelCode: 1010603,
      LevelName: "Lovya",
      DiscType: "Disc %",
      DiscValue: 2.0,
      Created: "25/05/2022",
    },
    {
      key: "5",
      ReferenceNo: "PDFMC160003977",
      ContractDescription: "P",
      DistCode: "GT0100020072200002007220",
      DistName: "SRI SAI HANUMAN AGENCIES",
      ChannelSubChannel: "FMCG/FMCG-RAAGA",
      Group: "FMCG - Bus Stand Outlets",
      Class: "HVO",
      LevelCode: 7,
      LevelName: "CHILLOUT",
      DiscType: "Disc %",
      DiscValue: 2.0,
      Created: "25/05/2022",
    },
    {
      key: "6",
      ReferenceNo: "PDFMC160003977",
      ContractDescription: "P",
      DistCode: "GT0100020072200002007220",
      DistName: "SRI SAI HANUMAN AGENCIES",
      ChannelSubChannel: "FMCG/FMCG-RAAGA",
      Group: "RA-PARLOUR",
      Class: "HVO",
      LevelCode: 1010603,
      LevelName: "CHILLOUT",
      DiscType: "Disc %",
      DiscValue: 2.0,
      Created: "25/05/2022",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
      <Paper elevation={0} className="pad-10 mar-tb">
        <Grid item xs={12}>
          <Typography className="cursor_pointer">
            <span onClick={() => navigator(`/MainDistribution`)}>
              Product & Price
            </span>{" "}
            &nbsp;/
            <span>
              <b>&nbsp;Price Discount</b>
            </span>
          </Typography>
        </Grid>
      </Paper>
      {showForm ? (
        <PriceDiscountCreate onBack={handleCreateNew} />
      ) : (
        <Box>
          <Button
            variant="contained"
            className="btn-colr"
            onClick={handleCreateNew}
          >
            {" "}
            <AddIcon /> Create new
          </Button>
          <Grid item sx={{ mt: 2 }}>
            <Typography> Quick Search</Typography>
          </Grid>

          <Grid
            container
            mt={2}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 10 }}
          >
            <Grid item xs={12} sm={6}>
              <Typography className="fnt-lable">Search Based On</Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  className="selct-box"
                >
                  {Options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            mt={1}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 10 }}
          >
            <Grid item xs={12} sm={6}>
              <Typography className="fnt-lable">Entity Type</Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  className="selct-box"
                >
                  {EntityType.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            mt={1}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 10 }}
          >
            <Grid item xs={12} sm={6}>
              <Typography className="fnt-lable">Entity </Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  className="selct-box"
                >
                  {Entity.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            mt={1}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 10 }}
          >
            <Grid item xs={12} sm={6}>
              <Typography className="fnt-lable">
                Retailer Channel/ Sub Channel
              </Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  className="selct-box"
                >
                  {RetailerChannel.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            mt={1}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 10 }}
          >
            <Grid item xs={12} sm={6}>
              <Typography className="fnt-lable">Retailer Group</Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  className="selct-box"
                >
                  {RetailerGroup.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            mt={1}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 10 }}
          >
            <Grid item xs={12} sm={6}>
              <Typography className="fnt-lable">Retailer Class </Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  className="selct-box"
                >
                  {RetailerClass.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              className="btn-apply btn-hgt"
              onClick={handleShowTable}
            >
              <SearchIcon className="fn-icon" /> Go
            </Button>
          </Grid>
          {showTable && (
            <Box className="mar-tb">
              <CommonTable
                // rowSelection={rowSelection}
                data={data}
                columns={columns}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
export default PriceDiscount;
