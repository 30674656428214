import {
  UOM_SUBMIT_FORM,
  PRODUCTUOM_FETCH_DATA,
  PRODUCT_ACTIVE_STATUS,
  PRODUCTUOM_Edit_DATA,
  PRODUCTUOM_UPDATE_DATA,
  PRODUCTUOM_UPDATE_ERROR,
  PRODUCTUOM_SUBMIT_ERROR,
} from "../../actionType";
import { Dispatch } from "redux";
import createAxiosInstance from "../../../hooks/useFetch";
import { ProductUomFormValues } from "../../../typescript/ProductUom";
const axiosInstance = createAxiosInstance();

export interface UomSubmitAction {
  type: typeof UOM_SUBMIT_FORM;
  payload: any;
}
export interface ProductUomGetAction {
  type: typeof PRODUCTUOM_FETCH_DATA;
  payload: any;
}
export interface productActiveStatus {
  type: typeof PRODUCT_ACTIVE_STATUS;
  payload: any;
}
export interface ProductUomEdit {
  type: typeof PRODUCTUOM_Edit_DATA;
  payload: any;
}
export interface ProductUomUpdate {
  type: typeof PRODUCTUOM_UPDATE_DATA;
  payload: any;
}
export interface ProductSubmitErrorAction {
  type: typeof PRODUCTUOM_SUBMIT_ERROR;
  payload: string; // Define the payload type accordingly, assuming error message is a string
}

export interface ProductUpdateErrorAction {
  type: typeof PRODUCTUOM_UPDATE_ERROR;
  payload: string;
}

export const ProductUomSubmitForm =
  (formData: ProductUomFormValues) =>
  async (dispatch: Dispatch<UomSubmitAction | ProductSubmitErrorAction>) => {
    try {
      const response = await axiosInstance.post(`product-uom/create`, formData);
      dispatch({
        type: UOM_SUBMIT_FORM,
        payload: response.data, // Assuming response contains data
      });
      return response.data;
    } catch (error: any) {
      dispatch({
        type: PRODUCTUOM_SUBMIT_ERROR,
        payload: error.response.data.data, // Assuming your error response contains a message field
      });
      throw error;
    }
  };

export const ProductUomFetchData = (requestData: {
  page: number;
  size: number;
}) => {
  return (dispatch: Dispatch<ProductUomGetAction>) => {
    axiosInstance
      .get(`product-uom/get`, {
        params: requestData, // Pass requestData as query parameters
      })
      .then((res) => {
        dispatch({
          type: PRODUCTUOM_FETCH_DATA, // Change this to COMPANY_FETCH_DATA
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};
export const productUomActiveStatus = ({
  id,
  status,
}: {
  id: string;
  status: boolean;
}) => {
  return (dispatch: Dispatch<productActiveStatus>) => {
    axiosInstance
      .put(
        `product-uom/active-in-active`,
        { id, status } // Pass requestData as query parameters
      )
      .then((res) => {
        dispatch({
          type: PRODUCT_ACTIVE_STATUS, // Change this to COMPANY_FETCH_DATA
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};
export const productUomEdit = (id: string) => {
  return (dispatch: Dispatch<ProductUomEdit>) => {
    axiosInstance
      .get(`product-uom/get-byid/${id}`)
      .then((res) => {
        dispatch({
          type: PRODUCTUOM_Edit_DATA, // Dispatch the type of action
          payload: res.data.data, // Pass any data received from the API response
        });
      })
      .catch((error) => {
        // Handle errors if any
      });
  };
};

export const productUomUpdate =
  ({ id, formData }: { id: string; formData: ProductUomFormValues }) =>
  async (dispatch: Dispatch<ProductUomUpdate | ProductUpdateErrorAction>) => {
    try {
      const response = await axiosInstance.put(
        `product-uom/update/${id}`,
        formData
      );
      dispatch({
        type: PRODUCTUOM_UPDATE_DATA,
        payload: response.data, // Assuming response contains data
      });
      return response.data;
    } catch (error: any) {
      dispatch({
        type: PRODUCTUOM_UPDATE_ERROR,
        payload: error.response.data.data,
      });
      throw error;
    }
  };
