import React, { useRef } from "react";

import { Box, Button, Grid, Link, Paper, Typography } from "@mui/material";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../../components/Modal/DeleteSupplier";
import AddIcon from "@mui/icons-material/Add";

import CommonTable from "../../../components/Tables/CommonTable";
import SchemeCreate from "./SchemeCreate";
import SchemeEdit from "./SchemeView";
import SchemeView from "./SchemeView";

interface DataType {
  key: React.Key;
  SchemeCode: string;
  SchemeName: string;
  SchemeBase: string;
  PayoutType: string;
  SchemeStart: string;
  Status: string;
  ApprovalStatus: string;
  Reason: string;
  action: string;
}

const Scheme: React.FC = () => {
  const [isViewing, setIsViewing] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);

  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // seach function here
  };

  const handleCreateNew = () => {
    setShowForm(!showForm);
  };

  const handleEditClick = (event: React.MouseEvent<unknown>) => {
    setIsEditing(!isEditing);
  };

  const handleViewClick = (event: React.MouseEvent<unknown>) => {
    setIsViewing(!isViewing);
  };
  const handleDeleteClick = (event: React.MouseEvent<unknown>) => {
    setIsDelete(!isDelete);
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "SchemeCode",
      title: "Scheme Code",
      // sorter: (a, b) => a.Distributor.length - b.Distributor.length,
    },
    {
      dataIndex: "SchemeName",
      title: " Scheme Name",
      // sorter: (a, b) => a.SchemeName.length - b.BranchCode.length,
    },
    {
      dataIndex: "SchemeBase",
      title: "Scheme Base",
      sorter: (a, b) => a.SchemeBase.length - b.SchemeBase.length,
    },
    {
      dataIndex: "PayoutType",
      title: " Payout Type",
      sorter: (a, b) => a.PayoutType.length - b.PayoutType.length,
    },
    {
      dataIndex: "SchemeStart",
      title: "Scheme Start Date",
      // sorter: (a, b) => a.ContactPerson.length - b.ContactPerson.length,
    },
    {
      dataIndex: "Status",
      title: "Status",
      // sorter: (a, b) => a.ContactPerson.length - b.ContactPerson.length,
    },
    {
      dataIndex: "ApprovalStatus",
      title: "Approval Status",
      // sorter: (a, b) => a.ContactPerson.length - b.ContactPerson.length,
    },
    {
      dataIndex: "Reason",
      title: "Reason ",
      // sorter: (a, b) => a.ContactPerson.length - b.ContactPerson.length,
    },

    {
      dataIndex: "action",
      title: "Action",

      render: (_: any, record: DataType) => (
        <>
          <Grid item sx={{ display: "flex" }}>
            <VisibilityIcon
              onClick={(event) => handleViewClick(event)}
              style={{
                cursor: "pointer",
                fontSize: "1rem",
                color: "#928282de",
              }}
            />
            <Link sx={{ ml: "5px" }}>Clone</Link>
          </Grid>
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      SchemeCode: "SCHFMC160012314",
      SchemeName: "MT NAT FAMILY V-MART 43.86 JAN 24",
      SchemeBase: "	Quantity Based",
      PayoutType: "	Discount %",
      SchemeStart: "31/01/2024",
      Status: "NA",
      ApprovalStatus: "Approved",
      Reason: "",
      action: "",
    },
    {
      key: 2,
      SchemeCode: "SCHFMC160012313",
      SchemeName: "MT NAT FAMILY V-MART 70.21 JAN 24",
      SchemeBase: "	Quantity Based",
      PayoutType: "	Discount %",
      SchemeStart: "31/01/2024",
      Status: "NA",
      ApprovalStatus: "Approved",
      Reason: "",
      action: "",
    },
    {
      key: 3,
      SchemeCode: "  SCHFMC160012312",
      SchemeName: "MT NAT FAMILY V-MART 77.78 JAN 24",
      SchemeBase: "	Quantity Based",
      PayoutType: "	Discount %",
      SchemeStart: "31/01/2024",
      Status: "NA",
      ApprovalStatus: "Approved",
      Reason: "",
      action: "",
    },
    {
      key: 4,
      SchemeCode: " SCHFMC160012311",
      SchemeName: "	MT NAT FAMILY V-MART 33.33 JAN 24",
      SchemeBase: "	Quantity Based",
      PayoutType: "	Discount %",
      SchemeStart: "31/01/2024",
      Status: "NA",
      ApprovalStatus: "Approved",
      Reason: "",
      action: "",
    },
    {
      key: 5,
      SchemeCode: "SCHFMC160012310",
      SchemeName: "MT SAMT CHIK EGG MEERA 340 ML 64.08 JAN 24",
      SchemeBase: "	Quantity Based",
      PayoutType: "	Discount %",
      SchemeStart: "31/01/2024",
      Status: "NA",
      ApprovalStatus: "Approved",
      Reason: "",
      action: "",
    },
    {
      key: 6,
      SchemeCode: " SCHFMC160012309",
      SchemeName: "MT SAMT CHIK EGG MEERA 340 ML 63.46 JAN 24",
      SchemeBase: "	Quantity Based ",
      PayoutType: "	Discount %",
      SchemeStart: "31/01/2024",
      Status: "NA",
      ApprovalStatus: "Approved",
      Reason: "",
      action: "",
    },
  ];
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
      <Grid item xs={12} className="pad-10 mar-tb">
        <Typography className="cursor_pointer">
          <b>Scheme</b>
        </Typography>
      </Grid>

      {showForm ? (
        <SchemeCreate onBack={handleCreateNew} />
      ) : isEditing ? (
        <></>
      ) : // <DistributorEdit onBack={(event) => handleEditClick(event)} />
      isViewing ? (
        <SchemeView onBack={(event) => handleViewClick(event)} />
      ) : (
        <Box>
          <Button
            variant="contained"
            className="btn-colr"
            onClick={handleCreateNew}
          >
            {" "}
            <AddIcon /> Create new
          </Button>
          <Grid item sx={{ mt: 2 }}>
            <Box>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "end", mb: 2 }}
              >
                <SearchBar
                  className="search-table"
                  value={textFieldValue}
                  onChange={(newValue) => {
                    setTextFieldValue(newValue);
                    handleSearch();
                  }}
                />
              </Grid>
              <CommonTable
                // rowSelection={rowSelection}
                data={data}
                columns={columns}
              />

              {isDelete && (
                <DeleteModal onBack={(event) => handleDeleteClick(event)} />
              )}
            </Box>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
export default Scheme;
