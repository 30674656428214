import React, { useEffect, useRef, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import { Grid, Stack, Typography, Skeleton } from "@mui/material";
import { Card } from "@material-ui/core";
import DrawerHeader from "../../../Layout/MainLayout/Drawer/DrawerHeader";
import MainCard from "../../../components/MainCard";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { camelCaseToNormal, decodeJwt } from "../../../utils/constant";
import SearchBar from "@mkyy/mui-search-bar";
import { TagsInput } from "react-tag-input-component";
import type { TableColumnsType } from "antd";
import { Spin, Table, Tooltip } from "antd";
import MiniDrawerStyled from "../../../Layout/MainLayout/Drawer/MiniDrawerStyled";
import { useTheme } from "@mui/material/styles";
import NonSaleableSidebar from "./NonSaleableSidebar";
import NonSaleableProducts from "./NonSaleableProducts";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import {
  getNonSaleableDashboardCounts,
  getNonSaleableProducts,
} from "../../../Redux/XDM/Inventory/nonSaleableReducer";
import { storeAllSeletedData } from "../../../Redux/XDM/Inventory/filterReducer";
import debounce from 'lodash.debounce'

interface DataProps {
  label: string;
  value: any;
}


const roundValue = (label: string, val: number | string): string => {
  if (
    (typeof val === "number" || !isNaN(Number(val))) &&
    label === "stockValue"
  ) {
    return formatIndianNumber(val);
  }
  return val?.toString();
};
const formatIndianNumber = (num: number | string): string => {
  const numStr = num.toString();
  const [integerPart, decimalPart] = numStr.split(".");
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);

  if (otherDigits !== "") {
    lastThreeDigits = "," + lastThreeDigits;
  }

  const formattedIntegerPart =
    otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

  
    return decimalPart
       ? formattedIntegerPart + "." + decimalPart.slice(0, 2)
       : formattedIntegerPart+ ".00";
};

const DataBox: React.FC<DataProps> = ({ label, value }) => {
  const shouldShowIcon = label === "stockValue";
  return (
    <MainCard border={false} boxShadow>
      <Stack
        spacing={1}
        justifyContent="center"
        alignItems="center"
        className={"card-box-counts " + label}
      >
        <Typography variant="h6" className="card-box-title">
          {camelCaseToNormal(label)}
        </Typography>
        <Typography variant="subtitle1" className="card-box-count">
          {shouldShowIcon && <CurrencyRupeeIcon sx={{ fontSize: 20 }} />}
          {roundValue(label, value)}
        </Typography>
      </Stack>
    </MainCard>
  );
};

interface ProductDataType {
  division: string;
  lastPurchaseDate: string;
  nearToexpiry: boolean;
  productCode: string;
  productHierarchy: string;
  productName: string;
  reason: string;
  scheme: boolean;
  stockInHand: number;
  value: number;
}
type TableRowType = ProductDataType | { noMoreData: boolean };

const NonSaleable: React.FC = () => {
  const [textFieldValue, setTextFieldValue] = React.useState<string>("");
  let selected = useAppSelector((state) => state.filterInv.storeAllSeletedData);
  let nonSaleableCounts = useAppSelector(
    (state) => state.nonSaleableData.nonSaleableCounts
  );
  let nonSaleableData = useAppSelector((state) => state.nonSaleableData);
  let saleableProductData = nonSaleableData.nonSaleableProducts;
  const filterTags = useAppSelector((state) => state.filterInv);

  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [isShow, setIsShow] = React.useState(false);
  const theme = useTheme();
  const [products, setProducts] = React.useState<ProductDataType[]>([]);

  const data_sales: ProductDataType[] = products ?? [];
  const filteredCount = saleableProductData.filteredCount ?? 0;

  const data_record: ProductDataType[] = data_sales.map((product) => ({
    nearToexpiry: product.nearToexpiry,
    productCode: product.productCode,
    productName: product.productName,
    productHierarchy: product.productHierarchy,
    division: product.division,
    stockInHand: Number(product.stockInHand), // Convert to number
    value: Number(product.value),
    lastPurchaseDate: product.lastPurchaseDate,
    scheme: product.scheme,
    reason: product.reason,
  }));

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const tableHeightRef = useRef(270);
  const filterData = {
    page: 0,
    size: 0,
    division: filterTags.divisionSelected.toString(),
    segment: filterTags.segmentSelected.toString(),
    brand: filterTags.brandSelected.toString(),
    variant: filterTags.variantSelected.toString(),
    subVariant: filterTags.subVariantSelected.toString(),
    packagingType: filterTags.packagingTypeSelected.toString(),
    productSize: filterTags.sizeSelected.toString(),
    reason: filterTags.reasonSelected.toString(),
    search: textFieldValue,
  };
  useEffect(() => {
    dispatch(getNonSaleableDashboardCounts());
  }, []);
  useEffect(() => {
    setPage(0)
    setHasMore(true);
    setProducts([]);
    loadMoreData(true);
  }, [
    textFieldValue,
    filterTags.divisionSelected,
    filterTags.segmentSelected,
    filterTags.brandSelected,
    filterTags.variantSelected,
    filterTags.subVariantSelected,
    filterTags.packagingTypeSelected,filterTags.reasonSelected,
    filterTags.sizeSelected,
  ]);
  const [commonLoader, setCommonLoader] = useState(true);

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 363;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const loadMoreData = (resetPage = false) => {
    

    updateTableHeight();
    window.addEventListener('resize', updateTableHeight);

    setCommonLoader(true);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    let nextPage = resetPage ? 0 : page;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;

    dispatch(
      getNonSaleableProducts({
        params: { ...filterData, page: nextPage },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const newProducts = response.payload.products || [];
          setProducts((prevProducts) => (resetPage ? newProducts : [...prevProducts, ...newProducts]));
          setPage(nextPage + 1);
          if (newProducts.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);

        } else {
          // setHasMore(false);
        }
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Failed to fetch data:', error);
        }
        setCommonLoader(false);
      });
  };
  const [requestData, setRequestData] = React.useState({
    nearToexpiry: true,
    productCode: "",
    productName: "",
    productHierarchy: "",
    division: "",
    stockInHand: 0,
    value: 0,
    lastPurchaseDate: "",
    scheme: true,
    reason:"",
  });

  const columns: TableColumnsType<TableRowType> = [
    {
      dataIndex: "productName",
      title: "Product Name",
      width: "30%",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
        return a.productName.localeCompare(b.productName);
      },
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } }; // Skip rendering for special row
        // const displayText =
        //   text.length > 45 ? `${text.substring(0, 45)}...` : text;
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text}</span>
          </Tooltip>
        );
      },
    },
    // {
    //   dataIndex: "productHierarchy",
    //   title: "Product Hierarchy",
    //   render: (text: string, record: TableRowType) => {
    //     if ("noMoreData" in record)
    //       return { children: null, props: { colSpan: 0 } }; // Skip rendering for special row
    //     return text;
    //   },
    // },
    {
      dataIndex: "division",
      title: "Division",
      width: "10%",
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } }; // Skip rendering for special row
        return text;
      },
    },
    {
      dataIndex: "stockInHand",
      width: "15%",
      title: "Stock in Hand (In Pcs)",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
        return a.stockInHand - b.stockInHand;
      },
      render: (text: string | number, record: TableRowType) => {
        if ("noMoreData" in record) {
          return {
            children: (
              <span
                className="fs-12 noMore"
                style={{ display: "block", margin: "10px" }}
              >
                <b>No more data found...</b>
              </span>
            ),
            props: {
              colSpan: 4, // Adjust this number based on the total number of columns to span
            },
          };
        }
        return text;
      },
    },
    {
      dataIndex: "value",
      title: "Value",
      width: "15%",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
        return a.value - b.value;
      },
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } }; // Skip rendering for special row
        return (
          <>
           <CurrencyRupeeIcon sx={{ fontSize: 10 }} /> {Number(record.value).toFixed(2)}
          </>
        );
      },
    },
    // {
    //   dataIndex: "reason",
    //   title: "Reason",
    //   sorter: (a, b) => {
    //     if ("noMoreData" in a || "noMoreData" in b) return 0;
    //     return a.reason.localeCompare(b.reason);
    //   },
    //   render: (text: string, record: TableRowType) => {
    //     if ("noMoreData" in record)
    //       return { children: null, props: { colSpan: 0 } }; // Skip rendering for special row
    //     return text ? text :"--";
    //   },
    // },
  ];
  // const dataToShow: TableRowType[] = hasMore ? products : [...products, { noMoreData: true }];
 const dataToShow: TableRowType[] =
   products.length > 0
     ? hasMore
       ? products
       : [...products, { noMoreData: true }]
     : []; 
  

 
  const handleSearch = () => {
    setTextFieldValue(textFieldValue);
  };

  const handleRowClick = (record: ProductDataType) => {
      const updatedData = {
    ...record,
    reason: filterTags.reasonSelected.toString() // Replace 'extraKey' and 'extraValue' with your desired key and value
  };

  setRequestData(updatedData);
    setIsShow(!isShow);
    toggleDrawer(true);
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const handleScroll = useCallback(
    debounce(() => {

      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector('.saleable-table-scroll .ant-table-body') as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);


  return (
    <Stack direction="row" spacing={2} justifyContent="space-between">
      <Box flex={1} p={0}>
        {/* <DrawerHeader /> */}
        <MiniDrawerStyled variant="permanent" open={true} theme={theme}>
          <DrawerHeader />
          <NonSaleableSidebar />
        </MiniDrawerStyled>
      </Box>
      <Box
        flex={8}
        className="margin-left-0px"
        sx={{ overflowY: "auto", height: "100vh", marginBottom: "100px" }}
      >
        <Card className="b-radius-0 main-card">
          <Grid container spacing={3} justifyContent="start" p={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingTop: "2px" }}
            >
              <h3
                style={{
                  marginTop: "0px",
                  marginLeft: "4px",
                  marginBottom: "0px",
                }}
              >
                <b>Overview</b>
              </h3>
            </Grid>
            {nonSaleableData.nonsaleableCountsLoading
              ? Array.from(new Array(2)).map((_, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2.4}
                    key={index}
                    className="mui-pt-0"
                    style={{ paddingTop: "3px" }}
                  >
                    <Skeleton variant="rounded" width="100%" height={81} />
                  </Grid>
                ))
              : Object.entries(nonSaleableCounts).map(([key, value]) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={key}
                    style={{ paddingTop: "3px" }}
                    className="mui-pt-8px"
                  >
                    <DataBox label={key} value={value} />
                  </Grid>
                ))}
          </Grid>
          <Grid container spacing={3} justifyContent="start" p={2}>
            <Grid
              container
              mt={4}
              m={2}
              sx={{
                borderBottom: "1px solid #0000000D",
              }}
            >
              <Grid item xs={6} sm={6} md={8.5} lg={8.5}>
                <h3
                  style={{
                    marginTop: "0px",
                    marginLeft: "8px",
                    marginBottom: "17px",
                  }}
                >
                  Products
                </h3>
              </Grid>
              <Grid item xs={6} sm={6} md={3.5} lg={3.5}>
                <SearchBar
                  className="search_box_saleable w-100px"
                  placeholder="Search based on filtered items"
                  value={textFieldValue}
                   onChange={(newValue) => {
                      setTextFieldValue(newValue);
                    }}
                    onCancelResearch={() => setTextFieldValue("")}
                    onSearch={(newValue) => {
                      handleSearch();
                    }}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} sm={12} md={12} lg={12} className="pt-0">
              {(filterTags.basedOnStockSelected.length !== 0 ||
                filterTags.divisionSelected.length !== 0 ||
                filterTags.segmentSelected.length !== 0 ||
                filterTags.brandSelected.length !== 0 ||
                filterTags.variantSelected.length !== 0 ||
                filterTags.subVariantSelected.length !== 0 ||
                filterTags.reasonSelected.length !== 0 ||
                filterTags.sizeSelected.length !== 0 ||
                filterTags.packagingTypeSelected.length !== 0 ||
                textFieldValue !== "") && (
                <p style={{ marginTop: "0px", marginBottom: "6px" }}>
                  Showing Results based on selected filters{" "}
                  <b>({filteredCount} Products)</b>
                </p>
              )}

              {/* <div onKeyPress={handleKeyPress}>
                <TagsInput
                  value={selected}
                  onChange={handleTagChange}
                  name="filteredTags"
                  placeHolder=""
                  classNames={{
                    input: "border-0px",
                    tag: "filtered-tag-class",
                  }}
                />
              </div> */}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="pad-top-0px">
              <div className="saleable-table-scroll ">
                <Table
                  className={`Header-table saleable-table nonsaleDataTable sale_last_hide scroll_cus_table elipsTable ${
                    commonLoader ? " nodataloader" : ""
                  }`}
                  // rowSelection={rowSelection}
                  columns={columns}
                  dataSource={dataToShow}
                  pagination={false}
                  loading={!page && commonLoader}
                  rowKey={(record) =>
                    "productCode" in record ? record.productCode : "noMoreData"
                  }
                  showSorterTooltip={false}
                  summary={() => {
                    return (
                      <>
                        {page !== 0 && commonLoader && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              index={1}
                              colSpan={4}
                              align="center"
                            >
                              <Spin />
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                      </>
                    );
                  }}
                  onRow={(record: TableRowType) => {
                    if ("noMoreData" in record) return {};
                    return {
                      onClick: () => handleRowClick(record as ProductDataType),
                    };
                  }}
                  scroll={{ y: tableHeightRef.current }}
                />
              </div>
            </Grid>
          </Grid>
        </Card>
        {isShow ? (
          <NonSaleableProducts
            onClose={() => setIsShow(false)}
            requestData={requestData}
          />
        ) : (
          <></>
        )}
      </Box>
    </Stack>
  );
};

export default NonSaleable;