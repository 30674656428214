export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const GET_USER_FROM_LOCALSTORAGE = "GET_USER_FROM_LOCALSTORAGE";
export const LOGOUT = "LOGOUT";
export const SHOW_TOASTER_NOTIFICATION = "SHOW_TOASTER_NOTIFICATION";

//Company form insert
export const COMPANY_SUBMIT_FORM = "COMPANY_SUBMIT_FORM";
export const COMPANY_Submit_ERROR = "COMPANY_Submit_ERROR";
export const COMPANY_FETCH_DATA = "COMPANY_FETCH_DATA";
export const COMPANY_Edit_DATA = "COMPANY_Edit_DATA";
export const COMPANY_UPDATE_DATA = "COMPANY_UPDATE_DATA";
export const COMPANY_UPDATE_ERROR = "COMPANY_UPDATE_ERROR";
export const COMPANY_ACTIVE_STATUS = "COMPANY_ACTIVE_STATUS";
export const COMPANY_ACTIVE_DEFAULT = "COMPANY_ACTIVE_DEFAULT";

//Supplier form submisition
export const SUPPLIER_SUBMIT_FORM = "SUPPLIER_SUBMIT_FORM";
export const SUPPLIER_Submit_ERROR = "SUPPLIER_Submit_ERROR";
export const SUPPLIER_FETCH_DATA = "SUPPLIER_FETCH_DATA";
export const SUPPLIER_ACTIVE_STATUS = "SUPPLIER_ACTIVE_STATUS";
export const SUPPLIER_EDIT_DATA = "SUPPLIER_EDIT_DATA";
export const SUPPLIER_UPDATE_DATA = "SUPPLIER_UPDATE_DATA";
export const SUPPLIER_UPDATE_ERROR = "SUPPLIER_UPDATE_ERROR";
export const GET_DEFAULT_COMPANY = "GET_DEFAULT_COMPANY;";

//Product UOM form submisition
export const UOM_SUBMIT_FORM = "UOM_SUBMIT_FORM";
export const PRODUCTUOM_FETCH_DATA = "PRODUCTUOM_FETCH_DATA";
export const PRODUCT_ACTIVE_STATUS = "PRODUCT_ACTIVE_STATUS";
export const PRODUCTUOM_Edit_DATA = "PRODUCTUOM_Edit_DATA";
export const PRODUCTUOM_UPDATE_DATA = "PRODUCTUOM_UPDATE_DATA";
export const PRODUCTUOM_SUBMIT_ERROR = "PRODUCTUOM_SUBMIT_ERROR";
export const PRODUCTUOM_UPDATE_ERROR = "PRODUCTUOM_UPDATE_ERROR";

// sidebar popup show
export const SIDEBAR_POPUP_SHOW = "SIDEBAR_POPUP_SHOW";

//SideBar Busniess Level Name
export const SIDEBAR_BUSNIESS_LEVEL = "SIDEBAR_BUSNIESS_LEVEL";
export const SIDEBAR_CATEGORY_LEVEL = "SIDEBAR_CATEGORY_LEVEL";
export const SIDEBAR_BRAND_LEVEL = "SIDEBAR_BRAND_LEVEL";
export const SIDEBAR_BRAND_CATEGORY_LEVEL = "SIDEBAR_BRAND_CATEGORY_LEVEL";
export const SIDEBAR_PRODUCT_VARIENT_LEVEL = "SIDEBAR_PRODUCT_VARIENT_LEVEL";
export const SIDEBAR_SKU_GROUP_LEVEL = "SIDEBAR_SKU_GROUP_LEVEL";
export const SIDEBAR_SKU_LEVEL = "SIDEBAR_SKU_LEVEL";
export const SIDEBAR_FILTER_SHOW = "SIDEBAR_FILTER_SHOW";
export const PRODUCT_CODE_NAME = "PRODUCT_CODE_NAME";
export const PRODUCT_HSN_CODE_NAME = "PRODUCT_HSN_CODE_NAME";

//HSN Code
export const HSN_CODE_SUBMIT_FORM = "HSN_CODE_SUBMIT_FORM";
export const HSN_CODE_Submit_ERROR = "HSN_CODE_Submit_ERROR";
export const HSN_CODE_FETCH_DATA = "HSN_CODE_FETCH_DATA";
export const HSN_CODE_EDIT_DATA = "HSN_CODE_EDIT_DATA";
export const HSN_CODE_UPDATE_DATA = "HSN_CODE_UPDATE_DATA";
export const HSN_CODE_UPDATE_ERROR = "HSN_CODE_UPDATE_ERROR";

//Gst State Tax
export const GST_STATE_TAX_SUBMIT_FORM = "GST_STATE_TAX_SUBMIT_FORM";
export const GST_STATE_TAX_Submit_ERROR = "GST_STATE_TAX_Submit_ERROR";
export const GST_STATE_TAX_FETCH_DATA = "GST_STATE_TAX_FETCH_DATA";
export const GST_State_Tax_ACTIVE_STATUS = "GST_State_Tax_ACTIVE_STATUS";
export const GST_STATE_TAX_EDIT_DATA = "GST_STATE_TAX_EDIT_DATA";
export const GST_STATE_TAX_UPDATE_DATA = "GST_STATE_TAX_UPDATE_DATA";
export const GST_STATE_TAX_UPDATE_ERROR = "GST_STATE_TAX_UPDATE_ERROR";

//Product master
export const PRODUCT_MASTER_SUBMIT_FORM = "PRODUCT_MASTER_SUBMIT_FORM";
export const PRODUCT_MASTER_SUBMIT_ERROR = "PRODUCT_MASTER_SUBMIT_ERROR";
export const PRODUCT_MASTER_FETCH_DATA = "PRODUCT_MASTER_FETCH_DATA";
export const PRODUCT_MASTER_EDIT_DATA = "PRODUCT_MASTER_EDIT_DATA";
export const PRODUCT_MASTER_UPDATE_DATA = "PRODUCT_MASTER_UPDATE_DATA";
export const PRODUCT_MASTER_UPDATE_ERROR = "PRODUCT_MASTER_UPDATE_ERROR";

//Product Tax Master
export const PRODUCT_TAX_SUBMIT_FORM = "PRODUCT_TAX_SUBMIT_FORM";
export const PRODUCT_TAX_Submit_ERROR = "PRODUCT_TAX_Submit_ERROR";
export const PRODUCT_TAX_FETCH_DATA = "PRODUCT_TAX_FETCH_DATA";
export const PRODUCT_Tax_ACTIVE_STATUS = "PRODUCT_Tax_ACTIVE_STATUS";
export const PRODUCT_TAX_EDIT_DATA = "PRODUCT_TAX_EDIT_DATA";
export const PRODUCT_TAX_UPDATE_DATA = "PRODUCT_TAX_UPDATE_DATA";
export const PRODUCT_TAX_UPDATE_ERROR = "PRODUCT_TAX_UPDATE_ERROR";
export const PRODUCT_TAX_FETCH_PRODUCT_NAME = "PRODUCT_TAX_FETCH_PRODUCT_NAME";

//Product hierarchy
export const PRODUCT_HIERARCHY_SUBMIT_FORM = "PRODUCT_HIERARCHY_SUBMIT_FORM";
export const PRODUCT_HIERARCHY_SUBMIT_ERROR = "PRODUCT_HIERARCHY_SUBMIT_ERROR";
export const PRODUCT_HIERARCHY_FETCH_DATA = "PRODUCT_HIERARCHY_FETCH_DATA";
export const PRODUCT_HIERARCHY_EDIT_DATA = "PRODUCT_HIERARCHY_EDIT_DATA";
export const PRODUCT_HIERARCHY_UPDATE_DATA = "PRODUCT_HIERARCHY_UPDATE_DATA";
export const PRODUCT_HIERARCHY_UPDATE_ERROR = "PRODUCT_HIERARCHY_UPDATE_ERROR";
export const PRODUCT_HIERARCHY_ACTIVE_STATUS =
  "PRODUCT_HIERARCHY_ACTIVE_STATUS";

// subHeader hidden
export const SUBHEADER_HIDDEN = "SUBHEADER_HIDDEN";

// Inventory
export const SIDEBAR_FILTER_SHOW_INV = "SIDEBAR_FILTER_SHOW_INV";
