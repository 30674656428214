import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import UndoIcon from "@mui/icons-material/Undo";

import Close from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";




interface AddLevelsPopupProps {
  handleClose: () => void; // A function that takes no arguments and returns void
}

const AddLevelsPopup = ({
  handleClose,
}: AddLevelsPopupProps) => {
  const options = ["Supplier Code", "Supplier Name"];

  const option = [
    "PYC1-Rohit India Private limited-KARAIKAL",
    "BRD1-CavinKare Pvt. Ltd, PAT DEPOT-PATNA",
    "MHT1-CavinKare Pvt. Ltd., MUM COTY-BHIWANDI",
  ];
  const rows = [
    {
      id: "GJD1",
      Name: "CavinKare Pvt. Ltd, AHM DEPOT",
      GeoMapping: "AHMEDABAD-1",
      IsDefault: "",
      action: "",
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Code",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "Name",
      headerName: "Name",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "GeoMapping",
      headerName: "Geo Mapping",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },

    {
      field: "IsDefault",
      headerName: "Is Default",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: () => (
        <>
          <FormGroup>
            <FormControlLabel
              label=""
              control={<Checkbox defaultChecked className="check-box" />}
            />
          </FormGroup>
        </>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: () => (
        <>
          <DeleteIcon
            style={{
              cursor: "pointer",
              marginRight: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />
        </>
      ),
    },
  ];



  const [isVisible, setIsVisible] = useState(false);
  const Visibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <Box>
      <form>
        <Grid
          item
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item>Add Levels</Grid>
          <IconButton aria-label="close" onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
        <Divider />

        <Grid container mt={3} rowSpacing={3} columnSpacing={{ xs: 3, sm: 10 }}>
          <Grid item xs={12} sm={12}>
            <Typography className="fnt-lable"> 
Product Hierarchy Level </Typography>
            <TextField placeholder="  " className="Txt-box" />
            <Button sx={{ color: "#fff", ml: 3 }} className="btn-colr">
              ...
            </Button>
          </Grid>
        </Grid>
        
        <Grid container mt={3} rowSpacing={3} columnSpacing={{ xs: 3, sm: 10 }}>
          <Grid item xs={12} sm={12}>
            <Typography className="fnt-lable"> 
            Product Hierarchy Level Value </Typography>
            <TextField placeholder="  " className="Txt-box" />
            <Button sx={{ color: "#fff", ml: 3 }} className="btn-colr">
              ...
            </Button>
          </Grid>
        </Grid>
        {isVisible && (
          <Grid item mt={2}>
            <Grid container columnGap={2}>
              <Grid item style={{ height: "100%", width: "100%" }}>
              
              </Grid>
            </Grid>
          </Grid>
         )}
        <Grid item mt={3} sx={{ display: "flex", justifyContent: "center" }}>
          <Button className="btn-colr " variant="contained" sx={{ mr: "10px" }}>
            Ok
          </Button>
          <Button
            className="next-button"
            variant="outlined"
            onClick={handleClose}
          >
            <UndoIcon
              sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
            />
            Cancle
          </Button>
        </Grid>
      </form>
    </Box>
  );
};
export default AddLevelsPopup;
