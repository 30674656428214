import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Drawer,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Close from "../../../assets/images/icons/Close_icon.png";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { ReactComponent as LowStockSVG } from "../../../assets/images/icons/low_stock_transfarant.svg";
import type { TableColumnsType } from "antd";
import { Spin, Table, Tooltip } from "antd";
import { decodeJwt } from "../../../utils/constant";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import { getSaleableProductDetails } from "../../../Redux/XDM/Inventory/saleableReducer";
import { getNonSaleableProductsList } from "../../../Redux/XDM/Inventory/nonSaleableReducer";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { Add, ArrowBackIos, Remove } from "@mui/icons-material";
import SearchBar from "@mkyy/mui-search-bar";
import { RemoveCircleOutline } from "@mui/icons-material";
import {
  deleteProductData,
  getorderDetails,
  updateProductQuantity,
} from "../../../Redux/XDM/Sales/orderBillingReducer";
import LoadingButton from "@mui/lab/LoadingButton";
import vectorImage from "../../../assets/images/icons/Vector_low_stock.png"
import { IoClose } from "react-icons/io5";

interface RequestData {
  orderId: string;
  date: string;
  outlet: string;
  route: string;
  salesman: string;
  netAmount: number;
}

const LowStockIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    component={LowStockSVG}
    fill="#F59D33"
    viewBox="0 0 24 24"
  />
);

interface ReviewOrderDetailsProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
}
interface ReviewOrderDetails {
  productName: string;
  productHierarchy: string;
  grossAmount: number;
  schemeAmount: number;
  netAmount: number;
  quantity: number;
  freeQuantity: number;
  uom: string;
  availableQuantity: number;
  lowStockOrder: boolean;
  sellRate: number;
}
interface updateQuantityData {
  orderId: string;
  productCode: string[];
  quantity: number[];
}
export default function OutletDetails(props: ReviewOrderDetailsProps) {
  const { onClose, requestData } = props; // Destructure the props
  const dispatch = useAppDispatch();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  let orderData = useAppSelector((state) => state.orderBillingData);
  let orderDetails = orderData.orderDetailsData;
  //   let nonSaleableData = useAppSelector((state) => state.nonSaleableData);
  //   const [hasMore, setHasMore] = useState(true);
  //   const [commonLoader, setCommonLoader] = useState(true);
  //   const [page, setPage] = useState(0);
  //   const [products, setProducts] = React.useState<StockDetail[]>([]);
  const [dataRecordOrders, setDataRecordOrders] = useState<
    ReviewOrderDetails[]
  >([
    // Add more records as needed
  ]);
  const [newQuantityRecords, setNewQuantityRecords] = useState<
    ReviewOrderDetails[]
  >([
    // Add more records as needed
  ]);
  const [productCodeDatas, setProductCodeDatas] = useState<
    ReviewOrderDetails[]
  >([
    // Add more records as needed
  ]);


  useEffect(() => {
    // Dispatch the action to fetch data when the component mounts
    dispatch(getorderDetails(requestData.orderId))
      .then((response) => {
        // Update the state with the fetched data
        setDataRecordOrders(response.payload);
        setFilteredDataRecordOrders(response.payload); 
      })
      .catch((error) => {
        console.error("Failed to fetch order details:", error);
      });
    setTextFieldValue("");

  }, [dispatch, requestData.orderId]);

  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const handleDeleteClick = (index: number) => {
    setDeleteIndex(index);
  };

  const handleDeleteConfirm = () => {
    if (deleteIndex !== null) {
      const orderToDelete = dataRecordOrders[deleteIndex];

      const ProductDetails = {
        orderId: requestData?.orderId,
        productCode: orderToDelete.productHierarchy,
        productName :orderToDelete?.productName,
      };

      dispatch(deleteProductData(ProductDetails))
        .then(() => {
          setDeleteIndex(null);
          setSnackbarMessage(ProductDetails.productName+" has been successfully removed from the Order ID:"+requestData?.orderId);
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          // Fetch updated order details
          dispatch(getorderDetails(requestData.orderId))
            .then((response) => {
              setDataRecordOrders(response.payload);
              setFilteredDataRecordOrders(response.payload); 
            })
            .catch((error) => {
              console.error("Failed to fetch updated order details:", error);
            });
        })
        .catch((error) => {
          console.error("Failed to delete product:", error);
          setSnackbarMessage("Data Deleted Failed!");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        });
    }
  };

  const handleDeleteCancel = () => {
    setDeleteIndex(null);
  };
const handleQuantityChange = (
  index: number,
  newQuantity: number,
  productCode: string
) => {
  // Find the correct item in dataRecordOrders
  const updatedDataRecordOrders = dataRecordOrders.map((order) => {
    if (order.productHierarchy === productCode) {
      const newGrossAmount = newQuantity * (order.sellRate || 0);
      const newNetAmount = newGrossAmount + (order.schemeAmount || 0);

      return {
        ...order,
        quantity: newQuantity,
        lowStockOrder: newQuantity > order.availableQuantity,
        grossAmount: newGrossAmount,
        netAmount: newNetAmount,
      };
    }
    return order;
  });

  // Update dataRecordOrders
  setDataRecordOrders(updatedDataRecordOrders);

  // Find the correct item in filteredDataRecordOrders
  const updatedFilteredDataRecordOrders = filteredDataRecordOrders.map(
    (order) => {
      if (order.productHierarchy === productCode) {
        const newGrossAmount = newQuantity * (order.sellRate || 0);
        const newNetAmount = newGrossAmount + (order.schemeAmount || 0);

        return {
          ...order,
          quantity: newQuantity,
          lowStockOrder: newQuantity > order.availableQuantity,
          grossAmount: newGrossAmount,
          netAmount: newNetAmount,
        };
      }
      return order;
    }
  );

  // Update filteredDataRecordOrders
  setFilteredDataRecordOrders(updatedFilteredDataRecordOrders);

  // Update newQuantityRecords
  setNewQuantityRecords((prevState) => {
    const updatedOrders = [...prevState];
    const recordIndex = updatedOrders.findIndex(
      (order) => order.productHierarchy === productCode
    );

    if (recordIndex !== -1) {
      updatedOrders[recordIndex] = {
        ...updatedOrders[recordIndex],
        quantity:
          newQuantity >= 0 ? newQuantity : updatedOrders[recordIndex]?.quantity,
        lowStockOrder:
          newQuantity > updatedOrders[recordIndex]?.availableQuantity,
        productHierarchy: productCode,
      };
    } else {
      updatedOrders.push({
        productHierarchy: productCode,
        quantity: newQuantity,
        lowStockOrder:
          newQuantity > updatedOrders[recordIndex]?.availableQuantity,
        productName: updatedOrders[recordIndex]?.productName,
        grossAmount: updatedOrders[recordIndex]?.grossAmount,
        schemeAmount: updatedOrders[recordIndex]?.schemeAmount,
        netAmount: updatedOrders[recordIndex]?.netAmount,
        availableQuantity: updatedOrders[recordIndex]?.availableQuantity,
        uom: updatedOrders[recordIndex]?.uom,
        sellRate: updatedOrders[recordIndex]?.sellRate,
        freeQuantity: updatedOrders[recordIndex]?.freeQuantity
      });
      
    }
    return updatedOrders;
  });
};


  const handleSaveQuantity = async () => {
    setButtonLoader(true);
    try {

      // const productCodes = productCodeDatas.map(
      //   (order) => order.productHierarchy
      // );
      const quantities = dataRecordOrders.map((order) => order.quantity);
      const products = dataRecordOrders.map((order) => order.productHierarchy);

      const payload: updateQuantityData = {
        orderId: requestData.orderId,
        productCode: products,
        quantity: quantities,
      };
      const resultAction = await dispatch(updateProductQuantity(payload));
      if (updateProductQuantity.fulfilled.match(resultAction)) {
        setSnackbarMessage("Data Updated successfully!");
        dispatch(getorderDetails(requestData.orderId))
          .then((response) => {

            setDataRecordOrders(response.payload);
            setFilteredDataRecordOrders(response.payload);
          })
          .catch((error) => {
            console.error("Failed to fetch updated order details:", error);
          });

        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setButtonLoader(false);
        setNewQuantityRecords([]);
        setProductCodeDatas([]);
        // onClose();
      } else {
        setSnackbarMessage("Data Updated Failed!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setButtonLoader(false);
      }

      // Optionally, refresh order details after update
    } catch (error) {
      setButtonLoader(false);
      console.error("Error updating quantities:", error);
      // Handle error appropriately
    }
  };
  const formatDate = (date: string) => {
    if (date) {
      const dateObj = moment(date, "YYYY-MM-DD");
      return dateObj.format("DD/MM/YYYY");
    }
    return "-";
  };
  const reviewOrderColumns: TableColumnsType<ReviewOrderDetails> = [
    {
      dataIndex: "lowStockOrder",
      title: "",
      width: "4%",
      render: (_: any, record: ReviewOrderDetails) => {
        let classes = "near-to-expiry-column";
        if (record.lowStockOrder) {
          classes += " low-stock";
        }

        return (
          <div className={`w-100px ${classes}`}>
            {record.lowStockOrder && (
              <>
                <img src={vectorImage} width={"5px"} alt="Low Stock" />
              </>
            )}
          </div>
        );
      },
    },
    {
      dataIndex: "productName",
      title: "Product Name",
      width: "15%",
      ellipsis: true,
      render: (text: string) => {
        // const displayText =
        //   text.length > 30 ? `${text.substring(0, 30)}...` : text;
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "productHierarchy",
      title: "Product Hierarchy",
      width: "10%",
      ellipsis: true,
      render: (text: string) => {
        const displayText =
          text.length > 25 ? `${text.substring(0, 25)}...` : text;
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "grossAmount",
      title: "Gross Amount in ₹",
      width: "11%",
      render: (value) => value?.toFixed(2),
    },
    {
      dataIndex: "schemeAmount",
      title: "Scheme Amount in ₹",
      width: "12%",
      render: (value) => 0, // Adjust based on the structure of your data
    },
    {
      dataIndex: "netAmount",
      title: "Net Amount in ₹",
      width: "10%",
      render: (value) => value?.toFixed(2),
    },
    {
      dataIndex: "quantity",
      width: "8%",
      title: "Quantity",
      render: (text: any, record: ReviewOrderDetails, index: number) => (
        <Box
          display="flex"
          alignItems="center"
          sx={{ border: "1px solid #ececec" }}
        >
          <IconButton
            onClick={() =>
              handleQuantityChange(
                index,
                record.quantity - 1,
                record.productHierarchy
              )
            }
            disabled={record.quantity <= 0}
            sx={{ height: 21, width: 20 }}
          >
            <Remove sx={{ color: "#7e57c2", fontSize: "14px" }} />
          </IconButton>
          <TextField
            value={record.quantity.toString().padStart(2, "0")}
            inputProps={{
              style: {
                textAlign: "center",
                width: 30,
                height: 21,
                fontSize: "10px",
                background: "#F6F6F6",
                border: "none",
                outline: "none",
                padding: 0,
              },
            }}
            onChange={(e) =>
              handleQuantityChange(
                index,
                Number(e.target.value),
                record.productHierarchy
              )
            }
            sx={{
              "& .MuiInputBase-root": {
                borderRadius: 1,
                "&:hover": {
                  backgroundColor: "#ffffff",
                },
              },
              "& .MuiOutlinedInput-notchedOutline": { border: "none" },
            }}
          />
          <IconButton
            sx={{ height: 21, width: 20 }}
            onClick={() =>
              handleQuantityChange(
                index,
                record.quantity + 1,
                record.productHierarchy
              )
            }
          >
            <Add sx={{ color: "#7e57c2", fontSize: "14px" }} />
          </IconButton>
        </Box>
      ),
    },
    {
      dataIndex: "freeQuantity",
      title: "Free Quantity",
      width: "8%",
    },
    {
      dataIndex: "uom",
      title: "UOM",
      width: "5%",
    },
    {
      dataIndex: "availableQuantity",
      title: "Available Quantity",
      width: "15%",
    },
    {
      title: "",
      width: "5%",
      render: (_: any, record: ReviewOrderDetails, index) => (
        <RemoveCircleOutline
          sx={{ fontSize: "14px", color: "red" }}
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick(index);
          }}
        />
      ),
    },
  ];
 
  const [filteredDataRecordOrders, setFilteredDataRecordOrders] = useState<
    ReviewOrderDetails[]
  >([
    // Add more records as needed
  ]);
  useEffect(() => {
    const filteredData = dataRecordOrders.filter((order) => {
      const searchTermLower = textFieldValue.toLowerCase();
      return (
        order.productName.toLowerCase().includes(searchTermLower) ||
        order.productHierarchy.toLowerCase().includes(searchTermLower)
      );
    });

    setFilteredDataRecordOrders(filteredData);
  }, [textFieldValue]);
const tableHeightRef= useRef(160);
const updateTableHeight = () => {
  const headerHeight = 0;
  const footerHeight = 420;
  const availableHeight = window.innerHeight - headerHeight - footerHeight;

  tableHeightRef.current = availableHeight;
};
  const handleSearch = () => {
    setTextFieldValue(textFieldValue);
  };
const loadMoreData = (resetPage = false) => {
  updateTableHeight();
  window.addEventListener('resize', updateTableHeight);

}
const totalNetAmount = dataRecordOrders.reduce(
  (sum, record) => sum + record.netAmount,
  0
);
useEffect(() => {
  loadMoreData();
},[])

const dateObj = moment(requestData.date, "YYYY-MM-DD");

const formattedDate = dateObj.format("DD MMM YYYY");



  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_outlet w-100px"
        // variant="persistent"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            style={{ top: "95px" }}
            color="green"
            sx={{
              width: {
                xs: "90%",   
                sm: "40%",  
                md: "30%",   
                lg: "25%",   
              },
              marginTop: "10px",
            }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={() => setSnackbarOpen(false)}
          >
            <Alert
              onClose={() => setSnackbarOpen(false)}
              severity={snackbarSeverity}
              className="Alert_orderBilling"
              sx={{ width: "100%",color:"green",fontWeight:"600",fontSize:"10px"}}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
          {/* Heading */}
          <Grid item sx={{ width: "100%" }}>
            <Grid container className="crt-header" mt={1} mb={0} p={2} pb={0}>
              <Typography gutterBottom className="outlet-header-lable">
                <ArrowBackIos
                  sx={{
                    fontSize: "10px",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    onClose();
                  }}
                />
                Review Order
              </Typography>
            </Grid>
          </Grid>
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form saleable-products-box"
          >
            {" "}
            <Grid
              item
              sx={{
                width: "100%",
                margin: "auto",
                marginBottom: "90px",
                padding: "0px 20px",
              }}
            >
              <Grid container mt={1}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Order Id
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={0.5}>
                    {requestData.orderId ? requestData.orderId : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Outlet Name
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={0.5}>
                    {requestData.outlet ? requestData.outlet : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Route Name
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={0.5}>
                    {requestData.route ? requestData.route : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Salesman Name
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={0.5}>
                    {requestData.salesman ? requestData.salesman : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Net Amount in <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={0.5}>
                    {totalNetAmount?.toFixed(2) ? totalNetAmount?.toFixed(2) : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Date
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={0.5}>
                    {formattedDate ? formattedDate : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container mt={2}>
                <Grid item xs={6} sm={6} md={9.5} lg={9.5}>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {/* Recent Invoices */}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={2.5} lg={2.5}>
                  <SearchBar
                    className="search_box_saleable w-100px"
                    placeholder="Search"
                    value={textFieldValue}
                    onChange={(newValue) => {
                        setTextFieldValue(newValue);
                      }}
                      onCancelResearch={() => setTextFieldValue("")}
                      onSearch={(newValue) => {
                        handleSearch();
                      }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="pad-top-0px"
                >
                  <div
                    className="saleable-table-scroll revieworder pad_tabRt"
                    id="tableScrollHalfPop"
                    style={{ height: tableHeightRef.current }}
                  >
                    <Table
                      className={`Header-table saleable-table ${
                        orderData.loading ? " nodataloader" : ""
                      }`}
                      // rowSelection={rowSelection}
                      columns={reviewOrderColumns}
                      dataSource={filteredDataRecordOrders}
                      pagination={false}
                      rowClassName={(record) =>
                        record.lowStockOrder ? "low-stock-row" : ""
                      }
                      // scroll={{y:tableHeightRef.current}}
                      // scroll={{ x: true }}
                      loading={orderData.loading}
                      showSorterTooltip={false}
                      // onRow={(record) => ({
                      //   onClick: () => handleRowClick(record),
                      // })}
                    />
                  </div>
                </Grid>
              </Grid>
              <hr className="hr-revieworder"></hr>
              <Grid container mt={2} sx={{ justifyContent: "end" }}>
                <Grid item sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    className="crt-btn-cancel"
                    style={{
                      height: "30px",
                      width: "40%",
                    }}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    {" "}
                    Cancel
                  </Button>{" "}
                  <LoadingButton
                    variant="contained"
                    loadingPosition="start"
                    loading={buttonLoader}
                    className={`${
                      newQuantityRecords.length > 0 ? "crt-btn-colr" : ""
                    }`}
                    style={{ height: "30px", width: "40%" }}
                    type="submit"
                    sx={{ mr: 2 }}
                    onClick={handleSaveQuantity}
                    disabled={newQuantityRecords.length !== 0 ? false : true}
                  >
                    {" "}
                    save
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Dialog
          open={deleteIndex !== null}
          onClose={handleDeleteCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ width: "333px", padding: "12px 16px" }}>
            <Box
              className="close_icon_btn cursor_pointer"
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
              onClick={handleDeleteCancel}
            >
              <IoClose className="ClosePop" />
            </Box>
            <DialogContent sx={{ width: "400px", padding: "0px 0px" }}>
              <DialogContentText
                className="dialog-title-remove"
                id="alert-dialog-description"
                style={{ paddingBottom: "10px" }}
              >
                Are you sure you want to remove?
              </DialogContentText>
              <DialogContentText
                id="alert-dialog-description"
                style={{ fontSize: "12px" }}
              >
                Note:The following line item will be removed.
              </DialogContentText>
            </DialogContent>
          </DialogContent>
          <DialogActions>
            <Button
              className="crt-btn-cancel"
              style={{
                height: "30px",
                width: "25%",
                marginRight: "3px",
                fontSize: "12px",
              }}
              onClick={handleDeleteCancel}
            >
              {" "}
              Cancel
            </Button>
            <Button
              variant="contained"
              className="crt-btn-colr"
              style={{ height: "30px", width: "25%", fontSize: "12px" }}
              type="submit"
              sx={{ mr: 2 }}
              onClick={handleDeleteConfirm}
            >
              {" "}
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      </Drawer>
    </Box>
  );
}