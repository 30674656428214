import * as React from 'react';
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { InputLabel, Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TableRadioDataTable from '../../../components/Modal/TablewithRadio'
import TableSearchDataTable from '../../../components/Modal/TableSearchModal'
import { Input } from 'antd';

const { Search } = Input;

interface DataType {
  key: React.Key;
  stateCode: number;
  stateName: string;
}
interface DataTypeLob {
  key: React.Key;
  lobCode: number;
  lobName: string;
}
interface DataTypeFDis {
  key: React.Key;
  disCode: number;
  disName: string;

}
interface DataTypeRoute {
  key: React.Key;
  routeCode: number;
  routeName: string;
}
interface DataTypeSalesman {
  key: React.Key;
  salesmanCode: number;
  salesmanName: string;
}
//SatetSelectionData
const DataStateSelection: DataType[] = [
  {
    key: '1',
    stateCode: 123456,
    stateName: 'Assam',
  },
  {
    key: '2',
    stateCode: 123456,
    stateName: 'Delhi',
  },
  {
    key: '3',
    stateCode: 123456,
    stateName: 'Behar',
  },

];
//Lob
const DataLob: DataTypeLob[] = [
  {
    key: '1',
    lobCode: 123456,
    lobName: 'Assam',
  },
  {
    key: '2',
    lobCode: 123456,
    lobName: 'Delhi',
  },
  {
    key: '3',
    lobCode: 123456,
    lobName: 'Behar',
  },

];
//From Distributor
const DataFDistibutor: DataTypeFDis[] = [
  {
    key: '1',
    disCode: 123456,
    disName: 'Assam',
  },
  {
    key: '2',
    disCode: 123456,
    disName: 'Delhi',
  },
  {
    key: '3',
    disCode: 123456,
    disName: 'Behar',
  },

];
//Route 
//SatetSelectionData
const DataRoute: DataTypeRoute[] = [
  {
    key: '1',
    routeCode: 123456,
    routeName: 'Assam',
  },
  {
    key: '2',
    routeCode: 123456,
    routeName: 'Delhi',
  },
  {
    key: '3',
    routeCode: 123456,
    routeName: 'Behar',
  },

];
//Salesman 
const DataSalesMan: DataTypeSalesman[] = [
  {
    key: '1',
    salesmanCode: 123456,
    salesmanName: 'Assam',
  },
  {
    key: '2',
    salesmanCode: 123456,
    salesmanName: 'Delhi',
  },
  {
    key: '3',
    salesmanCode: 123456,
    salesmanName: 'Behar',
  },

];
const CustomerMigration: React.FC = () => {
  const navigator = useNavigate()
  const [selectedOption, setSelectedOption] = React.useState('');
  const [showMigrationDueTo, setShowMigrationDueTo] = React.useState(false);

  const GroupOptions = ['tcs group', 'geo level', 'geo level value'];
  const ClassOptions = ['AA', 'BB', 'cc', 'dd'];

  const mandatoryhandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    setShowMigrationDueTo(event.target.value === 'customerMigration');
  };
  //LOB modal
  const [openLobionModel, setOpenLobModel] = React.useState(false);
  const HandleLobClick = (event: React.MouseEvent<unknown>) => {
    setOpenLobModel(!openStateSelectionModel);
  };
  const [originalDataLob, setOriginalDataLob] = React.useState(DataLob);
  const [filteredDataLob, setFilteredDataLob] = React.useState(originalDataLob); // Initialize with initial data
  const handleFilterChangeLob = (value: string, dataIndex: keyof DataTypeLob) => {
    if (value === "") {
      setFilteredDataLob(originalDataLob);
      return;
    } else {
      const newData = originalDataLob.filter(item =>
        String(item[dataIndex]).toLowerCase().includes(value.toLowerCase())
      );
      setFilteredDataLob(newData);
    }
  };

  const columnsLob = [
    {
      title: (
        <Grid container spacing={1} flexDirection={'row'}>
          <Grid item xs={12}>
            <Typography>LOB Code</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={e => handleFilterChangeLob(e.target.value, 'lobCode')}
            />
          </Grid>
        </Grid>
      ),
      dataIndex: 'lobCode',
      key: 'lobCode',
    },
    {
      title: (
        <Grid container spacing={1} flexDirection={'row'}>
          <Grid item xs={12}>
            <Typography>LOB Name</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={e => handleFilterChangeLob(e.target.value, 'lobName')}
            />
          </Grid>
        </Grid>
      ),
      dataIndex: 'lobName',
      key: 'lobName',
    },

  ]
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const onSelectChangeLob = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // Checkbox row selection
  const rowSelectionLob = {
    selectedRowKeys,
    onChange: onSelectChangeLob,// Initialize if needed

  };


  //SatetSelection modal
  const [openStateSelectionModel, setOpenStateSelectionModel] = React.useState(false);

  const HandleStateSelectionClick = (event: React.MouseEvent<unknown>) => {
    setOpenStateSelectionModel(!openStateSelectionModel);
  };
  const [originalDataStateSelection, setOriginalDataStateSelection] = React.useState(DataStateSelection);
  const [filteredDataStateSelection, setFilteredDataStateSelection] = React.useState(originalDataStateSelection); // Initialize with initial data
  const handleFilterChangeStateSelection = (value: string, dataIndex: keyof DataType) => {
    const newData = originalDataStateSelection.filter(item =>
      String(item[dataIndex]).toLowerCase().includes(value.toLowerCase())
    );
    setFilteredDataStateSelection(newData);
  };
  const columnsForStateSelection = [
    {
      title: (
        <Grid container spacing={1} flexDirection={'row'}>
          <Grid item xs={12}>
            <Typography>StateCode</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={e => handleFilterChangeStateSelection(e.target.value, 'stateCode')}
            />
          </Grid>
        </Grid>
      ),
      dataIndex: 'stateCode',
      key: 'stateCode',
    },
    {
      title: (
        <Grid container spacing={1} flexDirection={'row'}>
          <Grid item xs={12}>
            <Typography>StateName</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={e => handleFilterChangeStateSelection(e.target.value, 'stateName')}
            />
          </Grid>
        </Grid>
      ),
      dataIndex: 'stateName',
      key: 'stateName',
    },

  ]
  // Checkbox row selection
  const [selectionType, setSelectionType] = React.useState<'radio' | 'checkbox'>('radio');
  const [selectedRowKeysState, setSelectedRowKeysState] = React.useState<React.Key[]>(['false']);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    type: selectionType, // Assign the value of selectionType
    selectedRowKeysState,
    onChange: onSelectChange,// Initialize if needed

  };

  //From Distributor

  const [openFDistributorModel, setOpenFDistributorModel] = React.useState(false);

  const HandleFDistibutorClick = (event: React.MouseEvent<unknown>) => {
    setOpenFDistributorModel(!openStateSelectionModel);
  };
  const [originalDataFDistibutor, setOriginalDataFDistibutor] = React.useState(DataFDistibutor);
  const [filteredDataFDistibutor, setFilteredDataFDistibutor] = React.useState(originalDataFDistibutor); // Initialize with initial data
  const handleFilterChangeFDistibutor = (value: string, dataIndex: keyof DataTypeFDis) => {
    const newData = originalDataFDistibutor.filter(item =>
      String(item[dataIndex]).toLowerCase().includes(value.toLowerCase())
    );
    setFilteredDataFDistibutor(newData);
  };
  const columnsForFDistibutor = [
    {
      title: (
        <Grid container spacing={1} flexDirection={'row'}>
          <Grid item xs={12}>
            <Typography>DistributorCode</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={e => handleFilterChangeFDistibutor(e.target.value, 'disCode')}
            />
          </Grid>
        </Grid>
      ),
      dataIndex: 'disCode',
      key: 'disCode',
    },
    {
      title: (
        <Grid container spacing={1} flexDirection={'row'}>
          <Grid item xs={12}>
            <Typography>DistributorName</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={e => handleFilterChangeFDistibutor(e.target.value, 'disName')}
            />
          </Grid>
        </Grid>
      ),
      dataIndex: 'disName',
      key: 'disName',
    },

  ]

  //Route 
  const [openRouteModel, setOpenRouteModel] = React.useState(false);

  const HandleRouteClick = (event: React.MouseEvent<unknown>) => {
    setOpenFDistributorModel(!openStateSelectionModel);
  };
  const [originalDataRoute, setOriginalDataRoute] = React.useState(DataRoute);
  const [filteredDataRoute, setFilteredDataRoute] = React.useState(originalDataRoute); // Initialize with initial data
  const handleFilterChangeRoute = (value: string, dataIndex: keyof DataTypeRoute) => {
    const newData = originalDataRoute.filter(item =>
      String(item[dataIndex]).toLowerCase().includes(value.toLowerCase())
    );
    setFilteredDataRoute(newData);
  };
  const columnsForRoute = [
    {
      title: (
        <Grid container spacing={1} flexDirection={'row'}>
          <Grid item xs={12}>
            <Typography>RouteCode</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={e => handleFilterChangeRoute(e.target.value, 'routeCode')}
            />
          </Grid>
        </Grid>
      ),
      dataIndex: 'routeCode',
      key: 'routeCode',
    },
    {
      title: (
        <Grid container spacing={1} flexDirection={'row'}>
          <Grid item xs={12}>
            <Typography>RouteName</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={e => handleFilterChangeRoute(e.target.value, 'routeName')}
            />
          </Grid>
        </Grid>
      ),
      dataIndex: 'routeName',
      key: 'routeName',
    },

  ]

  //Saleman
  const [openSalesmanModel, setOpenSalesmanModel] = React.useState(false);

  const HandleSalesmanClick = (event: React.MouseEvent<unknown>) => {
    setOpenFDistributorModel(!openStateSelectionModel);
  };
  const [originalDataSalesMan, setOriginalDataSalesMan] = React.useState(DataSalesMan);
  const [filteredDataSalesMan, setFilteredDataSalesMan] = React.useState(originalDataSalesMan); // Initialize with initial data
  const handleFilterChangeSalesMan = (value: string, dataIndex: keyof DataTypeSalesman) => {
    const newData = originalDataSalesMan.filter(item =>
      String(item[dataIndex]).toLowerCase().includes(value.toLowerCase())
    );
    setFilteredDataSalesMan(newData);
  };
  const columnsForSalesman = [
    {
      title: (
        <Grid container spacing={1} flexDirection={'row'}>
          <Grid item xs={12}>
            <Typography>Salesman Code</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={e => handleFilterChangeSalesMan(e.target.value, 'salesmanCode')}
            />
          </Grid>
        </Grid>
      ),
      dataIndex: 'salesmanCode',
      key: 'salesmanCode',
    },
    {
      title: (
        <Grid container spacing={1} flexDirection={'row'}>
          <Grid item xs={12}>
            <Typography>Salesman Name</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={e => handleFilterChangeSalesMan(e.target.value, 'salesmanName')}
            />
          </Grid>
        </Grid>
      ),
      dataIndex: 'salesmanName',
      key: 'salesmanName',
    },

  ]



  return (
    <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
      <Paper elevation={0} className="pad-10 mar-tb">
        <Grid item xs={12}>
          <Typography className="cursor_pointer">
            <span onClick={() => navigator("/Customer")}>Customer</span> &nbsp;/
            <span>
              <b>&nbsp;Customer Migration</b>
            </span>
          </Typography>
        </Grid>
      </Paper>
      <Box>
        <form>
          <Grid container spacing={1} style={{ marginTop: "20px" }}>
            <Grid item xs={2}>
              <InputLabel size="normal">Type Of Migration</InputLabel>
            </Grid>
            <Grid item>
              <RadioGroup
                row
                aria-label="mandatory"
                name="mandatory"
                value={selectedOption}
                onChange={mandatoryhandleChange}
              >
                <FormControlLabel
                  value="customerMigration"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Customer Migration"
                />
                <FormControlLabel
                  value="customerSharing"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Customer Sharing"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          {showMigrationDueTo && (
            <Grid container spacing={1} style={{ marginTop: "20px" }}>
              <Grid item xs={2}>
                <InputLabel size="normal">Migration Due To</InputLabel>
              </Grid>
              <Grid item xs={4}>
                <FormControl sx={{ width: 300 }}>
                  <Select>
                    {GroupOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={1} style={{ marginTop: "20px" }}>
            <Grid item xs={2}>
              <InputLabel size="normal">Customer Type</InputLabel>
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ width: 300 }}>
                <Select>
                  {ClassOptions.map((option, index: number) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container sx={{ marginTop: "16px" }}>
            <Grid item xs={12}>
              <Grid item sx={{ display: "flex", mt: 2 }} spacing={2}>
                <Grid item xs={2} sx={{ mt: 1 }}>
                  <InputLabel size="normal"> LOB </InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <TextField sx={{ width: 300 }} size="medium"></TextField>
                </Grid>
                <Grid>
                  <Button
                    variant="contained"
                    className="btn-colr"
                    onClick={(event) => HandleLobClick(event)}
                  >
                    ...
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container sx={{ marginTop: "16px" }}>
            <Grid item xs={12}>
              <Grid item sx={{ display: "flex", mt: 2 }} spacing={2}>
                <Grid item xs={2} sx={{ mt: 1 }}>
                  <InputLabel size="normal"> State Selection </InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <TextField sx={{ width: 300 }} size="medium"></TextField>
                </Grid>
                <Grid>
                  <Button
                    variant="contained"
                    className="btn-colr"
                    onClick={(event) => HandleStateSelectionClick(event)}
                  >
                    ...
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container sx={{ marginTop: "16px" }}>
            <Grid item xs={12}>
              <Grid item sx={{ display: "flex", mt: 2 }} spacing={2}>
                <Grid item xs={2} sx={{ mt: 1 }}>
                  <InputLabel size="normal"> From Distributor </InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <TextField sx={{ width: 300 }} size="medium"></TextField>
                </Grid>
                <Grid>
                  <Button
                    variant="contained"
                    className="btn-colr"
                    onClick={(event) => HandleFDistibutorClick(event)}
                  >
                    ...
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container sx={{ marginTop: "16px" }}>
            <Grid item xs={12}>
              <Grid item sx={{ display: "flex", mt: 2 }} spacing={2}>
                <Grid item xs={2} sx={{ mt: 1 }}>
                  <InputLabel size="normal"> To Distributor </InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <TextField sx={{ width: 300 }} size="medium"></TextField>
                </Grid>
                <Grid>
                  <Button
                    variant="contained"
                    className="btn-colr"
                    onClick={(event) => HandleFDistibutorClick(event)}
                  >
                    ...
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container sx={{ marginTop: "16px" }}>
            <Grid item xs={12}>
              <Grid item sx={{ display: "flex", mt: 2 }} spacing={2}>
                <Grid item xs={2} sx={{ mt: 1 }}>
                  <InputLabel size="normal"> Salesman </InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <TextField sx={{ width: 300 }} size="medium"></TextField>
                </Grid>
                <Grid>
                  <Button
                    variant="contained"
                    className="btn-colr"
                    onClick={(event) => HandleSalesmanClick(event)}
                  >
                    ...
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container sx={{ marginTop: "16px" }}>
            <Grid item xs={12}>
              <Grid item sx={{ display: "flex", mt: 2 }} spacing={2}>
                <Grid item xs={2} sx={{ mt: 1 }}>
                  <InputLabel size="normal"> Route </InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <TextField sx={{ width: 300 }} size="medium"></TextField>
                </Grid>
                <Grid xs={1}>
                  <Button
                    variant="contained"
                    className="btn-colr"
                    onClick={(event) => HandleRouteClick(event)}
                  >
                    ...
                  </Button>
                </Grid>
                <Grid>
                  <Button variant="contained" className="btn-colr">
                    Go
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
      {/* # State Selection */}
      <TableRadioDataTable
        // rowSelection={rowSelection}
        data={filteredDataStateSelection}
        columns={columnsForStateSelection}
        isModalOpen={openStateSelectionModel}
        setIsModalOpen={setOpenStateSelectionModel}
      />
      {/* # LOB */}
      <TableSearchDataTable
        // rowSelection={rowSelectionLob}
        data={filteredDataLob}
        columns={columnsLob}
        isModalOpen={openLobionModel}
        setIsModalOpen={setOpenLobModel}
      />
      {/* # From Distributor */}
      <TableRadioDataTable
        // rowSelection={rowSelection}
        data={filteredDataFDistibutor}
        columns={columnsForFDistibutor}
        isModalOpen={openFDistributorModel}
        setIsModalOpen={setOpenFDistributorModel}
      />
      {/* # Route */}
      <TableSearchDataTable
        // rowSelection={rowSelectionLob}
        data={filteredDataRoute}
        columns={columnsForRoute}
        isModalOpen={openRouteModel}
        setIsModalOpen={setOpenRouteModel}
      />
      {/* # Saleman */}
      <TableSearchDataTable
        // rowSelection={rowSelectionLob}
        data={filteredDataSalesMan}
        columns={columnsForSalesman}
        isModalOpen={openSalesmanModel}
        setIsModalOpen={setOpenSalesmanModel}
      />
    </Box>
  );
}
export default CustomerMigration;
