import React, { useState, useRef, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import { Drawer, Grid, Typography } from "@mui/material";
import Close from "../../../assets/images/icons/Close_icon.png";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { ReactComponent as NearToExpirySVG } from "../../../assets/images/icons/time-exp.svg";
import { ReactComponent as NearToExpiryWarnSVG } from "../../../assets/images/icons/time-warning.svg";
import { ReactComponent as SchemeSVG } from "../../../assets/images/icons/scheme.svg";
import type { TableColumnsType } from "antd";
import { Spin, Table, Tooltip } from "antd";
import { decodeJwt } from "../../../utils/constant";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import { getSaleableProductDetails } from "../../../Redux/XDM/Inventory/saleableReducer";
import { getNonSaleableExtendedDetails } from "../../../Redux/XDM/Inventory/nonSaleableReducer";
import moment from "moment";
import debounce from "lodash.debounce";

interface RequestData {
  // key: number;
  nearToexpiry: boolean;
  productCode: string;
  productName: string;
  productHierarchy: string;
  division: string;
  stockInHand: number;
  value: number;
  // reason: string;
  scheme: boolean;
  lastPurchaseDate: string;
  reason:string
}

interface StockProductDetail {
  productDescription: string;
  matGrp2Desc: string;
  productCode: string;
  batchCode: string;
  margin: number;
  mrp: number;
  expiryDate: string;
  daysToExpire: number;
  expiryColor: string;
  stockInHand: number;
  stockValue: number;
}

interface StockDetail {
  productDescription: string;
  matGrp2Desc: string;
  productCode: string;
  stockInHand: number;
  stockValue: number;
  division: string;
  mrp:number;
  expiryDate: string;
  batchCode:string;
  reason:string;
}

const NearToExpiryIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={NearToExpirySVG} viewBox="0 0 16 16" />
);
const NearToExpiryWarnIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={NearToExpiryWarnSVG} viewBox="0 0 16 16" />
);

const SchemeIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={SchemeSVG} viewBox="0 0 24 24" />
);

interface NonSaleableProductsProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
}

export default function NonSaleableProducts(props: NonSaleableProductsProps) {
  const { onClose, requestData } = props; // Destructure the props
  const dispatch = useAppDispatch();

  let saleabledData = useAppSelector((state) => state.saleableData);
  let ProductDetails = saleabledData.saleableProductData;
  let nonSaleableData = useAppSelector((state) => state.nonSaleableData);
  const [hasMore, setHasMore] = useState(true);
  const [commonLoader, setCommonLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [products, setProducts] = React.useState<StockDetail[]>([]);
  const tableHeightRef = useRef(220);
  // let token = localStorage.getItem("token");
  // let decoded = decodeJwt(token ?? "");
  // let distributor = decoded.Distributor ?? "";
  const filterData = {
    // distributorCode: distributor,
    // productCode: "MAFD600MAG01PH",
    productHierarchy: requestData.productHierarchy,
    reason:requestData.reason,
    page: 0,
    size: 0,
  };
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);

  const handleExpand = (expanded: boolean, record: StockDetail) => {
    const newExpandedRowKeys = expanded ? [record.productCode] : [];

    setExpandedRowKeys(newExpandedRowKeys);
  };

  useEffect(() => {
    setPage(0)
    dispatch(
      getSaleableProductDetails({
        // distributorCode: distributor,
        productCode: requestData.productHierarchy,
        type: "nonsale",
      })
    );
    setProducts([]);
    loadMoreData(true);
  }, [requestData]);
  const tableBodyRef = useRef<HTMLElement | null>(null);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 413;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };
  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    setHasMore(true);
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;
    filterData.page = nextPage;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;

    dispatch(getNonSaleableExtendedDetails(filterData)).then((response) => {
      if (response.payload && response.payload && response.payload.details) {
        const newProducts = response.payload.details || [];
        setProducts((prevProducts) =>
          resetPage ? newProducts : [...prevProducts, ...newProducts]
        );
        setPage(nextPage + 1);
        if (newProducts.length < 15) {
          setHasMore(false);
        }
      } else {
        // setHasMore(false);
      }
      setCommonLoader(false);
    });
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };
  const stockDetailColumns: TableColumnsType<StockDetail> = [
    {
      title: "Product Description",
      dataIndex: "productDescription",
      key: "productDescription",
      ellipsis: true,
      render: (text: string, record: StockDetail) => {
        // const displayText =
        //   basicMaterial.length > 15
        //     ? `${basicMaterial.substring(0, 15)}...`
        //     : basicMaterial;
        const desc = record.matGrp2Desc
          ? record.matGrp2Desc
          : record.productDescription
          ? record.productDescription
          : "--";
        return (
          <Tooltip placement="top" title={desc} zIndex={999999}>
            <span>{desc? desc:"--"}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Product Code",
      dataIndex: "productCode",
      key: "productCode",
      ellipsis:true,
      render: (text: string) => {
        // const displayText =
        //   text.length > 15 ? `${text.substring(0, 15)}...` : text;
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text? text :"--"}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Batch Code",
      dataIndex: "batchCode",
      key: "batchCode",
      ellipsis:true,

      render: (text: string) => {
        // const displayText =
        //   text.length > 15 ? `${text.substring(0, 15)}...` : text;
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text ? text :"--"}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Stock in Hand(in Pcs)",
      dataIndex: "stockInHand",
      key: "stockInHand",
    },
    {
      title: "Stock Value",
      dataIndex: "stockValue",
      key: "stockValue",
      render: (_: any, record: StockDetail) => {
        return (
          <>
            <CurrencyRupeeIcon sx={{ fontSize: 10 }} />{" "}
            {record.stockValue ? record.stockValue.toFixed(2) : "0.00"}
          </>
        );
      },
    },
     {
      title: "MRP",
      dataIndex: "mrp",
      key: "mrp",
      render: (_: any, record: StockDetail) => {
        return (
          <>
            <CurrencyRupeeIcon sx={{ fontSize: 10 }} />{" "}
            {record.mrp ? record.mrp.toFixed(2) : "0.00"}
          </>
        );
      },
    },
     {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      ellipsis:true,
      render: (text: string, record: StockDetail) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text ? text :"Expired"}</span>
          </Tooltip>
        );
      },
    },
     {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      ellipsis:true,
      key: "expiryDate",
      render: (text: string, record: StockDetail) => {
              const dateObj = moment(record.expiryDate);
          const formattedDate = dateObj.format("DD MMM YYYY");
          return <div>{formattedDate ?formattedDate:"--" }</div>;
      },
    }
    // {
    //   title: "Division",
    //   dataIndex: "division",
    //   key: "division",
    //   render: (text: string) => {
    //     return (
    //       <Tooltip placement="top" title={text} zIndex={999999}>
    //         <span>{text}</span>
    //       </Tooltip>
    //     );
    //   },
    // },
  ];
  // const expandedRowRender = (record: StockDetail) => {
  //   const columns: TableColumnsType<StockProductDetail> = [
  //     {
  //       title: "Margin",
  //       dataIndex: "margin",
  //       key: "margin",
  //       render: (margin: number) => <>{margin} %</>,
  //     },
  //     {
  //       title: "MRP",
  //       dataIndex: "mrp",
  //       key: "mrp",
  //       render: (mrp: number) => <>{mrp?.toFixed(2)}</>,
  //     },
  //     {
  //       title: "Stock in Hand(in Pcs)",
  //       dataIndex: "stockInHand",
  //       key: "stockInHand",
  //       render: (margin: number) => <>{margin ? margin : 0}</>,
  //     },
  //     {
  //       title: "Stock Value",
  //       dataIndex: "stockValue",
  //       key: "stockValue",
  //       render: (stockValue: number) => (
  //         <>{stockValue ? stockValue?.toFixed(2) : 0}</>
  //       ),
  //     },
  //     {
  //       title: "Batch Code",
  //       dataIndex: "batchCode",
  //       key: "batchCode",
  //       render: (batchCode: string) => (
  //         <Tooltip placement="top" title={batchCode} zIndex={999999}>
  //           <span>{batchCode}</span>
  //         </Tooltip>
  //       ),
  //     },
  //     {
  //       title: "Expiry Date",
  //       dataIndex: "expiryDate",
  //       key: "expiryDate",
  //       render: (expiryDate: string) => {
  //         const dateObj = moment(expiryDate);
  //         const formattedDate = dateObj.format("DD MMM YYYY");
  //         return <div>{formattedDate}</div>;
  //       },
  //     },
  //   ];

  //   return (
  //     <Table
  //       columns={columns}
  //       dataSource={record.productDetails}
  //       pagination={false}
  //     />
  //   );
  // };

  const dataToShow: StockDetail[] = hasMore ? products : [...products];
  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );
  const formatDate = (date: string) => {
    if (date) {
      const dateObj = moment(date, "YYYY-MM-DD");
      return dateObj.format("DD MMM YYYY");
    }
    return "-";
  };
  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_stl"
        // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 1160,
              md: 536,
              sm: 600,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "100%" }} role="presentation">
            <Grid
              container
              className="crt-header"
              mt={1}
              style={{ padding: "16px 16px 0px 16px" }}
            >
              <Typography
                gutterBottom
                className="crt-header-lable"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "1000px",
                  whiteSpace: "nowrap",
                }}
                title={
                  requestData.productName +
                  "(" +
                  requestData.productHierarchy +
                  ")"
                }
              >
                {requestData.productName} ({requestData.productHierarchy})
              </Typography>

              <Grid item>
                <img
                  src={Close}
                  onClick={() => {
                    onClose();
                  }}
                  style={{ width: "24px", height: "24px", cursor: "pointer" }}
                  alt="Close Icon"
                />
              </Grid>
            </Grid>
            <Divider />
          </Grid>
          {/* Content */}
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form saleable-products-box"
          >
            <Grid
              item
              sx={{
                width: "95%",
                margin: "auto",
                marginBottom: "10px",
              }}
            >
              <Accordion className="accordian-products">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography gutterBottom className="crt-header-lable" mb={0}>
                    Product Details
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Typography className="crt-fnt-lable-light" mt={1}>
                        Division
                      </Typography>
                      <Typography
                        gutterBottom
                        className="crt-lable-input"
                        mt={1}
                      >
                        {ProductDetails?.division}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Typography className="crt-fnt-lable-light" mt={1}>
                        Segment
                      </Typography>
                      <Typography
                        gutterBottom
                        className="crt-lable-input"
                        mt={1}
                      >
                        {ProductDetails?.segment}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Typography className="crt-fnt-lable-light" mt={1}>
                        Brand
                      </Typography>
                      <Typography
                        gutterBottom
                        className="crt-lable-input"
                        mt={1}
                      >
                        {ProductDetails?.brand}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Typography className="crt-fnt-lable-light" mt={1}>
                        Variant
                      </Typography>
                      <Typography
                        gutterBottom
                        className="crt-lable-input"
                        mt={1}
                      >
                        {ProductDetails?.variant}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Typography className="crt-fnt-lable-light" mt={1}>
                        Sub Variant
                      </Typography>
                      <Typography
                        gutterBottom
                        className="crt-lable-input"
                        mt={1}
                      >
                        {ProductDetails?.subVariant}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Typography className="crt-fnt-lable-light" mt={1}>
                        Package Type
                      </Typography>
                      <Typography
                        gutterBottom
                        className="crt-lable-input"
                        mt={1}
                      >
                        {ProductDetails?.packageType}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Typography className="crt-fnt-lable-light" mt={1}>
                        Size
                      </Typography>
                      <Typography
                        gutterBottom
                        className="crt-lable-input"
                        mt={1}
                      >
                        {ProductDetails?.size}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid
              item
              sx={{
                width: "95%",
                margin: "auto",
                marginBottom: "10px",
              }}
            >
              <Typography gutterBottom className="crt-header-lable">
                Stock Details
              </Typography>
              <Divider />
              <Grid container mt={1}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Stock In Hand
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {Number(requestData?.stockInHand)} Pcs
                    {/* Pcs (
                    {(() => {
                      const totalNoOfPcs = Number(ProductDetails?.totalNoOfPcs);
                      const totalSaleableStock = Number(
                        requestData?.stockInHand
                      );

                      const cs =
                        totalSaleableStock !== 0
                          ? Math.floor(totalSaleableStock / totalNoOfPcs)
                          : 0;
                      const pc =
                        totalSaleableStock !== 0
                          ? totalSaleableStock % totalNoOfPcs
                          : 0;

                      const finalCs = isFinite(cs) ? cs : 0;
                      const finalPc = isFinite(pc) ? pc : 0;

                      return `${finalCs?.toFixed(0)} Cs, ${finalPc} Pc`;
                    })()}
                    ) */}
                  </Typography>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Available UOM
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    Cases, Pieces
                  </Typography>
                </Grid> */}
                {/* <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Last Purchased
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {requestData && requestData.lastPurchaseDate ? (
                      <div>{formatDate(requestData.lastPurchaseDate)}</div>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Grid> */}
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Stock Value
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    <CurrencyRupeeIcon sx={{ fontSize: 12 }} />
                    {requestData?.value
                      ? `${Number(requestData.value).toFixed(2)}`
                      : "0.00"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="saleable-table-scroll ">
                    <Table
                      className={`nonsaleable-product-table  sale-p scroll_cus_table elipsTable ${
                        nonSaleableData?.NonSaleableExtendedDataLoading
                          ? " nodataloader"
                          : ""
                      }`}
                      // rowSelection={rowSelection}
                      columns={stockDetailColumns}
                      dataSource={dataToShow.map((item) => ({
                        ...item,
                        key: item.productCode,
                      }))}
                      loading={
                        !page && nonSaleableData?.NonSaleableExtendedDataLoading
                      }
                      summary={() => {
                        return (
                          <>
                            {page !== 0 &&
                            nonSaleableData?.NonSaleableExtendedDataLoading ? (
                              <Table.Summary.Row>
                                <Table.Summary.Cell
                                  index={1}
                                  colSpan={8}
                                  align="center"
                                >
                                  <Spin />
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            ) : (
                              !hasMore && (
                                <Table.Summary.Row>
                                  <Table.Summary.Cell
                                    index={1}
                                    colSpan={8}
                                    align="center"
                                    className="fs-12 noMore btm_boreder"
                                  >
                                    <b>No more data found...</b>
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                              )
                            )}
                          </>
                        );
                      }}
                      pagination={false}
                      scroll={{ y: tableHeightRef.current }}
                      showSorterTooltip={true}
                      // expandable={{
                      //   expandedRowRender,
                      //   expandedRowKeys,
                      //   onExpand: handleExpand,
                      // }}
                      size="small"
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
