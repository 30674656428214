// import React from "react";

// const ProductMaster = () => {
//   return <div>index</div>;
// };

// export default ProductMaster;
import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Checkbox,
  Grid,
  Typography,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import type { TableColumnsType } from "antd";
import { Col, Row, Table } from "antd";
// style css
import "../ProductMustSellSKU/style.css";
//icons
import Addicon from "../../../../assets/images/icons/add.png";
import Rightdot from "../../../../assets/images/icons/Right_dot.png";
import { BsThreeDots } from "react-icons/bs";

import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import InfiniteScroll from "react-infinite-scroll-component";
// import TableSkeletonLoader from "../../../components/Skeleton/skeleton";
import Modal from "@mui/material/Modal";
import CommonTable from "../../../../components/Tables/CommonTable";
import EditProductStateCode from "../ProductGstStateCode/EditGstStateCode";
import TableSkeletonLoader from "../../../../components/Skeleton/skeleton";
import CreateProductStateCode from "../ProductGstStateCode/CreateGstStateCode";
import {
  GstStateTaxFetchData,
  GstStateTaxActiveStatus,
  GstStateTaxEdit,
} from "../../../../Redux/Masters/Products/GstStateTax/action";
import { useAppSelector, useAppDispatch } from "../../../../hooks/useFetch";
import AntswitchModel from "../ProductHsnCodeMaster/AntswitchModel";
import { Tooltip } from "antd";
import ProductMustSellSKUView from "./MustSellSKUView";
import { subHeaderHide } from "../../../../Redux/AuthLogin/action";
// Define a type for the state object
interface StateObject {
  isChecked: any;
  id: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface DataType {
  id: any;
  msskucode: string;
  CreatedDate: string;
  Geographic: string;
  Distributorsgroups: string;
  outletclass: string;
  NoOfproducts: string;
  status: string;
  clone: string;
}
export default function ProductMustSellSKU() {
  // const classes = useStyles();
  const renderAfterCalled = useRef(false);
  const [open, setOpen] = React.useState(false);
  const [editingData, setEditingData] = React.useState<DataType | null>(null);
  const [showForm, setShowForm] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isShow, setIsShow] = React.useState(false);
  const [textFieldValue, setTextFieldValue] = React.useState("");
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const dispatch = useAppDispatch();

  const [checkboxDefault, setCheckboxDefault] = useState<{
    [key: string]: any;
  }>({});
  const [requestData, setRequestData] = useState({ page: 0, size: 3 });
  const [prevData, setPrevData] = useState<any[]>([]);
  const [isDefaultActive, setIsDefaultActive] = React.useState(false);
  const [sampleState, setSampleState] = useState<StateObject | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [skeleton, setskeleton] = useState(false);
  const [checkboxStatus, setCheckboxStatus] = useState<{ [key: string]: any }>(
    {}
  );
  const [isModel, setIsModel] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleCreateNew = () => {
    setShowForm(!showForm);
  };

  const handleViewClick = () => {
    setIsEditing(!isEditing);
    toggleDrawer(true);
    dispatch(subHeaderHide(true));
    // setEditingData(rowData);
    // dispatch(GstStateTaxEdit(rowData.id));
  };

  const handleShowClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setIsShow(!isShow);
    toggleDrawer(true);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      dispatch(GstStateTaxFetchData(requestData));
    }
    return () => {
      renderAfterCalled.current = true;
    };
  }, [dispatch, requestData]);

  const GstStateTaxFetchDatas = useAppSelector(
    (state: any) => state.GstStateTaxFetchData.GstStateTaxFetch
  );

  useEffect(() => {
    if (prevData) {
      const initialCheckboxStatus: { [key: string]: any } = {};
      const initialCheckboxDefault: { [key: string]: any } = {}; // Declare initialCheckboxDefault
      prevData.forEach((record: any) => {
        initialCheckboxStatus[record.id] = record.status;
        initialCheckboxDefault[record.id] = record.isDefault;
      });
      setCheckboxStatus(initialCheckboxStatus);
      setCheckboxDefault(initialCheckboxDefault);
    }
  }, [prevData]);

  useEffect(() => {
    if (Array.isArray(GstStateTaxFetchDatas)) {
      if (requestData.page === 0) {
        setPrevData(GstStateTaxFetchDatas);
        setskeleton(false);
      } else {
        setPrevData((prevData) => [...prevData, ...GstStateTaxFetchDatas]);
        setskeleton(false);
      }
    } else {
      setskeleton(false);

      console.error(
        "companyFetchDatas is not an array:",
        GstStateTaxFetchDatas
      );
    }
  }, [GstStateTaxFetchDatas]);

  //   const fetchMoreData = () => {
  //     const nextPage = requestData.page + 1;
  //     const nextSize = requestData.size;
  //     const updatedRequestData = {
  //       ...requestData,
  //       page: nextPage,
  //       size: nextSize,
  //     };
  //     setRequestData(updatedRequestData);
  //     dispatch(companyFetchData(updatedRequestData));
  //     if (prevData?.length < nextSize) {
  //       setHasMore(false);
  //     }
  //   };

  //CheckBox Active in Active
  const handleChange = (isChecked: any, id: string) => {
    const check = isChecked.toString();
    prevData.forEach((record: any) => {
      if (record.id === id) {
        dispatch(
          GstStateTaxActiveStatus({
            id: record.id.toString(),
            status: isChecked,
          })
        );
        setCheckboxStatus((prevState) => ({
          ...prevState,
          [id]: check,
        }));
      }
    });
  };

  //confirmation active
  //   const handleOk = () => {
  //     if (sampleState) {
  //       const { isChecked, id } = sampleState;
  //       if (isChecked !== undefined && id !== undefined) {
  //         handleChangeDefault(isChecked, id);
  //       }
  //     }
  //   };

  //   const handleChangeDefault = (isChecked: any, id: string) => {

  //     const updatedCheckboxDefault: { [key: string]: any } = {};
  //     for (const record of prevData) {
  //       updatedCheckboxDefault[record.id] = false;
  //     }
  //     updatedCheckboxDefault[id] = isChecked;

  //     setCheckboxDefault(updatedCheckboxDefault);
  //     dispatch(companyActiveDefault({ id: id.toString(), value: isChecked }));
  //     setIsDefaultActive(false);
  //   };

  //Model Close
  const handleClose = () => setIsDefaultActive(false);
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    handleClose();
    setIsDefaultActive(false);
  };
  const handleActiveClick = (isChecked: any, id: string) => {
    setIsDefaultActive(!isDefaultActive);
    const newState: StateObject = {
      isChecked: isChecked,
      id: id,
    };
    setSampleState(newState);
  };

  //company table
  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "sno",
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span>S.No</span>
        </div>
      ),
      render: (_: any, __: any, index: number) => (
        <span
          className="sno_lable"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {index + 1}
        </span>
      ),
    },
    {
      dataIndex: "msskucode",
      title: "MS SKU COde",
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      dataIndex: "CreatedDate",
      title: "CREATED DATE",
    },
    {
      dataIndex: "Geographic",
      title: "GEOFRAPHIC",
    },
    {
      dataIndex: "Distributorsgroups",
      title: "DISTRIBUTORS GROUPS",
    },
    {
      dataIndex: "outletclass",
      title: "OUTLET CLASS",
    },
    {
      dataIndex: "NoOfproducts",
      title: " NO OF PRODUCTS",
    },

    {
      dataIndex: "status",
      title: "Status",
      render: (_: any, record: DataType, index: number) => (
        <Row>
          <Col>
            <AntSwitch
              // checked={checkboxStatus[record.id] === true}
              // onClick={(e) => e.stopPropagation()}
              onClick={handleShowClickmodel}
              // onChange={(event) =>
              //   handleChange(event.target.checked, record.id)
              // }
              inputProps={{ "aria-label": "ant design" }}
            />
          </Col>
          <Col>
            <Grid item style={{ marginLeft: "20px" }}>
              {/* {checkboxStatus[record.id] === true ? (
                <Typography>active</Typography>
              ) : (
                <Typography>Inactive</Typography>
              )} */}
            </Grid>
          </Col>
        </Row>
      ),
    },
    {
      dataIndex: "clone",
      title: " ",
      render: (_: any, record: DataType, index: number) => (
        <Grid item className="Clone_button">
          <Tooltip
            placement="bottomRight"
            title={<span onClick={handleViewClick}>Clone</span>}
          >
            <BsThreeDots style={{ fontSize: "20px" }} />
          </Tooltip>
        </Grid>
      ),
    },
  ];

  const data: DataType[] = [
    {
      id: "", // Add id property
      msskucode: "MS3309050",
      CreatedDate: "20-03-2024",
      Geographic: "North",
      Distributorsgroups: "MT, AT",
      outletclass: "GT, AT, AT",
      NoOfproducts: "06",
      status: "",
      clone: "",
    },
  ];

  const handleShowClickmodel = (
    event: React.MouseEvent<unknown, MouseEvent>
  ) => {
    event.stopPropagation();
    setIsModel(!isModel);
  };

  //AntSwitch

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    // display: 'flex',
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          background: "linear-gradient(to right, #614FB3, #944986)",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      // transition: theme.transitions.create(['width'], {
      //   duration: 200,
      // }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  return (
    <>
      {skeleton ? (
        <Box sx={{ marginTop: "2px", pr: { xs: 3 }, pl: { xs: 2 } }}>
          <Grid container>
            <Grid item xs={6} className="mar-tb">
              <Skeleton
                variant="text"
                sx={{ fontWeight: "700", fontSize: "16px" }}
              />
            </Grid>
            <Grid
              item
              container
              xs={6}
              sm={6}
              md={6}
              className="mar-tb"
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Grid item sx={{ display: "flex" }}>
                <Button className="mastercrt-button">
                  <Skeleton variant="rectangular" />
                </Button>
                <Grid item>
                  <Skeleton variant="rectangular" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box id="TableScroll">
            {/* <TableSkeletonLoader
             rowCount={CompanyLength} 
            columns={10} /> */}
          </Box>
        </Box>
      ) : (
        <>
          {isShow ? (
            <CreateProductStateCode
              onClose={() => setIsShow(false)}
              requestData={requestData}
            />
          ) : isEditing ? (
            <ProductMustSellSKUView
              onClose={() => setIsEditing(false)}
              requestData={requestData}
            />
          ) : (
            <Box
              sx={{
                p: { xs: 2, sm: 3 },
                pt: { xs: 1, sm: 3 },
              }}
            >
              <Grid container sx={{ display: "flex", alignItems:'center' 

              }}>
                <Grid
                  item
                  xs={6}
                  // className="mar-tb"
                >
                  <Typography>
                    <b className="header-lable">Must Sell SKU</b>
                  </Typography>
                </Grid>
                <Grid
                  container
                  xs={6}
                  sm={6}
                  md={6}
                  // className="mar-tb"
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Grid item sx={{ display: "flex" }}>
                    <Button
                      className="mastercrt-button"
                      onClick={handleShowClick}
                    >
                      <img src={Addicon} alt={Addicon} />
                      <span className="Crt-lable"> Create New</span>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Box id="TableScroll" mt={3}>
                {/* <Grid item xs={12} sx={{ display: "flex", justifyContent: "end", mb: 2 }} >
          <SearchBar
            className="search-table"
            value={textFieldValue}
            onChange={(newValue) => {
              setTextFieldValue(newValue);
              handleSearch();
            }}
          />
        </Grid> */}
                {/* <InfiniteScroll
              dataLength={companyFetchDatas?.length ?? 0}
              next={fetchMoreData}
              hasMore={false}
              className=""
              loader={
                <h6
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <CircularProgress
                    style={{
                      color: "#119BFF",
                    }}
                    size={20}
                  />
                </h6>
              }
            > */}
                <CommonTable
                  // rowSelection={rowSelection}
                  data={data}
                  // data={prevData ? prevData : GstStateTaxFetchDatas}
                  columns={columns}
                />
                {/* </InfiniteScroll> */}
              </Box>
              {/* {data.map((rowData) => (
        <Button
          key={rowData.key}
          onClick={(event) => handleEditClick(event, rowData)}
        >
          Edit
        </Button>
      ))} */}

              {/* 
            <Modal
              open={isDefaultActive}
              onClose={handleClose}
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-description">
                  Are you sure you want to activate the status?
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleBackClick}
                    sx={{ mr: 2 }}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    className="btn-colr"
                    //   onClick={handleOk}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            </Modal> */}
            </Box>
          )}
          {isModel ? (
            <AntswitchModel onBack={() => setIsModel(false)} />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}
