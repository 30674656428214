import React, { useRef } from "react";
import {
  Button,
  Grid,
  TextField,
  Card,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import * as XLSX from "xlsx";

function convertHeadersToXLS(headers: any) {
  const worksheet = XLSX.utils.aoa_to_sheet([headers]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  return blob;
}

// Function to download XLS file with headers only
function downloadXLSWithHeaders(headers: any) {
  const xlsBlob = convertHeadersToXLS(headers);
  const url = URL.createObjectURL(xlsBlob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "exported_headers.xlsx");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const RemovePrintExport = () => {
  const [fileName, setFileName] = React.useState();
  const [hideCard, setHideCard] = React.useState(true);

  // Your data
  const headers = ["ID", "Commodity", "Price", "Quantity"];
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      // Do something with the selected file
      // console.log(selectedFile.name);
    }
  };
  const hideandshow = () => {
    setHideCard(!hideCard);
  };
  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className="pad-10 mar-tb">
          <Typography>
            <b>Credit Note Upload</b>
          </Typography>
        </Grid>

        <Button variant="contained" className="btn-apply" onClick={hideandshow}>
          {" "}
          - importorExport
        </Button>
        {hideCard ? (
          <Card className="card-form" sx={{ border: "1px solid #EEE" }}>
            <Button
              variant="contained"
              className="btn-apply"
              onClick={() => downloadXLSWithHeaders(headers)}
            >
              Export Data
            </Button>
            <input
              ref={fileInputRef}
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <Grid container sx={{ marginTop: "16px" }}>
              <Grid item xs={2}>
                <TextField size="small" value={fileName}></TextField>
              </Grid>
              <Grid item xs={5}>
                <Button
                  variant="contained"
                  className="btn-apply-color"
                  onClick={handleButtonClick}
                >
                  Select File
                </Button>
                <Button
                  variant="contained"
                  className="btn-apply-color"
                  sx={{ marginLeft: "5px" }}
                  onClick={handleButtonClick}
                >
                  Upload
                </Button>
              </Grid>
            </Grid>
          </Card>
        ) : null}
      </Box>
    </>
  );
};
export default RemovePrintExport;
