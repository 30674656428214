import {
    Box,
    Card,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Skeleton,
    RadioGroup,
    Typography,
    Radio,
    Modal,
    Button,
  } from "@mui/material";
  import { IoMdClose } from "react-icons/io";
  import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
  import {
    getOutlet,
    getRoute,
    getSalesman,
    updateDate,
    updateFromDate,
    updateStatusGrn,
    updateToDate,
  } from "../../../Redux/XDM/Sales/billingReducer";
  import { useEffect, useState } from "react";
  // import DateFilterModal from "./DateFilterModal";
  import { DatePicker, Space } from "antd";
  import moment, { Moment } from "moment";
  import dayjs, { Dayjs } from "dayjs";
  import { IoClose } from "react-icons/io5";
import FilterModal from "../Inventory/FilterModal";
    const { RangePicker } = DatePicker;
  
 
  
  const GrnHistorySidebar = () => {
    const dispatch = useAppDispatch();
    const [filterModalTitle, setFilterModalTitle] = useState("");
    const fetch = useAppSelector((state) => state.orderBilling);
    const [dateFilter, setDateFilter] = useState("");
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [isWTDOpen, setIsWTDOpen] = useState(false);
    const [selectedDates, setSelectedDates] = useState<
      [Dayjs | null, Dayjs | null]
    >([null, null]);
  
    const handleFromToDateChange = (dates: [Dayjs | null, Dayjs | null]) => {
      setSelectedDates(dates);
    };
    const handleCustomDateApply = () => {
      const [fromDate, toDate] = selectedDates;
      if(fromDate && toDate){
      setIsDatePickerOpen(false);
      dispatch(updateFromDate(fromDate?.format("YYYY-MM-DD")));
      dispatch(updateToDate(toDate?.format("YYYY-MM-DD")));
      }
      setIsWTDOpen(false);

    };    

    const handleDateChange = (event: any) => {
      setSelectedDates([null, null])
      setDateFilter(event.target.value);
      if (event.target.value === "custom") {
        setIsDatePickerOpen(true);
        setIsWTDOpen(false);

      }
      else if (event.target.value === "lastWeek") {
        setIsWTDOpen(true);
      }
      else if (event.target.value === "currentMonth") {
        setIsWTDOpen(true);
      }
      else {
        setIsDatePickerOpen(false);
        setIsWTDOpen(false);

      }
      dispatch(updateDate(event.target.value));
    };
    const labels = ["Today", "WTD", "MTD", "Custom"];
    const values = ["today", "wtd", "mtd", "custom"];
    const GrnStatusList = [ "Accepted", "Partially Accepted", "Rejected"];

    const handleDatePickerClose = () => {
      setIsDatePickerOpen(false);
      setIsWTDOpen(false);
        dispatch(updateDate([]));
        dispatch(updateToDate([]));
        dispatch(updateFromDate([]));
    };
  
  
   const GrnStatusSelected=fetch.statusGrnSelected
    const dateSelected = fetch.dateSelected;
  
    const clearAll = (title: any) => {
      switch (title) {
        case "GrnStatus":
         dispatch(updateStatusGrn([]));
         break;
        case "Date":
          dispatch(updateDate([]));
          dispatch(updateToDate([]));
          dispatch(updateFromDate([]));
          setIsDatePickerOpen(false);
          setIsWTDOpen(false);
          break;
        default:
          break;
      }
    };
  
    const filterOpenSales = useAppSelector(
      (state) => state.orderBilling.filterPop
    );
  
    let tempData: any[] = [];
  
 
  
   const statusSelected = (value: string) => {
     const isSelected = GrnStatusSelected.includes(value);
     const updatedstatusSelected = isSelected
       ? GrnStatusSelected.filter((item:any) => item !== value)
       : [...GrnStatusSelected, value];
     dispatch(updateStatusGrn(updatedstatusSelected));
   };

  
    return (
      <Box
        sx={{
          padding: "0px 5px 0px 16px",
          marginBottom: "100px",
          marginRight: "4.3px",
          marginTop: "4px",
        }}
        className="dis-flex scrollHide"
      >
        <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
          <Box
            alignItems="center"
            sx={{
              padding: "5px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
              Date
            </Typography>
          </Box>
          <Divider />
          <FormControl component="fieldset" sx={{ mt: 1 }}>
            <RadioGroup value={dateSelected || ""} onChange={handleDateChange}>
              {labels.map((label, index) => (
                <FormControlLabel
                  key={index}
                  value={values[index]}
                  control={
                    <Radio
                      className="sidebar_checkbox"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 15,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label={
                    <div
                      style={{ paddingTop: "2px", paddingLeft: "5px" }}
                      className="filter_label"
                    >
                      {label}
                    </div>
                  }
                />
              ))}
             
            </RadioGroup>

            <Box
              alignItems="center"
              sx={{
                padding: "5px 0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {(Array.isArray(dateSelected) && dateSelected.length > 0) ||
              (!Array.isArray(dateSelected) && dateSelected) ? (
                <Grid
                  item
                  xs={8}
                  sx={{
                    color: "#F5222D",
                    fontSize: "10px",
                    fontWeight: "500",
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="cursor_pointer"
                  onClick={() => clearAll("Date")}
                >
                  <IoMdClose size={7} />
                  &nbsp; Clear all
                </Grid>
              ) : (
                <Grid item xs={8}></Grid>
              )}
              <Grid item xs={4}></Grid>
            </Box>
          </FormControl>
          {/* <DateFilterModal open={isDatePickerOpen} onClose={handleDatePickerClose} /> */}
          {((Array.isArray(dateSelected) && dateSelected.length > 0) ||
            (!Array.isArray(dateSelected) && dateSelected))  && isDatePickerOpen && (
            <RangePicker
              className="invoice_dateRange"
              popupClassName="popup_range"
              onChange={handleFromToDateChange}
              renderExtraFooter={() => (
                <>
                <Grid item>
                <Grid item
                 className="header_popup_design"
                >
                  <Typography className="popup_header_label">Select Date</Typography>
                  <IoClose className="ClosePop" onClick={handleDatePickerClose} />
                </Grid>
              </Grid>
                <Grid item>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Grid item className="from_to_date_border">
                      <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
                        {selectedDates[0]
                          ? selectedDates[0].format("DD/MM/YYYY")
                          : "Start Date"}
                      </Typography>
                    </Grid>
                    -
                    <Grid item className="from_to_date_border">
                      <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
                        {selectedDates[1]
                          ? selectedDates[1].format("DD/MM/YYYY")
                          : "End Date"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "16px",
                    }}
                    mb={1}
                  >
                    <Grid item xs={5.6} md={5.6} lg={5.6}>
                      <Button
                        className="invoice_close_btn"
                        variant="outlined"
                        onClick={handleDatePickerClose}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={5.6} md={5.6} lg={5.6}>
                      <Button
                        className="btn-colr"
                        variant="contained"
                        style={{
                          fontSize: "10px",
                          height: "26px",
                          width: "100%",
                        }}
                        onClick={handleCustomDateApply}
                      >
                        Apply
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                </>
              )}
              showTime
              open
              
            />
          )}
        </Card>
        <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
          <Box
            alignItems="center"
            sx={{
              padding: "5px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
              Status
            </Typography>
            <Typography
              sx={{
                fontSize: "9px",
                fontWeight: "500",
                mb: 1,
                color: "var(--grey-grey500, #505259)",
              }}
            >
              {GrnStatusSelected.length > 0 &&
                "(" + GrnStatusSelected.length + " Selected)"}
            </Typography>
          </Box>
          <Divider />
          <FormControl className="" sx={{ mt: 1 }}>
            {GrnStatusList &&
              GrnStatusList.map((values: any, index: number) => (
                <FormControlLabel
                  key={index}
                  value={values}
                  control={
                    <Checkbox
                      className="sidebar_checkbox"
                      checked={GrnStatusSelected.includes(values)}
                      onChange={() => statusSelected(values)}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 15,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label={
                    <div
                      style={{ paddingTop: "2px", paddingLeft: "5px" }}
                      className="filter_lable"
                    >
                      {values}
                    </div>
                  }
                />
              ))}
          </FormControl>
          <Box
            alignItems="center"
            sx={{
              padding: "5px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {GrnStatusSelected.length > 0 ? (
              <Grid
                item
                xs={8}
                sx={{
                  color: "#F5222D",
                  fontSize: "10px",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                }}
                className="cursor_pointer"
                onClick={() => clearAll("GrnStatus")}
              >
                <IoMdClose size={7} />
                &nbsp; Clear all
              </Grid>
            ) : (
              <Grid item xs={8}></Grid>
            )}
            <Grid item xs={4}></Grid>
          </Box>
        </Card>

        {filterOpenSales && <FilterModal title={filterModalTitle} />}
      </Box>
    );
  };
  
  export default GrnHistorySidebar
  