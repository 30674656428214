import {
  Box,
  Card,
  Grid,
  Stack,
  Divider,
  Tabs,
  Tab,
  Typography,
  Button
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import MiniDrawerStyled from "../../../../Layout/MainLayout/Drawer/MiniDrawerStyled";
import DrawerHeader from "../../../../Layout/MainLayout/Drawer/DrawerHeader";
import SalesReturnSidebar from "./SalesReturnSideBar";
import Pending from "./Pending";
// import GrnHistorySidebar from "./GrnHistorySidebar";
import History from "./History";
import NewSalesReturn from "./NewSalesReturn/NewSaelsReturn";
import { useLocation } from "react-router-dom";

const SalesReturn: React.FC = () => {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState(0);
  const [isNewSalesReturnShow, setIsNewSalesReturnShow] = useState(false);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  const location = useLocation();
  const getQueryParams = (query: any) => {
    return new URLSearchParams(query);
  };

  const queryParams = getQueryParams(location.search);
  const tab = queryParams.get("tab");

  useEffect(()=>{
    if(tab){
      setIsNewSalesReturnShow(true);
    }
  })

  const handleNewSalesReturn = (
    event: React.MouseEvent<unknown, MouseEvent>
  ) => {
    setIsNewSalesReturnShow(!isNewSalesReturnShow);
  };

  return (
    <Grid item>
      {isNewSalesReturnShow ? (
        <NewSalesReturn onClose={() => setIsNewSalesReturnShow(false)} />
      ) : (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          bgcolor="#eee"
        >
          <Box flex={1} p={0}>
            <MiniDrawerStyled variant="permanent" open={true} theme={theme}>
              <DrawerHeader />
              {/* {tabIndex === 0 && <SalesReturnSidebar />} */}
              {/* {tabIndex === 1 && <GrnHistorySidebar />} */}
              <SalesReturnSidebar status={tabIndex === 0 ? "Pending" : "Confirmed"} />
            </MiniDrawerStyled>
          </Box>
          <Box
            flex={8}
            className="margin-left-0px"
            sx={{ overflowY: "auto", height: "100vh", marginBottom: "100px" }}
          >
            <Card
              className="b-radius-0 main-card"
              style={{ marginTop: "2px", boxShadow: "none" }}
            >
              <Grid container spacing={3} justifyContent="start" style={{ padding: "10px 16px" }} >
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={9}
                  lg={9}
                  className="margin_tp_0"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 800,
                      fontFamily: "Montserrat",
                      color: "#000000",
                    }}
                  >
                    Sales return
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={3}
                  lg={3}
                  className="margin_tp_0"
                  sx={{ textAlign: "right" }}
                >
                  <Button
                    className="btn-apply-color"
                    variant="contained"
                    style={{ fontSize: "11px", borderRadius: "5px" }}
                    onClick={handleNewSalesReturn}
                  >
                    New Sales Return
                  </Button>
                </Grid>
              </Grid>
              <Divider />
              <Grid container sx={{ paddingLeft: " 20px", }}>
                <Grid item sm={6}>
                  <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    // TabIndicatorProps={{
                    //   style: {
                    //     background:
                    //       "linear-gradient(90.19deg, #614FB3 0%, #944986 100%)",
                    //   },
                    // }}
                    className="tab_box"
                    sx={{
                      "& .MuiTab-root": {
                        // background:
                        //   "linear-gradient(90.19deg, #614FB3 0%, #944986 100%)",
                        color: "#B9BABD",
                        "&.Mui-selected": {
                          color: "#944986",
                        },
                      },
                    }}
                  >
                    <Tab label="Pending" />
                    <Tab label="History" />
                  </Tabs>
                </Grid>
              </Grid>

              <TabPanel value={tabIndex} index={0}>
                <Pending />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <History />
              </TabPanel>
            </Card>
          </Box>
        </Stack>
      )}
    </Grid>
  );
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

export default SalesReturn;
