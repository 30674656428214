import { Box, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import { IoMdClose } from "react-icons/io";
import {
  updateBasedOnStock,
  updateBrand,
  updateBrandPop,
  updateBrandTemp,
  updateDivision,
  updateDivisionPop,
  updateDivisionTemp,
  updatePackagingType,
  updatePackagingTypePop,
  updatePackagingTypeTemp,
  updateReason,
  updateSegment,
  updateSegmentPop,
  updateSegmentTemp,
  updateSize,
  updateSizePop,
  updateSizeTemp,
  updateSubVariant,
  updateSubVariantPop,
  updateSubVariantTemp,
  updateVariant,
  updateVariantPop,
  updateVariantTemp,
} from "../../../../Redux/XDM/Inventory/filterReducer";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useFetch";
import { useLocation } from "react-router-dom";
import {
  updateDate,
  updateFromDate,
  updateInvoiceType,
  updateOutlet,
  updateOutletPop,
  updateOutletTemp,
  updatePaymentMode,
  updatePaymentModePop,
  updatePaymentModeTemp,
  updateRoute,
  updateRoutePop,
  updateRouteTemp,
  updateSalesman,
  updateSalesmanPop,
  updateSalesmanTemp,
  updateStatusGrn,
  updateStocks,
  updateToDate,
} from "../../../../Redux/XDM/Sales/billingReducer";
import {
  updateMaxValue,
  updateMinValue,
  updateOutletDivision,
  updateOutletDivisionPop,
  updateOutletDivisiontemp,
  updateOutletGroup,
  updateOutletGroupPop,
  updateOutletGrouptemp,
  updateStatus,
} from "../../../../Redux/XDM/Sales/outletSideBarReducer";
const DrawerHeader = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentPath = location.pathname;
  const clearAll = () => {
    if (currentPath.includes("inventory") || currentPath.includes("sales/without-invoice-sales-return")) {
      dispatch(updateBasedOnStock([]));
      dispatch(updateDivision([]));
      dispatch(updateReason([]));
      dispatch(updateSegment([]));
      dispatch(updateBrand([]));
      dispatch(updateVariant([]));
      dispatch(updateSubVariant([]));
      dispatch(updatePackagingType([]));
      dispatch(updateSize([]));
      dispatch(updateDivisionPop([]));
      dispatch(updateSegmentPop([]));
      dispatch(updateBrandPop([]));
      dispatch(updateVariantPop([]));
      dispatch(updateSubVariantPop([]));
      dispatch(updatePackagingTypePop([]));
      dispatch(updateSizePop([]));
      dispatch(updateDivisionTemp([]));
      dispatch(updateSegmentTemp([]));
      dispatch(updateBrandTemp([]));
      dispatch(updateVariantTemp([]));
      dispatch(updateSubVariantTemp([]));
      dispatch(updatePackagingTypeTemp([]));
      dispatch(updateSizeTemp([]));
    } else if (
      currentPath.includes("sales") ||
      currentPath.includes("invoice")
    ) {
      dispatch(updateStocks([]));
      dispatch(updateSalesman([]));
      dispatch(updateRoute([]));
      dispatch(updateOutlet([]));
      dispatch(updateSalesmanPop([]));
      dispatch(updateRoutePop([]));
      dispatch(updateOutletPop([]));
      dispatch(updateSalesmanTemp([]));
      dispatch(updateRouteTemp([]));
      dispatch(updateOutletTemp([]));
      dispatch(updateStatus([]));
      dispatch(updateDate([]));
      dispatch(updateToDate([]));
      dispatch(updateStatusGrn([]));
      dispatch(updateInvoiceType([]));
      dispatch(updateFromDate([]));
      dispatch(updateOutletGroup([]));
      dispatch(updateOutletDivision([]));
      dispatch(updateOutletGrouptemp([]));
      dispatch(updateOutletDivisiontemp([]));
      dispatch(updateOutletGroupPop([]));
      dispatch(updateOutletDivisionPop([]));
      dispatch(updateMinValue(""));
      dispatch(updateMaxValue(""));
      dispatch(updatePaymentMode([]));
      dispatch(updatePaymentModePop([]));
      dispatch(updatePaymentModeTemp([]));
    }
  };

  const fetch = useAppSelector((state) => state.filterInv);
  const fetchSales = useAppSelector((state) => state.orderBilling);
  const fetchOutlet = useAppSelector((state) => state.outletSideBar);

  return (
    <Stack
      direction="row"
      // spacing={9}
      alignItems="center"
      sx={{
        padding: "5px 18px 0px 18px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Grid item xs={6} sx={{ fontSize: "12px", fontWeight: "700" }}>
        Filters
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          color: "#F5222D",
          fontSize: "10px",
          fontWeight: "600",
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
        }}
        className="cursor_pointer"
        onClick={clearAll}
      >
        {fetch.basedOnStockSelected.length > 0 ||
          fetch.divisionSelected.length > 0 ||
          fetch.reasonSelected.length > 0 ||
          fetch.segmentSelected.length > 0 ||
          fetch.brandSelected.length > 0 ||
          fetch.variantSelected.length > 0 ||
          fetch.subVariantSelected.length > 0 ||
          fetch.packagingTypeSelected.length > 0 ||
          fetch.sizeSelected.length > 0 ||
          fetchSales.stockSelected.length > 0 ||
          fetchSales.salesmanSelected.length > 0 ||
          fetchSales.statusGrnSelected.length > 0 ||
          fetchSales.invoiceTypeSelected.length > 0 ||
          fetchSales.routeSelected.length > 0 ||
          fetchOutlet.minVal !== "" ||
          fetchOutlet.maxVal !== "" ||
          fetchOutlet.divisionSelected.length > 0 ||
          fetchOutlet.outletGroupSelected.length > 0 ||
          (Array.isArray(fetchOutlet.statusSelected) &&
            fetchOutlet.statusSelected.length !== 0) ||
          (!Array.isArray(fetchOutlet.statusSelected) &&
            fetchOutlet.statusSelected) ||
          (Array.isArray(fetchSales.dateSelected) &&
            fetchSales.dateSelected.length !== 0) ||
          (!Array.isArray(fetchSales.dateSelected) && fetchSales.dateSelected) ||
          fetchSales.outletSelected.length > 0 ? (
          <>
            <IoMdClose />
            <Box> &nbsp; Clear all</Box>
          </>
        ) : null}
      </Grid>
    </Stack>
  );
};

export default DrawerHeader;
