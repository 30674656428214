import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Snackbar, Alert } from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";
import MiniDrawerStyled from "../../../Layout/MainLayout/Drawer/MiniDrawerStyled";
import DrawerHeader from "../../../Layout/MainLayout/Drawer/DrawerHeader";
import { useTheme } from "@mui/material/styles";
import InvoiceSidebar from "./InvoiceSideBar";
import { camelCaseToNormal } from "../../../utils/constant";
import MainCard from "../../../components/MainCard";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import SearchBar from "@mkyy/mui-search-bar";
import { Spin, Table, TableColumnsType, Tooltip } from "antd";
import InvoiceReviewOrder from "./InvoiceReviewOrder";
import debounce from 'lodash.debounce'
import {
  getOrderBillingInvoice,
  deleteOrder,
  convertBill,
  getTotalValue,
} from "../../../Redux/XDM/Sales/orderBillingReducer";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { getInvoiceCounts } from "../../../Redux/XDM/Sales/InvoiceReducer";

interface OrderBillingDatatype {
  orderId: string;
  date: string;
  outlet: string;
  route: string;
  salesman: string;
  grossAmount: number;
  schemeAmount: number;
  netAmount: number;
  noOfLineItems: string;
  lowStockOrder: boolean;
  invoiceNumber: string;
  tax: number;
  salesmanMobile:string;
}
interface ConvertBillType {
  selectedItems: string[];
  isSelectAll: string;
}
type TableRowType = OrderBillingDatatype | { noMoreData: boolean };
const roundValue = (label: string, val: number | string): string => {
  if (
    typeof val === "number" &&
    (label === "stockValue" || label === "marginValue")
  ) {
    return val?.toFixed(2);
  }
  return val?.toString();
};

interface DataProps {
  label: string;
  value: number | string | any;
}

const DataBox: React.FC<DataProps> = ({ label, value }) => {
  const shouldShowIcon = label === "totalOrderValue" || label === "billedValue";
  return (
    <MainCard border={false} boxShadow>
      <Stack
        spacing={1}
        justifyContent="center"
        alignItems="center"
        className={"card-box-counts " + label}
      >
        <Typography variant="h6" className="card-box-title">
          {camelCaseToNormal(label)}
        </Typography>
        <Typography variant="subtitle1" className="card-box-count">
          {shouldShowIcon && <CurrencyRupeeIcon sx={{ fontSize: 15 }} />}
          {roundValue(label, value)}
        </Typography>
      </Stack>
    </MainCard>
  );
};
const Invoices: React.FC = () => {
  const theme = useTheme();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isShow, setIsShow] = React.useState(false);
  const [textFieldValue, setTextFieldValue] = React.useState("");
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [orders, setOrders] = React.useState<OrderBillingDatatype[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [convertBillPopUp, setConvertBillPopUp] = useState(false);
  const [commonLoader, setCommonLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [totalOrderValue, setTotalOrderValue] = useState<number>(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const tableHeightRef = useRef(350);
  let orderBillingData = useAppSelector((state) => state.orderBillingData);
  let orderBillingDataFetch = orderBillingData.orderBilling;
  const filterTags = useAppSelector((state) => state.orderBilling);
  const dispatch = useAppDispatch();
  let orderCounts = useAppSelector((state) => state.invoiceData.orderCounts);
  const [invoiceRowSelectedRecords, setInvoiceRowSelectedRecords] = useState<React.Key[]>([]);
  const filterData = {
    page: 0,
    size: 0,
    stocks: filterTags.stockSelected.toString(),
    salesman: filterTags.salesmanSelected.toString(),
    routes: filterTags.routeSelected.toString(),
    outlets: filterTags.outletSelected.toString(),
    date: filterTags.dateSelected,
    startDate: filterTags.fromDateSelected,
    endDate: filterTags.toDateSelected,
    search: textFieldValue,
  };
  useEffect(() => {
    dispatch(getInvoiceCounts());
  }, []);

  useEffect(() => {
    setHasMore(true);
    setOrders([]);
    loadMoreData(true);
  }, [
    textFieldValue,
    filterTags.stockSelected,
    filterTags.salesmanSelected,
    filterTags.routeSelected,
    filterTags.outletSelected,
    filterTags.dateSelected,
    filterTags.fromDateSelected,
    filterTags.toDateSelected,
  ]);

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 283;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    window.addEventListener('resize', updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;

    dispatch(
      getOrderBillingInvoice({
        params: { ...filterData, page: nextPage },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const newOrders = response.payload.orders || [];
          setOrders((prevOrders) => (resetPage ? newOrders : [...prevOrders, ...newOrders]));
          setPage(nextPage + 1);
          if (newOrders.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        } else {
          // setHasMore(false);
        }

      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Failed to fetch data:', error);
        }
        setCommonLoader(false);
      });
      return () => {
        window.removeEventListener('resize', updateTableHeight);
      };
  };
  const data_sales: OrderBillingDatatype[] = orders ?? [];
  const filteredCount = orderBillingDataFetch.totalCount ?? 0;

  const dataRecord: OrderBillingDatatype[] = data_sales.map((order) => ({
    orderId: order.orderId,
    date: order.date,
    outlet: order.outlet,
    route: order.route,
    salesman: order.salesman,
    grossAmount: order.grossAmount,
    schemeAmount: order.schemeAmount,
    netAmount: order.netAmount,
    noOfLineItems: order.noOfLineItems,
    lowStockOrder: order.lowStockOrder,
    invoiceNumber: order.invoiceNumber,
    salesmanMobile:order.salesmanMobile,
    tax: order.tax,
  }));

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: OrderBillingDatatype[],
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setInvoiceRowSelectedRecords(newSelectedRowKeys);

    let newSelectedItems = selectedRows.map((row) => row.orderId);
    let newTotalOrderValue = selectedRows.reduce((total, row) => total + row.grossAmount, 0);

    setConvertBillReq((prevState) => ({
      ...prevState,
      selectedItems: newSelectedItems,
      selectAll: newSelectedRowKeys.length === dataRecord.length,
    }));

    setTotalOrderValue(newTotalOrderValue);
  };

  const [convertBillReq, setConvertBillReq] = useState<ConvertBillType>({
    selectedItems: [],
    isSelectAll: "false",
  });
  const handleMasterCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      const allSelectedKeys = dataRecord.map((_, index) => index);
      const allSelectedItems = dataRecord.map((item) => item.orderId);
      setSelectedRowKeys(allSelectedKeys);
      setConvertBillReq({
        isSelectAll: "true",
        selectedItems: allSelectedItems,
      });
      dispatch(getTotalValue())
        .then((response) => {
          // Update the state with the fetched data
          setTotalOrderValue(response.payload.totalOrderValue); // Adjust based on the structure of your payload
        })
        .catch((error) => {
          console.error("Failed to fetch order details:", error);
        });
      // setTotalOrderValue(orderCounts ? orderCount : 0);
    } else {
      setSelectedRowKeys([]);
      setConvertBillReq({
        isSelectAll: "false",
        selectedItems: [],
      });
      setTotalOrderValue(0);
    }
  };

  const handleRowClick = (record: OrderBillingDatatype) => {
    setRequestData(record);

    setIsShow(!isShow);
  }

  const handleDeleteClick = (index: number) => {
    setDeleteIndex(index);
    setOrderId(orders[index].orderId);
  };
  const handleDeleteConfirm = () => {
    if (deleteIndex !== null) {
      try {
        const orderToDelete = orders[deleteIndex];
        dispatch(deleteOrder(orderToDelete.orderId));

        setSelectedRowKeys((prevSelected) =>
          prevSelected.filter((key) => key !== deleteIndex)
        );
        loadMoreData(true);
        setDeleteIndex(null);

        setSnackbarMessage("Order Id :" + orderId + "  deleted successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage("Error deleting orderId" + orderId);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };
  const handleConvertToBill = async () => {
    setConvertBillPopUp(true);
  };
  const handleDeleteConfirmConvertPopup = async () => {
    try {
      const resultAction = await dispatch(convertBill(convertBillReq));
      if (convertBill.fulfilled.match(resultAction)) {
        setSnackbarMessage("Bill Converted Successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        loadMoreData(true);
        setConvertBillPopUp(false);
        setSelectedRowKeys([]);
      } else {
        setSnackbarMessage("Error converting Bill");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Error converting Bill");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  const handleDeleteCancel = () => {
    setDeleteIndex(null);
  };
  const handleDeleteCancelConvertPopUp = () => {
    setConvertBillPopUp(false);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [requestData, setRequestData] = React.useState({
    invoiceNumber: "",
    orderId: "",
    date: "",
    outlet: "",
    route: "",
    salesman: "",
    netAmount: 0,
    salesmanMobile:""
  });
  const columns: TableColumnsType<TableRowType> = [
    {
      dataIndex: "invoiceNumber",
      title: "Invoice Number",
      width: "15%",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record) return { children: null, props: { colSpan: 0 } };
        return record.invoiceNumber;
      },
    },
    {
      dataIndex: "orderId",
      title: "Order Id",
      width: "10%",
      render: (text: string | number, record: TableRowType) => {
        if ("noMoreData" in record) {
          return {
            children: <span className="fs-12 noMore" style={{ display: "block", margin: "10px" }}><b>No more data found...</b></span>,
            props: {
              colSpan: 8, // Adjust this number based on the total number of columns to span
            },
          };
        }
      return (
        <Tooltip placement="top" title={text} zIndex={999999}>
          <span>{text}</span>
        </Tooltip>
      );
      },
    },
    {
      dataIndex: "date",
      width: "9%",
      title: "Date",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
        return a.date.localeCompare(b.date);
      },
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record) return { children: null, props: { colSpan: 0 } };
        if (record.date) {
          const dateObj = moment(record.date, "YYYY-MM-DD");

          const formattedDate = dateObj.format("DD MMM YYYY");

          return <div>{formattedDate}</div>;
        }
        return "-";
      },
    },

    {
      dataIndex: "salesman",
      title: "Salesman Name",
      width: "12%",
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record) return { children: null, props: { colSpan: 0 } }; // Skip rendering for special row
        let displayText = "-";
        if (text) {
          displayText = text;
        }
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{displayText}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "route",
      title: "Route Name",
      width: "10%",
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record) return { children: null, props: { colSpan: 0 } };
        let displayText = "-";
        if (text) {
          displayText = text;
        }
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{displayText}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "outlet",
      title: "Outlet Name",
      width: "10%",
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record) return { children: null, props: { colSpan: 0 } };
        let displayText = "-";
        if (text) {
          displayText = text;
        }
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{displayText}</span>
          </Tooltip>
        );
      },
    },

    {
      dataIndex: "noOfLineItems",
      title: "No of Line Item",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record) return { children: null, props: { colSpan: 0 } };
        return record.noOfLineItems;
      },
    },
    {
      dataIndex: "netAmount",
      title: "Net Amount",

      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record) return { children: null, props: { colSpan: 0 } };
        return (
          <span>
            <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
            {record.netAmount ? record.netAmount?.toFixed(2) : "0"}
          </span>
        );
      },
    },

  ];


  // const dataToShow: TableRowType[] = hasMore ? orders : [...orders, { noMoreData: true }];
  const dataToShow: TableRowType[] =
    orders.length > 0
      ? hasMore
        ? orders
        : [...orders, { noMoreData: true }]
      : [];
  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector('.saleable-table-scroll .ant-table-body') as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const handleSearch = () => {
    setTextFieldValue(textFieldValue);
  };






  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      bgcolor="#eee"
    >
      <Box flex={1} p={0}>
        <MiniDrawerStyled variant="permanent" open={true} theme={theme}>
          <DrawerHeader />
          <InvoiceSidebar />
        </MiniDrawerStyled>
      </Box>
      <Box
        flex={8}
        className="margin-left-0px"
        sx={{ overflowY: "auto", height: "100vh", marginBottom: "100px" }}
      >
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={100000}
          color="green"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Card
          className="b-radius-0 main-card"
          style={{ marginTop: "2px", boxShadow: "none" }}
        >
          <Grid container spacing={3} justifyContent="start" p={2}>
            {" "}
            <Grid item xs={6} sm={6} md={9} lg={9} className="margin_tp_0">
              <h4
                style={{
                  marginTop: "0px",
                  marginLeft: "0px",
                  marginBottom: "0px",
                }}
              >
                <b>Invoices</b> ({filteredCount})
              </h4>
            </Grid>
            {/* <Grid item xs={6} sm={6} md={3} lg={3} style={{ display: "flex", justifyContent: "flex-end" }} className="margin_tp_0">
              <Button
                variant="contained"
                className={selectRowRecords ? "prnt_af_btn" : "print_bt_shadow"}
                sx={{ mr: 2 }}
                style={{ width: "45%", fontSize: "10px", borderRadius: "8px", height: "36px" }}
                type="submit"
                disabled={!selectRowRecords}
              >
                <img
                  src={`/assets/images/${selectRowRecords ? 'Bf_printer.png' : 'Af_printer.png'}`}
                  alt="Print"
                  style={{ marginRight: "8px", }}
                  width={18}
                />
                {selectRowRecords ? 'Bulk Print' : 'Print'}
              </Button>
            </Grid> */}
          </Grid>
          <Divider />
          <Grid container spacing={3} justifyContent="start" p={2}>
            <Grid container mt={4} m={2}>
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <h3
                  style={{
                    marginTop: "0px",
                    marginLeft: "8px",
                    marginBottom: "16px",
                  }}
                ></h3>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <SearchBar
                  className="search_box_saleable w-100px"
                  placeholder="Search"
                  value={textFieldValue}
                    onChange={(newValue) => {
                    setTextFieldValue(newValue);
                  }}
                  onCancelResearch={() => setTextFieldValue("")}
                  onSearch={(newValue) => {
                    handleSearch();
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} sm={12} md={12} lg={12} className="pt-0">
              {(filterTags.salesmanSelected.length !== 0 ||
                filterTags.routeSelected.length !== 0 ||
                filterTags.outletSelected.length !== 0 ||
                textFieldValue !== "") && (
                <p style={{ marginTop: "0px", marginBottom: "6px" }}>
                  Showing Results based on selected filters{" "}
                  <b>({filteredCount} Products)</b>
                </p>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="pad-top-0px">
              <div className="saleable-table-scroll order-bill">
                <Table
                  className={`Header-table saleable-table sale_last_hide scroll_cus_table elipsTable ${
                  orderBillingData.orderBillingLoading ? " nodataloader" : ""
                  }`}
                  // rowSelection={rowSelection}
                  columns={columns}
                  dataSource={dataToShow}
                  pagination={false}
                  loading={!page && orderBillingData.orderBillingLoading}
                  showSorterTooltip={false}
                  summary={() => {
                    return (
                      <>
                        {page !== 0 && orderBillingData.orderBillingLoading && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              index={1}
                              colSpan={8}
                              align="center"
                            >
                              <Spin />
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                      </>
                    );
                  }}
                  onRow={(record: TableRowType) => {
                    if ("noMoreData" in record) return {};
                    return {
                      onClick: () =>
                        handleRowClick(record as OrderBillingDatatype),
                    };
                  }}
                  scroll={{ y: tableHeightRef.current }}
                />
              </div>
            </Grid>
          </Grid>
        </Card>
        {isShow ? (
          <InvoiceReviewOrder
            onClose={() => setIsShow(false)}
            requestData={requestData}
          />
        ) : (
          <></>
        )}
      </Box>
      <Dialog
        open={deleteIndex !== null}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: "333px", padding: "12px 16px" }}>
          <DialogContentText
            className="dialog-title-remove"
            id="alert-dialog-description"
          >
            Are you sure you want to remove?
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "black", fontSize: "12px", marginTop: "4px" }}
          >
            Note:The following{" "}
            <span className="dialog-title-remove">Order ID:{orderId} </span>will
            be removed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="crt-btn-cancel"
            style={{
              height: "30px",
              width: "25%",
              marginRight: "3px",
              fontSize: "12px",
            }}
            onClick={handleDeleteCancel}
          >
            {" "}
            Cancel
          </Button>
          <Button
            variant="contained"
            className="crt-btn-colr"
            style={{ height: "30px", width: "25%", fontSize: "12px" }}
            type="submit"
            sx={{ mr: 2 }}
            onClick={handleDeleteConfirm}
          >
            {" "}
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={convertBillPopUp}
        onClose={handleDeleteCancelConvertPopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: "333px", padding: "12px 16px" }}>
          <DialogContentText
            className="dialog-title-remove"
            id="alert-dialog-description"
          >
            Are you sure you want to convert the orders to bill?
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "black", fontSize: "12px", marginTop: "4px" }}
          >
            <span className="dialog-title-remove">
              {selectedRowKeys.length}{" "}
            </span>{" "}
            orders of value{" "}
            <span className="dialog-title-remove">₹ {totalOrderValue} </span>{" "}
            will be converted to bill.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="crt-btn-cancel"
            style={{
              height: "30px",
              width: "25%",
              marginRight: "3px",
              fontSize: "12px",
            }}
            onClick={handleDeleteCancelConvertPopUp}
          >
            {" "}
            Cancel
          </Button>
          <Button
            variant="contained"
            className="crt-btn-colr"
            style={{ height: "30px", width: "25%", fontSize: "12px" }}
            type="submit"
            sx={{ mr: 2 }}
            onClick={handleDeleteConfirmConvertPopup}
          >
            {" "}
            convert
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default Invoices;