import SearchBar from "@mkyy/mui-search-bar";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState ,useRef} from "react";
import { IoClose } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import {
  getOutletMore,
  getOutletMoreInvoice,
  getOutletMoreReturn,
  getRouteMore,
  getRouteMoreInvoice,
  getSalesmanInvoiceMore,
  getSalesmanMore,
  getSalesmanReturnMore,
  openFilterModel,
  updateOutlet,
  updateOutletPop,
  updateOutletTemp,
  updateRoute,
  updateRoutePop,
  updateRouteTemp,
  updateSalesman,
  updateSalesmanPop,
  updateSalesmanTemp,
} from "../../../Redux/XDM/Sales/billingReducer";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import Masonry from "react-masonry-css";
import { Spin } from "antd";
import {
  getOrderBillDivisionMore,
  getOutletDivsionMore,
  getOutletGroupMore,
  updateOutletDivision,
  updateOutletDivisionPop,
  updateOutletDivisiontemp,
  updateOutletGroup,
  updateOutletGroupPop,
  updateOutletGrouptemp,
} from "../../../Redux/XDM/Sales/outletSideBarReducer";
import {
  getOutletsFilterMore,
  getSalesmanFilterMore,
} from "../../../Redux/XDM/Sales/collectionReducer";
import { getSalesmanWithInvoiceMore } from "../../../Redux/XDM/Sales/salesReturnReducer";
interface filterProps {
  title: string;
  status: string;
}
export default function FilterModal(props: filterProps) {
  const { title, status } = props;
  const alphabets = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode(65 + i)
  );

  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = useState<string>("");

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const fetch = useAppSelector((state) => state.orderBilling);
  const collectionsData = useAppSelector(
    (state) => state.collectionReducerData
  );
  const salesReturnData = useAppSelector((state) => state.salesReturnData);
  const outletFetch = useAppSelector((state) => state.outletSideBar);

  const handleClose = () => {
    dispatch(openFilterModel(false));
  };
  const popupRef = useRef<HTMLDivElement | null>(null); // To reference the popup container

  useEffect(() => {
    const handleClickOutside = (event : any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClose(); // Close the popup if clicked outside
      }
    };

    const handleWindowBlur = () => {
      handleClose(); // Close the popup if the window loses focus
    };

    // Add event listeners for click outside and window blur
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("blur", handleWindowBlur);

    // Clean up the event listeners when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("blur", handleWindowBlur);
    };
  }, [handleClose]);
  const handleToggleFilter = (value: string) => {
    let currentVal;
    let isValueInArray;
    let newVal;

    switch (title) {
      case "Salesman":
        currentVal = fetch.tempSalesmanSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item: any) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateSalesmanTemp(newVal));
        break;
      case "SalesmanInvoice":
        currentVal = fetch.tempSalesmanSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item: any) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateSalesmanTemp(newVal));
        break;
      case "SalesmanReturn":
        currentVal = fetch.tempSalesmanSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item: any) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateSalesmanTemp(newVal));
        break;
      case "SalesmanCollection":
        currentVal = fetch.tempSalesmanSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item: any) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateSalesmanTemp(newVal));
        break;
      case "SalesmanReturnInvoice":
        currentVal = fetch.tempSalesmanSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item: any) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateSalesmanTemp(newVal));
        break;
      case "Routes":
        currentVal = fetch.tempRouteSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item: any) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateRouteTemp(newVal));
        break;
      case "RoutesInvoice":
        currentVal = fetch.tempRouteSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item: any) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateRouteTemp(newVal));
        break;
      case "Outlets":
        currentVal = fetch.tempOutletSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item: any) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateOutletTemp(newVal));
        break;
      case "OutletCollection":
        currentVal = fetch.tempOutletSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item: any) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateOutletTemp(newVal));
        break;
      case "OutletsReturn":
        currentVal = fetch.tempOutletSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item: any) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateOutletTemp(newVal));
        break;
      case "OutletsInvoice":
        currentVal = fetch.tempOutletSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item: any) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateOutletTemp(newVal));
        break;
      case "Division":
        currentVal = outletFetch.tempdivisionSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item: any) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateOutletDivisiontemp(newVal));
        break;
      case "Division Order Bill":
        currentVal = outletFetch.tempdivisionSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item: any) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateOutletDivisiontemp(newVal));
        break;
      case "Outlet Group":
        currentVal = outletFetch.tempoutletGroupSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item: any) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateOutletGrouptemp(newVal));
        break;
      case "Outlet Group OrderBill":
        currentVal = outletFetch.tempoutletGroupSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item: any) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateOutletGrouptemp(newVal));
        break;
      default:
        break;
    }
  };

  let tempData: any[] = [];
  let filterOptions: any[] = [];

  switch (title) {
    case "Salesman":
      tempData = fetch.tempSalesmanSelected;
      filterOptions = fetch.salesmanMore;
      break;
    case "SalesmanInvoice":
      tempData = fetch.tempSalesmanSelected;
      filterOptions = fetch.salesmanMore;
      break;
    case "SalesmanReturn":
      tempData = fetch.tempSalesmanSelected;
      filterOptions = fetch.salesmanMore;
      break;
    case "SalesmanCollection":
      tempData = fetch.tempSalesmanSelected;
      filterOptions = collectionsData.salesmanMore;
      break;
    case "SalesmanReturnInvoice":
      tempData = fetch.tempSalesmanSelected;
      filterOptions = salesReturnData.salesmanMore;
      break;
    case "Routes":
      tempData = fetch.tempRouteSelected;
      filterOptions = fetch.routeMore;
      break;
    case "RoutesInvoice":
      tempData = fetch.tempRouteSelected;
      filterOptions = fetch.routeMore;
      break;
    case "Outlets":
      tempData = fetch.tempOutletSelected;
      filterOptions = fetch.outletMore;
      break;
    case "OutletCollection":
      tempData = fetch.tempOutletSelected;
      filterOptions = collectionsData.outletMore;
      break;
    case "OutletsInvoice":
      tempData = fetch.tempOutletSelected;
      filterOptions = fetch.outletMore;
      break;
    case "OutletsReturn":
      tempData = fetch.tempOutletSelected;
      filterOptions = fetch.outletMore;
      break;
    case "Division":
      tempData = outletFetch.tempdivisionSelected;
      filterOptions = outletFetch.divisionMore;
      break;
    case "Division Order Bill":
      tempData = outletFetch.tempdivisionSelected;
      filterOptions = outletFetch.divisionMore;
      break;
    case "Outlet Group":
      tempData = outletFetch.tempoutletGroupSelected;
      filterOptions = outletFetch.outletGroupMore;
      break;
    case "Outlet Group OrderBill":
      tempData = outletFetch.tempoutletGroupSelected;
      filterOptions = outletFetch.outletGroupMore;
      break;
    default:
      break;
  }
  const alphaChangeOrder = Object.keys(filterOptions)
    .filter((key) => alphabets.includes(key))
    .sort();
  useEffect(() => {
    const filterData = {
      page: 0,
      size: 4,
      pageStatus: false,
    };
    const filterDataOrderBill = {
      page: 0,
      size: 4,
      pageStatus: false,
      status: status,
    };
    switch (title) {
      case "Salesman":
        dispatch(getSalesmanMore(filterDataOrderBill));
        break;
      case "SalesmanInvoice":
        dispatch(getSalesmanInvoiceMore(filterData));
        break;
      case "SalesmanReturn":
        dispatch(getSalesmanReturnMore(filterDataOrderBill));
        break;
      case "SalesmanCollection":
        dispatch(getSalesmanFilterMore(filterDataOrderBill));
        break;
      case "SalesmanReturnInvoice":
        dispatch(getSalesmanWithInvoiceMore(filterDataOrderBill));
        break;
      case "Routes":
        dispatch(getRouteMore(filterDataOrderBill));
        break;
      case "RoutesInvoice":
        dispatch(getRouteMoreInvoice(filterDataOrderBill));
        break;
      case "Outlets":
        dispatch(getOutletMore(filterDataOrderBill));
        break;
      case "OutletCollection":
        dispatch(getOutletsFilterMore(filterDataOrderBill));
        break;
      case "OutletsInvoice":
        dispatch(getOutletMoreInvoice(filterData));
        break;
      case "OutletsReturn":
        dispatch(getOutletMoreReturn(filterDataOrderBill));
        break;
      case "Outlet Group":
        dispatch(getOutletGroupMore(filterData));
        break;
      case "Outlet Group OrderBill":
        dispatch(getOutletGroupMore(filterData));
        break;
      case "Division":
        dispatch(getOutletDivsionMore(filterData));
        break;
      case "Division Order Bill":
        dispatch(getOrderBillDivisionMore(filterDataOrderBill));
        break;
      default:
        break;
    }
  }, [title]);

  const setSelectedSubmit = () => {
    switch (title) {
      case "Salesman":
        dispatch(updateSalesman(tempData));
        dispatch(updateSalesmanPop(tempData));
        break;
      case "SalesmanInvoice":
        dispatch(updateSalesman(tempData));
        dispatch(updateSalesmanPop(tempData));
        break;
      case "SalesmanReturn":
        dispatch(updateSalesman(tempData));
        dispatch(updateSalesmanPop(tempData));
        break;
      case "SalesmanCollection":
        dispatch(updateSalesman(tempData));
        dispatch(updateSalesmanPop(tempData));
        break;
      case "SalesmanReturnInvoice":
        dispatch(updateSalesman(tempData));
        dispatch(updateSalesmanPop(tempData));
        break;
      case "Routes":
        dispatch(updateRoute(tempData));
        dispatch(updateRoutePop(tempData));
        break;
      case "RoutesInvoice":
        dispatch(updateRoute(tempData));
        dispatch(updateRoutePop(tempData));
        break;
      case "Outlets":
        dispatch(updateOutlet(tempData));
        dispatch(updateOutletPop(tempData));
        break;
      case "OutletCollection":
        dispatch(updateOutlet(tempData));
        dispatch(updateOutletPop(tempData));
        break;
      case "OutletsReturn":
        dispatch(updateOutlet(tempData));
        dispatch(updateOutletPop(tempData));
        break;
      case "OutletsInvoice":
        dispatch(updateOutlet(tempData));
        dispatch(updateOutletPop(tempData));
        break;
      case "Outlet Group":
        dispatch(updateOutletGroup(tempData));
        dispatch(updateOutletGroupPop(tempData));
        break;
      case "Outlet Group OrderBill":
        dispatch(updateOutletGroup(tempData));
        dispatch(updateOutletGroupPop(tempData));
        break;
      case "Division":
        dispatch(updateOutletDivision(tempData));
        dispatch(updateOutletDivisionPop(tempData));
        break;
      case "Division Order Bill":
        dispatch(updateOutletDivision(tempData));
        dispatch(updateOutletDivisionPop(tempData));
        break;
      default:
        break;
    }
  };
  return (
    <Box
    ref={popupRef}
      className="sidebarModelContainerSales"
      sx={{ width: { sm: "70%", lg: " 68%", xl: "60%" } }}
    >
      <Box
        sx={{
          justifyContent: "space-between",
          display: "flex",
          alignContent: "flex-end",
        }}
      >
        <Typography
          sx={{
            fontSize: "11.5px !important",
            fontWeight: "600",
            color: "#000000",
            padding: "20px 0px 0px 19px",
          }}
        >
          {title === "SalesmanInvoice" ||
          title === "SalesmanReturn" ||
          title === "SalesmanCollection" ||
          title === "SalesmanReturnInvoice"
            ? "Salesman"
            : title === "OutletsInvoice" ||
              title === "OutletsReturn" ||
              title === "OutletCollection"
            ? "Outlets"
            : title === "RoutesInvoice"
            ? "Routes"
            : title === "Outlet Group OrderBill"
            ? "Outlet Group"
            : title === "Division Order Bill"
            ? "Division"
            : title}
        </Typography>
        <Box
          className="close_icon_btn cursor_pointer"
          sx={{
            display: "flex",
            justifyContent: "end",
            padding: "20px 23px 0px 0px",
          }}
          onClick={handleClose}
        >
          <IoClose className="ClosePop" />
        </Box>
      </Box>

      <Box
        sx={{
          borderBottom: "2px solid #b6b0b01a",
          width: "95%",
          marginLeft: "18px",
          padding: "6px",
        }}
      ></Box>

      <Box
        sx={{
          justifyContent: "space-between",
          display: "flex",
          alignContent: "flex-end",
          marginTop: "10px",
          padding: "10px 10px 0px 0px",
          alignItems: "baseline",
        }}
      >
        <SearchBar
          onChange={(value) => handleSearch(value)}
          value={searchValue}
          onCancelResearch={() => setSearchValue("")}
          className="sidePopSearchAlpha"
        />
        {alphabets.map((alphabet, index) => (
          <Typography
            key={index}
            sx={{
              fontSize: "10px",
              fontWeight: "400",
              color: "#944986",
            }}
          >
            {alphabet}
          </Typography>
        ))}
      </Box>

      <Box className="scrollSalesPop">
        <Grid item>
          {filterOptions.length !== 0 ? (
            <Masonry
              breakpointCols={{ default: 4 }}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {alphaChangeOrder.map(
                (alphabet: any, index: number) =>
                  filterOptions[alphabet] &&
                  filterOptions[alphabet].some((option: any) =>
                    title === "Outlet Group" ||
                    title === "Division" ||
                    title === "Division Order Bill" ||
                    title === "Outlet Group OrderBill"
                      ? option.toLowerCase().includes(searchValue.toLowerCase())
                      : option.name
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                  ) && (
                    <Grid item sm={3} key={index}>
                      <Box pl={2}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontWeight: "bold",
                            fontSize: "12px",
                            marginLeft: "10px",
                          }}
                        >
                          {alphabet}
                        </Typography>

                        {filterOptions[alphabet]
                          .filter((option: any) =>
                            title === "Outlet Group" ||
                            title === "Division" ||
                            title === "Division Order Bill" ||
                            title === "Outlet Group OrderBill"
                              ? option
                                  .toLowerCase()
                                  .includes(searchValue.toLowerCase())
                              : option.name
                                  .toLowerCase()
                                  .includes(searchValue.toLowerCase())
                          )
                          .map((name: any, index: number) => (
                            <Grid
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <FormControlLabel
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 15,
                                    color: "#6750A4",
                                  },
                                  marginLeft: "0px",
                                  "& .MuiFormControlLabel-label": {
                                    width: "100px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  },
                                }}
                                key={name}
                                control={
                                  <Checkbox
                                    checked={tempData.includes(
                                      title === "Outlet Group" ||
                                        title === "Division" ||
                                        title === "Division Order Bill" ||
                                        title === "Outlet Group OrderBill"
                                        ? name
                                        : name.code
                                    )}
                                    onChange={() =>
                                      handleToggleFilter(
                                        title === "Outlet Group" ||
                                          title === "Division" ||
                                          title === "Division Order Bill" ||
                                          title === "Outlet Group OrderBill"
                                          ? name
                                          : name.code
                                      )
                                    }
                                  />
                                }
                                label={
                                  title === "Outlet Group" ||
                                  title === "Division" ||
                                  title === "Division Order Bill" ||
                                  title === "Outlet Group OrderBill"
                                    ? name
                                    : name.name
                                }
                              />
                            </Grid>
                          ))}
                      </Box>
                    </Grid>
                  )
              )}
            </Masonry>
          ) : (
            <Grid container>
              <Grid xs={4}></Grid>
              <Grid xs={4} sx={{ textAlign: "center" }}>
                {" "}
                <Spin size="small" />
              </Grid>
              <Grid xs={4}></Grid>
            </Grid>
          )}
        </Grid>
      </Box>

      <Grid
        container
        xs={12}
        item
        mt={3}
        mb={2}
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Grid item sm={3} lg={2} onClick={handleClose}>
          <Button
            className="Close_button"
            variant="outlined"
            sx={{ fontSize: "11px" }}
          >
            Close
          </Button>
        </Grid>
        <Grid item sm={3} lg={2}>
          <Button
            className="btn-colr "
            variant="contained"
            style={{ width: "75%", fontSize: "11px", height: "34px" }}
            onClick={() => {
              handleClose();
              setSelectedSubmit();
            }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
