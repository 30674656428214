import React, { useState } from "react";
import Box from "@mui/material/Box";
//icons
import Close from "../../../../assets/images/icons/Close_icon.png";
import {
  Button,
  Drawer,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface SalesHierarchyCreateProps {
  onClose: () => void;
}

export default function SalesHierarchyCreate(props: SalesHierarchyCreateProps) {
  const { onClose } = props;
  const Sales = ["aaa", "bb"];
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [regionalValue, setRegionalValue] = useState<string>("");
  const [zonalValue, setZonalValue] = useState<string>("");
  const [cityValue, setCityValue] = useState<string>("");

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedValue(event.target.value);
  };
  const handleChangeRegional = (event: SelectChangeEvent<string>) => {
    setRegionalValue(event.target.value);
  };
  const handleChangeZonal = (event: SelectChangeEvent<string>) => {
    setZonalValue(event.target.value);
  };
  const handleChangeSDE = (event: SelectChangeEvent<string>) => {
    setCityValue(event.target.value);
  };

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        // variant="persistent"
        className="drawer_stl"
      >
        <Box
          sx={{
            width: {
              lg: 530,

              md: 536,
              sm: 740,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "95%" }} role="presentation">
            <Grid container className="geo-header">
              <Typography gutterBottom className="geo-header-lable">
                Create Zonal Sales Manager
              </Typography>

              <Grid item>
                <img
                  src={Close}
                  alt={Close}
                  onClick={() => {
                    onClose();
                  }}
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Content */}
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form"
          >
            {/* Your scrollable content */}
            <form>
              <Grid
                item
                sx={{ width: "95%", margin: "auto", marginBottom: "100px" }}
              >
                <Grid item>
                  <Typography className="geo-fnt-lable">
                    Head of Sales *
                  </Typography>
                  <Grid item xs={12} sx={{ mt: "5px" }}>
                    <FormControl fullWidth>
                      <Select
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        displayEmpty
                        value={selectedValue}
                        onChange={handleChange}
                      >
                        <MenuItem
                          className="select_placeholder"
                          disabled
                          value=""
                        >
                          <em>Select Head of Sales</em>
                        </MenuItem>
                        {Sales.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="geo-fnt-lable">
                    Regional Sales Manager *
                  </Typography>
                  <Grid item xs={12} mt={1}>
                    <FormControl fullWidth>
                      <Select
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        displayEmpty
                        value={regionalValue}
                        onChange={handleChangeRegional}
                      >
                        <MenuItem
                          className="select_placeholder"
                          disabled
                          value=""
                        >
                          <em>Select Regional sales </em>
                        </MenuItem>
                        {Sales.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item mt={2}>
                  <Typography className="geo-fnt-lable">
                    Zonal Sales Manager *
                  </Typography>
                  <Grid item xs={12} mt={1}>
                    <FormControl fullWidth>
                      <Select
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        displayEmpty
                        value={zonalValue}
                        onChange={handleChangeZonal}
                      >
                        <MenuItem
                          className="select_placeholder"
                          disabled
                          value=""
                        >
                          <em>Select State </em>
                        </MenuItem>
                        {Sales.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item mt={2}>
                  <Typography className="geo-fnt-lable">
                    Area Sales Manager *
                  </Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="sales-txt-box"
                      placeholder=" Enter District  "
                      type="text"
                      name={"District"}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="geo-fnt-lable">SDE/TSO *</Typography>
                  <Grid item xs={12} mt={1}>
                    <FormControl fullWidth>
                      <Select
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        displayEmpty
                        value={cityValue}
                        onChange={handleChangeSDE}
                      >
                        <MenuItem
                          className="select_placeholder  "
                          disabled
                          value=""
                        >
                          <em>Select City/Town </em>
                        </MenuItem>
                        {Sales.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item className="geo_Create-footer">
                <Button className="crt-btn-cancel" onClick={onClose}>
                  {" "}
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="crt-btn-colr"
                  type="submit"
                  sx={{ mr: 4 }}
                >
                  {" "}
                  Create
                </Button>
              </Grid>
            </form>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
