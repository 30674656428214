import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import createAxiosInstance from "../../../hooks/useFetch";
import qs from "qs";

interface CollectionOutletData {
  invoiceNumber: string;
  outStandingAmount: number;
}

interface InvoiceDetailsData {
  invoiceDetails: CollectionOutletData[];
  totalOutStandingAmount: number;
  outletCode: string;
  routeCode: string;
}

interface PendingPaymentRequest {
  paymentType: string;
  page: number;
  size: number;
  dateType: string;
  fromDate: string;
  toDate: string;
  search: string;
  salesmanId: string;
  outletId: string[];
}

interface SalesReturnProduct {
  productCode: string;
  productName: string;
  batchCode: string;
  expireDate: string;
  qty: number;
  mrp: number;
  returnStatus: string;
  reason: string;
  pc: number;
  value: number;
  img: string[]; // Array of image URLs
  uom: string;
}

interface ChequeDetailsDataType {
  bankName: string;
  chequeNumber: string;
  chequeDate: string;
}

interface ChequeUpdateDataList {
  bankName: string;
  chequeNumber: string;
  chequeDate: string;
  collectionId: string;
}

interface CashDominationRequest {
  collectionSumId: string[];
}

interface CashDominationResponse {
  totalAmount: number;
  "1_count": number;
  "1_total_value": number;
  "2_count": number;
  "2_total_value": number;
  "5_count": number;
  "5_total_value": number;
  "10_count": number;
  "10_total_value": number;
  "20_count": number;
  "20_total_value": number;
  "50_count": number;
  "50_total_value": number;
  "100_count": number;
  "100_total_value": number;
  "200_count": number;
  "200_total_value": number;
  "500_count": number;
  "500_total_value": number;
}

interface CollectionState {
  collectionOultetDetialsData: InvoiceDetailsData;
  collectionOultetDetialsDataLoading: boolean;
  collectionOultetDetialsDataError: string | null;

  collectionHistoryData: CollectionHistoryData;
  collectionHistoryDataLoading: boolean;
  collectionHistoryDataError: string | null;

  chequeAck: ChequeAckData;
  chequeAckLoading: boolean;
  chequeAckError: string | null;

  collectionHistoryCounts: collectionHistoryCounts;
  collectionHistoryCountsLoading: boolean;
  collectionHistoryCountsError: string | null;

  chequeupdateData: ChequeUpdateDataList[];
  chequeupdateDataLoading: boolean;
  chequeupdateDataError: string | null;

  singlecollectionHistory: {
    outletName: string;
    invoiceNumbers: [];
    collectionType: string;
    salesmanName: string;
    collectionDate: string;
    status: string;
    collectionNumber: string;
    amountCollected: number;
    totalCollectedAmount: number;
    outstandingAmt: number;
    paymentMode: string;
    checkDate: string;
    invoiceDetailsCollected: InvoiceNoDetails[];
    chequeDetails: ChequeDetailsDataType;
    images: string;
    receivedDate: string;
    img: [];
  };

  collectionPaymentList: PendingPaymentData;
  collectionPaymentListLoading: boolean;
  collectionPaymentListError: string | null;

  collectionCashPaymentList: PendingCashPaymentData;

  salesmanFilter: any;
  outletFilter: any;
  selectedCollectionData: any;
  selectedCollectionDataLoading: boolean;

  pendingCollectionCounts: pendingCollectionCountsData;
  pendingCollectionCountsLoading: boolean;
  pendingCollectionCountsError: string | null;

  pendingCollectionData: PendingCollectionData;
  pendingCollectionDataLoading: boolean;
  pendingCollectionDataError: string | null;

  pendingPaymentCounts: PendingPaymentCardData;
  pendingPaymentCountsLoading: boolean;
  pendingPaymentCountsError: string | null;

  collectionRejectAcceptData: RejectApprovedListResponse;
  collectionRejectAcceptDataLoading: boolean;
  collectionRejectAcceptDataError: string | null;

  collectionStoreData: boolean;

  collectionOutlet: collectionDataOutlet[];
  collectionOutletLoading: boolean;
  collectionOutletError: string | null;

  salesman: collectionDatasalesman[];
  salesmanLoading: boolean;
  salesmanError: string | null;

  salesmanMore: any;
  loadingMore: boolean;
  outletMore: any;

  bankListData: collectionBankList[];
  bankListDataLoading: boolean;
  bankListDataError: string | null;

  chequeCardCounts: ChequeAckCardCount;
  chequeCardCountsLoading: boolean;
  chequeCardCountsError: string | null;

  chequeApproveOrBounce: ChequeAckApproveOrBounce[];
  chequeApproveOrBounceLoading: boolean;
  chequeApproveOrBounceError: string | null;

  cashDominationData: CashDominationResponse;
  cashDominationLoading: boolean;
  cashDominationError: string | null;

  collectionChequeRejectAcceptData: ChequeRejectApprovedListResponse[];
  collectionChequeRejectAcceptDataLoading: boolean;
  collectionChequeRejectAcceptDataError: string | null;

  smTotalCollection: SmTotalCollection;
  smTotalCollectionLoading: boolean;
  smTotalCollectionError: string | null;

  saveCollectionResponseData: any;
  saveCollectionResponseLoading: boolean;
  saveCollectionResponseError: string | null;

  loading: boolean;
  error: string | null;

  salesmanDetails: salesmanRequest;
}

interface CollectionHistoryRequest {
  page: number;
  size: number;
  fromDate: string;
  toDate: string;
  status: string;
  search: string;
  salesMan: string;
  outlet: string;
  paymentMode: string;
  dateFilterType: string;
}

interface ChequeAckRequest {
  page: number;
  size: number;
  fromDate: string;
  toDate: string;
  search: string;
  salesMan: string[];
  outlet: string[];
  dateType: string;
}

interface CollectionHistoryTableData {
  outlet: string;
  invoiceNumber: string[];
  salesMan: string;
  collectionDate: string;
  status: string;
  collectionNumber: string;
  amountCollected: string;
  outstandingAmount: number;
  paymentMode: string;
  isAcknowledged: boolean;
  routeCode: string;
  receivedDate: string;
}

interface ChequeAckTableData {
  chequeNumber: string;
  salesManName: string;
  collectionId: string;
  bankName: string;
  chequeDate: string;
  img: [];
  outletName: string;
  totalCollectedAmount: number;
}

interface CollectionHistoryData {
  result: CollectionHistoryTableData[];
  totalCount: number;
}

interface ChequeAckData {
  result: ChequeAckTableData[];
  totalCount: number;
}

interface collectionHistoryCounts {
  totalReceivedAmt: number;
  upiCount: number;
  upiReceivedAmt: number;
  cashCount: number;
  cashReceivedAmt: number;
  chequeCount: number;
  chequeReceivedAmt: number;
}

export interface SaveCollectionRequest {
  collectionType: string;
  totalCollectedAmount: number;
  smUserId: string;
  invoiceDetails: InvoiceDetail[];
  images: File[];
  chequeDetails?: ChequeDetails;
}
interface PendingCollectionRequest {
  page: number;
  size: number;
  fromDate: string;
  toDate: string;
  search: string;
  dateType: string;
  salesmanId: string[];
}
interface PendingPaymentCardRequest {
  paymentType: string;
  salesmanId: string;
}

interface UpdateChequeRequest {
  chequeNumber: string;
  collectionId: string;
  bankName: string;
  chequeDate: string;
}

interface RejectAcceptListRequest {
  pendingList: {
    paymentType: string;
    collectionNumber: string;
    status: string;
    remarks: string;
  }[];
}

interface ChequeRejectAcceptListRequest {
  chequeList: {
    collectionNumber: string;
    status: string;
    remarks: string;
    validRemarksForRejection: boolean;
  }[];
}

export interface InvoiceDetail {
  invoiceNo: string;
  collectedAmt: number;
  outletCode: string;
  routeCode: string;
}

interface ChequeDetails {
  chequeNumber: string;
  bankName: string;
  bankBranch: string;
}
// export interface CashDetails {
//   denomination500: number;
//   denomination200: number;
//   denomination100: number;
//   denomination50: number;
//   denomination20: number;
//   denomination10: number;
//   denomination5: number;
//   denomination2: number;
//   denomination1: number;
// }

interface FilterDataRequestUpdated {
  page: number;
  size: number;
  pageStatus: boolean;
  status: string;
}
interface PendingCollectionTableData {
  salesManId: string;
  salesManName: string;
  mobile: string;
  collectionAmount: number;
  invoiceCount: number;
}

interface PendingCollectionData {
  result: PendingCollectionTableData[];
  totalCount: number;
}

interface PendingPaymentTableData {
  collectionNumber: string;
  invoiceNumber: string[];
  collectionDate: string;
  outletName: string;
  outStandingAmount: number;
  amountCollected: number;
  img: string[];
  chequeNumber: string;
  bankName: string;
  chequeDate: string;
  collectionSumNo: string;
}

interface PendingPaymentData {
  result: PendingPaymentTableData[];
  totalCount: number;
}

interface PendingCashPaymentData {
  result: PendingPaymentTableData[];
  totalCount: number;
  denomination: CashDominationResponse;
}

interface pendingCollectionCountsData {
  totalAmtCollected: number;
  upiCount: number;
  upiCollection: number;
  cashCount: number;
  cashCollection: number;
  chequeCount: number;
  chequeCollection: number;
}

interface InvoiceNoDetails {
  invoiceNumber: string;
  collectedAmount: number;
}

interface SingleCollectionDatatype {
  outletName: string;
  invoiceNumbers: [];
  collectionType: string;
  salesmanName: string;
  collectionDate: string;
  status: string;
  collectionNumber: string;
  amountCollected: number;
  outstandingAmt: number;
  totalCollectedAmount: number;
  paymentMode: string;
  checkDate: string;
  invoiceDetailsCollected: InvoiceNoDetails[];
  chequeDetails: ChequeDetailsDataType;
  images: string;
  receivedDate: string;
  img: [];
}

interface getCollectionDataType {
  collectionId: string;
}
interface PendingPaymentCardData {
  totalCollection: number;
  totalCollectionAmt: number;
}

interface salesmanRequest {
  salesManId: string;
  salesManName: string;
  mobile: string;
}
interface RejectApprovedListResponse {
  pendingList: {
    paymentType: string;
    collectionNumber: string;
    status: string;
    remarks: string;
  }[];
}

interface ChequeRejectApprovedListResponse {
  [key: string]: string;
}
interface collectionDataOutlet {
  outletCode: string;
  routeCode: string;
  outletName: string;
  address: string;
  routeName: string;
}
interface collectionDatasalesman {
  smUserId: string;
  userName: string;
}
interface collectionBankList {
  id: string;
  bankName: string;
}
interface ChequeAckCardCount {
  totalChequeCount: number;
  totalChequeCollection: number;
  inProgressCount: number;
  inProgressCollection: number;
  pendingChequeCount: number;
  pendingChequeCollection: number;
}
interface SmTotalCollection {
  totalCollectedAmount: number;
  totalCount: number;
}

interface ChequeAckApproveOrBounce {
  collectionId: string;
  status: string;
}

interface ChequeAckDetailsDataType {
  collectionDetails: ChequeAckApproveOrBounce[];
}

const axiosInstance = createAxiosInstance();

export const getOutletDetailsData = createAsyncThunk(
  "collection/get-collectionData",
  async (outletCode: string) => {
    const response = await axiosInstance.get(
      `collection/outlet-invoice-details?outletCode=${outletCode}`
    );
    return response.data.data as InvoiceDetailsData; // Ensure correct type is returned
  }
);

export const getPendingPaymentList = createAsyncThunk(
  "collection/getPending-Payment-Collection",
  async (req: { params: PendingPaymentRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(
      "/collection/getPending-Payment-Collection",
      {
        params,
        signal,
      }
    );
    return response.data.data;
  }
);

export const getPendingCashPaymentList = createAsyncThunk(
  "collection/cash-pending-List",
  async (req: { params: PendingPaymentRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get("/collection/cash-pending-List", {
      params,
      signal,
    });
    return response.data.data;
  }
);

export const saveCollection = createAsyncThunk(
  "collection/save-new-collection",
  async (
    req: { formData: FormData; signal: AbortSignal },
    { rejectWithValue }
  ) => {
    try {
      const { formData, signal } = req;
      const response = await axiosInstance.post(
        "collection/save-new-collection",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          signal,
        }
      );
      return response.data.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        message: error.message || "Something went wrong",
      });
    }
  }
);

export const getCollectionHistory = createAsyncThunk(
  "collection/get-collection-history",
  async (req: { params: CollectionHistoryRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(
      "/collection/get-collection-history",
      {
        params,
        signal,
      }
    );
    return response.data.data;
  }
);

export const getCollectionHistoryCard = createAsyncThunk(
  "collection/history-card-count",
  async (req: { params: CollectionHistoryRequest }) => {
    const { params } = req;
    const response = await axiosInstance.get("/collection/history-card-count", {
      params,
    });
    return response.data.data;
  }
);

export const getSalesman = createAsyncThunk(
  "collection/salesman-filter",
  async (req: FilterDataRequestUpdated) => {
    const response = await axiosInstance.get("/collection/salesman-filter", {
      params: req,
    });
    return response;
  }
);

export const getSalesmanFilterMore = createAsyncThunk(
  "collection/salesman-filter-more",
  async (req: FilterDataRequestUpdated) => {
    const response = await axiosInstance.get("/collection/salesman-filter", {
      params: req,
    });
    return response;
  }
);

export const getOutlets = createAsyncThunk(
  "collection/get-outlets",
  async (req: FilterDataRequestUpdated) => {
    const response = await axiosInstance.get("/collection/get-outlets", {
      params: req,
    });
    return response;
  }
);

export const getOutletsFilterMore = createAsyncThunk(
  "collection/get-outlets-more",
  async (req: FilterDataRequestUpdated) => {
    const response = await axiosInstance.get("/collection/get-outlets", {
      params: req,
    });
    return response;
  }
);

export const getPendingCollectionCard = createAsyncThunk(
  "collection/pending-card-count",
  async (req: { params: PendingCollectionRequest }) => {
    const { params } = req;
    const response = await axiosInstance.get("/collection/pending-card-count", {
      params,
    });
    return response.data.data;
  }
);

export const getPendingCollection = createAsyncThunk(
  "collection/pending-collection",
  async (req: { params: PendingCollectionRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get("/collection/pending-collection", {
      params,
      signal,
    });
    return response.data.data;
  }
);

export const getSingleCollectionHistory = createAsyncThunk(
  "collection/get-single-collection-history",
  async (req: { params: getCollectionDataType }) => {
    const { params } = req;
    const response = await axiosInstance.get(
      "/collection/get-single-collection-history",
      {
        params,
      }
    );
    return response.data.data;
  }
);

export const getPendingPaymentCardCount = createAsyncThunk(
  "collection/pending-payment-card-count",
  async (req: { params: PendingPaymentRequest }) => {
    const { params } = req;
    const response = await axiosInstance.get(
      "/collection/pending-payment-card-count",
      {
        params,
      }
    );
    return response.data.data;
  }
);
export const setSeletedCollectionData = createAsyncThunk(
  "sales-return/set-selected-collection",
  async (req: any) => {
    return req;
  }
);
export const getRejectAcceptList = createAsyncThunk(
  "collection/Pending-Accept-or-Reject",
  async (
    req: { params: RejectAcceptListRequest; signal: AbortSignal },
    { rejectWithValue }
  ) => {
    try {
      const { params, signal } = req;
      const response = await axiosInstance.post(
        "collection/Pending-Accept-or-Reject",
        params,
        {
          signal,
        }
      );
      return response.data.data; // Return the necessary data from response
    } catch (error: any) {
      // Check if error has a response and return a detailed error payload
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        message: error.message || "Something went wrong",
      });
    }
  }
);
export const getChequeRejectAcceptList = createAsyncThunk(
  "collection/cheque-pending-approve-reject",
  async (
    req: {
      params: ChequeRejectAcceptListRequest;
      signal: AbortSignal;
    },
    { rejectWithValue }
  ) => {
    //getChequeRejectAcceptList
    try {
      const { params, signal } = req;
      const response = await axiosInstance.post(
        "collection/cheque-pending-approve-reject",
        params,
        {
          signal,
        }
      );
      return response.data.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        message: error.message || "Something went wrong",
      });
    }
  }
);
export const setCollectionData = createAsyncThunk(
  "collection/set-collection-data",
  async (req: any) => {
    return req;
  }
);
export const getCollectionOutlet = createAsyncThunk(
  "collection/get-outlet-details",
  async (search: string | null) => {
    const response = await axiosInstance.get(
      `collection/get-outlet-details?search=${search}`
    );
    return response.data.data;
  }
);
export const getCollectionSalesman = createAsyncThunk(
  "collection/get-all-salesman",
  async (outletCode: string) => {
    const response = await axiosInstance.get(
      `collection/get-all-salesman?outletCode=${outletCode}`
    );
    return response.data.data as collectionDatasalesman[];
  }
);
export const getBankList = createAsyncThunk(
  "collection/get-bank-details",
  async (any) => {
    const response = await axiosInstance.get(`collection/get-bank-details`);
    return response.data.data as collectionBankList[];
  }
);

//cheque ack
export const getChequeAck = createAsyncThunk(
  "collection/get-check-acknowledgment",
  async (req: { params: ChequeAckRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(
      "/collection/get-check-acknowledgment",
      {
        params,
        signal,
      }
    );
    return response.data.data;
  }
);

export const getChequeAckCardCount = createAsyncThunk(
  "collection/cheque-card-count",
  async (req: { params: ChequeAckRequest }) => {
    const { params } = req;
    const response = await axiosInstance.get("/collection/cheque-card-count", {
      params,
    });
    return response.data.data;
  }
);

export const updateChequeDetails = createAsyncThunk(
  "collection/edit-cheque",
  async (
    req: { params: UpdateChequeRequest; signal: AbortSignal },
    thunkAPI
  ) => {
    try {
      const { params, signal } = req;
      const response = await axiosInstance.put(
        `collection/edit-cheque?collectionId=${params.collectionId}&chequeNumber=${params.chequeNumber}&chequeDate=${params.chequeDate}&bankName=${params.bankName}`,
        {
          signal,
        }
      );
      return response.data.data; // Assuming `data` is the relevant data
    } catch (error: any) {
      // Handle error and return the error message
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to update cheque details"
      );
    }
  }
);

export const getChequeApproveOrBounce = createAsyncThunk(
  "collection/check-approve-reject",
  async (req: { formData: ChequeAckDetailsDataType; signal: AbortSignal }) => {
    const { formData, signal } = req;
    const response = await axiosInstance.post(
      "collection/check-approve-reject",
      formData
    );
    return response.data.data;
  }
);

export const getCashDomination = createAsyncThunk(
  "collection/get-cash-denomination",
  async (req: { params: CashDominationRequest; signal: AbortSignal }) => {
    const { params, signal } = req;

    const response = await axiosInstance.get(
      `/collection/get-cash-denomination`,
      {
        params,
        signal,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" }); // Format array as collectionSumId=value1&collectionSumId=value2
        },
      }
    );
    return response.data.data;
  }
);

export const getTotalCollectionBasedSmId = createAsyncThunk(
  "collection/get-pending-total",
  async (salesManId: string) => {
    const response = await axiosInstance.get(
      `collection/get-pending-total?salesManId=${salesManId}`
    );
    return response.data.data;
  }
);

export const updatePendingCard = createAsyncThunk(
  "filter/updatePendingCard",
  async (req: PendingPaymentCardData) => {
    return req;
  }
);

export const setSalesmanDetails = createAsyncThunk(
  "salesman/salesmanDetails",
  async (req: salesmanRequest) => {
    return req;
  }
);

const initialState: CollectionState = {
  collectionHistoryData: { result: [], totalCount: 0 },
  collectionHistoryDataLoading: false,
  collectionHistoryDataError: null,
  collectionHistoryCounts: {
    totalReceivedAmt: 0,
    upiCount: 0,
    upiReceivedAmt: 0,
    cashCount: 0,
    cashReceivedAmt: 0,
    chequeCount: 0,
    chequeReceivedAmt: 0,
  },
  singlecollectionHistory: {
    outletName: "",
    invoiceNumbers: [],
    collectionType: "",
    salesmanName: "",
    collectionDate: "",
    status: "",
    collectionNumber: "",
    amountCollected: 0,
    totalCollectedAmount: 0,
    outstandingAmt: 0,
    paymentMode: "",
    checkDate: "",
    invoiceDetailsCollected: [],
    chequeDetails: {
      chequeNumber: "",
      chequeDate: "",
      bankName: "",
    },
    receivedDate: "",
    images: "",
    img: [],
  },
  collectionHistoryCountsLoading: false,
  collectionHistoryCountsError: null,
  collectionOultetDetialsData: {
    invoiceDetails: [],
    totalOutStandingAmount: 0,
    outletCode: "",
    routeCode: "",
  },
  collectionOultetDetialsDataLoading: false,
  collectionOultetDetialsDataError: null,

  collectionPaymentList: { result: [], totalCount: 0 },
  collectionPaymentListLoading: false,
  collectionPaymentListError: null,
  salesmanFilter: [],
  outletFilter: [],

  collectionCashPaymentList: {
    result: [],
    totalCount: 0,
    denomination: {
      totalAmount: 0,
      "1_count": 0,
      "1_total_value": 0,
      "2_count": 0,
      "2_total_value": 0,
      "5_count": 0,
      "5_total_value": 0,
      "10_count": 0,
      "10_total_value": 0,
      "20_count": 0,
      "20_total_value": 0,
      "50_count": 0,
      "50_total_value": 0,
      "100_count": 0,
      "100_total_value": 0,
      "200_count": 0,
      "200_total_value": 0,
      "500_count": 0,
      "500_total_value": 0,
    },
  },

  pendingCollectionCounts: {
    totalAmtCollected: 0,
    upiCount: 0,
    upiCollection: 0,
    cashCount: 0,
    cashCollection: 0,
    chequeCount: 0,
    chequeCollection: 0,
  },
  pendingCollectionCountsLoading: false,
  pendingCollectionCountsError: null,

  pendingCollectionData: { result: [], totalCount: 0 },
  pendingCollectionDataLoading: false,
  pendingCollectionDataError: null,

  pendingPaymentCounts: {
    totalCollection: 0,
    totalCollectionAmt: 0,
  },
  pendingPaymentCountsLoading: false,
  pendingPaymentCountsError: null,

  selectedCollectionData: null,
  selectedCollectionDataLoading: false,

  collectionRejectAcceptData: {
    pendingList: [],
  },
  collectionRejectAcceptDataLoading: false,
  collectionRejectAcceptDataError: null,

  collectionChequeRejectAcceptData: [],
  collectionChequeRejectAcceptDataLoading: false,
  collectionChequeRejectAcceptDataError: null,

  collectionStoreData: false,

  collectionOutlet: [],
  collectionOutletLoading: false,
  collectionOutletError: null,

  salesman: [],
  salesmanLoading: false,
  salesmanError: null,

  salesmanMore: [],
  loadingMore: false,

  outletMore: [],

  bankListData: [],
  bankListDataLoading: false,
  bankListDataError: null,

  chequeAck: { result: [], totalCount: 0 },
  chequeAckLoading: false,
  chequeAckError: null,

  chequeCardCounts: {
    totalChequeCount: 0,
    totalChequeCollection: 0,
    inProgressCount: 0,
    inProgressCollection: 0,
    pendingChequeCount: 0,
    pendingChequeCollection: 0,
  },
  chequeCardCountsLoading: false,
  chequeCardCountsError: null,
  chequeupdateData: [],
  chequeupdateDataLoading: false,
  chequeupdateDataError: null,

  chequeApproveOrBounce: [],
  chequeApproveOrBounceLoading: false,
  chequeApproveOrBounceError: null,

  cashDominationData: {
    totalAmount: 0,
    "1_count": 0,
    "1_total_value": 0,
    "2_count": 0,
    "2_total_value": 0,
    "5_count": 0,
    "5_total_value": 0,
    "10_count": 0,
    "10_total_value": 0,
    "20_count": 0,
    "20_total_value": 0,
    "50_count": 0,
    "50_total_value": 0,
    "100_count": 0,
    "100_total_value": 0,
    "200_count": 0,
    "200_total_value": 0,
    "500_count": 0,
    "500_total_value": 0,
  },
  cashDominationLoading: false,
  cashDominationError: null,

  smTotalCollection: {
    totalCollectedAmount: 0,
    totalCount: 0,
  },
  smTotalCollectionLoading: false,
  smTotalCollectionError: null,

  saveCollectionResponseData: null,
  saveCollectionResponseLoading: false,
  saveCollectionResponseError: null,

  loading: false,
  error: null,

  salesmanDetails: {
    salesManId: "",
    salesManName: "",
    mobile: "",
  },
};

const collectionSlice = createSlice({
  name: "collectionData",
  initialState,
  reducers: {
    collectionOutletDetailFetch: (
      state,
      action: PayloadAction<Partial<CollectionState>>
    ) => {
      state.collectionOultetDetialsData =
        action.payload.collectionOultetDetialsData ||
        state.collectionOultetDetialsData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOutletDetailsData.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.collectionOultetDetialsData = {
        invoiceDetails: [],
        totalOutStandingAmount: 0,
        outletCode: "",
        routeCode: "",
      };
      state.collectionOultetDetialsDataLoading = true;
      state.collectionOultetDetialsDataError = null;
    });

    builder.addCase(
      getOutletDetailsData.fulfilled,
      (state, action: PayloadAction<InvoiceDetailsData>) => {
        state.collectionOultetDetialsData = action.payload;
        state.loading = false;
        state.error = null;
        state.collectionOultetDetialsDataLoading = false;
        state.collectionOultetDetialsDataError = null;
      }
    );

    builder.addCase(getOutletDetailsData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.collectionOultetDetialsDataLoading = false;
      state.collectionOultetDetialsDataError = action.error.message ?? null;
    });

    // collection history
    builder.addCase(getCollectionHistory.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.collectionHistoryData = { result: [], totalCount: 0 };
      state.collectionHistoryDataLoading = true;
      state.collectionHistoryDataError = null;
    });

    builder.addCase(
      getCollectionHistory.fulfilled,
      (state, action: PayloadAction<CollectionHistoryData>) => {
        state.collectionHistoryData = action.payload;
        state.loading = false;
        state.error = null;
        state.collectionHistoryDataLoading = false;
        state.collectionHistoryDataError = null;
      }
    );
    builder.addCase(getCollectionHistory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.collectionHistoryDataLoading = false;
      state.collectionHistoryDataError = action.error.message ?? null;
    });

    // collection history
    builder.addCase(getChequeAck.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.chequeAck = { result: [], totalCount: 0 };
      state.chequeAckLoading = true;
      state.chequeAckError = null;
    });

    builder.addCase(
      getChequeAck.fulfilled,
      (state, action: PayloadAction<ChequeAckData>) => {
        state.chequeAck = action.payload;
        state.loading = false;
        state.error = null;
        state.chequeAckLoading = false;
        state.chequeAckError = null;
      }
    );
    builder.addCase(getChequeAck.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.chequeAckLoading = false;
      state.chequeAckError = action.error.message ?? null;
    });

    // history count card
    builder.addCase(getCollectionHistoryCard.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.collectionHistoryCountsLoading = true;
      state.collectionHistoryCountsError = null;
    });
    builder.addCase(
      getCollectionHistoryCard.fulfilled,
      (state, action: PayloadAction<collectionHistoryCounts>) => {
        state.collectionHistoryCounts = action.payload ?? {};
        state.collectionHistoryCountsLoading = false;
        state.collectionHistoryCountsError = null;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(getCollectionHistoryCard.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.collectionHistoryCountsLoading = false;
      state.collectionHistoryCountsError = action.error.message ?? null;
    });

    // single collection history
    builder.addCase(getSingleCollectionHistory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getSingleCollectionHistory.fulfilled,
      (state, action: PayloadAction<SingleCollectionDatatype>) => {
        state.singlecollectionHistory = action.payload ?? {};
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(getSingleCollectionHistory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.collectionHistoryCountsError = action.error.message ?? null;
    });

    //  Collection Pending Based on Payment Type
    builder.addCase(getPendingPaymentList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.collectionPaymentList = { result: [], totalCount: 0 };
      state.collectionPaymentListLoading = true;
      state.cashDominationLoading = true;
      state.collectionPaymentListError = null;
    });

    builder.addCase(
      getPendingPaymentList.fulfilled,
      (state, action: PayloadAction<PendingPaymentData>) => {
        state.collectionPaymentList = action.payload;
        state.loading = false;
        state.error = null;
        state.collectionPaymentListLoading = false;
        state.cashDominationLoading = false;
        state.collectionPaymentListError = null;
      }
    );

    builder.addCase(getPendingPaymentList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.collectionPaymentListLoading = false;
      state.cashDominationLoading = false;
      state.collectionPaymentListError = action.error.message ?? null;
    });

    //  Collection Pending  Cash
    builder.addCase(getPendingCashPaymentList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.collectionCashPaymentList = {
        result: [],
        totalCount: 0,
        denomination: {
          totalAmount: 0,
          "1_count": 0,
          "1_total_value": 0,
          "2_count": 0,
          "2_total_value": 0,
          "5_count": 0,
          "5_total_value": 0,
          "10_count": 0,
          "10_total_value": 0,
          "20_count": 0,
          "20_total_value": 0,
          "50_count": 0,
          "50_total_value": 0,
          "100_count": 0,
          "100_total_value": 0,
          "200_count": 0,
          "200_total_value": 0,
          "500_count": 0,
          "500_total_value": 0,
        },
      };
      state.collectionPaymentListLoading = true;
      state.cashDominationLoading = true;
      state.collectionPaymentListError = null;
    });

    builder.addCase(
      getPendingCashPaymentList.fulfilled,
      (state, action: PayloadAction<PendingCashPaymentData>) => {
        state.collectionCashPaymentList = action.payload;
        state.loading = false;
        state.error = null;
        state.collectionPaymentListLoading = false;
        state.cashDominationData = action.payload.denomination ?? {};
        state.cashDominationLoading = false;
        state.collectionPaymentListError = null;
      }
    );

    builder.addCase(getPendingCashPaymentList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.collectionPaymentListLoading = false;
      state.cashDominationLoading = false;
      state.collectionPaymentListError = action.error.message ?? null;
    });

    //Salesman filter
    builder.addCase(getSalesman.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.salesmanFilter = [];
    });

    builder.addCase(getSalesman.fulfilled, (state, action) => {
      state.salesmanFilter = action.payload.data.data ?? [];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getSalesman.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });

    //Salesman More filter
    builder.addCase(getSalesmanFilterMore.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.salesmanMore = [];
    });

    builder.addCase(getSalesmanFilterMore.fulfilled, (state, action) => {
      state.salesmanMore = action.payload.data.data ?? [];
      state.loadingMore = false;
      state.error = null;
    });
    builder.addCase(getSalesmanFilterMore.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message ?? null;
    });

    //Outlet filter
    builder.addCase(getOutlets.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.outletFilter = [];
    });

    builder.addCase(getOutlets.fulfilled, (state, action) => {
      state.outletFilter = action.payload.data.data ?? [];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getOutlets.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });

    //Outlet More filter
    builder.addCase(getOutletsFilterMore.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.outletMore = [];
    });

    builder.addCase(getOutletsFilterMore.fulfilled, (state, action) => {
      state.outletMore = action.payload.data.data ?? [];
      state.loadingMore = false;
      state.error = null;
    });
    builder.addCase(getOutletsFilterMore.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message ?? null;
    });

    // pending count card
    builder.addCase(getPendingCollectionCard.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.pendingCollectionCountsLoading = true;
      state.pendingCollectionCountsError = null;
    });
    builder.addCase(
      getPendingCollectionCard.fulfilled,
      (state, action: PayloadAction<pendingCollectionCountsData>) => {
        state.pendingCollectionCounts = action.payload ?? {};
        state.pendingCollectionCountsLoading = false;
        state.pendingCollectionCountsError = null;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(getPendingCollectionCard.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.pendingCollectionCountsLoading = false;
      state.pendingCollectionCountsError = action.error.message ?? null;
    });
    // pending collection
    builder.addCase(getPendingCollection.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.pendingCollectionData = { result: [], totalCount: 0 };
      state.pendingCollectionDataLoading = true;
      state.pendingCollectionDataError = null;
    });

    builder.addCase(
      getPendingCollection.fulfilled,
      (state, action: PayloadAction<PendingCollectionData>) => {
        state.pendingCollectionData = action.payload;
        state.loading = false;
        state.error = null;
        state.pendingCollectionDataLoading = false;
        state.pendingCollectionDataError = null;
      }
    );
    builder.addCase(getPendingCollection.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.pendingCollectionDataLoading = false;
      state.pendingCollectionDataError = action.error.message ?? null;
    });
    // pending payment card count
    builder.addCase(getPendingPaymentCardCount.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.pendingPaymentCountsLoading = true;
      state.pendingPaymentCountsError = null;
    });
    builder.addCase(
      getPendingPaymentCardCount.fulfilled,
      (state, action: PayloadAction<PendingPaymentCardData>) => {
        state.pendingPaymentCounts = action.payload ?? {};
        state.pendingPaymentCountsLoading = false;
        state.pendingPaymentCountsError = null;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(getPendingPaymentCardCount.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.pendingPaymentCountsLoading = false;
      state.pendingPaymentCountsError = action.error.message ?? null;
    });
    builder.addCase(setSeletedCollectionData.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.selectedCollectionDataLoading = true;
    });
    builder.addCase(
      setSeletedCollectionData.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = null;
        state.selectedCollectionData = "";
        state.selectedCollectionDataLoading = false;
        state.selectedCollectionData = action.payload;
      }
    );
    // reject approve response
    builder.addCase(getRejectAcceptList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.collectionRejectAcceptData = {
        pendingList: [],
      };
      state.collectionRejectAcceptDataLoading = true;
      state.collectionRejectAcceptDataError = null;
    });

    builder.addCase(
      getRejectAcceptList.fulfilled,
      (state, action: PayloadAction<RejectApprovedListResponse>) => {
        state.collectionRejectAcceptData = action.payload;
        // state.editedData = action.payload;
        state.loading = false;
        state.error = null;
        state.collectionRejectAcceptDataLoading = false;
        state.collectionRejectAcceptDataError = null;
      }
    );
    builder.addCase(getRejectAcceptList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.collectionRejectAcceptDataLoading = false;
      state.collectionRejectAcceptDataError = action.error.message ?? null;
    });

    // cheque reject & accept
    builder.addCase(getChequeRejectAcceptList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.collectionChequeRejectAcceptData = [];
      state.collectionChequeRejectAcceptDataLoading = true;
      state.collectionChequeRejectAcceptDataError = null;
    });

    builder.addCase(
      getChequeRejectAcceptList.fulfilled,
      (state, action: PayloadAction<ChequeRejectApprovedListResponse[]>) => {
        state.collectionChequeRejectAcceptData = action.payload;
        // state.editedData = action.payload;
        state.loading = false;
        state.error = null;
        state.collectionChequeRejectAcceptDataLoading = false;
        state.collectionChequeRejectAcceptDataError = null;
      }
    );
    builder.addCase(getChequeRejectAcceptList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.collectionChequeRejectAcceptDataLoading = false;
      state.collectionChequeRejectAcceptDataError =
        action.error.message ?? null;
    });
    //
    builder.addCase(
      setCollectionData.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = null;
        state.collectionStoreData = action.payload;
      }
    );
    builder.addCase(getCollectionOutlet.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.collectionOutletLoading = false;
      state.collectionOutletError = action.error.message ?? null;
    });
    builder.addCase(getCollectionOutlet.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.collectionOutletLoading = true;
      state.collectionOutletError = null;
    });
    builder.addCase(
      getCollectionOutlet.fulfilled,
      (state, action: PayloadAction<collectionDataOutlet[]>) => {
        state.collectionOutlet = action.payload;
        state.loading = false;
        state.error = null;
        state.collectionOutletLoading = false;
        state.collectionOutletError = null;
      }
    );
    builder.addCase(getCollectionSalesman.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.salesmanLoading = false;
      state.salesmanError = action.error.message ?? null;
    });
    builder.addCase(getCollectionSalesman.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.salesmanLoading = true;
      state.salesmanError = null;
    });
    builder.addCase(
      getCollectionSalesman.fulfilled,
      (state, action: PayloadAction<collectionDatasalesman[]>) => {
        state.salesman = action.payload;
        state.loading = false;
        state.error = null;
        state.salesmanLoading = false;
        state.salesmanError = null;
      }
    );
    builder.addCase(getBankList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.bankListDataLoading = false;
      state.bankListDataError = action.error.message ?? null;
    });
    builder.addCase(getBankList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.bankListDataLoading = true;
      state.bankListDataError = null;
    });
    builder.addCase(
      getBankList.fulfilled,
      (state, action: PayloadAction<collectionBankList[]>) => {
        state.bankListData = action.payload;
        state.loading = false;
        state.error = null;
        state.bankListDataLoading = false;
        state.bankListDataError = null;
      }
    );

    //Cheque Acknowledgement card count
    builder.addCase(getChequeAckCardCount.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.chequeCardCountsLoading = true;
      state.chequeCardCountsError = null;
    });
    builder.addCase(
      getChequeAckCardCount.fulfilled,
      (state, action: PayloadAction<ChequeAckCardCount>) => {
        state.chequeCardCounts = action.payload ?? {};
        state.chequeCardCountsLoading = false;
        state.chequeCardCountsError = null;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(getChequeAckCardCount.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.pendingPaymentCountsLoading = false;
      state.pendingPaymentCountsError = action.error.message ?? null;
    });

    //Cash Domination
    builder.addCase(getCashDomination.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.cashDominationLoading = true;
      state.cashDominationError = null;
    });
    builder.addCase(
      getCashDomination.fulfilled,
      (state, action: PayloadAction<CashDominationResponse>) => {
        state.cashDominationData = action.payload ?? {};
        state.cashDominationLoading = false;
        state.cashDominationError = null;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(getCashDomination.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.cashDominationLoading = false;
      state.cashDominationError = action.error.message ?? null;
    });

    // Cheque Update
    builder.addCase(updateChequeDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.chequeupdateData = [];
      state.chequeupdateDataLoading = true;
      state.chequeupdateDataError = null;
    });

    builder.addCase(
      updateChequeDetails.fulfilled,
      (state, action: PayloadAction<ChequeUpdateDataList[]>) => {
        state.chequeupdateData = action.payload;
        // state.editedData = action.payload;
        state.loading = false;
        state.error = null;
        state.chequeupdateDataLoading = false;
        state.chequeupdateDataError = null;
      }
    );
    builder.addCase(updateChequeDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.chequeupdateDataLoading = false;
      state.chequeupdateDataError = action.error.message ?? null;
    });

    // cheque bounce or approve
    builder.addCase(getChequeApproveOrBounce.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.chequeApproveOrBounce = [];
      state.chequeApproveOrBounceLoading = true;
      state.chequeApproveOrBounceError = null;
    });

    builder.addCase(
      getChequeApproveOrBounce.fulfilled,
      (state, action: PayloadAction<ChequeAckApproveOrBounce[]>) => {
        state.chequeApproveOrBounce = action.payload;
        state.loading = false;
        state.error = null;
        state.chequeApproveOrBounceLoading = false;
        state.chequeApproveOrBounceError = null;
      }
    );

    builder.addCase(getChequeApproveOrBounce.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.chequeApproveOrBounceLoading = false;
      state.chequeApproveOrBounceError = action.error.message ?? null;
    });
    builder.addCase(getTotalCollectionBasedSmId.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.smTotalCollectionLoading = true;
      state.smTotalCollectionError = null;
    });
    builder.addCase(
      getTotalCollectionBasedSmId.fulfilled,
      (state, action: PayloadAction<SmTotalCollection>) => {
        state.smTotalCollection = action.payload ?? {};
        state.smTotalCollectionLoading = false;
        state.smTotalCollectionError = null;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(getTotalCollectionBasedSmId.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.smTotalCollectionLoading = false;
      state.smTotalCollectionError = action.error.message ?? null;
    });

    builder.addCase(
      saveCollection.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.saveCollectionResponseData = action.payload ?? {};
        state.saveCollectionResponseLoading = false;
        state.saveCollectionResponseError = null;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(saveCollection.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.saveCollectionResponseLoading = false;
      state.saveCollectionResponseError = null;
    });

    builder.addCase(
      updatePendingCard.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.pendingPaymentCounts = action.payload;
      }
    );
    builder.addCase(
      setSalesmanDetails.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.salesmanDetails = action.payload;
      }
    );
  },
});

export const { collectionOutletDetailFetch } = collectionSlice.actions;
export default collectionSlice.reducer;
