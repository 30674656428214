import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Height } from "@material-ui/icons";
import { Grid } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  // height: 150,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  borderRadius: "8px",
  // p: 4,
  border: "none",
};

interface AntswitchModelProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const AntswitchModel: React.FC<AntswitchModelProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);

  const handleOk = () => {
    // Add your logic here for handling the "OK" button click
    setOpen(false);
  };
  return (
    <div>
      <Modal
        className="model_card"
        open={open}
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" className="model_title">
            Are you sure want to Inactive the company ?
          </Typography>
          <Typography id="modal-modal-description" className="model_subLine">
            CAVINKARE PVT LTD
          </Typography>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "end",
              mt: 2,
              mb: 2,
              mr: 1,
              // marginTop: "20px",
            }}
          >
            <Grid item>
              <Button
                className="Model_button_cancel"
                variant="outlined"
                // color="primary"
                onClick={handleBackClick}
                sx={{ mr: 2, mt: { xs: 1, sm: "0px" } }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{ mt: { xs: 1, sm: "0px" } }}
                style={{ marginRight: "10px" }}
                variant="contained"
                className="btn-colr Model_button"
                onClick={handleOk}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
export default AntswitchModel;
