import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Drawer,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import {
  ProductUomFormValues,
  FormErrors,
} from "../../../../typescript/ProductUom";
//icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Close from "../../../../assets/images/icons/Close_icon.png";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useFetch";
import {
  ProductTaxSubmitForm,
  ProductTaxFetchData,
} from "../../../../Redux/Masters/Products/ProductTaxMaster/action";
import { ProductTaxFetchProductName } from "../../../../Redux/Masters/Products/ProductTaxMaster/action";

import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface RequestData {
  page: number;
  size: number;
  hsnType: string;
  category: string;
}
interface ProductCodeDetails {
  id: number;
  productCode: string;
}
interface HsnCode {
  id: number;
  hsnCode: string;
}
interface CreateProductTaxProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
  product_Code: ProductCodeDetails;
  hsn_Code: HsnCode;
}
export default function CreateProductTax(props: CreateProductTaxProps) {
  const dispatch = useAppDispatch();
  const { onClose, requestData, product_Code, hsn_Code } = props; // Destructure the props
  const [FormError, setFormError] = useState<boolean>(false);
  const [sucessMessage, setSucessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [errors, setErrors] = useState<FormErrors>({
    uomCode: "",
    uomName: "",
  });
  //select

  const [productCode, setproductCode] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [gstCode, setGstCode] = useState("18%");

  
  const handleChangeSelectHsn = (event: SelectChangeEvent<string>) => {
    setHsnCode(event.target.value);
  };

  const handleChangeSelectProName = (event: SelectChangeEvent<string>) => {
    setproductCode(event.target.value);
    dispatch(ProductTaxFetchProductName(event.target.value));
  };

  const ProductName = useAppSelector(
    (state: any) => state.ProductTaxFetchData.ProductTaxProName
  );


  const validateFormData = (data: ProductUomFormValues): FormErrors => {
    const errors: FormErrors = {};
    const fieldsToValidate: (keyof ProductUomFormValues)[] = [
      "uomCode",
      "uomName",
    ];

    fieldsToValidate.forEach((field) => {
      if (!data[field]) {
        errors[field] = `${field} is required`;
      }
    });
    return errors;
  };


 const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    productCode: string,
    hsnCode: string,
    gstCode:string,
  ): Promise<void> => {
    event.preventDefault();
    // const validationErrors = validateFormData(formData);
    // if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await dispatch(ProductTaxSubmitForm(productCode,hsnCode,gstCode));
        const successMessage = response.message;
        setFormError(true);
        setSucessMessage(successMessage);
        dispatch(ProductTaxFetchData(requestData));
        setTimeout(() => onClose(), 2000);
      } catch (error: any) {
        if (error.response.data.data) {
          const errorMessage = error.response.data.data;
           setErrors(errorMessage);
        } else {
          setErrorMessage("something went Wrong");
        }
      }
    // } else {
    //   setErrors(validationErrors);
    // }
  };

  const handleChanges = (value: string) => {
    console.log(`selected ${value}`);
  };



  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_stl"
        // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 533,
              md: 536,
              sm: 600,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid
            item
            sx={{ width: "95%", paddingLeft: "14px" }}
            role="presentation"
          >
            <Grid container className="crt-header" mt={5}>
              <Typography gutterBottom className="crt-header-lable">
                Create Product Tax
              </Typography>

              <Grid item>
                <img
                  src={Close}
                  alt={Close}
                  onClick={() => {
                    onClose();
                  }}
                  style={{ width: "24px", height: "24px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Content */}
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form"
          >
            {/* Your scrollable content */}
            <form
            onSubmit={(event) => handleSubmit(event ,productCode, hsnCode,gstCode)}
            >
              <Grid
                item
                sx={{ width: "95%", margin: "auto", marginBottom: "100px" }}
              >
                <Grid item>
                  <Typography className="crt-fnt-lable">
                    Product Code *
                  </Typography>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Select
                        className="prd_select"
                        placeholder="Select HSN Code"
                        IconComponent={KeyboardArrowDownIcon}
                        displayEmpty
                        value={productCode}
                        onChange={handleChangeSelectProName}
                      >
                        <MenuItem disabled value="">
                          <em style={{ color: "#bababc" }}>
                            Select Product Code
                          </em>
                        </MenuItem>
                        {product_Code &&
                          Array.isArray(product_Code) &&
                          product_Code.map((option: any, index: number) => (
                            <MenuItem key={index} value={option.id}>
                              {option.productCode}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">
                    Product Name *
                  </Typography>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      className="Crt-txt-box"
                      placeholder="Enter Name"
                      name={"code"}
                      value={ProductName}
                      //   onChange={handleChange}
                      //   error={errors.code ? true : false}
                      //   helperText={errors.code && errors.code}
                    />
                  </Grid>
                </Grid>

                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">HSN Code *</Typography>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Select
                        className="prd_select"
                        placeholder="Select HSN Code"
                        IconComponent={KeyboardArrowDownIcon}
                        displayEmpty
                        value={hsnCode}
                        onChange={handleChangeSelectHsn}
                      >
                        <MenuItem disabled value="">
                          <em style={{ color: "#bababc" }}>Select HSN Code</em>
                        </MenuItem>
                        {hsn_Code &&
                          Array.isArray(hsn_Code) &&
                          hsn_Code.map((option: HsnCode, index: number) => (
                            <MenuItem key={index} value={option.id}>
                              {option.hsnCode}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">GST %</Typography>
                  <Grid item xs={12}>
                    <Grid item sx={{ mt: "5px" }}>
                      <Typography className="">{gstCode}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">CGST % </Typography>
                  <Grid item xs={12}>
                    <Grid item sx={{ mt: "5px" }}>
                      <Typography className="">9%</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">SGST % </Typography>
                  <Grid item xs={12}>
                    <Grid item sx={{ mt: "5px" }}>
                      <Typography className="">9%</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">IGST % </Typography>
                  <Grid item xs={12}>
                    <Grid item sx={{ mt: "5px" }}>
                      <Typography className="">0%</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item mt={2} className="product_Create_footer">
                <Button className="crt-btn-cancel" onClick={onClose}>
                  {" "}
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="crt-btn-colr"
                  type="submit"
                  sx={{ mr: 4 }}
                >
                  {" "}
                  Create
                </Button>
              </Grid>
            </form>

            {/* More content... */}
            {FormError ? (
              sucessMessage ? (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="success">{sucessMessage}</Alert>
                </Snackbar>
              ) : (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="error">{errorMessage}</Alert>
                </Snackbar>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
