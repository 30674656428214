import React, { useState } from "react";
import Box from "@mui/material/Box";
//icons
import Close from "../../../../assets/images/icons/Close_icon.png";
import {
  Button,
  Drawer,
  Grid,
  TextField,
  Typography,
} from "@mui/material";


interface GeoHierarchyCreateProps {
  onClose: () => void;
}



export default function GeoHierarchyCreate(props: GeoHierarchyCreateProps) {

  const { onClose } = props;

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
      // variant="persistent"
      className="drawer_stl"
      >
        <Box
          sx={{
            width: {
              lg: 530,

              md: 536,
              sm: 740,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
          
        >
          {/* Heading */}
          <Grid item sx={{ width: "95%"}} role="presentation">
            <Grid container className="geo-header" >
              <Typography gutterBottom className="geo-header-lable">
                Create District
              </Typography>

              <Grid item>
                <img
                  src={Close}
                  alt={Close}
                  onClick={() => { onClose() }}
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Content */}
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form"
          >
            {/* Your scrollable content */}
            <form>
              <Grid
                item
                sx={{ width: "95%", margin: "auto", marginBottom: "100px" }}
              >
                <Grid item>
                  <Typography className="geo-fnt-lable">Country</Typography>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      className="geo-txt-box"
                      name={'Country'}
                      placeholder="Select Country"

                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="geo-fnt-lable">Region</Typography>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      className="geo-txt-box"
                      placeholder="Select Region"
                      name={'Region'}

                    />
                  </Grid>
                </Grid>

                <Grid item mt={2}>
                  <Typography className="geo-fnt-lable">
                    State
                  </Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="geo-txt-box"
                      placeholder="Select State "
                      type="text"
                      name={'State'}

                    />
                  </Grid>
                </Grid>

                <Grid item mt={2}>
                  <Typography className="geo-fnt-lable">District </Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="geo-txt-box"
                      placeholder=" Select District  "
                      type="text"
                      name={'District'}


                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="geo-fnt-lable">City/Town </Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="geo-txt-box"
                      placeholder="Select City/Town "
                      name={'City_Town'}

                    />
                  </Grid>
                </Grid>
                
              </Grid>

              <Grid item  className="geo_Create-footer">
                <Button
                  className="crt-btn-cancel"
                  onClick={onClose}

                >
                  {" "}
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="crt-btn-colr"
                  type="submit"
                  sx={{ mr: 4 }}
                >
                  {" "}
                  Create
                </Button>
              </Grid>
            </form>
          </Box>
        </Box>
      </Drawer >
    </Box >
  );
}

