import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  Button,
  Drawer,
  Grid,
  Snackbar,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tabs,
  Tab,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import debounce from "lodash.debounce";
import type { TableColumnsType } from "antd";
import { Spin, Table, Tooltip } from "antd";
import moment from "moment";
import { ArrowBackIos } from "@mui/icons-material";
import SearchBar from "@mkyy/mui-search-bar";

import { useNavigate } from "react-router-dom";
import view_line from "../../../../assets/images/icons/view_line.png";
import vector_edit from "../../../../assets/images/icons/Vector_edit.png";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useFetch";
import { getPendingAcceptedGrn } from "../../../../Redux/XDM/Purchase/GrnReducer";
import { getReturnIdAccepted, getSalesReturnHistoryDetails } from "../../../../Redux/XDM/Sales/salesReturnReducer";
import ImageModal from "./PendingSalesReturn/ImageModal";
import InvoiceDetails from "../InvoiceReviewOrder";
interface RequestData {
  returnId: string,
  outletName: string,
  invoiceNo: string,
  salesmanName: string,
  date: string,
  noOfLineItem: number,
  returnValue: number
  status: string
  orderId: string,
  route: string,
  salesmanMobile: string
}
interface ItemStatusCardProps {
  count: number;
  label: string;
  status: string;
  bgColor: string;
  textColor: string;
}

interface ReviewOrderDetailsProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
  // acceptedData: AcceptedDataType[];
}
interface ReviewOrderDetails {
  productCode: string,
  productName: string,
  batchCode: string,
  expiryDate: string
  quantity: number,
  uom: string,
  mrp: number,
  ptr: number,
  grossAmount: number,
  reason: string,
  image: ImageResponse
  approvedQty: number,
  approvedValue: number,
  returnReason: string,
  status: string,
}
interface ImageResponse {
  files: string,
  fileUri: string[]
  folderName: string
}

const tabLabels = ["Saleable", "Non Saleable"];

export default function SalesReturnDetails(props: ReviewOrderDetailsProps) {
  const navigate = useNavigate();
  const { onClose, requestData } = props; // Destructure the props
  const dispatch = useAppDispatch();
  const [textFieldValue, setTextFieldValue] = React.useState("");
  const [hasMore, setHasMore] = useState(true);
  const [commonLoader, setCommonLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [isShow, setIsShow] = React.useState(false);

  const [openImage, setOpenImage] = React.useState(false);

  const [imageList, setImageList] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [totalApprovedQty, setTotalApprovedQty] = useState(0);
  const [totalApprovedValue, setTotalApprovedValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    // Update filterData with the corresponding label
    filterData.reasonType = tabLabels[newValue];
  };
  const handleImageOpen = (record: any) => {
    setImageList(record.image?.fileUri);
    setOpenImage(true);
  }

  let salesreturnData = useAppSelector((state) => state.salesReturnData);

  const [dataRecordOrders, setDataRecordOrders] = useState<
    ReviewOrderDetails[]
  >([
    // Add more records as needed
  ]);
  const filteredDataToShow = dataRecordOrders.filter((item) =>
    item.productName?.toLowerCase().includes(textFieldValue.toLowerCase()) ||
    item.productCode?.toLowerCase().includes(textFieldValue.toLowerCase())
  );

  const tableHeightRef = useRef(160);
  const tableHeightloadRef = useRef(180);

  const filterData = {
    returnId: requestData.returnId,
    reasonType: tabLabels[tabIndex],
    search: textFieldValue,
  };
  // useEffect(() => {
  //   const handlePopState = (event: PopStateEvent) => {
  //     event.preventDefault(); 
  //     window.history.go(1);
  //   };
  //   window.history.pushState(null, "", window.location.href);
  //   window.addEventListener("popstate", handlePopState);
  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, []);
  // useEffect(() => {
  //   const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  //     const message =
  //       "Are you sure you want to leave this page? You have unsaved changes.";
  //     event.preventDefault(); 
  //     event.returnValue = message;
  //     return message;
  //   };
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  //   useEffect(() => {
  //     const handleVisibilityChange = () => {
  //       if (document.visibilityState === "hidden") {
  //         alert("You are switching tabs. Please be aware of unsaved changes.");
  //       }
  //     };
  //     document.addEventListener("visibilitychange", handleVisibilityChange);
  //     return () => {
  //       document.removeEventListener(
  //         "visibilitychange",
  //         handleVisibilityChange
  //       );
  //     };
  //   }, []);
  const [invoiceRequestData, setInvoiceRequestData] = React.useState({
    invoiceNumber: "",
    orderId: "",
    date: "",
    outlet: "",
    route: "",
    salesman: "",
    netAmount: 0,
    salesmanMobile: ""
  });
  const handleInvoiceClick = () => {
    setInvoiceRequestData({
      invoiceNumber: requestData.invoiceNo,
      orderId: requestData.orderId,
      date: requestData.date,
      outlet: "",
      route: requestData.route,
      salesman: requestData.salesmanName,
      netAmount: 0,
      salesmanMobile: requestData.salesmanMobile
    });

    setIsShow(!isShow);
  }
  useEffect(() => {
    setPage(0);
    dispatch(getReturnIdAccepted(requestData.returnId))
    loadMoreData(true);
  }, [tabIndex]);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 353;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const tableHeightLoad = () => {
    const headerHeight = 0;
    const footerHeoght = 300;
    const availableHeight = window.innerHeight - headerHeight - footerHeoght;
    tableHeightloadRef.current = availableHeight;
  };

  const dateObj = moment(requestData?.date, "YYYY-MM-DD");

  const formattedDate = dateObj.format("DD MMM YYYY");

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    tableHeightLoad();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;


    dispatch(
      getSalesReturnHistoryDetails({
        params: { ...filterData },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const newOrders = response.payload || [];
          // Calculate the sums
          const totalApprovedQty = newOrders.reduce(
            (count: number, order: { status: string }) => {
              if (order.status !== "Rejected") {
                return count + 1;
              }
              return count;
            },
            0
          );

          const totalApprovedValue = newOrders.reduce(
            (sum: number, order: { approvedValue: number }) =>
              sum + (order?.approvedValue ? Number(order.approvedValue.toFixed(2)) : 0),
            0
          );
          // Update sums based on whether it's a reset or append
          setTotalApprovedQty((prevQty) =>
            resetPage ? totalApprovedQty : prevQty + totalApprovedQty
          );
          setTotalApprovedValue((prevValue) =>
            resetPage ? totalApprovedValue : prevValue + totalApprovedValue
          );
          setDataRecordOrders((prevOrders) =>
            resetPage ? newOrders : [...prevOrders, ...newOrders]
          );
          setPage(nextPage + 1);
          if (newOrders.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        } else {
          // setHasMore(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };


  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const reviewOrderColumns: TableColumnsType<ReviewOrderDetails> = [
    {
      dataIndex: "productName",
      title: "Product Name",
      width: "9%",
      render: (text: string) => (
        <Tooltip placement="top" title={text} zIndex={999999}>
          <div className="ellipseclass">{text ? text : "-"}</div>
        </Tooltip>
      ),
    },

    {
      dataIndex: "productCode",
      title: "Product Code",
      width: "8%",
      render: (text: string) => (
        <Tooltip placement="top" title={text} zIndex={999999}>
          <div className="ellipseclass">{text ? text : "-"}</div>
        </Tooltip>
      ),
    },
    {
      dataIndex: "batchCode",
      title: "Batch Code",
      width: "8%",
      render: (text: string) => (
        <Tooltip placement="top" title={text} zIndex={999999}>
          <div className="ellipseclass">{text ? text : "-"}</div>
        </Tooltip>
      ),
    },
    {
      dataIndex: "expiryDate",
      title: "Expired Date",
      width: "8%",
      render: (_: any, record: ReviewOrderDetails) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        if (record.expiryDate) {
          const dateObj = moment(record.expiryDate, "YYYY-MM-DD");
          const formattedDate = dateObj.format("DD MMM YYYY");
          return <div className="ellipseclass">{formattedDate}</div>;
        }
        return "-";
      },
    },
    {
      dataIndex: "quantity",
      title: "Quantity",
      width: "6%",

      render: (text: string) => (
        <span >{text ? text : "-"}</span>
      ),
    },
    {
      dataIndex: "uom",
      title: "UOM",
      width: "4%",

      render: (text: string) => (
        // <Tooltip placement="top" title={text} zIndex={999999}>
        <div >{text ? text : "-"}</div>
        // </Tooltip>
      ),
    },

    {
      dataIndex: "mrp",
      title: "MRP / PC",
      width: "5%",
      render: (text: number, record: ReviewOrderDetails) => {
        return (
          <span>₹{text ? text.toFixed(2) : "0.00"}</span>
        );
      },
    },
    {
      dataIndex: "ptr",
      title: "PTR / PC",
      width: "5%",
      render: (text: number, record: ReviewOrderDetails) => {
        return (
          <div className="ellipseclass">₹{text ? Number(text).toFixed(2) : "0.00"}</div>
        );
      },
    },
    {
      dataIndex: "grossAmount",
      title: "Value",
      width: "5%",

      render: (_: any, record: ReviewOrderDetails) => {
        return (
          <span>
            ₹
            {record?.grossAmount ? record?.grossAmount.toFixed(2) : "0"}
          </span>
        );
      },
    },
    {
      dataIndex: "reason",
      title: "Reason",
      width: "5%",

      render: (text: string) => (
        <Tooltip placement="top" title={text} zIndex={999999}>
          <div className="ellipseclass">{text ? text : "-"}</div>
        </Tooltip>
      ),
    },
    {
      dataIndex: "actions",
      title: "Image",
      align: "center",
      width: "5%",

      render: (_: any, record: ReviewOrderDetails, index: number) => {
        return (
          <Grid
            container
            sx={{
              justifyContent: "center",
              pointerEvents: "auto",
            }}
          >
            {record?.image?.fileUri.length > 0 ? (<Grid item onClick={() => handleImageOpen(record)}>
              <img
                src={view_line}
                alt="view-line"
                style={{ width: "20px", height: "20px" }}
              />
            </Grid>) : (
              <span>-</span>
            )}

          </Grid>
        );
      },
    },
    {
      dataIndex: "approvedQty",
      title: "Approved Qty.",
      width: "8%",
      align: "center",
      render: (text: string) => (
        // <Tooltip placement="top" title={text} zIndex={999999}>
        <div>{text ? text : "0"}</div>
        // </Tooltip>
      ),
    },
    {
      dataIndex: "acceptedValue",
      title: "Approved Value",
      width: "8%",

      render: (_: any, record: ReviewOrderDetails) => {
        return (
          <span>
            ₹
            {record?.approvedValue ? record?.approvedValue.toFixed(2) : "0.00"}
          </span>
        );
      },
    },
    {
      dataIndex: "returnReason",
      title: "Rejected Reason",
      width: "8%",
      render: (text) => (
        <Tooltip placement="top" title={text} zIndex={999999}>

          <div className="ellipseclass">
            {text ? text : "-"}
          </div>
        </Tooltip>
      ),
    },
    {
      dataIndex: "status",
      title: "Status",
      align: "center",
      width: "10%",
      render: (_: any, record: ReviewOrderDetails, index: number) => {
        if (record?.status === "Confirmed") {
          return (
            <Grid container className="action_edit_grid">
              <Button className="accepted_button">{" Approved"}</Button>

            </Grid>
          )
        } else if (record?.status === "Partially Confirmed") {
          return (
            <Grid container className="action_edit_grid">
              <Button className="pending_button">{" Approved"}</Button>
            </Grid>
          );
        } else {
          return (
            <Grid container className="action_edit_grid">
              <Button className="rejected_button">{"Rejected"}</Button>
            </Grid>
          )
        }
      },
    },
  ];

  const ItemStatusCard: React.FC<ItemStatusCardProps> = ({
    count,
    label,
    status,
    bgColor,
    textColor,
  }) => {
    return (
      <Card sx={{ backgroundColor: bgColor, borderRadius: 2, boxShadow: "none", height: "60px" }}>
        <CardContent style={{ padding: "15px" }}>
          <Typography
            variant="h6"
            sx={{ color: textColor, fontWeight: "bold" }}
          >
            {count} {count > 1 ? "Items" : "Item"}
          </Typography>
          <Typography variant="body2" sx={{ color: textColor, fontSize: "10px" }}>
            {status}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const dataToShow: ReviewOrderDetails[] =
    dataRecordOrders.length > 0
      ? hasMore
        ? dataRecordOrders
        : [...dataRecordOrders]
      : [];
  const [acceptedCount, setAcceptedCount] = useState(0);
  const [partiallyAcceptedCount, setPartiallyAcceptedCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);
  useEffect(() => {
    setAcceptedCount(
      dataRecordOrders.filter((item) => item.status === "Accepted").length
    );
    setPartiallyAcceptedCount(
      dataRecordOrders.filter((item) => item.status === "Partially Accepted")
        .length
    );
    setRejectedCount(
      dataRecordOrders.filter((item) => item.status === "Rejected").length
    );
  }, [dataRecordOrders]);
  const itemCount = [acceptedCount, partiallyAcceptedCount, rejectedCount].filter(
    (count) => count > 0
  ).length;
  const gridSize = itemCount === 2 ? 6 : itemCount === 1 ? 12 : 4;
  const handleSearch = () => {
    setTextFieldValue(textFieldValue);
  };

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer anchor="right" open={true} className="drawer_outlet w-100px">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            // mt: 2,
          }}
        >
          <Grid item sx={{ width: "100%" }}>
            <Grid
              container
              sx={{ borderBottom: "1px solid #0000000D", padding: "12px 24px" }}
            >
              <Grid
                item
                sm={0.3}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ArrowBackIos
                  sx={{
                    fontSize: "10px",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    onClose();
                  }}
                />
              </Grid>
              <Grid item xs={6.7} sm={7.7} md={8.7} lg={8.7}>
                <Grid item>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#000000",
                      fontFamily: "Montserrat",
                      fontSize: "13px",
                    }}
                  >
                    Return ID - {requestData.returnId}<br />
                    <b style={{ color: requestData?.status === "Confirmed" ? "green" : requestData?.status === "Partially Confirmed" ? "orange" : "red" }}>
                      {requestData.status === "Confirmed" ? "Approved" : requestData?.status === "Partially Confirmed" ? "Partially Approved" : requestData.status}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form saleable-products-box"
          >
            {" "}
            <Grid
              item
              sx={{
                width: "100%",
                margin: "auto",
                marginBottom: "90px",
                padding: "0px 24px",
              }}
            >
              <Grid container pl={3.6} mt={2}>
                <Grid item sm={3}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Invoice No
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography
                      className={`${requestData?.invoiceNo ? 'InvoiceNo_style' : ''}`}
                      sx={{
                        cursor: requestData.invoiceNo ? "pointer" : "default",
                      }}
                      onClick={requestData?.invoiceNo ? handleInvoiceClick : () => {}}
                    >
                      {requestData?.invoiceNo ? requestData?.invoiceNo : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={3}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Outlet Name
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                    {requestData?.outletName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={3}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Salesman Name
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                    {requestData?.salesmanName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={3}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Date
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {formattedDate ? formattedDate : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={3} mt={3}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      No of LineItem
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {requestData?.noOfLineItem
                        ? requestData?.noOfLineItem
                        : "0"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={3} mt={3}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Return Value
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
                      {requestData?.returnValue
                        ? requestData?.returnValue.toFixed(2)
                        : "0"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={3} mt={3}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Approved Line item
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {salesreturnData?.salesreturnAccepted?.approvedLinedItem
                        ? salesreturnData?.salesreturnAccepted?.approvedLinedItem
                        : "0"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={3} mt={3}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Approved Value
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      ₹ {salesreturnData?.salesreturnAccepted?.approvedValue
                        ? salesreturnData?.salesreturnAccepted?.approvedValue.toFixed(2)
                        : "0"}
                    </Typography>
                  </Grid>
                </Grid>

              </Grid>
              <Grid container >
                <Grid item sm={6}>
                  <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    // TabIndicatorProps={{
                    //   style: {
                    //     background:
                    //       "linear-gradient(90.19deg, #614FB3 0%, #944986 100%)",
                    //   },
                    // }}
                    className="tab_box"
                    sx={{
                      "& .MuiTab-root": {
                        // background:
                        //   "linear-gradient(90.19deg, #614FB3 0%, #944986 100%)",
                        color: "#B9BABD",
                        "&.Mui-selected": {
                          color: "#944986",
                        },
                      },
                    }}
                  >
                    <Tab label="Saleable" />
                    <Tab label="Non Saleable" />
                  </Tabs>
                </Grid>
                <Grid
                  item
                  sm={6}
                  sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                >
                  <Grid item>
                    <SearchBar
                      className="search_box_pending w-100px"
                      placeholder="Search Product Name, Code...."
                      value={textFieldValue}
                      onChange={(newValue) => {
                        setTextFieldValue(newValue);
                      }}
                      onCancelResearch={() => setTextFieldValue("")}
                      onSearch={(newValue) => {
                        handleSearch();
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="pad-top-0px"
                  sx={{ height: tableHeightloadRef.current }}
                >
                  <div className="saleable-table-scroll">
                    <Table
                      className={`Header-table saleable-table sale_last_hide scroll_cus_table Header-salesReturn-table  ${salesreturnData.salesRerurnHistoryDetailsLoading
                        ? " nodataloader"
                        : ""
                        }`}
                      columns={reviewOrderColumns}
                      dataSource={filteredDataToShow}
                      pagination={false}
                      scroll={{ y: tableHeightRef.current }}
                      loading={
                        !page &&
                        salesreturnData.salesRerurnHistoryDetailsLoading
                      }
                      showSorterTooltip={false}
                    />
                  </div>
                </Grid>
              </Grid>
              <hr className="hr-revieworder"></hr>
              <Grid container mt={2} gap={2}>
                <Grid item mb={2}>
                  <Typography
                    className="Pending_review_label"
                    sx={{ fontWeight: 800 }}
                    style={{ fontSize: "12px" }}
                  >
                    Approved Items : {totalApprovedQty}
                  </Typography>
                </Grid>
                <Grid item mb={2}>
                  <Typography
                    className="Pending_review_Sublabel"
                    sx={{ fontWeight: 800 }}
                    style={{ fontSize: "12px" }}
                  >
                    Approved Value : ₹ {totalApprovedValue.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
      {
        openImage ? (
          <ImageModal
            open={openImage}
            handleImageClose={() => setOpenImage(false)}
            imageList={imageList}
          />
        )
          :
          (
            <>
              {isShow && (
                <InvoiceDetails
                  onClose={() => setIsShow(false)}
                  requestData={invoiceRequestData}
                />
              )}
            </>
          )}
    </Box>
  );
}
