import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Skeleton,
  Typography,
  RadioGroup,
  Button,
  Radio,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { Dayjs } from "dayjs";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useFetch";
import {
  openFilterModel,
  updateDate,
  updateFromDate,
  updateOutlet,
  updateOutletPop,
  updateOutletTemp,
  updateSalesman,
  updateSalesmanPop,
  updateSalesmanTemp,
  updateToDate,
} from "../../../../../Redux/XDM/Sales/billingReducer";
import { useEffect, useState } from "react";
import FilterModal from "../../FilterModal";
import { IoClose } from "react-icons/io5";
import { DatePicker } from "antd";
import {
  getOutlets,
  getSalesman,
} from "../../../../../Redux/XDM/Sales/collectionReducer";

const { RangePicker } = DatePicker;

const CheckAckSidebar = () => {
  const dispatch = useAppDispatch();
  const [filterModalTitle, setFilterModalTitle] = useState("");
  const fetch = useAppSelector((state) => state.orderBilling);
  const collectionReducerData = useAppSelector(
    (state) => state.collectionReducerData
  );
  const [selectedDates, setSelectedDates] = useState<
    [Dayjs | null, Dayjs | null]
  >([null, null]);
  const [dateFilter, setDateFilter] = useState("");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const filterData = {
    page: 0,
    size: 4,
    pageStatus: true,
    status: "Approved",
  };

  const labels = ["Today", "WTD", "MTD", "Custom"];
  const values = ["today", "WTD", "MTD", "custom"];

  useEffect(() => {
    dispatch(getSalesman(filterData));
    dispatch(getOutlets(filterData));
  }, []);

  const salesman = collectionReducerData.salesmanFilter;
  const outlets = collectionReducerData.outletFilter;

  const salesmanSelected = fetch.salesmanSelected;
  const outletSelected = fetch.outletSelected;
  const dateSelected = fetch.dateSelected;

  const clearAll = (title: any) => {
    switch (title) {
      case "SalesmanCollection":
        dispatch(updateSalesman([]));
        dispatch(updateSalesmanPop([]));
        dispatch(updateSalesmanTemp([]));
        break;
      case "OutletCollection":
        dispatch(updateOutlet([]));
        dispatch(updateOutletPop([]));
        dispatch(updateOutletTemp([]));
        break;
      case "Date":
        dispatch(updateDate([]));
        dispatch(updateToDate([]));
        dispatch(updateFromDate([]));
        setIsDatePickerOpen(false);
        break;
      default:
        break;
    }
  };

  const filterOpenSales = useAppSelector(
    (state) => state.orderBilling.filterPop
  );

  const handleShowMoreModal = (title: any) => {
    setFilterModalTitle(title);
    dispatch(openFilterModel(true));
    switch (title) {
      case "SalesmanCollection":
        dispatch(updateSalesmanTemp(fetch.salesmanSelected));
        break;
      case "OutletCollection":
        dispatch(updateOutletTemp(fetch.outletSelected));
        break;
      default:
        break;
    }
  };

  const salesmanSelect = (value: string) => {
    const isSelected = salesmanSelected.includes(value);
    const updatedSalesmanSelected = isSelected
      ? salesmanSelected.filter((item: any) => item !== value)
      : [...salesmanSelected, value];
    dispatch(updateSalesman(updatedSalesmanSelected));
    dispatch(updateSalesmanPop(updatedSalesmanSelected));
    dispatch(updateSalesmanTemp(updatedSalesmanSelected));
  };

  const outletSelect = (value: string) => {
    const isSelected = outletSelected.includes(value);
    const updatedOutletSelected = isSelected
      ? outletSelected.filter((item: any) => item !== value)
      : [...outletSelected, value];
    dispatch(updateOutlet(updatedOutletSelected));
    dispatch(updateOutletPop(updatedOutletSelected));
    dispatch(updateOutletTemp(updatedOutletSelected));
  };

  const handleDateChange = (event: any) => {
    setSelectedDates([null, null]);
    setDateFilter(event.target.value);
    if (event.target.value === "custom") {
      setIsDatePickerOpen(true);
    } else {
      setIsDatePickerOpen(false);
    }
    dispatch(updateDate(event.target.value));
  };

  const handleDatePickerClose = () => {
    dispatch(updateDate([]));
    dispatch(updateToDate([]));
    dispatch(updateFromDate([]));
    setIsDatePickerOpen(false);
  };

  const handleFromToDateChange = (dates: [Dayjs | null, Dayjs | null]) => {
    setSelectedDates(dates);
  };

  const handleCustomDateApply = () => {
    const [fromDate, toDate] = selectedDates;
    if (fromDate && toDate) {
      setIsDatePickerOpen(false);
      dispatch(updateFromDate(fromDate?.format("YYYY-MM-DD")));
      dispatch(updateToDate(toDate?.format("YYYY-MM-DD")));
    }
  };

  return (
    <Box
      sx={{
        padding: "0px 5px 0px 16px",
        marginBottom: "100px",
        marginRight: "4.3px",
        marginTop: "4px",
      }}
      className="dis-flex scrollHide"
    >
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
            Date
          </Typography>
        </Box>
        <Divider />
        <FormControl component="fieldset" sx={{ mt: 1 }}>
          <RadioGroup value={dateSelected || ""} onChange={handleDateChange}>
            {labels.map((label, index) => (
              <FormControlLabel
                key={index}
                value={values[index]}
                control={
                  <Radio
                    className="sidebar_checkbox"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 15,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label={
                  <div
                    style={{ paddingTop: "2px", paddingLeft: "5px" }}
                    className="filter_label"
                  >
                    {label}
                  </div>
                }
              />
            ))}
          </RadioGroup>
          <Box
            alignItems="center"
            sx={{
              padding: "5px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {(Array.isArray(dateSelected) && dateSelected.length > 0) ||
            (!Array.isArray(dateSelected) && dateSelected) ? (
              <Grid
                item
                xs={8}
                sx={{
                  color: "#F5222D",
                  fontSize: "10px",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                }}
                className="cursor_pointer"
                onClick={() => clearAll("Date")}
              >
                <IoMdClose size={7} />
                &nbsp; Clear all
              </Grid>
            ) : (
              <Grid item xs={8}></Grid>
            )}
            <Grid item xs={4}></Grid>
          </Box>
        </FormControl>
        {/* <DateFilterModal open={isDatePickerOpen} onClose={handleDatePickerClose} /> */}
        {((Array.isArray(dateSelected) && dateSelected.length > 0) ||
          (!Array.isArray(dateSelected) && dateSelected)) &&
          isDatePickerOpen && (
            <RangePicker
              className="invoice_dateRange"
              popupClassName="popup_range"
              onChange={handleFromToDateChange}
              renderExtraFooter={() => (
                <>
                  <Grid item>
                    <Grid item className="header_popup_design">
                      <Typography className="popup_header_label">
                        Select Date
                      </Typography>
                      <IoClose
                        className="ClosePop"
                        onClick={handleDatePickerClose}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Grid item className="from_to_date_border">
                        <Typography
                          sx={{ fontSize: "10px", fontWeight: "700" }}
                        >
                          {selectedDates[0]
                            ? selectedDates[0].format("DD/MM/YYYY")
                            : "Start Date"}
                        </Typography>
                      </Grid>
                      -
                      <Grid item className="from_to_date_border">
                        <Typography
                          sx={{ fontSize: "10px", fontWeight: "700" }}
                        >
                          {selectedDates[1]
                            ? selectedDates[1].format("DD/MM/YYYY")
                            : "End Date"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "16px",
                      }}
                      mb={1}
                    >
                      <Grid item xs={5.6} md={5.6} lg={5.6}>
                        <Button
                          className="invoice_close_btn"
                          variant="outlined"
                          onClick={handleDatePickerClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={5.6} md={5.6} lg={5.6}>
                        <Button
                          className="btn-colr"
                          variant="contained"
                          style={{
                            fontSize: "10px",
                            height: "26px",
                            width: "100%",
                          }}
                          onClick={handleCustomDateApply}
                        >
                          Apply
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              showTime
              open
            />
          )}
      </Card>
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
            Salesman
          </Typography>
          <Typography
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              mb: 1,
              color: "var(--grey-grey500, #505259)",
            }}
          >
            {salesmanSelected.length > 0 &&
              "(" + salesmanSelected.length + " Selected)"}
          </Typography>
        </Box>
        <Divider />
        <FormControl className="" sx={{ mt: 1 }}>
          {fetch.loading
            ? Array.from(new Array(4)).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  height={15}
                  sx={{ mb: 1 }}
                />
              ))
            : Array.isArray(salesman.values) &&
              salesman.values.map((values: any, index: number) => {
                return (
                  <FormControlLabel
                    key={index}
                    value={values.code}
                    control={
                      <Checkbox
                        onChange={() => salesmanSelect(values.code)}
                        checked={salesmanSelected.includes(values.code)}
                        className="sidebar_checkbox"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 15,
                            color: "#6750A4",
                          },
                        }}
                      />
                    }
                    label={
                      <div
                        title={values.name}
                        style={{ paddingTop: "2px", paddingLeft: "5px" }}
                        className="hiddenDot"
                      >
                        {values.name}
                      </div>
                    }
                  />
                );
              })}
        </FormControl>

        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {salesmanSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              sx={{
                color: "#F5222D",
                fontSize: "10px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              }}
              className="cursor_pointer"
              onClick={() => clearAll("SalesmanCollection")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}>
            {salesman.count > 0 && (
              <Typography
                className="moreData"
                onClick={() => handleShowMoreModal("SalesmanCollection")}
              >
                + {salesman.count} More
              </Typography>
            )}
          </Grid>
        </Box>
      </Card>

      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
            Outlets
          </Typography>
          <Typography
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              mb: 1,
              color: "var(--grey-grey500, #505259)",
            }}
          >
            {outletSelected.length > 0 &&
              "(" + outletSelected.length + " Selected)"}
          </Typography>
        </Box>
        <Divider />
        <FormControl className="" sx={{ mt: 1 }}>
          {fetch.loading
            ? Array.from(new Array(4)).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  height={15}
                  sx={{ mb: 1 }}
                />
              ))
            : Array.isArray(outlets.values) &&
              outlets.values.map((values: any, index: number) => (
                <FormControlLabel
                  key={index}
                  value={values.code}
                  control={
                    <Checkbox
                      onChange={() => outletSelect(values.code)}
                      checked={outletSelected.includes(values.code)}
                      className="sidebar_checkbox"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 15,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label={
                    <div
                      title={values.name}
                      style={{ paddingTop: "2px", paddingLeft: "5px" }}
                      className="hiddenDot"
                    >
                      {values.name}
                    </div>
                  }
                />
              ))}
        </FormControl>

        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {outletSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              sx={{
                color: "#F5222D",
                fontSize: "10px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              }}
              className="cursor_pointer"
              onClick={() => clearAll("OutletCollection")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}>
            {outlets.count > 0 && (
              <Typography
                className="moreData"
                onClick={() => handleShowMoreModal("OutletCollection")}
              >
                + {outlets.count} More
              </Typography>
            )}
          </Grid>
        </Box>
      </Card>
      {filterOpenSales && (
        <FilterModal status="Approved" title={filterModalTitle} />
      )}
    </Box>
  );
};

export default CheckAckSidebar;
