import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import SearchBar from "@mkyy/mui-search-bar";
import { Spin, Table, TableColumnsType, Tooltip } from "antd";
import debounce from "lodash.debounce";
import { camelCaseToNormal } from "../../../../../utils/constant";
import MainCard from "../../../../../components/MainCard";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useFetch";
import MiniDrawerStyled from "../../../../../Layout/MainLayout/Drawer/MiniDrawerStyled";
import DrawerHeader from "../../../../../Layout/MainLayout/Drawer/DrawerHeader";
import CheckAckSidebar from "./CheckAckSidebar";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import view_line from "../../../../../assets/images/icons/view_line.png";
import ImageModal from "../../SalesReturn/PendingSalesReturn/ImageModal";
import moment from "moment";
import PaymentReceivedPop from "./PaymentReceivedPop";
import ChequeBounced from "./ChequeBounced";
import {
  getChequeAck,
  getChequeAckCardCount,
} from "../../../../../Redux/XDM/Sales/collectionReducer";
import {
  updateDate,
  updateFromDate,
  updateInvoiceType,
  updateOutlet,
  updateSalesman,
  updateToDate,
} from "../../../../../Redux/XDM/Sales/billingReducer";
import { useLocation } from "react-router-dom";

const roundValue = (label: string, val: number | string): string => {
  if (
    (typeof val === "number" || !isNaN(Number(val))) &&
    (label === "stockValue" || label === "marginValue")
  ) {
    return Number(val)?.toFixed(2);
  }
  return val?.toString();
};

const formatIndianNumber = (num: number | string): string => {
  const numStr = num.toString();
  const [integerPart, decimalPart] = numStr.split(".");
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);

  if (otherDigits !== "") {
    lastThreeDigits = "," + lastThreeDigits;
  }

  const formattedIntegerPart =
    otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

  return decimalPart
    ? formattedIntegerPart + "." + decimalPart.slice(0, 2)
    : formattedIntegerPart + ".00";
};
interface DataProps {
  label: string;
  value: number | string | any;
}
interface CheckAckknowledge {
  collectionId: string;
  outletName: string;
  salesManName: string;
  chequeNumber: string;
  bankName: string;
  chequeDate: string;
  img: [];
  totalCollectedAmount: number;
}
type TableRowType = CheckAckknowledge | { noMoreData: boolean };

const DataBox: React.FC<DataProps> = ({ label, value }) => {
  const shouldShowIcon =
    label === "chequeCollection" ||
    label === "inProgress" ||
    label === "pendingCheque";
  return (
    <MainCard border={false} boxShadow>
      <Stack
        spacing={1}
        justifyContent="center"
        alignItems="center"
        className={"card-box-counts " + label}
      >
        <Typography variant="h6" className="card-box-title">
          {label !== "inProgress" ? camelCaseToNormal(label) : "In-Progress"}{" "}
          {` (${value.count})`}
        </Typography>
        <Typography variant="subtitle1" className="card-box-count">
          {shouldShowIcon && <CurrencyRupeeIcon sx={{ fontSize: 15 }} />}
          {value?.amt?.toFixed(2)}
        </Typography>
      </Stack>
    </MainCard>
  );
};

const CheckAck = () => {
  const theme = useTheme();
  const [textFieldValue, setTextFieldValue] = React.useState("");
  const [isShow, setIsShow] = React.useState(false);
  const [isShowBounce, setIsShowBounce] = React.useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [totalOutlet, SetTotalOutlet] = useState(0);
  const [chequeAck, setChequeAck] = React.useState<CheckAckknowledge[]>([]);
  const [commonLoader, setCommonLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [requestData, setRequestData] = useState("");
  const tableHeightRef = useRef(190);
  let collectionReducerData = useAppSelector(
    (state) => state.collectionReducerData
  );
  let chequeAckCounts = collectionReducerData.chequeCardCounts;
  const filterTags = useAppSelector((state) => state.orderBilling);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [imageList, setImageList] = useState<string[]>([]);
  const [openImage, setOpenImage] = React.useState(false);
  const [totalAmt, setTotalAmt] = useState(0);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");

  const filterData = {
    page: 0,
    size: 0,
    fromDate: filterTags.fromDateSelected ? filterTags.fromDateSelected : "",
    toDate: filterTags.toDateSelected ? filterTags.toDateSelected : "",
    search: id ? id : textFieldValue,
    dateType:
      filterTags.dateSelected?.length !== 0 ? filterTags.dateSelected : "",
    salesMan: collectionReducerData.salesmanDetails?.salesManId
      ? collectionReducerData.salesmanDetails?.salesManId
      : filterTags.salesmanSelected.toString(),
    outlet: filterTags.outletSelected.toString(),
  };

  useEffect(() => {
    setPage(0);
    setHasMore(true);
    setChequeAck([]);
    loadMoreData(true);
    getChequeAckCardCounts(true);
  }, [
    textFieldValue,
    filterTags.dateSelected,
    filterTags.outletSelected,
    filterTags.salesmanSelected,
    filterTags.fromDateSelected,
    filterTags.toDateSelected,
  ]);

  useEffect(() => {
    dispatch(updateFromDate([]));
    dispatch(updateToDate([]));
    dispatch(updateDate([]));
    dispatch(updateSalesman([]));
    dispatch(updateOutlet([]));
    dispatch(updateInvoiceType([]));
  }, []);

  const getChequeAckCardCounts = (resetPage = false) => {
    let nextPage = resetPage ? 0 : page;

    dispatch(
      getChequeAckCardCount({
        params: { ...filterData, page: nextPage },
      })
    );
  };

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    newSelectedRows: any[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled: isChequeDateFuture(record.chequeDate), // Disable if chequeDate is in the future
    }),
  };

  const abortControllerRef = useRef<AbortController | null>(null);
  const tableBodyRef = useRef<HTMLElement | null>(null);
  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 443;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    setCommonLoader(true);
    let nextPage = resetPage ? 0 : page;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;
    dispatch(
      getChequeAck({
        params: { ...filterData, page: nextPage },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const chequeAck = response.payload.result || [];
          setChequeAck((prevCollection) =>
            resetPage ? chequeAck : [...prevCollection, ...chequeAck]
          );
          setPage(nextPage + 1);
          if (chequeAck.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
          setSelectedRowKeys([]);
          setSelectedRows([]);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };

  const handleRowClick = (record: CheckAckknowledge) => {
    setRequestData(record.collectionId);
  };

  const handleImageOpen = (record: any) => {
    setImageList(record.img);
    setOpenImage(true);
  };

  const isChequeDateFuture = (chequeDate: string) => {
    const today = new Date();
    return new Date(chequeDate) > today;
  };

  const columns: TableColumnsType<CheckAckknowledge> = [
    {
      dataIndex: "collectionId",
      width: "12%",
      title: "Collection Id",
      render: (text: string, record: CheckAckknowledge) => {
        // if ("noMoreData" in record)
        //   return { children: null, props: { colSpan: 0 } }; // Skip rendering for special row
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span
              style={{
                textDecoration: "underline",
                color: "#27538D",
                fontWeight: 600,
              }}
            >
              {text}
            </span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "outletName",
      title: "Outlet",
      width: "8%",
      render: (text: string, record: CheckAckknowledge) => {
        // if ("noMoreData" in record)
        //   return { children: null, props: { colSpan: 0 } };
        return text;
      },
    },
    {
      dataIndex: "salesManName",
      title: "Salesman",
      width: "8%",
      // sorter: (a, b) => {
      //   if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
      //   return a.salesManName.localeCompare(b.salesManName);
      // },
      render: (text: string | number, record: CheckAckknowledge) => {
        // if ("noMoreData" in record) {
        //   return {
        //     children: (
        //       <span
        //         className="fs-12 noMore"
        //         style={{ display: "block", margin: "10px" }}
        //       >
        //         <b>No more data found...</b>
        //       </span>
        //     ),
        //     props: {
        //       colSpan: 9, // Adjust this number based on the total number of columns to span
        //     },
        //   };
        // }
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "chequeNumber",
      title: "Cheque No",
      width: "8%",
      render: (text: string, record: CheckAckknowledge) => {
        // if ("noMoreData" in record)
        //   return { children: null, props: { colSpan: 0 } };
        return text;
      },
    },
    {
      dataIndex: "bankName",
      title: "Bank Name",
      width: "8%",
      render: (text: string, record: CheckAckknowledge) => {
        // if ("noMoreData" in record)
        //   return { children: null, props: { colSpan: 0 } };
        return text;
      },
    },
    {
      dataIndex: "chequeDate",
      title: "Cheque Date",
      width: "10%",
      // Change the date format
      render: (_: any, record: CheckAckknowledge) => {
        // if ("noMoreData" in record)
        //   return { children: null, props: { colSpan: 0 } }; // Skip rendering for special row
        let date = (record as any).chequeDate;
        if (date) {
          const dateObj = moment(date, "YYYY-MM-DD");

          const formattedDate = dateObj.format("DD MMM YYYY");

          return <div>{formattedDate}</div>;
        }
        return "-";
      },
    },
    {
      dataIndex: "img",
      title: "View Cheque",
      width: "10%",
      render: (text: [], record: CheckAckknowledge) => {
        const image = record.img;
        if (image?.length > 0) {
          return (
            <div onClick={() => handleImageOpen(record)}>
              <img
                src={view_line}
                alt="view-line"
                style={{ width: "20px", height: "20px" }}
              />
            </div>
          );
        } else {
          return <div>--</div>;
        }
      },
    },
    {
      dataIndex: "totalCollectedAmount",
      title: "Collected Amt",
      width: "10%",
      render: (text: number, record: CheckAckknowledge) => {
        // if ("noMoreData" in record)
        //   return { children: null, props: { colSpan: 0 } };
        return (
          <div>
            <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
            {text?.toFixed(2)}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (status: boolean, record: CheckAckknowledge) => {
        // Function to check if the cheque date is in the future

        const isFutureDate = isChequeDateFuture((record as any).chequeDate);
        // if ("noMoreData" in record)
        //   return { children: null, props: { colSpan: 0 } };
        return (
          <div>
            <Button
              variant="contained"
              className={`${!isFutureDate ? "checkBounced" : ""}`}
              style={{ height: "30px", width: "115px", fontSize: "10px" }}
              type="submit"
              disabled={isFutureDate || selectedRowKeys.length > 0}
              sx={{ mr: 2 }}
              onClick={() => chequeBounced(record.collectionId)}
            >
              {" "}
              Cheque Bounced
            </Button>
            <Button
              variant="contained"
              className={`${!isFutureDate ? "paymentReceived" : ""}`}
              style={{ height: "30px", width: "115px", fontSize: "10px" }}
              type="submit"
              disabled={isFutureDate || selectedRowKeys.length > 0}
              sx={{ mr: 2 }}
              onClick={() =>
                paymentReceived(
                  record.totalCollectedAmount,
                  record.collectionId
                )
              }
            >
              {" "}
              Payment Received
            </Button>
          </div>
        );
      },
    },
  ];
  const dataToShow: CheckAckknowledge[] =
    chequeAck.length > 0 ? (hasMore ? chequeAck : [...chequeAck]) : [];

  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const checkAckCounts = {
    chequeCollection: {
      amt: chequeAckCounts.totalChequeCollection,
      count: chequeAckCounts.totalChequeCount,
    },
    inProgress: {
      amt: chequeAckCounts.inProgressCollection,
      count: chequeAckCounts.inProgressCount,
    },
    pendingCheque: {
      amt: chequeAckCounts.pendingChequeCollection,
      count: chequeAckCounts.pendingChequeCount,
    },
  };

  const chequeBounced = (collectionId: any) => {
    let ids: string[] = [];
    if (collectionId !== "") {
      chequeAck.map((data) => {
        if (collectionId === data.collectionId) {
          ids.push(data.collectionId);
        }
      });
      setSelectedRows(ids);
    }
    setIsShowBounce(!isShowBounce);
  };

  const paymentReceived = (amt: any, collectionId: any) => {
    let payment = 0;
    if (selectedRowKeys.length === 0) setTotalAmt(amt);
    else {
      chequeAck.map((data) => {
        if (selectedRowKeys.includes(data.collectionId)) {
          payment += data.totalCollectedAmount;
        }
      });
      setTotalAmt(payment);
    }
    let ids: string[] = [];
    if (collectionId !== "") {
      chequeAck.map((data) => {
        if (collectionId === data.collectionId) {
          ids.push(data.collectionId);
        }
      });
      setSelectedRows(ids);
    } else {
      setSelectedRows(selectedRowKeys);
    }
    setIsShow(!isShow);
  };

  return (
    <Grid item>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Box flex={1} p={0}>
          <MiniDrawerStyled variant="permanent" open={true} theme={theme}>
            <DrawerHeader />
            <CheckAckSidebar />
          </MiniDrawerStyled>
        </Box>
      </Stack>
      <Box
        flex={8}
        className="margin-left-0px"
        sx={{
          overflowY: "auto",
          height: "148vh",
          // zIndex: 0,
          mt: { sm: -0.5, md: -5.3 },
        }}
      >
        {/* <Card className="b-radius-0 main-card-ack"> */}
        <Card
          className="b-radius-0 "
          style={{ marginTop: "2px", boxShadow: "none" }}
        >
          <Grid container spacing={3} justifyContent="start" p={2}>
            <Grid container mt={4} m={2}>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                {/* <h3
                  style={{
                    marginTop: "0px",
                    marginLeft: "8px",
                    marginBottom: "16px",
                  }}
                >
                  Outlets ({totalOutlet})
                </h3> */}
              </Grid>
              <Grid item xs={6} sm={5} md={3} lg={3}>
                <SearchBar
                  className="collection_search w-100px"
                  placeholder="Search Cheque Number, ID..."
                  value={textFieldValue}
                  onCancelResearch={() => setTextFieldValue("")}
                  onChange={(newValue) => {
                    setTextFieldValue(newValue);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            justifyContent="start"
            p={2}
            sx={{
              borderBottom: "1px solid #cab1b117",
              marginBottom: "10px",
            }}
          >
            {collectionReducerData.chequeCardCountsLoading
              ? Array.from(new Array(3)).map((_, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                    <Skeleton variant="rounded" width="100%" height={81} />
                  </Grid>
                ))
              : Object.entries(checkAckCounts).map(([key, value]) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={key}
                    style={{ paddingTop: "4px" }}
                  >
                    <DataBox label={key} value={value} />
                  </Grid>
                ))}
          </Grid>
          <Grid container spacing={3} justifyContent="start" p={2}>
            <Grid item xs={6} sm={12} md={12} lg={12} className="pt-0">
              {/* {((Array.isArray(filterTags.statusSelected) &&
                filterTags.statusSelected.length !== 0) ||
                (!Array.isArray(filterTags.statusSelected) &&
                  filterTags.statusSelected) ||
                filterTags.divisionSelected.length !== 0 ||
                filterTags.outletGroupSelected.length !== 0 ||
                filterTags.minVal !== "" ||
                filterTags.maxVal !== "" ||
                textFieldValue !== "") && (
                <p
                  style={{
                    marginTop: "0px",
                    marginBottom: "6px",
                    fontSize: "12px",
                  }}
                >
                  Showing Results based on selected filters{" "}
                </p>
              )} */}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="pad-top-0px">
              <div className="saleable-table-scroll order-bill">
                <Table
                  className={`Header-table saleable-table sale_last_hide  scroll_cus_table antcheck_manual Header_chechbox elipsTable pending_grn ${
                    commonLoader ? " nodataloader" : ""
                  }`}
                  columns={columns}
                  dataSource={dataToShow}
                  pagination={false}
                  scroll={{ y: tableHeightRef.current }}
                  loading={!page && commonLoader}
                  showSorterTooltip={false}
                  summary={() => {
                    if (!hasMore && chequeAck.length > 0) {
                      return (
                        <Table.Summary.Row>
                          <Table.Summary.Cell
                            index={1}
                            colSpan={10}
                            align="center"
                            className="noMore"
                          >
                            No more data found...
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      );
                    }
                    return (
                      <>
                        {page !== 0 && commonLoader && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              index={1}
                              colSpan={5}
                              align="center"
                            >
                              <Spin />
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                      </>
                    );
                  }}
                  rowSelection={rowSelection}
                  rowKey={(record) => (record as any).collectionId}
                />
              </div>
            </Grid>
          </Grid>
        </Card>
        <hr style={{ height: "16px", background: "#eee", border: "none" }}></hr>
        <Card
          className="b-radius-0 "
          style={{
            marginTop: "10px",
            padding: "8px 0px 0px 0px",
            boxShadow: "none",
          }}
        >
          <Grid
            container
            // spacing={3}
            justifyContent="start"
            alignItems={"center"}
          >
            <Grid
              item
              xs={4}
              sm={6}
              md={6}
              lg={6}
              sx={{ paddingLeft: "18px" }}
              pb={1}
            >
              <Typography className="inv-lable-input">
                {selectedRowKeys?.length !== 0 && (
                  <b> selected {selectedRowKeys?.length}</b>
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={8}
              sm={6}
              md={6}
              lg={6}
              pb={1}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <Grid item>
                  <Button
                    variant="contained"
                    className={`${
                      selectedRowKeys.length > 0 ? "checkBounced" : ""
                    }`}
                    style={{ height: "30px", width: "130px", fontSize: "10px" }}
                    type="submit"
                    disabled={selectedRowKeys.length > 0 ? false : true}
                    sx={{ mr: 2 }}
                    onClick={() => chequeBounced("")}
                  >
                    {" "}
                    Cheque Bounced
                  </Button>
                  <Button
                    variant="contained"
                    className={`${
                      selectedRowKeys.length > 0 ? "paymentReceived" : ""
                    }`}
                    style={{ height: "30px", width: "140px", fontSize: "10px" }}
                    type="submit"
                    disabled={selectedRowKeys.length > 0 ? false : true}
                    sx={{ mr: 2 }}
                    onClick={() => paymentReceived(0, "")}
                  >
                    {" "}
                    Payment Received
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        {openImage && (
          <ImageModal
            open={openImage}
            handleImageClose={() => setOpenImage(false)}
            imageList={imageList}
          />
        )}
        {isShow && (
          <PaymentReceivedPop
            amount={totalAmt}
            count={selectedRowKeys?.length > 0 ? selectedRowKeys?.length : 1}
            onBack={() => setIsShow(false)}
            collectionIds={selectedRows}
            loadMore={() => loadMoreData(true)}
          />
        )}
        {isShowBounce && (
          <ChequeBounced
            onBack={() => setIsShowBounce(false)}
            collectionIds={selectedRows}
            loadMore={() => loadMoreData(true)}
          />
        )}
      </Box>
    </Grid>
  );
};

export default CheckAck;
