import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  Drawer,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import Close from "../../../assets/images/icons/Close_icon.png";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import { getAlldiscrepencyInfo } from "../../../Redux/XDM/Purchase/GrnReducer";

interface DiscrepancyProps {
  onClose: () => void;
  grnNo:string;
}

export default function GmReviewView(props: Readonly<DiscrepancyProps>) {
  const { onClose ,grnNo} = props;
  const dispatch = useAppDispatch();
  let grnData=useAppSelector((state)=>state.pendingGrnData)
    useEffect(() => {
      dispatch(getAlldiscrepencyInfo(grnNo));
    }, [dispatch,grnNo]);
 const totalDiscrepancyValue = grnData?.discrepancyAllInfoData
   .reduce((total, item) => total + (parseFloat(item.discrepancyValue) || 0), 0)
   .toFixed(2);
  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer anchor="right" open={true} className="pen_drawer_stl">
        <Box
          sx={{
            width: {
              lg: 500,
              md: 460,
              sm: 400,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
            backgroundColor: "#F6F6F6",
          }}
        >
          <Grid item sx={{ height: "100%" }}>
            <Grid
              container
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                background: "white",
                height: "60px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
              }}
            >
              <Grid item sm={7}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "14px",
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                  }}
                >
                  Discrepancy Items ({grnData?.discrepancyAllInfoData.length})
                </Typography>
              </Grid>
              <Grid item sm={5} sx={{ display: "flex", justifyContent: "end" }}>
                <Button onClick={onClose} className="btn_close_grn">
                  <img
                    src={Close}
                    alt="Close"
                    style={{ width: "20px", height: "20px" }}
                  />
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                overflowY: "auto",
                height: "67%",
                padding: "20px",
                position: "sticky",
              }}
              className="discrepancy_card"
            >
              {grnData?.discrepancyAllInfoData.length !== 0 ? (
                <>
                  {grnData?.discrepancyAllInfoData.map((item, index) => (
                    <Card
                      key={index}
                      sx={{
                        border: "1px solid #DCDCDE",
                        padding: "15px",
                        borderRadius: "6px",
                        marginBottom: "10px",
                      }}
                    >
                      <Grid container sx={{ alignItems: "center" }}>
                        <Grid item sm={12}>
                          <Grid item>
                            <Typography
                              sx={{
                                color: "#000000",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                              }}
                            >
                              {item.productName}
                            </Typography>
                          </Grid>
                          <Grid item mt={0.1}>
                            <Typography
                              sx={{
                                color: "#000000",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                              }}
                            >
                              {item.productCode}
                            </Typography>
                          </Grid>
                          <Grid item mt={0.2}>
                            <Typography
                              sx={{
                                color: `${
                                  item.status === "Rejected"
                                    ? "#E12B1D"
                                    : "#F28500"
                                }`,
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                              }}
                            >
                              {item.status}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container mt={2}>
                        <Grid item sm={3.3}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            Invoice Quantity : {item.invoiceQty}
                          </Typography>
                        </Grid>
                        <Grid item sm={3.9}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            Invoice Value : ₹
                            {item.invoiceValue
                              ? Number(item.invoiceValue).toFixed(2)
                              : "0.00"}
                          </Typography>
                        </Grid>
                        <Grid item sm={2.1}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            UOM :{item.uom ?item.uom :"-"}                           </Typography>
                        </Grid>
                        <Grid item sm={2.7}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            MRP / PC : ₹ {item.mrp}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container mt={2}>
                        {item?.reason &&
                          Object.entries(item.reason).length > 0 &&
                          Object.entries(item.reason).map(
                            ([reason, qty]: any, idx: any) => (
                              <React.Fragment key={idx}>
                                <Grid item sm={6}>
                                  <Grid item mb={1.5}>
                                    <Typography
                                      sx={{
                                        color: "#44464C",
                                        fontSize: "12px",
                                        fontFamily: "Montserrat",
                                        fontWeight: 500,
                                      }}
                                    >
                                      Reason
                                    </Typography>
                                  </Grid>
                                  <Grid item mb={1.5}>
                                    <Typography
                                      sx={{
                                        color: "#000000",
                                        fontSize: "12px",
                                        fontFamily: "Montserrat",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {reason}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid item sm={6}>
                                  <Grid item mb={1.5}>
                                    <Typography
                                      sx={{
                                        color: "#44464C",
                                        fontSize: "12px",
                                        fontFamily: "Montserrat",
                                        fontWeight: 500,
                                      }}
                                    >
                                      Quantity
                                    </Typography>
                                  </Grid>
                                  <Grid item mb={1.5}>
                                    <Typography
                                      sx={{
                                        color: "#000000",
                                        fontSize: "12px",
                                        fontFamily: "Montserrat",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {" "}
                                      {qty}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            )
                          )}
                      </Grid>
                      <Grid container>
                        <Grid item sm={6}>
                          <Grid item mb={1.5}>
                            <Typography
                              sx={{
                                color: "#44464C",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                              }}
                            >
                              Discrepancy Quantity
                            </Typography>
                          </Grid>
                          <Grid item mb={1.5}>
                            <Typography
                              sx={{
                                color: "#000000",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                              }}
                            >
                              {item.discrepancyQty ? item.discrepancyQty : "0"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item sm={6}>
                          <Grid item mb={1.5}>
                            <Typography
                              sx={{
                                color: "#44464C",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                              }}
                            >
                              Discrepancy Value
                            </Typography>
                          </Grid>
                          <Grid item mb={1.5}>
                            <Typography
                              sx={{
                                color: "#000000",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                              }}
                            >
                              ₹
                              {item.discrepancyValue
                                ? Number(item.discrepancyValue).toFixed(2)
                                : "0.00"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item sm={6}>
                          <Grid item mb={1.5}>
                            <Typography
                              sx={{
                                color: "#44464C",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                              }}
                            >
                              Accepted Quantity
                            </Typography>
                          </Grid>
                          <Grid item mb={1.5}>
                            <Typography
                              sx={{
                                color: "#000000",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                              }}
                            >
                              {item.acceptedQty ? item.acceptedQty : "0"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item sm={6}>
                          <Grid item mb={1.5}>
                            <Typography
                              sx={{
                                color: "#44464C",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                              }}
                            >
                              Accepted Value
                            </Typography>
                          </Grid>
                          <Grid item mb={1.5}>
                            <Typography
                              sx={{
                                color: "#000000",
                                fontSize: "12px",
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                              }}
                            >
                              ₹
                              {item.acceptedValue
                                ? Number(item.acceptedValue).toFixed(2)
                                : "0.00"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </>
              ) : (
                <Grid item sm={12} height={300}>
                  <Typography
                    sx={{
                      color: "#44464C",
                      fontSize: "14px",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      textAlign: "center",
                      height: "60vh",
                    }}
                  >
                    {grnData.discrepancyAllInfoDataLoading ? (
                      <Skeleton
                        variant="rectangular"
                        height={350}
                        sx={{
                          borderRadius: 2,
                          width: {
                            lg: 450,
                            md: 430,
                            sm: 350,
                            xs: 350,
                          },
                        }}
                      />
                    ) : (
                      <b>No discrepancies found.</b>
                    )}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              sx={{
                position: "sticky",
                bottom: 50,
                height: "60px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
                background: "white",
              }}
            >
              <Grid item sm={12}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "13px",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                  }}
                >
                  Discrepancy Total Value : ₹ {totalDiscrepancyValue}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </Box>
  );
}
