import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createAxiosInstance from "../../../hooks/useFetch";
import { getDivision } from "../Inventory/filterReducer";
interface FilterDataRequest {
  page: number;
  size: number;
  pageStatus: boolean;
}
interface FilterDataRequestUpdated {
  page: number;
  size: number;
  pageStatus: boolean;
  status: string;
}
interface InitialState {
  status: any;
  minVal: string;
  maxVal: string;
  outletGroup: any;
  division: any;
  statusSelected: any;
  outletGroupSelected: any;
  divisionSelected: any;
  outletGroupSelectedPop: any;
  divisionSelectedPop: any;
  outletSelectedPop: any;
  divisionMore: any;
  outletGroupMore: any;
  tempoutletGroupSelected: any;
  tempdivisionSelected: any;
  filterPop: boolean;
  error: any;
  loading: boolean;
  loadingMore: boolean;
}
const axiosInstance = createAxiosInstance();

export const getOutletDivision = createAsyncThunk(
  "filter/division",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get("outlet/division", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getOutletDivsionMore = createAsyncThunk(
  "filter/divisionMore",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get("outlet/division", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getOutletGroup = createAsyncThunk(
  "filter/outletgroup",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get("outlet/outletgroup", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getOutletGroupMore = createAsyncThunk(
  "filter/outletgroupMore",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get("outlet/outletgroup", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getOutletGroupOrderBill = createAsyncThunk(
  "filter/outlet-groupOrderBill",
  async (req: FilterDataRequestUpdated) => {
    try {
      const response = await axiosInstance.get("sales/get-outlet-group", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getOutletGroupOrderBillMore = createAsyncThunk(
  "filter/outlet-groupOrderBill-more",
  async (req: FilterDataRequestUpdated) => {
    try {
      const response = await axiosInstance.get("sales/get-outlet-group", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getOrderBillDivision = createAsyncThunk(
  "filter/division-orderbill",
  async (req: FilterDataRequestUpdated) => {
    try {
      const response = await axiosInstance.get("sales/orderbilling-division", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getOrderBillDivisionMore = createAsyncThunk(
  "filter/getorder-divisionMore",
  async (req: FilterDataRequestUpdated) => {
    try {
      const response = await axiosInstance.get("sales/orderbilling-division", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const updateStatus = createAsyncThunk(
  "filter/updateStatus",
  async (req: any) => {
    return req;
  }
);
export const updateMinValue = createAsyncThunk(
  "filter/updateMinValue",
  async (req: any) => {
    return req;
  }
);
export const updateMaxValue = createAsyncThunk(
  "filter/updateMaxValue",
  async (req: any) => {
    return req;
  }
);
export const updateOutletDivision = createAsyncThunk(
  "filter/updateOutletDivision",
  async (req: string[]) => {
    return req;
  }
);
export const updateOutletGroup = createAsyncThunk(
  "filter/updateOutletGroup",
  async (req: string[]) => {
    return req;
  }
);
export const updateOutletGroupPop = createAsyncThunk(
  "filter/updateOutletGroupPop",
  async (req: string[]) => {
    return req;
  }
);

export const updateOutletDivisionPop = createAsyncThunk(
  "filter/updateOutletDivisionPop",
  async (req: string[]) => {
    return req;
  }
);
export const updateOutletGrouptemp = createAsyncThunk(
  "filter/updateOutletGrouptemp",
  async (req: string[]) => {
    return req;
  }
);

export const updateOutletDivisiontemp = createAsyncThunk(
  "filter/updateOutletDivisiontemp",
  async (req: string[]) => {
    return req;
  }
);
export const openFilterModel = createAsyncThunk(
  "filter/openFilterModel",
  async (req: boolean) => {
    return req;
  }
);
const outletSideBarSlice = createSlice({
  name: "outletSideBar",
  initialState: {
    status: null,
    maxVal: "",
    minVal: "",
    outletGroup: [],
    division: [],
    statusSelected: null,
    outletGroupSelected: [],
    divisionSelected: [],
    outletGroupSelectedPop: [],
    divisionSelectedPop: [],
    outletSelectedPop: [],
    divisionMore: [],
    outletGroupMore: [],
    tempoutletGroupSelected: [],
    tempdivisionSelected: [],
    filterPop: false,
    error: null,
    loading: false,
  } as InitialState,
  reducers: {
    outletSideBarStates: (state, action) => {
      state.status = action.payload.status;
      state.division = action.payload.division;
      state.outletGroup = action.payload.outletGroup;
      state.statusSelected = action.payload.statusSelected;
      state.divisionSelected = action.payload.divisionSelected;
      state.outletGroupSelected = action.payload.outletGroupSelected;
      state.divisionSelectedPop = action.payload.divisionSelectedPop;
      state.outletGroupSelectedPop = action.payload.outletGroupSelectedPop;
      state.divisionMore = action.payload.divisionMore;
      state.outletGroupMore = action.payload.outletGroupMore;
      state.tempoutletGroupSelected = action.payload.tempoutletGroupSelected;
      state.tempdivisionSelected = action.payload.tempdivisionSelected;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDivision.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.division = [];
    });
    builder.addCase(getDivision.fulfilled, (state, action) => {
      state.loading = false;
      state.division = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getDivision.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getOutletGroup.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getOutletGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.outletGroup = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getOutletGroup.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateStatus.fulfilled, (state, action) => {
      state.statusSelected = action.payload;
    });
    builder.addCase(updateMaxValue.fulfilled, (state, action) => {
      state.maxVal = action.payload;
    });
    builder.addCase(updateMinValue.fulfilled, (state, action) => {
      state.minVal = action.payload;
    });
    builder.addCase(updateOutletDivision.fulfilled, (state, action) => {
      state.divisionSelected = action.payload;
    });
    builder.addCase(updateOutletGroup.fulfilled, (state, action) => {
      state.outletGroupSelected = action.payload;
    });
    builder.addCase(updateOutletDivisionPop.fulfilled, (state, action) => {
      state.divisionSelected = action.payload;
    });
    builder.addCase(updateOutletGroupPop.fulfilled, (state, action) => {
      state.outletGroupSelected = action.payload;
    });
    builder.addCase(openFilterModel.fulfilled, (state, action) => {
      state.filterPop = action.payload;
    });
    builder.addCase(getOutletDivsionMore.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.divisionMore =[]
    });
    builder.addCase(getOutletDivsionMore.fulfilled, (state, action) => {
      state.loadingMore = false;
      state.divisionMore = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getOutletDivsionMore.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message;
    });
    builder.addCase(getOutletGroupMore.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
       state.outletGroupMore =[]
    });
    builder.addCase(getOutletGroupMore.fulfilled, (state, action) => {
      state.loadingMore = false;
      state.outletGroupMore = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getOutletGroupMore.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message;
    });

    builder.addCase(updateOutletDivisiontemp.fulfilled, (state, action) => {
      state.tempdivisionSelected = action.payload;
    });
    builder.addCase(updateOutletGrouptemp.fulfilled, (state, action) => {
      state.tempoutletGroupSelected = action.payload;
    });
    builder.addCase(getOutletGroupOrderBill.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.outletGroup = [];
    });
    builder.addCase(getOutletGroupOrderBill.fulfilled, (state, action) => {
      state.loading = false;
      state.outletGroup = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getOutletGroupOrderBill.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getOutletGroupOrderBillMore.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.outletGroupMore = [];
    });
    builder.addCase(getOutletGroupOrderBillMore.fulfilled, (state, action) => {
      state.loadingMore = false;
      state.outletGroupMore = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getOutletGroupOrderBillMore.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message;
    });
    builder.addCase(getOrderBillDivision.pending, (state) => {
      state.loading = true;
      state.error = null;
        state.division = [];
    });
    builder.addCase(getOrderBillDivision.fulfilled, (state, action) => {
      state.loading = false;
      state.division = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getOrderBillDivision.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getOrderBillDivisionMore.pending, (state) => {
      state.loading = true;
      state.error = null;
       state.divisionMore = [];
    });
    builder.addCase(getOrderBillDivisionMore.fulfilled, (state, action) => {
      state.loading = false;
      state.divisionMore = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getOrderBillDivisionMore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { outletSideBarStates } = outletSideBarSlice.actions;
export default outletSideBarSlice.reducer;
