import React from 'react';
import { Box, TextField, FormControl, Select, MenuItem, Button, Grid, InputLabel } from '@mui/material';



interface EditSupplierFormProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
}

const EditSupplierForm: React.FC<EditSupplierFormProps> = ({ onBack }) => {
    const handleBackClick = (event: React.MouseEvent<unknown>) => {
        onBack(event); // Pass the event argument to onBack function
    };
    const companyOptions = ['Company A', 'Company B', 'Company C'];
    const gstStateOptions = ['State A', 'State B', 'State C'];
    const geoHierarchyValueOptions = ['Value A', 'Value B', 'Value C'];

    return (
        <Box className='card-form'>
            <form noValidate>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 5 }}>
          <Grid item xs={12} sm={3}>
            <InputLabel size="normal" className="fnt-lable">
              Company
            </InputLabel>
            <FormControl fullWidth>
              <Select className="selct-box">
                {companyOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel size="normal" className="fnt-lable">
              GST State Name
            </InputLabel>
            <FormControl fullWidth>
              <Select className="selct-box">
                {gstStateOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel size="normal" className="fnt-lable">
              Code
            </InputLabel>
            <TextField placeholder="Code" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel size="normal" className="fnt-lable">
              Name
            </InputLabel>
            <TextField placeholder="Name" className="Txt-box" />
          </Grid>

          <Grid item xs={12} sm={3}>
            <InputLabel size="normal" className="fnt-lable">
              Country
            </InputLabel>
            <TextField placeholder="Country" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel size="normal" className="fnt-lable">
              State
            </InputLabel>
            <TextField placeholder="State" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel size="normal" className="fnt-lable">
              City
            </InputLabel>
            <TextField placeholder="City" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel size="normal" className="fnt-lable">
              Postal Code
            </InputLabel>
            <TextField placeholder="Postal Code" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel size="normal" className="fnt-lable">
              Geo Hierarchy Level
            </InputLabel>
            <TextField placeholder="Geo Hierarchy Level" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel size="normal" className="fnt-lable">
              GeoHierarchy Value
            </InputLabel>
            <FormControl fullWidth>
              <Select className="selct-box">
                {geoHierarchyValueOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel size="normal" className="fnt-lable">
              Phone No.
            </InputLabel>
            <TextField placeholder="Phone No." className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel size="normal" className="fnt-lable">
              Contact Person
            </InputLabel>
            <TextField placeholder="Contact Person" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel size="normal" className="fnt-lable">
              Email Id
            </InputLabel>
            <TextField placeholder="Email Id" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel size="normal" className="fnt-lable">
              Tin No
            </InputLabel>
            <TextField placeholder="Tin No" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel size="normal" className="fnt-lable">
              Pin No
            </InputLabel>
            <TextField placeholder="Pin No" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel size="normal" className="fnt-lable">
              Address
            </InputLabel>
            <Grid className="dis-address">
              <TextField placeholder="Address Line 1" className="Txt-box" />
            </Grid>
            
         
          </Grid>
          <Grid item xs={12} sm={3}>
      
            <Grid className="dis-address">
              <TextField placeholder="Address Line 2" className="Txt-box" />
            </Grid>
            
         
          </Grid>
          <Grid item xs={12} sm={3}>
           
            <Grid className="dis-address">
              <TextField placeholder="Address Line 3" className="Txt-box" />
            </Grid>
            
         
          </Grid>
        
            
          <Grid item xs={12}  mt={2} display="flex" justifyContent="center">
                    <Button variant="outlined" className='button-lable' onClick={handleBackClick} >
                        Cancel
                    </Button>
                    <Button variant="contained" className='btn-colr' style={{marginLeft:"10px"}}>
                        Update
                    </Button>
                </Grid>
        </Grid>
      </form>
        
        </Box>
    );
}

export default EditSupplierForm;
