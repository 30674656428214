import { Box, TextField, FormControl, Button, Grid, InputLabel } from '@mui/material';
import React, { useState } from "react";
import Select, { components } from "react-select";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Checkbox, Input } from "@material-ui/core";


interface ReasonFormProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
}


const ReasonForm: React.FC<ReasonFormProps> = ({ onBack }) => {
    const handleBackClick = (event: React.MouseEvent<unknown>) => {
        onBack(event); // Pass the event argument to onBack function
    };
   
    return (
        <>
            <Box className='card-form'>
                <form noValidate>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 10 }}>
                        <Grid item xs={12} sm={6}>
                            <InputLabel size="normal" >Module Name</InputLabel>
                            <FormControl fullWidth>
                                
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel size="normal" >Screen Name</InputLabel>
                            <FormControl fullWidth>

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel size="normal" >Code</InputLabel>
                            <TextField placeholder="Code" fullWidth />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <InputLabel size="normal" >Reason Name</InputLabel>
                            <TextField placeholder="reason Name" fullWidth />
                        </Grid>
                        <Grid item xs={12} mt={2} display="flex" justifyContent="center">
                            <Button variant="outlined" onClick={handleBackClick} >
                                Cancel
                            </Button>
                            <Button variant="contained" className='btn-colr' style={{ marginLeft: "10px" }}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>

        </>
    );
}

export default ReasonForm;
