import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Skeleton,
  Stack,
  SvgIcon,
  SvgIconProps,
  Typography,
} from "@mui/material";
import { Snackbar, Alert } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import MiniDrawerStyled from "../../../Layout/MainLayout/Drawer/MiniDrawerStyled";
import DrawerHeader from "../../../Layout/MainLayout/Drawer/DrawerHeader";
import { useTheme } from "@mui/material/styles";
import OrderBillingSidebar from "./OrderBillingSidebar";
import { camelCaseToNormal } from "../../../utils/constant";
import MainCard from "../../../components/MainCard";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import { ReactComponent as LowStockSvg } from "../../../assets/images/icons/lowStock.svg";
import SearchBar from "@mkyy/mui-search-bar";
import { Spin, Table, TableColumnsType, Tooltip } from "antd";
import { RemoveCircleOutline } from "@mui/icons-material";
import queue from "../../../assets/images/icons/queueImage.png";
import ReviewOrder from "./ReviewOrder";
import {
  getOrderBilling,
  deleteOrder,
  convertBill,
  getOrderCounts,
  getTotalValue,
} from "../../../Redux/XDM/Sales/orderBillingReducer";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { IoClose } from "react-icons/io5";
import lowstockorderImage from "../../../../public/assets/Images/low_Stock_image.png";
import LoadingButton from "@mui/lab/LoadingButton";
import lowstockImage from "../../../assets/images/icons/low_stock_image.png";
import debounce from "lodash.debounce";
interface OrderBillingDatatype {
  orderId: string;
  date: string;
  outlet: string;
  route: string;
  salesman: string;
  grossAmount: number;
  schemeAmount: number;
  netAmount: number;
  noOfLineItems: string;
  lowStockOrder: boolean;
}
type TableRowType =
  | OrderBillingDatatype
  | {
    noMoreData: boolean;
  };

interface ConvertBillType {
  selectedItems: string[];
  isSelectAll: string;
}
const LowStockIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={LowStockSvg} viewBox="0 0 24 24" />
);

interface DataProps {
  label: string;
  value: number | string | any;
}

const formatIndianNumber = (num: number): string => {
  const numStr = num.toString();
  const [integerPart, decimalPart] = numStr.split(".");
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);

  if (otherDigits !== "") {
    lastThreeDigits = "," + lastThreeDigits;
  }

  const formattedIntegerPart =
    otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

  return decimalPart
    ? formattedIntegerPart + "." + decimalPart.slice(0, 2)
    : formattedIntegerPart + ".00";
};

const roundValue = (label: string, val: number | string): string => {
  if (
    typeof val === "number" &&
    (label === "totalOrderValue" || label === "billedValue")
  ) {
    return formatIndianNumber(val);
  }
  return val?.toString();
};
const DataBox: React.FC<DataProps> = ({ label, value }) => {
  const shouldShowIcon = label === "totalOrderValue" || label === "billedValue";
  return (
    <MainCard border={false} boxShadow>
      <Stack
        spacing={1}
        justifyContent="center"
        alignItems="center"
        className={"card-box-counts " + label}
      >
        <Typography variant="h6" className="card-box-title">
          {camelCaseToNormal(label)}
        </Typography>
        <Typography variant="subtitle1" className="card-box-count">
          {shouldShowIcon && <CurrencyRupeeIcon sx={{ fontSize: 15 }} />}
          {roundValue(label, value)}
        </Typography>
      </Stack>
    </MainCard>
  );
};
interface orderBillProps {
  searchVal: string;
}
export default function OrderBilling(props: orderBillProps) {
  const { searchVal } = props;
  const theme = useTheme();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isShow, setIsShow] = React.useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [orders, setOrders] = React.useState<OrderBillingDatatype[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [convertBillPopUp, setConvertBillPopUp] = useState(false);
  const [commonLoader, setCommonLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [totalOrderValue, setTotalOrderValue] = useState<number>(0);
  const [totalSelected, setTotalSelected] = useState<number>(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const tableHeightRef = useRef(190);
  const tableHeightloadRef = useRef(210);

  let orderBillingData = useAppSelector((state) => state.orderBillingData);
  let orderBillingDataFetch = orderBillingData.orderBilling;
  const filterTags = useAppSelector((state) => state.orderBilling);
  const dispatch = useAppDispatch();

  let orderCounts = useAppSelector(
    (state) => state.orderBillingData.orderCounts
  );

  const filterData = {
    page: 0,
    size: 0,
    stocks: filterTags.stockSelected.toString(),
    salesman: filterTags.salesmanSelected.toString(),
    routes: filterTags.routeSelected.toString(),
    outlets: filterTags.outletSelected.toString(),
    search: searchVal.toString(),
    status: "Yet to Confirm",
  };
  useEffect(() => {
    if (!isShow) dispatch(getOrderCounts());
  }, [isShow]);

  useEffect(() => {
    setPage(0);
    setHasMore(true);
    setOrders([]);
    if (!isShow) loadMoreData(true);
  }, [
    searchVal,
    filterTags.stockSelected,
    filterTags.salesmanSelected,
    filterTags.routeSelected,
    filterTags.outletSelected,
    isShow,
  ]);
  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const tableHeightLoad = () => {
    const headerHeight = 0;
    const footerHeoght = 375;
    const availableHeight = window.innerHeight - headerHeight - footerHeoght;
    tableHeightloadRef.current = availableHeight;
  };
  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 443;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    tableHeightLoad();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;
    dispatch(
      getOrderBilling({
        params: { ...filterData, page: nextPage },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const newOrders = response.payload.orders || [];
          const orderIDs = response.payload.orders.map(
            (order: any) => order.orderId
          );
          if (!resetPage) {
            setOrders((prevOrders) =>
              resetPage ? newOrders : [...prevOrders, ...newOrders]
            );
            if (totalSelected) {
              setSelectedRowKeys((prevKeys) => [...prevKeys, ...orderIDs]);
            }
          } else {
            setOrders(response.payload ? response.payload.orders : []);
          }
          setPage(nextPage + 1);

          if (newOrders.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        } else {
          // setHasMore(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });
  };
  const data_sales: OrderBillingDatatype[] = orders ?? [];
  const filteredCount = orderBillingDataFetch.totalCount ?? 0;
  const dataToShow: TableRowType[] =
    orders.length > 0
      ? hasMore
        ? orders
        : [...orders, { noMoreData: true }]
      : [];
  const dataRecord: OrderBillingDatatype[] = data_sales.map((order) => ({
    orderId: order.orderId,
    date: order.date,
    outlet: order.outlet,
    route: order.route,
    salesman: order.salesman,
    grossAmount: order.grossAmount,
    schemeAmount: order.schemeAmount,
    netAmount: order.netAmount,
    noOfLineItems: order.noOfLineItems,
    lowStockOrder: order.lowStockOrder,
  }));

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    orderID: string,
    grossAmount: number
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    const isSelected = convertBillReq.selectedItems.includes(orderID);

    if (isSelected) {
      setConvertBillReq((prevState) => ({
        ...prevState,
        selectedItems: prevState.selectedItems.filter((id) => id !== orderID),
        selectAll: false,
      }));
      setTotalOrderValue(totalOrderValue - grossAmount);
      setTotalSelected(totalSelected - 1);
    } else {
      setConvertBillReq((prevState) => ({
        ...prevState,
        selectedItems: [...prevState.selectedItems, orderID],
        selectAll: newSelectedRowKeys.length === dataRecord.length,
      }));
      setTotalOrderValue(totalOrderValue + grossAmount);
      setTotalSelected(totalSelected + 1);
    }
  };
  const [convertBillReq, setConvertBillReq] = useState<ConvertBillType>({
    selectedItems: [],
    isSelectAll: "false",
  });
  const handleMasterCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      const allSelectedItems = dataRecord.map((item) => item.orderId);
      setSelectedRowKeys(allSelectedItems);

      setConvertBillReq({
        isSelectAll: "true",
        selectedItems: allSelectedItems,
      });
      dispatch(getTotalValue())
        .then((response) => {
          // Update the state with the fetched data
          setTotalSelected(response.payload.count);
          setTotalOrderValue(response.payload.totalOrderValue); // Adjust based on the structure of your payload
        })
        .catch((error) => {
          console.error("Failed to fetch order details:", error);
        });
      // setTotalOrderValue(orderCounts ? orderCount : 0);
    } else {
      setSelectedRowKeys([]);
      setConvertBillReq({
        isSelectAll: "false",
        selectedItems: [],
      });
      setTotalOrderValue(0);
      setTotalSelected(0);
    }
  };

  const handleRowClick = (
    record: any,
    index: number | undefined,
    columnIndex: number | undefined
  ) => {
    if (columnIndex !== undefined && columnIndex !== 0 && columnIndex !== 9) {
      setRequestData(record);

      setIsShow(!isShow);
    }
  };
  const handleDeleteClick = (index: number) => {
    setDeleteIndex(index);
    setOrderId(orders[index].orderId);
  };
  const handleDeleteConfirm = () => {
    if (deleteIndex !== null) {
      try {
        const orderToDelete = orders[deleteIndex];
        dispatch(deleteOrder(orderToDelete.orderId));

        setSelectedRowKeys([]);
        setTotalOrderValue(0);
        setTotalSelected(0);
        loadMoreData(true);
        setDeleteIndex(null);

        setSnackbarMessage("Order Id :" + orderId + "  deleted successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage("Error deleting orderId" + orderId);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };
  const handleConvertToBill = async () => {
    setConvertBillPopUp(true);
  };
  const handleConfirmConvertPopup = async () => {
    setBtnLoader(true);

    try {
      const resultAction = await dispatch(convertBill(convertBillReq));
      if (convertBill.fulfilled.match(resultAction)) {
        setSnackbarMessage("Bill Converted Successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTotalSelected(0);
        setConvertBillPopUp(false);
        setSelectedRowKeys([]);
        setTotalOrderValue(0);
        loadMoreData(true);
        dispatch(getOrderCounts());
        setBtnLoader(false);
      } else {
        setSnackbarMessage("Error converting Bill");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setBtnLoader(false);
      }
    } catch (error) {
      setSnackbarMessage("Error converting Bill");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setBtnLoader(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteIndex(null);
  };
  const handleDeleteCancelConvertPopUp = () => {
    setConvertBillPopUp(false);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);
  const [requestData, setRequestData] = React.useState({
    orderId: "",
    date: "",
    outlet: "",
    route: "",
    salesman: "",
    netAmount: 0,
  });
  const columns: TableColumnsType<TableRowType> = [
    {
      dataIndex: "lowStock",
      width: "5%",
      title: (
        <div style={{ paddingLeft: "0px" }}>
          <Checkbox
            className="sidebar_checkbox"
            sx={{
              "&:hover": {
                backgroundColor: "transparent", // Remove the hover background color
              },
              "& .MuiSvgIcon-root": {
                fontSize: 15,
                marginBottom: "1px",
                color: "#6750A4",
              },
            }}
            indeterminate={
              selectedRowKeys.length > 0 &&
              selectedRowKeys.length < dataRecord.length
            }
            checked={
              selectedRowKeys.length === dataRecord.length &&
              dataRecord.length !== 0
            }
            onChange={handleMasterCheckboxChange}
          />
        </div>
      ),
      render: (_: any, record: TableRowType, index) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } }; // Skip rendering for special row
        let classes = "near-to-expiry-column";
        if (record.lowStockOrder) {
          classes += "low-stock-order";
        } else {
          classes += " ";
        }

        return (
          <div className={`w-100px ${classes}`} style={{ display: "flex" }}>
            <Checkbox
              onChange={() =>
                onSelectChange(
                  selectedRowKeys.includes(record.orderId)
                    ? selectedRowKeys.filter((key) => key !== record.orderId)
                    : [...selectedRowKeys, record.orderId],
                  record.orderId,
                  record.grossAmount
                )
              }
              checked={selectedRowKeys.includes(record.orderId)}
              className="sidebar_checkbox"
              sx={{
                "&:hover": {
                  backgroundColor: "transparent", // Remove the hover background color
                },
                "& .MuiSvgIcon-root": {
                  fontSize: 15,
                  marginBottom: "9px",
                  color: "#6750A4",
                },
              }}
            />

            {record.lowStockOrder && (
              <Tooltip
                placement="top"
                title="Low Stock"
                overlayInnerStyle={{
                  backgroundColor: "#fee8ea",
                  color: "#F59D33",
                  fontSize: "10px",
                }}
                zIndex={999999}
              >
                <img
                  src={lowstockImage}
                  width={"20px"}
                  height={"20px"}
                  alt="Low Stock"
                  style={{ marginTop: "2px" }}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      dataIndex: "orderId",
      title: "Order ID",
      width: "15%",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
        return a.orderId.localeCompare(b.orderId);
      },
      render: (text: string | number, record: TableRowType) => {
        if ("noMoreData" in record) {
          return {
            children: (
              <span
                className="fs-12 noMore"
                style={{ display: "block", margin: "10px" }}
              >
                <b>No more data found...</b>
              </span>
            ),
            props: {
              colSpan: 10, // Adjust this number based on the total number of columns to span
            },
          };
        }
        return text;
      },
    },
    {
      dataIndex: "date",
      width: "9%",
      title: "Date",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0;
        const dateA = moment(a.date, "YYYY-MM-DD");
        const dateB = moment(b.date, "YYYY-MM-DD");
        return dateA.diff(dateB);
      },
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } }; // Skip rendering for special row
        if (record.date) {
          const dateObj = moment(record.date, "YYYY-MM-DD");

          const formattedDate = dateObj.format("DD MMM YYYY");

          return <div>{formattedDate}</div>;
        }
        return "-";
      },
    },
    {
      dataIndex: "outlet",
      title: "Outlet",
      width: "9%",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0;
        return a.outlet.localeCompare(b.outlet);
      },
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } }; // Skip rendering for special row
        let displayText = "-";
        if (text) {
          displayText = text.length > 12 ? `${text.substring(0, 12)}...` : text;
        }
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{displayText}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "route",
      title: "Route",
      width: "7%",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0;

        const routeA = a.route || ""; // Fallback to empty string if null/undefined
        const routeB = b.route || ""; // Fallback to empty string if null/undefined

        return routeA.localeCompare(routeB);
      },
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } }; // Skip rendering for special row
        let displayText = "-";
        if (record.route) {
          displayText =
            record.route.length > 12
              ? `${record.route.substring(0, 12)}...`
              : record.route;
        }
        return (
          <Tooltip placement="top" title={record.route} zIndex={999999}>
            <span>{displayText}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "salesman",
      title: "Salesman",
      width: "10%",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0;
        return a.salesman.localeCompare(b.salesman);
      },
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } }; // Skip rendering for special row
        let displayText = "-";
        if (record.salesman) {
          displayText =
            record.salesman.length > 12
              ? `${record.salesman.substring(0, 12)}...`
              : record.salesman;
        }
        return (
          <Tooltip placement="top" title={record.salesman} zIndex={999999}>
            <span>{displayText}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "grossAmount",
      title: "Gross Amount in ₹",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0;
        return a.grossAmount;
      },
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return record.grossAmount ? record.grossAmount?.toFixed(2) : 0;
      },
    },
    {
      dataIndex: "schemeAmount",
      title: "Scheme Amount in ₹",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0;
        return a.schemeAmount;
      },
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return record.schemeAmount ? record.schemeAmount?.toFixed(2) : 0;
      },
    },
    {
      dataIndex: "netAmount",
      title: "Net Amount in ₹",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0;
        return a.netAmount;
      },
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return record.netAmount ? record.netAmount?.toFixed(2) : 0;
      },
    },
    {
      dataIndex: "noOfLineItems",
      title: "No of Line Item",
      // sorter: (a, b) => {
      //   if ("noOfLineItems" in a || "noOfLineItems" in b) return 0;
      //   return a.noOfLineItems.localeCompare(b.noOfLineItems);
      // },
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return text;
      },
    },
    {
      dataIndex: "actions",
      title: "",
      width: "4%",
      render: (_: any, record: TableRowType, index) => {
        if ("noMoreData" in record) {
          return {
            children: null,
            props: { colSpan: 0 },
          };
        }

        return (
          <RemoveCircleOutline
            sx={{ fontSize: "14px", color: "red" }}
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(index);
            }}
          />
        );
      },
    },
  ];
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      bgcolor="#eee"
    >
      <Box
        flex={8}
        className="margin-left-0px"
        sx={{
          overflowY: "auto",
          height: "100%",
          marginBottom: "100px",
          zIndex: 0,
        }}
      >
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          color="green"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Card
          className="b-radius-0 main-card-order maincard_order_height"
          sx={{ marginTop: "0px", boxShadow: "none" }}
        >
          <Grid
            container
            spacing={3}
            justifyContent="start"
            px={2}
            sx={{
              borderBottom: "1px solid #cab1b117",
            }}
          >
            {" "}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingTop: "0px" }}
            >
              <h3
                style={{
                  marginTop: "0px",
                  marginLeft: "4px",
                  marginBottom: "0px",
                  fontSize: "14px",
                }}
              >
                <b>Overview</b>
              </h3>
            </Grid>
            {orderBillingData.orderCountsLoading
              ? Array.from(new Array(3)).map((_, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  key={index}
                  style={{ paddingTop: "4px" }}
                >
                  <Skeleton variant="rounded" width="100%" height={81} />
                </Grid>
              ))
              : Object.entries(orderCounts).map(([key, value]) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  key={key}
                  style={{ paddingTop: "4px" }}
                >
                  <DataBox label={key} value={value} />
                </Grid>
              ))}
          </Grid>
          <Grid container spacing={3} justifyContent="start" p={2}>
            <Grid
              container
              mt={3}
              ml={2}
              mr={2}

            // sx={{
            //   borderBottom: "1px solid #cab1b117",
            // }}
            >
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <h3
                  style={{
                    marginTop: "0px",
                    marginLeft: "8px",
                    marginBottom: "16px",
                  }}
                >
                  Order to Billing ({filteredCount})
                </h3>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={12} md={12} lg={12} className="pt-0">
              {(filterTags.stockSelected.length !== 0 ||
                filterTags.salesmanSelected.length !== 0 ||
                filterTags.routeSelected.length !== 0 ||
                filterTags.outletSelected.length !== 0 ||
                searchVal !== "") && (
                  <p
                    style={{
                      marginTop: "0px",
                      marginBottom: "6px",
                      fontSize: "12px",
                    }}
                  >
                    Showing Results based on selected filters{" "}
                    <b>({filteredCount} Products)</b>
                  </p>
                )}
            </Grid>
            {orders.length === 0 && !commonLoader ? (
              <>
                <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  alignContent="center"
                  marginTop="9%"
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img alt="queue" height={100} width={100} src={queue}></img>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Typography className="queue-label-input" mt={0.5}>
                      Your Order Queue is Taking a Breather!
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography className="queue-label-light" mt={0.5}>
                      The Order Queue is currently quiet, allowing us to prepare
                      for distributor orders. Your Order Queue is Taking a
                      Breather!
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
              </>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="pad-top-0px"
                sx={{ height: tableHeightloadRef.current }}
              >
                <div className="saleable-table-scroll order-bill">
                  <Table
                    className={`Header-table saleable-table sale_last_hide scroll_cus_table elipsTable ${orderBillingData.orderBillingLoading
                        ? " nodataloader"
                        : ""
                      }`}
                    columns={columns}
                    dataSource={dataToShow}
                    pagination={false}
                    loading={!page && orderBillingData.orderBillingLoading}
                    showSorterTooltip={false}
                    onRow={(record: TableRowType, index) => {
                      if ("noMoreData" in record) return {};
                      return {
                        onClick: (event: React.MouseEvent<HTMLElement>) => {
                          const target = event.target as HTMLElement;
                          const closestTd = target.closest("td");
                          const columnIndex = closestTd
                            ? closestTd.cellIndex
                            : null;

                          if (
                            columnIndex !== null &&
                            columnIndex !== 0 &&
                            columnIndex !== 9
                          ) {
                            handleRowClick(record, index, columnIndex);
                          }
                        },
                      };
                    }}
                    summary={() => {
                      return (
                        <>
                          {page !== 0 &&
                            orderBillingData.orderBillingLoading && (
                              <Table.Summary.Row>
                                <Table.Summary.Cell
                                  index={1}
                                  colSpan={11}
                                  align="center"
                                >
                                  <Spin />
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            )}
                        </>
                      );
                    }}
                    scroll={{ y: tableHeightRef.current }}
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </Card>
        <Card
          className="b-radius-0 "
          style={{ marginTop: "10px", padding: "8px 0px 0px 0px" }}
        >
          <Grid
            container
            // spacing={3}
            justifyContent="start"
            alignItems={"center"}
          >
            <Grid
              item
              xs={4}
              sm={6}
              md={6}
              lg={6}
              sx={{ paddingLeft: "18px" }}
              pb={1}
            >
              <Typography className="inv-lable-input">
                {`${selectedRowKeys.length > 0 && totalSelected === 0
                    ? "selected " + selectedRowKeys.length
                    : totalSelected !== 0
                      ? "selected " + totalSelected
                      : ""
                  }`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={8}
              sm={6}
              md={6}
              lg={6}
              pb={1}
              // pt={1}
              // sx={{ direction: "rtl" }}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <Grid item>
                  <Typography
                    sx={{ paddingRight: "10px" }}
                    className="inv-lable-input"
                  >
                    {`${selectedRowKeys.length > 0
                        ? `Total Order Value: ₹ ${totalOrderValue?.toFixed(2)}`
                        : ""
                      }`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className={`${selectedRowKeys.length > 0 ? "crt-btn-colr" : ""
                      }`}
                    style={{ height: "36px", width: "125px", fontSize: "12px" }}
                    type="submit"
                    disabled={selectedRowKeys.length > 0 ? false : true}
                    sx={{ mr: 2 }}
                    onClick={handleConvertToBill}
                  >
                    {" "}
                    convert to bill
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        {isShow ? (
          <ReviewOrder
            onClose={() => setIsShow(false)}
            requestData={requestData}
          />
        ) : (
          <></>
        )}
      </Box>
      <Dialog
        open={deleteIndex !== null}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: "333px", padding: "12px 16px" }}>
          <Box
            className="close_icon_btn cursor_pointer"
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
            onClick={handleDeleteCancel}
          >
            <IoClose className="ClosePop" />
          </Box>
          <DialogContentText
            className="dialog-title-remove"
            id="alert-dialog-description"
            style={{ paddingBottom: "10px" }}
          >
            Are you sure you want to remove?
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "black", fontSize: "12px", marginTop: "4px" }}
          >
            Note:The following{" "}
            <span className="dialog-title-remove" style={{ fontSize: "12px" }}>
              Order ID:{orderId}{" "}
            </span>
            will be removed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="crt-btn-cancel"
            style={{
              height: "30px",
              width: "25%",
              marginRight: "3px",
              fontSize: "12px",
            }}
            onClick={handleDeleteCancel}
          >
            {" "}
            Cancel
          </Button>
          <Button
            variant="contained"
            className="crt-btn-colr"
            style={{ height: "30px", width: "25%", fontSize: "12px" }}
            type="submit"
            sx={{ mr: 2 }}
            onClick={handleDeleteConfirm}
          >
            {" "}
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={convertBillPopUp}
        onClose={handleDeleteCancelConvertPopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: "333px", padding: "12px 16px" }}>
          <Box
            className="close_icon_btn cursor_pointer"
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
            onClick={handleDeleteCancelConvertPopUp}
          >
            <IoClose className="ClosePop" />
          </Box>
          <DialogContentText
            className="dialog-title-remove"
            id="alert-dialog-description"
          >
            Are you sure you want to convert the orders to bill?
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "black", fontSize: "12px", marginTop: "4px" }}
          >
            <span className="dialog-title-remove">
              {selectedRowKeys.length > 0 && totalSelected === 0 ? selectedRowKeys.length : totalSelected !== 0
                ? totalSelected
                : ""
              }
            </span>{" "}
            orders of value{" "}
            <span className="dialog-title-remove">
              ₹ {totalOrderValue?.toFixed(2)}{" "}
            </span>{" "}
            will be converted to bill.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="crt-btn-cancel"
            style={{
              height: "30px",
              width: "25%",
              marginRight: "3px",
              fontSize: "12px",
            }}
            onClick={handleDeleteCancelConvertPopUp}
          >
            {" "}
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            className="crt-btn-colr"
            style={{ height: "30px", width: "25%", fontSize: "12px" }}
            type="submit"
            loadingPosition="start"
            loadingIndicator={
              <CircularProgress
                size={10}
                sx={{ color: "white", marginLeft: "-10px" }}
              />
            }
            loading={btnLoader}
            sx={{ mr: 2 }}
            onClick={handleConfirmConvertPopup}
          >
            {" "}
            convert
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
