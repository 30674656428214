import React, { useRef } from "react";
import {
  Button,
  Grid,
  TextField,
  Box,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  InputLabel,
  Typography,
  Card,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
} from "@mui/material";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CommonTable from "../../../../components/Tables/CommonTable";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { TableColumnsType } from "antd";
import { Input } from "antd";
import TableSearchDataTable from "../../../../components/Modal/TableSearchModal";
import * as XLSX from "xlsx";
import Budgetplanning from "./BudgetPlanning";

const { Search } = Input;
interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}
interface DataTypetable{
    key: React.Key;
    SlabNo: string;
    FromBillQty: string; 
    UMO:string;
    PayoutPercent:string;
    ThresholdAmount:string;
}
interface DataType {
  key: React.Key;
  ProductCode: string;
  ProductName: string;
}
interface ModelDataType {
  key: React.Key;
  RetailerCode: String;
  RetailerChannelName: string;
}

const ProductRuleSetting: React.FC<DistributorTabProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        //   width: 250,
      },
    },
  };
  const handleChanges = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };

  const row: DataTypetable[] = [
    {
      key: 1,
      SlabNo: "1",
      FromBillQty: "1.0",
      UMO:'PC',
      PayoutPercent:'43.86',
      ThresholdAmount:""
    },
  ];
  const column: TableColumnsType<DataType> = [
    {
      dataIndex: "SlabNo",
      title: "Slab No"
      // sorter: (a, b) => a.Distributor.length - b.Distributor.length,
    },
    {
      dataIndex: "FromBillQty",
      title:"From Bill Qty"
      // sorter: (a, b) => a.Distributor.length - b.Distributor.length,
    },
    {
        dataIndex: "UOM",
        title:"UMO"
        // sorter: (a, b) => a.Distributor.length - b.Distributor.length,
      },
      {
        dataIndex: "PayoutPercent",
        title:"Payout Percent"
        // sorter: (a, b) => a.Distributor.length - b.Distributor.length,
      },
      {
        dataIndex: "ThresholdAmount",
        title:"Threshold Amount"
        // sorter: (a, b) => a.Distributor.length - b.Distributor.length,
      },
  ];
  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "ProductCode",
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Product Code</Typography>
          </Grid>
          <Grid item>
            <Search placeholder="Search code" />
          </Grid>
        </Grid>
      ),
      // sorter: (a, b) => a.Distributor.length - b.Distributor.length,
    },
    {
      dataIndex: "ProductName",
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Product Name</Typography>
          </Grid>
          <Grid item>
            <Search placeholder="Search code" />
          </Grid>
        </Grid>
      ),
      // sorter: (a, b) => a.Distributor.length - b.Distributor.length,
    },
  ];


  const data: DataType[] = [
    {
      key: 1,
      ProductCode: "3023",
      ProductName: "	RS Family Mart Muradnagar",
    },
  ];

  //model table
  const DataStateSelection: ModelDataType[] = [
    {
      key: "1",
      RetailerCode: "T/AB",
      RetailerChannelName: "AMBIENT BEVERAGE",
    },
    {
      key: "2",
      RetailerCode: "CC",
      RetailerChannelName: "COLD CHAIN",
    },
    {
      key: "3",
      RetailerCode: "GT/DA",
      RetailerChannelName: "DAIRY",
    },
  ];
  // retailer channel
  const columnsForStateSelection = [
    {
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Retailer Group Code</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={(e) =>
                handleFilterChangeStateSelection(e.target.value, "RetailerCode")
              }
            />
          </Grid>
        </Grid>
      ),
      dataIndex: "RetailerCode",
      key: "RetailerCode",
    },
    {
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Retailer Group Name</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={(e) =>
                handleFilterChangeStateSelection(
                  e.target.value,
                  "RetailerChannelName"
                )
              }
            />
          </Grid>
        </Grid>
      ),
      dataIndex: "RetailerChannelName",
      key: "RetailerChannelName",
    },
  ];

  const [retailerChannel, setRetailerChannel] =
    React.useState(DataStateSelection);
  const [filteredDataStateSelection, setFilteredDataStateSelection] =
    React.useState(retailerChannel); // Initialize with initial data
  const handleFilterChangeStateSelection = (
    value: string,
    dataIndex: keyof ModelDataType
  ) => {
    if (value === "") {
      setFilteredDataStateSelection(retailerChannel);
      return;
    } else {
      const newData = retailerChannel.filter((item) =>
        String(item[dataIndex]).toLowerCase().includes(value.toLowerCase())
      );
      setFilteredDataStateSelection(newData);
    }
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const [selectionType, setSelectionType] = React.useState<
    "radio" | "checkbox"
  >("checkbox"); // Change 'radio' to 'checkbox'

  // Checkbox row selection
  const rowSelection = {
    type: selectionType, // Assign the value of selectionType
    selectedRowKeys,
    onChange: onSelectChange, // Initialize if needed
  };
  const [openStateSelectionModel, setOpenStateSelectionModel] =
    React.useState(false);

  const HandleStateSelectionClick = (event: React.MouseEvent<unknown>) => {
    setOpenStateSelectionModel(!openStateSelectionModel);
  };

  const DisSchemeLevelcount = ["Product", ""];
  const ProductHierarchy = ["Product", ""];
  const uom = ["PIECES", ""];

  
  return (
    <>
  
        <Box>
          <form>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">Scheme At Level</Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    className="selct-box"
                  >
                    {DisSchemeLevelcount.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">Product Hierarchy</Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    className="selct-box"
                  >
                    {ProductHierarchy.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">
                  Scheme Description
                </Typography>
                <TextField variant="outlined" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">Recipient</Typography>
                <TextField variant="outlined" className="Txt-box" />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} mt={2}>
              <Typography className="fnt-lable">
                select The Attribute
              </Typography>
              <Grid item>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="division"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="division"
                    />
                    <FormControlLabel
                      value="brandPack"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="brandPack "
                    />
                    <FormControlLabel
                      value="product"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="product "
                    />
                    <FormControlLabel
                      value="brandCategory"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="brandCategory "
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 3, sm: 3 }}
              mt={3}
            >
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">Product Attribute</Typography>
                <TextField variant="outlined" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3} mt={3}>
                <Button
                  sx={{ ml: 1, color: "#fff" }}
                  className="btn-colr"
                  //   onClick={(event) => HandleStateSelectionClick(event)}
                >
                  ...
                </Button>
                <TableSearchDataTable
                  // rowSelection={rowSelection}
                  data={filteredDataStateSelection}
                  columns={columnsForStateSelection}
                  isModalOpen={openStateSelectionModel}
                  setIsModalOpen={setOpenStateSelectionModel}
                />
              </Grid>

              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 3, sm: 3 }}
                mt={3}
              >
                <Grid item xs={8}>
                  <CommonTable
                    // rowSelection={rowSelection}
                    data={data}
                    columns={columns}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
              <Grid item xs={12} sm={4} md={2} mt={2}>
                <Typography className="fnt-lable">Flexi</Typography>
                <Grid item>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 18,
                                color: "#6750A4",
                              },
                            }}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 18,
                                color: "#6750A4",
                              },
                            }}
                          />
                        }
                        label="No "
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4} md={2} mt={2}>
                <Typography className="fnt-lable">combi</Typography>
                <Grid item>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 18,
                                color: "#6750A4",
                              },
                            }}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 18,
                                color: "#6750A4",
                              },
                            }}
                          />
                        }
                        label="No "
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4} md={2} mt={2}>
                <Typography className="fnt-lable">Range</Typography>
                <Grid item>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Yes"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 18,
                                color: "#6750A4",
                              },
                            }}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 18,
                                color: "#6750A4",
                              },
                            }}
                          />
                        }
                        label="No "
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4} md={4} mt={3}>
                <Typography className="fnt-lable">
                  Apply Scheme On Pro-Rate Basis
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    label=""
                    control={<Checkbox defaultChecked className="check-box" />}
                  />
                </FormGroup>
              </Grid>
              <Grid item  xs={2}></Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">
                 From Bill Qty 
                </Typography>
                <TextField variant="outlined" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">UOM</Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    className="selct-box"
                  >
                    {uom.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">
                 For Every 
                </Typography>
                <TextField variant="outlined" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">
              Payout Percent
                </Typography>
                <TextField variant="outlined" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
              <Button
                  className="btn-colr "
                  variant="contained"
                >
                 
                + Add Slab
                </Button>
              </Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={12}>
                  <CommonTable
                    // rowSelection={rowSelection}
                    data={row}
                    columns={column}
                  />
                </Grid>
            </Grid>
            <Grid
              item
              mt={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item sx={{ ml: 2 }}>
                <Button variant="outlined" onClick={handleBackClick}>
                  Back
                </Button>
              </Grid>
              <Grid item sx={{ ml: 2 }}>
                <Button
                  className="btn-colr "
                  variant="contained"
                >
              
              Save
                </Button>
              </Grid>
              <Grid item sx={{ ml: 2 }}>
                <Button variant="outlined" onClick={handleBackClick}>
                  <CancelIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />{" "}
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
  
    </>
  );
};
export default ProductRuleSetting;
