import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import createAxiosInstance from "../../../hooks/useFetch";

// Define your interfaces
interface SaleableData {
  values: string[];
  products: string[];
  count: number;
}

interface Product {
  division: string;
  expiryDate: string;
  lastPurchaseDate: string;
  manufacturingDate: string;
  nearToexpiry: boolean;
  productCode: string;
  productHierarchy: string;
  productName: string;
  reason: string;
  scheme: boolean;
  stockInHand: string;
  value: string;
}

interface ProductDetails {
  batchCode: string;
  daysToExpiry: number;
  expiryColor: string;
  expiryDate: string;
  mrp: number;
  productCode: string;
  scheme: boolean;
  stockInHand: number;
  stockValue: number;
}

interface SaleableProductDetail {
  availableUom: string;
  basicMaterial: string;
  brand: string;
  division: string;
  packageType: string;
  productCode: string;
  productDescription: string;
  productHierarchy: string;
  productName: string;
  segment: string;
  size: string;
  subVariant: string;
  totalNoOfPcs: number;
  totalSaleableStock: number;
  totalStockValue: number;
  variant: string;
}

interface SaleableTableData {
  filteredCount: number;
  products: Product[];
}
interface SaleableExpandedData {
  productDescription: string;
  matGrp2Desc: string;
  productCode: string;
  batchCode: string;
  saleableStock: string;
  saleableStockValue: string;
  margin: string;
  mrp: string;
  manufactureDate: string;
  expiryDate: string;
  daysToExpire: string;
  expiryColor: string;
}
interface SaleableTableProductDatas {
  productDescription: string;
  matGrp2Description: string;
  productCode: string;
  stockInHand: string;
  stockValue: string;
  division: string;
  productDetails: SaleableExpandedData[];
}
interface SaleableState {
  saleableCounts: SaleableData[];
  saleableCountsLoading: boolean;
  saleableCountsError: string | null;

  saleableProducts: SaleableTableData;
  saleableProductsLoading: boolean;
  saleableProductsError: string | null;

  saleableProductData: SaleableProductDetail | null;
  saleableProductDataLoading: boolean;
  saleableProductDataError: string | null;
  saleableExtendedData: SaleableTableProductDatas[];
  saleableExtendedDataLoading: boolean;
  saleableExtendedDataError: string | null;
  saleableProductList: ProductDetails[];
  saleableProductListLoading: boolean;
  saleableProductListError: string | null;
  loading: boolean;
  error: string | null;
}

interface FilterDataRequest {
  // distributorCode: string;
  page: number;
  size: number;
  pageStatus: boolean;
  type: string;
  stock: string;
  division: string;
  segment: string;
  brand: string;
  variant: string;
  subVariant: string;
  packagingType: string;
  productSize: string;
  search: string;
}

interface ProductDataRequest {
  productCode: string;
  // distributorCode: string;
  page: number;
  size: number;
}
interface SaleableProductRequest {
  productHierarchy: string;
  // distributorCode: string;
  page: number;
  size: number;
}
interface ProductDetailRequest {
  productCode: string;
  // distributorCode: string;
  type: string;
}

const axiosInstance = createAxiosInstance();

export const getSaleableCounts = createAsyncThunk(
  "saleable/dashboard_counts",
  async () => {
    const response = await axiosInstance.get(
      "inventory/get-saleable-product-counts"
    );
    return response.data.data;
  }
);

export const getSaleableProducts = createAsyncThunk(
  "saleable/saleable_products",
  async (req: { params: FilterDataRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    console.log(signal);
    const response = await axiosInstance.get(
      "inventory/get-saleable-stock-product",
      {
        params,
        signal,
      }
    );
    return response.data.data;
  }
);

export const getSaleableProductsList = createAsyncThunk(
  "saleable/saleable_product_hierarchy",
  async (req: ProductDataRequest) => {
    const response = await axiosInstance.get(
      "inventory/get-saleable-product-list",
      {
        params: req,
      }
    );
    return response.data.data;
  }
);

export const getSaleableProductDetails = createAsyncThunk(
  "saleable/get-product-details",
  async (req: ProductDetailRequest) => {
    const response = await axiosInstance.get("inventory/get-product-details", {
      params: req,
    });
    return response.data.data;
  }
);
export const getSaleableExtendedDetails = createAsyncThunk(
  "saleable/get-saleable-product",
  async (req: SaleableProductRequest) => {
    const response = await axiosInstance.get("inventory/get-saleable-product", {
      params: req,
    });
    return response.data.data;
  }
);
const initialState: SaleableState = {
  saleableCounts: [],
  saleableCountsLoading: false,
  saleableCountsError: null,

  saleableProducts: { filteredCount: 0, products: [] },
  saleableProductsLoading: false,
  saleableProductsError: null,

  saleableProductData: null,
  saleableProductDataLoading: false,
  saleableProductDataError: null,

  saleableProductList: [],
  saleableProductListLoading: false,
  saleableProductListError: null,
  loading: false,
  error: null,
  saleableExtendedData: [],
  saleableExtendedDataLoading: false,
  saleableExtendedDataError: null,
};

const saleableSlice = createSlice({
  name: "saleable",
  initialState,
  reducers: {
    ProductFilterFetch: (
      state,
      action: PayloadAction<Partial<SaleableState>>
    ) => {
      state.saleableCounts =
        action.payload.saleableCounts || state.saleableCounts;
      state.saleableProducts =
        action.payload.saleableProducts || state.saleableProducts;
      state.saleableProductData =
        action.payload.saleableProductData || state.saleableProductData;
      state.saleableProductList =
        action.payload.saleableProductList || state.saleableProductList;
    },
  },
  extraReducers: (builder) => {
    // Saleable Counts
    builder.addCase(getSaleableCounts.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.saleableCountsLoading = true;
      state.saleableCountsError = null;
    });
    builder.addCase(
      getSaleableCounts.fulfilled,
      (state, action: PayloadAction<SaleableData[]>) => {
        state.saleableCounts = action.payload ?? [];
        state.saleableCountsLoading = false;
        state.saleableCountsError = null;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(getSaleableCounts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.saleableCountsLoading = false;
      state.saleableCountsError = action.error.message ?? null;
    });

    // Saleable Products
    builder.addCase(getSaleableProducts.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.saleableProductsLoading = true;
      state.saleableProductsError = null;
    });
    builder.addCase(
      getSaleableProducts.fulfilled,
      (state, action: PayloadAction<SaleableTableData>) => {
        state.saleableProducts = action.payload;
        state.loading = false;
        state.error = null;
        state.saleableProductsLoading = false;
        state.saleableProductsError = null;
      }
    );
    builder.addCase(getSaleableProducts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.saleableProductsLoading = false;
      state.saleableProductsError = action.error.message ?? null;
    });

    // Product Details
    builder.addCase(getSaleableProductDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.saleableProductDataLoading = true;
      state.saleableProductData = null;
      state.saleableProductDataError = null;
    });
    builder.addCase(
      getSaleableProductDetails.fulfilled,
      (state, action: PayloadAction<SaleableProductDetail>) => {
        state.saleableProductData = action.payload;
        state.loading = false;
        state.error = null;
        state.saleableProductDataLoading = false;
        state.saleableProductDataError = null;
      }
    );
    builder.addCase(getSaleableProductDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.saleableProductDataLoading = false;
      state.saleableProductDataError = action.error.message ?? null;
    });

    // Product List
    builder.addCase(getSaleableProductsList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.saleableProductListLoading = true;
      state.saleableProductListError = null;
    });
    builder.addCase(
      getSaleableProductsList.fulfilled,
      (state, action: PayloadAction<ProductDetails[]>) => {
        state.saleableProductList = action.payload ?? [];
        state.loading = false;
        state.error = null;
        state.saleableProductListLoading = false;
        state.saleableProductListError = null;
      }
    );
    builder.addCase(getSaleableProductsList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.saleableProductListLoading = false;
      state.saleableProductListError = action.error.message ?? null;
    });
    builder.addCase(getSaleableExtendedDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.saleableExtendedData = [];
      state.saleableExtendedDataLoading = true;
      state.saleableExtendedDataError = null;
    });
    builder.addCase(
      getSaleableExtendedDetails.fulfilled,
      (state, action: PayloadAction<SaleableTableProductDatas[]>) => {
        state.saleableExtendedData = action.payload ?? [];
        state.loading = false;
        state.error = null;
        state.saleableExtendedDataLoading = false;
        state.saleableExtendedDataError = null;
      }
    );
    builder.addCase(getSaleableExtendedDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.saleableExtendedDataLoading = false;
      state.saleableExtendedDataError = action.error.message ?? null;
    });
  },
});

export const { ProductFilterFetch } = saleableSlice.actions;
export default saleableSlice.reducer;
