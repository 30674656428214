import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Checkbox,
  Grid,
  Typography,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import type { TableColumnsType } from "antd";
import { Col, Row, Table } from "antd";
import CommonTable from "../../../components/Tables/CommonTable";
//icons
import Addicon from "../../../assets/images/icons/add.png";
import Rightdot from "../../../assets/images/icons/Right_dot.png";
import MasterCompanyEdit from "./CompanyEditform";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import MasterCompanyCreate from "./MasterCompanyCreate";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import {
  companyEdit,
  companyFetchData,
  companyActiveStatus,
  companyActiveDefault,
} from "../../../Redux/Masters/Company/action";
import InfiniteScroll from "react-infinite-scroll-component";
import TableSkeletonLoader from "../../../components/Skeleton/skeleton";
import Modal from "@mui/material/Modal";
import * as XLSX from "xlsx";
import { IoClose } from "react-icons/io5";
// Define a type for the state object
interface StateObject {
  isChecked: any;
  id: string;
}
interface DataType {
  key: React.Key;
  name: string;
  code: number;
  businessVertical: string;
  address: string;
  city: string;
  postalcode: number;
  gstno: string;
  emailid: string;
  state: string;
  isDefault: boolean;
  status: boolean;
  id: string;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};

export default function MasterCompany() {
  // const classes = useStyles();
  const renderAfterCalled = useRef(false);
  const [open, setOpen] = React.useState(false);
  const [editingData, setEditingData] = React.useState<DataType | null>(null);
  const [showForm, setShowForm] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isShow, setIsShow] = React.useState(false);
  const [textFieldValue, setTextFieldValue] = React.useState("");
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const dispatch = useAppDispatch();
  const [checkboxStatus, setCheckboxStatus] = useState<{ [key: string]: any }>(
    {}
  );
  const [checkboxDefault, setCheckboxDefault] = useState<{
    [key: string]: any;
  }>({});
  const [requestData, setRequestData] = useState({ page: 0, size: 5 });
  const [prevData, setPrevData] = useState<any[]>([]);
  const [isDefaultActive, setIsDefaultActive] = React.useState(false);
  const [sampleState, setSampleState] = useState<StateObject | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [skeleton, setskeleton] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isUpload, setIsUpload] = React.useState(false);
  const [uploadError, setUploadError] = React.useState("");


  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleCreateNew = () => {
    setShowForm(!showForm);
  };

  const handleEditClick = (rowData: DataType) => {
    setIsEditing(!isEditing);
    toggleDrawer(true);
    setEditingData(rowData); // Set editingData when edit button is clicked
    dispatch(companyEdit(rowData.id));
  };

  const handleShowClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setIsShow(!isShow);
    toggleDrawer(true);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  //fetch data from db
  const companyFetchDatas = useAppSelector(
    (state: any) => state.companyFetch.companyFetch
  );
  const CompanyLength = companyFetchDatas?.length;

  useEffect(() => {
    if (prevData) {
      const initialCheckboxStatus: { [key: string]: any } = {};
      const initialCheckboxDefault: { [key: string]: any } = {}; // Declare initialCheckboxDefault
      prevData.forEach((record: any) => {
        initialCheckboxStatus[record.id] = record.status;
        initialCheckboxDefault[record.id] = record.isDefault;
      });
      setCheckboxStatus(initialCheckboxStatus);
      setCheckboxDefault(initialCheckboxDefault);
    }
  }, [prevData]);

  useEffect(() => {
    if (Array.isArray(companyFetchDatas)) {
      if (requestData.page === 0) {
        setPrevData(companyFetchDatas);
        setskeleton(false);
      } else {
        setPrevData((prevData) => [...prevData, ...companyFetchDatas]);
        setskeleton(false);
      }
    } else {
      setskeleton(false);
    }
  }, [companyFetchDatas]);

  const fetchMoreData = () => {
    const nextPage = requestData.page + 1;
    const nextSize = requestData.size;
    const updatedRequestData = {
      ...requestData,
      page: nextPage,
      size: nextSize,
    };
    setRequestData(updatedRequestData);
    dispatch(companyFetchData(updatedRequestData));
    if (prevData?.length < nextSize) {
      setHasMore(false);
    }
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      dispatch(companyFetchData(requestData));
    }
    return () => {
      renderAfterCalled.current = true;
    };
  }, [dispatch, requestData]);

  //CheckBox Active in Active
  const handleChange = (isChecked: any, id: string) => {
    prevData.forEach((record: any) => {
      if (record.id === id) {
        dispatch(
          companyActiveStatus({ id: record.id.toString(), value: isChecked })
        );
        setCheckboxStatus((prevState) => ({
          ...prevState,
          [id]: isChecked,
        }));
      }
    });
  };

  const handleActiveClick = (isChecked: any, id: string) => {
    setIsDefaultActive(!isDefaultActive);
    const newState: StateObject = {
      isChecked: isChecked,
      id: id,
    };
    setSampleState(newState);
  };

  //confirmation active
  const handleOk = () => {
    if (sampleState) {
      const { isChecked, id } = sampleState;
      if (isChecked !== undefined && id !== undefined) {
        handleChangeDefault(isChecked, id);
      }
    }
  };

  const handleChangeDefault = (isChecked: any, id: string) => {
    // Set all checkboxes to false
    const updatedCheckboxDefault: { [key: string]: any } = {};
    for (const record of prevData) {
      updatedCheckboxDefault[record.id] = false;
    }
    updatedCheckboxDefault[id] = isChecked;

    setCheckboxDefault(updatedCheckboxDefault);
    dispatch(companyActiveDefault({ id: id.toString(), value: isChecked }));
    setIsDefaultActive(false);
  };

  //Model Close
  const handleClose = () => setIsDefaultActive(false);
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    handleClose();
    setIsDefaultActive(false);
  };

  const handleUploadClick = () => {
    setUploadError("");
    setIsUpload(!isUpload);
  };

  const handleCloseUpload = () => {
    setIsUpload(false);
    setUploadError("");
    setUploadedFile(null);
  }



  //company table
  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "name",
      title: "NAME",
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      dataIndex: "code",
      title: "CODE",
    },

    {
      dataIndex: "businessVertical",
      title: "BUSINESS VERTICAL ",
      // sorter: (a, b) => a.businessVertical.length - b.businessVertical.length,
    },
    {
      dataIndex: "address",
      title: " ADDRESS ",
      // sorter: (a, b) => a.address.length - b.address.length,
    },
    {
      dataIndex: "city",
      title: "CITY",
      // sorter: (a, b) => a.city.length - b.city.length,
    },
    {
      dataIndex: "state",
      title: "STATE",
    },

    {
      dataIndex: "postalCode",
      title: "POSTAL CODE",
    },
    {
      dataIndex: "gstNo",
      title: "GST NO",
      // sorter: (a, b) => a.gstno.length - b.gstno.length,
    },
    {
      dataIndex: "email",
      title: "EMAIL ID ",
      // sorter: (a, b) => a.emailid.length - b.emailid.length,
    },
    {
      dataIndex: "isDefault",
      title: "Action",

      render: (_: any, record: DataType) => (
        <>
          <Checkbox
            className="check-box"
            onClick={(event) => {
              const isChecked = (event.target as HTMLInputElement).checked;
              const recordId = record.id;
              handleActiveClick(isChecked, recordId);
              event.stopPropagation();
            }}
            checked={checkboxDefault[record.id] === true ? true : false}
          />
        </>
      ),
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (_: any, record: DataType, index: number) => (
        <Row>
          <Col>
            <AntSwitch
              checked={checkboxStatus[record.id] === true}
              onClick={(e) => e.stopPropagation()}
              onChange={(event) =>
                handleChange(event.target.checked, record.id)
              }
              inputProps={{ "aria-label": "ant design" }}
            />
          </Col>
          <Col>
            <Grid item style={{ marginLeft: "20px" }}>
              {checkboxStatus[record.id] === true ? (
                <Typography>active</Typography>
              ) : (
                <Typography>Inactive</Typography>
              )}
            </Grid>
          </Col>
        </Row>
      ),
    },
  ];

  //AntSwitch
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    // display: 'flex',
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          background: "linear-gradient(to right, #614FB3, #944986)",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      // transition: theme.transitions.create(['width'], {
      //   duration: 200,
      // }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const headers = [
    "NAME",
    "CODE",
    "BUSINESS VERTICAL",
    "ADDRESS",
    "CITY",
    "STATE",
    "POSTAL CODE",
    "GST NO",
    "EMAIL ID",
  ];

  function convertHeadersToXLS(headers: any) {
    const worksheet = XLSX.utils.aoa_to_sheet([headers]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    return blob;
  }

  // Function to download XLS file with headers only
  function downloadXLSWithHeaders(headers: any) {
    const xlsBlob = convertHeadersToXLS(headers);
    const url = URL.createObjectURL(xlsBlob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "exported_headers.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    
    if (file) {
      const fileNameParts = file.name.split(".");
      const fileExtension = fileNameParts[fileNameParts.length - 1];

      if (fileExtension !== "xlsx" && fileExtension !== "xls") {
        setUploadError("Unsupported file format. Please upload a .xlsx or .xls file.");
        event.target.value = "";
        setUploadedFile(null);
        return;
      }
      setUploadError("");
      setUploadedFile(file);
    }
    
  };

  const handleSubmit = async () => {
    if (!uploadedFile) {
      setUploadError("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append('file', uploadedFile);
    console.log("uploadedFile", uploadedFile);
    // handleCloseUpload();

    // try {
    //   const response = await axios.post('YOUR_API_ENDPOINT', formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   });

    //   if (response.status === 200) {
    //     setIsUpload(true);
    //     setUploadError("");
    //     handleCloseUpload();
    //     console.log("File uploaded successfully");
    //   } else {
    //     setUploadError("Failed to upload the file. Please try again.");
    //   }
    // } catch (error) {
    //   setUploadError("Failed to upload the file. Please try again.");
    // }
  };

  return (
    <>
      {skeleton ? (
        <Box
          sx={{
            marginTop: "2px",
            p: { xs: 2, sm: 3 },
            pt: { xs: 2, sm: 3 },
          }}
        >
          <Grid container>
            <Grid item xs={6} className="mar-tb">
              <Skeleton
                variant="text"
                sx={{ fontWeight: "700", fontSize: "16px" }}
              />
            </Grid>
            <Grid
              item
              container
              xs={6}
              sm={6}
              md={6}
              className="mar-tb"
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Grid item sx={{ display: "flex" }}>
                <Button className="mastercrt-button">
                  <Skeleton variant="rectangular" />
                </Button>
                <Grid item>
                  <Skeleton variant="rectangular" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box id="TableScroll">
            <TableSkeletonLoader rowCount={CompanyLength} columns={10} />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            marginTop: "3px",
            p: { xs: 2, sm: 3 },
            pt: { xs: 2, sm: 3 },
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              style={{ display: "flex", alignItems: "center" }}
            // className="mar-tb"
            >
              <Typography>
                <b className="header-lable">Company Master</b>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              // className="mar-tb"
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Grid
                item
                sx={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <Button
                  className="mastercrt-button"
                  onClick={() => downloadXLSWithHeaders(headers)}
                >
                  <span className="Crt-lable"> Sample Excel</span>
                </Button>
                <Button className="mastercrt-button" onClick={handleUploadClick}>
                  <span className="Crt-lable">Upload</span>
                </Button>
                <Button className="mastercrt-button" onClick={handleShowClick}>
                  {" "}
                  <img
                    src={Addicon}
                    alt={Addicon}
                    style={{ width: "19px", height: "19px " }}
                  />{" "}
                  <span className="Crt-lable"> Create New</span>
                </Button>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={Rightdot}
                    alt={Rightdot}
                    style={{ width: "42px", height: "42px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box id="TableScroll" sx={{ mt: 4 }}>
            {/* <Grid item xs={12} sx={{ display: "flex", justifyContent: "end", mb: 2 }} >
          <SearchBar
            className="search-table"
            value={textFieldValue}
            onChange={(newValue) => {
              setTextFieldValue(newValue);
              handleSearch();
            }}
          />
        </Grid> */}
            <InfiniteScroll
              dataLength={companyFetchDatas?.length ?? 0}
              next={fetchMoreData}
              hasMore={false}
              scrollableTarget="TableScroll"
              className=""
              loader={
                <h6
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <CircularProgress
                    style={{
                      color: "#119BFF",
                    }}
                    size={20}
                  />
                </h6>
              }
            // endMessage={
            //   companyFetchDatas?.length !== 0 && (
            //     <p className="fs-12 noMore">
            //       <b>No more data found...</b>
            //     </p>
            //   )
            // }
            >
              <CommonTable
                // rowSelection={rowSelection}
                data={prevData ? prevData : companyFetchDatas}
                columns={columns}
                onRow={(record) => ({
                  onClick: () => handleEditClick(record),
                })}
              />
            </InfiniteScroll>
          </Box>
          {/* {data.map((rowData) => (
        <Button
          key={rowData.key}
          onClick={(event) => handleEditClick(event, rowData)}
        >
          Edit
        </Button>
      ))} */}
          {isShow ? (
            <MasterCompanyCreate
              onClose={() => setIsShow(false)}
              requestData={requestData}
            />
          ) : isEditing ? (
            <MasterCompanyEdit
              onClose={() => setIsEditing(false)}
              requestData={requestData}
            />
          ) : (
            <></>
          )}

          <Modal
            open={isDefaultActive}
            onClose={handleClose}
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-description">
                Are you sure you want to activate the status?
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleBackClick}
                  sx={{ mr: 2 }}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  className="btn-colr"
                  onClick={handleOk}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={isUpload}
            onClose={handleCloseUpload}
            aria-describedby="modal-modal-description"
            BackdropProps={{ onClick: (event) => event.stopPropagation() }}

          >
            <Box sx={style} >
              <div style={{ display: "flex", justifyContent: "flex-end" }} onClick={handleCloseUpload}>
                <IoClose className="Close_icon" />
              </div>
              <Grid item>
                <div className="inputDnD" style={{ position: "relative" }}>
                  <input
                    type="file"
                    title=""
                    accept=".xlsx, .xls"
                    onChange={handleFileUpload}
                    className="form-control-file font-weight-bold"
                  />
                  {uploadedFile ? (
                    <Typography variant="body2" className="assetFileSub">{uploadedFile.name}</Typography>
                  )
                    :
                    <p className="assetFileSub">Click here to add Attachment.</p>
                  }
                </div>
                <Typography className="uploadError">{uploadError}</Typography>
              </Grid>

              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleCloseUpload}
                  sx={{ mr: 2 }}
                  className="crt-btn-cancel "
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  variant="contained"
                  className="btn-colr"
                  onClick={handleSubmit}
                >
                  Upload
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      )}
    </>
  );
}
