import React, { useState } from 'react';
import { Box, TextField, FormControl, Button, Grid, InputLabel, FormControlLabel, Link, Radio, RadioGroup, Card, Select, MenuItem, TextareaAutosize, Checkbox } from '@mui/material';
import type { TableColumnsType } from 'antd';
import SearchBar from '@mkyy/mui-search-bar';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CommonTable from '../../../components/Tables/CommonTable';

interface DataType {
    key: React.Key;
    type: string;
    dateVisit: string;
    dayVisit: string;
    routeName: string;
    action: string;
}


interface RoutePlanFormProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
}

const RoutePlanForm: React.FC<RoutePlanFormProps> = ({ onBack }) => {
    const [textFieldValue, setTextFieldValue] = React.useState('');

    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

    const handleSearch = () => {
        //search function logic here
    };


    const handleBackClick = (event: React.MouseEvent<unknown>) => {
        onBack(event); // Pass the event argument to onBack function
    };

    const Options = ['Radio Button', 'Checkbox', 'Dropdown'];


    const columns: TableColumnsType<DataType> = [


        {
            dataIndex: 'type',
            title: 'Type',
            sorter: (a, b) => a.type.length - b.type.length,
        },



        {
            dataIndex: 'dateVisit',
            title: 'Date of Visit',
            sorter: (a, b) => a.dateVisit.length - b.dateVisit.length,
        },

        {
            dataIndex: 'dayVisit',
            title: 'Day of Visit',
            sorter: (a, b) => a.dayVisit.length - b.dayVisit.length,
        },

        {
            dataIndex: 'routeName',
            title: 'Route Name',
            sorter: (a, b) => a.routeName.length - b.routeName.length,
        },
        {
            dataIndex: 'action',
            title: 'Action ',
        },

    ];

    const data: DataType[] = [
        { key: 1, type: "001", dateVisit: 'Supplier1', dayVisit: 'Comapny1', routeName: 'Comapny1', action: " " },
        { key: 2, type: "002", dateVisit: 'Supplier2', dayVisit: 'Comapny2', routeName: 'Comapny1', action: " " },
        { key: 3, type: "003", dateVisit: 'Supplier3', dayVisit: 'Comapny3', routeName: 'Comapny1', action: " " },
        { key: 4, type: "004", dateVisit: 'Supplier4', dayVisit: 'Comapny4', routeName: 'Comapny1', action: " " },
        { key: 5, type: "005", dateVisit: 'Supplier5', dayVisit: 'Comapny5', routeName: 'Comapny1', action: " " },
        { key: 6, type: "006", dateVisit: 'Supplier6', dayVisit: 'Comapny6', routeName: 'Comapny1', action: " " },
        { key: 7, type: "007", dateVisit: 'Supplier7', dayVisit: 'Comapny7', routeName: 'Comapny1', action: " " },
        { key: 8, type: "008", dateVisit: 'Supplier8', dayVisit: 'Comapny8', routeName: 'Comapny1', action: " " },
    ];

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    return (
        <Box className='card-form card-bg'>

            <form>
                <Grid item container sx={{ marginTop: "16px" }}>
                    <Grid item xs={12} lg={6}>
                        <Grid item sx={{ display: "flex", mt: 2 }}>
                            <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                                <InputLabel size="normal" >  State Selection </InputLabel>
                            </Grid>
                            <Grid item xs={4} sx={{ mr: 5 }}>
                                <TextField sx={{ width: 200 }} size="small"></TextField>
                            </Grid>
                            <Button variant='contained' className='btn-colr'>...</Button>
                        </Grid>
                        <Grid item sx={{ display: "flex", mt: 2 }}>
                            <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                                <InputLabel size="normal" >  Distributor Branch </InputLabel>
                            </Grid>
                            <Grid item xs={4} sx={{ mr: 5 }}>
                                <FormControl sx={{ width: 200 }}>
                                    <Select>
                                        {Options.map((option, index) => (
                                            <MenuItem key={index} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>
                        <Grid item sx={{ display: "flex", mt: 2 }}>
                            <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                                <InputLabel size="normal" > Salesman Name </InputLabel>
                            </Grid>
                            <Grid item xs={4} sx={{ mr: 5 }}>
                                <FormControl sx={{ width: 200 }}>
                                    <Select>
                                        {Options.map((option, index) => (
                                            <MenuItem key={index} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <Card className="card-form" sx={{ border: "1px solid #EEE", mt: 4 }}>
                    <Grid className="survey-box-lable">
                        Route Coverage Setting Pattern
                    </Grid>
                    <Grid item container sx={{ marginTop: "16px" }}>
                        <Grid item xs={12} lg={6}>
                            <Grid item sx={{ display: "flex", mt: 2 }}>
                                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                                    <InputLabel size="normal" >  Type </InputLabel>
                                </Grid>
                                <Grid item xs={4} sx={{ mr: 5 }}>
                                    <FormControl sx={{ width: 200 }}>
                                        <Select>
                                            {Options.map((option, index) => (
                                                <MenuItem key={index} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>
                            <Grid item sx={{ display: "flex", mt: 2 }}>
                                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                                    <InputLabel size="normal" > Visite Date </InputLabel>
                                </Grid>
                                <Grid item xs={4} sx={{ mr: 5 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>

                                            <Grid item >

                                                <DatePicker name="startDate" />
                                            </Grid>


                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ display: "flex", mt: 2 }}>
                                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                                    <InputLabel size="normal" >  Route  </InputLabel>
                                </Grid>
                                <Grid item xs={4} sx={{ mr: 5 }}>
                                    <FormControl sx={{ width: 200 }}>
                                        <Select>
                                            {Options.map((option, index) => (
                                                <MenuItem key={index} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Button variant='contained' className='btn-colr'>Add</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
                <Grid item className='mar-tb'>
                    <Button variant='contained' className='btn-colr mar-10'>Generate Pattern</Button>
                </Grid>


                <Box className='mar-tb'>
                    <Grid item >
                        Route Coverage Pattern Grid
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', mb: 2 }} >
                        <SearchBar
                            className='search-table'
                            value={textFieldValue}
                            onChange={newValue => {
                                setTextFieldValue(newValue);
                                handleSearch();
                            }}
                        />
                    </Grid>
                    <CommonTable 
                    // rowSelection={rowSelection} 
                    data={data} columns={columns} />
                </Box>

                <Grid item xs={12} mt={2} display="flex" justifyContent="center">
                    <Button variant="outlined" color="primary" onClick={handleBackClick}>
                        Cancel
                    </Button>
                    <Button variant="contained" className='btn-colr' style={{ marginLeft: "10px" }}>
                        save
                    </Button>
                </Grid>
            </form>
        </Box>
    );
}

export default RoutePlanForm;
