import React, { useState } from "react";
import {
  Box,

  Tab,
} from "@mui/material";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DistributorRetailer from "../../FocusBrand/FocusBrandview/distributor&Retailer";
import Distributorprice from "./Distributorprice";
import PriceCustomerTab from "./PriceCustomerTab";
import PriceProductInfo from "./PriceProductInfo";
// import DistributorRetailer from "./distributor&Retailer";
// import ProductInfoView from "./ProductInfo";


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface PriceDiscountCreateProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const PriceDiscountCreate: React.FC<PriceDiscountCreateProps> = ({onBack}) => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };

  const DistributorOptions = ["select", "", ""];
  const Discount = ["none", "", ""];
  const geoHierarchyValueOptions = ["Value A", "Value B", "Value C"];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };

  return (
    <>
        <Box
          sx={{ width: "100%", height: "40px", backgroundColor: "#8080801f" }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Distributor Info"
                  value="1"
                //   className="customer-general"
                />
                <Tab label="Customer Category" value="2" />
                <Tab label="Product Info" value="3" />

              </TabList>
            </Box>
            <TabPanel value="1">
              <Distributorprice  onBack={handleBackClick}/>{" "}
            </TabPanel>
            <TabPanel value="2" >
              <PriceCustomerTab onBack={handleBackClick} />
            </TabPanel>
            <TabPanel value="3" >
              < PriceProductInfo onBack={handleBackClick} />
            </TabPanel>
           
          </TabContext>
        </Box>
    
    </>
  );
};

export default PriceDiscountCreate;
