import PropTypes from "prop-types";
import { useMemo, useState, useEffect } from "react";
// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Drawer, useMediaQuery } from "@mui/material";
// project import
import DrawerHeader from "./DrawerHeader";
import DrawerContent from "./DrawerContent";
import MiniDrawerStyled from "./MiniDrawerStyled";
import { drawerWidth } from "../../../config";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //
interface MainDrawerProps {
  open: boolean;
  handleDrawerToggle: () => void;
  window?: Window;
}
const MainDrawer: React.FC<MainDrawerProps> = ({
  open,
  handleDrawerToggle,
  window,
}) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

  // responsive drawer container
  const container =
    typeof window !== "undefined" ? window.document.body : undefined;

  // header content
  const drawerContent = useMemo(() => <DrawerContent />, []);
  const drawerHeader = useMemo(() => <DrawerHeader />, [open]);
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    const firstPart = location.pathname;
    const pathParts = firstPart.split("/");
    setCurrentPath(pathParts[pathParts.length - 1]);
  }, [location.pathname]);

  return (
    <Box sx={{ flexShrink: { md: 0 } }} aria-label="mailbox folders">
      {currentPath !== "product_dashboard" &&
      currentPath !== "product_state_code" &&
      currentPath !== "company_dashboard" &&
      currentPath !== "Configuration_dashboard" &&
      currentPath !== "dashboard_supplier" &&
      !matchDownMD ? (
        <MiniDrawerStyled variant="permanent" open={open} theme={theme}>
          {drawerHeader}
          {drawerContent}
        </MiniDrawerStyled>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#eee",
              borderRight: `1px solid ${theme.palette.divider}`,
              boxShadow: "inherit",
            },
          }}
        >
          {open && drawerHeader}
          {drawerContent}
        </Drawer>
      )}
    </Box>
  );
};

MainDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  // window: PropTypes.object,
};

export default MainDrawer;
