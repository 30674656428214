import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import createAxiosInstance from "../../../hooks/useFetch";
import qs from "qs";

interface SalesReturnTableData {
  returnId: string;
  outletName: string;
  invoiceNo: string;
  salesmanName: string;
  date: string;
  noOfLineItem: number;
  returnValue: number;
  status: string;
}

interface SalesReturnInvoiceTableData {
  routeName: string;
  orderNumber: string;
  invoiceNo: string;
  salesmanName: string;
  invoiceDate: string;
  noOfLineItem: number;
  totalNetAmount: string;
}

interface SalesReturnProduct {
  productCode: string;
  productName: string;
  batchCode: string;
  expireDate: string;
  qty: number;
  mrp: number;
  returnStatus: string;
  reason: string;
  pc: number;
  value: number;
  img: string[]; // Array of image URLs
  uom: string;
}

interface SalesReturnProductData {
  result: SalesReturnProduct[];
  totalCount: number;
}

interface SalesReturnData {
  result: SalesReturnTableData[];
  totalCount: number;
}

interface SalesReturnInvoiceData {
  result: SalesReturnInvoiceTableData[];
  totalCount: number;
}

interface ProductDetailsInvoiceData {
  productName: string;
  quantity: number;
  sellRate: number;
  netAmount: number;
  uom: string;
  productCode: string;
  batchCode: string;
  mrp: string;
}

interface ReturnDetailsInvoiceData {
  products: ProductDetailsInvoiceData[];
  noOfLineItem: string;
  orderNumber: string;
  invoiceNo: string;
  routeCode: string;
  salesmanCode: string;
}

interface ProceedAcceptedData {
  saleable: number;
  nonSaleable: number;
}
interface AllProductsWithoutInvoiceRequest {
  segmentFilter: string[];
  brandFilter: string[];
  varientFilter: string[];
  subVarientFilter: string[];
  packagingTypeFilter: string[];
  sizeElements: string[];
  page: number;
  size: number;
  type: string;
  search: string;
}

interface SalesReturnDataOutlet {
  outletCode: string;
  routeCode: string;
  outletName: string;
  address: string;
  routeName: string;
}

interface SalesReturnState {
  segmentFilter: any;
  brandFilter: any;
  variantFilter: any;
  subVariantFilter: any;
  packagingTypeFilter: any;
  sizeFilter: any;

  segmentMore: any;
  brandMore: any;
  variantMore: any;
  subVariantMore: any;
  packagingTypeMore: any;
  sizeMore: any;

  salesReturnData: SalesReturnData;
  salesReturnDataLoading: boolean;
  salesReturnDataError: string | null;

  salesReturnWithInvoiceData: SalesReturnInvoiceData;
  salesReturnWithInvoiceDataLoading: boolean;
  salesReturnWithInvoiceDataError: string | null;

  returnWithInvoiceData: ReturnDetailsInvoiceData;
  returnWithInvoiceDataLoading: boolean;
  returnWithInvoiceDataError: string | null;

  salesReturnProductList: SalesReturnProductData;
  salesReturnProductListLoading: boolean;
  salesReturnProductListError: string | null;

  salesRerurnHistoryDetails: salesHistoryDetailsResponse[];
  salesRerurnHistoryDetailsLoading: boolean;
  salesRerurnHistoryDetailsError: string | null;

  AcceptedSalesDetails: AcceptedSalesDetailsData | null;
  AcceptedSalesDetailsLoading: boolean;
  AcceptedSalesDetailsError: string | null;

  ProductDetailsAccepted: ProductAcceptedTableData;
  ProductDetailsAcceptedLoading: boolean;
  ProductDetailsAcceptedError: string | null;

  ProductDetailsAcceptedConfirm: ProductAcceptedTableDataConfirm;
  ProductDetailsAcceptedConfirmLoading: boolean;
  ProductDetailsAcceptedConfirmError: string | null;

  ProceedInvoiceDetails: ProceedAcceptedData | null;
  ProceedInvoiceDetailsLoading: boolean;
  ProceedInvoiceDetailsError: string | null;

  ProductListWithOutInvoice: ProductReturnResponse[];

  salesreturnAccepted: Acceptedresponse | null;

  selectedData: any;

  editFetchData: any;

  reasonList: any;

  editedData: any;
  addedData: any;
  popUpData: any;

  salesReturnRejectApprovedData: RejectApprovedListResponse;
  salesReturnRejectApprovedDataLoading: boolean;
  salesReturnRejectApprovedDataError: string | null;

  allProductsWithoutInvoiceData: ProductWithoutInvoiceResponseData[];
  allProductsWithoutInvoiceDataLoading: boolean;
  allProductsWithoutInvoiceDataError: string | null;

  loading: boolean;
  error: string | null;

  salesmanFilter: any;
  salesmanMore: any;
  divisionList: DivisionData[];
  loadingMore: boolean;

  salesReturnOutlet: SalesReturnDataOutlet[];
  salesReturnOutletLoading: boolean;
  salesReturnOutletError: string | null;
}

interface ProductReturnResponse {
  productHierarchy: string;
  productReturnNumber: string;
  productCode: null;
  batchCode: string;
  uom: string;
  productName: string;
  qtyReturned: string;
  mrp: string;
  hsnCode: null;
  sellRateBeforeTax: number;
  purchaseRate: number;
  schemeDiscount: null;
  schemeId: null;
  tax: number;
  netAmount: number;
  grossAmount: number;
  remark: null;
  expiryDate: string;
  returnReason: string;
  returnStatus: null;
  reasonType: string;
  image: [];
  responseImg: [];
}

interface SalesReturnDataRequest {
  page: number;
  size: number;
  date: string;
  fromDate: string;
  toDate: string;
  status: string;
  search: string;
  reference: string;
  salesman: string;
  outlets: string;
}

interface SalesReturnInvoiceRequest {
  page: number;
  size: number;
  dateType: string;
  fromDate: string;
  toDate: string;
  search: string;
  salesman: string[];
  outletCode: string | null;
}

interface ProductsInvoiceRequest {
  invoiceNumber: string;
  search: string;
}

interface FilterDataRequestUpdated {
  page: number;
  size: number;
  pageStatus: boolean;
  status: string;
}

interface salesHistoryDetailsRequest {
  returnId: string;
}
interface RejectApprovedListRequest {
  returnId?: string;
  productList: {
    productReturnNumber: string;
    batchCode?: string;
    approvedValue: number;
    approvedQty: number;
    rejectedList: {
      rejectedQty: number;
      rejectedValue: number;
      rejectionReason: string;
    }[];
  }[];
}
interface salesHistoryDetailsResponse {
  productCode: string;
  productName: string;
  batchCode: string;
  expiryDate: string;
  quantity: number;
  uom: string;
  mrp: number;
  ptr: number;
  value: number;
  reason: string;
  image: ImageResponse;
  approvedQty: number;
  approvedValue: number;
  returnReason: string;
  status: string;
  resason: string;
}
interface ImageResponse {
  files: string;
  fileUri: string[];
  folderName: string;
}
interface Acceptedresponse {
  approvedLinedItem: number;
  approvedValue: number;
}
//Accept Invoice
interface AcceptedSalesDetailsData {
  totalInvoiceValue: number;
  totalInvoiceQty: string;
  totalAcceptedQtyValue: number;
  totalAcceptedQty: string;
  invoiceNo: string;
  orderId: string;
  invoiceDate: number;
  noItems: string;
}
//Reject Approved response
interface RejectApprovedListResponse {
  returnId?: string;
  productList: {
    productReturnNumber: string;
    batchCode: string;
    approvedValue: number;
    approvedQty: number;
    rejectedList: {
      rejectedQty: number;
      rejectedValue: number;
      rejectionReason: string;
    }[];
  }[];
}
//product Accepted
interface ProductDetailsAcceptedData {
  productCode: string;
  productName: string;
  batchCode: string;
  expiryDate: string;
  uom: string;
  mrp: number;
  value: number;
  reason: string;
  status: string;
  qty: string;
  ptr: string;
  img: string[];
}

interface ProductDetailsAcceptedDataConfirm {
  productCode: string;
  productName: string;
  batchCode: string;
  expiryDate: string;
  quantity: string;
  uom: string;
  mrp: number;
  ptr: string;
  grossAmount: number;
  reason: string;
  image: string[];
  approvedQty: number;
  approvedValue: number;
  returnReason: string;
  status: string;
}
interface ProductDetailsFilterDataRequest {
  returnId: string;
}

interface ProductDetailsFilterDataRequestConfirm {
  returnId: string;
}
interface ProductAcceptedTableData {
  ProductDetailsAccpetedList: ProductDetailsAcceptedData[];
}
interface ProductAcceptedTableDataConfirm {
  ProductDetailsAccpetedListConfirm: ProductDetailsAcceptedDataConfirm[];
}

interface editSalesReturnProduct {
  returnId: string;
  productCode?: string;
  productReturnNo: string;
}
interface DivisionData {
  division: string;
  description: string;
}
interface ProductWithoutInvoiceResponseData {
  productName: string;
  uom: string;
  productHierarchy: string;
}

interface SalesWithInvoiceReturnRequest {
  returnType: string;
  invoiceNumber: string;
  orderNumber: string;
  outletCode: string;
  routeCode: string;
  returnDetails: {
    qtyReturned: string;
    batchCode?: string;
    returnReason: string;
    expiryDate: string;
    reasonType: string;
    originalQty: string;
  }[];
}
interface FilterDataRequest {
  page: number;
  size: number;
  pageStatus: boolean;
}

const axiosInstance = createAxiosInstance();
export const getSegmentFilter = createAsyncThunk(
  "filter/segmentFilter",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get(
        "sales-return/get-segmentfilter",
        {
          params: req,
        }
      );
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getSegmentFilterMore = createAsyncThunk(
  "filter/segmentFilterMore",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get(
        "sales-return/get-segmentfilter",
        {
          params: req,
        }
      );
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getBrandFilter = createAsyncThunk(
  "filter/brandFilter",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get("sales-return/get-brandfilter", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getBrandFilterMore = createAsyncThunk(
  "filter/brandFilterMore",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get("sales-return/get-brandfilter", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getVarientFilter = createAsyncThunk(
  "filter/varientFilter",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get(
        "sales-return/get-variantfilter",
        {
          params: req,
        }
      );
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getVarientFilterMore = createAsyncThunk(
  "filter/varientFilterMore",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get(
        "sales-return/get-variantfilter",
        {
          params: req,
        }
      );
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getSubVarientFilter = createAsyncThunk(
  "filter/subVarientFilter",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get("sales-return/get-subVariant", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getSubVarientFilterMore = createAsyncThunk(
  "filter/subVarientFilterMore",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get("sales-return/get-subVariant", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getPackageTypeFilter = createAsyncThunk(
  "filter/packagingTypeFilter",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get("sales-return/PackageType", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getPackageTypeFilterMore = createAsyncThunk(
  "filter/packagingTypeFilterMore",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get("sales-return/PackageType", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getSizeFilter = createAsyncThunk(
  "filter/sizeFilter",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get(
        "sales-return/get-all-piece-weight",
        {
          params: req,
        }
      );
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getSizeFilterMore = createAsyncThunk(
  "filter/sizeFilterMore",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get(
        "sales-return/get-all-piece-weight",
        {
          params: req,
        }
      );
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getSalesReturnData = createAsyncThunk(
  "salesReturn/get-salesreturnData",
  async (req: { params: SalesReturnDataRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(
      "/sales-return/get-sales-return-pending",
      {
        params,
        signal,
      }
    );
    return response.data.data;
  }
);

export const getSalesReturnProductList = createAsyncThunk(
  "salesReturn/get-pending-products",
  async (req: { params: salesHistoryDetailsRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(
      "/sales-return/get-pending-products",
      {
        params,
        signal,
      }
    );
    return response.data.data;
  }
);

export const getSalesReturnReasonList = createAsyncThunk(
  "salesReturn/get-pending-reasons",
  async () => {
    const response = await axiosInstance.get(
      "/sales-return/sales-return-reason-list",
      {}
    );
    return response.data.data;
  }
);

export const mobilePlatformReasonList = createAsyncThunk(
  "salesReturn/mobile-platform-reason-list",
  async () => {
    const response = await axiosInstance.get(
      "/sales-return/mobile-platform-reason-list",
      {}
    );
    return response.data.data;
  }
);

export const getSalesReturnHistoryDetails = createAsyncThunk(
  "salesReturn/get-salesreturnData-history-details",
  async (req: { params: salesHistoryDetailsRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(
      "sales-return/get-returnId-details",
      {
        params,
        signal,
      }
    );
    return response.data.data;
  }
);
export const getReturnIdAccepted = createAsyncThunk(
  "invoice-grn/get-acceptedqty-returnd",
  async (returnNo: string) => {
    const response = await axiosInstance.get(
      `sales-return/get-based-on-returnId?returnNo=${returnNo}`
    );
    return response.data.data;
  }
);
export const getRejectApprovedList = createAsyncThunk(
  "sales-return/product-accept-reject",
  async (req: { params: RejectApprovedListRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.post(
      "sales-return/product-accept-reject",
      params,
      {
        signal,
      }
    );
    return response.data.data;
  }
);

export const setSeletedData = createAsyncThunk(
  "sales-return/set-selected-data",
  async (req: any) => {
    return req;
  }
);

//get-sales-return-all-products
export const getAcceptedSalesDetails = createAsyncThunk(
  "invoice-grn/fetch-accepted-invoiceDetails",
  async (req: { params: string; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(
      `invoice-grn/get-accepted-invoice-details?invoiceNo=${params}`,
      {
        signal,
      }
    );
    return response.data.data;
  }
);

//list
export const getPendingAccepted = createAsyncThunk(
  "sales-return/fetch-accepted-productDetails",
  async (req: {
    params: ProductDetailsFilterDataRequest;
    signal: AbortSignal;
  }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(
      "sales-return/get-sales-return-all-products",
      {
        params,
        signal,
      }
    );
    return response.data.data;
  }
);
export const getPendingAcceptedConfirm = createAsyncThunk(
  "sales-return/get-returnId-pending-details",
  async (req: {
    params: ProductDetailsFilterDataRequestConfirm;
    signal: AbortSignal;
  }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(
      "sales-return/get-returnId-pending-details",
      {
        params,
        signal,
      }
    );
    return response.data.data;
  }
);

export const getEditSalesReturnData = createAsyncThunk(
  "salesReturn/get-edit-salesreturnData",
  async (req: { params: editSalesReturnProduct; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get("/sales-return/get-edit-product", {
      params,
      signal,
    });
    return response.data.data;
  }
);
export const getProceedAcceptedSalesReturn = createAsyncThunk(
  "sales-return/confirm-sales-return",
  async (req: { params: string; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(
      `sales-return/confirm-sales-return?returnId=${params}`,
      {
        signal,
      }
    );
    return response.data.data;
  }
);
export const setAddedSalesReturnData = createAsyncThunk(
  "sales-return/set-added-sales-return",
  async (req: any) => {
    return req;
  }
);

//with invoice
export const getSalesReturnWithInvoice = createAsyncThunk(
  "salesReturn/with-invoice",
  async (req: { params: SalesReturnInvoiceRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get("/sales-return/with-invoice", {
      params,
      signal,
    });
    return response.data.data;
  }
);

//sidebar with invoice
export const getSalesmanWithInvoice = createAsyncThunk(
  "sales-return/salesman-with-invoice",
  async (req: FilterDataRequestUpdated) => {
    const response = await axiosInstance.get(
      "/sales-return/salesman-with-invoice",
      {
        params: req,
      }
    );
    return response;
  }
);

export const getSalesmanWithInvoiceMore = createAsyncThunk(
  "sales-return/salesman-with-invoice-more",
  async (req: FilterDataRequestUpdated) => {
    const response = await axiosInstance.get(
      "/sales-return/salesman-with-invoice",
      {
        params: req,
      }
    );
    return response;
  }
);

export const getProductsDetailsnWithInvoice = createAsyncThunk(
  "salesReturn/fetch-products-with-invoice",
  async (req: { params: ProductsInvoiceRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(
      "/sales-return/fetch-products-with-invoice",
      {
        params,
        signal,
      }
    );
    return response.data.data;
  }
);
export const salesReturnWithPopupData = createAsyncThunk(
  "sales-return/sales-return-popup-data",
  async (req: any) => {
    return req;
  }
);
//get division
export const getDivisionWithoutInvoice = createAsyncThunk(
  "sales-return/get-all-division",
  async () => {
    const response = await axiosInstance.get(
      "sales-return/get-all-division",
      {}
    );
    return response.data.data;
  }
);
// all products without invoice
export const getAllProductsWithouInvoice = createAsyncThunk(
  "sales-return/get-manual-sales-return-product-list",
  async (req: { params: any; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(
      "sales-return/get-manual-sales-return-product-list?",
      {
        params,
        signal,
      }
    );
    return response.data.data;
  }
);

export const saveWithInvoiceReturn = createAsyncThunk(
  "sales-return/save-sales-return",
  async (req: { formData: FormData; signal: AbortSignal }) => {
    const { formData, signal } = req;
    const response = await axiosInstance.post(
      "sales-return/save-sales-return",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        signal,
      }
    );
    return response.data.data;
  }
);

//save sales return
export const productWithOutInvoiceReturn = createAsyncThunk(
  "sales-return/return-product-list",
  async (
    req: { formData: FormData; signal: AbortSignal },
    { rejectWithValue }
  ) => {
    try {
      const { formData, signal } = req;
      const response = await axiosInstance.post(
        "sales-return/return-product-list",

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          signal,
        }
      );
      return response.data.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({
        message: error.message || "Something went wrong",
      });
    }
  }
);

export const confirmWithOutInvoiceReturn = createAsyncThunk(
  "sales-return/confirm-without-invoice",
  async (req: { params: any; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.post(
      "sales-return/confirm-without-invoice",
      params,
      {
        signal,
      }
    );
    return response.data.data;
  }
);

export const getSalesReturnOutlet = createAsyncThunk(
  "sales-return/get-all-outlet",
  async (req: { params: any; search: string | null }) => {
    const { params, search } = req;

    const queryParams = {
      ...params,
      ...(search ? { search } : {}),
    };

    const response = await axiosInstance.get(`sales-return/get-all-outlet`, {
      params: queryParams,
    });

    return response.data.data;
  }
);

const initialState: SalesReturnState = {
  segmentFilter: [],
  brandFilter: [],
  variantFilter: [],
  subVariantFilter: [],
  packagingTypeFilter: [],
  sizeFilter: [],

  segmentMore: [],
  brandMore: [],
  variantMore: [],
  subVariantMore: [],
  packagingTypeMore: [],
  sizeMore: [],

  salesReturnData: { result: [], totalCount: 0 },
  salesReturnDataLoading: false,
  salesReturnDataError: null,

  salesReturnWithInvoiceData: { result: [], totalCount: 0 },
  salesReturnWithInvoiceDataLoading: false,
  salesReturnWithInvoiceDataError: null,

  ProductListWithOutInvoice: [],

  returnWithInvoiceData: {
    products: [],
    noOfLineItem: "",
    orderNumber: "",
    invoiceNo: "",
    routeCode: "",
    salesmanCode: "",
  },
  returnWithInvoiceDataLoading: false,
  returnWithInvoiceDataError: null,

  salesReturnProductList: { result: [], totalCount: 0 },
  salesReturnProductListLoading: false,
  salesReturnProductListError: null,

  salesRerurnHistoryDetails: [],
  salesRerurnHistoryDetailsLoading: false,
  salesRerurnHistoryDetailsError: null,
  salesreturnAccepted: null,

  AcceptedSalesDetails: null,
  AcceptedSalesDetailsLoading: false,
  AcceptedSalesDetailsError: null,

  ProductDetailsAccepted: { ProductDetailsAccpetedList: [] },
  ProductDetailsAcceptedLoading: false,
  ProductDetailsAcceptedError: null,

  ProductDetailsAcceptedConfirm: { ProductDetailsAccpetedListConfirm: [] },
  ProductDetailsAcceptedConfirmLoading: false,
  ProductDetailsAcceptedConfirmError: null,

  salesReturnRejectApprovedData: {
    returnId: "",
    productList: [],
  },
  salesReturnRejectApprovedDataLoading: false,
  salesReturnRejectApprovedDataError: null,

  ProceedInvoiceDetails: null,
  ProceedInvoiceDetailsLoading: false,
  ProceedInvoiceDetailsError: null,

  allProductsWithoutInvoiceData: [],
  allProductsWithoutInvoiceDataLoading: false,
  allProductsWithoutInvoiceDataError: null,

  selectedData: [],

  reasonList: [],

  editFetchData: [],

  editedData: [],

  addedData: null,
  popUpData: null,

  loading: false,
  error: null,

  salesmanFilter: [],
  salesmanMore: [],
  divisionList: [],
  loadingMore: false,

  salesReturnOutlet: [],
  salesReturnOutletLoading: false,
  salesReturnOutletError: null,
};

const salesReturnSlice = createSlice({
  name: "salesReturnData",
  initialState,
  reducers: {
    salesReturnDataFilterFetch: (
      state,
      action: PayloadAction<Partial<SalesReturnState>>
    ) => {
      state.salesReturnData =
        action.payload.salesReturnData || state.salesReturnData;
    },
  },
  extraReducers: (builder) => {
    //without invoice filter
    builder.addCase(getSegmentFilter.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSegmentFilter.fulfilled, (state, action) => {
      state.segmentFilter = action.payload.data.data ?? [];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getSegmentFilter.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getBrandFilter.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getBrandFilter.fulfilled, (state, action) => {
      state.brandFilter = action.payload.data.data ?? [];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getBrandFilter.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getVarientFilter.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getVarientFilter.fulfilled, (state, action) => {
      state.variantFilter = action.payload.data.data ?? [];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getVarientFilter.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getSubVarientFilter.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSubVarientFilter.fulfilled, (state, action) => {
      state.subVariantFilter = action.payload.data.data ?? [];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getSubVarientFilter.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getPackageTypeFilter.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getPackageTypeFilter.fulfilled, (state, action) => {
      state.packagingTypeFilter = action.payload.data.data ?? [];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getPackageTypeFilter.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getSizeFilter.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSizeFilter.fulfilled, (state, action) => {
      state.sizeFilter = action.payload.data.data ?? [];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getSizeFilter.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });

    //segment More filter
    builder.addCase(getSegmentFilterMore.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.segmentMore = [];
    });

    builder.addCase(getSegmentFilterMore.fulfilled, (state, action) => {
      state.segmentMore = action.payload.data.data ?? [];
      state.loadingMore = false;
      state.error = null;
    });
    builder.addCase(getSegmentFilterMore.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message ?? null;
    });
    //brand More filter
    builder.addCase(getBrandFilterMore.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.brandMore = [];
    });

    builder.addCase(getBrandFilterMore.fulfilled, (state, action) => {
      state.brandMore = action.payload.data.data ?? [];
      state.loadingMore = false;
      state.error = null;
    });
    builder.addCase(getBrandFilterMore.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message ?? null;
    });
    //varient More filter
    builder.addCase(getVarientFilterMore.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.variantMore = [];
    });

    builder.addCase(getVarientFilterMore.fulfilled, (state, action) => {
      state.variantMore = action.payload.data.data ?? [];
      state.loadingMore = false;
      state.error = null;
    });
    builder.addCase(getVarientFilterMore.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message ?? null;
    });
    //SubVarient More filter
    builder.addCase(getSubVarientFilterMore.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.subVariantMore = [];
    });

    builder.addCase(getSubVarientFilterMore.fulfilled, (state, action) => {
      state.subVariantMore = action.payload.data.data ?? [];
      state.loadingMore = false;
      state.error = null;
    });
    builder.addCase(getSubVarientFilterMore.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message ?? null;
    });
    //PackageType More filter
    builder.addCase(getPackageTypeFilterMore.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.packagingTypeMore = [];
    });

    builder.addCase(getPackageTypeFilterMore.fulfilled, (state, action) => {
      state.packagingTypeMore = action.payload.data.data ?? [];
      state.loadingMore = false;
      state.error = null;
    });
    builder.addCase(getPackageTypeFilterMore.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getSizeFilterMore.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.sizeMore = [];
    });

    builder.addCase(getSizeFilterMore.fulfilled, (state, action) => {
      state.sizeMore = action.payload.data.data ?? [];
      state.loadingMore = false;
      state.error = null;
    });
    builder.addCase(getSizeFilterMore.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message ?? null;
    });

    builder.addCase(getSalesReturnData.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.salesReturnData = { result: [], totalCount: 0 };
      state.salesReturnDataLoading = true;
      state.salesReturnDataError = null;
    });

    builder.addCase(
      getSalesReturnData.fulfilled,
      (state, action: PayloadAction<SalesReturnData>) => {
        state.salesReturnData = action.payload;
        state.loading = false;
        state.error = null;
        state.salesReturnDataLoading = false;
        state.salesReturnDataError = null;
      }
    );
    builder.addCase(getSalesReturnData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.salesReturnDataLoading = false;
      state.salesReturnDataError = action.error.message ?? null;
    });
    // sales return product details
    builder.addCase(getSalesReturnProductList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.salesReturnProductList = { result: [], totalCount: 0 };
      state.salesReturnProductListLoading = true;
      state.salesReturnProductListError = null;
    });

    builder.addCase(
      getSalesReturnProductList.fulfilled,
      (state, action: PayloadAction<SalesReturnProductData>) => {
        state.salesReturnProductList = action.payload;
        state.loading = false;
        state.error = null;
        state.salesReturnProductListLoading = false;
        state.salesReturnProductListError = null;
      }
    );
    builder.addCase(getSalesReturnProductList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.salesReturnProductListLoading = false;
      state.salesReturnProductListError = action.error.message ?? null;
    });
    //
    builder.addCase(getSalesReturnHistoryDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.salesRerurnHistoryDetails = [];
      state.salesRerurnHistoryDetailsLoading = true;
      state.salesRerurnHistoryDetailsError = null;
    });

    builder.addCase(
      getSalesReturnHistoryDetails.fulfilled,
      (state, action: PayloadAction<salesHistoryDetailsResponse[]>) => {
        state.salesRerurnHistoryDetails = action.payload;
        state.loading = false;
        state.error = null;
        state.salesRerurnHistoryDetailsLoading = false;
        state.salesRerurnHistoryDetailsError = null;
      }
    );
    builder.addCase(getSalesReturnHistoryDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.salesRerurnHistoryDetailsLoading = false;
      state.salesRerurnHistoryDetailsError = action.error.message ?? null;
    });
    // reject approve response
    builder.addCase(getRejectApprovedList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.salesReturnRejectApprovedData = {
        returnId: "",
        productList: [],
      };
      state.salesReturnRejectApprovedDataLoading = true;
      state.salesReturnRejectApprovedDataError = null;
    });

    builder.addCase(
      getRejectApprovedList.fulfilled,
      (state, action: PayloadAction<RejectApprovedListResponse>) => {
        state.salesReturnRejectApprovedData = action.payload;
        state.editedData = action.payload;
        state.loading = false;
        state.error = null;
        state.salesReturnRejectApprovedDataLoading = false;
        state.salesReturnRejectApprovedDataError = null;
      }
    );
    builder.addCase(getRejectApprovedList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.salesReturnRejectApprovedDataLoading = false;
      state.salesReturnRejectApprovedDataError = action.error.message ?? null;
    });
    builder.addCase(getReturnIdAccepted.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(
      getReturnIdAccepted.fulfilled,
      (state, action: PayloadAction<Acceptedresponse>) => {
        state.salesreturnAccepted = action.payload;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(getReturnIdAccepted.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });

    //get-sales-return-all-products
    builder.addCase(getAcceptedSalesDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.AcceptedSalesDetails = null;
      state.AcceptedSalesDetailsLoading = true;
      state.AcceptedSalesDetailsError = null;
    });
    builder.addCase(
      getAcceptedSalesDetails.fulfilled,
      (state, action: PayloadAction<AcceptedSalesDetailsData>) => {
        state.AcceptedSalesDetails = action.payload;
        state.loading = false;
        state.error = null;
        state.AcceptedSalesDetailsLoading = false;
        state.AcceptedSalesDetailsError = null;
      }
    );
    builder.addCase(getAcceptedSalesDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.AcceptedSalesDetailsLoading = false;
      state.AcceptedSalesDetailsError = action.error.message ?? null;
    });

    //list
    builder.addCase(getPendingAccepted.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.ProductDetailsAccepted = {
        ProductDetailsAccpetedList: [],
      };
      state.ProductDetailsAcceptedLoading = true;
      state.ProductDetailsAcceptedError = null;
    });
    builder.addCase(
      getPendingAccepted.fulfilled,
      (state, action: PayloadAction<ProductAcceptedTableData>) => {
        state.ProductDetailsAccepted = action.payload;
        state.loading = false;
        state.error = null;
        state.ProductDetailsAcceptedLoading = false;
        state.ProductDetailsAcceptedError = null;
      }
    );
    builder.addCase(getPendingAccepted.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.ProductDetailsAcceptedLoading = false;
      state.ProductDetailsAcceptedError = action.error.message ?? null;
    });

    //list of confirm
    builder.addCase(getPendingAcceptedConfirm.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.ProductDetailsAcceptedConfirm = {
        ProductDetailsAccpetedListConfirm: [],
      };
      state.ProductDetailsAcceptedConfirmLoading = true;
      state.ProductDetailsAcceptedConfirmError = null;
    });
    builder.addCase(
      getPendingAcceptedConfirm.fulfilled,
      (state, action: PayloadAction<ProductAcceptedTableDataConfirm>) => {
        state.ProductDetailsAcceptedConfirm = action.payload;
        state.loading = false;
        state.error = null;
        state.ProductDetailsAcceptedConfirmLoading = false;
        state.ProductDetailsAcceptedConfirmError = null;
      }
    );
    builder.addCase(getPendingAcceptedConfirm.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.ProductDetailsAcceptedConfirmLoading = false;
      state.ProductDetailsAcceptedConfirmError = action.error.message ?? null;
    });
    builder.addCase(
      setSeletedData.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = null;
        state.selectedData = action.payload;
      }
    );
    builder.addCase(getSalesReturnReasonList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getSalesReturnReasonList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = null;
        state.reasonList = action.payload;
      }
    );
    builder.addCase(getSalesReturnReasonList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getEditSalesReturnData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getEditSalesReturnData.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = null;
        state.editFetchData = action.payload;
      }
    );
    builder.addCase(getEditSalesReturnData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getProceedAcceptedSalesReturn.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.ProceedInvoiceDetails = null;
      state.ProceedInvoiceDetailsLoading = true;
      state.ProceedInvoiceDetailsError = null;
    });
    builder.addCase(
      getProceedAcceptedSalesReturn.fulfilled,
      (state, action: PayloadAction<ProceedAcceptedData>) => {
        state.ProceedInvoiceDetails = action.payload;
        state.loading = false;
        state.error = null;
        state.ProceedInvoiceDetailsLoading = false;
        state.ProceedInvoiceDetailsError = null;
      }
    );
    builder.addCase(getProceedAcceptedSalesReturn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.ProceedInvoiceDetailsLoading = false;
      state.ProceedInvoiceDetailsError = action.error.message ?? null;
    });
    builder.addCase(
      setAddedSalesReturnData.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = null;
        state.addedData = action.payload;
      }
    );

    builder.addCase(getSalesReturnWithInvoice.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.salesReturnWithInvoiceData = { result: [], totalCount: 0 };
      state.salesReturnWithInvoiceDataLoading = true;
      state.salesReturnWithInvoiceDataError = null;
    });

    builder.addCase(
      getSalesReturnWithInvoice.fulfilled,
      (state, action: PayloadAction<SalesReturnInvoiceData>) => {
        state.salesReturnWithInvoiceData = action.payload;
        state.loading = false;
        state.error = null;
        state.salesReturnWithInvoiceDataLoading = false;
        state.salesReturnWithInvoiceDataError = null;
      }
    );

    builder.addCase(getSalesReturnWithInvoice.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.salesReturnWithInvoiceDataLoading = false;
      state.salesReturnWithInvoiceDataError = action.error.message ?? null;
    });

    builder.addCase(getProductsDetailsnWithInvoice.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.returnWithInvoiceData = {
        products: [],
        noOfLineItem: "",
        orderNumber: "",
        invoiceNo: "",
        routeCode: "",
        salesmanCode: "",
      };
      state.returnWithInvoiceDataLoading = true;
      state.returnWithInvoiceDataError = null;
    });

    builder.addCase(
      getProductsDetailsnWithInvoice.fulfilled,
      (state, action: PayloadAction<ReturnDetailsInvoiceData>) => {
        state.returnWithInvoiceData = action.payload;
        state.loading = false;
        state.error = null;
        state.returnWithInvoiceDataLoading = false;
        state.returnWithInvoiceDataError = null;
      }
    );

    builder.addCase(
      getProductsDetailsnWithInvoice.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
        state.returnWithInvoiceDataLoading = false;
        state.returnWithInvoiceDataError = action.error.message ?? null;
      }
    );

    //Salesman filter
    builder.addCase(getSalesmanWithInvoice.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.salesmanFilter = [];
    });

    builder.addCase(getSalesmanWithInvoice.fulfilled, (state, action) => {
      state.salesmanFilter = action.payload.data.data ?? [];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getSalesmanWithInvoice.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });

    //Salesman More filter
    builder.addCase(getSalesmanWithInvoiceMore.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.salesmanMore = [];
    });

    builder.addCase(getSalesmanWithInvoiceMore.fulfilled, (state, action) => {
      state.salesmanMore = action.payload.data.data ?? [];
      state.loadingMore = false;
      state.error = null;
    });
    builder.addCase(getSalesmanWithInvoiceMore.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message ?? null;
    });

    builder.addCase(mobilePlatformReasonList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      mobilePlatformReasonList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = null;
        state.reasonList = action.payload;
      }
    );
    builder.addCase(mobilePlatformReasonList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });

    //popup data for sales return
    builder.addCase(
      salesReturnWithPopupData.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = null;
        state.popUpData = action.payload;
      }
    );

    builder.addCase(getDivisionWithoutInvoice.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getDivisionWithoutInvoice.fulfilled,
      (state, action: PayloadAction<DivisionData[]>) => {
        state.loading = false;
        state.error = null;
        state.divisionList = action.payload;
      }
    );
    builder.addCase(getDivisionWithoutInvoice.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });

    // all products
    builder.addCase(getAllProductsWithouInvoice.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.allProductsWithoutInvoiceData = [];
      state.allProductsWithoutInvoiceDataLoading = true;
      state.allProductsWithoutInvoiceDataError = null;
    });

    builder.addCase(
      getAllProductsWithouInvoice.fulfilled,
      (state, action: PayloadAction<ProductWithoutInvoiceResponseData[]>) => {
        state.allProductsWithoutInvoiceData = action.payload;
        state.loading = false;
        state.error = null;
        state.allProductsWithoutInvoiceDataLoading = false;
        state.allProductsWithoutInvoiceDataError = null;
      }
    );

    builder.addCase(getAllProductsWithouInvoice.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.allProductsWithoutInvoiceDataLoading = false;
      state.allProductsWithoutInvoiceDataError = action.error.message ?? null;
    });
    builder.addCase(saveWithInvoiceReturn.pending, (state) => {
      state.ProceedInvoiceDetails = null;
      state.error = null;
    });
    builder.addCase(
      saveWithInvoiceReturn.fulfilled,
      (state, action: PayloadAction<ProceedAcceptedData>) => {
        state.ProceedInvoiceDetails = action.payload;
        state.error = null;
      }
    );
    builder.addCase(saveWithInvoiceReturn.rejected, (state, action) => {
      state.error = action.error.message ?? null;
    });
    builder.addCase(productWithOutInvoiceReturn.pending, (state) => {
      state.ProductListWithOutInvoice = [];
      state.error = null;
    });
    builder.addCase(
      productWithOutInvoiceReturn.fulfilled,
      (state, action: PayloadAction<ProductReturnResponse[]>) => {
        state.ProductListWithOutInvoice = action.payload;
        state.error = null;
      }
    );
    builder.addCase(productWithOutInvoiceReturn.rejected, (state, action) => {
      state.error = action.error.message ?? null;
    });
    builder.addCase(confirmWithOutInvoiceReturn.pending, (state) => {
      state.ProceedInvoiceDetails = null;
      state.error = null;
    });
    builder.addCase(
      confirmWithOutInvoiceReturn.fulfilled,
      (state, action: PayloadAction<ProceedAcceptedData>) => {
        state.ProceedInvoiceDetails = action.payload;
        state.error = null;
      }
    );
    builder.addCase(confirmWithOutInvoiceReturn.rejected, (state, action) => {
      state.error = action.error.message ?? null;
    });
    builder.addCase(getSalesReturnOutlet.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.salesReturnOutletLoading = false;
      state.salesReturnOutletError = action.error.message ?? null;
    });
    builder.addCase(getSalesReturnOutlet.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.salesReturnOutletLoading = true;
      state.salesReturnOutletError = null;
    });
    builder.addCase(
      getSalesReturnOutlet.fulfilled,
      (state, action: PayloadAction<SalesReturnDataOutlet[]>) => {
        state.salesReturnOutlet = action.payload;
        state.loading = false;
        state.error = null;
        state.salesReturnOutletLoading = false;
        state.salesReturnOutletError = null;
      }
    );
  },
});

export const { salesReturnDataFilterFetch } = salesReturnSlice.actions;
export default salesReturnSlice.reducer;
