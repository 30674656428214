import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "../Redux/AuthLogin/reducer";
import companyReducer from "../Redux/Masters/Company/reducer";
import SupplierReducer from "./Masters/Supplier/reducer";
import ProductUomReducer from "./Masters/Uom/reducer";
import menu from "../Redux/menu";
import sidebarShowMore from "../Redux/AuthLogin/reducer";
import filterOpen from "../Redux/AuthLogin/reducer";
import sidebarFilterReducer from "../Redux/Masters/Sidebar/reducer";
import HSNcodeReducer from "../Redux/Masters/Products/HSNcode/reducer";
import GstStateTaxReducer from "../Redux/Masters/Products/GstStateTax/reducer";
import ProductMasterReducer from "../Redux/Masters/Products/ProductMaster/reducer";
import ProductTaxReducer from "../Redux/Masters/Products/ProductTaxMaster/reducer";
import ProductHierarchyReducer from "../Redux/Masters/Products/ProductHierarchy/reducer";
import hideSubHeader from "../Redux/AuthLogin/reducer";
import { uomReducer } from "../Redux/Masters/Uom/UseReducer";
import filterReducer from "./XDM/Inventory/filterReducer";
import OtpSlice from "./XDM/Login/OtpSlice";
import SaleableReducer from "./XDM/Inventory/saleableReducer";
import nonSaleableReducer from "./XDM/Inventory/nonSaleableReducer";
import billingReducer from "./XDM/Sales/billingReducer";
import tabReducer from "./XDM/Header/tabReducer";
import orderBillingReducer from "./XDM/Sales/orderBillingReducer";
import InvoiceReducer from "./XDM/Sales/InvoiceReducer";
import outletSideBarReducer from "./XDM/Sales/outletSideBarReducer";
import outletReducer from "./XDM/Sales/outletReducer";
import ManualOrderReducer from "./XDM/Sales/ManualOrderReducer";
import pendingGrnReducer from "./XDM/Purchase/GrnReducer";
import salesReturnReducer from "./XDM/Sales/salesReturnReducer";
import collectionReducer from "./XDM/Sales/collectionReducer";

const reducers = combineReducers({
  menu,
  user: userReducer,
  companyFetch: companyReducer,
  supplierFetch: SupplierReducer,
  productUomFetch: ProductUomReducer,
  showMorePopup: sidebarShowMore,
  sidebarFilterOpen: filterOpen,
  sidebarFilter: sidebarFilterReducer,
  HSNcodeFetchData: HSNcodeReducer,
  GstStateTaxFetchData: GstStateTaxReducer,
  ProductMasterData: ProductMasterReducer,
  ProductTaxFetchData: ProductTaxReducer,
  ProductHierarchyFetchData: ProductHierarchyReducer,
  hideSubHeaderData: hideSubHeader,
  uom: uomReducer,
  filterInv: filterReducer,
  OtpRequest: OtpSlice,
  saleableData: SaleableReducer,
  nonSaleableData: nonSaleableReducer,
  orderBilling: billingReducer,
  activeTab: tabReducer,
  orderBillingData: orderBillingReducer,
  invoiceData: InvoiceReducer,
  outletSideBar: outletSideBarReducer,
  outletData: outletReducer,
  manualOrderdata: ManualOrderReducer,
  pendingGrnData: pendingGrnReducer,
  salesReturnData: salesReturnReducer,
  collectionReducerData: collectionReducer,
});

export default reducers;
