import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";
import OutletSideBar from "./OutletSideBar";
import DrawerHeader from "../../../Layout/MainLayout/Drawer/DrawerHeader";
import { useTheme } from "@mui/material/styles";
import MiniDrawerStyled from "../../../Layout/MainLayout/Drawer/MiniDrawerStyled";
import { Card } from "@material-ui/core";
import MainCard from "../../../components/MainCard";
import { camelCaseToNormal } from "../../../utils/constant";
import SearchBar from "@mkyy/mui-search-bar";
import { Spin, Table, TableColumnsType, Tooltip } from "antd";
import OutletDetails from "./OutletDetails";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import {
  getOutletDashCounts,
  getOutletData,
} from "../../../Redux/XDM/Sales/outletReducer";
import debounce from "lodash.debounce";

const roundValue = (label: string, val: number | string): string => {
  if (
    (typeof val === "number" || !isNaN(Number(val))) &&
    (label === "stockValue" || label === "marginValue")
  ) {
    return Number(val)?.toFixed(2);
  }
  return val?.toString();
};
const roundValue1 = (label: string, val: number | string): string => {
  if (typeof val === "number" && label === "Outstanding Amount") {
    return `₹ ${formatIndianNumber(val)}`;
  }
  return val?.toString();
};
const formatIndianNumber = (num: number): string => {
  const numStr = num.toString();
  const [integerPart, decimalPart] = numStr.split(".");
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);

  if (otherDigits !== "") {
    lastThreeDigits = "," + lastThreeDigits;
  }

  const formattedIntegerPart =
    otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

  return decimalPart
    ? formattedIntegerPart + "." + decimalPart.slice(0, 2)
    : formattedIntegerPart + ".00";
};
interface DataProps {
  label: string;
  value: number | string | any;
}
interface OutletDataType {
  outletName: string;
  outletId: string;
  outletGroup: string;
  contactInfo: string;
  outstandingAmt: number;
  status: boolean;
}
type TableRowType = OutletDataType | { noMoreData: boolean };

const DataBox: React.FC<DataProps> = ({ label, value }) => {
  let labels = "";
  if (label === "outletCount") {
    labels = "Outlets";
  } else if (label === "routeCount") {
    labels = "Routes";
  } else if (label === "outstandingAmount") {
    labels = "Outstanding Amount";
  }
  return (
    <MainCard border={false} boxShadow>
      <Stack
        spacing={1}
        justifyContent="center"
        alignItems="center"
        className={"card-box-counts " + label}
      >
        <Typography variant="h6" className="card-box-title">
          {camelCaseToNormal(labels)}
        </Typography>
        <Typography variant="subtitle1" className="card-box-count">
          {roundValue1(labels, value)}
        </Typography>
      </Stack>
    </MainCard>
  );
};

const OrderBilling = () => {
  const theme = useTheme();
  const [textFieldValue, setTextFieldValue] = React.useState("");
  const [isShow, setIsShow] = React.useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [totalOutlet, SetTotalOutlet] = useState(0);
  const [outlet, setOutlet] = React.useState<OutletDataType[]>([]);
  const [commonLoader, setCommonLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [requestData, setRequestData] = useState("");
  const tableHeightRef = useRef(320);
  let outletData = useAppSelector((state) => state.outletData);
  let outletDataFetch = outletData.outlet;
  const filterTags = useAppSelector((state) => state.outletSideBar);
  const dispatch = useAppDispatch();
  let dashCounts = useAppSelector((state) => state.outletData.dashCounts);
  const filterData = {
    page: 0,
    size: 0,
    status: filterTags.statusSelected,
    division: filterTags.divisionSelected.toString(),
    category: filterTags.outletGroupSelected.toString(),
    search: textFieldValue,
    min: filterTags.minVal,
    max: filterTags.maxVal,
  };
  useEffect(() => {
    dispatch(
      getOutletDashCounts(
        typeof filterTags.statusSelected === "object"
          ? "null"
          : filterTags.statusSelected.toString()
      )
    )
      .then((response: any) => {
        // Update the state with the fetched data
        SetTotalOutlet(response.payload.outletCount);
      })
      .catch((error) => {
        console.error("Failed to fetch order details:", error);
      });
  }, [filterTags.statusSelected]);

  useEffect(() => {
    setHasMore(true);
    setOutlet([]);
    loadMoreData(true);
  }, [
    textFieldValue,
    filterTags.statusSelected,
    filterTags.divisionSelected,
    filterTags.outletGroupSelected,
    filterTags.minVal,
    filterTags.maxVal,
  ]);

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 313;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;
    dispatch(
      getOutletData({
        params: { ...filterData, page: nextPage },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const newOutlets = response.payload.outlets || [];
          setOutlet((prevOutlets) =>
            resetPage ? newOutlets : [...prevOutlets, ...newOutlets]
          );
          setPage(nextPage + 1);
          if (newOutlets.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        } else {
          // setHasMore(false);
          // setCommonLoader(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };

  const handleRowClick = (record: OutletDataType) => {
    setRequestData(record.outletId);
    setIsShow(!isShow);
  };
  const columns: TableColumnsType<TableRowType> = [
    {
      dataIndex: "outletName",
      title: "Outlet Name",
      align: "start",
      // width: "30%",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
        return a.outletName.localeCompare(b.outletName);
      },
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } }; // Skip rendering for special row
        // const displayText =
        //   text.length > 15 ? `${text.substring(0, 15)}...` : text;
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "outletId",
      title: "Outlet ID",
      align: "start",
      // width: "20%",
    },
    {
      dataIndex: "outletGroup",
      // width: "20%",
      title: "Outlet Group",
      align: "start",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
        return a.outletGroup.localeCompare(b.outletGroup);
      },
      render: (text: string | number, record: TableRowType) => {
        if ("noMoreData" in record) {
          return {
            children: (
              <span
                className="fs-12 noMore"
                style={{ display: "block", margin: "10px" }}
              >
                <b>No more data found...</b>
              </span>
            ),
            props: {
              colSpan: 2, // Adjust this number based on the total number of columns to span
            },
          };
        }
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "contactInfo",
      // width: "20%",
      title: "Contact Info",
      align: "center",
    },
    {
      title: "Outstanding Amount",
      dataIndex: "outstandingAmt",
      align: "right",
      render: (text: number, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <div className="ellipseclass">
            ₹{text ? text?.toFixed(2) : "0.00"}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      // sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status: boolean, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } }; // Skip rendering for special row
        const color = status === true ? "green" : "red";
        const statusVal = status === true ? "Active" : "Inactive";
        return <span style={{ color }}>{statusVal}</span>;
      },
    },
  ];
  // const dataToShow: TableRowType[] = hasMore ? outlet : [...outlet, { noMoreData: true }];
  const dataToShow: TableRowType[] =
    outlet.length > 0
      ? hasMore
        ? outlet
        : [...outlet, { noMoreData: true }]
      : [];
  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );
  const handleSearch = () => {
    setTextFieldValue(textFieldValue);
  };

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);
  return (
    <Stack direction="row" spacing={2} justifyContent="space-between">
      <Box flex={1} p={0}>
        <MiniDrawerStyled variant="permanent" open={true} theme={theme}>
          <DrawerHeader />
          <OutletSideBar />
        </MiniDrawerStyled>
      </Box>
      <Box
        flex={8}
        className="margin-left-0px"
        sx={{
          overflowY: "auto",
          height: "100vh",
          marginBottom: "100px",
          zIndex: 0,
        }}
      >
        {" "}
        <Card className="b-radius-0 main-card">
          <Grid
            container
            spacing={3}
            justifyContent="start"
            p={2}
            sx={{
              borderBottom: "1px solid #cab1b117",
            }}
          >
            {outletData.dashCountsLoading
              ? Array.from(new Array(3)).map((_, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                    <Skeleton variant="rounded" width="100%" height={81} />
                  </Grid>
                ))
              : Object.entries(dashCounts).map(([key, value]) => (
                  <Grid item xs={12} sm={6} md={4} lg={4} key={key}>
                    <DataBox label={key} value={value} />
                  </Grid>
                ))}
          </Grid>
          <Grid container spacing={3} justifyContent="start" p={2}>
            <Grid
              container
              mt={4}
              m={2}
              sx={{
                borderBottom: "1px solid #cab1b117",
              }}
            >
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <h3
                  style={{
                    marginTop: "0px",
                    marginLeft: "8px",
                    marginBottom: "16px",
                  }}
                >
                  Outlets ({totalOutlet})
                </h3>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <SearchBar
                  className="search_box_saleable w-100px"
                  placeholder="Search Outlet Name, ID..."
                  value={textFieldValue}
                  onChange={(newValue) => {
                    setTextFieldValue(newValue);
                  }}
                  onCancelResearch={() => setTextFieldValue("")}
                  onSearch={(newValue) => {
                    handleSearch();
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} sm={12} md={12} lg={12} className="pt-0">
              {((Array.isArray(filterTags.statusSelected) &&
                filterTags.statusSelected.length !== 0) ||
                (!Array.isArray(filterTags.statusSelected) &&
                  filterTags.statusSelected) ||
                filterTags.divisionSelected.length !== 0 ||
                filterTags.outletGroupSelected.length !== 0 ||
                filterTags.minVal !== "" ||
                filterTags.maxVal !== "" ||
                textFieldValue !== "") && (
                <p
                  style={{
                    marginTop: "0px",
                    marginBottom: "6px",
                    fontSize: "12px",
                  }}
                >
                  Showing Results based on selected filters{" "}
                  {/* <b>({filteredCount} outlets)</b> */}
                </p>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="pad-top-0px">
              <div className="saleable-table-scroll">
                <Table
                  className={`Header-table saleable-table sale_last_hide scroll_cus_table elipsTable${
                    commonLoader ? " nodataloader" : ""
                  }`}
                  // rowSelection={rowSelection}
                  columns={columns}
                  dataSource={dataToShow}
                  pagination={false}
                  scroll={{ y: tableHeightRef.current }}
                  loading={!page && commonLoader}
                  showSorterTooltip={false}
                  summary={() => {
                    return (
                      <>
                        {page !== 0 && commonLoader && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              index={1}
                              colSpan={5}
                              align="center"
                            >
                              <Spin />
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                      </>
                    );
                  }}
                  onRow={(record: TableRowType) => {
                    if ("noMoreData" in record) return {};
                    return {
                      onClick: () => handleRowClick(record as OutletDataType),
                    };
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Card>
        {isShow ? (
          <OutletDetails
            onClose={() => setIsShow(false)}
            outletCode={requestData}
          />
        ) : (
          <></>
        )}
      </Box>
    </Stack>
  );
};

export default OrderBilling;
