import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import createAxiosInstance from "../../../hooks/useFetch";

interface OrderData {
  orderId: string;
  date: string;
  outlet: string;
  route: string;
  salesman: string;
  grossAmount: number;
  schemeAmount: number;
  netAmount: number;
  noOfLineItems: string;
  lowStockOrder: boolean;
}

interface orderTableData {
  orders: OrderData[];
  totalCount: number;
}

interface orderDetailsData {
  productName: string;
  productHierarchy: string;
  grossAmount: number;
  schemeAmount: number;
  netAmount: number;
  quantity: number;
  freeQuantity: number;
  uom: string;
  availableQuantity: number;
  lowStockOrder: boolean;
}
interface orderStatusDetailsData {
  productName: string;
  uom: string;
  orderQty: number;
  billedValue: number;
  invoiceStatus: string;
  orderValue: number;
  billedQty: number;
}
interface totalOrder {
  totalOrderValue: number;
  count: number;
}
interface orderBillingState {
  orderCounts: orderCountData[];
  orderCountsLoading: boolean;
  orderCountsError: string | null;

  orderBilling: orderTableData;
  orderBillingLoading: boolean;
  orderBillingError: string | null;

   orderDetailsDataStatusData: orderStatusDetailsData | null;
   orderDetailsDataStatusDataLoading: boolean;
   orderDetailsDataStatusDataError: string | null;

  orderDetailsData: orderDetailsData | null;
  orderDetailsDataLoading: boolean;
  orderDetailsDataError: string | null;
  orderStatusData:  orderDetailsDataStatusDatatype | null;
  orderStatusDataLoading: boolean;
  orderStatusDataError: string | null;
  totalCounts: totalOrder[];
  totalCountsLoading: boolean;
  totalCountsError: string | null;
  loading: boolean;
  error: string | null;
}
interface convertBillReq {
  selectedItems: string[];
  isSelectAll: string;
}
interface FilterDataRequest {
  page: number;
  size: number;
  stocks: string;
  salesman: string;
  routes: string;
  outlets: string;
  search: string;
  status:string;
}
interface StatusFilterDataRequest {
  page: number;
  size: number;
  salesMan: string;
  route: string;
  outlet: string;
  division:string;
  search: string;
  status: string;
  outletGroup:string;
  fromDate: string;
  toDate: string;
  date: string;
}
interface OrderBillingFilterDataRequest {
  page: number;
  size: number;
  stocks: string;
  salesman: string;
  routes: string;
  outlets: string;
  date: string;
  startDate: string;
  endDate: string;
  search: string;
}
interface orderCountData {
  values: string[];
  products: string[];
  count: number;
}
interface ProductDetails {
  orderId: string;
  productCode: string;
}
interface updateQuantityData {
  orderId: string;
  productCode: string[];
  quantity: number[];
}
interface  orderDetailsDataStatusDatatype {
  orderId: string;
  invoiceNo: string;
  date: string;
  outletName: string;
  route: string;
  salesMan: string;
  noOfLineItem: number;
  orderValue: string;
  billedValue: string;
  status: string;
  
}
interface dataRequest{
  orderId: string;
  page: number;
  size: number;
}
const axiosInstance = createAxiosInstance();

export const getOrderBilling = createAsyncThunk(
  "orderbilling/order-billing-fetch",
  async (req: { params: FilterDataRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get("sales/get-order-to-billing", {
      params,
      signal,
    });
    return response.data.data;
  }
);
export const getStatusOrderBilling = createAsyncThunk(
  "orderbilling/order-billing-fetch-status",
  async (req: { params: StatusFilterDataRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(
      "sales/get-order-based-on-status",
      {
        params,
        signal,
      }
    );
    return response.data.data;
  }
);

export const getOrderBillingInvoice = createAsyncThunk(
  "orderbilling/order-billing-fetch-invoice",
  async (req: {
    params: OrderBillingFilterDataRequest;
    signal: AbortSignal;
  }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(
      "invoice/get-order-to-billing-invoice",
      {
        params,
        signal,
      }
    );
    return response.data.data;
  }
);

export const getorderDetails = createAsyncThunk(
  "orderbilling/order-details-fetch",
  async (orderId: string) => {
    const response = await axiosInstance.get(
      `sales/get-review-order?orderId=${orderId}`
    );
    return response.data.data;
  }
);

export const getorderDetailsInvoice = createAsyncThunk(
  "orderbilling/order-details-fetch-invoice",
  async (orderId: string) => {
    const response = await axiosInstance.get(
      `invoice/get-review-order-invoice?orderId=${orderId}`
    );
    return response.data.data;
  }
);

export const getOrderCounts = createAsyncThunk(
  "orderBilling/dashboardCounts",
  async () => {
    const response = await axiosInstance.get("sales/getOrderCountdetails");
    return response.data.data;
  }
);
export const getTotalValue = createAsyncThunk(
  "orderBilling/totalValue",
  async () => {
    const response = await axiosInstance.get("sales/get-total-value");
    return response.data.data;
  }
);
export const deleteOrder = createAsyncThunk(
  "orderbilling/order-delete",
  async (orderId: string) => {
    const response = await axiosInstance.put(
      `sales/remove-order-data?id=${orderId}`
    );
    return response.data.data;
  }
);

export const convertBill = createAsyncThunk(
  "orderbilling/convertBill",
  async (formData: convertBillReq) => {
    const response = await axiosInstance.post("invoice/save", formData);
    return response.data;
  }
);

export const deleteProductData = createAsyncThunk(
  "orderbilling/product-delete",
  async (productDetails: ProductDetails) => {
    const { orderId, productCode } = productDetails;
    const response = await axiosInstance.put(
      `sales/remove-product-data?id=${orderId}&productCode=${productCode}`
    );
    console.log(response.data.data);
    return response.data.data;
  }
);

export const updateProductQuantity = createAsyncThunk(
  "orderbilling/update-Product-Quantity",
  async (datas: updateQuantityData) => {
    const response = await axiosInstance.put("sales/update-quantity", datas);
    return response.data;
  }
);
export const getOrderStatusDetails = createAsyncThunk(
  "orderbilling/order-details-fetch-status",
  async (req: dataRequest) => {
    const response = await axiosInstance.get(
      `sales/fetch-review-order-by-order-no`,
      {
        params: req,
      }
    );
    return response.data.data;
  }
);
const initialState: orderBillingState = {
  orderCounts: [],
  orderCountsLoading: false,
  orderCountsError: null,

  totalCounts: [],
  totalCountsLoading: false,
  totalCountsError: null,

  orderBilling: { orders: [], totalCount: 0 },
  orderBillingLoading: false,
  orderBillingError: null,

  orderDetailsData: null,
  orderDetailsDataLoading: false,
  orderDetailsDataError: null,
  orderStatusData: null,
  orderStatusDataLoading: false,
  orderStatusDataError: null,
   orderDetailsDataStatusData: null,
   orderDetailsDataStatusDataLoading: false,
   orderDetailsDataStatusDataError: null,
  loading: false,
  error: null,
};

const orderBillingSlice = createSlice({
  name: "orderBillingData",
  initialState,
  reducers: {
    orderBillingFilterFetch: (
      state,
      action: PayloadAction<Partial<orderBillingState>>
    ) => {
      state.orderCounts = action.payload.orderCounts || state.orderCounts;
      state.orderBilling = action.payload.orderBilling || state.orderBilling;
      state.orderDetailsData =
        action.payload.orderDetailsData || state.orderDetailsData;
      //   state.saleableProductList =
      //     action.payload.saleableProductList || state.saleableProductList;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrderCounts.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.orderCountsLoading = true;
      state.orderCountsError = null;
    });
    builder.addCase(
      getOrderCounts.fulfilled,
      (state, action: PayloadAction<orderCountData[]>) => {
        state.orderCounts = action.payload ?? [];
        state.orderCountsLoading = false;
        state.orderCountsError = null;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(getOrderCounts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.orderCountsLoading = false;
      state.orderCountsError = action.error.message ?? null;
    });
    builder.addCase(getTotalValue.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.totalCountsLoading = true;
      state.totalCountsError = null;
    });
    builder.addCase(
      getTotalValue.fulfilled,
      (state, action: PayloadAction<totalOrder[]>) => {
        state.totalCounts = action.payload ?? [];
        state.totalCountsLoading = false;
        state.totalCountsError = null;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(getTotalValue.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.totalCountsLoading = false;
      state.totalCountsError = action.error.message ?? null;
    });
    // Saleable Products
    builder.addCase(getOrderBilling.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.orderBilling = { orders: [], totalCount: 0 };
      state.orderBillingLoading = true;
      state.orderBillingError = null;
    });
    builder.addCase(
      getOrderBilling.fulfilled,
      (state, action: PayloadAction<orderTableData>) => {
        state.orderBilling = action.payload;
        state.loading = false;
        state.error = null;
        state.orderBillingLoading = false;
        state.orderBillingError = null;
      }
    );
    builder.addCase(getOrderBilling.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.orderDetailsDataLoading = false;
      state.orderDetailsDataError = action.error.message ?? null;
    });
      builder.addCase(getStatusOrderBilling.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.orderStatusData = null;
        state.orderStatusDataLoading = true;
        state.orderStatusDataError = null;
      });
      builder.addCase(
        getStatusOrderBilling.fulfilled,
        (state, action: PayloadAction< orderDetailsDataStatusDatatype>) => {
          state.orderStatusData = action.payload;
          state.loading = false;
          state.error = null;
          state.orderStatusDataLoading = false;
          state.orderStatusDataError = null;
        }
      );
      builder.addCase(getStatusOrderBilling.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
        state.orderStatusDataLoading = false;
        state.orderStatusDataError = action.error.message ?? null;
      });

    builder.addCase(getOrderBillingInvoice.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.orderBilling={orders:[],totalCount:state.orderBilling.totalCount};
      state.orderBillingLoading = true;
      state.orderBillingError = null;
    });
    builder.addCase(
      getOrderBillingInvoice.fulfilled,
      (state, action: PayloadAction<orderTableData>) => {
        state.orderBilling = action.payload;
        state.loading = false;
        state.error = null;
        state.orderBillingLoading = false;
        state.orderBillingError = null;
      }
    );
    builder.addCase(getOrderBillingInvoice.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.orderDetailsDataLoading = false;
      state.orderDetailsDataError = action.error.message ?? null;
    });
    builder.addCase(getorderDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.orderDetailsDataLoading = true;
      state.orderDetailsDataError = null;
    });
    builder.addCase(
      getorderDetails.fulfilled,
      (state, action: PayloadAction<orderDetailsData>) => {
        state.orderDetailsData = action.payload;
        state.loading = false;
        state.error = null;
        state.orderDetailsDataLoading = false;
        state.orderDetailsDataError = null;
      }
    );
    builder.addCase(getorderDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.orderDetailsDataLoading = false;
      state.orderDetailsDataError = action.error.message ?? null;
    });
    builder.addCase(getorderDetailsInvoice.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.orderDetailsDataLoading = true;
      state.orderDetailsDataError = null;
    });
    builder.addCase(
      getorderDetailsInvoice.fulfilled,
      (state, action: PayloadAction<orderDetailsData>) => {
        state.orderDetailsData = action.payload;
        state.loading = false;
        state.error = null;
        state.orderDetailsDataLoading = false;
        state.orderDetailsDataError = null;
      }
    );
    builder.addCase(getorderDetailsInvoice.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.orderBillingLoading = false;
      state.orderBillingError = action.error.message ?? null;
    });
    builder.addCase(deleteOrder.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      deleteOrder.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.orderBilling.orders = state.orderBilling.orders.filter(
          (order) => order.orderId !== action.payload
        );
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(deleteOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(convertBill.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      convertBill.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(convertBill.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
      builder.addCase(getOrderStatusDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
         state.orderDetailsDataStatusData =null;
        state.orderDetailsDataStatusDataLoading = true;
        state.orderDetailsDataStatusDataError = null;
      });
      builder.addCase(
        getOrderStatusDetails.fulfilled,
        (state, action: PayloadAction<orderStatusDetailsData>) => {
          state.orderDetailsDataStatusData = action.payload;
          state.loading = false;
          state.error = null;
          state.orderDetailsDataStatusDataLoading = false;
          state.orderDetailsDataStatusDataError = null;
        }
      );
      builder.addCase(getOrderStatusDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
        state.orderDetailsDataStatusDataLoading = false;
        state.orderDetailsDataStatusDataError = action.error.message ?? null;
      });
  },
});

export const { orderBillingFilterFetch } = orderBillingSlice.actions;
export default orderBillingSlice.reducer;
