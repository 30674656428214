import React from "react";
import {
  Button,
  Grid,
  TextField,
  Box,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  InputLabel,
  Typography,
  Card,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { SelectChangeEvent } from "@mui/material/Select";
import { TableColumnsType } from "antd";
import CommonTable from "../../../../components/Tables/CommonTable";
import DeleteModal from "../../../../components/Modal/DeleteSupplier";
import SaveIcon from "@mui/icons-material/Save";
import BatchInfoEdit from "./BatchInfoEdit";


// import ProductInfoView from "./ProductInfo";

// import DistributorSales from "./DistributorSales";
interface DataType {
  key: React.Key;
  AttributeName: string;
  GeographyHierarchyDescription: string;
  Remarks:string;
}

interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const ProductOtherAttributesEdit: React.FC<DistributorTabProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        //   width: 250,
      },
    },
  };
  const handleChanges = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [isDelete, setIsDelete] = React.useState(false);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };


  
  

  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };

  //table
  const columns: TableColumnsType<DataType> = [
    {
      title: "Attribute Name",
      dataIndex: "AttributeName",
    },
    {
      title: "Attribute Value ",
      dataIndex: "Attribute Value",
      render: (_: any, record: DataType) => (
      <>
      <TextField />
      </>
      ),
    },
    {
      title: "Remarks",
      dataIndex: "Remarks",
    },

  ];

  const data: DataType[] = [
    {
      key: "1",
      AttributeName: "brandCategory",
      GeographyHierarchyDescription: "brandCategory",
      Remarks:'For CK Integration brandCategoryCode'

    },
    {
      key: "2",
      AttributeName: "brandPack",
      GeographyHierarchyDescription: "	VELLORE",
      Remarks:'For CK Integration brandPackCode'
      
    },
    {
        key: "3",
        AttributeName: "division",
        GeographyHierarchyDescription: "	BANGALORE",
      Remarks:'For CK Integration divisionCode'
        
      },



  
  ];
  const handleDeleteClick = (event: React.MouseEvent<unknown>) => {
    setIsDelete(!isDelete);
  };
  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    //search function logic here
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <>
      {show ? (
       
       <BatchInfoEdit onBack={handleclick}/>
      ) : (
        <Box>
          <form noValidate>
         
  
            <Grid item mt={3}>
              <CommonTable
                // rowSelection={rowSelection}
                data={data}
                columns={columns}
              />
            </Grid>
            {isDelete && <DeleteModal onBack={(event) => handleDeleteClick(event)} />}

           
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", mt: 4 }}
            >
              <Grid item sx={{ mr: 2 }}>
                <Button variant="outlined" onClick={handleBackClick} className="button-lable">
               
                 Back
                </Button>
              </Grid>
              <Grid>
                <Button
                  className="btn-colr "
                  variant="contained"
                  onClick={handleclick}
                >
                  <RedoOutlinedIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />
                  Next
                </Button>
              </Grid>
          
          <Grid item ml={2}> 
            <Button className="btn-colr " variant="contained" >
              <SaveIcon
                sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
              />
              Update
            </Button>
          </Grid>
          <Grid item sx={{ ml: 2 }}>
                <Button variant="outlined" onClick={handleBackClick}>
                  <CancelIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />{" "}
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </>
  );
};
export default ProductOtherAttributesEdit;
