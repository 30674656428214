import React, { useState } from "react";
import {
  Box,

  Tab,
} from "@mui/material";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SchemeDefinition from "./SchemeDefinition";
import DistributorSelection from "./DistributorSelection";
import CustomerDifinition from "./Customerdifinition";
import Budgetplanning from "./BudgetPlanning";
import ProductRuleSetting from "./Product&RuleSetting";


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const SchemeCreate: React.FC<DistributorTabProps> = ({onBack}) => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };

  const DistributorOptions = ["select", "", ""];
  const Discount = ["none", "", ""];
  const geoHierarchyValueOptions = ["Value A", "Value B", "Value C"];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };

  return (
    <>
    
        <Box
          sx={{ width: "100%", height: "40px", backgroundColor: "#8080801f" }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Scheme Definition"
                  value="1"
                  className="customer-general"
                />
                <Tab label="Distributor Selection" value="2" />
                <Tab label="Customer Definition" value="3" />
                <Tab label="Budget Planning" value="4" />
                <Tab label="Product and Rule Setting" value="5" />

              </TabList>
            </Box>
            <TabPanel value="1">
               
              <SchemeDefinition  onBack={handleBackClick}/>{" "}
            </TabPanel>
            <TabPanel value="2" >
              <DistributorSelection onBack={handleBackClick}
                 /> 
            </TabPanel>
            <TabPanel value="3">
              <CustomerDifinition onBack={handleBackClick} />{" "}
            </TabPanel>
            <TabPanel value="4">
              <Budgetplanning onBack={handleBackClick} />{" "}
            </TabPanel>
            <TabPanel value="5">
              <ProductRuleSetting onBack={handleBackClick} />{" "}
            </TabPanel>
          </TabContext>
        </Box>
   
    </>
  );
};

export default SchemeCreate;
