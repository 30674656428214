import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { CardContent, Drawer, Grid, Typography } from "@mui/material";
import Close from "../../../assets/images/icons/Close_icon.png";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { ReactComponent as NearToExpirySVG } from "../../../assets/images/icons/time-exp.svg";
import { ReactComponent as NearToExpiryWarnSVG } from "../../../assets/images/icons/time-warning.svg";
import { ReactComponent as SchemeSVG } from "../../../assets/images/icons/scheme.svg";
import type { TableColumnsType } from "antd";
import { Card, Spin, Table, Tooltip } from "antd";
import { decodeJwt } from "../../../utils/constant";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import { getSaleableProductDetails } from "../../../Redux/XDM/Inventory/saleableReducer";
import { getNonSaleableProductsList } from "../../../Redux/XDM/Inventory/nonSaleableReducer";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { ArrowBackIos } from "@mui/icons-material";
import SearchBar from "@mkyy/mui-search-bar";
import { getinvoiceProductsDetails } from "../../../Redux/XDM/Sales/outletReducer";

interface RequestData {
  // key: number;
  outletCode: string;
  orderNumber: string;
  invoiceNumber: string;
  invoiceDate: string;
  invoiceStatus: string;
  invoiceValue: string;
  noOfLineItems: string;
}
interface invoiceTableData {
  productName: string;
  hsnCode: number;
  mrp: number;
  billed: number;
  free: number;
  total: number;
  rate: number;
  schemeDiscount: number;
  grossAmount: number;
  cgst: number;
  sgst: number;
  igst: number;
  amount: number;
}

interface InvoiceDetailsProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
}

export default function InvoiceDetails(props: InvoiceDetailsProps) {
  const { onClose, requestData } = props; // Destructure the props
  const dispatch = useAppDispatch();
  let outletDatas = useAppSelector((state) => state.outletData);
  let invoiceDatas = outletDatas.invoiceDetailsData;
  const [hasMore, setHasMore] = useState(true);
  const [commonLoader, setCommonLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [invoiceDetailss, setInvoiceDetailss] = React.useState<
    invoiceTableData[]
  >([]);

  const filterData = {
    invoiceNumber: requestData.invoiceNumber,
    page: 0,
    size: 15,
  };
  useEffect(() => {
    setInvoiceDetailss([]);
    loadMoreData(1);
  }, [requestData]);
  const loadMoreData = (type: Number) => {
    setHasMore(true);
    setCommonLoader(true);
    let pageCount = page;
    if (type) {
      setPage(0);
      pageCount = 0;
    }
    filterData.page = pageCount;
    dispatch(getinvoiceProductsDetails(filterData)).then((response) => {
      if (!type) {
        setInvoiceDetailss((prevInvoiceDetails: any) => [
          ...prevInvoiceDetails,
          ...response.payload,
        ]);
        // Increment the page number after successful fetch
      } else {
        setInvoiceDetailss(response.payload.invoicedetails);
      }
      console.log(response.payload.invoicedetails);

      if (15 > response.payload.invoicedetails.length) {
        setHasMore(false);
      }
      setPage(pageCount + 1);
      setCommonLoader(false);
    });
  };
  const formatDate = (date: string) => {
    if (date) {
      const dateObj = moment(date, "ddd MMM DD HH:mm:ss [IST] YYYY");
      return dateObj.format("DD MMM YYYY");
    }
    return "-";
  };
  const invoices: invoiceTableData[] = invoiceDetailss ?? [];

  const columns: TableColumnsType<invoiceTableData> = [
    {
      title: "Product Details",
      children: [
        {
          title: "Product Name",
          dataIndex: "productName",
          key: "productName",
          width: "20%",
          render: (text: string) => {
            // const displayText =
            //   text.length > 35 ? `${text.substring(0, 35)}...` : text;
            return (
              <Tooltip placement="top" title={text} zIndex={999999}>
                <span>{text}</span>
              </Tooltip>
            );
          },
        },
        {
          title: "HSN Code",
          dataIndex: "hsnCode",
          key: "hsnCode",
          render: (text: any, record: invoiceTableData) => {
            return text !== undefined && text !== null ? text : "--";
          },
        },
        {
          title: "MRP",
          dataIndex: "mrp",
          key: "mrp",
          render: (text: any, record: invoiceTableData) => {
            return text !== undefined && text !== null
              ? `₹${text.toFixed(2)}`
              : "₹0.00";
          },
        },
      ],
    },

    {
      title: "Quantity",
      children: [
        {
          title: "Billed",
          dataIndex: "billed",
          key: "billed",
          render: (text: any, record: invoiceTableData) => {
            return text ? text + "pcs" : "0pcs";
          },
        },
        {
          title: "Free",
          dataIndex: "free",
          key: "free",
          render: (text: any, record: invoiceTableData) => {
            return text ? text + "pcs" : "0pcs";
          },
        },
        {
          title: "Total",
          dataIndex: "total",
          key: "total",
          render: (text: any, record: invoiceTableData) => {
            return text ? text + "pcs" : "0pcs";
          },
        },
      ],
    },
    {
      title: "Price Details",
      children: [
        {
          title: "Rate",
          dataIndex: "rate",
          key: "rate",
          render: (text: any, record: invoiceTableData) => {
            return `₹${text?.toFixed(2)}`;
          },
        },
        {
          title: "Scheme Discount",
          dataIndex: "schemeDiscount",
          key: "schemeDiscount",
          render: (text: any, record: invoiceTableData) => {
            return text !== undefined && text !== null ? `₹${text}` : "₹0.00";
          },
        },
        {
          title: "Gross Amount",
          dataIndex: "grossAmount",
          key: "grossAmount",
          render: (text: any, record: invoiceTableData) => {
            return `₹${text?.toFixed(2)}`;
          },
        },
      ],
    },
    {
      title: "Tax Details",
      children: [
        {
          title: "CGST %",
          dataIndex: "cgst",
          key: "cgst",
        },
        {
          title: "SGST %",
          dataIndex: "sgst",
          key: "sgst",
        },
        {
          title: "IGST %",
          dataIndex: "igst",
          key: "igst",
        },
      ],
    },

    {
      title: "Amount",
      key: "amount",
      dataIndex: "netAmount",
      render: (text: any, record: invoiceTableData) => {
        return `₹${text?.toFixed(2)}`;
      },
    },
  ];

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_outlet w-100px"
        // variant="persistent"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "100%" }} role="presentation">
            <Grid container className="crt-header" mt={1} p={2}>
              <Typography gutterBottom className="outlet-header-lable">
                <ArrowBackIos
                  sx={{
                    fontSize: "10px",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    onClose();
                  }}
                />
                Invoice Summary
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form saleable-products-box"
          >
            {" "}
            <Grid
              item
              sx={{
                width: "95%",
                margin: "auto",
                marginBottom: "90px",
              }}
            >
              {" "}
              <Grid container mt={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Outlet ID
                  </Typography>
                  <Typography gutterBottom className="inv-lable-input" mt={1}>
                    {requestData.outletCode}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Invoice Number
                  </Typography>
                  <Typography gutterBottom className="inv-lable-input" mt={1}>
                    {requestData.invoiceNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Invoice Date
                  </Typography>
                  <Typography gutterBottom className="inv-lable-input" mt={1}>
                    {requestData && requestData.invoiceDate ? (
                      <div>{formatDate(requestData.invoiceDate)}</div>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Status
                  </Typography>
                  <Typography gutterBottom className="green-lable-input" mt={1}>
                    {requestData.invoiceStatus}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="pad-top-0px inv"
                >
                  {" "}
                  <div
                    className="saleable-table-scroll productData"
                    id="tableScrollFull"
                  >
                    <InfiniteScroll
                      dataLength={invoiceDetailss.length}
                      next={() => loadMoreData(0)}
                      hasMore={hasMore}
                      scrollableTarget="tableScrollFull"
                      className=""
                      loader={
                        page ? (
                          <h6
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Spin />
                          </h6>
                        ) : (
                          false
                        )
                      }
                      endMessage={
                        invoiceDatas.invoicedetails.length !== 0 && (
                          <p className="fs-12 noMore">
                            <b>No more data found...</b>
                          </p>
                        )
                      }
                    >
                      <Table
                        className="Header-table saleable-table scroll_cus_table elipsTable"
                        columns={columns}
                        dataSource={Array.isArray(invoices) ? invoices : []}
                        pagination={false}
                        loading={!page ? commonLoader : false}
                        scroll={{ y: 320 }}
                      />
                    </InfiniteScroll>
                  </div>
                </Grid>
              </Grid>
              <Box mt={2}>
                <Grid container className="br_invoice_rev">
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Typography className="crt-fnt-lable-light">
                      Gross Amount:{""}
                      <span className="inv-lable-input">
                        ₹ {invoiceDatas?.grossAmount?.toFixed(2)}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Typography className="crt-fnt-lable-light">
                      Scheme Applied:{""}
                      <span className="inv-lable-input">
                        ₹ {invoiceDatas?.schemeApplied?.toFixed(2)}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Typography className="crt-fnt-lable-light">
                      GST & taxes:{""}
                      <span className="inv-lable-input">
                        ₹ {invoiceDatas?.gstAndTaxes?.toFixed(2)}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Typography className="crt-fnt-lable-light">
                      Net Amount:{""}
                      <span className="inv-lable-input">
                        ₹ {invoiceDatas?.netAmount?.toFixed(2)}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
