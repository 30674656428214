import { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Drawer,
  FormControl,
  Grid,
  InputBase,
  Skeleton,
  Typography,
} from "@mui/material";
import moment from "moment";
import Close from "../../../../../assets/images/icons/Close_icon.png";
import remove_line from "../../../../../assets/images/icons/remove_line.png";
import { Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useFetch";
import { IoClose } from "react-icons/io5";
import {
  getRejectApprovedList,
  getSalesReturnReasonList,
} from "../../../../../Redux/XDM/Sales/salesReturnReducer";

interface RequestData {
  returnId: string;
  outletName: string;
  invoiceNo: string;
  salesmanName: string;
  date: string;
  noOfLineItem: number;
  returnValue: number;
  status: string;
}

interface RejectedProps {
  onClose: () => void;
  requestData: RequestData;
}
interface RejectedListItem {
  productCode: string;
  productName: string;
  matDesc: string;
  uomCode: string;
  slno: string;
  mrp: string;
  productReturnNumber: string;
  qty: number;
  value: number;
}
interface CardState {
  selectedSalesmanIds: string[];
  showSelectReason: boolean;
  rejectedQty: number;
  rejectedValue: number;
  approvedQty: number;
  approvedValue: number;
  reasonQuantities: Record<string, number>;
  rejectedQtyError?: string; // For error message on discrepancy quantity
  selectReasonError?: string; // For error message on select reason
  rejectedQtyCompareError?: string; // For error message on discrepancy quantity compare
  rejectedMatchQtyError?: string; // For error message when reason quantities do not match discrepancy quantity
}

const { Option } = Select;

export default function RejectReturnSales(props: RejectedProps) {
  const { onClose, requestData } = props; // Destructure the props
  const dispatch = useAppDispatch();
  const grnData = useAppSelector((state) => state.pendingGrnData);
  const addDiscrepancyList = useAppSelector(
    (state) => state.pendingGrnData?.addDiscrepancyList
  );

  let selectedDatas = useAppSelector(
    (state) => state.salesReturnData.selectedData.productList
  );

  let rejectedReasonList = useAppSelector(
    (state) => state.salesReturnData.reasonList
  );

  const [cardStates, setCardStates] = useState<Record<number, CardState>>({});
  const [rejectedSalesReturnListData, setRejectedSalesReturnListData] =
    useState<RejectedListItem[]>([]);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [serialNo, setSerialNo] = useState("");
  const [rejectedValueTotal, setRejectedValueTotal] = useState(0);
  const [rejectedTotal, setRejectedTotal] = useState(0);
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    dispatch(getSalesReturnReasonList());
    if (selectedDatas) {
      setRejectedSalesReturnListData(selectedDatas);
    }
  }, [dispatch, selectedDatas]);

  const handleDeleteClick = (index: number) => {
    console.log("index", index);
    setDeleteIndex(index);
    setSerialNo(selectedDatas[index]?.slno);
  };

  const handleDeleteCancel = () => {
    setDeleteIndex(null);
  };

  const handleDeleteConfirm = () => {
    if (deleteIndex !== null) {
      setRejectedSalesReturnListData((prevList) => {
        const updatedList = prevList.filter(
          (_, index) => index !== deleteIndex
        );
        console.log("updatedList", updatedList);

        if (updatedList.length === 0) {
          onClose();
        }

        return updatedList;
      });

      setDeleteIndex(null);
      setSerialNo("");
    }
  };
  const handleSalesmanSelect = (index: number, value: string[]) => {
    setCardStates((prevState) => {
      const existingCardState = prevState[index] || {
        selectedSalesmanIds: [],
        reasonQuantities: {},
        rejectedQty: 0,
        showSelectReason: false,
      };

      const reasonQuantities = existingCardState.reasonQuantities || {};

      const newReasonQuantities = value.reduce((acc, id) => {
        acc[id] = reasonQuantities[id] || 0;
        return acc;
      }, {} as Record<string, number>);

      let selectReasonError = "";
      if (value.length === 0) {
        selectReasonError = "Please select a reason";
      }

      return {
        ...prevState,
        [index]: {
          ...existingCardState,
          selectedSalesmanIds: value,
          reasonQuantities: newReasonQuantities,
          selectReasonError,
        },
      };
    });
  };

  const handleRejectedQtyChange = (
    index: number,
    value: string,
    invQty: number,
    netAmt: number
  ) => {
    const qty = parseInt(value, 10);
    let rejectedQtyError = "";
    let rejectedQtyCompareError = "";

    if (!qty || qty <= 0) {
      rejectedQtyError = "Please enter Rejected Quantity";
    } else if (qty > invQty) {
      rejectedQtyCompareError = `Rejected quantity cannot be greater than invoice quantity.`;
    }
    const roundedNetAmt = Math.floor(netAmt * 100) / 100;
    const rejectval = (roundedNetAmt / invQty) * qty;
    const Approvedval = netAmt - rejectval;

    setCardStates((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        showSelectReason: true,
        rejectedQty: qty,
        rejectedQtyError,
        rejectedValue: rejectval,
        approvedValue: Approvedval,
        approvedQty: invQty - qty,
        rejectedQtyCompareError,
      },
    }));
  };
  const handleReasonQtyChange = (
    index: number,
    reasonId: string,
    value: string
  ) => {
    const qty = parseInt(value, 10) || 0;

    setCardStates((prevState) => {
      const existingCardState = prevState[index] || {
        selectedSalesmanIds: [],
        reasonQuantities: {},
        rejectedQty: 0,
        showSelectReason: false,
      };

      const updatedReasonQuantities = {
        ...existingCardState.reasonQuantities,
        [reasonId]: qty,
      };

      // Calculate the sum of reason quantities
      const totalReasonQty = Object.values(updatedReasonQuantities).reduce(
        (acc, quantity) => acc + quantity,
        0
      );
      // Determine if there's an error
      let rejectedMatchQtyError = "";
      if (totalReasonQty !== existingCardState.rejectedQty) {
        rejectedMatchQtyError =
          "Please enter the quantities to match the rejected quantity.";
      }

      return {
        ...prevState,
        [index]: {
          ...existingCardState,
          reasonQuantities: updatedReasonQuantities,
          rejectedMatchQtyError,
        },
      };
    });
  };

  const calculateRejectedValue = (
    netAmt: number,
    invQty: number,
    rejectedQty: number
  ) => {
    const roundedNetAmt = Math.floor(netAmt * 100) / 100;
    return (roundedNetAmt / invQty) * rejectedQty;
  };

  const approvedValue = (rejectedQty: number, totalQty: number) => {
    return totalQty - rejectedQty;
  };

  const calculateApprovedValue = (
    value: number,
    qty: number,
    appQty: number
  ) => {
    const roundedNetAmt = Math.floor(value * 100) / 100;
    return (roundedNetAmt / qty) * appQty;
  };

  useEffect(() => {
    const totalvalue = Object.keys(cardStates).reduce((sum, key) => {
      const index = parseInt(key, 10);
      const value = parseFloat(cardStates[index]?.rejectedValue.toFixed(2)); // Convert the string back to a number
      return sum + value;
    }, 0);
    const total = Object.keys(cardStates).reduce((sum, key) => {
      const index = parseInt(key, 10);
      const value = parseFloat(cardStates[index]?.rejectedQty.toFixed(2)); // Convert the string back to a number
      return sum + value;
    }, 0);
    setRejectedValueTotal(totalvalue); // No need for parseFloat here
    setRejectedTotal(total); // No need for parseFloat here
  }, [cardStates, rejectedSalesReturnListData]);

  const handleSaveQuantity = async () => {
    let hasError = false;
    const newCardStates = { ...cardStates };
    rejectedSalesReturnListData?.forEach((item, index) => {
      let cardState = newCardStates[index];

      // Initialize cardState if it doesn't exist
      if (!cardState) {
        cardState = {
          selectedSalesmanIds: [],
          showSelectReason: false,
          rejectedQty: 0,
          rejectedValue: 0,
          approvedQty: 0,
          approvedValue: 0,
          reasonQuantities: {},
        };
        newCardStates[index] = cardState;
      }

      let rejectedQtyError = "";
      let selectReasonError = "";
      let rejectedMatchQtyError = "";

      if (!cardState.rejectedQty || cardState.rejectedQty <= 0) {
        rejectedQtyError = "Please enter Rejected Quantity";
        hasError = true;
      }

      // Validate selected reasons
      if (
        !cardState.selectedSalesmanIds ||
        cardState.selectedSalesmanIds.length === 0
      ) {
        selectReasonError = "Please select a reason";
        hasError = true;
      }

      // Check if rejectedQtyCompareError or rejectedMatchQtyError is present
      if (
        cardState.rejectedQtyCompareError ===
          "Rejected quantity cannot be greater than invoice quantity." ||
        cardState.rejectedMatchQtyError ===
          "Please enter the quantities to match the rejected quantity."
      ) {
        hasError = true;
      }

      // Ensure reasonQuantities is initialized as an empty object if it doesn't exist
      if (cardStates[index]?.selectedSalesmanIds?.length > 1) {
        const reasonQuantities = cardState.reasonQuantities || {};

        const reasonQuantitiesSum = Object.values(reasonQuantities).reduce(
          (sum, qty) => sum + qty,
          0
        );
        console.log(
          Object.values(reasonQuantities).some((quantity) => quantity === 0)
        );
        if (reasonQuantitiesSum !== cardState.rejectedQty) {
          rejectedMatchQtyError =
            "Please enter the quantities to match the rejected quantity.";
          hasError = true;
        }
        if (
          Object.values(reasonQuantities).some((quantity) => quantity === 0)
        ) {
          rejectedMatchQtyError = "Please enter the quantities.";
          hasError = true;
        }
      }

      // Update the state with errors if any
      newCardStates[index] = {
        ...cardState,
        rejectedQtyError,
        selectReasonError,
        rejectedMatchQtyError,
      };
    });
    setCardStates(newCardStates); // Update the state with error messages
    if (hasError) {
      return; // Exit if there are errors
    }
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const rejectedItems = rejectedSalesReturnListData.map((item, index) => {
      const reasonQuantities = cardStates[index]?.reasonQuantities || {};
      const rejectedQty = Object.values(reasonQuantities);

      // Calculate rejected values based on reason quantities
      const reasonbasedValue = rejectedQty.map((qty) =>
        calculateRejectedValue(item.value, item.qty, qty)
      );

      // Calculate single rejected value based on the main rejected quantity
      const singleReasonDisValue = calculateRejectedValue(
        item.value,
        item.qty,
        cardStates[index]?.rejectedQty || 0
      );

      // Check if a single reason is selected or multiple reasons
      const isSingleReason =
        cardStates[index]?.selectedSalesmanIds?.length === 1;

      return {
        productCode: item.productCode,
        slNo: item.slno,
        productReturnNumber: item.productReturnNumber,
        approvedValue: cardStates[index]?.approvedValue,
        approvedQty: cardStates[index]?.approvedQty || 0,
        discrepancyReason: cardStates[index]?.selectedSalesmanIds || [],
        rejectedQty: isSingleReason
          ? [cardStates[index]?.rejectedQty || 0]
          : rejectedQty,
        discrepancyValue: isSingleReason
          ? [singleReasonDisValue]
          : reasonbasedValue,
        invoicedQty: item.qty,
        invoicedValue: item.value,
      };
    });
    const saveRequestData = {
      returnId: requestData.returnId || "",
      productList: rejectedItems.map((record) => ({
        productReturnNumber: record.productReturnNumber,
        approvedValue: record.approvedValue || 0,
        approvedQty: record.approvedQty || 0,
        rejectedList: record.rejectedQty.map((qty, idx) => ({
          rejectedQty: qty,
          rejectedValue: (record.invoicedValue / record.invoicedQty) * qty,
          rejectionReason: record.discrepancyReason[idx] || "",
        })),
      })),
    };

    try {
      const response = await dispatch(
        getRejectApprovedList({
          params: saveRequestData,
          signal: abortController.signal,
        })
      );
      if (response?.meta?.requestStatus === "fulfilled") {
        onClose();
      }
    } catch (error) {
      console.error("Save error", error);
    }
  };

  const slideImages = [
    "https://picsum.photos/id/235/200/300",
    "https://picsum.photos/id/236/200/300",
    "https://picsum.photos/id/238/200/300",
    "https://picsum.photos/id/239/200/300",
    "https://picsum.photos/id/240/200/300",
  ];

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="pen_drawer_stl"
        // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 500,
              md: 460,
              sm: 400,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
            backgroundColor: "#F6F6F6",
          }}
        >
          <Grid item sx={{ height: "100%" }}>
            <Grid
              container
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                background: "white",
                height: "60px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
              }}
            >
              <Grid item sm={7}>
                <Typography>
                  <b>
                    {rejectedSalesReturnListData?.length > 1
                      ? `Reject Items (${rejectedSalesReturnListData?.length})`
                      : "Reject Item"}
                  </b>
                </Typography>
              </Grid>
              <Grid item sm={5} sx={{ display: "flex", justifyContent: "end" }}>
                <img
                  onClick={onClose}
                  src={Close}
                  alt="Close"
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{ overflowY: "auto", height: "70%", padding: "20px" }}
              className="discrepancy_card"
            >
              {rejectedSalesReturnListData?.length !== 0 ? (
                <>
                  {rejectedSalesReturnListData?.map(
                    (item: any, index: number) => (
                      <Card
                        key={index}
                        sx={{
                          border: "1px solid #DCDCDE",
                          padding: "15px",
                          borderRadius: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <Grid container sx={{ alignItems: "center" }}>
                          <Grid item sm={10}>
                            <Grid item>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: "13px",
                                  fontFamily: "Montserrat",
                                  fontWeight: 600,
                                }}
                              >
                                {item.productName} ({item.productCode})
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            sm={2}
                            sx={{ display: "flex", justifyContent: "end" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteClick(index);
                            }}
                          >
                            <img
                              src={remove_line}
                              alt="remove_line"
                              style={{ width: "20px", height: "20px" }}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          mt={2}
                          sx={{
                            border: "1px solid #DCDCDE",
                            padding: "8px",
                            borderRadius: "6px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              gap: "10px",
                              flexWrap: "wrap",
                            }}
                          >
                            <Grid item>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: "10px",
                                  fontFamily: "Montserrat",
                                  fontWeight: 600,
                                }}
                              >
                                Batch Code :{" "}
                                {item?.batchCode ? item?.batchCode : "-"}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: "10px",
                                  fontFamily: "Montserrat",
                                  fontWeight: 600,
                                }}
                              >
                                Expired Date:{" "}
                                {item.expiryDate
                                  ? moment(item.expiryDate).format(
                                      "DD MMM YYYY"
                                    )
                                  : "-"}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: "10px",
                                  fontFamily: "Montserrat",
                                  fontWeight: 600,
                                }}
                              >
                                UOM : {item.uom}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: "10px",
                                  fontFamily: "Montserrat",
                                  fontWeight: 600,
                                }}
                              >
                                Quantity : {item.qty ? item.qty : "-"}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: "10px",
                                  fontFamily: "Montserrat",
                                  fontWeight: 600,
                                }}
                              >
                                MRP / PC : ₹ {item.mrp ? item.mrp : "0.00"}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: "10px",
                                  fontFamily: "Montserrat",
                                  fontWeight: 600,
                                }}
                              >
                                PTR / PC :₹{" "}
                                {item.pc ? Number(item.pc).toFixed(2) : "0.00"}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: "10px",
                                  fontFamily: "Montserrat",
                                  fontWeight: 600,
                                }}
                              >
                                Value :₹{" "}
                                {item.value ? item.value.toFixed(2) : "-"}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: "10px",
                                  fontFamily: "Montserrat",
                                  fontWeight: 600,
                                }}
                              >
                                Reason for Return : {item.reason}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <div className="image_Container">
                              {item?.image?.map(
                                (thumbnail: any, index: number) => (
                                  <div className="Image_thumbnail" key={index}>
                                    <img
                                      src={thumbnail}
                                      alt={`Thumbnail ${index + 1}`}
                                      className="image_thumbnail_img"
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item mt={2}>
                          <Typography
                            sx={{
                              color: "#44464C",
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            Enter Rejected Quantity
                            <span style={{ color: "red" }}>&nbsp;*</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} mt={1}>
                          <InputBase
                            sx={{
                              border: "1px solid #DCDCDE",
                              width: "100%",
                              height: "38px",
                              paddingLeft: "10px",
                              color: "#505259",
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: 500,
                              borderRadius: "4px",
                            }}
                            inputProps={{
                              maxLength: 10,
                            }}
                            onInput={(e) => {
                              const target = e.target as HTMLInputElement;
                              target.value = target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*\.?\d*$/.test(value)) {
                                handleRejectedQtyChange(
                                  index,
                                  value,
                                  item.qty,
                                  item.value
                                );
                              }
                            }}
                          />
                          {cardStates[index]?.rejectedQtyError && (
                            <Typography color="error" variant="caption">
                              {cardStates[index].rejectedQtyError}
                            </Typography>
                          )}
                          {cardStates[index]?.rejectedQtyCompareError && (
                            <Typography color="error" variant="caption">
                              {cardStates[index].rejectedQtyCompareError}
                            </Typography>
                          )}
                        </Grid>
                        {cardStates[index]?.showSelectReason && (
                          <Grid item>
                            <Grid item mt={2}>
                              <Typography
                                sx={{
                                  color: "#44464C",
                                  fontFamily: "Montserrat",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                }}
                              >
                                Select Reason
                                <span style={{ color: "red" }}>&nbsp;*</span>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <FormControl
                                fullWidth
                                sx={{ marginTop: "9px", marginBottom: "2px" }}
                              >
                                <Select
                                  className="Quentity_select_box"
                                  mode="multiple"
                                  showSearch
                                  value={
                                    cardStates[index]?.selectedSalesmanIds || []
                                  }
                                  placeholder="Add Reason"
                                  filterOption={(input, option) => {
                                    const optionText =
                                      option?.children
                                        ?.toString()
                                        .toLowerCase() || "";
                                    return optionText.includes(
                                      input.toLowerCase()
                                    );
                                  }}
                                  dropdownStyle={{ zIndex: 2000 }}
                                  onChange={(value) =>
                                    handleSalesmanSelect(index, value)
                                  }
                                  style={{ width: "100%", fontSize: "12px" }} // Adjust the width as necessary
                                >
                                  {rejectedReasonList?.map((item: any) => (
                                    <Option key={item.id} value={item.id}>
                                      <div style={{ fontSize: "11px" }}>
                                        {item.returnReason}
                                      </div>
                                    </Option>
                                  ))}
                                </Select>
                              </FormControl>
                              {cardStates[index]?.selectReasonError && (
                                <Typography color="error" variant="caption">
                                  {cardStates[index].selectReasonError}
                                </Typography>
                              )}
                            </Grid>

                            {cardStates[index]?.selectedSalesmanIds?.length >
                              1 &&
                              cardStates[index].selectedSalesmanIds?.map(
                                (id, i) => {
                                  const selectedSalesman =
                                    rejectedReasonList?.find(
                                      (item: any) => item.id === id
                                    );

                                  return (
                                    <Grid container mt={1} key={i}>
                                      <Grid item>
                                        <Typography
                                          sx={{
                                            color: "#44464C",
                                            fontFamily: "Montserrat",
                                            fontSize: "12px",
                                            fontWeight: 500,
                                          }}
                                        >
                                          Enter {selectedSalesman?.returnReason}{" "}
                                          Quantity
                                          <span style={{ color: "red" }}>
                                            &nbsp;*
                                          </span>
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} mt={1}>
                                        <InputBase
                                          value={
                                            cardStates[index]?.reasonQuantities[
                                              id
                                            ] || ""
                                          }
                                          sx={{
                                            border: "1px solid #DCDCDE",
                                            width: "100%",
                                            height: "38px",
                                            paddingLeft: "10px",
                                            color: "#505259",
                                            fontSize: "10px",
                                            fontFamily: "Montserrat",
                                            fontWeight: 500,
                                            borderRadius: "4px",
                                          }}
                                          inputProps={{
                                            maxLength: 10,
                                          }}
                                          onInput={(e) => {
                                            const target =
                                              e.target as HTMLInputElement;
                                            target.value = target.value.replace(
                                              /[^0-9.]/g,
                                              ""
                                            );
                                          }}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*\.?\d*$/.test(value)) {
                                              handleReasonQtyChange(
                                                index,
                                                id,
                                                value
                                              );
                                            }
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  );
                                }
                              )}
                            {cardStates[index]?.rejectedMatchQtyError && (
                              <Typography color="error" variant="caption">
                                {cardStates[index].rejectedMatchQtyError}
                              </Typography>
                            )}
                          </Grid>
                        )}

                        <Grid container mt={1}>
                          <Grid item sm={6}>
                            <Typography
                              sx={{
                                color: "#000000",
                                fontSize: "11px",
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                              }}
                            >
                              Approved Quantity :{" "}
                              {cardStates[index]?.approvedQty}
                            </Typography>
                          </Grid>
                          <Grid item sm={6}>
                            <Typography
                              sx={{
                                color: "#000000",
                                fontSize: "11px",
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                              }}
                            >
                              Approved Value : ₹{" "}
                              {cardStates[index]?.approvedValue.toFixed(2) ||
                                "0.00"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container mt={1}>
                          <Grid item sm={6}>
                            <Typography
                              sx={{
                                color: "#000000",
                                fontSize: "11px",
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                              }}
                            >
                              Rejected Quantity :{" "}
                              {cardStates[index]?.rejectedQty || 0}
                            </Typography>
                          </Grid>
                          <Grid item sm={6}>
                            <Typography
                              sx={{
                                color: "#000000",
                                fontSize: "11px",
                                fontFamily: "Montserrat",
                                fontWeight: 600,
                              }}
                            >
                              Rejected Value : ₹{" "}
                              {cardStates[index]?.rejectedValue.toFixed(2) || 0}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    )
                  )}
                </>
              ) : (
                <Grid item sm={12} height={300}>
                  <Typography
                    sx={{
                      color: "#44464C",
                      fontSize: "14px",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      textAlign: "center",
                      height: "60vh",
                    }}
                  >
                    {grnData.addDiscrepancyListLoading ? (
                      <Skeleton
                        variant="rectangular"
                        height={250}
                        sx={{
                          borderRadius: 2,
                          width: {
                            lg: 450,
                            md: 430,
                            sm: 350,
                            xs: 350,
                          },
                        }}
                      />
                    ) : (
                      <b>No rejected found.</b>
                    )}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              sx={{
                position: "sticky",
                bottom: 85,
                height: "60px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
                background: "white",
              }}
            >
              <Grid item sm={6}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "12px",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                  }}
                >
                  Rejected Value : ₹{" "}
                  {rejectedValueTotal ? rejectedValueTotal.toFixed(2) : "0.00"}
                  <br />
                  Rejected Qty : {rejectedTotal ? rejectedTotal : 0}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Button className="Cancle_btn_des" onClick={onClose}>
                  Cancel
                </Button>
                <Button className="Save_btn_des" onClick={handleSaveQuantity}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
      <Dialog
        open={deleteIndex !== null}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: "333px", padding: "12px 16px" }}>
          <Box
            className="close_icon_btn cursor_pointer"
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
            onClick={handleDeleteCancel}
          >
            <IoClose className="ClosePop" />
          </Box>
          <DialogContentText
            className="dialog-title-remove"
            id="alert-dialog-description"
            style={{ paddingBottom: "10px" }}
          >
            Are you sure you want to remove?
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "black", fontSize: "12px", marginTop: "4px" }}
          >
            Note:The following{" "}
            <span className="dialog-title-remove" style={{ fontSize: "12px" }}>
              Product Slno:{serialNo}{" "}
            </span>
            will be removed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="crt-btn-cancel"
            style={{
              height: "30px",
              width: "25%",
              marginRight: "3px",
              fontSize: "12px",
            }}
            onClick={handleDeleteCancel}
          >
            {" "}
            Cancel
          </Button>
          <Button
            variant="contained"
            className="crt-btn-colr"
            style={{ height: "30px", width: "25%", fontSize: "12px" }}
            type="submit"
            sx={{ mr: 2 }}
            onClick={handleDeleteConfirm}
          >
            {" "}
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
