import React, { useRef } from "react";

import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CommonTable from "../../../components/Tables/CommonTable";
import { TableColumnsType } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../../components/Modal/DeleteSupplier";
import CreateGSTProduct from "./GSTPTSMappingCreate";

// import PriceDiscountCreate from "./PriceDiscountCreate";

interface DataType {
  key: React.Key;
  State: string;
  Company: string;
  ProductLevelValueName: string;

  CompanyProductcode: string;
  ProductName: string;
  IGSTCode: string;
  CGSTCode: string;
  action: string;
}
const GSTProductTaxStructureTax: React.FC = () => {
  const navigator = useNavigate();
  const [showForm, setShowForm] = React.useState(false);
  const [showTable, setShowTable] = React.useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [isDelete, setIsDelete] = React.useState(false);

  const handleCreateNew = () => {
    setShowForm(!showForm);
  };

  const Options = ["Specific SKU", "Reference Number"];
  const EntityType = [" Super Stockiest", "Sub Stockiest", "Distributor"];
  const Entity = [" SRI'S", "P S ENTERPRISES", "SRI SAI HANUMAN AGENCIES"];
  const RetailerChannel = [
    " COLD CHAIN/VD-TAMIL NADU",
    "FMCG/FMCG-GENERAL TRADE",
    "FMCG/FMCG-RAAGA",
  ];

  const RetailerGroup = [
    " RA-GENTS SALOON'S",
    "RA-LADIES SALOON",
    "RA-PARLOUR",
  ];
  const RetailerClass = [" A", "B", "C", "D", "HVO"];

  const handleShowTable = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setShowTable(!showTable);
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: "State",
      dataIndex: "State",
    },
    {
      title: "Company",
      dataIndex: "Company",
      //   sorter: (a, b) => a.CreatedDate.length - b.CreatedDate.length,
    },
    {
      title: "Product Level Value Name",
      dataIndex: "ProductLevelValueName",
      //   sorter: (a, b) => a.ValidFrom.length - b.ValidFrom.length,
    },
    {
      title: "Company Product code",
      dataIndex: "CompanyProductcode",
      // sorter: (a, b) => a.ValidFrom.length - b.ValidFrom.length,
    },

    {
      title: "Product Name",
      dataIndex: "ProductName",
      // sorter: (a, b) => a.ValidFrom.length - b.ValidFrom.length,
    },
    {
      title: "IGST Code",
      dataIndex: "IGSTCode",
      // sorter: (a, b) => a.ValidFrom.length - b.ValidFrom.length,
    },

    {
      title: "CGST Code",
      dataIndex: "CGSTCode",
      // sorter: (a, b) => a.ValidFrom.length - b.ValidFrom.length,
    },
    {
      dataIndex: "action",
      title: "Action",

      render: (_: any, record: DataType) => (
        <>
          <DeleteIcon
            style={{
              cursor: "pointer",
              marginLeft: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
            onClick={(event) => handleDeleteClick(event)}
          />
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      State: "Jammu And Kashmir",
      Company: "CAVINKARE PRIVATE LIMITED",
      ProductLevelValueName: "CAVINKARE PRIVATE LIMITED	",
      CompanyProductcode: "AAFF0030WRC01S",
      ProductName: "ARIAS WRINKLE REPAIR EYE CREAM 30g",
      IGSTCode: "OF",
      CGSTCode: "OE",
      action: "",
    },
    {
      key: "2",
      State: "Jammu And Kashmir",
      Company: "CAVINKARE PRIVATE LIMITED",
      ProductLevelValueName: "CAVINKARE PRIVATE LIMITED",
      CompanyProductcode: "AAFF0030WRC01S",
      ProductName: "ARIAS WRINKLE REPAIR EYE CREAM 30g",
      IGSTCode: "OF",
      CGSTCode: "OE",
      action: "",
    },
    {
      key: "3",
      State: "Himachal Pradesh",
      Company: "CAVINKARE PRIVATE LIMITED",
      ProductLevelValueName: "CAVINKARE PRIVATE LIMITED",
      CompanyProductcode: "	AAFF0030WRC01S",
      ProductName: "ARIAS WRINKLE REPAIR EYE CREAM 30g",
      IGSTCode: "OF",
      CGSTCode: "OE",
      action: "",
    },
    {
      key: "4",
      State: "Jammu And Kashmir",
      Company: "CAVINKARE PRIVATE LIMITED",
      ProductLevelValueName: "CAVINKARE PRIVATE LIMITED",
      CompanyProductcode: "AAFF0030WRC01S",
      ProductName: "ARIAS WRINKLE REPAIR EYE CREAM 30g",
      IGSTCode: "OF",
      CGSTCode: "OE",
      action: "",
    },
    {
      key: "5",
      State: "Himachal Pradesh",
      Company: "CAVINKARE PRIVATE LIMITED",
      ProductLevelValueName: "CAVINKARE PRIVATE LIMITED",
      CompanyProductcode: "AAFF0030WRC01S",
      ProductName: "ARIAS WRINKLE REPAIR EYE CREAM 30g",
      IGSTCode: "OF",
      CGSTCode: "OE",
      action: "",
    },
    {
      key: "6",
      State: "Punjab",
      Company: "CAVINKARE PRIVATE LIMITED",
      ProductLevelValueName: "CAVINKARE PRIVATE LIMITED",
      CompanyProductcode: "AAFF0030WRC01S",
      ProductName: "ARIAS WRINKLE REPAIR EYE CREAM 30g",
      IGSTCode: "OF",
      CGSTCode: "OE",
      action: "",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleDeleteClick = (event: React.MouseEvent<unknown>) => {
    setIsDelete(!isDelete);
  };

  return (
    <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
      <Grid item xs={12} className="pad-10 mar-tb">
        <Typography>
          <b> GST Product Tax Structure Mapping</b>
        </Typography>
      </Grid>
      {showForm ? (
        <CreateGSTProduct onBack={handleCreateNew} />
      ) : (
        <Box>
          <Button
            variant="contained"
            className="btn-colr"
            onClick={handleCreateNew}
          >
            {" "}
            <AddIcon /> Create new
          </Button>

          <Grid
            container
            mt={2}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 10 }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <Typography className="fnt-lable">Search By</Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  className="selct-box"
                >
                  {Options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            mt={1}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 10 }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <Typography className="fnt-lable"> LOB Value </Typography>
              <TextField placeholder="  " className="Txt-box" />
            </Grid>
            <Grid item mt={3}>
              <Button
                sx={{ color: "#fff", ml: 3 }}
                className="btn-colr"
                onClick={handleShowTable}
              >
                <SearchIcon className="fn-icon" /> Go
              </Button>
            </Grid>
          </Grid>

          {showTable && (
            <Box className="mar-tb">
              <CommonTable
                // rowSelection={rowSelection}
                data={data}
                columns={columns}
              />
              {isDelete && (
                <DeleteModal onBack={(event) => handleDeleteClick(event)} />
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
export default GSTProductTaxStructureTax;
