import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import UndoIcon from "@mui/icons-material/Undo";

import Close from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

interface OpenpopupProps {
  handleClose: () => void; // A function that takes no arguments and returns void
}

const Openpopup = ({ handleClose }: OpenpopupProps) => {
  const options = ["Supplier Code", "Supplier Name"];

  const option = [
    "PYC1-Rohit India Private limited-KARAIKAL",
    "BRD1-CavinKare Pvt. Ltd, PAT DEPOT-PATNA",
    "MHT1-CavinKare Pvt. Ltd., MUM COTY-BHIWANDI",
  ];
  const rows = [
    {
      id: "GJD1",
      Name: "CavinKare Pvt. Ltd, AHM DEPOT",
      GeoMapping: "AHMEDABAD-1",
      IsDefault: "",
      action: "",
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Code",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "Name",
      headerName: "Name",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "GeoMapping",
      headerName: "Geo Mapping",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },

    {
      field: "IsDefault",
      headerName: "Is Default",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: () => (
        <>
          <FormGroup>
            <FormControlLabel
              label=""
              control={<Checkbox defaultChecked className="check-box" />}
            />
          </FormGroup>
        </>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: () => (
        <>
          <DeleteIcon
            style={{
              cursor: "pointer",
              marginRight: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />
        </>
      ),
    },
  ];

  const [isVisible, setIsVisible] = useState(false);
  const Visibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <Box>
      <form>
        <Grid
          item
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item>Supplier Mapping</Grid>
          <IconButton aria-label="close" onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
        <Divider />

        <Grid container mt={4}>
          <Grid item mr={2} mt={1} xs={4}>
            <Grid item>Search By</Grid>
            <FormControl className="formcontrol-select">
              <Select labelId="demo-simple-select-label">
                {options.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item mt={1} xs={4}>
            <Grid item>Supplier Search</Grid>
            <FormControl className="formcontrol-select">
              <Select labelId="demo-simple-select-label">
                {option.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item mt={3}>
            <FormGroup>
              <FormControlLabel
                label="Is Default"
                control={<Checkbox defaultChecked className="check-box" />}
              />
            </FormGroup>
          </Grid>
          <Grid item mt={3}>
            <Button
              sx={{ ml: 1, color: "#fff" }}
              className="btn-colr"
              onClick={Visibility}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        {isVisible && (
          <Grid item mt={2}>
            <Grid container columnGap={2}>
              <Grid item style={{ height: "100%", width: "100%" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  className="Header-table"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item mt={3} sx={{ display: "flex", justifyContent: "center" }}>
          <Button className="btn-colr " variant="contained" sx={{ mr: "10px" }}>
            Ok
          </Button>
          <Button
            className="next-button"
            variant="outlined"
            onClick={handleClose}
          >
            <UndoIcon
              sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
            />
            Cancle
          </Button>
        </Grid>
      </form>
    </Box>
  );
};
export default Openpopup;
