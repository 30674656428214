import { useState, startTransition } from "react";
import {
  CardHeader,
  Card,
  CardContent,
  Avatar,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FaAdjust } from "react-icons/fa";
import { IoManOutline } from "react-icons/io5";
import { LiaToolsSolid } from "react-icons/lia";
import { MdGroups } from "react-icons/md";
import { TbSettingsFilled } from "react-icons/tb";
import { TiArrowUnsorted } from "react-icons/ti";
import { PiSquaresFourFill } from "react-icons/pi";
import { FaArrowsSpin } from "react-icons/fa6";
interface CardData {
  [key: string]: string | JSX.Element | undefined;
}

const Customer: React.FC = () => {
  const cardData: CardData[] = [
    { Customer: "Credit Note Upload", Icons: <FaAdjust /> },
    { Customer: "Customer Migration", Icons: <FaArrowsSpin /> },
    { Customer: "Must Visit Outlite", Icons: <PiSquaresFourFill /> },
    { Customer: "Retailer Channel", Icons: <LiaToolsSolid /> },
    { Customer: "Retailer Class", Icons: <TiArrowUnsorted /> },
    { Customer: "Retailer Group", Icons: <MdGroups /> },
    { Customer: "Customer", Icons: <IoManOutline /> },
    { Customer: "Retailer Target Upload", Icons: <TbSettingsFilled /> },
  ];

  const navigator = useNavigate();
  const handleCardClick = (index: number) => {
    startTransition(() => {
      const paths = [
        "/Credit",
        "/CustomerMigration",
        "/Mustvisitoutlit",
        "/RetailerChannel",
        "/RetailerClass",
        "/RetailerGroup",
        "/CustomerTable",
        "/RetailerTargetUpload",
      ]; // Paths for each index
      navigator(paths[index]);
    });
  };

  return (
    <Grid item sx={{ p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
      <Grid container>
        <Grid item xs={12} className="mar-tb">
          <Typography style={{ fontSize: "14px", fontWeight: "800" }}>
            Customer
          </Typography>
        </Grid>
        <Grid>
          <Typography className="cursor_pointer" style={{ color: "#b6b0b0" }}>
            <span onClick={() => navigator("/Dashboard")}>Dashboard</span>/
            <span>Customer</span>
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
          gap: 2,
          marginTop: 2,
        }}
      >
        <Card>
          <CardHeader
            avatar={
              <Avatar
                aria-label="recipe"
                style={{
                  background:
                    " linear-gradient(90deg, rgb(97, 79, 179) 0%, rgb(148, 73, 134) 100%)",
                }}
              >
                M
              </Avatar>
            }
            title="Modules"
            subheader="Customer"
          />
          <CardContent>
            <Grid container spacing={2}>
              {cardData.map((item, index) => (
                <Grid item xs={6} key={index}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={1}
                      style={{
                        fontSize: "1rem",
                        color: "#614fb3",
                      }}
                    >
                      {item.Icons}
                    </Grid>
                    <Grid item xs={11} onClick={() => handleCardClick(index)}>
                      <Typography className="hover-card">
                        {item.Customer}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};
export default Customer;
