import React, { useState } from "react";
import {
  Box,

  Tab,
} from "@mui/material";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ProdcutInfoEdit from "./ProductInfoEdit";
import GeographyInfoEdit from "./GeographyInfoEdit";
import ProductGSTMappingEdit from "./ProductGSTMappingEdit";
import ProductOtherAttributesEdit from "./ProductOtherAttributesEdit";
import BatchInfoEdit from "./BatchInfoEdit";

// import DistributorRetailer from "./distributor&Retailer";
// import ProductInfoView from "./ProductInfo";


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const ProductEdit: React.FC<DistributorTabProps> = ({onBack}) => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };

  const DistributorOptions = ["select", "", ""];
  const Discount = ["none", "", ""];
  const geoHierarchyValueOptions = ["Value A", "Value B", "Value C"];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };

  return (
    <>
        <Box
          sx={{ width: "100%", height: "40px", backgroundColor: "#8080801f" }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Product Info"
                  value="1"
                //   className="customer-general"
                />
                <Tab label="Geography Info" value="2" />
                <Tab label="GST Mapping" value="3" />

                <Tab label="Other Attributes" value="4" />
                <Tab label="Batch Info" value="5" />


              </TabList>
            </Box>
            <TabPanel value="1">
              <ProdcutInfoEdit  onBack={handleBackClick}/>{" "}
            </TabPanel>
            <TabPanel value="2" >
              <GeographyInfoEdit onBack={handleBackClick} />
            </TabPanel>
            <TabPanel value="3" >
              <ProductGSTMappingEdit onBack={handleBackClick} />
            </TabPanel>
            <TabPanel value="4" >
              <ProductOtherAttributesEdit onBack={handleBackClick} />
            </TabPanel>
            <TabPanel value="5" >
              <BatchInfoEdit onBack={handleBackClick} />
            </TabPanel>
            
          </TabContext>
        </Box>
    
    </>
  );
};

export default ProductEdit;
