import React from "react";
import {
  Button,
  Grid,
  TextField,
  Box,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  InputLabel,
  Typography,
  Card,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { SelectChangeEvent } from "@mui/material/Select";
import { TableColumnsType } from "antd";
import CommonTable from "../../../../components/Tables/CommonTable";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../../../components/Modal/DeleteSupplier";
import ProductGSTMapping from "./ProductGSTMapping";

// import ProductInfoView from "./ProductInfo";

// import DistributorSales from "./DistributorSales";
interface DataType {
  key: React.Key;
  DistributorCode: string;
  DistributorTypeFrom: string;
  DistributorTypeTo:string;
  PurchaseRate:string;
  SellingRate:string;
  MRP:string;
}

interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const BatchInfoView: React.FC<DistributorTabProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        //   width: 250,
      },
    },
  };
  const handleChanges = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [isDelete, setIsDelete] = React.useState(false);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const Options = ["	India", "VELLORE", "	BANGALORE"];

  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };

  //table
  const columns: TableColumnsType<DataType> = [
    {
      title: "Distributor Code",
      dataIndex: "DistributorCode",
    },
    {
      title: "Distributor Type From ",
      dataIndex: "DistributorTypeFrom",
      //   sorter: (a, b) => a.CreatedDate.length - b.CreatedDate.length,
    },
    {
        title: "Distributor Type To ",
        dataIndex: "DistributorTypeTo",
        //   sorter: (a, b) => a.CreatedDate.length - b.CreatedDate.length,
      },
      {
        title: "Purchase Rate",
        dataIndex: "PurchaseRate",
        render: (_: any, record: DataType) => (
            <>
           <TextField />
           
            </>)
      },
      {
        title: "Selling Rate",
        dataIndex: "SellingRate",
        render: (_: any, record: DataType) => (
            <>
           <TextField />
           
            </>)
      },
      {
        title: "MRP",
        dataIndex: "MRP",
        render: (_: any, record: DataType) => (
            <>
           <TextField />
           
            </>)
      },
 
  ];

  const data: DataType[] = [
    {
      key: "1",
      DistributorCode: "GT0100020119610002011961",
      DistributorTypeFrom: "Distributor",
      DistributorTypeTo:"Retailer",
      PurchaseRate:'',
      SellingRate:'',
      MRP:''
    },
 
  ];
  const handleDeleteClick = (event: React.MouseEvent<unknown>) => {
    setIsDelete(!isDelete);
  };
  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    //search function logic here
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <>
    
      {show ? (
        <ProductGSTMapping onBack={handleclick} />
      ) : (
        <Box>
          <form noValidate>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">Product code</Typography>
                <TextField placeholder="Product code" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">
                  {" "}
                  Batch Creation Date
                </Typography>
                <TextField placeholder="" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable"> Expiry Date</Typography>
                <TextField placeholder="" className="Txt-box" />
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={3}
              mt={3}
              columnSpacing={{ xs: 3, sm: 3 }}
            >
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">Batch code</Typography>
                <TextField placeholder="Batch code" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">
                  {" "}
                  Manufactured Date
                </Typography>
                <TextField placeholder="" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable"> Expiry Date</Typography>
                <TextField placeholder="" className="Txt-box" />
              </Grid>
            </Grid>

            <Grid item mt={3}>
              <CommonTable
                // rowSelection={rowSelection}
                data={data}
                columns={columns}
              />
            </Grid>
            {isDelete && (
              <DeleteModal onBack={(event) => handleDeleteClick(event)} />
            )}

            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", mt: 4 }}
            >
              <Grid item sx={{ mr: 2 }}>
                <Button
                  variant="outlined"
                  onClick={handleBackClick}
                  className="button-lable"
                >
                  Back
                </Button>
              </Grid>
              <Grid>
            
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </>
  );
};
export default BatchInfoView;
