import React from "react";
import {
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Grid,
  InputLabel,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Tab,
  Checkbox,
  Modal,
} from "@mui/material";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import Distributorpopupedit from "./Distributorpopupedit";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import EditContact from "./EditContact";
import EditSales from "./EditSales";
import EditGST from "./EditGRT";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface DistributorEditProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const DistributorEdit: React.FC<DistributorEditProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };

  const DistributorOptions = ["select", "", ""];
  const Discount = ["none", "", ""];
  const geoHierarchyValueOptions = ["Value A", "Value B", "Value C"];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };

  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <>
      {show ? (
        <Box>
          <form>
            <Box
              sx={{
                width: "100%",
                height: "40px",
                backgroundColor: "#8080801f",
              }}
            >
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      label="Distributor Contact Details"
                      value="1"
                      className="customer-general"
                    />
                    <Tab label="Sales and Geo Mapping" value="2" />
                    <Tab label="GST Mapping" value="3" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <EditContact onBack={handleBackClick} />{" "}
                </TabPanel>
                <TabPanel value="2">
                  <EditSales onBack={handleBackClick} />
                </TabPanel>
                <TabPanel value="3">
                  <EditGST  onBack={handleBackClick}/>{" "}
                </TabPanel>
              </TabContext>
            </Box>
            {/* <Button onClick={handleBackClick}>cancel</Button> */}
          </form>
        </Box>
      ) : (
        // <EditTab onBack={handleclick}/>
        <Box className="card-form">
          <form noValidate>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 10 }}>
              <Grid item xs={12} sm={4}>
                <InputLabel size="normal"> Distributor Code </InputLabel>
                <TextField placeholder="Distributor Code" disabled fullWidth />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel size="normal">Distributor Name </InputLabel>
                <TextField placeholder="Distributor Name" fullWidth />
              </Grid>
            </Grid>
            <Grid container mt={3} columnSpacing={{ xs: 3, sm: 10 }}>
              <Grid item xs={12} sm={4}>
                <InputLabel size="normal">Distributor Type</InputLabel>
                <FormControl fullWidth>
                  <Select>
                    {DistributorOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel size="normal">Parent code</InputLabel>
                <TextField placeholder="Parent code" fullWidth />
              </Grid>
            </Grid>
            <Grid container mt={3} columnSpacing={{ xs: 3, sm: 10 }}>
              <Grid item xs={12} sm={4}>
                <InputLabel size="normal">Suppiler</InputLabel>
                <TextField placeholder="Suppiler" sx={{ width: "81%" }} />
                <Button
                  sx={{ ml: 1, color: "#fff" }}
                  className="btn-colr"
                  onClick={handleOpen}
                >
                  ....
                </Button>

                <Grid item>
                  <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Distributorpopupedit handleClose={handleClose} />
                    </Box>
                  </Modal>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel size="normal">Discount Based On</InputLabel>
                <FormControl fullWidth>
                  <Select>
                    {Discount.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item mt={2}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked className="check-box" />}
                  label="SFA Enabled"
                />
                <FormControlLabel
                  control={<Checkbox className="check-box" />}
                  label="Allow Customer approval"
                />
                <FormControlLabel
                  control={<Checkbox className="check-box" />}
                  label="Auto purchase download"
                />
                <FormControlLabel
                  control={<Checkbox className="check-box" />}
                  label="Sell rate edit enabled"
                />
              </FormGroup>
            </Grid>
            <Grid item mt={2}>
              <FormControl>
                <FormLabel>Is Active</FormLabel>

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="Active"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                            color: "#6750A4",
                          },
                        }}
                      />
                    }
                    label="Active"
                  />
                  <FormControlLabel
                    value="In Active"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                            color: "#6750A4",
                          },
                        }}
                      />
                    }
                    label="In Active"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} mt={2} display="flex" justifyContent="center">
              <Button
                className="btn-colr "
                variant="contained"
                onClick={handleclick}
              >
                <RedoOutlinedIcon sx={{ color: "", fontSize: "15px" }} />
                Next
              </Button>
              <Grid item sx={{ ml: 2 }}>
                <Button variant="outlined" onClick={handleBackClick}>
                  <CancelIcon sx={{ color: "", fontSize: "15px" }} /> Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </>
  );
};

export default DistributorEdit;
