import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import createAxiosInstance from "../../../hooks/useFetch";
import { NavigationTimingPolyfillEntry } from "web-vitals";

interface PendingGrnData {
  invoiceNo: string;
  orderNo: string;
  invoiceDate: string;
  totNetAmt: number;
  noOfLineItem: string;
  status: string;
}
interface BillingShippingDetails {
  distributorName: string;
  address: string;
  panNo: string;
  gstnumber: string;
  shippingAddress: string;
  shippingGstNumber: string;
  pTerms: string;
  contactmobile: string;
  state: string;
}
interface pendingGrnTableData {
  pendingGrnList: PendingGrnData[];
  totalCount: number;
}

interface ProductDetailsGrnData {
  prodCode: string;
  prodName: string;
  matDesc: string;
  uomCode: string;
  slno: string;
  mrp: string;
  invQty: string;
  netAmt: number;
}

interface ProductDetailsGrnTableData {
  ProductDetailsGrnList: ProductDetailsGrnData[];
}

interface addDiscrepancyListData {
  prodCode: string;
  prodName: string;
  matDesc: string;
  uomCode: string;
  slno: string;
  mrp: string;
  invQty: number;
  netAmt: number;
}
interface addDiscrepancyReasonListData {
  id: string;
  discrepancyName: string;
  discrepancyKey: string;
}
interface GrnFromData {
  companyName: string;
  address1: string;
  address2: string;
  district: string;
  state: string;
  postalCode: 600032;
  supplierName: string;
  supplierAddress1: string;
  supplierAddress2: string;
  supplierAddress3: string;
  supplierDistrict: string;
  supplierCity: string;
  supplierState: string;
  panNo: string;
  supplierPostalCode: 302013;
  cInNo: string;
  orderID: string;
  orderDate: string;
  fssaiNo: string;
  gstState: string;
  gstNo: string;
  fssai: string;
  supplierCode: string;
  eWayBillNo: string;
  eWayBillDate: string;
  transportation: string;
  vehicleNo: string;
  date: string;
  timeOfSupply: string;
  placeOfSupply: string;
  lrNo: string;
  lrDate: string;
  carrierName: string;
}
interface grnInvoiceSummary {
  creditNote: number;
  debitNote: number;
  advance: number;
  tcsAmount: number;
  totalSummedValue: number;
  roundOff: number;
  netAmount: number;
}
interface GrnInvoices {
  details: grnInvoicedata[];
  summary: grnInvoiceSummary | null;
}
interface pendingGrnState {
  grnHistoryDetails: GrnHistoryDetails | null;
  grnHistoryDetailsError: string | null;
  grnHistoryDetailsLoading: boolean;

  pendingGrn: pendingGrnTableData;
  pendingGrnLoading: boolean;
  pendingGrnError: string | null;

  ProductDetailsGrn: ProductDetailsGrnTableData;
  ProductDetailsGrnLoading: boolean;
  ProductDetailsGrnError: string | null;

  addDiscrepancyList: addDiscrepancyListData[];
  addDiscrepancyListLoading: boolean;
  addDiscrepancyListError: string | null;

  addDiscrepancyReasonList: addDiscrepancyReasonListData[];
  addDiscrepancyReasonListLoading: boolean;
  addDiscrepancyReasonListError: string | null;

  grnHistoryData: ResponseGrnHistory | null;
  grnHistoryDataLoading: boolean;
  grnHistoryDataError: string | null;

  grnShippingBilling: BillingShippingDetails | null;
  grnShippingBillingLoading: boolean;
  grnShippingBillingError: string | null;

  grnInvoiceData: GrnInvoices;
  grnInvoiceDataLoading: boolean;
  grnInvoiceDataError: string | null;

  grnfromDatas: GrnFromData | null;
  grnfromDatasError: string | null;
  grnfromDatasLoading: boolean;

  discrepancyResponseData: DiscrepancyResponseDataList;
  discrepancyResponseDataLoading: boolean;
  discrepancyResponseDataError: string | null;

  grnProductDetails: GrnProductDetails[];
  grnProductDetailsLoading: boolean;
  grnProductDetailsError: string | null;

  discrepancyAllInfoData: DiscrepancyInfoAll[];
  discrepancyAllInfoDataLoading: boolean;
  discrepancyAllInfoDataError: string | null;

  ProductDetailsAcceptedGrn: ProductAcceptedGrnTableData;
  ProductDetailsGrnAcceptedLoading: boolean;
  ProductDetailsAcceptedGrnError: string | null;

  discrepancyInfo: DiscrepancyInfoAll | null;
  discrepancyInfoLoading: boolean;
  discrepancyInfoError: string | null;

  AcceptedInvoiceDetails: AcceptedInvoiceDetailsGrnData | null;
  AcceptedInvoiceDetailsLoading: boolean;
  AcceptedInvoiceDetailsError: string | null;

  ProceedInvoiceDetails: ProceedAcceptedData | null;
  ProceedInvoiceDetailsLoading: boolean;
  ProceedInvoiceDetailsError: string | null;

  grnpdfLoading: boolean;
  grnpdferror: any;
  grnPdf: string | null;
  loading: boolean;
  error: string | null;
}
interface GrnHistoryDetails {
  grnDate: string;
  invoiceNo: string;
  orderId: string;
  noOfLineItems: number;
  value: number;
  acceptedItems: number;
  acceptedValue: number;
  discrepancyItems: number;
  discrepancyValue: number;
}
interface PendingGrnFilterDataRequest {
  page: number;
  size: number;
  type: string;
  startDate: string;
  endDate: string;
  search: string;
}
interface GrnHistoryRequest {
  page: number;
  size: number;
  date: string;
  fromDate: string;
  toDate: string;
  status: string;
  search: string;
}
interface ProductDetailsGrnFilterDataRequest {
  page: number;
  size: number;
  invNo: string;
}
interface GrnAddDiscrepancyListRequest {
  isSelectAll: boolean;
  invoiceNo: string;
  slNo: any;
  unSelectedSlNo: any;
  page: number;
  size: number;
}
interface SaveAddDiscrepancyRequest {
  invoiceNo: string;
  productDetails: {
    productCode: string;
    slNo: string;
    discrepancyReason: string[];
    discrepancyQty: number[];
    discrepancyValue: number[];
    invoicedQty: number;
    invoicedValue: number;
  }[];
  totalDiscrepancyQty: number;
  totalDiscrepancyValue: number;
}
interface AcceptAllRequest {
  invoiceNo: string;
  productDetails: {
    productCode: string;
    slNo: string;
    invoicedQty: number;
    invoicedValue: number;
  }[];
  totalDiscrepancyQty: number;
  totalDiscrepancyValue: number;
}
interface ResponseGrnHistory {
  GrnHistoryData: GrnHistoryData;
  totalCount: number;
}
interface GrnHistoryData {
  grnDate: string;
  grnNo: string;
  invoiceNo: string;
  orderId: string;
  value: number;
  noOfLineItems: number;
}

interface grnInvoicedata {
  productName: string;
  hsnCode: string;
  mrp: number;
  quantity: number;
  uom: string;
  rate: number;
  cgstRate: number;
  sgstRate: number;
  igstRate: number;
  cgstAmount: number;
  sgstAmount: number;
  igstAmount: number;
  taxableValue: number;
  totalValue: number;
  grossAmount: number;
  discountAmount: number;
  addedDiscountAmount: number;
  tcsAmount: number;
}
interface DiscrepancyResponseDataList {
  invoiceNo: string;
  productDetails: {
    productCode: string;
    slNo: string;
    discrepancyReason: string[];
    discrepancyQty: number[];
    discrepancyValue: number[];
    invoicedQty: number;
    invoicedValue: number;
    status: string;
  }[];
  totalDiscrepancyQty: number;
  totalDiscrepancyValue: number;
}
interface GrnProductDetails {
  productCode: string;
  matGrpDesc: string;
  basicMaterial: string;
  uom: string;
  mrp: number;
  invoicedQuantity: number;
  invoicedValue: string;
  acceptedQty: number;
  acceptedValue: number;
  batchCode: string;
  status: string;
  discrepancyInfo: boolean;
}
interface DiscrepancyInfoAll {
  productCode: string;
  mrp: string;
  productName: string;
  basicMaterial: string;
  matGrp2Desc: string;
  status: string;
  acceptedQty: string;
  acceptedValue: string;
  invoiceValue: string;
  invoiceQty: string;
  discrepancyQty: string;
  discrepancyValue: string;
  reason: any;
  uom:string;
}
interface ProductDetailsAcceptedGrnData {
  productCode: string;
  productName: string;
  uom: string;
  slno: string;
  mrp: string;
  invoiceQuantity: string;
  invoiceValue: number;
  acceptedQty: string;
  acceptedValue: number;
  status: string;
}
interface AcceptedInvoiceDetailsGrnData {
  totalInvoiceValue: number;
  totalInvoiceQty: string;
  totalAcceptedQtyValue: number;
  totalAcceptedQty: string;
  invoiceNo: string;
  orderId: string;
  invoiceDate: number;
  noItems: string;
}
  interface ProceedAcceptedData {
    id: string;
    distributorCode: string;
    grnNo: string;
    priInvDate: string;
    priInvNumber: string;
    purchaseOrderNO: string;
    grnDate: string;
    totalInvoiceQty: string;
    totalInvoiceValue: number;
    totalAcceptedQty: string;
    totalAcceptedQtyValue: number;
    totalDiscrepancyQty: string;
    totalDiscrepancyQtyValue: number;
    invoiceType: string;
    status: string;
  }
interface ProductAcceptedGrnTableData {
  ProductDetailsAccpetedGrnList: ProductDetailsAcceptedGrnData[];
}
const axiosInstance = createAxiosInstance();
export const getGrnHistoryDeials = createAsyncThunk(
  "grnHistory/get-history-details",
  async (grnNo: string) => {
    const response = await axiosInstance.get(
      `/grnHistory/get-grn-history?grnNo=${grnNo}`
    );
    return response.data.data;
  }
);
export const getPendingGrn = createAsyncThunk(
  "invoice-grn/fetch-pending-grn",
  async (req: { params: PendingGrnFilterDataRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get("invoice-grn/pending-grn", {
      params,
      signal,
    });
    return response.data.data;
  }
);
export const getProductDetailsGrn = createAsyncThunk(
  "invoice-grn/fetch-pending-grn-productDetails",
  async (req: {
    params: ProductDetailsGrnFilterDataRequest;
    signal: AbortSignal;
  }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(
      "invoice-grn/pending-grn-details",
      {
        params,
        signal,
      }
    );
    return response.data.data;
  }
);
export const getAddDiscrepancyGrn = createAsyncThunk(
  "invoice-grn/fetch-pending-grn-add-discrepancy",
  async (req: {
    params: GrnAddDiscrepancyListRequest;
    signal: AbortSignal;
  }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(
      "invoice-grn/get-add-discrepancy-list",
      {
        params,
        signal,
      }
    );
    return response.data.data;
  }
);
export const getAddDiscrepancyReasonList = createAsyncThunk(
  "invoice-grn/add-discrepancy-reason",
  async (req: any) => {
    const response = await axiosInstance.get(
      "invoice-grn/get-discrepancy-reason"
    );
    return response.data.data;
  }
);

export const getGrnHistory = createAsyncThunk(
  "invoice-grn/fetch-grn-history",
  async (req: { params: GrnHistoryRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get("grnHistory/get-history-details", {
      params,
      signal,
    });
    return response.data.data;
  }
);
export const saveAddDiscrepancy = createAsyncThunk(
  "invoice-grn/saveidiscrepancy",
  async (req: { params: SaveAddDiscrepancyRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.post(
      "invoice-grn/save-discrepancy",
      params,
      {
        signal,
      }
    );
    return response.data.data;
  }
);
export const getBillingShippingDetails = createAsyncThunk(
  "grnHistory/get-receiver-info",
  async () => {
    const response = await axiosInstance.get("grnHistory/get-receiver-info");
    return response.data.data;
  }
);
export const getGrnInvoiceData = createAsyncThunk(
  "invoice-grn/get-purchase-product-details",
  async (invoiceNo: string) => {
    const response = await axiosInstance.get(
      `grnHistory/get-purchase-product-details?invoiceNo=${invoiceNo}`
    );
    return response.data.data;
  }
);
export const getGrninvoicefromDatas = createAsyncThunk(
  "grnHistory/get-from-info",
  async (invoiceNo: string) => {
    const response = await axiosInstance.get(
      `grnHistory/get-from-info?invoiceNo=${invoiceNo}`
    );
    return response.data.data;
  }
);
export const acceptAllPendingGrn = createAsyncThunk(
  "/invoice-grn/discrepancy-accept-all",
  async (req: { params: AcceptAllRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.post(
      "/invoice-grn/discrepancy-accept-all?type=all",
      params,
      {
        signal,
      }
    );
    return response.data.data;
  }
);
export const getGrnProductDetails = createAsyncThunk(
  "invoice-grn/get-Grn-product-details",
  async (grnNo: string) => {
    const response = await axiosInstance.get(
      `grnHistory/get-grn-product-details?grnNo=${grnNo}`
    );
    return response.data.data;
  }
);
export const getAlldiscrepencyInfo = createAsyncThunk(
  "invoice-grn/get-Grn-discrepency-all",
  async (grnNo: string) => {
    const response = await axiosInstance.get(
      `grnHistory/get-discrepency-view-all?grnNo=${grnNo}`
    );
    return response.data.data;
  }
);
export const getSingleDiscrepencyInfo = createAsyncThunk(
  "invoice-grn/get-Grn-discrepency-single",
  async (req: {
    params: {
      grnNo: string|null ;
      batchCode: string|null;
    }}) => {
      const {grnNo,batchCode} = req.params;
    const response = await axiosInstance.get(
      `grnHistory/get-discrepency-info?grnNo=${grnNo}&batchCode=${batchCode}`
    );
    return response.data.data;
  }
);
export const getPendingAcceptedGrn = createAsyncThunk(
  "invoice-grn/fetch-accepted-grn-productDetails",
  async (req: {
    params: ProductDetailsGrnFilterDataRequest;
    signal: AbortSignal;
  }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(
      "invoice-grn/get-accepted-products",
      {
        params,
        signal,
      }
    );
    return response.data.data;
  }
);
export const getAcceptedInvoiceDetails = createAsyncThunk(
  "invoice-grn/fetch-accepted-invoiceDetails",
  async (req: { params: string; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(
      `invoice-grn/get-accepted-invoice-details?invoiceNo=${params}`,
      {
        signal,
      }
    );
    return response.data.data;
  }
);
export const getProceedAcceptedGrn = createAsyncThunk(
  "invoice-grn/proceed-accepted-grn-productDetails",
  async (req: { params: string; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.post(
      `invoice-grn/confirm-products?invoiceNo=${params}`,
      {
        signal,
      }
    );
    return response.data.data;
  }
);
export const grnPdfGeneratr = createAsyncThunk(
  "invoice-grn/get-Grn-pdf-generated",
  async (req: {
    params: {
      invoiceNo: string | null;
      invoiceDate: string | null;
    };
  }) => {
    const { invoiceNo, invoiceDate } = req.params;
    const response = await axiosInstance.get(
      `grnHistory/generatePdf?invoiceNo=${invoiceNo}&invoiceDate=${invoiceDate}`
    );
    return response.data.data;
  }
);
const initialState: pendingGrnState = {
  grnHistoryDetails: null,
  grnHistoryDetailsError: null,
  grnHistoryDetailsLoading: false,
  pendingGrn: { pendingGrnList: [], totalCount: 0 },
  pendingGrnLoading: false,
  pendingGrnError: null,

  ProductDetailsGrn: { ProductDetailsGrnList: [] },
  ProductDetailsGrnLoading: false,
  ProductDetailsGrnError: null,

  addDiscrepancyList: [],
  addDiscrepancyListLoading: false,
  addDiscrepancyListError: null,

  addDiscrepancyReasonList: [],
  addDiscrepancyReasonListLoading: false,
  addDiscrepancyReasonListError: null,

  grnHistoryData: null,
  grnHistoryDataLoading: false,
  grnHistoryDataError: null,

  grnShippingBilling: null,
  grnShippingBillingError: null,
  grnShippingBillingLoading: false,

  grnInvoiceData: { details: [], summary: null },
  grnInvoiceDataLoading: false,
  grnInvoiceDataError: null,

  grnfromDatas: null,
  grnfromDatasError: null,
  grnfromDatasLoading: false,

  discrepancyResponseData: {
    invoiceNo: "",
    productDetails: [],
    totalDiscrepancyQty: 0,
    totalDiscrepancyValue: 0,
  },
  discrepancyResponseDataLoading: false,
  discrepancyResponseDataError: null,

  grnProductDetails: [],
  grnProductDetailsLoading: false,
  grnProductDetailsError: null,

  discrepancyAllInfoData: [],
  discrepancyAllInfoDataLoading: false,
  discrepancyAllInfoDataError: null,

  ProductDetailsAcceptedGrn: { ProductDetailsAccpetedGrnList: [] },
  ProductDetailsGrnAcceptedLoading: false,
  ProductDetailsAcceptedGrnError: null,

  discrepancyInfo: null,
  discrepancyInfoLoading: false,
  discrepancyInfoError:  null,

  AcceptedInvoiceDetails: null,
  AcceptedInvoiceDetailsLoading: false,
  AcceptedInvoiceDetailsError: null,

  ProceedInvoiceDetails: null,
  ProceedInvoiceDetailsLoading: false,
  ProceedInvoiceDetailsError: null,
  grnpdfLoading : true,
  grnpdferror : null,
  grnPdf : null,

  loading: false,
  error: null,
};

const orderBillingSlice = createSlice({
  name: "pendingGrnData",
  initialState,
  reducers: {
    pendingGrnFilterFetch: (
      state,
      action: PayloadAction<Partial<pendingGrnState>>
    ) => {
      state.pendingGrn = action.payload.pendingGrn || state.pendingGrn;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPendingGrn.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.pendingGrn = { pendingGrnList: [], totalCount: 0 };
      state.pendingGrnLoading = true;
      state.pendingGrnError = null;
    });
    builder.addCase(
      getPendingGrn.fulfilled,
      (state, action: PayloadAction<pendingGrnTableData>) => {
        state.pendingGrn = action.payload;
        state.loading = false;
        state.error = null;
        state.pendingGrnLoading = false;
        state.pendingGrnError = null;
      }
    );
    builder.addCase(getPendingGrn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.pendingGrnLoading = false;
      state.pendingGrnError = action.error.message ?? null;
    });
    builder.addCase(getProductDetailsGrn.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.ProductDetailsGrn = { ProductDetailsGrnList: [] };
      state.ProductDetailsGrnLoading = true;
      state.ProductDetailsGrnError = null;
    });
    builder.addCase(
      getProductDetailsGrn.fulfilled,
      (state, action: PayloadAction<ProductDetailsGrnTableData>) => {
        state.ProductDetailsGrn = action.payload;
        state.loading = false;
        state.error = null;
        state.ProductDetailsGrnLoading = false;
        state.ProductDetailsGrnError = null;
      }
    );
    builder.addCase(getProductDetailsGrn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.ProductDetailsGrnLoading = false;
      state.ProductDetailsGrnError = action.error.message ?? null;
    });
    builder.addCase(getAddDiscrepancyGrn.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.addDiscrepancyList = [];
      state.addDiscrepancyListLoading = true;
      state.addDiscrepancyListError = null;
    });
    builder.addCase(
      getAddDiscrepancyGrn.fulfilled,
      (state, action: PayloadAction<addDiscrepancyListData[]>) => {
        state.addDiscrepancyList = action.payload;
        state.loading = false;
        state.error = null;
        state.addDiscrepancyListLoading = false;
        state.addDiscrepancyListError = null;
      }
    );
    builder.addCase(getAddDiscrepancyGrn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.addDiscrepancyListLoading = false;
      state.addDiscrepancyListError = action.error.message ?? null;
    });
    builder.addCase(getAddDiscrepancyReasonList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.addDiscrepancyReasonList = [];
      state.addDiscrepancyReasonListLoading = true;
      state.addDiscrepancyReasonListError = null;
    });
    builder.addCase(
      getAddDiscrepancyReasonList.fulfilled,
      (state, action: PayloadAction<addDiscrepancyReasonListData[]>) => {
        state.addDiscrepancyReasonList = action.payload;
        state.loading = false;
        state.error = null;
        state.addDiscrepancyReasonListLoading = false;
        state.addDiscrepancyReasonListError = null;
      }
    );
    builder.addCase(getAddDiscrepancyReasonList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.addDiscrepancyReasonListLoading = false;
      state.addDiscrepancyReasonListError = action.error.message ?? null;
    });

    builder.addCase(getGrnHistory.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.grnHistoryData = null;
      state.grnHistoryDataLoading = true;
      state.grnHistoryDataError = null;
    });

    builder.addCase(
      getGrnHistory.fulfilled,
      (state, action: PayloadAction<ResponseGrnHistory>) => {
        state.grnHistoryData = action.payload;
        state.loading = false;
        state.error = null;
        state.grnHistoryDataLoading = false;
        state.grnHistoryDataError = null;
      }
    );
    builder.addCase(getGrnHistory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.grnHistoryDataLoading = false;
      state.grnHistoryDataError = action.error.message ?? null;
    });
    builder.addCase(getBillingShippingDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.grnShippingBilling = null;
      state.grnShippingBillingLoading = true;
      state.grnShippingBillingError = null;
    });

    builder.addCase(
      getBillingShippingDetails.fulfilled,
      (state, action: PayloadAction<BillingShippingDetails>) => {
        state.grnShippingBilling = action.payload;
        state.loading = false;
        state.error = null;
        state.grnShippingBillingLoading = false;
        state.grnShippingBillingError = null;
      }
    );
    builder.addCase(getBillingShippingDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.grnShippingBillingLoading = false;
      state.grnShippingBillingError = action.error.message ?? null;
    });
    builder.addCase(getGrnInvoiceData.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.grnInvoiceData = { details: [], summary: null };
      state.grnInvoiceDataLoading = true;
      state.grnInvoiceDataError = null;
    });

    builder.addCase(
      getGrnInvoiceData.fulfilled,
      (state, action: PayloadAction<GrnInvoices>) => {
        state.grnInvoiceData = action.payload;
        state.loading = false;
        state.error = null;
        state.grnInvoiceDataLoading = false;
        state.grnInvoiceDataError = null;
      }
    );
    builder.addCase(getGrnInvoiceData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.grnInvoiceDataLoading = false;
      state.grnInvoiceDataError = action.error.message ?? null;
    });
    builder.addCase(getGrninvoicefromDatas.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.grnfromDatas = null;
      state.grnfromDatasLoading = true;
      state.grnfromDatasError = null;
    });

    builder.addCase(
      getGrninvoicefromDatas.fulfilled,
      (state, action: PayloadAction<GrnFromData>) => {
        state.grnfromDatas = action.payload;
        state.loading = false;
        state.error = null;
        state.grnfromDatasLoading = false;
        state.grnfromDatasError = null;
      }
    );
    builder.addCase(getGrninvoicefromDatas.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.grnfromDatasLoading = false;
      state.grnfromDatasError = action.error.message ?? null;
    });
    builder.addCase(getGrnHistoryDeials.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.grnHistoryDetails = null;
      state.grnHistoryDetailsLoading = true;
      state.grnHistoryDetailsError = null;
    });

    builder.addCase(
      getGrnHistoryDeials.fulfilled,
      (state, action: PayloadAction<GrnHistoryDetails>) => {
        state.grnHistoryDetails = action.payload;
        state.loading = false;
        state.error = null;
        state.grnHistoryDetailsLoading = false;
        state.grnHistoryDetailsError = null;
      }
    );
    builder.addCase(getGrnHistoryDeials.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.grnHistoryDetailsLoading = false;
      state.grnHistoryDetailsError = action.error.message ?? null;
    });

    builder.addCase(saveAddDiscrepancy.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.discrepancyResponseData = {
        invoiceNo: "",
        productDetails: [],
        totalDiscrepancyQty: 0,
        totalDiscrepancyValue: 0,
      };
      state.discrepancyResponseDataLoading = true;
      state.discrepancyResponseDataError = null;
    });
    builder.addCase(
      saveAddDiscrepancy.fulfilled,
      (state, action: PayloadAction<DiscrepancyResponseDataList>) => {
        state.discrepancyResponseData = action.payload;
        state.loading = false;
        state.error = null;
        state.discrepancyResponseDataLoading = false;
        state.discrepancyResponseDataError = null;
      }
    );
    builder.addCase(saveAddDiscrepancy.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.discrepancyResponseDataLoading = false;
      state.discrepancyResponseDataError = action.error.message ?? null;
    });
    builder.addCase(getGrnProductDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.grnProductDetails = [];
      state.grnProductDetailsLoading = true;
      state.grnProductDetailsError = null;
    });

    builder.addCase(
      getGrnProductDetails.fulfilled,
      (state, action: PayloadAction<GrnProductDetails[]>) => {
        state.grnProductDetails = action.payload;
        state.loading = false;
        state.error = null;
        state.grnProductDetailsLoading = false;
        state.grnProductDetailsError = null;
      }
    );
    builder.addCase(getGrnProductDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.grnProductDetailsLoading = false;
      state.grnProductDetailsError = action.error.message ?? null;
    });
    builder.addCase(getAlldiscrepencyInfo.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.discrepancyAllInfoData = [];
      state.discrepancyAllInfoDataLoading = true;
      state.discrepancyAllInfoDataError = null;
    });

    builder.addCase(
      getAlldiscrepencyInfo.fulfilled,
      (state, action: PayloadAction<DiscrepancyInfoAll[]>) => {
        state.discrepancyAllInfoData = action.payload;
        state.loading = false;
        state.error = null;
        state.discrepancyAllInfoDataLoading = false;
        state.discrepancyAllInfoDataError = null;
      }
    );
    builder.addCase(getAlldiscrepencyInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.discrepancyAllInfoDataLoading = false;
      state.discrepancyAllInfoDataError = action.error.message ?? null;
    });
    builder.addCase(getPendingAcceptedGrn.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.ProductDetailsAcceptedGrn = { ProductDetailsAccpetedGrnList: [] };
      state.ProductDetailsGrnAcceptedLoading = true;
      state.ProductDetailsAcceptedGrnError = null;
    });
    builder.addCase(
      getPendingAcceptedGrn.fulfilled,
      (state, action: PayloadAction<ProductAcceptedGrnTableData>) => {
        state.ProductDetailsAcceptedGrn = action.payload;
        state.loading = false;
        state.error = null;
        state.ProductDetailsGrnAcceptedLoading = false;
        state.ProductDetailsAcceptedGrnError = null;
      }
    );
    builder.addCase(getPendingAcceptedGrn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.ProductDetailsGrnAcceptedLoading = false;
      state.ProductDetailsAcceptedGrnError = action.error.message ?? null;
    });
     builder.addCase(getSingleDiscrepencyInfo.pending, (state) => {
       state.loading = true;
       state.error = null;
       state.discrepancyInfo = null;
       state.discrepancyInfoLoading = true;
       state.discrepancyInfoError = null;
     });
     builder.addCase(
       getSingleDiscrepencyInfo.fulfilled,
       (state, action: PayloadAction<DiscrepancyInfoAll>) => {
         state.discrepancyInfo = action.payload;
         state.loading = false;
         state.error = null;
         state.discrepancyInfoLoading = false;
         state.discrepancyInfoError = null;
       }
     );
     builder.addCase(getSingleDiscrepencyInfo.rejected, (state, action) => {
       state.loading = false;
       state.error = action.error.message ?? null;
       state.discrepancyInfoLoading = false;
       state.discrepancyInfoError = action.error.message ?? null;
     });

    builder.addCase(getAcceptedInvoiceDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.AcceptedInvoiceDetails = null;
      state.AcceptedInvoiceDetailsLoading = true;
      state.AcceptedInvoiceDetailsError = null;
    });
    builder.addCase(
      getAcceptedInvoiceDetails.fulfilled,
      (state, action: PayloadAction<AcceptedInvoiceDetailsGrnData>) => {
        state.AcceptedInvoiceDetails = action.payload;
        state.loading = false;
        state.error = null;
        state.AcceptedInvoiceDetailsLoading = false;
        state.AcceptedInvoiceDetailsError = null;
      }
    );
    builder.addCase(getAcceptedInvoiceDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.AcceptedInvoiceDetailsLoading = false;
      state.AcceptedInvoiceDetailsError = action.error.message ?? null;
    });

   builder.addCase(getProceedAcceptedGrn.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.ProceedInvoiceDetails = null;
      state.ProceedInvoiceDetailsLoading = true;
      state.ProceedInvoiceDetailsError = null;
    });
    builder.addCase(
      getProceedAcceptedGrn.fulfilled,
      (state, action: PayloadAction<ProceedAcceptedData>) => {
        state.ProceedInvoiceDetails = action.payload;
        state.loading = false;
        state.error = null;
        state.ProceedInvoiceDetailsLoading = false;
        state.ProceedInvoiceDetailsError = null;
      }
    );
    builder.addCase(getProceedAcceptedGrn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.ProceedInvoiceDetailsLoading = false;
      state.ProceedInvoiceDetailsError = action.error.message ?? null;
    });
     builder.addCase(grnPdfGeneratr.pending, (state) => {
       state.grnpdfLoading = true;
       state.grnpdferror = null;
       state.grnPdf = null;
     });
     builder.addCase(grnPdfGeneratr.fulfilled, (state, action) => {
       state.grnpdfLoading = false;
       state.grnPdf = action.payload.data ?? [];
       state.grnpdferror = null;
     });
     builder.addCase(grnPdfGeneratr.rejected, (state, action) => {
       state.grnpdfLoading = false;
       state.grnpdferror = action.error.message;
     });
  },
});

export const { pendingGrnFilterFetch } = orderBillingSlice.actions;
export default orderBillingSlice.reducer;
