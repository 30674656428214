import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  import UndoIcon from "@mui/icons-material/Undo";
  import SaveIcon from "@mui/icons-material/Save";
  import CancelIcon from "@mui/icons-material/Cancel";
  
    interface DistributorTabProps {
      onBack: (event: React.MouseEvent<unknown>) => void;
    }
    
    const ProductInfoView: React.FC<DistributorTabProps> = ({onBack}) => {
   
    
      const handleBackClick = (event: React.MouseEvent<unknown>) => {
        onBack(event); // Pass the event argument to onBack function
      };
      const handleclick  =(event:React.MouseEvent<unknown>)=>{
        onBack(event)
      }
      
   
    const Options = ["Stand Level", "", ""];
  
    
    return (
      <Box>
        <form>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 10 }}>
            <Grid item xs={12} sm={6}>
              <Typography className="fnt-lable">Valid Form </Typography>
              <TextField variant="outlined" className="Txt-box" />
             
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="fnt-lable">
            Valid Till
              </Typography>
              <TextField variant="outlined" className="Txt-box"  />
            </Grid>
            <Grid item sm={4} mt={2}>
            <Typography className="fnt-lable">
              {" "}
           Status
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
         
            
          </Grid>
          <Grid container mt={3} rowSpacing={3} columnSpacing={{ xs: 3, sm: 10 }}>
   
          <Grid item xs={12} sm={6}>
                <Typography className="fnt-lable">Product Hierarchy</Typography>
                <FormControl fullWidth>
                  <Select labelId="demo-simple-select-label" className="selct-box">
                    {Options.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
         </Grid>
         <Grid container mt={3} rowSpacing={3} columnSpacing={{ xs: 3, sm: 10 }}>
         <Grid item xs={12} sm={6}>
                <Typography className="fnt-lable"> Product Hierarchy value </Typography>
                <TextField placeholder="  " className="Txt-box" />
                <Button sx={{ color: "#fff", ml: 3 }} className="btn-colr">
                  ...
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography> Product List</Typography>
                <TextField placeholder=" "className="Txt-box" />
                <Button sx={{ color: "#fff", ml: 3 }} className="btn-colr">
                  ...
                </Button>
              </Grid>
  
  </Grid>
         
         
          <Grid item sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Grid item sx={{ mr: 2 }}>
              <Button variant="outlined" onClick={handleclick} >
                <UndoIcon
                  sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                />
                Back
              </Button>
            </Grid>
          
            <Grid item sx={{ ml: 2 }}>
              <Button  variant="outlined" onClick={handleBackClick}>
                <CancelIcon
                  sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                />{" "}
                Cancle
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  };
  export default ProductInfoView;
  