import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import {
  getOutlet,
  getRoute,
  getSalesman,
  openFilterModel,
  updateOutlet,
  updateOutletPop,
  updateOutletTemp,
  updateRoute,
  updateRoutePop,
  updateRouteTemp,
  updateSalesman,
  updateSalesmanPop,
  updateSalesmanTemp,
  updateStocks,
} from "../../../Redux/XDM/Sales/billingReducer";
import { useEffect, useState } from "react";
import FilterModal from "./FilterModal";

const OrderBillingSidebar = () => {
  const dispatch = useAppDispatch();
  const [filterModalTitle, setFilterModalTitle] = useState("");
  const fetch = useAppSelector((state) => state.orderBilling);

   // values: ["Low Stock Orders", "Optimal Stock Orders"],
  const stocks = {
 
    values: ["lowStock", "optimalStock"],
  };

  const filterData = {
    page: 0,
    size: 4,
    pageStatus: true,
    status:"Yet to Confirm",
  };
  
  useEffect(() => {
    dispatch(getSalesman(filterData));
    dispatch(getRoute(filterData));
    dispatch(getOutlet(filterData));
  }, []); 

  const salesman = fetch.salesman;

  const routes = fetch.routes;

  const outlets = fetch.outlets;

  const stockSelected = fetch.stockSelected;
  const salesmanSelected = fetch.salesmanSelected;
  const routeSelected = fetch.routeSelected;
  const outletSelected = fetch.outletSelected;

  const clearAll = (title: any) => {
    switch (title) {
      case "Stocks":
        dispatch(updateStocks([]));
        break;
      case "Salesman":
        dispatch(updateSalesman([]));
        dispatch(updateSalesmanPop([]));
        dispatch(updateSalesmanTemp([]));
        break;
      case "Routes":
        dispatch(updateRoute([]));
        dispatch(updateRoutePop([]));
        dispatch(updateRouteTemp([]));
        break;
      case "Outlets":
        dispatch(updateOutlet([]));
        dispatch(updateOutletPop([]));
        dispatch(updateOutletTemp([]));
        break;
      default:
        break;
    }
  };

  const filterOpenSales = useAppSelector(
    (state) => state.orderBilling.filterPop
  );

  let tempData: any[] = [];

  const handleShowMoreModal = (title: any) => {
    setFilterModalTitle(title);
    dispatch(openFilterModel(true));
    switch (title) {
      case "Salesman":
        dispatch(updateSalesmanTemp(fetch.salesmanSelected));
        break;
      case "Routes":
        dispatch(updateRouteTemp(fetch.routeSelected));
        break;
      case "Outlets":
        dispatch(updateOutletTemp(fetch.outletSelected));
        break;
      default:
        break;
    }
  };

  const stockSelect = (value: string) => {
    const isSelected = stockSelected.includes(value);
    const updatedStockSelected = isSelected
      ? stockSelected.filter((item: any) => item !== value)
      : [...stockSelected, value];
    dispatch(updateStocks(updatedStockSelected));
  };

  const salesmanSelect = (value: string) => {
    const isSelected = salesmanSelected.includes(value);
    const updatedSalesmanSelected = isSelected
      ? salesmanSelected.filter((item: any) => item !== value)
      : [...salesmanSelected, value];
    dispatch(updateSalesman(updatedSalesmanSelected));
    dispatch(updateSalesmanPop(updatedSalesmanSelected));
    dispatch(updateSalesmanTemp(updatedSalesmanSelected));
  };

  const routeSelect = (value: string) => {
    const isSelected = routeSelected.includes(value);
    const updatedRouteSelected = isSelected
      ? routeSelected.filter((item: any) => item !== value)
      : [...routeSelected, value];
    dispatch(updateRoute(updatedRouteSelected));
    dispatch(updateRoutePop(updatedRouteSelected));
    dispatch(updateRouteTemp(updatedRouteSelected));
  };

  const outletSelect = (value: string) => {
    const isSelected = outletSelected.includes(value);
    const updatedOutletSelected = isSelected
      ? outletSelected.filter((item: any) => item !== value)
      : [...outletSelected, value];
    dispatch(updateOutlet(updatedOutletSelected));
    dispatch(updateOutletPop(updatedOutletSelected));
    dispatch(updateOutletTemp(updatedOutletSelected));
  };

  return (
    <Box
      sx={{
        padding: "0px 5px 0px 16px",
        marginBottom: "100px",
        marginRight: "4.3px",
        marginTop: "4px",
      }}
      className="dis-flex scrollHide"
    >
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700", }}>
            Stocks
          </Typography>
          <Typography
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              mb: 1,
              color: "var(--grey-grey500, #505259)",
            }}
          >
            {stockSelected.length > 0 &&
              "(" + stockSelected.length + " Selected)"}
          </Typography>
        </Box>
        <Divider />
        <FormControl className="" sx={{ mt: 1 }}>
          {Array.isArray(stocks.values) &&
            stocks.values.map((values: any, index: number) => (
              <FormControlLabel
                key={index}
                value={values}
                control={
                  <Checkbox
                    onChange={() => stockSelect(values)}
                    checked={stockSelected.includes(values)}
                    className="sidebar_checkbox"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 15,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label={
                  <div
                    title={values}
                    style={{ paddingTop: "2px", paddingLeft: "5px" }}
                    className="hiddenDot"
                  >
                     {values==="lowStock"?"Low Stock Orders":"Optimal Stock Orders"}
                  </div>
                }
              />
            ))}
        </FormControl>

        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {stockSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              sx={{
                color: "#F5222D",
                fontSize: "10px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              }}
              className="cursor_pointer"
              onClick={() => clearAll("Stocks")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}></Grid>
        </Box>
      </Card>
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700", }}>
            Salesman
          </Typography>
          <Typography
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              mb: 1,
              color: "var(--grey-grey500, #505259)",
            }}
          >
            {salesmanSelected.length > 0 &&
              "(" + salesmanSelected.length + " Selected)"}
          </Typography>
        </Box>
        <Divider />
        <FormControl className="" sx={{ mt: 1 }}>
          {fetch.loading
            ? Array.from(new Array(4)).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={15}
                sx={{ mb: 1 }}
              />
            ))
            : Array.isArray(salesman.values) &&
            salesman.values.map((values: any, index: number) => {
              return (
                <FormControlLabel
                  key={index}
                  value={values.code}
                  control={
                    <Checkbox
                      onChange={() => salesmanSelect(values.code)}
                      checked={salesmanSelected.includes(values.code)}
                      className="sidebar_checkbox"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 15,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label={
                    <div
                      title={values.name}
                      style={{ paddingTop: "2px", paddingLeft: "5px" }}
                      className="hiddenDot"
                    >
                      {values.name}
                    </div>
                  }
                />
              );
            })}
        </FormControl>

        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {salesmanSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              sx={{
                color: "#F5222D",
                fontSize: "10px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              }}
              className="cursor_pointer"
              onClick={() => clearAll("Salesman")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}>
            {salesman.count > 0 && (
              <Typography
                className="moreData"
                onClick={() => handleShowMoreModal("Salesman")}
              >
                + {salesman.count} More
              </Typography>
            )}
          </Grid>
        </Box>
      </Card>
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700", }}>
            Routes
          </Typography>
          <Typography
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              mb: 1,
              color: "var(--grey-grey500, #505259)",
            }}
          >
            {routeSelected.length > 0 &&
              "(" + routeSelected.length + " Selected)"}
          </Typography>
        </Box>
        <Divider />
        <FormControl className="" sx={{ mt: 1 }}>
          {fetch.loading
            ? Array.from(new Array(4)).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={15}
                sx={{ mb: 1 }}
              />
            ))
            : Array.isArray(routes.values) &&
            routes.values.map((values: any, index: number) => (
              <FormControlLabel
                key={index}
                value={values.code}
                control={
                  <Checkbox
                    onChange={() => routeSelect(values.code)}
                    checked={routeSelected.includes(values.code)}
                    className="sidebar_checkbox"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 15,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label={
                  <div
                    title={values.name}
                    style={{ paddingTop: "2px", paddingLeft: "5px" }}
                    className="hiddenDot"
                  >
                    {values.name}
                  </div>
                }
              />
            ))}
        </FormControl>

        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {routeSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              sx={{
                color: "#F5222D",
                fontSize: "10px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              }}
              className="cursor_pointer"
              onClick={() => clearAll("Routes")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}>
            {routes.count > 0 && (
              <Typography
                className="moreData"
                onClick={() => handleShowMoreModal("Routes")}
              >
                + {routes.count} More
              </Typography>
            )}
          </Grid>
        </Box>
      </Card>
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700", }}>
            Outlets
          </Typography>
          <Typography
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              mb: 1,
              color: "var(--grey-grey500, #505259)",
            }}
          >
            {outletSelected.length > 0 &&
              "(" + outletSelected.length + " Selected)"}
          </Typography>
        </Box>
        <Divider />
        <FormControl className="" sx={{ mt: 1 }}>
          {fetch.loading
            ? Array.from(new Array(4)).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={15}
                sx={{ mb: 1 }}
              />
            ))
            : Array.isArray(outlets.values) &&
            outlets.values.map((values: any, index: number) => (
              <FormControlLabel
                key={index}
                value={values.code}
                control={
                  <Checkbox
                    onChange={() => outletSelect(values.code)}
                    checked={outletSelected.includes(values.code)}
                    className="sidebar_checkbox"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 15,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label={
                  <div
                    title={values.name}
                    style={{ paddingTop: "2px", paddingLeft: "5px" }}
                    className="hiddenDot"
                  >
                    {values.name}
                  </div>
                }
              />
            ))}
        </FormControl>

        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {outletSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              sx={{
                color: "#F5222D",
                fontSize: "10px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              }}
              className="cursor_pointer"
              onClick={() => clearAll("Outlets")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}>
            {outlets.count > 0 && (
              <Typography
                className="moreData"
                onClick={() => handleShowMoreModal("Outlets")}
              >
                + {outlets.count} More
              </Typography>
            )}
          </Grid>
        </Box>
      </Card>
      {filterOpenSales && <FilterModal status="Yet to Confirm" title={filterModalTitle} />}
    </Box>
  );
};

export default OrderBillingSidebar;
