import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import AddIcon from "@mui/icons-material/Add";
import RoutePlanForm from "./RoutePlanForm";
import Modal from "@mui/material/Modal";
import { Close, Padding } from "@mui/icons-material";
import RouteExcelExport from "./ExcelForm";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  code: number;
  smCode: string;
  smName: string;
  dov: string;
  routeCode: string;
  routeName: string;
}

const RouteCoveragePaln = () => {
  const [showForm, setShowForm] = React.useState(false);
  const [excelForm, setExcelForm] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState("manual");

  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    //search function logic here
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "code",
      title: "Distributor Branch Name",
    },

    {
      dataIndex: "smCode",
      title: "Salesman Code",
      sorter: (a, b) => a.smCode.length - b.smCode.length,
    },

    {
      dataIndex: "smName",
      title: "Salesman Name",
      sorter: (a, b) => a.smName.length - b.smName.length,
    },

    {
      dataIndex: "dov",
      title: "Date of Visit",
      sorter: (a, b) => a.dov.length - b.dov.length,
    },

    {
      dataIndex: "routeCode",
      title: "Route Code",
      sorter: (a, b) => a.routeCode.length - b.routeCode.length,
    },

    {
      dataIndex: "routeName",
      title: "Route Name",
      sorter: (a, b) => a.routeName.length - b.routeName.length,
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      code: 55,
      smCode: "001",
      smName: "Supplier1",
      dov: "Comapny1",
      routeCode: "Supplier1",
      routeName: "Comapny1",
    },
    {
      key: 2,
      code: 55445,
      smCode: "002",
      smName: "Supplier2",
      dov: "Comapny2",
      routeCode: "Supplier1",
      routeName: "Comapny1",
    },
    {
      key: 3,
      code: 5565,
      smCode: "003",
      smName: "Supplier3",
      dov: "Comapny3",
      routeCode: "Supplier1",
      routeName: "Comapny1",
    },
    {
      key: 4,
      code: 5548,
      smCode: "004",
      smName: "Supplier4",
      dov: "Comapny4",
      routeCode: "Supplier1",
      routeName: "Comapny1",
    },
    {
      key: 5,
      code: 55121,
      smCode: "005",
      smName: "Supplier5",
      dov: "Comapny5",
      routeCode: "Supplier1",
      routeName: "Comapny1",
    },
    {
      key: 6,
      code: 5565,
      smCode: "006",
      smName: "Supplier6",
      dov: "Comapny6",
      routeCode: "Supplier1",
      routeName: "Comapny1",
    },
    {
      key: 7,
      code: 55789,
      smCode: "007",
      smName: "Supplier7",
      dov: "Comapny7",
      routeCode: "Supplier1",
      routeName: "Comapny1",
    },
    {
      key: 8,
      code: 55656,
      smCode: "008",
      smName: "Supplier8",
      dov: "Comapny8",
      routeCode: "Supplier1",
      routeName: "Comapny1",
    },
  ];

  const handleCreateNew = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleGoChange = () => {
    if (selectedOption == "manual") {
      setIsModalOpen(!isModalOpen);
      setShowForm(true);
    } else if (selectedOption == "excel") {
      setIsModalOpen(!isModalOpen);
      setExcelForm(true);
    }
  };
  const handleShowClose = () => {
    setShowForm(false);
  };
  const handleExcelClose = () => {
    setExcelForm(false);
  };

  const RadiohandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className="pad-10 mar-tb">
          <Typography>
            <b>Route Coverage Plan</b>
          </Typography>
        </Grid>

        {showForm ? (
          <RoutePlanForm onBack={handleShowClose} />
        ) : excelForm ? (
          <RouteExcelExport onBack={handleExcelClose} />
        ) : (
          <Box>
            <Button
              variant="contained"
              className="btn-colr"
              onClick={handleCreateNew}
            >
              {" "}
              <AddIcon /> Create new
            </Button>
            <Grid item container sx={{ marginTop: "16px" }}>
              <Grid item xs={12} lg={6}>
                <Grid item sx={{ display: "flex", mt: 2 }}>
                  <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                    <InputLabel size="normal">State Selection </InputLabel>
                  </Grid>
                  <Grid item xs={4} sx={{ mr: 5 }}>
                    <TextField sx={{ width: 200 }} size="small"></TextField>
                  </Grid>
                  <Button variant="contained" className="btn-colr">
                    ...
                  </Button>
                </Grid>
                <Grid item sx={{ display: "flex", mt: 2 }}>
                  <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                    <InputLabel size="normal"> Distributor</InputLabel>
                  </Grid>
                  <Grid item xs={4} sx={{ mr: 5 }}>
                    <TextField sx={{ width: 200 }} size="small"></TextField>
                  </Grid>
                  <Button variant="contained" className="btn-colr">
                    ...
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ mt: 2 }}>
              <Box>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "end", mb: 2 }}
                >
                  <SearchBar
                    className="search-table"
                    value={textFieldValue}
                    onChange={(newValue) => {
                      setTextFieldValue(newValue);
                      handleSearch();
                    }}
                  />
                </Grid>
                <CommonTable
                  // rowSelection={rowSelection}
                  data={data}
                  columns={columns}
                />
              </Box>
            </Grid>
          </Box>
        )}
        <Modal
          open={isModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid>Choose Entry Mode</Grid>
            <Box sx={{ flex: "none", marginBottom: 1 }}>
              <IconButton
                aria-label="close"
                style={{ position: "absolute", top: 10, right: 10 }}
                onClick={handleCreateNew}
              >
                <Close />
              </IconButton>
            </Box>
            <Divider />
            <Grid item sx={{ mt: 2 }}>
              <RadioGroup
                row
                aria-label="mandatory"
                name="mandatory"
                value={selectedOption}
                onChange={RadiohandleChange}
              >
                <FormControlLabel
                  value="manual"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Manual Entry"
                />
                <FormControlLabel
                  value="excel"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Excel Entry"
                />
              </RadioGroup>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
              <Button
                variant="contained"
                className="btn-colr"
                onClick={handleGoChange}
              >
                Go
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default RouteCoveragePaln;
