import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import createAxiosInstance from "../../../hooks/useFetch";

// interface CountDataRequest {
//   distributorCode: string;
// }

interface NonSaleableData {
  values: string[];
}
interface NonSaleableProductRequest {
  productHierarchy: string;
  reason:string;
  // distributorCode: string;
  page: number;
  size: number;
}
interface Product {
  division: string;
  expiryDate: string;
  lastPurchaseDate: string;
  manufacturingDate: string;
  nearToexpiry: boolean;
  productCode: string;
  productHierarchy: string;
  productName: string;
  reason: string;
  scheme: boolean;
  stockInHand: string;
  value: string;
}

interface ProductDetails {
  batchCode: string;
  expiryDate: Date;
  mrp: number;
  productCode: string;
  stockInHand: number;
  stockValue: number;
}

interface SaleableTableData {
  filteredCount: number;
  products: Product[];
}

interface FilterDataRequest {
  // distributorCode: string;
  page: number;
  size: number;
  division: string;
  segment: string;
  brand: string;
  variant: string;
  subVariant: string;
  packagingType: string;
  productSize: string;
  search: string;
}
interface NonSaleExpandedData {
  productDescription: string;
  matGrp2Desc: string;
  productCode: string;
  batchCode: string;
  margin: string;
  mrp: string;
  division: string;
  expiryDate: string;
  stockInHand: number;
  stockValue: number;

}
interface NonSaleableTableProductDatas {
  productDescription: string;
  matGrp2Desc: string;
  productCode: string;
  stockInHand: string;
  stockValue: string;
  division: string;
  productDetails: NonSaleExpandedData[];
}

interface SaleableState {
  nonSaleableCounts: NonSaleableData[];
  nonsaleableCountsLoading: boolean;
  nonSaleableProducts: SaleableTableData;
  nonSaleableProductList: ProductDetails[];
  loading: boolean;
  nonsaleableProductsLeading:boolean;
  error: string | null;
  NonSaleableExtendedData: NonSaleableTableProductDatas[];
  NonSaleableExtendedDataLoading: boolean;
  NonSaleableExtendedDataError: string | null;
}

interface ProductDataRequest {
  productCode: string;
  // distributorCode: string;
  page: number;
  size: number;
}

const axiosInstance = createAxiosInstance();

export const getNonSaleableDashboardCounts = createAsyncThunk(
  "nonsaleable/dashboard_counts",
  async () => {
    try {
      const response = await axiosInstance.get(
        "inventory/get-saleable-product-dashboard-value"
      );
      return response.data.data;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getNonSaleableProducts = createAsyncThunk(
  "saleable/non_saleable_products",
  async (req: { params: FilterDataRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    try {
      const response = await axiosInstance.get(
        "inventory/get-non-saleable-stock-product",
        {
          params,
          signal,
        }
      );
      return response.data.data;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getNonSaleableProductsList = createAsyncThunk(
  "saleable/non_saleable_product_hierarchy",
  async (req: ProductDataRequest) => {
    const response = await axiosInstance.get(
      "inventory/get-non-saleable-product-list",
      {
        params: req,
      }
    );
    return response.data.data;
  }
);export const getNonSaleableExtendedDetails = createAsyncThunk(
  "saleable/get-Non-saleable-product",
  async (req: NonSaleableProductRequest) => {
    const response = await axiosInstance.get("inventory/get-non-saleable-stock-product-detail", {
      params: req,
    });
    return response.data.data;
  }
);

const initialState: SaleableState = {
  nonSaleableCounts: [],
  nonsaleableCountsLoading: false,
  nonSaleableProducts: { filteredCount: 0, products: [] },
  nonSaleableProductList: [],
  loading: false,
  nonsaleableProductsLeading: false,
  error: null,
  NonSaleableExtendedData: [],
  NonSaleableExtendedDataLoading: false,
  NonSaleableExtendedDataError: null,
};

const nonSaleableSlice = createSlice({
  name: "nonSaleable",
  initialState,
  reducers: {
    ProductFilterFetch: (
      state,
      action: PayloadAction<Partial<SaleableState>>
    ) => {
      state.nonSaleableCounts =
        action.payload.nonSaleableCounts || state.nonSaleableCounts;
      state.nonSaleableProducts =
        action.payload.nonSaleableProducts || state.nonSaleableProducts;
      state.nonSaleableProductList =
        action.payload.nonSaleableProductList || state.nonSaleableProductList;
    },
  },
  extraReducers: (builder) => {
    // non-Saleable Counts
    builder.addCase(getNonSaleableDashboardCounts.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.nonsaleableCountsLoading = true;
    });
    builder.addCase(
      getNonSaleableDashboardCounts.fulfilled,
      (state, action) => {
        state.nonSaleableCounts = action.payload ?? [];
        state.loading = false;
        state.error = null;
        state.nonsaleableCountsLoading = false;
      }
    );
    builder.addCase(getNonSaleableDashboardCounts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.nonsaleableCountsLoading = false;
    });

    // non-Saleable Products
    builder.addCase(getNonSaleableProducts.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.nonsaleableProductsLeading = true;
    });
    builder.addCase(getNonSaleableProducts.fulfilled, (state, action) => {
      state.nonSaleableProducts = action.payload ?? [];
      state.loading = false;
      state.error = null;
      state.nonsaleableProductsLeading = false;

    });
    builder.addCase(getNonSaleableProducts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.nonsaleableProductsLeading = false;

    });
    // Product List
    builder.addCase(getNonSaleableProductsList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getNonSaleableProductsList.fulfilled,
      (state, action: PayloadAction<ProductDetails[]>) => {
        state.nonSaleableProductList = action.payload ?? [];
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(getNonSaleableProductsList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getNonSaleableExtendedDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.NonSaleableExtendedData = [];
      state.NonSaleableExtendedDataLoading = true;
      state.NonSaleableExtendedDataError = null;
    });
    builder.addCase(
      getNonSaleableExtendedDetails.fulfilled,
      (state, action: PayloadAction<NonSaleableTableProductDatas[]>) => {
        state.NonSaleableExtendedData = action.payload ?? [];
        state.loading = false;
        state.error = null;
        state.NonSaleableExtendedDataLoading = false;
        state.NonSaleableExtendedDataError = null;
      }
    );
    builder.addCase(getNonSaleableExtendedDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.NonSaleableExtendedDataLoading = false;
      state.NonSaleableExtendedDataError = action.error.message ?? null;
    });

  },
});

export const { ProductFilterFetch } = nonSaleableSlice.actions;
export default nonSaleableSlice.reducer;
