import React, { useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  Button,
  Grid,
  InputLabel,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Card,
  Select,
  MenuItem,
  TextareaAutosize,
  Checkbox,
  Typography,
} from "@mui/material";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CommonTable from "../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  disprodcode: string;
  proname: string;
  batchcode: string;
  fromtype: string;
  totype: string;
  enqty: string;
  adqty: string;
  rate: string;
  amt: string;
  reason: string;
  action: string;
}

interface InventoryFormProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const InventoryForm: React.FC<InventoryFormProps> = ({ onBack }) => {
  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    //search function logic here
  };

  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };

  const Options = ["Opening Stock", "", ""];
  const Godown = ["a", "", ""];
  const Stock = ["Saleable", "", ""];
  const ProductSearch = ["Company Product Code", "", ""];
  const Inputtext = ["aa", "", ""];
  const StockTypeTo = ["Saleable", "", ""];
  const Reason = ["AAw", "", ""];



  
  
  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "disprodcode",
      title: "Distr Prod Code",
      sorter: (a, b) => a.disprodcode.length - b.disprodcode.length,
    },
    {
      dataIndex: "proname",
      title: "Product Name",
      sorter: (a, b) => a.proname.length - b.proname.length,
    },

    {
      dataIndex: "batchcode",
      title: "Batch Code",
      sorter: (a, b) => a.batchcode.length - b.batchcode.length,
    },

    {
      dataIndex: "fromtype",
      title: "From Type",
      sorter: (a, b) => a.fromtype.length - b.fromtype.length,
    },
    {
      dataIndex: "totype",
      title: "To Type",
      sorter: (a, b) => a.totype.length - b.totype.length,
    },
    {
      dataIndex: "enqty",
      title: "Entd.Qty",
      sorter: (a, b) => a.enqty.length - b.enqty.length,
    },
    {
      dataIndex: "adqty",
      title: "Adj.Qty",
      sorter: (a, b) => a.adqty.length - b.adqty.length,
    },
    {
      dataIndex: "rate",
      title: "Rate",
      sorter: (a, b) => a.rate.length - b.rate.length,
    },
    {
      dataIndex: "amt",
      title: "Amount",
      sorter: (a, b) => a.amt.length - b.amt.length,
    },
    {
      dataIndex: "reason",
      title: "Reason",
      sorter: (a, b) => a.reason.length - b.reason.length,
    },
    {
      dataIndex: "action",
      title: "Action ",
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      disprodcode: "AKSHAJ ENTERPRISES",
      proname: "1101090335",
      batchcode: "DELHI",
      fromtype: "9999999999",
      totype: "ABC",
      enqty: "1101090335",
      adqty: "DELHI",
      rate: "9999999999",
      amt: "ABC",
      reason: "ABC",
      action: "",
    },

  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Box className="card-form card-bg">
      <form>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
          <Grid item xs={12} sm={4} md={3}>
            <Typography className="fnt-lable">Distributor</Typography>
            <TextField variant="outlined" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={4} md={3} mt={3}>
            <Button className="btn-colr" sx={{ color: "#fff" }}>
              ...
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Typography className="fnt-lable">Date</Typography>
            <TextField variant="outlined" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Typography className="fnt-lable">Transaction Type</Typography>
            <FormControl fullWidth>
              <Select  className="selct-box">
                {Options.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Typography className="fnt-lable">Godown</Typography>
            <FormControl fullWidth>
              <Select className="selct-box" >
                {Godown.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Typography className="fnt-lable">User Ref.No</Typography>
            <TextField variant="outlined" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Typography className="fnt-lable">Remarks</Typography>
            <TextField variant="outlined" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Typography className="fnt-lable">Stock Type From</Typography>
            <FormControl fullWidth>
              <Select  className="selct-box">
                {Stock.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Typography className="fnt-lable">Product Search</Typography>
            <FormControl fullWidth>
              <Select  className="selct-box">
                {ProductSearch.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Typography className="fnt-lable">Input Text-Product</Typography>
            <FormControl fullWidth>
              <Select  className="selct-box" placeholder="Enter atleast 3 characters">
                {Inputtext.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Typography className="fnt-lable">Stock Type To</Typography>
            <FormControl fullWidth>
              <Select  className="selct-box" placeholder="Enter atleast 3 characters">
                {StockTypeTo.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Typography className="fnt-lable">Reason</Typography>
            <FormControl fullWidth>
              <Select  className="selct-box" placeholder="Enter atleast 3 characters">
                {Reason.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Typography className="fnt-lable">Quantity</Typography>
            <TextField variant="outlined" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Typography className="fnt-lable">Available</Typography>
            <TextField variant="outlined" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={4} md={3} mt={3}>
            <Button className="btn-colr" sx={{ color: "#fff" }}>
           Add
            </Button>
          </Grid>
        </Grid>

        
    

        <Box className="mar-tb">
          <Grid item>Route Coverage Pattern Grid</Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "end", mb: 2 }}
          >
            <SearchBar
              className="search-table"
              value={textFieldValue}
              onChange={(newValue) => {
                setTextFieldValue(newValue);
                handleSearch();
              }}
            />
          </Grid>
          <CommonTable
            // rowSelection={rowSelection}
            data={data}
            columns={columns}
          />
        </Box>

        <Grid item xs={12} mt={2} display="flex" justifyContent="center">
          <Button variant="outlined" color="primary" onClick={handleBackClick} className="button-lable">
            Cancel
          </Button>
          <Button
            variant="contained"
            className="btn-colr"
            style={{ marginLeft: "10px" }}
          >
            save
          </Button>
        </Grid>
      </form>
    </Box>
  );
};

export default InventoryForm;
