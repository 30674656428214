import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import UndoIcon from "@mui/icons-material/Undo";

import Close from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";




interface AddRetailersProps {
  handleClose: () => void; // A function that takes no arguments and returns void
}

const AddRetailers = ({
  handleClose,
}: AddRetailersProps) => {
  const options = ["Supplier Code", "Supplier Name"];

  const option = [
    "PYC1-Rohit India Private limited-KARAIKAL",
    "BRD1-CavinKare Pvt. Ltd, PAT DEPOT-PATNA",
    "MHT1-CavinKare Pvt. Ltd., MUM COTY-BHIWANDI",
  ];
  const rows = [
    {
      id: "GJD1",
      Name: "CavinKare Pvt. Ltd, AHM DEPOT",
      GeoMapping: "AHMEDABAD-1",
      IsDefault: "",
      action: "",
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Code",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "Name",
      headerName: "Name",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "GeoMapping",
      headerName: "Geo Mapping",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },

    {
      field: "IsDefault",
      headerName: "Is Default",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: () => (
        <>
          <FormGroup>
            <FormControlLabel
              label=""
              control={<Checkbox defaultChecked className="check-box" />}
            />
          </FormGroup>
        </>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: () => (
        <>
          <DeleteIcon
            style={{
              cursor: "pointer",
              marginRight: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />
        </>
      ),
    },
  ];



  const [isVisible, setIsVisible] = useState(false);
  const Visibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <Box>
      <form>
        <Grid
          item
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item>Add Levels</Grid>
          <IconButton aria-label="close" onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
        <Divider />

        <Grid
            container
            mt={3}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 1 }}
          >
            <Grid item xs={12} sm={12}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="Disc. %"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                            color: "#6750A4",
                          },
                        }}
                      />
                    }
                    label="Disc. %"
                  />
                  <FormControlLabel
                    value=" Flat Amount"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                            color: "#6750A4",
                          },
                        }}
                      />
                    }
                    label=" Flat Amount"
                  />
                  <FormControlLabel
                    value="Selling Rate"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                            color: "#6750A4",
                          },
                        }}
                      />
                    }
                    label="Selling Rate"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            mt={3}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 1 }}
          >
            <Grid item sm={12} sx={{ display: "flex" }}>
            <Typography sx={{mr:2,mt:1}}> Selling Rate</Typography>

              <TextField placeholder="  " className="Txt-box" />
              <Grid item ml={2}>
                <FormControlLabel
                  control={<Checkbox defaultChecked className="check-box" />}
                  label="On Mrp"
                />
              </Grid>
            </Grid>
          
          </Grid>
          <Grid
            container
            mt={3}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 1 }}
          >
            <Grid item sm={12} sx={{ display: "flex" }}>
            <Typography sx={{mr:2,mt:1}}>Flat Amount</Typography>

              <TextField placeholder="  " className="Txt-box" />
             
            </Grid>
          
          </Grid>
          <Grid
            container
            mt={3}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 1 }}
          >
            <Grid item sm={12} sx={{ display: "flex" }}>
                <Typography sx={{mr:2,mt:1}}> Selling Rate</Typography>
           
              <TextField placeholder="  " className="Txt-box" />
              <Grid item ml={2}>
                <FormControlLabel
                  control={<Checkbox defaultChecked className="check-box" />}
                  label="Post Tax"
                />
              </Grid>
            </Grid>
            <Grid item sm={6} sx={{ display: "flex" }}>
              <Grid item sm={2} mt={1} mr={2}>
              </Grid>
             
            </Grid>
          </Grid>
        
       
        {isVisible && (
          <Grid item mt={2}>
            <Grid container columnGap={2}>
              <Grid item style={{ height: "100%", width: "100%" }}>
              
              </Grid>
            </Grid>
          </Grid>
         )}
        <Grid item mt={3} sx={{ display: "flex", justifyContent: "center" }}>
          <Button className="btn-colr " variant="contained" sx={{ mr: "10px" }}>
            Ok
          </Button>
          <Button
            className="next-button"
            variant="outlined"
            onClick={handleClose}
          >
            <UndoIcon
              sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
            />
            Cancle
          </Button>
        </Grid>
      </form>
    </Box>
  );
};
export default AddRetailers;
