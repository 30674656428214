import React from "react";
import {
  Button,
  Grid,
  TextField,
  Box,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  InputLabel,
  Typography,
  Card,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Modal,
} from "@mui/material";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import UndoIcon from "@mui/icons-material/Undo";
import SaveIcon from "@mui/icons-material/Save";
import AddLevelsPopup from "./AddLevelsModel";
import AddRetailers from "./AddRetailers";
// import ProductInfoView from "./ProductInfo";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

interface DataType {
  key: React.Key;
  ReferenceNo: string;
}
interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const PriceProductInfo: React.FC<DistributorTabProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const handleclick = (event: React.MouseEvent<unknown>) => {
    onBack(event);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const Level = ["CKL - CAVINKARE PRIVATE LIMITED", ""];
  return (
    <>
      <Box>
        <form noValidate>
          <Grid
            container
            mt={3}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 1 }}
          >
            <Grid item xs={12} sm={6}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="Disc. %"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                            color: "#6750A4",
                          },
                        }}
                      />
                    }
                    label="Disc. %"
                  />
                  <FormControlLabel
                    value=" Flat Amount"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                            color: "#6750A4",
                          },
                        }}
                      />
                    }
                    label=" Flat Amount"
                  />
                  <FormControlLabel
                    value="Selling Rate"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                            color: "#6750A4",
                          },
                        }}
                      />
                    }
                    label="Selling Rate"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            mt={3}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 1 }}
          >
            <Grid item sm={6} sx={{ display: "flex" }}>
              <TextField placeholder="  " className="Txt-box" />
              <Grid item ml={2}>
                <FormControlLabel
                  control={<Checkbox defaultChecked className="check-box" />}
                  label="On Mrp"
                />
              </Grid>
            </Grid>
            <Grid item sm={6} sx={{ display: "flex" }}>
              <Grid item sm={2} mt={1} mr={2}>
                <Typography className="fnt-lable"> Company</Typography>
              </Grid>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  className="selct-box"
                >
                  {Level.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            mt={3}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 1 }}
          >
            <Grid item sm={6} sx={{ display: "flex" }}>
              <TextField placeholder="  " className="Txt-box" />
            </Grid>
            <Grid item sm={6} sx={{ display: "flex" }}>
              <Grid item mt={1} sm={2}>
                <Typography className="fnt-lable"> Valid From</Typography>
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker sx={{ width: "108%" }} />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            mt={3}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 1 }}
          >
            <Grid item sm={6} sx={{ display: "flex" }}>
              <TextField placeholder="  " className="Txt-box" />
              <Grid item ml={2}>
                <FormControlLabel
                  control={<Checkbox defaultChecked className="check-box" />}
                  label="Post Tax"
                />
              </Grid>
            </Grid>
            <Grid item sm={6} sx={{ display: "flex" }}>
              <Grid item mt={1} sm={2}>
                <Typography className="fnt-lable">Valid Till</Typography>
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker sx={{ width: "108%" }} />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            mt={3}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 1 }}
          >
            <Grid item>
              <Typography>Status</Typography>
              <Grid item>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Yes"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              

            </Grid>
            <Grid item mt={2}>
                <Button className="btn-colr " 
                style={{color:'#fff'}}
                  onClick={handleOpen}
                  >Add Levels</Button>
              </Grid>
              <Grid item>
                  <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <AddLevelsPopup handleClose={handleClose} />
                    </Box>
                  </Modal>
                </Grid>
                <Grid item mt={2}>
                <Button className="btn-colr " 
                style={{color:'#fff'}}
                onClick={handleOpen}

                  >Add Retailers</Button>
              </Grid>
              <Grid item>
                  <Modal
                    open={open}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <AddRetailers handleClose={handleClose} />
                    </Box>
                  </Modal>
                </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", mt: 4 }}
          >
            <Grid item sx={{ mr: 2 }}>
              <Button variant="outlined" onClick={handleclick}>
                <UndoIcon
                  sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                />
                Back
              </Button>
            </Grid>
            <Grid item sx={{ mr: 2 }}>
              <Button className="btn-colr " variant="contained">
                <SaveIcon
                  sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                />
                Save
              </Button>
            </Grid>
            <Grid item sx={{ mr: 2 }}>
              <Button variant="outlined" onClick={handleBackClick}>
                <CancelIcon
                  sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                />{" "}
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};
export default PriceProductInfo;
