import React from "react";
import {
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../../components/Modal/DeleteSupplier";

import CancelIcon from "@mui/icons-material/Cancel";

import SaveIcon from "@mui/icons-material/Save";
import CommonTable from "../../../components/Tables/CommonTable";
import { TableColumnsType } from "antd";
import SearchIcon from '@mui/icons-material/Search';


interface DataType {
  key: React.Key;
  ProductCode: string;
  ProductName: string;
  IGST: string;
  CGST:string;
}


interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const CreateGSTProduct: React.FC<DistributorTabProps> = ({ onBack }) => {



  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [showTable, setShowTable] = React.useState(false);



  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const Product  = ["Company", "Business Level Name", "Category Level"];
  const  ProductName  = ["Product Name", "Product Code", ];

 
  const Company  = [
    "CAVINKARE PRIVATE LIMITED","",""
  ];
  const state = [
    "Delhi",
    "Bihar",
    " Assam",
    "Gujarat"
  ];
  const IGST = [
    "IGST1 - 5.0%",
    "IGST2 - 18.0%",
    " O0 - 0.0%",
    "OB - 5.0%"
  ];
  const CGST = [
    "CGST1 - 5.0%",
    "CGST1 - 3.0%",
   
  ];



  const [isDelete, setIsDelete] = React.useState(false);

  const handleDeleteClick = (event: React.MouseEvent<unknown>) => {
    setIsDelete(!isDelete);
  };

  const handleShowTable = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setShowTable(!showTable);
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "ProductCode",
      title: "Product Code",
    //   sorter: (a, b) => a.code.length - b.code.length,
    },
    {
      dataIndex: "ProductName",
      title: "Product Name",
    //   sorter: (a, b) => a.ProductName.length - b.ProductName.length,
    },
    {
        dataIndex: "IGST",
        title: "IGST ",
      //   sorter: (a, b) => a.IGST.length - b.IGST.length,
      },
      {
        dataIndex: "CGST",
        title: "CGST ",
      //   sorter: (a, b) => a.CGST.length - b.CGST.length,
      },

 
  ];


  const data: DataType[] = [
    {
      key: 1,
      ProductCode: "ZZRADYNICRBRO",
      ProductName: "MAIN ROAD",
      IGST:'IGST - 0%',
      CGST:"CGST - 0%"
    },
    {
      key: 2,
      ProductCode: "ZZRADYNICRBRO",
      ProductName: "MAIN ROAD",
      IGST:'IGST - 0%',
      CGST:"CGST - 0%"

    },
    {
        key: 3,
        ProductCode: "ZZRAGEMPLTECD",
        ProductName: "MAIN ROAD",
        IGST:'IGST - 0%',
      CGST:"CGST - 0%"

      },
      {
        key: 5,
        ProductCode: "ZZRAGIKCOMST",
        ProductName: "MAIN ROAD",
        IGST:'IGST - 0%',
      CGST:"CGST - 0%"

      },
      {
        key: 2,
        ProductCode: "ZZIONHDEVL",
        ProductName: "MAIN ROAD",
        IGST:'IGST - 0%',
      CGST:"CGST - 0%"

      },
  ];
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
};
 
  return (
    <>
      <Box className="card-form">
        <form noValidate>
        <Grid item className="fnt-lable">
            Applicable Products
            </Grid>
          <Grid container rowSpacing={2} mt={1}  columnSpacing={{ xs: 2, sm: 3 }}>
            
            <Grid item xs={12} sm={3}>
              <Typography className="fnt-lable" >Company</Typography>
              <FormControl fullWidth>
                <Select className="selct-box">
                  {Company.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography className="fnt-lable">Product Hierarchy Level  </Typography>
              <FormControl fullWidth>
                <Select className="selct-box">
                  {Product .map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>{" "}
            <Grid item xs={12} sm={3} >
              <Typography className="fnt-lable">Attach Routes </Typography>
            <Grid item  sx={{display:'flex'}}>

              <TextField variant="outlined"  className="Txt-box"/>
           <Grid>   <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
                ...
              </Button></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography className="fnt-lable">Attach Routes </Typography>
              <Grid item sx={{display:'flex'}}>
              <TextField variant="outlined"  className="Txt-box"/>
              <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
                ...
              </Button>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Button sx={{ ml: 1, mt: 2, color: "#fff" }} className="btn-colr">
                + Add Route
              </Button>
            </Grid> */}
           
          </Grid>
          <Grid item className="fnt-lable"  mt={3}>
            Applicable Products
            </Grid>
          <Grid container rowSpacing={2}  mt={1} columnSpacing={{ xs: 2, sm:3 }}>
            <Grid item xs={12} sm={3}>
              <Typography className="fnt-lable" >State</Typography>
              <FormControl fullWidth>
                <Select className="selct-box">
                  {state.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography className="fnt-lable">IGST </Typography>
              <FormControl fullWidth>
                <Select className="selct-box">
                  {IGST.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>{" "}
            <Grid item xs={12} sm={3}>
              <Typography className="fnt-lable">CGST </Typography>
              <FormControl fullWidth>
                <Select className="selct-box">
                  {CGST.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
        
            <Typography className="fnt-lable"> LOB Value </Typography>
            <TextField placeholder="  " className="Txt-box" />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Button sx={{ ml: 1, mt: 2, color: "#fff" }} className="btn-colr">
                + Add Route
              </Button>
            </Grid> */}
           
          </Grid>


          <Grid
            container
            mt={1}
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 5 }}
          >
            <Grid item xs={12} sm={3}>
      
            <Button sx={{ color: "#fff" }} className="btn-colr" 
             onClick={handleShowTable}
             >
               Populate SKU
            </Button>
          </Grid>
          

          </Grid>
        
          {showTable &&
              <Box className='mar-tb'>
               <Grid item>
               <Grid container rowSpacing={2} mt={3}  columnSpacing={{ xs: 2, sm: 10 }}>
            
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <Select className="selct-box">
                  {ProductName.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          
            <Grid item xs={12} sm={4}>
              <Grid item sx={{display:'flex'}}>
              <TextField variant="outlined"  className="Txt-box"/> <Button sx={{ color: "#fff", ml: 3 }} className="btn-colr" 
             onClick={handleShowTable}
             >
            <SearchIcon className='fn-icon' /> Go
            </Button>
            </Grid>
            </Grid>
           
        
          </Grid>
               </Grid>
               <Grid item mt={3}>
                <CommonTable 
                // rowSelection={rowSelection}
                 data={data} columns={columns} />
                </Grid>
                <Grid item xs={12} mt={4} display="flex" justifyContent="center">
            <Button className="btn-colr " variant="contained">
              <SaveIcon sx={{ color: "", fontSize: "15px" }} />
              Save
            </Button>
            <Grid item sx={{ ml: 2 }}>
              <Button variant="outlined" onClick={handleBackClick}>
                <CancelIcon sx={{ color: "", fontSize: "15px" }} /> Cancel
              </Button>
            </Grid>
          </Grid>
              </Box>
              
            }
       
    


          
      


        
        </form>
      </Box>
    </>
  );
};

export default CreateGSTProduct;
