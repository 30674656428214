import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import { sidebarFilterInv } from "../../../Redux/AuthLogin/action";
import FilterModal from "./FilterModal";
import {
  getBrand,
  getDivision,
  getPackagingType,
  getSegment,
  getSize,
  getSubVariant,
  getVariant,
  updateBrand,
  updateBrandPop,
  updateBrandTemp,
  updateDivision,
  updateDivisionPop,
  updateDivisionTemp,
  updatePackagingType,
  updatePackagingTypePop,
  updatePackagingTypeTemp,
  updateReason,
  updateSegment,
  updateSegmentPop,
  updateSegmentTemp,
  updateSize,
  updateSizePop,
  updateSizeTemp,
  updateSubVariant,
  updateSubVariantPop,
  updateSubVariantTemp,
  updateVariant,
  updateVariantPop,
  updateVariantTemp,
} from "../../../Redux/XDM/Inventory/filterReducer";
import { IoMdClose } from "react-icons/io";
import { decodeJwt } from "../../../utils/constant";

const NonSaleableSidebar = () => {
  const division = useAppSelector((state) => state.filterInv.division);
  const segment = useAppSelector((state) => state.filterInv.segment);
  const brand = useAppSelector((state) => state.filterInv.brand);
  const variant = useAppSelector((state) => state.filterInv.variant);
  const subVariant = useAppSelector((state) => state.filterInv.subVariant);
  const packagingType = useAppSelector(
    (state) => state.filterInv.packagingType
  );
  const size = useAppSelector((state) => state.filterInv.size);

  const [showMore, setShowMore] = useState(false);
  const showMoreFilter = () => {
    setShowMore(!showMore);
    if (!subVariant.values.length) {
      dispatch(getSubVariant(filterData));
      dispatch(getPackagingType(filterData));
      dispatch(getSize(filterData));
    }
  };

  const dispatch = useAppDispatch();
  const [filterModalTitle, setFilterModalTitle] = useState("");

  const filterOpenInv = useAppSelector(
    (state) => state.showMorePopup.filterOpenInv
  );

  let tempData: any[] = [];

  const fetch = useAppSelector((state) => state.filterInv);

  const handleShowMoreModal = (title: any) => {
    setFilterModalTitle(title);
    dispatch(sidebarFilterInv(true));
    switch (title) {
      case "Division":
        tempData = fetch.divisionSelected;
        dispatch(updateDivisionTemp(tempData));
        break;
      case "Segment":
        tempData = fetch.segmentSelected;
        dispatch(updateSegmentTemp(tempData));
        break;
      case "Brand":
        tempData = fetch.brandSelected;
        dispatch(updateBrandTemp(tempData));
        break;
      case "Variant":
        tempData = fetch.variantSelected;
        dispatch(updateVariantTemp(tempData));
        break;
      case "Sub Variant":
        tempData = fetch.subVariantSelected;
        dispatch(updateSubVariantTemp(tempData));
        break;
      case "Packaging Type":
        tempData = fetch.packagingTypeSelected;
        dispatch(updatePackagingTypeTemp(tempData));
        break;
      case "Size":
        tempData = fetch.sizeSelected;
        dispatch(updateSizeTemp(tempData));
        break;
      default:
    }
  };

  let token = localStorage.getItem("token");
  let decoded = decodeJwt(token ?? "");
  let distributor = decoded.Distributor ?? "";

  const filterData = {
    // distributorCode: distributor,
    filterName: "division",
    page: 0,
    size: 4,
    pageStatus: true,
    type: "non-sale",
  };

  const filterDataS = {
    // distributorCode: distributor,
    page: 0,
    size: 10,
    pageStatus: false,
    type: "non-saleable",
    stock: fetch.basedOnStockSelected.toString(),
    reason:fetch.reasonSelected.toString(),
    division: fetch.divisionSelected.toString(),
    segment: fetch.segmentSelected.toString(),
    brand: fetch.brandSelected.toString(),
    variant: fetch.variantSelected.toString(),
    subVariant: fetch.subVariantSelected.toString(),
    packagingType: fetch.packagingTypeSelected.toString(),
    productSize: fetch.sizeSelected.toString(),
    search: "",
  };

  useEffect(() => {
    dispatch(getDivision(filterData));
    dispatch(getSegment(filterData));
    dispatch(getBrand(filterData));
    dispatch(getVariant(filterData));
  }, []);

  const divisionSelected = useAppSelector(
    (state) => state.filterInv.divisionSelected
  );
  const segmentSelected = useAppSelector(
    (state) => state.filterInv.segmentSelected
  );
  const brandSelected = useAppSelector(
    (state) => state.filterInv.brandSelected
  );
  const variantSelected = useAppSelector(
    (state) => state.filterInv.variantSelected
  );
  const subVariantSelected = useAppSelector(
    (state) => state.filterInv.subVariantSelected
  );
  const packagingTypeSelected = useAppSelector(
    (state) => state.filterInv.packagingTypeSelected
  );
  const sizeSelected = useAppSelector((state) => state.filterInv.sizeSelected);

  const divisionSelectedPop = useAppSelector(
    (state) => state.filterInv.popDivisionSelected
  );
  const segmentSelectedPop = useAppSelector(
    (state) => state.filterInv.popSegmentSelected
  );
  const brandSelectedPop = useAppSelector(
    (state) => state.filterInv.popBrandSelected
  );
  const variantSelectedPop = useAppSelector(
    (state) => state.filterInv.popVariantSelected
  );
  const subVariantSelectedPop = useAppSelector(
    (state) => state.filterInv.popSubVariantSelected
  );
  const packagingTypeSelectedPop = useAppSelector(
    (state) => state.filterInv.popPackagingTypeSelected
  );
  const sizeSelectedPop = useAppSelector(
    (state) => state.filterInv.popSizeSelected
  );
   const reasonSelected = useAppSelector(
    (state) => state.filterInv.reasonSelected
  );
 const reason = {
    values: ["Damaged", "Expired"],
  };
  const divisionSelect = (value: string) => {
    const isSelected = divisionSelected.includes(value);
    const updatedDivisionSelected = isSelected
      ? divisionSelected.filter((item) => item !== value)
      : [...divisionSelected, value];
    dispatch(updateDivision(updatedDivisionSelected));
    dispatch(updateDivisionPop(updatedDivisionSelected));
    dispatch(updateDivisionTemp(updatedDivisionSelected));
  };

  const segmentSelect = (value: string) => {
    const isSelected = segmentSelected.includes(value);
    const updatedSegmentSelected = isSelected
      ? segmentSelected.filter((item) => item !== value)
      : [...segmentSelected, value];
    dispatch(updateSegment(updatedSegmentSelected));
    dispatch(updateSegmentPop(updatedSegmentSelected));
    dispatch(updateSegmentTemp(updatedSegmentSelected));
  };
  const reasonSelect = (value: string) => {
    const isSelected = reasonSelected.includes(value);
    const updatedreasonSelected = isSelected
      ? reasonSelected.filter((item) => item !== value)
      : [...reasonSelected, value];
    dispatch(updateReason(updatedreasonSelected));
  };
  const brandSelect = (value: string) => {
    const isSelected = brandSelected.includes(value);
    const updatedBrandSelected = isSelected
      ? brandSelected.filter((item) => item !== value)
      : [...brandSelected, value];
    dispatch(updateBrand(updatedBrandSelected));
    dispatch(updateBrandPop(updatedBrandSelected));
    dispatch(updateBrandTemp(updatedBrandSelected));
  };

  const variantSelect = (value: string) => {
    const isSelected = variantSelected.includes(value);
    const updatedVariantSelected = isSelected
      ? variantSelected.filter((item) => item !== value)
      : [...variantSelected, value];
    dispatch(updateVariant(updatedVariantSelected));
    dispatch(updateVariantPop(updatedVariantSelected));
    dispatch(updateVariantTemp(updatedVariantSelected));
  };

  const subVariantSelect = (value: string) => {
    const isSelected = subVariantSelected.includes(value);
    const updatedSubVariantSelected = isSelected
      ? subVariantSelected.filter((item) => item !== value)
      : [...subVariantSelected, value];
    dispatch(updateSubVariant(updatedSubVariantSelected));
    dispatch(updateSubVariantPop(updatedSubVariantSelected));
    dispatch(updateSubVariantTemp(updatedSubVariantSelected));
  };

  const packagingTypeSelect = (value: string) => {
    const isSelected = packagingTypeSelected.includes(value);
    const updatedPackagingTypeSelected = isSelected
      ? packagingTypeSelected.filter((item) => item !== value)
      : [...packagingTypeSelected, value];
    dispatch(updatePackagingType(updatedPackagingTypeSelected));
    dispatch(updatePackagingTypePop(updatedPackagingTypeSelected));
    dispatch(updatePackagingTypeTemp(updatedPackagingTypeSelected));
  };

  const sizeSelect = (value: string) => {
    const isSelected = sizeSelected.includes(value);
    const updatedSizeSelected = isSelected
      ? sizeSelected.filter((item) => item !== value)
      : [...sizeSelected, value];
    dispatch(updateSize(updatedSizeSelected));
    dispatch(updateSizePop(updatedSizeSelected));
    dispatch(updateSizeTemp(updatedSizeSelected));
  };

  const clearAll = (title: any) => {
    switch (title) {
      case "Division":
        dispatch(updateDivision([]));
        dispatch(updateDivisionPop([]));
        dispatch(updateDivisionTemp([]));
        filterDataS.division = "";
        break;
      case "Reason":
        dispatch(updateReason([]));
        filterDataS.reason = "";
        break;
      case "Segment":
        dispatch(updateSegment([]));
        dispatch(updateSegmentPop([]));
        dispatch(updateSegmentTemp([]));
        filterDataS.segment = "";
        break;
      case "Brand":
        dispatch(updateBrand([]));
        dispatch(updateBrandPop([]));
        dispatch(updateBrandTemp([]));
        filterDataS.brand = "";
        break;
      case "Variant":
        dispatch(updateVariant([]));
        dispatch(updateVariantPop([]));
        dispatch(updateVariantTemp([]));
        filterDataS.variant = "";
        break;
      case "Sub Variant":
        dispatch(updateSubVariant([]));
        dispatch(updateSubVariantPop([]));
        dispatch(updateSubVariantTemp([]));
        filterDataS.subVariant = "";
        break;
      case "Packaging Type":
        dispatch(updatePackagingType([]));
        dispatch(updatePackagingTypePop([]));
        dispatch(updatePackagingTypeTemp([]));
        filterDataS.packagingType = "";
        break;
      case "Size":
        dispatch(updateSize([]));
        dispatch(updateSizePop([]));
        dispatch(updateSizeTemp([]));
        filterDataS.productSize = "";
        break;
      default:
    }
  };

  return (
    <Box
      sx={{
        padding: "0px 5px 0px 16px",
        marginBottom: "100px",
        marginRight: "4.3px",
        marginTop: "4px",
      }}
      className="dis-flex scrollHide"
    >
       <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700", }}>
            Reason
          </Typography>
            <Typography
              sx={{
                fontSize: "9px",
                fontWeight: "500",
                mb: 1,
                color: "var(--grey-grey500, #505259)",
              }}
            >
              {reasonSelected.length > 0 &&
                "(" + reasonSelected.length + " Selected)"}
            </Typography>
        </Box>
        <Divider />
        <FormControl className="" sx={{ mt: 1 }}>
          {Array.isArray(reason.values) &&
            reason.values.map((values: any, index: number) => (
              <FormControlLabel
                key={index}
                value={values}
                control={
                  <Checkbox
                    onChange={() => reasonSelect(values)}
                    checked={reasonSelected.includes(values)}
                    className="sidebar_checkbox"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 15,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label={
                  <div
                    title={values}
                    style={{ paddingTop: "2px", paddingLeft: "5px" }}
                    className="filter_lable"
                  >
                    {values}
                  </div>
                }
              />
            ))}
        </FormControl>

        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {reasonSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              sx={{
                color: "#F5222D",
                fontSize: "10px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              }}
              className="cursor_pointer"
              onClick={() => clearAll("Reason")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}></Grid>
        </Box>
      </Card>
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700", }}>
            Division
          </Typography>
          <Typography
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              mb: 1,
              color: "var(--grey-grey500, #505259)",
            }}
          >
            {divisionSelectedPop.length > 0 &&
              "(" + divisionSelectedPop.length + " Selected)"}
          </Typography>
        </Box>
        <Divider />
        <FormControl className="w-100px" sx={{ mt: 1 }}>
          {fetch.loading
            ? // Render skeletons while loading
            Array.from(new Array(4)).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={15}
                sx={{ mb: 1 }}
              />
            ))
            : Array.isArray(division.values) &&
            division.values.map((values: any, index: number) => (
              <FormControlLabel
                key={index}
                value={values}
                control={
                  <Checkbox
                    onChange={() => divisionSelect(values)}
                    checked={divisionSelected.includes(values)}
                    className="sidebar_checkbox"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 15,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label={
                  <div
                    title={values}
                    style={{ paddingTop: "2px", paddingLeft: "5px" }}
                    className="filter_lable"
                  >
                    {values}
                  </div>
                }
              />
            ))}
        </FormControl>
        <Box alignItems="center" className="filterFooter">
          {divisionSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              className="clearAll"
              onClick={() => clearAll("Division")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}>
            {division.count > 0 && (
              <Typography
                className="moreData"
                onClick={() => handleShowMoreModal("Division")}
              >
                + {division.count} More
              </Typography>
            )}
          </Grid>
        </Box>
      </Card>
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700", }}>
            Segment
          </Typography>
          <Typography
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              mb: 1,
              color: "var(--grey-grey500, #505259)",
            }}
          >
            {segmentSelectedPop.length > 0 &&
              "(" + segmentSelectedPop.length + " Selected)"}
          </Typography>
        </Box>
        <Divider />
        <FormControl className="w-100px" sx={{ mt: 1 }}>
          {fetch.loading
            ? // Render skeletons while loading
            Array.from(new Array(4)).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={15}
                sx={{ mb: 1 }}
              />
            ))
            : Array.isArray(segment.values) &&
            segment.values.map((values: any, index: number) => (
              <FormControlLabel
                key={index}
                value={values}
                control={
                  <Checkbox
                    onChange={() => segmentSelect(values)}
                    checked={segmentSelected.includes(values)}
                    className="sidebar_checkbox"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 15,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label={
                  <div
                    title={values}
                    style={{ paddingTop: "2px", paddingLeft: "5px" }}
                    className="filter_lable"
                  >
                    {values}
                  </div>
                }
              />
            ))}
        </FormControl>
        <Box alignItems="center" className="filterFooter">
          {segmentSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              className="clearAll"
              onClick={() => clearAll("Segment")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}>
            {segment.count > 0 && (
              <Typography
                className="moreData"
                onClick={() => handleShowMoreModal("Segment")}
              >
                + {segment.count} More
              </Typography>
            )}
          </Grid>
        </Box>
      </Card>
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700", }}>
            Brand
          </Typography>
          <Typography
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              mb: 1,
              color: "var(--grey-grey500, #505259)",
            }}
          >
            {brandSelectedPop.length > 0 &&
              "(" + brandSelectedPop.length + " Selected)"}
          </Typography>
        </Box>
        <Divider />
        <FormControl className="w-100px" sx={{ mt: 1 }}>
          {fetch.loading
            ? // Render skeletons while loading
            Array.from(new Array(4)).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={15}
                sx={{ mb: 1 }}
              />
            ))
            : Array.isArray(brand.values) &&
            brand.values.map((values: any, index: number) => (
              <FormControlLabel
                key={index}
                value={values}
                control={
                  <Checkbox
                    onChange={() => brandSelect(values)}
                    checked={brandSelected.includes(values)}
                    className="sidebar_checkbox"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 15,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label={
                  <div
                    title={values}
                    style={{ paddingTop: "2px", paddingLeft: "5px" }}
                    className="filter_lable"
                  >
                    {values}
                  </div>
                }
              />
            ))}
        </FormControl>
        <Box alignItems="center" className="filterFooter">
          {brandSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              className="clearAll"
              onClick={() => clearAll("Brand")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}>
            {brand.count > 0 && (
              <Typography
                className="moreData"
                onClick={() => handleShowMoreModal("Brand")}
              >
                + {brand.count} More
              </Typography>
            )}
          </Grid>
        </Box>
      </Card>
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700", }}>
            Variant
          </Typography>
          <Typography
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              mb: 1,
              color: "var(--grey-grey500, #505259)",
            }}
          >
            {variantSelectedPop.length > 0 &&
              "(" + variantSelectedPop.length + " Selected)"}
          </Typography>
        </Box>
        <Divider />
        <FormControl className="w-100px" sx={{ mt: 1 }}>
          {fetch.loading
            ? // Render skeletons while loading
            Array.from(new Array(4)).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                height={15}
                sx={{ mb: 1 }}
              />
            ))
            : Array.isArray(variant.values) &&
            variant.values.map((values: any, index: number) => (
              <FormControlLabel
                key={index}
                value={values}
                control={
                  <Checkbox
                    onChange={() => variantSelect(values)}
                    checked={variantSelected.includes(values)}
                    className="sidebar_checkbox"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 15,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label={
                  <div
                    title={values}
                    style={{ paddingTop: "2px", paddingLeft: "5px" }}
                    className="filter_lable"
                  >
                    {values}
                  </div>
                }
              />
            ))}
        </FormControl>
        <Box alignItems="center" className="filterFooter">
          {variantSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              className="clearAll"
              onClick={() => clearAll("Variant")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}>
            {variant.count > 0 && (
              <Typography
                className="moreData"
                onClick={() => handleShowMoreModal("Variant")}
              >
                + {variant.count} More
              </Typography>
            )}
          </Grid>
        </Box>
      </Card>
      <Typography className="cursor_pointer showMore" onClick={showMoreFilter}>
        {showMore ? "Hide More Filters" : "Show More Filter"}
      </Typography>
      {showMore && (
        <>
          <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
            <Box
              alignItems="center"
              sx={{
                padding: "5px 0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "10px", fontWeight: "700", }}>
                Sub Variant
              </Typography>
              <Typography
                sx={{
                  fontSize: "9px",
                  fontWeight: "500",
                  mb: 1,
                  color: "var(--grey-grey500, #505259)",
                }}
              >
                {subVariantSelectedPop.length > 0 &&
                  "(" + subVariantSelectedPop.length + " Selected)"}
              </Typography>
            </Box>
            <Divider />
            <FormControl className="w-100px" sx={{ mt: 1 }}>
              {fetch.showMoreLoading
                ? // Render skeletons while loading
                Array.from(new Array(4)).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    height={15}
                    sx={{ mb: 1 }}
                  />
                ))
                : Array.isArray(subVariant.values) &&
                subVariant.values.map((values: any, index: number) => (
                  <FormControlLabel
                    key={index}
                    value={values}
                    control={
                      <Checkbox
                        onChange={() => subVariantSelect(values)}
                        checked={subVariantSelected.includes(values)}
                        className="sidebar_checkbox"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 15,
                            color: "#6750A4",
                          },
                        }}
                      />
                    }
                    label={
                      <div
                        title={values}
                        style={{ paddingTop: "2px", paddingLeft: "5px" }}
                        className="filter_lable"
                      >
                        {values}
                      </div>
                    }
                  />
                ))}
            </FormControl>
            <Box alignItems="center" className="filterFooter">
              {subVariantSelected.length > 0 ? (
                <Grid
                  item
                  xs={8}
                  className="clearAll"
                  onClick={() => clearAll("Sub Variant")}
                >
                  <IoMdClose size={7} />
                  &nbsp; Clear all
                </Grid>
              ) : (
                <Grid item xs={8}></Grid>
              )}
              <Grid item xs={4}>
                {subVariant.count > 0 && (
                  <Typography
                    className="moreData"
                    onClick={() => handleShowMoreModal("Sub Variant")}
                  >
                    + {subVariant.count} More
                  </Typography>
                )}
              </Grid>
            </Box>
          </Card>
          <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
            <Box
              alignItems="center"
              sx={{
                padding: "5px 0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "10px", fontWeight: "700", }}>
                Packaging Type
              </Typography>
              <Typography
                sx={{
                  fontSize: "9px",
                  fontWeight: "500",
                  mb: 1,
                  color: "var(--grey-grey500, #505259)",
                }}
              >
                {packagingTypeSelectedPop.length > 0 &&
                  "(" + packagingTypeSelectedPop.length + " Selected)"}
              </Typography>
            </Box>
            <Divider />
            <FormControl className="w-100px" sx={{ mt: 1 }}>
              {fetch.showMoreLoading
                ? // Render skeletons while loading
                Array.from(new Array(4)).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    height={15}
                    sx={{ mb: 1 }}
                  />
                ))
                : Array.isArray(packagingType.values) &&
                packagingType.values.map((values: any, index: number) => (
                  <FormControlLabel
                    key={index}
                    value={values}
                    control={
                      <Checkbox
                        onChange={() => packagingTypeSelect(values)}
                        checked={packagingTypeSelected.includes(values)}
                        className="sidebar_checkbox"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 15,
                            color: "#6750A4",
                          },
                        }}
                      />
                    }
                    label={
                      <div
                        title={values}
                        style={{ paddingTop: "2px", paddingLeft: "5px" }}
                        className="filter_lable"
                      >
                        {values}
                      </div>
                    }
                  />
                ))}
            </FormControl>
            <Box alignItems="center" className="filterFooter">
              {packagingTypeSelected.length > 0 ? (
                <Grid
                  item
                  xs={8}
                  className="clearAll"
                  onClick={() => clearAll("Packaging Type")}
                >
                  <IoMdClose size={7} />
                  &nbsp; Clear all
                </Grid>
              ) : (
                <Grid item xs={8}></Grid>
              )}
              <Grid item xs={4}>
                {packagingType.count > 0 && (
                  <Typography
                    className="moreData"
                    onClick={() => handleShowMoreModal("Packaging Type")}
                  >
                    + {packagingType.count} More
                  </Typography>
                )}
              </Grid>
            </Box>
          </Card>
          <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
            <Box
              alignItems="center"
              sx={{
                padding: "5px 0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "10px", fontWeight: "700", }}>
                Size
              </Typography>
              <Typography
                sx={{
                  fontSize: "9px",
                  fontWeight: "500",
                  mb: 1,
                  color: "var(--grey-grey500, #505259)",
                }}
              >
                {sizeSelectedPop.length > 0 &&
                  "(" + sizeSelectedPop.length + " Selected)"}
              </Typography>
            </Box>
            <Divider />
            <FormControl className="w-100px" sx={{ mt: 1 }}>
              {fetch.showMoreLoading
                ? // Render skeletons while loading
                Array.from(new Array(4)).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rectangular"
                    height={15}
                    sx={{ mb: 1 }}
                  />
                ))
                : Array.isArray(size.values) &&
                size.values.map((values: any, index: number) => (
                  <FormControlLabel
                    key={index}
                    value={values}
                    control={
                      <Checkbox
                        checked={sizeSelected.includes(values)}
                        onChange={() => sizeSelect(values)}
                        className="sidebar_checkbox"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 15,
                            color: "#6750A4",
                          },
                        }}
                      />
                    }
                    label={
                      <div
                        title={values}
                        style={{ paddingTop: "2px", paddingLeft: "5px" }}
                        className="filter_lable"
                      >
                        {values}
                      </div>
                    }
                  />
                ))}
            </FormControl>
            <Box alignItems="center" className="filterFooter">
              {sizeSelected.length > 0 ? (
                <Grid
                  item
                  xs={8}
                  className="clearAll"
                  onClick={() => clearAll("Size")}
                >
                  <IoMdClose size={7} />
                  &nbsp; Clear all
                </Grid>
              ) : (
                <Grid item xs={8}></Grid>
              )}
              <Grid item xs={4}>
                {size.count > 0 && (
                  <Typography
                    className="moreData"
                    onClick={() => handleShowMoreModal("Size")}
                  >
                    + {size.count} More
                  </Typography>
                )}
              </Grid>
            </Box>
          </Card>
        </>
      )}
      {filterOpenInv && <FilterModal title={filterModalTitle} />}
    </Box>
  );
};

export default NonSaleableSidebar;
