import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Alert, Button, Grid, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../routes/AuthContext";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ReactComponent as ErrorIcon } from "../../assets/images/icons/errorIcon.svg";
import { decodeJwt, JwtPayload, ROLE_LIST } from "../../utils/constant";

const DistributorLogin: React.FC = () => {
  const navigator = useNavigate();
  const { login } = useAuth();
  const [loadingbtn, setLoadingbtn] = useState(false);
  const [distributionCode, setDistributionCode] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [errors, setErrors] = useState({ distributionCode: "", password: "" });

  const validateForm = () => {
    let isValid = true;
    let errors = { distributionCode: "", password: "" };

    if (!distributionCode) {
      errors.distributionCode = "Distributor Code is required.";
      isValid = false;
    }

    if (!password) {
      errors.password = "Password is required.";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };
  const handleForgetPassword = () => {
    navigator("/distributor/forget-password");
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    setLoadingbtn(true);
    event.preventDefault();
    let errors = { distributionCode: "", password: "" };
    if (validateForm()) {
      const payload = { userId: distributionCode, password: password };
      await login(payload)
        .then((response) => {
          setErrors({ distributionCode: "", password: "" });
          if (response.status === 200) {
            let token = response.data.data.Token;

            let decoded = decodeJwt(token);
            let role = decoded.role ?? "";
            let username = decoded.Name ?? "";
            if (token === "") {
              navigator("/");
            } else {
              if (role === ROLE_LIST.ADMIN) {
                navigator("/dashboard");
              } else if (role === ROLE_LIST.DISTRIBUTOR) {
                navigator("/xdm/inventory/saleable");
              } else {
                navigator("/");
              }
            }
          }
        })
        .catch((error) => {
          errors.password = error.response?.data?.message;
          setErrors(errors);
        });
      setLoadingbtn(false);
      // navigator('/Dashboard');
    } else {
      setLoadingbtn(false);
    }
  };

  return (
    <Box>
      <ToastContainer />
      <Grid container style={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7.5}
          lg={8.5}
          xl={8.5}
          className="leftimg-distributor"
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={4.5}
          lg={3.5}
          xl={3.5}
          component={Paper}
          elevation={6}
          className="sigintop"
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "39%",
            }}
          >
            <img
              src="assets/Images/LoginImage/Altec Logo.png"
              alt="logo"
              title="Altec"
              height="70px"
            ></img>
            {errors.distributionCode && (
              <Alert
                icon={<ErrorIcon />}
                severity="error"
                className="w-100px mt-10 error_alert"
                color="error"
              >
                {errors.distributionCode}
              </Alert>
            )}
            {errors.password && (
              <Alert
                icon={<ErrorIcon />}
                severity="error"
                className="w-100px mt-10 error_alert"
                color="error"
              >
                {errors.password}
              </Alert>
            )}

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{
                marginTop:
                  errors.distributionCode || errors.password ? "2%" : "16%",
              }}
              style={{ width: "100%", padding: "0px 25px 0px 25px" }}
            >
              <Box className="mtsize">
                <Typography
                  style={{
                    display: "flex",
                    fontSize: "11px",
                    fontWeight: "700",
                  }}
                  id="DisText"
                >
                  Distributor Code or Mobile Number
                </Typography>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="text"
                  value={distributionCode}
                  onChange={(e) => setDistributionCode(e.target.value)}
                  className="mt_root mb-0"
                  autoComplete="off"
                  InputProps={{
                    style: { height: "35px" },
                  }}
                  inputProps={{ maxLength: 35 }}
                  onInput={(e) => {
                    const input = e.target as HTMLInputElement;
                    const cleanedValue = input.value.replace(
                      /[^a-zA-Z0-9]/g,
                      ""
                    );
                    if (input.value !== cleanedValue) {
                      input.value = cleanedValue;
                      setDistributionCode(cleanedValue);
                    }
                  }}
                />
              </Box>
              <Box style={{ marginTop: "19px" }} className="mtsize">
                <Typography
                  style={{
                    display: "flex",
                    fontSize: "11px",
                    fontWeight: "700",
                  }}
                  id="DisText"
                >
                  Password
                </Typography>
                <OutlinedInput
                  id="outlined-adornment-password"
                  className="mt_root"
                  style={{ height: "35px" }}
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  inputProps={{ maxLength: 30 }}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>
              <Box className="forgot_flex">
                <Typography
                  className="gradient-text fontCus-style cursor_pointer"
                  onClick={handleForgetPassword}
                >
                  Forgot Password
                </Typography>
              </Box>

              <LoadingButton
                type="submit"
                fullWidth
                loading={loadingbtn}
                loadingPosition="start"
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  //  background: "linear-gradient(90deg, #1677FF 0%, #003EB3 100%)",
                  background:
                    "linear-gradient(90deg, #614FB3 0%, #944986 100%)",
                  marginTop: "42px",
                  // width: "220px",
                  textTransform: "none",
                }}
              >
                Sign In
              </LoadingButton>
              <Grid container sx={{ marginTop: "91px" }}>
                <Grid
                  item
                  xs
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography
                    style={{
                      fontWeight: "400",
                      fontSize: "12px",
                      opacity: "50%",
                    }}
                  >
                    Copyright © 2024 CITPL. All right reserved.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default DistributorLogin;
