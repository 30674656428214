

import React, { useState } from 'react';
import { Box, TextField, FormControl, Button, Grid, InputLabel, FormControlLabel, Link, Radio, RadioGroup, Card, Select, MenuItem, TextareaAutosize, Checkbox } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import type { TableColumnsType } from 'antd';
import SearchBar from '@mkyy/mui-search-bar';
import CommonTable from '../../../components/Tables/CommonTable';

interface DataType {
    key: React.Key;
    fromdate: string;
    todate: string;
    question: string;
    answer: string;
    option: string;
    photo: string;
    mandatory: string;
}


interface ViewSurveyFormProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
}

const ViewSurveyForm: React.FC<ViewSurveyFormProps> = ({ onBack }) => {


    const handleBackClick = (event: React.MouseEvent<unknown>) => {
        onBack(event); // Pass the event argument to onBack function
    };
    const [selectedOption, setSelectedOption] = useState('');
    const [photoSelectedOption, setPhotoSelectedOption] = useState('');
    const [answerSelectedOption, setAnswerSelectedOption] = useState('');
    const [textFieldValue, setTextFieldValue] = React.useState('');

    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

    const handleSearch = () => {
        // search function logic here
    };


    const mandatoryhandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value);
    };

    const photoEnablehandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhotoSelectedOption(event.target.value);
    };
    const AnswerTypehandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAnswerSelectedOption(event.target.value);
    };
    const Options = ['Radio Button', 'Checkbox', 'Dropdown'];



    const columns: TableColumnsType<DataType> = [
        {
            dataIndex: 'fromdate',
            title: 'FromDate',
            sorter: (a, b) => a.fromdate.length - b.fromdate.length,
        },
        {
            dataIndex: 'todate',
            title: 'ToDate',
            sorter: (a, b) => a.todate.length - b.todate.length,
        },
        {
            dataIndex: 'question',
            title: 'Question',
            sorter: (a, b) => a.question.length - b.question.length,
        },
        {
            dataIndex: 'answer',
            title: 'Answer Type',
            sorter: (a, b) => a.answer.length - b.answer.length,
        },
        {
            dataIndex: 'option',
            title: 'Options',
            sorter: (a, b) => a.option.length - b.option.length,
        },
        {
            dataIndex: 'photo', title: 'PhotoEnable',
            sorter: (a, b) => a.photo.length - b.photo.length,
        },
        {
            dataIndex: 'mandatory', title: 'Mandatory',
            sorter: (a, b) => a.mandatory.length - b.mandatory.length,
        },

    ];

    const data: DataType[] = [
        { key: 1, fromdate: '100', todate: '2024/2/26', question: 'company', answer: 'Country', option: 'both', photo: 'photo checking', mandatory: 'person' },
        { key: 2, fromdate: '200', todate: '2024/2/26', question: 'company', answer: 'State', option: 'both', photo: 'photo checking', mandatory: 'person' },
        { key: 3, fromdate: '300', todate: '2024/2/26', question: 'company', answer: 'Town', option: 'both', photo: 'photo checking', mandatory: 'person' },
        { key: 4, fromdate: '400', todate: '2024/2/26', question: 'company', answer: 'District', option: 'both', photo: 'photo checking', mandatory: 'person' },
        { key: 5, fromdate: '500', todate: '2024/2/26', question: 'company', answer: 'Country1', option: 'both', photo: 'photo checking', mandatory: 'person' },
        { key: 6, fromdate: '600', todate: '2024/2/26', question: 'company', answer: 'Supplier6', option: 'both', photo: 'photo checking', mandatory: 'person' },
        { key: 7, fromdate: '700', todate: '2024/2/26', question: 'company', answer: 'Supplier7', option: 'both', photo: 'photo checking', mandatory: 'person' },
        { key: 8, fromdate: '800', todate: '2024/2/26', question: 'company', answer: 'Supplier8', option: 'both', photo: 'photo checking', mandatory: 'person' },
    ];

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    return (
        <Box className='card-form card-bg'>

            <form>
                <Grid container className='mar-10' >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                            <Grid container columnSpacing={2} xs={12} sx={{ display: "flex" }}>
                                <Grid item >
                                    <InputLabel size="normal" >From Date</InputLabel>
                                    <DatePicker name="startDate" />
                                </Grid>
                                <Grid item >
                                    <InputLabel size="normal" >To Date</InputLabel>
                                    <DatePicker name="toDate" />
                                </Grid>
                            </Grid>
                        </DemoContainer>
                    </LocalizationProvider>
                    <Grid container sx={{ mt: 2, ml: 2 }}>
                        <Grid xs={2} item sx={{ mt: 1 }}>
                            <InputLabel size="normal">Mandatory</InputLabel>
                        </Grid>
                        <Grid item>
                            <RadioGroup
                                row
                                aria-label="mandatory"
                                name="mandatory"
                                value={selectedOption}
                                onChange={mandatoryhandleChange}
                            >
                                <FormControlLabel value="yes" control={<Radio
                                    sx={{
                                        "& .MuiSvgIcon-root": {
                                            fontSize: 18,
                                            color: "#6750A4",
                                        },
                                    }}
                                />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio
                                    sx={{
                                        "& .MuiSvgIcon-root": {
                                            fontSize: 18,
                                            color: "#6750A4",
                                        },
                                    }}
                                />} label="No" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2} xs={12} sx={{ display: "flex", mt: 2, ml: 0 }}>
                        <Grid item >
                            <InputLabel size="normal" >Distributor</InputLabel>
                            <TextField size="small"></TextField>
                        </Grid>
                        <Grid item className='mar-tb' ><Button variant='contained' className='btn-apply'>...</Button></Grid>
                        <Grid item >
                            <InputLabel size="normal" >Survey Description </InputLabel>
                            <TextField size="small"></TextField>
                        </Grid>
                    </Grid>
                </Grid>
                <Card className="card-form" sx={{ border: "1px solid #EEE", mt: 4 }}>
                    <Grid className="survey-box-lable">
                        Retailer Category :
                    </Grid>
                    <Grid item container sx={{ marginTop: "16px" }}>
                        <Grid item xs={12} lg={6}>
                            <Grid item sx={{ display: "flex", mt: 2 }}>
                                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                                    <InputLabel size="normal" >  Retailer Channel </InputLabel>
                                </Grid>
                                <Grid item xs={4} sx={{ mr: 5 }}>
                                    <TextField sx={{ width: 200 }} size="small"></TextField>
                                </Grid>
                                <Button variant='contained' className='btn-colr'>...</Button>
                            </Grid>
                            <Grid item sx={{ display: "flex", mt: 2 }}>
                                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                                    <InputLabel size="normal" >  Retailer Group </InputLabel>
                                </Grid>
                                <Grid item xs={4} sx={{ mr: 5 }}>
                                    <TextField sx={{ width: 200 }} size="small"></TextField>
                                </Grid>
                                <Button variant='contained' className='btn-colr'>...</Button>
                            </Grid>
                            <Grid item sx={{ display: "flex", mt: 2 }}>
                                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                                    <InputLabel size="normal" >  Retailer Class  </InputLabel>
                                </Grid>
                                <Grid item xs={4} sx={{ mr: 5 }}>
                                    <TextField sx={{ width: 200 }} size="small"></TextField>
                                </Grid>
                                <Button variant='contained' className='btn-colr'>...</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
                <Card className="card-form" sx={{ border: "1px solid #EEE", mt: 4 }}>
                    <Grid item className="survey-box-lable">
                        Question and Answer key :
                    </Grid>
                    <Grid item container sx={{ marginTop: "16px" }}>
                        <Grid item xs={12} lg={6}>
                            <Grid item sx={{ display: "flex", mt: 2 }}>
                                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                                    <InputLabel size="normal" >  Question </InputLabel>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextareaAutosize
                                        placeholder="Description"
                                        minRows={4}
                                        maxRows={4}
                                        style={{ width: "200px" }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item sx={{ display: "flex", mt: 2 }}>
                                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                                    <InputLabel size="normal" > Answer Type</InputLabel>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: 200 }}>
                                        <Select>
                                            {Options.map((option, index) => (
                                                <MenuItem key={index} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mt: 2 }}>
                                <Grid xs={4} item sx={{ mt: 1 }}>
                                    <InputLabel size="normal">PhotoEnable</InputLabel>
                                </Grid>
                                <Grid item>
                                    <RadioGroup
                                        row
                                        aria-label="mandatory"
                                        name="mandatory"
                                        value={photoSelectedOption}
                                        onChange={photoEnablehandle}
                                    >
                                        <FormControlLabel value="yes" control={<Radio
                                            sx={{
                                                "& .MuiSvgIcon-root": {
                                                    fontSize: 18,
                                                    color: "#6750A4",
                                                },
                                            }}
                                        />} label="Yes" />
                                        <FormControlLabel value="no" control={<Radio
                                            sx={{
                                                "& .MuiSvgIcon-root": {
                                                    fontSize: 18,
                                                    color: "#6750A4",
                                                },
                                            }}
                                        />} label="No" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ mt: 2 }}>
                                <Grid xs={4} item sx={{ mt: 1 }}>
                                    <InputLabel size="normal">Answer Type Value</InputLabel>
                                </Grid>
                                <Grid item>
                                    <RadioGroup
                                        row
                                        aria-label="mandatory"
                                        name="mandatory"
                                        value={answerSelectedOption}
                                        onChange={AnswerTypehandle}
                                    >
                                        <FormControlLabel value="mandatory" control={<Radio
                                            sx={{
                                                "& .MuiSvgIcon-root": {
                                                    fontSize: 18,
                                                    color: "#6750A4",
                                                },
                                            }}
                                        />} label="Mandatory" />
                                        <FormControlLabel value="NonMandatory" control={<Radio
                                            sx={{
                                                "& .MuiSvgIcon-root": {
                                                    fontSize: 18,
                                                    color: "#6750A4",
                                                },
                                            }}
                                        />} label="Non-Mandatory" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ display: "flex", mt: 2 }}>
                                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                                    <InputLabel size="normal" >  Answer Type Values  </InputLabel>
                                </Grid>
                                <Grid item xs={4} sx={{ mr: 5 }}>
                                    <TextField sx={{ width: 200 }} size="small"></TextField>
                                </Grid>
                                <Button variant='contained' className='btn-colr'>Add</Button>
                            </Grid>

                        </Grid>
                    </Grid>
                </Card>
                <Box className='mar-tb'>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', mb: 2 }} >
                        <SearchBar
                            className='search-table'
                            value={textFieldValue}
                            onChange={newValue => {
                                setTextFieldValue(newValue);
                                handleSearch();
                            }}
                        />
                    </Grid>
                    <CommonTable 
                    // rowSelection={rowSelection}
                     data={data} columns={columns} />
                </Box>

                <Grid item xs={12} mt={2} display="flex" justifyContent="center">
                    <Button variant="contained" className='btn-colr' onClick={handleBackClick}>
                        Back
                    </Button>
                </Grid>
            </form>
        </Box>
    );
}

export default ViewSurveyForm;
