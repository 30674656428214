import React from 'react';
import { Box, TextField, FormControl, Select, MenuItem, Button, Grid, InputLabel, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

interface RouteEditFormProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
}

const RouteEditForm: React.FC<RouteEditFormProps> = ({ onBack }) => {
    const handleBackClick = (event: React.MouseEvent<unknown>) => {
        onBack(event); // Pass the event argument to onBack function
    };

    const companyOptions = ['Company A', 'Company B', 'Company C'];
    const gstStateOptions = ['State A', 'State B', 'State C'];
    const geoHierarchyValueOptions = ['Value A', 'Value B', 'Value C'];

    return (
        <Box className='card-form'>
            <form noValidate>
                <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 10 }}>
                    <Grid item xs={12} sm={6}>
                        <InputLabel size="normal" >Distributor Branch Code</InputLabel>
                        <FormControl fullWidth>
                            <Select>
                                {companyOptions.map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel size="normal" >Code *</InputLabel>
                        <TextField placeholder="Code" disabled fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel size="normal" >Route Name *</InputLabel>
                        <TextField placeholder="RouteName" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel size="normal" >Distance</InputLabel>
                        <TextField placeholder="Distance" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel size="normal" >Population</InputLabel>
                        <TextField placeholder="Population" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel size="normal" >City</InputLabel>
                        <FormControl fullWidth>
                            <Select>
                                {companyOptions.map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl>
                            <FormLabel >Is Van Route</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="Yes" control={<Radio sx={{
                                    "& .MuiSvgIcon-root": {
                                        fontSize: 18,
                                        color: "#6750A4",
                                    },
                                }} />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio sx={{
                                    "& .MuiSvgIcon-root": {
                                        fontSize: 18,
                                        color: "#6750A4",
                                    },
                                }} />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl>
                            <FormLabel >Route Type</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="Delivery Route" control={<Radio sx={{
                                    "& .MuiSvgIcon-root": {
                                        fontSize: 18,
                                        color: "#6750A4",
                                    },
                                }} />} label="Delivery Route" />
                                <FormControlLabel value="Sales Route" control={<Radio sx={{
                                    "& .MuiSvgIcon-root": {
                                        fontSize: 18,
                                        color: "#6750A4",
                                    },
                                }} />} label="Sales Route" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl>
                            <FormLabel >Is Active</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="Yes" control={<Radio sx={{
                                    "& .MuiSvgIcon-root": {
                                        fontSize: 18,
                                        color: "#6750A4",
                                    },
                                }} />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio sx={{
                                    "& .MuiSvgIcon-root": {
                                        fontSize: 18,
                                        color: "#6750A4",
                                    },
                                }} />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl>
                            <FormLabel >Is Van Route</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="Yes" control={<Radio sx={{
                                    "& .MuiSvgIcon-root": {
                                        fontSize: 18,
                                        color: "#6750A4",
                                    },
                                }} />} label="Yes" />
                                <FormControlLabel value="No" control={<Radio sx={{
                                    "& .MuiSvgIcon-root": {
                                        fontSize: 18,
                                        color: "#6750A4",
                                    },
                                }} />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormLabel > Local /UpCountry</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value="Yes" control={<Radio sx={{
                                "& .MuiSvgIcon-root": {
                                    fontSize: 18,
                                    color: "#6750A4",
                                },
                            }} />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio sx={{
                                "& .MuiSvgIcon-root": {
                                    fontSize: 18,
                                    color: "#6750A4",
                                },
                            }} />} label="No" />
                        </RadioGroup>
                    </Grid>


                    <Grid item xs={12} mt={2} display="flex" justifyContent="center">
                        <Button variant="outlined" onClick={handleBackClick} >
                            Cancel
                        </Button>
                        <Button variant="contained" className='btn-colr' style={{ marginLeft: "10px" }}>
                            Update
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}

export default RouteEditForm;
