import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createAxiosInstance from "../../../hooks/useFetch";

interface orderCountData {
  values: string[];
  products: string[];
  count: number;
}
interface TaxInvoiceFromInfo {
  distributorName: string;
  distributorCode: string;
  distributorAddress: string;
  distributorMobile: string;
  distributorGstNumber: string;
  distributorFssaiNumber: string;
  distributorState: string;
  salesmanName: string;
  routeName: string;
  invoiceDate: string;
  mobileNo: string;
}
interface TaxInvoiceToInfo {
  outletName: string;
  outletAddress: string;
  outletGstNumber: string;
  fssai: string;
  outletGstState: string;
  shippingAddress: string;
  shippingGstNumber: string;
  shippingFssai: string;
  shippingGstState: string;
  shippingPhone: string;
  shippingDate: string;
}
interface InvoiceTaxData {
  gstPercentage: string;
  taxableAmount: number;
  sgst: number;
  cgst: number;
  taxAmount: number;
}
interface invoiceProductTableData {
  productName: string;
  hsnCode: number;
  mrp: number;
  billedQty: number;
  freeQty: number;
  total: number;
  rate: number;
  schemeDiscount: number;
  grossAmount: number;
  cgstPercentage: number;
  sgstPercentage: number;
  igstPercentage: number;
  netAmount: number;
}
interface invoiceTotalProductDetails {
  productDetails: invoiceProductTableData[];
  totalBilledQty: number;
  totalFreeQty: number;
  totalSum: number;
  totalGrossAmount: number;
  totalSchemeDiscount: number;
  otherDiscountAmount: number;
  totalTaxAmount: number;
  tcsAmount: number;
  crDbAmount: number;
  totalNetAmount: number;
  totalNetPayable: number;
}
interface InitialState {
  orderCounts: orderCountData[];
  taxDetails: InvoiceTaxData[];
  taxProductDetails: invoiceTotalProductDetails | null;
  toInfo: TaxInvoiceToInfo | null;
  fromInfo: TaxInvoiceFromInfo | null;
  taxDetailsError: any;
  taxDetailsLoading: boolean;
  taxProductsError: any;
  taxProductsLoading: boolean;
  pdf: string | null;
  pdfLoading: boolean;
  pdfError: any;
  loading: boolean;
  error: any;
}
interface PdfGenerate {
  invoiceNumber: string,
  routeCode: string,
  invoiceDate: string,
  smName: string
}

const axiosInstance = createAxiosInstance();

export const getInvoiceCounts = createAsyncThunk(
  "invoice/getInvoiceBillDetails",
  async () => {
    const response = await axiosInstance.get("/invoice/getInvoiceBillDetails");
    return response;
  }
);
export const getTaxInvoiceFromInfo = createAsyncThunk(
  "invoice/get-from-info",
  async (invoiceNumber: string) => {
    const response = await axiosInstance.get(
      `invoice/get-from-info?invoiceNumber=${invoiceNumber}`
    );
    return response.data;
  }
);
export const getTaxInvoiceToInfo = createAsyncThunk(
  "invoice/get-to-info",
  async (invoiceNumber: string) => {
    const response = await axiosInstance.get(
      `invoice/get-to-info?invoiceNumber=${invoiceNumber}`
    );
    return response.data;
  }
);
export const getTaxInvoiceTaxDetails = createAsyncThunk(
  "invoice/get-tax-info",
  async (invoiceNumber: string) => {
    const response = await axiosInstance.get(
      `invoice/get-product-details-tax-invoice?invoiceNumber=${invoiceNumber}`
    );
    return response.data;
  }
);
export const getTaxInvoiceProductDetails = createAsyncThunk(
  "invoice/get-tax-product-info",
  async (invoiceNumber: string) => {
    const response = await axiosInstance.get(
      `invoice/get-product-details?invoiceNumber=${invoiceNumber}`
    );
    return response.data;
  }
);
export const InvoicePdfGenerate = createAsyncThunk(
  "invoice/generatePdf",
  async (req: PdfGenerate) => {
    const response = await axiosInstance.get(`invoice/generatePdf`, {
      params: req,
    });
    return response.data;
  }
);

const invoiceSlice = createSlice({
  name: "billing",
  initialState: {
    orderCounts: [],
    taxProductDetails: null,
    taxDetails: [],
    toInfo: null,
    fromInfo: null,
    taxDetailsError: null,
    taxDetailsLoading: false,
    taxProductsError: null,
    taxProductsLoading: false,
    pdf: null,
    pdfLoading: false,
    pdfError: null as string | null,
    error: null as string | null,
    loading: false,
  } as InitialState,
  reducers: {
    invoiceStates: (state, action) => {
      state.orderCounts = action.payload;
      state.toInfo = action.payload;
      state.fromInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInvoiceCounts.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getInvoiceCounts.fulfilled, (state, action) => {
      state.loading = false;
      state.orderCounts = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getInvoiceCounts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getTaxInvoiceFromInfo.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.fromInfo = null;
    });
    builder.addCase(getTaxInvoiceFromInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.fromInfo = action.payload.data ?? [];
      state.error = null;
    });
    builder.addCase(getTaxInvoiceFromInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getTaxInvoiceToInfo.pending, (state) => {
      state.loading = true;
      state.toInfo = null;
      state.error = null;
    });
    builder.addCase(getTaxInvoiceToInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.toInfo = action.payload.data ?? [];
      state.error = null;
    });
    builder.addCase(getTaxInvoiceToInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getTaxInvoiceTaxDetails.pending, (state) => {
      state.taxDetailsLoading = true;
      state.error = null;
      state.taxDetails = [];
    });
    builder.addCase(getTaxInvoiceTaxDetails.fulfilled, (state, action) => {
      state.taxDetailsLoading = false;
      state.taxDetails = action.payload.data ?? [];
      state.error = null;
    });
    builder.addCase(getTaxInvoiceTaxDetails.rejected, (state, action) => {
      state.taxDetailsLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(getTaxInvoiceProductDetails.pending, (state) => {
      state.taxProductsLoading = true;
      state.error = null;
      state.taxProductDetails = null;
    });
    builder.addCase(getTaxInvoiceProductDetails.fulfilled, (state, action) => {
      state.taxProductsLoading = false;
      state.taxProductDetails = action.payload.data ?? [];
      state.error = null;
    });
    builder.addCase(getTaxInvoiceProductDetails.rejected, (state, action) => {
      state.taxProductsLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(InvoicePdfGenerate.pending, (state) => {
      state.pdfLoading = true;
      state.error = null;
      state.pdf = null;
    });
    builder.addCase(InvoicePdfGenerate.fulfilled, (state, action) => {
      state.pdfLoading = false;
      state.pdf = action.payload.data ?? [];
      state.error = null;
    });
    builder.addCase(InvoicePdfGenerate.rejected, (state, action) => {
      state.pdfLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { invoiceStates } = invoiceSlice.actions;
export default invoiceSlice.reducer;
