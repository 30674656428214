import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  Paper,
  Typography,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TableSearchDataTable from "../../../components/Modal/TableSearchModal";
import type { GetRef, TableColumnsType } from "antd";
import { Input } from "antd";

interface DataType {
  key: React.Key;
  disbranchcode: string;
  disBranchName: string;
  disType: string;
}
const { Search } = Input;

const dataDistributor: DataType[] = [
  {
    key: "1",
    disbranchcode: "DisCode123654",
    disBranchName: "vanisri",
    disType: "New York No. 1 Lake Park",
  },
  {
    key: "2",
    disbranchcode: "DisCode123785",
    disBranchName: "shenba",
    disType: "London No. 1 Lake Park",
  },
  {
    key: "3",
    disbranchcode: "DisCode123789",
    disBranchName: "mani",
    disType: "Sydney No. 1 Lake Park",
  },
  {
    key: "4",
    disbranchcode: "DisCode123528",
    disBranchName: "arul",
    disType: "London No. 2 Lake Park",
  },
];
const dataRetailer: DataType[] = [
  {
    key: "1",
    disbranchcode: "DisCode123654",
    disBranchName: "vanisri",
    disType: "New York No. 1 Lake Park",
  },
  {
    key: "2",
    disbranchcode: "DisCode123785",
    disBranchName: "shenba",
    disType: "London No. 1 Lake Park",
  },
  {
    key: "3",
    disbranchcode: "DisCode123789",
    disBranchName: "mani",
    disType: "Sydney No. 1 Lake Park",
  },
  {
    key: "4",
    disbranchcode: "DisCode123528",
    disBranchName: "arul",
    disType: "London No. 2 Lake Park",
  },
];
const RetailerTargetUpload = () => {
  const navigator = useNavigate();
  const SelectMonth = ["Jan", "Feb", "March"];
  const SelectYear = [2016, 2017, 2018];

  const [filteredDataDistributor, setFilteredDataDistributor] =
    useState(dataDistributor); // Initialize with initial data
  const handleFilterChangeDistributor = (
    value: string,
    dataIndex: keyof DataType
  ) => {
    const newData = dataDistributor.filter((item) =>
      String(item[dataIndex]).toLowerCase().includes(value.toLowerCase())
    );
    setFilteredDataDistributor(newData);
  };
  const [filteredDataRetailer, setFilteredDataRetailer] =
    useState(dataRetailer); // Initialize with initial data
  const handleFilterChangeRetailer = (
    value: string,
    dataIndex: keyof DataType
  ) => {
    const newData = dataRetailer.filter((item) =>
      String(item[dataIndex]).toLowerCase().includes(value.toLowerCase())
    );
    setFilteredDataRetailer(newData);
  };

  const columns = [
    {
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Distributor Branch Code</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={(e) =>
                handleFilterChangeDistributor(e.target.value, "disbranchcode")
              }
            />
          </Grid>
        </Grid>
      ),
      dataIndex: "disbranchcode",
      key: "disbranchcode",
    },
    {
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Distributor Branch Name</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={(e) =>
                handleFilterChangeDistributor(e.target.value, "disBranchName")
              }
            />
          </Grid>
        </Grid>
      ),
      dataIndex: "disBranchName",
      key: "disBranchName",
    },
    {
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Distributor Type</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={(e) =>
                handleFilterChangeDistributor(e.target.value, "disType")
              }
            />
          </Grid>
        </Grid>
      ),
      dataIndex: "disType",
      key: "disType",
    },
  ];
  const columnsForRetailer = [
    {
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Distributor Branch Code</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={(e) =>
                handleFilterChangeRetailer(e.target.value, "disbranchcode")
              }
            />
          </Grid>
        </Grid>
      ),
      dataIndex: "disbranchcode",
      key: "disbranchcode",
    },
    {
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Distributor Branch Name</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={(e) =>
                handleFilterChangeRetailer(e.target.value, "disBranchName")
              }
            />
          </Grid>
        </Grid>
      ),
      dataIndex: "disBranchName",
      key: "disBranchName",
    },
  ];

  const [TableSelect, setableSelect] = React.useState(false);
  const [openRetailerModel, setOpenRetailerModel] = React.useState(false);

  const HandleRetailerSelectClick = (event: React.MouseEvent<unknown>) => {
    setOpenRetailerModel(!TableSelect);
  };
  const HandleTableSelectClick = (event: React.MouseEvent<unknown>) => {
    setableSelect(!TableSelect);
  };
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  //checkbox
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Grid sx={{ p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
      <Box sx={{ marginTop: "2px" }}>
        <Paper elevation={0} className="pad-10 mar-tb">
          <Grid item xs={12}>
            <Typography className="cursor_pointer">
              <span onClick={() => navigator("/Customer")}>Customer</span>{" "}
              &nbsp;/
              <span>
                <b>&nbsp;Retailer Target Upload</b>
              </span>
            </Typography>
          </Grid>
        </Paper>
      </Box>
      <form>
        <Paper elevation={0} className="pad-10 mar-tb">
          <Grid container spacing={5}>
            <Grid item xs={6} md={5}>
              <InputLabel size="normal">Month</InputLabel>
              <FormControl fullWidth>
                <Select>
                  {SelectMonth.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={5}>
              <InputLabel size="normal">Year</InputLabel>
              <FormControl fullWidth>
                <Select>
                  {SelectYear.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4}>
              <InputLabel size="normal">Distributor *</InputLabel>
              <FormControl fullWidth>
                <Select>
                  {SelectYear.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={1} mt={3}>
              <Button variant="contained" className="btn-apply-color">
                ...
              </Button>
            </Grid>
            <Grid item xs={6} md={4}>
              <InputLabel size="normal">Distributor Branch*</InputLabel>
              <FormControl fullWidth>
                <Select>
                  {SelectYear.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={1} mt={3}>
              <Button
                variant="contained"
                className="btn-apply-color"
                onClick={(event) => HandleTableSelectClick(event)}
              >
                ...
              </Button>
            </Grid>
            <Grid item xs={6} md={4}>
              <InputLabel size="normal">Retailer*</InputLabel>
              <FormControl fullWidth>
                <Select>
                  {SelectYear.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={1} mt={3}>
              <Button
                variant="contained"
                className="btn-apply-color"
                onClick={(event) => HandleRetailerSelectClick(event)}
              >
                ...
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            md={1}
            mt={3}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button variant="contained" className="btn-apply-color">
              Download Format
            </Button>
          </Grid>
        </Paper>
      </form>
      <TableSearchDataTable
        //  rowSelection={rowSelection}
        data={filteredDataDistributor}
        columns={columns}
        isModalOpen={TableSelect}
        setIsModalOpen={setableSelect}
      />
      <TableSearchDataTable
        // rowSelection={rowSelection}
        data={filteredDataRetailer}
        columns={columnsForRetailer}
        isModalOpen={openRetailerModel}
        setIsModalOpen={setOpenRetailerModel}
      />
    </Grid>
  );
};
export default RetailerTargetUpload;
