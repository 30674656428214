import { lazy } from "react";

// project import
import Loadable from "../components/Loadable";
import MainLayout from "../Layout/MainLayout";
import MiniLayout from "../Pages/Purchase/PurchaseInvoices";
import ForgetPassword from "../Pages/DistributorLogin/ForgetPassword";
import AuthLogin from "../Pages/AuthLogin";
import DistributorLogin from "../Pages/DistributorLogin/DistributorLogin";
import MinimalLayout from "../Layout/MinimalLayout/MinimalLayout";
import NotFound from "../Pages/NotFoundPage";

// render - login
// const AuthLogin = Loadable(lazy(()=> import('../Pages/AuthLogin')));
// const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/",
      element: <AuthLogin />,
    },
    {
      path: "/distributor/login",
      element: <DistributorLogin />,
    },
    {
      path: "/distributor/forget-password",
      element: <ForgetPassword />,
    },
    {
      path: "*",
      element: <NotFound />,
		
    },
  ],
};

export default LoginRoutes;
