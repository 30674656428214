import { useState } from "react";
// material-ui
import { Card, CardContent, CardActions, Box, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";

//dashboard
interface CardData {
  Title?: string;
  Icons?: JSX.Element | string;
  [key: string]: string | JSX.Element | undefined;
}

interface DashboardProps {
  cardData: CardData[];
  handleCardClick: (index: number) => void;
}

//Comapny
const CommonCard: React.FC<DashboardProps> = ({
  cardData,
  handleCardClick,
}) => {
  const navigator = useNavigate();
  // const cardColors = ["rgb(193 136 44)", "#66BB6A", "#AB47BC", "#FFCA28", "#64B5F6", "#EF5350","#F9A825", "#66BB6A", "#AB47BC", "#FFCA28", "#64B5F6", "#EF5350"];
  const cardHeight = 105;

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  //hover for dashboard card
  const handleHover = (index: number | null) => {
    setHoveredIndex(index);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
        gap: 2,
        marginTop: 2,
        p: { xs: 2, sm: 3 },
        pt: { xs: 1, sm: 1 },
      }}
    >
      {cardData.map((description, index: number) => {
        return (
          <Card
            key={index}
            variant="outlined"
            style={{
              background:
                " linear-gradient(90deg, rgb(97, 79, 179) 0%, rgb(148, 73, 134) 100%)",
              height: cardHeight,
            }}
            onMouseEnter={() => {
              if (description.hasOwnProperty("viewAll")) {
                handleHover(index);
              }
            }}
            onMouseLeave={() => {
              if (description.hasOwnProperty("viewAll")) {
                handleHover(null);
              }
            }}
          >
            <CardContent className="cursor_pointer">
              {hoveredIndex === index || description.hasOwnProperty("field") ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                    }}
                  >
                    {Object.entries(description)
                      .filter(
                        ([key]) =>
                          key !== "viewAll" &&
                          key !== "Title_for_route" &&
                          key !== "field" &&
                          key !== "Icons"
                      )
                      .map(([key, value]) => (
                        <Typography
                          key={key}
                          style={{ fontSize: "12px" }}
                          color="white"
                          onClick={() => navigator(`/${key}`)}
                        >
                          {value}
                        </Typography>
                      ))}
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    style={{
                      fontSize: "2rem",
                      color: "#FFF",
                    }}
                  >
                    {description.Icons}
                  </Box>

                  <Typography variant="h6" color="white">
                    {description.Title_for_route}
                  </Typography>
                </Box>
              )}
            </CardContent>
            <CardActions className="cursor_pointer viewall">
              {hoveredIndex === index ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      width: "100%",
                    }}
                    onClick={() => handleCardClick(index)}
                  >
                    <Typography variant="h6" color="white">
                      {description.viewAll}
                    </Typography>
                  </Box>
                </>
              ) : (
                ""
              )}
            </CardActions>
          </Card>
        );
      })}
    </Box>
  );
};
export default CommonCard;
