import PropTypes from 'prop-types';
import { useMemo } from 'react';
 
// material-ui
import { CssBaseline, StyledEngineProvider ,useMediaQuery,Theme} from '@mui/material';
import { createTheme, ThemeProvider,useTheme } from '@mui/material/styles';
 
// project import
import Palette from './palette';
import Typography from './typography';
import CustomShadows from './shadows';
import componentsOverride from './overrides';
import { useAppSelector } from '../hooks/useFetch';
import zIndex from '@mui/material/styles/zIndex';
 
// ==============================|| DEFAULT THEME - MAIN  ||============================== //
 
export default function ThemeCustomization({ children}) {
  const theme = Palette('light', 'default');
  const themeused = useTheme();
  const matchesXs = useMediaQuery(
    themeused.breakpoints.down("sm")
);
    const hideSubTab = useAppSelector(
      (state) => state.hideSubHeaderData.hideSubHeader
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const themeTypography = Typography(`'Public Sans', sans-serif`);
  const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);
 
  const themeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1536,
        },
      },
      direction: "ltr",
      mixins: {
        toolbar: {
          minHeight: hideSubTab || matchesXs ===true ? 0 : 60,
          paddingTop: 8,
          paddingBottom: 8,
          // zIndex:-1,
        },
      },
      palette: theme.palette,
      customShadows: themeCustomShadows,
      typography: themeTypography,
    }),
    [theme, themeTypography, themeCustomShadows]
  );
 
  const themes = createTheme(themeOptions);
  themes.components = componentsOverride(themes);
 
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider   theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
 
ThemeCustomization.propTypes = {
  children: PropTypes.node
};
 