import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button, Grid, Paper, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import RetailerGroupForm from "./ReatilerGroupForm";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  RetailerChannelName: string;
  RetailerGroupCode: string;
  RetailerGroupName: string;
  action: string;
}

const RetailerGroup: React.FC = () => {
  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // search function logic here
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "RetailerChannelName",
      title: "Retailer Channel Name",
      sorter: (a, b) =>
        a.RetailerChannelName.length - b.RetailerChannelName.length,
    },
    {
      dataIndex: "RetailerGroupCode",
      title: "Retailer Group Code",
      sorter: (a, b) => a.RetailerGroupCode.length - b.RetailerGroupCode.length,
    },
    {
      dataIndex: "RetailerGroupName",
      title: "Retailer Group Name",
      sorter: (a, b) => a.RetailerGroupName.length - b.RetailerGroupName.length,
    },
    {
      dataIndex: "action",
      title: "Action",

      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            onClick={(event) => handleViewClick(event)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      RetailerChannelName: "FMCG/FMCG-GENERAL TRADE",
      RetailerGroupCode: "FMCG019",
      RetailerGroupName: "FMCG - Temple/Tourist",
      action: "",
    },
    {
      key: 2,
      RetailerChannelName: "MODERN TRADE/MT-NORTH",
      RetailerGroupCode: "FMCG017B",
      RetailerGroupName: "BRONZE",
      action: "",
    },
    {
      key: 3,
      RetailerChannelName: "FMCG/FMCG-GENERAL TRADE",
      RetailerGroupCode: "FMCG017HVO",
      RetailerGroupName: "FMCG - Society/Apartments",
      action: "",
    },
    {
      key: 4,
      RetailerChannelName: "MODERN TRADE/MT-EAST",
      RetailerGroupCode: "FMCG019D",
      RetailerGroupName: "J24 STORES",
      action: "",
    },
    {
      key: 5,
      RetailerChannelName: "SS",
      RetailerGroupCode: "FMCG020C",
      RetailerGroupName: "VRIDDHI",
      action: "",
    },
  ];
  const [isViewing, setIsViewing] = React.useState(false);

  const handleViewClick = (event: React.MouseEvent<unknown>) => {
    setIsViewing(!isViewing);
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className="pad-10 mar-tb">
          <Typography>
            <b>Retailer Group</b>
          </Typography>
        </Grid>
        <Box>
          {isViewing ? (
            <RetailerGroupForm onBack={(event) => handleViewClick(event)} />
          ) : (
            <>
              <Grid item sx={{ mt: 2 }}>
                <Box>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "end", mb: 2 }}
                  >
                    <SearchBar
                      className="search-table"
                      value={textFieldValue}
                      onChange={(newValue) => {
                        setTextFieldValue(newValue);
                        handleSearch();
                      }}
                    />
                  </Grid>
                  <CommonTable
                    // rowSelection={rowSelection}
                    data={data}
                    columns={columns}
                  />
                </Box>
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
export default RetailerGroup;
