import { Box, Card, Grid, Stack, Drawer } from "@mui/material";
import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Divider } from "antd";
import CollectionSearchInput from "./CollectionSearchInput";
import { useAppDispatch } from "../../../../../hooks/useFetch";
import { ArrowBackIos } from "@mui/icons-material";
// import {
//   savePreviouslyAddedData,
//   showDisablecheckbox,
// } from "../../../Redux/XDM/Sales/ManualOrderReducer";

interface NewCollectionProps {
  onClose: () => void; // Define the onClose prop
}
const NewCollection: React.FC<NewCollectionProps> = (props) => {
  const { onClose } = props;
  const theme = useTheme();
  const dispatch = useAppDispatch();
  useEffect(() => {
    // dispatch(savePreviouslyAddedData([]));
  }, [dispatch]);

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer anchor="right" open={true} className="drawer_outlet w-100px">
        <Box
          flex={8}
          className="margin-left-0px"
          sx={{ overflowY: "auto", height: "100%", marginBottom: "100px" }}
        >
          <Card
            className="b-radius-0 main-card"
            style={{ marginTop: "2px", boxShadow: "none" }}
          >
            <Grid container spacing={3} justifyContent="start" p={2}>
              <Grid item xs={6} sm={6} md={9} lg={9} className="margin_tp_0">
                <h4
                  style={{
                    marginTop: "0px",
                    marginLeft: "0px",
                    marginBottom: "0px",
                  }}
                >
                  <ArrowBackIos
                    sx={{
                      fontSize: "10px",
                      cursor: "pointer",
                      marginRight: "5px",
                    }}
                    onClick={() => {
                      onClose();
                    }}
                  />
                  <b>New Collection</b>
                </h4>
              </Grid>
              <Divider className="margin_tp_5" />
            </Grid>
            <Grid container pt={0}>
              <Grid item xs={12}>
                <CollectionSearchInput />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
            </Grid>
          </Card>
        </Box>
      </Drawer>
    </Box>
  );
};

export default NewCollection;
