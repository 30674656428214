import SearchBar from "@mkyy/mui-search-bar";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@mui/material";
import React, { useEffect, useState,useRef } from "react";
import { IoClose } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import { sidebarFilterInv } from "../../../Redux/AuthLogin/action";
import {
  getBrandMore,
  getDivisionMore,
  getPackagingTypeMore,
  getSegmentMore,
  getSizeMore,
  getSubVariantMore,
  getVariantMore,
  updateBrand,
  updateBrandPop,
  updateBrandTemp,
  updateDivision,
  updateDivisionPop,
  updateDivisionTemp,
  updatePackagingType,
  updatePackagingTypePop,
  updatePackagingTypeTemp,
  updateSegment,
  updateSegmentPop,
  updateSegmentTemp,
  updateSize,
  updateSizePop,
  updateSizeTemp,
  updateSubVariant,
  updateSubVariantPop,
  updateSubVariantTemp,
  updateVariant,
  updateVariantPop,
  updateVariantTemp,
} from "../../../Redux/XDM/Inventory/filterReducer";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
import { getSizeFilterMore } from "../../../Redux/XDM/Sales/salesReturnReducer";

const FilterModal: React.FC<{ title: string }> = ({ title }) => {
  const dispatch = useAppDispatch();
  const filterInv = useAppSelector((state) => state.filterInv);
  const size = useAppSelector((state) => state.salesReturnData.sizeMore.data);
  let filterOptions: any[] = [];

  const location = useLocation();
  const currentPath = location.pathname;

  const filterData = {
    filterName: "division",
    page: 0,
    size: 4,
    pageStatus: false,
    type: currentPath.includes("non-saleable") ? "non-sale" : "sale",
  };

  useEffect(() => {
    switch (title) {
      case "Division":
        dispatch(getDivisionMore(filterData));
        break;
      case "Segment":
        dispatch(getSegmentMore(filterData));
        break;
      case "Brand":
        dispatch(getBrandMore(filterData));
        break;
      case "Variant":
        dispatch(getVariantMore(filterData));
        break;
      case "Sub Variant":
        dispatch(getSubVariantMore(filterData));
        break;
      case "Packaging Type":
        dispatch(getPackagingTypeMore(filterData));
        break;
      case "Size":
        dispatch(getSizeMore(filterData));
        break;
      case "SizeSales":
        dispatch(getSizeFilterMore(filterData));
        break;
      default:
        break;
    }
  }, [title]);

  const [searchValue, setSearchValue] = useState<string>("");

  const setSelectedSubmit = () => {
    // let token = localStorage.getItem("token");
    // let decoded = decodeJwt(token ?? "");
    // let distributor = decoded.Distributor ?? "";
    const filterDatas = {
      // distributorCode: distributor,
      page: 0,
      size: 10,
      pageStatus: false,
      type: currentPath.includes("saleable") ? "saleable" : "non-saleable",
      stock: filterInv.basedOnStockSelected.toString(),
      division: filterInv.divisionSelected.toString(),
      segment: filterInv.segmentSelected.toString(),
      brand: filterInv.brandSelected.toString(),
      variant: filterInv.variantSelected.toString(),
      subVariant: filterInv.subVariantSelected.toString(),
      packagingType: filterInv.packagingTypeSelected.toString(),
      productSize: filterInv.sizeSelected.toString(),
      search: "",
    };
    switch (title) {
      case "Division":
        dispatch(updateDivisionPop(tempData));
        dispatch(updateDivision(tempData));
        filterDatas.division = tempData.toString();
        break;
      case "Segment":
        dispatch(updateSegmentPop(tempData));
        dispatch(updateSegment(tempData));
        filterDatas.segment = tempData.toString();
        break;
      case "Brand":
        dispatch(updateBrandPop(tempData));
        dispatch(updateBrand(tempData));
        filterDatas.brand = tempData.toString();
        break;
      case "Variant":
        dispatch(updateVariantPop(tempData));
        dispatch(updateVariant(tempData));
        break;
      case "Sub Variant":
        dispatch(updateSubVariantPop(tempData));
        dispatch(updateSubVariant(tempData));
        filterDatas.subVariant = tempData.toString();
        break;
      case "Packaging Type":
        dispatch(updatePackagingTypePop(tempData));
        dispatch(updatePackagingType(tempData));
        filterDatas.packagingType = tempData.toString();
        break;
      case "Size":
      case "SizeSales":
        dispatch(updateSizePop(tempData));
        dispatch(updateSize(tempData));
        filterDatas.productSize = tempData.toString();
        break;
      default:
        break;
    }

    // dispatch(getSaleableProducts(filterDatas));
  };

  const handleToggleFilter = (value: string) => {
    let currentVal;
    let isValueInArray;
    let newVal;

    switch (title) {
      case "Division":
        currentVal = filterInv.tempDivisionSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateDivisionTemp(newVal));
        break;
      case "Segment":
        currentVal = filterInv.tempSegmentSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateSegmentTemp(newVal));
        break;
      case "Brand":
        currentVal = filterInv.tempBrandSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateBrandTemp(newVal));
        break;
      case "Variant":
        currentVal = filterInv.tempVariantSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateVariantTemp(newVal));
        break;
      case "Sub Variant":
        currentVal = filterInv.tempSubVariantSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateSubVariantTemp(newVal));
        break;
      case "Packaging Type":
        currentVal = filterInv.tempPackagingTypeSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updatePackagingTypeTemp(newVal));
        break;
      case "Size":
      case "SizeSales":
        currentVal = filterInv.tempSizeSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateSizeTemp(newVal));
        break;
      default:
        break;
    }
  };

  let tempData: any[] = [];

  switch (title) {
    case "Division":
      filterOptions = filterInv.divisionMore.values;
      tempData = filterInv.tempDivisionSelected;
      break;
    case "Segment":
      filterOptions = filterInv.segmentMore.values;
      tempData = filterInv.tempSegmentSelected;
      break;
    case "Brand":
      filterOptions = filterInv.brandMore.values;
      tempData = filterInv.tempBrandSelected;
      break;
    case "Variant":
      filterOptions = filterInv.variantMore.values;
      tempData = filterInv.tempVariantSelected;
      break;
    case "Sub Variant":
      filterOptions = filterInv.subVariantMore.values;
      tempData = filterInv.tempSubVariantSelected;
      break;
    case "Packaging Type":
      filterOptions = filterInv.packagingTypeMore.values;
      tempData = filterInv.tempPackagingTypeSelected;
      break;
    case "Size":
      filterOptions = filterInv.sizeMore.values;
      tempData = filterInv.tempSizeSelected;
      break;
    case "SizeSales":
      filterOptions = size;
      tempData = filterInv.tempSizeSelected;
      break;
    default:
      break;
  }

  const handleSelectAll = () => {
    switch (title) {
      case "Division":
        if (tempData?.length === filterOptions?.length) {
          dispatch(updateDivisionTemp([]));
        } else {
          dispatch(updateDivisionTemp(filterOptions.map((option) => option)));
        }
        break;
      case "Segment":
        if (tempData?.length === filterOptions?.length) {
          dispatch(updateSegmentTemp([]));
        } else {
          dispatch(updateSegmentTemp(filterOptions.map((option) => option)));
        }
        break;
      case "Brand":
        if (tempData.length === filterOptions.length) {
          dispatch(updateBrandTemp([]));
        } else {
          dispatch(updateBrandTemp(filterOptions.map((option) => option)));
        }
        break;
      case "Variant":
        if (tempData.length === filterOptions.length) {
          dispatch(updateVariantTemp([]));
        } else {
          dispatch(updateVariantTemp(filterOptions.map((option) => option)));
        }
        break;
      case "Sub Variant":
        if (tempData.length === filterOptions.length) {
          dispatch(updateSubVariantTemp([]));
        } else {
          dispatch(updateSubVariantTemp(filterOptions.map((option) => option)));
        }
        break;
      case "Packaging Type":
        if (tempData.length === filterOptions.length) {
          dispatch(updatePackagingTypeTemp([]));
        } else {
          dispatch(
            updatePackagingTypeTemp(filterOptions.map((option) => option))
          );
        }
        break;
      case "Size":
      case "SizeSales":
        if (tempData?.length === filterOptions?.length) {
          dispatch(updateSizeTemp([]));
        } else {
          dispatch(updateSizeTemp(filterOptions.map((option) => option)));
        }
        break;
      default:
        break;
    }
  };

  const handleClearAll = () => {
    switch (title) {
      case "Division":
        dispatch(updateDivisionPop([]));
        dispatch(updateDivision([]));
        dispatch(updateDivisionTemp([]));
        break;
      case "Segment":
        dispatch(updateSegmentPop([]));
        dispatch(updateSegment([]));
        dispatch(updateSegmentTemp([]));
        break;
      case "Brand":
        dispatch(updateBrandPop([]));
        dispatch(updateBrand([]));
        dispatch(updateBrandTemp([]));
        break;
      case "Variant":
        dispatch(updateVariantPop([]));
        dispatch(updateVariant([]));
        dispatch(updateVariantTemp([]));
        break;
      case "Sub Variant":
        dispatch(updateSubVariantPop([]));
        dispatch(updateSubVariant([]));
        dispatch(updateSubVariantTemp([]));
        break;
      case "Packaging Type":
        dispatch(updatePackagingTypePop([]));
        dispatch(updatePackagingType([]));
        dispatch(updatePackagingTypeTemp([]));
        break;
      case "Size":
      case "SizeSales":
        dispatch(updateSizePop([]));
        dispatch(updateSize([]));
        dispatch(updateSizeTemp([]));
        break;
      default:
        break;
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleClose = () => {
    dispatch(sidebarFilterInv(false));
  };

  const popupRef = useRef<HTMLDivElement | null>(null); // To reference the popup container

  useEffect(() => {
    const handleClickOutside = (event : any) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClose(); // Close the popup if clicked outside
      }
    };

    const handleWindowBlur = () => {
      handleClose(); // Close the popup if the window loses focus
    };

    // Add event listeners for click outside and window blur
    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("blur", handleWindowBlur);

    // Clean up the event listeners when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("blur", handleWindowBlur);
    };
  }, [handleClose]);

  return (
    <Box
    ref={popupRef}

      className="sidebarModelContainerNew"
      sx={{ width: { sm: "25%", lg: "23%", xl: "20%" }, height: "100%" }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          padding: "10px",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={6}>
          <b style={{ fontSize: "10px" }}>{title}</b> &nbsp;&nbsp;
          {tempData?.length > 0 && (
            <b className="showMore_popup">
              {tempData?.length} Filters Selected
            </b>
          )}
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IoClose onClick={handleClose} style={{ cursor: "pointer" }} />
        </Grid>
      </Grid>
      <SearchBar
        onChange={(value) => handleSearch(value)}
        className="sidePopSearch"
        value={searchValue}
        onCancelResearch={() => setSearchValue("")}
      />
      <Grid
        container
        item
        xs={12}
        sx={{ display: "flex", padding: "0px 16px", alignItems: "center" }}
      >
        <Grid item xs={6} sx={{ fontSize: "12px", fontWeight: "700" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  tempData?.length === filterOptions?.length &&
                  filterOptions?.length !== 0
                }
                onChange={handleSelectAll}
                indeterminate={
                  tempData?.length > 0 &&
                  tempData?.length < filterOptions?.length
                }
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 15,
                    color: "#944986",
                  },
                }}
              />
            }
            label="Select All"
            sx={{
              ".MuiFormControlLabel-label": {
                display: "inline-block",
                borderBottom: "1.5px solid #bb90b3",
                cursor: "pointer",
                textAlign: "center",
                textDecoration: "underline",
                background: "linear-gradient(90deg, #614fb3 0%, #944986 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                lineHeight: 1.1,
                fontWeight: 900,
                fontSize: "10px !important",
              },
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            color: "#F5222D",
            fontSize: "10px",
            fontWeight: "600",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleClearAll}
        >
          <IoMdClose />
          &nbsp; Clear all
        </Grid>
      </Grid>
      <Box sx={{ padding: "0px 16px" }} className="sidePopScroll">
        <FormGroup>
          {filterOptions?.length !== 0 ? (
            filterOptions
              ?.filter((option) =>
                option.toLowerCase().includes(searchValue.toLowerCase())
              )
              .map((option) => (
                <FormControlLabel
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 15,
                      color: "#6750A4",
                    },
                  }}
                  key={option}
                  control={
                    <Checkbox
                      checked={tempData.includes(option)}
                      onChange={() => handleToggleFilter(option)}
                    />
                  }
                  label={option}
                />
              ))
          ) : (
            <Spin size="small" />
          )}
        </FormGroup>
      </Box>
      <Grid
        item
        mt={3}
        mb={2}
        sx={{ justifyContent: "space-evenly", marginLeft: "12px" }}
      >
        <Button
          onClick={handleClose}
          className="sidepopclose"
          variant="outlined"
        >
          Cancel
        </Button>

        <Button
          className="btn-colr sidepopsubmit"
          onClick={() => {
            handleClose();
            setSelectedSubmit();
          }}
          variant="contained"
        >
          Select
        </Button>
      </Grid>
    </Box>
  );
};

export default FilterModal;
