import React from "react";
import {
  Button,
  Grid,
  TextField,
  Box,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  InputLabel,
  Typography,
  Card,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { TableColumnsType } from "antd";
import PriceProductInfo from "./PriceProductInfo";
import UndoIcon from '@mui/icons-material/Undo';
// import ProductInfoView from "./ProductInfo";

interface DataType {
  key: React.Key;
  ReferenceNo: string;
}
interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const PriceCustomerTab: React.FC<DistributorTabProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };

  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };


  return (
    <>
      {show ? (
        // <></>
        <PriceProductInfo onBack={handleclick} />
      ) : (
        <Box>
          <form noValidate>
            <Grid
              container
              mt={3}
              rowSpacing={3}
              columnSpacing={{ xs: 3, sm: 10 }}
            >
              <Grid item xs={12} sm={6}>
                <Typography className="fnt-lable">Channel Name</Typography>
                <TextField placeholder="  " className="Txt-box" />
                <Button sx={{ color: "#fff", ml: 3 }} className="btn-colr">
                  ...
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              mt={3}
              rowSpacing={3}
              columnSpacing={{ xs: 3, sm: 10 }}
            >
              <Grid item xs={12} sm={6}>
                <Typography className="fnt-lable">Sub Channel Name</Typography>
                <TextField placeholder="  " className="Txt-box" />
                <Button sx={{ color: "#fff", ml: 3 }} className="btn-colr">
                  ...
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              mt={3}
              rowSpacing={3}
              columnSpacing={{ xs: 3, sm: 10 }}
            >
              <Grid item xs={12} sm={6}>
                <Typography className="fnt-lable">Group Name </Typography>
                <TextField placeholder="  " className="Txt-box" />
                <Button sx={{ color: "#fff", ml: 3 }} className="btn-colr">
                  ...
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              mt={3}
              rowSpacing={3}
              columnSpacing={{ xs: 3, sm: 10 }}
            >
              <Grid item xs={12} sm={6}>
                <Typography className="fnt-lable"> Class Name </Typography>
                <TextField placeholder="  " className="Txt-box" />
                <Button sx={{ color: "#fff", ml: 3 }} className="btn-colr">
                  ...
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", mt: 4 }}
            >
                  <Grid item mr={2}>
                  <Button variant="outlined" onClick={handleBackClick} >
                    <UndoIcon   sx={{ color: "", fontSize: "15px", marginRight: "10px" }}/>
                                Back
                            </Button>
              </Grid>
               
                            <Grid>
                <Button
                  className="btn-colr "
                  variant="contained"
                  onClick={handleclick}
                >
                  <RedoOutlinedIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />
                  Next
                </Button>
              </Grid>
              <Grid item sx={{ ml: 2 }}>
                <Button variant="outlined" onClick={handleBackClick}>
                  <CancelIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />{" "}
                  Cancel
                </Button>
              </Grid>
            
            </Grid>
          </form>
        </Box>
      )}
    </>
  );
};
export default PriceCustomerTab;
