import { useState, startTransition } from "react";
import {
  CardHeader,
  Card,
  CardContent,
  Avatar,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IoPinSharp } from "react-icons/io5";
import { FaMapPin, FaShip } from "react-icons/fa6";
import { RiSpaceShipLine } from "react-icons/ri";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
interface CardData {
  [key: string]: string | JSX.Element | undefined;
}

const DistributorDashboard: React.FC = () => {
  const cardData: CardData[] = [
    { Distributor: "Distributor ", Icons: <FaShip /> },
    { Distributor: "Distributor division mapping ", Icons: <IoPinSharp /> },
    { Distributor: "Route ", Icons: <RiSpaceShipLine /> },
    { Distributor: "Route coverage plan ", Icons: <FaMapPin /> },
    { Distributor: "Sales man", Icons: <FaArrowRightArrowLeft /> },
    { Distributor: "Sales man route mapping ", Icons: <IoPinSharp /> },
    { Distributor: "Salesman SKU line mapping ", Icons: <FaShip /> },
    { Distributor: "SalesMan target ", Icons: <FaArrowRightArrowLeft /> },
  ];

  const navigator = useNavigate();
  const handleCardClick = (index: number) => {
    startTransition(() => {
      const paths = [
        "/Distributor",
        "/Distribution",
        "/Route",
        "/RouteCoveragePlan",
        "/salesman",
        "/SMRouteMap",
        "/SalesmanSKULineMapping",
        "/Salesmantarget",
      ]; // Paths for each index
      navigator(paths[index]);
    });
  };

  return (
    <Grid item sx={{ p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
      <Grid container>
        <Grid item xs={12} className="mar-tb">
          <Typography style={{ fontSize: "14px", fontWeight: "800" }}>
            Distribution
          </Typography>
        </Grid>
        <Grid>
          <Typography className="cursor_pointer" style={{ color: "#b6b0b0" }}>
            <span onClick={() => navigator("/Dashboard")}>Dashboard</span>/
            <span>Distribution</span>
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
          gap: 2,
          marginTop: 2,
        }}
      >
        <Card>
          <CardHeader
            avatar={
              <Avatar
                aria-label="recipe"
                style={{
                  background:
                    " linear-gradient(90deg, rgb(97, 79, 179) 0%, rgb(148, 73, 134) 100%)",
                }}
              >
                M
              </Avatar>
            }
            title="Modules"
            subheader="Distribution"
          />
          <CardContent>
            <Grid container spacing={2}>
              {cardData.map((item, index) => (
                <Grid item xs={6} key={index}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={1}
                      style={{
                        fontSize: "1rem",
                        color: "#614fb3",
                      }}
                    >
                      {item.Icons}
                    </Grid>
                    <Grid item xs={11} onClick={() => handleCardClick(index)}>
                      <Typography className="hover-card">
                        {item.Distributor}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
};
export default DistributorDashboard;
