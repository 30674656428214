import { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  Drawer,
  FormControl,
  Grid,
  InputBase,
  Typography,
} from "@mui/material";
import Close from "../../../../../assets/images/icons/Close_icon.png";
import remove_line from "../../../../../assets/images/icons/remove_line.png";
import { Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useFetch";

import { IoClose } from "react-icons/io5";
import {
  getRejectApprovedList,
  getSalesReturnReasonList,
} from "../../../../../Redux/XDM/Sales/salesReturnReducer";

interface RequestData {
  returnId: string;
  outletName: string;
  invoiceNo: string;
  salesmanName: string;
  date: string;
  noOfLineItem: number;
  returnValue: number;
  status: string;
}

interface DiscrepancyProps {
  onClose: () => void;
  requestData: RequestData;
}

interface SelectedReason {
  id: string;
  returnReason: string;
}

interface SelectedReasonQty {
  id: string;
  qty: number;
}

interface qtyError {
  id: string;
  error: string;
}

const { Option } = Select;

export default function EditReturnSales(props: DiscrepancyProps) {
  const { onClose, requestData } = props; // Destructure the props
  const [editReturnQtyEr, setEditReturnQtyEr] = useState<string>("");
  const [editReturnQtyCompareEr, setEditReturnQtyCompareEr] =
    useState<string>("");
  const [editEnterQtyEr, setEditEnterQtyEr] = useState<qtyError[]>([]);
  const [showReason, setShowReason] = useState<boolean>(false);
  const [rejectedQty, setRejectedQty] = useState<number>(0);
  const [rejectedReason, setRejectedReason] = useState<string>("");

  const [reasonSelected, setReasonSelected] = useState<SelectedReason[]>([]);
  const [reasonQty, setReasonQty] = useState<SelectedReasonQty[]>([]);

  const abortControllerRef = useRef<AbortController | null>(null);

  const dispatch = useAppDispatch();

  const editFetchData = useAppSelector(
    (state) => state.salesReturnData.editFetchData
  );

  let reasonList = useAppSelector((state) => state.salesReturnData.reasonList);

  const handleSalesReturnEditQty = (value: string, invQty: number) => {
    const qty = parseInt(value, 10);
    setEditReturnQtyEr("");
    setEditReturnQtyCompareEr("");

    if (!qty || qty <= 0) {
      setEditReturnQtyEr("Please enter Rejected Quantity.");
    } else if (qty > invQty) {
      setEditReturnQtyCompareEr(
        "Rejected quantity cannot be greater than invoice quantity."
      );
    }

    setRejectedQty(isNaN(qty) ? 0 : qty);
    showReasonForReturn(invQty);

    const totalQty = reasonQty.reduce((total, item) => total + item.qty, 0);
    if (totalQty > qty) {
      setEditEnterQtyEr([
        ...editEnterQtyEr,
        {
          id: "reason",
          error: "Total quantity should not exceed rejected quantity.",
        },
      ]);
    } else {
      const erReason = reasonQty
        .filter(
          (item) => item.qty === 0 || isNaN(item.qty) || item.qty === null
        )
        .map((item) => {
          return {
            id: item.id,
            error: "Please enter quantity for this reason.",
          };
        });
      setEditEnterQtyEr(erReason);
      if (erReason.length > 0) {
        return;
      }
      setEditEnterQtyEr([]);
    }
    setReasonQty(reasonQty.map((item) => ({ id: item.id, qty: 0 })));
  };

  const showReasonForReturn = (value: number) => {
    if (value > 0) {
      setShowReason(true);
    } else {
      setShowReason(false);
    }
  };

  const handleReasonSelect = (data: SelectedReason[]) => {
    setReasonSelected(data);
    if (data.length > 0) {
      setRejectedReason("");
    }
    setReasonQty(data.map((item) => ({ id: item.id, qty: 0 })));
  };

  const handleReasonDeSelect = (data: SelectedReason) => {
    setReasonSelected(reasonSelected.filter((item) => item.id !== data.id));
    setReasonQty(reasonQty.filter((item) => item.id !== data.id));
    setEditEnterQtyEr(editEnterQtyEr.filter((item) => item.id !== data.id));
  };

  const handleReasonQtyChange = (index: number, id: string, value: string) => {
    const qty = parseFloat(value);
    const newReasonQty = [...reasonQty];
    if (!isNaN(qty)) {
      newReasonQty[index] = { id, qty: qty };
      setReasonQty(newReasonQty);
    } else {
      // remove the item from the array if the value is not a number
      newReasonQty[index] = { id, qty: 0 };
      setReasonQty(newReasonQty.filter((item) => item.id !== id));
    }

    const totalQty = newReasonQty.reduce((total, item) => total + item.qty, 0);

    if (totalQty !== rejectedQty) {
      setEditEnterQtyEr([
        ...editEnterQtyEr.filter((item) => item.id !== id),
        {
          id: id,
          error: "Please enter the quantities to match the rejected quantity.",
        },
      ]);
    } else {
      setEditEnterQtyEr(editEnterQtyEr.filter((item) => item.id !== id));
    }
  };

  const saveEditReturn = async () => {
    // if (rejectedQty === 0 || isNaN(rejectedQty) || rejectedQty === null) {
    //   setEditReturnQtyEr("Please enter Rejected Quantity.");
    //   return;
    // }
    if (rejectedQty > 0 && reasonSelected.length < 1) {
      setRejectedReason("Please select reason for return.");
      return;
    } else {
      setRejectedReason("");
    }
    if (rejectedQty > editFetchData.qty) {
      setEditReturnQtyCompareEr(
        "Rejected quantity cannot be greater than invoice quantity."
      );
      return;
    } else {
      setEditReturnQtyCompareEr("");
    }

    if (reasonSelected.length > 1) {
      const erReason = reasonQty
        .filter(
          (item) => item.qty === 0 || isNaN(item.qty) || item.qty === null
        )
        .map((item) => {
          return {
            id: item.id,
            error: "Please enter quantity for this reason.",
          };
        });
      setEditEnterQtyEr(erReason);
      if (erReason.length > 0) {
        return;
      }

      const totalQty = reasonQty.reduce((total, item) => total + item.qty, 0);
      const qtyEr = erReason
        .filter((item) => totalQty > rejectedQty)
        .map((item) => {
          return {
            id: item.id,
            error: "Please enter quantity for this reason.",
          };
        });

      setEditEnterQtyEr(qtyEr);
      if (qtyEr.length > 0) {
        return;
      }

      // Calculate the total quantity of reasons
      if (totalQty !== rejectedQty) {
        console.log(editEnterQtyEr, "editEnterQtyEr");
        setEditEnterQtyEr([
          ...editEnterQtyEr,
          {
            id: "reason",
            error: "Total quantity should match rejected quantity.",
          },
        ]);
        return;
      }
    }

    let rejectedLists = [
      {
        rejectedQty: 0,
        rejectedValue: 0,
        rejectionReason: "",
      },
    ];
    if (rejectedQty !== 0) {
      if (reasonSelected.length > 1) {
        rejectedLists = reasonSelected.map((reason) => {
          const qty = reasonQty.find((item) => item.id === reason.id);
          return {
            rejectedQty: qty?.qty || 0,
            rejectedValue: calcApproveRejectedValue(
              editFetchData.value,
              editFetchData.qty,
              qty?.qty || 0
            ),
            rejectionReason: reason.id,
          };
        });
      } else {
        rejectedLists = [
          {
            rejectedQty: rejectedQty,
            rejectedValue: calcApproveRejectedValue(
              editFetchData.value,
              editFetchData.qty,
              rejectedQty
            ),
            rejectionReason: reasonSelected[0]?.id,
          },
        ];
      }
    }

    const editReqData = {
      returnId: requestData.returnId,
      productList: [
        {
          productReturnNumber: editFetchData.productReturnNumber,
          approvedValue: calcApproveRejectedValue(
            editFetchData.value,
            editFetchData.qty,
            editFetchData.qty - rejectedQty || 0
          ),
          approvedQty: editFetchData.qty - rejectedQty || 0,
          rejectedList: rejectedLists,
        },
      ],
    };
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    try {
      const response = await dispatch(
        getRejectApprovedList({
          params: editReqData,
          signal: abortController.signal,
        })
      );
      if (response?.meta?.requestStatus === "fulfilled") {
        onClose();
      }
    } catch (error) {
      console.error("Save error", error);
    }
  };

  const calcApproveRejectedValue = (
    value: any,
    totalQty: number,
    rejectedQty: number
  ) => {
    return (value?.toFixed(2) / totalQty) * rejectedQty;
  };

  useEffect(() => {
    let totalQty = 0;
    let reason: SelectedReason[] = [];
    editFetchData.rejected?.forEach((item: any) => {
      totalQty += item.rejectedQty;
      if (item.rejectionReason && item.reasonId) {
        reason.push({
          id: item.reasonId,
          returnReason: item.rejectionReason,
        });
      }
    });
    setRejectedQty(totalQty);
    setReasonSelected(reason);
    showReasonForReturn(totalQty);
    // Set the reasonQty state
    setReasonQty(
      reason.map((item) => {
        const qty = editFetchData.rejected.find(
          (rejected: any) => rejected.reasonId === item.id
        )?.rejectedQty;
        return { id: item.id, qty: qty || 0 };
      })
    );
  }, [dispatch, editFetchData]);

  const handleApproveAll = () => {
    setReasonQty([]);
    setReasonSelected([]);
    setEditEnterQtyEr([]);
    setRejectedQty(0);
    setShowReason(false);
    setEditReturnQtyEr("");
  };

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="pen_drawer_stl"
        // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 500,
              md: 460,
              sm: 400,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
            backgroundColor: "#F6F6F6",
          }}
        >
          <Grid item sx={{ height: "100%" }}>
            <Grid
              container
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                background: "white",
                height: "60px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
              }}
            >
              <Grid item sm={7}>
                <Typography>Edit Item (1)</Typography>
              </Grid>
              <Grid item sm={5} sx={{ display: "flex", justifyContent: "end" }}>
                <img
                  onClick={onClose}
                  src={Close}
                  alt="Close"
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{ overflowY: "auto", height: "70%", padding: "20px" }}
              className="discrepancy_card"
            >
              <Card
                sx={{
                  border: "1px solid #DCDCDE",
                  padding: "15px",
                  borderRadius: "6px",
                  marginBottom: "10px",
                }}
              >
                <Grid container sx={{ alignItems: "center" }}>
                  <Grid item sm={10}>
                    <Grid item>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: "13px",
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                        }}
                      >
                        {editFetchData.productName} ({editFetchData.productCode}
                        )
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={2}
                    sx={{ display: "flex", justifyContent: "end" }}
                    onClick={onClose}
                  >
                    <img
                      src={remove_line}
                      alt="remove_line"
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  mt={2}
                  sx={{
                    gap: "10px",
                    border: "1px solid #DCDCDE",
                    padding: "8px",
                    borderRadius: "6px",
                  }}
                >
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Batch Code : {editFetchData.batchCode}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Expired Date : {editFetchData.expiryDate}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      UOM : {editFetchData.uom}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Quantity : {editFetchData.qty}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      MRP / PC : ₹ {editFetchData.mrp?.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      PTR / PC :₹ {editFetchData.ptr?.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Value :₹ {editFetchData.value?.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Reason for Return : {editFetchData.returnReason}
                    </Typography>
                  </Grid>
                  <div className="image_Container">
                    {editFetchData.img?.map((thumbnail: any, index: number) => (
                      <div className="Image_thumbnail" key={index}>
                        <img
                          src={thumbnail}
                          alt={`Thumbnail ${index + 1}`}
                          className="image_thumbnail_img"
                        />
                      </div>
                    ))}
                  </div>
                </Grid>
                <Grid item mt={2}>
                  <Typography
                    sx={{
                      color: "#44464C",
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Enter Rejected Quantity
                    <span style={{ color: "red" }}>&nbsp;*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} mt={1}>
                  <InputBase
                    value={rejectedQty === 0 ? "" : rejectedQty}
                    sx={{
                      border: "1px solid #DCDCDE",
                      width: "100%",
                      height: "38px",
                      paddingLeft: "10px",
                      color: "#505259",
                      fontSize: "10px",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      borderRadius: "4px",
                    }}
                    inputProps={{
                      maxLength: 10,
                    }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      target.value = target.value.replace(/[^0-9]/g, "");
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value)) {
                        handleSalesReturnEditQty(value, editFetchData.qty);
                      }
                    }}
                  />
                  {editReturnQtyEr && (
                    <Typography color="error" variant="caption">
                      {editReturnQtyEr}
                    </Typography>
                  )}
                  {editReturnQtyCompareEr && (
                    <Typography color="error" variant="caption">
                      {editReturnQtyCompareEr}
                    </Typography>
                  )}
                </Grid>
                {showReason && (
                  <Grid item>
                    <Grid item mt={2}>
                      <Typography
                        sx={{
                          color: "#44464C",
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Select Reason
                        <span style={{ color: "red" }}>&nbsp;*</span>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <FormControl
                        fullWidth
                        sx={{ marginTop: "9px", marginBottom: "2px" }}
                      >
                        <Select
                          className="Quentity_select_box"
                          mode="multiple"
                          showSearch
                          placeholder="Add Reason"
                          filterOption={(input, option) => {
                            const optionText =
                              option?.children?.toString().toLowerCase() || "";
                            return optionText.includes(input.toLowerCase());
                          }}
                          value={reasonSelected.map((item) => item.id)}
                          dropdownStyle={{ zIndex: 2000 }}
                          style={{ width: "100%", fontSize: "12px" }} // Adjust the width as necessary
                          onChange={(selectedIds) => {
                            const selectedReasons = selectedIds.map(
                              (id: any) => {
                                const reason = reasonList.find(
                                  (item: any) => item.id === id
                                );
                                return {
                                  id: reason.id,
                                  returnReason: reason.returnReason,
                                };
                              }
                            );
                            handleReasonSelect(selectedReasons);
                          }}
                          onDeselect={(deselectedId) => {
                            const deselectedReason = reasonList.find(
                              (item: any) => item.id === deselectedId
                            );
                            handleReasonDeSelect({
                              id: deselectedReason.id,
                              returnReason: deselectedReason.returnReason,
                            });
                          }}
                        >
                          {reasonList?.map((item: any) => (
                            <Option key={item.id} value={item.id}>
                              <div style={{ fontSize: "11px" }}>
                                {item.returnReason}
                              </div>
                            </Option>
                          ))}
                        </Select>
                      </FormControl>
                      {rejectedReason && (
                        <Typography color="error" variant="caption">
                          {rejectedReason}
                        </Typography>
                      )}
                    </Grid>
                    <Grid container mt={1}>
                      {reasonSelected.length > 1 &&
                        reasonSelected.map(
                          (item: SelectedReason, index: number) => (
                            <>
                              <Grid item>
                                <Typography
                                  sx={{
                                    color: "#44464C",
                                    fontFamily: "Montserrat",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                  }}
                                >
                                  Enter {item?.returnReason} Quantity
                                  <span style={{ color: "red" }}>&nbsp;*</span>
                                </Typography>
                              </Grid>
                              <Grid item xs={12} mt={1}>
                                <InputBase
                                  value={(() => {
                                    const qty = reasonQty.filter(
                                      (qty) => item.id === qty.id
                                    )[0]?.qty;
                                    return isNaN(qty) || qty === 0
                                      ? ""
                                      : qty.toString();
                                  })()}
                                  sx={{
                                    border: "1px solid #DCDCDE",
                                    width: "100%",
                                    height: "38px",
                                    paddingLeft: "10px",
                                    color: "#505259",
                                    fontSize: "10px",
                                    fontFamily: "Montserrat",
                                    fontWeight: 500,
                                    borderRadius: "4px",
                                  }}
                                  inputProps={{
                                    maxLength: 10,
                                  }}
                                  onInput={(e) => {
                                    const target = e.target as HTMLInputElement;
                                    target.value = target.value.replace(
                                      /[^0-9.]/g,
                                      ""
                                    );
                                  }}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*\.?\d*$/.test(value)) {
                                      handleReasonQtyChange(
                                        index,
                                        item.id,
                                        value
                                      );
                                    }
                                  }}
                                />
                              </Grid>
                              {editEnterQtyEr
                                .filter((er) => item.id === er.id) // Filter out items where item.error is false (e.g., empty string, null, undefined)
                                .map((er, index) => (
                                  <Typography
                                    key={index}
                                    color="error"
                                    variant="caption"
                                  >
                                    {er.error}
                                  </Typography>
                                ))}
                            </>
                          )
                        )}
                    </Grid>
                  </Grid>
                )}

                <Grid container mt={1}>
                  <Grid item sm={6}>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "11px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Approved Quantity : {editFetchData.qty - rejectedQty}
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "11px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Approved Value : ₹{" "}
                      {calcApproveRejectedValue(
                        editFetchData.value,
                        editFetchData.qty,
                        editFetchData.qty - rejectedQty || 0
                      ).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container mt={1}>
                  <Grid item sm={6}>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "11px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Rejected Quantity : {rejectedQty || 0}
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "11px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Rejected Value : ₹{" "}
                      {calcApproveRejectedValue(
                        editFetchData.value,
                        editFetchData.qty,
                        rejectedQty || 0
                      ).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                {/* Approve all button with ouline border success */}
                {rejectedQty > 0 && (
                  <Grid container mt={1}>
                    <Grid item sm={12} sx={{ width: "100%" }}>
                      <Button
                        className="approveAllBtn"
                        onClick={() => handleApproveAll()}
                      >
                        Approve All
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Card>
            </Grid>
            <Grid
              container
              sx={{
                position: "sticky",
                bottom: 85,
                height: "60px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
                background: "white",
              }}
            >
              <Grid item sm={6}></Grid>
              <Grid item sm={6}>
                <Button className="Cancle_btn_des" onClick={onClose}>
                  Cancel
                </Button>
                <Button className="Save_btn_des" onClick={saveEditReturn}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </Box>
  );
}
