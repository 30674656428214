import {
  Box,
  Card,
  Grid,
  Snackbar,
  Alert,
  Link,
} from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import SearchBar from "@mkyy/mui-search-bar";
import { Spin, Table, TableColumnsType, Tooltip } from "antd";
import debounce from 'lodash.debounce'
import {
  getOrderBillingInvoice,
} from "../../../Redux/XDM/Sales/orderBillingReducer";
import moment from "moment";
import { getInvoiceCounts } from "../../../Redux/XDM/Sales/InvoiceReducer";
import InvoiceDetails from "./PrimaryInvoiceReviewOrder";
import GrnReviewDetails from "./GrnReviewDetails";
import { getGrnHistory } from "../../../Redux/XDM/Purchase/GrnReducer";
import { updateDate, updateFromDate, updateStatusGrn, updateToDate } from "../../../Redux/XDM/Sales/billingReducer";

interface ResponseGrnHistory {
  GrnHistoryData: GrnHistoryDatatype;
  totalCount: number;
}
interface GrnHistoryDatatype {
  date: string;
  grnDate: string;
  grnNo: string;
  invoiceNo: string;
  orderId: string;
  value: number;
  noOfLineItems: number;
  invoiceDate: string;
  outlet: string;
  route: string;
  salesman: string;
  status: string;
}

type TableRowType = GrnHistoryDatatype | { noMoreData: boolean };




const GrnHistory: React.FC = () => {
  const [isShow, setIsShow] = React.useState(false);
  const [isGrnShow, setIsGrnShow] = React.useState(false);
  const [textFieldValue, setTextFieldValue] = React.useState("");
  const [ordersCount, setOrdersCount] = React.useState(0);
  const [orders, setOrders] = React.useState<GrnHistoryDatatype[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [commonLoader, setCommonLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const tableHeightRef = useRef(350);
  let orderBillingData = useAppSelector((state) => state.orderBillingData);
  let grnData = useAppSelector((state) => state.pendingGrnData);
  let orderBillingDataFetch = orderBillingData.orderBilling;
  const filterTags = useAppSelector((state) => state.orderBilling);
  const dispatch = useAppDispatch();
  const filterData = {
    page: 0,
    size: 0,
    status: filterTags.statusGrnSelected.toString(),
    date: filterTags.dateSelected,
    fromDate: filterTags.fromDateSelected,
    toDate: filterTags.toDateSelected,
    search: textFieldValue,
  };
  useEffect(() => {
    dispatch(getInvoiceCounts());
    dispatch(updateFromDate([]));
    dispatch(updateToDate([]));
    dispatch(updateDate([]));

  }, []);

  useEffect(() => {
    setPage(0)
    setHasMore(true);
    setOrders([]);
    setOrdersCount(0);
    loadMoreData(true);
  }, [
    textFieldValue,
    filterTags.dateSelected,
    filterTags.fromDateSelected,
    filterTags.statusGrnSelected,
    filterTags.toDateSelected,
  ]);

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 283;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;
    tableHeightRef.current = availableHeight;
  };


  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    window.addEventListener('resize', updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;

    dispatch(
      getGrnHistory({
        params: { ...filterData, page: nextPage },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const newOrders = response.payload.result || [];
          setOrdersCount(response.payload.totalCount);
          setOrders((prevOrders) => (resetPage ? newOrders : [...prevOrders, ...newOrders]));
          setPage(nextPage + 1);
          if (newOrders.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        } else {
          // setHasMore(false);
        }

      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Failed to fetch data:', error);
        }
        setCommonLoader(false);
      });
    return () => {
      window.removeEventListener('resize', updateTableHeight);
    };
  };
  const data_sales: GrnHistoryDatatype[] = orders ?? [];


  const handleGRNClick = (record: GrnHistoryDatatype) => {
    setRequestData(record);

    setIsGrnShow(!isGrnShow);
  }
  const handleInvoiceClick = (record: GrnHistoryDatatype) => {
    setRequestData(record);

    setIsShow(!isShow);
  }
  const [requestData, setRequestData] = React.useState({
    grnDate: "",
    grnNo: "",
    invoiceNo: "",
    orderId: "",
    value: 0,
    noOfLineItems: 0,
    status: "",
    outlet: "",
    route: "",
    salesman: "",
    date: "",
  });
  const columns: TableColumnsType<TableRowType> = [
    {
      dataIndex: "grnNumber",
      title: "GRN No",
      width: "15%",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <div
            style={{
              textDecoration: "underline",
              color: "#27538D",
              fontWeight: 600,
            }}
            onClick={() => handleGRNClick(record as GrnHistoryDatatype)}
          >
            {record.grnNo}
          </div>
        );
      },
    },
    {
      dataIndex: "invoiceNumber",
      title: "Invoice No",
      width: "15%",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <div
            style={{
              textDecoration: "underline",
              color: "#27538D",
              fontWeight: 600,
            }}
            onClick={() => handleInvoiceClick(record as GrnHistoryDatatype)}
          >
            {record.invoiceNo}
          </div>
        );
      },
    },
    // {
    //   dataIndex: "orderId",
    //   title: "Order ID",
    //   width: "15%",
    //   render: (text: string | number, record: TableRowType) => {
    //     if ("noMoreData" in record) {
    //       return {
    //         children: (
    //           <span
    //             className="fs-12 noMore"
    //             style={{ display: "block", margin: "10px" }}
    //           >
    //             <b>No more data found...</b>
    //           </span>
    //         ),
    //         props: {
    //           colSpan:7, // Adjust this number based on the total number of columns to span
    //         },
    //       };
    //     }
    //     return (
    //       <Tooltip placement="top" title={text} zIndex={999999}>
    //         <span>{text}</span>
    //       </Tooltip>
    //     );
    //   },
    // },
    // {
    //   dataIndex: "invoiceDate",
    //   width: "12%",
    //   title: "Invoice Date",
    //   sorter: (a, b) => {
    //     if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
    //     return a.invoiceDate.localeCompare(b.invoiceDate);
    //   },
    //   render: (_: any, record: TableRowType) => {
    //     if ("noMoreData" in record)
    //       return { children: null, props: { colSpan: 0 } };
    //     if (record.invoiceDate) {
    //       const dateObj = moment(record.invoiceDate, "YYYY-MM-DD");

    //       const formattedDate = dateObj.format("DD MMM YYYY");

    //       return <div>{formattedDate}</div>;
    //     }
    //     return "-";
    //   },
    // },
    {
      dataIndex: "grnDate",
      width: "12%",
      title: "Date",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
        return a.grnDate.localeCompare(b.grnDate);
      },
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        if (record.grnDate) {
          const dateObj = moment(record.grnDate, "YYYY-MM-DD");

          const formattedDate = dateObj.format("DD MMM YYYY");

          return <div>{formattedDate}</div>;
        }
        return "-";
      },
    },

    {
      dataIndex: "noOfLineItems",
      width: "15%",
      title: "No of Line Item",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return record.noOfLineItems;
      },
    },
    {
      dataIndex: "value",
      width: "15%",
      title: "Value",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
        return a.value - b.value;
      },
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <span>
            <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
            {record.value ? record.value.toFixed(2) : "0"}
          </span>
        );
      },
    },
    {
      dataIndex: "status",
      width: "15%",
      title: "Status",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        let color;
        if (record.status === "Accepted")
          color = "green";
        else if (record.status === "Rejected")
          color = "red";
        else if (record.status === "Partially Accepted") color = "orange";
        else color = "black";
        return (<b style={{ color: color }}>{record.status}</b>);
      },
    },
  ];

  const dataToShow: TableRowType[] =
    orders.length > 0
      ? hasMore
        ? orders
        : [...orders, { noMoreData: true }]
      : [];
  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector('.saleable-table-scroll .ant-table-body') as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const handleSearch = () => {
    setTextFieldValue(textFieldValue);
  };
  return (
    <Box>
      <Box flex={8} sx={{ overflowY: "auto", height: "100vh", mt: -5.3 }}>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={100000}
          color="green"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Card
          className="b-radius-0"
          style={{ marginTop: "2px", boxShadow: "none" }}
        >
          <Grid container spacing={3} justifyContent="start" p={2}>
            <Grid container mt={4} m={2}>
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <h3
                  style={{
                    marginTop: "0px",
                    marginLeft: "8px",
                    marginBottom: "16px",
                  }}
                ></h3>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <SearchBar
                  className="search_box_saleable w-100px"
                  placeholder="Search Invoice Number, ID...."
                  value={textFieldValue}
                   onChange={(newValue) => {
                      setTextFieldValue(newValue);
                    }}
                    onCancelResearch={() => setTextFieldValue("")}
                    onSearch={(newValue) => {
                      handleSearch();
                    }}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} sm={12} md={12} lg={12} className="pt-0">
              {((filterTags.dateSelected?.length !== 0 &&
                filterTags.dateSelected !== null) ||
                filterTags.statusGrnSelected?.length !== 0 ||
                textFieldValue !== "") && (
                <p style={{ marginTop: "0px", marginBottom: "10px" }}>
                  Showing Results based on selected filters{" "}
                  <b>({ordersCount} Products)</b>
                </p>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="pad-top-0px">
              <div className="saleable-table-scroll order-bill">
                <Table
                  className={`Header-table saleable-table sale_last_hide scroll_cus_table elipsTable ${
                    commonLoader ? " nodataloader" : ""
                  }`}
                  columns={columns}
                  dataSource={dataToShow}
                  pagination={false}
                  loading={page === 0 && commonLoader}
                  showSorterTooltip={false}
                  summary={() => {
                    return (
                      <>
                        {page !== 0 && commonLoader && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              index={1}
                              colSpan={6}
                              align="center"
                            >
                              <Spin />
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                      </>
                    );
                  }}
                  // onRow={(record: TableRowType) => {
                  //     if ("noMoreData" in record) return {};
                  //     return {
                  //         onClick: () =>
                  //             handleRowClick(record as GrnHistoryDatatype),
                  //     };
                  // }}
                  scroll={{ y: tableHeightRef.current }}
                />
              </div>
            </Grid>
          </Grid>
        </Card>
        {isGrnShow ? (
          <GrnReviewDetails
            requestData={requestData}
            onClose={() => setIsGrnShow(false)}
          />
        ) : (
          <>
            {isShow && (  
              <InvoiceDetails
                onClose={() => setIsShow(false)}
                requestData={requestData}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default GrnHistory;