import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useFetch";
import { getChequeApproveOrBounce } from "../../../../../Redux/XDM/Sales/collectionReducer";
import { Alert, Grid, Snackbar } from "@mui/material";
import Close from "../../../../../assets/images/icons/Close_icon.png";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "5px",
};

interface PopProps {
  onBack: () => void;
  count: number;
  amount: number;
  collectionIds: any;
  loadMore: () => void;
}

const PaymentReceivedPop: React.FC<PopProps> = ({
  onBack,
  count,
  amount,
  collectionIds,
  loadMore,
}) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(); // Pass the event argument to onBack function
  };
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);
  const dispatch = useAppDispatch();

  const abortControllerRef = React.useRef<AbortController | null>(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState<
    "success" | "error"
  >("success");

  let collectionReducerData = useAppSelector(
    (state) => state.collectionReducerData
  );

  const handleOk = async () => {
    // Add your logic here for handling the "OK" button click
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const data = collectionIds.map((id: string) => ({
      collectionId: id,
      status: "Approve",
    }));
    const formaData = {
      collectionDetails: data,
    };
    try {
      const response = await dispatch(
        getChequeApproveOrBounce({
          formData: formaData,
          signal: abortController.signal,
        })
      );
      if (response?.meta?.requestStatus === "fulfilled") {
        setSnackbarMessage("Payment Received Successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => {
          setOpen(false);
          onBack();
        }, 1000);
        loadMore();
      }
    } catch (error) {
      console.error("Submission error", error);
    }
  };

  const formatIndianNumber = (num: number | string): string => {
    const numStr = num.toString();
    const [integerPart, decimalPart] = numStr.split(".");
    let lastThreeDigits = integerPart.slice(-3);
    let otherDigits = integerPart.slice(0, -3);

    if (otherDigits !== "") {
      lastThreeDigits = "," + lastThreeDigits;
    }

    const formattedIntegerPart =
      otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

    return decimalPart
      ? formattedIntegerPart + "." + decimalPart.slice(0, 2)
      : formattedIntegerPart + ".00";
  };

  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={100000}
        color="green"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        onClose={handleClose}
        aria-describedby="modal-modal-description"
      >
        <Grid>
          <Box sx={style}>
            <Grid lg={10}></Grid>
            <Grid item xs={2} md={2} lg={2} sx={{ textAlign: "right" }}>
              <img
                src={Close}
                onClick={onBack}
                style={{ width: "14px", height: "14px", cursor: "pointer" }}
                alt="Close Icon"
              />
            </Grid>
            <Typography
              id="modal-modal-description"
              sx={{ fontSize: "13px", lineHeight: 2, fontWeight: "500" }}
            >
              Are you sure you have received <br />
              <span style={{ fontWeight: "800" }}>
                {count} cheque payments
              </span>{" "}
              worth{" "}
              <span style={{ fontWeight: "800" }}>
                ₹ {amount?.toFixed(2)} ?
              </span>
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "end", mt: 1 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleBackClick}
                sx={{ mr: 2, fontSize: "10px", height: "30px !important" }}
                className="crt-btn-cancel"
                disabled={collectionReducerData.chequeApproveOrBounceLoading}
              >
                Cancel
              </Button>
              <LoadingButton
                sx={{ fontSize: "10px", height: "30px" }}
                className="btn-colr"
                onClick={handleOk}
                loading={collectionReducerData.chequeApproveOrBounceLoading}
                variant="contained"
              >
                Yes
              </LoadingButton>
            </Box>
          </Box>
        </Grid>
      </Modal>
    </div>
  );
};

export default PaymentReceivedPop;
