// material-ui
import { Checkbox, Typography, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Box, Card, Divider, CardHeader } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Button from "@mui/material/Button";
import {
  setUserFromLocalStorage,
  toggleShowMore,
} from "../../../../Redux/AuthLogin/action";
// import { useDispatch, useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from "../../../../hooks/useFetch";
import {
  BrandCategoryFetchData,
  BrandLevelFetchData,
  BussinessLevelFetchData,
  CategoryLevelFetchData,
  ProductVarientLevelFetchData,
  SkuGroupFetchData,
  SkuLevelFetchData,
} from "../../../../Redux/Masters/Sidebar/action";
import { HSNcodeFetchData } from "../../../../Redux/Masters/Products/HSNcode/action";
import { ProductTaxFetchData } from "../../../../Redux/Masters/Products/ProductTaxMaster/action";

const DrawerContent = () => {
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const dispatch = useAppDispatch();

  const handleStaticDateChange = (date: any) => {
    if (startDate.isBefore(date)) {
      setEndDate(date);
    } else {
      setStartDate(date);
    }
  };
  const storedDistributionCode = useAppSelector(
    (state: any) => state.user.getLocalData
  );

  const handleShowMoreClick = () => {
    dispatch(toggleShowMore(true));
  };

  useEffect(() => {
    // Dispatch the action to retrieve the value from localStorage
    dispatch(setUserFromLocalStorage());

    if (filtername === "Product Master" || filtername === "Product Hierarchy") {
      dispatch(BussinessLevelFetchData({ pageStatus: true, search: "" }));
      dispatch(CategoryLevelFetchData({ pageStatus: true, search: "" }));
      dispatch(ProductVarientLevelFetchData({ pageStatus: true, search: "" }));
      if (filtername === "Product Hierarchy") {
        dispatch(BrandLevelFetchData({ pageStatus: true, search: "" }));
        dispatch(BrandCategoryFetchData({ pageStatus: true, search: "" }));
        dispatch(SkuGroupFetchData({ pageStatus: true, search: "" }));
        dispatch(SkuLevelFetchData({ pageStatus: true, search: "" }));
      }
    }
  }, [dispatch]);

  const filtername = useAppSelector(
    (state: any) => state.sidebarFilterOpen.filterOpen.name
  );
  const filterBusniessLevelCard = useAppSelector(
    (state: any) => state.sidebarFilter.bussinesslevelFetch
  );
  const filterCategoryLevelCard = useAppSelector(
    (state: any) => state.sidebarFilter.categorylevelFetch
  );
  const filterBrandLevelCard = useAppSelector(
    (state: any) => state.sidebarFilter.brandlevelFetch
  );
  const filterBrandCategoryCard = useAppSelector(
    (state: any) => state.sidebarFilter.brandCategorylevelFetch
  );
  const filterProductVarientCard = useAppSelector(
    (state: any) => state.sidebarFilter.productVarientlevelFetch
  );
  const filterSkuGroupCard = useAppSelector(
    (state: any) => state.sidebarFilter.skuGroupFetch
  );
  const filterSkulevelCard = useAppSelector(
    (state: any) => state.sidebarFilter.skulevelFetch
  );

  const handleCheckboxChange = (hsnType: string) => {
    //( Dispatch HSNcodeFetchData with the selected hsnType
    if (filtername == "HSN Code Master") {
      dispatch(HSNcodeFetchData({ page: 0, size: 3, hsnType }));
    } else {
      dispatch(
        ProductTaxFetchData({
          page: 0,
          size: 3,
          hsnType,
          category: "" as string,
        })
      );
    }
  };

  return (
    <>
      {!storedDistributionCode ? (
        <Box sx={{
          padding: "0px 5px 0px 16px",
          marginBottom: "100px",
          marginRight: "4.3px",
          marginTop: "4px",
          pb: { xs: "0px", sm: "0px", md: "100px" },
        }}
          className="dis-flex scrollHide ">
          <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px" }}>
            <Typography sx={{ fontSize: "10px", fontWeight: "700", mb: 1 }}>
              Date
            </Typography>
            <Divider />
            <FormControl className="radio-hd">
              <RadioGroup defaultValue="outlined" name="radio-buttons-group">
                <FormControlLabel
                  value="outlined"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 12,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Last 7 Days"
                />
                <FormControlLabel
                  value="soft"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 12,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Last MOC"
                />
                <FormControlLabel
                  value="solid"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 12,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Last Year"
                />
                <FormControlLabel
                  value="plain"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 12,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Custom"
                />
              </RadioGroup>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["From", "To"]}>
                <DemoItem label="From">
                  <MobileDatePicker value={startDate} readOnly />
                </DemoItem>
                <DemoItem label="To">
                  <MobileDatePicker value={endDate} readOnly />
                </DemoItem>
              </DemoContainer>
              <StaticDatePicker
                className="date-picker"
                value={startDate}
                onChange={handleStaticDateChange}
              />
            </LocalizationProvider>
          </Card>
          <Button variant="contained" fullWidth className="btn-apply">
            Apply
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            padding: "0px 5px 0px 16px",
            marginBottom: "100px",
            marginRight: "4.3px",
            marginTop: "4px",
            pb: { xs: "0px", sm: "0px", md: "100px" },
          }}
          className="dis-flex dis-flex-admin scrollHide"
        >
          {(filtername === "HSN Code Master" ||
            filtername === "Product Tax Master") && (
              <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
                <Typography sx={{ fontSize: "10px", fontWeight: "700", mb: 1 }}>
                  Tax Type
                </Typography>
                <Divider />
                <FormControl className="" sx={{ mt: 1 }}>
                  <FormControlLabel
                    value="outlined"
                    control={
                      <Checkbox
                        className="sidebar_checkbox"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 15,
                            color: "#6750A4",
                          },
                        }}
                        onChange={() => handleCheckboxChange("Inter")}
                      />
                    }
                    label={
                      <div style={{ paddingTop: "2px", paddingLeft: "5px" }}>
                        Inter
                      </div>
                    }
                  />
                  <FormControlLabel
                    value="soft"
                    control={
                      <Checkbox
                        className="sidebar_checkbox"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 15,
                            color: "#6750A4",
                          },
                        }}
                        onChange={() => handleCheckboxChange("Intra")}
                      />
                    }
                    label={
                      <div style={{ paddingTop: "2px", paddingLeft: "5px" }}>
                        Intra
                      </div>
                    }
                  />
                </FormControl>
              </Card>
            )}
          {filterBusniessLevelCard &&
            (filtername === "Product Master" ||
              filtername === "Product Hierarchy") && (
              <Card
                variant="outlined"
                sx={{ padding: "5px 8px 0px 8px", mb: 1 }}
              >
                <Typography sx={{ fontSize: "10px", fontWeight: "700", mb: 1 }}>
                  Business Level Name (100)
                </Typography>
                <Divider />
                <FormControl className="" sx={{ mt: 1 }}>
                  {Array.isArray(filterBusniessLevelCard.values) &&
                    filterBusniessLevelCard.values.map(
                      (values: any, index: number) => (
                        <FormControlLabel
                          key={index}
                          value={values}
                          control={
                            <Checkbox
                              className="sidebar_checkbox"
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 15,
                                  color: "#6750A4",
                                },
                              }}
                            />
                          }
                          label={
                            <div
                              style={{ paddingTop: "2px", paddingLeft: "5px" }}
                              className="filter_lable"
                            >
                              {values}
                            </div>
                          }
                        />
                      )
                    )}
                </FormControl>
                <Grid container spacing={2}>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={4}>
                    <Typography
                      onClick={handleShowMoreClick}
                      style={{ cursor: "pointer" }}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "700",
                        color: "#87CEEB",
                      }}
                    >
                      + {filterBusniessLevelCard.count} More
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            )}
          {filterCategoryLevelCard &&
            (filtername === "Product Master" ||
              filtername === "Product Hierarchy") && (
              <Card
                variant="outlined"
                sx={{ padding: "5px 8px 0px 8px", mb: 1 }}
              >
                <Typography sx={{ fontSize: "10px", fontWeight: "700", mb: 1 }}>
                  Category Level (200)
                </Typography>
                <Divider />
                <FormControl className="" sx={{ mt: 1 }}>
                  {Array.isArray(filterCategoryLevelCard.values) &&
                    filterCategoryLevelCard.values.map(
                      (values: any, index: number) => (
                        <FormControlLabel
                          key={index}
                          value={values}
                          control={
                            <Checkbox
                              className="sidebar_checkbox"
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 15,
                                  color: "#6750A4",
                                },
                              }}
                            />
                          }
                          label={
                            <div
                              style={{ paddingTop: "2px", paddingLeft: "5px" }}
                              className="filter_lable"
                            >
                              {values}
                            </div>
                          }
                        />
                      )
                    )}
                </FormControl>
                <Grid container spacing={2}>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "700",
                        color: "#87CEEB",
                      }}
                    >
                      + {filterCategoryLevelCard.count} More
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            )}
          {filterProductVarientCard &&
            (filtername === "Product Master" ||
              filtername === "Product Hierarchy") && (
              <Card
                variant="outlined"
                sx={{ padding: "5px 8px 0px 8px", mb: 1 }}
              >
                <Typography sx={{ fontSize: "10px", fontWeight: "700", mb: 1 }}>
                  Product/varient Level (300)
                </Typography>
                <Divider />
                <FormControl className="" sx={{ mt: 1 }}>
                  {Array.isArray(filterProductVarientCard.values) &&
                    filterProductVarientCard.values.map(
                      (values: any, index: number) => (
                        <FormControlLabel
                          key={index}
                          value={values}
                          control={
                            <Checkbox
                              className="sidebar_checkbox"
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 15,
                                  color: "#6750A4",
                                },
                              }}
                            />
                          }
                          label={
                            <div
                              style={{ paddingTop: "2px", paddingLeft: "5px" }}
                              className="filter_lable"
                            >
                              {values}
                            </div>
                          }
                        />
                      )
                    )}
                </FormControl>
                <Grid container spacing={2}>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "700",
                        color: "#87CEEB",
                      }}
                    >
                      + {filterProductVarientCard.count} More
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            )}
          {filterBrandLevelCard && filtername === "Product Hierarchy" && (
            <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
              <Typography sx={{ fontSize: "10px", fontWeight: "700", mb: 1 }}>
                Brand Level (400)
              </Typography>
              <Divider />
              <FormControl className="" sx={{ mt: 1 }}>
                {Array.isArray(filterBrandLevelCard.values) &&
                  filterBrandLevelCard.values.map(
                    (values: any, index: number) => (
                      <FormControlLabel
                        key={index}
                        value={values}
                        control={
                          <Checkbox
                            className="sidebar_checkbox"
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 15,
                                color: "#6750A4",
                              },
                            }}
                          />
                        }
                        label={
                          <div
                            style={{ paddingTop: "2px", paddingLeft: "5px" }}
                            className="filter_lable"
                          >
                            {values}
                          </div>
                        }
                      />
                    )
                  )}
              </FormControl>
              <Grid container spacing={2}>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                  <Typography
                    onClick={handleShowMoreClick}
                    style={{ cursor: "pointer" }}
                    sx={{
                      fontSize: "12px",
                      fontWeight: "700",
                      color: "#87CEEB",
                    }}
                  >
                    + {filterBrandLevelCard.count} Mores
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          )}
          {filterBrandCategoryCard && filtername === "Product Hierarchy" && (
            <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
              <Typography sx={{ fontSize: "10px", fontWeight: "700", mb: 1 }}>
                Brand Category Level (500)
              </Typography>
              <Divider />
              <FormControl className="" sx={{ mt: 1 }}>
                {Array.isArray(filterBrandCategoryCard.values) &&
                  filterBrandCategoryCard.values.map(
                    (values: any, index: number) => (
                      <FormControlLabel
                        key={index}
                        value={values}
                        control={
                          <Checkbox
                            className="sidebar_checkbox"
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 15,
                                color: "#6750A4",
                              },
                            }}
                          />
                        }
                        label={
                          <div
                            style={{ paddingTop: "2px", paddingLeft: "5px" }}
                            className="filter_lable"
                          >
                            {values}
                          </div>
                        }
                      />
                    )
                  )}
              </FormControl>
              <Grid container spacing={2}>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "700",
                      color: "#87CEEB",
                    }}
                  >
                    + {filterBrandCategoryCard.count} More
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          )}
          {filterSkuGroupCard && filtername === "Product Hierarchy" && (
            <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
              <Typography sx={{ fontSize: "10px", fontWeight: "700", mb: 1 }}>
                SKU Group (600)
              </Typography>
              <Divider />
              <FormControl className="" sx={{ mt: 1 }}>
                {Array.isArray(filterSkuGroupCard.values) &&
                  filterSkuGroupCard.values.map(
                    (values: any, index: number) => (
                      <FormControlLabel
                        key={index}
                        value={values}
                        control={
                          <Checkbox
                            className="sidebar_checkbox"
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 15,
                                color: "#6750A4",
                              },
                            }}
                          />
                        }
                        label={
                          <div
                            style={{ paddingTop: "2px", paddingLeft: "5px" }}
                            className="filter_lable"
                          >
                            {values}
                          </div>
                        }
                      />
                    )
                  )}
              </FormControl>
              <Grid container spacing={2}>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "700",
                      color: "#87CEEB",
                    }}
                  >
                    + {filterSkuGroupCard.count} More
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          )}
          {filterSkulevelCard && filtername === "Product Hierarchy" && (
            <Card
              variant="outlined"
              sx={{ padding: "5px 8px 10px 8px", mb: 1 }}
            >
              <Typography sx={{ fontSize: "10px", fontWeight: "700", mb: 1 }}>
                SKU Level (700)
              </Typography>
              <Divider />
              <FormControl className="" sx={{ mt: 1 }}>
                {Array.isArray(filterSkulevelCard.values) &&
                  filterSkulevelCard.values.map(
                    (values: any, index: number) => (
                      <FormControlLabel
                        key={index}
                        value={values}
                        control={
                          <Checkbox
                            className="sidebar_checkbox"
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 15,
                                color: "#6750A4",
                              },
                            }}
                          />
                        }
                        label={
                          <div
                            style={{ paddingTop: "2px", paddingLeft: "5px" }}
                            className="filter_lable"
                          >
                            {values}
                          </div>
                        }
                      />
                    )
                  )}
              </FormControl>
              <Grid container spacing={2}>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "700",
                      color: "#87CEEB",
                    }}
                  >
                    + {filterSkulevelCard.count} More
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          )}
        </Box>
      )}
    </>
  );
};

export default DrawerContent;
