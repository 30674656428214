// import * as React from 'react';
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button, Grid, Paper, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import RetailerForm from "./RetailerForm";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  Channelcode: number;
  Channelname: string;
  action: string;
}

const RetailerChannel: React.FC = () => {
  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // search function logic here
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "Channelcode",
      title: "Channel Code/Sub Channel Code",
    },
    {
      dataIndex: "Channelname",
      title: "Channel Name/Sub Channel Name",
      sorter: (a, b) => a.Channelname.length - b.Channelname.length,
    },
    {
      dataIndex: "action",
      title: "Action",
      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            onClick={(event) => handleViewClick(event)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
        </>
      ),
    },
  ];

  const data: DataType[] = [
    { key: 1, Channelcode: 100, Channelname: "Channelname1", action: "" },
    { key: 2, Channelcode: 200, Channelname: "Channelname2", action: "" },
    { key: 3, Channelcode: 300, Channelname: "Channelname3", action: "" },
    { key: 4, Channelcode: 400, Channelname: "Channelname4", action: "" },
    { key: 5, Channelcode: 500, Channelname: "Channelname5", action: "" },
  ];
  const [isViewing, setIsViewing] = React.useState(false);

  const handleViewClick = (event: React.MouseEvent<unknown>) => {
    setIsViewing(!isViewing);
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className="mar-tb">
          <Typography>
            <b>Retailer Channel</b>
          </Typography>
        </Grid>
        <Box>
          {isViewing ? (
            <RetailerForm onBack={(event) => handleViewClick(event)} />
          ) : (
            <>
              <Grid item>
                <Box>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "end", mb: 2 }}
                  >
                    <SearchBar
                      className="search-table"
                      value={textFieldValue}
                      onChange={(newValue) => {
                        setTextFieldValue(newValue);
                        handleSearch();
                      }}
                    />
                  </Grid>
                  <CommonTable
                    //  rowSelection={rowSelection}
                    data={data}
                    columns={columns}
                  />
                </Box>
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
export default RetailerChannel;
