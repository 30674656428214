import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  SetStateAction,
} from "react";
import Box from "@mui/material/Box";
import { Drawer, Grid, Typography } from "@mui/material";
import Close from "../../../assets/images/icons/Close_icon.png";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { ReactComponent as NearToExpirySVG } from "../../../assets/images/icons/time-exp.svg";
import { ReactComponent as NearToExpiryWarnSVG } from "../../../assets/images/icons/time-warning.svg";
import { ReactComponent as SchemeSVG } from "../../../assets/images/icons/scheme.svg";
import type { TableColumnsType } from "antd";
import { Spin, Table, Tooltip } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import {
  getSaleableExtendedDetails,
  getSaleableProductDetails,
  getSaleableProductsList,
} from "../../../Redux/XDM/Inventory/saleableReducer";
import { decodeJwt } from "../../../utils/constant";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import debounce from "lodash.debounce";
import { DownOutlined } from "@ant-design/icons";

interface RequestData {
  nearToExpiry: boolean;
  productCode: string;
  productName: string;
  productHierarchy: string;
  division: string;
  stockInHand: number;
  value: number;
  lastPurchaseDate: string;
  scheme: boolean;
}

interface StockProductDetail {
  productDescription: string;
  matGrp2Desc: string;
  productCode: string;
  batchCode: string;
  saleableStock: number;
  saleableStockValue: number;
  margin: number;
  mrp: number;
  manufactureDate: string;
  expiryDate: string;
  daysToExpire: number;
}

interface StockDetail {
  productDescription: string;
  matGrp2Description: string;
  productCode: string;
  stockInHand: number;
  stockValue: number;
  division: string;
  margin: number;
  mrp: number;
  expiryDate: string;
  productDetails: StockProductDetail[];
  daysToExpire: number;
  expiryColor: string;
}

type TableRowType = StockDetail | { noMoreData: boolean };

const NearToExpiryIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={NearToExpirySVG} viewBox="0 0 16 16" />
);
const NearToExpiryWarnIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={NearToExpiryWarnSVG} viewBox="0 0 16 16" />
);

const SchemeIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={SchemeSVG} viewBox="0 0 24 24" />
);

interface SaleableProductsProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
}

export default function SaleableProducts(props: SaleableProductsProps) {
  const { onClose, requestData } = props; // Destructure the props

  let saleabledData = useAppSelector((state) => state.saleableData);
  let ProductDetails = saleabledData.saleableProductData;
  const dispatch = useAppDispatch();
  // let token = localStorage.getItem("token");
  // let decoded = decodeJwt(token ?? "");
  // let distributor = decoded.Distributor ?? "";
  const [hasMore, setHasMore] = useState(true);
  const [commonLoader, setCommonLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [products, setProducts] = React.useState<StockDetail[]>([]);
  const tableHeightRef = useRef(200);
  const filterData = {
    // distributorCode: distributor,
    productHierarchy: requestData.productHierarchy,
    // productCode: "CS0004BLK07S",
    page: 0,
    size: 0,
  };
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);

  const handleExpand = (expanded: boolean, record: StockDetail) => {
    const newExpandedRowKeys = expanded ? [record.productCode] : [];

    setExpandedRowKeys(newExpandedRowKeys);
  };
  useEffect(() => {
    dispatch(
      getSaleableProductDetails({
        // distributorCode: distributor,
        productCode: requestData.productHierarchy,
        type: "sale",
      })
    );
    setProducts([]);
    loadMoreData(true);
  }, [requestData]);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 413;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    setHasMore(true);
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;
    filterData.page = nextPage;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;

    dispatch(getSaleableExtendedDetails(filterData)).then((response: any) => {
      if (response.payload) {
        const newProducts = response.payload || [];
        setProducts((prevProducts) =>
          resetPage ? newProducts : [...prevProducts, ...newProducts]
        );
        setPage(nextPage + 1);
        if (newProducts.length < 15) {
          setHasMore(false);
        }
      } else {
        // setHasMore(false);
      }
      setCommonLoader(false);
    });

    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };
  const formatDate = (date: string) => {
    if (date) {
      const dateObj = moment(date, "YYYY-MM-DD");
      return dateObj.format("DD MMM YYYY");
    }
    return "-";
  };
  const stockDetailColumns: TableColumnsType<StockDetail> = [
    {
      title: "Product Description",
      dataIndex: "productDescription",
      key: "productDescription",
      ellipsis: true,
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        const desc = record.matGrp2Description
          ? record.matGrp2Description
          : record.productDescription
          ? record.productDescription
          : "--";
        return (
          <Tooltip placement="top" title={desc} zIndex={999999}>
            <span>{desc?desc:"--"}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Product Code",
      dataIndex: "productCode",
      key: "productCode",
      ellipsis:true,
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        // const displayText =
        //   text.length > 15 ? `${text.substring(0, 15)}...` : text;
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text ? text :"--"}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Batch Code",
      ellipsis:true,
      dataIndex: "batchCode",
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        // const displayText =
        //   text.length > 15 ? `${text.substring(0, 15)}...` : text;
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Stock in Hand(in Pcs)",
      dataIndex: "stockInHand",
      key: "stockInHand",
      render: (text: string | number, record: TableRowType) => {
        if ("noMoreData" in record) {
          return {
            children: (
              <span
                className="fs-12 noMore"
                style={{ display: "block", margin: "10px" }}
              >
                <b>No more data found...</b>
              </span>
            ),
            props: {
              colSpan: 9, // Adjust this number based on the total number of columns to span
            },
          };
        }
        return text;
      },
    },
    {
      title: "Stock Value",
      dataIndex: "stockValue",
      key: "stockValue",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <>
            <CurrencyRupeeIcon sx={{ fontSize: 10 }} />{" "}
            {record.stockValue ? record.stockValue.toFixed(2) : "0.00"}
          </>
        );
      },
    },
     {
      title: "Margin",
      dataIndex: "margin",
      key: "margin",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <>
            {record.margin ? record.margin : "0"} %
          </>
        );
      },
    },
     {
      title: "MRP",
      dataIndex: "mrp",
      key: "mrp",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <>
           ₹ {record.mrp ? record.mrp.toFixed(2) : "0.00"} 
          </>
        );
      },
    },
     {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
          const dateObj = moment(record.expiryDate);
          const formattedDate = dateObj.format("DD MMM YYYY");
          return <div>{formattedDate}</div>;
      },
    },
    {
        title: "Days to Expire",
        dataIndex: "expiryColor",
        key: "expiryColor",
        render: (text: any, record: TableRowType) => {
          let classes = "";
          let icon = null;
          if (text === "Red") {
            classes += " bg-red";
            icon = (
              <NearToExpiryIcon className="icon near-to-expiry scheme-icons" />
            );
          } else if (text === "Yellow") {
            classes += " bg-warning";
            icon = (
              <NearToExpiryWarnIcon className="icon near-to-expiry scheme-icons" />
            );
          } else {
            classes += " bg-success";
          }
           if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
          return (
            <div className={`w-100px daystoexpirey ${classes}`}>
              {icon} {record.daysToExpire} Days
            </div>
          );
        },
      },
   
  ];
  // const expandedRowRender = (record: StockDetail) => {
  //   const columns: TableColumnsType<StockProductDetail> = [
  //     {
  //       title: "Margin",
  //       dataIndex: "margin",
  //       key: "margin",
  //       render: (margin: number) => <>{margin} %</>,
  //     },
  //     {
  //       title: "MRP",
  //       dataIndex: "mrp",
  //       key: "mrp",
  //       render: (mrp: number) => <>{mrp?.toFixed(2)}</>,
  //     },
  //     {
  //       title: "Batch Code",
  //       dataIndex: "batchCode",
  //       key: "batchCode",
  //       render: (batchCode: string) => (
  //         <Tooltip placement="top" title={batchCode} zIndex={999999}>
  //           <span>{batchCode}</span>
  //         </Tooltip>
  //       ),
  //     },
  //     {
  //       title: "Stock in Hand(in Pcs)",
  //       dataIndex: "saleableStock",
  //       key: "saleableStock",
  //       render: (margin: number) => <>{margin ? margin : 0}</>,
  //     },
  //     {
  //       title: "Stock Value",
  //       dataIndex: "saleableStockValue",
  //       key: "saleableStockValue",
  //       render: (mrp: number) => <>{mrp ? mrp?.toFixed(2) : 0}</>,
  //     },
  //     {
  //       title: "Expiry Date",
  //       dataIndex: "expiryDate",
  //       key: "expiryDate",
  //       render: (expiryDate: string) => {
  //         const dateObj = moment(expiryDate);
  //         const formattedDate = dateObj.format("DD MMM YYYY");
  //         return <div>{formattedDate}</div>;
  //       },
  //     },
  //     {
  //       title: "Days to Expire",
  //       dataIndex: "daysToExpire",
  //       key: "daysToExpire",
  //       render: (daysToExpire: number, record: StockProductDetail) => {
  //         let classes = "";
  //         let icon = null;
  //         if (record.expiryColor === "Red") {
  //           classes += " bg-red";
  //           icon = (
  //             <NearToExpiryIcon className="icon near-to-expiry scheme-icons" />
  //           );
  //         } else if (record.expiryColor === "Yellow") {
  //           classes += " bg-warning";
  //           icon = (
  //             <NearToExpiryWarnIcon className="icon near-to-expiry scheme-icons" />
  //           );
  //         } else {
  //           classes += " bg-success";
  //         }
  //         return (
  //           <div className={`w-100px daystoexpirey ${classes}`}>
  //             {icon} {daysToExpire} Days
  //           </div>
  //         );
  //       },
  //     },
  //   ];

  //   return (
  //     <Table
  //       columns={columns}
  //       dataSource={record.productDetails}
  //       pagination={false}
  //     />
  //   );
  // };

  const dataToShow: StockDetail[] = hasMore ? products : [...products];
  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_stl"
        // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 1160,
              md: 536,
              sm: 600,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "100%" }} role="presentation">
            <Grid
              container
              className="crt-header"
              mt={1}
              style={{ padding: "16px 16px 0px 16px" }}
            >
              <Typography
                gutterBottom
                className="crt-header-lable"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "1000px",
                  whiteSpace: "nowrap",
                }}
                title={
                  requestData.productName +
                  "(" +
                  requestData.productHierarchy +
                  ")"
                }
              >
                {requestData.productName} ({requestData.productHierarchy})
              </Typography>

              <Grid item>
                <img
                  src={Close}
                  onClick={() => {
                    onClose();
                  }}
                  style={{ width: "24px", height: "24px", cursor: "pointer" }}
                  alt="Close Icon"
                />
              </Grid>
            </Grid>
            <Divider />
          </Grid>
          {/* Content */}
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form saleable-products-box"
          >
            <Grid
              item
              sx={{
                width: "95%",
                margin: "auto",
                marginBottom: "10px",
              }}
            >
              <Accordion className="accordian-products">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography gutterBottom className="crt-header-lable" mb={0}>
                    Product Details
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Typography className="crt-fnt-lable-light" mt={1}>
                        Division
                      </Typography>
                      <Typography
                        gutterBottom
                        className="crt-lable-input"
                        mt={1}
                      >
                        {ProductDetails?.division}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Typography className="crt-fnt-lable-light" mt={1}>
                        Segment
                      </Typography>
                      <Typography
                        gutterBottom
                        className="crt-lable-input"
                        mt={1}
                      >
                        {ProductDetails?.segment}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Typography className="crt-fnt-lable-light" mt={1}>
                        Brand
                      </Typography>
                      <Typography
                        gutterBottom
                        className="crt-lable-input"
                        mt={1}
                      >
                        {ProductDetails?.brand}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Typography className="crt-fnt-lable-light" mt={1}>
                        Variant
                      </Typography>
                      <Typography
                        gutterBottom
                        className="crt-lable-input"
                        mt={1}
                      >
                        {ProductDetails?.variant}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Typography className="crt-fnt-lable-light" mt={1}>
                        Sub Variant
                      </Typography>
                      <Typography
                        gutterBottom
                        className="crt-lable-input"
                        mt={1}
                      >
                        {ProductDetails?.subVariant}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Typography className="crt-fnt-lable-light" mt={1}>
                        Package Type
                      </Typography>
                      <Typography
                        gutterBottom
                        className="crt-lable-input"
                        mt={1}
                      >
                        {ProductDetails?.packageType}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Typography className="crt-fnt-lable-light" mt={1}>
                        Size
                      </Typography>
                      <Typography
                        gutterBottom
                        className="crt-lable-input"
                        mt={1}
                      >
                        {ProductDetails?.size}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid
              item
              sx={{
                width: "95%",
                margin: "auto",
                marginBottom: "10px",
              }}
            >
              <Typography gutterBottom className="crt-header-lable">
                Stock Details
              </Typography>
              <Divider />
              <Grid container mt={1}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Stock In Hand
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {Number(requestData?.stockInHand)} Pcs
                    {/* Pcs (
                    {(() => {
                      const totalNoOfPcs = Number(ProductDetails?.totalNoOfPcs);
                      const totalSaleableStock = Number(
                        requestData?.stockInHand
                      );
                      const cs =
                        totalSaleableStock !== 0
                          ? Math.floor(totalSaleableStock / totalNoOfPcs)
                          : 0;
                      const pc =
                        totalSaleableStock !== 0
                          ? totalSaleableStock % totalNoOfPcs
                          : 0;

                      const finalCs = isFinite(cs) ? cs : 0;
                      const finalPc = isFinite(pc) ? pc : 0;

                      return `${finalCs?.toFixed(0)} Cs, ${finalPc} Pc`;
                    })()}
                    ) */}
                  </Typography>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={3} lg={3}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Available UOM
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    Cases, Pieces
                  </Typography>
                </Grid> */}
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Last Purchased
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {requestData && requestData.lastPurchaseDate ? (
                      <div>{formatDate(requestData.lastPurchaseDate)}</div>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Stock Value
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {requestData?.value
                      ? `₹${Number(requestData.value).toFixed(2)}`
                      : "0.00"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="saleable-table-scroll productData ">
                    <Table
                      className={`saleable-product-table scroll_cus_table  sale-p ${
                        saleabledData.saleableExtendedDataLoading
                          ? " nodataloader"
                          : ""
                      }`}
                      columns={stockDetailColumns}
                      dataSource={dataToShow.map((item) => ({
                        ...item,
                        key: item.productCode,
                      }))}
                      pagination={false}
                      loading={
                        !page && saleabledData?.saleableExtendedDataLoading
                      }
                      summary={() => {
                        return (
                          <>
                            {page !== 0 &&
                            saleabledData?.saleableExtendedDataLoading ? (
                              <Table.Summary.Row>
                                <Table.Summary.Cell
                                  index={1}
                                  colSpan={9}
                                  align="center"
                                >
                                  <Spin />
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            ) : (
                              !hasMore && (
                                <Table.Summary.Row>
                                  <Table.Summary.Cell
                                    index={1}
                                    colSpan={9}
                                    align="center"
                                    className="fs-12 noMore btm_boreder"
                                  >
                                    <b>No more data found...</b>
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                              )
                            )}
                          </>
                        );
                      }}
                      scroll={{ y: tableHeightRef.current }}
                      showSorterTooltip={true}
                      // expandable={{
                      //   expandedRowRender,
                      //   expandedRowKeys,
                      //   onExpand: handleExpand,
                      // }}
                      size="small"
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
