import React from "react";
import {
  Button,
  Grid,
  TextField,
  Box,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  InputLabel,
  Typography,
  Card,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { SelectChangeEvent } from "@mui/material/Select";
import { TableColumnsType } from "antd";
import CommonTable from "../../../../components/Tables/CommonTable";
import ProductInfoView from "./ProductInfo";

// import DistributorSales from "./DistributorSales";
interface DataType {
  key: React.Key;
  DistributorNo: string;
  DistributorName: string;
}

interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const DistributorRetailer: React.FC<DistributorTabProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        //   width: 250,
      },
    },
  };
  const handleChanges = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const Options = ["Super Stockiest", "Sub Stockiest", "Distributor"];
  

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };

  //table
  const columns: TableColumnsType<DataType> = [
    {
      title: "Distributor No",
      dataIndex: "DistributorNo",
    },
    {
      title: "Distributor Name",
      dataIndex: "DistributorName",
      //   sorter: (a, b) => a.CreatedDate.length - b.CreatedDate.length,
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      DistributorNo: "GT24000200800400030001824",
      DistributorName: "101 COMMERCE SERVICES PRIVATE",
    },
    {
      key: "2",
      DistributorNo: "GT24000200800400030001824",
      DistributorName: "101 COMMERCE SERVICES PRIVATE",
    },
  ];
  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    //search function logic here
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <>
      {show ? (
       <ProductInfoView onBack={handleclick}/>
      ) : (
        <Box>
          <form noValidate>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 10 }}>
            <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable" >Reference No </Typography>
                <TextField placeholder="Reference No" className="Txt-box" />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">Distributor Type</Typography>
                <FormControl fullWidth >
                  <Select labelId="demo-simple-select-label" className="selct-box">
                    {Options.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable"> LOB Value </Typography>
                <TextField placeholder=" LOB Value "className="Txt-box"  />
                <Button sx={{ color: "#fff", ml: 3 }} className="btn-colr">
                  ...
                </Button>
              </Grid>
           
            </Grid>
            <Grid item mt={3}>
              <Card className="card-form" sx={{ border: "1px solid #EEE" }}>
                <Button variant="contained" className="btn-apply">
                  Filter Based On{" "}
                </Button>
                <Grid item>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Sales hierarchy	"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 18,
                                color: "#6750A4",
                              },
                            }}
                          />
                        }
                        label="Sales hierarchy	"
                      />
                      <FormControlLabel
                        value="Geography hierarchy	"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 18,
                                color: "#6750A4",
                              },
                            }}
                          />
                        }
                        label="Geography hierarchy	"
                      />
                      <FormControlLabel
                        value=" Distributor"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 18,
                                color: "#6750A4",
                              },
                            }}
                          />
                        }
                        label=" Distributor
"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Card>
            </Grid>
            <Grid item mt={3}>
              <CommonTable
                // rowSelection={rowSelection}
                data={data}
                columns={columns}
              />
            </Grid>
            <Grid item>EFFECT OF DISCOUNTS</Grid>

            <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 10 }}>

              <Grid item xs={12} sm={6}>
                <Typography className="fnt-lable"> Retaile Channel </Typography>
                <TextField placeholder=" Retaile Channel "className="Txt-box"  />
                <Button sx={{ color: "#fff", ml: 3 }} className="btn-colr">
                  ...
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="fnt-lable"> Retailer sub channgel </Typography>
                <TextField placeholder=" Retailer sub channgel  " className="Txt-box"  />
                <Button sx={{ color: "#fff", ml: 3 }} className="btn-colr">
                  ...
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="fnt-lable">Retailer Group </Typography>
                <TextField placeholder=" Retailer Group " className="Txt-box"  />
                <Button sx={{ color: "#fff", ml: 3 }} className="btn-colr">
                  ...
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className="fnt-lable">Retailer class</Typography>
                <TextField placeholder="Retailer class" className="Txt-box" />
                <Button sx={{ color: "#fff", ml: 3 }} className="btn-colr">
                  ...
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", mt: 4 }}
            >
              <Grid item sx={{ mr: 2 }}>
                <Button variant="outlined" onClick={handleBackClick}>
                  <CancelIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />{" "}
                  Cancel
                </Button>
              </Grid>
              <Grid>
                <Button
                  className="btn-colr "
                  variant="contained"
                  onClick={handleclick}
                >
                  <RedoOutlinedIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />
                  Next
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </>
  );
};
export default DistributorRetailer;
