import {
  PRODUCT_TAX_SUBMIT_FORM,
  PRODUCT_TAX_Submit_ERROR,
  PRODUCT_TAX_FETCH_DATA,
  PRODUCT_Tax_ACTIVE_STATUS,
  PRODUCT_TAX_EDIT_DATA,
  PRODUCT_TAX_UPDATE_DATA,
  PRODUCT_TAX_UPDATE_ERROR,
  PRODUCT_TAX_FETCH_PRODUCT_NAME,
} from "../../../actionType";
import { Dispatch } from "redux";
import createAxiosInstance from "../../../../hooks/useFetch";
import { ProductTaxFormValues } from "../../../../typescript/product";
const axiosInstance = createAxiosInstance();

export interface ProductTaxSubmitAction {
  type: typeof PRODUCT_TAX_SUBMIT_FORM;
  payload: any;
}

export interface ProductTaxSubmitErrorAction {
  type: typeof PRODUCT_TAX_Submit_ERROR;
  payload: string;
}
export interface ProductTaxGetAction {
  type: typeof PRODUCT_TAX_FETCH_DATA;
  payload: any;
}
export interface ProductTaxActiveStatus {
  type: typeof PRODUCT_Tax_ACTIVE_STATUS;
  payload: any;
}
export interface ProductTaxEdit {
  type: typeof PRODUCT_TAX_EDIT_DATA;
  payload: any;
}
export interface ProductUpdate {
  type: typeof PRODUCT_TAX_UPDATE_DATA;
  payload: any;
}
export interface ProductUpdateErrorAction {
  type: typeof PRODUCT_TAX_UPDATE_ERROR;
  payload: string;
}
export interface ProductTaxFetchProName {
  type: typeof PRODUCT_TAX_FETCH_PRODUCT_NAME;
  payload: any;
}

export const ProductTaxSubmitForm =
  (productCode: string, hsnCode: string, gstCode: string) =>
  async (
    dispatch: Dispatch<ProductTaxSubmitAction | ProductTaxSubmitErrorAction>
  ) => {
    try {
      const response = await axiosInstance.post(`product-tax/create`, {
        productCode,
        hsnCode,
        gstCode,
      });
      dispatch({
        type: PRODUCT_TAX_SUBMIT_FORM,
        payload: response.data, // Assuming response contains data
      });
      return response.data;
    } catch (error: any) {
      dispatch({
        type: PRODUCT_TAX_Submit_ERROR,
        payload: error.response.data.data,
      });
      throw error;
    }
  };

export const ProductTaxFetchData = (requestData: {
  page: number;
  size: number;
  hsnType: string;
  category: string;
}) => {
  return (dispatch: Dispatch<ProductTaxGetAction>) => {
    axiosInstance
      .get(`product-tax/get`, {
        params: requestData, // Pass requestData as query parameters
      })
      .then((res) => {
        dispatch({
          type: PRODUCT_TAX_FETCH_DATA, // Change this to COMPANY_FETCH_DATA
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};

export const ProductTaxFetchProductName = (id: string) => {
  return (dispatch: Dispatch<ProductTaxFetchProName>) => {
    axiosInstance
      .get(`product-master/get-product-name/${id}`)
      .then((res) => {
        dispatch({
          type: PRODUCT_TAX_FETCH_PRODUCT_NAME, // Change this to COMPANY_FETCH_DATA
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};
export const ProductTaxActiveStatus = ({
  id,
  status,
}: {
  id: string;
  status: boolean;
}) => {
  return (dispatch: Dispatch<ProductTaxActiveStatus>) => {
    axiosInstance
      .put(
        `product-tax/active-in-active`,
        { id, status } // Pass requestData as query parameters
      )
      .then((res) => {
        dispatch({
          type: PRODUCT_Tax_ACTIVE_STATUS, // Change this to COMPANY_FETCH_DATA
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};

export const ProductTaxEdit = (id: string) => {
  return (dispatch: Dispatch<ProductTaxEdit>) => {
    axiosInstance
      .get(`product-tax/get-byid//${id}`)
      .then((res) => {
        dispatch({
          type: PRODUCT_TAX_EDIT_DATA, // Dispatch the type of action
          payload: res.data.data, // Pass any data received from the API response
        });
      })
      .catch((error) => {
        // Handle errors if any
      });
  };
};

export const ProductTaxUpdate =
  ({ id, formData }: { id: string; formData: ProductTaxFormValues }) =>
  async (dispatch: Dispatch<ProductUpdate | ProductUpdateErrorAction>) => {
    try {
      const response = await axiosInstance.put(
        `product-tax/update/${id}`,
        formData
      );
      dispatch({
        type: PRODUCT_TAX_UPDATE_DATA,
        payload: response.data, // Assuming response contains data
      });

      return response.data;
    } catch (error: any) {
      dispatch({
        type: PRODUCT_TAX_UPDATE_ERROR,
        payload: error.response.data.data,
      });
      throw error;
    }
  };
