import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import { Drawer, Grid, Typography, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import type { TableColumnsType } from "antd";
import { Spin, Table, Tooltip } from "antd";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { ArrowBackIos } from "@mui/icons-material";
import SearchBar from "@mkyy/mui-search-bar";
import InvoiceDetails from "./InvoiceDetails";
import debounce from "lodash.debounce";
import {
  emptyOutletDetails,
  getOutletDetails,
  getOutletdetailsInfo,
  getOutletInvoiceDetails,
  getRecentInvoiceDetails,
} from "../../../Redux/XDM/Sales/outletReducer";
import OutletCollection from "./OutletCollection";
interface OutletDetailsProps {
  onClose: () => void; // Define the onClose prop
  outletCode: string;
}

interface RequestData{
  invoiceNumber: string;
  invoiceValue: string;
  outstandingAmt: string;
  paymentSts: string;
  isCollection: boolean;
}

interface RecentInvoiceData {
  outletCode: string;
  orderNumber: string;
  invoiceNumber: string;
  invoiceDate: string;
  invoiceStatus: string;
  invoiceValue: string;
  noOfLineItems: string;
  outstandingAmt: string;
  paymentSts: string;
  isCollection: boolean;
}

interface OutletInvoiceDatatype {
  outletCode: string;
  orderNumber: string;
  invoiceNumber: string;
  invoiceDate: string;
  invoiceStatus: string;
  invoiceValue: string;
  noOfLineItems: string;
  outstandingAmt: string | null;
  paymentSts: string;
  isCollection: boolean;
  collectionDetailsSize: number;
}

type TableRowType = OutletInvoiceDatatype | { noMoreData: boolean };

export default function OutletDetails(props: OutletDetailsProps) {
  const { onClose, outletCode } = props; // Destructure the props
  let outletDatas = useAppSelector((state) => state.outletData);
  let outletDetailsData = outletDatas.outletDetailsData;
  let outletDetailsDataInvoice = outletDatas.outletInvoiceData;

  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [hasMore, setHasMore] = useState(true);

  const [commonLoader, setCommonLoader] = useState(false);
  const [page, setPage] = useState(0);
  const tableHeightRef = useRef(190);
  const tableBodyRef = useRef<HTMLElement | null>(null);
  const filterData = {
    page: 0,
    size: 100,
    search: searchValue,
    outletCode: outletCode,
  };
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    setHasMore(true);
    setPendingCollectionList([]);
    loadMoreData(true);
    // if (abortControllerRef.current) {
    //   abortControllerRef.current.abort(); // Abort the previous request
    // }

    // const abortController = new AbortController();
    // abortControllerRef.current = abortController;
    // dispatch(
    //   getRecentInvoiceDetails({
    //     params: filterData,
    //     signal: abortController.signal,
    //   })
    // );
  }, [dispatch, searchValue, outletCode]);

  useEffect(() => {
    // Dispatch the action to fetch data when the component mounts
    dispatch(getOutletDetails(outletCode));
    dispatch(getOutletInvoiceDetails(outletCode));
    dispatch(getOutletdetailsInfo(outletCode));
  }, [dispatch, outletCode]);

  
  let outletTotalCollection = useAppSelector(
    (state) => state.outletData.ouletdetailsInfoData
  );

  const [isShow, setIsShow] = React.useState(false);
  const [isInvoiceShow, setIsInvoiceShow] = React.useState(false);

  const [pendingCollectionList, setPendingCollectionList] = React.useState<
    OutletInvoiceDatatype[]
  >([]);

  const handleSearch = () => {
    setSearchValue(searchValue);
  };
  const handleRowClick = (record: RecentInvoiceData) => {
    setRequestData(record);
    setIsShow(!isShow);
  };

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 383;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const handleInvoiceNo = (data:RecentInvoiceData) => {
    setIsInvoiceShow(true);
    setRequestData(data);
  };

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;

    filterData.size = tableHeightRef.current > 550 ? 25 : 100;

    dispatch(
      getRecentInvoiceDetails({
        params: filterData,
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const collectionHistory = response.payload.data || [];

          setPendingCollectionList((prevCollection) =>
            resetPage
              ? collectionHistory
              : [...prevCollection, ...collectionHistory]
          );
          setPage(nextPage + 1);
          if (collectionHistory.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        } else {
          // setHasMore(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };
  
  const handleClose = () => {
    dispatch(emptyOutletDetails([]));
    onClose();
  }

  const recentInvoiceData: RecentInvoiceData[] =
    outletDatas?.recentInvoiceData ?? [];

  const columns1: TableColumnsType<RecentInvoiceData> = [
    {
      dataIndex: "invoiceNumber",
      title: "Invoice Number",
      width: "15%",
      // sorter: (a, b) => a.invoiceNumber.localeCompare(b.invoiceNumber),
      render: (text: string, record: RecentInvoiceData) => {
        return (
          <div
            style={{
              textDecoration: "underline",
              color: "#27538D",
              fontWeight: 600,
            }}
            onClick={() => handleRowClick(record)}
          >
            {text}
          </div>
        );
      },
    },
    {
      dataIndex: "orderNumber",
      title: "Order ID",
      width: "15%",
      // sorter: (a, b) => a.orderNumber.localeCompare(b.orderNumber),
      render: (text: string) => {
        // const displayText =
        //   text.length > 15 ? `${text.substring(0, 15)}...` : text;
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text}</span>
          </Tooltip>
        );
      },
    },

    {
      dataIndex: "invoiceDate",
      width: "15%",
      title: "Invoice Date",
      align: "center",
      // sorter: (a, b) => a.invoiceDate.localeCompare(b.invoiceDate),
      render: (_: any, record: RecentInvoiceData) => {
        if (record.invoiceDate) {
          const dateObj = moment(
            record.invoiceDate,
            "ddd MMM DD HH:mm:ss [IST] YYYY"
          );

          const formattedDate = dateObj.format("DD MMM YYYY");

          return <div>{formattedDate}</div>;
        }
        return "-";
      },
    },
    {
      dataIndex: "noOfLineItems",
      width: "15%",
      title: "No. of Line Items",
      align: "center",
      // sorter: (a, b) => a.noOfLineItems.localeCompare(b.noOfLineItems),
    },
    {
      dataIndex: "invoiceValue",
      width: "15%",
      title: "Invoice Amount",
      align: "right",
      render: (text: string, record: RecentInvoiceData) => {
        return (
          <div className="ellipseclass">
            ₹{" "}
            {record.invoiceValue
              ? Number(record.invoiceValue).toFixed(2)
              : "0.00"}
          </div>
        );
      },
      // sorter: (a, b) => a.invoiceValue.localeCompare(b.invoiceValue),
    },
    {
      dataIndex: "outstandingAmt",
      width: "15%",
      title: "Outstanding Amount",
      align: "right",
      render: (text: string, record: RecentInvoiceData) => {
        return (
          <div className="ellipseclass">
            ₹{" "}
            {record.outstandingAmt
              ? Number(record.outstandingAmt).toFixed(2)
              : "0.00"}
          </div>
        );
      },
    },
    {
      dataIndex: "paymentSts",
      width: "15%", 
      title: "Payment Status",
      align: "center",
      render: (text: string, record: RecentInvoiceData) => {
        let status;
        let color;

        if (record.paymentSts === "Pending" && record.isCollection) {
          status = "Partially Received";
          color = "#F28500";
        } else if (record.paymentSts === "Pending" && !record.isCollection) {
          status = "Pending";
          color = "#E12B1D";
        } else if (record.paymentSts === "Received") {
          status = "Received";
          color = "#52A13D";
        } else {
          status = "--";
          color = "default"; // You can set this as a default value
        }

        return <div style={{color: color, fontWeight: "bold" }}>{status}</div>;
      },
    },
    {
      dataIndex: "collection",
      width: "15%",
      title: "Collection",
      align: "center",
      render: (_: any, record: RecentInvoiceData) => {
        let isDisable: boolean;
        let cls: string;

        if (record?.paymentSts === "Pending") {
          if (record?.isCollection) {
            isDisable = false;
            cls = "show-btn-collection";
          } else {
            isDisable = true;
            cls = "hide-btn-collection";
          }
        } else if (record?.paymentSts === "Received") {
          isDisable = false;
          cls = "show-btn-collection";
        } else {
          isDisable = true;
          cls = "hide-btn-collection";
        }
        return (
          <div>
            <Button
              variant="contained"
              className={"crt-btn-cancel " + cls}
              style={{ height: "30px", width: "115px", fontSize: "10px" }}
              type="button"
              sx={{ mr: 2 }}
              onClick={() => handleInvoiceNo(record)}
              disabled={isDisable}
            >
              View Collection
            </Button>
          </div>
        );
      },
    },
    {
      title: "Invoice Status",
      dataIndex: "invoiceStatus",
      width: "15%",
      key: "invoiceStatus",
      align: "center",
      // sorter: (a, b) => a.invoiceStatus.localeCompare(b.invoiceStatus),
      render: (status: string) => {
        const color = status.toLowerCase() === "active" ? "red" : "green";
        return <span style={{ color: color, fontWeight: "bold" }}>{status}</span>;
      },
    },
  ];

  const dataToShow: TableRowType[] =
    pendingCollectionList.length > 0
      ? hasMore
        ? pendingCollectionList
        : [...pendingCollectionList, { noMoreData: true }]
      : [];

  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const [requestData, setRequestData] = React.useState({
    outletCode: "",
    invoiceNumber: "",
    invoiceDate: "",
    invoiceStatus: "",
    orderNumber: "",
    invoiceValue: "",
    noOfLineItems: "",
    outstandingAmt: "",
    paymentSts: "",
    isCollection: false,
  });

  const [collectionData, setCollectionData] = React.useState({
    invoiceNumber: "",
    invoiceValue: "",
    outstandingAmt: "",
    paymentSts: "",
    isCollection: false,
  })
  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_outlet w-100px"
        // variant="persistent"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid
            container
            className="crt-header"
            mt={2}
            sx={{ padding: "16px 16px 0px 16px" }}
          >
            <Grid item sm={7.5} role="presentation">
              <Typography gutterBottom className="outlet-header-lable">
                <ArrowBackIos
                  sx={{
                    fontSize: "10px",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    handleClose();
                  }}
                />
                {}
                {outletDetailsData?.outletName } / {outletDetailsData?.outletCode}
                {/* {requestData.productName} ({requestData.productHierarchy}) */}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={2.5} lg={2.5}>
              <SearchBar
                className="search_box_saleable w-100px"
                placeholder="Search Invoice Number, ID...."
                value={searchValue}
                onChange={(newValue) => {
                  setSearchValue(newValue);
                }}
                onCancelResearch={() => setSearchValue("")}
                onSearch={(newValue) => {
                  handleSearch();
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ gap: "15px" }}
            style={{ padding: "0px 10px 0px 29px" }}
          >
            <Grid item>
              <Typography gutterBottom className="Collection_lable">
                No. Of Invoices : {outletTotalCollection?.invoiceCount}
              </Typography>
            </Grid>
            <Grid item>
              <Typography gutterBottom className="Collection_lable">
                Total Sales Amount : ₹ {outletTotalCollection?.totalNetAmount.toFixed(2)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography gutterBottom className="Collection_lable">
                Total Outstanding Amount : ₹ {outletTotalCollection.balanceAmount.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ width: "100%" }} />

          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form saleable-products-box"
          >
            {" "}
            <Grid
              item
              sx={{
                width: "95%",
                margin: "auto",
                marginBottom: "90px",
              }}
            >
              {" "}
              <Grid container mt={1}>
                <Grid item xs={12} sm={6} md={4} lg={3.0}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Outlet ID
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {outletDetailsData?.outletCode}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3.0}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Outlet group
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {outletDetailsData?.outletGroup}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3.0}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Outlet Class
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {outletDetailsData?.outletClass}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3.0}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    GST Number
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {outletDetailsData?.gstNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3.0}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Owner Name
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {outletDetailsData?.outletOwnerName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3.0}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Contact Person
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {outletDetailsData?.contactPerson}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3.0}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Contact Number
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {outletDetailsData?.contactMobile}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3.0}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Address
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {outletDetailsData?.address}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="pad-top-0px"
                >
                  <div
                    className="saleable-table-scroll pad_tabRt sale_last_hide"
                    id="tableScrollFull"
                    // style={{ height: "400px", overflowY: "auto" }}
                  >
                    <Table
                      className="Header-table saleable-table scroll_cus_table elipsTable"
                      // rowSelection={rowSelection}
                      columns={columns1}
                      dataSource={recentInvoiceData}
                      pagination={false}
                      // scroll={{ x: true }}
                      loading={commonLoader}
                      showSorterTooltip={false}
                      // onRow={(record) => ({
                      //   onClick: () => handleRowClick(record),
                      // })}
                      scroll={{ y: 270 }}
                    />
                    {/* <div className="saleable-table-scroll">
                      <Table
                        className={`Header-table saleable-table sale_last_hide scroll_cus_table elipsTable pending_grn ${
                          commonLoader ? " nodataloader" : ""
                        }`}
                        // rowSelection={rowSelection}
                        columns={columns1}
                        dataSource={dataToShow}
                        pagination={false}
                        loading={!page && commonLoader}
                        showSorterTooltip={false}
                        summary={() => {
                          return (
                            <>
                              {page !== 0 && commonLoader && (
                                <Table.Summary.Row>
                                  <Table.Summary.Cell
                                    index={1}
                                    colSpan={9}
                                    align="center"
                                  >
                                    <Spin />
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                              )}
                            </>
                          );
                        }}
                        scroll={{ y: tableHeightRef.current }}
                      />
                    </div> */}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
      {isShow ? (
        <InvoiceDetails
          onClose={() => setIsShow(false)}
          requestData={requestData}
        />
      ) : (
        <>
          {" "}
          {isInvoiceShow && (
            <OutletCollection
              onClose={() => setIsInvoiceShow(false)}
              requestDataCollection={requestData}
            />
          )}
        </>
      )}
    </Box>
  );
}
