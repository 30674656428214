import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Checkbox,
  Drawer,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import { Col, Input, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import CommonTable from "../../../components/Tables/CommonTable";
//icons
import Addicon from "../../../assets/images/icons/add.png";
import Rightdot from "../../../assets/images/icons/Right_dot.png";
import MasterSupplierEdit from "./SupplierEditform";
import MasterSupplierCreate from "./MasterSupplierCreate";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import {
  supplierActiveStatus,
  supplierFetchData,
  SupplierEdit,
} from "../../../Redux/Masters/Supplier/action";
import { styled } from "@mui/material/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import { Preview } from "@mui/icons-material";
import TableSkeletonLoader from "../../../components/Skeleton/skeleton";
import Modal from "@mui/material/Modal";
import * as XLSX from "xlsx";
import { IoClose } from "react-icons/io5";

// types declaration
interface DataType {
  key: React.Key;
  id: string;
  code: string;
  supplierName: string;
  companyName: string;
  status: string;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};

export default function MasterSupplier() {
  const dispatch = useAppDispatch();
  const renderAfterCalled = useRef(false);

  const [isEditing, setIsEditing] = React.useState(false);
  const [textFieldValue, setTextFieldValue] = React.useState("");
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [open, setOpen] = React.useState(false);
  const [editingData, setEditingData] = React.useState<DataType | null>(null);
  const [isShow, setIsShow] = React.useState(false);
  const [checkboxStatus, setCheckboxStatus] = useState<{ [key: string]: any }>(
    {}
  );
  const [requestData, setRequestData] = useState({ page: 0, size: 10 });
  const [prevData, setPrevData] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [skeleton, setskeleton] = useState(true);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isUpload, setIsUpload] = React.useState(false);
  const [uploadError, setUploadError] = React.useState("");


  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleSearch = () => {
    //search function logic here
  };

  //Form Edit
  const handleEditClick = (rowData: DataType) => {
    setIsEditing(!isEditing);
    toggleDrawer(true);
    setEditingData(rowData); // Set editingData when edit button is clicked
    dispatch(SupplierEdit(rowData.id));
  };

  const handleShowClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setIsShow(!isShow);
    toggleDrawer(true);
  };

  //fetch data from db

  const supplierFetchDatas = useAppSelector(
    (state: any) => state.supplierFetch.supplierFetch
  );
  const supplierLength = supplierFetchDatas?.length;
  useEffect(() => {
    if (prevData) {
      const initialCheckboxStatus: { [key: string]: any } = {};
      prevData.forEach((record: any) => {
        initialCheckboxStatus[record.id] = record.status;
      });
      setCheckboxStatus(initialCheckboxStatus);
    }
  }, [prevData]);

  useEffect(() => {
    if (Array.isArray(supplierFetchDatas)) {
      if (requestData.page === 0) {
        setPrevData(supplierFetchDatas);
        setskeleton(false);
      } else {
        setPrevData((prevData) => [...prevData, ...supplierFetchDatas]);
        setskeleton(false);
      }
    } else {
      setskeleton(false);
      console.error("supplierFetchDatas is not an array:", supplierFetchDatas);
    }
  }, [supplierFetchDatas]);

  const fetchMoreData = () => {
    const nextPage = requestData.page + 1;
    const nextSize = requestData.size;
    const updatedRequestData = {
      ...requestData,
      page: nextPage,
      size: nextSize,
    };
    setRequestData(updatedRequestData);
    dispatch(supplierFetchData(updatedRequestData));
    if (prevData?.length < nextSize) {
      setHasMore(false);
    }
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      dispatch(supplierFetchData(requestData));
    }
    return () => {
      renderAfterCalled.current = true;
    };
  }, [dispatch, requestData]);

  //CheckBox Active in Active
  const handleChange = (isChecked: any, id: string) => {
    const check = isChecked.toString();
    prevData.forEach((record: any) => {
      if (record.id === id) {
        dispatch(
          supplierActiveStatus({ id: record.id.toString(), value: isChecked })
        );
        setCheckboxStatus((prevState) => ({
          ...prevState,
          [id]: check,
        }));
      }
    });
  };

  //AntSwitch style
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    // display: 'flex',
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          background: "linear-gradient(to right, #614FB3, #944986)",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      // transition: theme.transitions.create(['width'], {
      //   duration: 200,
      // }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  // Table Column
  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "code",
      title: "CODE",
    },

    {
      dataIndex: "supplierName",
      title: " SUPPLIER NAME ",
      // sorter: (a, b) => a.suppilerName.length - b.suppilerName.length,
    },

    {
      dataIndex: "companyName",
      title: " COMPANY NAME ",
      // sorter: (a, b) => a.CompanyName.length - b.CompanyName.length,
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (_: any, record: DataType, index: number) => (
        <Row>
          <Col>
            <AntSwitch
              checked={checkboxStatus[record.id] === "true"}
              onClick={(e) => e.stopPropagation()}
              onChange={(event) =>
                handleChange(event.target.checked, record.id)
              }
              inputProps={{ "aria-label": "ant design" }}
            />
          </Col>
          <Col>
            <Grid item style={{ marginLeft: "20px" }}>
              {checkboxStatus[record.id] === "true" ? (
                <Typography>active</Typography>
              ) : (
                <Typography>Inactive</Typography>
              )}
            </Grid>
          </Col>
        </Row>
      ),
    },
  ];


  const headers = [
    "CODE",
    "SUPPLIER NAME",
    "COMPANY NAME",

  ];

  //
  function convertHeadersToXLS(headers: any) {
    const worksheet = XLSX.utils.aoa_to_sheet([headers]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    return blob;
  }
  // Function to download XLS file with headers only
  function downloadXLSWithHeaders(headers: any) {
    const xlsBlob = convertHeadersToXLS(headers);
    const url = URL.createObjectURL(xlsBlob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "exported_headers.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const handleUploadClick = () => {
    setUploadError("");
    setIsUpload(!isUpload);
  };

  const handleCloseUpload = () => {
    setIsUpload(false);
    setUploadError("");
    setUploadedFile(null);
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const fileNameParts = file.name.split(".");
      const fileExtension = fileNameParts[fileNameParts.length - 1];

      if (fileExtension !== "xlsx" && fileExtension !== "xls") {
        setUploadError("Unsupported file format. Please upload a .xlsx or .xls file.");
        event.target.value = "";
        setUploadedFile(null);
        return;
      }
      setUploadError("");
      setUploadedFile(file);
    }

  };

  const handleSubmit = async () => {
    if (!uploadedFile) {
      setUploadError("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append('file', uploadedFile);
    console.log("uploadedFile", uploadedFile);
    // handleCloseUpload();

    // try {
    //   const response = await axios.post('YOUR_API_ENDPOINT', formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   });

    //   if (response.status === 200) {
    //     setIsUpload(true);
    //     setUploadError("");
    //     handleCloseUpload();
    //     console.log("File uploaded successfully");
    //   } else {
    //     setUploadError("Failed to upload the file. Please try again.");
    //   }
    // } catch (error) {
    //   setUploadError("Failed to upload the file. Please try again.");
    // }
  };

  return (
    <>
      {skeleton ? (
        <Box
          sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}
        >
          <Grid container>
            <Grid item xs={6} className="mar-tb">
              <Skeleton
                variant="text"
                sx={{ fontWeight: "700", fontSize: "16px" }}
              />
            </Grid>
            <Grid
              container
              xs={6}
              sm={6}
              md={6}
              className="mar-tb"
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Grid item sx={{ display: "flex" }}>
                <Button
                  className="mastercrt-button"
                  onClick={toggleDrawer(true)}
                >
                  <Skeleton variant="rectangular" />
                </Button>
                <Grid item>
                  <Skeleton variant="rectangular" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box id="TableScroll"></Box>
          <TableSkeletonLoader rowCount={supplierLength} columns={4} />
        </Box>
      ) : (
        <Box
          sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 3, sm: 3 } }}
        >
          <Grid container sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={12} md={6}>
              <Typography>
                <b className="header-lable">Supplier Master</b>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            // className="mar-tb"
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  gap: "10px",
                }}
                container
              >
                <Button
                  className="mastercrt-button"
                  onClick={() => downloadXLSWithHeaders(headers)}
                >
                  <span className="Crt-lable"> Sample Excel</span>
                </Button>
                <Button className="mastercrt-button" onClick={handleUploadClick}>
                  <span className="Crt-lable">Upload</span>
                </Button>
                <Button
                  className="mastercrt-button"
                  onClick={handleShowClick}
                // onClick={toggleDrawer(true)}
                >
                  {" "}
                  <img src={Addicon} alt={Addicon} />{" "}
                  <span className="Crt-lable"> Create New</span>
                </Button>
                <Grid item>
                  <img
                    src={Rightdot}
                    alt={Rightdot}
                    style={{ width: "44px", height: "44px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box id="TableScroll" mt={3}>
            {/* <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "end", mb: 2 }}
        >
          <SearchBar
            className="search-table"
            value={textFieldValue}
            onChange={(newValue) => {
              setTextFieldValue(newValue);
              handleSearch();
            }}
          />
        </Grid> */}

            <InfiniteScroll
              dataLength={supplierFetchDatas?.length ?? 0}
              next={fetchMoreData}
              hasMore={hasMore}
              // scrollableTarget="TableScroll"
              className=""
              loader={
                <h6
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <CircularProgress
                    style={{
                      color: "#119BFF",
                    }}
                    size={20}
                  />
                </h6>
              }
            // endMessage={
            //   supplierFetchDatas?.length !== 0 && (
            //     <p className="fs-12 noMore">
            //       <b>No more data found...</b>
            //     </p>
            //   )
            // }
            >
              <CommonTable
                // rowSelection={rowSelection}
                data={prevData ? prevData : supplierFetchDatas}
                columns={columns}
                onRow={(record) => ({
                  onClick: () => handleEditClick(record),
                })}
              />
            </InfiniteScroll>
          </Box>

          {isShow ? (
            <MasterSupplierCreate
              onClose={() => setIsShow(false)}
              requestData={requestData}
            />
          ) : isEditing ? (
            <MasterSupplierEdit
              onClose={() => setIsEditing(false)}
              requestData={requestData}
            />
          ) : (
            <></>
          )}
          <Modal
            open={isUpload}
            onClose={handleCloseUpload}
            aria-describedby="modal-modal-description"
            BackdropProps={{ onClick: (event) => event.stopPropagation() }}

          >
            <Box sx={style} >
              <div style={{ display: "flex", justifyContent: "flex-end" }} onClick={handleCloseUpload}>
                <IoClose className="Close_icon" />
              </div>
              <Grid item>
                <div className="inputDnD" style={{ position: "relative" }}>
                  <input
                    type="file"
                    title=""
                    accept=".xlsx, .xls"
                    onChange={handleFileUpload}
                    className="form-control-file font-weight-bold"
                  />
                  {uploadedFile ? (
                    <Typography variant="body2" className="assetFileSub">{uploadedFile.name}</Typography>
                  )
                    :
                    <p className="assetFileSub">Click here to add Attachment.</p>
                  }
                </div>
                <Typography className="uploadError">{uploadError}</Typography>
              </Grid>

              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleCloseUpload}
                  sx={{ mr: 2 }}
                  className="crt-btn-cancel "
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  variant="contained"
                  className="btn-colr"
                  onClick={handleSubmit}
                >
                  Upload
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      )}
    </>
  );
}
