import React from "react";

import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import type { TableColumnsType } from 'antd';
import SearchBar from '@mkyy/mui-search-bar';
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../../components/Modal/DeleteSupplier";
import AddIcon from "@mui/icons-material/Add";
import CreateSMRoute from "./CreateSMRoute";
import EditSMRouts from "./EditSMRoute";
import ViewSMRoute from "./ViewSMRoute";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  code: string;
  SalesmanName: string;
  SalesmanCode: string;
  RouteCode: string;
  RouteName: string;
  action: string;
}

const SMRouteMap: React.FC = () => {
  const [isViewing, setIsViewing] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);

  const [textFieldValue, setTextFieldValue] = React.useState('');

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    //search function logic here
  };


  const handleCreateNew = () => {
    setShowForm(!showForm);
  };

  const handleEditClick = (event: React.MouseEvent<unknown>) => {
    setIsEditing(!isEditing);
  };

  const handleViewClick = (event: React.MouseEvent<unknown>) => {
    setIsViewing(!isViewing);
  };
  const handleDeleteClick = (event: React.MouseEvent<unknown>) => {
    setIsDelete(!isDelete);
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "code",
      title: "Distributor Branch Code",
      sorter: (a, b) => a.code.length - b.code.length,
    },
    {
      dataIndex: "SalesmanName",
      title: "Salesman Name",
      sorter: (a, b) => a.SalesmanName.length - b.SalesmanName.length,
    },
    {
      dataIndex: "SalesmanCode",
      title: "Salesman Code",
      sorter: (a, b) => a.SalesmanCode.length - b.SalesmanCode.length,
    },
    {
      dataIndex: "RouteCode",
      title: "Route Code",
      sorter: (a, b) => a.RouteCode.length - b.RouteCode.length,
    },
    {
      dataIndex: "RouteName",
      title: "Route Name",
      sorter: (a, b) => a.RouteName.length - b.RouteName.length,
    },


    {
      dataIndex: "action",
      title: "Action",

      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            onClick={(event) => handleViewClick(event)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
          <EditIcon
            onClick={(event) => handleEditClick(event)}
            style={{
              cursor: "pointer",
              marginLeft: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />
          <DeleteIcon
            onClick={(event) => handleDeleteClick(event)}
            style={{
              cursor: "pointer",
              marginRight: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      code: "GT0058589",
      SalesmanName: "SM1",
      SalesmanCode: "SLM001",
      RouteCode: "ROU001",
      RouteName: "ROUTE-1",
      action: "",
    },
    {
      key: 2,
      code: "GT0100021",
      SalesmanName: "	KARTHIK M",
      SalesmanCode: "SLM003",
      RouteCode: "	ROU001",
      RouteName: " WHOLESALE",
      action: "",
    },
    {
      key: 3,
      code: "GT010002",
      SalesmanName: "MURUGAN A P",
      SalesmanCode: "	SLM003",
      RouteCode: "	ROU0011",
      RouteName: "MONDAY",
      action: "",
    },
    {
      key: 4,
      code: "GT01000200082",
      SalesmanName: "NARAYANAN P",
      SalesmanCode: "	S10000036",
      RouteCode: "	ROU007",
      RouteName: " and CB ROAD",
      action: "",
    },
    {
      key: 5,
      code: '58',
      SalesmanName: "NARAYANAN P",
      SalesmanCode: "S10000036",
      RouteCode: "	ROU0036",
      RouteName: "	7WELLS-2",
      action: "",
    },
    {
      key: 6,
      code: "5665",
      SalesmanName: "KUMAR K",
      SalesmanCode: "S10000022",
      RouteCode: "	ROU00151 ",
      RouteName: "	TEST 08SEP2023",
      action: "",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
      <Grid item xs={12} className="pad-10 mar-tb">
        <Typography>
          <b>Salesman Route Mapping</b>
        </Typography>
      </Grid>
      {showForm ? (
        <CreateSMRoute onBack={handleCreateNew} />
      ) : isEditing ? (
        <EditSMRouts onBack={(event) => handleEditClick(event)} />
      ) : isViewing ? (
        <ViewSMRoute onBack={(event) => handleViewClick(event)} />
      ) : (
        <Box>
          <Button
            variant="contained"
            className="btn-colr"
            onClick={handleCreateNew}
          >
            {" "}
            <AddIcon /> Create new
          </Button>
          <Grid item sx={{ mt: 2 }}>
            <Box>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "end", mb: 2 }}
              >
                <SearchBar
                  className="search-table"
                  value={textFieldValue}
                  onChange={(newValue) => {
                    setTextFieldValue(newValue);
                    handleSearch();
                  }}
                />
              </Grid>
              <CommonTable
                // rowSelection={rowSelection}
                data={data}
                columns={columns}
              />

              {isDelete && (
                <DeleteModal onBack={(event) => handleDeleteClick(event)} />
              )}
            </Box>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
export default SMRouteMap;
