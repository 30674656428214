import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createAxiosInstance from "../../../hooks/useFetch";

interface FilterState {
  division: any;
  segment: any;
  brand: any;
  variant: any;
  subVariant: any;
  packagingType: any;
  size: any;
  divisionMore: any;
  segmentMore: any;
  brandMore: any;
  variantMore: any;
  subVariantMore: any;
  packagingTypeMore: any;
  sizeMore: any;
  basedOnStockSelected: string[];
  reasonSelected: string[];
  divisionSelected: string[];
  segmentSelected: string[];
  brandSelected: string[];
  variantSelected: string[];
  subVariantSelected: string[];
  packagingTypeSelected: string[];
  sizeSelected: string[];
  storeAllSeletedData: string[];
  popDivisionSelected: string[];
  popSegmentSelected: string[];
  popBrandSelected: string[];
  popVariantSelected: string[];
  popSubVariantSelected: string[];
  popPackagingTypeSelected: string[];
  popSizeSelected: string[];
  tempDivisionSelected: string[];
  tempSegmentSelected: string[];
  tempBrandSelected: string[];
  tempVariantSelected: string[];
  tempSubVariantSelected: string[];
  tempPackagingTypeSelected: string[];
  tempSizeSelected: string[];
  loading: boolean;
  popUpLoading: boolean;
  showMoreLoading: boolean;
  error: string | null;
}

interface FilterDataRequest {
  // distributorCode: string;
  filterName: string;
  page: number;
  size: number;
  pageStatus: boolean;
  type: string;
}

const axiosInstance = createAxiosInstance();

export const getDivision = createAsyncThunk(
  "filter/division",
  async (req: FilterDataRequest) => {
    try {
      req.filterName = "division";
      const response = await axiosInstance.get("inventory/get-filters", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getDivisionMore = createAsyncThunk(
  "filter/divisionMore",
  async (req: FilterDataRequest) => {
    try {
      req.filterName = "division";
      const response = await axiosInstance.get("inventory/get-filters", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getSegment = createAsyncThunk(
  "filter/segment",
  async (req: FilterDataRequest) => {
    try {
      req.filterName = "segment";
      const response = await axiosInstance.get("inventory/get-filters", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getSegmentMore = createAsyncThunk(
  "filter/segmentMore",
  async (req: FilterDataRequest) => {
    try {
      req.filterName = "segment";
      const response = await axiosInstance.get("inventory/get-filters", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getBrand = createAsyncThunk(
  "filter/brand",
  async (req: FilterDataRequest) => {
    try {
      req.filterName = "brand";
      const response = await axiosInstance.get("inventory/get-filters", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getBrandMore = createAsyncThunk(
  "filter/brandMore",
  async (req: FilterDataRequest) => {
    try {
      req.filterName = "brand";
      const response = await axiosInstance.get("inventory/get-filters", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getVariant = createAsyncThunk(
  "filter/varient",
  async (req: FilterDataRequest) => {
    try {
      req.filterName = "variant";
      const response = await axiosInstance.get("inventory/get-filters", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getVariantMore = createAsyncThunk(
  "filter/varientMore",
  async (req: FilterDataRequest) => {
    try {
      req.filterName = "variant";
      const response = await axiosInstance.get("inventory/get-filters", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getSubVariant = createAsyncThunk(
  "filter/subVarient",
  async (req: FilterDataRequest) => {
    try {
      req.filterName = "subVariant";
      const response = await axiosInstance.get("inventory/get-filters", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getSubVariantMore = createAsyncThunk(
  "filter/subVarientMore",
  async (req: FilterDataRequest) => {
    try {
      req.filterName = "subVariant";
      const response = await axiosInstance.get("inventory/get-filters", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getPackagingType = createAsyncThunk(
  "filter/packagingType",
  async (req: FilterDataRequest) => {
    try {
      req.filterName = "productPackageType";
      const response = await axiosInstance.get("inventory/get-filters", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getPackagingTypeMore = createAsyncThunk(
  "filter/packagingTypeMore",
  async (req: FilterDataRequest) => {
    try {
      req.filterName = "productPackageType";
      const response = await axiosInstance.get("inventory/get-filters", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getSize = createAsyncThunk(
  "filter/size",
  async (req: FilterDataRequest) => {
    try {
      req.filterName = "pieceWeight";
      const response = await axiosInstance.get("inventory/get-filters", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getSizeMore = createAsyncThunk(
  "filter/sizeMore",
  async (req: FilterDataRequest) => {
    try {
      req.filterName = "pieceWeight";
      const response = await axiosInstance.get("inventory/get-filters", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

// While choose division check box update the state
export const updateDivisionPop = createAsyncThunk(
  "filter/updateDivisionPop",
  async (req: string[]) => {
    return req;
  }
);

// While choose segment check box update the state
export const updateSegmentPop = createAsyncThunk(
  "filter/updateSegmentPop",
  async (req: string[]) => {
    return req;
  }
);

// While choose brand check box update the state
export const updateBrandPop = createAsyncThunk(
  "filter/updateBrandPop",
  async (req: string[]) => {
    return req;
  }
);

// While choose variant check box update the state
export const updateVariantPop = createAsyncThunk(
  "filter/updateVariantPop",
  async (req: string[]) => {
    return req;
  }
);

// While choose subVariant check box update the state
export const updateSubVariantPop = createAsyncThunk(
  "filter/updateSubVariantPop",
  async (req: string[]) => {
    return req;
  }
);

// While choose packagingType check box update the state
export const updatePackagingTypePop = createAsyncThunk(
  "filter/updatePackagingTypePop",
  async (req: string[]) => {
    return req;
  }
);

// While choose size check box update the state
export const updateSizePop = createAsyncThunk(
  "filter/updateSizePop",
  async (req: string[]) => {
    return req;
  }
);

export const storeAllSeletedDataPop = createAsyncThunk(
  "filter/storeAllSeletedDataPop",
  async (req: string[]) => {
    return req;
  }
);

// Unselect particular value from the selected data
export const unSelectValuePop = createAsyncThunk(
  "filter/unSelectValuePop",
  async (req: string) => {
    return req;
  }
);

// While choose stock check box update the state
export const updateBasedOnStock = createAsyncThunk(
  "filter/updateBasedOnStock",
  async (req: string[]) => {
    return req;
  }
);
export const updateReason = createAsyncThunk(
  "filter/updateRea",
  async (req: string[]) => {
    return req;
  }
);
// While choose division check box update the state
export const updateDivision = createAsyncThunk(
  "filter/updateDivision",
  async (req: string[]) => {
    return req;
  }
);

// While choose segment check box update the state
export const updateSegment = createAsyncThunk(
  "filter/updateSegment",
  async (req: string[]) => {
    return req;
  }
);

// While choose brand check box update the state
export const updateBrand = createAsyncThunk(
  "filter/updateBrand",
  async (req: string[]) => {
    return req;
  }
);

// While choose variant check box update the state
export const updateVariant = createAsyncThunk(
  "filter/updateVariant",
  async (req: string[]) => {
    return req;
  }
);

// While choose subVariant check box update the state
export const updateSubVariant = createAsyncThunk(
  "filter/updateSubVariant",
  async (req: string[]) => {
    return req;
  }
);

// While choose packagingType check box update the state
export const updatePackagingType = createAsyncThunk(
  "filter/updatePackagingType",
  async (req: string[]) => {
    return req;
  }
);

// While choose size check box update the state
export const updateSize = createAsyncThunk(
  "filter/updateSize",
  async (req: string[]) => {
    return req;
  }
);

export const storeAllSeletedData = createAsyncThunk(
  "filter/storeAllSeletedData",
  async (req: string[]) => {
    return req;
  }
);

// Unselect particular value from the selected data
export const unSelectValue = createAsyncThunk(
  "filter/unSelectValue",
  async (req: string) => {
    return req;
  }
);

// While choose division check box update the state
export const updateDivisionTemp = createAsyncThunk(
  "filter/updateDivisionTemp",
  async (req: string[]) => {
    return req;
  }
);

// While choose segment check box update the state
export const updateSegmentTemp = createAsyncThunk(
  "filter/updateSegmentTemp",
  async (req: string[]) => {
    return req;
  }
);

// While choose brand check box update the state
export const updateBrandTemp = createAsyncThunk(
  "filter/updateBrandTemp",
  async (req: string[]) => {
    return req;
  }
);

// While choose variant check box update the state
export const updateVariantTemp = createAsyncThunk(
  "filter/updateVariantTemp",
  async (req: string[]) => {
    return req;
  }
);

// While choose subVariant check box update the state
export const updateSubVariantTemp = createAsyncThunk(
  "filter/updateSubVariantTemp",
  async (req: string[]) => {
    return req;
  }
);

// While choose packagingType check box update the state
export const updatePackagingTypeTemp = createAsyncThunk(
  "filter/updatePackagingTypeTemp",
  async (req: string[]) => {
    return req;
  }
);

// While choose size check box update the state
export const updateSizeTemp = createAsyncThunk(
  "filter/updateSizeTemp",
  async (req: string[]) => {
    return req;
  }
);

const filterSlice = createSlice({
  name: "filter",
  initialState: {
    division: [],
    segment: [],
    brand: [],
    variant: [],
    subVariant: [],
    packagingType: [],
    size: [],
    divisionMore: [],
    segmentMore: [],
    brandMore: [],
    variantMore: [],
    subVariantMore: [],
    packagingTypeMore: [],
    sizeMore: [],
    storeAllSeletedData: [],
    basedOnStockSelected: [],
    reasonSelected: [],
    divisionSelected: [],
    segmentSelected: [],
    brandSelected: [],
    variantSelected: [],
    subVariantSelected: [],
    packagingTypeSelected: [],
    sizeSelected: [],
    popDivisionSelected: [],
    popSegmentSelected: [],
    popBrandSelected: [],
    popVariantSelected: [],
    popSubVariantSelected: [],
    popPackagingTypeSelected: [],
    popSizeSelected: [],
    tempDivisionSelected: [],
    tempSegmentSelected: [],
    tempBrandSelected: [],
    tempVariantSelected: [],
    tempSubVariantSelected: [],
    tempPackagingTypeSelected: [],
    tempSizeSelected: [],
    loading: false,
    popUpLoading: false,
    showMoreLoading: false,
    error: null,
  } as FilterState,
  reducers: {
    ProductFilterFetch: (state, action) => {
      state.division = action.payload.division;
      state.segment = action.payload.segment;
      state.brand = action.payload.brand;
      state.variant = action.payload.variant;
      state.subVariant = action.payload.subVariant;
      state.packagingType = action.payload.packagingType;
      state.size = action.payload.size;
      state.divisionMore = action.payload.divisionMore;
      state.segmentMore = action.payload.segmentMore;
      state.brandMore = action.payload.brandMore;
      state.variantMore = action.payload.variantMore;
      state.subVariantMore = action.payload.subVariantMore;
      state.packagingTypeMore = action.payload.packagingTypeMore;
      state.sizeMore = action.payload.sizeMore;
      state.storeAllSeletedData = action.payload.storeAllSeletedData;
      state.basedOnStockSelected = action.payload.basedOnStockSelected;
      state.reasonSelected = action.payload.reasonSelected;
      state.divisionSelected = action.payload.divisionSelected;
      state.segmentSelected = action.payload.segmentSelected;
      state.brandSelected = action.payload.brandSelected;
      state.variantSelected = action.payload.variantSelected;
      state.subVariantSelected = action.payload.subVariantSelected;
      state.packagingTypeSelected = action.payload.packagingTypeSelected;
      state.sizeSelected = action.payload.sizeSelected;
      state.popDivisionSelected = action.payload.popDivisionSelected;
      state.popSegmentSelected = action.payload.popSegmentSelected;
      state.popBrandSelected = action.payload.popBrandSelected;
      state.popVariantSelected = action.payload.popVariantSelected;
      state.popSubVariantSelected = action.payload.popSubVariantSelected;
      state.popPackagingTypeSelected = action.payload.popPackagingTypeSelected;
      state.tempDivisionSelected = action.payload.tempDivisionSelected;
      state.tempSegmentSelected = action.payload.tempSegmentSelected;
      state.tempBrandSelected = action.payload.tempBrandSelected;
      state.tempVariantSelected = action.payload.tempVariantSelected;
      state.tempSubVariantSelected = action.payload.tempSubVariantSelected;
      state.tempPackagingTypeSelected =
        action.payload.tempPackagingTypeSelected;
      state.tempSizeSelected = action.payload.tempSizeSelected;
      state.popSizeSelected = action.payload.popSizeSelected;
      state.sizeSelected = action.payload.sizeSelected;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDivision.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getDivision.fulfilled, (state, action) => {
      state.division = action.payload.data.data ?? [];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getDivision.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getSegment.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSegment.fulfilled, (state, action) => {
      state.segment = action.payload.data.data ?? [];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getSegment.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getBrand.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getBrand.fulfilled, (state, action) => {
      state.brand = action.payload.data.data ?? [];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getBrand.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getVariant.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getVariant.fulfilled, (state, action) => {
      state.variant = action.payload.data.data ?? [];
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getVariant.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getSubVariant.pending, (state) => {
      state.showMoreLoading = true;
      state.error = null;
    });
    builder.addCase(getSubVariant.fulfilled, (state, action) => {
      state.subVariant = action.payload.data.data ?? [];
      state.showMoreLoading = false;
      state.error = null;
    });
    builder.addCase(getSubVariant.rejected, (state, action) => {
      state.showMoreLoading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getPackagingType.pending, (state) => {
      state.showMoreLoading = true;
      state.error = null;
    });
    builder.addCase(getPackagingType.fulfilled, (state, action) => {
      state.packagingType = action.payload.data.data ?? [];
      state.showMoreLoading = false;
      state.error = null;
    });
    builder.addCase(getPackagingType.rejected, (state, action) => {
      state.showMoreLoading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getSize.pending, (state) => {
      state.showMoreLoading = true;
      state.error = null;
    });
    builder.addCase(getSize.fulfilled, (state, action) => {
      state.size = action.payload.data.data ?? [];
      state.showMoreLoading = false;
      state.error = null;
    });
    builder.addCase(getSize.rejected, (state, action) => {
      state.showMoreLoading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(updateDivision.fulfilled, (state, action) => {
      state.divisionSelected = action.payload;
    });
    builder.addCase(updateSegment.fulfilled, (state, action) => {
      state.segmentSelected = action.payload;
    });
    builder.addCase(updateBrand.fulfilled, (state, action) => {
      state.brandSelected = action.payload;
    });
    builder.addCase(updateVariant.fulfilled, (state, action) => {
      state.variantSelected = action.payload;
    });
    builder.addCase(updateSubVariant.fulfilled, (state, action) => {
      state.subVariantSelected = action.payload;
    });
    builder.addCase(updatePackagingType.fulfilled, (state, action) => {
      state.packagingTypeSelected = action.payload;
    });
    builder.addCase(updateSize.fulfilled, (state, action) => {
      state.sizeSelected = action.payload;
    });
    builder.addCase(updateBasedOnStock.fulfilled, (state, action) => {
      state.basedOnStockSelected = action.payload;
    });
    builder.addCase(updateReason.fulfilled, (state, action) => {
      state.reasonSelected = action.payload;
    });
    builder.addCase(storeAllSeletedData.fulfilled, (state, action) => {
      state.storeAllSeletedData = action.payload;
    });
    builder.addCase(unSelectValue.fulfilled, (state, action) => {
      state.storeAllSeletedData = state.storeAllSeletedData.filter(
        (item) => item !== action.payload
      );
    });
    builder.addCase(getDivisionMore.pending, (state) => {
      state.popUpLoading = true;
      state.error = null;
    });
    builder.addCase(getDivisionMore.fulfilled, (state, action) => {
      state.divisionMore = action.payload.data.data ?? [];
      state.popUpLoading = false;
      state.error = null;
    });
    builder.addCase(getDivisionMore.rejected, (state, action) => {
      state.popUpLoading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getSegmentMore.pending, (state) => {
      state.popUpLoading = true;
      state.error = null;
    });
    builder.addCase(getSegmentMore.fulfilled, (state, action) => {
      state.segmentMore = action.payload.data.data ?? [];
      state.popUpLoading = false;
      state.error = null;
    });
    builder.addCase(getSegmentMore.rejected, (state, action) => {
      state.popUpLoading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getBrandMore.pending, (state) => {
      state.popUpLoading = true;
      state.error = null;
    });
    builder.addCase(getBrandMore.fulfilled, (state, action) => {
      state.brandMore = action.payload.data.data ?? [];
      state.popUpLoading = false;
      state.error = null;
    });
    builder.addCase(getBrandMore.rejected, (state, action) => {
      state.popUpLoading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getVariantMore.pending, (state) => {
      state.popUpLoading = true;
      state.error = null;
    });
    builder.addCase(getVariantMore.fulfilled, (state, action) => {
      state.variantMore = action.payload.data.data ?? [];
      state.popUpLoading = false;
      state.error = null;
    });
    builder.addCase(getVariantMore.rejected, (state, action) => {
      state.popUpLoading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getSubVariantMore.pending, (state) => {
      state.popUpLoading = true;
      state.error = null;
    });
    builder.addCase(getSubVariantMore.fulfilled, (state, action) => {
      state.subVariantMore = action.payload.data.data ?? [];
      state.popUpLoading = false;
      state.error = null;
    });
    builder.addCase(getSubVariantMore.rejected, (state, action) => {
      state.popUpLoading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getPackagingTypeMore.pending, (state) => {
      state.popUpLoading = true;
      state.error = null;
    });
    builder.addCase(getPackagingTypeMore.fulfilled, (state, action) => {
      state.packagingTypeMore = action.payload.data.data ?? [];
      state.popUpLoading = false;
      state.error = null;
    });
    builder.addCase(getPackagingTypeMore.rejected, (state, action) => {
      state.popUpLoading = false;
      state.error = action.error.message ?? null;
    });
    builder.addCase(getSizeMore.pending, (state) => {
      state.popUpLoading = true;
      state.error = null;
    });
    builder.addCase(getSizeMore.fulfilled, (state, action) => {
      state.sizeMore = action.payload.data.data ?? [];
      state.popUpLoading = false;
      state.error = null;
    });
    builder.addCase(getSizeMore.rejected, (state, action) => {
      state.popUpLoading = false;
      state.error = action.error.message ?? null;
    });

    builder.addCase(updateDivisionPop.fulfilled, (state, action) => {
      state.popDivisionSelected = action.payload;
    });
    builder.addCase(updateSegmentPop.fulfilled, (state, action) => {
      state.popSegmentSelected = action.payload;
    });
    builder.addCase(updateBrandPop.fulfilled, (state, action) => {
      state.popBrandSelected = action.payload;
    });
    builder.addCase(updateVariantPop.fulfilled, (state, action) => {
      state.popVariantSelected = action.payload;
    });
    builder.addCase(updateSubVariantPop.fulfilled, (state, action) => {
      state.popSubVariantSelected = action.payload;
    });
    builder.addCase(updatePackagingTypePop.fulfilled, (state, action) => {
      state.popPackagingTypeSelected = action.payload;
    });
    builder.addCase(updateSizePop.fulfilled, (state, action) => {
      state.popSizeSelected = action.payload;
    });
    builder.addCase(updateDivisionTemp.fulfilled, (state, action) => {
      state.tempDivisionSelected = action.payload;
    });
    builder.addCase(updateSegmentTemp.fulfilled, (state, action) => {
      state.tempSegmentSelected = action.payload;
    });
    builder.addCase(updateBrandTemp.fulfilled, (state, action) => {
      state.tempBrandSelected = action.payload;
    });
    builder.addCase(updateVariantTemp.fulfilled, (state, action) => {
      state.tempVariantSelected = action.payload;
    });
    builder.addCase(updateSubVariantTemp.fulfilled, (state, action) => {
      state.tempSubVariantSelected = action.payload;
    });
    builder.addCase(updatePackagingTypeTemp.fulfilled, (state, action) => {
      state.tempPackagingTypeSelected = action.payload;
    });
    builder.addCase(updateSizeTemp.fulfilled, (state, action) => {
      state.tempSizeSelected = action.payload;
    });
  },
});

export const { ProductFilterFetch } = filterSlice.actions;
export default filterSlice.reducer;
