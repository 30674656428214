import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Grid,
  Card,
  Typography,
  Snackbar,
  Alert,
  Skeleton,
} from "@mui/material";
import debounce from "lodash.debounce";
import type { TableColumnsType } from "antd";
import { Spin, Table, Tooltip } from "antd";
import Modal from "@mui/material/Modal";
import SearchBar from "@mkyy/mui-search-bar";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useFetch";
import view_line from "../../../../../assets/images/icons/view_line.png";
import Accept_img from "../../../../../assets/images/icons/cheque_accept.png";
import Reject_img from "../../../../../assets/images/icons/cheque_reject.png";
import Cheque_edit_img from "../../../../../assets/images/icons/cheque_edit.png";
import Close from "../../../../../assets/images/icons/Close_icon.png";
import {
  getChequeRejectAcceptList,
  getPendingPaymentCardCount,
  getPendingPaymentList,
  setCollectionData,
  setSeletedCollectionData,
  updatePendingCard,
} from "../../../../../Redux/XDM/Sales/collectionReducer";
import ImageModal from "../../SalesReturn/PendingSalesReturn/ImageModal";
import PendingChequeEdit from "./PendingChequeEdit";
import {
  updateDate,
  updateFromDate,
  updateOutlet,
  updateSalesman,
  updateToDate,
} from "../../../../../Redux/XDM/Sales/billingReducer";
import LoadingButton from "@mui/lab/LoadingButton";
import RejectedCheque from "./RejectedChequePopup";
import InvoiceDetails from "../../InvoiceReviewOrder";
import { useLocation } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "3px",
};

interface RequestData {
  returnId: string;
  outletName: string;
  invoiceNo: string;
  salesmanName: string;
  date: string;
  noOfLineItem: number;
  returnValue: number;
  status: string;
}

interface ReviewOrderDetailsProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
}
interface ReviewOrderDetails {
  collectionNumber: string;
  invoiceNumber: string[];
  collectionDate: string;
  outletName: string;
  outStandingAmount: number;
  amountCollected: number;
  chequeNumber: string;
  bankName: string;
  chequeDate: string;
  img: string[];
}

interface RequestDataList {
  salesManId: string;
  salesManName: string;
  mobile: string;
}

interface SalesManIdProps {
  requestOfData: RequestDataList;
}

export default function PendingChequePayments(props: SalesManIdProps) {
  const { requestOfData } = props;
  const dispatch = useAppDispatch();
  const [hasMore, setHasMore] = useState(true);
  const [commonLoader, setCommonLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [acceptedReturnNo, setAcceptedReturnNo] = useState<string[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isRejectShow, setIsRejectShow] = React.useState(false);
  const [isEditShow, setIsEditShow] = React.useState(false);
  const [isEnableEdit, setIsEnableEdit] = React.useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [textFieldValue, setTextFieldValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openImage, setOpenImage] = React.useState(false);
  const [imageList, setImageList] = React.useState<string[]>([]);
  const filterTags = useAppSelector((state) => state.orderBilling);
  const [acceptPopupRecord, setAcceptPopupRecord] = useState<any | null>(null);
  const [isShowConfirm, setIsShowConfirm] = React.useState(false);
  const [isShowDetails, setIsShowDetails] = React.useState(false);
  const [invoiceRequestData, setInvoiceRequestData] = React.useState({
    invoiceNumber: "",
    orderId: "",
    date: "",
    outlet: "",
    route: "",
    salesman: "",
    netAmount: 0,
    salesmanMobile: "",
  });
  const handleImageOpen = (record: ReviewOrderDetails) => {
    // Type the record for clarity
    setImageList(record.img); // Set the image list from the record
    setOpenImage(true);
  };
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [dataRecordOrders, setDataRecordOrders] = useState<
    ReviewOrderDetails[]
  >([
    // Add more records as needed
  ]);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const mode = params.get("payMode");

  let salesReturnProductData = useAppSelector(
    (state) => state.collectionReducerData
  );
  let salesmanData = salesReturnProductData.salesmanDetails;

  let collectionStoreData = useAppSelector(
    (state) => state.collectionReducerData.collectionStoreData
  );

  const tableHeightRef = useRef(160);
  const tableHeightloadRef = useRef(180);

  const filterData = {
    // returnId: requestData.returnId,
    paymentType: "Cheque",
    page: 0,
    size: 10,
    dateType:
      filterTags.dateSelected?.length !== 0 ? filterTags.dateSelected : "",
    fromDate: filterTags.fromDateSelected ? filterTags.fromDateSelected : "",
    toDate: filterTags.toDateSelected ? filterTags.toDateSelected : "",
    search: id && mode === "Cheque" ? id : textFieldValue,
    salesmanId:
      salesmanData.salesManId && mode === "UPI"
        ? salesmanData.salesManId
        : requestOfData.salesManId,
    outletId: filterTags.outletSelected.toString(),
  };

  const CardfilterData = {
    paymentType: "Cheque",
    salesmanId: requestOfData.salesManId,
  };

  const handleInvoiceClick = (invoiceNo: string) => {
    setInvoiceRequestData({
      invoiceNumber: invoiceNo,
      orderId: "",
      date: "",
      outlet: "",
      route: "",
      salesman: "",
      netAmount: 0,
      salesmanMobile: "",
    });
    setIsShowDetails(!isShowDetails);
  };

  useEffect(() => {
    setHasMore(true);
    loadMoreData(true);
    dispatch(
      updatePendingCard({
        totalCollection: 0,
        totalCollectionAmt: 0,
      })
    );
    dispatch(
      getPendingPaymentCardCount({
        params: { ...filterData },
      })
    );
    if (collectionStoreData === true) {
      loadMoreData(true);
      dispatch(
        getPendingPaymentCardCount({
          params: { ...filterData },
        })
      );
    }
  }, [
    textFieldValue,
    filterTags.dateSelected,
    filterTags.outletSelected,
    filterTags.fromDateSelected,
    filterTags.toDateSelected,
    collectionStoreData,
  ]);

  useEffect(() => {
    // dispatch(updateFromDate([]));
    // dispatch(updateToDate([]));
    // dispatch(updateDate([]));
    // dispatch(updateSalesman([]));
    dispatch(updateOutlet([]));
  }, []);

  let pendingPaymentCount = useAppSelector(
    (state) => state.collectionReducerData
  );
  let pendingPaymentsCountData = useAppSelector(
    (state) => state.collectionReducerData.pendingPaymentCounts
  );
  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 353;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    newSelectedRows: any[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled:
        acceptedReturnNo?.includes(record.collectionNumber) ||
        ["Confirmed", "Partially Confirmed", "Rejected"].includes(
          record.returnStatus
        ),
    }),
  };

  const areAllRowsDisabled = () => {
    return dataRecordOrders.every((record) =>
      acceptedReturnNo.includes(record.collectionNumber)
    );
  };

  const tableHeightLoad = () => {
    const headerHeight = 0;
    const footerHeoght = 300;
    const availableHeight = window.innerHeight - headerHeight - footerHeoght;
    tableHeightloadRef.current = availableHeight;
  };

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    tableHeightLoad();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;

    dispatch(
      getPendingPaymentList({
        params: { ...filterData, page: nextPage },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        dispatch(setCollectionData(false));
        if (response.payload) {
          const newOrders = response.payload.data || [];

          setDataRecordOrders((prevOrders) =>
            resetPage ? newOrders : [...prevOrders, ...newOrders]
          );
          setPage(nextPage + 1);
          if (newOrders.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };

  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const handleEditClick = (record: any) => {
    const requestDatas = {
      collectionId: record?.collectionNumber,
      invoiceNumbers: record?.invoiceNumber,
      collectionDate: record?.collectionDate,
      outletName: record?.outletName,
      salesManId: requestOfData?.salesManId,
      salesmanName: requestOfData?.salesManName,
      pendingAmount: record?.outStandingAmount,
      images: record?.img,
      collectedAmt: record?.amountCollected,
      chequeNumber: record?.chequeNumber,
      bankName: record?.bankName,
      chequeDate: record?.chequeDate,
      dateType:
        filterTags.dateSelected?.length !== 0 ? filterTags.dateSelected : "",
      fromDate: filterTags.fromDateSelected ? filterTags.fromDateSelected : "",
      toDate: filterTags.toDateSelected ? filterTags.toDateSelected : "",
      search: textFieldValue,
      salesmanId: requestOfData.salesManId,
      outletId: filterTags.outletSelected.toString(),
    };

    dispatch(setSeletedCollectionData(requestDatas));

    setIsEditShow(!isEditShow);
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };
  const handleRejectClick = (record: any) => {
    const requestDatas = {
      collectionId: record?.collectionNumber,
      invoiceNumbers: record?.invoiceNumber,
      collectionDate: record?.collectionDate,
      outletName: record?.outletName,
      salesmanName: requestOfData?.salesManName,
      pendingAmount: record?.outStandingAmount,
      images: record?.img,
      collectedAmt: record?.amountCollected,
    };

    dispatch(setSeletedCollectionData(requestDatas));

    setIsRejectShow(!isRejectShow);
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  const reviewOrderColumns: TableColumnsType<ReviewOrderDetails> = [
    {
      dataIndex: "collectionNumber",
      title: "Collection ID",
      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "-"}</div>
          </Tooltip>
        );
      },
    },

    {
      dataIndex: "invoiceNumber",
      title: "Invoice No",
      width: "10%",
      render: (text: string, record: ReviewOrderDetails) => {
        if (
          Array.isArray(record.invoiceNumber) &&
          record.invoiceNumber.length > 0
        ) {
          const invoiceList = record.invoiceNumber[0]?.split(","); // Split all invoices by commas
          const firstInvoice = invoiceList[0]; // Get the first invoice

          return (
            <Tooltip
              placement="top"
              title={record.invoiceNumber[0]}
              zIndex={999999}
            >
              <div
                className="ellipseclass"
                style={{
                  textDecoration: "underline",
                  fontWeight: 600,
                  color: "#27538D",
                  display: "flex",
                  gap: "10px",
                  cursor: "pointer",
                }}
                onClick={() => handleInvoiceClick(firstInvoice)} // Pass only the first invoice
              >
                {invoiceList?.join(", ")} {/* Display all invoices */}
              </div>
            </Tooltip>
          );
        }
        return <div style={{ fontWeight: "bold" }}> - </div>;
      },
    },

    {
      dataIndex: "collectionDate",
      title: "Date",
      align: "center",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
        return a.collectionDate.localeCompare(b.collectionDate);
      },
      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "-"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "outletName",
      title: "Outlet",
      // width: "12%",
      align: "center",
      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "-"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "outStandingAmount",
      title: "Ols Outstanding Amount",
      align: "center",
      render: (text: number) => {
        return <span> ₹ {text ? text?.toFixed(2) : 0}</span>;
      },
    },

    {
      dataIndex: "amountCollected",
      title: "Amt Collected",
      // width: "10%",
      align: "right",
      render: (text: number, record: ReviewOrderDetails) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span> ₹ {text ? text?.toFixed(2) : 0.0}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "img",
      title: "View Cheque",
      align: "center",
      render: (_: any, record: ReviewOrderDetails, index: number) => {
        return (
          <Grid
            container
            sx={{
              justifyContent: "center",
              pointerEvents: "auto",
            }}
          >
            {record?.img?.length > 0 ? (
              <Grid item onClick={() => handleImageOpen(record)}>
                <img
                  src={view_line}
                  alt="view-line"
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
            ) : (
              <>--</>
            )}
          </Grid>
        );
      },
    },
    {
      dataIndex: "chequeNumber",
      title: "Cheque No",
      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "-"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "bankName",
      title: "Bank Name",
      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "-"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "chequeDate",
      title: "Cheque Date",
      align: "center",
      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "-"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "action",
      title: "Action",
      align: "center",
      render: (_: any, record: ReviewOrderDetails, index: number) => {
        const isSelected = selectedRowKeys.includes(record.collectionNumber);
        return (
          <>
            <Grid
              container
              sx={{
                justifyContent: "center",
                pointerEvents: isSelected ? "none" : "auto",
                opacity: isSelected ? 0.5 : 1,
              }}
            >
              <Grid item onClick={() => handleEditClick(record)}>
                <img
                  src={Cheque_edit_img}
                  alt="Edit"
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
              <Grid item ml={1} onClick={() => handleRejectClick(record)}>
                <img
                  src={Reject_img}
                  alt="Reject"
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
              <Grid item ml={1} onClick={() => handleAcceptPopup(record)}>
                <img
                  src={Accept_img}
                  alt="Accept"
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
            </Grid>
          </>
        );
      },
    },
  ];
  const filteredDataToShow = dataRecordOrders.filter(
    (item) =>
      item.collectionNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.bankName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.outletName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.chequeNumber?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const dataToShow: ReviewOrderDetails[] =
    filteredDataToShow.length > 0
      ? hasMore
        ? filteredDataToShow
        : [...filteredDataToShow]
      : [];

  const handleClose = () => setOpen(false);

  const handleCloseModal = () => setIsShowConfirm(false);

  const handleOk = () => {
    setOpen(false);
  };
  const formatIndianNumber = (num: number | string): string => {
    const numStr = num.toString();
    const [integerPart, decimalPart] = numStr.split(".");
    let lastThreeDigits = integerPart.slice(-3);
    let otherDigits = integerPart.slice(0, -3);

    if (otherDigits !== "") {
      lastThreeDigits = "," + lastThreeDigits;
    }

    const formattedIntegerPart =
      otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

    return decimalPart
      ? formattedIntegerPart + "." + decimalPart.slice(0, 2)
      : formattedIntegerPart + ".00";
  };

  const handleAcceptPopup = (
    record: ReviewOrderDetails | ReviewOrderDetails[]
  ) => {
    if (Array.isArray(record)) {
      setAcceptPopupRecord(record);
    } else {
      setAcceptPopupRecord([record]);
    }
    setIsShowConfirm(true);
  };

  // const handleAcceptOk = async () => {
  //   setOpen(true);
  //   setSelectedRowKeys([]);
  //   setSelectedRows([]);
  //   if (abortControllerRef.current) {
  //     abortControllerRef.current.abort(); // Abort the previous request
  //   }

  //   const abortController = new AbortController();
  //   abortControllerRef.current = abortController;

  //   const requestPayload = {
  //     chequeList: [
  //       {
  //         validRemarksForRejection: false,
  //         collectionNumber: record?.collectionNumber,
  //         status: "Approved",
  //         remarks: "",
  //       },
  //     ],
  //   };

  //   try {
  //     const response = await dispatch(
  //       getChequeRejectAcceptList({
  //         params: requestPayload,
  //         signal: abortController.signal,
  //       })
  //     );
  //     if (response?.meta?.requestStatus === "fulfilled") {
  //       setSnackbarMessage("Payment Accepted Successfully");
  //       setSnackbarSeverity("success");
  //       setSnackbarOpen(true);
  //       dispatch(setCollectionData(true));
  //       setTimeout(() => {
  //         dispatch(
  //           getPendingPaymentList({
  //             params: { ...filterData },
  //             signal: abortController.signal,
  //           })
  //         );
  //         setSnackbarOpen(false);
  //       }, 3000);
  //     } else if (response?.meta?.requestStatus === "rejected") {
  //       const errorMessage = response?.payload?.data;

  //       setSnackbarMessage(errorMessage);
  //       setSnackbarSeverity("error");
  //       setSnackbarOpen(true);
  //       setTimeout(() => {
  //         setSnackbarOpen(false);
  //       }, 3000);
  //     }
  //   } catch (error) {
  //     console.error("Submission error", error);
  //   }

  //   // setIsRejectShow(!isRejectShow);
  //   setSelectedRows([]);
  //   setSelectedRowKeys([]);
  // };
  const handleAcceptOk = async () => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const requestPayload = {
      chequeList: acceptPopupRecord.map((record: ReviewOrderDetails) => ({
        paymentType: "UPI",
        collectionNumber: record.collectionNumber,
        status: "Approved",
        remarks: "",
        validRemarksForRejection: false,
      })),
    };

    try {
      const response = await dispatch(
        getChequeRejectAcceptList({
          params: requestPayload,
          signal: abortController.signal,
        })
      );
      if (response?.meta?.requestStatus === "fulfilled") {
        setIsShowConfirm(false);
        setSnackbarMessage("Payment Accepted Successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
        }, 3000);
        setDataRecordOrders((prevData) =>
          prevData.filter(
            (item) =>
              !acceptPopupRecord.some(
                (record: ReviewOrderDetails) =>
                  record.collectionNumber === item.collectionNumber
              )
          )
        );
        dispatch(
          getPendingPaymentCardCount({
            params: { ...filterData },
          })
        );
      } else if (response?.meta?.requestStatus === "rejected") {
        const errorMessage = response?.payload?.data;

        setSnackbarMessage(errorMessage);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
        }, 3000);
      }
    } catch (error) {
      console.error("Submission error", error);
    }
  };

  return (
    <Box
      flex={8}
      sx={{ overflowY: "auto", height: "100vh", mt: { sm: -0.5, md: -5.3 } }}
    >
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={100000}
        color="green"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Card
        className="b-radius-0 "
        style={{ marginTop: "2px", boxShadow: "none" }}
      >
        <Grid container spacing={3} justifyContent="start" p={2}>
          <Grid container mt={4} m={2}>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <h3
                style={{
                  marginTop: "0px",
                  marginLeft: "8px",
                  marginBottom: "16px",
                }}
              />
            </Grid>
            <Grid item xs={6} sm={5} md={3} lg={3}>
              <SearchBar
                className="collection_search w-100px"
                placeholder="Search Collection ID"
                value={textFieldValue}
                onChange={(newValue) => {
                  setTextFieldValue(newValue);
                }}
                onCancelResearch={() => setTextFieldValue("")}
              />
            </Grid>
          </Grid>
          <Grid container pl={3.6} mt={2}>
            <Grid item sm={6}>
              <Grid item>
                <Typography className="Pending_review_lable">
                  Total Cheque Collection
                </Typography>
              </Grid>
              <Grid item mt={0.7}>
                {pendingPaymentCount?.pendingPaymentCountsLoading ? (
                  <Skeleton variant="rectangular" width="30%" />
                ) : (
                  <Typography className="Pending_review_Sublable">
                    {pendingPaymentsCountData.totalCollection}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid item>
                <Typography className="Pending_review_lable">
                  Total Cheque Collection Value
                </Typography>
              </Grid>
              <Grid item mt={0.7}>
                {pendingPaymentCount?.pendingPaymentCountsLoading ? (
                  <Skeleton variant="rectangular" width="30%" />
                ) : (
                  <Typography className="Pending_review_Sublable">
                    ₹{pendingPaymentsCountData?.totalCollectionAmt?.toFixed(2)}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="pad-top-0px"
              sx={{ height: tableHeightloadRef.current }}
            >
              <div className="saleable-table-scroll">
                <Table
                  className={`Header-table saleable-table sale_last_hide scroll_cus_table antcheck_manual Header_chechbox elipsTable pending_grn ${
                    commonLoader ? " nodataloader" : ""
                  }`}
                  columns={reviewOrderColumns}
                  dataSource={dataToShow}
                  pagination={false}
                  scroll={{ y: tableHeightRef.current }}
                  loading={!page && commonLoader}
                  showSorterTooltip={false}
                  summary={() => {
                    if (!hasMore && dataRecordOrders.length > 0) {
                      return (
                        <Table.Summary.Row>
                          <Table.Summary.Cell
                            index={1}
                            colSpan={areAllRowsDisabled() ? 8 : 9}
                            align="center"
                            className="noMore"
                          >
                            No more data found...
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      );
                    } else if (page !== 0 && commonLoader) {
                      return (
                        <Table.Summary.Row>
                          <Table.Summary.Cell
                            index={1}
                            colSpan={areAllRowsDisabled() ? 8 : 9}
                            align="center"
                          >
                            <Spin />
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      );
                    }
                  }}
                  // Conditionally render rowSelection based on areAllRowsDisabled
                  rowSelection={
                    !areAllRowsDisabled() ? rowSelection : undefined
                  }
                  rowKey={(record) => record.collectionNumber}
                />{" "}
                {isEditShow ? (
                  <PendingChequeEdit onClose={() => setIsEditShow(false)} />
                ) : (
                  <></>
                )}
                {isShowConfirm && (
                  <Modal
                    open={isShowConfirm}
                    onClose={handleCloseModal}
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        sx={{ textAlign: "Right" }}
                      >
                        <img
                          src={Close}
                          onClick={handleCloseModal}
                          style={{
                            width: "14px",
                            height: "14px",
                            cursor: "pointer",
                          }}
                          alt="Close Icon"
                        />
                      </Grid>

                      <Typography
                        id="modal-modal-description"
                        style={{ paddingRight: "50px" }}
                      >
                        Are you sure you have received{" "}
                        {acceptPopupRecord?.length} Cheque
                        {acceptPopupRecord?.length > 1 ? "s" : ""} worth{" "}
                        <b>
                          ₹{" "}
                          {acceptPopupRecord
                            ?.reduce(
                              (total: any, record: any) =>
                                total + record.amountCollected,
                              0
                            )
                            .toFixed(2) || 0.0}
                        </b>
                        ?
                      </Typography>
                      <Box
                        sx={{ display: "flex", justifyContent: "end", mt: 3 }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleCloseModal}
                          sx={{
                            mr: 2,
                            fontSize: "10px",
                            height: "30px !important",
                          }}
                          className="crt-btn-cancel"
                        >
                          Cancel
                        </Button>
                        {/* <Button
                          sx={{ fontSize: "10px", height: "30px" }}
                          variant="contained"
                          className="btn-colr"
                          onClick={handleAcceptOk}
                        >
                          Yes
                        </Button> */}
                        <LoadingButton
                          className="Save_btn_des"
                          sx={{ fontSize: "10px", height: "30px" }}
                          onClick={handleAcceptOk}
                          loading={
                            salesReturnProductData.collectionChequeRejectAcceptDataLoading
                          }
                          variant="contained"
                        >
                          Yes
                        </LoadingButton>
                      </Box>
                    </Box>
                  </Modal>
                )}
                {isRejectShow ? (
                  <RejectedCheque onClose={() => setIsRejectShow(false)} />
                ) : (
                  <></>
                )}
                {openImage ? (
                  <ImageModal
                    open={openImage}
                    handleImageClose={() => setOpenImage(false)}
                    imageList={imageList}
                  />
                ) : (
                  <></>
                )}
              </div>
            </Grid>
          </Grid>
          {isShowDetails && (
            <InvoiceDetails
              onClose={() => setIsShowDetails(false)}
              requestData={invoiceRequestData}
            />
          )}
        </Grid>
      </Card>
      <hr className="hr-revieworder"></hr>
      <Grid item mt={1.5} sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          className={
            selectedRowKeys.length === 0 ||
            selectedRows.every((row) =>
              acceptedReturnNo.includes(row.collectionNumber)
            )
              ? "disabled_btn_discrepancy"
              : "btn-apply-color"
          }
          variant="contained"
          sx={{
            height: "34px",
            width: { sm: "15%", md: "11%", lg: "9%" },
          }}
          disabled={
            selectedRowKeys.length === 0 ||
            selectedRows.every((row) =>
              acceptedReturnNo.includes(row.collectionNumber)
            )
          }
          onClick={() =>
            handleAcceptPopup(
              dataRecordOrders?.filter((item) =>
                selectedRowKeys?.includes(item.collectionNumber)
              )
            )
          }
        >
          Accept
        </Button>
      </Grid>
    </Box>
  );
}
