// import React from "react";

// const ProductMaster = () => {
//   return <div>index</div>;
// };

// export default ProductMaster;
import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Checkbox,
  Grid,
  Typography,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import type { TableColumnsType } from "antd";
//icons
import Addicon from "../../../../assets/images/icons/add.png";
import Rightdot from "../../../../assets/images/icons/Right_dot.png";

// import MasterCompanyEdit from "./CompanyEditform";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useFetch";
import {
  ProductTaxFetchData,
  ProductTaxEdit,
} from "../../../../Redux/Masters/Products/ProductTaxMaster/action";
import {
  ProductCodeFetchData,
  ProductHsnCodeFetchData,
} from "../../../../Redux/Masters/Sidebar/action";
import { sidebarFilter } from "../../../../Redux/AuthLogin/action";
import InfiniteScroll from "react-infinite-scroll-component";
// import TableSkeletonLoader from "../../../components/Skeleton/skeleton";
import Modal from "@mui/material/Modal";
import CommonTable from "../../../../components/Tables/CommonTable";
import CreateProductTax from "./CreateProductTax";
import EditProductTax from "./EditProductTax";

// Define a type for the state object
interface StateObject {
  isChecked: any;
  id: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface DataType {
  key: React.Key;
  id: string;
  sno: string;
  productCode: string;
  hsnCode: string;
  productName: string;
  category: string;
  fromDate: string;
  intraInter: string;
  gst: number;
  cgst: number;
  sgst: number;
  igst: number;
  action: string;
}
export default function ProductTaxMaster() {
  // const classes = useStyles();
  const renderAfterCalled = useRef(false);
  const [open, setOpen] = React.useState(false);
  const [editingData, setEditingData] = React.useState<DataType | null>(null);
  const [showForm, setShowForm] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isShow, setIsShow] = React.useState(false);
  const [textFieldValue, setTextFieldValue] = React.useState("");
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const dispatch = useAppDispatch();
  const [checkboxStatus, setCheckboxStatus] = useState<{ [key: string]: any }>(
    {}
  );
  const [checkboxDefault, setCheckboxDefault] = useState<{
    [key: string]: any;
  }>({});
  const [requestData, setRequestData] = useState({
    page: 0,
    size: 3,
    hsnType: "",
    category: "",
  });
  const [prevData, setPrevData] = useState<any[]>([]);
  const [isDefaultActive, setIsDefaultActive] = React.useState(false);
  const [sampleState, setSampleState] = useState<StateObject | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [skeleton, setskeleton] = useState(false);

  const handleSearch = () => {
    //search function logic here
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleCreateNew = () => {
    setShowForm(!showForm);
  };

  const handleEditClick = (rowData: DataType) => {
    setIsEditing(!isEditing);
    toggleDrawer(true);
    setEditingData(rowData); // Set editingData when edit button is clicked
    dispatch(ProductTaxEdit(rowData.id));
    dispatch(ProductCodeFetchData());
    dispatch(ProductHsnCodeFetchData());
  };

  const handleShowClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setIsShow(!isShow);
    toggleDrawer(true);
    dispatch(ProductCodeFetchData());
    dispatch(ProductHsnCodeFetchData());
  };

  const ProductCodeFetchDatas = useAppSelector(
    (state: any) => state.sidebarFilter.ProductCodeName
  );
  const ProductHsnCodeFetchDatas = useAppSelector(
    (state: any) => state.sidebarFilter.ProductHsnCodeName
  );

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  //fetch data from db
  //   const companyFetchDatas = useAppSelector(
  //     (state: any) => state.companyFetch.companyFetch
  //   );
  //   const CompanyLength = companyFetchDatas?.length;

  //   useEffect(() => {
  //     if (prevData) {
  //       const initialCheckboxStatus: { [key: string]: any } = {};
  //       const initialCheckboxDefault: { [key: string]: any } = {}; // Declare initialCheckboxDefault
  //       prevData.forEach((record: any) => {
  //         initialCheckboxStatus[record.id] = record.status;
  //         initialCheckboxDefault[record.id] = record.isDefault;
  //       });
  //       setCheckboxStatus(initialCheckboxStatus);
  //       setCheckboxDefault(initialCheckboxDefault);
  //     }
  //   }, [prevData]);

  //   useEffect(() => {
  //     if (Array.isArray(companyFetchDatas)) {
  //       if (requestData.page === 0) {
  //         setPrevData(companyFetchDatas);
  //         setskeleton(false);
  //       } else {
  //         setPrevData((prevData) => [...prevData, ...companyFetchDatas]);
  //         setskeleton(false);
  //       }
  //     } else {
  //       setskeleton(false);

  //       console.error("companyFetchDatas is not an array:", companyFetchDatas);
  //     }
  //   }, [companyFetchDatas]);

  //   const fetchMoreData = () => {
  //     const nextPage = requestData.page + 1;
  //     const nextSize = requestData.size;
  //     const updatedRequestData = {
  //       ...requestData,
  //       page: nextPage,
  //       size: nextSize,
  //     };
  //     setRequestData(updatedRequestData);
  //     dispatch(companyFetchData(updatedRequestData));
  //     if (prevData?.length < nextSize) {
  //       setHasMore(false);
  //     }
  //   };

  useEffect(() => {
    // if (!renderAfterCalled.current) {
    //   dispatch(ProductTaxFetchData(requestData));
    // }
    // return () => {
    //   renderAfterCalled.current = true;
    // };
    dispatch(ProductTaxFetchData(requestData));
  }, [dispatch, requestData]);

  const ProductTaxFetchDatas = useAppSelector(
    (state: any) => state.ProductTaxFetchData.ProductTaxFetch
  );

  //CheckBox Active in Active
  //   const handleChange = (isChecked: any, id: string) => {
  //     const check = isChecked.toString();
  //     prevData.forEach((record: any) => {
  //       if (record.id === id) {
  //         dispatch(
  //           companyActiveStatus({ id: record.id.toString(), value: isChecked })
  //         );
  //         setCheckboxStatus((prevState) => ({
  //           ...prevState,
  //           [id]: check,
  //         }));
  //       }
  //     });
  //   };

  const handleActiveClick = (isChecked: any, id: string) => {
    setIsDefaultActive(!isDefaultActive);
    const newState: StateObject = {
      isChecked: isChecked,
      id: id,
    };
    setSampleState(newState);
  };

  //confirmation active
  //   const handleOk = () => {
  //     if (sampleState) {
  //       const { isChecked, id } = sampleState;
  //       if (isChecked !== undefined && id !== undefined) {
  //         handleChangeDefault(isChecked, id);
  //       }
  //     }
  //   };

  //   const handleChangeDefault = (isChecked: any, id: string) => {

  //     const updatedCheckboxDefault: { [key: string]: any } = {};
  //     for (const record of prevData) {
  //       updatedCheckboxDefault[record.id] = false;
  //     }
  //     updatedCheckboxDefault[id] = isChecked;

  //     setCheckboxDefault(updatedCheckboxDefault);
  //     dispatch(companyActiveDefault({ id: id.toString(), value: isChecked }));
  //     setIsDefaultActive(false);
  //   };

  //Model Close
  const handleClose = () => setIsDefaultActive(false);
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    handleClose();
    setIsDefaultActive(false);
  };

  useEffect(() => {
    dispatch(sidebarFilter(true, "Product Tax Master"));
  });
  //company table
  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "sno",
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span>S.No</span>
        </div>
      ),
      render: (_: any, __: any, index: number) => (
        <span
          className="sno_lable"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {index + 1}
        </span>
      ),
    },
    {
      dataIndex: "productCode",
      title: "PRODUCT CODE",
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      dataIndex: "hsnCode",
      title: "HSN CODE",
    },
    {
      dataIndex: "productName",
      title: "PRODUCT NAME CODE",
    },
    {
      dataIndex: "category",
      title: "CATEGORY ",
    },
    {
      dataIndex: "fromDate",
      title: "FROM DATE  ",
    },

    {
      dataIndex: "intraInter",
      title: " INTRA/INTER ",
    },
    {
      dataIndex: "gst",
      title: "GST %",
    },
    {
      dataIndex: "cgst",
      title: "CGST",
    },
    {
      dataIndex: "sgst",
      title: "SGST",
    },
    {
      dataIndex: "igst",
      title: "IGST",
    },

    {
      dataIndex: "action",
      title: "STATUS",
      //   render: (_: any, record: DataType, index: number) => (
      //     <Row>
      //       <Col>
      //         <Grid item style={{ marginLeft: "20px" }}>
      //           <Typography
      //             className={
      //               checkboxStatus[record.id] === "true"
      //                 ? "status_act"
      //                 : "status_Inact"
      //             }
      //           >
      //             {checkboxStatus[record.id] === "true" ? "Active" : "Inactive"}
      //           </Typography>
      //         </Grid>
      //       </Col>
      //     </Row>
      //   ),
    },
  ];

  return (
    <>
      {skeleton ? (
        <Box sx={{ marginTop: "2px" }}>
          <Grid container>
            <Grid item xs={6} className="mar-tb">
              <Skeleton
                variant="text"
                sx={{ fontWeight: "700", fontSize: "16px" }}
              />
            </Grid>
            <Grid
              item
              container
              xs={6}
              sm={6}
              md={6}
              className="mar-tb"
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Grid item sx={{ display: "flex" }}>
                <Button className="mastercrt-button">
                  <Skeleton variant="rectangular" />
                </Button>
                <Grid item>
                  <Skeleton variant="rectangular" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box id="TableScroll">
            {/* <TableSkeletonLoader
             rowCount={CompanyLength} 
            columns={10} /> */}
          </Box>
        </Box>
      ) : (
        <Box sx={{  pr: { xs: 3 }, pl: { xs: 2 } }}>
          <Grid  container sx={{ display: "flex", alignItems: "center" }}>
            <Grid
              item
              xs={6}
              //  className="mar-tb"
            >
              <Typography>
                <b className="header-lable">Product Tax Master</b>
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={6}
              sm={6}
              md={6}
              // className="mar-tb"
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <Button className="mastercrt-button" onClick={handleShowClick}>
                  {" "}
                  <img src={Addicon} alt={Addicon} />{" "}
                  <span className="Crt-lable"> Create New</span>
                </Button>
                {/* <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={Rightdot}
                    alt={Rightdot}
                    style={{ width: "44px", height: "44px" }}
                  />
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <Box id="TableScroll" sx={{ mt: 3 }}>
            {/* <Grid item xs={12} sx={{ display: "flex", justifyContent: "end", mb: 2 }} >
          <SearchBar
            className="search-table"
            value={textFieldValue}
            onChange={(newValue) => {
              setTextFieldValue(newValue);
              handleSearch();
            }}
          />
        </Grid> */}
            {/* <InfiniteScroll
              dataLength={companyFetchDatas?.length ?? 0}
              next={fetchMoreData}
              hasMore={false}
              className=""
              loader={
                <h6
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <CircularProgress
                    style={{
                      color: "#119BFF",
                    }}
                    size={20}
                  />
                </h6>
              }
            > */}
            <CommonTable
              // rowSelection={rowSelection}
              data={ProductTaxFetchDatas}
              // data={prevData ? prevData : ProductTaxFetchDatas}
              columns={columns}
              // scroll={{ y: 300 }}
              onRow={(record) => ({
                onClick: () => handleEditClick(record),
              })}
            />
            {/* </InfiniteScroll> */}
          </Box>
          {/* {data.map((rowData) => (
        <Button
          key={rowData.key}
          onClick={(event) => handleEditClick(event, rowData)}
        >
          Edit
        </Button>
      ))} */}
          {isShow ? (
            <CreateProductTax
              onClose={() => setIsShow(false)}
              requestData={requestData}
              product_Code={ProductCodeFetchDatas}
              hsn_Code={ProductHsnCodeFetchDatas}
            />
          ) : isEditing ? (
            <EditProductTax
              onClose={() => setIsEditing(false)}
              requestData={requestData}
              product_Code={ProductCodeFetchDatas}
              hsn_Code={ProductHsnCodeFetchDatas}
            />
          ) : (
            <></>
          )}

          <Modal
            open={isDefaultActive}
            onClose={handleClose}
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-description">
                Are you sure you want to activate the status?
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleBackClick}
                  sx={{ mr: 2 }}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  className="btn-colr"
                  //   onClick={handleOk}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      )}
    </>
  );
}
