import { jwtDecode } from "jwt-decode";

// Define the JwtPayload interface
export interface JwtPayload {
  sub?: string;
  role?: string;
  empId?: string;
  Distributor?: string;
  CompanyId?: string;
  Name?: string;
  Email?: string;
  id?: string;
  iat?: number;
  exp?: number;
}

// Define a constant function to decode the JWT token
export const decodeJwt = (token: string): JwtPayload => {
  try {
    const decoded = jwtDecode<JwtPayload>(token);
    return decoded;
  } catch (error) {
    console.error("Invalid token:", error);
    throw new Error("Invalid token");
  }
};
export const PERMISSIONS = {
  CAN_VIEW_ADMIN: "CAN_VIEW_ADMIN",
  CAN_VIEW_DISTRIBUTOR: "CAN_VIEW_DISTRIBUTOR",
  CAN_VIEW_SALES: "CAN_VIEW_SALES",
  CAN_VIEW_DELIVERY_BOY: "CAN_VIEW_DISTRIBUTOR",
} as const;

export const ROLE_LIST = {
  ADMIN: "admin",
  DISTRIBUTOR: "distributor",
  SALES: "SALES",
  DELIVERY_BOY: "DELIVERY_BOY",
} as const;

export const API_AUTH_URL = "http://yourapiurl.com"; // replace with your actual API URL

export function camelCaseToNormal(camelCaseStr: string) {
  return camelCaseStr
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/^./, (str) => str.toUpperCase());
}
