import React, { useRef, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  MenuItem,
  Select,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ETL: React.FC = () => {
  const navigator = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFileName, setSelectedFileName] = useState<string>(" ");

  const options = [
    { value: "file1", label: "File 1" },
    { value: "file2", label: "File 2" },
    // Add more options as needed
  ];

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setSelectedFileName(selectedFile.name);
      // You can perform additional actions with the selected file here
    }
  };

  const handleSelectFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className=" mar-tb">
          <Typography>
            <b className="header-lable">ETL </b>
          </Typography>
        </Grid>

        <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }} mt={2}>
          <Grid item xs={12} sm={4} md={3}>
            <Select className="selct-box" value={""} displayEmpty>
              <MenuItem value="" disabled>
                Select Master File
              </MenuItem>
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: "none" }}
              onChange={handleFileInputChange}
            />

            <Button
              variant="contained"
              color="primary"
              className="btn-colr"
              onClick={handleSelectFileButtonClick}
              sx={{ width: "90%" }}
            >
              Select File
            </Button>
          </Grid>
          <Grid item>
            <Typography variant="body1">{selectedFileName}</Typography>
          </Grid>
        </Grid>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }} mt={2}>
          <Grid item xs={12} sm={4} md={3}>
            <Button
              variant="contained"
              color="primary"
              className="btn-colr"
              sx={{ width: "90%" }}
            >
              Download Format
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Button
              variant="contained"
              color="primary"
              className="btn-colr"
              sx={{ width: "90%" }}
            >
              Upload
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ETL;
