import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 200, // Adjust as needed
    height: 110, // Adjust as needed
    backgroundColor: "transparent", // Set background color to transparent
    margin: "5px",
    lineHeight: "normal",
    border: "1px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "none",

    "&:hover": {
      backgroundColor: "rgba(148, 73, 134, 0.1)",
      boxShadow: "0 0px 3px rgba(0, 0, 0, 0.3)", // Add box shadow
      transition: "box-shadow 0.3s ease", // Change background color on hover
      cursor: "pointer",
      "& $subTitle": {
        color: "#944986", // ChangesubTitle color on hover
      },
    },
  },
  hoverCard: {
    backgroundColor: "rgba(148, 73, 134, 0.1)",
    boxShadow: "0 0px 3px rgba(0, 0, 0, 0.3)", // Add box shadow
    transition: "box-shadow 0.3s ease", // Change background color on hover
  },
  title: {
    color: "#944986", // Default title color
  },
  subTitle: {
    "&:hover": {
      color: "#0000000", // Change color on hover
    }, // Default title color
  },
  hiddenCard: {
    visibility: "hidden",
  },
}));

interface MasterProductDashboardProps {
  title: string;
  description: string;
  index: number;
  hidden?: boolean;
}

const MasterProductDashboard: React.FC<MasterProductDashboardProps> = ({
  title,
  description,
  index,
}) => {
  const classes = useStyles();
  return (
    // <Card className={`${classes.card} ${index === 0 ? classes.hoverCard : ''}`}>
    <CardContent
      style={{ padding: "12px" }}
      className={`${classes.card} ${index === 0 ? classes.hoverCard : ""}`}
    >
      <span
        className={`${classes.subTitle} ${index === 0 ? classes.title : ""}`}
        style={{
          fontSize: "13px",
          fontWeight: "600",
          padding: "0px 0px 8px 0px",
        }}
      >
        {title}
      </span>
      <span style={{ fontSize: "10px", fontWeight: "600", color: "#000000" }}>
        {description}
      </span>
    </CardContent>
    // </Card>
  );
};

const CustomGrid: React.FC = () => {
  const navigator = useNavigate();
  const navigateToStateCode = () => {
    navigator("/master/product/product_state_code");
  };
  return (
    <Grid
      container
      // gap={3}
      columnSpacing={3}
      sx={{
        p: { xs: 2, sm: 3 },
        backgroundColor: "#FFFFFF",
        pr: 5,
      }}
      className="company_card_prd"
    >
      {/* First line with 6 cards */}
      <Grid
        item
        xs={6}
        md={4}
        lg={2}
        sm={6}
        onClick={() => navigator("/master/product_master")}
      >
        <MasterProductDashboard
          index={0}
          title="Product Master"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
      <Grid
        item
        xs={1}
        md={4}
        lg={2}
        sm={6}
        onClick={() => navigator("/master/product/Product_hierarchy")}
      >
        <MasterProductDashboard
          index={1}
          title="Product Hierarchy"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
      <Grid
        item
        xs={6}
        md={4}
        lg={2}
        sm={6}
        onClick={() => navigator("/master/master_Product_UOM")}
      >
        <MasterProductDashboard
          index={2}
          title="UOM Master"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
      <Grid
        item
        xs={6}
        md={4}
        lg={2}
        sm={6}
        onClick={() => navigator("/master/product/product_hsn_code")}
      >
        <MasterProductDashboard
          index={3}
          title="HSN Code Master"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
      <Grid
        item
        xs={6}
        md={4}
        lg={2}
        sm={6}
        onClick={() => navigator("/master/product_tax_master")}
      >
        <MasterProductDashboard
          index={4}
          title="Product Tax Master"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
      <Grid item xs={6} md={4} lg={2} sm={6} onClick={navigateToStateCode}>
        <MasterProductDashboard
          index={5}
          title="GST State Tax Code"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
      {/* Second line with 4 cards, excluding first and last */}
      <Grid
        item
        xs={6}
        md={4}
        lg={2}
        sm={6}
        sx={{ display: { md: "none", sm: "none", lg: "flex", xs: "none" } }}
      ></Grid>
      <Grid
        item
        xs={6}
        md={4}
        lg={2}
        sm={6}
        onClick={() => navigator("/master/product_Must_Sell_SKU")}
      >
        <MasterProductDashboard
          index={6}
          title="Must Sell SKU "
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
      <Grid item xs={6} md={4} lg={2} sm={6}>
        <MasterProductDashboard
          index={7}
          title="Recommended SKU"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
      <Grid item xs={6} md={4} lg={2} sm={6}>
        <MasterProductDashboard
          index={8}
          title="Product Groups"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
      <Grid item xs={6} md={4} lg={2} sm={6}>
        <MasterProductDashboard
          index={9}
          title="Schemes"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
      <Grid item xs={6} md={4} lg={2} sm={6} />
    </Grid>
  );
};

export default CustomGrid;
