import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  Drawer,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import Close from "../../../assets/images/icons/Close_icon.png";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import { getSingleDiscrepencyInfo } from "../../../Redux/XDM/Purchase/GrnReducer";
import { Spin } from "antd";

interface DiscrepancyProps {
  onClose: () => void;
  grnNo:string;
  batchCode:string;
}


export default function ViewDiscrepancyInfo(props: Readonly<DiscrepancyProps>) {
  const { onClose,grnNo,batchCode } = props;
  const dispatch = useAppDispatch();

  let grnData=useAppSelector((state)=>state.pendingGrnData);
  let discrepancyInfo =grnData?.discrepancyInfo
 
 useEffect(() => {
   dispatch(
     getSingleDiscrepencyInfo({params:{
       grnNo: grnNo,
       batchCode: batchCode,
     }}) 
   );
 }, [dispatch, grnNo, batchCode]);
  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer anchor="right" open={true} className="pen_drawer_stl">
        <Box
          sx={{
            width: {
              lg: 500,
              md: 460,
              sm: 400,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
            backgroundColor: "#F6F6F6",
          }}
        >
          <Grid item sx={{ height: "100%" }}>
            <Grid
              container
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                background: "white",
                height: "60px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
              }}
            >
              <Grid item sm={7}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "14px",
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                  }}
                >
                  Discrepancy Items
                </Typography>
              </Grid>
              <Grid item sm={5} sx={{ display: "flex", justifyContent: "end" }}>
                <Button onClick={onClose} className="btn_close_grn">
                  <img
                    src={Close}
                    alt="Close"
                    style={{ width: "20px", height: "20px" }}
                  />
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{ overflowY: "auto", height: "80%", padding: "20px" }}
              className="discrepancy_card"
            >
              {discrepancyInfo !== null ? (
                <Card
                  sx={{
                    border: "1px solid #DCDCDE",
                    padding: "15px",
                    borderRadius: "6px",
                    marginBottom: "10px",
                  }}
                >
                  <>
                    <Grid container sx={{ alignItems: "center" }}>
                      <Grid item sm={12}>
                        <Grid item>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "12px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            {discrepancyInfo?.productName}
                          </Typography>
                        </Grid>
                        <Grid item mt={0.1}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "12px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            {discrepancyInfo?.productCode}
                          </Typography>
                        </Grid>
                        <Grid item mt={0.2}>
                          <Typography
                            sx={{
                              color: `${
                                discrepancyInfo?.status === "Rejected"
                                  ? "#E12B1D"
                                  : "#F28500"
                              }`,
                              fontSize: "12px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            {discrepancyInfo?.status}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container mt={2}>
                      <Grid item sm={3.3}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontSize: "10px",
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                          }}
                        >
                          Invoice Quantity : {discrepancyInfo?.invoiceQty}
                        </Typography>
                      </Grid>
                      <Grid item sm={3.9}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontSize: "10px",
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                          }}
                        >
                          Invoice Value : ₹
                          {discrepancyInfo?.invoiceValue
                            ? Number(discrepancyInfo?.invoiceValue).toFixed(2)
                            : "0.00"}
                        </Typography>
                      </Grid>
                      <Grid item sm={2.1}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontSize: "10px",
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                          }}
                        >
                          UOM :{discrepancyInfo?.uom ?discrepancyInfo?.uom:"-"}
                        </Typography>
                      </Grid>
                      <Grid item sm={2.7}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontSize: "10px",
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                          }}
                        >
                          MRP / PC : ₹ {discrepancyInfo?.mrp}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container mt={2}>
                      {discrepancyInfo?.reason &&
                        Object.entries(discrepancyInfo.reason).length > 0 &&
                        Object.entries(discrepancyInfo.reason).map(
                          ([reason, qty]: [string, any], idx: number) => (
                            <React.Fragment key={idx}>
                              <Grid item sm={6}>
                                <Grid item mb={1.5}>
                                  <Typography
                                    sx={{
                                      color: "#44464C",
                                      fontSize: "12px",
                                      fontFamily: "Montserrat",
                                      fontWeight: 500,
                                    }}
                                  >
                                    Reason
                                  </Typography>
                                </Grid>
                                <Grid item mb={1.5}>
                                  <Typography
                                    sx={{
                                      color: "#000000",
                                      fontSize: "12px",
                                      fontFamily: "Montserrat",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {reason}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid item sm={6}>
                                <Grid item mb={1.5}>
                                  <Typography
                                    sx={{
                                      color: "#44464C",
                                      fontSize: "12px",
                                      fontFamily: "Montserrat",
                                      fontWeight: 500,
                                    }}
                                  >
                                    Quantity
                                  </Typography>
                                </Grid>
                                <Grid item mb={1.5}>
                                  <Typography
                                    sx={{
                                      color: "#000000",
                                      fontSize: "12px",
                                      fontFamily: "Montserrat",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {qty}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          )
                        )}
                    </Grid>
                    <Grid container>
                      <Grid item sm={6}>
                        <Grid item mb={1.5}>
                          <Typography
                            sx={{
                              color: "#44464C",
                              fontSize: "12px",
                              fontFamily: "Montserrat",
                              fontWeight: 500,
                            }}
                          >
                            Discrepancy Quantity
                          </Typography>
                        </Grid>
                        <Grid item mb={1.5}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "12px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            {discrepancyInfo?.discrepancyQty
                              ? discrepancyInfo?.discrepancyQty
                              : "0"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item sm={6}>
                        <Grid item mb={1.5}>
                          <Typography
                            sx={{
                              color: "#44464C",
                              fontSize: "12px",
                              fontFamily: "Montserrat",
                              fontWeight: 500,
                            }}
                          >
                            Discrepancy Value
                          </Typography>
                        </Grid>
                        <Grid item mb={1.5}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "12px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            ₹
                            {discrepancyInfo?.discrepancyValue
                              ? Number(
                                  discrepancyInfo?.discrepancyValue
                                ).toFixed(2)
                              : "0.00"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item sm={6}>
                        <Grid item mb={1.5}>
                          <Typography
                            sx={{
                              color: "#44464C",
                              fontSize: "12px",
                              fontFamily: "Montserrat",
                              fontWeight: 500,
                            }}
                          >
                            Accepted Quantity
                          </Typography>
                        </Grid>
                        <Grid item mb={1.5}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "12px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            {discrepancyInfo?.acceptedQty
                              ? discrepancyInfo?.acceptedQty
                              : "0"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item sm={6}>
                        <Grid item mb={1.5}>
                          <Typography
                            sx={{
                              color: "#44464C",
                              fontSize: "12px",
                              fontFamily: "Montserrat",
                              fontWeight: 500,
                            }}
                          >
                            Accepted Value
                          </Typography>
                        </Grid>
                        <Grid item mb={1.5}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "12px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            ₹
                            {discrepancyInfo?.acceptedValue
                              ? Number(discrepancyInfo?.acceptedValue).toFixed(
                                  2
                                )
                              : "0.00"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                </Card>
              ) : (
                <Grid item sm={12} height={300}>
                  <Typography
                    sx={{
                      color: "#44464C",
                      fontSize: "14px",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      textAlign: "center",
                      height: "60vh",
                    }}
                  >
                    {grnData.discrepancyInfoLoading ? (
                      <Skeleton
                        variant="rectangular"
                        height={350}
                        sx={{
                          borderRadius: 2,
                          width: {
                            lg: 450,
                            md: 430,
                            sm: 350,
                            xs: 350,
                          },
                        }}
                      />
                    ) : (
                      <b>No discrepancies found.</b>
                    )}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </Box>
  );
}
