import React from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import UndoIcon from "@mui/icons-material/Undo";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Close from "@mui/icons-material/Close";
import { TableColumnsType } from "antd";
import CommonTable from "../Tables/CommonTable";

interface PrimaryUploadmodelProps {
  handleClose: () => void; // A function that takes no arguments and returns void
}
interface DataType {
  key: React.Key;
  levelValue: string;

}

const Distributorbranchmodal = ({ handleClose }: PrimaryUploadmodelProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const option = [
    { value: "Super Stockiest ", label: "Super Stockiest " },
    { value: "Sub Stockiest", label: "Sub Stockiest" },
    { value: "Distributor", label: "Distributor" },
  ];
  const opctions = [
    { value: "Head of Sales", label: " Head of Sales" },
    { value: "", label: "" },
    { value: "", label: "" },
  ];
  
  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "levelValue",
      title: "levelValue",
      // sorter: (a, b) => a.code.length - b.code.length,
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      levelValue: "Head of Sales - PC - NM - SaLES",
    },

  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <Box>
      <form>
        <Grid
          item
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item>Add Distributor</Grid>
          <IconButton aria-label="close" onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
        <Divider />

        <Grid container mt={4}>
          <Grid item mr={2} mt={1} >
            Supper Chain Entity
          </Grid>
          <FormControl fullWidth>
            <Select labelId="demo-simple-select-label" className="selct-box">
              {option.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid container mt={2}>
          <Grid item mr={2} mt={1} >
            LOB Value
          </Grid>
          <TextField variant="outlined" className="Txt-box" />
          <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
            ...
          </Button>
        </Grid>

        <Card className="card-form" sx={{ border: "1px solid #EEE", mt: 3 }}>
          <Grid className="box-lable fil-lab">Filter Based On :</Grid>
          <Grid container sx={{ marginTop: "16px" }}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="Sales hierarchy"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Sales hierarchy"
                />
                <FormControlLabel
                  value="Geography hierarchy"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Geography hierarchy "
                />
                <FormControlLabel
                  value="Distributor"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Distributor "
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Card>
        <Grid item mt={3} >
          <Grid item mb={1}>
            level
          </Grid>
          <FormControl fullWidth>
            <Select labelId="demo-simple-select-label" className="selct-box">
              {opctions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item mt={2}>
          <Grid item>Value</Grid>
          <Grid container columnGap={2}>
            <Grid item xs={8} style={{ height: "100%", width: "100%" }}>
            <CommonTable
            //  rowSelection={rowSelection} 
             data={data} columns={columns} />

            </Grid>
            <Grid item mt={1}>
              <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
                <KeyboardDoubleArrowLeftIcon />
              </Button>
              <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
                <KeyboardDoubleArrowRightIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item mt={3} sx={{ display: "flex", justifyContent: "center" }}>
          <Button className="btn-colr " variant="contained" sx={{ mr: "10px" }}>
            Ok
          </Button>
          <Button
            className="next-button"
            variant="outlined"
            onClick={handleClose}
          >
            <UndoIcon
              sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
            />
            Cancle
          </Button>
        </Grid>
      </form>
    </Box>
  );
};
export default Distributorbranchmodal;

