import {
  SIDEBAR_BUSNIESS_LEVEL,
  SIDEBAR_CATEGORY_LEVEL,
  SIDEBAR_BRAND_LEVEL,
  SIDEBAR_BRAND_CATEGORY_LEVEL,
  SIDEBAR_PRODUCT_VARIENT_LEVEL,
  SIDEBAR_SKU_GROUP_LEVEL,
  SIDEBAR_SKU_LEVEL,
  PRODUCT_CODE_NAME,
  PRODUCT_HSN_CODE_NAME
} from '../../actionType'
interface State {
  bussinesslevelFetch: [];
  categorylevelFetch: [];
  brandlevelFetch: [];
  brandCategorylevelFetch: [];
  productVarientlevelFetch: [];
  skuGroupFetch: [];
  skulevelFetch: [];
  ProductCodeName: [];
  ProductHsnCodeName: [];
}

const initialState: State = {
  bussinesslevelFetch: [],
  categorylevelFetch: [],
  brandlevelFetch: [],
  brandCategorylevelFetch: [],
  productVarientlevelFetch: [],
  skuGroupFetch: [],
  skulevelFetch: [],
  ProductCodeName: [],
  ProductHsnCodeName: [],

};


const sidebarFilterReducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case SIDEBAR_BUSNIESS_LEVEL:
      return {
        ...state,
        bussinesslevelFetch: action.payload,
      };
    case SIDEBAR_CATEGORY_LEVEL:
      return {
        ...state,
        categorylevelFetch: action.payload,
      };
    case SIDEBAR_BRAND_LEVEL:
      return {
        ...state,
        brandlevelFetch: action.payload,
      };
    case SIDEBAR_BRAND_CATEGORY_LEVEL:
      return {
        ...state,
        brandCategorylevelFetch: action.payload,
      };
    case SIDEBAR_PRODUCT_VARIENT_LEVEL:
      return {
        ...state,
        productVarientlevelFetch: action.payload,
      };
    case SIDEBAR_SKU_GROUP_LEVEL:
      return {
        ...state,
        skuGroupFetch: action.payload,
      };
    case SIDEBAR_SKU_LEVEL:
      return {
        ...state,
        skulevelFetch: action.payload,
      };

    case PRODUCT_CODE_NAME:
      return {
        ...state,
        ProductCodeName: action.payload,
      };
    case PRODUCT_HSN_CODE_NAME:
      return {
        ...state,
        ProductHsnCodeName: action.payload,
      };

    default:
      return state;
  }
};

export default sidebarFilterReducer;

