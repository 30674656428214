  import {
    Box,
    Card,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    RadioGroup,
    Typography,
    Radio,
    Modal,
    Button,
  } from "@mui/material";
  import { IoMdClose } from "react-icons/io";
  import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
  import {
    updateDate,
    updateFromDate,
    updateToDate,
  } from "../../../Redux/XDM/Sales/billingReducer";
  import { useEffect, useState } from "react";
  import FilterModal from "./../Sales/FilterModal";
  // import DateFilterModal from "./DateFilterModal";
  import { DatePicker, Space } from "antd";
  import moment, { Moment } from "moment";
  import dayjs, { Dayjs } from "dayjs";
  import { IoClose } from "react-icons/io5";
  
  const { RangePicker } = DatePicker;
  const InvoiceAndGrnSidebar = () => {
    const dispatch = useAppDispatch();
    const [filterModalTitle, setFilterModalTitle] = useState("");
    const fetch = useAppSelector((state) => state.orderBilling);
    const [dateFilter, setDateFilter] = useState("");
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [selectedDates, setSelectedDates] = useState<
      [Dayjs | null, Dayjs | null]
    >([null, null]);
  
    const handleFromToDateChange = (dates: [Dayjs | null, Dayjs | null]) => {
      setSelectedDates(dates);
    };

    const handleCustomDateApply = () => {
      const [fromDate, toDate] = selectedDates;
      if(fromDate && toDate){
      setIsDatePickerOpen(false);
      dispatch(updateFromDate(fromDate?.format("YYYY-MM-DD")));
      dispatch(updateToDate(toDate?.format("YYYY-MM-DD")));
      }
    };    

    const handleDateChange = (event: any) => {
      setSelectedDates([null, null])
      setDateFilter(event.target.value);
      if (event.target.value === "CUSTOMDATE") {      
        setIsDatePickerOpen(true);
      } else {
        setIsDatePickerOpen(false);
      }
      dispatch(updateDate(event.target.value));
    };
    const labels = ["Today", "WTD", "MTD", "Custom"];
    const values = ["TODAY", "SEVENDAYS", "LASTMOC", "CUSTOMDATE"];

  
    const handleDatePickerClose = () => {
    dispatch(updateDate([]));
    dispatch(updateToDate([]));
    dispatch(updateFromDate([]));
    setIsDatePickerOpen(false);
    };
  
    const filterData = {
      page: 0,
      size: 4,
      pageStatus: true,
      status:"Yet to Confirm"
    };
  
  
    const salesman = fetch.salesman;
  
    const routes = fetch.routes;
  
    const outlets = fetch.outlets;
  
    const date = fetch.date;
    const fromDate = fetch.fromDate;
    const toDate = fetch.toDate;
  
    const salesmanSelected = fetch.salesmanSelected;
    const routeSelected = fetch.routeSelected;
    const outletSelected = fetch.outletSelected;
    const dateSelected = fetch.dateSelected;
    const fromDateSelected = fetch.fromDateSelected;
    const toDateSelected = fetch.toDateSelected;
  
    const clearAll = (title: any) => {
      switch (title) {
        case "Date":
          dispatch(updateDate([]));
          dispatch(updateToDate([]));
          dispatch(updateFromDate([]));
          setIsDatePickerOpen(false);
          break;
        default:
          break;
      }
    };
  
    const filterOpenSales = useAppSelector(
      (state) => state.orderBilling.filterPop
    );
  

  
    return (
      <Box
        sx={{
          padding: "0px 5px 0px 16px",
          marginBottom: "100px",
          marginRight: "4.3px",
          marginTop: "4px",
        }}
        className="dis-flex scrollHide"
      >
        <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
          <Box
            alignItems="center"
            sx={{
              padding: "5px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
              Date
            </Typography>
          </Box>
          <Divider />
          <FormControl component="fieldset" sx={{ mt: 1 }}>
            <RadioGroup value={dateSelected || ""} onChange={handleDateChange}>
              {labels.map((label, index) => (
                <FormControlLabel
                  key={index}
                  value={values[index]}
                  control={
                    <Radio
                      className="sidebar_checkbox"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 15,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label={
                    <div
                      style={{ paddingTop: "2px", paddingLeft: "5px" }}
                      className="filter_label"
                    >
                      {label}
                    </div>
                  }
                />
              ))}
            </RadioGroup>
            <Box
              alignItems="center"
              sx={{
                padding: "5px 0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {(Array.isArray(dateSelected) && dateSelected.length > 0) ||
              (!Array.isArray(dateSelected) && dateSelected) ? (
                <Grid
                  item
                  xs={8}
                  sx={{
                    color: "#F5222D",
                    fontSize: "10px",
                    fontWeight: "500",
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="cursor_pointer"
                  onClick={() => clearAll("Date")}
                >
                  <IoMdClose size={7} />
                  &nbsp; Clear all
                </Grid>
              ) : (
                <Grid item xs={8}></Grid>
              )}
              <Grid item xs={4}></Grid>
            </Box>
          </FormControl>
          {/* <DateFilterModal open={isDatePickerOpen} onClose={handleDatePickerClose} /> */}
          {((Array.isArray(dateSelected) && dateSelected.length > 0) ||
            (!Array.isArray(dateSelected) && dateSelected))  && isDatePickerOpen && (
            <RangePicker
              className="invoice_dateRange"
              popupClassName="popup_range"
              onChange={handleFromToDateChange}
              renderExtraFooter={() => (
                <>
                <Grid item>
                <Grid item
                 className="header_popup_design"
                >
                  <Typography className="popup_header_label">Select Date</Typography>
                  <IoClose className="ClosePop" onClick={handleDatePickerClose} />
                </Grid>
              </Grid>
                <Grid item>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Grid item className="from_to_date_border">
                      <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
                        {selectedDates[0]
                          ? selectedDates[0].format("DD/MM/YYYY")
                          : "Start Date"}
                      </Typography>
                    </Grid>
                    -
                    <Grid item className="from_to_date_border">
                      <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
                        {selectedDates[1]
                          ? selectedDates[1].format("DD/MM/YYYY")
                          : "End Date"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "16px",
                    }}
                    mb={1}
                  >
                    <Grid item xs={5.6} md={5.6} lg={5.6}>
                      <Button
                        className="invoice_close_btn"
                        variant="outlined"
                        onClick={handleDatePickerClose}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={5.6} md={5.6} lg={5.6}>
                      <Button
                        className="btn-colr"
                        variant="contained"
                        style={{
                          fontSize: "10px",
                          height: "26px",
                          width: "100%",
                        }}
                        onClick={handleCustomDateApply}
                      >
                        Apply
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                </>
              )}
              showTime
              open
            />
          )}
        </Card>
  
        {filterOpenSales && <FilterModal status="" title={filterModalTitle} />}
      </Box>
    );
  };
  
  export default InvoiceAndGrnSidebar;
  