import React from "react";
import {
  Button,
  Grid,
  TextField,
  Box,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  InputLabel,
  Typography,
  Card,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { SelectChangeEvent } from "@mui/material/Select";
import { TableColumnsType } from "antd";
import CommonTable from "../../../../components/Tables/CommonTable";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteModal from "../../../../components/Modal/DeleteSupplier";
import ProductOtherAttributes from "./ProductOtherAttributes";

// import ProductInfoView from "./ProductInfo";

// import DistributorSales from "./DistributorSales";
interface DataType {
  key: React.Key;
  GeographyHierarchy: string;
  GeographyHierarchyDescription: string;
}

interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const ProductGSTMapping: React.FC<DistributorTabProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        //   width: 250,
      },
    },
  };
  const handleChanges = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [isDelete, setIsDelete] = React.useState(false);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const Options = ["Normal", "", ""];

  
  

  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };

  //table
  const columns: TableColumnsType<DataType> = [
    {
      title: "Geography Hierarchy Level Value Code",
      dataIndex: "GeographyHierarchy",
    },
    {
      title: "Geography Hierarchy Level Value Description ",
      dataIndex: "GeographyHierarchyDescription",
      //   sorter: (a, b) => a.CreatedDate.length - b.CreatedDate.length,
    },

  ];

  const data: DataType[] = [
    {
      key: "1",
      GeographyHierarchy: "100IN",
      GeographyHierarchyDescription: "India",

    },
    {
      key: "2",
      GeographyHierarchy: "AV07",
      GeographyHierarchyDescription: "	VELLORE",
      
    },
    {
        key: "3",
        GeographyHierarchy: "CB03",
        GeographyHierarchyDescription: "	BANGALORE",
        
      },
      {
        key: "4",
        GeographyHierarchy: "CC02",
        GeographyHierarchyDescription: 	"CHAMRAJNAGAR",
        
      },
      {
        key: "5",
        GeographyHierarchy: "CC07",
        GeographyHierarchyDescription: "	CHIKMAGALUR",
        
      },


  
  ];
  const handleDeleteClick = (event: React.MouseEvent<unknown>) => {
    setIsDelete(!isDelete);
  };
  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    //search function logic here
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <>
      {show ? (
       <ProductOtherAttributes onBack={handleclick}/>
      ) : (
        <Box>
          <form noValidate>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
      

              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">HSN Code</Typography>
                <TextField placeholder="Product code" className="Txt-box" />
           
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">HSN Name </Typography>
                <TextField placeholder="Product code" className="Txt-box" />
           
              </Grid>
              
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">	GST Product Type</Typography>
                <FormControl fullWidth >
                  <Select labelId="demo-simple-select-label" className="selct-box">
                    {Options.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
       


            </Grid>
  

           
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", mt: 4 }}
            >
              <Grid item sx={{ mr: 2 }}>
                <Button variant="outlined" onClick={handleBackClick} className="button-lable">
               
                 Back
                </Button>
              </Grid>
              <Grid>
                <Button
                  className="btn-colr "
                  variant="contained"
                  onClick={handleclick}
                >
                  <RedoOutlinedIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />
                  Next
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </>
  );
};
export default ProductGSTMapping;
