import React, { useState } from 'react';
import { Box, Card, Button, RadioGroup, FormControlLabel, Radio, Grid, TextField } from '@mui/material';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";


interface SchemeControlFormProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
}

const SchemeControlForm: React.FC<SchemeControlFormProps> = ({ onBack }) => {

    const handleBackClick = (event: React.MouseEvent<unknown>) => {
        onBack(event); // Pass the event argument to onBack function
    };
    const [showEditScheme, setShowEditScheme] = useState(true);
    const [showAddProduct, setShowAddProduct] = useState(false);

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setShowEditScheme(value === 'deactivate');
        setShowAddProduct(value === 'addproduct');
    };

    const SchemeRecipient = [
        { value: "", label: "None" },
        { value: "retailer", label: "Retailer" },
    ];


    const SchemeSearch = [
        { value: "", label: "None" },
        { value: "schemecode", label: "Company Scheme Code" },
    ];

    const SchemeStatus = [
        { value: "active", label: "Active" },
        { value: "Inactive", label: "InActive" },
    ];

    return (
        <Box>
            <Card sx={{ border: "1px solid #EEE" }} className='card-form'>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleRadioChange}
                    value={showEditScheme ? 'deactivate' : (showAddProduct ? 'addproduct' : '')}
                >
                    <FormControlLabel value="deactivate" control={<Radio
                        sx={{
                            "& .MuiSvgIcon-root": {
                                fontSize: 18,
                                color: "#6750A4",
                            },
                        }}
                    />} label="Scheme Deactivation" />
                    <FormControlLabel value="addproduct" control={<Radio
                        sx={{
                            "& .MuiSvgIcon-root": {
                                fontSize: 18,
                                color: "#6750A4",
                            },
                        }}
                    />} label="Add Product to Scheme" />
                </RadioGroup>
            </Card>
            {showEditScheme && (
                <>
                    <Box>
                        <Button variant="contained" className='btn-apply'>Edit Scheme Deactivation</Button>
                        <Card sx={{ border: "1px solid #EEE", marginTop: '10px' }} className='card-form' >
                            <form>

                                <Grid item container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <InputLabel size="normal" className='fnt-lable' >Scheme Recipient</InputLabel>
                                        <FormControl fullWidth>
                                            <Select className="Country-selectbox" >
                                                {SchemeRecipient.map((recipient, index) => (
                                                    <MenuItem key={index} value={recipient.value}>
                                                        {recipient.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={3}>
                                        <InputLabel size="normal" className='fnt-lable' >From Date</InputLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <InputLabel size="normal" className='fnt-lable' >To Date</InputLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <InputLabel size="normal" className='fnt-lable' >Scheme Search By </InputLabel>
                                        <FormControl fullWidth>
                                            <Select className="Country-selectbox" >
                                                {SchemeSearch.map((scheme, index) => (
                                                    <MenuItem key={index} value={scheme.value}>
                                                        {scheme.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <InputLabel size="normal" className='fnt-lable' >Scheme Search Input</InputLabel>
                                        <TextField className='Txt-box' fullWidth />
                                    </Grid>

                                    <Grid item xs={12} sm={4} md={3}>
                                        <InputLabel size="normal" className='fnt-lable' >Scheme Code</InputLabel>
                                        <TextField className='Txt-box' fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <InputLabel size="normal" className='fnt-lable' >Scheme Status</InputLabel>
                                        <FormControl fullWidth>
                                            <Select className="Country-selectbox" >
                                                {SchemeStatus.map((status, index) => (
                                                    <MenuItem key={index} value={status.value}>
                                                        {status.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3} mt={1.4}>
                                        <Button variant='contained' className='btn-colr'>Go</Button>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ display: "flex", justifyContent: "center" }} mt={2}>
                                    <Grid item sx={{ mr: 2 }}>
                                        <Button variant="outlined" onClick={handleBackClick}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid>
                                        <Button className="btn-colr " variant="contained">
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Card>
                    </Box>
                </>
            )}
            {showAddProduct && (
                <>
                    <Button variant="contained" className='btn-apply'>Add Product to Scheme</Button>
                    <Card sx={{ border: "1px solid #EEE", marginTop: '10px' }} className='card-form' >
                        <form>

                            <Grid item container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
                                <Grid item xs={12} sm={4} md={3}>
                                    <InputLabel size="normal" className='fnt-lable' >Scheme Recipient</InputLabel>
                                    <FormControl fullWidth>
                                        <Select className="Country-selectbox" >
                                            {SchemeRecipient.map((recipient, index) => (
                                                <MenuItem key={index} value={recipient.value}>
                                                    {recipient.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={4} md={3}>
                                    <InputLabel size="normal" className='fnt-lable' >From Date</InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <InputLabel size="normal" className='fnt-lable' >To Date</InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <InputLabel size="normal" className='fnt-lable' >Scheme Search By </InputLabel>
                                    <FormControl fullWidth>
                                        <Select className="Country-selectbox" >
                                            {SchemeSearch.map((scheme, index) => (
                                                <MenuItem key={index} value={scheme.value}>
                                                    {scheme.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <InputLabel size="normal" className='fnt-lable' >Scheme Search Input</InputLabel>
                                    <TextField className='Txt-box' fullWidth />
                                </Grid>

                                <Grid item xs={12} sm={4} md={3}>
                                    <InputLabel size="normal" className='fnt-lable' >Product Hierarchy Level</InputLabel>
                                    <TextField className='Txt-box' fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3} mt={1.4}>
                                    <Button variant='contained' className='btn-colr'>Go</Button>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ display: "flex", justifyContent: "center" }} mt={2}>
                                <Grid item sx={{ mr: 2 }}>
                                    <Button variant="outlined" onClick={handleBackClick}>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid>
                                    <Button className="btn-colr " variant="contained">
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Card>
                </>
            )}
        </Box>
    );
}

export default SchemeControlForm;
