import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import { Checkbox, Input, Space, Button } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import CommonTable from "../../../../components/Tables/CommonTable";
import GeoHierarchyCreate from "../GeoHierarchy/GeoHierarchyCreate";
import vector from "../../../../assets/images/icons/Vector.png";
import Plus from "../../../../assets/images/icons/Group.png";
import Search from "../../../../assets/images/icons/Icon 24px.png";
import { IoCloseOutline } from "react-icons/io5";
import SearchBar from "@mkyy/mui-search-bar";
import SalesHierarchyCreate from "./CreateSalesHierarchy";
import SalesHierarchyEdit from "./EditSalesHierarchy";
import Modal from "@mui/material/Modal";
import * as XLSX from "xlsx";
import { IoClose } from "react-icons/io5";

interface DataType {
  key: React.Key;
  country: string;
  region: string;
  state: string;
  district: string;
  city: string;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};
export default function SalesHierarchy() {
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [isEditing, setIsEditing] = React.useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isUpload, setIsUpload] = React.useState(false);
  const [uploadError, setUploadError] = React.useState("");


  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleShowClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setIsShow(!isShow);
    toggleDrawer(true);
  };
  const handleEditClick = (rowData: DataType) => {
    setIsEditing(!isEditing);
    toggleDrawer(true);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const filteredOptions = [
    "Beverages",
    "Food",
    "Personal Care",
    "Snacks",
    "Lunch",
    "Dinner",
  ].filter((option) => option.toLowerCase().includes(searchText.toLowerCase()));

  const handleCheckboxChange = (selected: string[]) => {
    setSelectedItems(selected);
  };

  const getColumnFilterProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: {
      setSelectedKeys: (keys: string[]) => void;
      selectedKeys: string[];
      confirm: () => void;
      clearFilters: () => void;
    }) => (
      <Box style={{ padding: 8 }}>
        <Grid item container sx={{ flexWrap: "nowrap" }} columnSpacing={2}>
          <Grid item container sx={{ flexDirection: "column" }}>
            <Grid item>
              <Typography>
                <b style={{ fontSize: "12px" }}>Business Level Filters</b>
              </Typography>
              <Input
                placeholder="Search Filters"
                onChange={handleSearch}
                value={searchText}
                prefix={
                  <img
                    src={Search}
                    alt="search icon"
                    style={{ width: "14px" }}
                  />
                }
                className="filter_ser"
              />
            </Grid>
            <Grid item className="scroll_cbox">
              <Checkbox.Group
                onChange={handleCheckboxChange}
                className="filter_checkbox "
              >
                <Space direction="vertical" style={{ rowGap: "0px" }}>
                  {filteredOptions.map((option) => (
                    <Checkbox key={option} value={option}>
                      {option}
                    </Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>
            </Grid>
          </Grid>
          <Grid item container sx={{ flexDirection: "column" }}>
            <Grid item className="filter_dis" style={{ gap: "10px" }}>
              <Grid item>
                <Typography>
                  <b className="filter_text">
                    {selectedItems.length} Filter Selected
                  </b>
                </Typography>
              </Grid>
              <Grid item className="filter_dis">
                <IoCloseOutline style={{ color: "red" }} />
                <Typography className="clr_text">Clear All</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Space direction="vertical" className="filter_checkbox">
                {selectedItems.map((item, index) => (
                  <Checkbox key={index} checked={true}>
                    {item}
                  </Checkbox>
                ))}
              </Space>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="btn_filter">
          <Button size="small" style={{ width: 80, fontSize: "10px" }}>
            Close
          </Button>
          <Button
            type="primary"
            size="small"
            style={{ width: 80, fontSize: "10px" }}
            className="btn-colr"
          >
            Apply
          </Button>
        </Grid>
      </Box>
    ),
    filterIcon: () => (
      <Box>
        <img
          src={vector}
          alt="filter icon"
          style={{ height: "14px", color: "#944986" }}
        />
      </Box>
    ),
    render: (text: string) => (searchedColumn === dataIndex ? <></> : text),
  });

  const columns = [
    {
      dataIndex: "country",
      title: (
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item className="table_title">
            Head of Sales (100)
          </Grid>

          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <Grid item>
              <img src={Search} alt="search icon" style={{ width: "17px" }} />
            </Grid>
            <Grid item sx={{ paddingLeft: "10px" }}>
              <img
                src={Plus}
                alt="plus icon"
                style={{ height: "10px" }}
                onClick={handleShowClick}
              />
            </Grid>
          </Grid>
          {/* <Grid className="table_search">
            <Input
              placeholder="Search Country..."
              prefix={
                <img src={Search} alt="search icon" style={{ width: "17px" }} />
              }
            />
          </Grid> */}
        </Grid>
      ),
      // ...getColumnFilterProps("country"),
    },
    {
      dataIndex: "region",
      title: (
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid className="table_title">Regional Sales Manager (200)</Grid>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <Grid item>
              <img src={Search} alt="search icon" style={{ width: "17px" }} />
            </Grid>
            <Grid item sx={{ paddingLeft: "10px" }}>
              <img
                src={Plus}
                alt="plus icon"
                style={{ height: "10px" }}
                onClick={handleShowClick}
              />
            </Grid>
          </Grid>
        </Grid>
      ),
      // ...getColumnFilterProps("region"),
    },
    {
      dataIndex: "state",
      title: (
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid className="table_title">Zonal Sales Manager (300)</Grid>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <Grid item>
              <img src={Search} alt="search icon" style={{ width: "17px" }} />
            </Grid>
            <Grid item sx={{ paddingLeft: "10px" }}>
              <img
                src={Plus}
                alt="plus icon"
                style={{ height: "10px" }}
                onClick={handleShowClick}
              />
            </Grid>
          </Grid>
          {/* <Grid className="table_search">
            <Input
              placeholder="Search State..."
              prefix={
                <img src={Search} alt="search icon" style={{ width: "17px" }} />
              }
            />
          </Grid> */}
        </Grid>
      ),
      // ...getColumnFilterProps("state"),
    },
    {
      dataIndex: "district",
      title: (
        <Grid
          container
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid className="table_title">Area Sales Manager (400)</Grid>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <Grid item>
              <img src={Search} alt="search icon" style={{ width: "17px" }} />
            </Grid>
            <Grid item sx={{ paddingLeft: "10px" }}>
              <img
                src={Plus}
                alt="plus icon"
                style={{ height: "10px" }}
                onClick={handleShowClick}
              />
            </Grid>
          </Grid>
        </Grid>
      ),
      // ...getColumnFilterProps("district"),
    },
    {
      dataIndex: "city",
      title: (
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid className="table_title">SDE/TSO (500)</Grid>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <Grid item>
              <img src={Search} alt="search icon" style={{ width: "17px" }} />
            </Grid>
            <Grid item sx={{ paddingLeft: "10px" }}>
              <img
                src={Plus}
                alt="plus icon"
                style={{ height: "10px" }}
                onClick={handleShowClick}
              />
            </Grid>
          </Grid>
        </Grid>
      ),
      // ...getColumnFilterProps("city"),
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      country: "India",
      region: "North",
      state: "TamilNadu",
      district: "Chennai",
      city: "Adyar",
    },
    {
      key: 2,
      country: "",
      region: "South",
      state: "Pondicherry",
      district: "Pondicherry",
      city: "White Town",
    },
  ];

  const headers = [
    "Head of Sales (100)",
    "Regional Sales Manager (200)",
    "Zonal Sales Manager (300)",
    "Area Sales Manager (400)",
    "SDE/TSO (500)",

  ];

  //
  function convertHeadersToXLS(headers: any) {
    const worksheet = XLSX.utils.aoa_to_sheet([headers]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    return blob;
  }
  // Function to download XLS file with headers only
  function downloadXLSWithHeaders(headers: any) {
    const xlsBlob = convertHeadersToXLS(headers);
    const url = URL.createObjectURL(xlsBlob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "exported_headers.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleUploadClick = () => {
    setUploadError("");
    setIsUpload(!isUpload);
  };

  const handleCloseUpload = () => {
    setIsUpload(false);
    setUploadError("");
    setUploadedFile(null);
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const fileNameParts = file.name.split(".");
      const fileExtension = fileNameParts[fileNameParts.length - 1];

      if (fileExtension !== "xlsx" && fileExtension !== "xls") {
        setUploadError("Unsupported file format. Please upload a .xlsx or .xls file.");
        event.target.value = "";
        setUploadedFile(null);
        return;
      }
      setUploadError("");
      setUploadedFile(file);
    }

  };

  const handleSubmit = async () => {
    if (!uploadedFile) {
      setUploadError("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append('file', uploadedFile);
    console.log("uploadedFile", uploadedFile);
    // handleCloseUpload();

    // try {
    //   const response = await axios.post('YOUR_API_ENDPOINT', formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   });

    //   if (response.status === 200) {
    //     setIsUpload(true);
    //     setUploadError("");
    //     handleCloseUpload();
    //     console.log("File uploaded successfully");
    //   } else {
    //     setUploadError("Failed to upload the file. Please try again.");
    //   }
    // } catch (error) {
    //   setUploadError("Failed to upload the file. Please try again.");
    // }
  };
  return (
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 3, sm: 3 } }}>
        <Grid container sx={{ display: "flex", alignItems: "center" }}>
          <Grid item xs={12} sm={6}>
            <Typography>
              <b className="header-lable">Sales Hierarchy Level</b>
            </Typography>
          </Grid>

          <Grid
            xs={12}
            md={6}
            item
            sx={{ display: "flex", justifyContent: "end", gap: "10px" }}
          >
            <Button
              className="mastercrt-button"
              onClick={() => downloadXLSWithHeaders(headers)}
            >
              <span className="Crt-lable"> Sample Excel</span>
            </Button>
            <Button className="mastercrt-button" onClick={handleUploadClick}>
              <span className="Crt-lable">Upload</span>
            </Button>
            <SearchBar
              className="search-table"
            // value={textFieldValue}
            // onChange={(newValue) => {
            //   setTextFieldValue(newValue);
            //   handleSearch();
            // }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            // className="mar-tb"
            sx={{ display: "flex", justifyContent: "end" }}
          ></Grid>
        </Grid>
        <Box id="TableScroll" className="sales_table" sx={{ mt: 4 }}>
          <Grid item>
            <CommonTable
              data={data}
              columns={columns}
              onRow={(record) => ({
                onClick: () => handleEditClick(record),
              })}
            />
          </Grid>
        </Box>
        {isShow ? (
          <SalesHierarchyCreate onClose={() => setIsShow(false)} />
        ) : isEditing ? (
          <SalesHierarchyEdit onClose={() => setIsEditing(false)} />
        ) : (
          <></>
        )}
      <Modal
        open={isUpload}
        onClose={handleCloseUpload}
        aria-describedby="modal-modal-description"
        BackdropProps={{ onClick: (event) => event.stopPropagation() }}

      >
        <Box sx={style} >
          <div style={{ display: "flex", justifyContent: "flex-end" }} onClick={handleCloseUpload}>
            <IoClose className="Close_icon" />
          </div>
          <Grid item>
            <div className="inputDnD" style={{ position: "relative" }}>
              <input
                type="file"
                title=""
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
                className="form-control-file font-weight-bold"
              />
              {uploadedFile ? (
                <Typography variant="body2" className="assetFileSub">{uploadedFile.name}</Typography>
              )
                :
                <p className="assetFileSub">Click here to add Attachment.</p>
              }
            </div>
            <Typography className="uploadError">{uploadError}</Typography>
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button
              // variant="outlined"
              color="primary"
              onClick={handleCloseUpload}
              // sx={{ mr: 2 }}
              className="crt-btn-cancel "
            >
              Cancel
            </Button>
            <Button
              // type='submit'
              // variant="contained"
              className="btn-colr"
              onClick={handleSubmit}
            >
              Upload
            </Button>
          </Box>
        </Box>
      </Modal>
      </Box>
  );
}
