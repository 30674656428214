import { Box, Card, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Divider } from "antd";
import ManualSearchInput from "./ManualSearchInput";
import { useAppDispatch } from "../../../hooks/useFetch";
import { savePreviouslyAddedData, showDisablecheckbox } from "../../../Redux/XDM/Sales/ManualOrderReducer";

const ManualOrder: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(savePreviouslyAddedData([]));
  }, [dispatch]);

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      bgcolor="#eee"
    >
      <Box
        flex={8}
        className="margin-left-0px"
        sx={{ overflowY: "auto", height: "100%", marginBottom: "100px" }}
      >
        <Card
          className="b-radius-0 main-card"
          style={{ marginTop: "2px", boxShadow: "none" }}
        >
          <Grid container spacing={3} justifyContent="start" p={2}>
            <Grid item xs={6} sm={6} md={9} lg={9} className="margin_tp_0">
              <h4
                style={{
                  marginTop: "0px",
                  marginLeft: "0px",
                  marginBottom: "0px",
                }}
              >
                <b>Manual Order</b>
              </h4>
            </Grid>
            <Divider className="margin_tp_5" />
          </Grid>
          <Grid container pt={0}>
            <Grid
              item
              xs={12}
            >
              <ManualSearchInput />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
          </Grid>
        </Card>
      </Box>
    </Stack>
  );
};

export default ManualOrder;
