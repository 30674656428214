import * as React from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Input,
  ListItemText,
  Radio,
  TextField,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomerEditLicense from "./CustomerEditLicense";
import CustomerEditCoverage from "./CustomerEditCoverage";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      //   width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

  
  interface CustomergeneralProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
  }
  
  const  CustomerEditGeneral: React.FC<CustomergeneralProps> = ({ onBack }) => {
    const handleBackClick = (event: React.MouseEvent<unknown>) => {
      onBack(event); // Pass the event argument to onBack function
    };
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const countries = [
    { value: "", label: "None" },
    { value: "India", label: "India" },
  ];
  const State = [
    { value: "", label: "None" },
    { value: "ARUNACHAL PRADESH", label: "ARUNACHAL PRADESH" },
    { value: "ASSAM", label: "ASSAM" },
    { value: "BIHAR", label: "BIHAR" },
    { value: "TAMIL NADU", label: "TAMIL NADU" },
  ];
  const city = [
    { value: "", label: "None" },
    { value: "ARARIA", label: "ARARIA" },
    { value: "FORBESGANJ", label: "FORBESGANJ" },
    { value: "AURANGABAD", label: "AURANGABAD" },
    { value: "BARAUNI", label: "BARAUNI" },
  ];
  const Registered = [
    { value: "", label: "None" },
    { value: "UN Registered", label: "UN Registered" },
  ];
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleChanges = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const names = [
    "GT1000020102860002",
    "GT1000020117060002",
    "GT1000020117060002",
    "GT1000020117060002",
  ];
  const values=[
    "",
    "",
    "",
    "",
  ]
  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };
  return (
    <>
    {show?(
      <CustomerEditLicense onBack={handleclick} />
      ):(
    <Box>
      <form>
        <Grid item container spacing={2}>
          <Grid item sm={4} md={4} lg={2}>
            <Grid item>DistributorBranch </Grid>

            <FormControl fullWidth>
              <Select
                id="demo-multiple-checkbox"
                multiple
                value={personName}
                onChange={handleChanges}
                // input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {" "}
                {names.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={personName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={4} md={4} lg={2}>
            <Grid item>Type </Grid>
            <TextField variant="outlined" />
          </Grid>
          <Grid item sm={4} md={4} lg={2}>
            <Button variant="text" className="show-image-btn">
              Show Image
            </Button>
          </Grid>
        </Grid>
        <Grid item container spacing={2} mt={3}>
          <Grid item sm={4} md={4} lg={2}>
            <Grid item className="lable-customer">
              Customer Code 
            </Grid>
            <TextField fullWidth variant="outlined" />
          </Grid>
          <Grid item sm={4} md={4} lg={2}>
            <Grid item className="lable-customer">
              Distr Customer Code 
            </Grid>
            <TextField fullWidth variant="outlined" />
          </Grid>
          <Grid item sm={4} md={4} lg={2}>
            <Grid item className="lable-customer">
              Company Customer Code 
            </Grid>
            <TextField variant="outlined" />
          </Grid>
        </Grid>
        <Grid item container spacing={2} mt={3}>
          <Grid item sm={4} md={6} lg={6}>
            <Grid className="lable-customer">Customer Code </Grid>
            <TextField
              fullWidth
              variant="outlined"
              className="customer-name-text"
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} mt={3}>
          <Grid item sm={4} md={6} lg={6}>
            <Grid item className="lable-customer">
              Address 
            </Grid>
            <TextField
              fullWidth
              variant="outlined"
              className="customer-name-text"
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} mt={0.1}>
          <Grid item sm={4} md={6} lg={6}>
            <TextField
              fullWidth
              className="customer-name-text"
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} mt={0}>
          <Grid item sm={4} md={6} lg={6}>
            <TextField
              fullWidth
              variant="outlined"
              className="customer-name-text"
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} mt={3}>
          <Grid item sm={4} md={4} lg={2}>
            <Grid className="lable-customer">Country </Grid>
            <InputLabel></InputLabel>

            <FormControl className="country-select-form" fullWidth>
              <Select className="Country-selectbox" onChange={handleChange}>
                {countries.map((country, index) => (
                  <MenuItem key={index} value={country.value}>
                    {country.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={4} md={4} lg={2}>
            <Grid className="lable-customer">State </Grid>
            <InputLabel></InputLabel>

            <FormControl fullWidth>
              <Select className="Country-selectbox" onChange={handleChange}>
                {State.map((opction, index) => (
                  <MenuItem key={index} value={opction.value}>
                    {opction.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={4} md={4} lg={2}>
            <Grid className="lable-customer"> City</Grid>
            <InputLabel></InputLabel>

            <FormControl fullWidth>
              <Select className="Country-selectbox" onChange={handleChange}>
                {city.map((options, index) => (
                  <MenuItem key={index} value={options.value}>
                    {options.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={4} md={4} lg={2}>
            <Grid className="title-lable">Postal Code </Grid>
            <TextField
              fullWidth
              placeholder="Enter Postal code"
              variant="outlined"
            />
          </Grid>
          <Grid item sm={4} md={4} lg={2}>
            <Grid className="title-lable">Phone No.</Grid>
            <TextField
              fullWidth
              placeholder="Enter Phone number"
              variant="outlined"
            />
          </Grid>
          <Grid item sm={4} md={4} lg={2}>
            <Grid className="title-lable">Mobile No </Grid>
            <TextField
              placeholder="Enter Mobile no"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} mt={3}>
          <Grid item sm={4} md={4} lg={2}>
            <Grid item className="title-lable">
              Latitude
            </Grid>
            <TextField placeholder="0.00" variant="outlined" fullWidth />
          </Grid>
          <Grid item sm={4} md={4} lg={2}>
            <Grid item className="title-lable">
              Phone No. 
            </Grid>
            <TextField placeholder="0.0" variant="outlined" fullWidth />
          </Grid>
          <Grid item sm={4} md={4} lg={2}>
            <Grid item className="title-lable">
              {" "}
              Distance
            </Grid>

            <TextField placeholder="0" variant="outlined" fullWidth />
          </Grid>
        </Grid>
        <Grid item container spacing={2} mt={3}>
          <Grid item sm={4} md={4} lg={2}>
            <Grid item className="title-lable">
              Date of Birth
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={4} md={4} lg={2}>
            <Grid className="title-lable">Anniversary Date</Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={4} md={4} lg={2}>
            <Grid className="title-lable"> Enrollment Date</Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker sx={{ height: "15px" }} />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={4} md={4} lg={2}>
            <Grid item className="title-lable">
              {" "}
              Contact Person
            </Grid>

            <TextField variant="outlined" fullWidth />
          </Grid>
          <Grid item sm={4} md={4} lg={2}>
            <Grid item className="title-lable">
              {" "}
              Email Id :
            </Grid>

            <TextField
              fullWidth
              // html input attribute
              name="email"
              type="email"
              placeholder="ABC@gmail.com"
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} mt={3}>
          <Grid item sm={4} md={4} lg={2}>
            <Grid className="lable-customer">GST State </Grid>
            <FormControl fullWidth>
              <Select
                id="demo-multiple-checkbox"
                multiple
                value={personName}
                onChange={handleChanges}
                // input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {" "}
                {values.map((values) => (
                  <MenuItem key={values} value={values}>
                    <Checkbox checked={personName.indexOf(values) > -1} />
                    <ListItemText primary={values} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={4} md={4} lg={2}>
            <Grid item className="lable-customer">
              Retailer Type
            </Grid>
            <InputLabel></InputLabel>

            <FormControl fullWidth>
              <Select className="Country-selectbox" onChange={handleChange}>
                {Registered.map((opction, index) => (
                  <MenuItem key={index} value={opction.value}>
                    {opction.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item sm={4} md={4} lg={2}>
            <Grid item className="title-lable">
              PAN No
            </Grid>
            <TextField variant="outlined" fullWidth />
          </Grid>
          <Grid item sm={4} md={2} lg={2}>
            <Grid item className="title-lable">
              {" "}
              Aadhaar No.
            </Grid>
            <TextField variant="outlined" fullWidth />
          </Grid>
          <Grid item sm={4} md={2} lg={2}>
            <Grid item className="title-lable">
              {" "}
              GSTIN Number 
            </Grid>
            <TextField
              variant="outlined"
              className="postal-code-text"
              fullWidth
            />
          </Grid>
          <Grid item sm={4} md={4} lg={2}>
            <Grid item className="title-lable">
              {" "}
              TCS Applicable
            </Grid>
            <Box sx={{ display: "flex" }}>
              <Radio
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 18,
                  color: '#6750A4'}
              }}
                checked={selectedValue === "yes"}
                onChange={handleRadioChange}
                value="yes"
                name="radio-buttons"
              />{" "}
              <span className="radio-opction">Yes</span>
              <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18,
                      color: '#6750A4'}
                  }}
                checked={selectedValue === "no"}
                onChange={handleRadioChange}
                value="no"
                name="radio-buttons"
              />{" "}
              <span className="radio-opction">No</span>
            </Box>
          </Grid>
          <Grid item sm={4} md={4} lg={2}>
            <Grid className="title-lable"> Related Party</Grid>
            <Box sx={{ display: "flex" }}>
              <Radio value="yes" name="radio-buttons" 
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18,
                      color: '#6750A4'}
                  }}
              />{" "}
              <span className="radio-opction">Yes</span>
              <Radio value="no" name="radio-buttons"     sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 18,
                  color: '#6750A4'}
              }} />{" "}
              <span className="radio-opction">No</span>
            </Box>
          </Grid>
          <Grid item sm={4} md={4} lg={1}>
            <Grid className="title-lable"> Composite</Grid>
            <Box sx={{ display: "flex" }}>
              <Radio value="yes" name="radio-buttons"     sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 18,
                  color: '#6750A4'}
              }} />{" "}
              <span className="radio-opction">Yes</span>
              <Radio value="no" name="radio-buttons"     sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 18,
                  color: '#6750A4'}
              }} />{" "}
              <span className="radio-opction">No</span>
            </Box>
          </Grid>
        </Grid>
        <Grid item container spacing={2} mt={3}>
          <Grid item sm={4} md={4} lg={2}>
            <Grid item className="title-lable">
              {" "}
              TDS Applicable
            </Grid>
            <Box sx={{ display: "flex" }}>
              <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18,
                      color: '#6750A4'}
                  }}
        
                value="yes"
                name="radio-buttons"
              />{" "}
              <span className="radio-opction">Yes</span>
              <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18,
                      color: '#6750A4'}
                  }}
                value="no"
                name="radio-buttons"
              />{" "}
              <span className="radio-opction">No</span>
            </Box>
          </Grid>
        </Grid>
        <Grid item sx={{ display: "flex", justifyContent: "center" }}>
        <Grid item sx={{ mr: 2 }}>
            <Button variant="outlined" onClick={handleBackClick} >
              <CancelIcon sx={{color:'', fontSize:'15px', marginRight:'10px'}} /> Cancel
            </Button>
          </Grid>
          <Grid>
            <Button className="btn-colr " variant="contained" onClick={handleclick}>
              <RedoOutlinedIcon sx={{color:'', fontSize:'15px', marginRight:'10px'}} />
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
    )}</>
  );
};
export default CustomerEditGeneral;
