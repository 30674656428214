import {
  COMPANY_SUBMIT_FORM,
  COMPANY_FETCH_DATA,
  COMPANY_Submit_ERROR,
  COMPANY_Edit_DATA,
  COMPANY_UPDATE_DATA,
  COMPANY_UPDATE_ERROR,
  COMPANY_ACTIVE_STATUS,
  COMPANY_ACTIVE_DEFAULT,
} from "../../actionType";
import { Dispatch } from "redux";
import createAxiosInstance from "../../../hooks/useFetch";
import { CreateCompanyFormValues } from "../../../typescript/Company";

const axiosInstance = createAxiosInstance();
export interface CompanySubmitAction {
  type: typeof COMPANY_SUBMIT_FORM;
  payload: any; // Define the payload type accordingly
}
export interface companyGetAction {
  type: typeof COMPANY_FETCH_DATA;
  payload: any;
}
export interface CompanySubmitErrorAction {
  type: typeof COMPANY_Submit_ERROR;
  payload: string; // Define the payload type accordingly, assuming error message is a string
}
export interface CompanyEdit {
  type: typeof COMPANY_Edit_DATA;
  payload: any;
}
export interface CompanyUpdate {
  type: typeof COMPANY_UPDATE_DATA;
  payload: any;
}
export interface CompanyUpdateErrorAction {
  type: typeof COMPANY_UPDATE_ERROR;
  payload: string;
}

export interface companyActiveStatus {
  type: typeof COMPANY_ACTIVE_STATUS;
  payload: any;
}
export interface companyActiveDefault {
  type: typeof COMPANY_ACTIVE_DEFAULT;
  payload: any;
}
export const companySubmitForm =
  (formData: CreateCompanyFormValues) =>
  async (
    dispatch: Dispatch<CompanySubmitAction | CompanySubmitErrorAction>
  ) => {
    try {
      const response = await axiosInstance.post(`company/create`, formData);
      dispatch({
        type: COMPANY_SUBMIT_FORM,
        payload: response.data,
      });
      return response.data;
    } catch (error: any) {
      dispatch({
        type: COMPANY_Submit_ERROR,
        payload: error.response.data.data,
      });
      throw error;
    }
  };

export const companyFetchData = (requestData: {
  page: number;
  size: number;
}) => {
  return (dispatch: Dispatch<companyGetAction>) => {
    axiosInstance
      .get(`company/get`, {
        params: requestData,
      })
      .then((res) => {
        dispatch({
          type: COMPANY_FETCH_DATA,
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};

export const companyEdit = (id: string) => {
  return (dispatch: Dispatch<CompanyEdit>) => {
    axiosInstance
      .get(`company/get-byid/${id}`)
      .then((res) => {
        dispatch({
          type: COMPANY_Edit_DATA,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        // Handle errors if any
      });
  };
};

export const companyUpdate =
  ({ id, formData }: { id: string; formData: CreateCompanyFormValues }) =>
  async (dispatch: Dispatch<CompanyUpdate | CompanyUpdateErrorAction>) => {
    try {
      const response = await axiosInstance.put(
        `company/update/${id}`,
        formData
      );
      dispatch({
        type: COMPANY_UPDATE_DATA,
        payload: response.data,
      });
      return response.data;
    } catch (error: any) {
      dispatch({
        type: COMPANY_UPDATE_ERROR,
        payload: error.response.data.data,
      });
      throw error;
    }
  };

export const companyActiveStatus = ({
  id,
  value,
}: {
  id: string;
  value: boolean;
}) => {
  return (dispatch: Dispatch<companyActiveStatus>) => {
    axiosInstance
      .put(
        `company/active-in-active`,
        { id, value } // Pass requestData as query parameters
      )
      .then((res) => {
        dispatch({
          type: COMPANY_ACTIVE_STATUS, // Change this to COMPANY_FETCH_DATA
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};

export const companyActiveDefault = ({
  id,
  value,
}: {
  id: string;
  value: boolean;
}) => {
  return (dispatch: Dispatch<companyActiveDefault>) => {
    axiosInstance
      .put(`company/set-default`, { id, value })
      .then((res) => {
        dispatch({
          type: COMPANY_ACTIVE_DEFAULT, // Change this to COMPANY_FETCH_DATA
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};
