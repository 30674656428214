import { sidebarFilter, subHeaderHide } from "../Redux/AuthLogin/action";
import { useAppDispatch } from "../hooks/useFetch";

interface HeaderDataProps {
  path: string;
  label: string;
  sidebar?: boolean;
}

export const xdmHeaders: HeaderDataProps[] = [
  // { path: "/Dashboard1", label: "Dashboard" },
  { path: "/xdm/purchase/invoices-grn", label: "Purchase", sidebar: true },
  { path: "/xdm/inventory/saleable", label: "Inventory", sidebar: true },
  { path: "/xdm/sales/order-billing", label: "Sales", sidebar: true },
  // { path: "/reports", label: "Reports" },
];

export const useSidebar = () => {
  const dispatch = useAppDispatch();
  const sidebar = () => {
    dispatch(sidebarFilter(false, ""));
    dispatch(subHeaderHide(false));
  };
  return sidebar;
};

export const mdmHeaders: HeaderDataProps[] = [
  {
    path: "/Dashboard",
    label: "Dashboard",
    sidebar: true,
  },
  {
    path: "/master/company_dashboard",
    label: "Masters",
    sidebar: true,
  },
  {
    path: "/sales_man",
    label: "Salesman",
    sidebar: true,
  },
  {
    path: "/sales_team",
    label: "Sales Team",
    sidebar: true,
  },
  {
    path: "/configuration",
    label: "Configuration",
    sidebar: true,
  },
];

export const defaultHeaders: HeaderDataProps[] = [
  { path: "/Dashboard", label: "Dashboard" },
  { path: "/purchase/purchase_request", label: "Purchase" },
  { path: "/sales/sales_company", label: "Sales" },
  { path: "/inventory/inventory_data", label: "Inventory" },
  { path: "/reports/reports_data", label: "Reports" },
];
