import React from "react";
import {
  Button,
  Grid,
  TextField,
  Box,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  InputLabel,
  Typography,
  Card,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { SelectChangeEvent } from "@mui/material/Select";
import { TableColumnsType } from "antd";
import CommonTable from "../../../../components/Tables/CommonTable";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import PriceCustomerTab from "./PriceCustomerTab";
// import ProductInfoView from "./ProductInfo";




interface DataType {
    key: React.Key;
    ReferenceNo: string;
  }
interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const Distributorprice: React.FC<DistributorTabProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        //   width: 250,
      },
    },
  };
 


 


 
  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };


  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const Level = ["Head of Sales", "Regional Head", "Zonal Sales Manager"];
  const option = ["Retailer", ""];
  const SupplyChain = ["Super Stockiest", "Sub Stockiest", "Distributor"];

  const columns: TableColumnsType<DataType> = [
    {
      title: "Ref. No",
      dataIndex: "ReferenceNo",
    },
  ];

  const data: DataType[] = [
    { key: "1", ReferenceNo: "Head of Sales - PC - NM - SALES" },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <>
      {show ? (
       <PriceCustomerTab onBack={handleclick}/>
      ) : (
        <Box>
          <form noValidate>
          <Grid container mt={3} rowSpacing={3} columnSpacing={{ xs: 3, sm: 10 }}>
          <Grid item xs={12} sm={6}>
            <Typography className="fnt-lable"> Discount Recipient</Typography>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label" className="selct-box">
                {option.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container mt={4} sm={6}>
          <Typography className="fnt-lable"> Supper Chain Entity </Typography>

          <FormControl fullWidth>
            <Select labelId="demo-simple-select-label" className="selct-box">
              {SupplyChain.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid container mt={3} rowSpacing={3} columnSpacing={{ xs: 3, sm: 10 }}>
          <Grid item xs={12} sm={6}>
            <Typography className="fnt-lable"> LOB Value </Typography>
            <TextField placeholder="  " className="Txt-box" />
            <Button sx={{ color: "#fff", ml: 3 }} className="btn-colr">
              ...
            </Button>
          </Grid>
        </Grid>

        <Card className="card-form" sx={{ border: "1px solid #EEE", mt: 3 }}>
          <Grid className="box-lable fil-lab">Filter Based On :</Grid>
          <Grid container sx={{ marginTop: "16px" }}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="Sales hierarchy"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Sales hierarchy"
                />
                <FormControlLabel
                  value="Geography hierarchy"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Geography hierarchy "
                />
                <FormControlLabel
                  value="Distributor"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Distributor "
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Card>

        <Grid container mt={3} rowSpacing={3} columnSpacing={{ xs: 3, sm: 10 }}>
          <Grid item xs={12} sm={6}>
            <Typography className="fnt-lable"> level</Typography>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label" className="selct-box">
                {Level.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item mt={2}>
          <Grid item>Value</Grid>
          <Grid container columnGap={2}>
            <Grid item xs={8} style={{ height: "100%", width: "100%" }}>
              <CommonTable
                // rowSelection={rowSelection}
                data={data}
                columns={columns}
              />
            </Grid>
            <Grid item mt={1}>
              <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
                <KeyboardDoubleArrowLeftIcon />
              </Button>
              <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
                <KeyboardDoubleArrowRightIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>

            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", mt: 4 }}
            >
                
              <Grid item sx={{ mr: 2 }}>
                <Button variant="outlined" onClick={handleBackClick}>
                  <CancelIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />{" "}
                  Cancel
                </Button>
              </Grid>
              <Grid>
                <Button
                  className="btn-colr "
                  variant="contained"
                  onClick={handleclick}
                >
                  <RedoOutlinedIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />
                  Next
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </>
  );
};
export default Distributorprice;

