// import * as React from 'react';
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import SalesLevelValueForm from "./SalesLevelValueForm";
import LobMapping from "./LobMapping";
import Modal from "@mui/material/Modal";
import ImageSwitcher from "../../../components/Modal/ImageSwitcher";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  code: number;
  name: string;
  LevelName: string;
  ReportingTo: string;
  ReportingLevel: string;
  action: string;
}

const SalesHierarchyLevel: React.FC = () => {
  const navigator = useNavigate();

  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    //search function logic here
  };

  const columns: TableColumnsType<DataType> = [
    { dataIndex: "code", title: "Code" },
    {
      dataIndex: "name",
      title: "Value(Name)",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      dataIndex: "LevelName",
      title: "Level Name",
      sorter: (a, b) => a.LevelName.length - b.LevelName.length,
    },
    {
      dataIndex: "ReportingTo",
      title: "Reporting To",
      sorter: (a, b) => a.ReportingTo.length - b.ReportingTo.length,
    },
    {
      dataIndex: "ReportingLevel",
      title: "Reporting Level",
      sorter: (a, b) => a.ReportingLevel.length - b.ReportingLevel.length,
    },
    {
      dataIndex: "action",
      title: "Action",

      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            onClick={(event) => handleViewClick(event)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      code: 100,
      name: "Country",
      LevelName: "PR SO HUBLI",
      ReportingTo: "Territory Sales Officer",
      ReportingLevel: "PR AM BANGALORE",
      action: "",
    },
    {
      key: 2,
      code: 200,
      name: "State",
      LevelName: "AD AM CHENNAI-ADSTNAM001",
      ReportingTo: "Regional Head",
      ReportingLevel: "PR AM BANGALORE",
      action: "",
    },
    {
      key: 3,
      code: 300,
      name: "Town",
      LevelName: "PR SO HUBLI",
      ReportingTo: "	Regional Head",
      ReportingLevel: "PR AM BANGALORE",
      action: "",
    },
    {
      key: 4,
      code: 400,
      name: "District",
      LevelName: "PR SO HUBLI",
      ReportingTo: "Territory Sales Officer",
      ReportingLevel: "PR AM BANGALORE",
      action: "",
    },
    {
      key: 5,
      code: 500,
      name: "Country1",
      LevelName: "AD AM CHENNAI-ADSTNAM001",
      ReportingTo: "Area Sales Manager",
      ReportingLevel: "PR AM BANGALORE",
      action: "",
    },
    {
      key: 6,
      code: 600,
      name: "Supplier6",
      LevelName: "PR SO HUBLI",
      ReportingTo: "Zonal Sales Manager",
      ReportingLevel: "PR AM BANGALORE",
      action: "",
    },
    {
      key: 7,
      code: 700,
      name: "Supplier7",
      LevelName: "PR SO HUBLI",
      ReportingTo: "Zonal Sales Manager",
      ReportingLevel: "PR AM BANGALORE",
      action: "",
    },
    {
      key: 8,
      code: 800,
      name: "Supplier8",
      LevelName: "AD AM CHENNAI-ADSTNAM001",
      ReportingTo: "Territory Sales Officer",
      ReportingLevel: "PR AM BANGALORE",
      action: "",
    },
  ];

  const [isViewing, setIsViewing] = React.useState(false);
  const [isLobViewing, setIsLobViewing] = React.useState(false);

  const handleViewClick = (event: React.MouseEvent<unknown>) => {
    setIsViewing(!isViewing);
  };
  const handleLobViewClick = (event: React.MouseEvent<unknown>) => {
    setIsLobViewing(!isLobViewing);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
      <Grid item xs={12} className=" mar-tb">
        <Typography>
          <b className="header-lable">Sales Hierarchy Level Value </b>
        </Typography>
      </Grid>

      <Box>
        {isLobViewing ? (
          <LobMapping onBack={(event) => handleLobViewClick(event)} />
        ) : isViewing ? (
          <SalesLevelValueForm
            onBack={(event) => handleViewClick(event)}
            onClick={(event) => handleLobViewClick(event)}
          />
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="contained"
                  className="btn-apply"
                  onClick={handleOpen}
                >
                  View Sales Hierarchy
                </Button>
                <div
                  style={{
                    display: "flex",
                    marginTop: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Modal
                    open={open}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ImageSwitcher onClose={handleClose} />
                  </Modal>
                </div>
              </Grid>
            </Grid>
            <Grid item sx={{ mt: 2 }}>
              <Box>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "end", mb: 2 }}
                >
                  <SearchBar
                    className="search-table"
                    value={textFieldValue}
                    onChange={(newValue) => {
                      setTextFieldValue(newValue);
                      handleSearch();
                    }}
                  />
                </Grid>
                <CommonTable
                  //  rowSelection={rowSelection}
                  data={data}
                  columns={columns}
                />
              </Box>
            </Grid>
          </>
        )}
      </Box>
    </Box>
  );
};
export default SalesHierarchyLevel;
