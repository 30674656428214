import React, { useState, useRef, useEffect } from "react";
import { AutoComplete, Input, Select, Spin } from "antd";
import { RightOutlined } from "@ant-design/icons";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
  Typography,
} from "@mui/material";
import { IoClose, IoCloseOutline } from "react-icons/io5";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useFetch";
import {
  getRecentSearch,
  saveRecentSearch,
} from "../../../../../Redux/XDM/Sales/ManualOrderReducer";
import { useNavigate } from "react-router-dom";
import searchImg from "../../../../../assets/images/Search_alt_light.png";
import recentArrow from "../../../../../assets/images/recent_arrow.png";
import GroupManual from "../../../../../assets/images/Group_manual.png";

import { getOutletDetailsData } from "../../../../../Redux/XDM/Sales/collectionReducer";
import { SalesReturnSearchFilter } from "./SalesReturnSearchFilter";
import {
  getSalesReturnOutlet,
  setAddedSalesReturnData,
} from "../../../../../Redux/XDM/Sales/salesReturnReducer";
import { log } from "console";

type ListItem = {
  outletCode: string;
  outletName: string;
  address: string;
  routeCode: string;
};
const { Option } = Select;
const SalesReturnSearchInput: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [popVisible, setPopVisible] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>(""); // State for auto-complete options
  const [routeCodeSelected, setRouteCodeSelected] = useState<string>("");
  const [salesman, setSalesman] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [outletName, setOutletName] = useState<string>("");
  // const [recentSearches, setRecentSearches] = useState<ListItem[]>([]); // State fo  r recent searches
  const [list, setList] = useState<ListItem[]>([]); // State for recent searches
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [btnEnabled, setBtnEnabled] = useState(false);
  const [valueSelected, setValueSelected] = useState<string>("");
  const dispatch = useAppDispatch();
  const navigator = useNavigate();

  useEffect(() => {
    dispatch(setAddedSalesReturnData([])); // for clearing purpose once enter this search page
    const handleClick = (e: globalThis.MouseEvent) => {
      if (
        dropdownRef.current &&
        dropdownRef.current.contains(e.target as Node)
      ) {
        return;
      }
      setVisible(false);
    };

    document.addEventListener("mousedown", handleClick);
    return () => document.removeEventListener("mousedown", handleClick);
  }, []);

  const recentSearch = {
    menu: "sales",
    subMenu: "newSalesReturn",
    outletCode: "0",
  };

  useEffect(() => {
    const filterData = {
      pageStatus: false,
      size: 0,
      page: 0,
    };
    dispatch(getSalesReturnOutlet({ params: filterData, search: searchValue }))
      .then((response: any) => {
        const data = response.payload.data;
        const uniqueList = Array.from(
          new Set(data.map((item: any) => item.outletCode))
        ).map((outletCode) => {
          return data.find((item: any) => item.outletCode === outletCode);
        });
        setList(uniqueList);
      })

      .catch((error) => {
        console.error("Failed to fetch order details:", error);
      });
  }, [searchValue]);

  useEffect(() => {
    dispatch(getRecentSearch({ menu: "sales", subMenu: "newSalesReturn" }));
  }, []);

  const values = {
    values: ["With Invoice", "Without Invoice"],
  };
  useEffect(() => {
    if (
      valueSelected === "With Invoice" ||
      valueSelected === "Without Invoice"
    ) {
      setBtnEnabled(true);
    } else {
      setBtnEnabled(false);
    }
  }, [valueSelected]);

  const ManualOrderData = useAppSelector((state) => state.manualOrderdata);
  const recentSearches = ManualOrderData.recentSearch;

  const salesReturnData = useAppSelector((state) => state.salesReturnData);
  const Options = ManualOrderData.salesman;
  const routeCodeOptions = ManualOrderData.route;
  const handleValueSelect = (value: string) => {
    setValueSelected(value);
  };
  const handleChange = (value: string) => {
    setSearchValue(value);
    if (!visible) {
      setVisible(true);
    }
  };
  const selectItem = async (value: string, option: any) => {
    const item = list.find((item) => item.outletCode === option.key);

    if (item) {
      setSearchValue(item.outletName);
      setSelectedItem(item.outletCode);
      setOutletName(item.outletName);
      setRouteCodeSelected(item.routeCode[0]);
      setVisible(false);
      setPopVisible(true);
      recentSearch.outletCode = item.outletCode;

      try {
        // Wait for saveRecentSearch to complete
        await dispatch(saveRecentSearch(recentSearch));

        // After saving, dispatch getRecentSearch
        dispatch(getOutletDetailsData(item.outletCode));
        dispatch(getRecentSearch({ menu: "sales", subMenu: "newSalesReturn" }));
      } catch (error) {
        console.error("Error saving recent search", error);
      }
    }
  };

  const renderTitle = (title: string) => {
    if (searchValue) {
      return (
        <span>
          {title} <b>{searchValue}</b>
        </span>
      );
    }
  };
  const handlePopUpClose = () => {
    setPopVisible(false);
    setSalesman("");
    setRouteCodeSelected("");
    setErrorMsg("");
  };
  const handleRecentTag = (name: string, value: string, route: any) => {
    setPopVisible(true);
    dispatch(getOutletDetailsData(value));
    setSelectedItem(value);
    setOutletName(name);
    // setRouteCodeSelected(null);
    setSearchValue(name);
  };
  const requestData = {
    selectedItem,
    salesman,
    routeCodeSelected,
    searchValue,
  };

  const renderOptions = (): {
    label: React.ReactElement;
    key: string;
    className?: string;
    value?: string;
  }[] => {
    if (salesReturnData.salesReturnOutletLoading) {
      return [
        {
          label: (
            <div style={{ textAlign: "center" }}>
              <Spin />
            </div>
          ),
          key: "loading",
        },
      ];
    } else if (
      salesReturnData?.salesReturnOutlet?.length === 0 &&
      !salesReturnData?.salesReturnOutletLoading
    ) {
      return [
        {
          label: <div style={{ textAlign: "center" }}>No data Found</div>,
          key: "loading",
        },
      ];
    }

    const filteredList = SalesReturnSearchFilter(searchValue, list);
    const options: {
      label: React.ReactElement;
      key: string;
      className?: string;
      value?: string;
    }[] = [];

    if (searchValue) {
      options.push({
        label: renderTitle("Result for") as React.ReactElement,
        key: "result-title",
        className: "option-title-border",
      });
    } else {
      options.push({
        label: renderTitle("") as React.ReactElement,
        key: "result-title",
        className: "option-title",
      });
    }

    if (filteredList) {
      const items = filteredList.map((x) => ({
        value: x.outletName,
        label: (
          <div className="item-box-search" key={x.outletCode}>
            <div
              className="item_text1"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <b style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {x.outletName} ({x.outletCode})
              </b>
              <span>
                <RightOutlined style={{ width: "12px" }} />
              </span>
            </div>
            <div className="item_text2">
              <b>Address</b>
            </div>
            <div className="item_text3">{x.address}</div>
          </div>
        ),
        key: x.outletCode,
      }));

      return [...options, ...items];
    }

    return options;
  };

  const clearSearch = () => {
    setSearchValue("");
    setVisible(false);
  };

  const handletoshowReviewOrder = () => {
    const queryString = `?outletcode=${selectedItem}&outletName=${outletName}`;
    if (valueSelected === "Without Invoice") {
      navigator(`/xdm/sales/without-invoice-sales-return${queryString}`);
    } else if (valueSelected === "With Invoice") {
      navigator(`/xdm/sales/with-invoice-sales-return${queryString}`);
    }
  };

  return (
    <Grid item>
      <Grid item rowGap={2}>
        <Grid
          item
          className="input_container"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={6} sm={6} md={4} lg={3.2} pt={0}>
            <Typography className="seach_lbl">
              Search and Select the Outlet
            </Typography>

            <AutoComplete
              className="input search_box_saleable w-100px"
              popupClassName="manual_search_input custom_manul_search"
              value={searchValue}
              onChange={handleChange}
              onSelect={selectItem}
              options={renderOptions()}
              style={{ marginTop: "5px" }}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            >
              <Input
                className="search_box_saleable border-radius-50px w-100px"
                placeholder="Search Outlet Name, Code"
                value={searchValue}
                prefix={
                  <img src={searchImg} width={"18px"} alt="search image" />
                }
                style={{ fontSize: "12px" }}
                suffix={
                  searchValue && (
                    <IoCloseOutline
                      style={{
                        color: "#d9d9d9",
                        width: "16px",
                        height: "16px",
                        cursor: "pointer",
                      }}
                      onClick={clearSearch}
                    />
                  )
                }
              />
            </AutoComplete>
          </Grid>
        </Grid>

        <Grid
          item
          className="recent_seacr_con"
          justifyContent={"center"}
          pt={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={5}
            lg={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "700",
            }}
          >
            Recent Searches
          </Grid>
        </Grid>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          {ManualOrderData.outletLoading && (
            <Grid container spacing={2}>
              <Grid item md={3} lg={3}></Grid>
              {Array.from(new Array(4)).map((_, index) => (
                <Grid item md={1.5} lg={1.5} key={index}>
                  <Skeleton
                    key={index} // Ensure each Skeleton has a unique key
                    variant="rectangular"
                    height={25}
                    width={160}
                    sx={{ mb: 1, mt: 3, borderRadius: 2 }}
                  />
                </Grid>
              ))}
              <Grid item md={3} lg={3}></Grid>
              <Grid item md={3.5} lg={3.5}></Grid>
              {Array.from(new Array(3)).map((_, index) => (
                <Grid item md={1.5} lg={1.5} key={index + 4}>
                  {" "}
                  {/* Ensure unique key */}
                  <Skeleton
                    key={index + 4} // Unique key for the second set of Skeletons
                    variant="rectangular"
                    height={25}
                    width={160}
                    sx={{ mb: 1, mt: 1, borderRadius: 2 }}
                  />
                </Grid>
              ))}
              <Grid item md={3.5} lg={3.5}></Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          sx={{ display: "flex", justifyContent: "center" }}
          pt={1}
        >
          <Grid item xs={12} sm={12} md={7} lg={6}>
            <Grid
              container
              gap={1}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {/* {Array.from(new Array(4)).map((_, index) => ( */}

              {/* ))} */}
              {!ManualOrderData.outletLoading &&
                recentSearches.map((item: any) => (
                  <Grid
                    item
                    md={3}
                    lg={3}
                    key={item.outletCode}
                    className="recent_div"
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleRecentTag(
                        item.outletName,
                        item.outletCode,
                        item.routeCode
                      )
                    }
                  >
                    {/* <Tooltip
                    placement="top"
                    color="primary"
                    title={item.outletName}
                    zIndex={999999}
                  > */}
                    <Grid item title={item.outletName} className="item_text3">
                      {item.outletName}
                    </Grid>
                    {/* </Tooltip> */}

                    <Grid item>
                      <img
                        src={recentArrow}
                        width={"10px"}
                        alt="search Arrow"
                      />
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        className="mannual_imag"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <img src={GroupManual} width={"280px"} alt="group image" />
      </Grid>
      <Dialog
        open={popVisible}
        onClose={handlePopUpClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: "333px", padding: "12px 16px" }}>
          <Grid
            className="close_icon_btn cursor_pointer"
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
            onClick={handlePopUpClose}
          >
            <IoClose style={{ width: "19px", height: "19px" }} />
          </Grid>
          <DialogContentText
            className="dialog-title-remove"
            id="alert-dialog-description"
          >
            Select the type of return
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "black", fontSize: "12px", marginTop: "4px" }}
          >
            <FormControl component="fieldset" sx={{ mt: 1, mb: 1 }}>
              <RadioGroup
                row
                value={valueSelected || ""}
                onChange={(event) => handleValueSelect(event.target.value)}
              >
                {Array.isArray(values.values) &&
                  values.values.map((values: any, index: number) => (
                    <FormControlLabel
                      key={index}
                      value={values.toString()}
                      control={
                        <Radio
                          className="sidebar_checkbox"
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 15,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label={
                        <div
                          style={{
                            paddingTop: "2px",
                            paddingLeft: "5px",
                            fontSize: "12px",
                          }}
                          className=""
                        >
                          {values}
                        </div>
                      }
                    />
                  ))}
              </RadioGroup>
            </FormControl>
            <span style={{ marginTop: "2px", color: "red" }}>{errorMsg}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="crt-btn-cancel"
            style={{
              height: "30px",
              width: "25%",
              marginRight: "3px",
              fontSize: "12px",
            }}
            onClick={handlePopUpClose}
          >
            {" "}
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            className={` ${
              !btnEnabled ? "crt-btn-colr-disabled" : "crt-btn-colr"
            }`}
            style={{ height: "30px", width: "25%", fontSize: "12px" }}
            type="submit"
            loadingPosition="start"
            loadingIndicator={
              <CircularProgress
                size={10}
                sx={{ color: "white", marginLeft: "-10px" }}
              />
            }
            // loading={btnLoader}
            sx={{ mr: 2 }}
            onClick={handletoshowReviewOrder}
            disabled={!btnEnabled}
          >
            {" "}
            Proceed
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default SalesReturnSearchInput;
