import { COMPANY_Edit_DATA, COMPANY_FETCH_DATA, COMPANY_UPDATE_DATA,COMPANY_Submit_ERROR,COMPANY_UPDATE_ERROR } from '../../actionType'
interface State {
    companyFetch: [];
    CompanyEdit: [];
    CompanyUpdate:[];
    companyToast: [];
    companyUpdateToast:[];
    loading: boolean;
    error: string | null;
  }
  

  const initialState: State = {
    companyFetch: [],
    CompanyEdit: [],
    CompanyUpdate:[],
    companyToast:[],
    companyUpdateToast:[],
    loading: false,
    error: null,
  };


 const companyReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case COMPANY_FETCH_DATA:
        return {
          ...state,
          companyFetch: action.payload,
        };
        
        case COMPANY_Edit_DATA:
          return {
            ...state,
            CompanyEdit: action.payload,
          };
          case COMPANY_UPDATE_DATA:
            return {
              ...state,
              CompanyUpdate: action.payload,
            };
            case COMPANY_Submit_ERROR:
              return {
                ...state,
                companyToast: action.payload,
              };
              case COMPANY_UPDATE_ERROR:
                return {
                  ...state,
                  companyUpdateToast: action.payload,
                };
      default:
        return state;
    }
  };
  
export default companyReducer;

