/* eslint-disable no-unused-vars */
import React, { Component, ReactNode, ErrorInfo } from "react";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ErrorImage from "../../assets/images/error.svg";
import { Box, Grid } from "@mui/material";

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    console.log(this.state.errorInfo, "errorInfo");
    if (this.state.errorInfo) {
      // Error path
      return (
        <>
          {/* <div className="error-handling-box">
            <h2>
              <span>
                <ReportProblemIcon className="error-icon" />
              </span>
              Oops! Error occurred!
            </h2>
          </div> */}
          <Box sx={{ width: "100%", maxHeight: "100%", bgcolor: "#ffff" }}>
            {/* <AuthBackground /> */}
            <Grid item columns={12} container sx={{ display: "flex", pt: 6 }}>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                display={{
                  xs: "inline",
                  lg: "inline",
                  md: "inline",
                  sm: "inline",
                }}
              >
                <img
                  src={ErrorImage}
                  alt={"Page Not Found"}
                  className="page_notfound_image"
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Grid item className="h-100px">
                  <Grid
                    spacing={3}
                    columns={12}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    mt={4}
                    className="ds-flext-center w-100px"
                  >
                    <div className="error-handling-box">
                      <h2>
                        <span>
                          <ReportProblemIcon className="error-icon" />
                        </span>
                        Oops! Something went wrong
                      </h2>
                    </div>
                    <details
                      style={{ whiteSpace: "pre-wrap" }}
                      className="error-toggle-btn"
                    >
                      {this.state.error && this.state.error.toString()}
                      <br />
                      {this.state.errorInfo.componentStack}
                    </details>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
