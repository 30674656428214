import React from "react";

import { Box } from "@mui/material";

const PurchaseReturn: React.FC = () => {

  return <Box>
    <p>Purchase Return</p>
  </Box>;
};
export default PurchaseReturn;
