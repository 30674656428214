import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Drawer,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";

//icons
import editIcon from "../../../../assets/images/icons/editIcon.png";

import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import GeberalInfoEdit from "./GeberalInfoEdit";

interface RequestData {
  page: number;
  size: number;
}
// export default function GeneralInfo(props: GeneralInfoProps) {
//   const { onClose, requestData } = props;

export default function GeneralInfo() {
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
    setOpen(true);

    // toggleDrawer(true);
  };
  // const toggleDrawer = (newOpen: boolean) => () => {
  //   setOpen(newOpen);
  // };
  return (
    <Box
      sx={{
        // display: "flex",
        // flexDirection: "column",
        height: "100%",
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
          alignItems: "center",
          height: "55px",
          borderBottom: "1px solid #00000005",
          paddingLeft: "35px",
          position: "sticky",
          top: "140px",
          backgroundColor: "#FFFFFF",
          zIndex: 1,
        }}
      >
        <Typography
          sx={{ color: "#00000", fontSize: "13px", fontWeight: "700" }}
        >
          General Information
        </Typography>{" "}
        &nbsp; &nbsp; &nbsp;
        <Typography className="Edit_button_general" onClick={handleEditClick}>
          Edit &nbsp;
          <span>
            <img
              src={editIcon}
              alt={editIcon}
              style={{ width: "13px", height: "14px" }}
            />
          </span>
        </Typography>
      </Grid>
      {/* Heading */}
      <form style={{ backgroundColor: "#FFFFFF" }}>
        <Grid
          item
          sx={{
            width: "85%",
            ml: { xs: 5, sm: "100px", md: "130px" },
            overflowY: "auto",
          }}
        >
          {/* Content */}
          <Box mt={3} sx={{ height: "100%" }} className="create-form">
            {/* Your scrollable content */}

            <Box
            // sx={{
            //   marginBottom: "100px",
            //   display: "flex",
            //   gap: "20px",
            //   padding: "0px 18px",
            // }}
            >
              <Grid container role="presentation" sx={{ display: "flex" }}>
                <Grid item mt={1} sm={11} className="">
                  <Divider textAlign="left" className="crt-title-lable ">
                    Distributor Details
                  </Divider>
                </Grid>
              </Grid>
              <Grid mt={1} container rowSpacing={2} columnSpacing={4} mb={1}>
                <Grid item xs={11} sm={3.7}>
                  <Typography className="crt-fnt-lable">
                    Distributor Code *
                  </Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder=""
                      focused={false}
                      // value={companyData.name}
                      // disabled
                    />
                  </Grid>
                </Grid>

                <Grid item xs={11} sm={3.7}>
                  <Grid item>
                    <Typography className="crt-fnt-lable">
                      Distributor Name *
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        focused={false}
                        className="Crt-txt-box"
                        placeholder=""
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={11} sm={3.7}>
                  <Grid item>
                    <Typography className="crt-fnt-lable">
                      Distributor Type *
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        focused={false}
                        className="Crt-txt-box"
                        placeholder=""
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={11} sm={3.7}>
                  <Grid item>
                    <Typography className="crt-fnt-lable">
                      Supplier *
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        focused={false}
                        className="Crt-txt-box"
                        placeholder=" "
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={11} sm={3.7}>
                  <Grid item>
                    <Typography className="crt-fnt-lable">
                      Branch Code *
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        focused={false}
                        className="Crt-txt-box"
                        placeholder=""
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={11} sm={3.7}>
                  <Grid item>
                    <Typography className="crt-fnt-lable">
                      Branch Name *
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        focused={false}
                        className="Crt-txt-box"
                        placeholder=""
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container role="presentation" sx={{ display: "flex" }}>
                <Grid item sm={11} className="" mt={2}>
                  <Divider textAlign="left" className="crt-title-lable ">
                    Address
                  </Divider>
                </Grid>
                <Grid mt={1} container rowSpacing={2} columnSpacing={4} mb={1}>
                  <Grid item xs={11} sm={3.7}>
                    <Typography className="crt-fnt-lable">
                      Address 1 *
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder=""
                        focused={false}
                        // value={companyData.name}
                        // disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        Address 2 *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder="Distributor Name"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        Address 3 *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder="Distributor Type"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        Country *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">State *</Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=" "
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">City *</Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        Pin Code *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container role="presentation" sx={{ display: "flex" }}>
                <Grid item sm={11} className="" mt={2}>
                  <Divider textAlign="left" className="crt-title-lable ">
                    Contact
                  </Divider>
                </Grid>
                <Grid mt={1} container rowSpacing={3} columnSpacing={4} mb={1}>
                  <Grid item xs={11} sm={3.7}>
                    <Typography className="crt-fnt-lable">
                      Phone No *
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder=""
                        focused={false}
                        // value={companyData.name}
                        // disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        Contact Person *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        Email ID *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder="Distributor Type"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container role="presentation" sx={{ display: "flex" }}>
                <Grid item sm={11} className="" mt={2}>
                  <Divider textAlign="left" className="crt-title-lable ">
                    OtherDetails
                  </Divider>
                </Grid>
                <Grid mt={1} container rowSpacing={2} columnSpacing={4} mb={1}>
                  <Grid item xs={11} sm={3.7}>
                    <Typography className="crt-fnt-lable">GST No *</Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder=""
                        focused={false}
                        // value={companyData.name}
                        // disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        GST Valid Up To *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        FSSAI No *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        FSSAI Valid Up to *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        Drug Licensed No *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=" "
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        Drug Licensed Valid Up to *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        Week Off *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        Drug Licensed No *
                      </Typography>
                      <Grid item xs={12}>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="Yes	"
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 18,
                                    color: "#6750A4",
                                  },
                                }}
                              />
                            }
                            label="Yes	"
                          />
                          <FormControlLabel
                            value="No	"
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 18,
                                    color: "#6750A4",
                                  },
                                }}
                              />
                            }
                            label="No	"
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        Distributor Validity *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        No.Of Salesman(Subsidiary) *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        Salesman Salary Budget PM *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        Geofencing *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        Geo Hierarchy *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        Sales Hierarchy *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        Division Mapping with Code *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={11} sm={3.7}>
                    <Grid item>
                      <Typography className="crt-fnt-lable">
                        Route Mapping *
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          focused={false}
                          className="Crt-txt-box"
                          placeholder=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </form>
      {isEditing ? (
        <GeberalInfoEdit
          onClose={() => setIsEditing(false)}
          // requestData={requestData}
        />
      ) : (
        <></>
      )}
    </Box>
  );
}
