import React, { useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Grid,
  InputLabel,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  Modal,
  Card,
  Paper,
  Tab,
  Typography,
} from "@mui/material";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
// import Createdistributorpopup from "./Createdistributorpopup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SaveIcon from "@mui/icons-material/Save";


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const EditsalesMan: React.FC<DistributorTabProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const DistributorCode = ["Select One", "", ""];
  const Selects = ["Select One", "", ""];

  
  const Attach = ["CAVINKARE PRIVATE LIMITED", "", ""];
  const type = ["Order Booking", "", ""];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  return (
    <>
      <Box className="card-form">
        <form noValidate>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 5 }}>
            <Grid item xs={12} sm={4}>
              <InputLabel size="normal">Distributor Code</InputLabel>
              <FormControl fullWidth>
                <Select>
                  {DistributorCode.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel size="normal">Code</InputLabel>
              <TextField placeholder="Code" fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel size="normal">Name</InputLabel>
              <TextField placeholder="Name" fullWidth />
            </Grid>
          </Grid>
          <Grid container mt={3} columnSpacing={{ xs: 2, sm: 5 }}>
            <Grid item xs={12} sm={4}>
              <InputLabel size="normal">Attach Company</InputLabel>
              <FormControl fullWidth>
                <Select>
                  {Attach.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel size="normal">Phone No:</InputLabel>
              <TextField placeholder="PhoneNo" fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel size="normal">E-mail Id:</InputLabel>
              <TextField placeholder="E-mail" type="email" fullWidth />
            </Grid>
          </Grid>
          <Grid container mt={3} columnSpacing={{ xs: 2, sm: 5 }}>
            <Grid item xs={12} sm={4}>
              <InputLabel size="normal">Select Photo</InputLabel>
              <TextField
                placeholder="Select Photo"
                type="file"
                typeof="image"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel size="normal">Daily Allowance</InputLabel>
              <TextField placeholder="0.00" fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel size="normal">Monthly Salary</InputLabel>
              <TextField placeholder="0.00" fullWidth />
            </Grid>
          </Grid>
          <Grid container mt={3} columnSpacing={{ xs: 2, sm: 5 }}>
            <Grid item xs={12} sm={4}>
              <InputLabel size="normal">Salesman Type</InputLabel>
              <FormControl fullWidth>
                <Select>
                  {type.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              {" "}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid item>
                  <Typography>Date of Birth</Typography>
                  <DatePicker name="startDate" className="date-picker" />
                </Grid>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl>
                <FormLabel>Staus</FormLabel>

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="Active"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                            color: "#6750A4",
                          },
                        }}
                      />
                    }
                    label="Active"
                  />
                  <FormControlLabel
                    value="In Active"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                            color: "#6750A4",
                          },
                        }}
                      />
                    }
                    label="In Active"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container mt={3} columnSpacing={{ xs: 2, sm: 5 }}>
            <Grid item xs={12} sm={4} mt={2}>
              <FormControlLabel
                control={<Checkbox className="check-box" />}
                label="Create Godown"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel size="normal">Aadhaar No</InputLabel>
              <TextField placeholder="Aadhaar No" fullWidth />
            </Grid>
            <Grid item xs={12} sm={4} mt={2}>
              <FormControlLabel
                control={<Checkbox className="check-box" />}
                label="SFA Enabled"
              />
            </Grid>
          </Grid>
          <Grid container mt={3} columnSpacing={{ xs: 2, sm: 5 }}>
          <Grid item xs={12} sm={4}>
              <InputLabel size="normal">Device No</InputLabel>
              <TextField placeholder="Device No" fullWidth />
            </Grid>
          <Grid item xs={12} sm={4}>
              <InputLabel size="normal">Salesman Type</InputLabel>
              <FormControl fullWidth>
                <Select>
                  {Selects.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          
            <Grid item xs={12} sm={4}>
              <InputLabel size="normal">Set SFA Password</InputLabel>
              <TextField placeholder="Set SFA Password" fullWidth />
            </Grid>
          </Grid>
          <Grid container mt={3} columnSpacing={{ xs: 2, sm: 5 }}>
          <Grid item xs={12} sm={4}>
              <InputLabel size="normal">SM Unique Code</InputLabel>
              <TextField placeholder=" SM Unique Code" fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel size="normal">Third Party Emp. Code</InputLabel>
              <TextField placeholder=" Third Party Emp. Code" fullWidth />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel size="normal">Replacement For</InputLabel>
              <TextField placeholder="" fullWidth />
            </Grid>
          
        
          </Grid>
          <Grid item xs={12} mt={2} display="flex" justifyContent="center">
            <Button className="btn-colr " variant="contained">
              <SaveIcon sx={{ color: "", fontSize: "15px" }} />
         Update
            </Button>
            <Grid item sx={{ ml: 2 }}>
              <Button variant="outlined" onClick={handleBackClick}>
               Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditsalesMan;
