import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Drawer, Grid, Typography, Button } from "@mui/material";
import type { TableColumnsType } from "antd";
import { Table, Tooltip } from "antd";
import { decodeJwt } from "../../../utils/constant";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import Baseurl from "../../../hooks/useLocalStorage";
import moment from "moment";
import { ArrowBackIos } from "@mui/icons-material";
import printer from "../../../assets/images/icons/Bf_printer.png";
import {
  getTaxInvoiceFromInfo,
  getTaxInvoiceProductDetails,
  getTaxInvoiceTaxDetails,
  getTaxInvoiceToInfo,
  InvoicePdfGenerate,
} from "../../../Redux/XDM/Sales/InvoiceReducer";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { toWords } from "number-to-words";

declare module "number-to-words" {
  export function toWords(number: number): string;
  export function toOrdinal(number: number): string;
  export function toWordsOrdinal(number: number): string;
}

const convertToWords = (amount: any) => {
  return toWords(amount).replace(/,/g, "") + " only";
};
// const convertToWords = (amount: number) => {
//   // Separate the integer and fractional parts
//   const [integerPart, fractionalPart] = amount.toString().split('.');

//   // Convert integer part to words
//   const integerPartInWords = toWords(parseInt(integerPart)).replace(/,/g, "");

//   // Convert fractional part to words as paisa, if it exists
//   let fractionalPartInWords = '';
//   if (fractionalPart) {
//     const fractionalValue = parseInt(fractionalPart.padEnd(2, '0')); // Ensure 2 digits for paisa
//     fractionalPartInWords = ' and ' + toWords(fractionalValue).replace(/,/g, "") + ' paisa';
//   }

//   // Combine the parts
//   return (integerPartInWords + fractionalPartInWords + " only").toUpperCase();
// };

interface RequestData {
  invoiceNumber: string;
  orderId: string;
  date: string;
  outlet: string;
  route: string;
  salesman: string;
  netAmount: number;
  salesmanMobile: string;
}
interface invoiceProductTableData {
  productName: string;
  hsnCode: number;
  mrp: number;
  billedQty: number;
  freeQty: number;
  total: number;
  rate: number;
  schemeDiscount: number;
  grossAmount: number;
  cgstPercentage: number;
  sgstPercentage: number;
  igstPercentage: number;
  netAmount: number;
}

interface InvoiceTaxData {
  gstPercentage: string;
  taxableAmount: number;
  sgst: number;
  cgst: number;
  taxAmount: number;
}

interface InvoiceDetailsProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
}

export default function InvoiceDetails(props: InvoiceDetailsProps) {
  const { onClose, requestData } = props; // Destructure the props
  const dispatch = useAppDispatch();
  const [buttonLoading, setButtonLoading] = useState(false);
  let invoiceData = useAppSelector((state) => state.invoiceData);
  let invoiceFromdata = invoiceData.fromInfo;
  let invoiceTodata = invoiceData.toInfo;
  let invoiceTaxdata = invoiceData.taxDetails;
  let invoiceProductDetails = invoiceData.taxProductDetails;
  let pdfGenerate = invoiceData.pdf;
  
  const roundValue = (val: number | string): string => {
    if (typeof val === "number" && val !== null) {
      return val?.toFixed(2);
    }
    return val?.toString();
  };
  useEffect(() => {
    // dispatch(getTaxInvoiceFromInfo("INV-20240704-33305"));
    // dispatch(getTaxInvoiceToInfo("INV-20240704-33305"));
    // dispatch(getTaxInvoiceTaxDetails("INV-20240704-33305"));
    // dispatch(getTaxInvoiceProductDetails("INV-20240704-33305"));
    dispatch(getTaxInvoiceFromInfo(requestData.invoiceNumber));
    dispatch(getTaxInvoiceToInfo(requestData.invoiceNumber));
    dispatch(getTaxInvoiceTaxDetails(requestData.invoiceNumber));
    dispatch(getTaxInvoiceProductDetails(requestData.invoiceNumber));
  }, [requestData]);
  const formatDate = (date: string | undefined) => {
    if (date && date !== "00-00-0000") {
      const dateObj = moment(date, "YYYY-MM-DD");
      return dateObj.format("DD MMM YYYY");
    }
    return "-";
  };
  const dataRequest = {
    invoiceNumber: requestData.invoiceNumber,
    routeCode: requestData.route,
    invoiceDate: requestData.date,
    smName: requestData.salesman,
    // routeName:
    // invoiceNumber: "INV-20240711-25335",
    // routeCode: "GT0100020020750002002075-ROU0032ab",
    // invoiceDate: "22-July-2024",
    // smName: "Saran",
  };
  const handlePrintButton = async () => {
    setButtonLoading(true);
    try {
      dispatch(InvoicePdfGenerate(dataRequest))
        .then((response) => {
          const pdfGenerateval = response.payload.data;
          // Update the state with the fetched data
          setButtonLoading(false);
          // Need to open the pdf in new tab But it's downloading the pdf
          const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
            pdfGenerateval
          )}`;
          window.open(viewerUrl, "_blank");
        })
        .catch((error) => {
          setButtonLoading(false);
        });
    } catch (error) {
      setButtonLoading(false);
    }
  };

  //   useEffect(() => {
  //  if (pdfGenerate) {
  //    const url = Baseurl + pdfGenerate;
  //    window.open(url, "_blank");
  //  }
  //      },
  //     [pdfGenerate]);
  const columns1: TableColumnsType<invoiceProductTableData> = [
    {
      title: "Product Details",
      children: [
        {
          title: "Product Name",
          dataIndex: "productName",
          key: "productName",
          width: "20%",
          render: (text: string) => {
            // const displayText =
            //   text.length > 35 ? `${text.substring(0, 35)}...` : text;
            return (
              <Tooltip placement="top" title={text} zIndex={999999}>
                <span>{text}</span>
              </Tooltip>
            );
          },
        },
        {
          title: "HSN Code",
          dataIndex: "hsnCode",
          key: "hsnCode",
        },
        {
          title: "MRP",
          dataIndex: "mrp",
          key: "mrp",
          render: (text: number) => {
            return `₹${roundValue(text)}`;
          },
        },
      ],
    },

    {
      title: "Quantity",
      children: [
        {
          title: "Billed",
          dataIndex: "billedQty",
          key: "billedQty",
          render: (text: number) => {
            return text + "pcs";
          },
        },
        {
          title: "Free",
          dataIndex: "freeQty",
          key: "freeQty",
          render: (text: number) => {
            return text + "pcs";
          },
        },
        {
          title: "Total",
          dataIndex: "total",
          key: "total",
          render: (text: number) => {
            return text + "pcs";
          },
        },
      ],
    },
    {
      title: "Price Details",
      children: [
        {
          title: "Rate",
          dataIndex: "rate",
          key: "rate",
          render: (text: number) => {
            return `₹${roundValue(text)}`;
          },
        },
        {
          title: "Scheme Discount",
          dataIndex: "schemeDiscount",
          key: "schemeDiscount",
          render: (text: number) => {
            return `₹${roundValue(text)}`;
          },
        },
        {
          title: "Gross Amount",
          dataIndex: "grossAmount",
          key: "grossAmount",
          render: (text: number, record: invoiceProductTableData) => {
            return record.grossAmount !== undefined && record.grossAmount !== null
            ? `₹${record.grossAmount.toFixed(2)}`
            : "-";
          },
        },
      ],
    },
    {
      title: "Taxes",
      children: [
        {
          title: "CGST %",
          dataIndex: "cgstPercentage",
          key: "cgstPercentage",
          render: (text: number) => {
            return roundValue(text);
          },
        },
        {
          title: "SGST %",
          dataIndex: "sgstPercentage",
          key: "sgstPercentage",
          render: (text: number) => {
            return roundValue(text);
          },
        },
        {
          title: "IGST %",
          dataIndex: "igstPercentage",
          key: "igstPercentage",
          render: (text: number) => {
            return roundValue(text);
          },
        },
      ],
    },

    {
      title: "Net Amount",
      key: "amount",
      dataIndex: "netAmount",
      render: (text: any, record: invoiceProductTableData) => {
        // const tax =
        //   record.grossAmount *
        //   (record.cgstPercentage / 100 +
        //     record.sgstPercentage / 100 +
        //     record.igstPercentage / 100);
        // const amount = record.grossAmount - record.schemeDiscount;
        return `₹${record.netAmount?.toFixed(2)}`;
      },
    },
  ];

  const taxDescriptionColumns: TableColumnsType<InvoiceTaxData> = [
    {
      title: "Gst percentage",
      dataIndex: "gstPercentage",
      key: "gstPercentage",
      render: (text: number) => {
        return roundValue(text);
      },
    },
    {
      title: "Taxable Amount",
      dataIndex: "taxableAmount",
      key: "taxableAmount",
      render: (text: number) => {
        return `₹${roundValue(text)}`;
      },
    },
    {
      title: "SGST",
      dataIndex: "sgst",
      key: "sgst",
      render: (text: number) => {
        return roundValue(text);
      },
    },
    {
      title: "CGST",
      dataIndex: "cgst",
      key: "cgst",
      render: (text: number) => {
        return roundValue(text);
      },
    },
    {
      title: "IGST",
      dataIndex: "igst",
      key: "igst",
      render: (text: number) => {
        return roundValue(text);
      },
    },
    {
      title: "Tax Amount",
      dataIndex: "taxAmount",
      key: "taxAmount",
      render: (text: number) => {
        return `₹${roundValue(text)}`;
      },
    },
  ];

  const formatAmount = (amount: number | undefined) => {
    return amount ? amount?.toFixed(2) : "";
  };

  const amountTableData = [
    {
      key: "1",
      billed: "Gross Amount(Include Discount)",
      symbol: ":",
      amount: invoiceProductDetails?.totalGrossAmount
        ? formatAmount(invoiceProductDetails?.totalGrossAmount)
        : 0.0,
    },
    {
      key: "2",
      billed: "Scheme Discount Amount (-)",
      symbol: ":",
      amount: invoiceProductDetails?.totalSchemeDiscount
        ? formatAmount(invoiceProductDetails?.totalSchemeDiscount)
        : 0.0,
    },
    {
      key: "3",
      billed: "Other Discount Amount (-)",
      symbol: ":",
      amount: invoiceProductDetails?.otherDiscountAmount
        ? formatAmount(invoiceProductDetails?.otherDiscountAmount)
        : 0.0,
    },
    {
      key: "4",
      billed: "Tax Amount (+)",
      symbol: ":",
      amount: invoiceProductDetails?.totalTaxAmount
        ? formatAmount(invoiceProductDetails?.totalTaxAmount)
        : 0.0,
    },
    {
      key: "5",
      billed: "TCS Amount (+)",
      symbol: ":",
      amount: invoiceProductDetails?.tcsAmount
        ? formatAmount(invoiceProductDetails?.tcsAmount)
        : 0.0,
    },
    {
      key: "6",
      billed: "Net Amount",
      symbol: ":",
      amount: invoiceProductDetails?.totalNetAmount
        ? formatAmount(invoiceProductDetails?.totalNetAmount)
        : 0.0,
    },
    {
      key: "7",
      billed: "Cr/Db Amount (+/-)",
      symbol: ":",
      amount: invoiceProductDetails?.crDbAmount
        ? formatAmount(invoiceProductDetails?.crDbAmount)
        : 0.0,
    },
    {
      key: "8",
      billed: "Net Payable",
      symbol: ":",
      amount: invoiceProductDetails?.totalNetPayable
        ? formatAmount(invoiceProductDetails?.totalNetPayable)
        : 0.0,
    },
  ];

  const amountTable = [
    {
      dataIndex: "billed",
      key: "billed",
      render: (text: string) => {
        const displayText =
          text === "Net Amount" || "Net Payable" ? "bold" : "normal";
        return <span style={{ fontWeight: displayText }}>{text}</span>;
      },
    },
    {
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      dataIndex: "amount",
      key: "amount",
    },
  ];
  const totalNetPayable = invoiceProductDetails?.totalNetPayable ?? 0;
  const totalNetPayableInWords = convertToWords(totalNetPayable).toUpperCase();

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_outlet w-100px"
      // variant="persistent"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "100%" }} role="presentation">
            <Grid container className="crt-header" mt={1} p={2}>
              <Grid item xs={6} sm={6} md={9} lg={9} className="margin_tp_0">
                <Typography gutterBottom className="outlet-header-lable">
                  <ArrowBackIos
                    sx={{
                      fontSize: "10px",
                      cursor: "pointer",
                      marginRight: "5px",
                    }}
                    onClick={() => {
                      onClose();
                    }}
                  />
                  Tax Invoice
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                lg={3}
                style={{ display: "flex", justifyContent: "flex-end" }}
                className="margin_tp_0"
              >
                <LoadingButton
                  loadingPosition="start"
                  loading={buttonLoading}
                  variant="contained"
                  className="prnt_af_btn"
                  sx={{ mr: 2 }}
                  onClick={handlePrintButton}
                  style={{
                    width: "45%",
                    fontSize: "10px",
                    borderRadius: "8px",
                    height: "36px",
                  }}
                  type="button"
                >
                  <img
                    src={printer}
                    alt="Print"
                    style={{ marginRight: "8px" }}
                    width={18}
                  />
                  Print
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          {/* <Divider /> */}
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form saleable-products-box"
          >
            {" "}
            <Grid
              item
              sx={{
                width: "95%",
                margin: "auto",
                marginBottom: "90px",
              }}
            >
              {" "}
              <Grid container columnGap={1} mt={1}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={2.9}
                  sx={{
                    border: "1px solid #d3cece73",
                    borderRadius: "5px",
                    margin: "0px 0px",
                    paddingLeft: "3px !important",
                    paddingTop: "0px !important",
                  }}
                >
                  <Typography
                    className="crt-fnt-lable-light"
                    mt={1}
                    sx={{ fontSize: "11px !important" }}
                  >
                    From :
                  </Typography>
                  <Typography
                    gutterBottom
                    className="inv-lable-input"
                    sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                  >
                    {/* {requestData.gstin} */}
                    {invoiceFromdata?.distributorName
                      ? invoiceFromdata.distributorName
                      : "-"}
                  </Typography>
                  <Typography
                    gutterBottom
                    className="inv-lable-input"
                    sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                  >
                    {/* {requestData.gstin} */}
                    {invoiceFromdata?.distributorCode
                      ? invoiceFromdata.distributorCode
                      : "-"}
                  </Typography>
                  <Typography
                    gutterBottom
                    className="inv-lable-input"
                    sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                  >
                    {/* {requestData.gstin} */}
                    {invoiceFromdata?.distributorAddress
                      ? invoiceFromdata.distributorAddress
                      : "-"}
                  </Typography>
                  <Typography
                    gutterBottom
                    className="inv-lable-input"
                    sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                  >
                    Phone Number: &nbsp;{/* {requestData.gstin} */}
                    {invoiceFromdata?.distributorMobile
                      ? invoiceFromdata.distributorMobile
                      : "-"}
                  </Typography>
                  <Typography
                    gutterBottom
                    className="inv-lable-input"
                    sx={{ marginBottom: "0px", fontSize: "11px !important" }}
                  >
                    {/* {requestData.outletCode} */}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "10px !important", width: "52px" }}
                    >
                      GSTIN No
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;{/* {requestData.gstin} */}
                      {invoiceFromdata?.distributorGstNumber
                        ? invoiceFromdata.distributorGstNumber
                        : "-"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "10px !important", width: "52px" }}
                    >
                      FSSAI No
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {invoiceFromdata?.distributorFssaiNumber
                        ? invoiceFromdata.distributorFssaiNumber
                        : "-"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "10px !important", width: "52px" }}
                    >
                      GST State
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {invoiceFromdata?.distributorState
                        ? invoiceFromdata.distributorState
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={2.9}
                  sx={{
                    border: "1px solid #d3cece73",
                    borderRadius: "5px",
                    margin: "0px 0px",
                    paddingLeft: "3px !important",
                    paddingTop: "0px !important",
                  }}
                >
                  <Typography
                    className="crt-fnt-lable-light"
                    mt={1}
                    sx={{ fontSize: "10px !important" }}
                  >
                    To :
                  </Typography>
                  <Typography
                    gutterBottom
                    className="inv-lable-input"
                    sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                  >
                    {/* {requestData.gstin} */}
                    {invoiceTodata?.outletName ? invoiceTodata.outletName : "-"}
                  </Typography>
                  <Typography
                    gutterBottom
                    className="inv-lable-input"
                    sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                  >
                    {/* {requestData.gstin} */}
                    {invoiceTodata?.outletAddress
                      ? invoiceTodata?.outletAddress
                      : "-"}
                  </Typography>

                  <Typography
                    gutterBottom
                    className="inv-lable-input"
                    sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                    mt={1}
                  >
                    {/* {requestData.outletCode} */}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "10px !important", width: "52px" }}
                    >
                      GSTIN No
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;{/* {requestData.gstin} */}
                      {invoiceTodata?.outletGstNumber
                        ? invoiceTodata.outletGstNumber
                        : "-"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "10px !important", width: "52px" }}
                    >
                      FSSAI
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {invoiceTodata?.fssai ? invoiceTodata.fssai : "-"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "10px !important", width: "52px" }}
                    >
                      GST State
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {invoiceTodata?.outletGstState
                        ? invoiceTodata.outletGstState
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={2.9}
                  sx={{
                    border: "1px solid #d3cece73",
                    borderRadius: "5px",
                    margin: "0px 0px",
                    paddingLeft: "3px !important",
                    paddingTop: "0px !important",
                  }}
                >
                  <Typography
                    className="crt-fnt-lable-light"
                    mt={1}
                    sx={{ fontSize: "10px !important" }}
                  >
                    Shipping Address :
                  </Typography>
                  <Typography
                    gutterBottom
                    className="inv-lable-input"
                    sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                  >
                    {invoiceTodata?.shippingAddress
                      ? invoiceTodata.shippingAddress
                      : "-"}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "10px !important", width: "52px" }}
                    >
                      GSTIN No
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {invoiceTodata?.shippingGstNumber
                        ? invoiceTodata.shippingGstNumber
                        : "-"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "10px !important", width: "52px" }}
                    >
                      FSSAI
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {invoiceTodata?.shippingFssai
                        ? invoiceTodata.shippingFssai
                        : "-"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "10px !important", width: "52px" }}
                    >
                      GST State
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {invoiceTodata?.shippingGstState
                        ? invoiceTodata.shippingGstState
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={3}
                  sx={{
                    border: "1px solid #d3cece73",
                    borderRadius: "5px",
                    margin: "0px 0px",
                    paddingLeft: "3px !important",
                    paddingTop: "0px !important",
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "10px !important", width: "90px" }}
                    >
                      Invoice No
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {requestData.invoiceNumber
                        ? requestData.invoiceNumber
                        : "-"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "10px !important", width: "90px" }}
                    >
                      Date
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;{invoiceFromdata?.invoiceDate ? invoiceFromdata?.invoiceDate : "-"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "10px !important", width: "90px" }}
                    >
                      RT Name
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {invoiceFromdata?.routeName ? invoiceFromdata?.routeName : "-"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "10px !important", width: "90px" }}
                    >
                      SM Name
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {invoiceFromdata?.salesmanName ? invoiceFromdata?.salesmanName : "-"}

                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "10px !important", width: "90px" }}
                    >
                      Phone
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {invoiceFromdata?.mobileNo
                        ? invoiceFromdata?.mobileNo
                        : "-"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "10px !important", width: "90px" }}
                    >
                      Shipping Date
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;{formatDate(invoiceTodata?.shippingDate)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="pad-top-0px inv"
                >
                  {" "}
                  <div className="saleable-table-scroll productData border-set">
                    <Table
                      className="Header-table saleable-table Table_footer border-set last_hide scroll_cus_table elipsTable"
                      columns={columns1}
                      dataSource={invoiceProductDetails?.productDetails}
                      loading={invoiceData.taxProductsLoading}
                      scroll={{ y: 150 }}
                      summary={(pageData) => {
                        return (
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={3}>
                              Total
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align="center">
                              {invoiceProductDetails?.totalBilledQty} pcs
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} align="center">
                              {invoiceProductDetails?.totalFreeQty} pcs
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3} align="center">
                              {invoiceProductDetails?.totalSum} pcs
                            </Table.Summary.Cell>
                            <Table.Summary.Cell
                              index={4}
                              colSpan={7}
                            ></Table.Summary.Cell>
                          </Table.Summary.Row>
                        );
                      }}
                      pagination={false}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item xs={12} sm={6} md={4} lg={4} className="inv">
                  <div className="productData">
                    <Table
                      className="Header-table saleable-table  description-table last_hide scroll_cus_table"
                      columns={taxDescriptionColumns}
                      loading={invoiceData.taxDetailsLoading}
                      dataSource={invoiceTaxdata}
                      pagination={false}
                      scroll={{ y: 150 }}
                    />
                  </div>
                  <div style={{ fontSize: "12px", paddingTop: "10px" }}>
                    Net Payable In words: {totalNetPayableInWords}
                  </div>
                  <div style={{ fontSize: "12px", paddingTop: "15px" }}>
                    Declaration:{" "}
                  </div>
                  <div style={{ fontSize: "12px" }}>
                    <b>(E. & O.E.)</b>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}></Grid>
                <Grid item xs={12} sm={6} md={4} lg={4.5} className="inv">
                  <div className="productData">
                    <Table
                      className="Header-table saleable-table border-set remove-header"
                      columns={amountTable}
                      loading={invoiceData.taxProductsLoading}
                      pagination={false}
                      dataSource={amountTableData}
                    />
                  </div>
                  <div style={{ fontSize: "10px" }}>
                    For : {invoiceFromdata?.distributorName}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
