import {
  Box,
  Card,
  Grid,
  Stack,
  Divider,
  Tabs,
  Tab,
  Typography,
  Button,
  Skeleton,
} from "@mui/material";

import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useFetch";
import MainCard from "../../../../components/MainCard";
import { camelCaseToNormal } from "../../../../utils/constant";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SearchBar from "@mkyy/mui-search-bar";
import { Spin, Table, TableColumnsType } from "antd";
import debounce from "lodash.debounce";
import {
  updateDate,
  updateFromDate,
  updateInvoiceType,
  updateOutlet,
  updatePaymentMode,
  updateSalesman,
  updateStatusGrn,
  updateToDate,
} from "../../../../Redux/XDM/Sales/billingReducer";
import PendingCollectionReview from "./PendingCollection/PendingCollectionReview";
import MiniDrawerStyled from "../../../../Layout/MainLayout/Drawer/MiniDrawerStyled";
import DrawerHeader from "../../../../Layout/MainLayout/Drawer/DrawerHeader";
import NewCollection from "./NewCollection/NewCollection";
import CollectionHistory from "./CollectionHistory/CollectionHistory";
import PendingCollectionSideBar from "./PendingCollectionSideBar";
import CheckAck from "./CheckAcknowledgement/CheckAck";
import CollectionHistorySideBar from "./CollectionHistorySideBar";
import {
  getPendingCollection,
  getPendingCollectionCard,
  getTotalCollectionBasedSmId,
} from "../../../../Redux/XDM/Sales/collectionReducer";
import { useLocation } from "react-router-dom";

interface PendingCollectionDatatype {
  salesManId: string;
  salesManName: string;
  mobile: string;
  collectionAmount: number;
  invoiceCount: number;
}
type TableRowType = PendingCollectionDatatype | { noMoreData: boolean };

interface DataProps {
  label: string;
  value: number | string | any;
}
const formatIndianNumber = (num: number): string => {
  const numStr = num.toString();
  const [integerPart, decimalPart] = numStr.split(".");
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);

  if (otherDigits !== "") {
    lastThreeDigits = "," + lastThreeDigits;
  }

  const formattedIntegerPart =
    otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

  return decimalPart
    ? formattedIntegerPart + "." + decimalPart.slice(0, 2)
    : formattedIntegerPart + ".00";
};

// const roundValue = (label: string, val: number | string): string => {
//   if (
//     typeof val === "number" &&
//     (label === "totalAmountCollected" ||
//       label === "upiCollection" ||
//       label === "cashCollection" ||
//       label === "chequeCollection")
//   ) {
//     return formatIndianNumber(val);
//   }
//   return val?.toString();
// };
const DataBox: React.FC<DataProps> = ({ label, value }) => {
  const shouldShowIcon =
    label === "totalAmountCollected" ||
    label === "upiCollection" ||
    label === "cashCollection" ||
    label === "chequeCollection";

  // Conditionally hide count for totalAmountCollected
  const shouldShowCount = label !== "totalAmountCollected";

  return (
    <MainCard border={false} boxShadow>
      <Stack
        spacing={1}
        justifyContent="center"
        alignItems="center"
        className={"card-box-counts " + label}
      >
        <Typography variant="h6" className="card-box-title">
          {label === "upiCollection"
            ? "UPI Collection"
            : camelCaseToNormal(label)}{" "}
          {shouldShowCount ? `(${value.count})` : ""}
        </Typography>
        <Typography variant="subtitle1" className="card-box-count">
          {shouldShowIcon && <CurrencyRupeeIcon sx={{ fontSize: 15 }} />}
          {value?.amt?.toFixed(2)}
        </Typography>
      </Stack>
    </MainCard>
  );
};

const Collection: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isNewCollectionShow, setIsNewCollectionShow] = useState(false);
  const theme = useTheme();

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleNewCollection = (
    event: React.MouseEvent<unknown, MouseEvent>
  ) => {
    setIsNewCollectionShow(!isNewCollectionShow);
  };

  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [pendingCollectionList, setPendingCollectionList] = React.useState<
    PendingCollectionDatatype[]
  >([]);
  const [hasMore, setHasMore] = useState(true);

  const [commonLoader, setCommonLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const location = useLocation();

  const tableHeightRef = useRef(350);
  let pendingCollectionData = useAppSelector((state) => state.salesReturnData);
  const filterTags = useAppSelector((state) => state.orderBilling);
  let collectionDetailsData = useAppSelector(
    (state) => state.collectionReducerData
  );
  let collectionCountData = useAppSelector(
    (state) => state.collectionReducerData.pendingCollectionCounts
  );

  const orderCountsData = {
    totalAmountCollected: {
      amt: collectionCountData.totalAmtCollected,
      count: " ",
    },
    upiCollection: {
      amt: collectionCountData.upiCollection,
      count: collectionCountData.upiCount,
    },
    cashCollection: {
      amt: collectionCountData.cashCollection,
      count: collectionCountData.cashCount,
    },
    chequeCollection: {
      amt: collectionCountData.chequeCollection,
      count: collectionCountData.chequeCount,
    },
  };

  const salesmanData = collectionDetailsData.salesmanDetails;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get("id");
    const status = params.get("status");
    if (id && status === "Pending") {
      setRequestData(salesmanData);
      const salesManId = salesmanData.salesManId;
      dispatch(getTotalCollectionBasedSmId(salesManId));
      setIsShow(!isShow);
    } else if (id && status === "History") {
      setTabIndex(1);
    } else if (id && status === "ChequeAck") {
      setTabIndex(2);
    }
  }, []);

  const filterData = {
    page: 0,
    size: 0,
    fromDate: filterTags.fromDateSelected ? filterTags.fromDateSelected : "",
    toDate: filterTags.toDateSelected ? filterTags.toDateSelected : "",
    search: textFieldValue,
    dateType:
      filterTags.dateSelected?.length !== 0 ? filterTags.dateSelected : "",
    salesmanId:
      filterTags.salesmanSelected?.toString() || salesmanData.salesManId, // Array of selected salesman IDs
  };

  useEffect(() => {
    setPage(0);
    setHasMore(true);
    setPendingCollectionList([]);
    // loadMoreData(true);
    // getCollectionCardCounts(true);
    if (tabIndex === 0) {
      loadMoreData(true);
      getCollectionCardCounts(true);
    }
  }, [
    textFieldValue,
    filterTags.dateSelected,
    filterTags.outletSelected,
    filterTags.fromDateSelected,
    filterTags.toDateSelected,
    filterTags.salesmanSelected,
    filterTags.invoiceTypeSelected,
    tabIndex,
  ]);

  useEffect(() => {
    dispatch(updateFromDate([]));
    dispatch(updateToDate([]));
    dispatch(updateDate([]));
    dispatch(updateSalesman([]));
    dispatch(updateOutlet([]));
    dispatch(updateInvoiceType([]));
    dispatch(updatePaymentMode([]));
  }, [tabIndex]);

  const getCollectionCardCounts = (resetPage = false) => {
    let nextPage = resetPage ? 0 : page;

    dispatch(
      getPendingCollectionCard({
        params: { ...filterData, page: nextPage },
      })
    );
  };

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 283;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;

    dispatch(
      getPendingCollection({
        params: { ...filterData, page: nextPage },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const newOrders = response.payload.data || [];
          setPendingCollectionList((prevOrders) =>
            resetPage ? newOrders : [...prevOrders, ...newOrders]
          );
          setPage(nextPage + 1);
          if (newOrders.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        } else {
          // setHasMore(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };

  // const filteredCount = pendingCollectionDataFetch.totalCount ?? 0;

  const [requestData, setRequestData] = React.useState({
    salesManId: "",
    salesManName: "",
    mobile: "",
  });

  const handleRowClick = (record: PendingCollectionDatatype) => {
    setRequestData(record);
    const salesManId = record?.salesManId;
    dispatch(getTotalCollectionBasedSmId(salesManId));
    setIsShow(!isShow);
  };

  const columns: TableColumnsType<TableRowType> = [
    {
      dataIndex: "salesManName",
      title: "Salesman Name",
      align: "start",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return record.salesManName;
      },
    },
    {
      dataIndex: "mobile",
      title: "Salesman Number",
      align: "start",
      render: (text: string | number, record: TableRowType) => {
        if ("noMoreData" in record) {
          return {
            children: (
              <span
                className="fs-12 noMore"
                style={{ display: "block", margin: "10px" }}
              >
                <b>No more data found...</b>
              </span>
            ),
            props: {
              colSpan: 4, // Adjust this number based on the total number of columns to span
            },
          };
        }
        return record.mobile;
      },
    },

    {
      dataIndex: "invoiceCount",
      title: "No. of Collection",
      align: "center",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return record.invoiceCount;
      },
    },
    {
      dataIndex: "collectionAmount",
      title: "Collection Value",
      align: "right",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <span>
            ₹
            {record?.collectionAmount
              ? record?.collectionAmount?.toFixed(2)
              : "0.00"}
          </span>
        );
      },
    },
  ];

  const dataToShow: TableRowType[] =
    pendingCollectionList.length > 0
      ? hasMore
        ? pendingCollectionList
        : [...pendingCollectionList, { noMoreData: true }]
      : [];

  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <Grid item>
      {isShow ? (
        <PendingCollectionReview
          onClose={() => setIsShow(false)}
          requestData={requestData}
        />
      ) : (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          bgcolor="#eee"
        >
          <Box flex={1} p={0}>
            <MiniDrawerStyled variant="permanent" open={true} theme={theme}>
              <DrawerHeader />
              {/* {tabIndex === 0 && <SalesReturnSidebar />} */}
              {tabIndex === 1 && (
                <CollectionHistorySideBar status={"History"} />
              )}
              {tabIndex === 0 && (
                <PendingCollectionSideBar status={"Settled"} />
              )}
            </MiniDrawerStyled>
          </Box>
          <Box
            flex={8}
            className="margin-left-0px"
            sx={{ overflowY: "auto", height: "100vh", marginBottom: "100px" }}
          >
            <Card
              className="b-radius-0 main-card"
              style={{ marginTop: "2px", boxShadow: "none" }}
            >
              <Grid
                container
                spacing={3}
                justifyContent="start"
                style={{ padding: "10px 16px" }}
              >
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={9}
                  lg={9}
                  className="margin_tp_0"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 800,
                      fontFamily: "Montserrat",
                      color: "#000000",
                    }}
                  >
                    Collection
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={3}
                  lg={3}
                  className="margin_tp_0"
                  sx={{ textAlign: "right" }}
                >
                  <Button
                    className="btn-apply-color"
                    variant="contained"
                    style={{ fontSize: "11px", borderRadius: "5px" }}
                    onClick={handleNewCollection}
                  >
                    New Collection
                  </Button>
                </Grid>
              </Grid>
              <Divider />
              <Grid container sx={{ paddingLeft: " 20px" }}>
                <Grid item sm={12}>
                  <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    className="tab_box"
                    sx={{
                      "& .MuiTab-root": {
                        color: "#B9BABD",
                        "&.Mui-selected": {
                          color: "#944986",
                        },
                      },
                    }}
                  >
                    <Tab label="Pending Collection" />
                    <Tab label="Collection History" />
                    <Tab label="Cheque Acknowledgement" />
                  </Tabs>
                  <Divider />
                </Grid>
              </Grid>
              <TabPanel value={tabIndex} index={0}>
                <Box>
                  <Box
                    flex={8}
                    sx={{
                      overflowY: "auto",
                      height: "100vh",
                      mt: { sm: -0.5, md: -5.3 },
                    }}
                  >
                    <Card
                      className="b-radius-0 "
                      style={{ marginTop: "2px", boxShadow: "none" }}
                    >
                      <Grid container spacing={3} justifyContent="start" p={2}>
                        <Grid container mt={4} m={2}>
                          <Grid item xs={12} sm={12} md={9} lg={9}>
                            {/* <h3
                              style={{
                                marginTop: "0px",
                                marginLeft: "8px",
                                marginBottom: "16px",
                              }}
                            /> */}
                          </Grid>
                          <Grid item xs={6} sm={5} md={3} lg={3}>
                            <SearchBar
                              className="collection_search w-100px"
                              placeholder="Search Salesman Name,ID.."
                              value={textFieldValue}
                              onChange={(newValue) => {
                                setTextFieldValue(newValue);
                              }}
                              onCancelResearch={() => setTextFieldValue("")}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={3}
                          justifyContent="start"
                          px={2}
                          mt={1}
                        >
                          {collectionDetailsData.pendingCollectionCountsLoading
                            ? Array.from(new Array(4)).map((_, index) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={3}
                                  lg={3}
                                  key={index}
                                  style={{ paddingTop: "4px" }}
                                >
                                  <Skeleton
                                    variant="rounded"
                                    width="100%"
                                    height={81}
                                  />
                                </Grid>
                              ))
                            : Object.entries(orderCountsData).map(
                                ([key, value]) => (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    lg={3}
                                    key={key}
                                    style={{ paddingTop: "4px" }}
                                  >
                                    <DataBox label={key} value={value} />
                                  </Grid>
                                )
                              )}
                        </Grid>
                        <Grid
                          container
                          spacing={3}
                          mt={2}
                          justifyContent="start"
                          p={2}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="pad-top-0px"
                          >
                            <div className="saleable-table-scroll">
                              <Table
                                className={`Header-table saleable-table sale_last_hide scroll_cus_table elipsTable pending_grn ${
                                  commonLoader ? " nodataloader" : ""
                                }`}
                                // rowSelection={rowSelection}
                                columns={columns}
                                dataSource={dataToShow}
                                pagination={false}
                                loading={!page && commonLoader}
                                showSorterTooltip={false}
                                summary={() => {
                                  return (
                                    <>
                                      {page !== 0 && commonLoader && (
                                        <Table.Summary.Row>
                                          <Table.Summary.Cell
                                            index={1}
                                            colSpan={4}
                                            align="center"
                                          >
                                            <Spin />
                                          </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                      )}
                                    </>
                                  );
                                }}
                                onRow={(record: TableRowType, index) => {
                                  if ("noMoreData" in record) return {};
                                  return {
                                    onClick: (
                                      event: React.MouseEvent<HTMLElement>
                                    ) => {
                                      handleRowClick(record);
                                    },
                                  };
                                }}
                                scroll={{ y: tableHeightRef.current }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <CollectionHistory />
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <CheckAck />
              </TabPanel>
            </Card>
          </Box>
        </Stack>
      )}
      {isNewCollectionShow ? (
        <NewCollection onClose={() => setIsNewCollectionShow(false)} />
      ) : (
        <></>
      )}
    </Grid>
  );
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}
export default Collection;
