import * as React from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  Box,
  FormControl,
} from "@mui/material";
import { InputLabel, Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DeleteSurveyModal from "./DeleteSurvey";
import ViewSurveyForm from "./ViewSurvey";
import SurveyForm from "./SurveyForm";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  channel: string;
  group: string;
  class: string;
  survey: string;
  action: string;
}

const SurveyDefinition: React.FC = () => {
  const navigator = useNavigate();

  const [showTable, setShowTable] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  const [isViewing, setIsViewing] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);

  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // search function logic here
  };

  const ChannelOptions = ["tcs group", "geo level", "geo level value"];
  const GroupOptions = ["tcs group", "geo level", "geo level value"];
  const ClassOptions = ["AA", "BB", "cc", "dd"];

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "channel",
      title: "Channel Code",
      sorter: (a, b) => a.channel.length - b.channel.length,
    },
    {
      dataIndex: "group",
      title: "Group Code",
      sorter: (a, b) => a.group.length - b.group.length,
    },
    {
      dataIndex: "class",
      title: "Class Code",
      sorter: (a, b) => a.class.length - b.class.length,
    },
    {
      dataIndex: "survey",
      title: "Survey Code",
      sorter: (a, b) => a.survey.length - b.survey.length,
    },
    {
      dataIndex: "action",
      title: "Action",
      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            onClick={(event) => handleViewClick(event)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
          <DeleteIcon
            onClick={(event) => handleDeleteClick(event)}
            style={{
              cursor: "pointer",
              marginRight: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      channel: "55",
      group: "00-Supplier1",
      class: "person1-AA",
      survey: "7821",
      action: "",
    },
    {
      key: 2,
      channel: "55445",
      group: "11-Supplier2",
      class: "person2-BB",
      survey: "7822",
      action: "",
    },
    {
      key: 3,
      channel: "5565",
      group: "22-Supplier3",
      class: "person3-CC",
      survey: "7823",
      action: "",
    },
    {
      key: 4,
      channel: "5548",
      group: "33-Supplier4",
      class: "person4",
      survey: "7824",
      action: "",
    },
    {
      key: 5,
      channel: "55121",
      group: "44-Supplier5",
      class: "person5",
      survey: "7825",
      action: "",
    },
    {
      key: 6,
      channel: "5565",
      group: "55-Supplier6",
      class: "person6",
      survey: "7826",
      action: "",
    },
    {
      key: 7,
      channel: "55789",
      group: "99-Supplier7",
      class: "person7",
      survey: "7827",
      action: "",
    },
    {
      key: 8,
      channel: "55656",
      group: "66-Supplier8",
      class: "person8",
      survey: "7828",
      action: "",
    },
  ];

  const handleShowTable = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setShowTable(!showTable);
  };

  const handleCreateNew = () => {
    setShowForm(!showForm);
  };

  const handleViewClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setIsViewing(!isViewing);
  };

  const handleDeleteClick = (event: React.MouseEvent<unknown>) => {
    setIsDelete(!isDelete);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
      <Grid item xs={12} className=" mar-tb">
        <Typography>
          <b className="header-lable">Survey Definition </b>
        </Typography>
      </Grid>

      {showForm ? (
        <SurveyForm onBack={handleCreateNew} />
      ) : isViewing ? (
        <ViewSurveyForm onBack={(event) => handleViewClick(event)} />
      ) : (
        <Box>
          <Grid>
            <Button
              variant="contained"
              className="btn-colr"
              onClick={handleCreateNew}
            >
              <AddIcon /> Create new
            </Button>
          </Grid>
          <form>
            <Grid container spacing={1} style={{ marginTop: "20px" }}>
              <Grid item xs={12} md={2}>
                <InputLabel size="normal" className="fnt-lable">
                  Retailer Channel
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FormControl fullWidth>
                  <Select className="selct-box">
                    {ChannelOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: "20px" }}>
              <Grid item xs={12} md={2}>
                <InputLabel size="normal" className="fnt-lable">
                  Retailer Group
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FormControl fullWidth>
                  <Select className="selct-box">
                    {GroupOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={1} style={{ marginTop: "20px" }}>
              <Grid item xs={12} md={2}>
                <InputLabel size="normal" className="fnt-lable">
                  Retailer Class
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FormControl fullWidth>
                  <Select className="selct-box">
                    {ClassOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Button
                variant="contained"
                className="btn-apply btn-hgt"
                onClick={handleShowTable}
              >
                <SearchIcon className="fn-icon" /> Go
              </Button>
            </Grid>
          </form>

          {showTable && (
            <Box className="mar-tb">
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "end", mb: 2 }}
              >
                <SearchBar
                  className="search-table"
                  value={textFieldValue}
                  onChange={(newValue) => {
                    setTextFieldValue(newValue);
                    handleSearch();
                  }}
                />
              </Grid>
              <CommonTable
                //  rowSelection={rowSelection}
                data={data}
                columns={columns}
              />
            </Box>
          )}
        </Box>
      )}

      {isDelete && (
        <DeleteSurveyModal onBack={(event) => handleDeleteClick(event)} />
      )}
    </Box>
  );
};
export default SurveyDefinition;
