import {
  SIDEBAR_BUSNIESS_LEVEL,
  SIDEBAR_CATEGORY_LEVEL,
  SIDEBAR_BRAND_LEVEL,
  SIDEBAR_BRAND_CATEGORY_LEVEL,
  SIDEBAR_PRODUCT_VARIENT_LEVEL,
  SIDEBAR_SKU_GROUP_LEVEL,
  SIDEBAR_SKU_LEVEL,
  PRODUCT_CODE_NAME,
  PRODUCT_HSN_CODE_NAME,
} from "../../actionType";
import { Dispatch } from "redux";
import createAxiosInstance from "../../../hooks/useFetch";

const axiosInstance = createAxiosInstance();

export interface sidebarBussinessLevelGetAction {
  type: typeof SIDEBAR_BUSNIESS_LEVEL;
  payload: any;
}
export interface sidebarCategoryLevelGetAction {
  type: typeof SIDEBAR_CATEGORY_LEVEL;
  payload: any;
}
export interface sidebarBrandLevelGetAction {
  type: typeof SIDEBAR_BRAND_LEVEL;
  payload: any;
}
export interface sidebarBrandCategoryLevelGetAction {
  type: typeof SIDEBAR_BRAND_CATEGORY_LEVEL;
  payload: any;
}
export interface sidebarProductVarientLevelGetAction {
  type: typeof SIDEBAR_PRODUCT_VARIENT_LEVEL;
  payload: any;
}
export interface sidebarSkuGroupGetAction {
  type: typeof SIDEBAR_SKU_GROUP_LEVEL;
  payload: any;
}
export interface sidebarSkuLevelGetAction {
  type: typeof SIDEBAR_SKU_LEVEL;
  payload: any;
}
export interface ProductCodeName {
  type: typeof PRODUCT_CODE_NAME;
  payload: any;
}
export interface ProductHsnCodeName {
  type: typeof PRODUCT_HSN_CODE_NAME;
  payload: any;
}

//Fetch Product Code Name
export const ProductCodeFetchData = () => {
  return (dispatch: Dispatch<ProductCodeName>) => {
    axiosInstance
      .get(`product-master/get-product-code`, {})
      .then((res) => {
        dispatch({
          type: PRODUCT_CODE_NAME,
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};

//Fetch Product hsn code Name
export const ProductHsnCodeFetchData = () => {
  return (dispatch: Dispatch<ProductHsnCodeName>) => {
    axiosInstance
      .get(`product-hsnCode/get-hsn-code`, {})
      .then((res) => {
        dispatch({
          type: PRODUCT_HSN_CODE_NAME,
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};

//filter APi

export const BussinessLevelFetchData = (requestData: {
  pageStatus: boolean;
  search: string;
}) => {
  return (dispatch: Dispatch<sidebarBussinessLevelGetAction>) => {
    axiosInstance
      .get(`product-hierarchy/get-business-level`, {
        params: requestData,
      })
      .then((res) => {
        dispatch({
          type: SIDEBAR_BUSNIESS_LEVEL,
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};

export const CategoryLevelFetchData = (requestData: {
  pageStatus: boolean;
  search: string;
}) => {
  return (dispatch: Dispatch<sidebarCategoryLevelGetAction>) => {
    axiosInstance
      .get(`product-hierarchy/get-category-level`, {
        params: requestData,
      })
      .then((res) => {
        dispatch({
          type: SIDEBAR_CATEGORY_LEVEL,
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};

export const BrandLevelFetchData = (requestData: {
  pageStatus: boolean;
  search: string;
}) => {
  return (dispatch: Dispatch<sidebarBrandLevelGetAction>) => {
    axiosInstance
      .get(`product-hierarchy/get-brand-level`, {
        params: requestData,
      })
      .then((res) => {
        dispatch({
          type: SIDEBAR_BRAND_LEVEL,
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};

export const BrandCategoryFetchData = (requestData: {
  pageStatus: boolean;
  search: string;
}) => {
  return (dispatch: Dispatch<sidebarBrandCategoryLevelGetAction>) => {
    axiosInstance
      .get(`product-hierarchy/get-brand-category`, {
        params: requestData,
      })
      .then((res) => {
        dispatch({
          type: SIDEBAR_BRAND_CATEGORY_LEVEL,
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};

export const ProductVarientLevelFetchData = (requestData: {
  pageStatus: boolean;
  search: string;
}) => {
  return (dispatch: Dispatch<sidebarProductVarientLevelGetAction>) => {
    axiosInstance
      .get(`product-hierarchy/get-product-level`, {
        params: requestData,
      })
      .then((res) => {
        dispatch({
          type: SIDEBAR_PRODUCT_VARIENT_LEVEL,
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};

export const SkuGroupFetchData = (requestData: {
  pageStatus: boolean;
  search: string;
}) => {
  return (dispatch: Dispatch<sidebarSkuGroupGetAction>) => {
    axiosInstance
      .get(`product-hierarchy/get-sku-group`, {
        params: requestData,
      })
      .then((res) => {
        dispatch({
          type: SIDEBAR_SKU_GROUP_LEVEL,
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};

export const SkuLevelFetchData = (requestData: {
  pageStatus: boolean;
  search: string;
}) => {
  return (dispatch: Dispatch<sidebarSkuLevelGetAction>) => {
    axiosInstance
      .get(`product-hierarchy/get-sku-level`, {
        params: requestData,
      })
      .then((res) => {
        dispatch({
          type: SIDEBAR_SKU_LEVEL,
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};
