import React, { useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Grid,
  Checkbox,
  InputLabel,
} from "@mui/material";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  name: string;
  create: string;
  view: string;
  edit: string;
  delete: string;
  selectall: string;
}

interface ViewUserGrpFormProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const UserGrpViewForm: React.FC<ViewUserGrpFormProps> = ({ onBack }) => {
  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // seach function here
  };

  const [rowStates, setRowStates] = useState<
    Array<{
      id: number;
      createChecked: boolean;
      viewChecked: boolean;
      editChecked: boolean;
      deleteChecked: boolean;
      selectAllChecked: boolean;
    }>
  >([
    {
      id: 1,
      createChecked: false,
      viewChecked: false,
      editChecked: false,
      deleteChecked: false,
      selectAllChecked: false,
    },
    {
      id: 2,
      createChecked: false,
      viewChecked: false,
      editChecked: false,
      deleteChecked: false,
      selectAllChecked: false,
    },
    // Add more rows as needed
  ]);

  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "name",
      title: "Screen Name",
    },
    {
      dataIndex: "create",
      title: "Create",
      render: (_: any, record: DataType) => (
        <Checkbox
          checked={
            rowStates.find((row) => row.id === record.key)?.createChecked
          }
          onChange={(e) =>
            handleCheckboxChange(
              Number(record.key),
              "createChecked",
              e.target.checked
            )
          }
        />
      ),
    },
    {
      dataIndex: "view",
      title: "View",
      render: (_: any, record: DataType) => (
        <Checkbox
          checked={rowStates.find((row) => row.id === record.key)?.viewChecked}
          onChange={(e) =>
            handleCheckboxChange(
              Number(record.key),
              "viewChecked",
              e.target.checked
            )
          }
        />
      ),
    },
    {
      dataIndex: "edit",
      title: "Edit",
      render: (_: any, record: DataType) => (
        <Checkbox
          checked={rowStates.find((row) => row.id === record.key)?.editChecked}
          onChange={(e) =>
            handleCheckboxChange(
              Number(record.key),
              "editChecked",
              e.target.checked
            )
          }
        />
      ),
    },
    {
      dataIndex: "delete",
      title: "Delete",
      render: (_: any, record: DataType) => (
        <Checkbox
          checked={
            rowStates.find((row) => row.id === record.key)?.deleteChecked
          }
          onChange={(e) =>
            handleCheckboxChange(
              Number(record.key),
              "deleteChecked",
              e.target.checked
            )
          }
        />
      ),
    },
    {
      dataIndex: "selectall",
      title: "Select All",
      render: (_: any, record: DataType) => (
        <Checkbox
          checked={
            rowStates.find((row) => row.id === record.key)?.selectAllChecked
          }
          onChange={(e) =>
            handleCheckboxChange(
              Number(record.key),
              "selectAllChecked",
              e.target.checked
            )
          }
        />
      ),
    },
  ];

  const handleCheckboxChange = (
    rowId: number,
    field: string,
    checked: boolean
  ) => {
    setRowStates((prevState) =>
      prevState.map((row) =>
        row.id === rowId ? { ...row, [field]: checked } : row
      )
    );
  };

  const data: DataType[] = [
    {
      key: 1,
      name: "ScreenName1",
      create: "",
      view: "",
      edit: "",
      delete: "",
      selectall: "",
    },
    {
      key: 2,
      name: "ScreenName2",
      create: "",
      view: "",
      edit: "",
      delete: "",
      selectall: "",
    },
    {
      key: 3,
      name: "ScreenName3",
      create: "",
      view: "",
      edit: "",
      delete: "",
      selectall: "",
    },
    {
      key: 4,
      name: "ScreenName4",
      create: "",
      view: "",
      edit: "",
      delete: "",
      selectall: "",
    },
    {
      key: 5,
      name: "ScreenName5",
      create: "",
      view: "",
      edit: "",
      delete: "",
      selectall: "",
    },
    {
      key: 6,
      name: "ScreenName6",
      create: "",
      view: "",
      edit: "",
      delete: "",
      selectall: "",
    },
    {
      key: 7,
      name: "ScreenName7",
      create: "",
      view: "",
      edit: "",
      delete: "",
      selectall: "",
    },
    {
      key: 8,
      name: "ScreenName8",
      create: "",
      view: "",
      edit: "",
      delete: "",
      selectall: "",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Box className="card-form">
        <form noValidate>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Group Code{" "}
              </InputLabel>
              <TextField className="Txt-box" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable"> Group Name</InputLabel>
              <TextField className="Txt-box" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">Module Name</InputLabel>
              <TextField className="Txt-box" />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "end", mb: 2 }}
                >
                  <SearchBar
                    className="search-table"
                    value={textFieldValue}
                    onChange={(newValue) => {
                      setTextFieldValue(newValue);
                      handleSearch();
                    }}
                  />
                </Grid>
                <CommonTable
                  // rowSelection={rowSelection}
                  data={data}
                  columns={columns}
                />
              </Box>
            </Grid>

            <Grid item xs={12} mt={2} display="flex" justifyContent="center">
              <Button
              className="button-lable"
                variant="outlined"
                color="primary"
                onClick={handleBackClick}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};
export default UserGrpViewForm;
