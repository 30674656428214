import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Grid,
  Card,
  Typography,
  Snackbar,
  Alert,
  Skeleton,
} from "@mui/material";
import debounce from "lodash.debounce";
import type { TableColumnsType } from "antd";
import { Spin, Table, Tooltip } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useFetch";
import Modal from "@mui/material/Modal";
import Close from "../../../../../assets/images/icons/Close_icon.png";
import {
  getPendingCashPaymentList,
  getPendingPaymentCardCount,
  getRejectAcceptList,
  updatePendingCard,
} from "../../../../../Redux/XDM/Sales/collectionReducer";
import {
  updateDate,
  updateFromDate,
  updateOutlet,
  updateSalesman,
  updateToDate,
} from "../../../../../Redux/XDM/Sales/billingReducer";
import LoadingButton from "@mui/lab/LoadingButton";
import InvoiceDetails from "../../InvoiceReviewOrder";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "3px",
};

interface ReviewOrderDetails {
  collectionNumber: string;
  invoiceNumber: string[];
  collectionDate: string;
  outletName: string;
  outStandingAmount: number;
  amountCollected: number;
  collectionSumNo: string;
}
interface CashDenomination {
  denomination: string;
  count: number;
  totalValue: number;
}
interface SalesManIdProps {
  salesManId: string;
}

interface CashDominationResponse {
  totalAmount: number;
  "1_count": number;
  "1_total_value": number;
  "2_count": number;
  "2_total_value": number;
  "5_count": number;
  "5_total_value": number;
  "10_count": number;
  "10_total_value": number;
  "20_count": number;
  "20_total_value": number;
  "50_count": number;
  "50_total_value": number;
  "100_count": number;
  "100_total_value": number;
  "200_count": number;
  "200_total_value": number;
  "500_count": number;
  "500_total_value": number;
}
export default function PendingCashPayments(props: SalesManIdProps) {
  const { salesManId } = props;
  const dispatch = useAppDispatch();
  const [hasMore, setHasMore] = useState(true);
  const [commonLoader, setCommonLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [textFieldValue, setTextFieldValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [acceptPopupRecord, setAcceptPopupRecord] = useState<any | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [isShowDetails, setIsShowDetails] = React.useState(false);
  const [invoiceRequestData, setInvoiceRequestData] = React.useState({
    invoiceNumber: "",
    orderId: "",
    date: "",
    outlet: "",
    route: "",
    salesman: "",
    netAmount: 0,
    salesmanMobile: "",
  });
  const collectionSumNo = useRef<string[]>([]);
  const [cashDomData, setCashDomData] = useState<CashDominationResponse>({
    totalAmount: 0,
    "1_count": 0,
    "1_total_value": 0,
    "2_count": 0,
    "2_total_value": 0,
    "5_count": 0,
    "5_total_value": 0,
    "10_count": 0,
    "10_total_value": 0,
    "20_count": 0,
    "20_total_value": 0,
    "50_count": 0,
    "50_total_value": 0,
    "100_count": 0,
    "100_total_value": 0,
    "200_count": 0,
    "200_total_value": 0,
    "500_count": 0,
    "500_total_value": 0,
  });
  const [dataRecordOrders, setDataRecordOrders] = useState<
    ReviewOrderDetails[]
  >([
    // Add more records as needed
  ]);

  const tableHeightRef = useRef(160);
  const tableHeightloadRef = useRef(180);
  const filterTags = useAppSelector((state) => state.orderBilling);

  const filterData = {
    paymentType: "Cash",
    page: 0,
    size: 0,
    dateType:
      filterTags.dateSelected?.length !== 0 ? filterTags.dateSelected : "",
    fromDate: filterTags.fromDateSelected ? filterTags.fromDateSelected : "",
    toDate: filterTags.toDateSelected ? filterTags.toDateSelected : "",
    search: textFieldValue,
    salesmanId: salesManId,
    outletId: filterTags.outletSelected.toString(),
  };

  const CardfilterData = {
    paymentType: "Cash",
    salesmanId: salesManId,
  };

  useEffect(() => {
    setHasMore(true);
    loadMoreData(true);
    dispatch(
      updatePendingCard({
        totalCollection: 0,
        totalCollectionAmt: 0,
      })
    );
    dispatch(
      getPendingPaymentCardCount({
        params: { ...filterData },
      })
    );
  }, [
    textFieldValue,
    filterTags.dateSelected,
    filterTags.outletSelected,
    filterTags.fromDateSelected,
    filterTags.toDateSelected,
  ]);

  useEffect(() => {
    // dispatch(updateFromDate([]));
    // dispatch(updateToDate([]));
    // dispatch(updateDate([]));
    // dispatch(updateSalesman([]));
    dispatch(updateOutlet([]));
  }, []);

  let pendingPaymentData = useAppSelector(
    (state) => state.collectionReducerData
  );
  let pendingPaymentsCountData = useAppSelector(
    (state) => state.collectionReducerData.pendingPaymentCounts
  );
  let cashDominationData = pendingPaymentData.cashDominationData;

  const handleInvoiceClick = (invoiceNo: string) => {
    setInvoiceRequestData({
      invoiceNumber: invoiceNo,
      orderId: "",
      date: "",
      outlet: "",
      route: "",
      salesman: "",
      netAmount: 0,
      salesmanMobile: "",
    });
    setIsShowDetails(!isShowDetails);
  };

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 353;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const tableHeightLoad = () => {
    const headerHeight = 0;
    const footerHeoght = 300;
    const availableHeight = window.innerHeight - headerHeight - footerHeoght;
    tableHeightloadRef.current = availableHeight;
  };

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    tableHeightLoad();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;
    filterData.size = tableHeightRef.current > 550 ? 25 : 15;

    dispatch(
      getPendingCashPaymentList({
        params: { ...filterData, page: nextPage },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const newOrders = response.payload.data || [];
          const cashDomination = response.payload.denomination || {};

          if (cashDomination && Object.keys(cashDomination).length !== 0) {
            setCashDomData(cashDomination);
          } else {
            setCashDomData({
              totalAmount: 0,
              "1_count": 0,
              "1_total_value": 0,
              "2_count": 0,
              "2_total_value": 0,
              "5_count": 0,
              "5_total_value": 0,
              "10_count": 0,
              "10_total_value": 0,
              "20_count": 0,
              "20_total_value": 0,
              "50_count": 0,
              "50_total_value": 0,
              "100_count": 0,
              "100_total_value": 0,
              "200_count": 0,
              "200_total_value": 0,
              "500_count": 0,
              "500_total_value": 0,
            });
          }
          setDataRecordOrders((prevOrders) =>
            resetPage ? newOrders : [...prevOrders, ...newOrders]
          );

          setPage(nextPage + 1);
          if (newOrders.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };

  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const reviewOrderColumns: TableColumnsType<ReviewOrderDetails> = [
    {
      dataIndex: "collectionNumber",
      title: "Collection ID",
      width: "15%",
      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "-"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "invoiceNumber",
      title: "Invoice No",
      width: "10%",
      render: (text: string, record: ReviewOrderDetails) => {
        if (Array.isArray(record.invoiceNumber) && record.invoiceNumber.length > 0) {
          const invoiceList = record.invoiceNumber[0]?.split(","); // Split all invoices by commas
          const firstInvoice = invoiceList[0]; // Get the first invoice

          return (
            <Tooltip
              placement="top"
              title={record.invoiceNumber[0]}
              zIndex={999999}
            >
              <div
                className="ellipseclass"
                style={{
                  textDecoration: "underline",
                  fontWeight: 600,
                  color: "#27538D",
                  display: "flex",
                  gap: "10px",
                  cursor: "pointer",
                }}
                onClick={() => handleInvoiceClick(firstInvoice)} // Pass only the first invoice
              >
                {invoiceList?.join(", ")}
              </div>
            </Tooltip>
          );
        }
        return <div style={{fontWeight: "bold" }}> - </div>;
        },
    },

    {
      dataIndex: "collectionDate",
      title: "Date",
      align: "center",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
        return a.collectionDate.localeCompare(b.collectionDate);
      },
      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "-"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "outletName",
      title: "Outlet",
      // width: "12%",
      align: "center",
      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "-"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "outStandingAmount",
      title: "Ols Outstanding Amount",
      width: "20%",
      align: "center",
      render: (text: number) => {
        return <span> ₹{text ? text?.toFixed(2) : 0.0}</span>;
      },
    },

    {
      dataIndex: "amountCollected",
      title: "Amount Collected",
      // width: "10%",
      align: "right",
      render: (text: number, record: ReviewOrderDetails) => {
        return <span> ₹{text ? text?.toFixed(2) : 0.0}</span>;
      },
    },
  ];
  const filteredDataToShow = dataRecordOrders.filter((item) =>
    item.collectionNumber.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const dataToShow: ReviewOrderDetails[] =
    filteredDataToShow.length > 0
      ? hasMore
        ? filteredDataToShow
        : [...filteredDataToShow]
      : [];

  const data: CashDenomination[] = [
    {
      denomination: "₹ 500",
      count: cashDomData?.["500_count"],
      totalValue: cashDomData?.["500_total_value"],
    },
    {
      denomination: "₹ 200",
      count: cashDomData?.["200_count"],
      totalValue: cashDomData?.["200_total_value"],
    },
    {
      denomination: "₹ 100",
      count: cashDomData?.["100_count"],
      totalValue: cashDomData?.["100_total_value"],
    },
    {
      denomination: "₹ 50",
      count: cashDomData?.["50_count"],
      totalValue: cashDomData?.["50_total_value"],
    },
    {
      denomination: "₹ 20",
      count: cashDomData?.["20_count"],
      totalValue: cashDomData?.["20_total_value"],
    },
    {
      denomination: "₹ 10",
      count: cashDomData?.["10_count"],
      totalValue: cashDomData?.["10_total_value"],
    },
    {
      denomination: "₹ 5",
      count: cashDomData?.["5_count"],
      totalValue: cashDomData?.["5_total_value"],
    },
    {
      denomination: "₹ 2",
      count: cashDomData?.["2_count"],
      totalValue: cashDomData?.["2_total_value"],
    },
    {
      denomination: "₹ 1",
      count: cashDomData?.["1_count"],
      totalValue: cashDomData?.["1_total_value"],
    },
  ];

  const columns: TableColumnsType<CashDenomination> = [
    {
      title: "Cash Denomination",
      dataIndex: "denomination",
      key: "denomination",
      colSpan: 3,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      colSpan: 0,
      render: (_: any) => <div>X</div>,
    },
    {
      title: "",
      dataIndex: "count",
      key: "count",
      colSpan: 0,
    },
    {
      title: "",
      dataIndex: "totalValue",
      key: "totalValue",
      colSpan: 0,
      render: (value: number) => `₹ ${value}`,
    },
  ];

  const totalAmount = cashDomData?.totalAmount;

  const handleClose = () => setOpen(false);

  const handleAcceptPopup = (records: ReviewOrderDetails[]) => {
    setAcceptPopupRecord(records);
    setOpen(true);
  };

  const handleAcceptOk = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const requestPayload = {
      pendingList: acceptPopupRecord.map((record: ReviewOrderDetails) => ({
        paymentType: "Cash",
        collectionNumber: record.collectionNumber,
        status: "Approved",
        remarks: "",
      })),
    };

    try {
      const response = await dispatch(
        getRejectAcceptList({
          params: requestPayload,
          signal: abortController.signal,
        })
      );
      if (response?.meta?.requestStatus === "fulfilled") {
        setOpen(false);
        setSnackbarMessage("Payment Accepted Successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
        }, 3000);
        setDataRecordOrders((prevData) =>
          prevData.filter(
            (item) =>
              !acceptPopupRecord.some(
                (record: ReviewOrderDetails) =>
                  record.collectionNumber === item.collectionNumber
              )
          )
        );
        dispatch(
          getPendingPaymentCardCount({
            params: { ...filterData },
          })
        );
        setCashDomData({
          totalAmount: 0,
          "1_count": 0,
          "1_total_value": 0,
          "2_count": 0,
          "2_total_value": 0,
          "5_count": 0,
          "5_total_value": 0,
          "10_count": 0,
          "10_total_value": 0,
          "20_count": 0,
          "20_total_value": 0,
          "50_count": 0,
          "50_total_value": 0,
          "100_count": 0,
          "100_total_value": 0,
          "200_count": 0,
          "200_total_value": 0,
          "500_count": 0,
          "500_total_value": 0,
        });
      } else if (response?.meta?.requestStatus === "rejected") {
        const errorMessage = response?.payload?.data;

        setSnackbarMessage(errorMessage);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
        }, 3000);
      }
    } catch (error) {
      console.error("Submission error", error);
    }
  };
  const formatIndianNumber = (num: number | string): string => {
    const numStr = num.toString();
    const [integerPart, decimalPart] = numStr.split(".");
    let lastThreeDigits = integerPart.slice(-3);
    let otherDigits = integerPart.slice(0, -3);

    if (otherDigits !== "") {
      lastThreeDigits = "," + lastThreeDigits;
    }

    const formattedIntegerPart =
      otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

    return decimalPart
      ? formattedIntegerPart + "." + decimalPart.slice(0, 2)
      : formattedIntegerPart + ".00";
  };

  return (
    <Box>
      <Box
        flex={8}
        sx={{ overflowY: "auto", height: "100vh", mt: { sm: -0.5, md: -5.3 } }}
      >
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={100000}
          color="green"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Card
          className="b-radius-0 "
          style={{ marginTop: "2px", boxShadow: "none" }}
        >
          <Grid container spacing={3} justifyContent="start" p={2}>
            <Grid container mt={4} m={2}>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                {/* <h3
                  style={{
                    marginTop: "0px",
                    marginLeft: "8px",
                    marginBottom: "16px",
                  }}
                /> */}
              </Grid>
              <Grid item xs={6} sm={5} md={3} lg={3}>
                <SearchBar
                  className="collection_search w-100px"
                  placeholder="Search Collection ID"
                  value={textFieldValue}
                  onChange={(newValue) => {
                    setTextFieldValue(newValue);
                  }}
                  onCancelResearch={() => setTextFieldValue("")}
                />
              </Grid>
            </Grid>
            <Grid container pl={3.6} mt={2}>
              <Grid item sm={6}>
                <Grid item>
                  <Typography className="Pending_review_lable">
                    Total Cash Collection
                  </Typography>
                </Grid>
                <Grid item mt={0.7}>
                  {pendingPaymentData?.pendingPaymentCountsLoading ? (
                    <Skeleton variant="rectangular" width="30%" />
                  ) : (
                    <Typography className="Pending_review_Sublable">
                      {pendingPaymentsCountData.totalCollection}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item sm={6}>
                <Grid item>
                  <Typography className="Pending_review_lable">
                    Total Cash Collection Value
                  </Typography>
                </Grid>
                <Grid item mt={0.7}>
                  {pendingPaymentData?.pendingPaymentCountsLoading ? (
                    <Skeleton variant="rectangular" width="30%" />
                  ) : (
                    <Typography className="Pending_review_Sublable">
                      ₹
                      {pendingPaymentsCountData?.totalCollectionAmt?.toFixed(2)}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid container mt={2} spacing={1}>
              <Grid
                item
                sm={9.3}
                className="pad-top-0px"
                sx={{ height: tableHeightloadRef.current }}
              >
                <div className="saleable-table-scroll">
                  <Table
                    className={`Header-table saleable-table sale_last_hide scroll_cus_table elipsTable pending_grn ${
                      commonLoader ? " nodataloader" : ""
                    }`}
                    columns={reviewOrderColumns}
                    dataSource={dataToShow}
                    pagination={false}
                    scroll={{ y: tableHeightRef.current }}
                    loading={!page && commonLoader}
                    showSorterTooltip={false}
                    summary={() => {
                      if (!hasMore && dataRecordOrders.length > 0) {
                        return (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              index={1}
                              colSpan={6}
                              align="center"
                              className="noMore"
                            >
                              No more data found...
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        );
                      } else if (page !== 0 && commonLoader) {
                        return (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              index={1}
                              colSpan={6}
                              align="center"
                            >
                              <Spin />
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        );
                      }
                    }}
                  />
                </div>
              </Grid>
              <Grid
                item
                sm={2.7}
                className="pad-top-0px"
                sx={{ height: tableHeightloadRef.current }}
              >
                {page === 0 && commonLoader ? (
                  <Skeleton variant="rectangular" width="100%" height="100%" />
                ) : (
                  <div className="cash_denomination">
                    <Table
                      className={`Header-table saleable-table  scroll_cus_table   pending_grn cash-table ${
                        pendingPaymentData?.cashDominationLoading
                          ? " nodataloader"
                          : ""
                      }`}
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      scroll={{ y: tableHeightRef.current }}
                      bordered
                      summary={() => {
                        if (pendingPaymentData?.cashDominationLoading) {
                          return (
                            <Table.Summary.Row>
                              <Table.Summary.Cell
                                index={1}
                                colSpan={4}
                                align="center"
                              >
                                <Spin />
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        } else {
                          return (
                            <Table.Summary.Row>
                              <Table.Summary.Cell index={0} colSpan={3}>
                                Total Amount: ₹ {totalAmount}
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        }
                      }}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          {isShowDetails && (
            <InvoiceDetails
              onClose={() => setIsShowDetails(false)}
              requestData={invoiceRequestData}
            />
          )}
        </Card>
      </Box>
      <hr className="hr-revieworder"></hr>
      <Grid item mt={1.5} sx={{ display: "flex", justifyContent: "end", marginRight:"20px" }}>
        <Button
          className={dataRecordOrders?.length !== 0 ? "btn-apply-color" : ""}
          variant="contained"
          sx={{
            height: "34px",
            width: { sm: "15%", md: "11%", lg: "9%" },
          }}
          disabled={!dataRecordOrders || dataRecordOrders.length === 0}
          onClick={() => handleAcceptPopup(dataRecordOrders)}
        >
          Accept
        </Button>
      </Grid>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid item xs={2} md={2} lg={2} sx={{ textAlign: "Right" }}>
              <img
                src={Close}
                onClick={handleClose}
                style={{ width: "14px", height: "14px", cursor: "pointer" }}
                alt="Close Icon"
              />
            </Grid>

            <Typography id="modal-modal-description">
              Are you sure you have received{" "}
              <b>{acceptPopupRecord?.length} cash collections </b> worth{" "}
              <b>
                ₹{" "}
                {acceptPopupRecord
                  ?.reduce(
                    (total: any, record: any) => total + record.amountCollected,
                    0
                  )
                  .toFixed(2) || 0.0}{" "}
                ?
              </b>
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "end", mt: 3 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
                sx={{ mr: 2, fontSize: "10px", height: "30px !important" }}
                className="crt-btn-cancel"
              >
                Cancel
              </Button>
              {/* <Button
                sx={{ fontSize: "10px", height: "30px" }}
                variant="contained"
                className="btn-colr"
                onClick={handleAcceptOk}
              >
                Yes
              </Button> */}
              <LoadingButton
                className="Save_btn_des"
                onClick={handleAcceptOk}
                loading={pendingPaymentData.collectionRejectAcceptDataLoading}
                variant="contained"
              >
                Yes
              </LoadingButton>
            </Box>
          </Box>
        </Modal>
      )}
    </Box>
  );
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}
