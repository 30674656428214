import { useState, startTransition } from "react";
import {
  CardHeader,
  Card,
  CardContent,
  Avatar,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Business } from "@material-ui/icons";
import { BiWorld } from "react-icons/bi";
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { FaPenToSquare, FaRegCalendarDays } from "react-icons/fa6";
import { PiPasswordFill } from "react-icons/pi";
import { TbBinaryTree } from "react-icons/tb";
import { FaBookOpen } from "react-icons/fa";
import { BsFillUnlockFill } from "react-icons/bs";
import { GiNotebook } from "react-icons/gi";
import { FaUserAstronaut } from "react-icons/fa";
import { TbCircleLetterI } from "react-icons/tb";
import { FiBox } from "react-icons/fi";
import { MdOutlineSettingsSuggest } from "react-icons/md";
import { GiStarKey } from "react-icons/gi";
import { RiNewspaperFill } from "react-icons/ri";

interface CardData {
  [key: string]: string | JSX.Element | undefined;
}

const Configuration: React.FC = () => {
  const cardData: CardData[] = [
    { Configuration: "Geography Hierarchy Level Value", Icons: <BiWorld /> },
    { Configuration: "ETL", Icons: <FaArrowUpFromBracket /> },
    { Configuration: "Geography Hierarchy Level", Icons: <FaPenToSquare /> },
    { Configuration: "PassWord Reset", Icons: <PiPasswordFill /> },
    { Configuration: "Reason", Icons: <TbCircleLetterI /> },
    { Configuration: "Sales Hierarchy Level", Icons: <TbBinaryTree /> },
    { Configuration: "Supply Chain Mapping", Icons: <FaBookOpen /> },
    { Configuration: "JcCalendar", Icons: <FaRegCalendarDays /> },
    { Configuration: "Reset Login / UnlockUser", Icons: <BsFillUnlockFill /> },
    { Configuration: "SalesHierarchyLevelValue", Icons: <GiNotebook /> },
    { Configuration: "UserProfile", Icons: <FaUserAstronaut /> },
    { Configuration: "TCS/TDS Configuration", Icons: <FiBox /> },
    { Configuration: "User Group", Icons: <MdOutlineSettingsSuggest /> },
    { Configuration: "Key Generator", Icons: <GiStarKey /> },
    { Configuration: "Survey Definition", Icons: <RiNewspaperFill /> },
  ];

  const navigator = useNavigate();
  const handleCardClick = (index: number) => {
    startTransition(() => {
      const paths = [
        "/GeographyHierarchyLevelValue",
        "/ETL",
        "/GeographyHierarchyLevel",
        "/PasswordReset",
        "/Reason",
        "/SalesHierarchyLevel",
        "/SupplyChainMapping",
        "/JcCalander",
        "/ResetUserLock ",
        "/SalesHierarchyLevelValue",
        "/UserProfile",
        "/TCSConfiguration",
        "/UserGroup",
        "/KeyGenerator",
        "/SurveyDefinition",
      ]; // Paths for each index
      navigator(paths[index]);
    });
  };

  return (
    <>
      <Grid container sx={{ p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className="mar-tb">
          <Typography style={{ fontSize: "14px", fontWeight: "800" }}>
            Configuration
          </Typography>
        </Grid>
        <Grid>
          <Typography className="cursor_pointer" style={{ color: "#b6b0b0" }}>
            <span onClick={() => navigator("/Dashboard")}>Dashboard</span>/
            <span>Configuration</span>
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
          gap: 2,
          marginTop: 2,
          p: { xs: 2, sm: 3 },
          pt: { xs: 1, sm: 1 },
        }}
      >
        <Card>
          <CardHeader
            avatar={
              <Avatar
                aria-label="recipe"
                style={{
                  background:
                    " linear-gradient(90deg, rgb(97, 79, 179) 0%, rgb(148, 73, 134) 100%)",
                }}
              >
                M
              </Avatar>
            }
            title="Modules"
            subheader="Configuration"
          />
          <CardContent>
            <Grid container spacing={2}>
              {cardData.map((item, index) => (
                <Grid item xs={6} key={index}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={1}
                      style={{
                        fontSize: "1rem",
                        color: "#614fb3",
                      }}
                    >
                      {item.Icons}
                    </Grid>
                    <Grid item xs={11} onClick={() => handleCardClick(index)}>
                      <Typography className="hover-card">
                        {item.Configuration}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
export default Configuration;
