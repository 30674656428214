import { SetStateAction, useRef, useState } from "react";
import { Avatar, Paper, Popper, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../routes/AuthContext";
import { toast } from "react-toastify";

const Profile = () => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = async (event: { target: any }) => {
    try {
      const response = await logout();
      navigate("/distributor/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }
    setOpen(false);
    localStorage.clear();
    navigate("/distributor/login");
  };

  const handleChange = (event: any, newValue: SetStateAction<number>) => {
    setValue(newValue);
  };

  return (
    <>
      <Avatar
        alt="profile user"
        onClick={handleToggle}
        ref={anchorRef}
        sx={{ width: 20, height: 20 }}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        disablePortal
        placement="bottom-end"
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 9],
            },
          },
        ]}
      >
        <Paper>
          <Button onClick={handleClose}>Logout</Button>
        </Paper>
      </Popper>
    </>
  );
};

export default Profile;
