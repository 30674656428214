import * as React from "react";
import Box from "@mui/material/Box";
import { Grid, Paper, Typography, Button } from "@mui/material";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import UserProfileViewForm from "./UserProfileViewForm";
import UserProfileCreateForm from "./UserProfileCreateForm";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  UserGroupName: string;
  UserName: string;
  UserLoginCode: string;
  Designation: string;
  action: string;
}

const UserProfile: React.FC = () => {
  const navigator = useNavigate();
  const [isViewing, setIsViewing] = React.useState(false);

  const [showForm, setShowForm] = React.useState(false);

  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // seach function here
  };

  const handleViewClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setIsViewing(!isViewing);
  };
  const handleCreateNew = () => {
    setShowForm(!showForm);
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "UserGroupName",
      title: "User Group Name",
      sorter: (a, b) => a.UserGroupName.length - b.UserGroupName.length,
    },
    {
      dataIndex: "UserName",
      title: "User Name",
      sorter: (a, b) => a.UserName.length - b.UserName.length,
    },
    {
      dataIndex: "UserLoginCode",
      title: "User Login Code",
      sorter: (a, b) => a.UserLoginCode.length - b.UserLoginCode.length,
    },
    {
      dataIndex: "Designation",
      title: "Designation",
      sorter: (a, b) => a.Designation.length - b.Designation.length,
    },
    {
      dataIndex: "action",
      title: "Action",

      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            onClick={(event) => handleViewClick(event)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      UserGroupName: "ZSM Group",
      UserName: "AD-NM-SALES",
      UserLoginCode: "NM01",
      Designation: "ASM",
      action: "",
    },
    {
      key: 2,
      UserGroupName: "VSO",
      UserName: "PC AM CHENNAI",
      UserLoginCode: "CKVSO1",
      Designation: "ZSM",
      action: "",
    },
    {
      key: 3,
      UserGroupName: "ADMINISTRATOR",
      UserName: "ZSM",
      UserLoginCode: "VSO004",
      Designation: "VSO",
      action: "",
    },
    {
      key: 4,
      UserGroupName: "ASM Group",
      UserName: "ASHWIN",
      UserLoginCode: "CITPL1",
      Designation: "UAT TEST",
      action: "",
    },
    {
      key: 5,
      UserGroupName: "ZSM Group",
      UserName: "AD-NM-SALES",
      UserLoginCode: "VSO004",
      Designation: "ASM",
      action: "",
    },
    {
      key: 6,
      UserGroupName: "CFA Group",
      UserName: "ASHWIN",
      UserLoginCode: "NM01",
      Designation: "ZSM",
      action: "",
    },
    {
      key: 7,
      UserGroupName: "ADMINISTRATOR",
      UserName: "ZSM",
      UserLoginCode: "CKVSO1",
      Designation: "	VSO",
      action: "",
    },
    {
      key: 8,
      UserGroupName: "ASM Group",
      UserName: "AD-NM-SALES",
      UserLoginCode: "CITPL1",
      Designation: "UAT TEST",
      action: "",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className=" mar-tb">
          <Typography>
            <b className="header-lable">UserProfile </b>
          </Typography>
        </Grid>

        {showForm ? (
          <UserProfileCreateForm onBack={handleCreateNew} />
        ) : isViewing ? (
          <UserProfileViewForm onBack={(event) => handleViewClick(event)} />
        ) : (
          <Box>
            <Button
              variant="contained"
              className="btn-colr"
              onClick={handleCreateNew}
            >
              Create new
            </Button>
            <Grid item sx={{ mt: 2 }}>
              <Box>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "end", mb: 2 }}
                >
                  <SearchBar
                    className="search-table"
                    value={textFieldValue}
                    onChange={(newValue) => {
                      setTextFieldValue(newValue);
                      handleSearch();
                    }}
                  />
                </Grid>
                <CommonTable
                  // rowSelection={rowSelection}
                  data={data}
                  columns={columns}
                />
              </Box>
            </Grid>
          </Box>
        )}
      </Box>
    </>
  );
};
export default UserProfile;
