import React, { ChangeEvent } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Drawer,
  Grid,
  TextField,
  Typography,
  Tab,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { makeStyles } from "@material-ui/core/styles";

import { useTheme } from "@mui/material/styles";
//icons
import backArrow from "../../../../assets/images/icons/backArrow.png";

import Close from "../../../../assets/images/icons/Close_icon.png";

import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { useAppDispatch } from "../../../../hooks/useFetch";
import { subHeaderHide } from "../../../../Redux/AuthLogin/action";

interface ProductMustSellSKUViewProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
}
interface TabInfo {
  label: string;
  value: string;
}

interface RequestData {
  page: number;
  size: number;
}

const useStyless = makeStyles((theme) => ({
  customTab: {
    border: "1px solid #e1e7ed",
    fontSize: "11px",
    padding: "0px 12px",
    borderRadius: "30px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "27px",
    background: "white",
  },
}));

export default function ProductMustSellSKUView(
  props: ProductMustSellSKUViewProps
) {
  const { onClose, requestData } = props; // Destructure the props
  const theme = useTheme();
  const [value, setValue] = React.useState("1");
  const dispatch = useAppDispatch();

  const classes = useStyless();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const tabs = [
    { label: "General Info", value: "1" },
    { label: "LOB", value: "2" },
    { label: "Trade Division Mapping", value: "3" },
    { label: "Customer Mapping", value: "4" },
    { label: "Salesman", value: "5" },
    { label: "Market Area", value: "6" },
    { label: "Route", value: "7" },
    { label: "Credit Note/Debit Note", value: "8" },
  ];
  const handleBackClick = () => {
    onClose();
    dispatch(subHeaderHide(false));
  };
  return (
    <Box>
      <Grid mt={1} container>
        <Grid
          container
          className="mustSell_view"
          sx={{
            display: "flex",
            alignItems: "center",
            position: "sticky",
            top: "50px",
            zIndex: 999,
          }}
        >
          <Grid
            xs={12}
            item
            sx={{
              paddingTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <Grid
              container
              xs={5}
              sx={{
                paddingLeft: "20px",
              }}
            > */}
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "20px",
              }}
              onClick={handleBackClick}
            >
              <img
                src={backArrow}
                alt={backArrow}
                style={{ height: "18px", width: "20px " }}
              />
              <Typography>
                <span
                  style={{
                    fontSize: "18px",
                    color: "#000000",
                    fontWeight: "600",
                  }}
                >
                  New MS SKU
                </span>
              </Typography>
            </Grid>
            <Grid item sx={{ display: "flex" }}>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                // xs={5}
              >
                <Grid item className="circle_sell" mr={1}>
                  {" "}
                  1
                </Grid>
                <Grid item>
                  <Typography
                    sx={{ fontSize: "13px", fontWeight: "700" }}
                    className="sellsku_lable"
                  >
                    General Details
                  </Typography>
                </Grid>
              </Grid>
              <Grid item sx={{}}></Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                // xs={5}
              >
                <Grid item className="circle_sell" mr={1}>
                  {" "}
                  2
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "700",
                      color: "#000000",
                    }}
                    // className="sellsku_lable"
                  >
                    Product Selection
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              // xs={2}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignContent: "center",
                paddingRight: "20px",
              }}
            >
              <img
                onClick={handleBackClick}
                src={Close}
                alt={Close}
                style={{ height: "22px", width: "22px" }}
              />
            </Grid>
            {/* </Grid> */}
          </Grid>
        </Grid>

        {/* </Box> */}
        {/* </Grid> */}
      </Grid>
    </Box>
  );
}
