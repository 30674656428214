import React from "react";
import { Table, ConfigProvider } from "antd";
import type { TableColumnsType } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useFetch";
import { Grid } from "@mui/material";
interface DataType {
  key: React.Key;
  productCode: string;
  hsnCode: string;
  sapCode: string;
  skuName: string;
  skuShortName: string;
  businessLevelName: string;
  categoryLevel: string;
  brandLevel: string;
  brandCategory: String;
  productLevel: string;
  skuGroup: string;
  skuLevel: string;
  mrp: string;
}

const columns: TableColumnsType<DataType> = [
  {
    dataIndex: "productCode",
    title: "PRODUCT CODE",
    width: 150,
    fixed: "left",
    // sorter: (a, b) => a.name.length - b.name.length,
  },
  {
    dataIndex: "hsnCode",
    title: "HSN CODE",
    width: 100,
    fixed: "left",
  },
  {
    dataIndex: "sapCode",
    title: "SAP CODE",
    width: 100,
    fixed: "left",
  },
  {
    dataIndex: "skuName",
    title: "SKU NAME",
    width: 180,
    fixed: "left",
  },
  {
    dataIndex: "skuShortName",
    title: "SKU SHORT NAME",
    width: 150,
    fixed: "left",
    className: "SKuShort_table",
  },

  {
    dataIndex: "businessLevelName",
    title: "BUSINESS LEVEL NAME(100)",
    width: 170,
  },
  {
    dataIndex: "categoryLevel",
    title: "CATEGORY LEVEL(200)",
    width: 150,
  },
  {
    dataIndex: "brandLevel",
    title: "BRAND LEVEL(300)",
    width: 150,
  },
  {
    dataIndex: "brandCategory",
    title: "BRAND CATEGORY(400)",
    width: 150,
  },
  {
    dataIndex: "productLevel",
    title: "PRODUCT/VARIANT LEVEL(500)",
    width: 150,
  },
  {
    dataIndex: "skuGroup",
    title: "SKU GROUP(600)",
    width: 150,
  },
  {
    dataIndex: "skuLevel",
    title: "SKU LEVEL(700)",
    width: 150,
  },
  {
    dataIndex: "mrp",
    title: "MRP",
    width: 150,

    //   render: (_: any, record: DataType) => (
    //     <>
    //       <div>₹</div>
    //     </>
    //   ),
  },
];

interface TableProps {
  // rowSelection: {
  //     selectedRowKeys: Key[];
  //     onChange: (selectedRowKeys: Key[]) => void;
  // };
  onRow?: (record: any) => any;
}
const ProductTable: React.FC<TableProps> = ({
  onRow, // you can include other props here if needed
}) => {
  const productMasterFetchDatas = useAppSelector(
    (state: any) => state.ProductMasterData.ProductMasterFetch
  );

  return (
    <Table
      columns={columns}
      dataSource={productMasterFetchDatas}
      scroll={{ x: 1500 }}
      className="Product_table" // Add className to target the table header
      pagination={false}
      onRow={onRow}
    />
  );
};

export default ProductTable;
