
import * as React from 'react';
import Box from '@mui/material/Box';
import {
    Button,
    Grid,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    InputLabel,
    TextField,
    Stack,
} from '@mui/material';


interface RetailerFormProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
}

const RetailerForm: React.FC<RetailerFormProps> = ({ onBack }) => {
    const handleBackClick = (event: React.MouseEvent<unknown>) => {
        onBack(event); // Pass the event argument to onBack function
    };
    return (
        <Box className='card-form'>
            <form noValidate>
                <Grid container rowSpacing={3} columnGap={4}>
                    <Grid item xs={12} sm={6}>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="Channel" control={<Radio />} label="Channel" />
                                <FormControlLabel value="Sub Channel" control={<Radio />} label="Sub Channel" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel size="normal"  >Channel Code </InputLabel>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel size="normal"  >
                            Channel Name</InputLabel>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} mt={2} display="flex" justifyContent="center">
                        <Button className='btn-apply' variant='contained' onClick={handleBackClick}>Back</Button>

                    </Grid>
                </Grid>
            </form>

        </Box>
    );
}
export default RetailerForm