import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, ClickAwayListener, IconButton, Paper, Popper, Toolbar, Typography, Stack } from '@mui/material';

// project import
import Profile from '.';
import Transitions from '../../../../../components/@extended/Transitions';
import HeaderContent from '../../HeaderContent';
import Tabheader from './TabHeader';
import SubTabs from './SubTab';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// assets
import { MoreOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Notification from './Notification';
import Settings from './Settings';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

// ==============================|| HEADER CONTENT - MOBILE ||============================== //
const headerpages = ['Dashboard', 'Purchase', 'Sales', 'Inventory', 'Reports'];
const subTabs = [
  // Define sub-tabs for each main tab
  [],
  [
    { label: 'Purchase Request', icon: 'Icon3', Route: "/purchase/purchase_request" },
    { label: 'Invoices', icon: 'Icon4', Route: "/purchase/invoices" },
    { label: 'Purchase Return', icon: 'Icon5', Route: "/purchase/purchase_retrun" },
    { label: 'Account', icon: 'Icon6', Route: "/purchase/purchase_account" }
  ],
  [
    { label: 'Company', icon: 'Icon5', Route: "/sales/sales_company" },
    { label: 'Supplier', icon: 'Icon6', Route: "/sales/sales_company1" },
    { label: 'Tax', icon: 'Icon5', Route: "/sales/sales_company2" },
    { label: 'Products', icon: 'Icon6', Route: "/sales/sales_company3" },
    { label: 'Outlets', icon: 'Icon5', Route: "/sales/sales_company4" },
    { label: 'Distributor', icon: 'Icon6', Route: "/sales/sales_company5" },
    { label: 'Configuration', icon: 'Icon6', Route: "/sales/sales_company6" },
  ],
  [
    { label: 'Inventory', icon: 'Icon5', Route: "/inventory/inventory_data" },
    { label: 'Supplier', icon: 'Icon6', Route: "/inventory/inventory_data1" },
    { label: 'Tax', icon: 'Icon5', Route: "/inventory/inventory_data2" },
    { label: 'Products', icon: 'Icon6', Route: "/inventory/inventory_data3" },
    { label: 'Outlets', icon: 'Icon5', Route: "/inventory/inventory_data4" },
    { label: 'Distributor', icon: 'Icon6', Route: "/inventory/inventory_data5" },
    { label: 'Configuration', icon: 'Icon6', Route: "/inventory/inventory_data6" },
  ],
  [
    { label: 'Report', icon: 'Icon5', Route: "/reports/reports_data" },
    { label: 'Supplier', icon: 'Icon6', Route: "/reports/reports_data1" },
    { label: 'Tax', icon: 'Icon5', Route: "/reports/reports_data2" },
    { label: 'Products', icon: 'Icon6', Route: "/reports/reports_data3" },
    { label: 'Outlets', icon: 'Icon5', Route: "/reports/reports_data4" },
    { label: 'Distributor', icon: 'Icon6', Route: "/reports/reports_data5" },
    { label: 'Configuration', icon: 'Icon6', Route: "/reports/reports_data6" },
  ],
];

const MobileSection = () => {
  const theme = useTheme();
  const navigator = useNavigate();
  const urlheader = ['/Dashboard']

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: { target: any; }) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    setExpanded(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef.current) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  const [expanded, setExpanded] = useState<string | false>('panel1');
  
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);

    };

  

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
        <IconButton
          disableRipple
          sx={{
            bgcolor: open ? 'grey.300' : 'grey.100'
          }}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="secondary"
        >
          <MoreOutlined />
        </IconButton>
      </Box>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          width: '100%'
        }}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" position="bottom-end" in={open} {...TransitionProps} >
            <Paper elevation={3} >
              <ClickAwayListener onClickAway={handleClose}>
                <AppBar color="inherit">
                  <Toolbar >
                    <Stack spacing={2}>
                      {headerpages.map((page, index) => (
                        <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                          <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
                            <Typography className='header-lable'>{page}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {subTabs[index].map((subTab, subIndex) => (
                              <List key={subIndex} onClick={() => {
                                navigator(subTab.Route);
                              }}
                                sx={{ width: '100%', bgcolor: 'background.paper' }}
                              >
                                <ListItemButton onClick={handleClose} key={subIndex}>
                                  <ListItemText className='fnt-lable' primary={subTab.label} />
                                </ListItemButton>
                              </List>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                      <List>
                        <ListItemButton >
                          <ListItemText className='header-lable' primary='Profile' />
                          <Profile />
                        </ListItemButton>
                        <ListItemButton >
                          <ListItemText className='header-lable' primary='Settings' />
                          <Settings />
                        </ListItemButton>
                      </List>
                    </Stack>
                  </Toolbar>
                </AppBar>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default MobileSection;
