// material-ui
import { Box, Tabs, Tab } from "@mui/material";
// ==============================|| HEADER - CONTENT ||============================== //
// material-ui
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  sidebarFilter,
  subHeaderHide,
} from "../../../../../Redux/AuthLogin/action";
import { useAppDispatch } from "../../../../../hooks/useFetch";
import MainHeaderData from "./MainHeaderData";
import {
  defaultHeaders,
  mdmHeaders,
  xdmHeaders,
} from "../../../../../utils/TabsArrayList";

const useStyles = makeStyles((theme) => ({
  gradientText: {
    background: "linear-gradient(90.19deg, #614FB3 0%, #944986 100%)", // Example gradient from red to green
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    color: "#FFF",
  },
}));
// ==============================|| HEADER CONTENT - SEARCH ||============================== //
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
interface HeaderProps {
  open?: boolean;
  handleDrawerToggle?: () => void;
}

interface HeaderDataProps {
  path: string;
  label: string;
  currentPath?: string;
  sideBarChange?: () => void;
}

const Tabheadaer: React.FC<HeaderProps> = ({
  open = false,
  handleDrawerToggle,
}) => {
  const [adLogin, setAdLogin] = useState("");
  const classes = useStyles();
  useEffect(() => {
    // Retrieve distributionCode from localStorage if available
    const storedDistributionCode = localStorage.getItem("ad-distributionCode");
    if (storedDistributionCode) {
      setAdLogin(storedDistributionCode);
    }
  }, []);
  // Now you can use currentPath as needed
  return (
    <>
      {adLogin ? (
        <MainHeaderData classes={classes}  tabs={mdmHeaders} />
      ) : 
        <MainHeaderData classes={classes}  tabs={xdmHeaders} />
      }
    </>
  );
};

export default Tabheadaer;
