import React, { useState, useEffect } from "react";
import { startTransition } from "react";
// material-ui
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CommonCard from "../../components/cards/CommonCard";
import { IoSettingsOutline, IoBookmarkOutline } from "react-icons/io5";
import { FaRegBuilding } from "react-icons/fa";
import { IoManOutline, IoPricetagsOutline } from "react-icons/io5";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
// import {localStorageDataReducer} from '../../Redux/index'
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setUserFromLocalStorage } from "../../Redux/AuthLogin/action";

interface CardData {
  Title?: string;
  Icons?: JSX.Element | string;
  [key: string]: string | JSX.Element | undefined;
}

const MainDashboard = () => {
  const navigator = useNavigate();
  const cardData: CardData[] = [
    {
      Title_for_route: "Company",
      company: "Company",
      supplier: "Supplier",
      viewAll: "View All",
      Icons: <FaRegBuilding />,
    },
    {
      Title_for_route: "Configuration",
      ETL: "ETL",
      GeographyHierarchyLevelValue: "Geography Hierarchy LevelValue",
      viewAll: "View All",
      Icons: <IoSettingsOutline />,
    },
    {
      Title_for_route: "Customer",
      Credit: "Creadit Note Upload",
      CustomerTable: "Customer",
      viewAll: "View All",
      Icons: <IoManOutline />,
    },
    {
      Title_for_route: "Distribution",
      RouteCoveragePlan: "Route Coverage Plan",
      Route: "Route",
      viewAll: "View All",
      Icons: <AiOutlineUsergroupAdd />,
    },
    {
      Title_for_route: "Product & Price",
      focus_brand: "Focus brand",
      gst_tax_structure: "GST Tax structure",
      viewAll: "View All",
      Icons: <IoBookmarkOutline />,
    },
    {
      Title_for_route: "Schemes & Claims",
      scheme_master_control: "Scheme master control",
      scheme: "Scheme",
      viewAll: "View All",
      Icons: <IoPricetagsOutline />,
    },
  ];

  const handleCardClick = (index: number) => {
    startTransition(() => {
      const paths = [
        "/MainCompany",
        "/Configuration",
        "/Customer",
        "/MainDistribution",
        "/product_price",
        "/schemes_claims",
      ];
      navigator(paths[index]);
    });
  };
  const dispatch = useDispatch();
  const storedDistributionCode = useSelector(
    (state: any) => state.user.getLocalData
  );

  useEffect(() => {
    // Dispatch the action to retrieve the value from localStorage
    dispatch(setUserFromLocalStorage());
  }, [dispatch]);

  return (
    <>
      <Grid container sx={{ p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={8.4}>
          <Typography style={{ fontSize: "14px", fontWeight: "800" }}>
            Dashboard
          </Typography>
          {/* <Typography style={{ fontSize: "14px", fontWeight: "800" }}>{storedDistributionCode}</Typography> */}
        </Grid>
      </Grid>
      <CommonCard cardData={cardData} handleCardClick={handleCardClick} />
    </>
  );
};
export default MainDashboard;
