import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
   
};


interface DeleteSupplierFormProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
}

const DeleteModal: React.FC<DeleteSupplierFormProps> = ({ onBack }) => {
    const handleBackClick = (event: React.MouseEvent<unknown>) => {
        onBack(event); // Pass the event argument to onBack function
    };
    const [open, setOpen] = React.useState(true);
    const handleClose = () => setOpen(false);


    const handleOk = () => {
        // Add your logic here for handling the "OK" button click
        setOpen(false);
    };
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-description">
                        Are You Sure Want To Delete.
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <Button variant="contained" color="primary" onClick={handleBackClick} sx={{ mr: 2 }}>
                            Cancel
                        </Button>
                        <Button variant="contained" className='btn-colr' onClick={handleOk}>
                            OK
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
export default DeleteModal;