import React, { useEffect, useRef, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import { Grid, Stack, Typography, Button, Checkbox, Card } from "@mui/material";
import SearchBar from "@mkyy/mui-search-bar";
import type { TableColumnsType } from "antd";
import { Spin, Table, Tooltip } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/useFetch";
import addedImage from "../../../../../../assets/images/icons/Check_ring_light.png";

import debounce from "lodash.debounce";
import { useLocation } from "react-router-dom";
import { getAllProductsWithouInvoice, setAddedSalesReturnData } from "../../../../../../Redux/XDM/Sales/salesReturnReducer";


interface withoutInvoiceSchemeData {
  productName: string;
  uom: string;
  productHierarchy: string;
}
interface SalesReturnDataProps {
  type: string;
}
const SalesReturnLeftTable: React.FC<SalesReturnDataProps> = ({ type }) => {
  const [textFieldValue, setTextFieldValue] = React.useState<string>("");
  const tableHeightRef = useRef(410);
  const productCardRef = useRef(419);
  const tableHeightLoad = useRef(410);

  const dispatch = useAppDispatch();
  const [hasMore, setHasMore] = useState(true);

  const [commonLoader, setCommonLoader] = useState(false);
  const [page, setPage] = useState(0);

  const filterTags = useAppSelector((state) => state.filterInv);
  let salesReturnData = useAppSelector((state) => state.salesReturnData);
  const location = useLocation();
  const [filteredData, setFilteredData] = useState<withoutInvoiceSchemeData[]>([]);

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const addedSalesReturnData = useAppSelector(
    (state) => state.salesReturnData?.addedData
  );

  const filterData = {
    segment: filterTags?.segmentSelected.toString() || [],
    brand: filterTags?.brandSelected.toString() || [],
    varient: filterTags?.variantSelected.toString() || [],
    subVarient: filterTags?.subVariantSelected.toString() || [],
    packagingType: filterTags?.packagingTypeSelected.toString() || [],
    sizeElements: filterTags?.sizeSelected.toString() || [],
    page: 0,
    size: 0,
    type: type,
    search: textFieldValue,
  };

  useEffect(() => {
    setPage(0)
    setHasMore(true);
    setFilteredData([]);
    loadMoreData(true);
  }, [type,
    textFieldValue,
    filterTags.segmentSelected,
    filterTags.brandSelected,
    filterTags.variantSelected,
    filterTags.subVariantSelected,
    filterTags.packagingTypeSelected,
    filterTags.sizeSelected,
  ]);

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    updateProductCard();
    updateTableHeightLoad();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;

    dispatch(
      getAllProductsWithouInvoice({
        params: { ...filterData, page: nextPage },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const newOrders = response.payload || [];
          setFilteredData((prevOrders) =>
            resetPage ? newOrders : [...prevOrders, ...newOrders]
          );
          setPage(nextPage + 1);
          if (newOrders.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        } else {
          // setHasMore(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };


  const columns: TableColumnsType<withoutInvoiceSchemeData> = [
    {
      dataIndex: "productName",
      title: "Product Name / Code",
      // width: "45%",

      render: (text: string, record: withoutInvoiceSchemeData) => {
        return (
          <Tooltip placement="top" title={record.productName} zIndex={999999}>
            <b>{record.productName}</b>
            <br />
            <span>{record.productHierarchy}</span>
          </Tooltip>
        );
      },
    },

    {
      dataIndex: "uom",
      title: "UOM",
      align: "center",
    },
    {
      dataIndex: "actions",
      title: "",
      // width: "25%",
      render: (_: any, record: withoutInvoiceSchemeData) => {
        const isAdded = addedSalesReturnData?.some(
          (item: any) => item.productHierarchy === record.productHierarchy
        );
        return (
          <>
            {isAdded ? (
              <Button className="added-btn">
                Added
                <img
                  src={addedImage}
                  width={"12px"}
                  alt="Check_ring_light"
                  style={{ marginLeft: "5px" }}
                />
              </Button>
            ) : (
              <Button
                className="add-to-cart-btn"
                onClick={() => HandleAddButton(record)}
              >
                Add
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const tableClassName = `Header-table saleable-table saleDataTable sale_last_hide man_left_tab Header_chechbox antcheck_manual ${salesReturnData?.allProductsWithoutInvoiceDataLoading ? "nodataloader" : ""
    }`;


  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: any[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: withoutInvoiceSchemeData) => ({
      disabled:
        addedSalesReturnData?.some(
          (item: any) => item.productHierarchy === record.productHierarchy
        )
    }),
  };


  const HandleAddButton = async (record: withoutInvoiceSchemeData) => {
    const addedData = addedSalesReturnData || [];
    const newRequestData = [...addedData, record];
    dispatch(setAddedSalesReturnData(newRequestData));
  };


  const handleMultiAdd = (records: withoutInvoiceSchemeData[]) => {
    const newRequestData = [...addedSalesReturnData, ...records];
    dispatch(setAddedSalesReturnData(newRequestData));
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };




  const updateProductCard = () => {
    const productHeaderHeight = 0;
    const productFooterHeight = 280;
    const availableHeightprdouct =
      window.innerHeight - productHeaderHeight - productFooterHeight;
    productCardRef.current = availableHeightprdouct;
  };
  //
  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 330;

    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };
  const updateTableHeightLoad = () => {
    const headerHeightload = 0;
    const footerHeightload = 300;

    const availableHeightLoad =
      window.innerHeight - headerHeightload - footerHeightload;

    tableHeightLoad.current = availableHeightLoad;
  };

  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const dataToShow: withoutInvoiceSchemeData[] =
    filteredData.length > 0
      ? hasMore
        ? filteredData
        : [...filteredData]
      : [];

  return (
    <Box>
      <Grid container style={{ margin: "4px 0px 12px 0px" }}>
        <Grid item
        // xs={6} sm={6} md={6} lg={6}
        >
          <SearchBar
            className="w-100px man_elip_input"
            placeholder="Search Product Name,Code,Division "
            value={textFieldValue}
            onChange={(newValue) => {
              setTextFieldValue(newValue);
            }}
            onCancelResearch={() => setTextFieldValue("")}
            style={{ height: "26px" }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        pt={1}
        className="pad-top-0px"
        sx={{ height: tableHeightLoad.current }}
        xs={12}
      >
        <div className="saleable-table-scroll sale  mannual_table elipsTable">
          <Table
            className={tableClassName}
            columns={columns}
            dataSource={dataToShow}
            pagination={false}
            scroll={{ y: tableHeightRef.current }}
            loading={salesReturnData?.allProductsWithoutInvoiceDataLoading}
            showSorterTooltip={false}
            summary={() => {
              if (!hasMore && filteredData.length > 0) {
                return (
                  <Table.Summary.Row>
                    <Table.Summary.Cell
                      index={1}
                      colSpan={3}
                      align="center"
                      className="noMore"
                    >
                      No more data found...
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                );
              } else if (page !== 0 && salesReturnData?.allProductsWithoutInvoiceDataLoading) {
                return (
                  <Table.Summary.Row>
                    <Table.Summary.Cell
                      index={1}
                      colSpan={3}
                      align="center"
                    >
                      <Spin />
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                );
              }
            }}
            rowSelection={rowSelection}
            rowKey={(record) => record.productHierarchy}
          />
        </div>
      </Grid>
      <Grid
        container
        pt={2}
        pb={4}
        pl={2}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          bottom: 0,
          position: "relative",
          top: 0,
        }}
        xs={12}

      >
        <Grid item>
          <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
            selected {selectedRowKeys.length}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className="Save_btn_des"
            type="submit"
            sx={{ mr: 2 }}
            onClick={() =>
              handleMultiAdd(selectedRows as withoutInvoiceSchemeData[])
            }
          >
            {" "}
            Add
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SalesReturnLeftTable;
