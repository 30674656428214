import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Grid, Paper, Typography } from "@mui/material";
import SupplierForm from "./SupplierForm";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditSupplierForm from "./EditSupplier";
import ViewSupplierForm from "./ViewSupplier";
import DeleteModal from "../../../components/Modal/DeleteSupplier";
import AddIcon from "@mui/icons-material/Add";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  code: number;
  supplierName: string;
  businessName: string;
  action: string;
}

const Supplier = () => {
  const [showForm, setShowForm] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isViewing, setIsViewing] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);

  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // search function logic
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      sorter: (a, b) => a.supplierName.length - b.supplierName.length,
    },
    {
      title: "Business Name",
      dataIndex: "businessName",
      sorter: (a, b) => a.businessName.length - b.businessName.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
            onClick={(event) => handleViewClick(event)}
          />
          <EditIcon
            style={{
              cursor: "pointer",
              marginLeft: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
            onClick={(event) => handleEditClick(event)}
          />
          <DeleteIcon
            style={{
              cursor: "pointer",
              marginLeft: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
            onClick={(event) => handleDeleteClick(event)}
          />
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      code: 55,
      supplierName: "veena",
      businessName: "Comapny1",
      action: "",
    },
    {
      key: "2",
      code: 55445,
      supplierName: "alpha",
      businessName: "Comapny2",
      action: "",
    },
    {
      key: "3",
      code: 5565,
      supplierName: "hellbey",
      businessName: "Comapny3",
      action: "",
    },
    {
      key: "4",
      code: 5548,
      supplierName: "Supplier4",
      businessName: "Comapny4",
      action: "",
    },
    {
      key: "5",
      code: 55121,
      supplierName: "Supplier5",
      businessName: "Comapny5",
      action: "",
    },
    {
      key: "6",
      code: 5565,
      supplierName: "Supplier6",
      businessName: "Comapny6",
      action: "",
    },
    {
      key: "7",
      code: 55789,
      supplierName: "Supplier7",
      businessName: "Comapny7",
      action: "",
    },
    {
      key: "8",
      code: 55656,
      supplierName: "Supplier8",
      businessName: "Comapny8",
      action: "",
    },
    {
      key: "9",
      code: 55,
      supplierName: "Supplier1",
      businessName: "Comapny1",
      action: "",
    },
    {
      key: "10",
      code: 55445,
      supplierName: "Supplier2",
      businessName: "Comapny2",
      action: "",
    },
    {
      key: "11",
      code: 5565,
      supplierName: "Supplier3",
      businessName: "Comapny3",
      action: "",
    },
    {
      key: "12",
      code: 5548,
      supplierName: "Supplier4",
      businessName: "Comapny4",
      action: "",
    },
    {
      key: "13",
      code: 55121,
      supplierName: "Supplier5",
      businessName: "Comapny5",
      action: "",
    },
    {
      key: "14",
      code: 5565,
      supplierName: "Supplier6",
      businessName: "Comapny6",
      action: "",
    },
    {
      key: "15",
      code: 55789,
      supplierName: "Supplier7",
      businessName: "Comapny7",
      action: "",
    },
    {
      key: "16",
      code: 55656,
      supplierName: "Supplier8",
      businessName: "Comapny8",
      action: "",
    },
  ];

  const handleCreateNew = () => {
    setShowForm(!showForm);
  };

  const handleEditClick = (event: React.MouseEvent<unknown>) => {
    setIsEditing(!isEditing);
  };

  const handleViewClick = (event: React.MouseEvent<unknown>) => {
    setIsViewing(!isViewing);
  };
  const handleDeleteClick = (event: React.MouseEvent<unknown>) => {
    setIsDelete(!isDelete);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className=" mar-tb">
          <Typography>
            <b className="header-lable">Supplier</b>
          </Typography>
        </Grid>

        {showForm ? (
          <SupplierForm onBack={handleCreateNew} />
        ) : isEditing ? (
          <EditSupplierForm onBack={(event) => handleEditClick(event)} />
        ) : isViewing ? (
          <ViewSupplierForm onBack={(event) => handleViewClick(event)} />
        ) : (
          <Box>
            <Button
              variant="contained"
              className="btn-colr"
              onClick={handleCreateNew}
            >
              {" "}
              <AddIcon /> Create new
            </Button>
            <Grid item sx={{ mt: 2 }}>
              <Box>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "end", mb: 2 }}
                >
                  <SearchBar
                    className="search-table"
                    value={textFieldValue}
                    onChange={(newValue) => {
                      setTextFieldValue(newValue);
                      handleSearch();
                    }}
                  />
                </Grid>
                <CommonTable
                  //  rowSelection={rowSelection}
                  data={data}
                  columns={columns}
                />
                {isDelete && (
                  <DeleteModal onBack={(event) => handleDeleteClick(event)} />
                )}
              </Box>
            </Grid>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Supplier;
