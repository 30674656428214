import {
  HSN_CODE_Submit_ERROR,
  HSN_CODE_FETCH_DATA,
  HSN_CODE_EDIT_DATA
} from '../../../actionType'
interface State {
  HSNcodeFetch: [];
  HsnCodeEdit: [];
}


const initialState: State = {
  HSNcodeFetch: [],
  HsnCodeEdit: [],

};


const HsnCodeReducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case HSN_CODE_FETCH_DATA:
      return {
        ...state,
        HSNcodeFetch: action.payload,
      };
      case HSN_CODE_EDIT_DATA:
        return {
          ...state,
          HsnCodeEdit: action.payload,
        };
    case HSN_CODE_Submit_ERROR:
      return {
        ...state,
        HsnCodeToast: action.payload,
      };

    default:
      return state;
  }
};

export default HsnCodeReducer;

