import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  Button,
  Drawer,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import debounce from "lodash.debounce";
import type { TableColumnsType } from "antd";
import { Spin, Table, Tooltip } from "antd";
import moment from "moment";
import { ArrowBackIos } from "@mui/icons-material";
import SearchBar from "@mkyy/mui-search-bar";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useFetch";
import LoadingButton from "@mui/lab/LoadingButton";
import view_line from "../../../../../assets/images/icons/view_line.png";
import Accept_img from "../../../../../assets/images/icons/Accept_img.png";
import Reject_img from "../../../../../assets/images/icons/Reject.png";
import vector_edit from "../../../../../assets/images/icons/Vector_edit.png";
import ImageModal from "./ImageModal";

import RejectReturnSales from "./RejectReturnPopUp";
import {
  getEditSalesReturnData,
  getRejectApprovedList,
  getSalesReturnProductList,
  getSalesReturnReasonList,
  setSeletedData,
} from "../../../../../Redux/XDM/Sales/salesReturnReducer";
import PendingConfirmReview from "./PendingConfirmOrder";
import EditReturnSales from "./EditReturnPopUp";
import InvoiceDetails from "../../InvoiceReviewOrder";

interface RequestData {
  returnId: string;
  outletName: string;
  invoiceNo: string;
  salesmanName: string;
  date: string;
  noOfLineItem: number;
  returnValue: number;
  status: string;
}
interface invoiceRequestData {
  invoiceNumber: string;
  orderId: string;
  date: string;
  outlet: string;
  route: string;
  salesman: string;
  netAmount: number;
  salesmanMobile: string;
}

interface ReviewOrderDetailsProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
  invoiceRequestData: invoiceRequestData;
}
interface ReviewOrderDetails {
  productCode: string;
  productName: string;
  batchCode: string;
  expiryDate: string;
  qty: number;
  mrp: number;
  returnStatus: string;
  approvedQty: string;
  reason: string;
  pc: number;
  value: number;
  img: string[]; // Array of image URLs
  uom: string;
  productReturnNumber: string;
}

export default function PendingSalesReturnReview(
  props: ReviewOrderDetailsProps
) {
  const { onClose, requestData, invoiceRequestData } = props; // Destructure the props
  const dispatch = useAppDispatch();
  const [hasMore, setHasMore] = useState(true);
  const [commonLoader, setCommonLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [isShow, setIsShow] = React.useState(false);
  const [isShowConfirm, setIsShowConfirm] = React.useState(false);
  const [editIsShow, setEditIsShow] = React.useState(false);
  // const [searchTerm, setSearchTerm] = useState<string>("");
  const [acceptedSlno, setAcceptedSlno] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [acceptedReturnNo, setAcceptedReturnNo] = useState<string[]>([]);
  const [openImage, setOpenImage] = React.useState(false);
  const [imageList, setImageList] = React.useState<string[]>([]);
  const [isShowDetails, setIsShowDetails] = React.useState(false);
  const handleImageOpen = (record: ReviewOrderDetails) => {
    // Type the record for clarity
    setImageList(record?.img); // Set the image list from the record
    setOpenImage(true);
  };
  const [dataRecordOrders, setDataRecordOrders] = useState<
    ReviewOrderDetails[]
  >([
    // Add more records as needed
  ]);

  let salesReturnProductData = useAppSelector((state) => state.salesReturnData);

  let RejectedStatusDataList = useAppSelector(
    (state) => state.salesReturnData.salesReturnRejectApprovedData
  );

  let approvedCountData = useAppSelector(
    (state) => state.salesReturnData.editedData
  );

  useEffect(() => {
    if (dataRecordOrders && Array.isArray(RejectedStatusDataList)) {
      // Create a mapping of productReturnNumber to status from RejectedStatusDataList
      const statusMap = RejectedStatusDataList.reduce<Record<string, string>>(
        (map, product) => {
          map[product.productReturnNumber + "status"] = product.returnStatus;
          map[product.productReturnNumber + "qty"] = product?.returnApprovalDetails?.approved?.approvedQty;
          return map;
        },
        {}
      );

      // Update the status in dataRecordOrders based on the productReturnNumber
      const updatedDataRecordOrders = dataRecordOrders.map((order) => ({
        ...order,
        returnStatus: statusMap[order.productReturnNumber + "status"] || order.returnStatus,
        approvedQty: statusMap[order.productReturnNumber + "qty"] || order.approvedQty,
      }));
      console.log("updatedDataRecordOrders", updatedDataRecordOrders);

      setDataRecordOrders(updatedDataRecordOrders);
    }
  }, [RejectedStatusDataList]);

  const tableHeightRef = useRef(160);
  const tableHeightloadRef = useRef(180);

  const filterData = {
    returnId: requestData.returnId,
  };

  useEffect(() => {
    setHasMore(true);
    loadMoreData(true);
  }, []);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 353;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const tableHeightLoad = () => {
    const headerHeight = 0;
    const footerHeoght = 300;
    const availableHeight = window.innerHeight - headerHeight - footerHeoght;
    tableHeightloadRef.current = availableHeight;
  };

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    tableHeightLoad();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;

    dispatch(
      getSalesReturnProductList({
        params: { ...filterData },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const newOrders = response.payload.result || [];
          setDataRecordOrders((prevOrders) =>
            resetPage ? newOrders : [...prevOrders, ...newOrders]
          );
          setPage(nextPage + 1);
          if (newOrders.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };

  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    newSelectedRows: any[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled:
        acceptedReturnNo?.includes(record.productReturnNumber) ||
        ["Confirmed", "Partially Confirmed", "Rejected"].includes(
          record.returnStatus
        ),
    }),
  };

  const handleMultipleReject = () => {
    const productList = selectedRows.map((record) => {
      return {
        productName: record?.productName,
        productReturnNumber: record?.productReturnNumber,
        productCode: record?.productCode,
        batchCode: record?.batchCode,
        expiryDate: record?.expiryDate,
        uom: record?.uom,
        qty: record?.qty,
        mrp: record?.mrp,
        pc: record?.pc,
        value: record?.value,
        reason: record?.reason,
        image: record?.img,
      };
    });

    const requestDataArray = {
      returnId: requestData.returnId, // Ensure this is set appropriately
      productList,
    };

    dispatch(setSeletedData(requestDataArray));

    setIsShow(!isShow);
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  const handleEdit = (record: any) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const editRequest = {
      returnId: requestData.returnId,
      productCode: record.productCode,
      productReturnNo: record.productReturnNumber,
    };
    dispatch(
      getEditSalesReturnData({
        params: { ...editRequest },
        signal: abortController.signal,
      })
    );
    dispatch(getSalesReturnReasonList());

    setEditIsShow(!editIsShow);
  };

  const handleSingleRejectClick = (record: any) => {
    const requestDatas = {
      returnId: requestData.returnId, // Ensure this is set appropriately
      productList: [
        {
          productName: record.productName,
          productReturnNumber: record.productReturnNumber,
          productCode: record.productCode,
          batchCode: record.batchCode,
          expiryDate: record.expiryDate,
          uom: record.uom,
          qty: record.qty,
          mrp: record.mrp,
          pc: record.pc,
          value: record.value,
          reason: record.reason,
          image: record.img,
        },
      ],
    };

    dispatch(setSeletedData(requestDatas));

    setIsShow(!isShow);
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  const handleSingleApprovedClick = async (record: any) => {
    const saveRequestData = {
      returnId: requestData.returnId || "",
      productList: [
        {
          productReturnNumber: record.productReturnNumber || "",
          batchCode: record.batchCode || "",
          approvedValue: record.value || 0,
          approvedQty: record.qty || 0,
          rejectedList: [
            {
              rejectedQty: 0,
              rejectedValue: 0,
              rejectionReason: "",
            },
          ],
        },
      ],
    };

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    try {
      // Dispatch the API call with prepared data
      const response = await dispatch(
        getRejectApprovedList({
          params: saveRequestData,
          signal: abortController.signal,
        })
      );
      if (response?.meta?.requestStatus === "fulfilled") {
        setAcceptedReturnNo((prevReturnNo) => [
          ...prevReturnNo,
          record.productReturnNumber,
        ]);
      }
    } catch (error) {
      console.error("Save error", error);
    }
  };

  const handleMultipleApproved = async () => {
    const selectedReturnNumber = selectedRows.map(
      (row) => row.productReturnNumber
    );

    setSelectedRowKeys([]);
    setSelectedRows([]);

    // Prepare data for API request
    const productDetails = selectedRows.map((item) => {
      return {
        productReturnNumber: item.productReturnNumber || "",
        batchCode: item.batchCode || "",
        approvedValue: item.value || 0,
        approvedQty: item.qty || 0,
        rejectedList: [
          {
            rejectedQty: 0,
            rejectedValue: 0,
            rejectionReason: "",
          },
        ],
      };
    });

    const saveRequestData = {
      returnId: requestData.returnId || "",
      productList: productDetails,
    };

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    try {
      // Dispatch the API call with prepared data
      const response = await dispatch(
        getRejectApprovedList({
          params: saveRequestData,
          signal: abortController.signal,
        })
      );
      if (response?.meta?.requestStatus === "fulfilled") {
        setAcceptedReturnNo((prevReturnNo) =>
          Array.from(new Set([...prevReturnNo, ...selectedReturnNumber]))
        );
      }
    } catch (error) {
      console.error("Save error", error);
    }
  };

  const reviewOrderColumns: TableColumnsType<ReviewOrderDetails> = [
    {
      dataIndex: "productName",
      title: "Product Name",
      width: "12%",
      ellipsis: true,
      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text ? text : "--"}</span>
          </Tooltip>
        );
      },
    },

    {
      dataIndex: "productCode",
      title: "Product Code",
      width: "10%",
      ellipsis: true,
      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text ? text : "--"}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "batchCode",
      title: "Batch Code",
      width: "10%",
      ellipsis: true,
      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text ? text : "--"}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "expiryDate",
      title: "Expiry Date",
      width: "10%",
      align: "center",
      ellipsis: true,
      render: (text: string, record: ReviewOrderDetails) => {
        if (record.expiryDate) {
          const dateObj = moment(record.expiryDate, "YYYY-MM-DD");
          const formattedDate = dateObj.format("DD MMM YYYY");
          return <div>{formattedDate}</div>;
        }
        return "-";
      },
    },
    {
      dataIndex: "qty",
      title: "Quantity",
      // width: "12%",
      align: "center",
    },
    {
      dataIndex: "uom",
      title: "UOM",
      // width: "10%",
      align: "center",
    },

    {
      dataIndex: "mrp",
      title: "MRP / PC",
      // width: "10%",
      align: "right",
      render: (text: number, record: ReviewOrderDetails) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span> ₹{text ? text?.toFixed(2) : 0.0}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "pc",
      title: "PTR / PC",
      // width: "10%",
      align: "right",
      render: (text: string, record: ReviewOrderDetails) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span> ₹ {text ? Number(text).toFixed(2) : "0.00"}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "value",
      title: "Value",
      // width: "15%",
      render: (_: any, record: ReviewOrderDetails) => {
        return (
          <span>₹{record?.value ? record?.value.toFixed(2) : "0.00"}</span>
        );
      },
    },
    {
      dataIndex: "reason",
      title: "Reason",
      align: "center",
      ellipsis: true,
      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text ? text : "--"}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "image",
      title: "Image",
      align: "center",
      render: (_: any, record: ReviewOrderDetails, index: number) => {
        return (
          <Grid
            container
            sx={{
              justifyContent: "center",
              pointerEvents: "auto",
            }}
          >
            {record?.img?.length > 0 ? (
              <Grid item onClick={() => handleImageOpen(record)}>
                <img
                  src={view_line}
                  alt="view-line"
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
            ) : (
              <>--</>
            )}
          </Grid>
        );
      },
    },
    {
      dataIndex: "returnStatus",
      title: "Action",
      align: "center",
      width: "12%",
      render: (_: any, record: ReviewOrderDetails, index: number) => {
        const isSelected = selectedRowKeys.includes(record.productReturnNumber);
        const status = record.returnStatus;
        return (
          <>
            {
              // acceptedReturnNo?.includes(record.productReturnNumber) ? (
              //   <Grid container className="action_edit_grid">
              //     {salesReturnProductData?.salesReturnRejectApprovedDataLoading ? (
              //       <Skeleton variant="rectangular" width="30%" />
              //     ) : (
              //       <Button className="Accepted_button_pen">{"Approved"}</Button>
              //     )}
              //     <Grid
              //       item
              //       className="edit_button_pen"
              //       onClick={() => handleEdit(record)}
              //     >
              //       <img
              //         src={vector_edit}
              //         alt="Vector-edit"
              //         style={{ width: "8px", height: "8px" }}
              //       />
              //     </Grid>
              //   </Grid>
              // ) :
              status === "Confirmed" ? (
                <Grid container className="action_edit_grid">
                  {salesReturnProductData?.salesReturnRejectApprovedDataLoading ? (
                    <Skeleton variant="rectangular" width="30%" />
                  ) : (
                    <Button className="Accepted_button_pen">{"Approved"}</Button>
                  )}
                  <Grid
                    item
                    className="edit_button_pen"
                    onClick={() => handleEdit(record)}
                  >
                    <img
                      src={vector_edit}
                      alt="Vector-edit"
                      style={{ width: "8px", height: "8px" }}
                    />
                  </Grid>
                </Grid>
              ) : status === "Partially Confirmed" ? (
                <Grid container className="action_edit_grid">
                  {salesReturnProductData?.salesReturnRejectApprovedDataLoading ? (
                    <Skeleton variant="rectangular" width="30%" />
                  ) : (
                    <Button className="Partiatially_button_pen">
                      Approved({record.approvedQty})
                    </Button>
                  )}

                  <Grid
                    item
                    className="edit_button_pen"
                    onClick={() => handleEdit(record)}
                  >
                    <img
                      src={vector_edit}
                      alt="Vector-edit"
                      style={{ width: "8px", height: "8px" }}
                    />
                  </Grid>
                </Grid>
              ) : status === "Rejected" ? (
                <Grid container className="action_edit_grid">
                  {salesReturnProductData?.salesReturnRejectApprovedDataLoading ? (
                    <Skeleton variant="rectangular" width="30%" />
                  ) : (
                    <Button className="Rejected_button_pen">{"Rejected"}</Button>
                  )}
                  <Grid
                    item
                    className="edit_button_pen"
                    onClick={() => handleEdit(record)}
                  >
                    <img
                      src={vector_edit}
                      alt="Vector-edit"
                      style={{ width: "8px", height: "8px" }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  sx={{
                    justifyContent: "center",
                    pointerEvents: isSelected ? "none" : "auto",
                    opacity: isSelected ? 0.5 : 1,
                  }}
                >
                  <Grid item onClick={() => handleSingleRejectClick(record)}>
                    <img
                      src={Reject_img}
                      alt="Reject"
                      style={{ width: "23px", height: "23px" }}
                    />
                  </Grid>
                  <Grid
                    item
                    ml={1}
                    onClick={() => handleSingleApprovedClick(record)}
                  >
                    <img
                      src={Accept_img}
                      alt="Accept"
                      style={{ width: "23px", height: "23px" }}
                    />
                  </Grid>
                </Grid>
              )}
          </>
        );
      },
    },
  ];
  const filteredDataToShow = dataRecordOrders.filter(
    (item) =>
      item.productName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.productCode?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.batchCode?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.reason?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const dataToShow: ReviewOrderDetails[] =
    filteredDataToShow.length > 0
      ? hasMore
        ? filteredDataToShow
        : [...filteredDataToShow]
      : [];

  const areAllRowsDisabled = () => {
    return dataRecordOrders.every(
      (record) =>
        acceptedReturnNo.includes(record.productReturnNumber) ||
        ["Confirmed", "Partially Confirmed", "Rejected"].includes(
          record.returnStatus
        )
    );
  };

  const handleAcceptConfirm = (record: any) => {
    setIsShowConfirm(true);
  };

  const handleInvoiceClick = () => {
    setIsShowDetails(!isShowDetails);
  };
  const handleSearch = () => {
    setSearchTerm(searchTerm);
  };

  const dateObj = moment(requestData?.date, "YYYY-MM-DD");

  const formattedDate = dateObj.format("DD MMM YYYY");
  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer anchor="right" open={true} className="drawer_outlet w-100px">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            // mt: 2,
          }}
        >
          <Grid item sx={{ width: "100%" }}>
            <Grid
              container
              sx={{ borderBottom: "1px solid #0000000D", padding: "12px 24px" }}
            >
              <Grid
                item
                sm={0.3}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ArrowBackIos
                  sx={{
                    fontSize: "10px",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    onClose();
                  }}
                />
              </Grid>
              <Grid
                item
                xs={6.7}
                sm={7.7}
                md={8.7}
                lg={8.7}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    color: "#000000",
                    fontFamily: "Montserrat",
                    fontSize: "13px",
                  }}
                >
                  Return ID - {requestData.returnId}
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                sm={4}
                md={3}
                lg={3}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <SearchBar
                  className="search_box_pending w-100px"
                  placeholder="Search Product Name, Code...."
                  value={searchTerm}
                  onChange={(newValue) => {
                    setSearchTerm(newValue);
                  }}
                  onCancelResearch={() => setSearchTerm("")}
                  onSearch={(newValue) => {
                    handleSearch();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form saleable-products-box"
          >
            {" "}
            <Grid
              item
              sx={{
                width: "100%",
                margin: "auto",
                marginBottom: "90px",
                padding: "0px 24px",
              }}
            >
              <Grid container pl={3.6} mt={2}>
                <Grid item sm={2.7}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Invoice No
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography
                      className={`${requestData?.invoiceNo ? 'InvoiceNo_style' : ''}`}
                      sx={{
                        // fontWeight: "700",
                        // color: "#000000",
                        // fontFamily: "Montserrat",
                        // fontSize: "13px",
                        cursor: requestData.invoiceNo ? "pointer" : "default",
                      }}
                      onClick={requestData?.invoiceNo ? handleInvoiceClick : () => {}}
                    >
                      {requestData?.invoiceNo ? requestData?.invoiceNo : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={2.7}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Outlet Name
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {requestData?.outletName ? requestData?.outletName : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={2.7}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Salesman Name
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {requestData?.salesmanName ? requestData?.salesmanName : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={2.7}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Date
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {formattedDate ? formattedDate : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="pad-top-0px"
                  sx={{ height: tableHeightloadRef.current }}
                >
                  <div className="saleable-table-scroll">
                    <Table
                      className={`Header-table saleable-table sale_last_hide scroll_cus_table antcheck_manual Header_chechbox pending_grn_table ${salesReturnProductData.salesReturnProductListLoading
                        ? " nodataloader"
                        : ""
                        }`}
                      columns={reviewOrderColumns}
                      dataSource={dataToShow}
                      pagination={false}
                      scroll={{ y: tableHeightRef.current }}
                      loading={
                        salesReturnProductData?.salesReturnProductListLoading
                      }
                      showSorterTooltip={false}
                      summary={() => {
                        if (!hasMore && dataRecordOrders.length > 0) {
                          return (
                            <Table.Summary.Row>
                              <Table.Summary.Cell
                                index={1}
                                colSpan={areAllRowsDisabled() ? 12 : 13}
                                align="center"
                                className="noMore"
                              >
                                No more data found...
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        } else if (
                          page !== 0 &&
                          salesReturnProductData.salesReturnProductListLoading
                        ) {
                          return (
                            <Table.Summary.Row>
                              <Table.Summary.Cell
                                index={1}
                                colSpan={areAllRowsDisabled() ? 12 : 13}
                                align="center"
                              >
                                <Spin />
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        }
                      }}
                      // Conditionally render rowSelection based on areAllRowsDisabled
                      rowSelection={
                        !areAllRowsDisabled() ? rowSelection : undefined
                      }
                      rowKey={(record) => record.productReturnNumber}
                    />
                  </div>
                </Grid>
              </Grid>
              <hr className="hr-revieworder"></hr>

              {areAllRowsDisabled() ? (
                <Grid container>
                  <Grid item sm={9}></Grid>
                  <Grid
                    item
                    sm={3}
                    mt={1.5}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <Typography
                      className="Pending_review_Sublabel"
                      sx={{ fontWeight: 1000 }}
                      style={{ fontSize: "12px" }}
                    >
                      Return value :₹{" "}
                      {requestData?.returnValue
                        ? requestData?.returnValue.toFixed(2)
                        : "0.00"}
                    </Typography>

                    <Button
                      className="Save_btn_des"
                      sx={{
                        height: "34px",
                        width: { sm: "11%", md: "8%", lg: "6%" },
                      }}
                      onClick={handleAcceptConfirm}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  mt={1.5}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    className={
                      selectedRowKeys.length === 0 ||
                        selectedRows.every((row) =>
                          acceptedReturnNo.includes(row.productReturnNumber)
                        )
                        ? "disabled_btn_discrepancy"
                        : "reject_btn_discrepancy"
                    }
                    sx={{
                      height: "34px",
                      width: { sm: "15%", md: "13%", lg: "10%" },
                    }}
                    onClick={handleMultipleReject}
                    disabled={
                      selectedRowKeys.length === 0 ||
                      selectedRows.every((row) =>
                        acceptedReturnNo.includes(row.productReturnNumber)
                      )
                    }
                  >
                    Reject
                  </Button>
                  <Button
                    className={
                      selectedRowKeys.length === 0 ||
                        selectedRows.every((row) =>
                          acceptedReturnNo.includes(row.productReturnNumber)
                        )
                        ? "disabled_btn_discrepancy"
                        : "pending_btn_accept"
                    }
                    sx={{
                      height: "34px",
                      width: { sm: "15%", md: "11%", lg: "9%" },
                    }}
                    disabled={
                      selectedRowKeys.length === 0 ||
                      selectedRows.every((row) =>
                        acceptedReturnNo.includes(row.productReturnNumber)
                      )
                    }
                    onClick={handleMultipleApproved}
                  >
                    Approve
                  </Button>
                  <Button
                    className="disabled_btn_discrepancy"
                    sx={{
                      height: "34px",
                      width: { sm: "15%", md: "11%", lg: "9%" },
                      mr: 2,
                    }}
                    disabled={true}
                  >
                    Next
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Drawer>
      {isShow ? (
        <RejectReturnSales
          onClose={() => setIsShow(false)}
          requestData={requestData}
        />
      ) : (
        <></>
      )}
      {isShowConfirm ? (
        <PendingConfirmReview
          onClose={() => setIsShowConfirm(false)}
          requestData={requestData}
          invoiceRequestData={invoiceRequestData}
        />
      ) : (
        <></>
      )}
      {openImage ? (
        <ImageModal
          open={openImage}
          handleImageClose={() => setOpenImage(false)}
          imageList={imageList}
        />
      ) : (
        <></>
      )}
      {editIsShow ? (
        <EditReturnSales
          onClose={() => setEditIsShow(false)}
          requestData={requestData}
        />
      ) : (
        <></>
      )}
      {isShowDetails && (
        <InvoiceDetails
          onClose={() => setIsShowDetails(false)}
          requestData={invoiceRequestData}
        />
      )}
    </Box>
  );
}