import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Drawer,
  Grid,
  Card,
  Typography,
  InputBase,
  FormControl,
  DialogActions,
  DialogContentText,
  Dialog,
  DialogContent,
  Snackbar,
  Alert,
} from "@mui/material";
import { Select } from "antd";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../hooks/useFetch";
import { mobilePlatformReasonList } from "../../../../../../Redux/XDM/Sales/salesReturnReducer";
import Close from "../../../../../../assets/images/icons/Close_icon.png";
import remove_line from "../../../../../../assets/images/icons/remove_line.png";
import upload_file from "../../../../../../assets/images/icons/upload-file.png";
import Close_round_fill from "../../../../../../assets/images/icons/Close_round_fill.png";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDropzone } from "react-dropzone";
import WithInvoiceConfirmReview from "./WithConfirmOrder";
import { IoClose } from "react-icons/io5";
import dayjs from "dayjs";

interface ReviewOrderDetailsProps {
  onClose: () => void; // Define the onClose prop
  requestData: any;
  invoiceRequestData: any;
  onRequestDataChange: (data: any) => void;
  requestDataInvoice: any;
}

interface ImageFile extends File {
  preview: string;
}

interface ImageUploadProps {
  index: number;
  id: string;
  setCardStates: React.Dispatch<
    React.SetStateAction<Record<number, CardState>>
  >;
}
interface CardState {
  selectedSalesmanIds: string[];
  showSelectReason: boolean;
  returnQty: number;
  batchCode: string;
  reasonDetails: Record<
    string,
    {
      type: string;
      reasonName: string;
      qty: string;
      expiryDate: Date | null;
      images: ImageFile[];
      batchCode: string;
    }
  >;
  returnQtyError?: string;
  selectReasonError?: string;
  returnQtyCompareError?: string;
  returnMatchQtyError?: string;
  expiryDateError?: string; // New field for expiry date error
}

const ImageUploadComponent: React.FC<ImageUploadProps> = ({
  index,
  id,
  setCardStates,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning"
  >("success");
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setCardStates((prevState: any) => {
        const existingCardState = prevState[index] || {
          selectedSalesmanIds: [],
          reasonDetails: {},
          returnQty: 0,
          showSelectReason: false,
        };
        const currentImages = existingCardState.reasonDetails[id]?.images || [];

        // Check if the total number of images exceeds the limit of 5
        if (currentImages.length + acceptedFiles.length > 5) {
          setSnackbarMessage("You can only upload a maximum of 5 images.");
          setSnackbarSeverity("warning");
          setSnackbarOpen(true);
          setTimeout(() => {
            setSnackbarOpen(false);
          }, 3000);
          return prevState; // Return the current state without updating
        }

        const newImages = acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );

        const updatedImages = [...currentImages, ...newImages];

        return {
          ...prevState,
          [index]: {
            ...existingCardState,
            reasonDetails: {
              ...existingCardState.reasonDetails,
              [id]: {
                ...existingCardState.reasonDetails[id],
                images: updatedImages,
              },
            },
          },
        };
      });
    },
    [index, setCardStates, id]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    onDrop,
    onDropRejected: () => {
      alert("Only image files are accepted!");
    },
  });

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={100000}
        color="green"
        className="new-coll-alert"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Box
        {...getRootProps()}
        sx={{
          border: "1px solid #DCDCDE",
          borderRadius: "9px",
          p: 2,
          bgcolor: isDragActive ? "action.hover" : "background.paper",
        }}
      >
        <input {...getInputProps()} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={upload_file} alt="upload-file" width={30} />
        </div>

        <Typography
          variant="body2"
          className="textcolorupload"
          sx={{
            fontStyle: "Montserrat",
            fontSize: "9px",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          Add image
        </Typography>
      </Box>
    </>
  );
};

const { Option } = Select;

export default function InvoiceProductPopup(props: ReviewOrderDetailsProps) {
  let {
    onClose,
    requestData,
    invoiceRequestData,
    onRequestDataChange,
    requestDataInvoice,
  } = props; // Destructure the props
  const dispatch = useAppDispatch();
  const [returnTotal, setReturnTotal] = useState(0);
  const [cardStates, setCardStates] = useState<Record<number, CardState>>({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [requestTableData, setRequestTableData] =
    useState<any>(invoiceRequestData);

  let salesreturnData = useAppSelector((state) => state.salesReturnData);
  const [batchCode, setBatchCode] = useState<string>("");
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const addReasonList = salesreturnData.reasonList;

  useEffect(() => {
    dispatch(mobilePlatformReasonList());
  }, [dispatch]);
  useEffect(() => {
    if (requestData) {
      const initialCardStates = requestData.reduce(
        (acc: any, item: any, index: number) => {
          acc[index] = {
            batchCode: item.batchCode,
            showSelectReason: false,
            returnQty: 0,
            reasonDetails: { batchCode: item.batchCode },
            selectedSalesmanIds: [],
          };
          return acc;
        },
        {} as Record<number, CardState>
      );
      setCardStates(initialCardStates);
    }
  }, []);

  const handleNextClick = () => {
    const updatedReturnDetails = Object.values(cardStates).map((data: any) => {
      const reasonDetailsArray = Object.values(data.reasonDetails).map(
        (reason: any) => {
          const matchingRequestData = requestData.find(
            (request: any) => request.batchCode === reason.batchCode
          );

          if (matchingRequestData) {
            // If there's only one reasonDetail, set qty to returnQty
            if (Object.values(data.reasonDetails).length === 1) {
              reason.qty = data.returnQty;
            }

            return {
              type: reason.type,
              reasonName: reason.reasonName,
              qty: reason.qty,
              expiryDate: reason.expiryDate,
              images: reason.images,
              batchCode: reason.batchCode,
              productCode: matchingRequestData.productCode,
              productName: matchingRequestData.productName,
              quantity: matchingRequestData.quantity,
              uom: matchingRequestData.uom,
              mrp: matchingRequestData.mrp,
              netAmount: matchingRequestData.netAmount,
            };
          }

          return null;
        }
      );

      return reasonDetailsArray.filter((detail: any) => detail !== null);
    });

    setRequestTableData((prev: any) => ({
      ...prev,
      returnDetails: updatedReturnDetails.flat(),
    }));

    setOpenConfirm(true);
  };

  useEffect(() => {
    const total = Object.keys(cardStates).reduce((sum, key) => {
      const index = parseInt(key, 10);
      const item = requestData[index];
      if (item && cardStates[index]) {
        const discrepancyValue = calculateReturnValue(
          item.netAmount,
          item.quantity,
          cardStates[index]?.returnQty || 0
        );
        const value = parseFloat(discrepancyValue.toFixed(2)); // Convert the string back to a number
        return sum + value;
      }
      return sum;
    }, 0);

    setReturnTotal(total);
  }, [cardStates]);

  const handleReturnQuantity = (
    index: number,
    value: string,
    invQty: number
  ) => {
    if (!value) {
      // Reset the data to default values if value is empty
      setCardStates((prevState) => ({
        ...prevState,
        [index]: {
          selectedSalesmanIds: [],
          reasonDetails: {},
          returnQty: 0,
          batchCode: requestData[index].batchCode,
          showSelectReason: false,
        },
      }));
      return; // Exit early as no further processing is needed
    }

    const qty = parseInt(value, 10);
    let returnQtyError = "";
    let returnQtyCompareError = "";

    if (!qty || qty <= 0) {
      returnQtyError = "Please enter return Quantity";
    } else if (qty > invQty) {
      returnQtyCompareError = `Return quantity cannot be greater than quantity.`;
    }

    setCardStates((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        showSelectReason: true,
        reasonDetails: { batchCode: requestData[index].batchCode },
        returnQty: qty,
        returnQtyError,
        batchCode: requestData[index].batchCode,
        returnQtyCompareError,
      },
    }));
  };

  const handleReasonSelect = (index: number, value: string[]) => {
    setCardStates((prevState: any) => {
      const existingCardState = prevState[index] || {
        selectedSalesmanIds: [],
        reasonDetails: {},
        returnQty: 0,
        showSelectReason: false,
      };

      const reasonDetails = existingCardState.reasonDetails || {};
      const returnQtyValue = existingCardState.returnQty;

      const newReasonDetails = value.reduce((acc, id) => {
        const reason = addReasonList.find((r: any) => r.id === id);
        if (reason) {
          acc[id] = {
            type: reason.type || "",
            reasonName: reason.returnReason || "",
            qty: reasonDetails[id]?.qty || "",
            expiryDate: reasonDetails[id]?.expiryDate || null,
            images: reasonDetails[id]?.images || [],
            batchCode: requestData[index].batchCode,
          };
        }
        return acc;
      }, {} as Record<string, { type: string; reasonName: string; qty: string; expiryDate: Date | null; images: []; batchCode: string }>);

      let selectReasonError = "";
      if (value.length === 0) {
        selectReasonError = "Please select a reason";
      }

      return {
        ...prevState,
        [index]: {
          ...existingCardState,
          selectedSalesmanIds: value,
          reasonDetails: newReasonDetails,
          selectReasonError,
        },
      };
    });
  };

  const handleReasonQtyChange = (
    index: number,
    reasonId: string,
    value: string
  ) => {
    const qty = parseInt(value, 10) || 0;

    setCardStates((prevState) => {
      const existingCardState = prevState[index] || {
        selectedSalesmanIds: [],
        reasonDetails: {},
        returnQty: 0,
        showSelectReason: false,
      };
      const updatedReasonDetails = {
        ...existingCardState.reasonDetails,
        [reasonId]: {
          ...existingCardState.reasonDetails[reasonId],
          qty: qty.toString(),
          batchCode: requestData[index].batchCode,
        },
      };

      const totalReasonQty = Object.values(updatedReasonDetails).reduce(
        (acc, { qty }) => acc + parseInt(qty || "0", 10),
        0
      );

      let returnMatchQtyError = "";
      if (totalReasonQty !== existingCardState.returnQty) {
        returnMatchQtyError =
          "Please enter the quantities to match the return quantity.";
      }

      return {
        ...prevState,
        [index]: {
          ...existingCardState,
          reasonDetails: updatedReasonDetails,
          returnMatchQtyError,
        },
      };
    });
  };

  const handleExpiryDateChange = (
    index: number,
    reasonId: string,
    date: any | null
  ) => {
    setCardStates((prevState) => {
      const existingCardState = prevState[index] || {
        selectedSalesmanIds: [],
        reasonDetails: {},
        returnQty: 0,
        showSelectReason: false,
      };

      const updatedReasonDetails = {
        ...existingCardState.reasonDetails,
        [reasonId]: {
          ...existingCardState.reasonDetails[reasonId],
          expiryDate: date,
        },
      };

      let expiryDateError = "";
      if (!date) {
        expiryDateError = "Please select an expiry date.";
      }

      return {
        ...prevState,
        [index]: {
          ...existingCardState,
          reasonDetails: updatedReasonDetails,
          expiryDateError,
        },
      };
    });
  };

  const calculateReturnValue = (
    netAmt: number,
    invQty: number,
    returnQty: number
  ) => {
    const roundedDisAmt = (netAmt / invQty) * returnQty;
    return roundedDisAmt;
  };

  const validateReasonDetails = () => {
    if (Object.keys(cardStates).length === 0) {
      return false;
    }

    if (requestData.length !== Object.keys(cardStates).length) {
      return false;
    }

    return Object.values(cardStates).every((cardState) => {
      if (
        cardState.returnQtyError ||
        cardState.selectReasonError ||
        cardState.returnQtyCompareError ||
        cardState.returnMatchQtyError ||
        cardState.expiryDateError
      ) {
        return false;
      }

      if (!cardState.returnQty) {
        return false;
      }

      const reasonDetails = cardState.reasonDetails || {};
      const reasonDetailsValues = Object.values(reasonDetails);

      // If there's only one reasonDetail item, skip checking qty
      if (reasonDetailsValues.length === 1) {
        return reasonDetailsValues.every(
          (reasonDetail) =>
            reasonDetail?.type &&
            reasonDetail?.reasonName &&
            reasonDetail?.expiryDate
        );
      }

      // If there are multiple reasonDetails, include qty in validation
      return reasonDetailsValues.every(
        (reasonDetail) =>
          reasonDetail?.type &&
          reasonDetail?.reasonName &&
          reasonDetail?.expiryDate &&
          reasonDetail?.qty
      );
    });
  };

  const handleDeleteClick = (index: number, batchCode: string) => {
    setDeleteIndex(index);
    setBatchCode(batchCode);
  };

  const handleDeleteConfirm = () => {
    // Filter out the deleted item from requestData
    const updatedData = requestData.filter(
      (item: any) => item.batchCode !== batchCode
    );

    setCardStates((prevState) => {
      const filteredStatesArray = Object.values(prevState).filter(
        (state) => state.batchCode !== batchCode
      );

      const newCardStates = filteredStatesArray.reduce((acc, state, index) => {
        acc[index] = state;
        return acc;
      }, {} as Record<number, CardState>);

      return newCardStates;
    });

    // Call the callback with updatedData if provided
    if (onRequestDataChange) {
      onRequestDataChange(updatedData);
    }

    // Reset deleteIndex
    setDeleteIndex(null);
  };

  if (requestData.length === 0) {
    onClose();
  }

  const handleRemoveImage = (imageIndex: number, index: number, id: string) => {
    setCardStates((prevState: any) => {
      const existingCardState = prevState[index];
      const currentImages = existingCardState.reasonDetails[id]?.images || [];

      const updatedImages = currentImages.filter(
        (_: any, i: number) => i !== imageIndex
      );

      const updatedReasonDetails = {
        ...existingCardState.reasonDetails[id],
        images: updatedImages,
      };

      let result = {
        ...prevState,
        [index]: {
          ...existingCardState,
          reasonDetails: {
            ...existingCardState.reasonDetails,
            [id]: updatedReasonDetails,
          },
        },
      };

      return result;
    });
  };

  const handleDeleteCancel = () => {
    setDeleteIndex(null);
  };

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer anchor="right" open={true} className="drawer_stl">
        <Box
          sx={{
            width: {
              lg: 500,
              md: 460,
              sm: 460,
              xs: 460,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
            backgroundColor: "#F6F6F6",
          }}
        >
          <Grid item sx={{ height: "100%" }}>
            <Grid
              container
              sx={{
                position: "sticky",
                top: 0,
                // zIndex: 1,
                background: "white",
                height: "60px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
              }}
            >
              <Grid item xs={7}>
                <Typography
                  sx={{
                    fontWeight: "700",
                    color: "#000000",
                    fontFamily: "Montserrat",
                    fontSize: "13px",
                  }}
                >
                  Sales Return ({requestData.length})
                </Typography>
              </Grid>
              <Grid item xs={5} sx={{ display: "flex", justifyContent: "end" }}>
                <Button onClick={onClose} className="btn_close_grn">
                  <img
                    src={Close}
                    alt="Close"
                    style={{ width: "20px", height: "20px" }}
                  />
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                overflowY: "auto",
                height: "67%",
                padding: "20px",
                position: "sticky",
              }}
              className="discrepancy_card"
            >
              {requestData.length !== 0 ? (
                <>
                  {requestData?.map((item: any, index: number) => (
                    <Card
                      key={index}
                      sx={{
                        border: "1px solid #DCDCDE",
                        padding: "15px",
                        borderRadius: "6px",
                        marginBottom: "10px",
                      }}
                    >
                      <Grid container sx={{ alignItems: "center" }}>
                        <Grid item sm={10}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "12px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            {item.productName} ({item.productCode})
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sm={2}
                          sx={{ display: "flex", justifyContent: "end" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteClick(index, item.batchCode);
                          }}
                        >
                          <img
                            src={remove_line}
                            alt="remove_line"
                            style={{
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container mt={2}>
                        <Grid item sm={2.5}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            UOM : {item.uom ? item.uom : "-"}{" "}
                          </Typography>
                        </Grid>
                        <Grid item sm={2.5}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            Quantity : {item.quantity}
                          </Typography>
                        </Grid>
                        <Grid item sm={3}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            MRP / PC : ₹ {item.mrp}
                          </Typography>
                        </Grid>
                        <Grid item sm={3.6}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            Net Amount : ₹
                            {item.netAmount
                              ? Number(item.netAmount).toFixed(2)
                              : "0.00"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} mt={2}>
                        <Grid item mb={1.5}>
                          <Typography
                            sx={{
                              color: "#44464C",
                              fontSize: "12px",
                              fontFamily: "Montserrat",
                              fontWeight: 500,
                            }}
                          >
                            Enter Return Quantity
                            <span style={{ color: "red" }}>&nbsp;*</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} mt={1}>
                          <InputBase
                            sx={{
                              border: "1px solid #DCDCDE",
                              width: "100%",
                              height: "38px",
                              paddingLeft: "10px",
                              color: "#505259",
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: 500,
                              borderRadius: "4px",
                            }}
                            inputProps={{
                              maxLength: 10,
                            }}
                            value={cardStates[index]?.returnQty}
                            onInput={(e) => {
                              const target = e.target as HTMLInputElement;
                              target.value = target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*\.?\d*$/.test(value)) {
                                handleReturnQuantity(
                                  index,
                                  value,
                                  item.quantity
                                );
                              }
                            }}
                          />
                          {cardStates[index]?.returnQtyError && (
                            <Typography color="error" variant="caption">
                              {cardStates[index].returnQtyError}
                            </Typography>
                          )}
                          {cardStates[index]?.returnQtyCompareError && (
                            <Typography color="error" variant="caption">
                              {cardStates[index].returnQtyCompareError}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      {cardStates[index]?.showSelectReason && (
                        <Grid item>
                          <Grid item mt={2}>
                            <Typography
                              sx={{
                                color: "#44464C",
                                fontFamily: "Montserrat",
                                fontSize: "12px",
                                fontWeight: 500,
                              }}
                            >
                              Select Reason
                              <span style={{ color: "red" }}>&nbsp;*</span>
                            </Typography>
                          </Grid>
                          <Grid item>
                            <FormControl fullWidth sx={{ marginBottom: "2px" }}>
                              <Select
                                className="Quentity_select_box"
                                mode="multiple"
                                showSearch
                                value={
                                  cardStates[index]?.selectedSalesmanIds || []
                                }
                                placeholder="Add Reason"
                                filterOption={(input: any, option: any) => {
                                  const optionText =
                                    option?.children
                                      ?.toString()
                                      .toLowerCase() || "";
                                  return optionText.includes(
                                    input.toLowerCase()
                                  );
                                }}
                                dropdownStyle={{ zIndex: 2000 }}
                                onChange={(value) =>
                                  handleReasonSelect(index, value)
                                }
                                style={{ width: "100%", fontSize: "12px" }}
                              >
                                {addReasonList?.map((reason: any) => (
                                  <Option key={reason.id} value={reason.id}>
                                    <div style={{ fontSize: "11px" }}>
                                      {reason.returnReason}
                                    </div>
                                  </Option>
                                ))}
                              </Select>
                            </FormControl>
                            {cardStates[index]?.selectReasonError && (
                              <Typography color="error" variant="caption">
                                {cardStates[index].selectReasonError}
                              </Typography>
                            )}
                          </Grid>

                          {cardStates[index]?.selectedSalesmanIds?.map(
                            (id, i) => {
                              const selectedSalesman = addReasonList?.find(
                                (reason: any) => reason.id === id
                              );

                              const isSingleSelected =
                                cardStates[index]?.selectedSalesmanIds
                                  ?.length === 1;

                              return (
                                <>
                                  {!isSingleSelected && (
                                    <Grid
                                      container
                                      mt={1}
                                      key={`quantity-${i}`}
                                    >
                                      <Grid item>
                                        <Typography
                                          sx={{
                                            color: "#44464C",
                                            fontFamily: "Montserrat",
                                            fontSize: "12px",
                                            fontWeight: 500,
                                          }}
                                        >
                                          Enter {selectedSalesman?.returnReason}{" "}
                                          Quantity
                                          <span style={{ color: "red" }}>
                                            &nbsp;*
                                          </span>
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} mt={1}>
                                        <InputBase
                                          value={
                                            cardStates[index]?.reasonDetails[id]
                                              ?.qty || ""
                                          }
                                          sx={{
                                            border: "1px solid #DCDCDE",
                                            width: "100%",
                                            height: "38px",
                                            paddingLeft: "10px",
                                            color: "#505259",
                                            fontSize: "10px",
                                            fontFamily: "Montserrat",
                                            fontWeight: 500,
                                            borderRadius: "4px",
                                          }}
                                          inputProps={{
                                            maxLength: 10,
                                          }}
                                          onInput={(e) => {
                                            const target =
                                              e.target as HTMLInputElement;
                                            target.value = target.value.replace(
                                              /[^0-9.]/g,
                                              ""
                                            );
                                          }}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*\.?\d*$/.test(value)) {
                                              handleReasonQtyChange(
                                                index,
                                                id,
                                                value
                                              );
                                            }
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  )}
                                  <Grid container mt={1} key={`expiry-${i}`}>
                                    <Grid item>
                                      <Typography
                                        sx={{
                                          color: "#44464C",
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        Select Expiry Date{" "}
                                        {selectedSalesman?.returnReason
                                          ? `for ${selectedSalesman?.returnReason}`
                                          : ""}
                                        <span style={{ color: "red" }}>
                                          &nbsp;*
                                        </span>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} mt={1}>
                                      <FormControl fullWidth>
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <DatePicker
                                            sx={{
                                              fontSize: "12px",
                                            }}
                                            value={
                                              cardStates[index]?.reasonDetails[
                                                id
                                              ]?.expiryDate
                                                ? dayjs(
                                                  cardStates[index]
                                                    .reasonDetails[id]
                                                    .expiryDate
                                                )
                                                : null
                                            }
                                            onChange={(date) =>
                                              handleExpiryDateChange(
                                                index,
                                                id,
                                                date
                                              )
                                            }
                                          // maxDate={dayjs()}
                                          />

                                          {cardStates[index]
                                            ?.expiryDateError && (
                                              <Typography
                                                color="error"
                                                variant="caption"
                                              >
                                                {
                                                  cardStates[index]
                                                    .expiryDateError
                                                }
                                              </Typography>
                                            )}
                                        </LocalizationProvider>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                  <Grid container mt={1} key={`image-${i}`}>
                                    <Grid item>
                                      <Typography
                                        sx={{
                                          color: "#44464C",
                                          fontFamily: "Montserrat",
                                          fontSize: "12px",
                                          fontWeight: 500,
                                        }}
                                      >
                                        Upload Image{" "}
                                        {selectedSalesman?.returnReason
                                          ? `for ${selectedSalesman?.returnReason}`
                                          : ""}
                                        (Optional)
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      mt={1}
                                      gap={1}
                                      sx={{ display: "flex" }}
                                    >
                                      <Grid item xs={2.7}>
                                        <ImageUploadComponent
                                          index={index}
                                          id={id}
                                          setCardStates={setCardStates}
                                        />
                                      </Grid>
                                      <Grid item xs={8.3}>
                                        <Grid
                                          item
                                          sx={{
                                            display: "flex",
                                            overflowX: "auto",
                                            whiteSpace: "nowrap",
                                            scrollbarWidth: "thin", // For Firefox, sets a thin scrollbar
                                            msOverflowStyle: "none", // Removes scroll arrows for IE and Edge
                                          }}
                                          className="image_container onwheel_scroll"
                                          onWheel={(e) => {
                                            // Prevents default behavior and allows horizontal scrolling with the mouse wheel
                                            if (e.deltaY !== 0) {
                                              e.preventDefault();
                                              e.currentTarget.scrollLeft += e.deltaY; // Scrolls horizontally based on vertical scroll
                                            }
                                          }}
                                        >
                                          {cardStates[index]?.reasonDetails[id]?.images?.map(
                                            (thumbnail: any, imageIndex: number) => (
                                              <Grid
                                                className="image_thumbnail"
                                                key={imageIndex}
                                                item
                                                mr={1}
                                                position="relative"
                                              >
                                                <Grid
                                                  sx={{
                                                    position: "absolute",
                                                    top: "-3px",
                                                    right: "-6px",
                                                    zIndex: 2,
                                                  }}
                                                  onClick={() => handleRemoveImage(imageIndex, index, id)}
                                                >
                                                  <img
                                                    src={Close_round_fill}
                                                    alt="close"
                                                    className="close-icon"
                                                  />
                                                </Grid>
                                                <img
                                                  src={thumbnail.preview}
                                                  alt={`Thumbnail ${imageIndex + 1}`}
                                                  className="image_thumbnail_img"
                                                  style={{
                                                    borderRadius: "4px",
                                                    objectFit: "cover",
                                                  }}
                                                />
                                              </Grid>
                                            )
                                          )}
                                        </Grid>
                                      </Grid>

                                    </Grid>
                                  </Grid>
                                </>
                              );
                            }
                          )}

                          {cardStates[index]?.returnMatchQtyError && (
                            <Typography color="error" variant="caption">
                              {cardStates[index].returnMatchQtyError}
                            </Typography>
                          )}
                        </Grid>
                      )}
                      <Grid container mt={1}>
                        <Grid item sm={4}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "11px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            Return Quantity :{" "}
                            {cardStates[index]?.returnQty || 0}
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "11px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            Sell Price : ₹{" "}
                            {item.sellRate ? item.sellRate : "0.00"}
                          </Typography>
                        </Grid>
                        <Grid item sm={4}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "11px",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                          >
                            Return Value : ₹{" "}
                            {calculateReturnValue(
                              item.netAmount,
                              item.quantity,
                              cardStates[index]?.returnQty || 0
                            ).toFixed(2)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
                </>
              ) : (
                <></>
              )}
            </Grid>
            <Grid
              container
              sx={{
                position: "sticky",
                bottom: 50,
                height: "60px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
                background: "white",
              }}
            >
              <Grid item xs={6}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "12px",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                  }}
                >
                  Total Return Value : ₹{" "}
                  {returnTotal ? returnTotal.toFixed(2) : "0.00"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  className="cancel-to-cart-btn"
                  type="submit"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  className="next_btn_inv"
                  type="submit"
                  onClick={handleNextClick}
                  disabled={!validateReasonDetails()}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
      <Dialog
        open={deleteIndex !== null}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: "333px", padding: "12px 16px" }}>
          <Box
            className="close_icon_btn cursor_pointer"
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
            onClick={handleDeleteCancel}
          >
            <IoClose className="ClosePop" />
          </Box>
          <DialogContentText
            className="dialog-title-remove"
            id="alert-dialog-description"
            style={{ paddingBottom: "10px" }}
          >
            Are you sure you want to remove?
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "black", fontSize: "12px", marginTop: "4px" }}
          >
            Note:The following{" "}
            <span className="dialog-title-remove" style={{ fontSize: "12px" }}>
              Product BatchCode: {batchCode}{" "}
            </span>
            will be removed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="crt-btn-cancel"
            style={{
              height: "30px",
              width: "25%",
              marginRight: "3px",
              fontSize: "12px",
            }}
            onClick={handleDeleteCancel}
          >
            {" "}
            Cancel
          </Button>
          <Button
            variant="contained"
            className="crt-btn-colr"
            style={{ height: "30px", width: "25%", fontSize: "12px" }}
            type="submit"
            sx={{ mr: 2 }}
            onClick={handleDeleteConfirm}
          >
            {" "}
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      {openConfirm && (
        <WithInvoiceConfirmReview
          onClose={() => setOpenConfirm(false)}
          requestData={requestTableData}
          invoiceRequestData={invoiceRequestData}
          requestDataInvoice={requestDataInvoice}
        />
      )}
    </Box>
  );
}
