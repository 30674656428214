import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createAxiosInstance from "../../../hooks/useFetch";

interface reqData {
  mobileNo: string;
}

interface reqState {
  loading: boolean;
  error: string | null;
}
interface verifyData {
  mobileNo: string;
  otp: string;
}
interface updatePasswordData {
  mobileNo: string;
  otp: string;
  password: string;
  confirmpassword: string;
}
const axiosInstance = createAxiosInstance();

export const requestOtp = createAsyncThunk(
  "otp/request",
  async (otpRequest: reqData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "auth/xdm-request-otp",
        otpRequest
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to Submit"
      );
    }
  }
);

export const updatePassword = createAsyncThunk(
  "otp/updatepassword",
  async (updatPassReq: updatePasswordData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "auth/xdm-password-update",
        updatPassReq
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to Verify OTP"
      );
    }
  }
);
export const verifyOtp = createAsyncThunk(
  "otp/verifyOtp",
  async (otpVerifyRequest: verifyData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "auth/xdm-verify-otp",
        otpVerifyRequest
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to Verify OTP"
      );
    }
  }
);

const otpSlice = createSlice({
  name: "otpRequest",
  initialState: {
    loading: false,
    error: null,
  } as reqState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(requestOtp.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(requestOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(requestOtp.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(verifyOtp.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(verifyOtp.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updatePassword.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updatePassword.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export default otpSlice.reducer;
