import * as React from 'react';
import Box from '@mui/material/Box';
import {
    Button,
    Grid,
    Paper,
    Typography,
    MenuItem,
    FormControl,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import ViewJc from './ViewJc';
import SearchBar from '@mkyy/mui-search-bar';
import type { TableColumnsType } from 'antd';
import CommonTable from '../../../components/Tables/CommonTable';

interface DataType {
    key: React.Key;
    Company: string;
    Year: string;
    StartDate: string;
    EndDate: string;
    Months: string;
    Weeks: string;
    action: string;
}

const JcCalendar: React.FC = () => {

    const [textFieldValue, setTextFieldValue] = React.useState('');

    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

    const handleSearch = () => {
        // search function logic 
    };


    const columns: TableColumnsType<DataType> = [
        {
            dataIndex: 'Company',
            title: 'Company',
            sorter: (a, b) => a.Company.length - b.Company.length,
        },
        { dataIndex: 'Year', title: 'Year' },
        { dataIndex: 'StartDate', title: 'Start Date' },
        { dataIndex: 'EndDate', title: 'End Date' },
        { dataIndex: 'Months', title: 'Months ' },
        { dataIndex: 'Weeks', title: 'Weeks' },
        {
            dataIndex: 'action',
            title: 'Action',
            render: (_: any, record: DataType) => (
                <>
                    <VisibilityIcon onClick={(event) => handleViewClick(event)} style={{ cursor: 'pointer', fontSize: '1rem', color: '#928282de' }} />

                </>
            )
        },
    ];
    const data: DataType[] = [
        { key: '1', Company: 'ComapanyName1', Year: '2021', StartDate: '16/12/2020', EndDate: '16/12/2020', Months: '52', Weeks: '55', action: '' },
        { key: '2', Company: 'ComapanyName2', Year: '2022', StartDate: '16/12/2020', EndDate: '17/12/2022', Months: '52', Weeks: '24', action: '' },
        { key: '3', Company: 'ComapanyName3', Year: '2023', StartDate: '16/12/2020', EndDate: '16/12/2023', Months: '22', Weeks: '84', action: '' },
        { key: '4', Company: 'ComapanyName4', Year: '2024', StartDate: '16/12/2020', EndDate: '16/4/2024', Months: '84', Weeks: '55', action: '' },
        { key: '5', Company: 'ComapanyName5', Year: '2025', StartDate: '16/12/2020', EndDate: '16/12/2025', Months: '77', Weeks: '74', action: '' },
        { key: '6', Company: 'ComapanyName6', Year: '2026', StartDate: '16/12/2020', EndDate: '16/12/2024', Months: '55', Weeks: '51', action: '' },
    ];
    

    function formatDate(dateString: any) {
        const parts = dateString.split('/');
        if (parts.length === 3) {
            return `${parts[0].padStart(2, '0')}/${parts[1].padStart(2, '0')}/${parts[2]}`;
        }
        return dateString;
    }

    // Format dates in the rows array
    data.forEach(row => {
        row.StartDate = formatDate(row.StartDate);
        row.EndDate = formatDate(row.EndDate);
    });


    const navigator = useNavigate();
    const [isViewing, setIsViewing] = React.useState(false);
    const [hideTable, setIsHideTable] = React.useState(false);
    const [Year, setYear] = React.useState('');
    const [isSelectValid, setIsSelectValid] = React.useState(false);

    const handleChangeSelectBox = (event: SelectChangeEvent<string>) => {
        const selectedAge = event.target.value as string;
        setYear(selectedAge);
        setIsSelectValid(selectedAge !== '');
    };
    const handleHideClick = () => {
        setIsHideTable(!hideTable)
    }
    const handleViewClick = (event: React.MouseEvent<unknown>) => {
        setIsViewing(!isViewing);
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    return (
      <>
        <Box
          sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}
        >
          <Grid item xs={12} className=" mar-tb">
            <Typography>
              <b className="header-lable"> JC Calendar</b>
            </Typography>
          </Grid>

          {isViewing ? (
            <ViewJc onBack={handleViewClick} />
          ) : (
            <>
              <Grid container justifyContent="end">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    id="demo-simple-select"
                    value={Year}
                    onChange={handleChangeSelectBox}
                  >
                    <MenuItem value={2021}>2021</MenuItem>
                    <MenuItem value={2022}>2022</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  className="btn-apply"
                  onClick={handleHideClick}
                  disabled={!isSelectValid}
                >
                  ViewAll
                </Button>
              </Grid>
              {hideTable ? (
                <>
                  {isViewing ? null : (
                    <Box>
                      <Grid item sx={{ mt: 2 }}>
                        <Box>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              mb: 2,
                            }}
                          >
                            <SearchBar
                              className="search-table"
                              value={textFieldValue}
                              onChange={(newValue) => {
                                setTextFieldValue(newValue);
                                handleSearch();
                              }}
                            />
                          </Grid>
                          <CommonTable
                            // rowSelection={rowSelection}
                            data={data}
                            columns={columns}
                          />
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </>
              ) : null}
            </>
          )}
        </Box>
      </>
    );
}
export default JcCalendar;