import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface InitialState {
  activeTab: string;
  subTab: string;
}

export const activeMainTab = createAsyncThunk(
  "filter/activeMainTab",
  async (req: string) => {
    return req;
  }
);

export const activeSubTab = createAsyncThunk(
  "filter/activeSubTab",
  async (req: string) => {
    return req;
  }
);

const tabSlice = createSlice({
  name: "tab",
  initialState: {
    activeTab: "",
  } as InitialState,
  reducers: {
    activeStages: (state, action) => {
      state.activeTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(activeMainTab.fulfilled, (state, action) => {
      state.activeTab = action.payload;
    });
    builder.addCase(activeSubTab.fulfilled, (state, action) => {
      state.subTab = action.payload;
    });
  },
});

export const { activeStages } = tabSlice.actions;
export default tabSlice.reducer;
