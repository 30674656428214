import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import { Close, Padding } from "@mui/icons-material";
import SkuLineExcelExport from "./SkuLineExcelExport";
import SkuLineMappingForm from "./SkuLineMappingForm";
import SearchIcon from "@mui/icons-material/Search";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../../components/Modal/DeleteSupplier";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  code: number;
  supplierName: string;
  groupCode: string;
  action: string;
}

const SkuLineMapping = () => {
  const [showForm, setShowForm] = React.useState(false);
  const [excelForm, setExcelForm] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState("manual");
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [tableView, setTableView] = React.useState(false);

  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    //search function logic here
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "code",
      title: "Salesman Code",
    },
    {
      dataIndex: "supplierName",
      title: "Salesman Name",
      sorter: (a, b) => a.supplierName.length - b.supplierName.length,
    },
    {
      dataIndex: "groupCode",
      title: "Group Code",
      sorter: (a, b) => a.groupCode.length - b.groupCode.length,
    },
    {
      dataIndex: "action",
      title: "Action",
      render: (_: any, record: DataType) => (
        <>
          <DeleteIcon
            onClick={(event) => handleDeleteClick(event)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      code: 55,
      supplierName: "Supplier1",
      groupCode: "012Comapny1",
      action: "",
    },
    {
      key: 2,
      code: 55445,
      supplierName: "Supplier2",
      groupCode: "012Comapny2",
      action: "",
    },
    {
      key: 3,
      code: 5565,
      supplierName: "Supplier3",
      groupCode: "012Comapny3",
      action: "",
    },
    {
      key: 4,
      code: 5548,
      supplierName: "Supplier4",
      groupCode: "012Comapny4",
      action: "",
    },
    {
      key: 5,
      code: 55121,
      supplierName: "Supplier5",
      groupCode: "012Comapny5",
      action: "",
    },
    {
      key: 6,
      code: 5565,
      supplierName: "Supplier6",
      groupCode: "012Comapny6",
      action: "",
    },
    {
      key: 7,
      code: 55789,
      supplierName: "Supplier7",
      groupCode: "012Comapny7",
      action: "",
    },
    {
      key: 8,
      code: 55656,
      supplierName: "Supplier8",
      groupCode: "012Comapny8",
      action: "",
    },
  ];

  const handleCreateNew = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleGoChange = () => {
    if (selectedOption == "manual") {
      setIsModalOpen(!isModalOpen);
      setShowForm(true);
    } else if (selectedOption == "excel") {
      setIsModalOpen(!isModalOpen);
      setExcelForm(true);
    }
  };
  const handleShowClose = () => {
    setShowForm(false);
  };
  const handleExcelClose = () => {
    setExcelForm(false);
  };

  const RadiohandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const Options = ["tcs group", "geo level", "geo level value"];

  const handleDeleteClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setDeleteModal(!deleteModal);
  };

  const handleShowTable = () => {
    setTableView(!tableView);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className="mar-tb">
          <Typography>
            <b>Salesman SKU Line Mapping</b>
          </Typography>
        </Grid>

        {showForm ? (
          <SkuLineMappingForm onBack={handleShowClose} />
        ) : excelForm ? (
          <SkuLineExcelExport onBack={handleExcelClose} />
        ) : (
          <Box>
            <Button
              variant="contained"
              className="btn-colr"
              onClick={handleCreateNew}
            >
              {" "}
              <AddIcon /> Create new
            </Button>
            <form>
              <Grid container spacing={1} style={{ marginTop: "33px" }}>
                <Grid item xs={2}>
                  <InputLabel size="normal">Search By</InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <FormControl sx={{ width: 300 }}>
                    <Select>
                      {Options.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={1} style={{ marginTop: "33px" }}>
                <Grid item xs={2}>
                  <InputLabel size="normal">Search Input</InputLabel>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="outlined-size-small"
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Button
                  variant="contained"
                  className="btn-apply btn-hgt"
                  onClick={handleShowTable}
                >
                  <SearchIcon className="fn-icon" /> Go
                </Button>
              </Grid>
            </form>
            {tableView && (
              <Grid item sx={{ mt: 2 }}>
                <Box>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "end", mb: 2 }}
                  >
                    <SearchBar
                      className="search-table"
                      value={textFieldValue}
                      onChange={(newValue) => {
                        setTextFieldValue(newValue);
                        handleSearch();
                      }}
                    />
                  </Grid>
                  <CommonTable
                    // rowSelection={rowSelection}
                    data={data}
                    columns={columns}
                  />
                  {deleteModal && (
                    <DeleteModal onBack={(event) => handleDeleteClick(event)} />
                  )}
                </Box>
              </Grid>
            )}
          </Box>
        )}
        <Modal
          open={isModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid>Choose Entry Mode</Grid>
            <Box sx={{ flex: "none", marginBottom: 1 }}>
              <IconButton
                aria-label="close"
                style={{ position: "absolute", top: 10, right: 10 }}
                onClick={handleCreateNew}
              >
                <Close />
              </IconButton>
            </Box>
            <Divider />
            <Grid item sx={{ mt: 2 }}>
              <RadioGroup
                row
                aria-label="mandatory"
                name="mandatory"
                value={selectedOption}
                onChange={RadiohandleChange}
              >
                <FormControlLabel
                  value="manual"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Manual Entry"
                />
                <FormControlLabel
                  value="excel"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Excel Entry"
                />
              </RadioGroup>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
              <Button
                variant="contained"
                className="btn-colr"
                onClick={handleGoChange}
              >
                Go
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default SkuLineMapping;
