import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CustomerCoverage from './CustomerCoverage ';
import Customerother from './Customerother';
import Customergeneral from './Customergeneral';
import Customerlicense from './Customerlicense';
interface ViewSupplierFormProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const CustomerTableview: React.FC<ViewSupplierFormProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); 
    const prevTab = parseInt(value) - 1;
    setValue(prevTab.toString());
  };

  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };


 

  const handleNextClick = () => {
    if (value !== '4') {
      const nextTab = (parseInt(value) + 1).toString();
      setValue(nextTab);
    }
  };


  
  return (
    <Box sx={{ width: '100%', height: '40px', backgroundColor: '#8080801f' }}>
      <TabContext value={value}  >
        <Box sx={{ borderBottom: 1, borderColor: 'divider', }} >
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Customer-General"disabled={value !== '1'} value="1" />
            <Tab label="License & Setting"disabled={value !== '2'} value="2" />
            <Tab label="Coverage & Attributes"  disabled={value !== '3'}  value="3" />
            <Tab label="Other Attributes" disabled={value !== '4'}  value="4" />
          </TabList>
        </Box>
        <TabPanel value="1"  ><Customergeneral   onBack={handleBackClick}  onNext={handleNextClick} /></TabPanel>
        <TabPanel value="2" ><Customerlicense  onBack={handleBackClick} onNext={handleNextClick}  /></TabPanel>
        <TabPanel value="3"><CustomerCoverage   onBack={handleBackClick} onNext={handleNextClick} /> </TabPanel>
        <TabPanel value="4"><Customerother  onBack={handleBackClick} onNext={handleNextClick}  /> </TabPanel>
      </TabContext>
    </Box>
  );
}
export default CustomerTableview;