import React, { useState } from 'react';
import { Box, TextField, FormControl, Button, Grid, InputLabel, FormControlLabel, Link, Radio, RadioGroup, Card, Select, MenuItem, TextareaAutosize, Checkbox } from '@mui/material';
import type { TableColumnsType } from 'antd';
import SearchBar from '@mkyy/mui-search-bar';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CommonTable from '../../../components/Tables/CommonTable';
interface DataType {
    key: React.Key;
    distCode: number;
    smCode: string;
    smName: string;
    groupCode: string;
    action: string;
}

interface SkuLineFormProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
}

const SkuLineMappingForm: React.FC<SkuLineFormProps> = ({ onBack }) => {

    const [deleteModal, setDeleteModal] = React.useState(false);

    const handleBackClick = (event: React.MouseEvent<unknown>) => {
        onBack(event); // Pass the event argument to onBack function
    };

    const Options = ['Radio Button', 'Checkbox', 'Dropdown'];

    const [textFieldValue, setTextFieldValue] = React.useState('');

    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

    const handleSearch = () => {
        //search function logic here
    };

    const columns: TableColumnsType<DataType> = [

        {
            dataIndex: 'distCode',
            title: 'Dist.Code',
        },
        {
            dataIndex: 'smCode',
            title: 'SalesmanCode',
            sorter: (a, b) => a.smCode.length - b.smCode.length,
        },
        {
            dataIndex: 'smName',
            title: 'SalesmanName',
            sorter: (a, b) => a.smName.length - b.smName.length,
        },
        {
            dataIndex: 'groupCode',
            title: 'Group Code',
            sorter: (a, b) => a.groupCode.length - b.groupCode.length,
        },
        {
            dataIndex: "action",
            title: "Action",

            render: (_: any, record: DataType) => (
                <>
                    <DeleteIcon
                        onClick={(event) => handleDeleteClick(event)}
                        style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
                    />
                </>
            ),
        },
    ];

    const data: DataType[] = [
        { key: 1, distCode: 100, smCode: '0001', smName: '1', groupCode: '005204', action: '' },
        { key: 2, distCode: 200, smCode: '0001', smName: '2', groupCode: '005204', action: '' },
        { key: 3, distCode: 300, smCode: '0001', smName: '3', groupCode: '005204', action: '' },
        { key: 4, distCode: 400, smCode: '0001', smName: '4', groupCode: '005204', action: '' },
        { key: 5, distCode: 500, smCode: '0001', smName: '5', groupCode: '005204', action: '' },
        { key: 6, distCode: 600, smCode: '0001', smName: '6', groupCode: '005204', action: '' },
        { key: 7, distCode: 700, smCode: '0001', smName: '7', groupCode: '005204', action: '' },
        { key: 8, distCode: 800, smCode: '0001', smName: '8', groupCode: '005204', action: '' },
    ];


    const handleDeleteClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
        setDeleteModal(!deleteModal);
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <Box className='card-form card-bg'>

            <form>
                <Grid item container sx={{ marginTop: "16px" }}>
                    <Grid item xs={12} lg={6}>
                        <Grid item sx={{ display: "flex", mt: 2 }}>
                            <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                                <InputLabel size="normal" > Salesman</InputLabel>
                            </Grid>
                            <Grid item xs={4} sx={{ mr: 5 }}>
                                <TextField sx={{ width: 200 }} size="small"></TextField>
                            </Grid>
                            <Button variant='contained' className='btn-colr'>...</Button>
                        </Grid>
                        <Grid item sx={{ display: "flex", mt: 2 }}>
                            <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                                <InputLabel size="normal" >  Group Code </InputLabel>
                            </Grid>
                            <Grid item xs={4} sx={{ mr: 5 }}>
                                <TextField sx={{ width: 200 }} size="small"></TextField>
                            </Grid>
                            <Button variant='contained' className='btn-colr'>...</Button>
                        </Grid>
                        <Grid item className='mar-tb'>
                            <Button variant="contained" className='btn-colr' > <AddIcon /> Add</Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Box className='mar-tb'>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', mb: 2 }} >
                        <SearchBar
                            className='search-table'
                            value={textFieldValue}
                            onChange={newValue => {
                                setTextFieldValue(newValue);
                                handleSearch();
                            }}
                        />
                    </Grid>
                    <CommonTable 
                    // rowSelection={rowSelection}
                     data={data} columns={columns} />
                </Box>

                <Grid item xs={12} mt={2} display="flex" justifyContent="center">
                    <Button variant="outlined" color="primary" onClick={handleBackClick}>
                        Cancel
                    </Button>
                    <Button variant="contained" className='btn-colr' style={{ marginLeft: "10px" }}>
                        save
                    </Button>
                </Grid>
            </form>
        </Box>
    );
}

export default SkuLineMappingForm;
