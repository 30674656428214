import {
  Box,
  Card,
  Grid,
} from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SearchBar from "@mkyy/mui-search-bar";
import { Spin, Table, TableColumnsType, Tooltip } from "antd";
import debounce from "lodash.debounce";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useFetch";
import { getPendingGrn } from "../../../../Redux/XDM/Purchase/GrnReducer";
import PendingReview from "./PendingReviewOrder";
import { updateDate, updateFromDate, updateStatusGrn, updateToDate } from "../../../../Redux/XDM/Sales/billingReducer";

interface PendingGrnDatatype {
  invoiceNo: string;
  orderNo: string;
  invoiceDate: string;
  totNetAmt: number;
  noOfLineItem: string;
  status: string;
}
type TableRowType = PendingGrnDatatype | { noMoreData: boolean };




const PendingGRN: React.FC<any> = () => {

  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [purchaseOrder, setPurchaseOrder] = React.useState<PendingGrnDatatype[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const [commonLoader, setCommonLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [isShow, setIsShow] = useState(false);



  const tableHeightRef = useRef(350);
  let pendingGrnData = useAppSelector((state) => state.pendingGrnData);
  let pendingGrnDataFetch = pendingGrnData?.pendingGrn;
  const filterTags = useAppSelector((state) => state.orderBilling);
  const dispatch = useAppDispatch();
  const filterData = {
    page: 0,
    size: 0,
    type: filterTags?.dateSelected,
    startDate: filterTags.fromDateSelected,
    endDate: filterTags.toDateSelected,
    search: textFieldValue,
  };

   
  useEffect(() => {
    setPage(0)
    setHasMore(true);
    setPurchaseOrder([]);
    loadMoreData(true);
  }, [
    textFieldValue,
    filterTags.dateSelected,
    filterTags.fromDateSelected,
    filterTags.toDateSelected,
  ]);
    useEffect(() => {
      dispatch(updateFromDate([]));
      dispatch(updateToDate([]));
      dispatch(updateDate([]));
      dispatch(updateStatusGrn([]));
    }, []);

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 283;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;

    dispatch(
      getPendingGrn({
        params: { ...filterData, page: nextPage },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const newOrders = response.payload.result || [];
          setPurchaseOrder((prevOrders) =>
            resetPage ? newOrders : [...prevOrders, ...newOrders]
          );
          setPage(nextPage + 1);
          if (newOrders.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        } else {
          // setHasMore(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };
  const data_sales: PendingGrnDatatype[] = purchaseOrder ?? [];
  const filteredCount = pendingGrnDataFetch.totalCount ?? 0;

  const handleRowClick = (record: PendingGrnDatatype) => {
    setRequestData(record);
  
    setIsShow(!isShow);
  };


  const [requestData, setRequestData] = React.useState({
    invoiceNo: "",
    orderNo: "",
    invoiceDate: "",
    totNetAmt: 0,
    noOfLineItem: "",
  });
  const columns: TableColumnsType<TableRowType> = [
    {
      dataIndex: "invoiceNo",
      title: "Invoice No",
      width: "15%",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return record.invoiceNo;
      },
    },

    // {
    //   dataIndex: "orderNo",
    //   title: "Order ID",
    //   width: "15%",
    //   render: (text: string | number, record: TableRowType) => {
    //     if ("noMoreData" in record) {
    //       return {
    //         children: (
    //           <span
    //             className="fs-12 noMore"
    //             style={{ display: "block", margin: "10px" }}
    //           >
    //             <b>No more data found...</b>
    //           </span>
    //         ),
    //         props: {
    //           colSpan:6, // Adjust this number based on the total number of columns to span
    //         },
    //       };
    //     }
    //     return (
    //       <Tooltip placement="top" title={text} zIndex={999999}>
    //         <span>{text}</span>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      dataIndex: "invoiceDate",
      width: "12%",
      title: "Invoice Date",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
        return a.invoiceDate.localeCompare(b.invoiceDate);
      },
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
            return { children: null, props: { colSpan: 0 } };
        if (record.invoiceDate) {
            const dateObj = moment(record.invoiceDate, "ddd MMM DD HH:mm:ss [IST] YYYY");
            const formattedDate = dateObj.format("DD MMM YYYY");
            return <div>{formattedDate}</div>;
        }
        return "-";
    },
    },

    {
      dataIndex: "noOfLineItem",
      width: "15%",
      title: "No of Line Item",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return record.noOfLineItem;
      },
    },
    {
      dataIndex: "totNetAmt",
      width: "15%",
      title: "Value",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
        return a.totNetAmt - b.totNetAmt;
      },
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <span>
            <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
            {record.totNetAmt ? record.totNetAmt.toFixed(2) : "0"}
          </span>
        );
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return <Grid item sx={{color:'#5daa48', fontWeight:600, fontFamily:'Montserrat'}}>{record.status}</Grid> ;
      },
    },
  ];

  const dataToShow: TableRowType[] =
    purchaseOrder.length > 0
      ? hasMore
        ? purchaseOrder
        : [...purchaseOrder, { noMoreData: true }]
      : [];

  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );
  const handleSearch = () => {
    setTextFieldValue(textFieldValue);
  };
  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  
  return (
    <Box>
      <Box flex={8} sx={{ overflowY: "auto", height: "100vh", mt: -5.3 }}>
        <Card
          className="b-radius-0"
          style={{ marginTop: "2px", boxShadow: "none" }}
        >
          <Grid container spacing={3} justifyContent="start" p={2}>
            <Grid container mt={4} m={2}>
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <h3
                  style={{
                    marginTop: "0px",
                    marginLeft: "8px",
                    marginBottom: "16px",
                  }}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <SearchBar
                  className="search_box_saleable w-100px"
                  placeholder="Search Invoice Number, ID...."
                  value={textFieldValue}
                    onChange={(newValue) => {
                      setTextFieldValue(newValue);
                    }}
                    onCancelResearch={() => setTextFieldValue("")}
                    onSearch={(newValue) => {
                      handleSearch();
                    }}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} sm={12} md={12} lg={12} className="pt-0">
              {(filterTags.dateSelected?.length !== 0 && filterTags.dateSelected !== null ||
                textFieldValue !== "") && (
                  <p style={{ marginTop: "0px", marginBottom: "10px" }}>
                    Showing Results based on selected filters{" "}
                    <b>({filteredCount} Products)</b>
                  </p>
                )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="pad-top-0px">
              <div className="saleable-table-scroll order-bill">
                <Table
                  className={`Header-table saleable-table sale_last_hide scroll_cus_table elipsTable pending_grn ${commonLoader? " nodataloader" : ""
                    }`}
                  // rowSelection={rowSelection}
                  columns={columns}
                  dataSource={dataToShow}
                  pagination={false}
                  loading={!page && commonLoader}
                  showSorterTooltip={false}
                  summary={() => {
                    return (
                      <>
                        {page !== 0 &&commonLoader && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              index={1}
                              colSpan={5}
                              align="center"
                            >
                              <Spin />
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                      </>
                    );
                  }}
                  onRow={(record: TableRowType) => {
                    if ("noMoreData" in record) return {};
                    return {
                      onClick: () => handleRowClick(record)
                    };
                  }}
                  scroll={{ y: tableHeightRef.current }}
                />
              </div>
            </Grid>
          </Grid>
        </Card>
        {isShow ? (
        <PendingReview
          onClose={() => setIsShow(false)}
          requestData={requestData}
        />
      ) : (<></> )}
      </Box>
    </Box>
  );
};

export default PendingGRN;
