import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Chip,
  Drawer,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

//icons
import Close from "../../../../assets/images/icons/Close_icon.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Editicon from "../../../../assets/images/icons/edit_icon.png";
import { Theme, useTheme } from "@mui/material/styles";

interface SalesHierarchyEditProps {
  onClose: () => void;
}

//
function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SalesHierarchyEdit(props: SalesHierarchyEditProps) {
  const { onClose } = props; // Destructure the props
  const [open, setOpen] = React.useState(false);
  const [isEditEnabled, setIsEditEnabled] = React.useState(true);

  const handleEditClick = () => {
    setIsEditEnabled(false); // Disable edit when clicked
  };

  const Sales = ["aaa", "bb"];

  const product = ["Subbash", "Thrishal", "Sharan raj"];

  const [selectedValue, setSelectedValue] = useState<string>("");
  const [regionalValue, setRegionalValue] = useState<string>("");
  const [zonalValue, setZonalValue] = useState<string>("");
  const [cityValue, setCityValue] = useState<string>("");
  const theme = useTheme();
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedValue(event.target.value);
  };
  const handleChangeRegional = (event: SelectChangeEvent<string>) => {
    setRegionalValue(event.target.value);
  };
  const handleChangeZonal = (event: SelectChangeEvent<string>) => {
    setZonalValue(event.target.value);
  };
  const handleChangeSDE = (event: SelectChangeEvent<string>) => {
    setCityValue(event.target.value);
  };
  const handleChanges = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleRemove = (
    value: string,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation(); // Prevent the event from propagating to parent elements
    // Your remaining code for removing the chip
  };

  return (
    <>
      <Box sx={{ marginTop: "2px" }}>
        <Drawer
          anchor="right"
          open={true}
          // variant="persistent"
          className="drawer_stl"
        >
          <Box
            sx={{
              width: {
                lg: 530,

                md: 536,
                sm: 740,
                xs: 400,
              },
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            {/* Heading */}
            <Grid item sx={{ width: "95%" }} role="presentation">
              <Grid container className="geo-header">
                <Typography gutterBottom className="geo-header-lable">
                  Zonal Sales Manager
                </Typography>

                <Grid item>
                  <img
                    src={Editicon}
                    alt={Editicon}
                    onClick={handleEditClick}
                    style={{
                      width: "24px",
                      height: "24px",
                      marginRight: "15px",
                    }}
                  />
                  <img
                    src={Close}
                    alt={Close}
                    onClick={onClose}
                    style={{ width: "24px", height: "24px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Content */}
            <Box
              mt={2}
              sx={{
                overflowY: "auto",
              }}
              className="create-form"
            >
              {/* Your scrollable content */}
              <form>
                <Grid
                  item
                  sx={{ width: "95%", margin: "auto", marginBottom: "100px" }}
                >
                  <Grid item>
                    <Typography className="geo-fnt-lable">
                      Head of Sales *
                    </Typography>
                    <Grid item xs={12} sx={{ mt: "5px" }}>
                      <FormControl fullWidth>
                        <Select
                          className="prd_select"
                          IconComponent={KeyboardArrowDownIcon}
                          displayEmpty
                          value={selectedValue}
                          disabled={isEditEnabled}
                          onChange={handleChange}
                        >
                          <MenuItem
                            className="select_placeholder"
                            disabled
                            value=""
                          >
                            <em>Select Head of Sales</em>
                          </MenuItem>
                          {Sales.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="geo-fnt-lable">
                      Regional Sales Manager *
                    </Typography>
                    <Grid item xs={12} mt={1}>
                      <FormControl fullWidth>
                        <Select
                          className="prd_select"
                          IconComponent={KeyboardArrowDownIcon}
                          displayEmpty
                          value={regionalValue}
                          onChange={handleChangeRegional}
                          disabled={isEditEnabled}
                        >
                          <MenuItem
                            className="select_placeholder"
                            disabled
                            value=""
                          >
                            <em>Select Regional sales </em>
                          </MenuItem>
                          {Sales.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item mt={2}>
                    <Typography className="geo-fnt-lable">
                      Zonal Sales Manager *
                    </Typography>
                    <Grid item xs={12} mt={1}>
                      <FormControl fullWidth>
                        <Select
                          className="prd_select"
                          IconComponent={KeyboardArrowDownIcon}
                          displayEmpty
                          disabled={isEditEnabled}
                          value={zonalValue}
                          onChange={handleChangeZonal}
                        >
                          <MenuItem
                            className="select_placeholder"
                            disabled
                            value=""
                          >
                            <em>Select State </em>
                          </MenuItem>
                          {Sales.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item mt={2}>
                    <Typography className="geo-fnt-lable">
                      Area Sales Manager *
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        disabled={isEditEnabled}
                        className="sales-txt-box"
                        placeholder=" Enter District  "
                        type="text"
                        name={"District"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="geo-fnt-lable">SDE/TSO *</Typography>
                    <Grid item xs={12} mt={1}>
                      {/* <FormControl fullWidth>
                        <Select
                          className="prd_select"
                          IconComponent={KeyboardArrowDownIcon}
                          displayEmpty
                          value={cityValue}
                          disabled={isEditEnabled}
                          onChange={handleChangeSDE}
                        >
                          <MenuItem
                            className="select_placeholder  "
                            disabled
                            value=""
                          >
                            <em>Select City/Town </em>
                          </MenuItem>
                          {Sales.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                      <FormControl fullWidth>
                        <Select
                          multiple
                          className="multiSel_product"
                          value={personName}
                          onChange={handleChanges}
                          IconComponent={KeyboardArrowDownIcon}
                          displayEmpty
                          disabled={isEditEnabled}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  onClick={(event) =>
                                    handleRemove(value, event)
                                  }
                                  className="Select_chip"
                                  key={value}
                                  label={value}
                                  avatar={
                                    <img
                                      src={Close}
                                      alt={Close}
                                      style={{
                                        width: "15px",
                                        height: "15px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  }
                                  style={{ flexDirection: "row-reverse" }}
                                />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {product.map((name) => (
                            <MenuItem
                              className="select_opction"
                              key={name}
                              value={name}
                                style={getStyles(name, personName, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item className="geo_Create-footer">
                  <Button className="crt-btn-cancel" onClick={onClose}>
                    {" "}
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className="crt-btn-colr"
                    type="submit"
                    sx={{ mr: 4 }}
                  >
                    {" "}
                    Update
                  </Button>
                </Grid>
              </form>
            </Box>
          </Box>
        </Drawer>
      </Box>
    </>
  );
}
