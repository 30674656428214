import { Box, Card, Grid } from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SearchBar from "@mkyy/mui-search-bar";
import { Spin, Table, TableColumnsType, Tooltip } from "antd";
import debounce from "lodash.debounce";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useFetch";
import { getPendingGrn } from "../../../../Redux/XDM/Purchase/GrnReducer";
import {
  updateDate,
  updateFromDate,
  updateStatusGrn,
  updateToDate,
} from "../../../../Redux/XDM/Sales/billingReducer";
import PendingSalesReturnReview from "./PendingSalesReturn/PendingReviewOrder";
import { getSalesReturnData } from "../../../../Redux/XDM/Sales/salesReturnReducer";
import InvoiceDetails from "../InvoiceReviewOrder";

interface PendingSalesReturnDatatype {
  returnId: string;
  outletName: string;
  invoiceNo: string;
  salesmanName: string;
  date: string;
  noOfLineItem: number;
  returnValue: number;
  status: string;
  orderNumber: string;
  routeCode: string;
  mobileNo: string;
}
type TableRowType = PendingSalesReturnDatatype | { noMoreData: boolean };

interface historyProps {
  status: string;
}

const PendingReturn: React.FC<any> = () => {
  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [salesReturnOrder, setSalesReturnOrder] = React.useState<
    PendingSalesReturnDatatype[]
  >([]);
  const [hasMore, setHasMore] = useState(true);

  const [commonLoader, setCommonLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);

  const tableHeightRef = useRef(350);
  let pendingSalesReturnData = useAppSelector((state) => state.salesReturnData);
  let pendingSalesReturnDataFetch = pendingSalesReturnData?.salesReturnData;
  const filterTags = useAppSelector((state) => state.orderBilling);
  const dispatch = useAppDispatch();
  const filterData = {
    page: 0,
    size: 0,
    status: "pending",
    reference: filterTags.invoiceTypeSelected.toString(),
    salesman: filterTags.salesmanSelected.toString(),
    outlets: filterTags.outletSelected.toString(),
    date: filterTags.dateSelected,
    fromDate: filterTags.fromDateSelected,
    toDate: filterTags.toDateSelected,
    search: textFieldValue,
  };

  useEffect(() => {
    setPage(0);
    setHasMore(true);
    setSalesReturnOrder([]);
    loadMoreData(true);
  }, [
    textFieldValue,
    filterTags.dateSelected,
    filterTags.outletSelected,
    filterTags.fromDateSelected,
    filterTags.toDateSelected,
    filterTags.invoiceTypeSelected,
    filterTags.salesmanSelected,
  ]);
  useEffect(() => {
    dispatch(updateFromDate([]));
    dispatch(updateToDate([]));
    dispatch(updateDate([]));
    dispatch(updateStatusGrn([]));
  }, []);

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 283;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;

    dispatch(
      getSalesReturnData({
        params: { ...filterData, page: nextPage },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const newOrders = response.payload.result || [];
          setSalesReturnOrder((prevOrders) =>
            resetPage ? newOrders : [...prevOrders, ...newOrders]
          );
          setPage(nextPage + 1);
          if (newOrders.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        } else {
          // setHasMore(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };

  const filteredCount = pendingSalesReturnDataFetch.totalCount ?? 0;

  const handleRowClick = (record: PendingSalesReturnDatatype) => {
    setRequestData(record);
    setInvoiceRequestData({
      invoiceNumber: record.invoiceNo,
      orderId: record.orderNumber,
      date: record.date,
      outlet: "",
      route: record.routeCode,
      salesman: record.salesmanName,
      netAmount: 0,
      salesmanMobile: record.mobileNo,
    });
    setIsShow(!isShow);
  };

  const [requestData, setRequestData] = React.useState({
    returnId: "",
    outletName: "",
    invoiceNo: "",
    salesmanName: "",
    date: "",
    noOfLineItem: 0,
    returnValue: 0,
    status: "",
  });
  const [invoiceRequestData, setInvoiceRequestData] = React.useState({
    invoiceNumber: "",
    orderId: "",
    date: "",
    outlet: "",
    route: "",
    salesman: "",
    netAmount: 0,
    salesmanMobile: "",
  });
  const handleInvoiceClick = (record: PendingSalesReturnDatatype) => {
    setInvoiceRequestData({
      invoiceNumber: record.invoiceNo,
      orderId: record.orderNumber,
      date: record.date,
      outlet: "",
      route: record.routeCode,
      salesman: record.salesmanName,
      netAmount: 0,
      salesmanMobile: record.mobileNo,
    });

    setIsInvoice(!isInvoice);
  };

  const columns: TableColumnsType<TableRowType> = [
    {
      dataIndex: "returnId",
      title: "Return ID",
      width: "15%",
      align: "left",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return record.returnId;
      },
    },
    {
      dataIndex: "outletName",
      title: "Outlet Name",
      width: "15%",
      align: "left",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return record.outletName;
      },
    },
    {
      dataIndex: "invoiceNo",
      title: "Invoice No",
      width: "15%",
      align: "left",
      render: (text: string | number, record: TableRowType) => {
        if ("noMoreData" in record) {
          return {
            children: (
              <span
                className="fs-12 noMore"
                style={{ display: "block", margin: "10px" }}
              >
                <b>No more data found...</b>
              </span>
            ),
            props: {
              colSpan: 7, // Adjust this number based on the total number of columns to span
            },
          };
        }
        const invoiceNo = record.invoiceNo ? record.invoiceNo : "-";
        return (
          <div
            style={{
              textDecoration: invoiceNo !== "-" ? "underline" : "none",
              color: invoiceNo !== "-" ? "#27538D" : "inherit",
              fontWeight: invoiceNo !== "-" ? 600 : "normal",
            }}
            onClick={() => {
              if (invoiceNo  !== "-") {
                handleInvoiceClick(record as PendingSalesReturnDatatype)
              }
            }}
          >
            {invoiceNo}
          </div>
        );
      },
    },
    {
      dataIndex: "salesmanName",
      title: "Salesman Name",
      width: "15%",
      align: "start",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return record.salesmanName ?? "-";
      },
    },
    {
      dataIndex: "date",
      width: "12%",
      align: "left",
      title: "Date",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
        return a.date.localeCompare(b.date);
      },
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        if (record.date) {
          const dateObj = moment(record.date, "YYYY-MM-DD");
          const formattedDate = dateObj.format("DD MMM YYYY");
          return <div>{formattedDate}</div>;
        }
        return "-";
      },
    },

    {
      dataIndex: "noOfLineItem",
      width: "15%",
      title: "No of Line Item",
      align: "center",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return record.noOfLineItem;
      },
    },
    {
      dataIndex: "returnValue",
      title: "Return Value",
      align: "right",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <span>
            ₹{record?.returnValue ? record?.returnValue.toFixed(2) : "0.00"}
          </span>
        );
      },
    },
  ];

  const dataToShow: TableRowType[] =
    salesReturnOrder.length > 0
      ? hasMore
        ? salesReturnOrder
        : [...salesReturnOrder, { noMoreData: true }]
      : [];

  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );
 const handleSearch = () => {
    setTextFieldValue(textFieldValue);
  };
  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <Box>
      <Box flex={8} sx={{ overflowY: "auto", height: "100vh", mt: -5.3 }}>
        <Card
          className="b-radius-0"
          style={{ marginTop: "2px", boxShadow: "none" }}
        >
          <Grid container spacing={3} justifyContent="start" p={2}>
            <Grid container mt={4} m={2}>
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <h3
                  style={{
                    marginTop: "0px",
                    marginLeft: "8px",
                    marginBottom: "16px",
                  }}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <SearchBar
                  className="search_box_saleable w-100px"
                  placeholder="Search Return ID"
                  value={textFieldValue}
                    onChange={(newValue) => {
                    setTextFieldValue(newValue);
                  }}
                  onCancelResearch={() => setTextFieldValue("")}
                  onSearch={(newValue) => {
                    handleSearch();
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} sm={12} md={12} lg={12} className="pt-0">
              {((filterTags.dateSelected?.length !== 0 &&
                filterTags.dateSelected !== null) ||
                textFieldValue !== "") && (
                  <p style={{ marginTop: "0px", marginBottom: "10px" }}>
                    Showing Results based on selected filters{" "}
                    <b>({filteredCount} Products)</b>
                  </p>
                )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="pad-top-0px">
              <div className="saleable-table-scroll">
                <Table
                  className={`Header-table saleable-table sale_last_hide scroll_cus_table elipsTable pending_grn ${commonLoader ? " nodataloader" : ""
                    }`}
                  // rowSelection={rowSelection}
                  columns={columns}
                  dataSource={dataToShow}
                  pagination={false}
                  loading={!page && commonLoader}
                  showSorterTooltip={false}
                  summary={() => {
                    return (
                      <>
                        {page !== 0 && commonLoader && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              index={1}
                              colSpan={6}
                              align="center"
                            >
                              <Spin />
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                      </>
                    );
                  }}
                  onRow={(record: TableRowType, index) => {
                    if ("noMoreData" in record) return {};
                    return {
                      onClick: (event: React.MouseEvent<HTMLElement>) => {
                        const target = event.target as HTMLElement;
                        const closestTd = target.closest("td");
                        const columnIndex = closestTd
                          ? closestTd.cellIndex
                          : null;

                        if (columnIndex !== null && columnIndex !== 2) {
                          handleRowClick(record);
                        }
                      },
                    };
                  }}
                  scroll={{ y: tableHeightRef.current }}
                />
              </div>
            </Grid>
          </Grid>
        </Card>
        {isShow ? (
          <PendingSalesReturnReview
            onClose={() => setIsShow(false)}
            requestData={requestData}
            invoiceRequestData={invoiceRequestData}
          />
        ) : (
          <>
            {isInvoice && (
              <InvoiceDetails
                onClose={() => setIsInvoice(false)}
                requestData={invoiceRequestData}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default PendingReturn;
