// reducers.js
import {
  LOGIN_SUCCESS,
  GET_USER_FROM_LOCALSTORAGE,
  LOGOUT,
  SIDEBAR_POPUP_SHOW,
  SIDEBAR_FILTER_SHOW,
  SUBHEADER_HIDDEN,
  SIDEBAR_FILTER_SHOW_INV,
} from "../actionType";
import { combineReducers } from "redux";
interface UserState {
  user: any;
  getLocalData: any;
  isLoggedIn: boolean;
  sidebarShowMore: boolean;
  filterOpen: boolean;
  hideSubHeader: boolean;
}
const initialState = {
  user: null,
  getLocalData: [],
  isLoggedIn: false,
  sidebarShowMore: false,
  filterOpen: false,
  hideSubHeader: false,
  filterOpenInv: false,
};

const userReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
      };
    case GET_USER_FROM_LOCALSTORAGE:
      return {
        ...state,
        getLocalData: action.payload,
        isLoggedIn: !!action.payload,
      };
    case SIDEBAR_POPUP_SHOW:
      return {
        ...state,
        sidebarShowMore: action.payload,
      };
    case SIDEBAR_FILTER_SHOW:
      return {
        ...state,
        filterOpen: action.payload,
      };
    case SUBHEADER_HIDDEN:
      return {
        ...state,
        hideSubHeader: action.payload,
      };
    case SIDEBAR_FILTER_SHOW_INV:
      return {
        ...state,
        filterOpenInv: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
