import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Drawer,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { ReactComponent as LowStockSVG } from "../../../assets/images/icons/low_stock_transfarant.svg";
import type { TableColumnsType } from "antd";
import { Spin, Table, Tooltip } from "antd";
import { decodeJwt } from "../../../utils/constant";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import { getSaleableProductDetails } from "../../../Redux/XDM/Inventory/saleableReducer";
import { getNonSaleableProductsList } from "../../../Redux/XDM/Inventory/nonSaleableReducer";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { Add, ArrowBackIos, Remove } from "@mui/icons-material";
import SearchBar from "@mkyy/mui-search-bar";
import { RemoveCircleOutline } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  mannualOrderConvertBill,
  savePreviouslyAddedData,
} from "../../../Redux/XDM/Sales/ManualOrderReducer";
import { useLocation } from "react-router-dom";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { updateDivision } from "../../../Redux/XDM/Inventory/filterReducer";
import view_icon from "../../../assets/images/icons/view_icon.png";

interface RequestData {
  productHierarchy: string;
  flags: string[];
  mrp: number;
  basicMaterial: string;
  matGrp2Desc: string;
  totalSaleableStock: number;
  sellingRate: number;
  productCode: string;
  uom: string;
  division: string;
  brand: string;
  variant: string;
  sgstOrUgstPercentage: number;
  cgstPercentage: number;
  sgstPercentage: number;
  igstPercentage: number;
  noOfPcs: number;
}

const LowStockIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    component={LowStockSVG}
    fill="#F59D33"
    viewBox="0 0 24 24"
  />
);

interface ReviewOrderDetailsProps {
  requestData: RequestData[];
  clearRequestData: () => void;
  removeFromRequestData: (productHierarchy: string) => void;
}
interface ReviewOrderDetails {
  productHierarchy: string;
  flags: string[];
  mrp: number;
  basicMaterial: string;
  matGrp2Desc: string;
  sellingRate: number;
  grossAmount: number;
  schemeAmount: number;
  productCode: string;
  netAmount: number;
  quantity: number;
  freeQuantity: number;
  totalSaleableStock: number;
  lowStockOrder: boolean;
  uom: string;
  division: string;
  brand: string;
  variant: string;
  sgstOrUgstPercentage: number;
  cgstPercentage: number;
  sgstPercentage: number;
  igstPercentage: number;
  noOfPcs: number;
  tax: string;
  updateNetAmount: number;
}
interface updateQuantityData {
  orderId: string;
  productCode: string[];
  quantity: number[];
}
interface convertBillReq {
  outletCode: string | null;
  smUserId: string | null;
  routeCode: string | null;
  routeName: string | null;
  outletName: string | null;
  salesmanName: string | null;
  totalSchemeDiscount: string;
  totalTax: string;
  totalValue: number;
  productDetails: ProductDetails[];
}

interface ProductDetails {
  qty: number;
  productCode: string;
  productName: string;
  sellRate: string;
  schemeDiscount: string;
  tax: string;
  orderValue: string;
  orderTakenBy: string;
  productHierarchy: string;
  uom: string;
  division: string;
  brand: string;
  variant: string;
  cgstPercentage: number;
  sgstOrUgstPercentage: number;
  igstPercentage: number;
  noOfPcs: number;
}
const calculateGrossAmount = (
  sellingRate: number,
  quantity: number
): number => {
  return sellingRate * quantity;
};

const transformRequestDataToReviewOrderDetails = (
  requestData: RequestData[]
): ReviewOrderDetails[] => {
  return requestData.map((data) => {
    const grossAmount = calculateGrossAmount(data.sellingRate, 1);
    const schemeAmount = 0; // or calculate based on your logic
    const netAmount = grossAmount - schemeAmount;

    return {
      basicMaterial: data.basicMaterial,
      matGrp2Desc: data.matGrp2Desc,
      productHierarchy: data.productHierarchy,
      productCode: data.productCode,
      sellingRate: data.sellingRate,
      grossAmount: grossAmount,
      schemeAmount: schemeAmount,
      flags: data.flags,
      mrp: data.mrp,
      netAmount: netAmount,
      uom: data.uom,
      division: data.division,
      brand: data.brand,
      variant: data.variant,
      sgstOrUgstPercentage: data.sgstOrUgstPercentage,
      cgstPercentage: data.cgstPercentage,
      sgstPercentage: data.sgstPercentage,
      igstPercentage: data.igstPercentage,
      quantity: data.totalSaleableStock === 0 ? 0 : 1,
      freeQuantity: 0, // or fetch from another source
      totalSaleableStock: data.totalSaleableStock, // or fetch from another source
      lowStockOrder: false, // or determine based on logic
      noOfPcs: data.noOfPcs,
      tax: "",
      updateNetAmount: 0.0,
    };
  });
};
export default function MannualRightOrder(props: ReviewOrderDetailsProps) {
  const { requestData, clearRequestData, removeFromRequestData } = props; // Destructure the props
  const [dataRecordOrders, setDataRecordOrders] = useState<
    ReviewOrderDetails[]
  >([]);
  const dispatch = useAppDispatch();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [productHierarchy, setProductHeirarchy] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [totalTaxAmount, setTotalTaxAmount] = useState<number>(0);
  const [totalNetAmount, setTotalNetAmount] = useState<number>(0);

  const location = useLocation();

  const getQueryParams = (query: any) => {
    return new URLSearchParams(query);
  };
  const previousData = useAppSelector(
    (state) => state.manualOrderdata.previousSelectedData
  );
  const queryParams = getQueryParams(location.search);
  const outletcode = queryParams.get("outletcode");
  const salesman = queryParams.get("salesman");
  const routecode = queryParams.get("routecode");
  const routeName = queryParams.get("routeName");
  const outletName = queryParams.get("outletName");
  const salesmanName = queryParams.get("salesmanName");

  const data_record: ProductDetails[] = dataRecordOrders.map((product) => ({
    qty: product.quantity,
    productCode: product.productCode,
    productName: product.basicMaterial
      ? product.basicMaterial
      : product.matGrp2Desc,
    sellRate: product.sellingRate.toString(),
    schemeDiscount: "0.00",
    tax: product.tax.toString(),
    orderValue: product.updateNetAmount.toString(),
    orderTakenBy: salesman ? "SM" : "Outlet",
    productHierarchy: product.productHierarchy,
    uom: product.uom,
    division: product.division,
    brand: product.brand,
    variant: product.variant,
    cgstPercentage: product.cgstPercentage,
    sgstOrUgstPercentage: product.sgstOrUgstPercentage,
    igstPercentage: product.igstPercentage,
    noOfPcs: product.noOfPcs,
  }));
  useEffect(() => {
    setProductHeirarchy("");
    loadMoreData(true);
    // calculateTaxesAndNetAmount();
  }, [requestData]);

  const [newQuantityRecords, setNewQuantityRecords] = useState<
    ReviewOrderDetails[]
  >([
    // Add more records as needed
  ]);

  useEffect(() => {
    setConvertBillReqData((prevData) => ({
      ...prevData,
      totalValue: totalNetAmount, // Update totalValue in convertBillReqData
    }));
  }, [totalNetAmount]);
  const [convertBillReqData, setConvertBillReqData] = useState<convertBillReq>({
    outletCode: outletcode,
    smUserId: salesman,
    routeCode: routecode,
    routeName: routeName,
    outletName: outletName,
    salesmanName: salesmanName,
    totalSchemeDiscount: "0.0",
    totalTax: totalTaxAmount.toString(),
    totalValue: totalNetAmount,
    productDetails: data_record,
  });
  useEffect(() => {
    // Transform requestData to ReviewOrderDetails
    // Merge with existing dataRecordOrders to request data
    const reviewOrderDetails =
      transformRequestDataToReviewOrderDetails(requestData);

    const combinedData = [
      ...dataRecordOrders,
      ...reviewOrderDetails,
      ...previousData,
    ];

    // Remove the duplicates from the combined data
    const uniqueData = combinedData
      .map((item) => ({
        ...item,
        priority: item.quantity > 0 ? 1 : 0,
        grossAmount: item.quantity * item.sellingRate,
      })) // Add a priority flag based on quantity
      .sort((a, b) => b.priority - a.priority) // Sort by priority, higher first
      .filter(
        (item, index, self) =>
          index ===
            self.findIndex(
              (t) => t.productHierarchy === item.productHierarchy
            ) && item.productHierarchy !== productHierarchy
      );
    let totalTaxAmountValue = 0;
    let totalNetAmountValue = 0;
    const updatedOrders = uniqueData.map((order) => {
      const {
        grossAmount,
        igstPercentage = 0,
        cgstPercentage = 0,
        sgstPercentage = 0,
      } = order;

      // Calculate tax amounts
      const igst = grossAmount * (igstPercentage / 100);
      const cgst = grossAmount * (cgstPercentage / 100);
      const sgst = grossAmount * (sgstPercentage / 100);

      // Total tax amount
      const taxAmount = igst + cgst + sgst;

      // Add this record's taxAmount to totalTaxAmountValue
      totalTaxAmountValue += parseFloat(taxAmount.toFixed(2));

      // Calculate net amount
      const netAmount = grossAmount + taxAmount;

      // Add this record's netAmount to totalNetAmountValue
      totalNetAmountValue += parseFloat(netAmount.toFixed(2));
      return {
        ...order,
        igst: igst.toFixed(2),
        cgst: cgst.toFixed(2),
        sgst: sgst.toFixed(2),
        tax: taxAmount.toFixed(2),
        updateNetAmount: parseFloat(netAmount.toFixed(2)), // Parse the string to a number
      };
    });
    // Update the state with the calculated values
    setDataRecordOrders(updatedOrders);
    // Set the Total Tax Amount in the state
    setTotalTaxAmount(totalTaxAmountValue);
    // Set the Total Net Amount in the state
    setTotalNetAmount(totalNetAmountValue);

    dispatch(savePreviouslyAddedData(uniqueData));
    // setDataRecordOrders(uniqueData);
    // Update convertBillReqData when dataRecordOrders changes
    setConvertBillReqData((prevData) => ({
      ...prevData,
      outletCode: outletcode,
      smUserId: salesman,
      routeCode: routecode,
      totalValue: totalNetAmount,
      totalSchemeDiscount: "0.0",
      totalTax: parseFloat(totalTaxAmount.toString()).toFixed(2),
      productDetails: updatedOrders.map((product) => ({
        qty: product.quantity,
        productCode: product.productCode,
        productName: product.basicMaterial
          ? product.basicMaterial
          : product.matGrp2Desc,
        sellRate: product.sellingRate.toString(),
        schemeDiscount: "0.00", // Adjust based on your logic
        tax: product.tax, // Adjust based on your logic
        orderValue: product.updateNetAmount.toString(),
        orderTakenBy: salesman ? "SM" : "Outlet",
        productHierarchy: product.productHierarchy,
        uom: product.uom,
        division: product.division,
        brand: product.brand,
        variant: product.variant,
        cgstPercentage: product.cgstPercentage,
        sgstOrUgstPercentage: product.sgstOrUgstPercentage,
        igstPercentage: product.igstPercentage,
        noOfPcs: product.noOfPcs,
      })),
    }));
  }, [requestData, outletcode, salesman, routecode, newQuantityRecords]);

  let orderData = useAppSelector((state) => state.orderBillingData);

  const [productCodeDatas, setProductCodeDatas] = useState<
    ReviewOrderDetails[]
  >([
    // Add more records as needed
  ]);

  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const handleDeleteClick = (index: number, productCode: string) => {
    setDeleteIndex(index);
    setProductHeirarchy(productCode);
  };

  const handleDeleteCancel = () => {
    setProductHeirarchy("");
    setDeleteIndex(null);
  };

  const handleQuantityChange = (
    index: number,
    newQuantity: number,
    productCode: string,
    qty: number,
    availableQty: number
  ) => {
    if (newQuantity === availableQty + 1) {
      setSnackbarMessage("Order quantity cannot exceed available quantity.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }

    setDataRecordOrders((prevState) => {
      const updatedOrders = [...prevState];
      updatedOrders[index] = {
        ...updatedOrders[index],
        quantity:
          newQuantity >= updatedOrders[index]?.totalSaleableStock
            ? updatedOrders[index]?.totalSaleableStock
            : newQuantity,
        lowStockOrder: newQuantity > updatedOrders[index]?.totalSaleableStock,
      };
      return updatedOrders;
    });
    setNewQuantityRecords((prevState) => {
      const updatedOrders = [...prevState];
      updatedOrders[index] = {
        ...updatedOrders[index],
        quantity: newQuantity >= 0 ? newQuantity : 0,
        lowStockOrder: newQuantity >= updatedOrders[index]?.totalSaleableStock,
      };
      return updatedOrders;
    });
    setProductCodeDatas((prevState) => {
      const upadatedOrders = [...prevState];
      upadatedOrders[index] = {
        ...upadatedOrders[index],
        productHierarchy: productCode,
      };
      return upadatedOrders;
    });

    setConvertBillReqData((prevState) => {
      const updatedProductDetails = [...prevState.productDetails];
      updatedProductDetails[index] = {
        ...updatedProductDetails[index],
        qty: newQuantity,
        productHierarchy: productCode,
      };
      return {
        ...prevState,
        productDetails: updatedProductDetails,
        totalValue: totalNetAmount,
      };
    });
  };

  const handleConvert = async () => {
    const hasquantity = convertBillReqData?.productDetails?.some(
      (record: any) => record.qty === 0
    );
    if (hasquantity) {
      setSnackbarMessage("Quantity cannot be zero!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } else {
      setButtonLoader(true);
      try {
        // Dispatch the async thunk with convertBillReq as the payload
        const resultAction = await dispatch(
          mannualOrderConvertBill(convertBillReqData)
        );
        if (mannualOrderConvertBill.fulfilled.match(resultAction)) {
          setDataRecordOrders([]);
          dispatch(savePreviouslyAddedData([]));
          clearRequestData();
          setSnackbarMessage("Data Updated successfully!");
          setSnackbarSeverity("success");
          setProductHeirarchy("");
          setSnackbarOpen(true);
          setButtonLoader(false);
        } else {
          setSnackbarMessage("Data Updated Failed!");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setButtonLoader(false);
        }
        // Handle success if needed
      } catch (error) {
        setButtonLoader(false);
        // Handle errors
        console.error("Error converting to Bill:", error);
      }
    }
  };
  const handleRemove = () => {
    const updatedRecords = dataRecordOrders.filter(
      (item) => item.productHierarchy !== productHierarchy
    );

    dispatch(savePreviouslyAddedData(updatedRecords));
    setDataRecordOrders(updatedRecords);

    setDeleteIndex(null);
    removeFromRequestData(productHierarchy);
  };

  const reviewOrderColumns: TableColumnsType<ReviewOrderDetails> = [
    {
      dataIndex: "basicMaterial",
      title: "Product Name / Code",
      width: "15%",
      ellipsis: true,
      render: (text: string, record: ReviewOrderDetails) => {
        const prdName = record.basicMaterial
          ? record.basicMaterial
          : record.matGrp2Desc;
        // const displayText =
        //   prdName.length > 15 ? `${prdName.substring(0, 15)}...` : text;
        return (
          <Tooltip placement="top" title={prdName} zIndex={999999}>
            <b>{prdName}</b>
            <br />
            <span>{record.productHierarchy}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "productCode",
      title: "Schemes",

      render: (text: string, record: ReviewOrderDetails, index: number) => {
        return (
          <>
            {/* {record.flags.includes("Scheme") ? (
              <Button
                className="view_css"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <img src={view_icon} width={"12px"} alt="view_icon" />
                View(5)
              </Button>
            ) : (
              <span>NA</span>
            )} */}
            <span>NA</span>
          </>
        );
      },
    },
    {
      dataIndex: "sellingRate",
      title: "Rate",
      width: "8%",
      render: (text: any, record: ReviewOrderDetails) => {
        return (
          <span>
            <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
            {record.sellingRate?.toFixed(2)}
          </span>
        );
      },
    },
    {
      dataIndex: "totalSaleableStock",
      title: "Available Qty",
    },
    {
      dataIndex: "quantity",
      width: "12%",
      title: "Quantity",
      render: (text: any, record: ReviewOrderDetails, index: number) => (
        <Box
          display="flex"
          alignItems="center"
          sx={{ border: "1px solid #ececec" }}
        >
          <IconButton
            onClick={() =>
              handleQuantityChange(
                index,
                record.quantity - 1,
                record.productHierarchy,
                record.quantity,
                record.totalSaleableStock
              )
            }
            disabled={record.quantity <= 0}
            sx={{ height: 21, width: 20 }}
          >
            <Remove sx={{ color: "#7e57c2", fontSize: "14px" }} />
          </IconButton>
          <TextField
            value={record.quantity.toString().padStart(2, "0")}
            inputProps={{
              style: {
                textAlign: "center",
                width: 30,
                height: 21, // Adjust height to match IconButton height
                fontSize: "10px", // Adjust font size for better visibility
                background: "#F6F6F6",
                border: "none", // Ensure no border to match IconButton
                outline: "none", // Remove outline on focus
                padding: 0, // Remove extra padding
              },
            }}
            onChange={(e) =>
              handleQuantityChange(
                index,
                Number(e.target.value),
                record.productHierarchy,
                record.quantity,
                record.totalSaleableStock
              )
            }
            sx={{
              "& .MuiInputBase-root": {
                borderRadius: 1,
                "&:hover": {
                  backgroundColor: "#ffffff", // Optional: Add hover background color
                },
              },
              "& .MuiOutlinedInput-notchedOutline": { border: "none" },
            }}
          />
          <IconButton
            sx={{ height: 21, width: 20 }}
            onClick={() =>
              handleQuantityChange(
                index,
                record.quantity + 1,
                record.productHierarchy,
                record.quantity,
                record.totalSaleableStock
              )
            }
            // disabled={record.lowStockOrder}
          >
            <Add sx={{ color: "#7e57c2", fontSize: "14px" }} />
          </IconButton>
        </Box>
      ),
    },
    {
      dataIndex: "freeQuantity",
      title: "Free Quantity",
    },
    {
      dataIndex: "grossAmount",
      title: "Gross Amount",
      // render: (text: string, record: ReviewOrderDetails) => {
      //   const grossAmount = record.sellingRate * record.quantity;
      //   return grossAmount.toFixed(2);
      // },
      render: (text: any, record: ReviewOrderDetails) => {
        const grossAmount = record.sellingRate * record.quantity;
        return (
          <span>
            <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
            {grossAmount?.toFixed(2)}
          </span>
        );
      },
    },
    {
      dataIndex: "schemeAmount",
      title: "Scheme Discount",

      render: (value) => {
        return (
          <span>
            <CurrencyRupeeIcon sx={{ fontSize: 10 }} /> {0}
          </span>
        );
      },
    },
    {
      dataIndex: "updateNetAmount",
      title: "Net Amount",
    },

    {
      dataIndex: "actions",
      title: "",
      render: (_: any, record: ReviewOrderDetails, index) => (
        <RemoveCircleOutline
          sx={{ fontSize: "14px", color: "red" }}
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick(index, record.productHierarchy);
          }}
        />
      ),
    },
  ];
  const tableHeightRef = useRef(400);
  const productCardRef = useRef(419);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 330;

    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };
  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    updateProductCard();
    window.addEventListener("resize", updateTableHeight);
  };
  const updateProductCard = () => {
    const productHeaderHeight = 0;
    const productFooterHeight = 190;
    const availableHeightprdouct =
      window.innerHeight - productHeaderHeight - productFooterHeight;
    productCardRef.current = availableHeightprdouct;
  };
  return (
    <Box sx={{ height: productCardRef.current }}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        color="green"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Grid
        item
        sx={{ display: "flex", height: "100%", flexDirection: "column" }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} className="pad-top-0px">
          <div className="saleable-table-scroll revieworder pad_tabRt manual_table_header">
            <Table
              className="Header-table saleable-table manual_head  "
              // rowSelection={rowSelection}
              columns={reviewOrderColumns}
              dataSource={dataRecordOrders}
              pagination={false}
              // rowClassName={(record) =>
              //   record.lowStockOrder ? "low-stock-row" : ""
              // }
              scroll={{ y: tableHeightRef.current }}
              // scroll={{ x: true }}
              loading={orderData.loading}
              showSorterTooltip={false}
              // onRow={(record) => ({
              //   onClick: () => handleRowClick(record),
              // })}
            />
          </div>
        </Grid>
        {/* <hr className="manual-hr-revieworder"></hr> */}
        <Grid
          container
          pt={2}
          pb={4}
          // mb={2}
          pl={2}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            // position:'absolute',
            bottom: 0,
            borderTop: "1px solid #DCDCDE",
            // background:'red'
          }}
        >
          <Grid item sx={{ display: "flex" }}>
            <Grid item>
              <Grid item>
                <Typography style={{ fontSize: "10px" }}>
                  {" "}
                  GST and Taxes
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                  Total Order Value ({dataRecordOrders.length} items)
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid item>
                <Typography style={{ fontSize: "10px" }}>
                  &nbsp;: <CurrencyRupeeIcon sx={{ fontSize: 10 }} />{" "}
                  {totalTaxAmount.toFixed(2)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
                  &nbsp;: <CurrencyRupeeIcon sx={{ fontSize: 10 }} />{" "}
                  {totalNetAmount.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <LoadingButton
              variant="contained"
              loadingPosition="start"
              loading={buttonLoader}
              // className={`${newQuantityRecords.length > 0 ? "mannual-crt-btn-colr" : ""
              //     }`}
              className="mannual-crt-btn-colr"
              type="submit"
              sx={{ mr: 2 }}
              onClick={handleConvert}
              // disabled
            >
              {" "}
              Convert to Bill
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={deleteIndex !== null}
        // onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: "350px" }}>
          <DialogContentText
            className="dialog-title-remove"
            id="alert-dialog-description"
            style={{ paddingBottom: "10px" }}
          >
            Are you sure you want to discard the manual order?
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: "12px", color: "black" }}
          >
            Cancel to return to the order without discarding, or discard to
            proceed with discarding the manual order.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="crt-btn-cancel"
            style={{
              height: "30px",
              width: "25%",
              marginRight: "3px",
              fontSize: "12px",
            }}
            onClick={handleDeleteCancel}
          >
            {" "}
            Cancel
          </Button>
          <Button
            variant="contained"
            className="crt-btn-colr"
            style={{ height: "30px", width: "25%", fontSize: "12px" }}
            type="submit"
            sx={{ mr: 2 }}
            onClick={handleRemove}
          >
            {" "}
            Discard
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
