import React, { useEffect, useRef, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import { Grid, Stack, Typography, Button, Checkbox, Card } from "@mui/material";
import MainCard from "../../../components/MainCard";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { camelCaseToNormal } from "../../../utils/constant";
import SearchBar from "@mkyy/mui-search-bar";
import type { TableColumnsType } from "antd";
import { Table, Tooltip } from "antd";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { ReactComponent as SchemeSVG } from "../../../assets/images/icons/scheme.svg";
import { ReactComponent as RecommendedSVG } from "../../../assets/images/icons/recommend.svg";
import { ReactComponent as MustSellSVG } from "../../../assets/images/icons/mustSell.svg";
import { ReactComponent as FocusSVG } from "../../../assets/images/icons/focus.svg";
import { useTheme } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import { storeAllSeletedData } from "../../../Redux/XDM/Inventory/filterReducer";
import { ReactComponent as ObjectsSVG } from "../../../assets/images/icons/objects.svg";
import cartImage from "../../../assets/images/icons/Obj_png.png";
import addedImage from "../../../assets/images/icons/Check_ring_light.png";
import {
  getSaleableCounts,
  getSaleableProducts,
} from "../../../Redux/XDM/Inventory/saleableReducer";
import { decodeJwt } from "../../../utils/constant";
import dayjs from "dayjs";
import debounce from "lodash.debounce";
import LoadingButton from "@mui/lab/LoadingButton";
import MannualRightOrder from "./MannualRightTable";
import {
  getManualOrderAllProducts,
  getManualOrderLastOrder,
  getManualOrderSchemeData,
  manualReqAddToCart,
  showDisablecheckbox,
} from "../../../Redux/XDM/Sales/ManualOrderReducer";
import { useLocation } from "react-router-dom";
import { getOutletData } from "../../../Redux/XDM/Sales/outletReducer";
import { Height } from "@mui/icons-material";

// Define the type for the shadow prop

interface DataProps {
  label: string;
  value: number | string | any;
}

const roundValue = (label: string, val: number | string): string => {
  if (
    typeof val === "number" &&
    (label === "stockValue" || label === "marginValue")
  ) {
    return formatIndianNumber(val);
  }
  return val?.toString();
};
const formatIndianNumber = (num: number): string => {
  const numStr = num.toString();

  const [integerPart, decimalPart] = numStr.split(".");
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);

  if (otherDigits !== "") {
    lastThreeDigits = "," + lastThreeDigits;
  }

  const formattedIntegerPart =
    otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

  return formattedIntegerPart;
};

const SchemeIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={SchemeSVG} viewBox="0 0 24 24" />
);
const RecommendedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={RecommendedSVG} viewBox="0 0 24 24" />
);
const MustSellIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={MustSellSVG} viewBox="0 0 24 24" />
);
const FocusIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={FocusSVG} viewBox="0 0 24 24" />
);
const ObjectsIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={ObjectsSVG} viewBox="0 0 24 24" />
);
interface manaulOrderSchemeData {
  productHierarchy: string;
  flags: string[];
  mrp: number;
  basicMaterial: string;
  matGrp2Desc: string;
  totalSaleableStock: number;
  sellingRate: number;
  productCode: string;
  uom: string;
  division: string;
  brand: string;
  variant: string;
  sgstOrUgstPercentage: number;
  cgstPercentage: number;
  sgstPercentage: number;
  igstPercentage: number;
  noOfPcs: number;
}

interface ManualOrderDataProps {
  type: string;
}
const MannualLeftTable: React.FC<ManualOrderDataProps> = ({ type }) => {
  const [textFieldValue, setTextFieldValue] = React.useState<string>("");
  const tableHeightRef = useRef(410);
  const productCardRef = useRef(419);
  const tableHeightLoad = useRef(410);

  const filterTags = useAppSelector((state) => state.filterInv);
  const filterData = {
    page: 0,
    size: 0,
    // status: filterTags.statusSelected,
    // division: filterTags.divisionSelected.toString(),
    // category: filterTags.outletGroupSelected.toString(),
    search: textFieldValue,
  };

  const dispatch = useAppDispatch();
  const previousData = useAppSelector(
    (state) => state.manualOrderdata.previousSelectedData
  );
  const [isShow, setIsShow] = React.useState(
    previousData.length > 0 ? true : false
  );

  let manualOrderdata = useAppSelector((state) => state.manualOrderdata);
  let manualOrderdataSchemeData = manualOrderdata.manualScheme;
  let manualOrderdataAllProducts = manualOrderdata.manualAllProducts;
  let manualOrderdataLastOrderProducts = manualOrderdata.manualLastOrder;
  const location = useLocation();
  const [filteredData, setFilteredData] = useState<manaulOrderSchemeData[]>([]);
  // useEffect(()=>{
  //   if( previousData.length ){
  //     setIsShow(false)
  //   }

  // },[isSho])
  const getQueryParams = (query: any) => {
    return new URLSearchParams(query);
  };

  const queryParams = getQueryParams(location.search);
  const outletCode = queryParams.get("outletcode");
  const abortControllerRef = useRef<AbortController | null>(null);
  const previousAddedData = useAppSelector(
    (state) => state.manualOrderdata.previousSelectedData
  );
  const fetchData = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setFilteredData([]);
    if (
      (type === "MS SKU" ||
        type === "Recommended" ||
        type === "Focus" ||
        type === "Scheme") &&
      textFieldValue === ""
    ) {
      dispatch(
        getManualOrderSchemeData({
          params: {
            outletCode: outletCode,
            searchTerm: null,
            flags: type,
          },
          signal: abortController.signal,
        })
      );
    } else if (type === "all-products" && textFieldValue === "") {
      dispatch(
        getManualOrderAllProducts({
          outletCode: outletCode,
          signal: abortController.signal,
        })
      );
    } else if (type === "last-order" && textFieldValue === "") {
      dispatch(
        getManualOrderLastOrder({
          outletCode: outletCode,
          signal: abortController.signal,
        })
      );
    }
  }, [type, textFieldValue, dispatch, outletCode]);

  useEffect(() => {
    fetchData();
    // setRequestData([]);
    // setTempRequestData([]);
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [type, fetchData]);

  useEffect(() => {
    setFilteredData([]);
    // setRequestData([]);
    // setTempRequestData([]);

    setFilteredData(
      manualOrderdataSchemeData.filter((item) => item.totalSaleableStock > 0)
    );
  }, [manualOrderdataSchemeData]);

  useEffect(() => {
    setFilteredData([]);
    loadMoreData(true);

    // setRequestData([]);
    // setTempRequestData([]);
    setFilteredData(
      manualOrderdataAllProducts.filter((item) => item.totalSaleableStock > 0)
    );
  }, [manualOrderdataAllProducts]);
  useEffect(() => {
    setFilteredData([]);
    setFilteredData(
      manualOrderdataLastOrderProducts.filter(
        (item) => item.totalSaleableStock > 0
      )
    );
  }, [manualOrderdataLastOrderProducts]);

  const handleSearch = debounce((searchTerm) => {
    const datas =
      type === "all-products"
        ? manualOrderdataAllProducts
        : type === "last-order"
        ? manualOrderdataLastOrderProducts
        : manualOrderdataSchemeData;

    const filtered = datas.filter((record: any) => {
      const productName = record.basicMaterial || record.matGrp2Desc;
      const productHierarchy = record.productHierarchy.toString();
      const mrp = record.mrp.toString();
      const sellingRate = record.sellingRate.toString();

      return (
        (productName?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          productHierarchy?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          mrp.includes(searchTerm) ||
          sellingRate.includes(searchTerm)) &&
        record.totalSaleableStock !== 0
      );
    });
    setFilteredData(filtered);
  }, 300);
  const handleclearData = () => {
    setRequestData([]);
    setTempRequestData([]);
  };
  const [requestData, setRequestData] = useState<manaulOrderSchemeData[]>([]);
  const columns: TableColumnsType<manaulOrderSchemeData> = [
    {
      dataIndex: "nearToExpiry",
      title: "",
      // title: (
      //   <Checkbox
      //     className="sidebar_checkbox"
      //     sx={{
      //       "&:hover": {
      //         backgroundColor: "transparent", // Remove the hover background color
      //       },
      //       "& .MuiSvgIcon-root": {
      //         fontSize: 15,
      //         marginBottom: "9px",
      //         color: "#6750A4",
      //       },
      //     }}
      //   />
      // ),
      width: "5%",
      render: (_: any, record: manaulOrderSchemeData) => {
        // Skip rendering for special row
        let classes = "near-to-expiry-column";
        const uniqueFlags = Array.from(new Set(record.flags));
        if (record.flags) {
          classes += " mannual-scheme-border";
        }
        return (
          <div className={`w-100px ${classes}`}>
            {/* <Checkbox
              className="sidebar_checkbox"
              sx={{
                "&:hover": {
                  backgroundColor: "transparent", // Remove the hover background color
                },
                "& .MuiSvgIcon-root": {
                  fontSize: 15,
                  marginBottom: "9px",
                  color: "#6750A4",
                },
              }}
            /> */}
            {uniqueFlags.includes("Scheme") && (
              <Tooltip
                placement="top"
                title="Scheme"
                overlayInnerStyle={{
                  backgroundColor: "#f0f6fc",
                  color: "#27538d",
                  fontSize: "10px",
                }}
              >
                <SchemeIcon className="icon scheme man-scheme-icons" />
              </Tooltip>
            )}
            {uniqueFlags.includes("Recommended") && (
              <Tooltip
                placement="top"
                title="Recommended"
                overlayInnerStyle={{
                  backgroundColor: "#f0f6fc",
                  color: "#27538d",
                  fontSize: "10px",
                }}
              >
                <RecommendedIcon className="icon scheme man-scheme-icons" />
              </Tooltip>
            )}
            {uniqueFlags.includes("MS SKU") && (
              <Tooltip
                placement="top"
                title="MS SKU"
                overlayInnerStyle={{
                  backgroundColor: "#f0f6fc",
                  color: "#27538d",
                  fontSize: "10px",
                }}
              >
                <MustSellIcon className="icon scheme man-scheme-icons" />
              </Tooltip>
            )}
            {uniqueFlags.includes("Focus") && (
              <Tooltip
                placement="top"
                title="Focus"
                overlayInnerStyle={{
                  backgroundColor: "#f0f6fc",
                  color: "#27538d",
                  fontSize: "10px",
                }}
              >
                <FocusIcon className="icon scheme man-scheme-icons" />
              </Tooltip>
            )}
          </div>
        );
      },
    },

    {
      dataIndex: "productName",
      title: "Product Name / Code",
      width: "45%",

      render: (text: string, record: manaulOrderSchemeData) => {
        const prdName = record.basicMaterial
          ? record.basicMaterial
          : record.matGrp2Desc;
        // const displayText =
        //   prdName.length > 35
        //     ? `${prdName.substring(0, 35)}...`
        //     : record.basicMaterial;
        return (
          <Tooltip placement="top" title={prdName} zIndex={999999}>
            <b>{prdName}</b>
            <br />
            <span>{record.productHierarchy}</span>
          </Tooltip>
        );
      },
    },

    {
      dataIndex: "value",
      title: "Price",
      width: "20%",
      render: (_: any, record: manaulOrderSchemeData) => {
        return (
          <>
            <b>
              Rate: <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
              {record.sellingRate?.toFixed(2)}
            </b>
            <br />
            <span>
              {" "}
              Mrp: <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
              {record.mrp?.toFixed(2)}
            </span>
          </>
        );
      },
    },
    {
      dataIndex: "actions",
      title: "",
      width: "25%",
      render: (_: any, record: manaulOrderSchemeData) => {
        const isAdded = tempRequestData.some(
          (item: any) => item.productHierarchy === record.productHierarchy
        );
        const isAddedReducer = previousData.some(
          (item: any) => item.productHierarchy === record.productHierarchy
        );
        return (
          <>
            {isAdded || isAddedReducer ? (
              <Button className="added-btn">
                Added
                <img
                  src={addedImage}
                  width={"12px"}
                  alt="Check_ring_light"
                  style={{ marginLeft: "5px" }}
                />
              </Button>
            ) : (
              <Button
                className="add-to-cart-btn"
                onClick={() => HandleAddToCart(record)}
              >
                Add To Cart
              </Button>
            )}
          </>
        );
      },
    },
  ];
  const isLoading = (() => {
    switch (type) {
      case "all-products":
        return manualOrderdata.manualAllProductsLoading;
      case "last-order":
        return manualOrderdata.manualLastOrderLoading;
      default:
        return manualOrderdata.manualSchemeLoading;
    }
  })();

  const tableClassName = `Header-table saleable-table saleDataTable_text saleDataTable sale_last_hide man_left_tab Header_chechbox antcheck_manual ${
    isLoading ? "nodataloader" : ""
  }`;

  const [tempRequestData, setTempRequestData] = useState<
    manaulOrderSchemeData[]
  >([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: any[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(selectedRows);
  };
  const combinedProductHierarchies = [
    ...tempRequestData.map((item) => item.productHierarchy),
    ...requestData.map((item) => item.productHierarchy),
  ];

  // useEffect(() => {
  //   // Dispatch the combined product hierarchies to the showDisablecheckbox async thunk
  //   dispatch(showDisablecheckbox(combinedProductHierarchies));
  // }, [dispatch, combinedProductHierarchies]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: manaulOrderSchemeData) => ({
      disabled:
        tempRequestData.some(
          (item) => item.productHierarchy === record.productHierarchy
        ) ||
        previousAddedData.some(
          (item) => item.productHierarchy === record.productHierarchy
        ),
    }),
  };

  const HandleAddToCart = async (record: manaulOrderSchemeData) => {
    setRequestData([record]);
    setTempRequestData((prevItems) => [...prevItems, record]);
    setIsShow(true);
  };
  const handleMultiCart = (records: manaulOrderSchemeData[]) => {
    setRequestData(records);
    setTempRequestData((prevItems) => [...prevItems, ...records]);
    setIsShow(true);
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  const handleRemoveFromRequestData = (productHierarchy: string) => {
    setRequestData((prevData) =>
      prevData.filter((item) => item.productHierarchy !== productHierarchy)
    );
    setTempRequestData((prevData) =>
      prevData.filter((item) => item.productHierarchy !== productHierarchy)
    );
  };

  const updateProductCard = () => {
    const productHeaderHeight = 0;
    const productFooterHeight = 280;
    const availableHeightprdouct =
      window.innerHeight - productHeaderHeight - productFooterHeight;
    productCardRef.current = availableHeightprdouct;
  };
  //
  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 330;

    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };
  const updateTableHeightLoad = () => {
    const headerHeightload = 0;
    const footerHeightload = 300;

    const availableHeightLoad =
      window.innerHeight - headerHeightload - footerHeightload;

    tableHeightLoad.current = availableHeightLoad;
  };

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    updateProductCard();
    updateTableHeightLoad();
    window.addEventListener("resize", updateTableHeight);
  };

  return (
    <Box>
      <Grid container>
        <Grid
          item
          spacing={3}
          justifyContent="start"
          pl={2}
          pr={2}
          xs={12}
          sm={6}
          md={5}
          lg={5}
        >
          <Grid container style={{ margin: "4px 0px 12px 0px" }}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <SearchBar
                className="w-100px man_elip_input"
                placeholder="Search Product Name,Code,Division "
                value={textFieldValue}
                onChange={(newValue) => {
                  setTextFieldValue(newValue);
                  handleSearch(newValue);
                }}
                onCancelResearch={() => setTextFieldValue("")}
                style={{ height: "26px" }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            pt={1}
            className="pad-top-0px"
            sx={{ height: tableHeightLoad.current }}
          >
            <div className="saleable-table-scroll sale  mannual_table elipsTable">
              <Table
                className={tableClassName}
                columns={columns}
                dataSource={filteredData}
                pagination={false}
                // scroll={{ y: 450 }}
                scroll={{ y: tableHeightRef.current }}
                loading={isLoading}
                // This hides the "No Data" box
                // This hides the "No Data" box
                // loading={
                //   type === "all-products"
                //     ? manualOrderdata.manualAllProductsLoading
                //     : manualOrderdata.manualSchemeLoading
                // }
                // rowKey={(record) => "productCode" in record ? record.productCode : "noMoreData"}
                showSorterTooltip={false}
                // onRow={(record: TableRowType) => {
                //     if ("noMoreData" in record) return {};
                //     return {
                //         onClick: () => HandleAddToCart(record as Product),
                //     };
                // }}
                rowSelection={rowSelection}
                rowKey={(record) => record.productHierarchy}
              />
            </div>
          </Grid>
          <Grid
            container
            pt={2}
            pb={4}
            pl={2}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              bottom: 0,
              position: "relative",
              top: 0,
            }}
          >
            <Grid item>
              <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                selected {selectedRowKeys.length}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className="mannual-crt-btn-colr"
                type="submit"
                sx={{ mr: 2 }}
                onClick={() =>
                  handleMultiCart(selectedRows as manaulOrderSchemeData[])
                }
              >
                {" "}
                Add To Cart
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5.9} md={6.9} lg={6.9}>
          <Card
            className="manual_rght-card manual_card_box"
            sx={{
              height: "100%",

              overflowY: tableHeightRef.current,
            }}
          >
            <Grid
              item
              sx={{
                width: "100%",
                margin: "auto",
                marginBottom: "20px",
              }}
            >
              <Grid mt={2}>
                <Grid item style={{ padding: "0px 10px" }}>
                  <Typography className="outlet-lable-input" mt={1} mb={1}>
                    Cart
                    <span style={{ fontSize: "11px", fontWeight: "500" }}>
                      {" "}
                      ({previousData.length} items)
                    </span>
                  </Typography>
                </Grid>
                <hr className="manual-hr-revieworder"></hr>
                <Grid item>
                  {!isShow && (
                    <>
                      <Grid
                        item
                        className="emty_manual pad-a00"
                        sx={{ height: productCardRef.current }}
                        component="div"
                      >
                        <Grid item>
                          <img src={cartImage} width={"130px"} alt="object" />
                        </Grid>
                        <Grid
                          item
                          className="emty_manual"
                          style={{ width: "250px" }}
                        >
                          <Typography
                            style={{ fontSize: "12px", fontWeight: "700" }}
                          >
                            Add Products to Cart
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "10px",
                              color: "#73757A",
                              textAlign: "center",
                            }}
                          >
                            Browse our catalog and select items to populate your
                            order list.
                          </Typography>
                        </Grid>
                      </Grid>
                      {/* <hr className="manual-hr-revieworder"></hr> */}
                      <Grid
                        container
                        pt={2}
                        pb={4}
                        // mb={2}
                        pl={2}
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          // position:'absolute',
                          bottom: 0,
                          borderTop: "1px solid #DCDCDE",
                          // background:'red'
                        }}
                      >
                        <Grid item sx={{ display: "flex" }}>
                          <Grid item>
                            <Grid item>
                              <Typography style={{ fontSize: "10px" }}>
                                {" "}
                                GST and Taxes
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                sx={{ fontSize: "12px", fontWeight: "bold" }}
                              >
                                Total Order Value (0 items)
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid item>
                              <Typography style={{ fontSize: "10px" }}>
                                &nbsp;:{" "}
                                <CurrencyRupeeIcon sx={{ fontSize: 10 }} /> 0
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                              >
                                &nbsp;:{" "}
                                <CurrencyRupeeIcon sx={{ fontSize: 10 }} /> 0
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item>
                          <LoadingButton
                            variant="contained"
                            loadingPosition="start"
                            type="submit"
                            sx={{ mr: 4 }}
                            disabled
                          >
                            {" "}
                            Convert to Bill
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {isShow && (
                    <>
                      <MannualRightOrder
                        requestData={requestData}
                        clearRequestData={handleclearData}
                        removeFromRequestData={handleRemoveFromRequestData}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MannualLeftTable;
