import { lazy, LazyExoticComponent } from "react";
import { FC } from "react";
// import Loadable from 'react-loadable';
import Loadable from "../components/Loadable";
import { PERMISSIONS } from "../utils/constant";
import MainLayout from "../Layout/MainLayout";
import DashboardDefault from "../Pages/Dashboard/index";
// import DashboardDefault from "../Pages/Dashboard/index";
//comapny
import CompanyMainCard from "../Pages/CompanyMainCard/index";
import Supplier from "../Pages/CompanyMainCard/Supplier/index";
import Company from "../Pages/CompanyMainCard/Company";
//configuration
import Configuration from "../Pages/Configurations/index";
import GeographyHierarchyLevel from "../Pages/Configurations/GeographyHierarchyLevel/index";
import ETL from "../Pages/Configurations/ETL/index";
import GeographyHierarchyLevelValue from "../Pages/Configurations/GeographyHierarchyLevelValue/index";
import Reset from "../Pages/Configurations/Reset/Reset";
import Reason from "../Pages/Configurations/Reason/index";
import SalesHierarchyLevel from "../Pages/Configurations/SalesHierarchyLevel/index";
import SupplyChainMapping from "../Pages/Configurations/SupplyChainMapping/index";
import JcCalendar from "../Pages/Configurations/JcCalendar";
import UnlockUser from "../Pages/Configurations/ResetLogin";
import SalesHierarchyLevelValue from "../Pages/Configurations/SalesHierarchyLevelValue/index";
import UserProfile from "../Pages/Configurations/UserProfile/index";
import TCSConfiguration from "../Pages/Configurations/TCS_TDS_Configuration/index";
import UserGroup from "../Pages/Configurations/UserGroup/index";
import KeyGenerator from "../Pages/Configurations/KeyGenerator/index";
import SurveyDefinition from "../Pages/Configurations/SurveyDefinition";

//Customer
import Customer from "../Pages/Customer/index";
import RetailerChannel from "../Pages/Customer/RetailerChannel/index";
import RetailerClass from "../Pages/Customer/RetailerClass/index";
import RetailerGroup from "../Pages/Customer/RetailerGroup/index";
import Credit from "../Pages/Customer/CreditNotUpload/index";
import CustomerTable from "../Pages/Customer/CustomerView/index";
import CustomerMigration from "../Pages/Customer/CustomerMigration/index";
import RetailerTargetUpload from "../Pages/Customer/RetailerTargetUpload/index";
import Mustvisitoutlit from "../Pages/Customer/MustVisitOutlit";

//Distribution
import DistributionDashboard from "../Pages/Distribution/index";
import Route from "../Pages/Distribution/Route/index";
import RouteCoveragePlan from "../Pages/Distribution/RouteCoveragePlan";
import DistributorDivision from "../Pages/Distribution/DistributorDivisionMapping";
import Distributor from "../Pages/Distribution/Distributor";
import SalesMan from "../Pages/Distribution/SalesMan/index";
import SMRouteMap from "../Pages/Distribution/SalesManRouteMapping";
import SalesmanTarget from "../Pages/Distribution/SalesmanTarget/index";
import SalesmanSKULineMapping from "../Pages/Distribution/SalesmanSKULineMapping/index";

//Product
import ProductPrice from "../Pages/Product&Price/index";
import GSTTaxstructure from "../Pages/Product&Price/GSTTaxstructure";
import FocusBrand from "../Pages/Product&Price/FocusBrand";
import PriceDiscount from "../Pages/Product&Price/PriceDiscount/index";
import ProductHierarchyLevel from "../Pages/Product&Price/ProductHierarchyLevel/index";
import ProductPriceUpload from "../Pages/Product&Price/ProductPriceUpload/index";
import UomMaster from "../Pages/Product&Price/UomMaster/index";
import PrimaryUpload from "../Pages/Product&Price/PrimaryUpload/index";
import GSTProductTaxStructureTax from "../Pages/Product&Price/GSTProductTaxStructureMappin";
import ProductHierarchyValue from "../Pages/Product&Price/ProductHierarchyValue";
import Product from "../Pages/Product&Price/Product";

//Schemes & Claims
import SchemesClaims from "../Pages/Schemes&Claims/index";
import SchemeMasterControl from "../Pages/Schemes&Claims/SchemeMasterControl/index";
import Scheme from "../Pages/Schemes&Claims/Scheme/index";

//purchase
import PurchaseInvoices from "../Pages/Purchase/PurchaseInvoices/index";
import PurchaseRequest from "../Pages/Purchase/PurchaseRequest/index";
import PurchaseReturn from "../Pages/Purchase/PurchaseReturn/index";
import PurchaseAccount from "../Pages/Purchase/Account/index";
//Sales
import SalesCompany from "../Pages/Sales/index";
//inventory
import Inventory from "../Pages/Inventory/index";
//Reports
import Reports from "../Pages/Reports/index";
// company
import CompanyDashboard from "../Pages/Masters/Company/DashboardCompany";

//Masters

import MasterSupplier from "../Pages/Masters/Supplier/index";
// import MasterProduct from "../Pages/Masters/Product/ProductUom";
import MasterProduct from "../Pages/Masters/Product/uom/index";

import MasterConfiguration from "../Pages/Masters/Configuration/GeoHierarchy/GeoHierarchy";
import MasterProductDashboard from "../Pages/Masters/Product/index";
import ProductMaster from "../Pages/Masters/Product/ProductMaster";
import ProductStateCode from "../Pages/Masters/Product/ProductGstStateCode";
import ProductTaxMaster from "../Pages/Masters/Product/ProductTaxMaster";
import ProductHsnCodeMaster from "../Pages/Masters/Product/ProductHsnCodeMaster/index";
import ProductHierarchy from "../Pages/Masters/Product/ProductHierarchy";
import MasterCompany from "../Pages/Masters/Company";
import ConfigurationGrid from "../Pages/Masters/Configuration/index";
import DashboardSupplier from "../Pages/Masters/Supplier/DashboardSupplier";
import MasterProductUOM from "../Pages/Masters/Product/ProductUom";
import MasterDistributor from "../Pages/Masters/Distributor/index";
import MasterCustomer from "../Pages/Masters/Customer/index";
import ProductMustSellSKU from "../Pages/Masters/Product/ProductMustSellSKU";
import SalesHierarchy from "../Pages/Masters/Configuration/SalesHierarchy";
import ProtectedRoute from "./ProtectedRoute";

// const DashboardDefault = lazy(
//   () => import("../Pages/dashboard/index")
// );
//comapny
// const CompanyMainCard: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/CompanyMainCard/index'));
// const Supplier: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/CompanyMainCard/Supplier/index'));
// const Company: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/CompanyMainCard/Company'));

//configuration
// const Configuration: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/Configurations/index'));
// const GeographyHierarchyLevel: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/Configurations/GeographyHierarchyLevel/index'));
// const ETL: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/Configurations/ETL/index'));
// const GeographyHierarchyLevelValue: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/Configurations/GeographyHierarchyLevelValue/index'));
// const Reset: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/Configurations/Reset/Reset'));
// const Reason: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/Configurations/Reason/index'));
// const SalesHierarchyLevel: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/Configurations/SalesHierarchyLevel/index'));
// const SupplyChainMapping: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/Configurations/SupplyChainMapping/index'));
// const JcCalendar: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/Configurations/JcCalendar'))
// const UnlockUser: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Configurations/ResetLogin"))
// const SalesHierarchyLevelValue: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Configurations/SalesHierarchyLevelValue/index"))
// const UserProfile: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Configurations/UserProfile/index"))
// const TCSConfiguration: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/Configurations/TCS_TDS_Configuration/index'));
// const UserGroup: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/Configurations/UserGroup/index'));
// const KeyGenerator: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/Configurations/KeyGenerator/index'));
// const SurveyDefinition: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/Configurations/SurveyDefinition'));

//Customer
// const Customer: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/Customer/index'))
// const RetailerChannel: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/Customer/RetailerChannel/index'))
// const RetailerClass: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Customer/RetailerClass/index"))
// const RetailerGroup: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Customer/RetailerGroup/index"))
// const Credit: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Customer/CreditNotUpload/index"))
// const CustomerTable: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Customer/CustomerView/index"))
// const CustomerMigration: LazyExoticComponent<FC<{}>> = lazy(() => import('../Pages/Customer/CustomerMigration/index'));
// const RetailerTargetUpload: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Customer/RetailerTargetUpload/index"))
// const Mustvisitoutlit: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Customer/MustVisitOutlit"))

//Distribution
// const DistributionDashboard: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Distribution/index"))
// const Route: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Distribution/Route/index"))
// const RouteCoveragePlan: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Distribution/RouteCoveragePlan"))
// const DistributorDivision: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Distribution/DistributorDivisionMapping"));
// const Distributor: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Distribution/Distributor"));
// const SalesMan: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Distribution/SalesMan/index"));
// const SMRouteMap: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Distribution/SalesManRouteMapping"));
// const SalesmanTarget: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Distribution/SalesmanTarget/index"));
// const SalesmanSKULineMapping: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Distribution/SalesmanSKULineMapping/index"));

//Product
// const ProductPrice: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Product&Price/index"));
// const GSTTaxstructure: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Product&Price/GSTTaxstructure"));
// const FocusBrand: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Product&Price/FocusBrand"));
// const PriceDiscount: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Product&Price/PriceDiscount/index"));
// const ProductHierarchyLevel: LazyExoticComponent<FC<{}>> = lazy(()=> import("../Pages/Product&Price/ProductHierarchyLevel/index"));
// const ProductPriceUpload: LazyExoticComponent<FC<{}>> = lazy(()=> import("../Pages/Product&Price/ProductPriceUpload/index"));
// const UomMaster: LazyExoticComponent<FC<{}>> = lazy(()=> import("../Pages/Product&Price/UomMaster/index"));
// const PrimaryUpload: LazyExoticComponent<FC<{}>> = lazy(()=> import("../Pages/Product&Price/PrimaryUpload/index"));
// const GSTProductTaxStructureTax: LazyExoticComponent<FC<{}>> = lazy(()=> import("../Pages/Product&Price/GSTProductTaxStructureMappin"));
// const ProductHierarchyValue: LazyExoticComponent<FC<{}>> = lazy(()=> import("../Pages/Product&Price/ProductHierarchyValue"));
// const Product: LazyExoticComponent<FC<{}>> = lazy(()=> import("../Pages/Product&Price/Product"));

// //Schemes & Claims
// const SchemesClaims: LazyExoticComponent<FC<{}>> = lazy(()=> import("../Pages/Schemes&Claims/index"));
// const SchemeMasterControl: LazyExoticComponent<FC<{}>> = lazy(()=> import("../Pages/Schemes&Claims/SchemeMasterControl/index"));
// const Scheme: LazyExoticComponent<FC<{}>> = lazy(()=> import("../Pages/Schemes&Claims/Scheme/index"));

// //purchase
// const PurchaseInvoices: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Purchase/PurchaseInvoices/index"));
// const PurchaseRequest: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Purchase/PurchaseRequest/index"));
// const PurchaseReturn: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Purchase/PurchaseReturn/index"));
// const PurchaseAccount: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Purchase/Account/index"));
// //Sales
// const SalesCompany: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Sales/index"));
// //inventory
// const Inventory: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Inventory/index"));

// //Reports
// const Reports: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Reports/index"));
// const NotFound: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/NotFoundPage"));

// //Masters

// const MasterCompany: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Masters/Company/index"));
// const MasterSupplier: LazyExoticComponent<FC<{}>> = lazy(() => import("../Pages/Masters/Supplier/index"));

// project import
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/Dashboard",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <DashboardDefault />
        </ProtectedRoute>
      ),
    },
    {
      path: "/purchase/invoices",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <PurchaseInvoices />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/purchase/purchase_request",
      element: <PurchaseRequest />,
    },
    {
      path: "/purchase/purchase_retrun",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <PurchaseReturn />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/purchase/purchase_account",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <PurchaseAccount />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/sales/sales_company",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <SalesCompany />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/inventory/inventory_data",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <Inventory />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/reports/reports_data",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <Reports />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/MainCompany",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <CompanyMainCard />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/Supplier",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <Supplier />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/Company",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <Company />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/Configuration",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <Configuration />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/GeographyHierarchyLevel",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <GeographyHierarchyLevel />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/ETL",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <ETL />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/GeographyHierarchyLevelValue",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <GeographyHierarchyLevelValue />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/PasswordReset",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <Reset />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/Reason",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <Reason />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/SalesHierarchyLevel",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <SalesHierarchyLevel />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/SupplyChainMapping",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <SupplyChainMapping />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/JcCalander",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <JcCalendar />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/CustomerTable",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <CustomerTable />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/Customer",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <Customer />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/ResetUserLock",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <UnlockUser />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/SalesHierarchyLevelValue",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <SalesHierarchyLevelValue />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/RetailerChannel",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <RetailerChannel />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/RetailerClass",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <RetailerClass />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/RetailerGroup",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <RetailerGroup />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/Credit",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <Credit />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/UserProfile",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <UserProfile />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/TCSConfiguration",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <TCSConfiguration />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/UserGroup",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <UserGroup />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/KeyGenerator",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <KeyGenerator />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/SurveyDefinition",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <SurveyDefinition />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/CustomerMigration",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <CustomerMigration />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/RetailerTargetUpload",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <RetailerTargetUpload />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/Mustvisitoutlit",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <Mustvisitoutlit />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/Distribution",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <DistributorDivision />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/Distributor",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <Distributor />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/MainDistribution",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <DistributionDashboard />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/Route",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <Route />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/RouteCoveragePlan",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <RouteCoveragePlan />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/salesman",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <SalesMan />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/SMRouteMap",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <SMRouteMap />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/SalesmanTarget",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <SalesmanTarget />,
        </ProtectedRoute>
      ),
    },

    {
      path: "/SalesmanSKULineMapping",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <SalesmanSKULineMapping />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/product_price",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <ProductPrice />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/gst_tax_structure",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <GSTTaxstructure />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/focus_brand",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <FocusBrand />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/product_hierarchy_level",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <ProductHierarchyLevel />,
        </ProtectedRoute>
      ),
    },

    {
      path: "/price_discount",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <PriceDiscount />,
        </ProtectedRoute>
      ),
    },

    {
      path: "/product_price_upload",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <ProductPriceUpload />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/uom_master",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <UomMaster />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/primary_upload",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <PrimaryUpload />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/gst_product_tax_structure",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <GSTProductTaxStructureTax />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/product_hierarchy_value",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <ProductHierarchyValue />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/Product",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <Product />,
        </ProtectedRoute>
      ),
    },

    {
      path: "/schemes_claims",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <SchemesClaims />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/scheme_master_control",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <SchemeMasterControl />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/scheme",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <Scheme />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/master/product_dashboard",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <MasterProductDashboard />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/master/company_dashboard",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <CompanyDashboard />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/master/company_master",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <MasterCompany />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/master/dashboard_supplier",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <DashboardSupplier />,
        </ProtectedRoute>
      ),
    },

    {
      path: "/master/master_supplier",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <MasterSupplier />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/master/master_Product_UOM",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <MasterProductUOM />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/master/master_configuration",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <MasterConfiguration />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/master/master_distributor",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <MasterDistributor />,
        </ProtectedRoute>
      ),
    },

    {
      path: "/master/product_master",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <ProductMaster />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/master/product/product_state_code",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <ProductStateCode />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/master/product_tax_master",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <ProductTaxMaster />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/master/product_Must_Sell_SKU",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <ProductMustSellSKU />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/master/product/product_hsn_code",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <ProductHsnCodeMaster />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/master/product/Product_hierarchy",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <ProductHierarchy />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/master/Configuration_dashboard",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <ConfigurationGrid />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/master/master_customer",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <MasterCustomer />,
        </ProtectedRoute>
      ),
    },
    {
      path: "/master/MasterProductaa",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <MasterProduct />
        </ProtectedRoute>
      ),
    },
    {
      path: "/master/configuration_salesHierarchy",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_ADMIN]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <SalesHierarchy />,
        </ProtectedRoute>
      ),
    },
  ],
};

export default MainRoutes;
