import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Grid,
  Paper,
  Typography,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockModal from "../../../components/Modal/LockModel";
import { useNavigate } from "react-router-dom";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  UserCode: string;
  UserName: string;
  action: string;
}

const ResetLogin: React.FC = () => {
  const navigator = useNavigate();
  const [radioVal, setRadioVal] = React.useState("Company");
  const [unLock, setUnLock] = React.useState(false);

  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    //search function logic here
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "UserCode",
      title: "User Code",
      sorter: (a, b) => a.UserCode.length - b.UserCode.length,
    },
    {
      dataIndex: "UserName",
      title: "User Name",
      sorter: (a, b) => a.UserName.length - b.UserName.length,
    },
    {
      dataIndex: "action",
      title: "Action",
      render: (_: any, record: DataType) => (
        <>
          <LockOpenIcon
            onClick={(event) => HandleUnLockClick(event)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
        </>
      ),
    },
  ];
  const data: DataType[] = [
    { key: 1, UserCode: "C002536", UserName: "Vanisri", action: "" },
    { key: 2, UserCode: "C002534", UserName: "Shenbagam", action: "" },
    { key: 3, UserCode: "C002538", UserName: "Mani", action: "" },
    { key: 4, UserCode: "C002535", UserName: "Divya", action: "" },
    { key: 5, UserCode: "C002537", UserName: "Gowtham", action: "" },
    { key: 6, UserCode: "C002533", UserName: "Sri", action: "" },
  ];

  const HandleChangeGo = () => {
    setRadioVal("Company");
  };
  const HandleUnLockClick = (event: React.MouseEvent<unknown>) => {
    setUnLock(!unLock);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <Grid item sx={{ p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
      <Grid item xs={12} className=" mar-tb">
        <Typography>
          <b className="header-lable">Reset Login &nbsp; Unlock User</b>
        </Typography>
      </Grid>

      <Paper elevation={0} className="pad-10 mar-tb">
        <Box className="card-form">
          <FormControl>
            <FormLabel className="fnt-lable">User Status</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="Logged-In"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label="Logged-In"
              />
              <FormControlLabel
                value="	Locked"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label="	Locked"
              />
            </RadioGroup>
            <FormLabel className="fnt-lable">Search Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={radioVal}
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue !== radioVal) {
                  setRadioVal(newValue);
                }
              }}
            >
              <FormControlLabel
                value="Company"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label="Company"
              />
              <FormControlLabel
                value="Distributor"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label="	Distributor"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Paper>
      <Box>
        <Grid item sx={{ mt: 2 }}>
          {radioVal == "Distributor" ? (
            <>
              <form>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        <InputLabel size="normal">
                          Search By<span></span>
                        </InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl fullWidth>
                          <Select id="demo-simple-select">
                            <MenuItem value={10}>Distributor Code</MenuItem>
                            <MenuItem value={20}>Distributor Name</MenuItem>
                            <MenuItem value={30}>user Code</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        <InputLabel size="normal">
                          Search Input<span></span>
                        </InputLabel>
                      </Grid>
                      <Grid item xs={2}>
                        <TextField variant="outlined" />
                      </Grid>
                      <Grid item xs={2}>
                        <Button variant="contained" onClick={HandleChangeGo}>
                          Go
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </>
          ) : (
            <Box>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "end", mb: 2 }}
              >
                <SearchBar
                  className="search-table"
                  value={textFieldValue}
                  onChange={(newValue) => {
                    setTextFieldValue(newValue);
                    handleSearch();
                  }}
                />
              </Grid>
              <CommonTable
                // rowSelection={rowSelection}
                data={data}
                columns={columns}
              />
              {unLock && (
                <LockModal onBack={(event) => HandleUnLockClick(event)} />
              )}
            </Box>
          )}
        </Grid>
      </Box>
    </Grid>
  );
};
export default ResetLogin;
