import React from "react";
import {
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";

import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../../components/Modal/DeleteSupplier";

import UndoIcon from "@mui/icons-material/Undo";


interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const ViewSMRoute: React.FC<DistributorTabProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const Salesman = ["Select One", "SRI SAI HANUMAN AGENCIES", ""];
  const Branchcode = [
    "Select One",
    "6243304637-VIGNESH AGENCY",
    " 5072066471-SRI LAKSHMI KANTHA AGENCIES",
  ];



  const [isDelete, setIsDelete] = React.useState(false);

  const handleDeleteClick = (event: React.MouseEvent<unknown>) => {
    setIsDelete(!isDelete);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Route Code",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "RouteName",
      headerName: "Route Name",
      type: "number",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },

    {
      field: "action",
      headerName: "Action",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <DeleteIcon
            onClick={() => handleDeleteClick(params.row.id)}
            style={{
              cursor: "pointer",
              marginRight: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />
        </>
      ),
    },
  ];

  const rows = [
    {
      id: "GT0100020072200002007220-ROU001",
      RouteName: "MAIN ROAD",
    },
  ];

  return (
    <>
      <Box className="card-form">
        <form noValidate>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 10 }}>
            <Grid item xs={12} sm={6}>
              <InputLabel size="normal">Distributor Branch Code </InputLabel>
              <FormControl fullWidth>
                <Select>
                  {Branchcode.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel size="normal">Distributor Branch Code </InputLabel>
              <FormControl fullWidth>
                <Select>
                  {Salesman.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>{" "}
            <Grid item xs={12} sm={6}>
              <Typography>Attach Routes </Typography>
              <TextField variant="outlined" sx={{ width: "88%" }} />
              <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
                ...
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button sx={{ ml: 1, mt: 2, color: "#fff" }} className="btn-colr">
                + Add Route
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
            <Box sx={{ marginTop: "2px",  }}>
              <Box>
                <Grid item sx={{ mt: 2 }}>
                  <Box>
               
              <DataGrid
                  rows={rows}
                  columns={columns}
                  className="Header-table"
                />

                    {isDelete && (
                      <DeleteModal
                        onBack={(event) => handleDeleteClick(event)}
                      />
                    )}
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Grid>
          </Grid>
       

          <Grid item xs={12} mt={4} display="flex" justifyContent="center">
            <Grid item sx={{ ml: 2 }}>
              <Button variant="outlined" onClick={handleBackClick}>
                <UndoIcon sx={{ color: "", fontSize: "15px", mr:1 }} /> Back
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default ViewSMRoute;
