import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useFetch";
//icons
import Close from "../../../../assets/images/icons/Close_icon.png";
import Editicon from "../../../../assets/images/icons/edit_icon.png";
import calendar from "../../../../assets/images/icons/Calendar.png";


import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

// DatePicker
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { HSNcodeFormValueErrors, HSNcodeFormValues } from "../../../../typescript/product";
import { HSNcodeFetchData, HSNcodeUpdate } from "../../../../Redux/Masters/Products/HSNcode/action";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface RequestData {
  page: number;
  size: number;
  hsnType: string;
}
interface EditMasterProductProps {
  onClose: () => void;
  requestData: RequestData;

}
export default function EditProductHsnCodeMaster(props: EditMasterProductProps) {
  const dispatch = useAppDispatch();
  const { onClose, requestData } = props; // Destructure the props
  const [open, setOpen] = React.useState(false);
  const [isEditEnabled, setIsEditEnabled] = React.useState(true);
  const [FormError, setFormError] = useState<boolean>(false);
  const [sucessMessage, setSucessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const HsnCodeEditData = useAppSelector((state: any) => state.HSNcodeFetchData.HsnCodeEdit
  );



  const [formData, setFormData] = useState<HSNcodeFormValues>({
    hsnCode: "",
    gst: "",
    cgst: "",
    sgst: "",
    igst: "",
    hsnType: "",
    fromDate: "",

  });

  const [errors, setErrors] = useState<HSNcodeFormValueErrors>({
    hsnCode: "",
    gst: "",
    cgst: "",
    sgst: "",
    igst: "",
    hsnType: "",
    fromDate: "",
  });

  const validateFormData = (data: HSNcodeFormValues): HSNcodeFormValueErrors => {
    const errors: HSNcodeFormValueErrors = {};

    const fieldsToValidate: (keyof HSNcodeFormValues)[] = [
      'hsnCode',
      'gst',
      'cgst',
      'sgst',
      'igst',
      'hsnType',
      'fromDate',
    ];

    // Validate fields other than address

    fieldsToValidate.forEach(field => {
      if (!data[field]) {
        errors[field] = `${field} is required`;
      }
    });

    return errors;

  }

  useEffect(() => {
    if (HsnCodeEditData) {


      // Update the formData state
      setFormData(prevData => ({
        ...prevData,
        hsnCode: HsnCodeEditData.hsnCode || "",
        gst: HsnCodeEditData.gst || "",
        cgst: HsnCodeEditData.cgst || "",
        sgst: HsnCodeEditData.sgst || "",
        igst: HsnCodeEditData.igst || "",
        fromDate: HsnCodeEditData.fromDate || "",
        hsnType: HsnCodeEditData.hsnType || "",
      }));
    }
  }, [HsnCodeEditData]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleEditClick = () => {
    setIsEditEnabled(false); // Disable edit when clicked
  };

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   let newFormData: CreateCompanyFormValues = { ...formData }; // Make a copy of formData

  //   // If the changed field is part of the address object, update it properly
  //   if (name === "doorNo" || name === "streetName" || name === "area") {
  //     newFormData = {
  //       ...newFormData,
  //       address: {
  //         ...newFormData.address,
  //         [name]: value,
  //       },
  //     };
  //   } else {
  //     // If it's not part of the address object, update it normally
  //     newFormData = {
  //       ...newFormData,
  //       [name]: value,
  //     };
  //   }
  //   // Email regex pattern
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //   // Check if the changed field is the email field and validate against the regex pattern
  //   if (name === "email" && !emailRegex.test(value)) {
  //     setErrors({ ...errors, email: "Please Enter a Valid email Address(Ex:example@domain.com)" });
  //   } else {
  //     setErrors({ ...errors, email: "" }); // Clear email error if it's valid
  //   }
  //   setFormData(newFormData); // Update formData
  // };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    formData: HSNcodeFormValues
  ): Promise<void> => {
    event.preventDefault();
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length === 0) {
      try {

        const response = await dispatch(HSNcodeUpdate({ id: HsnCodeEditData.id, formData }));
        const successMessage = response.message;
        setFormError(true);
        setSucessMessage(successMessage);
        dispatch(HSNcodeFetchData(requestData));
        setTimeout(() => onClose(), 2000);
      }
      catch (error: any) {
        if (error.response.data.data) {
          const errorMessage = error.response.data.data;
          setErrors(errorMessage);
        }
        else {
          setErrorMessage("something went Wrong")
        }
      }
    } else {
      // Form is invalid, display errors
      setErrors(validationErrors);
    }

  };
  const GRT = ["aaa", "bb"];
  const CalendarIcon = () => (
    <img
      src={calendar}
      alt={calendar}
      style={{ width: "24px", height: "24px" }}
    />
  );
  const handleChangeFromDate = (name: string, value: string | Date | null) => {
    if (value === null) {
      // Handle case when date is cleared
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: '', // Or any default value you prefer
      }));
      setSelectedDate(null); // Update the selectedDate state
    } else if (typeof value === 'string') {
      // Handle text input changes
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else {
      // Handle date changes
      const selectedDate = new Date(value); // Convert value to Date object if it's not already
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth() + 1; // Months are 0-indexed, so we add 1
      const day = selectedDate.getDate();
      const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: formattedDate,
      }));
      setSelectedDate(selectedDate); // Update the selectedDate state
    }
  };
  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_stl"
      // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 533,
              md: 536,
              sm: 600,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "95%" }} role="presentation">
            <Grid
              container
              className="crt-header"
              mt={4}
              style={{ paddingLeft: "14px" }}
            >
              <Typography gutterBottom className="crt-header-lable">
                HSN Code
              </Typography>

              <Grid item>
                <img
                  src={Editicon}
                  alt={Editicon}
                  onClick={handleEditClick}
                  //   onClick={onClose}
                  style={{ width: "24px", height: "24px", marginRight: "15px" }}
                />
                <img
                  src={Close}
                  alt={Close}
                  onClick={onClose}
                  style={{ width: "24px", height: "24px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Content */}
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
              marginBottom: "120px",
            }}
            className="create-form"
          >
            {/* Your scrollable content */}
            <form
              onSubmit={(event) => handleSubmit(event, formData)}
            >
              <Grid item sx={{ width: "95%", margin: "auto", mb: "52px" }}>
                <Grid item>
                  <Typography className="crt-fnt-lable">HSN Code *</Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Product Code"
                      name="hsnCode"
                      disabled={isEditEnabled}
                      onChange={handleInputChange}
                      value={formData.hsnCode}
                      error={errors.hsnCode ? true : false}
                      helperText={errors.hsnCode && errors.hsnCode}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">GST % *</Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Enter Code"
                      name={"gst"}
                      disabled={isEditEnabled}
                      onChange={handleInputChange}
                      value={formData.gst}
                      error={errors.gst ? true : false}
                      helperText={errors.gst && errors.gst}
                    />
                  </Grid>
                </Grid>

                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">From Date *</Typography>
                  <Grid item xs={12} mt={1}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="Date_picker_box"
                        slots={{
                          openPickerIcon: CalendarIcon,
                        }}
                        slotProps={{
                          textField: { placeholder: "Select Date" },
                        }}
                        sx={{
                          border: 0,
                          width: "100%",
                          backgroundColor: "#f6f6f680",
                          // border: "1px solid  #f6f6fc",
                        }}
                        // value={formData.fromDate} // Convert formData.fromDate to Date
                        // onChange={(newValue: Date | null) => {
                        //   handleChangeFromDate("fromDate", newValue); // Update the formData with the new date value
                        //   setSelectedDate(newValue); // Update the selectedDate state
                        // }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">CGST %</Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Enter CGST %"
                      name={"cgst"}
                      disabled={isEditEnabled}
                      onChange={handleInputChange}
                      value={formData.cgst}
                      error={errors.cgst ? true : false}
                      helperText={errors.cgst && errors.cgst}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">SGST %</Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Enter SGST %"
                      name={"sgst"}
                      disabled={isEditEnabled}
                      onChange={handleInputChange}
                      value={formData.sgst}
                      error={errors.sgst ? true : false}
                      helperText={errors.sgst && errors.sgst}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">IGST %</Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Enter IGST %"
                      name={"igst"}
                      disabled={isEditEnabled}
                      onChange={handleInputChange}
                      value={formData.igst}
                      error={errors.igst ? true : false}
                      helperText={errors.igst && errors.igst}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item mt={2} className="product_Create_footer">
                <Button className="crt-btn-cancel" onClick={onClose}>
                  {" "}
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="crt-btn-colr"
                  sx={{ mr: 4 }}
                  type="submit"
                >
                  {" "}
                  Update
                </Button>
              </Grid>
            </form>

            {/* More content... */}
            {FormError ? (
              sucessMessage ? (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="success">{sucessMessage}</Alert>
                </Snackbar>
              ) : (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="error">{errorMessage}</Alert>
                </Snackbar>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
