import React from "react";
import {
  Button,
  Grid,
  TextField,
  Box,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  InputLabel,
  Typography,
  Card,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CommonTable from "../../../../components/Tables/CommonTable";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { TableColumnsType } from "antd";
import CustomerDifinitionView from "./CustomerDifinitionView";


interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}
interface DataType {
  key: React.Key;
  LevelValue: string;
}

const DistributorSelectionView: React.FC<DistributorTabProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        //   width: 250,
      },
    },
  };
  const handleChanges = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const Discount = ["Retailer", "TPR1"];
  const option = ["Super Stockiest ", "Sub Stockiest", "Distributor"];
  const level = [
    "Head of Sales",
    "Regional Head",
    "Zonal Sales Manager",
    "Area Sales Manager",
    "Territory Sales Officer",
  ];
  const BudgetType = ["Amount", ""];
  const Company = ["CAVINKARE PRIVATE LIMITED", ""];
  const PayoutType = ["Amount", "Discount %", "Free Product"];
  const PayoutFrequency = ["Same Bill", ""];
  const ClaimableOn = ["Purchase Rate", ""];
  const FlexiSchemeType = ["None", ""];
  const QPSReset = ["No", ""];

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "LevelValue",
      title: "Level - Value",
      // sorter: (a, b) => a.Distributor.length - b.Distributor.length,
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      LevelValue: "Territory Sales Officer - AD SO LAKHIMPUR",
    },
    {
      key: 2,
      LevelValue: "Territory Sales Officer - AD SO JORHAT",
    },
    {
      key: 3,
      LevelValue: "  Territory Sales Officer - AD SO GUWAHATI",
    },
    {
      key: 4,
      LevelValue: " Territory Sales Officer - AD SO SILCHAR",
    },
    {
      key: 5,
      LevelValue: "Territory Sales Officer - AD SO AIZAWL",
    },
    {
      key: 6,
      LevelValue: " Territory Sales Officer - AD SO GUWAHATI 2",
    },
  ];
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <>
      {show ? (
        <CustomerDifinitionView onBack={handleclick} />
      ) : (
        <Box>
          <form>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">
                  Discount Recipient
                </Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    className="selct-box"
                  >
                    {Discount.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">
                  Supply Chain Entity
                </Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    className="selct-box"
                  >
                    {option.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">LOB Value</Typography>
                <TextField variant="outlined" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3} mt={3}>
                <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
                  ...
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Card
                  className="card-form"
                  sx={{ border: "1px solid #EEE", mt: 3 }}
                >
                  <Grid className="box-lable fil-lab">Filter Based On :</Grid>
                  <Grid container sx={{ marginTop: "16px" }}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="Sales hierarchy"
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 18,
                                  color: "#6750A4",
                                },
                              }}
                            />
                          }
                          label="Sales hierarchy"
                        />
                        <FormControlLabel
                          value="Geography hierarchy"
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 18,
                                  color: "#6750A4",
                                },
                              }}
                            />
                          }
                          label="Geography hierarchy "
                        />
                        <FormControlLabel
                          value="Distributor"
                          control={
                            <Radio
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 18,
                                  color: "#6750A4",
                                },
                              }}
                            />
                          }
                          label="Distributor "
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">level</Typography>

                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    className="selct-box"
                  >
                    {level.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 3, sm: 3 }}
                mt={3}
              >
                <Grid item xs={12} sm={8} md={8}>
                  <CommonTable
                    // rowSelection={rowSelection}
                    data={data}
                    columns={columns}
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={2} mt={3}>
                  <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
                    <KeyboardDoubleArrowLeftIcon />
                  </Button>
                  <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
                    <KeyboardDoubleArrowRightIcon />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              mt={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item sx={{ ml: 2 }}>
                <Button variant="outlined" onClick={handleBackClick}>
                  Back
                </Button>
              </Grid>
              <Grid item sx={{ ml: 2 }}>
                <Button
                  className="btn-colr "
                  variant="contained"
                  onClick={handleclick}
                >
                  <RedoOutlinedIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />
                  Next
                </Button>
              </Grid>
              <Grid item sx={{ ml: 2 }}>
                <Button variant="outlined" onClick={handleBackClick}>
                  <CancelIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />{" "}
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </>
  );
};
export default DistributorSelectionView;
