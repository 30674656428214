import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import { ArrowBackIos } from "@mui/icons-material";
import MiniDrawerStyled from "../../../../../../Layout/MainLayout/Drawer/MiniDrawerStyled";
import DrawerHeader from "../../../../../../Layout/MainLayout/Drawer/DrawerHeader";
import WithInvoiceSideBar from "./WithInvoiceSideBar";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SearchBar from "@mkyy/mui-search-bar";
import { Spin, Table, TableColumnsType, Tooltip } from "antd";
import debounce from "lodash.debounce";
import moment from "moment";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../hooks/useFetch";
import {
  updateDate,
  updateFromDate,
  updateStatusGrn,
  updateToDate,
} from "../../../../../../Redux/XDM/Sales/billingReducer";
import { useLocation, useNavigate } from "react-router-dom";
import { getSalesReturnWithInvoice } from "../../../../../../Redux/XDM/Sales/salesReturnReducer";
import InvoiceProductReview from "./InvoiceProductReview";

interface SalesReturnInvoice {
  routeName: string;
  orderNumber: string;
  invoiceNo: string;
  salesmanName: string;
  invoiceDate: string;
  noOfLineItem: number;
  totalNetAmount: string;
}
type TableRowType = SalesReturnInvoice | { noMoreData: boolean };

const WithInvoiceReviewDetails: React.FC = () => {
  const theme = useTheme();
  const [textFieldValue, setTextFieldValue] = React.useState("");
  const navigator = useNavigate();
  const [purchaseOrder, setPurchaseOrder] = React.useState<
    SalesReturnInvoice[]
  >([]);
  const [hasMore, setHasMore] = useState(true);

  const [commonLoader, setCommonLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [isShow, setIsShow] = useState(false);

  const tableHeightRef = useRef(400);
  const filterTags = useAppSelector((state) => state.orderBilling);
  const dispatch = useAppDispatch();

  const location = useLocation();
  const getQueryParams = (query: any) => {
    return new URLSearchParams(query);
  };

  const queryParams = getQueryParams(location.search);
  const outletName = queryParams.get("outletName");
  const outletCode = queryParams.get("outletcode");

  const filterData = {
    page: 0,
    size: 0,
    salesman: filterTags.salesmanSelected.toString(),
    outletCode: outletCode,
    dateType: filterTags?.dateSelected,
    fromDate: filterTags.fromDateSelected,
    toDate: filterTags.toDateSelected,
    search: textFieldValue,
  };

  useEffect(() => {
    setPage(0);
    setHasMore(true);
    setPurchaseOrder([]);
    loadMoreData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    textFieldValue,
    filterTags.dateSelected,
    filterTags.fromDateSelected,
    filterTags.toDateSelected,
    filterTags.salesmanSelected,
  ]);
  useEffect(() => {
    dispatch(updateFromDate([]));
    dispatch(updateToDate([]));
    dispatch(updateDate([]));
    dispatch(updateStatusGrn([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 233;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;

    dispatch(
      getSalesReturnWithInvoice({
        params: { ...filterData, page: nextPage },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const newOrders = response.payload.result || [];
          setPurchaseOrder((prevOrders) =>
            resetPage ? newOrders : [...prevOrders, ...newOrders]
          );
          setPage(nextPage + 1);
          if (newOrders.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };

  const handleRowClick = (record: SalesReturnInvoice) => {
    setRequestData(record);
    setIsShow(!isShow);
  };

  const [requestData, setRequestData] = React.useState({
    routeName: "",
    orderNumber: "",
    invoiceNo: "",
    salesmanName: "",
    invoiceDate: "",
    noOfLineItem: 0,
    totalNetAmount: "",
  });

  const columns: TableColumnsType<TableRowType> = [
    {
      dataIndex: "invoiceNo",
      title: "Invoice Number",
      width: "15%",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return record.invoiceNo;
      },
    },

    {
      dataIndex: "orderNumber",
      title: "Order ID",
      width: "15%",
      render: (text: string | number, record: TableRowType) => {
        if ("noMoreData" in record) {
          return {
            children: (
              <span
                className="fs-12 noMore"
                style={{ display: "block", margin: "10px" }}
              >
                <b>No more data found...</b>
              </span>
            ),
            props: {
              colSpan: 6, // Adjust this number based on the total number of columns to span
            },
          };
        }
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "salesmanName",
      title: "Salesman Name",
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            {text ? text : "-"}
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "invoiceDate",
      width: "12%",
      title: "Invoice Date",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
        return a.invoiceDate.localeCompare(b.invoiceDate);
      },
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        if (text) {
          const formattedDate = moment(text).format("DD MMM YYYY");
          return <div>{formattedDate}</div>;
        }
        return "-";
      },
    },
    {
      dataIndex: "routeName",
      title: "Route Name",
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            {text}
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "noOfLineItem",
      width: "15%",
      title: "No. of Line item",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return record.noOfLineItem;
      },
    },
    {
      dataIndex: "totalNetAmount",
      width: "15%",
      title: "Net Amount",
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <span>
            <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
            {text}
          </span>
        );
      },
    },
  ];

  const backtoSalesReturn = () => {
    navigator("/xdm/sales/sales-return?tab=true");
  };

  let dataToShow: TableRowType[] = [];

  if (purchaseOrder.length > 0) {
    if (hasMore) {
      dataToShow = purchaseOrder;
    } else {
      dataToShow = [...purchaseOrder, { noMoreData: true }];
    }
  }

  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );
  const handleSearch = (newTextFieldValue: string) => {
    setTextFieldValue(newTextFieldValue);
  };

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <Grid item>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        bgcolor="#eee"
      >
        <Box flex={1} p={0}>
          <MiniDrawerStyled variant="permanent" open={true} theme={theme}>
            <DrawerHeader />
            <WithInvoiceSideBar status="Confirmed" />
          </MiniDrawerStyled>
        </Box>
        <Box
          flex={8}
          className="margin-left-0px"
          sx={{ overflowY: "auto", height: "100vh", marginBottom: "100px" }}
        >
          <Card
            className="b-radius-0"
            style={{ boxShadow: "none", paddingTop: "12px", height: "100%" }}
          >
            <Box flex={8} sx={{ overflowY: "auto", height: "100vh" }}>
              <Card className="b-radius-0" style={{ boxShadow: "none" }}>
                <Grid item sx={{ width: "100%" }}>
                  <Grid
                    container
                    sx={{
                      padding: "12px 24px",
                    }}
                  >
                    <Grid
                      item
                      sm={0.3}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <ArrowBackIos
                        sx={{
                          fontSize: "10px",
                          cursor: "pointer",
                          marginRight: "5px",
                        }}
                        onClick={backtoSalesReturn}
                      />
                    </Grid>
                    <Grid item xs={6.7} sm={7.7} md={8.7} lg={8.7}>
                      <Grid item>
                        <Typography
                          sx={{
                            fontWeight: "700",
                            color: "#000000",
                            fontFamily: "Montserrat",
                            fontSize: "13px",
                          }}
                        >
                          Sales Return with Invoice ({outletName} / {outletCode}
                          )
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            color: "#505259",
                            fontFamily: "Montserrat",
                            fontSize: "11px",
                            fontWeight: 600,
                          }}
                        >
                          Select Invoice and Add Product
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      sm={4}
                      md={3}
                      lg={3}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <SearchBar
                        className="search_box_saleable w-100px"
                        placeholder="Search Invoice Number"
                        value={textFieldValue}
                        onChange={(newValue) => {
                          setTextFieldValue(newValue);
                        }}
                        onCancelResearch={() => setTextFieldValue("")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item xs={6} sm={12} md={12} lg={12} className="pt-0">
                  {((filterTags.dateSelected?.length !== 0 &&
                    filterTags.dateSelected !== null) ||
                    textFieldValue !== "") && (
                    <p style={{ marginTop: "0px", marginBottom: "10px" }}>
                      Showing Results based on selected filters{" "}
                      <b>({filteredCount} Products)</b>
                    </p>
                  )}
                </Grid> */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  mx={2}
                  className="pad-top-0px"
                >
                  <div className="saleable-table-scroll order-bill">
                    <Table
                      className={`Header-table saleable-table sale_last_hide scroll_cus_table elipsTable pending_grn ${
                        commonLoader ? " nodataloader" : ""
                      }`}
                      // rowSelection={rowSelection}
                      columns={columns}
                      dataSource={dataToShow}
                      pagination={false}
                      loading={!page && commonLoader}
                      showSorterTooltip={false}
                      summary={() => {
                        return (
                          <>
                            {page !== 0 && commonLoader && (
                              <Table.Summary.Row>
                                <Table.Summary.Cell
                                  index={1}
                                  colSpan={5}
                                  align="center"
                                >
                                  <Spin />
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            )}
                          </>
                        );
                      }}
                      onRow={(record: TableRowType) => {
                        if ("noMoreData" in record) return {};
                        return {
                          onClick: () => handleRowClick(record),
                        };
                      }}
                      scroll={{ y: tableHeightRef.current }}
                    />
                  </div>
                </Grid>
              </Card>
            </Box>
          </Card>
          {isShow ? (
            <InvoiceProductReview
              onClose={() => setIsShow(false)}
              requestData={requestData}
              outletCode={outletCode}
              outletName={outletName}
            />
          ) : (
            <></>
          )}
        </Box>
      </Stack>
    </Grid>
  );
};

export default WithInvoiceReviewDetails;
