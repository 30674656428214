import React from 'react';
import { Box, TextField, Button, Grid, InputLabel } from '@mui/material';
import type { TableColumnsType } from 'antd';
import CommonTable from '../../../components/Tables/CommonTable';

interface DataType {
    key: React.Key;
    code: number;
    value: string;

  }

interface ViewSchemeControlFormProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
}

const ViewSchemeControlForm: React.FC<ViewSchemeControlFormProps> = ({ onBack }) => {
    const handleBackClick = (event: React.MouseEvent<unknown>) => {
        onBack(event); // Pass the event argument to onBack function
    };
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

    const columns: TableColumnsType<DataType> = [
        {
          dataIndex: 'code',
          title: 'Code',
        },
        {
          dataIndex: 'value',
          title: 'Value ',
          sorter: (a, b) => a.value.length - b.value.length,
        },


      ];
    
      const data: DataType[] = [
        { key: '1', code: 55, value: '33veena',  },
        { key: '2', code: 55445, value: '33alpha',  },
        { key: '3', code: 5565, value: '33hellbey',  },
        { key: '4', code: 5548, value: '33Supplier4',  },
        { key: '5', code: 55121, value: '33Supplier5',  },
        { key: '6', code: 5565, value: '33Supplier6',  },
      ];
    
      const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
      };
    
      const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };

    return (
        <Box className='mar-tb'>
            <form noValidate>
                <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>

                    <Grid item xs={12} sm={4} md={3}>
                        <InputLabel size="normal" className='fnt-lable' >Ref.No</InputLabel>
                        <TextField className='Txt-box' fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <InputLabel size="normal" className='fnt-lable'>Scheme Code</InputLabel>
                        <TextField className='Txt-box' fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <InputLabel size="normal"className='fnt-lable' >Modified User</InputLabel>
                        <TextField className='Txt-box'  fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <InputLabel size="normal"className='fnt-lable' > Modified Date</InputLabel>
                        <TextField className='Txt-box' fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={12} mt={2}>
                    <CommonTable 
                    // rowSelection={rowSelection} 
                    data={data} columns={columns} /> 
                    </Grid>

                    <Grid item xs={12} mt={2} display="flex" justifyContent="center">
                        <Button variant="contained" className='btn-colr' onClick={handleBackClick}>
                            Back
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}

export default ViewSchemeControlForm;
