import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Skeleton, Paper } from '@mui/material';

interface Row {
  id: number;
}

interface TableSkeletonLoaderProps {
  rowCount: number;
  columns: number;
}

const TableSkeletonLoader: React.FC<TableSkeletonLoaderProps> = ({ rowCount, columns }) => {
  const rows: Row[] = [];

  for (let i = 0; i < rowCount; i++) {
    rows.push({ id: i });
  }

  return (
    <TableContainer component={Paper}>
      <Table >
        <TableHead style={{ background: 'linear-gradient(90deg, rgba(97, 79, 179, 0.1) 0%, rgba(121, 80, 163, 0.1) 100%)' }}>
          <TableRow>
            {[...Array(columns)].map((_, index) => (
              <TableCell key={index}>
                <Skeleton />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              {[...Array(columns)].map((_, index) => (
                <TableCell key={index}>
                  <Skeleton animation="wave" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSkeletonLoader;
