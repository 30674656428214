import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button, Grid, Paper, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RouteForm from "../Route/RouteCreateForm";
import RouteEditForm from "./RouteEditForm";
import RouteViewForm from "./RouteViewForm";
import DeleteModal from "../../../components/Modal/DeleteSupplier";
import CommonTable from "../../../components/Tables/CommonTable";
interface DataType {
  key: React.Key;
  BranchCode: string;
  RouteCode: string;
  RouteName: string;
  RouteType: string;
  IsActive: string;
  action: string;
}

const Route: React.FC = () => {
  const [isViewing, setIsViewing] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);

  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // search function logic here
  };

  const handleCreateNew = () => {
    setShowForm(!showForm);
  };

  const handleEditClick = (event: React.MouseEvent<unknown>) => {
    setIsEditing(!isEditing);
  };

  const handleViewClick = (event: React.MouseEvent<unknown>) => {
    setIsViewing(!isViewing);
  };
  const handleDeleteClick = (event: React.MouseEvent<unknown>) => {
    setIsDelete(!isDelete);
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "BranchCode",
      title: "Branch Code",
      sorter: (a, b) => a.BranchCode.length - b.BranchCode.length,
    },

    {
      dataIndex: "RouteCode",
      title: "Route Code",
      sorter: (a, b) => a.RouteCode.length - b.RouteCode.length,
    },

    {
      dataIndex: "RouteName",
      title: "Route Name",
      sorter: (a, b) => a.RouteName.length - b.RouteName.length,
    },

    {
      dataIndex: "RouteType",
      title: "Route Type",
      sorter: (a, b) => a.RouteType.length - b.RouteType.length,
    },

    {
      dataIndex: "IsActive",
      title: "Is Active",
      sorter: (a, b) => a.IsActive.length - b.IsActive.length,
    },

    {
      dataIndex: "action",
      title: "Action",
      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            onClick={(event) => handleViewClick(event)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
          <EditIcon
            onClick={(event) => handleEditClick(event)}
            style={{
              cursor: "pointer",
              marginLeft: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />
          <DeleteIcon
            onClick={(event) => handleDeleteClick(event)}
            style={{
              cursor: "pointer",
              marginRight: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      BranchCode: "GT0200020005890002000589",
      RouteCode: "GT0200020005940002000594-ROU001",
      RouteName: "ROUTE-1",
      RouteType: "Sales Route",
      IsActive: "Y",
      action: "",
    },
    {
      key: 2,
      BranchCode: "GT0100020000820002000082",
      RouteCode: "GT0200020005940002000594-ROU003",
      RouteName: "7WELLS-2",
      RouteType: "Delivery Route",
      IsActive: "Y",
      action: "",
    },
    {
      key: 3,
      BranchCode: "GT0100020000820002000082",
      RouteCode: "GT0200020005940002000594-ROU002",
      RouteName: "7WELLS-2",
      RouteType: "Sales Route",
      IsActive: "N",
      action: "",
    },
    {
      key: 4,
      BranchCode: "GT0100020000820002000082",
      RouteCode: "GT0200020005940002000594-ROU005",
      RouteName: "MONDAY",
      RouteType: "Sales Route",
      IsActive: "Y",
      action: "",
    },
    {
      key: 5,
      BranchCode: "GT0100020000820002000082",
      RouteCode: "GT0200020005940002000594-ROU004",
      RouteName: "ROUTE-1",
      RouteType: "Delivery Route",
      IsActive: "N",
      action: "",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className="pad-10 mar-tb">
          <Typography>
            <b>Route</b>
          </Typography>
        </Grid>

        {showForm ? (
          <RouteForm onBack={handleCreateNew} />
        ) : isEditing ? (
          <RouteEditForm onBack={(event) => handleEditClick(event)} />
        ) : isViewing ? (
          <RouteViewForm onBack={(event) => handleViewClick(event)} />
        ) : (
          <Box>
            <Button
              variant="contained"
              className="btn-colr"
              onClick={handleCreateNew}
            >
              {" "}
              <AddIcon /> Create new
            </Button>
            <Grid item sx={{ mt: 2 }}>
              <Box>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "end", mb: 2 }}
                >
                  <SearchBar
                    className="search-table"
                    value={textFieldValue}
                    onChange={(newValue) => {
                      setTextFieldValue(newValue);
                      handleSearch();
                    }}
                  />
                </Grid>
                <CommonTable
                  // rowSelection={rowSelection}
                  data={data}
                  columns={columns}
                />
                {isDelete && (
                  <DeleteModal onBack={(event) => handleDeleteClick(event)} />
                )}
              </Box>
            </Grid>
          </Box>
        )}
      </Box>
    </>
  );
};
export default Route;
