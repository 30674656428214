import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Grid,
  Card,
  Typography,
  Stack,
  Tabs,
  Tab,
  Divider,
  Snackbar,
  Alert,
  Skeleton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import debounce from "lodash.debounce";
import MiniDrawerStyled from "../../../../../Layout/MainLayout/Drawer/MiniDrawerStyled";
import DrawerHeader from "../../../../../Layout/MainLayout/Drawer/DrawerHeader";
import type { TableColumnsType } from "antd";
import { Spin, Table, Tooltip } from "antd";
import { ArrowBackIos } from "@mui/icons-material";
import SearchBar from "@mkyy/mui-search-bar";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useFetch";
import view_line from "../../../../../assets/images/icons/view_line.png";
import Accept_img from "../../../../../assets/images/icons/Accept_img.png";
import Reject_img from "../../../../../assets/images/icons/Reject.png";
import Close from "../../../../../assets/images/icons/Close_icon.png";
import PendingDetailsSideBar from "./PendingDetailsSideBar";
import PendingCashPayments from "./PendingCashPayments";
import PendingChequePayments from "./PendingChequePayments";
import {
  getPendingPaymentCardCount,
  getPendingPaymentList,
  getRejectAcceptList,
  setSeletedCollectionData,
  updatePendingCard,
} from "../../../../../Redux/XDM/Sales/collectionReducer";
import Modal from "@mui/material/Modal";
import RejectedCollection from "./RejectedCollectionPopup";
import ImageModal from "../../SalesReturn/PendingSalesReturn/ImageModal";
import {
  updateDate,
  updateFromDate,
  updateOutlet,
  updateSalesman,
  updateToDate,
} from "../../../../../Redux/XDM/Sales/billingReducer";
import LoadingButton from "@mui/lab/LoadingButton";
import InvoiceDetails from "../../InvoiceReviewOrder";
import { useLocation } from "react-router-dom";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "3px",
};

interface RequestData {
  salesManId: string;
  salesManName: string;
  mobile: string;
}

interface ReviewOrderDetailsProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
}
interface ReviewOrderDetails {
  collectionNumber: string;
  invoiceNumber: string[];
  collectionDate: string;
  outletName: string;
  outStandingAmount: number;
  amountCollected: number;
  img: string[];
}
export default function PendingCollectionReview(
  props: ReviewOrderDetailsProps
) {
  const { onClose, requestData } = props; // Destructure the props
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [hasMore, setHasMore] = useState(true);
  const [commonLoader, setCommonLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [isShowConfirm, setIsShowConfirm] = React.useState(false);
  const [isRejectShow, setIsRejectShow] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [openImage, setOpenImage] = React.useState(false);
  const [imageList, setImageList] = React.useState<string[]>([]);
  const [isShowDetails, setIsShowDetails] = React.useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [textFieldValue, setTextFieldValue] = React.useState("");
  const filterTags = useAppSelector((state) => state.orderBilling);
  const [acceptPopupRecord, setAcceptPopupRecord] = useState<any | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  const handleImageOpen = (record: ReviewOrderDetails) => {
    setImageList(record?.img); // Set the image list from the record
    setOpenImage(true);
  };
  const [dataRecordOrders, setDataRecordOrders] = useState<
    ReviewOrderDetails[]
  >([]);

  let pendingCollectionData = useAppSelector(
    (state) => state.collectionReducerData
  );
  let salesmanData = pendingCollectionData.salesmanDetails;

  let pendingPaymentsCountData = useAppSelector(
    (state) => state.collectionReducerData.pendingPaymentCounts
  );

  let collectionStoreData = useAppSelector(
    (state) => state.collectionReducerData.collectionStoreData
  );

  let smTotalCollection = useAppSelector(
    (state) => state.collectionReducerData.smTotalCollection
  );

  const tableHeightRef = useRef(160);
  const tableHeightloadRef = useRef(180);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const mode = params.get("payMode");
  const status = params.get("status");

  const filterData = {
    paymentType: "UPI",
    page: 0,
    size: 0,
    dateType:
      filterTags.dateSelected?.length !== 0 ? filterTags.dateSelected : "",
    fromDate: filterTags.fromDateSelected ? filterTags.fromDateSelected : "",
    toDate: filterTags.toDateSelected ? filterTags.toDateSelected : "",
    search: id && mode === "UPI" ? id : textFieldValue,
    salesmanId:
      salesmanData.salesManId && mode === "UPI"
        ? salesmanData.salesManId
        : requestData.salesManId,
    outletId: filterTags.outletSelected.toString(),
  };
  const CardfilterData = {
    paymentType: "UPI",
    salesmanId: requestData.salesManId,
  };

  const [invoiceRequestData, setInvoiceRequestData] = React.useState({
    invoiceNumber: "",
    orderId: "",
    date: "",
    outlet: "",
    route: "",
    salesman: "",
    netAmount: 0,
    salesmanMobile: "",
  });

  useEffect(() => {
    setHasMore(true);
    setDataRecordOrders([]);
    dispatch(
      updatePendingCard({
        totalCollection: 0,
        totalCollectionAmt: 0,
      })
    );
    if (tabIndex === 0 || collectionStoreData === true) {
      loadMoreData(true);
      dispatch(
        getPendingPaymentCardCount({
          params: { ...filterData },
        })
      );
    }
  }, [
    tabIndex,
    textFieldValue,
    filterTags.dateSelected,
    filterTags.outletSelected,
    filterTags.fromDateSelected,
    filterTags.toDateSelected,
    collectionStoreData,
  ]);

  useEffect(() => {
    // dispatch(updateFromDate([]));
    // dispatch(updateToDate([]));
    // dispatch(updateDate([]));
    // dispatch(updateSalesman([]));
    dispatch(updateOutlet([]));
  }, []);

  useEffect(() => {
    if (status === "Pending") {
      if (mode === "UPI") {
        setTabIndex(0);
      } else if (mode === "Cash") {
        setTabIndex(1);
      } else if (mode === "Cheque") {
        setTabIndex(2);
      }
    }
  }, [mode]);
  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 353;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const tableHeightLoad = () => {
    const headerHeight = 0;
    const footerHeoght = 300;
    const availableHeight = window.innerHeight - headerHeight - footerHeoght;
    tableHeightloadRef.current = availableHeight;
  };

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    tableHeightLoad();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;

    dispatch(
      getPendingPaymentList({
        params: { ...filterData, page: nextPage },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const newOrders = response.payload.data || [];
          setDataRecordOrders((prevOrders) =>
            resetPage ? newOrders : [...prevOrders, ...newOrders]
          );
          setPage(nextPage + 1);
          if (newOrders.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };

  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    newSelectedRows: any[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleRejectClick = (record: any) => {
    const requestDatas = {
      collectionId: record?.collectionNumber,
      invoiceNumbers: record?.invoiceNumber,
      collectionDate: record?.collectionDate,
      outletName: record?.outletName,
      salesmanName: requestData?.salesManName,
      pendingAmount: record?.outStandingAmount,
      images: record?.img,
      collectedAmt: record?.amountCollected,
    };

    dispatch(setSeletedCollectionData(requestDatas));

    setIsRejectShow(!isRejectShow);
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  const reviewOrderColumns: TableColumnsType<ReviewOrderDetails> = [
    {
      dataIndex: "collectionNumber",
      title: "Collection ID",
      width: "12%",
      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "-"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "invoiceNumber",
      title: "Invoice No",
      width: "10%",
      render: (text: string, record: ReviewOrderDetails) => {
        if (
          Array.isArray(record.invoiceNumber) &&
          record.invoiceNumber.length > 0
        ) {
          const invoiceList = record.invoiceNumber[0]?.split(","); // Split all invoices by commas
          const firstInvoice = invoiceList[0]; // Get the first invoice

          return (
            <Tooltip
              placement="top"
              title={record.invoiceNumber[0]}
              zIndex={999999}
            >
              <div
                className="ellipseclass"
                style={{
                  textDecoration: "underline",
                  fontWeight: 600,
                  color: "#27538D",
                  display: "flex",
                  gap: "10px",
                  cursor: "pointer",
                }}
                onClick={() => handleInvoiceClick(firstInvoice)}
              >
                {invoiceList?.join(", ")}
              </div>
            </Tooltip>
          );
        }
        return <div style={{ fontWeight: "bold" }}> - </div>;
      },
    },
    {
      dataIndex: "collectionDate",
      title: "Date",
      width: "10%",
      align: "center",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
        return a.collectionDate.localeCompare(b.collectionDate);
      },
      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "-"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "outletName",
      title: "Outlet",
      // width: "12%",
      align: "center",
      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "-"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "outStandingAmount",
      title: "Ols Outstanding Amount",
      width: "20%",
      align: "center",
      render: (text: number) => {
        return <span> ₹{text ? text?.toFixed(2) : 0}</span>;
      },
    },

    {
      dataIndex: "amountCollected",
      title: "Amount Collected",
      // width: "10%",
      align: "right",
      render: (text: number, record: ReviewOrderDetails) => {
        return <span> ₹{text ? text?.toFixed(2) : 0}</span>;
      },
    },
    {
      dataIndex: "img",
      title: "UPI Image",
      align: "center",
      render: (_: any, record: ReviewOrderDetails, index: number) => {
        return (
          <Grid
            container
            sx={{
              justifyContent: "center",
              pointerEvents: "auto",
            }}
          >
            {record?.img?.length > 0 && record?.img !== null ? (
              <Grid item onClick={() => handleImageOpen(record)}>
                <img
                  src={view_line}
                  alt="view-line"
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
            ) : (
              <>--</>
            )}
          </Grid>
        );
      },
    },
    {
      dataIndex: "",
      title: "Action",
      align: "center",
      width: "12%",
      render: (_: any, record: ReviewOrderDetails, index: number) => {
        const isSelected = selectedRowKeys.includes(record.collectionNumber);
        return (
          <Grid
            container
            sx={{
              justifyContent: "center",
              pointerEvents: isSelected ? "none" : "auto",
              opacity: isSelected ? 0.5 : 1,
            }}
          >
            <Grid item onClick={() => handleRejectClick(record)}>
              <img
                src={Reject_img}
                alt="Reject"
                style={{ width: "23px", height: "23px" }}
              />
            </Grid>
            <Grid item ml={1} onClick={() => handleAcceptPopup(record)}>
              <img
                src={Accept_img}
                alt="Accept"
                style={{ width: "23px", height: "23px" }}
              />
            </Grid>
          </Grid>
        );
      },
    },
  ];
  const filteredDataToShow = dataRecordOrders.filter((item) =>
    item.collectionNumber?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const dataToShow: ReviewOrderDetails[] =
    filteredDataToShow.length > 0
      ? hasMore
        ? filteredDataToShow
        : [...filteredDataToShow]
      : [];

  const handleInvoiceClick = (invoiceNo: string) => {
    setInvoiceRequestData({
      invoiceNumber: invoiceNo,
      orderId: "",
      date: "",
      outlet: "",
      route: "",
      salesman: "",
      netAmount: 0,
      salesmanMobile: "",
    });
    setIsShowDetails(!isShowDetails);
  };
  const handleClose = () => setIsShowConfirm(false);

  const handleAcceptPopup = (
    record: ReviewOrderDetails | ReviewOrderDetails[]
  ) => {
    if (Array.isArray(record)) {
      setAcceptPopupRecord(record);
    } else {
      setAcceptPopupRecord([record]);
    }
    setIsShowConfirm(true);
  };

  const handleAcceptOk = async () => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const requestPayload = {
      pendingList: acceptPopupRecord.map((record: ReviewOrderDetails) => ({
        paymentType: "UPI",
        collectionNumber: record.collectionNumber,
        status: "Approved",
        remarks: "",
      })),
    };

    try {
      const response = await dispatch(
        getRejectAcceptList({
          params: requestPayload,
          signal: abortController.signal,
        })
      );
      if (response?.meta?.requestStatus === "fulfilled") {
        setIsShowConfirm(false);
        setSnackbarMessage("Payment Accepted Successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
        }, 3000);
        setDataRecordOrders((prevData) =>
          prevData.filter(
            (item) =>
              !acceptPopupRecord.some(
                (record: ReviewOrderDetails) =>
                  record.collectionNumber === item.collectionNumber
              )
          )
        );
        dispatch(
          getPendingPaymentCardCount({
            params: { ...filterData },
          })
        );
      } else if (response?.meta?.requestStatus === "rejected") {
        const errorMessage = response?.payload?.data;

        setSnackbarMessage(errorMessage);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
        }, 3000);
      }
    } catch (error) {
      console.error("Submission error", error);
    }
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      bgcolor="#eee"
    >
      <Box flex={1} p={0}>
        <MiniDrawerStyled variant="permanent" open={true} theme={theme}>
          <DrawerHeader />
          <PendingDetailsSideBar status={"Settled"} />
        </MiniDrawerStyled>
      </Box>
      <Box
        flex={8}
        className="margin-left-0px"
        sx={{ overflowY: "auto", height: "100vh", marginBottom: "100px" }}
      >
        <Card
          className="b-radius-0 "
          style={{ boxShadow: "none", paddingTop: "12px" }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              // mt: 2,
            }}
          >
            <Grid item sx={{ width: "100%" }}>
              <Grid
                container
                sx={{
                  borderBottom: "1px solid #0000000D",
                  padding: "7px 24px",
                }}
              >
                <Grid item sm={0.3}>
                  <ArrowBackIos
                    sx={{
                      fontSize: "10px",
                      cursor: "pointer",
                      marginRight: "5px",
                    }}
                    onClick={() => {
                      onClose();
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={9.7}
                  sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
                >
                  <Grid item>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        color: "#000000",
                        fontFamily: "Montserrat",
                        fontSize: "13px",
                      }}
                    >
                      {requestData.salesManName}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      gap: "15px",
                    }}
                  >
                    <Grid item>
                      <Typography className="Collection_lable">
                        Total Collection : {smTotalCollection?.totalCount}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className="Collection_lable">
                        Total Collection Value:{" "}
                        {smTotalCollection?.totalCollectedAmount?.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box
              mt={2}
              sx={{
                overflowY: "auto",
              }}
              className="create-form saleable-products-box"
            >
              {" "}
              <Grid
                item
                sx={{
                  width: "100%",
                  margin: "auto",
                  marginBottom: "90px",
                  padding: "0px 0px 0px 24px",
                }}
              >
                <Grid container sx={{ paddingLeft: " 20px" }}>
                  <Grid item sm={12}>
                    <Tabs
                      value={tabIndex}
                      onChange={handleTabChange}
                      // TabIndicatorProps={{
                      //   style: {
                      //     background:
                      //       "linear-gradient(90.19deg, #614FB3 0%, #944986 100%)",
                      //   },
                      // }}
                      className="tab_box"
                      sx={{
                        "& .MuiTab-root": {
                          // background:
                          //   "linear-gradient(90.19deg, #614FB3 0%, #944986 100%)",
                          color: "#B9BABD",
                          "&.Mui-selected": {
                            color: "#944986",
                          },
                        },
                      }}
                    >
                      <Tab label="Pending UPI Payments" />
                      <Tab label="Pending Cash Payments" />
                      <Tab label="Pending Cheque Payments" />
                    </Tabs>
                    <Divider />
                  </Grid>
                </Grid>
                <TabPanel value={tabIndex} index={0}>
                  <Box
                    flex={8}
                    sx={{
                      overflowY: "auto",
                      height: "100vh",
                      mt: { sm: -0.5, md: -5.3 },
                    }}
                  >
                    <Snackbar
                      open={snackbarOpen}
                      autoHideDuration={100000}
                      color="green"
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      onClose={() => setSnackbarOpen(false)}
                    >
                      <Alert
                        onClose={() => setSnackbarOpen(false)}
                        severity={snackbarSeverity}
                        sx={{ width: "100%" }}
                      >
                        {snackbarMessage}
                      </Alert>
                    </Snackbar>

                    <Card
                      className="b-radius-0 "
                      style={{ marginTop: "2px", boxShadow: "none" }}
                    >
                      <Grid container spacing={3} justifyContent="start" p={2}>
                        <Grid container mt={4} m={2}>
                          <Grid item xs={12} sm={12} md={9} lg={9}>
                            <h3
                              style={{
                                marginTop: "0px",
                                marginLeft: "8px",
                                marginBottom: "16px",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6} sm={5} md={3} lg={3}>
                            <SearchBar
                              className="collection_search w-100px"
                              placeholder="Search Collection ID"
                              value={textFieldValue}
                              onChange={(newValue) => {
                                setTextFieldValue(newValue);
                              }}
                              onCancelResearch={() => setTextFieldValue("")}
                            />
                          </Grid>
                        </Grid>
                        <Grid container pl={3.6} mt={2}>
                          <Grid item sm={6}>
                            <Grid item>
                              <Typography className="Pending_review_lable">
                                Total UPI Collection
                              </Typography>
                            </Grid>
                            <Grid item mt={0.7}>
                              {pendingCollectionData?.pendingPaymentCountsLoading ? (
                                <Skeleton variant="rectangular" width="30%" />
                              ) : (
                                <Typography className="Pending_review_Sublable">
                                  {pendingPaymentsCountData?.totalCollection}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item sm={6}>
                            <Grid item>
                              <Typography className="Pending_review_lable">
                                Total UPI Collection Value
                              </Typography>
                            </Grid>
                            <Grid item mt={0.7}>
                              {pendingCollectionData?.pendingPaymentCountsLoading ? (
                                <Skeleton variant="rectangular" width="30%" />
                              ) : (
                                <Typography className="Pending_review_Sublable">
                                  ₹
                                  {pendingPaymentsCountData?.totalCollectionAmt?.toFixed(
                                    2
                                  )}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container mt={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="pad-top-0px"
                            sx={{ height: tableHeightloadRef.current }}
                          >
                            <div className="saleable-table-scroll">
                              <Table
                                className={`Header-table saleable-table sale_last_hide scroll_cus_table antcheck_manual Header_chechbox pending_grn ${
                                  commonLoader ? " nodataloader" : ""
                                }`}
                                columns={reviewOrderColumns}
                                dataSource={dataToShow}
                                pagination={false}
                                scroll={{ y: tableHeightRef.current }}
                                loading={!page && commonLoader}
                                showSorterTooltip={false}
                                summary={() => {
                                  if (!hasMore && dataRecordOrders.length > 0) {
                                    return (
                                      <Table.Summary.Row>
                                        <Table.Summary.Cell
                                          index={1}
                                          colSpan={9}
                                          align="center"
                                          className="noMore"
                                        >
                                          No more data found...
                                        </Table.Summary.Cell>
                                      </Table.Summary.Row>
                                    );
                                  } else if (page !== 0 && commonLoader) {
                                    return (
                                      <Table.Summary.Row>
                                        <Table.Summary.Cell
                                          index={1}
                                          colSpan={9}
                                          align="center"
                                        >
                                          <Spin />
                                        </Table.Summary.Cell>
                                      </Table.Summary.Row>
                                    );
                                  }
                                }}
                                // Conditionally render rowSelection based on areAllRowsDisabled
                                // rowSelection={
                                //   !areAllRowsDisabled() ? rowSelection : undefined
                                // }
                                rowSelection={rowSelection}
                                rowKey={(record) => record.collectionNumber}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                    <hr className="hr-revieworder"></hr>
                    <Grid container mt={1.5} pl={2} pr={2}>
                      <Grid item sm={6}>
                        <Typography>
                          {selectedRowKeys?.length !== 0 && (
                            <b> selected {selectedRowKeys?.length}</b>
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sm={6}
                        sx={{ display: "flex", justifyContent: "end" }}
                      >
                        <Button
                          variant="contained"
                          className={
                            selectedRowKeys?.length !== 0
                              ? "btn-apply-color"
                              : ""
                          }
                          sx={{
                            height: "34px",
                            width: { lg: "15%" },
                          }}
                          disabled={selectedRowKeys?.length === 0}
                          onClick={() =>
                            handleAcceptPopup(
                              dataRecordOrders?.filter((item) =>
                                selectedRowKeys?.includes(item.collectionNumber)
                              )
                            )
                          }
                        >
                          Accept
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  {isShowConfirm && (
                    <Modal
                      open={isShowConfirm}
                      onClose={handleClose}
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Grid
                          item
                          xs={2}
                          md={2}
                          lg={2}
                          sx={{ textAlign: "Right" }}
                        >
                          <img
                            src={Close}
                            onClick={handleClose}
                            style={{
                              width: "14px",
                              height: "14px",
                              cursor: "pointer",
                            }}
                            alt="Close Icon"
                          />
                        </Grid>
                        <Typography
                          id="modal-modal-description"
                          style={{ paddingRight: "50px" }}
                        >
                          Are you sure you have received{" "}
                          {acceptPopupRecord?.length} UPI collection
                          {acceptPopupRecord?.length > 1 ? "s" : ""} worth{" "}
                          <b>
                            ₹{" "}
                            {acceptPopupRecord
                              ?.reduce(
                                (total: any, record: any) =>
                                  total + record.amountCollected,
                                0
                              )
                              .toFixed(2) || 0.0}
                          </b>
                          ?
                        </Typography>
                        <Box
                          sx={{ display: "flex", justifyContent: "end", mt: 3 }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClose}
                            sx={{
                              mr: 2,
                              fontSize: "10px",
                              height: "30px !important",
                            }}
                            className="crt-btn-cancel"
                          >
                            Cancel
                          </Button>
                          {/* <Button
                            sx={{ fontSize: "10px", height: "30px" }}
                            variant="contained"
                            className="btn-colr"
                            onClick={handleAcceptOk}
                          >
                            Yes
                          </Button> */}
                          <LoadingButton
                            sx={{ fontSize: "10px", height: "30px" }}
                            className="Save_btn_des"
                            onClick={handleAcceptOk}
                            loading={
                              pendingCollectionData.collectionRejectAcceptDataLoading
                            }
                            variant="contained"
                          >
                            Yes
                          </LoadingButton>
                        </Box>
                      </Box>
                    </Modal>
                  )}
                  {isRejectShow ? (
                    <RejectedCollection
                      onClose={() => setIsRejectShow(false)}
                    />
                  ) : (
                    <></>
                  )}
                  {openImage ? (
                    <ImageModal
                      open={openImage}
                      handleImageClose={() => setOpenImage(false)}
                      imageList={imageList}
                    />
                  ) : (
                    <></>
                  )}
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <PendingCashPayments salesManId={requestData?.salesManId} />
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                  <PendingChequePayments requestOfData={requestData} />
                </TabPanel>
              </Grid>
              {isShowDetails && (
                <InvoiceDetails
                  onClose={() => setIsShowDetails(false)}
                  requestData={invoiceRequestData}
                />
              )}
            </Box>
          </Box>
        </Card>
      </Box>
    </Stack>
  );
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}
