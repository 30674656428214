import SearchBar from "@mkyy/mui-search-bar";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState,useRef } from "react";
import { IoClose } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/useFetch";
import { sidebarFilterInv } from "../../../../../../Redux/AuthLogin/action";
import {
  updateBrand,
  updateBrandPop,
  updateBrandTemp,
  updatePackagingType,
  updatePackagingTypePop,
  updatePackagingTypeTemp,
  updateSegment,
  updateSegmentPop,
  updateSegmentTemp,
  updateSubVariant,
  updateSubVariantPop,
  updateSubVariantTemp,
  updateVariant,
  updateVariantPop,
  updateVariantTemp,
} from "../../../../../../Redux/XDM/Inventory/filterReducer";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
import { getBrandFilterMore, getPackageTypeFilterMore, getSegmentFilterMore, getSubVarientFilterMore, getVarientFilterMore } from "../../../../../../Redux/XDM/Sales/salesReturnReducer";
import Masonry from "react-masonry-css";

const FilterModal: React.FC<{ title: string }> = ({ title }) => {
  const dispatch = useAppDispatch();
  const filterInv = useAppSelector((state) => state.filterInv);
  const filterInvOption = useAppSelector((state) => state.salesReturnData);
  const alphabets = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode(65 + i)
  );
  let filterOptions: any[] = [];

  const location = useLocation();
  const currentPath = location.pathname;


  const filterData = {
    page: 0,
    size: 4,
    pageStatus: false,
  };

  useEffect(() => {
    switch (title) {
      case "Segment":
        dispatch(getSegmentFilterMore(filterData));
        break;
      case "Brand":
        dispatch(getBrandFilterMore(filterData));
        break;
      case "Variant":
        dispatch(getVarientFilterMore(filterData));
        break;
      case "Sub Variant":
        dispatch(getSubVarientFilterMore(filterData));
        break;
      case "Packaging Type":
        dispatch(getPackageTypeFilterMore(filterData));
        break;
      default:
        break;
    }
  }, [title]);

  const [searchValue, setSearchValue] = useState<string>("");

  const setSelectedSubmit = () => {

    switch (title) {
      case "Segment":
        dispatch(updateSegmentPop(tempData));
        dispatch(updateSegment(tempData));
        break;
      case "Brand":
        dispatch(updateBrandPop(tempData));
        dispatch(updateBrand(tempData));
        break;
      case "Variant":
        dispatch(updateVariantPop(tempData));
        dispatch(updateVariant(tempData));
        break;
      case "Sub Variant":
        dispatch(updateSubVariantPop(tempData));
        dispatch(updateSubVariant(tempData));
        break;
      case "Packaging Type":
        dispatch(updatePackagingTypePop(tempData));
        dispatch(updatePackagingType(tempData));
        break;
      default:
        break;
    }
  };

  const handleToggleFilter = (value: string) => {
    let currentVal;
    let isValueInArray;
    let newVal;

    switch (title) {
      case "Segment":
        currentVal = filterInv.tempSegmentSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateSegmentTemp(newVal));
        break;
      case "Brand":
        currentVal = filterInv.tempBrandSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateBrandTemp(newVal));
        break;
      case "Variant":
        currentVal = filterInv.tempVariantSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateVariantTemp(newVal));
        break;
      case "Sub Variant":
        currentVal = filterInv.tempSubVariantSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updateSubVariantTemp(newVal));
        break;
      case "Packaging Type":
        currentVal = filterInv.tempPackagingTypeSelected;
        isValueInArray = currentVal.includes(value);
        if (isValueInArray) {
          newVal = currentVal.filter((item) => item !== value);
        } else {
          newVal = [...currentVal, value];
        }
        dispatch(updatePackagingTypeTemp(newVal));
        break;
      default:
        break;
    }
  };

  let tempData: any[] = [];

  switch (title) {

    case "Segment":
      filterOptions = filterInvOption.segmentMore;
      tempData = filterInv.tempSegmentSelected;
      break;
    case "Brand":
      filterOptions = filterInvOption.brandMore;
      tempData = filterInv.tempBrandSelected;
      break;
    case "Variant":
      filterOptions = filterInvOption.variantMore;
      tempData = filterInv.tempVariantSelected;
      break;
    case "Sub Variant":
      filterOptions = filterInvOption.subVariantMore;
      tempData = filterInv.tempSubVariantSelected;
      break;
    case "Packaging Type":
      filterOptions = filterInvOption.packagingTypeMore;
      tempData = filterInv.tempPackagingTypeSelected;
      break;

    default:
      break;
  }

  const handleSelectAll = () => {
    switch (title) {
      case "Segment":
        if (tempData?.length === filterOptions?.length) {
          dispatch(updateSegmentTemp([]));
        } else {
          dispatch(updateSegmentTemp(filterOptions.map((option) => option)));
        }
        break;
      case "Brand":
        if (tempData?.length === filterOptions?.length) {
          dispatch(updateBrandTemp([]));
        } else {
          dispatch(updateBrandTemp(filterOptions.map((option) => option)));
        }
        break;
      case "Variant":
        if (tempData?.length === filterOptions?.length) {
          dispatch(updateVariantTemp([]));
        } else {
          dispatch(updateVariantTemp(filterOptions.map((option) => option)));
        }
        break;
      case "Sub Variant":
        if (tempData?.length === filterOptions?.length) {
          dispatch(updateSubVariantTemp([]));
        } else {
          dispatch(updateSubVariantTemp(filterOptions.map((option) => option)));
        }
        break;
      case "Packaging Type":
        if (tempData?.length === filterOptions?.length) {
          dispatch(updatePackagingTypeTemp([]));
        } else {
          dispatch(
            updatePackagingTypeTemp(filterOptions.map((option) => option))
          );
        }
        break;
      default:
        break;
    }
  };

  const handleClearAll = () => {
    switch (title) {
      case "Segment":
        dispatch(updateSegmentPop([]));
        dispatch(updateSegment([]));
        dispatch(updateSegmentTemp([]));
        break;
      case "Brand":
        dispatch(updateBrandPop([]));
        dispatch(updateBrand([]));
        dispatch(updateBrandTemp([]));
        break;
      case "Variant":
        dispatch(updateVariantPop([]));
        dispatch(updateVariant([]));
        dispatch(updateVariantTemp([]));
        break;
      case "Sub Variant":
        dispatch(updateSubVariantPop([]));
        dispatch(updateSubVariant([]));
        dispatch(updateSubVariantTemp([]));
        break;
      case "Packaging Type":
        dispatch(updatePackagingTypePop([]));
        dispatch(updatePackagingType([]));
        dispatch(updatePackagingTypeTemp([]));
        break;
      default:
        break;
    }
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleClose = () => {
    dispatch(sidebarFilterInv(false));
  };

  const alphaChangeOrder = Object.keys(filterOptions)
    .filter((key) => alphabets.includes(key))
    .sort();

    const popupRef = useRef<HTMLDivElement | null>(null); // To reference the popup container

    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          handleClose(); // Close the popup if clicked outside
        }
      };
  
      const handleWindowBlur = () => {
        handleClose(); // Close the popup if the window loses focus
      };
  
      // Add event listeners for click outside and window blur
      document.addEventListener("mousedown", handleClickOutside);
      window.addEventListener("blur", handleWindowBlur);
  
      // Clean up the event listeners when the component is unmounted
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        window.removeEventListener("blur", handleWindowBlur);
      };
    }, [handleClose]);
  return (
    <Box
    ref={popupRef}

    className="sidebarModelContainerSales"
    sx={{ width: { sm: "70%", lg: " 68%", xl: "60%" } }}
  >
    <Box
      sx={{
        justifyContent: "space-between",
        display: "flex",
        alignContent: "flex-end",
      }}
    >
      <Typography
        sx={{
          fontSize: "11.5px !important",
          fontWeight: "600",
          color: "#000000",
          padding: "20px 0px 0px 19px",
        }}
      >
        {title === "Segment"
          ? "Segment"
          : title === "Brand"
          ? "Brand"
          : title === "Variant"
          ? "Variant"
          : title === "Sub Variant"
          ? "Sub Variant"
          : title === "Packaging Type"
          ? "Packaging Type"
          : title}
      </Typography>
      <Box
        className="close_icon_btn cursor_pointer"
        sx={{
          display: "flex",
          justifyContent: "end",
          padding: "20px 23px 0px 0px",
        }}
        onClick={handleClose}
      >
        <IoClose className="ClosePop" />
      </Box>
    </Box>

    <Box
      sx={{
        borderBottom: "2px solid #b6b0b01a",
        width: "95%",
        marginLeft: "18px",
        padding: "6px",
      }}
    ></Box>

    <Box
      sx={{
        justifyContent: "space-between",
        display: "flex",
        alignContent: "flex-end",
        marginTop: "10px",
        padding: "10px 10px 0px 0px",
        alignItems: "baseline",
      }}
    >
      <SearchBar
        onChange={(value) => handleSearch(value)}
        value={searchValue}
        onCancelResearch={() => setSearchValue("")}
        className="sidePopSearchAlpha"
      />
      {alphabets.map((alphabet, index) => (
        <Typography
          key={index}
          sx={{
            fontSize: "10px",
            fontWeight: "400",
            color: "#944986",
          }}
        >
          {alphabet}
        </Typography>
      ))}
    </Box>

    <Box className="scrollSalesPop">
      <Grid item>
        {filterOptions.length !== 0 ? (
          <Masonry
            breakpointCols={{ default: 4 }}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {alphaChangeOrder.map(
              (alphabet: any, index: number) =>
                filterOptions[alphabet] &&
                filterOptions[alphabet].some((option: any) =>
                  title === "Outlet Group" ||
                  title === "Division" ||
                  title === "Division Order Bill" ||
                  title === "Outlet Group OrderBill"
                    ? option.toLowerCase().includes(searchValue.toLowerCase())
                    : option.name
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                ) && (
                  <Grid item sm={3} key={index}>
                    <Box pl={2}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontWeight: "bold",
                          fontSize: "12px",
                          marginLeft: "10px",
                        }}
                      >
                        {alphabet}
                      </Typography>

                      {filterOptions[alphabet]
                        .filter((option: any) =>
                          title === "Outlet Group" ||
                          title === "Division" ||
                          title === "Division Order Bill" ||
                          title === "Outlet Group OrderBill"
                            ? option
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                            : option.name
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                        )
                        .map((name: any, index: number) => (
                          <Grid
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <FormControlLabel
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 15,
                                  color: "#6750A4",
                                },
                                marginLeft: "0px",
                                "& .MuiFormControlLabel-label": {
                                  width: "100px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                },
                              }}
                              key={name.name}
                              control={
                                <Checkbox
                                  checked={tempData.includes(
                                    name.name
                                  )}
                                  onChange={() =>
                                    handleToggleFilter(
                                      name.name
                                    )
                                  }
                                />
                              }
                              label={name.name}
                            />
                          </Grid>
                        ))}
                    </Box>
                  </Grid>
                )
            )}
          </Masonry>
        ) : (
          <Grid container>
            <Grid xs={4}></Grid>
            <Grid xs={4} sx={{ textAlign: "center" }}>
              {" "}
              <Spin size="small" />
            </Grid>
            <Grid xs={4}></Grid>
          </Grid>
        )}
      </Grid>
    </Box>

    <Grid
      container
      xs={12}
      item
      mt={3}
      mb={2}
      sx={{
        display: "flex",
        justifyContent: "end",
      }}
    >
      <Grid item sm={3} lg={2} onClick={handleClose}>
        <Button
          className="Close_button"
          variant="outlined"
          sx={{ fontSize: "11px" }}
        >
          Close
        </Button>
      </Grid>
      <Grid item sm={3} lg={2}>
        <Button
          className="btn-colr "
          variant="contained"
          style={{ width: "75%", fontSize: "11px", height: "34px" }}
          onClick={() => {
            handleClose();
            setSelectedSubmit();
          }}
        >
          Apply
        </Button>
      </Grid>
    </Grid>
  </Box>
  );
};

export default FilterModal;
