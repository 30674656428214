import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Drawer,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  HSNcodeFormValues,
  HSNcodeFormValueErrors,
} from "../../../../typescript/product";
//icons
import Close from "../../../../assets/images/icons/Close_icon.png";
import { useAppDispatch } from "../../../../hooks/useFetch";
import calendar from "../../../../assets/images/icons/Calendar.png";

import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

// date picker
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  HSNcodeFetchData,
  HSNcodeSubmitForm,
} from "../../../../Redux/Masters/Products/HSNcode/action";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface RequestData {
  page: number;
  size: number;
  hsnType: string;
}
interface CreateProductMasterProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
}
export default function CreateProductHsnCodeMaster(
  props: CreateProductMasterProps
) {
  const dispatch = useAppDispatch();
  const { onClose, requestData } = props; // Destructure the props

  const [FormError, setFormError] = useState<boolean>(false);
  const [sucessMessage, setSucessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);

  const [errors, setErrors] = useState<HSNcodeFormValueErrors>({
    hsnCode: "",
    gst: "",
    cgst: "",
    sgst: "",
    igst: "",
    hsnType: "",
    fromDate: "",
  });

  const [formData, setFormData] = useState<HSNcodeFormValues>({
    hsnCode: "",
    gst: "",
    cgst: "",
    sgst: "",
    igst: "",
    hsnType: "infra",
    fromDate: "",
  });

  // 2024-04-25




  const validateFormData = (data: HSNcodeFormValues): HSNcodeFormValueErrors => {
    const errors: HSNcodeFormValueErrors = {};

    const fieldsToValidate: (keyof HSNcodeFormValues)[] = [
      "hsnCode",
      "gst",
      "cgst",
      "sgst",
      "igst",
      "hsnType",
      "fromDate"
    ];

    fieldsToValidate.forEach((field) => {
      if (!data[field]) {
        errors[field] = `${field} is required`;
      }
    });
    return errors;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleChangeFromDate = (name: string, value: string | Date | null) => {
    if (value === null) {
      // Handle case when date is cleared
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: '', // Or any default value you prefer
      }));
      setSelectedDate(null); // Update the selectedDate state
    } else if (typeof value === 'string') {
      // Handle text input changes
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else {
      // Handle date changes
      const selectedDate = new Date(value); // Convert value to Date object if it's not already
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth() + 1; // Months are 0-indexed, so we add 1
      const day = selectedDate.getDate();
      const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: formattedDate,
      }));
      setSelectedDate(selectedDate); // Update the selectedDate state
    }
  };



  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    formData: HSNcodeFormValues
  ): Promise<void> => {
    event.preventDefault();
    const validationErrors = validateFormData(formData);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await dispatch(HSNcodeSubmitForm(formData));
        const successMessage = response.message;
        setFormError(true);
        setSucessMessage(successMessage);
        dispatch(HSNcodeFetchData(requestData));
        setTimeout(() => onClose(), 2000);
      } catch (error: any) {
        if (error.response.data.data) {
          const errorMessage = error.response.data.data;
          setErrors(errorMessage);
        } else {
          setErrorMessage("something went Wrong");
        }
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const handleChanges = (value: string) => {
    console.log(`selected ${value}`);
  };
  const CalendarIcon = () => (
    <img
      src={calendar}
      alt={calendar}
      style={{ width: "24px", height: "24px" }}
    />
  );

  const GRT = ["aaa", "bb"];
  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_stl"
      // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 533,
              md: 536,
              sm: 600,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "95%" }} role="presentation">
            <Grid
              container
              className="crt-header"
              mt={4}
              style={{ paddingLeft: "14px" }}
            >
              <Typography gutterBottom className="crt-header-lable">
                Create HSN Code
              </Typography>

              <Grid item>
                <img
                  src={Close}
                  alt={Close}
                  onClick={() => {
                    onClose();
                  }}
                  style={{ width: "24px", height: "24px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Content */}
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
              marginBottom: "70px",
            }}
            className="create-form"
          >
            {/* Your scrollable content */}
            <form onSubmit={(event) => handleSubmit(event, formData)}>
              <Grid
                item
                sx={{ width: "95%", margin: "auto", marginBottom: "100px" }}
              >
                <Grid item>
                  <Typography className="crt-fnt-lable">HSN Code *</Typography>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      className="Crt-txt-box"
                      name={"hsnCode"}
                      placeholder="Enter Code"
                      value={formData.hsnCode}
                      onChange={handleChange}
                      error={errors.hsnCode ? true : false}
                      helperText={errors.hsnCode && errors.hsnCode}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">GST % *</Typography>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      className="Crt-txt-box"
                      placeholder="Enter GST %"
                      name={"gst"}
                      value={formData.gst}
                      onChange={handleChange}
                      error={errors.gst ? true : false}
                      helperText={errors.gst && errors.gst}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">From Date *</Typography>
                  <Grid item xs={12} mt={1}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        className="Date_picker_box"
                        slots={{
                          openPickerIcon: CalendarIcon,
                        }}
                        slotProps={{
                          textField: { placeholder: "Select Date" },
                        }}
                        sx={{
                          border: 0,
                          width: "100%",
                          backgroundColor: "#f6f6f680",
                          // border: "1px solid  #f6f6fc",
                        }}
                        value={selectedDate} // Pass the selectedDate state
                        onChange={(newValue) => {
                          handleChangeFromDate("fromDate", newValue); // Update the formData with the new date value
                          setSelectedDate(newValue); // Update the selectedDate state
                        }}
                      />

                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">CGST % *</Typography>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      className="Crt-txt-box"
                      placeholder="Enter CGST % "
                      name={"cgst"}
                      value={formData.cgst}
                      onChange={handleChange}
                      error={errors.cgst ? true : false}
                      helperText={errors.cgst && errors.cgst}
                    />
                  </Grid>
                </Grid>{" "}
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">SGST % *</Typography>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      className="Crt-txt-box"
                      placeholder="Enter SGST %"
                      name={"sgst"}
                      value={formData.sgst}
                      onChange={handleChange}
                      error={errors.sgst ? true : false}
                      helperText={errors.sgst && errors.sgst}
                    />
                  </Grid>
                </Grid>{" "}
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">IGST % *</Typography>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      className="Crt-txt-box"
                      placeholder="Enter IGST % "
                      name={"igst"}
                      value={formData.igst}
                      onChange={handleChange}
                      error={errors.igst ? true : false}
                      helperText={errors.igst && errors.igst}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item mt={2} className="product_Create_footer">
                <Button className="crt-btn-cancel" onClick={onClose}>
                  {" "}
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="crt-btn-colr"
                  type="submit"
                  sx={{ mr: 4 }}
                >
                  {" "}
                  Create
                </Button>
              </Grid>
            </form>

            {/* More content... */}
            {FormError ? (
              sucessMessage ? (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="success">{sucessMessage}</Alert>
                </Snackbar>
              ) : (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="error">{errorMessage}</Alert>
                </Snackbar>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Drawer>
    </Box>
    // <Box sx={{ marginTop: "2px" }}>
    //   <Drawer
    //     anchor="right"
    //     open={true}
    //     className="drawer_stl"
    //     // variant="persistent"
    //   >
    //     <Box
    //       sx={{
    //         width: {
    //           lg: 630,

    //           md: 536,
    //           sm: 740,
    //           xs: 400,
    //         },
    //         // display: "flex",
    //         flexDirection: "column",
    //         height: "100%",
    //       }}
    //     >
    //       {/* Heading */}
    //       <Grid item sx={{ width: "95%", margin: "auto" }} role="presentation">
    //         <Grid container className="crt-header" mt={5}>
    //           <Typography gutterBottom className="crt-header-lable">
    //             Create sku
    //           </Typography>

    //           <Grid item>
    //             <img
    //               src={Close}
    //               alt={Close}
    //               // onClick={onClose}
    //               onClick={() => {
    //                 onClose();
    //               }}
    //               style={{ width: "24px", height: "24px" }}
    //             />
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //       {/* Content */}
    //       <Box
    //         mt={3}
    //         sx={{
    //           // flexGrow: 1,
    //           overflowY: "auto",
    //         }}
    //         className="create-form"
    //       >
    //         {/* Your scrollable content */}
    //         <form onSubmit={(event) => handleSubmit(event, formData)}>
    //           <Grid item sx={{ width: "95%", margin: "auto" }}>
    //             <Grid item>
    //               <Typography className="crt-fnt-lable">
    //                 Product CODE
    //               </Typography>
    //               <Grid item xs={12}>
    //                 <TextField
    //                   className="Crt-txt-box"
    //                   placeholder="Enter Code"
    //                   error={errors.uomCode ? true : false}
    //                   helperText={errors.uomCode && errors.uomCode}
    //                   name={"uomCode"}
    //                   value={formData.uomCode}
    //                   onChange={handleChange}
    //                 />
    //               </Grid>
    //             </Grid>
    //             <Grid item mt={2}>
    //               <Typography className="crt-fnt-lable">SAP Code</Typography>
    //               <Grid item xs={12}>
    //                 <TextField
    //                   className="Crt-txt-box"
    //                   placeholder="Enter Code"
    //                   name={"uomName"}
    //                   value={formData.uomName}
    //                   onChange={handleChange}
    //                   error={errors.uomName ? true : false}
    //                   helperText={errors.uomName && errors.uomName}
    //                 />
    //               </Grid>
    //             </Grid>
    //           </Grid>
    //           <Grid item mt={2} className="Create-footer">
    //             <Button className="crt-btn-cancel" onClick={onClose}>
    //               {" "}
    //               Cancel
    //             </Button>
    //             <Button
    //               variant="contained"
    //               className="crt-btn-colr"
    //               type="submit"
    //               sx={{ mr: 4 }}
    //             >
    //               {" "}
    //               Create
    //             </Button>
    //           </Grid>
    //         </form>

    //         {/* More content... */}
    //         {FormError ? (
    //           sucessMessage ? (
    //             <Snackbar
    //               anchorOrigin={{ vertical: "top", horizontal: "right" }}
    //               open={true}
    //               autoHideDuration={2000}
    //               style={{ marginTop: "10%" }}
    //             >
    //               <Alert severity="success">{sucessMessage}</Alert>
    //             </Snackbar>
    //           ) : (
    //             <Snackbar
    //               anchorOrigin={{ vertical: "top", horizontal: "right" }}
    //               open={true}
    //               autoHideDuration={2000}
    //               style={{ marginTop: "10%" }}
    //             >
    //               <Alert severity="error">{errorMessage}</Alert>
    //             </Snackbar>
    //           )
    //         ) : (
    //           ""
    //         )}
    //       </Box>
    //     </Box>
    //   </Drawer>
    // </Box>
  );
}
