// actions.js
import {
  LOGIN_SUCCESS,
  GET_USER_FROM_LOCALSTORAGE,
  LOGOUT,
  SIDEBAR_POPUP_SHOW,
  SIDEBAR_FILTER_SHOW,
  SUBHEADER_HIDDEN,
  SIDEBAR_FILTER_SHOW_INV,
} from "../actionType";

export const loginSuccess = (user: any) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const setUserFromLocalStorage = () => {
  const storedDistributionCode = localStorage.getItem("ad-distributionCode");
  return {
    type: GET_USER_FROM_LOCALSTORAGE,
    payload: storedDistributionCode,
  };
};

export const logout = () => ({
  type: LOGOUT,
});

export const toggleShowMore = (showMore: boolean) => ({
  type: SIDEBAR_POPUP_SHOW,
  payload: showMore,
});
export const sidebarFilter = (filterOpen: boolean, name: string) => {
  return {
    type: SIDEBAR_FILTER_SHOW,
    payload: { filterOpen, name },
  };
};


export const subHeaderHide = (hideSubHeader: boolean) => ({
  type: SUBHEADER_HIDDEN,
  payload: hideSubHeader,
});

export const sidebarFilterInv = (filterOpenInv: boolean) => {
  return {
    type: SIDEBAR_FILTER_SHOW_INV,
    payload:  filterOpenInv,
  };
}
