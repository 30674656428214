import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Drawer,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

//icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Close from "../../../../assets/images/icons/Close_icon.png";

import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import {
  ProductMasterFetchData,
  ProductMasterSubmitForm,
} from "../../../../Redux/Masters/Products/ProductMaster/action";
import {
  ProductMasterFormErrors,
  ProductMasterFormValues,
} from "../../../../typescript/product";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useFetch";
import { ProductHsnCodeFetchData } from "../../../../Redux/Masters/Sidebar/action";
function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface RequestData {
  page: number;
  size: number;
  businessLevel: string;
  categoryLevel: string;
  productLevel: string;
  date: string;
  newProducts: string;
  search: string;
}
interface CreateProductMasterProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
}
interface Producthsn {
  id: string;
  hsnCode: string;
}
export default function CreateProductMaster(props: CreateProductMasterProps) {
  const dispatch = useAppDispatch();
  const { onClose, requestData } = props; // Destructure the props

  const [FormError, setFormError] = useState<boolean>(false);
  const [sucessMessage, setSucessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    dispatch(ProductHsnCodeFetchData());
  }, [dispatch]);

  const productHsnCodeFetch = useAppSelector(
    (state: any) => state.sidebarFilter.ProductHsnCodeName
  );

  const [errors, setErrors] = useState<ProductMasterFormErrors>({
    productCode: "",
    hsnCode: "",
    sapCode: "",
    skuName: "",
    skuShortName: "",
    businessLevelName: "",
    categoryLevel: "",
    brandLevel: "",
    brandCategory: "",
    productLevel: "",
    skuGroup: "",
    skuLevel: "",
    purchaseRate: "",
    distributorMargin: "",
    sellingRate: "",
    mrp: "",
  });

  const [formData, setFormData] = useState<ProductMasterFormValues>({
    productCode: "",
    hsnCode: "",
    sapCode: "",
    skuName: "",
    skuShortName: "",
    businessLevelName: "",
    categoryLevel: "",
    brandLevel: "",
    brandCategory: "",
    productLevel: "",
    skuGroup: "",
    skuLevel: "",
    purchaseRate: "2000",
    distributorMargin: "100",
    sellingRate: "1000",
    mrp: "",
  });

  const validateFormData = (
    data: ProductMasterFormValues
  ): ProductMasterFormErrors => {
    const errors: ProductMasterFormErrors = {};
    const fieldsToValidate: (keyof ProductMasterFormValues)[] = [
      "productCode",
      "hsnCode",
      "sapCode",
      "skuName",
      "skuShortName",
      "businessLevelName",
      "categoryLevel",
      "brandLevel",
      "brandCategory",
      "productLevel",
      "skuGroup",
      "skuLevel",
      "purchaseRate",
      "distributorMargin",
      "sellingRate",
      "mrp",
    ];

    fieldsToValidate.forEach((field) => {
      if (!data[field]) {
        errors[field] = `${field} is required`;
      }
    });
    return errors;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    formData: ProductMasterFormValues
  ): Promise<void> => {
    event.preventDefault();
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await dispatch(ProductMasterSubmitForm(formData));
        const successMessage = response.message;
        setFormError(true);
        setSucessMessage(successMessage);
        dispatch(ProductMasterFetchData(requestData));
        setTimeout(() => onClose(), 2000);
      } catch (error: any) {
        if (error.response.data.data) {
          const errorMessage = error.response.data.data;
          setErrors(errorMessage);
        } else {
          setErrorMessage("something went Wrong");
        }
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const GRT = ["aaa", "bb"];

  const handleChangeHsnCode = (event: SelectChangeEvent<string>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      hsnCode: event.target.value,
    }));
  };

  const handleChangeSelectName = (event: SelectChangeEvent<string>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      businessLevelName: event.target.value,
    }));
  };

  const handleChangeSelectLevel = (event: SelectChangeEvent<string>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      categoryLevel: event.target.value,
    }));
  };
  const handleChangeSelectBrand = (event: SelectChangeEvent<string>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      brandLevel: event.target.value,
    }));
  };
  const handleChangeSelectBrandCategory = (
    event: SelectChangeEvent<string>
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      brandCategory: event.target.value,
    }));
  };
  const handleChangeSelectProduct = (event: SelectChangeEvent<string>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      productLevel: event.target.value,
    }));
  };
  const handleChangeSelectSKU = (event: SelectChangeEvent<string>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      skuGroup: event.target.value,
    }));
  };
  const handleChangeSelectSKULevel = (event: SelectChangeEvent<string>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      skuLevel: event.target.value,
    }));
  };

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_stl"
        // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 533,
              md: 536,
              sm: 600,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "95%", margin: "auto" }} role="presentation">
            <Grid container className="crt-header" mt={5}>
              <Typography gutterBottom className="crt-header-lable">
                Create SKU
              </Typography>

              <Grid item>
                <img
                  src={Close}
                  alt={Close}
                  onClick={() => {
                    onClose();
                  }}
                  style={{ width: "24px", height: "24px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Content */}
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form"
          >
            {/* Your scrollable content */}
            <form onSubmit={(event) => handleSubmit(event, formData)}>
              <Grid
                item
                sx={{ width: "95%", margin: "auto", marginBottom: "100px" }}
              >
                <Grid item>
                  <Typography className="crt-fnt-lable">
                    Product Code *
                  </Typography>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      className="Crt-txt-box"
                      name={"productCode"}
                      placeholder="Product Code"
                      value={formData.productCode}
                      onChange={handleChange}
                      error={errors.productCode ? true : false}
                      helperText={errors.productCode && errors.productCode}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">SAP Code *</Typography>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      className="Crt-txt-box"
                      placeholder="Enter Code"
                      name={"sapCode"}
                      value={formData.sapCode}
                      onChange={handleChange}
                      error={errors.sapCode ? true : false}
                      helperText={errors.sapCode && errors.sapCode}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">HSN Code *</Typography>
                  <Grid item xs={12} sx={{ mt: "5px" }}>
                    <FormControl fullWidth>
                      <Select
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        displayEmpty
                        value={formData.hsnCode}
                        onChange={handleChangeHsnCode}
                      >
                        <MenuItem
                          className="select_placeholder"
                          disabled
                          value=""
                        >
                          <em>Select HSN Code</em>
                        </MenuItem>
                        {productHsnCodeFetch.map(
                          (
                            option: Producthsn,
                            index: React.Key | null | undefined
                          ) => (
                            <MenuItem key={index} value={option.id}>
                              {option.hsnCode}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">SKU Name *</Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Enter Name "
                      type="text"
                      name={"skuName"}
                      value={formData.skuName}
                      onChange={handleChange}
                      error={errors.skuName ? true : false}
                      helperText={errors.skuName && errors.skuName}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">
                    SKU Short Name *
                  </Typography>
                  <Grid item xs={12}>
                    <Grid item>
                      <TextField
                        className="area-Crt-txt-box"
                        placeholder="Enter Short Name  "
                        type="text"
                        name="skuShortName"
                        value={formData.skuShortName}
                        onChange={handleChange}
                        error={errors.skuShortName ? true : false}
                        helperText={errors.skuShortName && errors.skuShortName}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">
                    Business Level Name (100) *
                  </Typography>
                  <Grid item xs={12} sx={{ mt: "5px" }}>
                    <FormControl fullWidth>
                      <Select
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        displayEmpty
                        value={formData.businessLevelName}
                        onChange={handleChangeSelectName}
                      >
                        <MenuItem
                          className="select_placeholder"
                          disabled
                          value=""
                        >
                          <em>Select Business Level</em>
                        </MenuItem>
                        {GRT.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">
                    Category Level (200) *
                  </Typography>
                  <Grid item xs={12} sx={{ mt: "5px" }}>
                    <FormControl fullWidth>
                      <Select
                        placeholder="Select Category Level"
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        value={formData.categoryLevel}
                        displayEmpty
                        onChange={handleChangeSelectLevel}
                      >
                        <MenuItem
                          className="select_placeholder"
                          disabled
                          value=""
                        >
                          <em style={{ color: "#bababc" }}>
                            Select Category Level
                          </em>
                        </MenuItem>
                        {GRT.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item mt={2} sx={{ mt: "5px" }}>
                  <Typography className="crt-fnt-lable">
                    Brand Levle (300) *
                  </Typography>
                  <Grid item xs={12} mt={1}>
                    <FormControl fullWidth>
                      <Select
                        placeholder="Select Brand Level"
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        value={formData.brandLevel}
                        displayEmpty
                        onChange={handleChangeSelectBrand}
                      >
                        <MenuItem
                          className="select_placeholder"
                          disabled
                          value=""
                        >
                          <em style={{ color: "#bababc" }}>
                            Select Brand Level
                          </em>
                        </MenuItem>
                        {GRT.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">
                    Brand Category (400) *
                  </Typography>
                  <Grid item xs={12} sx={{ mt: "5px" }}>
                    <FormControl fullWidth>
                      <Select
                        placeholder="Select Brand Category "
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        value={formData.brandCategory}
                        displayEmpty
                        onChange={handleChangeSelectBrandCategory}
                      >
                        <MenuItem
                          className="select_placeholder"
                          disabled
                          value=""
                        >
                          <em style={{ color: "#bababc" }}>
                            Select Brand Category{" "}
                          </em>
                        </MenuItem>
                        {GRT.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">
                    Product/variant Level(500) *
                  </Typography>
                  <Grid item xs={12} sx={{ mt: "5px" }}>
                    <FormControl fullWidth>
                      <Select
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        value={formData.productLevel}
                        displayEmpty
                        onChange={handleChangeSelectProduct}
                      >
                        <MenuItem
                          className="select_placeholder"
                          disabled
                          value=""
                        >
                          <em style={{ color: "#bababc" }}>
                            Select Product/Variant{" "}
                          </em>
                        </MenuItem>
                        {GRT.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">
                    SKU Group (600) *
                  </Typography>
                  <Grid item xs={12} sx={{ mt: "5px" }}>
                    <FormControl fullWidth>
                      <Select
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        value={formData.skuGroup}
                        displayEmpty
                        onChange={handleChangeSelectSKU}
                      >
                        <MenuItem
                          className="select_placeholder"
                          disabled
                          value=""
                        >
                          <em style={{ color: "#bababc" }}>Select SKU Group</em>
                        </MenuItem>
                        {GRT.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">
                    SKU Level (700) *
                  </Typography>
                  <Grid item xs={12} sx={{ mt: "5px" }}>
                    <FormControl fullWidth>
                      <Select
                        placeholder="Select SKU Level"
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        value={formData.skuLevel}
                        displayEmpty
                        onChange={handleChangeSelectSKULevel}
                      >
                        <MenuItem
                          className="select_placeholder"
                          disabled
                          value=""
                        >
                          <em style={{ color: " #b9babc" }}>
                            Select SKU Level{" "}
                          </em>
                        </MenuItem>
                        {GRT.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* <TextField
                      className="Crt-txt-box"
                      placeholder="    "
                      name={""}
                    /> */}
                  </Grid>
                </Grid>
                <Grid item mt={2} style={{ marginBottom: "166px" }}>
                  <Typography className="crt-fnt-lable">MRP *</Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="MRP "
                      name={"mrp"}
                      value={formData.mrp}
                      onChange={handleChange}
                      error={errors.mrp ? true : false}
                      helperText={errors.mrp && errors.mrp}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item mt={2} className="product_Create_footer">
                <Button className="crt-btn-cancel" onClick={onClose}>
                  {" "}
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="crt-btn-colr"
                  type="submit"
                  sx={{ mr: 4 }}
                >
                  {" "}
                  Create
                </Button>
              </Grid>
            </form>

            {/* More content... */}
            {FormError ? (
              sucessMessage ? (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="success">{sucessMessage}</Alert>
                </Snackbar>
              ) : (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="error">{errorMessage}</Alert>
                </Snackbar>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Drawer>
    </Box>
    // <Box sx={{ marginTop: "2px" }}>
    //   <Drawer
    //     anchor="right"
    //     open={true}
    //     className="drawer_stl"
    //     // variant="persistent"
    //   >
    //     <Box
    //       sx={{
    //         width: {
    //           lg: 630,

    //           md: 536,
    //           sm: 740,
    //           xs: 400,
    //         },
    //         // display: "flex",
    //         flexDirection: "column",
    //         height: "100%",
    //       }}
    //     >
    //       {/* Heading */}
    //       <Grid item sx={{ width: "95%", margin: "auto" }} role="presentation">
    //         <Grid container className="crt-header" mt={5}>
    //           <Typography gutterBottom className="crt-header-lable">
    //             Create sku
    //           </Typography>

    //           <Grid item>
    //             <img
    //               src={Close}
    //               alt={Close}
    //               // onClick={onClose}
    //               onClick={() => {
    //                 onClose();
    //               }}
    //               style={{ width: "24px", height: "24px" }}
    //             />
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //       {/* Content */}
    //       <Box
    //         mt={3}
    //         sx={{
    //           // flexGrow: 1,
    //           overflowY: "auto",
    //         }}
    //         className="create-form"
    //       >
    //         {/* Your scrollable content */}
    //         <form onSubmit={(event) => handleSubmit(event, formData)}>
    //           <Grid item sx={{ width: "95%", margin: "auto" }}>
    //             <Grid item>
    //               <Typography className="crt-fnt-lable">
    //                 Product CODE
    //               </Typography>
    //               <Grid item xs={12}>
    //                 <TextField
    //                   className="Crt-txt-box"
    //                   placeholder="Enter Code"
    //                   error={errors.uomCode ? true : false}
    //                   helperText={errors.uomCode && errors.uomCode}
    //                   name={"uomCode"}
    //                   value={formData.uomCode}
    //                   onChange={handleChange}
    //                 />
    //               </Grid>
    //             </Grid>
    //             <Grid item mt={2}>
    //               <Typography className="crt-fnt-lable">SAP Code</Typography>
    //               <Grid item xs={12}>
    //                 <TextField
    //                   className="Crt-txt-box"
    //                   placeholder="Enter Code"
    //                   name={"uomName"}
    //                   value={formData.uomName}
    //                   onChange={handleChange}
    //                   error={errors.uomName ? true : false}
    //                   helperText={errors.uomName && errors.uomName}
    //                 />
    //               </Grid>
    //             </Grid>
    //           </Grid>
    //           <Grid item mt={2} className="Create-footer">
    //             <Button className="crt-btn-cancel" onClick={onClose}>
    //               {" "}
    //               Cancel
    //             </Button>
    //             <Button
    //               variant="contained"
    //               className="crt-btn-colr"
    //               type="submit"
    //               sx={{ mr: 4 }}
    //             >
    //               {" "}
    //               Create
    //             </Button>
    //           </Grid>
    //         </form>

    //         {/* More content... */}
    //         {FormError ? (
    //           sucessMessage ? (
    //             <Snackbar
    //               anchorOrigin={{ vertical: "top", horizontal: "right" }}
    //               open={true}
    //               autoHideDuration={2000}
    //               style={{ marginTop: "10%" }}
    //             >
    //               <Alert severity="success">{sucessMessage}</Alert>
    //             </Snackbar>
    //           ) : (
    //             <Snackbar
    //               anchorOrigin={{ vertical: "top", horizontal: "right" }}
    //               open={true}
    //               autoHideDuration={2000}
    //               style={{ marginTop: "10%" }}
    //             >
    //               <Alert severity="error">{errorMessage}</Alert>
    //             </Snackbar>
    //           )
    //         ) : (
    //           ""
    //         )}
    //       </Box>
    //     </Box>
    //   </Drawer>
    // </Box>
  );
}
