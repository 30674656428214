import React from "react";
import {
  Button,
  Grid,
  TextField,
  Box,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  InputLabel,
  Typography,
  Card,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DistributorSelection from "./DistributorSelection";

interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const SchemeDefinition: React.FC<DistributorTabProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        //   width: 250,
      },
    },
  };
  const handleChanges = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const ClaimType = ["TP1", "TPR1"];
  const GLCodeValue = ["Scheme Claim", ""];
  const GLCode = ["CG17", ""];
  const BudgetType = ["Amount", ""];
  const Company = ["CAVINKARE PRIVATE LIMITED", ""];
  const PayoutType = ["Amount", "Discount %", "Free Product"];
  const PayoutFrequency = ["Same Bill", ""];
  const ClaimableOn = ["Purchase Rate", ""];
  const FlexiSchemeType = ["None", ""];
  const QPSReset = ["No", ""];

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };
  return (
    <>
      {show ? (
      
        <DistributorSelection onBack={handleclick} />
      ) : (
        <Box>
          <form noValidate>
            <Card className="card-form">
              <Grid container sx={{ mt: 2 }}>
                <Grid xs={2} item sx={{ mt: 1 }}>
                  <InputLabel size="normal" className="fnt-lable">
                    Entry criteria
                  </InputLabel>
                </Grid>
                <Grid item>
                  <RadioGroup row aria-label="mandatory" name="mandatory">
                    <FormControlLabel
                      value="Quantity Based"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="Quantity Based"
                    />
                    <FormControlLabel
                      value="Weight Based "
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="Weight Based"
                    />
                    <FormControlLabel
                      value="Amount Based  "
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="Amount Based"
                    />
                    <FormControlLabel
                      value="Window Display"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="Window Display"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 1 }}>
                <Grid xs={2} item sx={{ mt: 1 }}>
                  <InputLabel size="normal" className="fnt-lable">
                    Additional Criteria
                  </InputLabel>
                </Grid>
                <Grid item>
                  <RadioGroup row aria-label="mandatory" name="mandatory">
                    <FormControlLabel
                      value="None"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="None"
                    />
                    <FormControlLabel
                      value="QPS "
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="QPS"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Card>
            <Card className="card-form" sx={{ mt: 3 }}>
              <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">Claim Type</Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      className="selct-box"
                    >
                      {ClaimType.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">GL Code Value </Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      className="selct-box"
                    >
                      {GLCodeValue.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">GL Code </Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      className="selct-box"
                    >
                      {GLCode.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">Budget Type </Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      className="selct-box"
                    >
                      {BudgetType.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">Company</Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      className="selct-box"
                    >
                      {Company.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">Payout Type</Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      className="selct-box"
                    >
                      {PayoutType.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">
                    Company Scheme Code
                  </Typography>
                  <TextField placeholder="" className="Txt-box" />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">
                    Payout Frequency
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      className="selct-box"
                    >
                      {PayoutFrequency.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">Scheme Code</Typography>
                  <TextField placeholder="" className="Txt-box" />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">
                    Scheme Description
                  </Typography>
                  <TextField placeholder="" className="Txt-box" />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">
                    Scheme Description
                  </Typography>

                  <RadioGroup row aria-label="Claimable" name="Claimable">
                    <FormControlLabel
                      value="Yes"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No "
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable"> Claimable On</Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      className="selct-box"
                    >
                      {ClaimableOn.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">
                    {" "}
                    Scheme Start Date
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker sx={{ width: "90%" }} />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">Scheme End Date</Typography>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker sx={{ width: "90%" }} />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">Scheme Status</Typography>

                  <RadioGroup row aria-label="Claimable" name="Claimable">
                    <FormControlLabel
                      value="Active"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="Active"
                    />
                    <FormControlLabel
                      value="InActive "
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="InActive"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Card>
            <Card className="card-form" sx={{ mt: 3 }}>
              <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">Combi Scheme</Typography>

                  <RadioGroup row aria-label="Combi Scheme" name="Combi Scheme">
                    <FormControlLabel
                      value="Yes"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No "
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">Flexi Scheme</Typography>

                  <RadioGroup row aria-label="Flexi Scheme" name="Flexi Scheme">
                    <FormControlLabel
                      value="Yes"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No "
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">Consumer Promo</Typography>

                  <RadioGroup
                    row
                    aria-label="Consumer Promo"
                    name="Consumer Promo"
                  >
                    <FormControlLabel
                      value="Yes"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No "
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">
                    Flexi Scheme Type{" "}
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      className="selct-box"
                    >
                      {FlexiSchemeType.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">QPS Reset</Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      className="selct-box"
                    >
                      {QPSReset.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">Retailer Cap</Typography>
                  <TextField placeholder="" className="Txt-box" />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                  <Typography className="fnt-lable">Invoice Cap</Typography>
                  <TextField placeholder="" className="Txt-box" />
                </Grid>
              </Grid>
            </Card>

            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", mt: 4 }}
            >
              <Grid item sx={{ mr: 2 }}>
                <Button variant="outlined">
                  <CancelIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />{" "}
                  Clear
                </Button>
              </Grid>

              <Grid item>
                <Button
                  className="btn-colr "
                  variant="contained"
                  onClick={handleclick}
                >
                  <RedoOutlinedIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />
                  Next
                </Button>
              </Grid>
              <Grid item sx={{ ml: 2 }}>
                <Button variant="outlined" onClick={handleBackClick}>
                  <CancelIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />{" "}
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </>
  );
};
export default SchemeDefinition;
