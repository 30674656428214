import React from "react";

import { Box } from "@mui/material";

const Reports: React.FC = () => {

  return <Box>
    <p>Reports Reports</p>
  </Box>;
};
export default Reports;
