// SubTabs.js
import React, { useEffect, useState } from "react";
import {
  Tabs,
  Card,
  Tab,
  Typography,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
import {
  sidebarFilter,
  subHeaderHide,
} from "../../../../../Redux/AuthLogin/action";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useFetch";
import {
  updateBasedOnStock,
  updateBrand,
  updateBrandPop,
  updateBrandTemp,
  updateDivision,
  updateDivisionPop,
  updateDivisionTemp,
  updatePackagingType,
  updatePackagingTypePop,
  updatePackagingTypeTemp,
  updateReason,
  updateSegment,
  updateSegmentPop,
  updateSegmentTemp,
  updateSize,
  updateSizePop,
  updateSizeTemp,
  updateSubVariant,
  updateSubVariantPop,
  updateSubVariantTemp,
  updateVariant,
  updateVariantPop,
  updateVariantTemp,
} from "../../../../../Redux/XDM/Inventory/filterReducer";
import { activeSubTab } from "../../../../../Redux/XDM/Header/tabReducer";
import {
  updateDate,
  updateFromDate,
  updateInvoiceType,
  updateInvoiceTypePop,
  updateInvoiceTypeTemp,
  updateOutlet,
  updateOutletPop,
  updateOutletTemp,
  updatePaymentMode,
  updatePaymentModePop,
  updatePaymentModeTemp,
  updateRoute,
  updateRoutePop,
  updateRouteTemp,
  updateSalesman,
  updateSalesmanPop,
  updateSalesmanTemp,
  updateStatusGrn,
  updateStocks,
  updateToDate,
} from "../../../../../Redux/XDM/Sales/billingReducer";
import {
  updateMaxValue,
  updateMinValue,
  updateOutletDivision,
  updateOutletDivisionPop,
  updateOutletDivisiontemp,
  updateOutletGroup,
  updateOutletGroupPop,
  updateOutletGrouptemp,
  updateStatus,
} from "../../../../../Redux/XDM/Sales/outletSideBarReducer";
interface SubTabsProps {
  mainTabValue: string;
}

const useStyles = makeStyles((theme) => ({
  gradientText: {
    background: "linear-gradient(90.19deg, #614FB3 0%, #944986 100%)", // Example gradient from red to green
    border: "1px solid ",
    borderRadius: "30px",
    alignItems: "center",
    fontSize: "10px",
    display: "flex",
    padding: "4px 12px",
    justifyContent: "center",
    // height: "27px",
    gap: "12px",
    color: "#FFF",
  },
  customTab: {
    border: "1px solid #e1e7ed",
    fontSize: "10px",
    padding: "4px 12px",
    borderRadius: "30px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    // height: "27px",
    backgroundColor: "", // Adjust the color as needed
  },
}));

const SubTabs: React.FC<SubTabsProps> = ({ mainTabValue }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const matchesXs = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  // const [adLogin, setAdLogin] = useState('');

  const subTabs = [
    // Define sub-tabs for each main tab
    [],
    [
      {
        label: "Purchase Request",
        icon: "Icon3",
        Route: "/purchase/purchase_request",
      },
      { label: "Invoices", icon: "Icon4", Route: "/purchase/invoices" },
      {
        label: "Purchase Return",
        icon: "Icon5",
        Route: "/purchase/purchase_retrun",
      },
      { label: "Account", icon: "Icon6", Route: "/purchase/purchase_account" },
    ],
    [
      { label: "Company", icon: "Icon5", Route: "/sales/sales_company" },
      { label: "Supplier", icon: "Icon6", Route: "/sales/sales_company1" },
      { label: "Tax", icon: "Icon5", Route: "/sales/sales_company2" },
      { label: "Products", icon: "Icon6", Route: "/sales/sales_company3" },
      { label: "Outlets", icon: "Icon5", Route: "/sales/sales_company4" },
      { label: "Distributor", icon: "Icon6", Route: "/sales/sales_company5" },
      { label: "Configuration", icon: "Icon6", Route: "/sales/sales_company6" },
    ],
    [
      { label: "Inventory", icon: "Icon5", Route: "/inventory/inventory_data" },
      { label: "Supplier", icon: "Icon6", Route: "/inventory/inventory_data1" },
      { label: "Tax", icon: "Icon5", Route: "/inventory/inventory_data2" },
      { label: "Products", icon: "Icon6", Route: "/inventory/inventory_data3" },
      { label: "Outlets", icon: "Icon5", Route: "/inventory/inventory_data4" },
      {
        label: "Distributor",
        icon: "Icon6",
        Route: "/inventory/inventory_data5",
      },
      {
        label: "Configuration",
        icon: "Icon6",
        Route: "/inventory/inventory_data6",
      },
    ],
    [
      { label: "Report", icon: "Icon5", Route: "/reports/reports_data" },
      { label: "Supplier", icon: "Icon6", Route: "/reports/reports_data1" },
      { label: "Tax", icon: "Icon5", Route: "/reports/reports_data2" },
      { label: "Products", icon: "Icon6", Route: "/reports/reports_data3" },
      { label: "Outlets", icon: "Icon5", Route: "/reports/reports_data4" },
      { label: "Distributor", icon: "Icon6", Route: "/reports/reports_data5" },
      {
        label: "Configuration",
        icon: "Icon6",
        Route: "/reports/reports_data6",
      },
    ],

    //AD - login
    [
      { label: "Company", icon: "Icon5", Route: "/master/company_dashboard" },
      { label: "Supplier", icon: "Icon6", Route: "/master/dashboard_supplier" },
      { label: "Products", icon: "Icon5", Route: "/master/product_dashboard" },
      {
        label: "Distributor",
        icon: "Icon5",
        Route: "/master/master_distributor",
      },
      { label: "Customer", icon: "Icon5", Route: "/master/master_customer" },
      { label: "Salesman", icon: "Icon6", Route: "/master/master_salesman" },
      {
        label: "Configuration",
        icon: "Icon5",
        Route: "/master/Configuration_dashboard",
      },
    ],
    // Distributor Login Purchase
    [
      {
        label: "Invoices & GRN",
        icon: "Icon5",
        Route: "/xdm/purchase/invoices-grn",
      },
    ],
    // Distributor Login Inventory
    [
      {
        label: "Saleable Stock",
        icon: "Icon5",
        Route: "/xdm/inventory/saleable",
      },
      {
        label: "Non-Saleable Stock",
        icon: "Icon6",
        Route: "/xdm/inventory/non-saleable",
      },
    ],

    // Distributor Login Sales
    [
      {
        label: "Order to Billing",
        icon: "Icon5",
        Route: "/xdm/sales/order-billing",
      },
      {
        label: "Manual Order",
        icon: "Icon6",
        Route: "/xdm/sales/manual-order",
      },
      { label: "Invoices", icon: "Icon5", Route: "/xdm/sales/invoices" },
      { label: "Outlet", icon: "Icon6", Route: "/xdm/sales/outlet" },
      { label: "Collection", icon: "Icon6", Route: "/xdm/sales/collection" },
      {
        label: "Sales return",
        icon: "Icon6",
        Route: "/xdm/sales/sales-return",
      },
    ],
  ];

  const [dynamicValue, setDynamicValue] = React.useState(0);
  const location = useLocation();
  const currentPath = location.pathname;

  const subTabActive = useAppSelector((state) => state.activeTab.subTab);

  useEffect(() => {
    // Update dynamicValue based on mainTabValue
    if (mainTabValue === "dashboard") {
      setDynamicValue(0);
    } else if (mainTabValue === "purchase") {
      setDynamicValue(1);
    } else if (mainTabValue === "sales") {
      setDynamicValue(2);
    } else if (mainTabValue === "inventory") {
      setDynamicValue(3);
    } else if (mainTabValue === "reports") {
      setDynamicValue(4);
    } else if (mainTabValue === "master") {
      setDynamicValue(5);
    } else if (mainTabValue === "invoices-grn") {
      setDynamicValue(6);
    } else if (mainTabValue === "saleable" || mainTabValue === "non-saleable") {
      setDynamicValue(7);
    } else if (
      mainTabValue === "order-billing" ||
      mainTabValue === "manual-order" ||
      mainTabValue === "invoices" ||
      mainTabValue === "outlet" ||
      mainTabValue === "manual-order-review" ||
      mainTabValue === "sales-return" ||
      mainTabValue === "collection" ||
      mainTabValue === "without-invoice-sales-return" ||
      mainTabValue === "with-invoice-sales-return"
    ) {
      setDynamicValue(8);
    } else {
      setDynamicValue(0);
    }
  }, [mainTabValue]);

  const navigate = useNavigate();
  const classes = useStyles();

  const sidebarChange = () => {
    dispatch(sidebarFilter(false, ""));
    dispatch(subHeaderHide(false));
  };

  useEffect(() => {
    if (currentPath.includes("manual")) {
      dispatch(activeSubTab("/xdm/sales/manual-order"));
    } else if (currentPath.includes("sales-return")) {
      dispatch(activeSubTab("/xdm/sales/sales-return"));
    } else {
      dispatch(activeSubTab(currentPath));
    }
  }, [currentPath]);

  const clearAll = () => {
    dispatch(updateBasedOnStock([]));
    dispatch(updateDivision([]));
    dispatch(updateReason([]));
    dispatch(updateSegment([]));
    dispatch(updateBrand([]));
    dispatch(updateVariant([]));
    dispatch(updateSubVariant([]));
    dispatch(updatePackagingType([]));
    dispatch(updateSize([]));
    dispatch(updateDivisionPop([]));
    dispatch(updateSegmentPop([]));
    dispatch(updateBrandPop([]));
    dispatch(updateVariantPop([]));
    dispatch(updateSubVariantPop([]));
    dispatch(updatePackagingTypePop([]));
    dispatch(updateSizePop([]));
    dispatch(updateDivisionTemp([]));
    dispatch(updateSegmentTemp([]));
    dispatch(updateBrandTemp([]));
    dispatch(updateVariantTemp([]));
    dispatch(updateSubVariantTemp([]));
    dispatch(updatePackagingTypeTemp([]));
    dispatch(updateSizeTemp([]));
    dispatch(updateStocks([]));
    dispatch(updateSalesman([]));
    dispatch(updateRoute([]));
    dispatch(updateOutlet([]));
    dispatch(updateSalesmanPop([]));
    dispatch(updateRoutePop([]));
    dispatch(updateOutletPop([]));
    dispatch(updateSalesmanTemp([]));
    dispatch(updateRouteTemp([]));
    dispatch(updateOutletTemp([]));
    dispatch(updateStatus([]));
    dispatch(updateMinValue(""));
    dispatch(updateMaxValue(""));
    dispatch(updateDate([]));
    dispatch(updateToDate([]));
    dispatch(updateStatusGrn([]));
    dispatch(updateInvoiceType([]));
    dispatch(updateInvoiceTypePop([]));
    dispatch(updateInvoiceTypeTemp([]));
    dispatch(updateFromDate([]));
    dispatch(updateOutletGroup([]));
    dispatch(updateOutletDivision([]));
    dispatch(updateOutletGrouptemp([]));
    dispatch(updateOutletDivisiontemp([]));
    dispatch(updateOutletGroupPop([]));
    dispatch(updateOutletDivisionPop([]));
    dispatch(updatePaymentMode([]));
    dispatch(updatePaymentModePop([]));
    dispatch(updatePaymentModeTemp([]));
  };
  return (
    <>
      {!matchesXs && (
        <Card
          className="SubTab_Card"
          sx={{
            overflowX: "auto",
            display: "flex",
            justifyContent: "center",
            boxShadow: "none",
            borderRadius: "0px",
            height: "40px",
            alignItems: "center",
          }}
        >
          <Tabs
            className="subtab"
            value={subTabActive}
            TabIndicatorProps={{
              style: { display: "none" },
            }}
          >
            {/* Render sub tabs based on the main tab value */}
            {subTabs[dynamicValue].map((subTab, index) => (
              <Tab
                value={subTab.Route}
                className="tab_hover tab_caps"
                key={index}
                label={
                  <Typography
                    className={`${subTabActive === subTab.Route
                        ? classes.gradientText
                        : classes.customTab
                      } ${classes.customTab} `}
                  >
                    {subTab.label}
                  </Typography>
                }
                iconPosition="start"
                onClick={() => {
                  navigate(subTab.Route);
                  sidebarChange();
                  dispatch(activeSubTab(subTab.Route));
                  if (
                    subTab.label === "Purchase" ||
                    subTab.label === "Saleable Stock" ||
                    subTab.label === "Non-Saleable Stock" ||
                    subTab.label === "Order to Billing" ||
                    subTab.label === "Invoices" ||
                    subTab.label === "Outlet" ||
                    subTab.label === "Collection" ||
                    subTab.label === "Sales return"
                  ) {
                    clearAll();
                  }
                }}
              />
            ))}
          </Tabs>
        </Card>
      )}
    </>
  );
};

export default SubTabs;
