import React, { useRef } from "react";

import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import type { TableColumnsType } from 'antd';
import SearchBar from '@mkyy/mui-search-bar';
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../../components/Modal/DeleteSupplier";
import AddIcon from "@mui/icons-material/Add";
import Distributorcreate from "./Createdistributor";
import DistributorView from "./DistributorView/DistributorView";
import DistributorEdit from "./DistributorEdit/DistributorEdit";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  Distributor: string;
  BranchCode: string;
  Location: string;
  PhoneNumber: string;
  ContactPerson: string;
  action: string;
}

const Distributor: React.FC = () => {

  const [isViewing, setIsViewing] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);

  const [textFieldValue, setTextFieldValue] = React.useState('');
 
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // search function logic here
  };
 

  const handleCreateNew = () => {
    setShowForm(!showForm);
  };

  const handleEditClick = (event: React.MouseEvent<unknown>) => {
    setIsEditing(!isEditing);
  };

  const handleViewClick = (event: React.MouseEvent<unknown>) => {
    setIsViewing(!isViewing);
  };
  const handleDeleteClick = (event: React.MouseEvent<unknown>) => {
    setIsDelete(!isDelete);
  };

  const columns: TableColumnsType<DataType>= [

    {
      dataIndex: "Distributor",
      title: "Distributor",
      sorter: (a, b) => a.Distributor.length - b.Distributor.length,
    },
    {
      dataIndex: "BranchCode",
      title: "Branch Code",
      sorter: (a, b) => a.BranchCode.length - b.BranchCode.length,
    },
    {
      dataIndex: "Location",
      title: "Location",
      sorter: (a, b) => a.Location.length - b.Location.length,
    },
    {
      dataIndex: "PhoneNumber",
      title: "Phone Number",
      sorter: (a, b) => a.PhoneNumber.length - b.PhoneNumber.length,
    },
    {
      dataIndex: "ContactPerson",
      title: "Contact Person",
      sorter: (a, b) => a.ContactPerson.length - b.ContactPerson.length,
    },

    {
      dataIndex: "action",
      title: "Action",

      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            onClick={(event) => handleViewClick(event)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
          <EditIcon
            onClick={(event) => handleEditClick(event)}
            style={{
              cursor: "pointer",
              marginLeft: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />
          <DeleteIcon
            onClick={(event) => handleDeleteClick(event)}
            style={{
              cursor: "pointer",
              marginRight: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      Distributor: "AKSHAJ ENTERPRISES",
      BranchCode: "1101090335",
      Location: "DELHI",
      PhoneNumber: "9999999999",
      ContactPerson: "ABC",
      action: "",
    },
    {
      key: 2,
      Distributor: "BHAWNA ENTERPRISES",
      BranchCode: "1101090340",
      Location: "DELHI",
      PhoneNumber: "9999999999",
      ContactPerson: "ABC",
      action: "",
    },
    {
      key: 3,
      Distributor: "HARDIK MAYANAK AGENCEY",
      BranchCode: "1101090341",
      Location: "DELHI",
      PhoneNumber: "9999999999",
      ContactPerson: "ABC",
      action: "",
    },
    {
      key: 4,
      Distributor: "KATYANI ENTERPRISES",
      BranchCode: "1101090338",
      Location: "DELHI",
      PhoneNumber: "9999999999",
      ContactPerson: "ABC",
      action: "",
    },
    {
      key: 5,
      Distributor: "	R. R ENTERPRISES",
      BranchCode: "1101334289",
      Location: "DELHI",
      PhoneNumber: "9953153102",
      ContactPerson: "ABC",
      action: "",
    },
    {
      key: 6,
      Distributor: "	RL AGENCY",
      BranchCode: "1101455200",
      Location: "DELHI ",
      PhoneNumber: "9953891119",
      ContactPerson: "ABC",
      action: "",
    },
  ];
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
};
  return (
    <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
      <Grid item xs={12} className="pad-10 mar-tb">
        <Typography className="cursor_pointer">
          <b>Distributor</b>
        </Typography>
      </Grid>

      {showForm ? (
        <Distributorcreate onBack={handleCreateNew} />
      ) : isEditing ? (
        <DistributorEdit onBack={(event) => handleEditClick(event)} />
      ) : isViewing ? (
        <DistributorView onBack={(event) => handleViewClick(event)} />
      ) : (
        <Box>
          <Button
            variant="contained"
            className="btn-colr"
            onClick={handleCreateNew}
          >
            {" "}
            <AddIcon /> Create new
          </Button>
          <Grid item sx={{ mt: 2 }}>
            <Box>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "end", mb: 2 }}
              >
                <SearchBar
                  className="search-table"
                  value={textFieldValue}
                  onChange={(newValue) => {
                    setTextFieldValue(newValue);
                    handleSearch();
                  }}
                />
              </Grid>
              <CommonTable
                // rowSelection={rowSelection}
                data={data}
                columns={columns}
              />

              {isDelete && (
                <DeleteModal onBack={(event) => handleDeleteClick(event)} />
              )}
            </Box>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
export default Distributor;
