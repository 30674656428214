import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
  Alert,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import { ReactComponent as ErrorIcon } from "../../assets/images/icons/errorIcon.svg";
import { ReactComponent as SuccessIcon } from "../../assets/images/icons/successIcon.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAppDispatch } from "../../hooks/useFetch";
import { useSelector } from "react-redux";
import { updatePassword } from "../../Redux/XDM/Login/OtpSlice";
import LoadingButton from "@mui/lab/LoadingButton";
interface PasswordUpdateProps {
  onStatusChange: (status: boolean) => void;
  phone: string;
  otp: string;
}
const PasswordUpdate: React.FC<PasswordUpdateProps> = ({
  phone,
  otp,
  onStatusChange,
}) => {
  const navigator = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [successAlert, setSuccessAlert] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [loadingbtn, setLoadingbtn] = useState<boolean>(false);

  const [errors, setErrors] = useState({
    password: "",
  });
  const dispatch = useAppDispatch();
  const apiError = useSelector((state: any) => state.OtpRequest?.error);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleMouseDownNewPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  useEffect(() => {}, []);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoadingbtn(true);
    event.preventDefault();
    if (validateForm()) {
      const resultAction = await dispatch(
        updatePassword({
          mobileNo: phone,
          otp: otp,
          password: password,
          confirmpassword: newPassword,
        })
      );
      if (updatePassword.fulfilled.match(resultAction)) {
        setSuccessAlert(true);
        setLoadingbtn(false);
        setTimeout(() => {
          onStatusChange(true);
          navigator("/distributor/login");
          setSuccessAlert(false);
        }, 3000);
      } else {
        setLoadingbtn(false);
      }
    } else {
      setLoadingbtn(false);
    }
  };
  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowNewPassword = () =>
    setShowNewPassword((showNew) => !showNew);
  const validateForm = () => {
    let isValid = true;
    let errors = { password: "" };
    let PasswordErrors: string[] = [];
    if (!password) {
      PasswordErrors.push("Password is required.");
      isValid = false;
    }

    if (!newPassword) {
      PasswordErrors.push("Confirm Password is required.");
      isValid = false;
    } else {
      if (newPassword.length < 8) {
        PasswordErrors.push("Must be at least 8 characters long.");
        isValid = false;
      }
      if (!/[A-Z]/.test(newPassword)) {
        PasswordErrors.push("Must contain at least one uppercase letter.");
        isValid = false;
      }
      if (!/[a-z]/.test(newPassword)) {
        PasswordErrors.push("Must contain at least one lowercase letter.");
        isValid = false;
      }
      if (!/[0-9]/.test(newPassword)) {
        PasswordErrors.push("Must contain at least one numeric character.");
        isValid = false;
      }
      if (!/[!@#$%^&*]/.test(newPassword)) {
        PasswordErrors.push("Must contain at least one special character.");
        isValid = false;
      }
      if (password != newPassword) {
        PasswordErrors.push("Password does not match.");
        isValid = false;
      }
    }
    if (PasswordErrors.length > 0) {
      errors.password = PasswordErrors.join("\n");
    }
    setErrors(errors);
    return isValid;
  };
  return (
    <Grid
      item
      xs={12}
      sm={8}
      md={4.5}
      lg={3.5}
      xl={3.5}
      component={Paper}
      elevation={6}
      className="sigintop"
      square
    >
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "39%",
        }}
      >
        <img
          src="assets/Images/LoginImage/Altec Logo.png"
          alt="logo"
          title="Altec"
          height="70px"
        ></img>
        {errors.password && (
          <Alert
            icon={<ErrorIcon />}
            severity="error"
            className="w-100px mt-10 error_alert width-88"
            color="error"
            style={{ whiteSpace: "pre-line" }}
          >
            {errors.password}
          </Alert>
        )}
        {successAlert && (
          <Alert
            severity="success"
            color="success"
            icon={<SuccessIcon height={22} width={22} />}
            className="w-100px width-88 mt-10 success_alert"
          >
            Password Changed Successfully
          </Alert>
        )}
        {apiError && (
          <Alert
            icon={<ErrorIcon />}
            severity="error"
            className="w-100px mt-10 width-88 error_alert"
            color="error"
          >
            {apiError}
          </Alert>
        )}
        {!errors.password && !apiError && !successAlert && (
          <Typography
            style={{ fontSize: "9px", width: "100%", padding: "10px 25px" }}
            id="DisText"
          >
            Must be 8 characters long
            <br />
            Must contain a mix of letters, numbers, and special characters
            <br />
            Must contain minimum 1 upper case
            <br />
            Must contain minimum 1 lower case
            <br />
            Must contain minimum 1 numeric character
            <br />
            Must contain minimum 1 special character
          </Typography>
        )}
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            marginTop:
              apiError || errors.password || successAlert ? "2%" : "0%",
          }}
          style={{ width: "100%", padding: "0px 25px 0px 25px" }}
        >
          <Box className="mtsize">
            <Typography
              style={{ display: "flex", fontSize: "11px", fontWeight: "700" }}
              id="DisText"
            >
              Enter New Password
            </Typography>
            <OutlinedInput
              id="outlined-adornment-password"
              className="mt_root"
              style={{ height: "35px" }}
              type={showPassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              inputProps={{ maxLength: 30 }}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
          <Box style={{ marginTop: "19px" }} className="mtsize">
            <Typography
              style={{ display: "flex", fontSize: "11px", fontWeight: "700" }}
              id="DisText"
            >
              Confirm New Password
            </Typography>
            <OutlinedInput
              id="outlined-adornment-password"
              className="mt_root"
              style={{ height: "35px" }}
              type={showNewPassword ? "text" : "password"}
              onChange={(e) => setNewPassword(e.target.value)}
              inputProps={{ maxLength: 30 }}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownNewPassword}
                    edge="end"
                  >
                    {showNewPassword ? < Visibility/> : < VisibilityOff/>}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>

          <LoadingButton
            type="submit"
            fullWidth
            loading={loadingbtn}
            loadingPosition="start"
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              //  background: "linear-gradient(90deg, #1677FF 0%, #003EB3 100%)",
              background: "linear-gradient(90deg, #614FB3 0%, #944986 100%)",
              marginTop: "42px",
              // width: "220px",
              textTransform: "none",
            }}
          >
            Submit
          </LoadingButton>
          <Grid container sx={{ marginTop: "91px" }}>
            <Grid item xs>
              <Typography
                style={{ fontWeight: "400", fontSize: "12px", opacity: "50%" }}
              >
                Copyright © 2024 CITPL. All right reserved.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};
export default PasswordUpdate;
