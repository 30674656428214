import React from 'react';
import { Box, TextField,Button, Grid, InputLabel } from '@mui/material';


interface ViewGeoFormProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
}

const GeoHierarchyViewForm: React.FC<ViewGeoFormProps> = ({ onBack }) => {
    const handleBackClick = (event: React.MouseEvent<unknown>) => {
        onBack(event); // Pass the event argument to onBack function
    };

    return (
        <>
            <Box className='card-form'>
                <form noValidate>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm:3 }}>
                        
                        
                    <Grid item xs={12} sm={4} md={3}>
                        <InputLabel size="normal" className='fnt-lable' >Code</InputLabel>
                            <TextField  className='Txt-box' />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                        <InputLabel size="normal" className='fnt-lable' >Name</InputLabel>
                            <TextField  className='Txt-box' />
                        </Grid>
               
                        <Grid item xs={12} mt={2} display="flex" justifyContent="center">
                            <Button variant="contained" className='btn-colr' onClick={handleBackClick}>
                                Back
                            </Button>
                            
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </>
    );
}
export default GeoHierarchyViewForm