import React, { useRef } from "react";
import {
  Box,
  TextField,
  FormControl,
  MenuItem,
  Button,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";

interface UserProfileCreateFormProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}
const ITEM_HEIGHT = 20;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      //   width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const UserProfileCreateForm: React.FC<UserProfileCreateFormProps> = ({
  onBack,
}) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const UserGroupOptions = ["UserGroup A", "UserGroup B", "UserGroup C"];
  const SalesLevelOptions = ["SalesLevel A", "SalesLevel B", "SalesLevel C"];

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      // Do something with the selected file
      // console.log(selectedFile.name);
    }
  };

  const [personName, setPersonName] = React.useState<string[]>([]);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [error, setError] = React.useState("");

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event: any) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
    } else {
      // Reset form fields and error
      setPassword("");
      setConfirmPassword("");
      setError("");
    }
  };
  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <Box className="card-form">
        <form noValidate onSubmit={handleSubmit}>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                User Group
              </InputLabel>
              <FormControl fullWidth>
                <Select className="selct-box">
                  {UserGroupOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                User Name
              </InputLabel>
              <TextField placeholder="username" className="Txt-box" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                User Login Code{" "}
              </InputLabel>
              <TextField placeholder="userlogincode" className="Txt-box" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Designation
              </InputLabel>
              <TextField placeholder="designation" className="Txt-box" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Password
              </InputLabel>

              <TextField
                type="password"
                variant="outlined"
                fullWidth
                value={password}
                onChange={handlePasswordChange}
                // margin="normal"
                required
                className="Txt-box"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Confirm PassWord
              </InputLabel>

              <TextField
                type="password"
                variant="outlined"
                fullWidth
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                // margin="normal"
                required
                error={error !== ""}
                helperText={error}
                className="Txt-box"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Email Id
              </InputLabel>
              <TextField placeholder="emailid" className="Txt-box" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                {" "}
                Mobile No
              </InputLabel>
              <TextField placeholder="State" className="Txt-box" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <input
                ref={fileInputRef}
                type="file"
                accept=".png,.gif,.jpeg"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                className="btn-apply-color"
                sx={{ marginTop: "18px" }}
                onClick={handleButtonClick}
              >
                Select Photo
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Sales Hierarchy Level
              </InputLabel>
              <FormControl fullWidth>
                <Select className="selct-box">
                  {SalesLevelOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Sales Hierarchy Values
              </InputLabel>

              <FormControl sx={{ width: 300 }}>
                <Select
                  value={personName}
                  onChange={handleChange}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Radio
                        checked={personName.indexOf(name) > -1}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                            color: "#6750A4",
                          },
                        }}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Status
              </InputLabel>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="Channel"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                            color: "#6750A4",
                          },
                        }}
                      />
                    }
                    label="Channel"
                  />
                  <FormControlLabel
                    value="Sub Channel"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 18,
                            color: "#6750A4",
                          },
                        }}
                      />
                    }
                    label="Sub Channel"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} mt={2} display="flex" justifyContent="center">
              <Button variant="outlined" onClick={handleBackClick} className="button-lable">
                Cancel
              </Button>
              <Button
                variant="contained"
                className="btn-colr"
                style={{ marginLeft: "10px" }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};
export default UserProfileCreateForm;
