import Routes from './routes';
import ThemeCustomization from '../src/themes';

import React, { Suspense, ComponentType } from 'react';
import Loader from './components/Loader';
import { AuthProvider } from './routes/AuthContext';

function App() {
  return (
    // <div className="App">
    <ThemeCustomization >
      {/* <ScrollTop> */}
      <div className="App">
      {/* <Suspense fallback={''}> */}
        <AuthProvider>
        <Routes />
        </AuthProvider>
        {/* </Suspense> */}
      </div>
      {/* </ScrollTop> */}
    </ThemeCustomization>

  );
}

export default App;
