import React, { useRef } from "react";
import {
  Button,
  Grid,
  TextField,
  Card,
  Box,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link,
  Select,
  FormControl,
} from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomerEditCoverage from "./CustomerEditCoverage";


  interface CustomerEditLicenseProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
  }
  
  const  CustomerEditLicense: React.FC< CustomerEditLicenseProps> = ({ onBack }) => {
    const handleBackClick = (event: React.MouseEvent<unknown>) => {
      onBack(event); // Pass the event argument to onBack function
    };
  const symbol = [
    { value: ">=", label: ">=" },
    { value: "<=", label: "<=" },
    { value: "==", label: "==" },
  ];
  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };
  return (
    <>
    {show?(
      <CustomerEditCoverage onBack={handleclick} />
      ):(
    <Box>
      <form>
        <Card className="card-form" sx={{ border: "1px solid #EEE" }}>
          <Grid className="box-lable">Tax Setting</Grid>
          <Grid container sx={{ marginTop: "16px" }}>
            <Grid item sx={{ mt: 1, mr: 1 }}>
              Tin No
            </Grid>

            <Grid item xs={2}>
              <TextField size="small"></TextField>
            </Grid>
          </Grid>
        </Card>
        <Card className="card-form" sx={{ border: "1px solid #EEE", mt: 4 }}>
          <Grid className="box-lable">Licenses</Grid>
          <Grid container sx={{ marginTop: "16px" }}>
            <Grid item xs={12} lg={6}>
              <Grid sx={{ display: "flex" }}>
                <Grid xs={4} item sx={{ mt: 1, mr: 1 }}>
                  Pin no.
                </Grid>

                <TextField size="small"></TextField>
              </Grid>
              <Grid item sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  License No :
                </Grid>

                <TextField size="small"></TextField>
              </Grid>
              <Grid item sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  Drug License No1 :
                </Grid>

                <TextField size="small"></TextField>
              </Grid>
              <Grid item sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  Drug License No2 :
                </Grid>

                <TextField size="small"></TextField>
              </Grid>
              <Grid item sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  Pest License No :
                </Grid>

                <TextField size="small"></TextField>
              </Grid>
              <Grid item sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  FSSAI No
                </Grid>

                <TextField size="small"></TextField>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  CST No
                </Grid>

                <TextField size="small"></TextField>
              </Grid>
              <Grid item sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  Lic Expiry Date :
                </Grid>
                <Grid className="adapter-day">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid item sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  DL1 Expiry Date :
                </Grid>

                <Grid className="adapter-day">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid item sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  DL2 Expiry Date :
                </Grid>

                <Grid className="adapter-day">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Card className="card-form" sx={{ border: "1px solid #EEE", mt: 4 }}>
          <Grid item className="box-lable">
            Credit Policy
          </Grid>
          <Grid item container sx={{ marginTop: "16px" }}>
            <Grid item xs={12} lg={6}>
              <Grid item sx={{ display: "flex" }}>
                <Grid xs={4} item sx={{ mt: 1, mr: 1 }}>
                  Credit Bills
                </Grid>

                <TextField size="small"></TextField>
              </Grid>
              <Grid item sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  Credit Limit
                </Grid>

                <TextField size="small"></TextField>
              </Grid>
              <Grid item sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  Credit Days
                </Grid>

                <TextField size="small"></TextField>
              </Grid>
              <Grid item sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  Cash Discount
                </Grid>

                <TextField size="small"></TextField>
              </Grid>

              <Grid item sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  CD Trigger Action :
                </Grid>
                <Grid item xs={3}>
                  <FormControl sx={{ width: 180 }}>
                    <Select
                      labelId="demo-simple-select-label"
                      className="select-box"
                    >
                      {symbol.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid sx={{ display: "flex", mt: 0 }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="female"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label={
                        <Link className="link-lable"> Alert & Allow </Link>
                      }
                    />
                    <FormControlLabel
                      value="male"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label={<Link className="link-lable"> Alert & Stop</Link>}
                    />
                    <FormControlLabel
                      value="other"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label={<Link className="link-lable"> None</Link>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid sx={{ display: "flex", mt: 2 }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="female"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label={
                        <Link className="link-lable"> Alert & Allow </Link>
                      }
                    />
                    <FormControlLabel
                      value="male"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label={<Link className="link-lable"> Alert & Stop</Link>}
                    />
                    <FormControlLabel
                      value="other"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label={<Link className="link-lable"> None</Link>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid sx={{ display: "flex", mt: 2 }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="female"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label={
                        <Link className="link-lable"> Alert & Allow </Link>
                      }
                    />
                    <FormControlLabel
                      value="male"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label={<Link className="link-lable"> Alert & Stop</Link>}
                    />
                    <FormControlLabel
                      value="other"
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 18,
                              color: "#6750A4",
                            },
                          }}
                        />
                      }
                      label={<Link className="link-lable"> None</Link>}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  Limit Amount :
                </Grid>

                <TextField size="small"></TextField>
              </Grid>
              <Grid sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  Trigger Amount :
                </Grid>

                <TextField size="small"></TextField>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Grid item sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Grid item sx={{ mr: 2 }}>
            <Button variant="outlined" onClick={handleBackClick}>
              <CancelIcon
                sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
              />{" "}
              Cancel
            </Button>
          </Grid>
          <Grid>
            <Button className="btn-colr " variant="contained" onClick={handleclick}>
              <RedoOutlinedIcon
                sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
              />
              Next
            </Button>
          </Grid>
          <Grid item sx={{ ml: 2 }}>
            <Button className="next-button" variant="outlined" onClick={handleBackClick}>
              <UndoIcon
                sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
              />
              Back
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
    )}</>
  );
};
export default CustomerEditLicense;
