import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useFetch";
//icons
import Close from "../../../../assets/images/icons/Close_icon.png";
import Editicon from "../../../../assets/images/icons/edit_icon.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  GstStateTaxFetchData,
  GstStateTaxUpdate,
} from "../../../../../src/Redux/Masters/Products/GstStateTax/action";
import {
  GstStateTaxFormValues,
  GstStateTaxFormErrors,
} from "../../../../typescript/product";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface RequestData {
  page: number;
  size: number;
}
interface EditMasterProductProps {
  onClose: () => void;
  requestData: RequestData;
}
export default function EditProductStateCode(props: EditMasterProductProps) {
  const dispatch = useAppDispatch();
  const { onClose, requestData } = props; // Destructure the props
  const [open, setOpen] = React.useState(false);
  const [isEditEnabled, setIsEditEnabled] = React.useState(true);
  const [FormError, setFormError] = useState<boolean>(false);
  const [sucessMessage, setSucessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const gstStateTaxEditData = useAppSelector(
    (state: any) => state.GstStateTaxFetchData.GststateTaxEdit
  );

  const [formData, setFormData] = useState<GstStateTaxFormValues>({
    stateName: "",
    gstCode: "",
  });

  const [errors, setErrors] = useState<GstStateTaxFormErrors>({
    stateName: "",
    gstCode: "",
  });

  const validateFormData = (
    data: GstStateTaxFormValues
  ): GstStateTaxFormErrors => {
    const errors: GstStateTaxFormErrors = {};

    const fieldsToValidate: (keyof GstStateTaxFormValues)[] = [
      "stateName",
      "gstCode",
    ];

    //   // Validate fields other than address

    fieldsToValidate.forEach((field) => {
      if (!data[field]) {
        errors[field] = `${field} is required`;
      }
    });
    return errors;
  };

  useEffect(() => {
    if (gstStateTaxEditData) {
      setFormData((prevData) => ({
        ...prevData,
        stateName: gstStateTaxEditData.stateName,
        gstCode: gstStateTaxEditData.gstCode,
      }));
    }
  }, [gstStateTaxEditData]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleEditClick = () => {
    setIsEditEnabled(false);
    // Disable edit when clicked
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newFormData: GstStateTaxFormValues = { ...formData }; // Make a copy of formData

    // If it's not part of the address object, update it normally
    newFormData = {
      ...newFormData,
      [name]: value,
    };

    setFormData(newFormData); // Update formData
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    formData: GstStateTaxFormValues
  ): Promise<void> => {
    event.preventDefault();
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await dispatch(
          GstStateTaxUpdate({ id: gstStateTaxEditData.id, formData })
        );
        const successMessage = response.message;
        setFormError(true);
        setSucessMessage(successMessage);
        dispatch(GstStateTaxFetchData(requestData));
        setTimeout(() => onClose(), 2000);
      } catch (error: any) {
        if (error.response.data.data) {
          const errorMessage = error.response.data.data;
          setErrors(errorMessage);
        } else {
          setErrorMessage("something went Wrong");
        }
      }
    } else {
      // Form is invalid, display errors
      setErrors(validationErrors);
    }
  };

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_stl"
        // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 533,
              md: 536,
              sm: 600,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "95%" }} role="presentation">
            <Grid
              container
              className="crt-header"
              mt={4}
              style={{ paddingLeft: "14px" }}
            >
              <Typography gutterBottom className="crt-header-lable">
                GST State Tax Code
              </Typography>

              <Grid item>
                <img
                  src={Editicon}
                  alt={Editicon}
                  onClick={handleEditClick}
                  //   onClick={onClose}
                  style={{ width: "23px", height: "23px", marginRight: "15px" }}
                />
                <img
                  src={Close}
                  alt={Close}
                  onClick={onClose}
                  style={{ width: "23px", height: "23px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Content */}
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
              marginBottom: "70px",
            }}
            className="create-form"
          >
            {/* Your scrollable content */}
            <form
             onSubmit={(event) => handleSubmit(event, formData)}
            >
              <Grid
                item
                sx={{ width: "95%", margin: "auto", marginBottom: "100px" }}
              >
                <Grid item>
                  <Typography className="crt-fnt-lable">State Name</Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Enter Code"
                      name="stateName"
                      disabled={isEditEnabled}
                      onChange={handleInputChange}
                      value={formData.stateName}
                      error={errors.stateName ? true : false}
                      helperText={errors.stateName && errors.stateName}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">GST Code</Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Enter Code"
                      name={"gstCode"}
                      disabled={isEditEnabled}
                      onChange={handleInputChange}
                      value={formData.gstCode}
                      error={errors.gstCode ? true : false}
                      helperText={errors.gstCode && errors.gstCode}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item mt={2} className="product_Create_footer">
                <Button className="crt-btn-cancel" onClick={onClose}>
                  {" "}
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="crt-btn-colr"
                  sx={{ mr: 4 }}
                  type="submit"
                >
                  {" "}
                  Update
                </Button>
              </Grid>
            </form>

            {/* More content... */}
            {FormError ? (
              sucessMessage ? (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="success">{sucessMessage}</Alert>
                </Snackbar>
              ) : (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="error">{errorMessage}</Alert>
                </Snackbar>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
