import React from "react";
import {
  Button,
  Grid,
  TextField,
  Card,
  Box,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link,
  Select,
  FormControl,
  Checkbox,
  ListItemText,
} from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { SelectChangeEvent } from "@mui/material/Select";
import Customerother from "./Customerother";


  interface CustomerCoverageProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
    onNext: () => void;
  }

  
  const CustomerCoverage : React.FC<CustomerCoverageProps> = ({ onBack, onNext }) => {
    const handleBackClick = (event: React.MouseEvent<unknown>) => {
      onBack(event); // Pass the event argument to onBack function
    };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        //   width: 250,
      },
    },
  };
  const handleChanges = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const classes = ["RESIDENTIAL", "COMMERCIAL", "HOUSE KEEPING", "SCHOOLS"];

  const opctions = [
    { value: "Independent", label: "Independent" },
    { value: "", label: "" },
    { value: "", label: "" },
  ];
  const Booking = [
    { value: "Order Booking", label: "Order Booking" },
    { value: "Counter Sales", label: "Counter Sales" },
    { value: "Van Sales", label: "Van Sales" },
  ];
  const calander = [
    { value: "Weekly", label: "Weekly" },
    { value: "Daily", label: "Daily" },
    { value: "Monthly", label: "Monthly" },
  ];
  const status = [
    { value: "Approved", label: "Approved" },
    { value: "", label: "" },
  ];
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
    onNext();

  };

  return (
    <>
       {show?(
      <Customerother onBack={handleBackClick} onNext={handleclick} />
      ):(
    <Box>
      <form>
        <Card className="card-form" sx={{ border: "1px solid #EEE", mt: 4 }}>
          <Grid className="box-lable">Routes & Coverage</Grid>
          <Grid container sx={{ marginTop: "16px" }}>
            <Grid item xs={12} lg={6}>
              <Grid sx={{ display: "flex" }}>
                <Grid xs={4} item sx={{ mt: 1, mr: 1 }}>
                  Coverage Mode:
                </Grid>

                <FormControl sx={{ width: 180 }}>
                  <Select labelId="demo-simple-select-label" 
                      className="select-box"
                      >
                    {Booking.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  Sales Route :
                </Grid>

                <TextField size="small"></TextField>
                <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
                  Add
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid sx={{ display: "flex" }}>
                <Grid xs={4} item sx={{ mt: 1, mr: 1 }}>
                  Coverage Mode:
                </Grid>

                <FormControl sx={{ width: 180 }}>
                  <Select labelId="demo-simple-select-label" 
                      className="select-box"
                      >
                    {calander.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  Sales Route :
                </Grid>

                <TextField size="small" ></TextField>
                <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Card className="card-form" sx={{ border: "1px solid #EEE", mt: 4 }}>
          <Grid className="box-lable retailer-lable">
            Retailer Attributes Mapping
          </Grid>
          <Grid container sx={{ marginTop: "16px" }}>
            <Grid item xs={12} lg={6}>
              <Grid sx={{ display: "flex" }}>
                <Grid xs={4} item sx={{ mt: 1, mr: 1 }}>
                  Retailer Class
                </Grid>
                <Grid xs={4}>
                  <FormControl sx={{width:"85%"}}>
                    <Select
                      id="demo-multiple-checkbox"
                      className="select-box"
                      multiple
                      value={personName}
                      onChange={handleChanges}
                      // input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {" "}
                      {classes.map((opction) => (
                        <MenuItem key={opction} value={opction}>
                          <Checkbox
                            checked={personName.indexOf(opction) > -1}
                          />
                          <ListItemText primary={opction} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  Parent/Child
                </Grid>
                <Grid xs={4}>
                  <FormControl sx={{width:"85%"}}>
                    <Select labelId="demo-simple-select-label" 
                      className="select-box"
                      >
                      {opctions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  Retailer Attribute Tree
                </Grid>

                <TextField size="small"></TextField>
              </Grid>
              <Grid item sx={{ display: "flex", mt: 2 }}>
                <Grid item xs={4} sx={{ mt: 1, mr: 1 }}>
                  Attach Parent
                </Grid>

                <TextField size="small"></TextField>
                <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Grid item mt={4}>
          <Grid item container xs={6}>
            <Grid item xs={3}>
              Approval Status
            </Grid>
            <Grid item>
              <FormControl sx={{ width: 180 }}>
                <Select labelId="demo-simple-select-label"
                      className="select-box"
                      >
                  {status.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container xs={6} mt={2}>
            <Grid item xs={3}>
              Customer Status:
            </Grid>
          
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="Active"
                    control={<Radio  sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}/>}
                    label={<Link className="link-lable">Active</Link>}
                  />
                  <FormControlLabel
                    value="In Active"
                    control={<Radio  sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }} />}
                    label={<Link className="link-lable"> In Active</Link>}
                  />
                </RadioGroup>
              </FormControl>
          </Grid>

          <Grid item></Grid>
          <Grid item>
            Key Account <Checkbox defaultChecked className="check-box" />
          </Grid>
        </Grid>
        <Card className="card-form" sx={{ border: "1px solid #EEE", mt: 4 }}>
          <Grid item className="box-lable">
            Weekly Off Day
          </Grid>
          <Grid item container sx={{ marginTop: "16px" }}>
            <Grid item sx={{ display: "flex" }}>
              <Grid item sx={{ mr: 2 }}>
                <Checkbox {...label} defaultChecked className="check-box" />
                Monday
              </Grid>
              <Grid item sx={{ mr: 2 }}>
                <Checkbox {...label} className="check-box" />
                Tuesday
              </Grid>
              <Grid item sx={{ mr: 2 }}>
                <Checkbox {...label} className="check-box" />
                Wednesday
              </Grid>
              <Grid item sx={{ mr: 2 }}>
                <Checkbox {...label} className="check-box" />
                Thursday
              </Grid>
              <Grid item sx={{ mr: 2 }}>
                <Checkbox {...label} className="check-box" />
                Friday
              </Grid>
              <Grid item sx={{ mr: 2 }}>
                <Checkbox {...label} className="check-box" />
                Saturday
              </Grid>
              <Grid item sx={{ mr: 2 }}>
                <Checkbox {...label} className="check-box" />
                Sunday
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Grid item sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Grid item sx={{ mr: 2 }}>
            <Button variant="outlined" onClick={handleBackClick}>
              <CancelIcon
                sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
              />{" "}
              Cancel
            </Button>
          </Grid>
          <Grid>
            <Button className="btn-colr " variant="contained" onClick={handleclick}>
              <RedoOutlinedIcon
                sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
              />
              Next
            </Button>
          </Grid>
          <Grid item sx={{ ml: 2 }}>
            <Button className="next-button" variant="outlined" onClick={handleBackClick}>
              <UndoIcon
                sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
              />
              Back
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
    )}
    </>
  );
};
export default CustomerCoverage;
