import { Table } from "antd";
import { Key } from "react";

interface TableProps {
  data: any[];
  columns: any[];
  // rowSelection: {
  //     selectedRowKeys: Key[];
  //     onChange: (selectedRowKeys: Key[]) => void;
  // };
  onRow?: (record: any) => any;
}

const CommonTable: React.FC<TableProps> = ({
  // rowSelection,
  data,
  columns,
  onRow,
}) => {
  return (
    <Table
      className="Header-table "
      // rowSelection={rowSelection}
      columns={columns}
      dataSource={data}
      pagination={false}
      // scroll={{ x: true }}
      showSorterTooltip={false}
      onRow={onRow}
    />
  );
};

export default CommonTable;
