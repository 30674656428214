import {
  PRODUCT_HIERARCHY_SUBMIT_FORM,
  PRODUCT_HIERARCHY_SUBMIT_ERROR,
  PRODUCT_HIERARCHY_FETCH_DATA,
  // PRODUCT_HIERARCHY_ACTIVE_STATUS ,
  PRODUCT_HIERARCHY_EDIT_DATA,
  PRODUCT_HIERARCHY_UPDATE_DATA,
  PRODUCT_HIERARCHY_UPDATE_ERROR,
} from "../../../actionType";
import { Dispatch } from "redux";
import createAxiosInstance from "../../../../hooks/useFetch";
// import { ProductHierarchyFormValues } from "../../../../typescript/product"
const axiosInstance = createAxiosInstance();

export interface ProductHierarchySubmitAction {
  type: typeof PRODUCT_HIERARCHY_SUBMIT_FORM;
  payload: any;
}

export interface ProductHierarchySubmitErrorAction {
  type: typeof PRODUCT_HIERARCHY_SUBMIT_ERROR;
  payload: string;
}
export interface ProductHierarchyGetAction {
  type: typeof PRODUCT_HIERARCHY_FETCH_DATA;
  payload: any;
}
// export interface ProductHierarchyActiveStatus{
//   type: typeof PRODUCT_HIERARCHY_ACTIVE_STATUS;
//   payload: any;
// }
export interface ProductHierarchyEdit {
  type: typeof PRODUCT_HIERARCHY_EDIT_DATA;
  payload: any;
}
export interface ProductHierarchyUpdate {
  type: typeof PRODUCT_HIERARCHY_UPDATE_DATA;
  payload: any;
}
export interface ProductHierarchyUpdateErrorAction {
  type: typeof PRODUCT_HIERARCHY_UPDATE_ERROR;
  payload: string;
}

// export const ProductHierarchySubmitForm = (formData: ProductHierarchyFormValues) => async (dispatch: Dispatch<ProductHierarchySubmitAction | ProductHierarchySubmitErrorAction>) => {

//   try {

//     const response = await axiosInstance.post(`product-Hierarchy/create`, formData);
//     dispatch({
//       type: PRODUCT_HIERARCHY_SUBMIT_FORM,
//       payload: response.data, // Assuming response contains data
//     });
//     return response.data;
//   } catch (error: any) {
//     dispatch({
//       type: PRODUCT_HIERARCHY_SUBMIT_ERROR,
//       payload: error.response.data.data,
//     });
//     throw error;
//   }
// };

export const ProductHierarchyFetchData = (requestData: {
  search: string;
  businessLevel: string;
  categoryLevel: string;
  brandLevel: string;
  brandCategory: string;
  productLevel: string;
  skuGroup: string;
  skuLevel: string;
  page: number;
  size: number;
}) => {
  return (dispatch: Dispatch<ProductHierarchyGetAction>) => {
    axiosInstance
      .get(`product-hierarchy/get`, {
        params: requestData, // Pass requestData as query parameters
      })
      .then((res) => {
        dispatch({
          type: PRODUCT_HIERARCHY_FETCH_DATA,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        // Handle error
      });
  };
};

// export const ProductHierarchyActiveStatus = ({ id, value }: { id: string; value: boolean }) => {
//   return (dispatch: Dispatch<ProductHierarchyActiveStatus>) => {
//     axiosInstance.put(`ProductHierarchy/active-in-active`,
//     { id, value }  // Pass requestData as query parameters
//     )
//       .then((res) => {
//         dispatch({
//           type: PRODUCT_HIERARCHY_ACTIVE_STATUS, // Change this to COMPANY_FETCH_DATA
//           payload: res.data.data,
//         });
//       })
//       .catch((error) => {
//       });
//   };
// };

// export const ProductHierarchyEdit = (id: string) => {
//   return (dispatch: Dispatch<ProductHierarchyEdit>) => {
//     axiosInstance.get(`product-Hierarchy/get-byid/${id}`)
//       .then((res) => {
//         dispatch({
//           type: PRODUCT_HIERARCHY_EDIT_DATA, // Dispatch the type of action
//           payload: res.data.data, // Pass any data received from the API response
//         });
//       })
//       .catch((error) => {
//         // Handle errors if any
//       });
//   };
// };

// export const ProductHierarchyUpdate = ({ id, formData }: { id: string; formData: ProductHierarchyFormValues }) => async (dispatch: Dispatch<ProductHierarchyUpdate | ProductHierarchyUpdateErrorAction>) => {
//   try {
//     const response = await axiosInstance.put(`product-Hierarchy/update/${id}`, formData);
//     dispatch({
//       type: PRODUCT_HIERARCHY_UPDATE_DATA,
//       payload: response.data // Assuming response contains data
//     });
//     return response.data;
//   } catch (error: any) {
//     dispatch({
//       type: PRODUCT_HIERARCHY_UPDATE_ERROR,
//       payload: error.response.data.data,
//     });
//     throw error;
//   }
// };
