import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, Drawer, Grid, TextField, Typography } from "@mui/material";

//icons
import Close from "../../../assets/images/icons/Close_icon.png";
import {
  supplierSubmitForm,
  supplierFetchData,
  DefaultCompanyFetch,
} from "../../../Redux/Masters/Supplier/action";
import { SupplierFormValues, FormErrors } from "../../../typescript/Supplier";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface MasterDistributorCreateProps {
  onClose: () => void;
  requestData: RequestData;
}
interface RequestData {
  page: number;
  size: number;
}
export default function MasterDistributorCreate(
  props: MasterDistributorCreateProps
) {
  const dispatch = useAppDispatch();
  const { onClose, requestData } = props; // Destructure the props

  const [textFieldValue, setTextFieldValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [FormError, setFormError] = useState<boolean>(false);
  const [sucessMessage, setSucessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [companyData, setCompanyData] = useState<{ id: string; name: string }>({
    id: "",
    name: "",
  });

  const DefaultCompany = useAppSelector(
    (state: any) => state.supplierFetch.GetDefaultCompany
  );

  const [formData, setFormData] = useState<SupplierFormValues>({
    companyId: "",
    gstStateName: "",
    supplierName: "",
    address: {
      doorNo: "",
      streetName: "",
      area: "",
    },
    code: "",
    supplierGstNo: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    geoHierarchyLevel: "",
    geoHierarchyValue: "",
    contactPerson: "",
    mobileNumber: "",
    email: "",
  });

  useEffect(() => {
    dispatch(DefaultCompanyFetch());
  }, [DefaultCompanyFetch]);

  useEffect(() => {
    if (DefaultCompany) {
      setCompanyData(DefaultCompany); // Update companyData with DefaultCompany
      setFormData((prevFormData) => ({
        ...prevFormData,
        companyId: DefaultCompany.id, // Update companyId in formData
      }));
    }
  }, [DefaultCompany]);

  const [errors, setErrors] = useState<FormErrors>({
    companyId: "",
    gstStateName: "",
    supplierName: "",
    address: {
      doorNo: "",
      streetName: "",
      area: "",
    },
    code: "",
    supplierGstNo: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    geoHierarchyLevel: "",
    geoHierarchyValue: "",
    contactPerson: "",
    mobileNumber: "",
    email: "",
  });

  const validateFormData = (data: SupplierFormValues): FormErrors => {
    const errors: FormErrors = {};

    const fieldsToValidate: (keyof SupplierFormValues)[] = [
      "companyId",
      "gstStateName",
      "supplierName",
      "address",
      "code",
      "supplierGstNo",
      "city",
      "state",
      "country",
      "postalCode",
      "geoHierarchyLevel",
      "geoHierarchyValue",
      "contactPerson",
      "mobileNumber",
      "email",
    ];

    // Validate fields other than address
    fieldsToValidate.forEach((field) => {
      if (!data[field]) {
        errors[field] = `${field} is required`;
      }
    });

    // Validate address fields
    if (!data.address) {
      errors.address = {
        doorNo: "Door number is required",
        streetName: "Street name is required",
        area: "Area is required",
      };
    } else {
      if (!data.address.doorNo) {
        errors.address = {
          ...errors.address,
          doorNo: "Door number is required",
        };
      }
      if (!data.address.streetName) {
        errors.address = {
          ...errors.address,
          streetName: "Street name is required",
        };
      }
      if (!data.address.area) {
        errors.address = { ...errors.address, area: "Area is required" };
      }
    }

    return errors;
  };

  const handleSearch = () => {
    //search function logic here
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newErrors: FormErrors = { ...errors }; // Make a copy of errors

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^\d{10}$/;

    // Check if the changed field is the email field and validate against the regex pattern
    if (name === "email" && !emailRegex.test(value)) {
      newErrors = {
        ...newErrors,
        email: "Please Enter a Valid email Address(Ex:example@domain.com)",
      };
    } else if (name === "email") {
      newErrors = { ...newErrors, email: "" }; // Clear email error if it's valid
    }

    // Check if the changed field is the mobile number field and validate against the regex pattern
    if (name === "mobileNumber" && !mobileRegex.test(value)) {
      newErrors = {
        ...newErrors,
        mobileNumber: "Mobile number must be at least 10 numbers",
      };
    } else if (name === "mobileNumber") {
      newErrors = { ...newErrors, mobileNumber: "" }; // Clear mobile number error if it's valid
    }

    if (name.startsWith("address")) {
      const addressField = name.split(".")[1]; // Extract the specific field of the address
      setFormData((prevFormData) => ({
        ...prevFormData,
        address: {
          ...prevFormData.address,
          [addressField]: value,
        },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setErrors(newErrors); // Update errors state
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    formData: SupplierFormValues
  ): Promise<void> => {
    event.preventDefault();
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const updatedFormData = { ...formData, companyId: companyData.id };
        const response = await dispatch(supplierSubmitForm(updatedFormData));
        const successMessage = response.message;
        setFormError(true);
        setSucessMessage(successMessage);
        dispatch(supplierFetchData(requestData));
        setTimeout(() => onClose(), 2000);
      } catch (error: any) {
        if (error.response.data.data) {
          const errorMessage = error.response.data.data;
          setErrors(errorMessage);
        } else {
          setErrorMessage("something went Wrong");
        }
      }
    } else {
      // Form is invalid, display errors
      setErrors(validationErrors);
    }
  };

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        // variant="persistent"
        className="drawer_stl"
      >
        <Box
          sx={{
            width: {
              lg: 1066,

              md: 1000,
              sm: 740,
              xs: 450,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "95%" }} role="presentation">
            <Grid container className="geo-header" mt={15}>
              <Typography gutterBottom className="crt-header-lable">
                Create Distributor
              </Typography>

              <Grid item>
                <img
                  src={Close}
                  alt={Close}
                  onClick={onClose}
                  style={{ width: "24px", height: "24px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Content */}
          <Box
            mt={3}
            sx={{ height: "100%", marginBottom: "70px" }}
            className="create-form"
          >
            {/* Your scrollable content */}
            <form onSubmit={(event) => handleSubmit(event, formData)}>
              <Box
                sx={{
                  marginBottom: "100px",
                  display: "flex",
                  gap: "20px",
                  padding: "0px 18px",
                }}
              >
                <Grid
                  item
                  sx={{ width: "95%", margin: "auto", }}
                >
                  <Grid item>
                    <Typography className="crt-fnt-lable">
                      Distributor Code
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Distributor Code"
                      // value={formData.distributorCode}
                      // disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Distributor Type
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Distributor Type"
                        name={"distributorType"}
                        // value={formData.distributorType}
                        onChange={handleChange}
                      // error={errors.distributorType ? true : false}
                      // helperText={errors.distributorType && errors.distributorType}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Branch Code
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Branch Code"
                        name={"branchCode"}
                        // value={formData.branchCode}
                        onChange={handleChange}
                      // error={errors.branchCode ? true : false}
                      // helperText={errors.branchCode && errors.branchCode}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Address 1</Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Address1 "
                        name={"address1"}
                        // value={formData.address1}
                        onChange={handleChange}
                      // error={errors.address1 ? true : false}
                      // helperText={errors.address1 && errors.address1}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Address 3</Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Address3"
                        name={"address3"}
                        // value={formData.address3}
                        onChange={handleChange}
                      // error={errors.address3 ? true : false}
                      // helperText={
                      //   errors.address3 && errors.address3
                      // }
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">State</Typography>
                    <Grid item xs={12}>
                      <Grid item sx={{ mt: 1 }}>
                        <TextField
                          className="Crt-txt-box"
                          placeholder="Enter State"
                          name={"address.state"}
                          // value={formData.address.state}
                          onChange={handleChange}
                        // error={
                        //   errors.address && errors.address.state
                        //     ? true
                        //     : false
                        // }
                        // helperText={
                        //   errors.address && errors.address.state
                        //     ? errors.address.state
                        //     : null
                        // }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Pin code </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Pin Code "
                        name={"pinCode"}
                        // value={formData.pinCode}
                        onChange={handleChange}
                      // error={errors.pinCode ? true : false}
                      // helperText={errors.pinCode && errors.pinCode}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Contact Person{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Contact Person "
                        name={"contactPesrson"}
                        // value={formData.contactPesrson}
                        onChange={handleChange}
                      // error={errors.contactPesrson ? true : false}
                      // helperText={errors.contactPesrson && errors.contactPesrson}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">GST NO </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="GST No  "
                        name={"gstNo"}
                        // value={formData.gstNo}
                        onChange={handleChange}
                      // error={errors.gstNo ? true : false}
                      // helperText={errors.gstNo && errors.gstNo}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">FSSAI No </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter FSSAI No "
                        name={"fssaiNo"}
                        // value={formData.fssaiNo}
                        onChange={handleChange}
                      // error={errors.fssaiNo ? true : false}
                      // helperText={errors.fssaiNo && errors.fssaiNo}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Drug Licensed No{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Drug Licensed No"
                        name={"drugLicensedNo"}
                        // value={formData.drugLicensedNo}
                        onChange={handleChange}
                      // error={errors.drugLicensedNo ? true : false}
                      // helperText={
                      //   errors.drugLicensedNo && errors.drugLicensedNo
                      // }
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Week Off </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Week Off  "
                        name={"weekOff"}
                        // value={formData.weekOff}
                        onChange={handleChange}
                      // error={errors.weekOff ? true : false}
                      // helperText={
                      //   errors.weekOff && errors.weekOff
                      // }
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      No.of Salesman(Subsidiary){" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="No.of Salesman "
                        name={"noOfSalesman"}
                        // value={formData.noOfSalesman}
                        onChange={handleChange}
                      // error={errors.noOfSalesman ? true : false}
                      // helperText={
                      //   errors.noOfSalesman && errors.noOfSalesman
                      // }
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Geofencing{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Geofencing"
                        name={"geofencing"}
                        // value={formData.geofencing}
                        onChange={handleChange}
                      // error={errors.geofencing ? true : false}
                      // helperText={errors.geofencing && errors.geofencing}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={{ width: "95%", margin: "auto" }}>
                  <Grid item>
                    <Typography className="crt-fnt-lable">
                      Distributor Name
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Distributor Name"
                      // value={formData.distributorName}
                      // disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Supplier</Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Supplier"
                        name={"supplier"}
                        // value={formData.supplier}
                        onChange={handleChange}
                      // error={errors.supplier ? true : false}
                      // helperText={errors.supplier && errors.supplier}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Branch Name
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Branch Name"
                        name={"branchName"}
                        // value={formData.branchName}
                        onChange={handleChange}
                      // error={errors.branchName ? true : false}
                      // helperText={errors.branchName && errors.branchName}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Address 2</Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Address2 "
                        name={"address2"}
                        // value={formData.address2}
                        onChange={handleChange}
                      // error={errors.address2 ? true : false}
                      // helperText={errors.address2 && errors.address2}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Country</Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Country "
                        name={"country"}
                        value={formData.country}
                        onChange={handleChange}
                        error={errors.country ? true : false}
                        helperText={
                          errors.country && errors.country
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">City</Typography>
                    <Grid item xs={12}>
                      <Grid item sx={{ mt: 1 }}>
                        <TextField
                          className="Crt-txt-box"
                          placeholder="City"
                          name={"city"}
                          value={formData.city}
                          onChange={handleChange}
                          error={
                            errors.city
                              ? true
                              : false
                          }
                          helperText={
                            errors.city
                              ? errors.city
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Phone No </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Phone No "
                        name={"phoneNo"}
                        // value={formData.phoneNo}
                        onChange={handleChange}
                        // error={errors.phoneNo ? true : false}
                        // helperText={errors.phoneNo && errors.phoneNo}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Email Id </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Email Id "
                        name={"emailId"}
                        // value={formData.emailId}
                        onChange={handleChange}
                        // error={errors.emailId ? true : false}
                        // helperText={errors.emailId && errors.emailId}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      GST Valid Up To{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder=" GST Valid "
                        name={" GSTValid"}
                        // value={formData.GSTValid}
                        onChange={handleChange}
                        // error={errors.GSTValid ? true : false}
                        // helperText={errors.GSTValid && errors.GSTValid}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      FSSAI Valid Up To{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder=" FSSAI Valid "
                        name={"fssaiValid"}
                        // value={formData.fssaiValid}
                        onChange={handleChange}
                        // error={errors.fssaiValid ? true : false}
                        // helperText={errors.fssaiValid && errors.fssaiValid}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Drug Licenced Valid Upto{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Drug Licenced Valid"
                        name={"drugLicencedValid"}
                        // value={formData.drugLicencedValid}
                        onChange={handleChange}
                        // error={errors.drugLicencedValid ? true : false}
                        // helperText={
                        //   errors.drugLicencedValid && errors.drugLicencedValid
                        // }
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Distributor Visibility{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Distributor Visibility "
                        name={"distributorVisibility"}
                        // value={formData.distributorVisibility}
                        onChange={handleChange}
                        // error={errors.distributorVisibility ? true : false}
                        // helperText={
                        //   errors.distributorVisibility && errors.distributorVisibility
                        // }
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Salesman Salary Budget PM{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Salesman Salary Budget PM"
                        name={"salesmanSalaryBudget"}
                        // value={formData.salesmanSalaryBudget}
                        onChange={handleChange}
                        // error={errors.salesmanSalaryBudget ? true : false}
                        // helperText={
                        //   errors.salesmanSalaryBudget && errors.salesmanSalaryBudget
                        // }
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Geo Hierarchy{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Geo Hierarchy"
                        name={"geoHierarchy"}
                        // value={formData.geoHierarchy}
                        onChange={handleChange}
                        // error={errors.geoHierarchy ? true : false}
                        // helperText={errors.geoHierarchy && errors.geoHierarchy}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Grid item mt={2} className="geo_Create-footer">
                <Button className="dis-btn-cancel" onClick={onClose}>
                  {" "}
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="dis-btn-colr"
                  sx={{ mr: 4 }}
                  type="submit"
                >
                  {" "}
                  Create
                </Button>
              </Grid>
            </form>

            {/* More content... */}
            {FormError ? (
              sucessMessage ? (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="success">{sucessMessage}</Alert>
                </Snackbar>
              ) : (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="error">{errorMessage}</Alert>
                </Snackbar>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
