import React, { useRef } from "react";
import {
  Button,
  Grid,
  TextField,
  Box,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  InputLabel,
  Typography,
  Card,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CommonTable from "../../../../components/Tables/CommonTable";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { TableColumnsType } from "antd";
import { Input } from "antd";
import { Table } from "antd";
import TableSearchDataTable from "../../../../components/Modal/TableSearchModal";
import * as XLSX from "xlsx";
import ProductRuleSettingView from "./ProductRuleSettingView";


const { Search } = Input;
interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}
interface DataType {
  key: React.Key;
  DistributorType: string;
  DistributorCode: string;
  DistributorName: string;
  Budget: string;
  Unlimited: string;
}

const BudgetplanningView: React.FC<DistributorTabProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        //   width: 250,
      },
    },
  };
  const handleChanges = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "DistributorType",
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Typography>Distributor Type</Typography>
        </Grid>
      ),

      // sorter: (a, b) => a.Distributor.length - b.Distributor.length,
    },
    {
      dataIndex: "DistributorCode",
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Typography>Distributor Code</Typography>
        </Grid>
      ),

      // sorter: (a, b) => a.Distributor.length - b.Distributor.length,
    },
    {
      dataIndex: "DistributorName",
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Typography>Distributor Name</Typography>
        </Grid>
      ),
      // sorter: (a, b) => a.Distributor.length - b.Distributor.length,
    },
    {
      dataIndex: "Budget",
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Typography>Budget</Typography>
        </Grid>
      ),
    },
    Table.SELECTION_COLUMN,
    {
      dataIndex: "Unlimited",
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Typography mt={1}> Unlimited</Typography>
        </Grid>
      ),
      // sorter: (a, b) => a.Distributor.length - b.Distributor.length,
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      DistributorType: "3023873919",
      DistributorCode: "	RS Family Mart Muradnagar",
      DistributorName: "MT0600032001770003200177",
      Budget: "MT0600032001770003200177",
      Unlimited: "",
    },
  ];

  //model table

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      {show ? (
                <ProductRuleSettingView onBack={handleclick} />

      ) : (

        <Box>
          <form>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">Allocated Budget</Typography>
                <TextField variant="outlined" className="Txt-box" />
              </Grid>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 3, sm: 3 }}
                mt={3}
              >
                <Grid item xs={12}>
                  <CommonTable
                    // rowSelection={rowSelection}
                    data={data}
                    columns={columns}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              mt={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item sx={{ ml: 2 }}>
                <Button variant="outlined" onClick={handleBackClick} 
                  className="button-lable"
                  >
                  Back
                </Button>
              </Grid>
              <Grid item sx={{ ml: 2 }}>
                <Button
                  className="btn-colr "
                  variant="contained"
                  onClick={handleclick}
                >
                  <RedoOutlinedIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />
                  Next
                </Button>
              </Grid>
              <Grid item sx={{ ml: 2 }}>
                <Button
                  variant="outlined"
                  onClick={handleBackClick}
                  className="button-lable"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item sx={{ ml: 2 }}>
                <Button
                  variant="outlined"
                  sx={{ color: "#fff" }}
                  className="btn-colr "
                >
                  Skip Budget
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </>
  );
};
export default BudgetplanningView;
