import React, { useRef } from "react";

import { Box, Button, Grid, Paper, Typography } from "@mui/material";

import type { TableColumnsType } from 'antd';
import SearchBar from '@mkyy/mui-search-bar';
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../../components/Modal/DeleteSupplier";
import AddIcon from "@mui/icons-material/Add";
import SalesMancreate from "./Createsalesman";
import EditsalesMan from "./EditSalesMan";
import ViewsalesMan from "./ViewSalesMan";
import CommonTable from "../../../components/Tables/CommonTable";
// import SalesmanView from "./DistributorView/DistributorView";
// import DistributorEdit from "./DistributorEdit/DistributorEdit";


interface DataType {
  key: React.Key;
  code: string;
  SalesmanCode: string;
  SalesmanName: string;
  PhoneNo: string;
  IsActive: string;
  Status: string;
  action: string;
}

const SalesMan: React.FC = () => {

  const [isViewing, setIsViewing] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);

  const [textFieldValue, setTextFieldValue] = React.useState('');

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    //search function logic here
  };



  const handleCreateNew = () => {
    setShowForm(!showForm);
  };

  const handleEditClick = (event: React.MouseEvent<unknown>) => {
    setIsEditing(!isEditing);
  };

  const handleViewClick = (event: React.MouseEvent<unknown>) => {
    setIsViewing(!isViewing);
  };
  const handleDeleteClick = (event: React.MouseEvent<unknown>) => {
    setIsDelete(!isDelete);
  };

  const columns: TableColumnsType<DataType> = [

    {
      dataIndex: "code",
      title: "Distributor Code",
      sorter: (a, b) => a.code.length - b.code.length,
    },
    {
      dataIndex: "SalesmanCode",
      title: "Salesman Code",
      sorter: (a, b) => a.SalesmanCode.length - b.SalesmanCode.length,
    },
    {
      dataIndex: "SalesmanName",
      title: "Salesman Name",
      sorter: (a, b) => a.SalesmanName.length - b.SalesmanName.length,
    },
    {
      dataIndex: "PhoneNo",
      title: "Phone No.",
      sorter: (a, b) => a.PhoneNo.length - b.PhoneNo.length,
    },
    {
      dataIndex: "IsActive",
      title: "Is Active",
      sorter: (a, b) => a.IsActive.length - b.IsActive.length,
    },
    {
      dataIndex: "Status",
      title: "Status",
      sorter: (a, b) => a.Status.length - b.Status.length,
    },

    {
      dataIndex: "action",
      title: "Action",
      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            onClick={(event) => handleViewClick(event)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
          <EditIcon
            onClick={(event) => handleEditClick(event)}
            style={{
              cursor: "pointer",
              marginLeft: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />

        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      code: "1101090331",
      SalesmanCode: "110109035-SLM001",
      SalesmanName: "NAVEEN sHARMA",
      PhoneNo: "1899479193",
      IsActive: "N",
      Status: "Approvedroved",
      action: "",
    },
    {
      key: 2,
      code: "1101090332",
      SalesmanCode: "110109035-SLM002",
      SalesmanName: "Parween Gang",
      PhoneNo: "8882334773",
      IsActive: "Y",
      Status: "Approved",
      action: "",
    },
    {
      key: 3,
      code: "1101090333",
      SalesmanCode: "110109035-SLM003",
      SalesmanName: "1101090341",
      PhoneNo: "1011176121",
      IsActive: "Y",
      Status: "Approved",
      action: "",
    },
    {
      key: 4,
      code: "1101090334",
      SalesmanCode: "110109035-SLM004",
      SalesmanName: "RITIK PAL",
      PhoneNo: "1011176121",
      IsActive: "N",
      Status: "Approved",
      action: "",
    },
    {
      key: 5,
      code: "1101090335",
      SalesmanCode: "	110109035-SLM005",

      SalesmanName: "DANI SAHNI",
      PhoneNo: "5650864750",
      IsActive: "Y",
      Status: "Approved",
      action: "",
    },
    {
      key: 6,
      code: "1101090336",
      SalesmanCode: "	110109035-SLM006",
      SalesmanName: "DANI SAHNI",
      PhoneNo: "5650864750 ",
      IsActive: "N",
      Status: "Approved",
      action: "",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
      <Grid item xs={12} className="pad-10 mar-tb">
        <Typography>
          <b>Sales Man</b>
        </Typography>
      </Grid>

      {showForm ? (
        <SalesMancreate onBack={handleCreateNew} />
      ) : isEditing ? (
        <EditsalesMan onBack={(event) => handleEditClick(event)} />
      ) : isViewing ? (
        <ViewsalesMan onBack={(event) => handleViewClick(event)} />
      ) : (
        <Box>
          <Button
            variant="contained"
            className="btn-colr"
            onClick={handleCreateNew}
          >
            {" "}
            <AddIcon /> Create new
          </Button>
          <Grid item sx={{ mt: 2 }}>
            <Box>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "end", mb: 2 }}
              >
                <SearchBar
                  className="search-table"
                  value={textFieldValue}
                  onChange={(newValue) => {
                    setTextFieldValue(newValue);
                    handleSearch();
                  }}
                />
              </Grid>
              <CommonTable
                // rowSelection={rowSelection}
                data={data}
                columns={columns}
              />

              {isDelete && (
                <DeleteModal onBack={(event) => handleDeleteClick(event)} />
              )}
            </Box>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
export default SalesMan;
