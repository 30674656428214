import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CustomerEditCoverage from './CustomerEditCoverage';
import CustomerEditOther from './CustomerEditOther';
import CustomerEditGeneral from './CustomerEditGeneral';
import CustomerEditLicense from './CustomerEditLicense';
interface ViewSupplierFormProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const CustomerTableEdit: React.FC<ViewSupplierFormProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };

  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', height: '40px', backgroundColor: '#8080801f' }}>
      <TabContext value={value}  >
        <Box sx={{ borderBottom: 1, borderColor: 'divider', }} >
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Customer-General" value="1" className='customer-general' />
            <Tab label="License & Setting" value="2" />
            <Tab label="Coverage & Attributes" value="3" />
            <Tab label="Other Attributes" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" ><CustomerEditGeneral   onBack={handleBackClick}/> </TabPanel>
        <TabPanel value="2"> <CustomerEditLicense onBack={handleBackClick} /></TabPanel>
        <TabPanel value="3"><CustomerEditCoverage onBack={handleBackClick} /> </TabPanel>
        <TabPanel value="4"><CustomerEditOther  onBack={handleBackClick} /> </TabPanel>
      </TabContext>
    </Box>
  );
}
export default CustomerTableEdit;