import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, Drawer, Grid, TextField, Typography } from "@mui/material";

//icons

import Close from "../../../../assets/images/icons/Close_icon.png";
// import { useAppDispatch } from "../../../hooks/useFetch";

import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

interface GeberalInfoEditProps {
  onClose: () => void; // Define the onClose prop
  //   requestData: RequestData;
}
interface RequestData {
  page: number;
  size: number;
}

export default function GeberalInfoEdit(props: GeberalInfoEditProps) {
  const { onClose } = props; // Destructure the props

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Drawer
        anchor="right"
        open={true}
        className="distributor_drawer"
        // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 1066,

              md: 1000,
              sm: 740,
              xs: 450,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "95%" }} role="presentation">
            <Grid container className="geo-header" mt={15}>
              <Typography gutterBottom className="crt-header-lable">
                Distributor
              </Typography>

              <Grid item>
                <img
                  src={Close}
                  alt={Close}
                  onClick={onClose}
                  style={{ width: "17px", height: "17px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Content */}
          <Box mt={3} sx={{ height: "100%" }} className="create-form">
            {/* Your scrollable content */}
            <form>
              <Box
                sx={{
                  marginBottom: "130px",
                  display: "flex",
                  gap: "20px",
                  padding: "0px 18px",
                }}
              >
                <Grid item sx={{ width: "95%", margin: "auto" }}>
                  <Grid item>
                    <Typography className="crt-fnt-lable">
                      Distributor Code
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Company Name"
                        // value={companyData.name}
                        // disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Distributor Type
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Select State"
                        name={"gstStateName"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Branch Code
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Name"
                        name={"supplierName"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Address 1</Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Code "
                        name={"code"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Address 3</Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Number"
                        name={"supplierGstNo"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">State</Typography>
                    <Grid item xs={12}>
                      <Grid item sx={{ mt: 1 }}>
                        <TextField
                          className="Crt-txt-box"
                          placeholder="Door No  "
                          name={"address.doorNo"}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Pin code </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Select City  "
                        name={"city"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Contact Person{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Select State  "
                        name={"state"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">GST NO </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Country  "
                        name={"country"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">FSSAI No </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Zip Code  "
                        name={"postalCode"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Drug Licensed No{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Mention Level  "
                        name={"geoHierarchyLevel"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Week Off </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter value  "
                        name={"geoHierarchyValue"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      No.of Salesman(Subsidiary){" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Name  "
                        name={"contactPerson"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Geofencing{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Number  "
                        name={"mobileNumber"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={{ width: "95%", margin: "auto" }}>
                  <Grid item>
                    <Typography className="crt-fnt-lable">
                      Distributor Name
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Company Name"
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Supplier</Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Select State"
                        name={"gstStateName"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Branch Name
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Name"
                        name={"supplierName"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Address 2</Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Code "
                        name={"code"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Country</Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Number"
                        name={"supplierGstNo"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">City</Typography>
                    <Grid item xs={12}>
                      <Grid item sx={{ mt: 1 }}>
                        <TextField
                          className="Crt-txt-box"
                          placeholder="Door No  "
                          name={"address.doorNo"}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Phone No </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Select City  "
                        name={"city"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Email Id </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Select State  "
                        name={"state"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      GST Valid Up To{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Country  "
                        name={"country"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      FSSAI Valid Up To{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Zip Code  "
                        name={"postalCode"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Drug Licenced Valid Upto{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Mention Level  "
                        name={"geoHierarchyLevel"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Distributor Visibility{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter value  "
                        name={"geoHierarchyValue"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Salesman Salary Budget PM{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Name  "
                        name={"contactPerson"}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Geo Hierarchy{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Number  "
                        name={"mobileNumber"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Grid item mt={2} className="distributor_footer">
                <Button className="dis-btn-cancel" onClick={onClose}>
                  {" "}
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="dis-btn-colr"
                  sx={{ mr: 4 }}
                  type="submit"
                >
                  {" "}
                  Update
                </Button>
              </Grid>
            </form>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
