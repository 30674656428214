import {
  PRODUCT_HIERARCHY_SUBMIT_ERROR,
  PRODUCT_HIERARCHY_FETCH_DATA,
} from '../../../actionType'
interface State {
  ProductHierarchyEdit: [];
  ProductHierarchyUpdate: [];
  ProductHierarchyToast: [];
  ProductHierarchyUpdateToast: [];
  loading: boolean;
  error: string | null;
}


const initialState: State = {
  ProductHierarchyEdit: [],
  ProductHierarchyUpdate: [],
  ProductHierarchyToast: [],
  ProductHierarchyUpdateToast: [],
  loading: false,
  error: null,
};


const ProductHierarchyReducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case PRODUCT_HIERARCHY_FETCH_DATA:
      return {
        ...state,
        ProductHierarchyFetch: action.payload,
      };

    case PRODUCT_HIERARCHY_SUBMIT_ERROR:
      return {
        ...state,
        ProductHierarchyToast: action.payload,
      };

    default:
      return state;
  }
};

export default ProductHierarchyReducer;

