import * as React from "react";

import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Distributorpopup from "./Distributorpopup";
import Distributorbranchmodal from "../../../components/Modal/Distributorbranchmodal";
import Close from "@mui/icons-material/Close";

const Mustvisitoutlit: React.FC = () => {
  const years = [
    { value: "2023", label: "2023" },
    { value: "2022", label: "2022" },
    { value: "2021", label: "2021" },
    { value: "2020", label: "2020" },
    { value: "2019", label: "2019" },
    { value: "2018", label: "2018" },
  ];
  const Months = [
    { value: "MOC01", label: "MOC01" },
    { value: "MOC03", label: "MOC03" },
    { value: "MOC04", label: "MOC04" },
    { value: "MOC05", label: "MOC05" },
    { value: "MOC06", label: "MOC06" },
    { value: "MOC07", label: "MOC07" },
  ];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [downloadopen, setDownloadOpen] = React.useState(false);
  const handledownloadOpen = () => setDownloadOpen(true);
  const handledownloadClose = () => setDownloadOpen(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const model = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "25%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };


  return (
    <Grid item sx={{ p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 }}}>
    <Box>

      <Grid className="mar-tb">
        <Typography >
          <b> Must Visit Outlit</b>
        </Typography>
      </Grid>

      <form>
        <Card className="card-form" sx={{ mt: 4 }}>
          <Grid item container>
            <Grid item xs={4}>
              <FormControl className="formcontrol-select">
                <Grid item>Year</Grid>
                <Select labelId="demo-simple-select-label">
                  {years.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl className="formcontrol-select">
                <Grid item>Month</Grid>
                <Select labelId="demo-simple-select-label">
                  {Months.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container mt={3}>
            <Grid item xs={4}>
              <Grid item>Distributor </Grid>
              <TextField variant="outlined" />
              <Button
                sx={{ ml: 1, color: "#fff" }}
                className="btn-colr"
                onClick={handleOpen}
              >
                ....
              </Button>

              <Grid item>
                <Modal
                  open={open}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Distributorpopup handleClose={handleClose} />
                  </Box>
                </Modal>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid item>Distributor Branch</Grid>
              <TextField variant="outlined" />
              <Button
                sx={{ ml: 1, color: "#fff" }}
                className="btn-colr"
                onClick={handleOpen}
              >
                ....
              </Button>
              <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Distributorbranchmodal handleClose={handleClose} />
                </Box>
              </Modal>
            </Grid>
          </Grid>
          <Grid item container mt={3}>
            <Grid item>
              <Button
                sx={{ ml: 1, color: "#fff" }}
                className="btn-colr btn-hover"
                onClick={handledownloadOpen}

              >
                <ArrowDownwardIcon
                  className="downward-icon"
                />
                Generate
              </Button>
              <Modal

                open={downloadopen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={model}>
                  <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item
                    >
                      Must Visit Outlit
                    </Grid >
                    <IconButton
                      aria-label="close"

                      onClick={handledownloadClose}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                  <Divider />
                  <Grid item sx={{ display: 'flex', justifyContent: 'center' }} mt={3}>
                    <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
                      Download
                    </Button>
                  </Grid>
                </Box>
              </Modal>
            </Grid>
            <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
              Download Format
            </Button>
          </Grid>
        </Card>
      </form>
    </Box>
    </Grid>
  );
};
export default Mustvisitoutlit;
