import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  ReactNode,
} from "react";
import { useCookies } from "react-cookie";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import createAxiosInstance from "../hooks/useFetch";
import { jwtDecode } from "jwt-decode";
import { PERMISSIONS, ROLE_LIST } from "../utils/constant";
import { AES, enc } from "crypto-js";
import Baseurl from "../hooks/useLocalStorage";
type User = {
  username: string;
  permissions: string[];
};

type FormData = {
  userId: string;
  password: string;
};

type AuthContextType = {
  user: User;
  cookies: { [key: string]: string };
  login: (formData: FormData) => Promise<AxiosResponse<any>>;
  logout: () => Promise<void>;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [user, setUser] = useState<User>({
    username: "",
    permissions: [],
  });
  const createLoginAxiosInstance = (): AxiosInstance => {
    return axios.create({
      baseURL: Baseurl,
    });
  };

  const axiosInstance = createAxiosInstance();
  const axiosInstanceLogin = createLoginAxiosInstance();

  const login = async (formData: FormData): Promise<AxiosResponse<any>> => {
    try {
      const response = await axiosInstanceLogin.post(
        Baseurl + "auth/xdm-signin",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const token = response.data.data.Token;
        if (token) {
          interface JwtPayload {
            sub?: string;
            role?: string;
            empId?: string;
            CompanyId?: string;
            Name?: string;
            Email?: string;
            id?: string;
            iat?: number;
            exp?: number;
          }
          const decoded = jwtDecode<JwtPayload>(token);
          const role = decoded.role ?? "";
          const username = decoded.Name ?? "";
          localStorage.setItem("token", token);
          setCookie("token", token);

          let permissions = [];
          switch (role) {
            case ROLE_LIST.ADMIN:
              permissions = [PERMISSIONS.CAN_VIEW_ADMIN];
              break;
            case ROLE_LIST.DISTRIBUTOR:
              permissions = [PERMISSIONS.CAN_VIEW_DISTRIBUTOR];
              break;
            case ROLE_LIST.SALES:
              permissions = [PERMISSIONS.CAN_VIEW_SALES];
              break;
            case ROLE_LIST.DELIVERY_BOY:
              permissions = [PERMISSIONS.CAN_VIEW_DELIVERY_BOY];
              break;
            default:
              permissions = [PERMISSIONS.CAN_VIEW_ADMIN];
          }

          const encryptedPermissions = encryptData(permissions);

          setUser({
            username: username,
            permissions: permissions,
          });

          setCookie("permissions", encryptedPermissions);

          setCookie("permissions", encryptedPermissions);
        } else {
          throw new Error("Token is empty");
        }
        return response;
      } else {
        console.error("Error during login:");
        throw new Error("Login failed");
      }
    } catch (error) {
      console.error("Error during login:", error);
      throw error;
    }
  };

  const logout = async (): Promise<void> => {
    try {
      const response = await axiosInstance.post(
        Baseurl + "basic-auth/xdm-signout",
        {},
        {
          headers: {},
        }
      );
      if (response.status === 200) {
        window.localStorage.clear();
        localStorage.removeItem("token");
        ["token", "permissions"].forEach((obj) => removeCookie(obj));
        removeCookie("token");
        removeCookie("permissions");
      }
    } catch (error) {
      console.error("Error during logout:", error);
      throw error;
    }
  };

  const value = useMemo(
    () => ({
      user,
      cookies,
      login,
      logout,
    }),
    [cookies, user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

function encryptData(data: string | string[]): string | string[] {
  if (Array.isArray(data)) {
    return data.map((item) =>
      AES.encrypt(item, "yourEncryptionKey").toString()
    );
  } else {
    return AES.encrypt(data, "yourEncryptionKey").toString();
  }
}

// Function to decrypt data
function decryptData(encryptedData: string | string[]): string | string[] {
  if (Array.isArray(encryptedData)) {
    return encryptedData.map((item) =>
      AES.decrypt(item, "yourEncryptionKey").toString(enc.Utf8)
    );
  } else {
    return AES.decrypt(encryptedData, "yourEncryptionKey").toString(enc.Utf8);
  }
}
