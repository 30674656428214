import {
  Box,
  Card,
  Grid,
  Stack,
  Divider,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";

import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import MiniDrawerStyled from "../../../Layout/MainLayout/Drawer/MiniDrawerStyled";
import DrawerHeader from "../../../Layout/MainLayout/Drawer/DrawerHeader";
import InvoiceAndGrnSidebar from "./InvoiceGrnSidebar";
import PendingGRN from "./Pending Order/PendingGRN";
import GrnHistorySidebar from "./GrnHistorySidebar";
import GrnHistory from "./GrnHistory";

const InvoiceAndGrn: React.FC = () => {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };



  return (
    <Grid item>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        bgcolor="#eee"
      >
        <Box flex={1} p={0}>
          <MiniDrawerStyled variant="permanent" open={true} theme={theme}>
            <DrawerHeader />
            {tabIndex === 0 && <InvoiceAndGrnSidebar />}
            {tabIndex === 1 && <GrnHistorySidebar />}
          </MiniDrawerStyled>
        </Box>
        <Box
          flex={8}
          className="margin-left-0px"
          sx={{ overflowY: "auto", height: "100vh", marginBottom: "100px" }}
        >
          <Card
            className="b-radius-0 main-card"
            style={{ marginTop: "2px", boxShadow: "none" }}
          >
            <Grid container spacing={3} justifyContent="start" style={{ padding: "10px 16px" }} >
              <Grid item xs={6} sm={6} md={9} lg={9} className="margin_tp_0">
                <Typography sx={{ fontSize: "13px", fontWeight: 800, fontFamily: "Montserrat", color: '#000000' }}>Goods Receipt Note</Typography>

              </Grid>
            </Grid>
            <Divider />
            <Grid container sx={{ paddingLeft: " 20px", }}>
              <Grid item sm={6}>
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  // TabIndicatorProps={{
                  //   style: {
                  //     background:
                  //       "linear-gradient(90.19deg, #614FB3 0%, #944986 100%)",
                  //   },
                  // }}
                  className="tab_box"
                  sx={{
                    "& .MuiTab-root": {
                      // background:
                      //   "linear-gradient(90.19deg, #614FB3 0%, #944986 100%)",
                      color: "#B9BABD",
                      "&.Mui-selected": {
                        color: "#944986",
                      },
                    },
                  }}
                >
                  <Tab label="Pending GRN" />
                  <Tab label="GRN History" />
                </Tabs>
              </Grid>
            </Grid>

            <TabPanel value={tabIndex} index={0}>
              <PendingGRN />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <GrnHistory />
            </TabPanel>
          </Card>
        </Box>
      </Stack>

    </Grid>
  );
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

export default InvoiceAndGrn;
