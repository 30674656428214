import axios, { AxiosInstance } from "axios";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import Baseurl from "./useLocalStorage"; // Assuming Baseurl is exported from "./useLocalStorage"
import type { RootState, AppDispatch } from "../store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const createAxiosInstance = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: Baseurl,
  });

  // Add a request interceptor
  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

export default createAxiosInstance;
