import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import UndoIcon from "@mui/icons-material/Undo";
import DeleteIcon from "@mui/icons-material/Delete";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import DistributorGST from "../DistributorGST";

  interface DistributorTabProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
  }
  
  const ViewDistributorSales: React.FC<DistributorTabProps> = ({onBack}) => {
 
  
    const handleBackClick = (event: React.MouseEvent<unknown>) => {
      onBack(event); // Pass the event argument to onBack function
    };
  const options = ["AALO", "ABOHAR", "ABUROAD", "ADDANKI", "ADILABAD"];
  const LOB = ["Select "];

  const rows = [
    {
      id: "CKL",
      SalesHierarchy: "	AB - TSO - NORTH - RURAL",
      action: "",
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "LOB Name",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "SalesHierarchy",
      headerName: "Sales Hierarchy Value Name",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },

    {
      field: "action",
      headerName: "Action",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: () => (
        <>
          <DeleteIcon
            style={{
              cursor: "pointer",
              marginRight: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />
        </>
      ),
    },
  ];

  const [isVisible, setIsVisible] = useState(false);
  const Visibility = () => {
    setIsVisible(!isVisible);
  };
  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };
  
  return (
    <>
       {show ? (
  <DistributorGST onBack={handleclick}/>
      ) : (
    <Box>
      <form>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 10 }}>
          <Grid item xs={12} sm={6}>
            <Typography>Geo Hierarchy Level</Typography>
            <TextField placeholder="" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item>Geo Hierarchy Value</Grid>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label">
                {options.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>Sales Hierarchy Level</Typography>
            <TextField placeholder="" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item>LOB</Grid>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label">
                {LOB.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container mt={2} rowSpacing={3} columnSpacing={{ xs: 3, sm: 10 }}>
          <Grid item xs={12} sm={6}>
            <Grid item>LOB</Grid>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label">
                {LOB.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item mt={3} ml={-8}>
            <Button
              sx={{ color: "#fff" }}
              className="btn-colr"
              onClick={Visibility}
            >
              Add
            </Button>
          </Grid>
        </Grid>

        {isVisible && (
          <Grid item mt={2}>
            <Grid container columnGap={2}>
              <Grid item style={{ height: "100%", width: "100%" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  className="Header-table"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item mt={3} sx={{ display: "flex", justifyContent: "center" }}>
          <Grid item mr={2}>
            <Button className="btn-colr " variant="contained" onClick={handleclick}>
              <RedoOutlinedIcon
                sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
              />
              Next
            </Button>
          </Grid>
          <Button className="next-button" variant="outlined" onClick={handleBackClick}> 
            <UndoIcon
              sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
            />
            Cancle
          </Button>
        </Grid>
      </form>
    </Box>
      )}
    </>
  );
};
export default ViewDistributorSales;
