import * as React from "react";
// material-ui
import { Grid, Typography } from "@mui/material";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  FormControl,
  Box,
  InputLabel,
  Button,
  Card,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReasonView from "./ReasonView";
import ReasonForm from "./ReasonForm";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  ModuleName: string;
  ScreenName: string;
  Code: string;
  ReasonName: string;
  action: string;
}

const Reason: React.FC = () => {
  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // search function logic
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "ModuleName",
      title: "Module Name",
      sorter: (a, b) => a.ModuleName.length - b.ModuleName.length,
    },
    {
      dataIndex: "ScreenName",
      title: "Screen Name",
      sorter: (a, b) => a.ScreenName.length - b.ScreenName.length,
    },
    {
      dataIndex: "Code",
      title: "Code",
      sorter: (a, b) => a.ReasonName.length - b.ReasonName.length,
    },
    {
      dataIndex: "ReasonName",
      title: "Reason Name",
      sorter: (a, b) => a.ReasonName.length - b.ReasonName.length,
    },

    {
      dataIndex: "action",
      title: "Action",
      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            onClick={(event) => handleViewClick(event)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
        </>
      ),
    },
  ];
  const data: DataType[] = [
    {
      key: 1,
      ModuleName: "55",
      ScreenName: "ScreenName1",
      Code: "Code1",
      ReasonName: "ReasonName1",
      action: "",
    },
    {
      key: 2,
      ModuleName: "55445",
      ScreenName: "ScreenName2",
      Code: "Code2",
      ReasonName: "ReasonName2",
      action: "",
    },
    {
      key: 3,
      ModuleName: "5565",
      ScreenName: "ScreenName3",
      Code: "Code3",
      ReasonName: "ReasonName3",
      action: "",
    },
    {
      key: 4,
      ModuleName: "5548",
      ScreenName: "ScreenName4",
      Code: "Code4",
      ReasonName: "ReasonName4",
      action: "",
    },
    {
      key: 5,
      ModuleName: "55121",
      ScreenName: "ScreenName5",
      Code: "Code5",
      ReasonName: "ReasonName5",
      action: "",
    },
    {
      key: 6,
      ModuleName: "5565",
      ScreenName: "ScreenName6",
      Code: "Code6",
      ReasonName: "ReasonName6",
      action: "",
    },
    {
      key: 7,
      ModuleName: "55789",
      ScreenName: "ScreenName7",
      Code: "Code7",
      ReasonName: "ReasonName7",
      action: "",
    },
    {
      key: 8,
      ModuleName: "55656",
      ScreenName: "ScreenName8",
      Code: "Code8",
      ReasonName: "ReasonName8",
      action: "",
    },
  ];
  const navigator = useNavigate();
  const [showForm, setShowForm] = React.useState(false);
  const [isViewing, setIsViewing] = React.useState(false);

  const handleCreateNew = () => {
    setShowForm(!showForm);
  };

  const handleViewClick = (event: React.MouseEvent<unknown>) => {
    setIsViewing(!isViewing);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Paper elevation={0} className="pad-10 mar-tb">
          <Grid item xs={12}>
            <Typography className="cursor_pointer">
              <span onClick={() => navigator("/Configuration")}>
                Configuration
              </span>{" "}
              &nbsp;/
              <span>
                <b>&nbsp;Reason</b>
              </span>
            </Typography>
          </Grid>
        </Paper>

        {showForm ? (
          <ReasonForm onBack={handleCreateNew} />
        ) : isViewing ? (
          <ReasonView onBack={(event) => handleViewClick(event)} />
        ) : (
          <Box>
            <Button
              variant="contained"
              className="btn-apply"
              onClick={handleCreateNew}
            >
              Create
            </Button>
            <Grid item sx={{ mt: 2 }}>
              <Box>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "end", mb: 2 }}
                >
                  <SearchBar
                    className="search-table"
                    value={textFieldValue}
                    onChange={(newValue) => {
                      setTextFieldValue(newValue);
                      handleSearch();
                    }}
                  />
                </Grid>
                <CommonTable
                  // rowSelection={rowSelection}
                  data={data}
                  columns={columns}
                />
              </Box>
            </Grid>
          </Box>
        )}
      </Box>
    </>
  );
};
export default Reason;
