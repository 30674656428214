import * as React from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { FormControl, InputLabel, Button, Card, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import TCS_EditForm from "./TCSEditForm";
import TCS_ViewForm from "./TCSViewForm";
import SearchIcon from "@mui/icons-material/Search";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  code: number;
  partiesWithName: string;
  partiesWithoutName: string;
  partiesNot: string;
  taxFiled: string;
  taxNotFiled: string;
  tdsclaculation: string;
  action: string;
}

const TCS_Configuration: React.FC = () => {
  const navigator = useNavigate();

  const [isEditing, setIsEditing] = React.useState(false);
  const [isViewing, setIsViewing] = React.useState(false);

  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // search function logic here
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "code",
      title: "Cmp Code",
    },
    {
      dataIndex: "partiesWithName",
      title: "Parties with Pan/Aadhar",
      sorter: (a, b) => a.partiesWithName.length - b.partiesWithName.length,
    },
    {
      dataIndex: "partiesWithoutName",
      title: "Parties without Pan/Aadhar",
      sorter: (a, b) =>
        a.partiesWithoutName.length - b.partiesWithoutName.length,
    },
    {
      dataIndex: "partiesNot",
      title: "Parties with Pan/Aadhar and Income Tax not Filed",
      sorter: (a, b) => a.partiesNot.length - b.partiesNot.length,
    },
    {
      dataIndex: "taxFiled",
      title: "Turnover >50 lakh and Income Tax Filed",
      sorter: (a, b) => a.taxFiled.length - b.taxFiled.length,
    },
    {
      dataIndex: "taxNotFiled",
      title: "Turnover >50 lakh and Income Tax not Filed",
      width: 150,
      sorter: (a, b) => a.taxNotFiled.length - b.taxNotFiled.length,
    },
    {
      dataIndex: "tdsclaculation",
      title: "TDS Calculation Based on",
      sorter: (a, b) => a.tdsclaculation.length - b.tdsclaculation.length,
    },
    {
      dataIndex: "action",
      title: "Action",
      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            onClick={(event) => handleViewClick(event)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
          <EditIcon
            onClick={(event) => handleEditClick(event)}
            style={{
              cursor: "pointer",
              marginLeft: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />
        </>
      ),
    },
  ];
  const data: DataType[] = [
    {
      key: 1,
      code: 55,
      partiesWithName: "Supplier1",
      partiesWithoutName: "person1",
      partiesNot: "7821",
      taxFiled: "1281",
      taxNotFiled: "defaultCompany1",
      tdsclaculation: "1000",
      action: "",
    },
    {
      key: 2,
      code: 55445,
      partiesWithName: "Supplier2",
      partiesWithoutName: "person2",
      partiesNot: "7822",
      taxFiled: "1282",
      taxNotFiled: "defaultCompany2",
      tdsclaculation: "1000",
      action: "",
    },
    {
      key: 3,
      code: 5565,
      partiesWithName: "Supplier3",
      partiesWithoutName: "person3",
      partiesNot: "7823",
      taxFiled: "1283",
      taxNotFiled: "defaultCompany3",
      tdsclaculation: "1000",
      action: "",
    },
    {
      key: 4,
      code: 5548,
      partiesWithName: "Supplier4",
      partiesWithoutName: "person4",
      partiesNot: "7824",
      taxFiled: "1284",
      taxNotFiled: "defaultCompany4",
      tdsclaculation: "1000",
      action: "",
    },
    {
      key: 5,
      code: 55121,
      partiesWithName: "Supplier5",
      partiesWithoutName: "person5",
      partiesNot: "7825",
      taxFiled: "1285",
      taxNotFiled: "defaultCompany5",
      tdsclaculation: "1000",
      action: "",
    },
    {
      key: 6,
      code: 5565,
      partiesWithName: "Supplier6",
      partiesWithoutName: "person6",
      partiesNot: "7826",
      taxFiled: "1286",
      taxNotFiled: "defaultCompany6",
      tdsclaculation: "1000",
      action: "",
    },
    {
      key: 7,
      code: 55789,
      partiesWithName: "Supplier7",
      partiesWithoutName: "person7",
      partiesNot: "7827",
      taxFiled: "1287",
      taxNotFiled: "defaultCompany7",
      tdsclaculation: "1000",
      action: "",
    },
    {
      key: 8,
      code: 55656,
      partiesWithName: "Supplier8",
      partiesWithoutName: "person8",
      partiesNot: "7828",
      taxFiled: "1288",
      taxNotFiled: "defaultCompany8",
      tdsclaculation: "1000",
      action: "",
    },
  ];

  const handleEditClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setIsEditing(!isEditing);
  };

  const handleViewClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setIsViewing(!isViewing);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const Options = ["tcs group", "geo level", "geo level value"];
  return (
    <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
      <Grid item xs={12} className=" mar-tb">
        <Typography>
          <b className="header-lable">TCS-TDS Configuration </b>
        </Typography>
      </Grid>

      {isEditing ? (
        <TCS_EditForm onBack={(event) => handleEditClick(event)} />
      ) : isViewing ? (
        <TCS_ViewForm onBack={(event) => handleViewClick(event)} />
      ) : (
        <>
          <form>
            <Grid container rowGap={3} columnGap={{ xs: 3, sm: 3 }}>
              <Grid item xs={12} sm={4} md={2}>
                <InputLabel size="normal" className="fnt-lable">
                  Search By
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <FormControl fullWidth>
                  <Select className="selct-box">
                    {Options.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container rowGap={3} columnGap={{ xs: 3, sm: 3 }} mt={3}>
              <Grid item xs={12} sm={4} md={2}>
                <InputLabel size="normal" className="fnt-lable">
                  Search Input
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <TextField className="Txt-box" />
              </Grid>
              <Button variant="contained" className="btn-apply btn-hgt">
                <SearchIcon className="fn-icon" /> Go
              </Button>
            </Grid>
          </form>
          <Box className="mar-tb">
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "end", mb: 2 }}
            >
              <SearchBar
                className="search-table"
                value={textFieldValue}
                onChange={(newValue) => {
                  setTextFieldValue(newValue);
                  handleSearch();
                }}
              />
            </Grid>
            <CommonTable
              // rowSelection={rowSelection}
              data={data}
              columns={columns}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
export default TCS_Configuration;
