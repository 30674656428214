import React from 'react';
import { Box, TextField, FormControl, Select, MenuItem, Button, Grid, Checkbox, InputLabel, SelectChangeEvent } from '@mui/material';

import ListItemText from '@mui/material/ListItemText';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Retailer',
  'Distributor',
  'Sub Stockiest',
  'Super Stockiest',
];



interface ViewSupplyChainFormProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
}

const SupplyChainViewForm: React.FC<ViewSupplyChainFormProps> = ({ onBack }) => {
    const handleBackClick = (event: React.MouseEvent<unknown>) => {
        onBack(event); // Pass the event argument to onBack function
    };

    const [personName, setPersonName] = React.useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent<typeof personName>) => {
      const {
        target: { value },
      } = event;
      setPersonName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    return (
        <>
            <Box className='card-form'>
                <form noValidate>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>

                        <Grid item xs={12} sm={4} md={3}>
                            <InputLabel size="normal"  className='fnt-lable'>SC Entity Type Code</InputLabel>
                            <TextField  className='Txt-box' />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>

                            <InputLabel size="normal"className='fnt-lable'  >SC Entity Type Name</InputLabel>
                            <TextField className='Txt-box' />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>

                            <InputLabel size="normal" className='fnt-lable' >Sell To Entity</InputLabel>
                            <FormControl fullWidth>
                                <Select
                                className='selct-box'
                                    id="demo-multiple-checkbox"
                                    // multiple
                                    value={personName}
                                    onChange={handleChange}
                                    // input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {names.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={personName.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} mt={2} display="flex" justifyContent="center">
                            <Button variant="contained" className='btn-colr' onClick={handleBackClick}>
                                Back
                            </Button>

                        </Grid>
                    </Grid>
                </form>
            </Box>
        </>
    );
}
export default SupplyChainViewForm