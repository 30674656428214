import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../hooks/useFetch";
import { sidebarFilterInv } from "../../../../../../Redux/AuthLogin/action";

import {
  updateBrand,
  updateBrandPop,
  updateBrandTemp,
  updatePackagingType,
  updatePackagingTypePop,
  updatePackagingTypeTemp,
  updateSegment,
  updateSegmentPop,
  updateSegmentTemp,
  updateSize,
  updateSizePop,
  updateSizeTemp,
  updateSubVariant,
  updateSubVariantPop,
  updateSubVariantTemp,
  updateVariant,
  updateVariantPop,
  updateVariantTemp,
} from "../../../../../../Redux/XDM/Inventory/filterReducer";
import { IoMdClose } from "react-icons/io";
import { decodeJwt } from "../../../../../../utils/constant";
import {
  getBrandFilter,
  getPackageTypeFilter,
  getSegmentFilter,
  getSizeFilter,
  getSubVarientFilter,
  getVarientFilter,
} from "../../../../../../Redux/XDM/Sales/salesReturnReducer";
import FilterModal from "./FilterModal";
import FilterSizeModal from "../../../../Inventory/FilterModal";

const WithoutInvoiceSidebar = () => {
  const segment = useAppSelector(
    (state) => state.salesReturnData.segmentFilter
  );
  const brand = useAppSelector((state) => state.salesReturnData.brandFilter);
  const variant = useAppSelector(
    (state) => state.salesReturnData.variantFilter
  );
  const subVariant = useAppSelector(
    (state) => state.salesReturnData.subVariantFilter
  );
  const packagingType = useAppSelector(
    (state) => state.salesReturnData.packagingTypeFilter
  );
  const size = useAppSelector((state) => state.salesReturnData.sizeFilter);
  const sizeCount = useAppSelector(
    (state) => state.salesReturnData.sizeFilter.count
  );

  const dispatch = useAppDispatch();
  const [filterModalTitle, setFilterModalTitle] = useState("");
  const [filterSizeModalTitle, setFilterSizeModalTitle] = useState("");

  const filterOpenInv = useAppSelector(
    (state) => state.showMorePopup.filterOpenInv
  );

  let tempData: any[] = [];

  const fetch = useAppSelector((state) => state.filterInv);

  const handleShowMoreModal = (title: any) => {
    setFilterModalTitle(title);
    dispatch(sidebarFilterInv(true));
    switch (title) {
      case "Segment":
        tempData = fetch.segmentSelected;
        dispatch(updateSegmentTemp(tempData));
        break;
      case "Brand":
        tempData = fetch.brandSelected;
        dispatch(updateBrandTemp(tempData));
        break;
      case "Variant":
        tempData = fetch.variantSelected;
        dispatch(updateVariantTemp(tempData));
        break;
      case "Sub Variant":
        tempData = fetch.subVariantSelected;
        dispatch(updateSubVariantTemp(tempData));
        break;
      case "Packaging Type":
        tempData = fetch.packagingTypeSelected;
        dispatch(updatePackagingTypeTemp(tempData));
        break;
      case "Size":
        tempData = fetch.sizeSelected;
        dispatch(updateSizeTemp(tempData));
        break;
      default:
    }
  };

  const handleSizeShowMoreModal = (title: any) => {
    setFilterSizeModalTitle("SizeSales");
    dispatch(sidebarFilterInv(true));
    tempData = fetch.sizeSelected;
    dispatch(updateSizeTemp(tempData));
  };

  let token = localStorage.getItem("token");
  let decoded = decodeJwt(token ?? "");

  const filterData = {
    page: 0,
    size: 4,
    pageStatus: true,
  };

  useEffect(() => {
    dispatch(getSegmentFilter(filterData));
    dispatch(getBrandFilter(filterData));
    dispatch(getVarientFilter(filterData));
    dispatch(getSubVarientFilter(filterData));
    dispatch(getPackageTypeFilter(filterData));
    dispatch(getSizeFilter(filterData));
  }, []);

  const segmentSelected = useAppSelector(
    (state) => state.filterInv.segmentSelected
  );
  const brandSelected = useAppSelector(
    (state) => state.filterInv.brandSelected
  );
  const variantSelected = useAppSelector(
    (state) => state.filterInv.variantSelected
  );
  const subVariantSelected = useAppSelector(
    (state) => state.filterInv.subVariantSelected
  );
  const packagingTypeSelected = useAppSelector(
    (state) => state.filterInv.packagingTypeSelected
  );
  const sizeSelected = useAppSelector((state) => state.filterInv.sizeSelected);

  const segmentSelectedPop = useAppSelector(
    (state) => state.filterInv.popSegmentSelected
  );
  const brandSelectedPop = useAppSelector(
    (state) => state.filterInv.popBrandSelected
  );
  const variantSelectedPop = useAppSelector(
    (state) => state.filterInv.popVariantSelected
  );
  const subVariantSelectedPop = useAppSelector(
    (state) => state.filterInv.popSubVariantSelected
  );
  const packagingTypeSelectedPop = useAppSelector(
    (state) => state.filterInv.popPackagingTypeSelected
  );
  const sizeSelectedPop = useAppSelector(
    (state) => state.filterInv.popSizeSelected
  );

  const segmentSelect = (value: string) => {
    const isSelected = segmentSelected.includes(value);
    const updatedSegmentSelected = isSelected
      ? segmentSelected.filter((item) => item !== value)
      : [...segmentSelected, value];
    dispatch(updateSegment(updatedSegmentSelected));
    dispatch(updateSegmentPop(updatedSegmentSelected));
    dispatch(updateSegmentTemp(updatedSegmentSelected));
  };

  const brandSelect = (value: string) => {
    const isSelected = brandSelected.includes(value);
    const updatedBrandSelected = isSelected
      ? brandSelected.filter((item) => item !== value)
      : [...brandSelected, value];
    dispatch(updateBrand(updatedBrandSelected));
    dispatch(updateBrandPop(updatedBrandSelected));
    dispatch(updateBrandTemp(updatedBrandSelected));
  };

  const variantSelect = (value: string) => {
    const isSelected = variantSelected.includes(value);
    const updatedVariantSelected = isSelected
      ? variantSelected.filter((item) => item !== value)
      : [...variantSelected, value];
    dispatch(updateVariant(updatedVariantSelected));
    dispatch(updateVariantPop(updatedVariantSelected));
    dispatch(updateVariantTemp(updatedVariantSelected));
  };

  const subVariantSelect = (value: string) => {
    const isSelected = subVariantSelected.includes(value);
    const updatedSubVariantSelected = isSelected
      ? subVariantSelected.filter((item) => item !== value)
      : [...subVariantSelected, value];
    dispatch(updateSubVariant(updatedSubVariantSelected));
    dispatch(updateSubVariantPop(updatedSubVariantSelected));
    dispatch(updateSubVariantTemp(updatedSubVariantSelected));
  };

  const packagingTypeSelect = (value: string) => {
    const isSelected = packagingTypeSelected.includes(value);
    const updatedPackagingTypeSelected = isSelected
      ? packagingTypeSelected.filter((item) => item !== value)
      : [...packagingTypeSelected, value];
    dispatch(updatePackagingType(updatedPackagingTypeSelected));
    dispatch(updatePackagingTypePop(updatedPackagingTypeSelected));
    dispatch(updatePackagingTypeTemp(updatedPackagingTypeSelected));
  };
  const sizeSelect = (value: string) => {
    const isSelected = sizeSelected.includes(value);
    const updatedSizeSelected = isSelected
      ? sizeSelected.filter((item) => item !== value)
      : [...sizeSelected, value];
    dispatch(updateSize(updatedSizeSelected));
    dispatch(updateSizePop(updatedSizeSelected));
    dispatch(updateSizeTemp(updatedSizeSelected));
  };

  const clearAll = (title: any) => {
    switch (title) {
      case "Segment":
        dispatch(updateSegment([]));
        dispatch(updateSegmentPop([]));
        dispatch(updateSegmentTemp([]));
        break;
      case "Brand":
        dispatch(updateBrand([]));
        dispatch(updateBrandPop([]));
        dispatch(updateBrandTemp([]));
        break;
      case "Variant":
        dispatch(updateVariant([]));
        dispatch(updateVariantPop([]));
        dispatch(updateVariantTemp([]));
        break;
      case "Sub Variant":
        dispatch(updateSubVariant([]));
        dispatch(updateSubVariantPop([]));
        dispatch(updateSubVariantTemp([]));
        break;
      case "Packaging Type":
        dispatch(updatePackagingType([]));
        dispatch(updatePackagingTypePop([]));
        dispatch(updatePackagingTypeTemp([]));
        break;
      case "Size":
        dispatch(updateSize([]));
        dispatch(updateSizePop([]));
        dispatch(updateSizeTemp([]));
        break;
      default:
    }
  };

  return (
    <Box
      sx={{
        padding: "0px 5px 0px 16px",
        marginBottom: "100px",
        marginRight: "4.3px",
        marginTop: "4px",
      }}
      className="dis-flex scrollHide"
    >
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
            Segment
          </Typography>
          <Typography
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              mb: 1,
              color: "var(--grey-grey500, #505259)",
            }}
          >
            {segmentSelectedPop.length > 0 &&
              "(" + segmentSelectedPop.length + " Selected)"}
          </Typography>
        </Box>
        <Divider />
        <FormControl className="w-100px" sx={{ mt: 1 }}>
          {fetch.loading
            ? // Render skeletons while loading
              Array.from(new Array(4)).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  height={15}
                  sx={{ mb: 1 }}
                />
              ))
            : Array.isArray(segment.values) &&
              segment.values.map((values: any, index: number) => (
                <FormControlLabel
                  key={index}
                  value={values}
                  control={
                    <Checkbox
                      onChange={() => segmentSelect(values.name)}
                      checked={segmentSelected.includes(values.name)}
                      className="sidebar_checkbox"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 15,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label={
                    <div
                      title={values.name}
                      style={{ paddingTop: "2px", paddingLeft: "5px" }}
                      className="filter_lable"
                    >
                      {values.name}
                    </div>
                  }
                />
              ))}
        </FormControl>
        <Box alignItems="center" className="filterFooter">
          {segmentSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              className="clearAll"
              onClick={() => clearAll("Segment")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}>
            {segment.count > 0 && (
              <Typography
                className="moreData"
                onClick={() => handleShowMoreModal("Segment")}
              >
                + {segment.count} More
              </Typography>
            )}
          </Grid>
        </Box>
      </Card>
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
            Brand
          </Typography>
          <Typography
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              mb: 1,
              color: "var(--grey-grey500, #505259)",
            }}
          >
            {brandSelectedPop.length > 0 &&
              "(" + brandSelectedPop.length + " Selected)"}
          </Typography>
        </Box>
        <Divider />
        <FormControl className="w-100px" sx={{ mt: 1 }}>
          {fetch.loading
            ? // Render skeletons while loading
              Array.from(new Array(4)).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  height={15}
                  sx={{ mb: 1 }}
                />
              ))
            : Array.isArray(brand.values) &&
              brand.values.map((values: any, index: number) => (
                <FormControlLabel
                  key={index}
                  value={values.name}
                  control={
                    <Checkbox
                      onChange={() => brandSelect(values.name)}
                      checked={brandSelected.includes(values.name)}
                      className="sidebar_checkbox"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 15,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label={
                    <div
                      title={values.name}
                      style={{ paddingTop: "2px", paddingLeft: "5px" }}
                      className="filter_lable"
                    >
                      {values.name}
                    </div>
                  }
                />
              ))}
        </FormControl>
        <Box alignItems="center" className="filterFooter">
          {brandSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              className="clearAll"
              onClick={() => clearAll("Brand")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}>
            {brand.count > 0 && (
              <Typography
                className="moreData"
                onClick={() => handleShowMoreModal("Brand")}
              >
                + {brand.count} More
              </Typography>
            )}
          </Grid>
        </Box>
      </Card>
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
            Variant
          </Typography>
          <Typography
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              mb: 1,
              color: "var(--grey-grey500, #505259)",
            }}
          >
            {variantSelectedPop.length > 0 &&
              "(" + variantSelectedPop.length + " Selected)"}
          </Typography>
        </Box>
        <Divider />
        <FormControl className="w-100px" sx={{ mt: 1 }}>
          {fetch.loading
            ? // Render skeletons while loading
              Array.from(new Array(4)).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  height={15}
                  sx={{ mb: 1 }}
                />
              ))
            : Array.isArray(variant.values) &&
              variant.values.map((values: any, index: number) => (
                <FormControlLabel
                  key={index}
                  value={values.name}
                  control={
                    <Checkbox
                      onChange={() => variantSelect(values.name)}
                      checked={variantSelected.includes(values.name)}
                      className="sidebar_checkbox"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 15,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label={
                    <div
                      title={values.name}
                      style={{ paddingTop: "2px", paddingLeft: "5px" }}
                      className="filter_lable"
                    >
                      {values.name}
                    </div>
                  }
                />
              ))}
        </FormControl>
        <Box alignItems="center" className="filterFooter">
          {variantSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              className="clearAll"
              onClick={() => clearAll("Variant")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}>
            {variant.count > 0 && (
              <Typography
                className="moreData"
                onClick={() => handleShowMoreModal("Variant")}
              >
                + {variant.count} More
              </Typography>
            )}
          </Grid>
        </Box>
      </Card>
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
            Sub Variant
          </Typography>
          <Typography
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              mb: 1,
              color: "var(--grey-grey500, #505259)",
            }}
          >
            {subVariantSelectedPop.length > 0 &&
              "(" + subVariantSelectedPop.length + " Selected)"}
          </Typography>
        </Box>
        <Divider />
        <FormControl className="w-100px" sx={{ mt: 1 }}>
          {fetch.showMoreLoading
            ? // Render skeletons while loading
              Array.from(new Array(4)).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  height={15}
                  sx={{ mb: 1 }}
                />
              ))
            : Array.isArray(subVariant.values) &&
              subVariant.values.map((values: any, index: number) => (
                <FormControlLabel
                  key={index}
                  value={values.name}
                  control={
                    <Checkbox
                      onChange={() => subVariantSelect(values.name)}
                      checked={subVariantSelected.includes(values.name)}
                      className="sidebar_checkbox"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 15,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label={
                    <div
                      title={values.name}
                      style={{ paddingTop: "2px", paddingLeft: "5px" }}
                      className="filter_lable"
                    >
                      {values.name}
                    </div>
                  }
                />
              ))}
        </FormControl>
        <Box alignItems="center" className="filterFooter">
          {subVariantSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              className="clearAll"
              onClick={() => clearAll("Sub Variant")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}>
            {subVariant.count > 0 && (
              <Typography
                className="moreData"
                onClick={() => handleShowMoreModal("Sub Variant")}
              >
                + {subVariant.count} More
              </Typography>
            )}
          </Grid>
        </Box>
      </Card>
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
            Packaging Type
          </Typography>
          <Typography
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              mb: 1,
              color: "var(--grey-grey500, #505259)",
            }}
          >
            {packagingTypeSelectedPop.length > 0 &&
              "(" + packagingTypeSelectedPop.length + " Selected)"}
          </Typography>
        </Box>
        <Divider />
        <FormControl className="w-100px" sx={{ mt: 1 }}>
          {fetch.showMoreLoading
            ? // Render skeletons while loading
              Array.from(new Array(4)).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  height={15}
                  sx={{ mb: 1 }}
                />
              ))
            : Array.isArray(packagingType.values) &&
              packagingType.values.map((values: any, index: number) => (
                <FormControlLabel
                  key={index}
                  value={values.name}
                  control={
                    <Checkbox
                      onChange={() => packagingTypeSelect(values.name)}
                      checked={packagingTypeSelected.includes(values.name)}
                      className="sidebar_checkbox"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 15,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label={
                    <div
                      title={values.name}
                      style={{ paddingTop: "2px", paddingLeft: "5px" }}
                      className="filter_lable"
                    >
                      {values.name}
                    </div>
                  }
                />
              ))}
        </FormControl>
        <Box alignItems="center" className="filterFooter">
          {packagingTypeSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              className="clearAll"
              onClick={() => clearAll("Packaging Type")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}>
            {packagingType.count > 0 && (
              <Typography
                className="moreData"
                onClick={() => handleShowMoreModal("Packaging Type")}
              >
                + {packagingType.count} More
              </Typography>
            )}
          </Grid>
        </Box>
      </Card>
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
            Size
          </Typography>
          <Typography
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              mb: 1,
              color: "var(--grey-grey500, #505259)",
            }}
          >
            {sizeSelectedPop.length > 0 &&
              "(" + sizeSelectedPop.length + " Selected)"}
          </Typography>
        </Box>
        <Divider />
        <FormControl className="w-100px" sx={{ mt: 1 }}>
          {fetch.showMoreLoading
            ? // Render skeletons while loading
              Array.from(new Array(4)).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  height={15}
                  sx={{ mb: 1 }}
                />
              ))
            : Array.isArray(size.data) &&
              size.data.map((values: any, index: number) => (
                <FormControlLabel
                  key={index}
                  value={values}
                  control={
                    <Checkbox
                      checked={sizeSelected.includes(values)}
                      onChange={() => sizeSelect(values)}
                      className="sidebar_checkbox"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 15,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label={
                    <div
                      title={values}
                      style={{ paddingTop: "2px", paddingLeft: "5px" }}
                      className="filter_label"
                    >
                      {values}
                    </div>
                  }
                />
              ))}
        </FormControl>
        <Box alignItems="center" className="filterFooter">
          {sizeSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              className="clearAll"
              onClick={() => clearAll("Size")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}>
            {size.count > 0 && (
              <Typography
                className="moreData"
                onClick={() => handleSizeShowMoreModal("Size")}
              >
                + {size.count} More
              </Typography>
            )}
          </Grid>
        </Box>
      </Card>
      {filterOpenInv && filterSizeModalTitle !== "SizeSales" && (
        <FilterModal title={filterModalTitle} />
      )}
      {filterOpenInv && filterSizeModalTitle === "SizeSales" && (
        <FilterSizeModal title={filterSizeModalTitle} />
      )}
    </Box>
  );
};

export default WithoutInvoiceSidebar;
