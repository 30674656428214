import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Box, Grid, IconButton, Toolbar, useMediaQuery } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/useFetch";
// project import
import Drawer from "../MainLayout/Drawer";
import Header from "./Header";
import { drawerWidth } from "../../config";
// types
import { openDrawer } from "../../Redux/menu";
import { extraspace } from "../../config";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
// import { useSubTabsContext } from './Header/HeaderContent/SubHeaderContent/SubTabsContext';
import SidebarModel from "./Drawer/DrawerContent/SidebarModel";
import { toggleShowMore } from "../../Redux/AuthLogin/action";
import ErrorBoundary from "../../Pages/ExtraPages/ErrorBoundary";

// ==============================|| MAIN LAYOUT ||============================== //
interface HeaderProps {
  open?: boolean;
  handleDrawerToggle?: () => void;
}

const MainLayout: React.FC<HeaderProps> = () => {
  // const { value, selectedTab } = useSubTabsContext();
  const theme = useTheme();
  const iconBackColor = "grey.100";
  const iconBackColorOpen = "grey.200";
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useAppDispatch();

  const { drawerOpen } = useAppSelector((state: any) => state.menu);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("");
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    const firstPart = location.pathname;
    const pathParts = firstPart.split("/");
    setCurrentPath(pathParts[pathParts.length - 1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen, location.pathname]);
  const [adLogin, setAdLogin] = useState("");

  useEffect(() => {
    const storedDistributionCode = localStorage.getItem("ad-distributionCode");
    if (storedDistributionCode) {
      setAdLogin(storedDistributionCode);
    }
  }, []);

  const showMorePopup = useAppSelector(
    (state: any) => state.showMorePopup.sidebarShowMore
  );

  const handleSidebarClose = () => {
    dispatch(toggleShowMore(false));
  };

  const sidebarFilterOpen = useAppSelector(
    (state: any) => state.sidebarFilterOpen.filterOpen.filterOpen
  );

  return (
    <Box sx={{ display: "flex", width: "100%" }} className="headercls">
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      {sidebarFilterOpen === true && (
        <Drawer open={open} handleDrawerToggle={handleDrawerToggle}></Drawer>
      )}

      <Box
        component="main"
        className="main_card"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          flexGrow: 1,
          // p: { xs: 2, sm: 3 },
          marginTop: "20px",
          overflowY: "auto",
        }}
      >
        <Toolbar />
        <Grid item>
          {currentPath !== "product_dashboard" &&
            currentPath !== "product_state_code" &&
            currentPath !== "company_dashboard" &&
            currentPath !== "Configuration_dashboard" &&
            currentPath !== "dashboard_supplier" &&
            sidebarFilterOpen === true && (
              <Grid
                item
                sx={{
                  pl: { xs: 2, sm: 0.4 },
                  pt: { xs: 1, sm: 1.2 },
                  pb: 0,
                  height: "25px",
                }}
              >
                <IconButton
                  disableRipple
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="start"
                  color="secondary"
                  sx={{
                    color: "text.primary",
                    bgcolor: open ? iconBackColorOpen : iconBackColor,

                    ml: { xs: 0, lg: 0 },
                  }}
                  className="sidebar_icon"
                >
                  {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </IconButton>
              </Grid>
            )}
        </Grid>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
        {showMorePopup && <SidebarModel onClose={handleSidebarClose} />}
      </Box>
    </Box>
  );
};

export default MainLayout;
