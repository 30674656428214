import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import createAxiosInstance from "../../../hooks/useFetch";

interface manualOrderDataOutlet {
  outletCode: string;
  outletName: string;
  routCode: string[];
  address: string;
}
interface manualfiltrer {
  outletCode: string;
  searchTerm: string | null;
  flags: string;
}
interface SaveRecentSearch {
  menu: string;
  subMenu: string;
  outletCode: string;
}
interface manualOrderDatasalesman {
  smUserId: string;
  userName: string;
}
interface manualOrderDataroute {
  routeCode: string;
  routeName: string;
}
interface manaulOrderSchemeData {
  productHierarchy: string;
  flags: string[];
  mrp: number;
  basicMaterial: string;
  matGrp2Desc: string;
  totalSaleableStock: number;
  productCode: string;
  sellingRate: number;
  uom: string;
  division: string;
  brand: string;
  variant: string;
  sgstOrUgstPercentage: number;
  cgstPercentage: number;
  sgstPercentage: number;
  igstPercentage: number;
  noOfPcs: number;
}
interface manaulOrderSchemeRequestData {
  productHierarchy: string;
  flags: string[];
  mrp: number;
  basicMaterial: string;
  matGrp2Desc: string;
  sellingRate: number;
  grossAmount: number;
  schemeAmount: number;
  productCode: string;
  netAmount: number;
  quantity: number;
  freeQuantity: number;
  totalSaleableStock: number;
  lowStockOrder: boolean;
  uom: string;
  division: string;
  brand: string;
  variant: string;
  sgstOrUgstPercentage: number;
  cgstPercentage: number;
  sgstPercentage: number;
  igstPercentage: number;
  noOfPcs: number;
}

interface manualOrderState {
  outlet: manualOrderDataOutlet[];
  outletLoading: boolean;
  outletError: string | null;
  salesman: manualOrderDatasalesman[];
  salesmanLoading: boolean;
  salesmanError: string | null;
  route: manualOrderDataroute[];
  routeLoading: boolean;
  routeError: string | null;
  loading: boolean;
  error: string | null;
  manualScheme: manaulOrderSchemeData[];
  manualSchemeLoading: boolean;
  manualSchemeError: string | null;
  manualAllProducts: manaulOrderSchemeData[];
  manualAllProductsLoading: boolean;
  manualAllProductsError: string | null;
  manualLastOrder: manaulOrderSchemeData[];
  manualLastOrderLoading: boolean;
  manualLastOrderError: string | null;
  addToCartSelected: manaulOrderSchemeRequestData[];
  previousSelectedData: manaulOrderSchemeRequestData[];
  recentSearch: manualOrderDataOutlet[];
  recentSearchLoading: boolean;
  recentSearchError: string | null;
  previousDisabledCheckbox: boolean | null;
}

interface convertBillReq {
  outletCode: string | null;
  smUserId: string | null;
  routeCode: string | null;
  routeName: string | null;
  outletName: string | null;
  salesmanName: string | null;
  totalSchemeDiscount: string;
  totalTax: string;
  totalValue: number;
  productDetails: ProductDetails[];
}
interface ProductDetails {
  qty: number;
  productCode: string;
  productName: string;
  sellRate: string;
  schemeDiscount: string;
  tax: string;
  division: string;
  brand: string;
  variant: string;
  orderValue: string;
  orderTakenBy: string;
  productHierarchy: string;
  noOfPcs: number;
}
const axiosInstance = createAxiosInstance();

export const getManualOrderOutlet = createAsyncThunk(
  "manualOrder/get-manual-order",
  async (search: string | null) => {
    const response = await axiosInstance.get(
      `manualOrder/outlet?search=${search}`
    );
    return response.data.data;
  }
);
export const getManualOrderSalesman = createAsyncThunk(
  "manualOrder/get-salesman-data",
  async (outletCode: string) => {
    const response = await axiosInstance.get(
      `manualOrder/salesman?outletCode=${outletCode}`
    );
    return response.data.data as manualOrderDatasalesman[];
  }
);
export const getManualOrderRoute = createAsyncThunk(
  "manualOrder/get-route-data",
  async (params: { outletCode: string; salesmanCode: string | null }) => {
    const { outletCode, salesmanCode } = params;
    let url = `manualOrder/routes?outletCode=${outletCode}`;
    if (salesmanCode !== null) {
      url = url + `&salesmanCode=${salesmanCode}`;
    }
    const response = await axiosInstance.get(url);
    return response.data.data as manualOrderDataroute[];
  }
);
export const getManualOrderSchemeData = createAsyncThunk(
  "manualOrder/get-manual-order-scheme-data",
  async (req: {
    params: {
      outletCode: string | null;
      searchTerm: string | null;
      flags: string | null;
    };
    signal: AbortSignal;
  }) => {
    const { outletCode, searchTerm, flags } = req.params;
    const response = await axiosInstance.get(
      `manualOrder/getProductsByScheme?outletCode=${outletCode}&searchTerm=${searchTerm}&flags=${flags}`,
      { signal: req.signal }
    );
    return response.data.data as manaulOrderSchemeData[];
  }
);
export const getManualOrderAllProducts = createAsyncThunk(
  "manualOrder/get-manual-order-all-data",
  async (
    params: { outletCode: string | null; signal: AbortSignal },
    { signal }
  ) => {
    const { outletCode } = params;
    const response = await axiosInstance.get(
      `manualOrder/get-manual-orderList?outletCode=${outletCode}`,
      { signal: params.signal }
    );
    return response.data.data as manaulOrderSchemeData[];
  }
);
export const getManualOrderLastOrder = createAsyncThunk(
  "manualOrder/get-manual-order-last-data",
  async (
    params: { outletCode: string | null; signal: AbortSignal },
    { signal }
  ) => {
    const { outletCode } = params;
    const response = await axiosInstance.get(
      `manualOrder/get-manual-lastOrder?outletCode=${outletCode}`,
      { signal: params.signal }
    );
    return response.data.data as manaulOrderSchemeData[];
  }
);

export const mannualOrderConvertBill = createAsyncThunk(
  "manualOrder/convertBill",
  async (formData: convertBillReq) => {
    const response = await axiosInstance.post(
      "manualOrder/book-order",
      formData
    );
    return response.data;
  }
);
export const manualReqAddToCart = createAsyncThunk(
  "manual/addToCart",
  async (req: any[]) => {
    return req;
  }
);
export const savePreviouslyAddedData = createAsyncThunk(
  "manual/savePreviouslyAddedData",
  async (req: any) => {
    return req;
  }
);
export const saveRecentSearch = createAsyncThunk(
  "save /recentSearch",
  async (formData: SaveRecentSearch) => {
    const response = await axiosInstance.post(
      "manualOrder/save-recent-search",
      formData
    );
    return response.data;
  }
);
export const getRecentSearch = createAsyncThunk(
  "manualOrder/get-recent-search",
  async (params: { menu: string; subMenu: string }) => {
    const { menu, subMenu } = params;

    const response = await axiosInstance.get(
      `manualOrder/recent-search?menu=${menu}&subMenu=${subMenu}`
    );
    return response.data.data;
  }
);
export const showDisablecheckbox = createAsyncThunk(
  "manual/showDisablecheckbox",
  async (req: any) => {
    return req;
  }
);
const initialState: manualOrderState = {
  outlet: [],
  outletLoading: false,
  outletError: null,
  salesman: [],
  salesmanLoading: false,
  salesmanError: null,
  manualScheme: [],
  manualSchemeLoading: false,
  manualSchemeError: null,
  manualAllProducts: [],
  manualAllProductsLoading: false,
  manualAllProductsError: null,
  manualLastOrder: [],
  manualLastOrderLoading: false,
  manualLastOrderError: null,
  route: [],
  routeLoading: false,
  routeError: null,
  loading: false,
  error: null,
  addToCartSelected: [],
  previousSelectedData: [],
  recentSearch: [],
  recentSearchLoading: false,
  recentSearchError: null,
  previousDisabledCheckbox: null,
};
const ManualOrderSlice = createSlice({
  name: "ManualOrderData",
  initialState,
  reducers: {
    outletFilterFetch: (
      state,
      action: PayloadAction<Partial<manualOrderDataOutlet>>
    ) => {
      // state.outlet = action.payload|| state.outlet;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getManualOrderOutlet.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.outletLoading = false;
      state.outletError = action.error.message ?? null;
    });
    builder.addCase(getManualOrderOutlet.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.outletLoading = true;
      state.outletError = null;
    });
    builder.addCase(
      getManualOrderOutlet.fulfilled,
      (state, action: PayloadAction<manualOrderDataOutlet[]>) => {
        state.outlet = action.payload;
        state.loading = false;
        state.error = null;
        state.outletLoading = false;
        state.outletError = null;
      }
    );
    builder.addCase(getManualOrderSalesman.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.salesmanLoading = false;
      state.salesmanError = action.error.message ?? null;
    });
    builder.addCase(getManualOrderSalesman.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.salesmanLoading = true;
      state.salesmanError = null;
    });
    builder.addCase(
      getManualOrderSalesman.fulfilled,
      (state, action: PayloadAction<manualOrderDatasalesman[]>) => {
        state.salesman = action.payload;
        state.loading = false;
        state.error = null;
        state.salesmanLoading = false;
        state.salesmanError = null;
      }
    );
    builder.addCase(getManualOrderRoute.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.routeLoading = false;
      state.routeError = action.error.message ?? null;
    });
    builder.addCase(getManualOrderRoute.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.routeLoading = true;
      state.routeError = null;
    });
    builder.addCase(
      getManualOrderRoute.fulfilled,
      (state, action: PayloadAction<manualOrderDataroute[]>) => {
        state.route = action.payload;
        state.loading = false;
        state.error = null;
        state.routeLoading = false;
        state.routeError = null;
      }
    );
    builder.addCase(getManualOrderSchemeData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.manualSchemeLoading = false;
      state.manualSchemeError = action.error.message ?? null;
    });
    builder.addCase(getManualOrderSchemeData.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.manualScheme = [];
      state.manualSchemeLoading = true;
      state.manualSchemeError = null;
    });
    builder.addCase(
      getManualOrderSchemeData.fulfilled,
      (state, action: PayloadAction<manaulOrderSchemeData[]>) => {
        state.manualScheme = action.payload;
        state.loading = false;
        state.error = null;
        state.manualSchemeLoading = false;
        state.manualSchemeError = null;
      }
    );
    builder.addCase(getManualOrderAllProducts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.manualAllProductsLoading = false;
      state.manualAllProductsError = action.error.message ?? null;
    });
    builder.addCase(getManualOrderAllProducts.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.manualAllProducts = [];
      state.manualAllProductsLoading = true;
      state.manualAllProductsError = null;
    });
    builder.addCase(
      getManualOrderAllProducts.fulfilled,
      (state, action: PayloadAction<manaulOrderSchemeData[]>) => {
        state.manualAllProducts = action.payload;
        state.loading = false;
        state.error = null;
        state.manualAllProductsLoading = false;
        state.manualAllProductsError = null;
      }
    );
    builder.addCase(getManualOrderLastOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.manualLastOrderLoading = false;
      state.manualLastOrderError = action.error.message ?? null;
    });
    builder.addCase(getManualOrderLastOrder.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.manualLastOrder = [];
      state.manualLastOrderLoading = true;
      state.manualLastOrderError = null;
    });
    builder.addCase(
      getManualOrderLastOrder.fulfilled,
      (state, action: PayloadAction<manaulOrderSchemeData[]>) => {
        state.manualLastOrder = action.payload;
        state.loading = false;
        state.error = null;
        state.manualLastOrderLoading = false;
        state.manualLastOrderError = null;
      }
    );
    builder.addCase(manualReqAddToCart.fulfilled, (state, action) => {
      state.addToCartSelected = action.payload;
    });
    builder.addCase(savePreviouslyAddedData.fulfilled, (state, action) => {
      state.previousSelectedData = action.payload;
    });
    builder.addCase(getRecentSearch.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.recentSearchLoading = false;
      state.recentSearchError = action.error.message ?? null;
    });
    builder.addCase(getRecentSearch.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.recentSearch = [];
      state.recentSearchLoading = true;
      state.recentSearchError = null;
    });
    builder.addCase(
      getRecentSearch.fulfilled,
      (state, action: PayloadAction<manualOrderDataOutlet[]>) => {
        state.recentSearch = action.payload;
        state.loading = false;
        state.error = null;
        state.recentSearchLoading = false;
        state.recentSearchError = null;
      }
    );
    builder.addCase(showDisablecheckbox.fulfilled, (state, action) => {
      state.previousDisabledCheckbox = action.payload;
    });
  },
});
export default ManualOrderSlice.reducer;
