import React, { useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  Button,
  Grid,
  InputLabel,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Card,
  Select,
  MenuItem,
  TextareaAutosize,
  Checkbox,
  Typography,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  fromdate: string;
  todate: string;
  question: string;
  answer: string;
  option: string;
  photo: string;
  mandatory: string;
  action: string;
}

interface SurveyFormProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const SurveyForm: React.FC<SurveyFormProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const [selectedOption, setSelectedOption] = useState("");
  const [photoSelectedOption, setPhotoSelectedOption] = useState("");
  const [answerSelectedOption, setAnswerSelectedOption] = useState("");

  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // seach function here
  };

  const mandatoryhandleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedOption(event.target.value);
  };

  const photoEnablehandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhotoSelectedOption(event.target.value);
  };
  const AnswerTypehandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnswerSelectedOption(event.target.value);
  };
  const Options = ["Radio Button", "Checkbox", "Dropdown"];

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "fromdate",
      title: "FromDate",
      sorter: (a, b) => a.fromdate.length - b.fromdate.length,
    },
    {
      dataIndex: "todate",
      title: "ToDate",
      sorter: (a, b) => a.todate.length - b.todate.length,
    },
    {
      dataIndex: "question",
      title: "Question",
      sorter: (a, b) => a.question.length - b.question.length,
    },
    {
      dataIndex: "answer",
      title: "Answer Type",
      sorter: (a, b) => a.answer.length - b.answer.length,
    },
    {
      dataIndex: "option",
      title: "Options",
      sorter: (a, b) => a.option.length - b.option.length,
    },
    {
      dataIndex: "photo",
      title: "PhotoEnable",
      sorter: (a, b) => a.photo.length - b.photo.length,
    },
    {
      dataIndex: "mandatory",
      title: "Mandatory",
      sorter: (a, b) => a.mandatory.length - b.mandatory.length,
    },
    {
      dataIndex: "action",
      title: "Action",
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      fromdate: "100",
      todate: "2024/2/26",
      question: "company",
      answer: "Country",
      option: "both",
      photo: "photo checking",
      mandatory: "person",
      action: "",
    },
    {
      key: 2,
      fromdate: "200",
      todate: "2024/2/26",
      question: "company",
      answer: "State",
      option: "both",
      photo: "photo checking",
      mandatory: "person",
      action: "",
    },
    {
      key: 3,
      fromdate: "300",
      todate: "2024/2/26",
      question: "company",
      answer: "Town",
      option: "both",
      photo: "photo checking",
      mandatory: "person",
      action: "",
    },
    {
      key: 4,
      fromdate: "400",
      todate: "2024/2/26",
      question: "company",
      answer: "District",
      option: "both",
      photo: "photo checking",
      mandatory: "person",
      action: "",
    },
    {
      key: 5,
      fromdate: "500",
      todate: "2024/2/26",
      question: "company",
      answer: "Country1",
      option: "both",
      photo: "photo checking",
      mandatory: "person",
      action: "",
    },
    {
      key: 6,
      fromdate: "600",
      todate: "2024/2/26",
      question: "company",
      answer: "Supplier6",
      option: "both",
      photo: "photo checking",
      mandatory: "person",
      action: "",
    },
    {
      key: 7,
      fromdate: "700",
      todate: "2024/2/26",
      question: "company",
      answer: "Supplier7",
      option: "both",
      photo: "photo checking",
      mandatory: "person",
      action: "",
    },
    {
      key: 8,
      fromdate: "800",
      todate: "2024/2/26",
      question: "company",
      answer: "Supplier8",
      option: "both",
      photo: "photo checking",
      mandatory: "person",
      action: "",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Box className="card-form card-bg">
      <form>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }} mt={3}>
          <Grid item xs={12} sm={4} md={3}>
            <Typography className="fnt-lable">From Date</Typography>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker sx={{ width: "90%" }} />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Typography className="fnt-lable">To Date</Typography>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker sx={{ width: "90%" }} />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }} mt={2}>
          <Grid item xs={12} sm={1}>
            <InputLabel size="normal" className="fnt-lable">
              Mandatory
            </InputLabel>
          </Grid>
          <Grid item>
            <RadioGroup
              row
              aria-label="mandatory"
              name="mandatory"
              value={selectedOption}
              onChange={mandatoryhandleChange}
            >
              <FormControlLabel
                value="yes"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label="No"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }} mt={2}>
          <Grid item xs={12} sm={4} md={3}>
            <InputLabel size="normal" className="fnt-lable">
              Distributor
            </InputLabel>
            <TextField className="Txt-box"></TextField>
          </Grid>
          <Grid item xs={12} sm={4} md={3} mt={1}>
            <Button variant="contained" className="btn-apply">
              ...
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <InputLabel size="normal" className="fnt-lable">
              Survey Description{" "}
            </InputLabel>
            <TextField className="Txt-box"></TextField>
          </Grid>
        </Grid>
        {/* </Grid> */}
        <Card className="card-form" sx={{ border: "1px solid #EEE", mt: 4 }}>
        <Grid container xs={12} sm={4} md={3} className="survey-box-lable">

          <Grid>Retailer Category :</Grid>
          </Grid>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 3 }}
            mt={1}
          >
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                {" "}
                Retailer Channel{" "}
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField className="Txt-box" size="small"></TextField>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Button variant="contained" className="btn-colr">
                ...
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 3 }}
            mt={1}
          >
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                {" "}
                Retailer Group{" "}
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField className="Txt-box"></TextField>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Button variant="contained" className="btn-colr">
                ...
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 3 }}
            mt={1}
          >
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                {" "}
                Retailer Class{" "}
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField className="Txt-box"></TextField>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Button variant="contained" className="btn-colr">
                ...
              </Button>
            </Grid>
          </Grid>
        </Card>
        <Card className="card-form" sx={{ border: "1px solid #EEE", mt: 4 }}>
        <Grid container xs={12} sm={4} md={3} className="survey-box-lable">

            Question and Answer key :
          </Grid>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 3 }}
            mt={1}
          >
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                {" "}
                Question{" "}
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextareaAutosize
                placeholder="Description"
                minRows={4}
                maxRows={4}
                style={{ width: "90%" }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 3 }}
            mt={1}
          >
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                {" "}
                Answer Type
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <FormControl fullWidth>
                <Select className="selct-box">
                  {Options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 3 }}
            mt={1}
          >
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                PhotoEnable
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <RadioGroup
                row
                aria-label="mandatory"
                name="mandatory"
                value={photoSelectedOption}
                onChange={photoEnablehandle}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 3 }}
            mt={1}
          >
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Answer Type Value
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <RadioGroup
                row
                aria-label="mandatory"
                name="mandatory"
                value={photoSelectedOption}
                onChange={photoEnablehandle}
              >
                <FormControlLabel
                  value="mandatory"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="mandatory"
                />
                <FormControlLabel
                  value="Non-Mandatory"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="Non-Mandatory"
                />
              </RadioGroup>
            </Grid>
          </Grid>

          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 3, sm: 3 }}
            mt={1}
          >
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable"> Answer Type Values </InputLabel>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField className="Txt-box" size="small"></TextField>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>

            <Button variant="contained" className="btn-colr">
              Add
            </Button>
            </Grid>
          </Grid>
          {/* </Grid> */}
        </Card>
        <Box className="mar-tb">
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "end", mb: 2 }}
          >
            <SearchBar
              className="search-table"
              value={textFieldValue}
              onChange={(newValue) => {
                setTextFieldValue(newValue);
                handleSearch();
              }}
            />
          </Grid>
          <CommonTable
            // rowSelection={rowSelection}
            data={data}
            columns={columns}
          />
        </Box>

        <Grid item xs={12} mt={2} display="flex" justifyContent="center">
          <Button variant="outlined" color="primary" onClick={handleBackClick} className="button-lable">
            Cancel
          </Button>
          <Button
            variant="contained"
            className="btn-colr"
            style={{ marginLeft: "10px" }}
          >
            save
          </Button>
        </Grid>
      </form>
    </Box>
  );
};

export default SurveyForm;
