import React from 'react';
import { Box, TextField, FormControl, Select, MenuItem, Button, Grid, Checkbox, InputLabel } from '@mui/material';


interface EditTCSFormProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
}

const TCS_EditForm: React.FC<EditTCSFormProps> = ({ onBack }) => {
    const handleBackClick = (event: React.MouseEvent<unknown>) => {
        onBack(event); // Pass the event argument to onBack function
    };

    return (
        <>
            <Box className='card-form'>
                <form noValidate>
                    <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
                        <Grid item xs={12} sm={4} md={3}>
                            <InputLabel size="normal"  className='fnt-lable'>Code</InputLabel>
                            <TextField placeholder="Code" className='Txt-box' />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>

                            <InputLabel size="normal" className='fnt-lable' >Name</InputLabel>
                            <TextField placeholder="Name" className='Txt-box' />
                        </Grid>

                        <Grid item xs={12} mt={2} display="flex" justifyContent="center">
                            <Button variant="outlined" className='button-lable' color="primary" onClick={handleBackClick} >
                                Cancel
                            </Button>
                            <Button variant="contained" className='btn-colr' style={{ marginLeft: "10px" }}>
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </>
    );
}
export default TCS_EditForm