import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  Button,
  Drawer,
  Grid,
  Snackbar,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import type { TableColumnsType } from "antd";
import { Table, Tooltip } from "antd";
import moment from "moment";
import { ArrowBackIos } from "@mui/icons-material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../hooks/useFetch";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import CheckRingLight from "../../../../../../assets/images/icons/Check_ring_lightLin.png";
import view_line from "../../../../../../assets/images/icons/view_line.png";
import ImageModal from "../../PendingSalesReturn/ImageModal";
import { saveWithInvoiceReturn } from "../../../../../../Redux/XDM/Sales/salesReturnReducer";

interface ReviewOrderDetailsProps {
  onClose: () => void;
  requestData: any;
  invoiceRequestData: any;
  requestDataInvoice: any;
}

interface ReviewOrderDetails {
  productCode: string;
  productName: string;
  batchCode: string;
  expiryDate: string;
  quantity: string;
  uom: string;
  mrp: number;
  netAmount: number;
  reasonName: string;
  images: [];
  qty: number;
}

export default function WithInvoiceConfirmReview(
  props: ReviewOrderDetailsProps
) {
  const navigate = useNavigate();
  const { onClose, requestData, requestDataInvoice } = props; // Destructure the props
  const dispatch = useAppDispatch();
  const [hasMore, setHasMore] = useState(true);

  const [saleable, setSaleable] = useState(0);
  const [nonSaleable, setNonSaleable] = useState(0);
  const [totalApprovedQty, setTotalApprovedQty] = useState(0);
  const [totalApprovedValue, setTotalApprovedValue] = useState(0);
  const [openImage, setOpenImage] = React.useState(false);
  const [imageList, setImageList] = React.useState<string[]>([]);
  const handleImageOpen = (record: any) => {
    setImageList(record?.images); // Set the image list from the record
    setOpenImage(true);
  };

  let salesreturnData = useAppSelector((state) => state.salesReturnData);

  const [dataRecordOrders, setDataRecordOrders] = useState<
    ReviewOrderDetails[]
  >(requestData.returnDetails);

  const tableHeightRef = useRef(160);
  const tableHeightloadRef = useRef(400);
  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 353;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const tableHeightLoad = () => {
    const headerHeight = 0;
    const footerHeoght = 230;
    const availableHeight = window.innerHeight - headerHeight - footerHeoght;
    tableHeightloadRef.current = availableHeight;
  };

  useEffect(() => {
    updateTableHeight();
    tableHeightLoad();
    const data = requestData.returnDetails;

    if (data && data.length > 0) {
      const totalQty = data.reduce(
        (acc: number, item: any) => acc + parseInt(item.qty),
        0
      );
      const totalValue = data.reduce((acc: number, item: any) => {
        let amount = item.netAmount / parseInt(item.quantity);
        let total = item.qty * amount;
        return acc + total;
      }, 0);

      setTotalApprovedValue(totalValue);
      setTotalApprovedQty(totalQty);
    }
  }, [requestData.returnDetails]);

  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [proceedSuccess, setProceedSuccess] = useState(false);

  const reviewOrderColumns: TableColumnsType<ReviewOrderDetails> = [
    {
      dataIndex: "productName",
      title: "Product Name",
      width: "10%",

      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "--"}</div>
          </Tooltip>
        );
      },
    },

    {
      dataIndex: "productCode",
      title: "Product Code",
      width: "7%",

      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "--"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "uom",
      title: "UOM",
      width: "4%",
      align: "center",
    },
    {
      dataIndex: "quantity",
      title: "Invoice Quantity",
      width: "6%",
      align: "center",
    },
    {
      dataIndex: "mrp",
      title: "MRP / PC",
      width: "5%",
      align: "right",

      render: (text: number, record: ReviewOrderDetails) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>₹{text ? text.toFixed(2) : "0.00"}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "netAmount",
      title: "Net Amount",
      width: "5%",
      align: "right",

      render: (text: number, record: ReviewOrderDetails) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>₹{text ? text.toFixed(2) : "0.00"}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "expiryDate",
      title: "Expiry Date",
      width: "5%",
      align: "center",
      render: (_: any, record: ReviewOrderDetails) => {
        if (record.expiryDate) {
          const dateObj = moment(record.expiryDate);
          const formattedDate = dateObj.format("DD MMM YYYY");
          return <div>{formattedDate}</div>;
        }
        return "-";
      },
    },
    {
      dataIndex: "images",
      title: "Image",
      align: "center",
      width: "3%",

      render: (_: any, record: ReviewOrderDetails, index: number) => {
        return (
          <Grid
            container
            sx={{
              justifyContent: "center",
              pointerEvents: "auto",
            }}
          >
            {record?.images?.length > 0 ? (
              <Grid item onClick={() => handleImageOpen(record)}>
                <img
                  src={view_line}
                  alt="view-line"
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
            ) : (
              <>--</>
            )}
          </Grid>
        );
      },
    },
    {
      dataIndex: "reasonName",
      title: "Reason",
      width: "5%",
      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "--"}</div>
          </Tooltip>
        );
      },
    },

    {
      dataIndex: "qty",
      title: "Return Quantity",
      align: "center",
      width: "7%",
    },
    {
      dataIndex: "",
      title: "Return Value",
      width: "7%",
      align: "right",
      render: (_: any, record: ReviewOrderDetails) => {
        let quantity = record.quantity;
        let netAmount = record.netAmount;
        let amount = netAmount / parseInt(quantity);
        let total = record.qty * amount;
        return <span>₹{total ? total.toFixed(2) : "0"}</span>;
      },
    },
  ];

  const dataToShow: ReviewOrderDetails[] =
    dataRecordOrders.length > 0
      ? hasMore
        ? dataRecordOrders
        : [...dataRecordOrders]
      : [];

  const handleCancelAccept = (record: any) => {
    onClose();
    setConfirmModalShow(false);
  };
  const handleAcceptConfirm = (record: any) => {
    setConfirmModalShow(true);
  };
  const handleCancel = () => {
    setConfirmModalShow(false);
  };
  const handleConfirm = () => {
    setLoadingModal(true);
    const formatExpiryDate = (dateObj: any) => {
      return new Date(dateObj.$d).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };

    const formData = new FormData();

    // Append the main fields
    formData.append("returnType", requestData.returnType); // "With Invoice"
    formData.append("invoiceNumber", requestData.invoiceNumber);
    formData.append("orderNumber", requestData.orderNumber);
    formData.append("outletCode", requestData.outletCode);
    formData.append("routeCode", requestData.routeCode);

    // Process return details and append to FormData
    requestData.returnDetails.forEach((item: any, index: number) => {
      formData.append(`returnDetails[${index}].qtyReturned`, item.qty);
      formData.append(`returnDetails[${index}].batchCode`, item.batchCode);
      formData.append(`returnDetails[${index}].returnReason`, item.reasonName);
      formData.append(
        `returnDetails[${index}].expiryDate`,
        formatExpiryDate(item.expiryDate)
      );
      formData.append(`returnDetails[${index}].reasonType`, item.type);
      formData.append(`returnDetails[${index}].originalQty`, item.quantity);
      // formData.append(`returnDetails[${index}].images`, item.images);

      // Append each image file (make sure item.image contains File objects)
      if (item.images && Array.isArray(item.images)) {
        item.images.forEach((img: File) => {
    
          formData.append(`returnDetails[${index}].image`, img);
        });
      } else if (item.images instanceof File) {
        formData.append(`returnDetails[${index}].image`, item.images);
      }
    });

    const abortController = new AbortController();
    dispatch(
      saveWithInvoiceReturn({
        formData,
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          setSaleable(response.payload.saleable);
          setNonSaleable(response.payload.nonSaleable);
          setTimeout(() => {
            setLoadingModal(false);
            setProceedSuccess(true);
          }, 2000);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
      });
  };
  const handleBackSales = () => {
    navigate("/xdm/sales/sales-return");
  };

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer anchor="right" open={true} className="drawer_outlet w-100px">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Grid item sx={{ width: "100%" }}>
            <Grid
              container
              sx={{ borderBottom: "1px solid #0000000D", padding: "12px 24px" }}
            >
              <Grid
                item
                sm={0.3}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ArrowBackIos
                  sx={{
                    fontSize: "10px",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    onClose();
                  }}
                />
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#000000",
                      fontFamily: "Montserrat",
                      fontSize: "13px",
                    }}
                  >
                    Sales Return Summary with Invoice for{" "}
                    {requestData?.outletName} / {requestData?.outletCode} (
                    {requestDataInvoice})
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form saleable-products-box"
          >
            {" "}
            <Grid
              item
              sx={{
                width: "100%",
                margin: "auto",
                marginBottom: "90px",
                padding: "0px 24px",
              }}
            >
              <Grid container mt={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="pad-top-0px"
                  sx={{ height: tableHeightloadRef.current }}
                >
                  <div className="saleable-table-scroll">
                    <Table
                      className={`Header-table saleable-table sale_last_hide scroll_cus_table  pending_grn ${
                        salesreturnData.loading ? " nodataloader" : ""
                      }`}
                      columns={reviewOrderColumns}
                      dataSource={dataToShow}
                      pagination={false}
                      scroll={{ y: tableHeightRef.current }}
                      showSorterTooltip={false}
                      summary={() => {
                        if (!hasMore && dataRecordOrders.length > 0) {
                          return (
                            <Table.Summary.Row>
                              <Table.Summary.Cell
                                index={1}
                                colSpan={14}
                                align="center"
                                className="noMore"
                              >
                                No more data found
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        }
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <hr className="hr-revieworder"></hr>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  mt={1.5}
                  sx={{
                    display: "flex",
                    justifyContent: "sapce-between",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Grid item xs={7.4} sm={8} md={9} lg={9.6}>
                    <Grid item>
                      <Typography
                        className="Pending_review_label"
                        sx={{
                          fontWeight: 700,
                          fontSize: "12px",
                          color: "#505259",
                        }}
                      >
                        Total Return Quantity : {totalApprovedQty}
                      </Typography>
                    </Grid>
                    <Grid item mt={0.7}>
                      <Typography
                        className="Pending_review_Sublabel"
                        sx={{
                          fontWeight: 700,
                          fontSize: "12px",
                          color: "#000000",
                        }}
                      >
                        Total Return Value : ₹ {totalApprovedValue.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={2.3} sm={2} md={1.5} lg={1.2}>
                    <Button
                      variant="contained"
                      className="cancel-to-cart-btn"
                      type="submit"
                      onClick={handleCancelAccept}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={2.3} sm={2} md={1.5} lg={1.2}>
                    <Button
                      variant="contained"
                      className="next_btn_inv"
                      type="submit"
                      onClick={handleAcceptConfirm}
                    >
                      Confirm
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Dialog
          open={confirmModalShow}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {loadingModal ? (
            <DialogContent
              sx={{
                width: "308px",
                padding: "0px 16px",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <div className="loader"></div>
                <Typography
                  sx={{
                    marginTop: "20px",
                    color: "#344054",
                    fontSize: "16px",
                    fontWeight: 500,
                    fontFamily: "var(--Fontfamilyfont-family-body)",
                    lineHeight: "var(--Lineheighttext-lg)",
                  }}
                >
                  Loading...
                </Typography>
              </Box>
            </DialogContent>
          ) : (
            <>
              {proceedSuccess ? (
                <DialogContent sx={{ width: "308px", padding: "0px 16px" }}>
                  <Box
                    className="close_icon_btn cursor_pointer"
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                    onClick={handleBackSales}
                  >
                    <IoClose className="ClosePop" />
                  </Box>
                  <DialogContentText
                    className="dialog-title-remove"
                    id="alert-dialog-description"
                  >
                    {[
                      { count: saleable, text: "Saleable" },
                      { count: nonSaleable, text: "Non-Saleable" },
                    ].map(
                      (item, index) =>
                        item.count > 0 && (
                          <Grid
                            container
                            alignItems="center"
                            key={index}
                            mt={1}
                          >
                            <Grid item xs={2}>
                              <img
                                src={CheckRingLight}
                                alt="Check Ring Light"
                                style={{
                                  width: "31.5px",
                                  height: "30.5px",
                                  marginTop: "6.25px",
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={10}
                              sx={{
                                fontFamily: "Montserrat",
                                fontSize: "12px",
                              }}
                            >
                              <span
                                style={{ color: "#000000", fontWeight: 900 }}
                              >
                                {item.count}{" "}
                                {item.count === 1 ? "Item" : "Items"}
                              </span>{" "}
                              <span style={{ color: "#858585" }}>
                                updated successfully in the{" "}
                              </span>
                              <b>{item.text} Inventory</b>.
                            </Grid>
                          </Grid>
                        )
                    )}
                  </DialogContentText>
                </DialogContent>
              ) : (
                <DialogContent sx={{ width: "308px", padding: "0px 16px" }}>
                  <Box
                    className="close_icon_btn cursor_pointer"
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                    onClick={handleCancel}
                  >
                    <IoClose className="ClosePop" />
                  </Box>
                  <DialogContentText
                    className="dialog-title-remove"
                    id="alert-dialog-description"
                    sx={{ paddingBottom: "10px" }}
                  >
                    Are you sure you want to confirm this sales return?
                  </DialogContentText>
                  <Grid
                    container
                    gap={0.5}
                    sx={{ paddingBottom: "10px", fontSize: "12px" }}
                  >
                    <b>{dataRecordOrders.length}</b> items of value{" "}
                    <b> ₹{totalApprovedValue.toFixed(2)}</b>
                  </Grid>
                </DialogContent>
              )}

              <DialogActions>
                {proceedSuccess ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        className="crt-btn-grn"
                        fullWidth
                        style={{
                          height: "30px",
                          fontSize: "12px",
                        }}
                        onClick={handleBackSales}
                      >
                        Back to Sales return
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      className="crt-btn-cancel"
                      style={{
                        height: "28px",
                        width: "23%",
                        marginRight: "5px",
                        fontSize: "10px",
                      }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      className="crt-btn-colr"
                      style={{
                        height: "28px",
                        width: "23%",
                        fontSize: "10px",
                        marginRight: "5px",
                      }}
                      onClick={handleConfirm}
                    >
                      Proceed
                    </Button>
                  </>
                )}
              </DialogActions>
            </>
          )}
        </Dialog>
      </Drawer>
      {openImage ? (
        <ImageModal
          open={openImage}
          handleImageClose={() => setOpenImage(false)}
          imageList={imageList}
        />
      ) : (
        <></>
      )}
    </Box>
  );
}
