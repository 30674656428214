
import * as React from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,

} from '@mui/material';
import type { TableColumnsType } from 'antd';
import SearchBar from '@mkyy/mui-search-bar';
import AddIcon from "@mui/icons-material/Add";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import VisibilityIcon from "@mui/icons-material/Visibility";

import Modal from '@mui/material/Modal';
import { Close, Padding } from '@mui/icons-material';

import CommonTable from '../../components/Tables/CommonTable';
import InventoryExcel from './ExcelForm';
import InventoryForm from './InventoryForm';
import InventoryFormView from './InventoryFormView';



interface DataType {
  key: React.Key;
  code: number;
  reference: string;
  date: string;
  godown: string;
  stock: string;
  transaction: string;
  approval:string;
  rejected:string;
  action:string;
}

const Inventory = () => {


  const [showForm, setShowForm] = React.useState(false);
  const [excelForm, setExcelForm] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState('manual');
  const [isViewing, setIsViewing] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleViewClick = (event: React.MouseEvent<unknown>) => {
    setIsViewing(!isViewing);
  };

  const columns: TableColumnsType<DataType> = [

    {
      dataIndex: 'code',
      title: 'Distributor Code',
    },

    {
      dataIndex: 'reference',
      title: 'Reference No.',
      sorter: (a, b) => a.reference.length - b.reference.length,
    },

    {
      dataIndex: 'date',
      title: 'Doc.Date',
      sorter: (a, b) => a.date.length - b.date.length,
    },

    {
      dataIndex: 'godown',
      title: 'Godown',
      sorter: (a, b) => a.godown.length - b.godown.length,
    },

    {
      dataIndex: 'stock',
      title: 'Stock Status ',
      sorter: (a, b) => a.stock.length - b.stock.length,
    },

    {
      dataIndex: 'transaction',
      title: 'Transaction Type ',
      sorter: (a, b) => a.transaction.length - b.transaction.length,
    },
    {
      dataIndex: 'approval',
      title: 'Approval Status',
      sorter: (a, b) => a.approval.length - b.approval.length,
    },
    {
      dataIndex: 'rejected',
      title: 'Rejected Reason ',
      sorter: (a, b) => a.rejected.length - b.rejected.length,
    },
    {
      dataIndex: 'action',
      title: 'Action ',
      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            onClick={(event) => handleViewClick(event)}

            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />

        </>
      ),
    },

  ];

  const data: DataType[] = [
    { key: 1, code: 55, reference: "001", date:'01/03/2024',godown: 'Supplier1', stock: '', transaction: 'Supplier1', approval:'',rejected:'',action: '', },
    { key: 2, code: 55445, reference: "002", date:'01/03/2024',godown: 'Supplier2', stock: '', transaction: 'Supplier1', approval:'',rejected:'',action: '', },
    { key: 3, code: 5565, reference: "003", date:'01/03/2024',godown: 'Supplier3', stock: '', transaction: 'Supplier1', approval:'',rejected:'',action: '', },
    { key: 4, code: 5548, reference: "004", date:'01/03/2024',godown: 'Supplier4', stock: '', transaction: 'Supplier1', approval:'',rejected:'',action: '', },
    { key: 5, code: 55121, reference: "005", date:'01/03/2024',godown: 'Supplier5', stock: '', transaction: 'Supplier1', approval:'',rejected:'',action: '', },
    { key: 6, code: 5565, reference: "006", date:'01/03/2024',godown: 'Supplier6', stock: '', transaction: 'Supplier1', approval:'',rejected:'',action: '', },
    { key: 7, code: 55789, reference: "007", date:'01/03/2024',godown: 'Supplier7', stock: '', transaction: 'Supplier1', approval:'',rejected:'',action: '', },
    { key: 8, code: 55656, reference: "008", date:'01/03/2024',godown: 'Supplier8', stock: '', transaction: 'Supplier1', approval:'',rejected:'',action: '', },
  ];

  const handleCreateNew = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleGoChange = () => {

    if (selectedOption == "manual") {
      setIsModalOpen(!isModalOpen);
      setShowForm(true);
    }
    else if (selectedOption == "excel") {
      setIsModalOpen(!isModalOpen);
      setExcelForm(true)
    }

  };
  const handleShowClose = () => {
    setShowForm(false);
  };
  const handleExcelClose = () => {
    setExcelForm(false)
  };

  const handleBackClick = () => {
    setIsViewing(false)
  };


  const RadiohandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };


  return (
    <>
      <Box sx={{ marginTop: '2px' }}>


        <Grid item xs={12} className=' mar-tb' >
          <Typography className='header-lable' >
            <b>Inventory</b></Typography>
        </Grid>

        {showForm ? <InventoryForm onBack={handleShowClose} /> :
        isViewing?<InventoryFormView onBack={handleBackClick}  />:
          excelForm ? <InventoryExcel onBack={handleExcelClose} /> :
            <Box>
              <Button variant="contained" className='btn-colr' onClick={handleCreateNew}> <AddIcon /> Create new</Button>
              <Grid item container sx={{ marginTop: "16px" }}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', mb: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item>
                      <DatePicker name="startDate" />
                    </Grid>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid item sx={{ mt: 2 }}>
                <Box >
                  <CommonTable 
                  // rowSelection={rowSelection} 
                  data={data} columns={columns} />
                </Box>
              </Grid>
            </Box>

        }
        <Modal
          open={isModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid>Choose Entry Mode</Grid>
            <Box sx={{ flex: 'none', marginBottom: 1 }}>
              <IconButton
                aria-label="close"
                style={{ position: 'absolute', top: 10, right: 10 }}
                onClick={handleCreateNew}
              >
                <Close />
              </IconButton>
            </Box>
            <Divider />
            <Grid item sx={{ mt: 2 }}>
              <RadioGroup
                row
                aria-label="mandatory"
                name="mandatory"
                value={selectedOption}
                onChange={RadiohandleChange}
              >
                <FormControlLabel value="manual" control={<Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 18,
                      color: "#6750A4",
                    },
                  }}
                />} label="Manual Entry" />
                <FormControlLabel value="excel" control={<Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 18,
                      color: "#6750A4",
                    },
                  }}
                />} label="Excel Entry" />
              </RadioGroup>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>

              <Button variant="contained" className='btn-colr' onClick={handleGoChange}>
                Go
              </Button>
            </Box>
          </Box>
        </Modal>

      </Box>
    </>
  )

}


export default Inventory;
