import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button, Drawer, Grid, TextField, Typography } from "@mui/material";
import {
  ProductUomFormValues,
  FormErrors,
} from "../../../../typescript/ProductUom";
//icons
import Close from "../../../../assets/images/icons/Close_icon.png";
import { useAppDispatch } from "../../../../hooks/useFetch";

import {
  ProductUomSubmitForm,
  ProductUomFetchData,
} from "../../../../Redux/Masters/Uom/UseReducer";

import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface RequestData {
  page: number;
  size: number;
}
interface MasterCompanyCreateProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
}
export default function MasterCompanyCreate(props: MasterCompanyCreateProps) {
  const dispatch = useAppDispatch();
  const { onClose, requestData } = props; // Destructure the props

  const [FormError, setFormError] = useState<boolean>(false);
  const [sucessMessage, setSucessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [errors, setErrors] = useState<FormErrors>({
    uomCode: "",
    uomName: "",
  });

  const [formData, setFormData] = useState<ProductUomFormValues>({
    uomCode: "",
    uomName: "",
  });

  const validateFormData = (data: ProductUomFormValues): FormErrors => {
    const errors: FormErrors = {};
    const fieldsToValidate: (keyof ProductUomFormValues)[] = [
      "uomCode",
      "uomName",
    ];

    fieldsToValidate.forEach((field) => {
      if (!data[field]) {
        errors[field] = `${field} is required`;
      }
    });
    return errors;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    formData: ProductUomFormValues
  ): Promise<void> => {
    event.preventDefault();
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await dispatch(ProductUomSubmitForm(formData));
        // const successMessage = response.data.message;
        // console.log(successMessage,'successMessagesuccessMessagesuccessMessage');
        
        setFormError(true);
        // setSucessMessage(successMessage);
        dispatch(ProductUomFetchData(requestData));
        setTimeout(() => onClose(), 2000);
      } catch (error: any) {
        if (error.response.data.data) {
          const errorMessage = error.response.data.data;
          setErrors(errorMessage);
        } else {
          setErrorMessage("something went Wrong");
        }
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_stl"
        // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 533,
              md: 536,
              sm: 600,
              xs: 400,
            },
            // display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "95%", margin: "auto" }} role="presentation">
            <Grid container className="crt-header" mt={4}>
              <Typography gutterBottom className="crt-header-lable">
                Create UOM Master
              </Typography>

              <Grid item>
                <img
                  src={Close}
                  alt={Close}
                  // onClick={onClose}
                  onClick={() => {
                    onClose();
                  }}
                  style={{ width: "24px", height: "24px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Content */}
          <Box
            mt={3}
            sx={{
              // flexGrow: 1,
              overflowY: "auto",
              marginBottom: "70px",
            }}
            className="create-form"
          >
            {/* Your scrollable content */}
            <form onSubmit={(event) => handleSubmit(event, formData)}>
              <Grid
                item
                sx={{ width: "95%", margin: "auto", marginBottom: "70px" }}
              >
                <Grid item>
                  <Typography className="crt-fnt-lable">UOM CODE</Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Enter Code"
                      error={errors.uomCode ? true : false}
                      helperText={errors.uomCode && errors.uomCode}
                      name={"uomCode"}
                      value={formData.uomCode}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">UOM NAME</Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Enter Name"
                      name={"uomName"}
                      value={formData.uomName}
                      onChange={handleChange}
                      error={errors.uomName ? true : false}
                      helperText={errors.uomName && errors.uomName}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item mt={2} className="product_Create_footer">
                <Button className="crt-btn-cancel" onClick={onClose}>
                  {" "}
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="crt-btn-colr"
                  type="submit"
                  sx={{ mr: 4 }}
                >
                  {" "}
                  Create
                </Button>
              </Grid>
            </form>

            {/* More content... */}
            {FormError ? (
              sucessMessage ? (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="success">{sucessMessage}</Alert>
                </Snackbar>
              ) : (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="error">{errorMessage}</Alert>
                </Snackbar>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
