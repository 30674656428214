import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Grid,
  Typography,
  CircularProgress,
  Skeleton,
} from "@mui/material";

import type { TableColumnsType } from "antd";
import { styled } from "@mui/material/styles";
import { Col, Row } from "antd";
import Switch from "@mui/material/Switch";

import CommonTable from "../../../../components/Tables/CommonTable";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useFetch";
import {
  ProductUomFetchData,
  productUomActiveStatus,
  productUomEdit,
} from "../../../../Redux/Masters/Uom/action";
//icons
import Addicon from "../../../../assets/images/icons/add.png";
import Rightdot from "../../../../assets/images/icons/Right_dot.png";
import MasterCompanyCreate from "./MasterCreateProductUOM";
import MasterProductEdit from "./MasterProductEditUOM";
import InfiniteScroll from "react-infinite-scroll-component";
import TableSkeletonLoader from "../../../../components/Skeleton/skeleton";
import Checkbox from "@mui/material/Checkbox";
interface DataType {
  key: React.Key;
  Sno: string;
  id: string;
  umocode: string;
  uomname: string;
  status: string;
}

export default function MasterProductUOM() {
  const dispatch = useAppDispatch();
  const renderAfterCalled = useRef(false);

  const [isEditing, setIsEditing] = React.useState(false);
  const [isViewing, setIsViewing] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [open, setOpen] = React.useState(false);
  const [editingData, setEditingData] = React.useState<DataType | null>(null);
  const [isShow, setIsShow] = React.useState(false);
  const [checkboxStatus, setCheckboxStatus] = useState<{ [key: string]: any }>(
    {}
  );
  const [requestData, setRequestData] = useState({ page: 0, size: 10 });
  const [prevData, setPrevData] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [skeleton, setskeleton] = useState(true);

  const handleViewClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setIsViewing(!isViewing);
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleEditClick = (rowData: DataType) => {
    setIsEditing(!isEditing);
    toggleDrawer(true);
    setEditingData(rowData); // Set editingData when edit button is clicked
    dispatch(productUomEdit(rowData.id));
  };

  const handleShowClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
    setIsShow(!isShow);
    toggleDrawer(true);
  };

  const ProductUomFetchDatas = useAppSelector(
    (state: any) => state.productUomFetch.ProductUomFetch
  );
  const ProductLength = ProductUomFetchDatas?.length;
  useEffect(() => {
    if (prevData) {
      const initialCheckboxStatus: { [key: string]: any } = {};
      prevData.forEach((record: any) => {
        initialCheckboxStatus[record.id] = record.status;
      });
      setCheckboxStatus(initialCheckboxStatus);
    }
  }, [prevData]);

  useEffect(() => {
    if (Array.isArray(ProductUomFetchDatas)) {
      if (requestData.page === 0) {
        setPrevData(ProductUomFetchDatas);
        setskeleton(false);
      } else {
        setPrevData((prevData) => [...prevData, ...ProductUomFetchDatas]);
        setskeleton(false);
      }
    } else {
      console.error(
        "ProductUomFetchDatas is not an array:",
        ProductUomFetchDatas
      );
      setskeleton(false);
    }
  }, [ProductUomFetchDatas]);

  const fetchMoreData = () => {
    const nextPage = requestData.page + 1;
    const nextSize = requestData.size;
    const updatedRequestData = {
      ...requestData,
      page: nextPage,
      size: nextSize,
    };
    setRequestData(updatedRequestData);
    dispatch(ProductUomFetchData(updatedRequestData));
    if (prevData?.length < nextSize) {
      setHasMore(false);
    }
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      dispatch(ProductUomFetchData(requestData));
    }
    return () => {
      renderAfterCalled.current = true;
    };
  }, [dispatch, requestData]);

  //CheckBox Active in Active
  const handleChange = (isChecked: any, id: string) => {
    const check = isChecked.toString();
    prevData.forEach((record: any) => {
      if (record.id === id) {
        dispatch(
          productUomActiveStatus({
            id: record.id.toString(),
            status: isChecked,
          })
        );
        setCheckboxStatus((prevState) => ({
          ...prevState,
          [id]: check,
        }));
      }
    });
  };

  //AntSwitch
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    // display: 'flex',
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          background: "linear-gradient(to right, #614FB3, #944986)",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      // transition: theme.transitions.create(['width'], {
      //   duration: 200,
      // }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "index",
      title: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox className="Checkbox_Gst" />
          <span>S.No</span>
        </div>
      ),
      render: (_: any, __: DataType, index: number) => (
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            paddingRight: "10px",
          }}
        >
          <Col>
            <Checkbox
              className="Checkbox_Gst"
              onClick={(e) => e.stopPropagation()}
            />
          </Col>

          <Col>
            <span className="sno_lable">{index + 1}</span>
          </Col>
        </Row>
      ),
    },

    {
      dataIndex: "uomCode",
      title: " UOM CODE ",
      // sorter: (a, b) => a.umocode.length - b.umocode.length,
    },
    {
      dataIndex: "uomName",
      title: " UOM NAME ",
      // sorter: (a, b) => a.uomname.length - b.uomname.length,
    },
    // {
    //   dataIndex: "status",
    //   title: "Status",
    //   render: (_: any, record: DataType, index: number) => (
    //     <Row>
    //       <Col>
    //         <AntSwitch
    //           checked={checkboxStatus[record.id] === "true"}
    //           onClick={(e) => e.stopPropagation()}
    //           onChange={(event) =>
    //             handleChange(event.target.checked, record.id)
    //           }
    //           inputProps={{ "aria-label": "ant design" }}
    //         />
    //       </Col>
    //       <Col>
    //         <Grid item style={{ marginLeft: "20px" }}>
    //           {checkboxStatus[record.id] === "true" ? (
    //             <Typography>active</Typography>
    //           ) : (
    //             <Typography>Inactive</Typography>
    //           )}
    //         </Grid>
    //       </Col>
    //     </Row>
    //   ),
    // },
  ];

  return (
    <>
      {skeleton ? (
        <Box
          sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}
        >
          <Grid container>
            <Grid item xs={6} sm={6} md={6} className="mar-tb">
              <Skeleton
                variant="text"
                sx={{ fontWeight: "700", fontSize: "16px" }}
              />
            </Grid>
            <Grid
              container
              xs={6}
              sm={6}
              md={6}
              className="mar-tb"
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Grid item sx={{ display: "flex" }}>
                <Button className="mastercrt-button">
                  <Skeleton variant="rectangular" />
                </Button>
                <Grid item>
                  <Skeleton variant="rectangular" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box id="TableScroll">
            <Grid item sx={{ width: "50%", margin: "auto" }}>
              <TableSkeletonLoader rowCount={ProductLength} columns={4} />
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 2, sm: 3 } }}
        >
          <Grid container>
            <Grid
              item
              xs={6}
              //  className="mar-tb"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography>
                <b className="header-lable">UOM Master</b>
              </Typography>
            </Grid>
            <Grid
              container
              xs={6}
              sm={6}
              md={6}
              // className="mar-tb"
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <Button className="mastercrt-button" onClick={handleShowClick}>
                  <img src={Addicon} alt={Addicon} />
                  <span className="Crt-lable"> Create New</span>
                </Button>
                {/* <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={Rightdot}
                    alt={Rightdot}
                    style={{ width: "42px", height: "42px" }}
                  />
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <Box id="TableScroll">
            {/* <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "end", mb: 2 }}
        >
          <SearchBar
            className="search-table"
            value={textFieldValue}
            onChange={(newValue) => {
              setTextFieldValue(newValue);
              handleSearch();
            }}
          />
        </Grid> */}
            <Grid
              item
              sx={{ width: { sm: "70%", md: "40%" }, margin: "auto", mt: 3 }}
              className="table_uom"
            >
              <InfiniteScroll
                dataLength={ProductUomFetchDatas?.length ?? 0}
                next={fetchMoreData}
                hasMore={hasMore}
                scrollableTarget="TableScroll"
                className=""
                loader={
                  <h6
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <CircularProgress
                      style={{
                        color: "#119BFF",
                      }}
                      size={20}
                    />
                  </h6>
                }
                // endMessage={
                //   ProductUomFetchDatas?.length !== 0 && (
                //     <p className="fs-12 noMore">
                //       <b>No more data found...</b>
                //     </p>
                //   )
                // }
              >
                <CommonTable
                  // rowSelection={rowSelection}
                  data={prevData ? prevData : ProductUomFetchDatas}
                  columns={columns}
                  onRow={(record) => ({
                    onClick: () => handleEditClick(record),
                  })}
                />
              </InfiniteScroll>
            </Grid>
          </Box>

          {isShow ? (
            <MasterCompanyCreate
              onClose={() => setIsShow(false)}
              requestData={requestData}
            />
          ) : // Update
          isEditing ? (
            <MasterProductEdit
              onClose={() => setIsEditing(false)}
              requestData={requestData}
            />
          ) : (
            <></>
          )}
        </Box>
      )}
    </>
  );
}
