import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import createAxiosInstance from "../../../hooks/useFetch";

interface InitialState {
  stocks: any;
  statusGrn: any;
  salesman: any;
  routes: any;
  date: any;
  dateSelected: any;
  fromDate: any;
  fromDateSelected: any;
  toDate: any;
  toDateSelected: any;
  outlets: any;
  stockSelected: any;
  statusGrnSelected: any;
  invoiceType: any;
  invoiceTypeSelected: any;
  salesmanSelected: any;
  paymentModeSelected: any;
  paymentModeSelectedPop: any;
  routeSelected: any;
  outletSelected: any;
  salesmanSelectedPop: any;
  routeSelectedPop: any;
  outletSelectedPop: any;
  salesmanMore: any;
  routeMore: any;
  outletMore: any;
  tempSalesmanSelected: any;
  tempInvoiceTypeSelected: any;
  tempPaymentModeSelected: any;
  tempRouteSelected: any;
  tempOutletSelected: any;
  filterPop: boolean;
  error: any;
  loading: boolean;
  loadingMore: boolean;
}

interface FilterDataRequest {
  page: number;
  size: number;
  pageStatus: boolean;
}
interface FilterDataRequestUpdated {
  page: number;
  size: number;
  pageStatus: boolean;
  status: string;
}

const axiosInstance = createAxiosInstance();

export const getSalesman = createAsyncThunk(
  "filter/salesman",
  async (req: FilterDataRequestUpdated, val) => {
    try {
      const response = await axiosInstance.get("sales/salesman", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getSalesmanInvoice = createAsyncThunk(
  "filter/salesman-invoice",
  async (req: FilterDataRequest, val) => {
    try {
      const response = await axiosInstance.get("invoice/salesman", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getSalesmanReturn = createAsyncThunk(
  "filter/salesman-salesreturn",
  async (req: FilterDataRequestUpdated, val) => {
    try {
      const response = await axiosInstance.get("sales-return/get-salesman", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getSalesmanReturnMore = createAsyncThunk(
  "filter/salesman-salesreturnMore",
  async (req: FilterDataRequestUpdated) => {
    try {
      const response = await axiosInstance.get("sales-return/get-salesman", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getSalesmanInvoiceMore = createAsyncThunk(
  "filter/salesmanMore-invoice",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get("invoice/salesman", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getRoute = createAsyncThunk(
  "filter/route",
  async (req: FilterDataRequestUpdated) => {
    try {
      const response = await axiosInstance.get("sales/routes", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getRouteInvoice = createAsyncThunk(
  "filter/route-invoice",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get("invoice/routes", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getRouteMoreInvoice = createAsyncThunk(
  "filter/routeMoreInvoice",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get("invoice/routes", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getOutlet = createAsyncThunk(
  "filter/outlet",
  async (req: FilterDataRequestUpdated) => {
    try {
      const response = await axiosInstance.get("sales/outlet", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getOutletInvoice = createAsyncThunk(
  "filter/outletinvoice",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get("invoice/outlet", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getOutletReturn = createAsyncThunk(
  "filter/outlet-salesReturn",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get(
        "sales-return/get-outlet-details",
        {
          params: req,
        }
      );
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getOutletMoreInvoice = createAsyncThunk(
  "filter/outletMoreInvoice",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get("invoice/outlet", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);
export const getOutletMoreReturn = createAsyncThunk(
  "filter/outletMore-return",
  async (req: FilterDataRequest) => {
    try {
      const response = await axiosInstance.get(
        "sales-return/get-outlet-details",
        {
          params: req,
        }
      );
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getSalesmanMore = createAsyncThunk(
  "filter/salesmanMore",
  async (req: FilterDataRequestUpdated) => {
    try {
      const response = await axiosInstance.get("sales/salesman", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getRouteMore = createAsyncThunk(
  "filter/routeMore",
  async (req: FilterDataRequestUpdated) => {
    try {
      const response = await axiosInstance.get("sales/routes", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const getOutletMore = createAsyncThunk(
  "filter/outletMore",
  async (req: FilterDataRequestUpdated) => {
    try {
      const response = await axiosInstance.get("sales/outlet", {
        params: req,
      });
      return response;
    } catch (error) {
      throw Error("Failed to fetch data");
    }
  }
);

export const updateStocks = createAsyncThunk(
  "filter/updateStocks",
  async (req: string[]) => {
    return req;
  }
);
export const updateStatusGrn = createAsyncThunk(
  "filter/updateStatusGrn",
  async (req: string[]) => {
    return req;
  }
);
export const updateInvoiceType = createAsyncThunk(
  "filter/updateInvoiceType",
  async (req: string[]) => {
    return req;
  }
);

export const updateSalesman = createAsyncThunk(
  "filter/updateSalesman",
  async (req: string[]) => {
    return req;
  }
);

export const updateRoute = createAsyncThunk(
  "filter/updateRouten",
  async (req: string[]) => {
    return req;
  }
);

export const updateOutlet = createAsyncThunk(
  "filter/updateOutlet",
  async (req: string[]) => {
    return req;
  }
);

export const updatePaymentMode = createAsyncThunk(
  "filter/updatePaymentMode",
  async (req: string[]) => {
    return req;
  }
);

export const updatePaymentModePop = createAsyncThunk(
  "filter/updatePaymentModePop",
  async (req: string[]) => {
    return req;
  }
);

export const updatePaymentModeTemp = createAsyncThunk(
  "filter/updatePaymentModeTemp",
  async (req: string[]) => {
    return req;
  }
);

export const updateSalesmanPop = createAsyncThunk(
  "filter/updateSalesmanPop",
  async (req: string[]) => {
    return req;
  }
);

export const updateInvoiceTypePop = createAsyncThunk(
  "filter/updateInvoiceTypePop",
  async (req: string[]) => {
    return req;
  }
);

export const updateRoutePop = createAsyncThunk(
  "filter/updateRoutenPop",
  async (req: string[]) => {
    return req;
  }
);

export const updateOutletPop = createAsyncThunk(
  "filter/updateOutletPop",
  async (req: string[]) => {
    return req;
  }
);

export const updateSalesmanTemp = createAsyncThunk(
  "filter/updateSalesmanTemp",
  async (req: string[]) => {
    return req;
  }
);

export const updateInvoiceTypeTemp = createAsyncThunk(
  "filter/updateInoviceTypeTemp",
  async (req: string[]) => {
    return req;
  }
);

export const updateRouteTemp = createAsyncThunk(
  "filter/updateRoutenTemp",
  async (req: string[]) => {
    return req;
  }
);

export const updateOutletTemp = createAsyncThunk(
  "filter/updateOutletTemp",
  async (req: string[]) => {
    return req;
  }
);

export const openFilterModel = createAsyncThunk(
  "filter/openFilterModel",
  async (req: boolean) => {
    return req;
  }
);
export const updateDate = createAsyncThunk(
  "filter/updateDate",
  async (req: any) => {
    return req;
  }
);
export const updateFromDate = createAsyncThunk(
  "filter/updateFromDate",
  async (req: any) => {
    return req;
  }
);
export const updateToDate = createAsyncThunk(
  "filter/updateToDate",
  async (req: any) => {
    return req;
  }
);
const billingSlice = createSlice({
  name: "billing",
  initialState: {
    stocks: [],
    salesman: [],
    routes: [],
    outlets: [],
    stockSelected: [],
    salesmanSelected: [],
    paymentModeSelected: [],
    routeSelected: [],
    outletSelected: [],
    salesmanSelectedPop: [],
    paymentModeSelectedPop: [],
    routeSelectedPop: [],
    outletSelectedPop: [],
    salesmanMore: [],
    routeMore: [],
    outletMore: [],
    tempSalesmanSelected: [],
    tempInvoiceTypeSelected: [],
    tempPaymentModeSelected: [],
    tempRouteSelected: [],
    tempOutletSelected: [],
    statusGrn: [],
    statusGrnSelected: [],
    invoiceType: [],
    invoiceTypeSelected: [],
    date: null,
    dateSelected: null,
    fromDate: null,
    fromDateSelected: null,
    toDate: null,
    toDateSelected: null,
    filterPop: false,
    error: null,
    loading: false,
  } as InitialState,
  reducers: {
    billingStates: (state, action) => {
      state.stocks = action.payload.stocks;
      state.salesman = action.payload.salesman;
      state.routes = action.payload.routes;
      state.outlets = action.payload.outlets;
      state.stockSelected = action.payload.stockSelected;
      state.invoiceTypeSelected = action.payload.invoiceTypeSelected;
      state.invoiceType = action.payload.invoiceType;
      state.salesmanSelected = action.payload.salesmanSelected;
      state.paymentModeSelected = action.payload.paymentModeSelected;
      state.routeSelected = action.payload.routeSelected;
      state.outletSelected = action.payload.outletSelected;
      state.salesmanSelectedPop = action.payload.salesmanSelectedPop;
      state.paymentModeSelectedPop = action.payload.paymentModeSelectedPop;
      state.routeSelectedPop = action.payload.routeSelectedPop;
      state.outletSelectedPop = action.payload.outletSelectedPop;
      state.salesmanMore = action.payload.salesmanMore;
      state.routeMore = action.payload.routeMore;
      state.outletMore = action.payload.outletMore;
      state.tempSalesmanSelected = action.payload.tempSalesmanSelected;
      state.tempPaymentModeSelected = action.payload.tempPaymentModeSelected;
      state.tempRouteSelected = action.payload.tempRouteSelected;
      state.tempOutletSelected = action.payload.tempOutletSelected;
      state.date = action.payload.date;
      state.dateSelected = action.payload.dateSelected;
      state.fromDate = action.payload.fromDate;
      state.fromDateSelected = action.payload.fromDateSelected;
      state.toDate = action.payload.toDate;
      state.toDateSelected = action.payload.toDateSelected;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSalesman.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.salesman = [];
    });
    builder.addCase(getSalesman.fulfilled, (state, action) => {
      state.loading = false;
      state.salesman = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getSalesman.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getSalesmanInvoice.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.salesman = [];
    });
    builder.addCase(getSalesmanInvoice.fulfilled, (state, action) => {
      state.loading = false;
      state.salesman = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getSalesmanInvoice.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getSalesmanReturn.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.salesman = [];
    });
    builder.addCase(getSalesmanReturn.fulfilled, (state, action) => {
      state.loading = false;
      state.salesman = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getSalesmanReturn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getRoute.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.routes = [];
    });
    builder.addCase(getRoute.fulfilled, (state, action) => {
      state.loading = false;
      state.routes = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getRoute.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getRouteInvoice.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.routes = [];
    });
    builder.addCase(getRouteInvoice.fulfilled, (state, action) => {
      state.loading = false;
      state.routes = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getRouteInvoice.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getOutlet.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.outlets = [];
    });
    builder.addCase(getOutlet.fulfilled, (state, action) => {
      state.loading = false;
      state.outlets = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getOutlet.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getOutletReturn.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.outlets = [];
    });
    builder.addCase(getOutletReturn.fulfilled, (state, action) => {
      state.loading = false;
      state.outlets = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getOutletReturn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getOutletInvoice.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.outlets = [];
    });
    builder.addCase(getOutletInvoice.fulfilled, (state, action) => {
      state.loading = false;
      state.outlets = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getOutletInvoice.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateStocks.fulfilled, (state, action) => {
      state.stockSelected = action.payload;
    });
    builder.addCase(updateStatusGrn.fulfilled, (state, action) => {
      state.statusGrnSelected = action.payload;
    });
    builder.addCase(updateInvoiceType.fulfilled, (state, action) => {
      state.invoiceTypeSelected = action.payload;
    });
    builder.addCase(updateSalesman.fulfilled, (state, action) => {
      state.salesmanSelected = action.payload;
    });
    builder.addCase(updatePaymentMode.fulfilled, (state, action) => {
      state.paymentModeSelected = action.payload;
    });
    builder.addCase(updateRoute.fulfilled, (state, action) => {
      state.routeSelected = action.payload;
    });
    builder.addCase(updateOutlet.fulfilled, (state, action) => {
      state.outletSelected = action.payload;
    });
    builder.addCase(updateSalesmanPop.fulfilled, (state, action) => {
      state.salesmanSelected = action.payload;
    });
    builder.addCase(updateInvoiceTypePop.fulfilled, (state, action) => {
      state.invoiceTypeSelected = action.payload;
    });
    builder.addCase(updatePaymentModePop.fulfilled, (state, action) => {
      state.paymentModeSelected = action.payload;
    });
    builder.addCase(updateRoutePop.fulfilled, (state, action) => {
      state.routeSelected = action.payload;
    });
    builder.addCase(updateOutletPop.fulfilled, (state, action) => {
      state.outletSelected = action.payload;
    });
    builder.addCase(openFilterModel.fulfilled, (state, action) => {
      state.filterPop = action.payload;
    });
    builder.addCase(getSalesmanMore.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.salesmanMore = [];
    });
    builder.addCase(getSalesmanMore.fulfilled, (state, action) => {
      state.loadingMore = false;
      state.salesmanMore = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getSalesmanMore.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message;
    });
    builder.addCase(getSalesmanInvoiceMore.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.salesmanMore = [];
    });
    builder.addCase(getSalesmanInvoiceMore.fulfilled, (state, action) => {
      state.loadingMore = false;
      state.salesmanMore = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getSalesmanInvoiceMore.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message;
    });
    builder.addCase(getSalesmanReturnMore.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.salesmanMore = [];
    });
    builder.addCase(getSalesmanReturnMore.fulfilled, (state, action) => {
      state.loadingMore = false;
      state.salesmanMore = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getSalesmanReturnMore.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message;
    });

    builder.addCase(getRouteMore.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.routeMore = [];
    });
    builder.addCase(getRouteMore.fulfilled, (state, action) => {
      state.loadingMore = false;
      state.routeMore = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getRouteMore.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message;
    });
    builder.addCase(getRouteMoreInvoice.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.routeMore = [];
    });
    builder.addCase(getRouteMoreInvoice.fulfilled, (state, action) => {
      state.loadingMore = false;
      state.routeMore = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getRouteMoreInvoice.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message;
    });
    builder.addCase(getOutletMore.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.outletMore = [];
    });
    builder.addCase(getOutletMore.fulfilled, (state, action) => {
      state.loadingMore = false;
      state.outletMore = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getOutletMore.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message;
    });
    builder.addCase(getOutletMoreReturn.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.outletMore = [];
    });
    builder.addCase(getOutletMoreReturn.fulfilled, (state, action) => {
      state.loadingMore = false;
      state.outletMore = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getOutletMoreReturn.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message;
    });
    builder.addCase(getOutletMoreInvoice.pending, (state) => {
      state.loadingMore = true;
      state.error = null;
      state.outletMore = [];
    });
    builder.addCase(getOutletMoreInvoice.fulfilled, (state, action) => {
      state.loadingMore = false;
      state.outletMore = action.payload.data.data ?? [];
      state.error = null;
    });
    builder.addCase(getOutletMoreInvoice.rejected, (state, action) => {
      state.loadingMore = false;
      state.error = action.error.message;
    });
    builder.addCase(updateSalesmanTemp.fulfilled, (state, action) => {
      state.tempSalesmanSelected = action.payload;
    });
    builder.addCase(updateInvoiceTypeTemp.fulfilled, (state, action) => {
      state.tempSalesmanSelected = action.payload;
    });
    builder.addCase(updatePaymentModeTemp.fulfilled, (state, action) => {
      state.tempPaymentModeSelected = action.payload;
    });
    builder.addCase(updateRouteTemp.fulfilled, (state, action) => {
      state.tempRouteSelected = action.payload;
    });
    builder.addCase(updateOutletTemp.fulfilled, (state, action) => {
      state.tempOutletSelected = action.payload;
    });
    builder.addCase(updateDate.fulfilled, (state, action) => {
      state.dateSelected = action.payload;
    });
    builder.addCase(updateFromDate.fulfilled, (state, action) => {
      state.fromDateSelected = action.payload;
    });
    builder.addCase(updateToDate.fulfilled, (state, action) => {
      state.toDateSelected = action.payload;
    });
  },
});

export const { billingStates } = billingSlice.actions;
export default billingSlice.reducer;
