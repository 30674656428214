import React, { ChangeEvent } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Drawer,
  Grid,
  TextField,
  Typography,
  Tab,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { makeStyles } from "@material-ui/core/styles";

import { useTheme } from "@mui/material/styles";
//icons
import backArrow from "../../../assets/images/icons/backArrow.png";

import Close from "../../../assets/images/icons/Close_icon.png";

import { useAppDispatch } from "../../../hooks/useFetch";

import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import GeneralInfo from "./GeneralInfo";
import { subHeaderHide } from "../../../Redux/AuthLogin/action";

interface MasterDistributorViewProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
}
interface TabInfo {
  label: string;
  value: string;
}

interface RequestData {
  page: number;
  size: number;
}

const useStyless = makeStyles((theme) => ({
  customTab: {
    border: "1px solid #e1e7ed",
    fontSize: "10px",
    padding: "0px 8px",
    borderRadius: "30px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "27px",
    background: "white",
  },
}));

export default function MasterDistributorView(
  props: MasterDistributorViewProps
) {
  const { onClose, requestData } = props; // Destructure the props
  const theme = useTheme();
  const [value, setValue] = React.useState("1");
  const dispatch = useAppDispatch();

  const classes = useStyless();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const tabs = [
    { label: "General Info", value: "1" },
    { label: "LOB", value: "2" },
    { label: "Trade Division Mapping", value: "3" },
    { label: "Customer Mapping", value: "4" },
    { label: "Salesman", value: "5" },
    { label: "Market Area", value: "6" },
    { label: "Route", value: "7" },
    { label: "Credit Note/Debit Note", value: "8" },
  ];
  const handleBackClick = () => {
    onClose();
    dispatch(subHeaderHide(false));
  };
  return (
    <Box>
      <Grid mt={1} container>
        <Grid
          container
          className="Distributor_view"
          sx={{
            display: "flex",
            alignItems: "center",
            position: "sticky",
            top: "50px",
            zIndex: 999,
          }}
        >
          <Grid
            xs={12}
            item
            sx={{
              paddingTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <Grid
              container
              xs={5}
              sx={{
                paddingLeft: "20px",
              }}
            > */}
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "20px",
              }}
              onClick={handleBackClick}
            >
              <img
                src={backArrow}
                alt={backArrow}
                style={{ height: "18px", width: "20px " }}
              />
              <Typography>
                <span
                  style={{
                    fontSize: "18px",
                    color: "#000000",
                    fontWeight: "600",
                  }}
                >
                  Back
                </span>
              </Typography>
            </Grid>
            <Grid
              item
              // xs={5}
            >
              <Typography sx={{ fontSize: "17px", fontWeight: "700" }}>
                Amman Traders
              </Typography>
            </Grid>

            <Grid
              item
              // xs={2}
              sx={{
                display: "flex",
                justifyContent: "end",
                alignContent: "center",
                paddingRight: "20px",
              }}
            >
              <img
                onClick={handleBackClick}
                src={Close}
                alt={Close}
                style={{ height: "22px", width: "22px" }}
              />
            </Grid>
            {/* </Grid> */}
          </Grid>
          <Grid item xs={12}>
            {/* <Box sx={{ width: "100%", typography: "body1" }}> */}
            <TabContext value={value}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <TabList
                  className="Distributor_Tab"
                  aria-label="lab API tabs example"
                  onChange={handleChange}
                >
                  {tabs.map((tab: TabInfo, index: number) => (
                    <Tab
                      key={tab.value} // Add a unique key for each tab
                      label={
                        <Typography
                          className={`${
                            value === tab.value
                              ? "active_tab"
                              : classes.customTab
                          } ${classes.customTab}`}
                        >
                          {tab.label}
                        </Typography>
                      }
                      value={tab.value}
                    />
                  ))}
                </TabList>
              </Box>
            </TabContext>
          </Grid>
        </Grid>
        <TabContext value={value}>
          <Grid item>
            <TabPanel className="tab" value="1">
              <GeneralInfo />
            </TabPanel>
            <TabPanel value="2">LOB</TabPanel>
            <TabPanel value="3">Trade Division Mapping</TabPanel>
            <TabPanel value="4">Customer Mapping</TabPanel>
            <TabPanel value="5">Salesman</TabPanel>
            <TabPanel value="6">Market Area</TabPanel>
            <TabPanel value="7">Route</TabPanel>
            <TabPanel value="8">Credit Note/Debit Note</TabPanel>
          </Grid>
        </TabContext>
        {/* </Box> */}
        {/* </Grid> */}
      </Grid>
    </Box>
  );
}
