import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

  interface DistributorProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
  }
  
  const EditGST: React.FC<DistributorProps> = ({onBack}) => {
 
  const handleclick=(event:React.MouseEvent<unknown>)=>{
    onBack(event)
  }
    const handleBackClick = (event: React.MouseEvent<unknown>) => {
      onBack(event); // Pass the event argument to onBack function
    };
  const GRT = [
    "Chhattisgarh",
    "Jammu And Kashmir",
    "Madhya Pradesh",
    "Himachal Pradesh",
    "Gujarat",
  ];

  return (
    <Box>
      <form>
        <Grid container mt={2} rowSpacing={3} columnSpacing={{ xs: 3, sm: 10 }}>
          <Grid item xs={12} sm={6}>
            <Grid item>GST State Name </Grid>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label">
                {GRT.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item className="title-lable">
              PAN No
            </Grid>
            <TextField variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item className="title-lable">
              {" "}
              GSTIN Number
            </Grid>
            <TextField variant="outlined" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item className="title-lable">
              {" "}
              Aadhaar No.
              <TextField variant="outlined" fullWidth />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={4} mt={2}>
          <Grid item className="title-lable">
            {" "}
            TCS Applicable
          </Grid>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="Yes"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={4} mt={2}>
          <Grid item className="title-lable">
            {" "}
            GST Distributor Type
          </Grid>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="Normal"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label="Normal"
              />
              <FormControlLabel
                value="Composition"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label="Composition"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={4} mt={2}>
          <Grid item className="title-lable">
            {" "}
            TDS Applicable
          </Grid>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="Yes"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Grid item sx={{ mr: 2 }}>
            <Button variant="outlined" onClick={handleclick}>
              <UndoIcon
                sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
              />
              Back
            </Button>
          </Grid>
          <Grid>
            <Button className="btn-colr " variant="contained">
              <SaveIcon
                sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
              />
              Update
            </Button>
          </Grid>
          <Grid item sx={{ ml: 2 }}>
            <Button className="next-button" variant="outlined" onClick={handleBackClick}>
              <CancelIcon
                sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
              />{" "}
              Cancle
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
export default EditGST;
