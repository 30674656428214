import { PRODUCTUOM_FETCH_DATA, UOM_SUBMIT_FORM, PRODUCTUOM_Edit_DATA, PRODUCTUOM_UPDATE_DATA, PRODUCTUOM_SUBMIT_ERROR, PRODUCTUOM_UPDATE_ERROR } from '../../actionType'
interface State {
  ProductUomFetch: [];
  ProductUomEdit: [];
  ProductUomUpdate: [];
  ProductUomToast:[];
  ProductUomUpdateToast:[];
  loading: boolean;
  error: string | null;
}


const initialState: State = {
  ProductUomFetch: [],
  ProductUomEdit: [],
  ProductUomUpdate: [],
  ProductUomToast:[],
  ProductUomUpdateToast:[],
  loading: false,
  error: null,
};


const ProductUomReducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case UOM_SUBMIT_FORM:
      return {
        ...state,
        message: action.payload,
      };
    case PRODUCTUOM_FETCH_DATA:
      return {
        ...state,
        ProductUomFetch: action.payload,
      };
    case PRODUCTUOM_Edit_DATA:
      return {
        ...state,
        ProductUomEdit: action.payload,
      };
    case PRODUCTUOM_UPDATE_DATA:
      return {
        ...state,
        ProductUomUpdate: action.payload,
      };
      case PRODUCTUOM_SUBMIT_ERROR:
        return {
          ...state,
          ProductUomToast: action.payload,
        };
        case PRODUCTUOM_UPDATE_ERROR:
          return {
            ...state,
            ProductUomUpdateToast: action.payload,
          };
    default:
      return state;
  }
};


export default ProductUomReducer;

