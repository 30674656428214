import {
  Box,
  Card,
  Grid,
  Stack,
  Divider,
  Tabs,
  Tab,
  Typography,
  Button,
  Skeleton,
} from "@mui/material";

import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useFetch";
import MainCard from "../../../../../components/MainCard";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SearchBar from "@mkyy/mui-search-bar";
import { Spin, Table, TableColumnsType, Tooltip } from "antd";
import debounce from "lodash.debounce";
import moment from "moment";
import {
  updateDate,
  updateFromDate,
  updateInvoiceType,
  updateOutlet,
  updatePaymentMode,
  updateSalesman,
  updateStatusGrn,
  updateToDate,
} from "../../../../../Redux/XDM/Sales/billingReducer";
// import DrawerHeader from "../../../../Layout/MainLayout/Drawer/DrawerHeader";
import NewCollection from "../NewCollection/NewCollection";
import { camelCaseToNormal } from "../../../../../utils/constant";
import MiniDrawerStyled from "../../../../../Layout/MainLayout/Drawer/MiniDrawerStyled";
import NonSaleableProducts from "../../../Inventory/NonSaleableProducts";
import CollectionHistoryDetails from "./CollectionHistoryDetails";
import {
  getCollectionHistory,
  getCollectionHistoryCard,
} from "../../../../../Redux/XDM/Sales/collectionReducer";
import InvoiceDetails from "../../InvoiceReviewOrder";
import { useLocation } from "react-router-dom";

interface PendingCollectionDatatype {
  outlet: string;
  salesMan: string;
  invoiceNumber: string[];
  receivedDate: string;
  collectionDate: string;
  status: string;
  collectionNumber: string;
  amountCollected: string;
  outStandingAmount: number;
  paymentMode: string;
  checkDate: string;
  isAcknowledged: boolean;
  routeCode: string;
}

type RequestDataType = {
  amountCollected: string;
  outlet: string;
  invoiceNumber: string[];
  salesMan: string;
  collectionDate: string;
  paymentMode: string;
  outstandingAmount: number;
  collectionNumber: string;
  checkDate: string;
  status: string;
  isAcknowledged: boolean;
};

type TableRowType = PendingCollectionDatatype | { noMoreData: boolean };

interface DataProps {
  label: string;
  value: number | string | any;
}
const formatIndianNumber = (num: number): string => {
  const numStr = num.toString();
  const [integerPart, decimalPart] = numStr.split(".");
  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);

  if (otherDigits !== "") {
    lastThreeDigits = "," + lastThreeDigits;
  }

  const formattedIntegerPart =
    otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThreeDigits;

  return decimalPart
    ? formattedIntegerPart + "." + decimalPart.slice(0, 2)
    : formattedIntegerPart + ".00";
};

const roundValue = (label: string, val: number | string): string => {
  if (
    typeof val === "number" &&
    (label === "totalCollection" ||
      label === "upiCollection" ||
      label === "cashCollection" ||
      label === "chequeCollection")
  ) {
    return formatIndianNumber(val);
  }
  return val?.toString();
};
const DataBox: React.FC<DataProps> = ({ label, value }) => {
  const shouldShowIcon =
    label === "totalReceivedAmount" ||
    label === "upiReceivedAmount" ||
    label === "cashReceivedAmount" ||
    label === "chequeReceivedAmount";
  return (
    <MainCard border={false} boxShadow>
      <Stack
        spacing={1}
        justifyContent="center"
        alignItems="center"
        className={"card-box-counts " + label}
      >
        <Typography variant="h6" className="card-box-title">
          {label === "upiReceivedAmount"
            ? "UPI Received Amount"
            : camelCaseToNormal(label)}{" "}
          ({value.count})
        </Typography>
        <Typography variant="subtitle1" className="card-box-count">
          {shouldShowIcon && <CurrencyRupeeIcon sx={{ fontSize: 15 }} />}
          {/* {roundValue(label, value.amt)} */}
          {value?.amt?.toFixed(2)}
        </Typography>
      </Stack>
    </MainCard>
  );
};
const CollectionHistory: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isNewCollectionShow, setIsNewCollectionShow] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleNewCollection = (
    event: React.MouseEvent<unknown, MouseEvent>
  ) => {
    setIsNewCollectionShow(!isNewCollectionShow);
  };

  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [pendingCollectionList, setPendingCollectionList] = React.useState<
    PendingCollectionDatatype[]
  >([]);
  const [hasMore, setHasMore] = useState(true);

  const [commonLoader, setCommonLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const location = useLocation();
  const tableHeightRef = useRef(190);
  let pendingCollectionData = useAppSelector(
    (state) => state.collectionReducerData
  );
  let pendingCollectionDataFetch = pendingCollectionData?.collectionHistoryData;
  const filterTags = useAppSelector((state) => state.orderBilling);

  let collectionReducerData = useAppSelector(
    (state) => state.collectionReducerData
  );
  let collectionCounts = useAppSelector(
    (state) => state.collectionReducerData.collectionHistoryCounts
  );
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const collectionHistoryCountData = {
    totalReceivedAmount: {
      amt: collectionCounts.totalReceivedAmt,
      count: "YTD",
    },
    upiReceivedAmount: {
      amt: collectionCounts.upiReceivedAmt,
      count: collectionCounts.upiCount,
    },
    cashReceivedAmount: {
      amt: collectionCounts.cashReceivedAmt,
      count: collectionCounts.cashCount,
    },
    chequeReceivedAmount: {
      amt: collectionCounts.chequeReceivedAmt,
      count: collectionCounts.chequeCount,
    },
  };

  const filterData = {
    page: 0,
    size: 0,
    status: filterTags.invoiceTypeSelected.toString(),
    salesMan: collectionReducerData.salesmanDetails?.salesManId
      ? collectionReducerData.salesmanDetails?.salesManId
      : filterTags.salesmanSelected.toString(),
    outlet: filterTags.outletSelected.toString(),
    dateFilterType:
      filterTags.dateSelected.length !== 0 ? filterTags.dateSelected : "",
    fromDate: filterTags.fromDateSelected ? filterTags.fromDateSelected : "",
    toDate: filterTags.toDateSelected ? filterTags.toDateSelected : "",
    search: id ? id : textFieldValue,
    paymentMode: filterTags.paymentModeSelected.toString(),
  };

  useEffect(() => {
    setPage(0);
    setHasMore(true);
    setPendingCollectionList([]);
    loadMoreData(true);
    getCollectionCardCounts(true);
  }, [
    textFieldValue,
    filterTags.dateSelected,
    filterTags.outletSelected,
    filterTags.salesmanSelected,
    filterTags.fromDateSelected,
    filterTags.toDateSelected,
    filterTags.invoiceTypeSelected,
    filterTags.paymentModeSelected,
  ]);

  useEffect(() => {
    dispatch(updateFromDate([]));
    dispatch(updateToDate([]));
    dispatch(updateDate([]));
    dispatch(updateStatusGrn([]));
    dispatch(updateSalesman([]));
    dispatch(updateInvoiceType([]));
    dispatch(updatePaymentMode([]));
    dispatch(updateOutlet([]));
  }, []);

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 383;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };
  const getCollectionCardCounts = (resetPage = false) => {
    let nextPage = resetPage ? 0 : page;

    dispatch(
      getCollectionHistoryCard({
        params: { ...filterData, page: nextPage },
      })
    );
  };
  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;

    dispatch(
      getCollectionHistory({
        params: { ...filterData, page: nextPage },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const collectionHistory = response.payload.data || [];

          setPendingCollectionList((prevCollection) =>
            resetPage
              ? collectionHistory
              : [...prevCollection, ...collectionHistory]
          );
          setPage(nextPage + 1);
          if (collectionHistory.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        } else {
          // setHasMore(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };

  const filteredCount = pendingCollectionDataFetch.totalCount ?? 0;

  const handleRowClick = (record: PendingCollectionDatatype) => {
    setRequestData({
      amountCollected: record.amountCollected,
      outlet: record.outlet,
      invoiceNumber: record.invoiceNumber,
      salesMan: record.salesMan,
      collectionDate: record.collectionDate,
      paymentMode: record.paymentMode,
      outstandingAmount: record.outStandingAmount, // Correctly mapping outStandingAmount
      collectionNumber: record.collectionNumber,
      checkDate: record.checkDate,
      status: record.status,
      isAcknowledged: record.isAcknowledged,
    });

    setCollectionId(record.collectionNumber);

    setInvoiceRequestData({
      invoiceNumber: "", // Assuming this is empty as per your logic
      orderId: record.collectionNumber,
      date: record.collectionDate,
      outlet: record.outlet,
      route: record.routeCode,
      salesman: record.salesMan,
      netAmount: 0, // Assuming this as default
      salesmanMobile: "", // Assuming this as default
    });

    setIsShow(!isShow);
  };

  const [requestData, setRequestData] = useState<RequestDataType>({
    amountCollected: "",
    outlet: "",
    invoiceNumber: [], // Now correctly typed as InvoiceDataDataType[]
    salesMan: "",
    collectionDate: "",
    paymentMode: "",
    outstandingAmount: 0,
    collectionNumber: "",
    checkDate: "",
    status: "",
    isAcknowledged: false,
  });

  const [invoiceRequestData, setInvoiceRequestData] = React.useState({
    invoiceNumber: "",
    orderId: "",
    date: "",
    outlet: "",
    route: "",
    salesman: "",
    netAmount: 0,
    salesmanMobile: "",
  });

  const [collectionId, setCollectionId] = React.useState("");

  const handleInvoiceClick = (value: string) => {
    setInvoiceRequestData({
      invoiceNumber: value,
      orderId: "",
      date: "",
      outlet: "",
      route: "",
      salesman: "",
      netAmount: 0,
      salesmanMobile: "",
    });

    setIsInvoice(!isInvoice);
  };

  const changeDateFormate = (date: string) => {
    const dateObj = moment(date, "YYYY-MM-DD");
    const formattedDate = dateObj.format("DD MMM YYYY");
    return date ? formattedDate : "--";
  };

  const columns: TableColumnsType<TableRowType> = [
    {
      dataIndex: "collectionNumber",
      title: "Collection Id",
      width: "9%",
      align: "start",
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "--"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "invoiceNumber",
      title: "Invoice No",
      width: "9%",
      align: "start",
      render: (text: string | number, record: TableRowType) => {
        if ("noMoreData" in record) {
          return {
            children: (
              <span
                className="fs-12 noMore"
                style={{ display: "block", margin: "10px" }}
              >
                <b>No more data found...</b>
              </span>
            ),
            props: {
              colSpan: 9, // Adjust this number based on the total number of columns to span
            },
          };
        }
        const invoiceNumbers = record.invoiceNumber?.join(", ");
        return (
          <div
            style={{
              textDecoration: "underline",
              fontWeight: 600,
              color: "#27538D",
              display: "flex",
              gap: "10px",
            }}
          >
            {record.invoiceNumber?.map((value, index) => (
              <React.Fragment key={index}>
                {value && (
                  <Tooltip
                    placement="top"
                    title={invoiceNumbers}
                    zIndex={999999}
                  >
                    <div
                      style={{
                        cursor: "pointer", // Move the cursor style here for individual clickability
                      }}
                      onClick={() => handleInvoiceClick(value[0])} // Adjusted to pass value and record
                    >
                      {value}
                    </div>
                  </Tooltip>
                )}
              </React.Fragment>
            ))}
          </div>
        );
      },
    },
    {
      dataIndex: "collectionDate",
      width: "9%",
      title: "Date",
      align: "center",
      sorter: (a, b) => {
        if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
        return a.collectionDate.localeCompare(b.collectionDate);
      },
      render: (
        _: any,
        record: PendingCollectionDatatype | { noMoreData: boolean }
      ) => {
        // Check if record has noMoreData
        if ("noMoreData" in record) {
          return { children: null, props: { colSpan: 0 } };
        }

        // Safely access collectionDate only if it exists in the correct record type
        if ("collectionDate" in record && record.collectionDate) {
          return <div>{record.collectionDate}</div>;
        }

        return "-";
      },
    },
    {
      dataIndex: "outlet",
      width: "9%",
      title: "Outlet",
      align: "center",
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "--"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "salesMan",
      title: "Salesman",
      width: "9%",
      align: "center",
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "--"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "amountCollected",
      title: "Amt Collected",
      width: "12%",
      align: "right",
      render: (text: number, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <div className="ellipseclass">
            ₹{text ? text?.toFixed(2) : "0.00"}
          </div>
        );
      },
    },
    {
      dataIndex: "outstandingAmount",
      title: "Ols Outstanding Amt",
      width: "14%",
      align: "right",
      render: (text: number, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        let osaData = record.outStandingAmount;
        return (
          <div className="ellipseclass">
            ₹{osaData ? osaData?.toFixed(2) : "00:00"}
          </div>
        );
      },
    },
    {
      dataIndex: "paymentMode",
      title: "Payment Mode",
      width: "10%",
      align: "center",
      render: (text: string, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "--"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "status",
      width: "20%",
      title: "Status",
      align: "center",
      render: (text: string, record: TableRowType) => {
        let statusContent = "";
        let color = "";
        let paymentType = (record as any).paymentMode;
        let date = "";
        let isAcknowledged = (record as any).isAcknowledged;
        if (
          paymentType === "Cheque" &&
          (text === "Approved" || text === "Rejected")
        ) {
          date = (record as any).collectionDate;
        } else {
          date = changeDateFormate((record as any).receivedDate);
        }
        if (
          (paymentType === "Cash" || paymentType === "UPI") &&
          text === "Approved"
        ) {
          statusContent = "Payment Received on " + date;
          color = "#52A13D";
        } else if (
          (paymentType === "Cash" || paymentType === "UPI") &&
          text === "Rejected"
        ) {
          statusContent = "Payment Rejected on " + date;
          color = "#E12B1D";
        } else if (paymentType === "Cheque" && text === "Bounced") {
          statusContent = "Cheque Bounced on " + date;
          color = "#E12B1D";
        } else if (
          paymentType === "Cheque" &&
          text === "Approved" &&
          isAcknowledged === true
        ) {
          statusContent = "Payment Received on " + date;
          color = "#52A13D";
        } else if (
          paymentType === "Cheque" &&
          text === "Approved" &&
          isAcknowledged === false
        ) {
          statusContent = "Cheque Received on " + date;
          color = "#F28500";
        } else if (paymentType === "Cheque" && text === "Rejected") {
          statusContent = "Cheque Rejected on " + date;
          color = "#E12B1D";
        }

        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <Tooltip placement="top" title={statusContent} zIndex={999999}>
            <div
              className="ellipseclass"
              style={{ color: color, fontWeight: "700" }}
            >
              {statusContent ? statusContent : text}
            </div>
          </Tooltip>
        );
      },
    },
  ];

  const dataToShow: TableRowType[] =
    pendingCollectionList.length > 0
      ? hasMore
        ? pendingCollectionList
        : [...pendingCollectionList, { noMoreData: true }]
      : [];

  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <Grid item>
      {/* <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        bgcolor="#eee"
      > */}
      {/* <Box flex={1} p={0}>
            <MiniDrawerStyled variant="permanent" open={true} theme={theme}>
              <DrawerHeader />
              <PendingCollectionSideBar
                status={tabIndex === 0 ? "Pending" : "History"}
              />
            </MiniDrawerStyled>
          </Box> */}

      {/* <Box
          flex={8}
          className="margin-left-0px"
          sx={{ overflowY: "auto", height: "100vh", marginBottom: "100px" }}
        >
          <Card
            className="b-radius-0 main-card"
            style={{ marginTop: "2px", boxShadow: "none" }}
          > */}
      {/* <Grid container sx={{ paddingLeft: " 20px" }}></Grid> */}
      <TabPanel value={tabIndex} index={0}>
        <Box>
          <Box
            flex={8}
            sx={{
              overflowY: "auto",
              height: "100vh",
              mt: { sm: -0.5, md: -5.3 },
            }}
          >
            <Card
              className="b-radius-0 "
              style={{ marginTop: "2px", boxShadow: "none" }}
            >
              <Grid container spacing={3} justifyContent="start" p={2}>
                <Grid container mt={4} m={2}>
                  <Grid item xs={12} sm={12} md={9} lg={9}>
                    <h3
                      style={{
                        marginTop: "0px",
                        marginLeft: "8px",
                        marginBottom: "16px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={5} md={3} lg={3}>
                    <SearchBar
                      className="collection_search w-100px"
                      placeholder="Search Collection ID"
                      value={textFieldValue}
                      onChange={(newValue) => {
                        setTextFieldValue(newValue);
                      }}
                      onCancelResearch={() => setTextFieldValue("")}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={3}
                  justifyContent="start"
                  px={2}
                  mt={1}
                >
                  <Grid xs={12} sm={12} md={12} lg={12} my={2}>
                    <Typography
                      variant="subtitle1"
                      className="card-box-count"
                      sx={{ fontSize: "12px", paddingLeft: "28px" }}
                    >
                      Showing data based on the overall year-to-date amount
                      collected
                    </Typography>
                  </Grid>
                  {collectionReducerData.collectionHistoryCountsLoading
                    ? Array.from(new Array(4)).map((_, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          lg={3}
                          key={index}
                          style={{ paddingTop: "4px" }}
                        >
                          <Skeleton
                            variant="rounded"
                            width="100%"
                            height={81}
                          />
                        </Grid>
                      ))
                    : Object.entries(collectionHistoryCountData).map(
                        ([key, value]) => (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            lg={3}
                            key={key}
                            style={{ paddingTop: "4px" }}
                          >
                            <DataBox label={key} value={value} />
                          </Grid>
                        )
                      )}
                </Grid>
                <Grid container spacing={3} mt={2} justifyContent="start" p={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className="pad-top-0px"
                  >
                    <div className="saleable-table-scroll">
                      <Table
                        className={`Header-table saleable-table sale_last_hide scroll_cus_table elipsTable pending_grn ${
                          commonLoader ? " nodataloader" : ""
                        }`}
                        // rowSelection={rowSelection}
                        columns={columns}
                        dataSource={dataToShow}
                        pagination={false}
                        loading={!page && commonLoader}
                        showSorterTooltip={false}
                        summary={() => {
                          return (
                            <>
                              {page !== 0 && commonLoader && (
                                <Table.Summary.Row>
                                  <Table.Summary.Cell
                                    index={1}
                                    colSpan={9}
                                    align="center"
                                  >
                                    <Spin />
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                              )}
                            </>
                          );
                        }}
                        onRow={(record: TableRowType, index) => {
                          if ("noMoreData" in record) return {};
                          return {
                            onClick: (event: React.MouseEvent<HTMLElement>) => {
                              const target = event.target as HTMLElement;
                              const closestTd = target.closest("td");
                              const columnIndex = closestTd
                                ? closestTd.cellIndex
                                : null;

                              if (columnIndex !== null && columnIndex !== 1) {
                                handleRowClick(record);
                              }
                            },
                          };
                        }}
                        scroll={{ y: tableHeightRef.current }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Box>
        </Box>
      </TabPanel>
      {/* </Card>
        </Box>
      </Stack> */}
      {isShow ? (
        <CollectionHistoryDetails
          onClose={() => setIsShow(false)}
          requestData={requestData}
          collectionId={collectionId}
          invoiceRequestData={invoiceRequestData}
        />
      ) : (
        <>
          {isInvoice && (
            <InvoiceDetails
              onClose={() => setIsInvoice(false)}
              requestData={invoiceRequestData}
            />
          )}
        </>
      )}
    </Grid>
  );
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}
export default CollectionHistory;
