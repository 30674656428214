import MainLayout from "../Layout/MainLayout";
import NonSaleable from "../Pages/XDM/Inventory/NonSaleable";

import Saleable from "../Pages/XDM/Inventory/Saleable";
import ProtectedRoute from "./ProtectedRoute";
import { PERMISSIONS } from "../utils/constant";
import path from "path";
import OrderBilling from "../Pages/XDM/Sales/OrderBillingCommon";
import Outlet from "../Pages/XDM/Sales/Outlet";
import Invoices from "../Pages/XDM/Sales/Invoices";
import ManualOrder from "../Pages/XDM/Sales/ManualOrder";
import MannualReviewDetails from "../Pages/XDM/Sales/MannualReviewOder";
import InvoiceAndGrn from "../Pages/XDM/Purchase/InvoicesAndGRN";
import SalesReturn from "../Pages/XDM/Sales/SalesReturn/SalesReturn";
import Collection from "../Pages/XDM/Sales/Collection/Collection";
import CheckAck from "../Pages/XDM/Sales/Collection/CheckAcknowledgement/CheckAck";
import WithoutInvoiceReviewDetails from "../Pages/XDM/Sales/SalesReturn/NewSalesReturn/WithoutInvoice/WithoutInvoiceSrReview";
import WithInvoiceReviewDetails from "../Pages/XDM/Sales/SalesReturn/NewSalesReturn/WithInvoice/WithInvoiceSrReview";

const DistributorRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    // XDM Routes
    {
      path: "/xdm/purchase/invoices-grn",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_DISTRIBUTOR]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <InvoiceAndGrn />
        </ProtectedRoute>
      ),
    },
    {
      path: "/xdm/inventory/saleable",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_DISTRIBUTOR]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <Saleable />
        </ProtectedRoute>
      ),
    },
    {
      path: "/xdm/inventory/non-saleable",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_DISTRIBUTOR]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <NonSaleable />
        </ProtectedRoute>
      ),
    },
    {
      path: "/xdm/sales/order-billing",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_DISTRIBUTOR]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <OrderBilling />
        </ProtectedRoute>
      ),
    },
    {
      path: "/xdm/sales/invoices",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_DISTRIBUTOR]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <Invoices />
        </ProtectedRoute>
      ),
    },
    {
      path: "/xdm/sales/outlet",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_DISTRIBUTOR]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <Outlet />
        </ProtectedRoute>
      ),
    },
    {
      path: "/xdm/sales/collection",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_DISTRIBUTOR]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <Collection />
        </ProtectedRoute>
      ),
    },
    {
      path: "/xdm/sales/manual-order",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_DISTRIBUTOR]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <ManualOrder />
        </ProtectedRoute>
      ),
    },
    {
      path: "/xdm/sales/manual-order-review",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_DISTRIBUTOR]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <MannualReviewDetails />
        </ProtectedRoute>
      ),
    },
    {
      path: "/xdm/sales/sales-return",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_DISTRIBUTOR]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <SalesReturn />
        </ProtectedRoute>
      ),
    },
    {
      path: "/xdm/sales/without-invoice-sales-return",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_DISTRIBUTOR]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <WithoutInvoiceReviewDetails />
        </ProtectedRoute>
      ),
    },
    {
      path: "/xdm/sales/with-invoice-sales-return",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_DISTRIBUTOR]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <WithInvoiceReviewDetails />
        </ProtectedRoute>
      ),
    },
    
    {
      path: "/xdm/collection/check-ack",
      element: (
        <ProtectedRoute
          permissions={[PERMISSIONS.CAN_VIEW_DISTRIBUTOR]}
          redirectPath="/unauthorized"
          isAllowed={true}
        >
          <CheckAck />
        </ProtectedRoute>
      ),
    },
  ],
};

export default DistributorRoutes;
