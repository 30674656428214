import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button, Grid, Paper, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import RetailerClassForm from "./RetailerClassForm";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  Retailergrpname: string;
  Retailerclscode: string;
  Retailerclsname: string;
  Turnover: string;
  action: string;
}

const RetailerClass: React.FC = () => {
  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // search function logic here
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "Retailergrpname",
      title: "Retailer Group Name",
      sorter: (a, b) => a.Retailergrpname.length - b.Retailergrpname.length,
    },
    {
      dataIndex: "Retailerclscode",
      title: "Retailer Class Code",
      sorter: (a, b) => a.Retailerclscode.length - b.Retailerclscode.length,
    },
    {
      dataIndex: "Retailerclsname",
      title: "Retailer Class Name",
      sorter: (a, b) => a.Retailerclsname.length - b.Retailerclsname.length,
    },
    {
      dataIndex: "Turnover",
      title: "Turnover (Amount)",
      sorter: (a, b) => a.Turnover.length - b.Turnover.length,
    },
    {
      dataIndex: "action",
      title: "Action",
      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            onClick={(event) => handleViewClick(event)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      Retailergrpname: "OTHERS",
      Retailerclscode: "SS05B",
      Retailerclsname: "VRIDDHI",
      Turnover: "0.00",
      action: "",
    },
    {
      key: 2,
      Retailergrpname: "FMCG - Society/Apartments",
      Retailerclscode: "FMCG017B",
      Retailerclsname: "A",
      Turnover: "0.00",
      action: "",
    },
    {
      key: 3,
      Retailergrpname: "FMCG - Society/Apartments",
      Retailerclscode: "FMCG017HVO",
      Retailerclsname: "HVO",
      Turnover: "0.00",
      action: "",
    },
    {
      key: 4,
      Retailergrpname: "FMCG - Temple/Tourist",
      Retailerclscode: "FMCG019D",
      Retailerclsname: "C",
      Turnover: "0.00",
      action: "",
    },
    {
      key: 5,
      Retailergrpname: "FMCG - School Zone",
      Retailerclscode: "FMCG020C",
      Retailerclsname: "W",
      Turnover: "0.00",
      action: "",
    },
  ];
  const [isViewing, setIsViewing] = React.useState(false);

  const handleViewClick = (event: React.MouseEvent<unknown>) => {
    setIsViewing(!isViewing);
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className=" mar-tb">
          <Typography>
            <b>Retailer Class</b>
          </Typography>
        </Grid>

        <Box>
          {isViewing ? (
            <RetailerClassForm onBack={(event) => handleViewClick(event)} />
          ) : (
            <>
              <Grid item>
                <Box>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "end", mb: 2 }}
                  >
                    <SearchBar
                      className="search-table"
                      value={textFieldValue}
                      onChange={(newValue) => {
                        setTextFieldValue(newValue);
                        handleSearch();
                      }}
                    />
                  </Grid>
                  <CommonTable
                    //  rowSelection={rowSelection}
                    data={data}
                    columns={columns}
                  />
                </Box>
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
export default RetailerClass;
