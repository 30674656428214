import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Drawer, Grid, Typography, Divider } from "@mui/material";
import type { TableColumnsType } from "antd";
import { Table, Tooltip } from "antd";
import { decodeJwt } from "../../../utils/constant";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import Baseurl from "../../../hooks/useLocalStorage";
import moment from "moment";
import { ArrowBackIos } from "@mui/icons-material";
import printer from "../../../assets/images/icons/Bf_printer.png";
import { InvoicePdfGenerate } from "../../../Redux/XDM/Sales/InvoiceReducer";
import LoadingButton from "@mui/lab/LoadingButton";
import { toWords } from "number-to-words";
import {
  getBillingShippingDetails,
  getGrnInvoiceData,
  getGrninvoicefromDatas,
  grnPdfGeneratr,
} from "../../../Redux/XDM/Purchase/GrnReducer";

declare module "number-to-words" {
  export function toWords(number: number): string;
  export function toOrdinal(number: number): string;
  export function toWordsOrdinal(number: number): string;
}

const convertToWords = (amount: any) => {
  return toWords(amount).replace(/,/g, "") + " only";
};
// const convertToWords = (amount: number) => {
//   // Separate the integer and fractional parts
//   const [integerPart, fractionalPart] = amount.toString().split('.');

//   // Convert integer part to words
//   const integerPartInWords = toWords(parseInt(integerPart)).replace(/,/g, "");

//   // Convert fractional part to words as paisa, if it exists
//   let fractionalPartInWords = '';
//   if (fractionalPart) {
//     const fractionalValue = parseInt(fractionalPart.padEnd(2, '0')); // Ensure 2 digits for paisa
//     fractionalPartInWords = ' and ' + toWords(fractionalValue).replace(/,/g, "") + ' paisa';
//   }

//   // Combine the parts
//   return (integerPartInWords + fractionalPartInWords + " only").toUpperCase();
// };

interface RequestData {
  grnDate: string;
  grnNo: string;
  invoiceNo: string;
  orderId: string;
  value: number;
  noOfLineItems: number;
  date: string;
  outlet: string;
  route: string;
  salesman: string;
  status: string;
}
interface invoiceProductTableData {
  productName: string;
  hsnCode: string;
  mrp: number;
  quantity: number;
  uom: string;
  rate: number;
  cgstRate: number;
  sgstRate: number;
  igstRate: number;
  cgstAmount: number;
  sgstAmount: number;
  igstAmount: number;
  taxableValue: number;
  totalValue: number;
  grossAmount: number;
  discountAmount: number;
  addedDiscountAmount: number;
  tcsAmount: number;
}

interface InvoiceDetailsProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
}

export default function InvoiceDetails(props: InvoiceDetailsProps) {
  const { onClose, requestData } = props; // Destructure the props

  const dispatch = useAppDispatch();
  const [buttonLoading, setButtonLoading] = useState(false);
  let invoiceData = useAppSelector((state) => state.invoiceData);
  let invoiceTodata = invoiceData.toInfo;
  let invoiceProductDetails = invoiceData.taxProductDetails;
  let grnData = useAppSelector((state) => state.pendingGrnData);
  let grninvoice = grnData.grnShippingBilling;
  let grnfromDatas = grnData.grnfromDatas;
  let grnDatainvoice = grnData.grnInvoiceData;
  const roundValue = (val: number | string): string => {
    if (typeof val === "number" && val !== null) {
      return val?.toFixed(2);
    }
    return val?.toString();
  };
  useEffect(() => {
    dispatch(getBillingShippingDetails());
    dispatch(getGrnInvoiceData(requestData.invoiceNo));
    dispatch(getGrninvoicefromDatas(requestData.invoiceNo));
  }, [requestData]);
  const formatDate = (date: string | undefined) => {
    if (date) {
      const dateObj = moment(date, "YYYY-MM-DD HH:mm:ss");
      return dateObj.format("DD MMM YYYY");
    }
    return "-";
  };

  const handlePrintButton = async () => {
    setButtonLoading(true);
    try {
      dispatch(
        grnPdfGeneratr({
          params: {
            invoiceDate: formatDate(requestData.grnDate),
            invoiceNo: requestData.invoiceNo,
          },
        })
      )
        .then((response) => {
          const pdfGenerateval = response.payload;
          setButtonLoading(false);
          // Need to open the pdf in new tab But it's downloading the pdf
          const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
            pdfGenerateval
          )}`;
          window.open(viewerUrl, "_blank");
        })
        .catch((error) => {
          setButtonLoading(false);
        });
    } catch (error) {
      setButtonLoading(false);
    }
  };

  //   useEffect(() => {
  //  if (pdfGenerate) {
  //    const url = Baseurl + pdfGenerate;
  //    window.open(url, "_blank");
  //  }
  //      },
  //     [pdfGenerate]);

  const columns1: TableColumnsType<invoiceProductTableData> = [
    {
      title: "Product Details",
      children: [
        {
          title: "Material Name",
          dataIndex: "productName",
          key: "productName",
          width: "20%",
          ellipsis: true,
          render: (text: string) => {
            return (
              <Tooltip placement="top" title={text} zIndex={999999}>
                <span>{text ? text : "--"}</span>
              </Tooltip>
            );
          },
        },
        {
          title: "HSN Code",
          dataIndex: "hsnCode",
          key: "hsnCode",
          ellipsis: true,
          render: (text: string) => {
            return (
              <Tooltip placement="top" title={text} zIndex={999999}>
                <span>{text ? text : "--"}</span>
              </Tooltip>
            );
          },
        },
        {
          title: "MRP/Pcs",
          dataIndex: "mrp",
          key: "mrp",
          render: (text: string) => {
            return (
              <span title={text}>{text ? `₹${roundValue(text)}` : "0.00"}</span>
            );
          },
        },
        {
          title: "Quantity",
          dataIndex: "quantity",
          key: "quantity",
          render: (text: string) => {
            return <span title={text}>{text ? text : "0"}</span>;
          },
        },
        {
          title: "Rate",
          dataIndex: "rate",
          key: "rate",
          render: (text: number) => {
            return (
              <span title={String(text)}>
                {text ? `₹${roundValue(text)}` : "0.00"}
              </span>
            );
          },
        },
        {
          title: "UOM",
          dataIndex: "uom",
          key: "uom",
          render: (text: number) => {
            return <span title={String(text)}>{text ? text : "--"}</span>;
          },
        },
        {
          title: "Gross Amount",
          dataIndex: "grossValue",
          key: "grossValue",
          render: (text: number) => {
            return (
              <span title={String(text)}>
                {text ? `₹${roundValue(text)}` : "0.00"}
              </span>
            );
          },
        },
        {
          title: "Dis.Amount",
          dataIndex: "discountAmount",
          key: "discountAmount",
          render: (text: number) => {
            return (
              <span title={String(text)}>
                {text ? `₹${roundValue(text)}` : "0.00"}
              </span>
            );
          },
        },
        {
          title: "Additional Dis.Amount ",
          dataIndex: "addedDiscountAmount",
          key: "addedDiscountAmount",
          render: (text: number) => {
            return (
              <span title={String(text)}>
                {text ? `₹${roundValue(text)}` : "0.00"}
              </span>
            );
          },
        },
        {
          title: "Taxable Value",
          dataIndex: "taxableValue",
          key: "taxableValue",
          render: (text: number) => {
            return (
              <span title={String(text)}>
                {text ? `₹${roundValue(text)}` : "0.00"}
              </span>
            );
          },
        },
      ],
    },

    {
      title: "CGST",
      children: [
        {
          title: "Rate",
          dataIndex: "cgstRate",
          key: "cgstRate",
          render: (text: string) => {
            return <span title={text}>{text ? text : "0"}%</span>;
          },
        },
        {
          title: "Amount",
          dataIndex: "cgstAmount",
          key: "cgstAmount",
          render: (text: number) => {
            return (
              <span title={String(text)}>
                {text ? `₹${roundValue(text)}` : "0.00"}
              </span>
            );
          },
        },
      ],
    },
    {
      title: "SGST",
      children: [
        {
          title: "Rate",
          dataIndex: "sgstRate",
          key: "sgstRate",
          render: (text: string) => {
            return <span title={text}>{text ? text : "0"}%</span>;
          },
        },
        {
          title: "Amount",
          dataIndex: "sgstAmount",
          key: "sgstAmount",
          render: (text: number) => {
            return (
              <span title={String(text)}>
                {text ? `₹${roundValue(text)}` : "0.00"}
              </span>
            );
          },
        },
      ],
    },
    {
      title: "IGST",
      children: [
        {
          title: "Rate",
          dataIndex: "igstRate",
          key: "igstRate",
          render: (text: string) => {
            return <span title={text}>{text ? text : "0"}%</span>;
          },
        },
        {
          title: "Amount",
          dataIndex: "igstAmount",
          key: "igstAmount",
          render: (text: number) => {
            return (
              <span title={String(text)}>
                {text ? `₹${roundValue(text)}` : "0.00"}
              </span>
            );
          },
        },
      ],
    },
    {
      title: "TCS",
      children: [
        {
          title: "TCS Amount",
          dataIndex: "tcsAmount",
          key: "tcsAmount",
          render: (text: number) => {
            return (
              <span title={String(text)}>
                {text ? `₹${roundValue(text)}` : "0.00"}
              </span>
            );
          },
        },
      ],
    },
    {
      title: "Total Value",
      key: "amount",
      fixed: "right",
      width: 100,
      className: "background_color",
      render: (record: invoiceProductTableData) => {
        return (
          <span title={String(record.totalValue)}>
            {record.totalValue ? `₹${roundValue(record.totalValue)}` : "0.00"}
          </span>
        );
      },
    },
  ];

  const formatAmount = (amount: number | undefined) => {
    return amount ? amount?.toFixed(2) : "";
  };

  const amountTableData = [
    {
      key: "1",
      billed: "Total",
      symbol: ":",
      amount: grnDatainvoice?.summary?.totalSummedValue ? (
        <span>₹{formatAmount(grnDatainvoice?.summary?.totalSummedValue)}</span>
      ) : (
        <span>₹{"0.00"}</span>
      ),
    },
    {
      key: "2",
      billed: "Credit Note",
      symbol: ":",
      amount: grnDatainvoice?.summary?.creditNote ? (
        <span>₹{formatAmount(grnDatainvoice?.summary?.creditNote)}</span>
      ) : (
        <span>₹{"0.00"}</span>
      ),
    },
    {
      key: "3",
      billed: "Debit Note",
      symbol: ":",
      amount: grnDatainvoice?.summary?.debitNote ? (
        <span>₹{formatAmount(grnDatainvoice?.summary?.debitNote)}</span>
      ) : (
        <span>₹{"0.00"}</span>
      ),
    },
    {
      key: "4",
      billed: "Advance",
      symbol: ":",
      amount: grnDatainvoice?.summary?.advance ? (
        <span>₹{formatAmount(grnDatainvoice?.summary?.advance)}</span>
      ) : (
        <span>₹{"0.00"}</span>
      ),
    },
    {
      key: "5",
      billed: "Round.off",
      symbol: ":",
      amount: grnDatainvoice?.summary?.roundOff ? (
        <span>₹{formatAmount(grnDatainvoice?.summary?.roundOff)}</span>
      ) : (
        <span>₹{"0.00"}</span>
      ),
    },
    {
      key: "6",
      billed: "TCS Amount",
      symbol: ":",
      amount: grnDatainvoice?.summary?.tcsAmount ? (
        <span>₹{formatAmount(grnDatainvoice?.summary?.tcsAmount)}</span>
      ) : (
        <span>₹{"0.00"}</span>
      ),
    },

    {
      key: "8",
      billed: "Net Amount",
      symbol: ":",
      amount: grnDatainvoice?.summary?.netAmount ? (
        <span>₹{formatAmount(grnDatainvoice?.summary?.netAmount)}</span>
      ) : (
        <span>₹{"0.00"}</span>
      ),
    },
  ];

  const amountTable = [
    {
      dataIndex: "billed",
      key: "billed",
      render: (text: string) => {
        const displayText =
          text === "Net Amount" || "Net Payable" ? "bold" : "normal";
        return <span style={{ fontWeight: displayText }}>{text}</span>;
      },
    },
    {
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      dataIndex: "amount",
      key: "amount",
    },
  ];
  const totalNetPayable = grnDatainvoice?.summary?.netAmount ?? 0;
  const totalNetPayableInWords = convertToWords(totalNetPayable);

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_outlet w-100px"
      // variant="persistent"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "0px 16px",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "100%" }} role="presentation">
            <Grid container className="crt-header" mt={1} pt={2} pb={2}>
              <Grid
                item
                xs={0.3}
                sm={0.3}
                md={0.2}
                lg={0.2}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <ArrowBackIos
                  sx={{
                    fontSize: "10px",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    onClose();
                  }}
                />
              </Grid>
              <Grid
                item
                xs={5.7}
                sm={5.7}
                md={8.8}
                lg={8.8}
                className="margin_tp_0"
              >
                <Grid item>
                  <Typography className="outlet-header-lable">
                    Invoice ({requestData.invoiceNo})
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className="purchase-header-lable">
                    Delivered on{" "}
                    {requestData.grnDate
                      ? formatDate(requestData.grnDate)
                      : "--"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                lg={3}
                style={{ display: "flex", justifyContent: "flex-end" }}
                className="margin_tp_0"
              >
                <LoadingButton
                  loadingPosition="start"
                  loading={buttonLoading}
                  variant="contained"
                  className="prnt_af_btn"
                  // sx={{ mr: 2 }}
                  onClick={handlePrintButton}
                  style={{
                    width: "36%",
                    fontSize: "10px",
                    borderRadius: "8px",
                    height: "32px",
                  }}
                  type="button"
                >
                  <img
                    src={printer}
                    alt="Print"
                    style={{ marginRight: "8px" }}
                    width={18}
                  />
                  Print
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Box
            mt={3}
            sx={{
              overflowY: "auto",
            }}
            className="create-form saleable-products-box"
          >
            {" "}
            <Grid
              item
              sx={{
                width: "100%",
                marginBottom: "90px",
              }}
            >
              {" "}
              <Grid container columnGap={1} mt={1}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={2.3}
                  sx={{
                    border: "1px solid #d3cece73",
                    borderRadius: "5px",
                    margin: "0px 0px",
                    padding: "0px 4px !important",
                  }}
                >
                  <Typography
                    className="crt-fnt-lable-light"
                    mt={1}
                    sx={{ fontSize: "10px !important", marginBottom: "2px" }}
                  >
                    From
                  </Typography>
                  <Typography
                    gutterBottom
                    className="inv-lable-input"
                    sx={{
                      marginBottom: "0px",
                      fontSize: "9px !important",
                      lineHeight: "16px",
                    }}
                  >
                    {grnfromDatas?.companyName},{grnfromDatas?.address1},
                    {grnfromDatas?.address2},{grnfromDatas?.state},
                    {grnfromDatas?.district},{grnfromDatas?.postalCode},
                  </Typography>
                  <Typography
                    className="crt-fnt-lable-light"
                    mt={1}
                    sx={{ fontSize: "10px !important", marginBottom: "2px" }}
                  >
                    Delivering Address
                  </Typography>
                  <Typography
                    gutterBottom
                    className="inv-lable-input"
                    sx={{
                      marginBottom: "0px",
                      fontSize: "9px !important",
                      lineHeight: "16px",
                    }}
                  >
                    {grnfromDatas?.supplierName},
                    {grnfromDatas?.supplierAddress1},
                    {grnfromDatas?.supplierAddress2},
                    {grnfromDatas?.supplierAddress3},
                    {grnfromDatas?.supplierDistrict},
                    {grnfromDatas?.supplierCity},
                    {grnfromDatas?.supplierPostalCode}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      CIN.No
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;{/* {requestData.gstin} */}
                      {grnfromDatas?.cInNo ? grnfromDatas.cInNo : "-"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      FSSAI No
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {grnfromDatas?.fssaiNo ? grnfromDatas.fssaiNo : "-"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      GST State
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {grnfromDatas?.gstState ? grnfromDatas.gstState : "-"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={2.3}
                  sx={{
                    border: "1px solid #d3cece73",
                    borderRadius: "5px",
                    margin: "0px 0px",
                    padding: "0px 4px !important",
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      GSTIN No
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;{/* {requestData.gstin} */}
                      {grnfromDatas?.gstNo ? grnfromDatas.gstNo : "-"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      FSSAI
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {grnfromDatas?.fssai ? grnfromDatas.fssai : "-"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      Invoice No
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {requestData?.invoiceNo ? requestData?.invoiceNo : "-"}
                      {/* {grnfromDatas?. ? grnfromDatas. : "--"} */}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      Invoice Date
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {requestData?.grnDate
                        ? formatDate(requestData?.grnDate)
                        : "-"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      PAN No
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {grnfromDatas?.panNo ? grnfromDatas.panNo : "--"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      Order ID & Date
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {grnfromDatas?.orderID
                        ? grnfromDatas.orderID
                        : "--"} &&{" "}
                      {grnfromDatas?.orderDate ? grnfromDatas.orderDate : "--"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      E-Way Bill No & Date
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {grnfromDatas?.eWayBillNo
                        ? grnfromDatas.eWayBillNo
                        : "--"}{" "}
                      &&{" "}
                      {grnfromDatas?.eWayBillDate
                        ? grnfromDatas.eWayBillDate
                        : "--"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={2.3}
                  sx={{
                    border: "1px solid #d3cece73",
                    borderRadius: "5px",
                    margin: "0px 0px",
                    padding: "0px 4px !important",
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      Transportation
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;{/* {requestData.gstin} */}
                      {grnfromDatas?.transportation
                        ? grnfromDatas.transportation
                        : "--"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      Vehicle No
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {grnfromDatas?.vehicleNo ? grnfromDatas.vehicleNo : "--"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      Date & Time of Supply
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {grnfromDatas?.date ? grnfromDatas.date : "--"} &&{" "}
                      {grnfromDatas?.timeOfSupply
                        ? grnfromDatas.timeOfSupply
                        : "--"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      Place of Supply
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {grnfromDatas?.placeOfSupply
                        ? grnfromDatas.placeOfSupply
                        : "--"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      LR No
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {grnfromDatas?.lrNo ? grnfromDatas.lrNo : "--"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      LR Date
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {grnfromDatas?.lrDate ? grnfromDatas.lrDate : "--"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      Carrier Name
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {grnfromDatas?.carrierName
                        ? grnfromDatas.carrierName
                        : "--"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={2.4}
                  sx={{
                    border: "1px solid #d3cece73",
                    borderRadius: "5px",
                    margin: "0px 0px",
                    padding: "0px 4px !important",
                  }}
                >
                  <Typography
                    className="crt-fnt-lable-light"
                    mt={1}
                    sx={{ fontSize: "9px !important", marginBottom: "2px" }}
                  >
                    Details of Receiver (Billed to)
                  </Typography>

                  <Typography
                    gutterBottom
                    className="inv-lable-input"
                    sx={{
                      marginBottom: "0px",
                      fontSize: "9px !important",
                      lineHeight: "16px",
                    }}
                  >
                    {grninvoice?.address ? grninvoice.address : "-- "}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      PAN No :
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {grninvoice?.panNo ? grninvoice?.panNo : "-- "}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      GST No :
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {grninvoice?.gstnumber ? grninvoice?.gstnumber : "-- "}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important" }}
                    >
                      State
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {grninvoice?.state ? grninvoice?.state : "-- "}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={2.4}
                  sx={{
                    border: "1px solid #d3cece73",
                    borderRadius: "5px",
                    margin: "0px 0px",
                    padding: "0px 4px !important",
                  }}
                >
                  {" "}
                  <Typography
                    className="crt-fnt-lable-light"
                    mt={1}
                    sx={{ fontSize: "10px !important", marginBottom: "2px" }}
                  >
                    Details of Consignee (Shipped to)
                  </Typography>
                  <Typography
                    gutterBottom
                    className="inv-lable-input"
                    sx={{
                      marginBottom: "0px",
                      fontSize: "9px !important",
                      lineHeight: "16px",
                    }}
                  >
                    {grninvoice?.shippingAddress
                      ? grninvoice?.shippingAddress
                      : "-- "}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important", width: "90px" }}
                    >
                      GST No
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {grninvoice?.shippingGstNumber
                        ? grninvoice?.shippingGstNumber
                        : "-- "}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "9px !important", width: "90px" }}
                    >
                      P.Term
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "9px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {grninvoice?.pTerms ? grninvoice?.pTerms : "-- "}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={1}
                    sx={{ marginTop: "0px !important" }}
                  >
                    <Typography
                      className="crt-fnt-lable-light"
                      mt={1}
                      sx={{ fontSize: "10px !important", width: "90px" }}
                    >
                      Mobile
                    </Typography>
                    <Typography
                      gutterBottom
                      className="inv-lable-input"
                      sx={{ marginBottom: "0px", fontSize: "10px !important" }}
                      mt={1}
                    >
                      &nbsp;: &nbsp;
                      {grninvoice?.contactmobile
                        ? grninvoice?.contactmobile
                        : "-- "}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="pad-top-0px inv"
                >
                  {" "}
                  <div className="primaryGrn-table-scroll productData border-set">
                    <Table
                      className="Header-table saleable-table Table_footer border-set last_hide scroll_cus_table"
                      columns={columns1}
                      dataSource={grnData?.grnInvoiceData?.details}
                      loading={grnData.grnInvoiceDataLoading}
                      scroll={{ x: 2000, y: 300 }}
                      pagination={false}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item xs={12} sm={6} md={4} lg={4} className="inv">
                  <div style={{ fontSize: "12px", paddingTop: "10px" }}>
                    Net Invoice Value(In Words): {totalNetPayableInWords}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}></Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4.5}
                  className="inv"
                  mb={2}
                >
                  <div className="productData">
                    <Table
                      className="Header-table saleable-table border-set remove-header"
                      columns={amountTable}
                      loading={grnData.grnInvoiceDataLoading}
                      pagination={false}
                      dataSource={amountTableData}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
