import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, Drawer, Grid, TextField, Typography } from "@mui/material";
import {
  ProductUomFormValues,
  FormErrors,
} from "../../../../typescript/ProductUom";
import {
  productUomUpdate,
  ProductUomFetchData,
} from "../../../../Redux/Masters/Uom/action";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useFetch";
//icons
import Close from "../../../../assets/images/icons/Close_icon.png";
import Editicon from "../../../../assets/images/icons/edit_icon.png";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface RequestData {
  page: number;
  size: number;
}

interface MasterProductEdit {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
}
export default function MasterProductEdit(props: MasterProductEdit) {
  const dispatch = useAppDispatch();
  const { onClose, requestData } = props; // Destructure the props

  const [textFieldValue, setTextFieldValue] = React.useState("");
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [isEditEnabled, setIsEditEnabled] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [FormError, setFormError] = useState<boolean>(false);
  const [sucessMessage, setSucessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const ProductUomEditData = useAppSelector(
    (state: any) => state.productUomFetch.ProductUomEdit
  );

  useEffect(() => {
    setFormData({
      uomCode: ProductUomEditData.uomCode,
      uomName: ProductUomEditData.uomName,
    });
  }, [ProductUomEditData]);

  const [formData, setFormData] = React.useState({
    uomCode: "",
    uomName: "",
  });

  const [errors, setErrors] = useState<FormErrors>({
    uomCode: "",
    uomName: "",
  });

  const validateFormData = (data: ProductUomFormValues): FormErrors => {
    const errors: FormErrors = {};
    const fieldsToValidate: (keyof ProductUomFormValues)[] = [
      "uomCode",
      "uomName",
    ];

    fieldsToValidate.forEach((field) => {
      if (!data[field]) {
        errors[field] = `${field} is required`;
      }
    });
    return errors;
  };

  const handleEditClick = () => {
    setIsEditEnabled(false); // Disable edit when clicked
  };
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    formData: ProductUomFormValues
  ): Promise<void> => {
    event.preventDefault();
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await dispatch(
          productUomUpdate({ id: ProductUomEditData.id, formData })
        );
        const successMessage = response.message;
        setFormError(true);
        setSucessMessage(successMessage);
        dispatch(ProductUomFetchData(requestData));
        setTimeout(() => onClose(), 2000);
      } catch (error: any) {
        if (error.response.data.data) {
          const errorMessage = error.response.data.data;
          setErrors(errorMessage);
        } else {
          setErrorMessage("something went Wrong");
        }
      }
    } else {
      setErrors(validationErrors);
    }
  };
  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_stl"
        // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 533,
              md: 536,
              sm: 600,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "95%", margin: "auto" }} role="presentation">
            <Grid container className="crt-header" mt={4}>
              <Typography gutterBottom className="crt-header-lable">
                Edit UOM Master
              </Typography>

              <Grid item>
                <img
                  src={Editicon}
                  alt={Editicon}
                  onClick={handleEditClick}
                  style={{ width: "24px", height: "24px", marginRight: "15px" }}
                />
                <img
                  src={Close}
                  alt={Close}
                  onClick={onClose}
                  style={{ width: "24px", height: "24px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Content */}
          <Box
            mt={3}
            sx={{ height: "100%", marginBottom: "70px" }}
            className="create-form"
          >
            {/* Your scrollable content */}
            <form onSubmit={(event) => handleSubmit(event, formData)}>
              <Grid
                item
                sx={{ width: "95%", margin: "auto", marginBottom: "70px" }}
              >
                <Grid item>
                  <Typography className="crt-fnt-lable">UOM CODE</Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Enter Code"
                      name="uomCode"
                      disabled={isEditEnabled}
                      onChange={handleInputChange}
                      value={formData.uomCode}
                      error={errors.uomCode ? true : false}
                      helperText={errors.uomCode && errors.uomCode}
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">UOM NAME</Typography>
                  <Grid item xs={12}>
                    <TextField
                      className="Crt-txt-box"
                      placeholder="Enter Name"
                      name="uomName"
                      disabled={isEditEnabled}
                      onChange={handleInputChange}
                      value={formData.uomName}
                      error={errors.uomName ? true : false}
                      helperText={errors.uomName && errors.uomName}
                    />
                  </Grid>
                </Grid>
             
              </Grid>
              <Grid item mt={2} className="product_Create_footer">
                <Button
                  className="crt-btn-cancel"
                  onClick={() => {
                    onClose();
                  }}
                >
                  {" "}
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="crt-btn-colr"
                  type="submit"
                  sx={{ mr: 4 }}
                >
                  {" "}
                  Update
                </Button>
              </Grid>
            </form>

            {/* More content... */}
            {FormError ? (
              sucessMessage ? (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="success">{sucessMessage}</Alert>
                </Snackbar>
              ) : (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="error">{errorMessage}</Alert>
                </Snackbar>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
