import * as React from "react";
import Box from "@mui/material/Box";
import { Grid, Paper, Typography, Button, Card } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PlaceIcon from "@mui/icons-material/Place";
import EditIcon from "@mui/icons-material/Edit";
import type { TableColumnsType } from 'antd';
import SearchBar from '@mkyy/mui-search-bar';
import Customerview from "./CustomerTabview";
import CustomerTableEdit from "./CustomerTabEdit";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  branch: string;
  Type: string;
  DistrCustome: string;
  CustomerCode: string;
  companycc: string;
  customername: string;
  phone: string;
  Status: string;
  mobilno: string;
  action: string;
}

export default function CustomerTable() {


  const [isViewing, setIsViewing] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  const [textFieldValue, setTextFieldValue] = React.useState('');

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // search function logic here
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "branch",
      title: "Distributor Branch",
      // sorter: (a, b) => a.branch.length - b.branch.length,
    },
    {
      dataIndex: "Type",
      title: "Type",
      // sorter: (a, b) => a.Type.length - b.Type.length,
    },
    {
      dataIndex: "DistrCustome",
      title: "	Distr Customer Code",
      // sorter: (a, b) => a.DistrCustome.length - b.DistrCustome.length,
    },
    {
      dataIndex: "CustomerCode",
      title: "Customer Code",
      // sorter: (a, b) => a.CustomerCode.length - b.CustomerCode.length,
    },
    {
      dataIndex: "companycc",
      title: "Company Customer Code",
      // sorter: (a, b) => a.companycc.length - b.companycc.length,
    },
    {
      dataIndex: "customername",
      title: "Customer Name",
      // sorter: (a, b) => a.customername.length - b.customername.length,
      render: (_: any, record: DataType) => (
        <>
        <div style={{display:'flex', justifyContent:'space-around'}}>
          <span>{record.customername}</span>
          <PlaceIcon
            // onClick={() => handleViewClick(params.row.id)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
          </div>
        </>
      ),
    },
    {
      dataIndex: "phone",
      title: "Phone",
      // sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      dataIndex: "Status",
      title: "Status",
      // sorter: (a, b) => a.Status.length - b.Status.length,
    },
    {
      dataIndex: "mobilno",
      title: "MobileNo",
      // sorter: (a, b) => a.mobilno.length - b.mobilno.length,
    },

    {
      dataIndex: "action",
      title: "Action",
      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            onClick={(event) => handleViewClick(event)}
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
          />
          <EditIcon
            onClick={(event) => handleEditClick(event)}
            style={{
              cursor: "pointer",
              marginLeft: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      branch: "GT1000020102860002010286",
      Type: "Retailer",
      DistrCustome: "GT1000020075160002007516-SLM0021576214474",
      CustomerCode: "0002671517",
      companycc: "4001818972",
      customername: "Prashant Street",
      phone: "7668494495",
      Status: "InActive",
      mobilno: "7668494495",
      action: "",
    },
    {
      key: 2,
      branch: "GT1000020117060002011706",
      Type: "Retailer",
      DistrCustome: "	GT1000020075160002007516-SLM0021576214474",
      CustomerCode: "0002874369M",
      companycc: "4001818972",
      customername: "Prashant Street",
      phone: "7668494495",
      Status: "InActive",
      mobilno: "7668494495",
      action: "",
    },
    {
      key: 3,
      branch: "4132758428",
      Type: "Retailer",
      DistrCustome: "4132758428-SLM0011629955659",
      CustomerCode: "0002952993",
      companycc: "0002952993",
      customername: "D K TRADING",
      phone: "9423348086",
      Status: "Active",
      mobilno: "9423348086",
      action: "",
    },
    {
      key: 4,
      branch: "GT1000020149540002014954",
      Type: "Retailer",
      DistrCustome: "GT1000020075160002007516-SLM0021576214474",
      CustomerCode: "0003857943M",
      companycc: "4001818972",
      customername: "Shri balaji",
      phone: "7668494495",
      Status: "Active",
      mobilno: "7668494495",
      action: "",
    },
    {
      key: 5,
      branch: "GT1000020110200002011020",
      Type: "Retailer",
      DistrCustome: "4001798765-SLM0011578644247",
      CustomerCode: "0042694551",
      companycc: "4001899022",
      customername: "Prashant Street",
      phone: "9930398804",
      Status: "Active",
      mobilno: "9930398804",
      action: "",
    },
    {
      key: 6,
      branch: "GT1000020114990002011499",
      Type: "Retailer",
      DistrCustome: "4001798765-SLM0011578644247",
      CustomerCode: "0042720327",
      companycc: "4001899022",
      customername: "Shri balaji",
      phone: "9930398804",
      Status: "Active",
      mobilno: "9930398804",
      action: "",
    },
    {
      key: 7,
      branch: "GT1000020128360002012836",
      Type: "Retailer",
      DistrCustome: "	4001798765-SLM0011578644247",
      CustomerCode: "0043252375M",
      companycc: "4001899022",
      customername: "Shri balaji",
      phone: "9930398804",
      Status: "InActive",
      mobilno: "9930398804",
      action: "",
    },
    {
      key: 8,
      branch: "GT1000020018380002001838",
      Type: "Retailer",
      DistrCustome: "	4001798765-SLM0011578644247",
      CustomerCode: "0043550725M",
      companycc: "4001899022",
      customername: "Shri balaji",
      phone: "9930398804",
      Status: "InActive",
      mobilno: "9930398804",
      action: "",
    },
  ];
  const [showForm, setShowForm] = React.useState(false);

  const handleViewClick = (event: React.MouseEvent<unknown>) => {
    setIsViewing(!isViewing);
  };
  const handleEditClick = (event: React.MouseEvent<unknown>) => {
    setIsEdit(!isEdit);

  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className="mar-tb">
          <Typography>
            <b>Customer</b>
          </Typography>
        </Grid>
        {isEdit ? (
          <CustomerTableEdit onBack={(event) => handleEditClick(event)} />
        ) : isViewing ? (
          <Customerview onBack={(event) => handleViewClick(event)} />
        ) : (
          <Box>
            <Grid item sx={{ mt: 2 }}>
              <Box>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "end", mb: 2 }}
                >
                  <SearchBar
                    className="search-table"
                    value={textFieldValue}
                    onChange={(newValue) => {
                      setTextFieldValue(newValue);
                      handleSearch();
                    }}
                  />
                </Grid>
                <CommonTable
                  //  rowSelection={rowSelection}
                  data={data}
                  columns={columns}
                />
              </Box>
            </Grid>
          </Box>
        )}
      </Box>
    </>
  );
}
