import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { loginSuccess } from "../Redux/AuthLogin/action";
import { connect } from "react-redux";

const AuthLogin: React.FC = () => {
  const navigator = useNavigate();
  const [distributionCode, setDistributionCode] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = () => {
    navigator("/Dashboard");
    const user = { username: "example" };
    loginSuccess(user);
    if (distributionCode === "AD001" && password === "12345678") {
      localStorage.setItem("ad-distributionCode", distributionCode);
      localStorage.setItem("password", password);
    }
  };
 

  return (
    <Box>
      <Grid container style={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7.5}
          lg={7.5}
          xl={7.5}
          className="leftimg"
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={4.5}
          lg={4.5}
          xl={4.5}
          component={Paper}
          elevation={6}
          className="sigintop"
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "39%",
            }}
          >
            <img
              src="assets/Images/LoginImage/Altec Logo.png"
              height="70px"
              alt="Altec Logo"
            ></img>

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ marginTop: "16%" }}
            >
              <Box className="mtsize">
                <Typography
                  style={{
                    display: "flex",
                    fontSize: "11px",
                    fontWeight: "700",
                  }}
                  id="DisText"
                >
                  Distributor Code
                </Typography>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  // name="dis_code"
                  type="text"
                  // id="dis_code"
                  value={distributionCode}
                  onChange={(e) => setDistributionCode(e.target.value)}
                  className="mt_root"
                  autoComplete="off"
                  InputProps={{
                    style: {
                      height: "35px",
                    },
                  }}
                />
                <span
                  id="disError"
                  style={{ color: "red" }}
                  className="error"
                ></span>
              </Box>
              <Box style={{ marginTop: "19px" }} className="mtsize">
                <Typography
                  style={{
                    display: "flex",
                    fontSize: "11px",
                    fontWeight: "700",
                  }}
                  id="DisText"
                >
                  Password
                </Typography>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  // name="pass_word"
                  type="password"
                  // id="pass_word"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mt_root"
                  autoComplete="off"
                  InputProps={{
                    style: {
                      height: "35px",
                    },
                  }}
                />
                <span
                  id="passError"
                  style={{ color: "red" }}
                  className="error"
                ></span>
              </Box>
              <Box className="forgot_flex">
                <Typography
                  className="gradient-text fontCus-style cursor_pointer"
                >
                  Forgot Password
                </Typography>
              </Box>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  //  background: "linear-gradient(90deg, #1677FF 0%, #003EB3 100%)",
                  background:
                    "linear-gradient(90deg, #614FB3 0%, #944986 100%)",
                  marginTop: "42px",
                  // width: "220px",
                  textTransform: "none",
                }}
              >
                Sign In
              </Button>
              <Grid container sx={{ marginTop: "91px" }}>
                <Grid item xs>
                  <Typography
                    style={{
                      fontWeight: "400",
                      fontSize: "12px",
                      opacity: "50%",
                    }}
                  >
                    Copyright © 2024 CITPL. All right reserved.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AuthLogin;
