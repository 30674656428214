import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Grid,
  Paper,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  TextField,
  Card,
} from "@mui/material";

import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  jcMonth: string;
  StartDate: string;
  EndDate: string;
  Months: string;
  Weeks: string;
  action: string;
}

interface jcViewProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const ViewJc: React.FC<jcViewProps> = ({ onBack }) => {
  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
   // search function logic 
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "jcMonth",
      title: "Jc Month",
      sorter: (a, b) => a.jcMonth.length - b.jcMonth.length,
    },
    {
      dataIndex: "StartDate",
      title: "Start Date",
      sorter: (a, b) => a.StartDate.length - b.StartDate.length,
    },
    {
      dataIndex: "EndDate",
      title: "End Date",
      sorter: (a, b) => a.EndDate.length - b.EndDate.length,
    },
    {
      dataIndex: "Months",
      title: "Days ",
      sorter: (a, b) => a.Months.length - b.Months.length,
    },
    {
      dataIndex: "Weeks",
      title: "Quarter",
      sorter: (a, b) => a.Weeks.length - b.Weeks.length,
    },
  ];
  const data: DataType[] = [
    {
      key: 1,
      jcMonth: "ComapanyName1",
      StartDate: "16/12/2020",
      EndDate: "16/12/2020",
      Months: "52",
      Weeks: "55",
      action: "",
    },
    {
      key: 2,
      jcMonth: "ComapanyName2",
      StartDate: "16/12/2020",
      EndDate: "17/12/2022",
      Months: "52",
      Weeks: "24",
      action: "",
    },
    {
      key: 3,
      jcMonth: "ComapanyName3",
      StartDate: "16/12/2020",
      EndDate: "16/12/2023",
      Months: "22",
      Weeks: "84",
      action: "",
    },
    {
      key: 4,
      jcMonth: "ComapanyName4",
      StartDate: "16/12/2020",
      EndDate: "16/4/2024",
      Months: "84",
      Weeks: "55",
      action: "",
    },
    {
      key: 5,
      jcMonth: "ComapanyName5",
      StartDate: "16/12/2020",
      EndDate: "16/12/2025",
      Months: "77",
      Weeks: "74",
      action: "",
    },
    {
      key: 6,
      jcMonth: "ComapanyName6",
      StartDate: "16/12/2020",
      EndDate: "16/12/2024",
      Months: "55",
      Weeks: "51",
      action: "",
    },
  ];

  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Box className="card-form">
        <form noValidate>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Comapny<span></span>
              </InputLabel>

              <FormControl fullWidth>
                <Select className="selct-box" id="demo-simple-select">
                  <MenuItem value={2021}>Comapny1</MenuItem>
                  <MenuItem value={2022}>Comapny2</MenuItem>
                  <MenuItem value={2023}>Comapny3</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Year
              </InputLabel>
              <FormControl className="selct-box">
                <Select id="demo-simple-select">
                  <MenuItem value={2021}>2021</MenuItem>
                  <MenuItem value={2022}>2022</MenuItem>
                  <MenuItem value={2023}>2023</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Type
              </InputLabel>
              <FormControl className="selct-box">
                <Select id="demo-simple-select">
                  <MenuItem value={2021}>2021</MenuItem>
                  <MenuItem value={2022}>2022</MenuItem>
                  <MenuItem value={2023}>2023</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Weekend
              </InputLabel>
              <FormControl className="selct-box">
                <Select id="demo-simple-select">
                  <MenuItem value={2021}>2021</MenuItem>
                  <MenuItem value={2022}>2022</MenuItem>
                  <MenuItem value={2023}>2023</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Jc Month
              </InputLabel>
              <TextField placeholder="Jc Month" className="Txt-box" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal">Start Date</InputLabel>
              <TextField placeholder="Start Date" className="Txt-box" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal">End Date</InputLabel>
              <TextField placeholder="End Date" className="Txt-box" />
            </Grid>
            <Grid item xs={12} sm={6} justifyContent="space-between">
              <Button
                sx={{ margin: "5px " }}
                variant="contained"
                className="btn-colr"
              >
                Populate Jc
              </Button>
              <Button variant="contained" 
                className="btn-colr"
                >Add Row</Button>
            </Grid>
          </Grid>
          <Box
      
          >
            <Grid item sx={{ mt: 2 }} xs={12}>
              <Grid item  >
                <Typography>Month Details</Typography>
              </Grid >
              <Box>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "end", mb: 2 }}
                >
                  <SearchBar
                    className="search-table"
                    value={textFieldValue}
                    onChange={(newValue) => {
                      setTextFieldValue(newValue);
                      handleSearch();
                    }}
                  />
                </Grid>
                <CommonTable
                  // rowSelection={rowSelection}
                  data={data}
                  columns={columns}
                />
              </Box>
            </Grid>
          </Box>
          <Grid item xs={12} mt={2} display="flex" justifyContent="center">
            <Button
              variant="contained"
              onClick={handleBackClick}
              className="btn-colr"
              style={{ marginLeft: "10px" }}
            >
              Back
            </Button>
          </Grid>
        </form>
      </Box>
    </>
  );
};
export default ViewJc;
