import React, { useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  Button,
  Grid,
  InputLabel,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Card,
  Select,
  MenuItem,
  TextareaAutosize,
  Checkbox,
  Typography,
  FormLabel,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import UndoIcon from "@mui/icons-material/Undo";

interface GSTTaxstructureviewProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const GSTTaxstructureview: React.FC<GSTTaxstructureviewProps> = ({ onBack }) => {
  const [isViewing, setIsViewing] = React.useState(false);

  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };

  const options = ["Chhattisgarh", "Jammu And Kashmir", "Madhya Pradesh"];
  const TaxCode = ["CGST1", "CGST2", "CGST3", "CGST4"];
  const Apply = ["Purchase Rate", ""];
  const Rate = ["Selling Rate", ""];
  const Discount = ["Reduce", "None"];

  return (
    <Box>
      <form>
        <Grid container mt={3} columnSpacing={{ xs: 3, sm: 3 }}>
          <Grid item xs={12} sm={3}>
            <Grid item className="fnt-lable">Geo Hierarchy Value</Grid>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label" className="selct-box">
                {options.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography className="fnt-lable">Tax Type</Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="CGST"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="CGST"
                />
                <FormControlLabel
                  value="IGST"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label="IGST"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid item className="fnt-lable">Effective From</Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item>
                <DatePicker name="startDate" sx={{ width: "90%" }} />
              </Grid>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className="fnt-lable"> Tax Code</Typography>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label" className="selct-box">
                {TaxCode.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container mt={3} rowGap={2} columnSpacing={{ xs: 3, sm: 1 }}>
          <Grid item xs={12} sm={2} md={1}>
            <FormControlLabel
              control={<Checkbox className="check-box" />}
              label="CGST"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className="fnt-lable">Input in (%)</Typography>

            <TextField variant="outlined" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className="fnt-lable">Apply on</Typography>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label"className="selct-box" >
                {Apply.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className="fnt-lable">Output in (%)</Typography>

            <TextField variant="outlined" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography className="fnt-lable">Apply on</Typography>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label" >
                {Rate.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container mt={2} columnSpacing={{ xs: 3, sm: 1 }}>
        <Grid item xs={12} sm={2} md={1}>

            <FormControlLabel
              control={<Checkbox className="check-box" />}
              label="SGST"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className="fnt-lable">Input in (%)</Typography>

            <TextField variant="outlined" className="Txt-box"/>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className="fnt-lable">Apply on</Typography>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label" className="selct-box">
                {Apply.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className="fnt-lable"> Output in (%)</Typography>

            <TextField variant="outlined" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography className="fnt-lable">Apply on</Typography>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label">
                {Rate.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container mt={2} columnSpacing={{ xs: 3, sm: 1 }}>
        <Grid item xs={12} sm={2} md={1}>

            <FormControlLabel
              control={<Checkbox className="check-box" />}
              label="Additional"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className="fnt-lable">Input in (%)</Typography>

            <TextField variant="outlined" className="Txt-box"/>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography className="fnt-lable">Output in (%)</Typography>

            <TextField variant="outlined" className="Txt-box"/>
          </Grid>
        </Grid>
        <Grid container mt={2} columnSpacing={{ xs: 3, sm: 3 }}>
        <Grid item xs={12} sm={2} md={1}>

            <FormControlLabel
              control={<Checkbox className="check-box" />}
              label="CESS"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className="fnt-lable">Input in (%)</Typography>

            <TextField variant="outlined" className="Txt-box"/>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className="fnt-lable">Apply on</Typography>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label" className="selct-box">
                {Apply.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className="fnt-lable">Output in (%)</Typography>

            <TextField variant="outlined" className="Txt-box" />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography className="fnt-lable">Apply on</Typography>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label">
                {Rate.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item mt={3} className="header-lable">
          EFFECT OF DISCOUNTS
        </Grid>
        <Grid container mt={1 } columnSpacing={{ xs: 3, sm: 3 }}>
          <Grid item xs={12} sm={3}>
            <Typography className="fnt-lable">Scheme Discount </Typography>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label" className="selct-box">
                {Discount.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container mt={1} columnSpacing={{ xs: 3, sm: 3 }}>
          <Grid item xs={12} sm={3}>
            <Typography className="fnt-lable">Cash Discount</Typography>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label" className="selct-box">
                {Discount.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container mt={1} columnSpacing={{ xs: 3, sm: 3 }}>
          <Grid item xs={12} sm={3}>
            <Typography className="fnt-lable">DB Discount</Typography>
            <FormControl fullWidth>
              <Select labelId="demo-simple-select-label" className="selct-box">
                {Discount.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item mt={3} sx={{ display: "flex", justifyContent: "center" }}>
         
          <Button
            className="next-button"
            variant="outlined"
            onClick={handleBackClick}
          >
          
          Back
          </Button>
        </Grid>
      </form>
    </Box>
  );
};

export default GSTTaxstructureview;
