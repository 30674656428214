import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  Tab,
  Tabs,
  Typography,
  Card,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useNavigate } from "react-router-dom";
import MannualLeftTable from "./MannualLeftTable";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { ReactComponent as FocusSVG } from "../../../assets/images/icons/bf_focus.svg";
import { ReactComponent as MustSVG } from "../../../assets/images/icons/bf_must.svg";
import { ReactComponent as RecomendSVG } from "../../../assets/images/icons/bf_recomend.svg";
import { ReactComponent as SchemeSVG } from "../../../assets/images/icons/bf_scheme.svg";
import { ReactComponent as ActFocusSVG } from "../../../assets/images/icons/act_focus.svg";
import { ReactComponent as ActMustSVG } from "../../../assets/images/icons/act_mustSell.svg";
import { ReactComponent as ActRecomendSVG } from "../../../assets/images/icons/act_reccomend.svg";
import { ReactComponent as ActSchemeSVG } from "../../../assets/images/icons/act_scheme.svg";
import { useAppSelector } from "../../../hooks/useFetch";
const useStyles = makeStyles((theme) => ({
  gradientText: {
    background: "linear-gradient(90.19deg, #614FB3 0%, #944986 100%)",
    border: "1px solid",
    borderRadius: "30px",
    alignItems: "center",
    display: "flex",
    padding: "0px 12px",
    justifyContent: "center",
    height: "27px",
    gap: "12px",
    color: "#FFF",
  },
  customTab: {
    border: "1px solid #e1e7ed",
    fontSize: "11px",
    padding: "0px 12px",
    borderRadius: "30px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: "27px",
    backgroundColor: "", // Adjust the color as needed
  },
}));
const useStyless = makeStyles({
  customScroll: {
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "blue",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "darkblue",
    },
  },
});

const FocusIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={FocusSVG} viewBox="0 0 24 24" />
);
const MustIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={MustSVG} viewBox="0 0 24 24" />
);
const RecommendIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={RecomendSVG} viewBox="0 0 24 24" />
);
const SchemeIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={SchemeSVG} viewBox="0 0 24 24" />
);
const ActFocusIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={ActFocusSVG} viewBox="0 0 24 24" />
);
const ActMustIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={ActMustSVG} viewBox="0 0 24 24" />
);
const ActRecommendIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={ActRecomendSVG} viewBox="0 0 24 24" />
);
const ActSchemeIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} component={ActSchemeSVG} viewBox="0 0 24 24" />
);

const getIcon = (route: string, isActive: boolean) => {
  switch (route) {
    case "focused":
      return isActive ? (
        <ActFocusIcon className="wid_schem mar_ryt_mantab" />
      ) : (
        <FocusIcon className="wid_schem" />
      );
    case "must-sell":
      return isActive ? (
        <ActMustIcon className="wid_schem mar_ryt_mantab" />
      ) : (
        <MustIcon className="wid_schem" />
      );
    case "recommended":
      return isActive ? (
        <ActRecommendIcon className="wid_schem mar_ryt_mantab" />
      ) : (
        <RecommendIcon className="wid_schem" />
      );
    case "scheme":
      return isActive ? (
        <ActSchemeIcon className="wid_schem mar_ryt_mantab" />
      ) : (
        <SchemeIcon className="wid_schem" />
      );
    default:
      return null;
  }
};

const OrderTabs = [
  { Route: "all-products", label: "All Products" },
  { Route: "last-order", label: "Last Order" },
  { Route: "focused", label: "Focused" },
  { Route: "must-sell", label: "Must Sell" },
  { Route: "recommended", label: "Recommended" },
  { Route: "scheme", label: "Scheme" },
];

export default function MannualReviewDetails() {
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const classes = useStyles();
  const navigator = useNavigate();
  const [subTabActive, setSubTabActive] = React.useState<string>(
    OrderTabs[0].Route
  );
  const previousData = useAppSelector(
    (state) => state.manualOrderdata.previousSelectedData
  );

  const backtoMannualOrder = () => {
    if (previousData.length == 0) {
      navigator("/xdm/sales/manual-order");
    } else {
      setDeleteIndex(previousData.length);
    }
  };

  const location = useLocation();
  const getQueryParams = (query: any) => {
    return new URLSearchParams(query);
  };

  const queryParams = getQueryParams(location.search);
  const outletName = queryParams.get("outletName");
  const outletcode = queryParams.get("outletcode");
  const handleTabChange = (event: any, newValue: any) => {
    setSubTabActive(newValue);
  };
  const handleDeleteCancel = () => {
    setDeleteIndex(null);
  };
  const handleRemove = () => {
    navigator("/xdm/sales/manual-order");
  };

  const scroll = useStyless();
  return (
    <Card
      className="Mannual_Card "
      sx={{
        overflowY: {
          xs: "auto",
          sm: "hidden",
          md: "hidden",
          lg: "hidden",
          xl: "hidden",
        },
        borderRadius: "10px",
        height: {
          xs: "calc(100vh - 150px)",
          sm: "88vh",
          md: "88vh",
          lg: "87vh ",
          xl: "92vh",
        },
      }}
    >
      <Grid item sx={{ width: "100%" }}>
        <Grid container className="crt-header" mt={1} mb={0} p={2} pb={0}>
          <Typography gutterBottom className="outlet-header-lable">
            <ArrowBackIos
              sx={{
                fontSize: "10px",
                cursor: "pointer",
                marginRight: "5px",
              }}
              onClick={backtoMannualOrder}
            />
            Manual Order ({outletName} / {outletcode})
          </Typography>
        </Grid>
        <Grid container>
          <Grid item>
            <Grid
              item
              className="Mannual_SubTab_Card  Mannual_tab_Card"
              sx={{
                display: "flex",
                justifyContent: "center",
                boxShadow: "none",
                borderRadius: "0px",
                alignItems: "center",
                paddingLeft: { sm: 0, md: 0, lg: 0, xl: "16px" },
              }}
              xs={12}
              sm={4.8}
              lg={4.8}
            >
              <Tabs
                className="Mannual_subtab mannual_tab"
                value={subTabActive}
                onChange={handleTabChange}
                variant="scrollable"
                TabIndicatorProps={{
                  style: { display: "none" },
                }}
              >
                {OrderTabs.map((subTab, index) => (
                  <Tab
                    value={subTab.Route}
                    className="tab_hover"
                    key={index}
                    label={
                      <Typography
                        className={`${
                          subTabActive === subTab.Route
                            ? classes.gradientText
                            : classes.customTab
                        } ${classes.customTab}`}
                      >
                        {/* {subTab.icon} */}
                        {getIcon(subTab.Route, subTabActive === subTab.Route)}
                        {subTab.label}
                      </Typography>
                    }
                    iconPosition="start"
                  />
                ))}
              </Tabs>
            </Grid>
            {subTabActive === "all-products" && (
              <MannualLeftTable type={subTabActive} />
            )}

            {subTabActive === "last-order" && (
              <MannualLeftTable type={subTabActive} />
            )}
            {subTabActive === "focused" && <MannualLeftTable type="Focus" />}
            {subTabActive === "must-sell" && <MannualLeftTable type="MS SKU" />}
            {subTabActive === "recommended" && (
              <MannualLeftTable type="Recommended" />
            )}
            {subTabActive === "scheme" && <MannualLeftTable type="Scheme" />}

            <Dialog
              open={deleteIndex !== null}
              // onClose={handleDeleteCancel}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent sx={{ width: "350px" }}>
                <DialogContentText
                  className="dialog-title-remove"
                  id="alert-dialog-description"
                  style={{ paddingBottom: "10px" }}
                >
                  Are you sure you want to discard the manual order?
                </DialogContentText>
                <DialogContentText
                  id="alert-dialog-description"
                  style={{ fontSize: "12px", color: "black" }}
                >
                  Cancel to return to the order without discarding, or discard
                  to proceed with discarding the manual order.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  className="crt-btn-cancel"
                  style={{
                    height: "30px",
                    width: "25%",
                    marginRight: "3px",
                    fontSize: "12px",
                  }}
                  onClick={handleDeleteCancel}
                >
                  {" "}
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="crt-btn-colr"
                  style={{ height: "30px", width: "25%", fontSize: "12px" }}
                  type="submit"
                  sx={{ mr: 2 }}
                  onClick={handleRemove}
                >
                  {" "}
                  Discard
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={7} lg={7}>
            <Card className="b-radius-0 main-card">
              <Box>Add Products to Cart</Box>
            </Card>
          </Grid> */}
        </Grid>
      </Grid>
    </Card>
  );
}
