import { Box, IconButton, Toolbar, Link, useMediaQuery, Theme, Grid, Stack, Card, Tabs, Tab } from '@mui/material';
import * as React from 'react';

const Settings: React.FC = () => {

    return (
     
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" >
                <path d="M7 8.5C7 5.46243 9.46243 3 12.5 3V3C15.5376 3 18 5.46243 18 8.5V10.5C18 12.1228 18.5263 13.7018 19.5 15L19.7143 15.2857C19.7873 15.383 19.8237 15.4316 19.8449 15.4651C20.2476 16.1012 19.8295 16.9374 19.079 16.9969C19.0396 17 18.9788 17 18.8571 17H6.14286C6.02124 17 5.96044 17 5.921 16.9969C5.17047 16.9374 4.75236 16.1012 5.1551 15.4651C5.17626 15.4316 5.21275 15.383 5.28571 15.2857L5.5 15C6.47367 13.7018 7 12.1228 7 10.5V8.5Z" stroke="white" />
                <path d="M10.0852 19.147C10.2276 19.6786 10.5415 20.1484 10.9781 20.4834C11.4147 20.8184 11.9497 21 12.5 21C13.0503 21 13.5853 20.8184 14.0219 20.4834C14.4585 20.1484 14.7724 19.6786 14.9148 19.147" stroke="white" strokeLinecap="round" />
            </svg>
    );
};

export default Settings;