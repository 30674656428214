import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import createAxiosInstance from "../../../hooks/useFetch";
import { ProductUomFormValues } from "../../../typescript/ProductUom";

const axiosInstance = createAxiosInstance();

// Define your initial state interface
interface UomState {
  formData: any[]; // Adjust the type according to your actual data structure
  loading: boolean;
  error: string | null;
  editedItem: ProductUomFormValues;
}

// Modify the fetchUomData action creator to accept page and size parameters
export const ProductUomFetchData = createAsyncThunk(
  "uom/fetchData",
  async ({ page, size }: { page: number; size: number }) => {
    try {
      const response = await axiosInstance.get("product-uom/get", {
        params: { page, size }, // Use the provided page and size parameters
      });
      return response.data.data;
    } catch (error) {
      throw Error("Failed to fetch UOM data");
    }
  }
);

export const ProductUomSubmitForm = createAsyncThunk(
  "form/addFormDataAsync",
  async (formData: ProductUomFormValues) => {
    const response = await axiosInstance.post("product-uom/create", formData);
    return response.data;
  }
);

export const productUomEdit = createAsyncThunk(
  "uom/edit",
  async ({ id }: { id: string }) => {
    const response = await axiosInstance.get(`product-uom/get-byid/${id}`);
    const existinguser = response.data;
    console.log(existinguser, "existinguser");
    return existinguser;
  }
);
export const productUomUpdate = createAsyncThunk(
  "uom/update",
  async ({ id, formData }: { id: string; formData: ProductUomFormValues }) => {
    const response = await axiosInstance.put(
      `product-uom/update/${id}`,
      formData
    );
    return response.data;
  }
);

// Define your slice name and initial state
const uomSlice = createSlice({
  name: "uom",
  initialState: {
    formData: [],
    loading: false,
    error: null,
    editedItem: [],
  } as UomState,
  reducers: {
    ProductUomSubmitForm: (state, action) => {
      state.formData.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ProductUomFetchData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ProductUomFetchData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        // Merge the fetched data with existing state
        state.formData = action.payload;
      })
      .addCase(ProductUomFetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch UOM data";
      })
      .addCase(productUomEdit.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        // Replace the existing item with the edited item
        const editedItemIndex = state.formData.findIndex(
          (item) => item.id === action.payload.id
        );
        if (editedItemIndex == -1) {
          state.formData[editedItemIndex] = action.payload;
          state.editedItem = action.payload.data;
        }
      })
      .addCase(productUomUpdate.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        // Assuming the response contains updated data
        const updatedItemIndex = state.formData.findIndex(
          (item) => item.id === action.payload.id
        );
        if (updatedItemIndex !== -1) {
          state.formData[updatedItemIndex] = action.payload;

          state.formData[updatedItemIndex] = null; // Reset editedItem after successful update
        }
      })
      .addCase(productUomUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to update UOM data";
      });
  },
});

// Export your reducer and actions
export const { reducer: uomReducer, actions: uomActions } = uomSlice;
