import {
  PRODUCT_MASTER_SUBMIT_ERROR, 
 PRODUCT_MASTER_FETCH_DATA,
 PRODUCT_MASTER_EDIT_DATA,
 PRODUCT_MASTER_UPDATE_DATA, 
 PRODUCT_MASTER_UPDATE_ERROR,
} from '../../../actionType'
interface State {
  ProductMasterEdit: [];
  ProductMasterUpdate: [];
  ProductMasterToast: [];
  ProductMasterUpdateToast: [];
  loading: boolean;
  error: string | null;
}


const initialState: State = {
  ProductMasterEdit: [],
  ProductMasterUpdate: [],
  ProductMasterToast: [],
  ProductMasterUpdateToast: [],
  loading: false,
  error: null,
};


const ProductMasterReducer = (state = initialState, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case PRODUCT_MASTER_FETCH_DATA:
      return {
        ...state,
        ProductMasterFetch: action.payload,
      };
    case PRODUCT_MASTER_EDIT_DATA:
      return {
        ...state,
        ProductMasterEdit: action.payload,
      };
    case PRODUCT_MASTER_UPDATE_DATA:
      return {
        ...state,
        ProductMasterUpdate: action.payload,
      };
    case PRODUCT_MASTER_SUBMIT_ERROR:
      return {
        ...state,
        ProductMasterToast: action.payload,
      };
    case PRODUCT_MASTER_UPDATE_ERROR:
      return {
        ...state,
        ProductMasterUpdateToast: action.payload,
      };
    default:
      return state;
  }
};

export default ProductMasterReducer;

