import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";

import SearchBar from "@mkyy/mui-search-bar";
import { drawerWidth } from "../../../../config";
import searchIcon from "../../../../assets/images/icons/Icon 24px.png";
import { IoClose } from "react-icons/io5";

interface ModelProps {
  onClose: (event: React.MouseEvent<unknown>) => void;
}

const SidebarModel: React.FC<ModelProps> = ({ onClose }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onClose(event);
  };

  const alphabets = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode(65 + i)
  );
  const inlineStyle = (pctFill: string) => {
    return {
      background:
        "linear-gradient(90deg, #134758 " +
        pctFill +
        "%, #d3d3d3 " +
        pctFill +
        "%)",
    };
  };
  return (
    <Box
      className="sidebarModelContainer"
      sx={{ width: { sm: "70%", lg: " 68%", xl: "60%" } }}
    >
      <Grid
        item
        sx={{
          width: "93%",
          margin: "auto",
        }}
      >
        <Grid
          item
          container
          mt={3}
          sx={{ height: "35px", borderBottom: "2px solid #b6b0b01a" }}
        >
          <Grid
            item
            xs={10}
            sm={8}
            md={6}
            sx={{ color: "#000000", fontSize: "12px", fontWeight: 600 }}
          >
            Category Level (100)
          </Grid>
          <Grid
            item
            sm={4}
            md={6}
            sx={{ display: "flex", justifyContent: "end" }}
            onClick={handleBackClick}
          >
            <div className="close_icon_btn">
              <IoClose className="Close_icon" />

              {/* <img
                src={closeIcon}
                alt={closeIcon}
                style={{ width: "20px", height: "20px", color: "#28292D" }}
              /> */}
            </div>
          </Grid>
        </Grid>
        <Grid container columnSpacing={2}>
          <Grid item mt={2} xs={12} sm={6} md={4} lg={5}>
            <SearchBar className="search_model" value={""} />
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={7}
            item
            style={{
              paddingTop: "10px",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            {alphabets.map((alphabet, index) => (
              <Grid
                item
                sx={{
                  p: {
                    xs: "3px",
                    sm: "4px",
                    md: "3px",
                    lg: "4.6px",
                    xl: "5px",
                  },

                  fontSize: "12px",
                  fontWeight: "500",
                }}
                key={index}
              >
                {alphabet}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item className="sidebar_scroll">
          <Grid
            container
            direction="column"
            mt={2}
            style={{
              display: "flex",
              height: "100%",
              paddingBottom: "15px",
            }}
          >
            <Grid item sm={3}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                A
              </Typography>

              <Grid style={{ display: "flex", alignItems: "center" }}>
                <Checkbox defaultChecked className="check_box" />
                <Typography
                  style={{
                    color: "#000000",
                    fontSize: "11px",
                    fontWeight: "600",
                  }}
                >
                  Autocare
                </Typography>
              </Grid>
            </Grid>

            <Grid item sm={3}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                B
              </Typography>

              <Grid style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  defaultChecked
                  className="check_box"
                  style={{ fontSize: "10px" }}
                />
                <Typography className="sidebarModel_checkbox">
                  Beverage
                </Typography>
              </Grid>
            </Grid>
            <Grid item sm={3}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                C
              </Typography>

              <Grid style={{ display: "flex", alignItems: "center" }}>
                <Checkbox defaultChecked className="check_box" />
                <Typography className="sidebarModel_checkbox">Chips</Typography>
              </Grid>
            </Grid>
            <Grid item sm={3}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                E
              </Typography>

              <Grid style={{ display: "flex", alignItems: "center" }}>
                <Checkbox defaultChecked className="check_box" />
                <Typography className="sidebarModel_checkbox">
                  Extruded
                </Typography>
              </Grid>
            </Grid>
            <Grid item sm={3}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                F
              </Typography>

              <Grid style={{ display: "flex", alignItems: "center" }}>
                <Checkbox defaultChecked className="check_box" />
                <Typography className="sidebarModel_checkbox">
                  Food Jam
                </Typography>
              </Grid>
            </Grid>
            <Grid item sm={3}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                H
              </Typography>

              <Grid style={{ display: "flex", alignItems: "center" }}>
                <Checkbox defaultChecked className="check_box" />
                <Typography className="sidebarModel_checkbox">
                  Hair Care
                </Typography>
              </Grid>
            </Grid>
            <Grid item sm={3}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                N
              </Typography>

              <Grid style={{ display: "flex", alignItems: "center" }}>
                <Checkbox defaultChecked className="check_box" />
                <Typography className="sidebarModel_checkbox">
                  Namkeen
                </Typography>
              </Grid>
            </Grid>
            <Grid item sm={3}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                S
              </Typography>

              <Grid style={{ display: "flex", alignItems: "center" }}>
                <Checkbox defaultChecked className="check_box" />
                <Typography className="sidebarModel_checkbox">
                  Sweet Shop
                </Typography>
              </Grid>
            </Grid>
            <Grid item sm={3}>
              <Typography
                sx={{
                  color: "#000000",
                  fontWeight: "600",
                  fontSize: "12px",
                  marginLeft: "10px",
                }}
              >
                W
              </Typography>

              <Grid style={{ display: "flex", alignItems: "center" }}>
                <Checkbox defaultChecked className="check_box" />
                <Typography className="sidebarModel_checkbox">
                  Wafers
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          item
          mt={3}
          mb={2}
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Grid item sm={3} lg={2} onClick={handleBackClick}>
            <Button
              className="Close_button"
              variant="outlined"
              sx={{ mr: "10px" }}
            >
              Close
            </Button>
          </Grid>
          <Grid item sm={3} lg={2}>
            <Button
              className="btn-colr "
              variant="contained"
              style={{ width: "90%" }}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default SidebarModel;
