import React, { useRef } from "react";
import {
  Button,
  Grid,
  TextField,
  Box,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  InputLabel,
  Typography,
  Card,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CommonTable from "../../../../components/Tables/CommonTable";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { TableColumnsType } from "antd";
import { Input } from "antd";
import TableSearchDataTable from "../../../../components/Modal/TableSearchModal";
import * as XLSX from "xlsx";
import Budgetplanning from "./BudgetPlanning";

const { Search } = Input;
interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}
interface DataType {
  key: React.Key;
  CustomerCode: string;
  CustomerName:string;
  DistributorCode:string;
  DistributorBranchCode:string;
}
interface ModelDataType {
  key: React.Key;
  RetailerCode: String;
  RetailerChannelName: string;
}

interface RetailerGroupCode {
  key: React.Key;
  RetailerGroup: String;
  RetailgroupName: string;
}
interface Retailesubchannel {
  key: React.Key;
  SubChannelCode: String;
  SubChannelName: string;
}
interface Retaileclass {
  key: React.Key;
  RetailerClassCode: String;
  RetailerClassName: string;
}
interface CustomerAttribute {
  key: React.Key;
  AttributeName: String;
  AttributeValue: string;
}

const CustomerDifinition: React.FC<DistributorTabProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        //   width: 250,
      },
    },
  };
  const handleChanges = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [show, setShow] = React.useState(false);
  const handleclick = () => {
    setShow(!show);
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "CustomerCode",
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Customer Code</Typography>
          </Grid>
          <Grid item >
            <Search
              placeholder="Search code"
             
         
            />
          </Grid>
        </Grid>
      ),
      // sorter: (a, b) => a.Distributor.length - b.Distributor.length,
    },
    {
        dataIndex: "CustomerName",
        title: (
          <Grid container spacing={1} flexDirection={"row"}>
            <Grid item xs={12}>
              <Typography>Customer Name</Typography>
            </Grid>
            <Grid item >
              <Search
                placeholder="Search code"
               
           
              />
            </Grid>
          </Grid>
        ),
        // sorter: (a, b) => a.Distributor.length - b.Distributor.length,
      },
      {
        dataIndex: "DistributorCode",
        title: (
          <Grid container spacing={1} flexDirection={"row"}>
            <Grid item xs={12}>
              <Typography>Distributor Code</Typography>
            </Grid>
            <Grid item >
              <Search
                placeholder="Search code"
               
           
              />
            </Grid>
          </Grid>
        ),
        // sorter: (a, b) => a.Distributor.length - b.Distributor.length,
      },
      {
        dataIndex: "DistributorBranchCode",
        title: (
          <Grid container spacing={1} flexDirection={"row"}>
            <Grid item xs={12}>
              <Typography>Distributor Branch Code</Typography>
            </Grid>
            <Grid item >
              <Search
                placeholder="Search code"
               
           
              />
            </Grid>
          </Grid>
        ),
        // sorter: (a, b) => a.Distributor.length - b.Distributor.length,
      },
  ];

  const data: DataType[] = [
    {
      key: 1,
      CustomerCode: "3023873919",
      CustomerName:"	RS Family Mart Muradnagar",
      DistributorCode:"MT0600032001770003200177",
      DistributorBranchCode:"MT0600032001770003200177",

    },

  ];

  //model table
  const DataStateSelection: ModelDataType[] = [
    {
      key: "1",
      RetailerCode: "T/AB",
      RetailerChannelName: "AMBIENT BEVERAGE",
    },
    {
      key: "2",
      RetailerCode: "CC",
      RetailerChannelName: "COLD CHAIN",
    },
    {
      key: "3",
      RetailerCode: "GT/DA",
      RetailerChannelName: "DAIRY",
    },
  ];
  // retailer channel
  const columnsForStateSelection = [
    {
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Retailer Group Code</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={(e) =>
                handleFilterChangeStateSelection(e.target.value, "RetailerCode")
              }
            />
          </Grid>
        </Grid>
      ),
      dataIndex: "RetailerCode",
      key: "RetailerCode",
    },
    {
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Retailer Group Name</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={(e) =>
                handleFilterChangeStateSelection(
                  e.target.value,
                  "RetailerChannelName"
                )
              }
            />
          </Grid>
        </Grid>
      ),
      dataIndex: "RetailerChannelName",
      key: "RetailerChannelName",
    },
  ];

  const [retailerChannel, setRetailerChannel] =
    React.useState(DataStateSelection);
  const [filteredDataStateSelection, setFilteredDataStateSelection] =
    React.useState(retailerChannel); // Initialize with initial data
  const handleFilterChangeStateSelection = (
    value: string,
    dataIndex: keyof ModelDataType
  ) => {
    if (value === "") {
      setFilteredDataStateSelection(retailerChannel);
      return;
    } else {
      const newData = retailerChannel.filter((item) =>
        String(item[dataIndex]).toLowerCase().includes(value.toLowerCase())
      );
      setFilteredDataStateSelection(newData);
    }
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const [selectionType, setSelectionType] = React.useState<
    "radio" | "checkbox"
  >("checkbox"); // Change 'radio' to 'checkbox'

  // Checkbox row selection
  const rowSelection = {
    type: selectionType, // Assign the value of selectionType
    selectedRowKeys,
    onChange: onSelectChange, // Initialize if needed
  };
  const [openStateSelectionModel, setOpenStateSelectionModel] =
    React.useState(false);

  const HandleStateSelectionClick = (event: React.MouseEvent<unknown>) => {
    setOpenStateSelectionModel(!openStateSelectionModel);
  };

  // Retailer Group
  const RetailerGroup: RetailerGroupCode[] = [
    {
      key: "1",
      RetailerGroup: "MT - NORTHY13",
      RetailgroupName: "11 HOUR",
    },
    {
      key: "2",
      RetailerGroup: "9T0",
      RetailgroupName: "9 HOUR",
    },
    {
      key: "3",
      RetailerGroup: "MT - NORTHY13",
      RetailgroupName: "11 HOUR",
    },
  ];

  const columnsRetailerGroup = [
    {
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Retailer Group Code</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={(e) =>
                handlefilteredRetailerGroup(e.target.value, "RetailerGroup")
              }
            />
          </Grid>
        </Grid>
      ),
      dataIndex: "RetailerGroup",
      key: "RetailerGroup",
    },
    {
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Retailer Group Name</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={(e) =>
                handlefilteredRetailerGroup(e.target.value, "RetailgroupName")
              }
            />
          </Grid>
        </Grid>
      ),
      dataIndex: "RetailgroupName",
      key: "RetailgroupName",
    },
  ];

  const [retailerGroup, setRetailerGroup] = React.useState(RetailerGroup);
  const [filteredRetailerGroup, setFilteredRetailerGroup] =
    React.useState(retailerGroup); // Initialize with initial data
  const handlefilteredRetailerGroup = (
    value: string,
    dataIndex: keyof RetailerGroupCode
  ) => {
    if (value === "") {
      setFilteredRetailerGroup(retailerGroup);
      return;
    } else {
      const newData = retailerGroup.filter((item) =>
        String(item[dataIndex]).toLowerCase().includes(value.toLowerCase())
      );
      setFilteredRetailerGroup(newData);
    }
  };

  const [openRatailergroupModel, setOpenRatailergroupModel] =
    React.useState(false);

  const HandleRetailergroupClick = (event: React.MouseEvent<unknown>) => {
    setOpenRatailergroupModel(!openRatailergroupModel);
  };
  //Retailer Sub Channel
  const Retailesubchannel: Retailesubchannel[] = [
    {
      key: "1",
      SubChannelCode: "MT/MT-AP",
      SubChannelName: "MODERN TRADE/MT-ANDHRA PRADESH",
    },
    {
      key: "2",
      SubChannelCode: "MT/MT-CC",
      SubChannelName: "MODERN TRADE/MT-COLD CHAIN",
    },
    {
      key: "3",
      SubChannelCode: "MT/MT-EAST",
      SubChannelName: "MODERN TRADE/MT-EAST",
    },
  ];

  const columnsRetailersubchannel = [
    {
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Retailer Channel Code/ Sub Channel Code</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={(e) =>
                handleFilteredRetailersubchannel(
                  e.target.value,
                  "SubChannelCode"
                )
              }
            />
          </Grid>
        </Grid>
      ),
      dataIndex: "SubChannelCode",
      key: "SubChannelCode",
    },
    {
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Retailer Channel Name / Sub Channel Name</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={(e) =>
                handleFilteredRetailersubchannel(
                  e.target.value,
                  "SubChannelName"
                )
              }
            />
          </Grid>
        </Grid>
      ),
      dataIndex: "SubChannelName",
      key: "SubChannelName",
    },
  ];

  const [subChannelName, setSubChannelName] = React.useState(Retailesubchannel);
  const [filteredRetailersubchannel, setFilteredRetailersubchannel] =
    React.useState(subChannelName); // Initialize with initial data
  const handleFilteredRetailersubchannel = (
    value: string,
    dataIndex: keyof Retailesubchannel
  ) => {
    if (value === "") {
      setFilteredRetailersubchannel(subChannelName);
      return;
    } else {
      const newData = subChannelName.filter((item) =>
        String(item[dataIndex]).toLowerCase().includes(value.toLowerCase())
      );
      setFilteredRetailersubchannel(newData);
    }
  };

  const [openRetailersubchannelModel, setOpenRetailersunchannelModel] =
    React.useState(false);

  const HandleRetailersubchannelClick = (event: React.MouseEvent<unknown>) => {
    setOpenRetailersunchannelModel(!openRetailersubchannelModel);
  };

  // Retailer class
  const Retaileclass: Retaileclass[] = [
    {
      key: "1",
      RetailerClassCode: "BBINST012A",
      RetailerClassName: "A",
    },
    {
      key: "2",
      RetailerClassCode: "BBINST013A",
      RetailerClassName: "A",
    },
    {
      key: "3",
      RetailerClassCode: "BBINST014A",
      RetailerClassName: "A",
    },
  ];

  const columnsRetailerclass = [
    {
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Retailer Class Code</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={(e) =>
                handleFilteredRetailerclass(e.target.value, "RetailerClassCode")
              }
            />
          </Grid>
        </Grid>
      ),
      dataIndex: "RetailerClassCode",
      key: "RetailerClassCode",
    },
    {
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Retailer Class Name</Typography>
          </Grid>
          <Grid item xs={10}>
            <Search
              placeholder="Search code"
              onChange={(e) =>
                handleFilteredRetailerclass(e.target.value, "RetailerClassName")
              }
            />
          </Grid>
        </Grid>
      ),
      dataIndex: "RetailerClassName",
      key: "RetailerClassName",
    },
  ];

  const [retailerClass, setRetailerClass] = React.useState(Retaileclass);
  const [filteredRetailerclass, setFilteredRetailerclass] =
    React.useState(retailerClass); // Initialize with initial data
  const handleFilteredRetailerclass = (
    value: string,
    dataIndex: keyof Retaileclass
  ) => {
    if (value === "") {
      setFilteredRetailerclass(retailerClass);
      return;
    } else {
      const newData = retailerClass.filter((item) =>
        String(item[dataIndex]).toLowerCase().includes(value.toLowerCase())
      );
      setFilteredRetailerclass(newData);
    }
  };

  const [openRetailerclassModel, setOpenRetailerclassModel] =
    React.useState(false);

  const HandleRetailerclassClick = (event: React.MouseEvent<unknown>) => {
    setOpenRetailerclassModel(!openRetailerclassModel);
  };

  //Customer Attribute

  const CustomerAttribute: CustomerAttribute[] = [
    {
      key: "1",
      AttributeName: "A",
      AttributeValue: "1",
    },
    {
      key: "2",
      AttributeName: "B",
      AttributeValue: "2",
    },
    {
      key: "3",
      AttributeName: "C",
      AttributeValue: "3",
    },
  ];

  const columnsCustomerAttribute = [
    {
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Attribute Name</Typography>
          </Grid>
        </Grid>
      ),
      dataIndex: "AttributeName",
      key: "AttributeName",
    },
    {
      title: (
        <Grid container spacing={1} flexDirection={"row"}>
          <Grid item xs={12}>
            <Typography>Attribute Code</Typography>
          </Grid>
        </Grid>
      ),
      dataIndex: "AttributeValue",
      key: "AttributeValue",
    },
  ];
  const [openCustomerAttribute, setOpenCustomerAttribute] =
    React.useState(false);

  const HandleCustomerAttribute = (event: React.MouseEvent<unknown>) => {
    setOpenCustomerAttribute(!openCustomerAttribute);
  };

  function convertHeadersToXLS(headers: any) {
    const worksheet = XLSX.utils.aoa_to_sheet([headers]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    return blob;
  }

  // Function to download XLS file with headers only
  function downloadXLSWithHeaders(headers: any) {
    const xlsBlob = convertHeadersToXLS(headers);
    const url = URL.createObjectURL(xlsBlob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "exported_headers.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  //
  const headers = ["ID", "Commodity", "Price", "Quantity"];
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <>
      {show ? (
           <Budgetplanning onBack={handleclick} />


      ) : (

        <Box>
          <form>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">Scheme Code</Typography>
                <TextField variant="outlined" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">
                  Scheme Description
                </Typography>
                <TextField variant="outlined" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">Recipient</Typography>
                <TextField variant="outlined" className="Txt-box" />
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 3, sm: 3 }}
              mt={3}
            >
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">Retailer Channel </Typography>
                <TextField variant="outlined" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3} mt={3}>
                <Button
                  sx={{ ml: 1, color: "#fff" }}
                  className="btn-colr"
                  onClick={(event) => HandleStateSelectionClick(event)}
                >
                  ...
                </Button>
                <TableSearchDataTable
                  // rowSelection={rowSelection}
                  data={filteredDataStateSelection}
                  columns={columnsForStateSelection}
                  isModalOpen={openStateSelectionModel}
                  setIsModalOpen={setOpenStateSelectionModel}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">
                  Retailer Sub Channel{" "}
                </Typography>
                <TextField variant="outlined" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3} mt={3}>
                <Button
                  sx={{ ml: 1, color: "#fff" }}
                  className="btn-colr"
                  onClick={(event) => HandleRetailersubchannelClick(event)}
                >
                  ...
                </Button>
                <TableSearchDataTable
                  // rowSelection={rowSelection}
                  data={filteredRetailersubchannel}
                  columns={columnsRetailersubchannel}
                  isModalOpen={openRetailersubchannelModel}
                  setIsModalOpen={setOpenRetailersunchannelModel}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">Retailer Group </Typography>
                <TextField variant="outlined" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3} mt={3}>
                <Button
                  sx={{ ml: 1, color: "#fff" }}
                  className="btn-colr"
                  onClick={(event) => HandleRetailergroupClick(event)}
                >
                  ...
                </Button>
                <TableSearchDataTable
                  // rowSelection={rowSelection}
                  data={filteredRetailerGroup}
                  columns={columnsRetailerGroup}
                  isModalOpen={openRatailergroupModel}
                  setIsModalOpen={setOpenRatailergroupModel}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">Retailer Class </Typography>
                <TextField variant="outlined" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3} mt={3}>
                <Button
                  sx={{ ml: 1, color: "#fff" }}
                  className="btn-colr"
                  onClick={(event) => HandleRetailerclassClick(event)}
                >
                  ...
                </Button>
                <TableSearchDataTable
                  // rowSelection={rowSelection}
                  data={filteredRetailerclass}
                  columns={columnsRetailerclass}
                  isModalOpen={openRetailerclassModel}
                  setIsModalOpen={setOpenRetailerclassModel}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <Typography className="fnt-lable">
                  Customer Attribute{" "}
                </Typography>
                <TextField variant="outlined" className="Txt-box" />
              </Grid>
              <Grid item xs={12} sm={4} md={3} mt={3}>
                <Button
                  sx={{ ml: 1, color: "#fff" }}
                  className="btn-colr"
                  onClick={(event) => HandleCustomerAttribute(event)}
                >
                  ...
                </Button>
                <TableSearchDataTable
                  // rowSelection={rowSelection}
                  data={CustomerAttribute}
                  columns={columnsCustomerAttribute}
                  isModalOpen={openCustomerAttribute}
                  setIsModalOpen={setOpenCustomerAttribute}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="fnt-lable">Scheme Start Date</Typography>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker sx={{ width: "90%" }} />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="fnt-lable">Scheme End Date</Typography>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker sx={{ width: "90%" }} />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                {" "}
                <Button
                  className="btn-colr"
                  sx={{ mt: 3, color: "#fff", width: "100%" }}
                >
                  {" "}
                 Load Customer
                </Button>
              </Grid>

              <Grid item xs={12} sm={2} md={2}>
                {" "}
                <Button
                  className="btn-colr"
                  sx={{ mt: 3, color: "#fff", width: "100%" }}
                >
                  {" "}
                  Select All
                </Button>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <Button
                  sx={{ mt: 3, color: "#fff", width: "100%" }}
                  variant="contained"
                  className="btn-colr"

                  onClick={() => downloadXLSWithHeaders(headers)}
                >
                  Download 
                </Button>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>

                  <Button
                    variant="contained"
                  className="btn-colr"

                    sx={{ mt: 3, color: "#fff", width: "100%" }}
                    onClick={handleButtonClick}
                  >
                    + Select File
                  </Button>
               
                </Grid>

                <Grid item xs={12} sm={2} md={2}>

              <Button
                    variant="contained"
                    sx={{ mt: 3, color: "#fff", width: "100%" }}
                    className="btn-colr"

                    onClick={handleButtonClick}
                  >
                   Upload File
                  </Button>
              </Grid>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 3, sm: 3 }}
                mt={3}
              >
                <Grid item xs={12}>
                  <CommonTable
                    // rowSelection={rowSelection}
                    data={data}
                    columns={columns}
                  />
                </Grid>

              
              </Grid>
            </Grid>
            <Grid
              item
              mt={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item sx={{ ml: 2 }}>
                <Button variant="outlined" onClick={handleBackClick}>
                  Back
                </Button>
              </Grid>
              <Grid item sx={{ ml: 2 }}>
                <Button
                  className="btn-colr "
                  variant="contained"
                  onClick={handleclick}
                >
                  <RedoOutlinedIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />
                  Next
                </Button>
              </Grid>
              <Grid item sx={{ ml: 2 }}>
                <Button variant="outlined" onClick={handleBackClick}>
                  <CancelIcon
                    sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
                  />{" "}
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </>
  );
};
export default CustomerDifinition;
