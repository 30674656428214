import React, { useRef } from "react";
import {
  Button,
  Grid,
  TextField,
  Box,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";

  interface CustomerotherProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
    onNext:()=>void;
  }

  
  const Customerother : React.FC<CustomerotherProps> = ({ onBack, onNext}) => {
    const handleBackClick = (event: React.MouseEvent<unknown>) => {
      onBack(event); // Pass the event argument to onBack function
    };
 
  const status = [
    { value: "", label: "" },
    { value: "", label: "" },
  ];
  const days = [
    { value: "Monday1 ", label: "Monday1" },
    { value: "Monday2", label: "Monday2" },
  ];
  const opctions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
    { value: "NA", label: "NA" },
  ];
  return (
    <Box>
      <form>
        <Grid item container>
          <Grid item xs={6}>
            <Grid item container>
              <Grid item xs={3}>
                QA TEST
              </Grid>
              <Grid item>
                <TextField fullWidth variant="outlined" />
              </Grid>
            </Grid>
            <Grid item container mt={2}>
              <Grid item xs={3}>
                Test 2
              </Grid>
              <Grid item>
                <TextField fullWidth variant="outlined" />
              </Grid>
            </Grid>
            <Grid item container mt={2}>
              <Grid item xs={3}>
                Test New
              </Grid>
              <Grid item>
                <TextField fullWidth variant="outlined" />
              </Grid>
            </Grid>
            <Grid item container mt={2}>
              <Grid item xs={3}>
                Monday
              </Grid>
              <Grid item>
                <FormControl className="formcontrol-select">
                  <Select labelId="demo-simple-select-label">
                    {days.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container mt={2}>
              <Grid item xs={3}>
                Nirmal
              </Grid>
              <Grid item>
                <FormControl  className="formcontrol-select" >
                  <Select labelId="demo-simple-select-label">
                    {opctions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item container>
              <Grid item xs={3}>
                customer scheme
              </Grid>

              <Grid item>
                <TextField fullWidth variant="outlined" />
              </Grid>
            </Grid>
            <Grid item container mt={2}>
              <Grid item xs={3}>
                ARA
              </Grid>
              <Grid item>
                <FormControl className="formcontrol-select" >
                  <Select labelId="demo-simple-select-label">
                    {status.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container mt={2}>
              <Grid item xs={3}>
                Thursday
              </Grid>
              <Grid item>
                <TextField fullWidth variant="outlined" />
              </Grid>
            </Grid>
            <Grid item container mt={2}>
              <Grid item xs={3}>
                Monday Test
              </Grid>
              <Grid item>
                <FormControl className="formcontrol-select">
                  <Select labelId="demo-simple-select-label">
                    {days.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Grid item sx={{ mr: 2 }}>
            <Button variant="outlined" onClick={handleBackClick}>
            <UndoIcon
                sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
              />
            Back
            </Button>
          </Grid>
          <Grid>
            <Button className="btn-colr " variant="contained">
              <SaveIcon
                sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
              />
              Update
            </Button>
          </Grid>
          <Grid item sx={{ ml: 2 }}>
            <Button className="next-button" variant="outlined" onClick={handleBackClick}>
               <CancelIcon
              sx={{ color: "", fontSize: "15px", marginRight: "10px" }}
            />{" "}
              Cancle
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
export default Customerother;
