import React from "react";
import {
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Grid,
  InputLabel,
} from "@mui/material";

interface ViewSalesLevelValueProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
  onClick: (event: React.MouseEvent<unknown>) => void;
}

const SalesLevelValueForm: React.FC<ViewSalesLevelValueProps> = ({
  onBack,
  onClick,
}) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const handleLobViewClick = (event: React.MouseEvent<unknown>) => {
    onClick(event);
  };

  return (
    <>
      <Box className="card-form">
        <form noValidate>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Level Name{" "}
              </InputLabel>
              <TextField placeholder="Level Name" className="Txt-box" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Code
              </InputLabel>
              <TextField placeholder="Code" className="Txt-box" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">
                Value(Name)
              </InputLabel>
              <TextField placeholder="Name" className="Txt-box" />
            </Grid>

            <Grid item xs={12} mt={2} display="flex" justifyContent="center">
              <Button
                variant="contained"
                className="btn-colr"
                onClick={handleLobViewClick}
              >
                View LOB Mapping
              </Button>
              <Button
                variant="contained"
                className="btn-colr"
                style={{ marginLeft: "10px" }}
                onClick={handleBackClick}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};
export default SalesLevelValueForm;
