import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import { useEffect, useState } from "react";
import {
  getOutletDivision,
  getOutletGroup,
  openFilterModel,
  updateMaxValue,
  updateMinValue,
  updateOutletDivision,
  updateOutletDivisionPop,
  updateOutletDivisiontemp,
  updateOutletGroup,
  updateOutletGroupPop,
  updateOutletGrouptemp,
  updateStatus,
} from "../../../Redux/XDM/Sales/outletSideBarReducer";
import FilterModal from "./FilterModal";

const OutletSideBar = () => {
  const dispatch = useAppDispatch();
  const [filterModalTitle, setFilterModalTitle] = useState("");
  
  const fetch = useAppSelector((state) => state.outletSideBar);
  useEffect(() => {
    dispatch(getOutletDivision(filterData));
    dispatch(getOutletGroup(filterData));
  }, []);
  const status = {
    values: [true, false],
  };
  const division = fetch.division;
  const outletGroup = fetch.outletGroup;

  const filterData = {
    page: 0,
    size: 4,
    pageStatus: true,
  };
  const statusSelected = fetch.statusSelected;
  const divisionSelected = fetch.divisionSelected;
  const outletGroupSelected = fetch.outletGroupSelected;
    const min = fetch.minVal;
    const max = fetch.maxVal;

  const clearAll = (title: any) => {
    switch (title) {
      case "Status":
        dispatch(updateStatus([]));
        break;
      case "Division":
        dispatch(updateOutletDivision([]));
        dispatch(updateOutletDivisionPop([]));
        dispatch(updateOutletDivisiontemp([]));
        break;
      case "Outlet Group":
        dispatch(updateOutletGroup([]));
        dispatch(updateOutletGroupPop([]));
        dispatch(updateOutletGrouptemp([]));
        break;
      case "MinMax":
        dispatch(updateMinValue(""));
        dispatch(updateMaxValue(""));
        break;
      default:
        break;
    }
  };
  const filterOpenSales = useAppSelector(
    (state) => state.outletSideBar.filterPop
  );
  const handleShowMoreModal = (title: any) => {
    setFilterModalTitle(title);
    dispatch(openFilterModel(true));
    switch (title) {
      case "Division":
        dispatch(updateOutletDivisiontemp(fetch.divisionSelected));
        break;
      case "Outlet Group":
        dispatch(updateOutletGrouptemp(fetch.outletGroupSelected));
        break;  
      default:
        break;
    }
  };
  const statusSelect = (value: string) => {
    dispatch(updateStatus(value));
  };
  const divisionSelect = (value: string) => {
    const isSelected = divisionSelected.includes(value);
    const updateddivisionSelected = isSelected
      ? divisionSelected.filter((item: any) => item !== value)
      : [...divisionSelected, value];
    dispatch(updateOutletDivision(updateddivisionSelected));
    dispatch(updateOutletDivisionPop(updateddivisionSelected));
    dispatch(updateOutletDivisiontemp(updateddivisionSelected));
  };
  const minValUpdate =(value : string)=>{
    dispatch(updateMinValue(value));
  }
  const maxValUpdate =(value : string)=>{
    dispatch(updateMaxValue(value));
  }
  const outletGroupSelect = (value: string) => {
    const isSelected = outletGroupSelected.includes(value);
    const updatedoutletGroupSelected = isSelected
      ? outletGroupSelected.filter((item: any) => item !== value)
      : [...outletGroupSelected, value];
    dispatch(updateOutletGroup(updatedoutletGroupSelected));
    dispatch(updateOutletGroupPop(updatedoutletGroupSelected));
    dispatch(updateOutletGrouptemp(updatedoutletGroupSelected));
  };
  return (
    <Box
      sx={{
        padding: "0px 5px 0px 16px",
        marginBottom: "100px",
        marginRight: "4.3px",
        marginTop: "4px",
      }}
      className="dis-flex scrollHide"
    >
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
            Status
          </Typography>
        </Box>
        <Divider />
        <FormControl component="fieldset" sx={{ mt: 1 }}>
          <RadioGroup
            value={statusSelected || ""}
            onChange={(event) => statusSelect(event.target.value)}
          >
            {Array.isArray(status.values) &&
              status.values.map((values: any, index: number) => (
                <FormControlLabel
                  key={index}
                  value={values.toString()}
                  control={
                    <Radio
                      className="sidebar_checkbox"
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 15,
                          color: "#6750A4",
                        },
                      }}
                    />
                  }
                  label={
                    <div
                      style={{ paddingTop: "2px", paddingLeft: "5px" }}
                      className="filter_lable"
                    >
                      {values == true ? "Active" : "Inactive"}
                    </div>
                  }
                />
              ))}
          </RadioGroup>
        </FormControl>

        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {(Array.isArray(statusSelected) && statusSelected.length > 0) ||
          (!Array.isArray(statusSelected) && statusSelected) ? (
            <Grid
              item
              xs={8}
              sx={{
                color: "#F5222D",
                fontSize: "10px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              }}
              className="cursor_pointer"
              onClick={() => clearAll("Status")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}></Grid>
        </Box>
      </Card>
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
            Based on Sales
          </Typography>
          <span style={{ fontSize: "9px", fontWeight: "500" }}>
            (FY 2024-2025)
          </span>
        </Box>
        <Divider />

        <FormControl className="" sx={{ mt: 1 }}>
          <Grid container>
            <Grid xs={5} md={5}>
              <span style={{ fontSize: "9px", fontWeight: "500" }}>
                Min.Amount
              </span>
              <br />
              <TextField
                value={min}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow only numbers and dots
                  const validValue = value.match(/^[0-9]*\.?[0-9]*$/)
                    ? value
                    : min;
                  minValUpdate(validValue);
                }}
                inputProps={{
                  style: {
                    textAlign: "center",
                    // width: 30,
                    height: 21,
                    fontSize: "10px",
                    padding: 0,
                  },
                  inputMode: "decimal", // Mobile devices will show the numeric keyboard with dot
                  pattern: "[0-9]*\\.?[0-9]*", // Regex pattern to allow only numbers and dot
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: 1,
                    "&:hover": {
                      backgroundColor: "#ffffff",
                    },
                  },
                }}
              />
            </Grid>
            <Grid xs={2} md={2} textAlign="center">
              {" "}
              -{" "}
            </Grid>
            <Grid xs={5} md={5}>
              <span style={{ fontSize: "9px", fontWeight: "500" }}>
                Max.Amount
              </span>
              <br />
              <TextField
                value={max}
                onChange={(e) => maxValUpdate(String(e.target.value))}
                inputProps={{
                  style: {
                    textAlign: "center",
                    // width: 30,
                    height: 21,
                    fontSize: "10px",
                    padding: 0,
                  },
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: 1,
                    "&:hover": {
                      backgroundColor: "#ffffff",
                    },
                  },
                  // "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                }}
              />
            </Grid>
          </Grid>
        </FormControl>

        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {min !== "" || max !== "" ? (
            <Grid
              item
              xs={8}
              sx={{
                color: "#F5222D",
                fontSize: "10px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              }}
              className="cursor_pointer"
              onClick={() => clearAll("MinMax")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
        </Box>
      </Card>
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
            Division
          </Typography>
          <Typography
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              mb: 1,
              color: "var(--grey-grey500, #505259)",
            }}
          >
            {divisionSelected.length > 0 &&
              "(" + divisionSelected.length + " Selected)"}
          </Typography>
        </Box>
        <Divider />
        <FormControl className="" sx={{ mt: 1 }}>
          {Array.isArray(division.values) &&
            division.values.map((values: any, index: number) => (
              <FormControlLabel
                key={index}
                value={values}
                control={
                  <Checkbox
                    onChange={() => divisionSelect(values)}
                    checked={divisionSelected.includes(values)}
                    className="sidebar_checkbox"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 15,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label={
                  <div
                    style={{ paddingTop: "2px", paddingLeft: "5px" }}
                    className="filter_lable"
                  >
                    {values}
                  </div>
                }
              />
            ))}
        </FormControl>

        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {divisionSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              sx={{
                color: "#F5222D",
                fontSize: "10px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              }}
              className="cursor_pointer"
              onClick={() => clearAll("Division")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}>
            {division.count > 0 && (
              <Typography
                className="moreData"
                onClick={() => handleShowMoreModal("Division")}
              >
                + {division.count} More
              </Typography>
            )}
          </Grid>
        </Box>
      </Card>
      <Card variant="outlined" sx={{ padding: "5px 8px 0px 8px", mb: 1 }}>
        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "10px", fontWeight: "700" }}>
            Outlet Group
          </Typography>
          <Typography
            sx={{
              fontSize: "9px",
              fontWeight: "500",
              mb: 1,
              color: "var(--grey-grey500, #505259)",
            }}
          >
            {outletGroupSelected.length > 0 &&
              "(" + outletGroupSelected.length + " Selected)"}
          </Typography>
        </Box>
        <Divider />
        <FormControl className="" sx={{ mt: 1 }}>
          {Array.isArray(outletGroup.values) &&
            outletGroup.values.map((values: any, index: number) => (
              <FormControlLabel
                key={index}
                value={values}
                control={
                  <Checkbox
                    onChange={() => outletGroupSelect(values)}
                    checked={outletGroupSelected.includes(values)}
                    className="sidebar_checkbox"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 15,
                        color: "#6750A4",
                      },
                    }}
                  />
                }
                label={
                  <div
                    style={{ paddingTop: "2px", paddingLeft: "5px" }}
                    className="filter_lable"
                  >
                    {values}
                  </div>
                }
              />
            ))}
        </FormControl>

        <Box
          alignItems="center"
          sx={{
            padding: "5px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {outletGroupSelected.length > 0 ? (
            <Grid
              item
              xs={8}
              sx={{
                color: "#F5222D",
                fontSize: "10px",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
              }}
              className="cursor_pointer"
              onClick={() => clearAll("Outlet Group")}
            >
              <IoMdClose size={7} />
              &nbsp; Clear all
            </Grid>
          ) : (
            <Grid item xs={8}></Grid>
          )}
          <Grid item xs={4}>
            {" "}
            {outletGroup.count > 0 && (
              <Typography
                className="moreData"
                onClick={() => handleShowMoreModal("Outlet Group")}
              >
                + {outletGroup.count} More
              </Typography>
            )}
          </Grid>
        </Box>
      </Card>
      {filterOpenSales && <FilterModal status="" title={filterModalTitle} />}
    </Box>
  );
};

export default OutletSideBar;
