import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Drawer,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  ProductUomFormValues,
  FormErrors,
} from "../../../typescript/ProductUom";
//icons
import Close from "../../../assets/images/icons/Close_icon.png";
import { useAppDispatch } from "../../../hooks/useFetch";
import {
  ProductUomSubmitForm,
  ProductUomFetchData,
} from "../../../Redux/Masters/Uom/action";

import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface RequestData {
  page: number;
  size: number;
}
interface MasterCompanyCreateProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
}
export default function MasterCustomerCreate(props: MasterCompanyCreateProps) {
  const dispatch = useAppDispatch();
  const { onClose, requestData } = props; // Destructure the props

  const [FormError, setFormError] = useState<boolean>(false);
  const [sucessMessage, setSucessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [errors, setErrors] = useState<FormErrors>({
    uomCode: "",
    uomName: "",
  });

  const [formData, setFormData] = useState<ProductUomFormValues>({
    uomCode: "",
    uomName: "",
  });

  const validateFormData = (data: ProductUomFormValues): FormErrors => {
    const errors: FormErrors = {};
    const fieldsToValidate: (keyof ProductUomFormValues)[] = [
      "uomCode",
      "uomName",
    ];

    fieldsToValidate.forEach((field) => {
      if (!data[field]) {
        errors[field] = `${field} is required`;
      }
    });
    return errors;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    formData: ProductUomFormValues
  ): Promise<void> => {
    event.preventDefault();
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await dispatch(ProductUomSubmitForm(formData));
        const successMessage = response.message;
        setFormError(true);
        setSucessMessage(successMessage);
        dispatch(ProductUomFetchData(requestData));
        setTimeout(() => onClose(), 2000);
      } catch (error: any) {
        if (error.response.data.data) {
          const errorMessage = error.response.data.data;
          setErrors(errorMessage);
        } else {
          setErrorMessage("something went Wrong");
        }
      }
    } else {
      setErrors(validationErrors);
    }
  };
  const GRT = ["aaa", "bb"];
  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_stl"
        // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 630,

              md: 536,
              sm: 740,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "95%" }} role="presentation">
            <Grid
              container
              className="crt-header"
              mt={3}
              style={{ paddingLeft: "14px" }}
            >
              <Typography gutterBottom className="crt-header-lable">
                Create Geography Hierarchy Level
              </Typography>

              <Grid item>
                <img
                  src={Close}
                  alt={Close}
                  // onClick={onClose}
                  onClick={() => {
                    onClose();
                  }}
                  style={{ width: "22px", height: "22px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Content */}
          <Box
            mt={3.1}
            sx={{
              // flexGrow: 1,
              overflowY: "auto",
              marginBottom: "70px",
            }}
            className="create-form"
          >
            {/* Your scrollable content */}
            <form onSubmit={(event) => handleSubmit(event, formData)}>
              <Grid
                item
                sx={{ width: "95%", margin: "auto", marginBottom: "100px" }}
              >
                <Grid item>
                  <Typography className="crt-fnt-lable">Country *</Typography>
                  <Grid item xs={12} sx={{ mt: "5px" }}>
                    <FormControl fullWidth>
                      <Select
                        placeholder="Select Country"
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        // value={formData.country}
                        displayEmpty
                      >
                        <MenuItem
                          className="select_placeholder"
                          disabled
                          value=""
                        >
                          <em style={{ color: "#bababc" }}>Select Country</em>
                        </MenuItem>
                        {GRT.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">Region *</Typography>
                  <Grid item xs={12} sx={{ mt: "5px" }}>
                    <FormControl fullWidth>
                      <Select
                        placeholder="Select Region"
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        // value={formData.region}
                        displayEmpty
                      >
                        <MenuItem
                          className="select_placeholder"
                          disabled
                          value=""
                        >
                          <em style={{ color: "#bababc" }}>Select Region</em>
                        </MenuItem>
                        {GRT.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">State *</Typography>
                  <Grid item xs={12} sx={{ mt: "5px" }}>
                    <FormControl fullWidth>
                      <Select
                        placeholder="Select State"
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        // value={formData.state}
                        displayEmpty
                      >
                        <MenuItem
                          className="select_placeholder"
                          disabled
                          value=""
                        >
                          <em style={{ color: "#bababc" }}>Select State</em>
                        </MenuItem>
                        {GRT.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">District *</Typography>
                  <Grid item xs={12} sx={{ mt: "5px" }}>
                    <FormControl fullWidth>
                      <Select
                        placeholder="Select District"
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        // value={formData.district}
                        displayEmpty
                      >
                        <MenuItem
                          className="select_placeholder"
                          disabled
                          value=""
                        >
                          <em style={{ color: "#bababc" }}>Select District</em>
                        </MenuItem>
                        {GRT.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">Town *</Typography>
                  <Grid item xs={12} sx={{ mt: "5px" }}>
                    <FormControl fullWidth>
                      <Select
                        placeholder="Select Town"
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        // value={formData.town}
                        displayEmpty
                      >
                        <MenuItem
                          className="select_placeholder"
                          disabled
                          value=""
                        >
                          <em style={{ color: "#bababc" }}>Select Town</em>
                        </MenuItem>
                        {GRT.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item mt={2} className="product_Create_footer">
                <Button className="crt-btn-cancel" onClick={onClose}>
                  {" "}
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="crt-btn-colr"
                  type="submit"
                  sx={{ mr: 4 }}
                >
                  {" "}
                  Create
                </Button>
              </Grid>
            </form>

            {/* More content... */}
            {FormError ? (
              sucessMessage ? (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="success">{sucessMessage}</Alert>
                </Snackbar>
              ) : (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="error">{errorMessage}</Alert>
                </Snackbar>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
