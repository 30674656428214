import {
  Box,
  Card,
  Grid,
  Stack,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import MiniDrawerStyled from "../../../Layout/MainLayout/Drawer/MiniDrawerStyled";
import DrawerHeader from "../../../Layout/MainLayout/Drawer/DrawerHeader";
import OrderBilling from "./OrderBilling";
import OrderBillingSidebar from "./OrderBillingSidebar";
import OrderBillingStatusSidebar from "./OrderBillingStatusSideBar";
import OrderBillingStatus from "./OrderBillingSatus";
import SearchBar from "@mkyy/mui-search-bar";
import { updateDate, updateFromDate, updateOutlet, updateOutletPop, updateOutletTemp, updateRoute, updateRoutePop, updateRouteTemp, updateSalesman, updateSalesmanPop, updateSalesmanTemp, updateStocks, updateToDate } from "../../../Redux/XDM/Sales/billingReducer";
import { useAppDispatch } from "../../../hooks/useFetch";
import { updateOutletDivision, updateOutletDivisionPop, updateOutletDivisiontemp, updateOutletGroup, updateOutletGroupPop, updateOutletGrouptemp } from "../../../Redux/XDM/Sales/outletSideBarReducer";


const OrderBillingCommon: React.FC = () => {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    setSearchValue("");
    if (newValue === 0) {
      setTabVal("Yet To Confirm");
    } else if (newValue === 1) {
      setTabVal("Confirmed");
    } else if (newValue === 2) {
      setTabVal("Rejected");
    } else if (newValue === 3) {
      setTabVal("Cancelled");
    }
  };

  const [tabVal, setTabVal] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useAppDispatch();
  const handleSearch = () => {
    setSearchValue(searchValue);
  };
useEffect(() => {
   dispatch(updateStocks([]));
   dispatch(updateSalesman([]));
   dispatch(updateRoute([]));
   dispatch(updateOutlet([]));
   dispatch(updateSalesmanPop([]));
   dispatch(updateRoutePop([]));
   dispatch(updateOutletPop([]));
   dispatch(updateSalesmanTemp([]));
   dispatch(updateRouteTemp([]));
   dispatch(updateOutletTemp([]));
   dispatch(updateDate([]));
   dispatch(updateToDate([]));
   dispatch(updateFromDate([]));
  dispatch(updateOutletGroupPop([]));
  dispatch(updateOutletGrouptemp([]));
  dispatch(updateOutletGroup([]));
  dispatch(updateOutletDivision([]));
  dispatch(updateOutletDivisionPop([]));
  dispatch(updateOutletDivisiontemp([]));
},[dispatch,tabVal])

  return (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        bgcolor="#eee"
      >
        <Box flex={1} p={0}>
          <MiniDrawerStyled variant="permanent" open={true} theme={theme}>
            <DrawerHeader />
            {tabIndex === 0 && <OrderBillingSidebar />}
            {tabIndex === 1 && <OrderBillingStatusSidebar tabName={tabVal} />}
            {tabIndex === 2 && <OrderBillingStatusSidebar tabName={tabVal} />}
            {tabIndex === 3 && <OrderBillingStatusSidebar tabName={tabVal} />}
          </MiniDrawerStyled>
        </Box>
        <Box
          flex={8}
          className="margin-left-0px"
          sx={{ overflowY: "auto", height: "100vh", marginBottom: "100px" }}
        >
          <Card
            className="b-radius-0 main-card-order-billing"
            style={{ boxShadow: "none" }}
          >
            <Grid container sx={{ padding:"0px 15px 0px 15px" }}>
              <Grid item sm={9}>
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  // TabIndicatorProps={{
                  //   style: {
                  //     background:
                  //       "linear-gradient(90.19deg, #614FB3 0%, #944986 100%)",
                  //   },
                  // }}
                  className="tab_box"
                  sx={{
                    "& .MuiTab-root": {
                      // background:
                      //   "linear-gradient(90.19deg, #614FB3 0%, #944986 100%)",
                      color: "#B9BABD",
                      "&.Mui-selected": {
                        color: "#944986",
                      },
                    },
                  }}
                >
                  <Tab label="Pending Bills" />
                  <Tab label="Confirmed" />
                  <Tab label="Rejected" />
                  <Tab label="Cancelled" />
                </Tabs>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} mt={2}>
                <SearchBar
                  className="search_box_saleable w-100px"
                  placeholder="Order ID,Outlet,Route.."
                  value={searchValue}
                   onChange={(newValue) => {
                      setSearchValue(newValue);
                    }}
                    onCancelResearch={() => setSearchValue("")}
                    onSearch={(newValue) => {
                      handleSearch();
                    }}
                />
              </Grid>
            </Grid>

            <TabPanel value={tabIndex} index={0}>
              <OrderBilling searchVal={searchValue} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <OrderBillingStatus searchVal={searchValue} tabName={tabVal} />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <OrderBillingStatus searchVal={searchValue} tabName={tabVal} />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <OrderBillingStatus searchVal={searchValue} tabName={tabVal} />
            </TabPanel>
          </Card>
        </Box>
      </Stack>
  );
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

export default OrderBillingCommon;
