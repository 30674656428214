// material-ui
import {
  Box,
  IconButton,
  Toolbar,
  Link,
  useMediaQuery,
  Theme,
  Grid,
  Stack,
  Card,
  Tabs,
  Tab,
} from "@mui/material";

// project import
import Profile from "./SubHeaderContent";
import Notification from "./SubHeaderContent/Notification";
import MobileSection from "./SubHeaderContent/MobileSection";
import SubTabs from "./SubHeaderContent/SubTab";
import TabHeader from "./SubHeaderContent/TabHeader";

// ==============================|| HEADER - CONTENT ||============================== //
// material-ui
import * as React from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { fontGrid } from "@mui/material/styles/cssUtils";
import Settings from "./SubHeaderContent/Settings";
import { useAppSelector } from "../../../../hooks/useFetch";
import HeaderBackground from "../../../../utils/HeaderBackground";

// ==============================|| HEADER CONTENT - SEARCH ||============================== //
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
interface HeaderProps {
  open?: boolean;
  handleDrawerToggle?: () => void;
}

const HeaderContent: React.FC<HeaderProps> = ({
  open = false,
  handleDrawerToggle,
}) => {
  const matchesXs = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const navigator = useNavigate();
  const location = useLocation();
  const firstPart = location.pathname; // This will give you the current route value
  const path = firstPart.split("/");
  const currentPath = path[path.length - 1];

  let result;

  if (currentPath === "Dashboard") {
    // Set the result if the current path is 'Dashboard'
    result = currentPath; // or any other value you want
  } else if (
    currentPath === "purchase" ||
    currentPath === "sales" ||
    currentPath === "reports" ||
    currentPath === "inventory"
  ) {
    // Set the result if the current path is not 'Dashboard'
    result = currentPath; // or any other value you want
  } else {
    result = "Dashboard";
  }
  const hideSubTab = useAppSelector(
    (state: any) => state.hideSubHeaderData.hideSubHeader
  );

  // Now you can use currentPath as needed
  return (
    <>
      <Toolbar className="check">
        <Grid container className="hd-gap">
          <Grid
            item
            // xs={1}
            // sm={2}
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <img
              src="assets/Images/LoginImage/Altec Logo Header.png"
              alt=""
              style={{ width: "114px" }}
            />
          </Grid>

          {!matchesXs && (
            <Grid
              item
              // xs={7}
              // sm={8}
              // md={7}
              sx={{ borderBottom: 0, justifyContent: "end", display: "flex" }}
            >
              <TabHeader />
            </Grid>
          )}

          {matchesXs && (
            <Grid
            // xs={8.5}
            />
          )}

          <Grid
            item
            // xs={2}
            // sm={2}
            // md={3}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{
                paddingLeft: 1,
                backgroundImage: `url("https://testing_demo.cavinkare.in/shortner/r2biR")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className="curser_pointer"
            >
              {!matchesXs && <Settings />}

              {!matchesXs && <Notification />}
              {!matchesXs && <Profile />}

              {matchesXs && <MobileSection />}
            </Stack>
          </Grid>
        </Grid>
      </Toolbar>
      {!hideSubTab && <SubTabs mainTabValue={currentPath} />}
    </>
  );
};

export default HeaderContent;
