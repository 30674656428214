import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { connect } from "react-redux";
import ForgetPasswordComponent from "./ForgetPasswordComponent";
import OtpReceive from "./OtpReceive";
import PasswordUpdate from "./PasswordUpdate";

const ForgetPassword: React.FC = () => {
  const [passwordView, setPasswordView] = useState<Boolean | undefined>(true);
  const [otpView, setOtpView] = useState<Boolean | undefined>(false);
  const [phone, setPhone] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [passwardUpdateView, setPasswordUpdateView] = useState<
    Boolean | undefined
  >(false);
  const handleForgetPasswordStatus = (status: boolean) => {
    setOtpView(true);
    setPasswordView(status);
  };
  const handleMobileNumber = (phone: string) => {
    setPhone(phone);
  };
  const handleOtp = (otp: string) => {
    setOtp(otp);
  };
  const handleOtpReceiveStatus = (status: boolean) => {
    setOtpView(status);
    setPasswordUpdateView(true);
  };

  const handlePasswordUpdate = (status: boolean) => {
    setOtpView(status);
  };
  useEffect(() => {}, []);
  return (
    <Box>
      {/* <Grid container style={{ height: "100vh" }}> */}
      <Grid container>
        <Grid
          item
          xs={false}
          sm={4}
          md={7.5}
          lg={8.5}
          xl={8.5}
          className="leftimg-distributor"
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        {passwordView ? (
          <ForgetPasswordComponent
            onStatusChange={handleForgetPasswordStatus}
            onMobileChange={handleMobileNumber}
          />
        ) : null}
        {otpView ? (
          <OtpReceive
            onOtpChange={handleOtp}
            onStatusChange={handleOtpReceiveStatus}
            phone={phone}
          />
        ) : null}
        {passwardUpdateView ? (
          <PasswordUpdate
            phone={phone}
            otp={otp}
            onStatusChange={handlePasswordUpdate}
          />
        ) : null}
      </Grid>
    </Box>
  );
};
export default ForgetPassword;
