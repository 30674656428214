import React from "react";
import {
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import CommonTable from "../../../components/Tables/CommonTable";
import { TableColumnsType } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../../components/Modal/DeleteSupplier";

import SaveIcon from "@mui/icons-material/Save";

interface DataType {
  key: React.Key;
  code: string;
  RouteName: string;
  action: string;
}

interface DistributorTabProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const EditSMRouts: React.FC<DistributorTabProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const Salesman = ["Select One", "SRI SAI HANUMAN AGENCIES", ""];
  const Branchcode = [
    "Select One",
    "6243304637-VIGNESH AGENCY",
    " 5072066471-SRI LAKSHMI KANTHA AGENCIES",
  ];


  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);


  const [isDelete, setIsDelete] = React.useState(false);

  const handleDeleteClick = (event: React.MouseEvent<unknown>) => {
    setIsDelete(!isDelete);
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "code",
      title: "Route Code",
      sorter: (a, b) => a.code.length - b.code.length,
    },
    {
      dataIndex: "RouteName",
      title: "Route Name",
      sorter: (a, b) => a.RouteName.length - b.RouteName.length,
    },

    {
      dataIndex: "action",
      title: "Action",
      render: (_: any, record: DataType) => (
        <>
          <DeleteIcon
            onClick={(event) => handleDeleteClick(event)}
            style={{
              cursor: "pointer",
              marginRight: "8px",
              fontSize: "1rem",
              color: "#928282de",
            }}
          />
        </>
      ),
    },
  ];


  const data: DataType[] = [
    {
      key: 1,
      code: "GT010072OU001",
      RouteName: "MAIN ROAD",
      action:'',
    },
    {
      key: 2,
      code: "GT01ROU001",
      RouteName: "MAIN ROAD",
      action:'',
    },
  ];
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
};
 

  return (
    <>
      <Box className="card-form">
        <form noValidate>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 10 }}>
            <Grid item xs={12} sm={6}>
              <InputLabel size="normal">Distributor Branch Code </InputLabel>
              <FormControl fullWidth>
                <Select>
                  {Branchcode.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel size="normal">Distributor Branch Code </InputLabel>
              <FormControl fullWidth>
                <Select>
                  {Salesman.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>{" "}
            <Grid item xs={12} sm={6}>
              <Typography>Attach Routes </Typography>
              <TextField variant="outlined" sx={{ width: "88%" }} />
              <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
                ...
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button sx={{ ml: 1, mt: 2, color: "#fff" }} className="btn-colr">
                + Add Route
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
            <Box sx={{ marginTop: "2px",  }}>
              <Box>
                <Grid item sx={{ mt: 2 }}>
              <Grid item style={{ height: "100%", width: "100%" }}>

              <CommonTable 
              // rowSelection={rowSelection}
               data={data} columns={columns} />

                    {isDelete && (
                      <DeleteModal
                        onBack={(event) => handleDeleteClick(event)}
                      />
                    )}
                  </Grid>
                  </Grid>
              </Box>
            </Box>
          </Grid>
          </Grid>
       

          <Grid item xs={12} mt={4} display="flex" justifyContent="center">
            <Button className="btn-colr " variant="contained">
              <SaveIcon sx={{ color: "", fontSize: "15px" }} />
              Save
            </Button>
            <Grid item sx={{ ml: 2 }}>
              <Button variant="outlined" onClick={handleBackClick}>
               Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditSMRouts;
