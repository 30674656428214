import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, Drawer, Grid, TextField, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";

import {
  SupplierUpdate,
  supplierFetchData,
} from "../../../Redux/Masters/Supplier/action";
import { SupplierFormValues, FormErrors } from "../../../typescript/Supplier";
//icons
import Close from "../../../assets/images/icons/Close_icon.png";
import Editicon from "../../../assets/images/icons/edit_icon.png";
import Snackbar, { SnackbarOrigin } from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface RequestData {
  page: number;
  size: number;
}
interface MasterSupplierEditProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
}
export default function MasterSupplierEdit(props: MasterSupplierEditProps) {
  const dispatch = useAppDispatch();
  const { onClose, requestData } = props; // Destructure the props

  const [textFieldValue, setTextFieldValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [FormError, setFormError] = useState<boolean>(false);
  const [sucessMessage, setSucessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const SupplierEditData = useAppSelector(
    (state: any) => state.supplierFetch.SupplierEdit
  );

  const [formData, setFormData] = React.useState({
    companyId: "",
    gstStateName: "",
    supplierName: "",
    address: { doorNo: "", streetName: "", area: "" },
    code: "",
    supplierGstNo: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    geoHierarchyLevel: "",
    geoHierarchyValue: "",
    contactPerson: "",
    mobileNumber: "",
    email: "",
  });

  const [errors, setErrors] = useState<FormErrors>({
    companyId: "",
    gstStateName: "",
    supplierName: "",
    address: {
      doorNo: "",
      streetName: "",
      area: "",
    },
    code: "",
    supplierGstNo: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    geoHierarchyLevel: "",
    geoHierarchyValue: "",
    contactPerson: "",
    mobileNumber: "",
    email: "",
  });

  const validateFormData = (data: SupplierFormValues): FormErrors => {
    const errors: FormErrors = {};

    const fieldsToValidate: (keyof SupplierFormValues)[] = [
      "companyId",
      "gstStateName",
      "supplierName",
      "address",
      "code",
      "supplierGstNo",
      "city",
      "state",
      "country",
      "postalCode",
      "geoHierarchyLevel",
      "geoHierarchyValue",
      "contactPerson",
      "mobileNumber",
      "email",
    ];

    // Validate fields other than address
    fieldsToValidate.forEach((field) => {
      if (!data[field]) {
        errors[field] = `${field} is required`;
      }
    });

    // Validate address fields
    if (!data.address) {
      errors.address = {
        doorNo: "Door number is required",
        streetName: "Street name is required",
        area: "Area is required",
      };
    } else {
      if (!data.address.doorNo) {
        errors.address = {
          ...errors.address,
          doorNo: "Door number is required",
        };
      }
      if (!data.address.streetName) {
        errors.address = {
          ...errors.address,
          streetName: "Street name is required",
        };
      }
      if (!data.address.area) {
        errors.address = { ...errors.address, area: "Area is required" };
      }
    }

    return errors;
  };

  const [isEditEnabled, setIsEditEnabled] = React.useState(true);
  const handleEditClick = () => {
    setIsEditEnabled(false); // Disable edit when clicked
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    if (SupplierEditData && SupplierEditData.address) {
      setFormData((prevData) => ({
        ...prevData,
        companyId: SupplierEditData.companyId,
        gstStateName: SupplierEditData.gstStateName,
        supplierName: SupplierEditData.supplierName,
        address: {
          doorNo: SupplierEditData.address.doorNo || "",
          streetName: SupplierEditData.address.streetName || "",
          area: SupplierEditData.address.area || "",
        },
        code: SupplierEditData.code,
        supplierGstNo: SupplierEditData.supplierGstNo,
        city: SupplierEditData.city,
        state: SupplierEditData.state,
        country: SupplierEditData.country,
        postalCode: SupplierEditData.postalCode,
        geoHierarchyLevel: SupplierEditData.geoHierarchyLevel,
        geoHierarchyValue: SupplierEditData.geoHierarchyValue,
        contactPerson: SupplierEditData.contactPerson,
        mobileNumber: SupplierEditData.mobileNumber,
        email: SupplierEditData.email,
      }));
    }
  }, [SupplierEditData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let newErrors: FormErrors = { ...errors }; // Make a copy of errors

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^\d{10}$/;

    // Check if the changed field is the email field and validate against the regex pattern
    if (name === "email" && !emailRegex.test(value)) {
      newErrors = {
        ...newErrors,
        email: "Please Enter a Valid email Address(Ex:example@domain.com)",
      };
    } else if (name === "email") {
      newErrors = { ...newErrors, email: "" }; // Clear email error if it's valid
    }

    // Check if the changed field is the mobile number field and validate against the regex pattern
    if (name === "mobileNumber" && !mobileRegex.test(value)) {
      newErrors = {
        ...newErrors,
        mobileNumber: "Mobile number must be at least 10 numbers",
      };
    } else if (name === "mobileNumber") {
      newErrors = { ...newErrors, mobileNumber: "" }; // Clear mobile number error if it's valid
    }

    if (name.startsWith("address")) {
      const addressField = name.split(".")[1]; // Extract the specific field of the address
      setFormData((prevFormData) => ({
        ...prevFormData,
        address: {
          ...prevFormData.address,
          [addressField]: value,
        },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setErrors(newErrors); // Update errors state
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    formData: SupplierFormValues
  ): Promise<void> => {
    event.preventDefault();
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await dispatch(
          SupplierUpdate({ id: SupplierEditData.id, formData })
        );
        const successMessage = response.message;
        setFormError(true);
        setSucessMessage(successMessage);
        dispatch(supplierFetchData(requestData));
        setTimeout(() => onClose(), 2000);
      } catch (error: any) {
        if (error.response.data.data) {
          const errorMessage = error.response.data.data;
          setErrors(errorMessage);
        } else {
          setErrorMessage("something went Wrong");
        }
      }
    } else {
      // Form is invalid, display errors
      setErrors(validationErrors);
    }
  };
  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        className="drawer_stl"
        open={true}
        // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 533,
              md: 536,
              sm: 600,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "95%", margin: "auto" }} role="presentation">
            <Grid container className="crt-header" mt={4}>
              <Typography gutterBottom className="crt-header-lable">
                Supplier 1
              </Typography>

              <Grid item>
                <img
                  src={Editicon}
                  alt={Editicon}
                  onClick={handleEditClick}
                  //   onClick={onClose}
                  style={{ width: "24px", height: "24px", marginRight: "15px" }}
                />
                <img
                  src={Close}
                  alt={Close}
                  onClick={onClose}
                  style={{ width: "24px", height: "24px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Content */}
          <Box
            mt={2}
            sx={{ height: "100%", marginBottom: "70px" }}
            className="create-form"
          >
            {/* Your scrollable content */}
            <form onSubmit={(event) => handleSubmit(event, formData)}>
              <Box sx={{ marginBottom: "100px" }}>
                <Grid
                  item
                  sx={{
                    width: "95%",
                    margin: "auto",
                  }}
                >
                  <Grid item>
                    <Typography className="crt-fnt-lable">Company</Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Company Name"
                        name="companyName"
                        disabled={isEditEnabled}
                        value={formData.companyId}
                        onChange={handleInputChange}
                        error={errors.companyId ? true : false}
                        helperText={errors.companyId && errors.companyId}
                        // name="companyId"
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      GST State Name
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Select State"
                        disabled={isEditEnabled}
                        name="gstStateName"
                        onChange={handleInputChange}
                        error={errors.gstStateName ? true : false}
                        helperText={errors.gstStateName && errors.gstStateName}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Supplier Name
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Name"
                        disabled={isEditEnabled}
                        value={formData.supplierName}
                        onChange={handleInputChange}
                        name="supplierName"
                        error={errors.supplierName ? true : false}
                        helperText={errors.supplierName && errors.supplierName}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Code</Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Code "
                        disabled={isEditEnabled}
                        onChange={handleInputChange}
                        value={formData.code}
                        name="code"
                        error={errors.code ? true : false}
                        helperText={errors.code && errors.code}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Supplier GST No
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Number"
                        disabled={isEditEnabled}
                        value={formData.supplierGstNo}
                        onChange={handleInputChange}
                        name="supplierGstNo"
                        error={errors.supplierGstNo ? true : false}
                        helperText={
                          errors.supplierGstNo && errors.supplierGstNo
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Address</Typography>
                    <Grid item xs={12}>
                      <Grid item sx={{ mt: 1 }}>
                        <TextField
                          className="Crt-txt-box"
                          placeholder="Door No  "
                          disabled={isEditEnabled}
                          value={formData.address.doorNo}
                          onChange={handleInputChange}
                          name="doorNo"
                          error={
                            errors.address && errors.address.doorNo
                              ? true
                              : false
                          }
                          helperText={
                            errors.address && errors.address.doorNo
                              ? errors.address.doorNo
                              : null
                          }
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          className="area-Crt-txt-box"
                          placeholder="Street Name"
                          disabled={isEditEnabled}
                          value={formData.address.streetName}
                          onChange={handleInputChange}
                          name="streetName"
                          error={
                            errors.address && errors.address.streetName
                              ? true
                              : false
                          }
                          helperText={
                            errors.address && errors.address.streetName
                              ? errors.address.streetName
                              : null
                          }
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          className="area-Crt-txt-box"
                          placeholder="Area "
                          disabled={isEditEnabled}
                          onChange={handleInputChange}
                          name="area"
                          value={formData.address.area}
                          error={
                            errors.address && errors.address.area ? true : false
                          }
                          helperText={
                            errors.address && errors.address.area
                              ? errors.address.area
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">City </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Select City  "
                        disabled={isEditEnabled}
                        value={formData.city}
                        name="city"
                        onChange={handleInputChange}
                        error={errors.city ? true : false}
                        helperText={errors.city && errors.city}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">State </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Select State  "
                        disabled={isEditEnabled}
                        value={formData.state}
                        name="state"
                        onChange={handleInputChange}
                        error={errors.state ? true : false}
                        helperText={errors.state && errors.state}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Country </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Country  "
                        disabled={isEditEnabled}
                        value={formData.country}
                        name="country"
                        onChange={handleInputChange}
                        error={errors.country ? true : false}
                        helperText={errors.country && errors.country}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Postal Code{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Zip Code  "
                        disabled={isEditEnabled}
                        value={formData.postalCode}
                        name="postalCode"
                        onChange={handleInputChange}
                        error={errors.postalCode ? true : false}
                        helperText={errors.postalCode && errors.postalCode}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Geo Hierarchy Level{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Mention Level"
                        disabled={isEditEnabled}
                        value={formData.geoHierarchyLevel}
                        name="geoHierarchyLevel"
                        onChange={handleInputChange}
                        error={errors.geoHierarchyLevel ? true : false}
                        helperText={
                          errors.geoHierarchyLevel && errors.geoHierarchyLevel
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Geo Hierarchy value{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter value"
                        disabled={isEditEnabled}
                        value={formData.geoHierarchyValue}
                        name="geoHierarchyValue"
                        onChange={handleInputChange}
                        error={errors.geoHierarchyValue ? true : false}
                        helperText={
                          errors.geoHierarchyValue && errors.geoHierarchyValue
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Contact Person{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Name"
                        disabled={isEditEnabled}
                        value={formData.contactPerson}
                        name="contactPerson"
                        onChange={handleInputChange}
                        error={errors.contactPerson ? true : false}
                        helperText={
                          errors.contactPerson && errors.contactPerson
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">
                      Mobile Number{" "}
                    </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Enter Number  "
                        value={formData.mobileNumber}
                        name="mobileNumber"
                        disabled={isEditEnabled}
                        onChange={handleInputChange}
                        error={errors.mobileNumber ? true : false}
                        helperText={errors.mobileNumber && errors.mobileNumber}
                      />
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography className="crt-fnt-lable">Email ID </Typography>
                    <Grid item xs={12}>
                      <TextField
                        className="Crt-txt-box"
                        placeholder="Mail ID   "
                        disabled={isEditEnabled}
                        value={formData.email}
                        name="email"
                        onChange={handleInputChange}
                        error={errors.email ? true : false}
                        helperText={errors.email && errors.email}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Grid item mt={2} className="product_Create_footer">
                <Button
                  className="crt-btn-cancel"
                  onClick={toggleDrawer(false)}
                >
                  {" "}
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="crt-btn-colr"
                  type="submit"
                  sx={{ mr: 4 }}
                >
                  {" "}
                  Update
                </Button>
              </Grid>
            </form>

            {/* More content... */}
            {FormError ? (
              sucessMessage ? (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="success">{sucessMessage}</Alert>
                </Snackbar>
              ) : (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={true}
                  autoHideDuration={2000}
                  style={{ marginTop: "10%" }}
                >
                  <Alert severity="error">{errorMessage}</Alert>
                </Snackbar>
              )
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
