import { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  Drawer,
  Grid,
  InputBase,
  Skeleton,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import Close from "../../../../../assets/images/icons/Close_icon.png";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useFetch";
import {
  getChequeRejectAcceptList,
  getPendingPaymentList,
  setCollectionData,
} from "../../../../../Redux/XDM/Sales/collectionReducer";
import LoadingButton from "@mui/lab/LoadingButton";

interface RejectedProps {
  onClose: () => void;
}

export default function RejectedCheque(props: RejectedProps) {
  const { onClose } = props; // Destructure the props
  const dispatch = useAppDispatch();

  const abortControllerRef = useRef<AbortController | null>(null);

  const [remarks, setRemarks] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const [isDisabled, setIsDisabled] = useState(false); // New state for disabling the button

  let selectedDatas = useAppSelector(
    (state) => state.collectionReducerData.selectedCollectionData
  );

  let collectionReducerData = useAppSelector(
    (state) => state.collectionReducerData
  );

  const filterData = {
    paymentType: "Cheque",
    page: 0,
    size: 10,
    dateType: selectedDatas?.dateType,
    fromDate: selectedDatas?.fromDate,
    toDate: selectedDatas?.toDate,
    search: selectedDatas?.search,
    salesmanId: selectedDatas?.salesManId,
    outletId: selectedDatas?.outletId,
  };

  const handleSubmit = async () => {
    if (!remarks.trim()) {
      setError("Remark is required.");
    } else {
      setError(null);

      if (abortControllerRef.current) {
        abortControllerRef.current.abort(); // Abort the previous request
      }

      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      const requestPayload = {
        chequeList: [
          {
            validRemarksForRejection: true,
            collectionNumber: selectedDatas?.collectionId,
            status: "Rejected",
            remarks: remarks,
          },
        ],
      };

      try {
        const response = await dispatch(
          getChequeRejectAcceptList({
            params: requestPayload,
            signal: abortController.signal,
          })
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          setSnackbarMessage("Collection Rejected Successfully");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          setIsDisabled(true); // Disable the button when fulfilled
          setTimeout(() => {
            onClose();
          }, 2000);
          dispatch(setCollectionData(true));
          dispatch(
            getPendingPaymentList({
              params: { ...filterData },
              signal: abortController.signal,
            })
          );
        }
        else if (response?.meta?.requestStatus === "rejected") {
          const errorMessage = response?.payload?.data;
  
          setSnackbarMessage(errorMessage);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setTimeout(() => {
            setSnackbarOpen(false);
          }, 2000);
        }
      } catch (error) {
        console.error("Submission error", error);
      }
    }
  };
  return (
    <Box sx={{ marginTop: "2px" }}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={100000}
        color="green"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Drawer
        anchor="right"
        open={true}
        className="pen_drawer_stl"
        // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 500,
              md: 460,
              sm: 400,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
            backgroundColor: "#F6F6F6",
          }}
        >
          <Grid item sx={{ height: "100%" }}>
            <Grid
              container
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                background: "white",
                height: "60px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
              }}
            >
              <Grid item sm={7}>
                <Typography>
                  <b>Reject Collections</b>
                </Typography>
              </Grid>
              <Grid item sm={5} sx={{ display: "flex", justifyContent: "end" }}>
                <img
                  onClick={onClose}
                  src={Close}
                  alt="Close"
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{ overflowY: "auto", height: "70%", padding: "20px" }}
              className="discrepancy_card"
            >
              <Card
                sx={{
                  border: "1px solid #DCDCDE",
                  padding: "15px",
                  borderRadius: "6px",
                  marginBottom: "10px",
                }}
              >
                <Grid container sx={{ alignItems: "center" }}>
                  <Grid item>
                    <Grid item>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: "11px",
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                        }}
                      >
                        Collection ID : {selectedDatas?.collectionId}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{
                          color: "#f28500",
                          fontSize: "10px",
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                        }}
                      >
                        {"Pending"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container mt={2} sx={{ gap: "10px" }}>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Invoice No :
                      {selectedDatas?.invoiceNumbers?.map((value: any) => (
                        <>
                          <span
                            style={{
                              textDecoration: "underline",
                              fontWeight: 600,
                              color: "#27538D",
                            }}
                          >
                            {value}
                          </span>
                          <span
                            style={{
                              color: "#27538D",
                            }}
                          >
                            {selectedDatas?.invoiceNumbers?.length > 1 && ", "}
                          </span>
                        </>
                      ))}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Date : {selectedDatas?.collectionDate}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Outlet:{" "}
                      {selectedDatas?.outletName
                        ? selectedDatas.outletName
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Salesman Name : {selectedDatas?.salesmanName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Pending Amount : ₹{" "}
                      {selectedDatas?.pendingAmount?.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography
                    sx={{
                      color: "#44464C",
                      fontFamily: "Montserrat",
                      fontSize: "10px",
                      fontWeight: 500,
                    }}
                  >
                    Enter Remark
                    <span style={{ color: "red" }}>&nbsp;*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} mt={1}>
                  <InputBase
                    sx={{
                      border: "1px solid #DCDCDE",
                      width: "100%",
                      height: "38px",
                      paddingLeft: "10px",
                      color: "#505259",
                      fontSize: "10px",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      borderRadius: "4px",
                    }}
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                  {error && (
                    <Typography
                      sx={{
                        color: "red",
                        fontSize: "10px",
                        marginTop: "5px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      {error}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <div className="image_Container">
                    {selectedDatas?.images?.map(
                      (thumbnail: any, index: number) => (
                        <div className="Image_thumbnail" key={index}>
                          <img
                            src={thumbnail}
                            alt={`Thumbnail ${index + 1}`}
                            className="image_thumbnail_img"
                          />
                        </div>
                      )
                    )}
                  </div>
                </Grid>
              </Card>
            </Grid>
            <Grid
              container
              sx={{
                position: "sticky",
                bottom: 85,
                height: "60px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
                background: "white",
              }}
            >
              <Grid item sm={6}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "13px",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                  }}
                >
                  Rejected Value : ₹ {selectedDatas?.collectedAmt}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Button className="Cancle_btn_des" onClick={onClose}>
                  Cancel
                </Button>
                {/* <Button className="Save_btn_des" onClick={handleSubmit}>
                  Submit
                </Button> */}
                <LoadingButton
                  onClick={handleSubmit}
                  loading={
                    collectionReducerData.collectionChequeRejectAcceptDataLoading
                  }
                  className={`Save_btn_des ${isDisabled ? "disabled_btn_collection" : ""}`}
                  disabled={isDisabled} // Disable button when isDisabled is true
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </Box>
  );
}
