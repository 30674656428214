import React from 'react';
import { Box, TextField, FormControl, Select, MenuItem, Button, Grid, Checkbox, InputLabel } from '@mui/material';


interface ViewSupplierFormProps {
    onBack: (event: React.MouseEvent<unknown>) => void;
}

const CompanyViewForm: React.FC<ViewSupplierFormProps> = ({ onBack }) => {
    const handleBackClick = (event: React.MouseEvent<unknown>) => {
        onBack(event); // Pass the event argument to onBack function
    };
    const companyOptions = ['Company A', 'Company B', 'Company C'];
    const gstStateOptions = ['State A', 'State B', 'State C'];
    const geoHierarchyValueOptions = ['Value A', 'Value B', 'Value C'];
    return (
        <>
             <Box className='card-form'>
                <form noValidate>
                    <Grid container rowSpacing={3} columnGap={2}>
                        <Grid item xs={12} sm={3}>
                            <InputLabel size="normal"className='fnt-lable' >Code</InputLabel>
                            <TextField placeholder="Code" className='Txt-box' />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InputLabel size="normal"className='fnt-lable' >Name</InputLabel>
                            <TextField placeholder="Name" className='Txt-box' />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InputLabel size="normal"className='fnt-lable' >GeoHierarchy Value</InputLabel>
                            <FormControl fullWidth>
                                <Select className='selct-box'>
                                    {geoHierarchyValueOptions.map((option, index) => (
                                        <MenuItem key={index} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InputLabel size="normal"className='fnt-lable' >Country</InputLabel>
                            <TextField placeholder="Country" className='Txt-box' />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InputLabel size="normal"className='fnt-lable' >State</InputLabel>
                            <TextField placeholder="State" className='Txt-box' />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InputLabel size="normal" className='fnt-lable'>City</InputLabel>
                            <TextField placeholder="City" className='Txt-box' />
                        </Grid>
                      
                       
                        <Grid item xs={12} sm={3}>
            <InputLabel size="normal" className="fnt-lable">
              Address
            </InputLabel>
            <Grid className="dis-address">
              <TextField placeholder="Address Line 1" className="Txt-box" />
            </Grid>
            
         
          </Grid>
          <Grid item xs={12} sm={3} mt={2}>
      
            <Grid className="dis-address">
              <TextField placeholder="Address Line 2" className="Txt-box" />
            </Grid>
            
         
          </Grid>
          <Grid item xs={12} sm={3}  mt={2}>
           
            <Grid className="dis-address">
              <TextField placeholder="Address Line 3" className="Txt-box" />
            </Grid>
            
         
          </Grid>
          <Grid item xs={12} sm={3} >
                            <InputLabel size="normal" className='fnt-lable'>Postal Code</InputLabel>
                            <TextField placeholder="Postal Code" className='Txt-box'/>
                        </Grid>
                        <Grid item xs={12} sm={6}  mt={2}>
                            <Checkbox ></Checkbox>
                            <label className='fnt-lable'>Is Default Company</label>
                        </Grid>

                        <Grid item xs={12} mt={2} display="flex" justifyContent="center">
                        <Button variant="contained" className='btn-colr' onClick={handleBackClick}>
                            Back
                        </Button>
                    </Grid>
                    </Grid>
                </form>
            </Box>
           
        </>
    );
}
export default CompanyViewForm