import { Drawer, Grid, Typography, Box, Skeleton } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import SearchBar from "@mkyy/mui-search-bar";
import { ArrowBackIos } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import { Spin, Table, Tooltip, TableColumnsType } from "antd";
import moment from "moment";
import {
  getInvoiceCollectionDetails,
  getOutletInvoiceCollectionDetails,
} from "../../../Redux/XDM/Sales/outletReducer";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { setSalesmanDetails } from "../../../Redux/XDM/Sales/collectionReducer";
import InvoiceDetails from "./InvoiceDetails";
import { isSet } from "util/types";

interface OutletCollectionProps {
  onClose: () => void;
  requestDataCollection: RequestCollectionData;
}

interface RequestData {
  invoiceNumber: string;
  invoiceValue: string;
  outstandingAmt: string;
  paymentSts: string;
  isCollection: boolean;
}

interface RequestCollectionData {
  outletCode: string;
  orderNumber: string;
  invoiceNumber: string;
  invoiceDate: string;
  invoiceStatus: string;
  invoiceValue: string;
  noOfLineItems: string;
  outstandingAmt: string;
  paymentSts: string;
  isCollection: boolean;
}
interface CollectionDetails {
  collectionId: string;
  collectionDate: string;
  amtCollected: number;
  paymentMode: string;
  salesmanId: string;
  salesmanMobile: string;
  salesmanName: string;
  status: string;
  isAcknowledged: boolean;
  receivedDate: string | null;
}

export default function OutletCollection(props: OutletCollectionProps) {
  const { onClose, requestDataCollection } = props; // Destructure the props
  let outletDatas = useAppSelector((state) => state.outletData);
  let invoiceCollectionDetails = outletDatas.invoiceCollectionData;
  const [commonLoader, setCommonLoader] = useState(true);
  const [dataRecordOrders, setDataRecordOrders] = useState<CollectionDetails[]>(
    [
      // Add more records as needed
    ]
  );

  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [isShow, setIsShow] = React.useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const navigate = useNavigate();
  const [isShowDetails, setIsShowDetails] = React.useState(false);
  const handleSearch = () => {
    setSearchValue(searchValue);
  };

  const filterData = {
    invoiceNo: requestDataCollection.invoiceNumber,
    search: searchValue,
  };

  interface RecentInvoiceData {
    outletCode: string;
    orderNumber: string;
    invoiceNumber: string;
    invoiceDate: string;
    invoiceStatus: string;
    invoiceValue: string;
    noOfLineItems: string;
    outstandingAmt: string;
    paymentSts: string;
    isCollection: boolean;
  }

  const handleInvoiceClick = () => {
    setIsShowDetails(!isShowDetails);
  };

  const loadMoreData = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);
    dispatch(
      getInvoiceCollectionDetails({
        params: { ...filterData },
        signal: abortController.signal,
      })
    ).then((response) => {
      if (response.payload) {
        const newData = response?.payload || [];
        setDataRecordOrders(newData);
        setCommonLoader(false);
      }
    });
  };

  const changeDateFormate = (date: string) => {
    const dateObj = moment(date, "YYYY-MM-DD");
    const formattedDate = dateObj.format("DD MMM YYYY");
    return date ? formattedDate : "--";
  };

  useEffect(() => {
    loadMoreData();
    dispatch(
      getOutletInvoiceCollectionDetails(requestDataCollection.invoiceNumber)
    );
  }, [dispatch, requestDataCollection.invoiceNumber, searchValue]);

  let outletInvoiceCollectionData = useAppSelector(
    (state) => state.outletData.outletInvoiceCollectionData
  );

  const filteredDataToShow = dataRecordOrders.filter((item) =>
    item.collectionId?.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleCollectionId = (
    id: string,
    payMode: string,
    salesmanId: string,
    salesmanName: string,
    salesmanMobile: string,
    status: string,
    isAcknowledged: boolean
  ) => {
    const reqData = {
      salesManId: salesmanId,
      salesManName: salesmanName,
      mobile: salesmanMobile,
    };
    if (
      payMode === "Cheque" &&
      status === "Approved" &&
      isAcknowledged === false
    ) {
      navigate(
        `/xdm/sales/collection?id=${id}&payMode=${payMode}&status=ChequeAck`
      );
    } else if (status === "Settled") {
      navigate(
        `/xdm/sales/collection?id=${id}&payMode=${payMode}&status=Pending`
      );
    } else {
      navigate(
        `/xdm/sales/collection?id=${id}&payMode=${payMode}&status=History`
      );
    }
    dispatch(setSalesmanDetails(reqData));
  };

  const recentInvoiceData: CollectionDetails[] = dataRecordOrders ?? [];

  const recentCollData: RecentInvoiceData[] =
    outletDatas?.recentInvoiceData ?? [];

  const columns1: TableColumnsType<CollectionDetails> = [
    {
      dataIndex: "collectionId",
      title: "Collection ID",
      width: "9%",
      align: "start",
      render: (text: string, record: CollectionDetails) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div
              className="ellipseclass"
              style={{
                textDecoration: "underline",
                color: "#27538D",
                fontWeight: 600,
              }}
              onClick={() =>
                handleCollectionId(
                  text,
                  record.paymentMode,
                  record.salesmanId,
                  record.salesmanName,
                  record.salesmanMobile,
                  record.status,
                  record.isAcknowledged
                )
              }
            >
              {text ? text : "--"}
            </div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "collectionDate",
      width: "9%",
      title: "Collection Date",
      align: "center",
      render: (_: any, record: CollectionDetails | { noMoreData: boolean }) => {
        // Check if record has noMoreData
        if ("noMoreData" in record) {
          return { children: null, props: { colSpan: 0 } };
        }

        // Safely access collectionDate only if it exists in the correct record type
        if ("collectionDate" in record && record.collectionDate) {
          return <div>{record.collectionDate}</div>;
        }

        return "-";
      },
    },
    {
      dataIndex: "amtCollected",
      title: "Amount Collected",
      width: "12%",
      align: "right",
      render: (text: number, record: CollectionDetails) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <div className="ellipseclass">
            ₹{text ? text?.toFixed(2) : "0.00"}
          </div>
        );
      },
    },
    {
      dataIndex: "paymentMode",
      title: "Payment Mode",
      width: "10%",
      align: "center",
      render: (text: string, record: CollectionDetails) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <div className="ellipseclass">{text ? text : "--"}</div>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "status",
      width: "20%",
      title: "Payment Status",
      align: "center",
      render: (text: string, record: CollectionDetails) => {
        let statusContent = "";
        let color = "";
        let paymentType = (record as any).paymentMode;
        let date = "";
        let isAcknowledged = (record as any).isAcknowledged;
        if (
          paymentType === "Cheque" &&
          (text === "Approved" || text === "Rejected")
        ) {
          date = (record as any).collectionDate;
        } else {
          date = (record as any).receivedDate;
        }
        if (
          (paymentType === "Cash" || paymentType === "UPI") &&
          text === "Approved"
        ) {
          statusContent = "Payment Received on " + date;
          color = "#52A13D";
        } else if (
          (paymentType === "Cash" || paymentType === "UPI") &&
          text === "Rejected"
        ) {
          statusContent = "Payment Rejected on " + date;
          color = "#E12B1D";
        } else if (paymentType === "Cheque" && text === "Bounced") {
          statusContent = "Cheque Bounced on " + date;
          color = "#E12B1D";
        } else if (
          paymentType === "Cheque" &&
          text === "Approved" &&
          isAcknowledged === true
        ) {
          statusContent = "Payment Received on " + date;
          color = "#52A13D";
        } else if (
          paymentType === "Cheque" &&
          text === "Approved" &&
          isAcknowledged === false
        ) {
          statusContent = "Cheque Received on " + date;
          color = "#F28500";
        } else if (paymentType === "Cheque" && text === "Rejected") {
          statusContent = "Cheque Rejected on " + date;
          color = "#E12B1D";
        } else if (
          (paymentType === "Cash" ||
            paymentType === "UPI" ||
            paymentType === "Cheque") &&
          text === "Settled"
        ) {
          statusContent = "Pending";
          color = "#F28500";
        }

        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <Tooltip placement="top" title={statusContent} zIndex={999999}>
            <div
              className="ellipseclass"
              style={{ color: color, fontWeight: "700" }}
            >
              {statusContent ? statusContent : text}
            </div>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_outlet w-100px"
        // variant="persistent"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid
            container
            className="crt-header"
            mt={2}
            sx={{ padding: "16px 16px 0px 16px" }}
          >
            <Grid item sm={7.5} role="presentation">
              <Typography gutterBottom className="outlet-header-lable">
                <ArrowBackIos
                  sx={{
                    fontSize: "10px",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    onClose();
                  }}
                />
                {} Collection Details for {requestDataCollection.invoiceNumber}
                <br />
                {(() => {
                  let status;
                  let color;

                  if (
                    requestDataCollection.paymentSts === "Pending" &&
                    requestDataCollection.isCollection
                  ) {
                    status = "Partially Received";
                    color = "orange";
                  } else if (
                    requestDataCollection.paymentSts === "Pending" &&
                    !requestDataCollection.isCollection
                  ) {
                    status = "Pending";
                    color = "red";
                  } else if (requestDataCollection.paymentSts === "Received") {
                    status = "Received";
                    color = "green";
                  } else {
                    status = "--";
                    color = "default"; // Default color or style
                  }

                  // Return the colored status div here
                  return (
                    <div
                      style={{ color, marginLeft: "20px", fontSize: "12px" }}
                    >
                      {status}
                    </div>
                  );
                })()}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={2.5} lg={2.5}>
              <SearchBar
                className="search_box_saleable w-100px"
                placeholder="Search Collection ID"
                value={searchValue}
                onChange={(newValue) => {
                  setSearchValue(newValue);
                }}
                onCancelResearch={() => setSearchValue("")}
                onSearch={(newValue) => {
                  handleSearch();
                }}
              />
            </Grid>
          </Grid>

          <Divider sx={{ width: "100%" }} />

          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form saleable-products-box"
          >
            {" "}
            <Grid
              item
              sx={{
                width: "95%",
                margin: "auto",
                marginBottom: "90px",
              }}
            >
              {" "}
              <Grid container mt={1}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Invoice Number
                  </Typography>
                  <Typography
                    gutterBottom
                    className="InvoiceNo_style"
                    mt={1}
                    sx={{
                      fontWeight: "800",
                      color: "#000000",
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    onClick={handleInvoiceClick}
                  >
                    {outletDatas.outletInvoiceCollectionDataLoading ? (
                      <Skeleton variant="rectangular" width="30%" />
                    ) : (
                      requestDataCollection.invoiceNumber
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Invoice Amount
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {outletDatas.outletInvoiceCollectionDataLoading ? (
                      <Skeleton variant="rectangular" width="30%" />
                    ) : (
                      requestDataCollection.invoiceValue
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    No. of Collection
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {outletDatas.outletInvoiceCollectionDataLoading ? (
                      <Skeleton variant="rectangular" width="30%" />
                    ) : (
                      outletInvoiceCollectionData?.noOfCollection
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Total Collection Amount
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {outletDatas.outletInvoiceCollectionDataLoading ? (
                      <Skeleton variant="rectangular" width="30%" />
                    ) : (
                      outletInvoiceCollectionData?.collectedAmount
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Outstanding Amount
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    {" "}
                    {outletDatas.outletInvoiceCollectionDataLoading ? (
                      <Skeleton variant="rectangular" width="30%" />
                    ) : (
                      Number(requestDataCollection.outstandingAmt).toFixed(2)
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="pad-top-0px"
                >
                  <div
                    className="saleable-table-scroll"
                    // id="tableScrollFull"
                    // style={{ height: "400px", overflowY: "auto" }}
                  >
                    <Table
                      className="Header-table saleable-table scroll_cus_table elipsTable"
                      // rowSelection={rowSelection}
                      columns={columns1}
                      dataSource={recentInvoiceData}
                      pagination={false}
                      // scroll={{ x: true }}
                      loading={commonLoader}
                      showSorterTooltip={false}
                      // onRow={(record) => ({
                      //   onClick: () => handleRowClick(record),
                      // })}
                      scroll={{ y: 270 }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
      {isShowDetails ? (
        <InvoiceDetails
          onClose={() => setIsShowDetails(false)}
          requestData={requestDataCollection}
        />
      ) : (
        <></>
      )}
    </Box>
  );
}
