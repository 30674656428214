import React, { useState } from "react";
import Box from "@mui/material/Box";

//icons
import Close from "../../../../assets/images/icons/Close_icon.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  Button,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Theme, useTheme } from "@mui/material/styles";
interface ProductHierarchyCreateProps {
  onClose: () => void;
}
function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];
export default function ProductHierarchyCreate(
  props: ProductHierarchyCreateProps
) {
  const { onClose } = props;

  // select
  const businesslevel = ["aaa", "bb"];
  const product = ["Club Soda", "Panner soda", "Rose Milk"];

  //   const [isShow, setIsShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionCategory, setSelectedOptionCategory] = useState("");
  const [selectedOptionBrand, setSelectedOptionBrand] = useState("");

  const handleChangeSelectName = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value);
  };
  const handleChangeSelectCategory = (event: SelectChangeEvent<string>) => {
    setSelectedOptionCategory(event.target.value);
  };
  const handleChangeSelectBrand = (event: SelectChangeEvent<string>) => {
    setSelectedOptionBrand(event.target.value);
  };
  //   const handleClick = (event: React.MouseEvent<unknown, MouseEvent>) => {
  //     setIsShow(!isShow);
  //   };
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const theme = useTheme();
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleRemove = (
    value: string,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation(); // Prevent the event from propagating to parent elements
    // Your remaining code for removing the chip
  };

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        // variant="persistent"
        className="drawer_stl"
      >
        <Box
          sx={{
            width: {
              lg: 530,

              md: 536,
              sm: 740,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "95%" }} role="presentation">
            <Grid container className="geo-header">
              <Typography gutterBottom className="geo-header-lable">
                Create Brand Category
              </Typography>

              <Grid item>
                <img
                  src={Close}
                  alt={Close}
                  onClick={() => {
                    onClose();
                  }}
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Content */}
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form"
          >
            {/* Your scrollable content */}
            <form>
              <Grid
                item
                sx={{ width: "95%", margin: "auto", marginBottom: "100px" }}
              >
                <Grid item mt={0.5}>
                  <Typography className="crt-fnt-lable">
                    Business Level Name *
                  </Typography>
                  <Grid item xs={12} sx={{ mt: "5px" }}>
                    <FormControl fullWidth>
                      <Select
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        displayEmpty
                        value={selectedOption}
                        onChange={handleChangeSelectName}
                      >
                        <MenuItem disabled value="">
                          <em style={{ color: "#bababc" }}>
                            Select Business Level Name
                          </em>
                        </MenuItem>
                        {businesslevel.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">
                    Category Level Name *
                  </Typography>
                  <Grid item xs={12} sx={{ mt: "5px" }}>
                    <FormControl fullWidth>
                      <Select
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        displayEmpty
                        value={selectedOptionCategory}
                        onChange={handleChangeSelectCategory}
                      >
                        <MenuItem disabled value="">
                          <em style={{ color: "#bababc" }}>
                            Select Category Level Name
                          </em>
                        </MenuItem>
                        {businesslevel.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">
                    Brand Level *
                  </Typography>
                  <Grid item xs={12} sx={{ mt: "5px" }}>
                    <FormControl fullWidth>
                      <Select
                        className="prd_select"
                        IconComponent={KeyboardArrowDownIcon}
                        displayEmpty
                        value={selectedOptionBrand}
                        onChange={handleChangeSelectBrand}
                      >
                        <MenuItem disabled value="">
                          <em style={{ color: "#bababc" }}>
                            Select Brand Level
                          </em>
                        </MenuItem>
                        {businesslevel.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">
                    Brand Category *
                  </Typography>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      className="Crt-txt-box"
                      name={"BrandCategory"}
                      placeholder="Enter Brand Category"
                    />
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography className="crt-fnt-lable">
                    Product/Variant Level *
                  </Typography>
                  <Grid item xs={12} sx={{ mt: "5px" }}>
                    <FormControl fullWidth>
                      <Select
                        multiple
                        className="multiSel_product"
                        value={personName}
                        onChange={handleChange}
                        IconComponent={KeyboardArrowDownIcon}
                        displayEmpty
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value) => (
                              <Chip
                                onClick={(event) => handleRemove(value, event)}
                                className="Select_chip"
                                key={value}
                                label={value}
                                avatar={
                                  <img
                                    src={Close}
                                    alt={Close}
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                      marginRight: "10px",
                                    }}
                                  />
                                }
                                style={{ flexDirection: "row-reverse" }}
                              />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        <MenuItem disabled value="">
                          <em style={{ color: "#bababc" }}>
                            Enter/Select Product/variant Level
                          </em>
                        </MenuItem>
                        {product.map((name) => (
                          <MenuItem
                            className="select_opction"
                            key={name}
                            value={name}
                            style={getStyles(name, personName, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* {isShow ? (
                  <>
                    <Grid item mt={2}>
                      <Typography className="geo-fnt-lable">State</Typography>
                      <Grid item xs={12}>
                        <TextField
                          className="geo-txt-box"
                          placeholder="Select State "
                          type="text"
                          name={"State"}
                        />
                      </Grid>
                    </Grid>

                    <Grid item mt={2}>
                      <Typography className="geo-fnt-lable">
                        District{" "}
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          className="geo-txt-box"
                          placeholder=" Select District  "
                          type="text"
                          name={"District"}
                        />
                      </Grid>
                    </Grid>
                    <Grid item mt={2}>
                      <Typography className="geo-fnt-lable">
                        City/Town{" "}
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          className="geo-txt-box"
                          placeholder="Select City/Town "
                          name={"City_Town"}
                        />
                      </Grid>
                    </Grid>
                    <Grid item mt={2}>
                      <Typography className="geo-fnt-lable">
                        City/Town{" "}
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          className="geo-txt-box"
                          placeholder="Select City/Town "
                          name={"City_Town"}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )} */}
              </Grid>

              <Grid item className="geo_Create-footer">
                <Button className="crt-btn-cancel" onClick={onClose}>
                  {" "}
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="crt-btn-colr"
                  type="submit"
                  sx={{ mr: 4 }}
                >
                  {" "}
                  Create
                </Button>
              </Grid>
            </form>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
