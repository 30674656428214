import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import { Button, Drawer, Grid, Typography } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import debounce from "lodash.debounce";
import type { TableColumnsType } from "antd";
import { Spin, Table, Tooltip } from "antd";
import moment from "moment";
import { ArrowBackIos } from "@mui/icons-material";
import SearchBar from "@mkyy/mui-search-bar";
import { useAppDispatch } from "../../../../../../hooks/useFetch";
import { getProductsDetailsnWithInvoice } from "../../../../../../Redux/XDM/Sales/salesReturnReducer";
import addedImage from "../../../../../../assets/images/icons/Check_ring_light.png";
import InvoiceProductPopup from "./InvoiceProductPopup";
interface RequestData {
  routeName: string;
  orderNumber: string;
  invoiceNo: string;
  salesmanName: string;
  invoiceDate: string;
  noOfLineItem: number;
  totalNetAmount: string;
}

interface ReviewOrderDetailsProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
  outletCode: string | null;
  outletName: string | null;
}

interface ProductDetailsInvoiceData {
  productName: string;
  quantity: number;
  sellRate: number;
  netAmount: number;
  uom: string;
  productCode: string;
  batchCode: string;
  mrp: number;
}

export default function InvoiceProductReview(props: ReviewOrderDetailsProps) {
  const { onClose, requestData, outletName, outletCode } = props; // Destructure the props
  const dispatch = useAppDispatch();
  const [hasMore, setHasMore] = useState(true);
  const [commonLoader, setCommonLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [isShow, setIsShow] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [invoiceDetails, setInvoiceDetails] = useState<any>({});
  const [dataRecordOrders, setDataRecordOrders] = useState<
    ProductDetailsInvoiceData[]
  >([
    // Add more records as needed
  ]);
  const [tempRequestData, setTempRequestData] = useState<
    ProductDetailsInvoiceData[]
  >([]);
  const tableHeightRef = useRef(200);
  const tableHeightloadRef = useRef(200);

  const filterData = {
    invoiceNumber: requestData.invoiceNo,
    search: searchTerm,
  };

  useEffect(() => {
    setHasMore(true);
    loadMoreData(true);
  }, [searchTerm]);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 433;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;
    tableHeightRef.current = availableHeight;
  };
  const tableHeightLoad = () => {
    const headerHeight = 0;
    const footerHeoght = 360;
    const availableHeight = window.innerHeight - headerHeight - footerHeoght;
    tableHeightloadRef.current = availableHeight;
  };

  const dateObj = moment(requestData.invoiceDate, "YYYY-MM-DD");

  const formattedDate = dateObj.format("DD MMM YYYY");

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    tableHeightLoad();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    // let nextPage = resetPage ? 0 : page;

    // filterData.size = tableHeightRef.current > 550 ? 25 : 15;

    dispatch(
      getProductsDetailsnWithInvoice({
        params: { ...filterData },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload !== null) {
          const newOrders = response.payload?.products || [];
          const productData = response.payload;
          setDataRecordOrders((prevOrders) =>
            resetPage ? newOrders : [...prevOrders, ...newOrders]
          );
          const invoiceDetails = {
            invoiceNumber: productData.invoiceNo,
            outletCode: outletCode,
            outletName: outletName,
            routeCode: productData.routeCode,
            orderNumber: productData.orderNumber,
            returnType: "With Invoice",
            returnDetails: [],
          };
          setInvoiceDetails(invoiceDetails);
          // setPage(nextPage + 1);
          // if (newOrders.length < 15) {
          //   setHasMore(false);
          // }
          setCommonLoader(false);
        } else {
          // setHasMore(false);
          setDataRecordOrders([]);
          setCommonLoader(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };

  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [requestDatas, setRequestDatas] = useState<ProductDetailsInvoiceData[]>(
    []
  );
  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    newSelectedRows: any[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: ProductDetailsInvoiceData) => ({
      disabled: tempRequestData.some(
        (item) => item.batchCode === record.batchCode
      ),
    }),
  };

  const HandleAddButton = async (record: ProductDetailsInvoiceData) => {
    setRequestDatas([record]);
    setTempRequestData((prevItems) => [...prevItems, record]);
    setIsShow(true);
  };

  const handleMultiAdd = (records: ProductDetailsInvoiceData[]) => {
    setRequestDatas(records);
    setTempRequestData((prevItems) => [...prevItems, ...records]);
    setIsShow(true);
    // setSelectedRows([]);
    // setSelectedRowKeys([]);
  };

  const reviewOrderColumns: TableColumnsType<ProductDetailsInvoiceData> = [
    {
      dataIndex: "productName",
      title: "Product Name",
      // width: "20%",
      ellipsis: true,
      render: (text: string, record: ProductDetailsInvoiceData) => {
        return text ? text : "-";
      },
    },

    {
      dataIndex: "productCode",
      title: "Product Code",
      // width: "12%",
      render: (text: string, record: ProductDetailsInvoiceData) => {
        return text ? text : "-";
      },
    },
    {
      dataIndex: "uom",
      title: "UOM",
      // width: "10%",
      align: "center",
    },
    {
      dataIndex: "quantity",
      title: "Quantity",
      // width: "10%",
      align: "center",
    },
    {
      dataIndex: "mrp",
      title: "MRP / PC",
      // width: "10%",
      align: "end",
      render: (text: string | number, record: ProductDetailsInvoiceData) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>
              <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
              {text}
            </span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "netAmount",
      title: "Net Amount",
      // width: "15%",
      align: "end",
      render: (_: any, record: ProductDetailsInvoiceData) => {
        return (
          <span>
            <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
            {record?.netAmount ? record?.netAmount.toFixed(2) : "0"}
          </span>
        );
      },
    },
    {
      dataIndex: "actions",
      title: "Action",
      align: "center",
      render: (_: any, record: ProductDetailsInvoiceData) => {
        const isAdded = tempRequestData.some(
          (item: any) => item.batchCode === record.batchCode
        );
        return (
          <>
            {isAdded ? (
              <Button className="added-btn">
                Added
                <img
                  src={addedImage}
                  width={"12px"}
                  alt="Check_ring_light"
                  style={{ marginLeft: "5px", fontWeight: "700" }}
                />
              </Button>
            ) : (
              <Button
                className="add-to-cart-btn"
                onClick={() => HandleAddButton(record)}
                style={{ fontWeight: "700" }}
                disabled={selectedRowKeys.some(
                  (item) => item === record.batchCode
                )}
              >
                Add
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const filteredDataToShow = dataRecordOrders.filter(
    (item) =>
      item.productName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.productCode?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const dataToShow: ProductDetailsInvoiceData[] =
    filteredDataToShow.length > 0
      ? hasMore
        ? filteredDataToShow
        : [...filteredDataToShow]
      : [];

  const handleSearch = () => {
    setSearchTerm(searchTerm);
  };

  const closePopup = () => {
    setIsShow(false);
    setTempRequestData([]);
    setSelectedRowKeys([]);
    setSelectedRows([]);
  };

  const handleRequestDataChange = (updatedData: any) => {
    setRequestDatas(updatedData); // Update the parent state
  };

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer anchor="right" open={true} className="drawer_outlet w-100px">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            // height: "100%",
            // mt: 2,
          }}
        >
          <Grid item sx={{ width: "100%" }}>
            <Grid container sx={{ padding: "12px 24px" }}>
              <Grid
                item
                sm={0.3}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ArrowBackIos
                  sx={{
                    fontSize: "10px",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    onClose();
                  }}
                />
              </Grid>
              <Grid item xs={6.7} sm={7.7} md={8.7} lg={8.7}>
                <Grid item>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#000000",
                      fontFamily: "Montserrat",
                      fontSize: "13px",
                    }}
                  >
                    {outletName} / {outletCode} ({requestData?.invoiceNo})
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      color: "#505259",
                      fontFamily: "Montserrat",
                      fontSize: "11px",
                      fontWeight: 600,
                    }}
                  >
                    Add Product
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={5}
                sm={4}
                md={3}
                lg={3}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <SearchBar
                  className="search_box_pending w-100px"
                  placeholder="Search Product Name, Code...."
                  value={searchTerm}
                  onChange={(newValue) => {
                    setSearchTerm(newValue);
                  }}
                  onCancelResearch={() => setSearchTerm("")}
                  onSearch={(newValue) => {
                    handleSearch();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form saleable-products-box"
          >
            {" "}
            <Grid
              item
              sx={{
                width: "100%",
                margin: "auto",
                marginBottom: "90px",
                padding: "0px 24px",
              }}
            >
              <Grid container pl={3.6} mt={1}>
                <Grid item sm={4}>
                  <Grid item mt={2}>
                    <Typography className="Pending_review_lable">
                      Order ID
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {requestData?.orderNumber}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={4}>
                  <Grid item mt={2}>
                    <Typography className="Pending_review_lable">
                      Salesman Name
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {requestData?.salesmanName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={4}>
                  <Grid item mt={2}>
                    <Typography className="Pending_review_lable">
                      Date
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {formattedDate}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={4}>
                  <Grid item mt={2}>
                    <Typography className="Pending_review_lable">
                      Route Name
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {requestData?.routeName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={4}>
                  <Grid item mt={2}>
                    <Typography className="Pending_review_lable">
                      No. of Line item
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {requestData?.noOfLineItem}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={4}>
                  <Grid item mt={2}>
                    <Typography className="Pending_review_lable">
                      Net Amount
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
                      {requestData?.totalNetAmount
                        ? requestData?.totalNetAmount
                        : "0"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  // mx={2}
                  className="pad-top-0px"
                  sx={{ height: tableHeightloadRef.current }}
                >
                  <div className="saleable-table-scroll">
                    <Table
                      className={`Header-table saleable-table sale_last_hide scroll_cus_table antcheck_manual Header_chechbox pending_grn ${
                        commonLoader ? " nodataloader" : ""
                      }`}
                      columns={reviewOrderColumns}
                      dataSource={dataToShow}
                      pagination={false}
                      scroll={{ y: tableHeightRef.current }}
                      loading={!page && commonLoader}
                      showSorterTooltip={false}
                      summary={() => {
                        if (!hasMore && dataRecordOrders.length > 0) {
                          return (
                            <Table.Summary.Row>
                              <Table.Summary.Cell
                                index={1}
                                colSpan={8}
                                align="center"
                                className="noMore"
                              >
                                No more data found...
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        } else if (page !== 0 && commonLoader) {
                          return (
                            <Table.Summary.Row>
                              <Table.Summary.Cell
                                index={1}
                                colSpan={8}
                                align="center"
                              >
                                <Spin />
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        }
                      }}
                      rowSelection={rowSelection}
                      rowKey={(record) => record.batchCode}
                    />
                  </div>
                </Grid>
              </Grid>
              <hr className="hr-revieworder"></hr>
              <Grid
                container
                mt={1.5}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid item sm={10}>
                  {/* <Typography>selected {selectedRowKeys.length}</Typography> */}
                </Grid>
                <Grid
                  item
                  sm={2}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    variant="contained"
                    className="Save_btn_des"
                    type="submit"
                    sx={{ mr: 2 }}
                    onClick={() =>
                      handleMultiAdd(
                        selectedRows as ProductDetailsInvoiceData[]
                      )
                    }
                    disabled={selectedRowKeys.length === 0}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
      {isShow ? (
        <InvoiceProductPopup
          onClose={() => closePopup()}
          requestData={requestDatas}
          invoiceRequestData={invoiceDetails}
          onRequestDataChange={handleRequestDataChange}
          requestDataInvoice={requestData?.invoiceNo}
        />
      ) : (
        <></>
      )}
    </Box>
  );
}
