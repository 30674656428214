import {
  GST_STATE_TAX_Submit_ERROR,
  GST_STATE_TAX_FETCH_DATA,
  GST_STATE_TAX_EDIT_DATA,
  } from '../../../actionType'
  interface State {
    GstStateTaxFetch: [];
    GststateTaxEdit:[];
  
  }
  
  
  const initialState: State = {
    GstStateTaxFetch: [],
    GststateTaxEdit:[],
  
  
  };
  
  
  const GstStateTaxReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case GST_STATE_TAX_FETCH_DATA:
        return {
          ...state,
          GstStateTaxFetch: action.payload,
        };
        case GST_STATE_TAX_EDIT_DATA:
          return {
            ...state,
            GststateTaxEdit: action.payload,
          };
      case GST_STATE_TAX_Submit_ERROR:
        return {
          ...state,
          GstStateTaxToast: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default GstStateTaxReducer;
  
  