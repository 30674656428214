import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  Button,
  Drawer,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import debounce from "lodash.debounce";
import type { TableColumnsType } from "antd";
import { Spin, Table, Tooltip } from "antd";
import moment from "moment";
import { ArrowBackIos } from "@mui/icons-material";
import SearchBar from "@mkyy/mui-search-bar";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useFetch";
import LoadingButton from "@mui/lab/LoadingButton";
import Discrepancy_img from "../../../../assets/images/icons/Discrepancy_img.png";
import Accept_img from "../../../../assets/images/icons/Accept_img.png";
import Discrepancy from "../Discrepancy";
import PendingConfirmOrder from "../Pending Order/PendingConfirmOrder";
import {
  acceptAllPendingGrn,
  getAddDiscrepancyGrn,
  getProductDetailsGrn,
} from "../../../../Redux/XDM/Purchase/GrnReducer";

interface RequestData {
  invoiceNo: string;
  orderNo: string;
  invoiceDate: string;
  totNetAmt: number;
  noOfLineItem: string;
}

interface ReviewOrderDetailsProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
}
interface ReviewOrderDetails {
  prodCode: string;
  prodName: string;
  matDesc: string;
  uomCode: string;
  slno: string;
  mrp: string;
  invQty: string;
  netAmt: number;
  status: string;
}

export default function PendingReview(props: ReviewOrderDetailsProps) {
  const { onClose, requestData } = props; // Destructure the props
  const dispatch = useAppDispatch();
  const [hasMore, setHasMore] = useState(true);
  const [commonLoader, setCommonLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [isShow, setIsShow] = React.useState(false);
  const [isShowConfirm, setIsShowConfirm] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [dataRecordOrders, setDataRecordOrders] = useState<
    ReviewOrderDetails[]
  >([
    // Add more records as needed
  ]);

  let productListGrnData = useAppSelector((state) => state.pendingGrnData);

  let DiscrepancyStatusDataList = useAppSelector(
    (state) => state.pendingGrnData.discrepancyResponseData
  );

  useEffect(() => {
    if (dataRecordOrders && Array.isArray(DiscrepancyStatusDataList)) {
      // Create a mapping of slNo to status from DiscrepancyStatusDataList
      const statusMap = DiscrepancyStatusDataList.reduce<
        Record<string, string>
      >((map, product) => {
        map[product.slNo] = product.status;
        return map;
      }, {});

      // Update the status in dataRecordOrders based on the slNo
      const updatedDataRecordOrders = dataRecordOrders.map((order) => ({
        ...order,
        status: statusMap[order.slno] || order.status,
      }));

      setDataRecordOrders(updatedDataRecordOrders);
    }
  }, [DiscrepancyStatusDataList]);

  const tableHeightRef = useRef(160);
  const tableHeightloadRef = useRef(180);

  const filterData = {
    page: 0,
    size: 0,
    invNo: requestData.invoiceNo,
    // search: textFieldValue,
  };
  // useEffect(() => {
  //   const handlePopState = (event: PopStateEvent) => {
  //     event.preventDefault();
  //     window.history.go(1);
  //   };
  //   window.history.pushState(null, "", window.location.href);
  //   window.addEventListener("popstate", handlePopState);
  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, []);
  // useEffect(() => {
  //   const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  //     const message =
  //       "Are you sure you want to leave this page? You have unsaved changes.";
  //     event.preventDefault();
  //     event.returnValue = message;
  //     return message;
  //   };
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === "hidden") {
  //       alert("You are switching tabs. Please be aware of unsaved changes.");
  //     }
  //   };
  //   document.addEventListener("visibilitychange", handleVisibilityChange);
  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);

  useEffect(() => {
    setHasMore(true);
    loadMoreData(true);
  }, []);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 353;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const tableHeightLoad = () => {
    const headerHeight = 0;
    const footerHeoght = 300;
    const availableHeight = window.innerHeight - headerHeight - footerHeoght;
    tableHeightloadRef.current = availableHeight;
  };

  const dateObj = moment(requestData.invoiceDate, "YYYY-MM-DD");

  const formattedDate = dateObj.format("DD MMM YYYY");

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    tableHeightLoad();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setCommonLoader(true);

    let nextPage = resetPage ? 0 : page;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;

    dispatch(
      getProductDetailsGrn({
        params: { ...filterData, page: nextPage },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const newOrders = response.payload || [];
          setDataRecordOrders((prevOrders) =>
            resetPage ? newOrders : [...prevOrders, ...newOrders]
          );
          setPage(nextPage + 1);
          if (newOrders.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        } else {
          // setHasMore(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };

  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [unselectedRowKeys, setUnselectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    newSelectedRows: any[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);

    if (newSelectedRowKeys.length === dataToShow.length) {
      setIsSelectAll(true);
      setUnselectedRowKeys([]);
    } else if (newSelectedRowKeys.length === 0) {
      setIsSelectAll(false);
      setUnselectedRowKeys([]);
    } else {
      setIsSelectAll(false);
      const unselectedKeys = dataToShow
        .map((item) => item.slno)
        .filter((key) => !newSelectedRowKeys.includes(key));
      setUnselectedRowKeys(unselectedKeys);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled:
        acceptedSlno?.includes(record.slno) ||
        ["Partially Accepted", "Rejected"].includes(record.status),
    }),
  };

  const handleMultipleDiscrepancy = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    // Filter out accepted SL numbers
    const selectedSerialNo = selectedRows
      .filter((row) => !acceptedSlno.includes(row.slno))
      .map((row) => row.slno);
    const addDiscripancyData = {
      isSelectAll: isSelectAll,
      invoiceNo: requestData.invoiceNo,
      slNo: isSelectAll === true ? "0" : selectedSerialNo.toString(),
      unSelectedSlNo:
        isSelectAll && unselectedRowKeys.length > 0
          ? unselectedRowKeys.toString()
          : "0",
      page: 0,
      size: 10,
    };
    dispatch(
      getAddDiscrepancyGrn({
        params: { ...addDiscripancyData },
        signal: abortController.signal,
      })
    );
    setIsShow(!isShow);
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  const handleSingleDiscrepancy = (record: any) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const selectedSerialNo = record.slno;
    const addDiscripancyData = {
      isSelectAll: false,
      invoiceNo: requestData.invoiceNo,
      slNo: selectedSerialNo.toString(),
      unSelectedSlNo: 0,
      page: 0,
      size: 10,
    };
    dispatch(
      getAddDiscrepancyGrn({
        params: { ...addDiscripancyData },
        signal: abortController.signal,
      })
    );
    setIsShow(!isShow);
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  const [acceptedSlno, setAcceptedSlno] = useState<string[]>([]);

  const handleAcceptedClick = async (record: any) => {
    // Prepare data for API request
    const productDetail = {
      productCode: record.prodCode,
      slNo: record.slno,
      invoicedQty: parseInt(record.invQty, 10),
      invoicedValue: record.netAmt,
    };

    const saveRequestData = {
      invoiceNo: requestData.invoiceNo,
      productDetails: [productDetail], // Wrap in an array
      totalDiscrepancyQty: 0,
      totalDiscrepancyValue: 0,
    };

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    try {
      // Dispatch the API call with prepared data
      const response = await dispatch(
        acceptAllPendingGrn({
          params: saveRequestData,
          signal: abortController.signal,
        })
      );
      if (response?.meta?.requestStatus === "fulfilled") {
        setAcceptedSlno((prevSlno) => [...prevSlno, record.slno]);
      }
    } catch (error) {
      console.error("Save error", error);
    }
  };

  const handleMultipleAccept = async () => {
    const selectedSerialNo = selectedRows.map((row) => row.slno);

    setSelectedRowKeys([]);
    setSelectedRows([]);

    // Prepare data for API request
    const productDetails = selectedRows.map((item) => {
      return {
        productCode: item.prodCode,
        slNo: item.slno,
        invoicedQty: parseInt(item.invQty, 10),
        invoicedValue: item.netAmt,
      };
    });

    const saveRequestData = {
      invoiceNo: requestData.invoiceNo,
      productDetails: productDetails,
      totalDiscrepancyQty: 0,
      totalDiscrepancyValue: 0,
    };

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    try {
      // Dispatch the API call with prepared data
      const response = await dispatch(
        acceptAllPendingGrn({
          params: saveRequestData,
          signal: abortController.signal,
        })
      );
      if (response?.meta?.requestStatus === "fulfilled") {
        setAcceptedSlno((prevSlno) =>
          Array.from(new Set([...prevSlno, ...selectedSerialNo]))
        );
      }
    } catch (error) {
      console.error("Save error", error);
    }
  };

  const reviewOrderColumns: TableColumnsType<ReviewOrderDetails> = [
    {
      dataIndex: "prodName",
      title: "Product Name",
      // width: "20%",
      ellipsis: true,
      render: (_: any, record: ReviewOrderDetails) => {
        const productName = record?.matDesc
          ? record?.matDesc
          : record?.prodName;
        return productName;
      },
    },

    {
      dataIndex: "prodCode",
      title: "Product Code",
      // width: "12%",
    },
    {
      dataIndex: "uomCode",
      title: "UOM",
      // width: "10%",
      align: "center",
    },

    {
      dataIndex: "mrp",
      title: "MRP / PC",
      // width: "10%",
      align: "end",
      render: (text: string | number, record: ReviewOrderDetails) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "invQty",
      title: "Invoice Quantity",
      // width: "10%",
      align: "center"
    },
    {
      dataIndex: "invoiceValue",
      title: "Invoice Value",
      // width: "15%",
      align: "end",
      render: (_: any, record: ReviewOrderDetails) => {
        return (
          <span>
            <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
            {record?.netAmt ? record?.netAmt.toFixed(2) : "0"}
          </span>
        );
      },
    },
    {
      dataIndex: "actions",
      title: "Action",
      align: "center",
      width: "15%",
      render: (_: any, record: ReviewOrderDetails, index: number) => {
        const isSelected = selectedRowKeys.includes(record.slno);
        const status = record.status;
        return (
          <>
            {acceptedSlno?.includes(record.slno) ? (
              <Button className="Accepted_button_pen">{"Accepted"}</Button>
            ) : status === "Partially Accepted" ? (
              <Button className="Partiatially_button_pen">{status}</Button>
            ) : status === "Rejected" ? (
              <Button className="Rejected_button_pen">{status}</Button>
            ) : (
              <Grid
                container
                sx={{
                  justifyContent: "center",
                  pointerEvents: isSelected ? "none" : "auto",
                  opacity: isSelected ? 0.5 : 1,
                }}
              >
                <Grid item onClick={() => handleSingleDiscrepancy(record)}>
                  <img
                    src={Discrepancy_img}
                    alt="Discrepancy"
                    style={{ width: "25px", height: "25px" }}
                  />
                </Grid>
                <Grid item ml={1} onClick={() => handleAcceptedClick(record)}>
                  <img
                    src={Accept_img}
                    alt="Accept"
                    style={{ width: "25px", height: "25px" }}
                  />
                </Grid>
              </Grid>
            )}
          </>
        );
      },
    },
  ];
  const handleSaveQuantity = () => {
    setIsShowConfirm(true);
  };
  const filteredDataToShow = dataRecordOrders.filter((item) =>
    item.prodName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.prodCode?.toLowerCase().includes(searchTerm.toLowerCase()) 
  );

  const dataToShow: ReviewOrderDetails[] =
    filteredDataToShow.length > 0
      ? hasMore
        ? filteredDataToShow
        : [...filteredDataToShow]
      : [];

  // const dataToShow: ReviewOrderDetails[] =
  //   dataRecordOrders.length > 0
  //     ? hasMore
  //       ? dataRecordOrders
  //       : [...dataRecordOrders]
  //     : [];

  const areAllRowsDisabled = () => {
    return dataRecordOrders.every(
      (record) =>
        acceptedSlno.includes(record.slno) ||
        ["Partially Accepted", "Rejected"].includes(record.status)
    );
  };
 const handleSearch = () => {
    setSearchTerm(searchTerm);
  };
  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer anchor="right" open={true} className="drawer_outlet w-100px">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            // mt: 2,
          }}
        >
          <Grid item sx={{ width: "100%" }}>
            <Grid
              container
              sx={{ borderBottom: "1px solid #0000000D", padding: "12px 24px" }}
            >
              <Grid
                item
                sm={0.3}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ArrowBackIos
                  sx={{
                    fontSize: "10px",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    onClose();
                  }}
                />
              </Grid>
              <Grid item xs={6.7} sm={7.7} md={8.7} lg={8.7}>
                <Grid item>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#000000",
                      fontFamily: "Montserrat",
                      fontSize: "13px",
                    }}
                  >
                    Pending GRN
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      color: "#6ec358fa",
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    Delivered
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={5}
                sm={4}
                md={3}
                lg={3}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <SearchBar
                  className="search_box_pending w-100px"
                  placeholder="Search Product Name, Code...."
                    value={searchTerm}
                    onChange={(newValue) => {
                      setSearchTerm(newValue);
                    }}
                    onCancelResearch={() => setSearchTerm("")}
                    onSearch={(newValue) => {
                      handleSearch();
                    }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form saleable-products-box"
          >
            {" "}
            <Grid
              item
              sx={{
                width: "100%",
                margin: "auto",
                marginBottom: "90px",
                padding: "0px 24px",
              }}
            >
              <Grid container pl={3.6} mt={2}>
                <Grid item sm={3}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Invoice No
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {requestData?.invoiceNo}
                    </Typography>
                  </Grid>
                </Grid>
                {/* <Grid item sm={3}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Order ID
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {requestData?.orderNo}
                    </Typography>
                  </Grid>
                </Grid> */}
                <Grid item sm={3}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Invoice Date
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {formattedDate}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={3}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      No of Line item
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      {requestData?.noOfLineItem}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sm={3}>
                  <Grid item>
                    <Typography className="Pending_review_lable">
                      Value
                    </Typography>
                  </Grid>
                  <Grid item mt={0.7}>
                    <Typography className="Pending_review_Sublable">
                      <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
                      {requestData?.totNetAmt
                        ? requestData?.totNetAmt.toFixed(2)
                        : "0"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="pad-top-0px"
                  sx={{ height: tableHeightloadRef.current }}
                >
                  <div className="saleable-table-scroll">
                    <Table
                      className={`Header-table saleable-table sale_last_hide scroll_cus_table antcheck_manual Header_chechbox pending_grn_table ${productListGrnData.ProductDetailsGrnLoading
                        ? " nodataloader"
                        : ""
                        }`}
                      columns={reviewOrderColumns}
                      dataSource={dataToShow}
                      pagination={false}
                      scroll={{ y: tableHeightRef.current }}
                      loading={productListGrnData?.ProductDetailsGrnLoading}
                      showSorterTooltip={false}
                      summary={() => {
                        if (!hasMore && dataRecordOrders.length > 0) {
                          return (
                            <Table.Summary.Row>
                              <Table.Summary.Cell
                                index={1}
                                colSpan={8}
                                align="center"
                                className="noMore"
                              >
                                No more data found...
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        } else if (
                          page !== 0 &&
                          productListGrnData.ProductDetailsGrnLoading
                        ) {
                          return (
                            <Table.Summary.Row>
                              <Table.Summary.Cell
                                index={1}
                                colSpan={8}
                                align="center"
                              >
                                <Spin />
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        }
                      }}
                      rowSelection={rowSelection}
                      rowKey={(record) => record.slno}
                    />
                  </div>
                </Grid>
              </Grid>
              <hr className="hr-revieworder"></hr>
              <Grid container
                mt={1.5}
              >
                <Grid
                  item
                  sm={6}


                >
                  <Typography>
                    {selectedRowKeys.length === 0 ||
                      selectedRows.every((row) => acceptedSlno.includes(row.slno)) ? (
                      " "
                    ) : (
                      <b> selected {selectedRowKeys.length}</b>
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm={6}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    className={
                      selectedRowKeys.length === 0 ||
                        selectedRows.every((row) => acceptedSlno.includes(row.slno))
                        ? "disabled_btn_discrepancy"
                        : "pending_btn_discrepancy"
                    }
                    sx={{
                      height: "34px",
                      width: { sm: "30%", md: "23%", lg: "20%" },
                    }}
                    onClick={handleMultipleDiscrepancy}
                    disabled={
                      selectedRowKeys.length === 0 ||
                      selectedRows.every((row) => acceptedSlno.includes(row.slno))
                    }
                  >
                    Add Discrepancy
                  </Button>
                  <Button
                    className={
                      selectedRowKeys.length === 0 ||
                        selectedRows.every((row) => acceptedSlno.includes(row.slno))
                        ? "disabled_btn_discrepancy"
                        : "pending_btn_accept"
                    }
                    sx={{
                      height: "34px",
                      width: { sm: "30%", md: "23%", lg: "20%" },

                    }}
                    disabled={
                      selectedRowKeys.length === 0 ||
                      selectedRows.every((row) => acceptedSlno.includes(row.slno))
                    }
                    onClick={handleMultipleAccept}
                  >
                    Accept
                  </Button>
                  <Button
                    className={
                      !areAllRowsDisabled()
                        ? "disabled_btn_discrepancy"
                        : "pending_btn_next"
                    }
                    sx={{
                      height: "34px",
                      width: { sm: "30%", md: "23%", lg: "20%" },

                      mr: 2,
                    }}
                    onClick={handleSaveQuantity}
                    disabled={!areAllRowsDisabled()}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
      {isShow ? (
        <Discrepancy
          onClose={() => setIsShow(false)}
          requestData={requestData}
        />
      ) : (
        <></>
      )}
      {isShowConfirm ? (
        <PendingConfirmOrder
          onClose={() => setIsShowConfirm(false)}
          requestData={requestData}
        // acceptedData={acceptedSlno}
        />
      ) : (
        <></>
      )}
    </Box>
  );
}
