import React, { useRef } from 'react';
import { Button, Grid, TextField, Card, Box } from '@mui/material';
import * as XLSX from 'xlsx';
import type { TableColumnsType } from 'antd';
import SearchBar from '@mkyy/mui-search-bar';
import CommonTable from '../../../components/Tables/CommonTable';

interface DataType {
  key: React.Key;
  processName: string;
  fileName: string;
  start: string;
  end: string;
  status: string;
  action: string;
}

function convertHeadersToXLS(headers: any) {
  const worksheet = XLSX.utils.aoa_to_sheet([headers]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  return blob;
}

// Function to download XLS file with headers only
function downloadXLSWithHeaders(headers: any) {
  const xlsBlob = convertHeadersToXLS(headers);
  const url = URL.createObjectURL(xlsBlob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'exported_headers.xlsx');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

interface RoutePlanFormProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const RouteExcelExport: React.FC<RoutePlanFormProps> = ({ onBack }) => {

  const [fileName, setFileName] = React.useState();
  const [hideCard, setHideCard] = React.useState(true);

  const [textFieldValue, setTextFieldValue] = React.useState('');

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // search function logic here
  };

  // Your data
  const headers = ['ID', 'Commodity', 'Price', 'Quantity'];
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      // Do something with the selected file
      // console.log(selectedFile.name);
    }
  };
  const hideandshow = () => {
    setHideCard(!hideCard);
  }

  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };

  const columns: TableColumnsType<DataType> = [

    {
      dataIndex: 'processName',
      title: 'Process Name',
      sorter: (a, b) => a.processName.length - b.processName.length,
    },

    {
      dataIndex: 'fileName',
      title: 'File Name',
      sorter: (a, b) => a.fileName.length - b.fileName.length,
    },

    {
      dataIndex: 'start',
      title: 'Start Date',
      sorter: (a, b) => a.start.length - b.start.length,
    },

    {
      dataIndex: 'end',
      title: 'End Date',
      sorter: (a, b) => a.end.length - b.end.length,
    },

    {
      dataIndex: 'status',
      title: 'Status',
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      dataIndex: 'action',
      title: 'Action',

    },

  ];
  const data: DataType[] = [
    { key: 1, processName: "001", fileName: 'Supplier1', start: 'Comapny1', end: 'Supplier1', status: 'Comapny1', action: ' ' },
    { key: 2, processName: "002", fileName: 'Supplier2', start: 'Comapny2', end: 'Supplier1', status: 'Comapny1', action: ' ' },
    { key: 3, processName: "003", fileName: 'Supplier3', start: 'Comapny3', end: 'Supplier1', status: 'Comapny1', action: ' ' },
    { key: 4, processName: "004", fileName: 'Supplier4', start: 'Comapny4', end: 'Supplier1', status: 'Comapny1', action: ' ' },
    { key: 5, processName: "005", fileName: 'Supplier5', start: 'Comapny5', end: 'Supplier1', status: 'Comapny1', action: ' ' },
    { key: 6, processName: "006", fileName: 'Supplier6', start: 'Comapny6', end: 'Supplier1', status: 'Comapny1', action: ' ' },
    { key: 7, processName: "007", fileName: 'Supplier7', start: 'Comapny7', end: 'Supplier1', status: 'Comapny1', action: ' ' },
    { key: 8, processName: "008", fileName: 'Supplier8', start: 'Comapny8', end: 'Supplier1', status: 'Comapny1', action: ' ' },
  ];
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };


  return (
    <>
      <Button variant="contained" className='btn-apply' onClick={hideandshow}> - importorExport</Button>
      {
        hideCard && <Card className='card-form' sx={{ border: "1px solid #EEE" }}>
          <Button variant="contained" className='btn-apply' onClick={() => downloadXLSWithHeaders(headers)}>Download Format</Button>
          <input
            ref={fileInputRef}
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <Grid container sx={{ marginTop: "16px" }}>
            <Grid item xs={2} >
              <TextField size='small' value={fileName}></TextField>

            </Grid>
            <Grid item xs={5}>
              <Button variant="contained" className='btn-apply-color' sx={{ marginLeft: "5px" }} onClick={handleButtonClick}>Select File</Button>
              <Button variant="contained" className='btn-apply-color' sx={{ marginLeft: "5px" }} onClick={handleButtonClick}>Upload</Button>


            </Grid>


          </Grid>
          <Grid item xs={12} mt={2} display="flex" justifyContent="center">
            <Button variant="outlined" color="primary" onClick={handleBackClick}>
              Cancel
            </Button>
            <Button variant="contained" className='btn-colr' style={{ marginLeft: "10px" }}>
              Reset
            </Button>
          </Grid>
        </Card>}

      <Box className='mar-tb'>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', mb: 2 }} >
          <SearchBar
            className='search-table'
            value={textFieldValue}
            onChange={newValue => {
              setTextFieldValue(newValue);
              handleSearch();
            }}
          />
        </Grid>
        <CommonTable 
        // rowSelection={rowSelection} 
        data={data} columns={columns} />
      </Box>
    </>


  );
}
export default RouteExcelExport

