import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import Box from "@mui/material/Box";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import {
  Button,
  Card,
  Drawer,
  FormControl,
  Grid,
  InputBase,
  Typography,
  RadioGroup,
  FormControlLabel,
  Snackbar,
  Alert,
  Radio,
} from "@mui/material";
import Close from "../../../../../assets/images/icons/Close_icon.png";
import { Select, Collapse, Upload } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useFetch";
import Warn from "../../../../../assets/images/icons/Warn.png";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getBankList,
  getCollectionSalesman,
  saveCollection,
} from "../../../../../Redux/XDM/Sales/collectionReducer";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import dayjs from 'dayjs';

interface RequestData {
  selectedItem: string;
  salesman: string;
  routeCodeSelected: string;
  searchValue: string;
}

interface CollectionCreateProps {
  onClose: () => void;
  requestData: RequestData;
}
interface CollectionOutletData {
  invoiceNumber: string;
  outStandingAmount: number;
}

interface InvoiceDetailsData {
  invoiceDetails: CollectionOutletData[];
  totalOutStandingAmount: number;
  outletCode: string;
  routeCode: string;
}

const { Option } = Select;
interface Bank {
  id: string;
  name: string;
}

export default function CollectionCreate(props: CollectionCreateProps) {
  const { Dragger } = Upload;
  const { onClose, requestData } = props; // Destructure the props
  const dispatch = useAppDispatch();

  const abortControllerRef = useRef<AbortController | null>(null);

  const [enteredAmount, setEnteredAmount] = useState<number>(0);
  const [amountError, setAmountError] = useState<string>("");
  const [inputValues, setInputValues] = useState<Record<string, string>>({});
  const [InvoiceAmtErrors, setInvoiceAmtErrors] = useState<
    Record<string, boolean>
  >({});

  const [images, setImages] = useState<File[]>([]);
  const [selectedSalesmanId, setSelectedSalesmanId] = useState<string>("");
  const [salesmanError, setSalesmanError] = useState<string>("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentError, setPaymentError] = useState<string | null>(null);
  const [chequeNumber, setChequeNumber] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [chequeDate, setChequeDate] = useState<Date | null>(null);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [maxImageError, setMaxImageError] = useState<string | null>(null);
  const [imageUploaded, setImageUploaded] = useState<boolean>(false);
  const [amtEqualError, setAmtEqualError] = useState<string | null>(null);
  const [enterAmtCashEqualError, setEnterAmtCashEqualError] = useState<
    string | null
  >(null);
  const [open, setOpen] = React.useState(false);
  const [bankError, setBankError] = useState<string | null>(null);
  const [chequeDateError, setChequeDateError] = useState<string | null>(null);
  const [chequeNumberError, setChequeNumberError] = useState<string | null>(
    null
  );
  const [isDisabled, setIsDisabled] = useState(false); // New state for disabling the button
  const OutletInvoiceDetailsData = useAppSelector(
    (state) => state.collectionReducerData
  );
  const Options = OutletInvoiceDetailsData.salesman;
  const BankDetailsData = OutletInvoiceDetailsData.bankListData;
  const OutletDetailsData =
    OutletInvoiceDetailsData?.collectionOultetDetialsData as unknown as InvoiceDetailsData[];
  const totalOutStandingAmount = OutletDetailsData[0]?.totalOutStandingAmount;

  const InvoiceDetails = OutletDetailsData[0]?.invoiceDetails;
  const routeCode = OutletDetailsData[0]?.routeCode;

  const outletCode = OutletDetailsData[0]?.outletCode;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [collapseOpen, setCollapseOpen] = useState<boolean>(false);

  const handlePaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(event.target.value);
    setPaymentError(null);
    setUploadError(null);
    setImages([]);
    setEnterAmtCashEqualError(null);
    setMaxImageError(null);
  };

  const handleImageUpload = (info: any) => {
    if (info.fileList) {
      setImages(info.fileList.map((file: any) => file.originFileObj));
    }
    if (info.fileList.length > 0) {
      setImageUploaded(true);
      setUploadError(null);
    }
  };

  type DenominationKeys =
    | "500"
    | "200"
    | "100"
    | "50"
    | "20"
    | "10"
    | "5"
    | "2"
    | "1";

  const [denomination, setDenomination] = useState<
    Record<DenominationKeys, number>
  >({
    "500": 0,
    "200": 0,
    "100": 0,
    "50": 0,
    "20": 0,
    "10": 0,
    "5": 0,
    "2": 0,
    "1": 0,
  });

  const totalAmount = Object.entries(denomination).reduce(
    (acc, [key, value]) => {
      return acc + parseInt(key, 10) * value;
    },
    0
  );
  const handleCountOfRupees = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    denominationType: string
  ) => {
    const value = parseInt(event.target.value) || 0;
    setDenomination((prev) => ({
      ...prev,
      [denominationType]: value,
    }));
  };

  useEffect(() => {
    if (totalAmount !== enteredAmount) {
      setEnterAmtCashEqualError("To match the entered amount");
    } else {
      setEnterAmtCashEqualError(null);
    }
  }, [totalAmount]);

  useEffect(() => {
    dispatch(getCollectionSalesman(requestData.selectedItem));
    dispatch(getBankList());
  }, [dispatch]);

  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value.replace(/[^0-9.]/g, "")) || 0;
    const roundedValue = Math.round(value * 100) / 100;
    setEnteredAmount(roundedValue);

    const roundedTotal = Math.round(totalOutStandingAmount * 100) / 100;

    if (roundedValue <= 0) {
      setAmountError("Please enter an amount.");
      setCollapseOpen(false); // Close Collapse when invalid amount
    } else if (roundedValue > roundedTotal) {
      setAmountError("Amount cannot exceed Outstanding Amount.");
      setCollapseOpen(false); // Close Collapse for invalid amount
    } else {
      setAmountError("");
      setCollapseOpen(true); // Open Collapse for valid amount
    }

    // Apply amount to invoices using FIFO
    allocateAmountToInvoices(roundedValue);
  };

  const allocateAmountToInvoices = (totalAmount: number) => {
    let remainingAmount = totalAmount;
    const updatedInputValues: Record<string, string> = {};
    let newInvoiceAmtErrors: Record<string, boolean> = {};

    InvoiceDetails?.forEach((invoice) => {
      const outstanding = invoice?.outStandingAmount;

      if (remainingAmount > 0) {
        const allocatedAmount = Math.min(remainingAmount, outstanding);
        remainingAmount -= allocatedAmount;

        updatedInputValues[invoice.invoiceNumber] = allocatedAmount.toFixed(2);
        newInvoiceAmtErrors[invoice.invoiceNumber] = allocatedAmount > outstanding;
      } else {
        updatedInputValues[invoice.invoiceNumber] = ""; // No amount allocated
        newInvoiceAmtErrors[invoice.invoiceNumber] = false;
      }
    });

    setInputValues(updatedInputValues);
    setInvoiceAmtErrors(newInvoiceAmtErrors);

    const totalAllocated = Object.values(updatedInputValues)
      .map((val) => parseFloat(val) || 0)
      .reduce((acc, curr) => acc + curr, 0);
    // Check if the total matches the entered amount
    if (totalAllocated !== totalAmount) {
      setAmtEqualError("Total allocated amount does not match the entered amount.");
    } else {
      setAmtEqualError(null);
    }
  };


  const handleSalesmanChange = (value: string) => {
    setSelectedSalesmanId(value);
    setSalesmanError("");
  };

  const totalPendingAmount = totalOutStandingAmount - enteredAmount;

  // Initialize inputValues and errors when InvoiceDetails is available
  useEffect(() => {
    if (InvoiceDetails?.length > 0) {
      const initialValues = InvoiceDetails.reduce((acc, invoice) => {
        acc[invoice.invoiceNumber] = ""; // Initialize as empty
        return acc;
      }, {} as Record<string, string>);

      const initialErrors = InvoiceDetails.reduce((acc, invoice) => {
        acc[invoice.invoiceNumber] = false;
        return acc;
      }, {} as Record<string, boolean>);

      setInputValues(initialValues);
      setInvoiceAmtErrors(initialErrors);
    }
  }, [InvoiceDetails]);

  const handleInvoiceInput = (
    invoiceNo: string,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    // Allow only numbers and one decimal point
    const sanitizedValue = e.target.value
      .replace(/[^0-9.]/g, "") // Allow only numbers and one decimal point
      .replace(/(\..*?)\./g, "$1"); // Prevent multiple decimal points

    // Convert the sanitized value to a float or fallback to 0
    const numericValue = parseFloat(sanitizedValue) || 0;

    // Update the input values without currency symbol
    setInputValues((prev) => {
      const updatedValues = {
        ...prev,
        [invoiceNo]: sanitizedValue,
      };

      const totalEnteredAmount = Object.values(updatedValues)
        .map((value) => parseFloat(value) || 0)
        .reduce((acc, curr) => acc + curr, 0);

      const roundedEnteredAmount = Math.round(enteredAmount * 100) / 100;
      const roundedTotalEnteredAmount =
        Math.round(totalEnteredAmount * 100) / 100;

      if (roundedTotalEnteredAmount !== roundedEnteredAmount) {
        setAmtEqualError("To match the total collection amount");
      } else {
        setAmtEqualError(null);
      }

      return updatedValues;
    });

    const invoice = InvoiceDetails?.find(
      (inv) => inv.invoiceNumber === invoiceNo
    );
    if (invoice) {
      const roundvalamt = Number(invoice?.outStandingAmount.toFixed(2))
      if (numericValue > roundvalamt) {
        setInvoiceAmtErrors((prev) => ({ ...prev, [invoiceNo]: true }));
      } else {
        setInvoiceAmtErrors((prev) => ({ ...prev, [invoiceNo]: false }));
      }
    }
  };

  const validateForm = () => {
    let hasError = false;

    if (!enteredAmount || enteredAmount <= 0) {
      setAmountError("Please enter a amount.");
      hasError = true;
    } else if (enteredAmount > totalOutStandingAmount) {
      setAmountError("Amount cannot exceed Outstanding Amount.");
      hasError = true;
    } else {
      setAmountError("");
    }

    if (!selectedSalesmanId) {
      setSalesmanError("Please select a salesman.");
      hasError = true;
    } else {
      setSalesmanError("");
    }

    if (!paymentMethod) {
      setPaymentError("Please select a payment method.");
      hasError = true;
    } else {
      setPaymentError(null);
    }

    if (amountError || Object.values(InvoiceAmtErrors).includes(true)) {
      hasError = true;
    }
    // if (
    //   (paymentMethod === "upi") &&
    //   !imageUploaded
    // ) {
    //   setUploadError("Image is required.");
    //   hasError = true;
    // }
    if (amtEqualError == "To match the total collection amount") {
      hasError = true;
    }
    // Compare the enter  amount with cash total
    if (enterAmtCashEqualError == "To match the enter amount") {
      hasError = true;
    }
    // Validate Cheque details when payment method is 'cheque'
    if (paymentMethod === "cheque") {
      if (!selectedBank) {
        setBankError("Please select a bank.");
        hasError = true;
      } else {
        setBankError("");
      }

      if (!chequeDate) {
        setChequeDateError("Please select a cheque date.");
        hasError = true;
      } else {
        setChequeDateError("");
      }

      if (!chequeNumber) {
        setChequeNumberError("Please enter a valid cheque number.");
        hasError = true;
      } else {
        setChequeNumberError("");
      }
      if (maxImageError === "You can only upload a maximum of 3 images.") {
        hasError = true;
      }
      if (uploadError === "You can only upload SVG, PNG, JPG, or GIF files.") {
        hasError = true;
      }
    }
    return !hasError;
  };

  const handleSaveCollection = async () => {
    if (!validateForm()) {
      return;
    }
    setButtonLoading(true);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const formData = new FormData();
    formData.append("collectionType", paymentMethod);
    formData.append("totalCollectedAmount", String(enteredAmount));
    formData.append("smUserId", selectedSalesmanId);
    formData.append("outletCode", outletCode);
    formData.append("routeCode", routeCode);

    InvoiceDetails?.forEach((invoice, index) => {
      const collectedAmt =
        Number(inputValues[invoice.invoiceNumber]?.replace(/[^0-9.]/g, "")) || 0;

      if (collectedAmt > 0) {
        formData.append(
          `invoiceDetails[${index}].invoiceNo`,
          invoice.invoiceNumber
        );
        formData.append(
          `invoiceDetails[${index}].collectedAmt`,
          String(collectedAmt)
        );
      }
    });

    if (paymentMethod === "upi") {
      images.forEach((image) => {
        formData.append("images", image);
      });
    }

    if (paymentMethod === "cheque") {
      formData.append("chequeDetails.chequeNumber", chequeNumber);
      formData.append("chequeDetails.bankName", selectedBank);
      if (chequeDate) {
        const formattedChequeDate = moment(chequeDate).format("YYYY-MM-DD");
        formData.append("chequeDetails.chequeDate", formattedChequeDate);
      } else {
        formData.append("chequeDetails.chequeDate", "");
      }
      images.forEach((image) => {
        formData.append("images", image);
      });
    }

    // Append cash details if payment method is 'cash'
    if (paymentMethod === "cash") {
      formData.append(
        "cashDetails.denomination500",
        denomination["500"].toString()
      );
      formData.append(
        "cashDetails.denomination200",
        denomination["200"].toString()
      );
      formData.append(
        "cashDetails.denomination100",
        denomination["100"].toString()
      );
      formData.append(
        "cashDetails.denomination50",
        denomination["50"].toString()
      );
      formData.append(
        "cashDetails.denomination20",
        denomination["20"].toString()
      );
      formData.append(
        "cashDetails.denomination10",
        denomination["10"].toString()
      );
      formData.append(
        "cashDetails.denomination5",
        denomination["5"].toString()
      );
      formData.append(
        "cashDetails.denomination2",
        denomination["2"].toString()
      );
      formData.append(
        "cashDetails.denomination1",
        denomination["1"].toString()
      );
    }

    try {
      const response = await dispatch(
        saveCollection({ formData, signal: abortController.signal })
      );

      if (response?.meta?.requestStatus === "fulfilled") {
        setSnackbarMessage("Saved Successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setButtonLoading(false);
        setIsDisabled(true); // Disable the button when fulfilled
        setTimeout(() => {
          setSnackbarOpen(false);
          onClose();
        }, 3000);
      } else if (response?.meta?.requestStatus === "rejected") {
        setButtonLoading(false);
        const errorMessage = 
          response?.payload?.data || "Error while saving";
          // response?.payload?.data?.invoiceDetails || "Error while saving";
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
          // onClose();
        }, 3000);
      }
    } catch (error) {
      setButtonLoading(false);
      setSnackbarMessage("An unexpected error occurred");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 3000);
    }
  };

  const handleCollapseToggle = () => {
    setCollapseOpen((prevOpen) => !prevOpen);
  };


  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="pen_drawer_stl"
      // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 500,
              md: 460,
              sm: 400,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
            backgroundColor: "#F6F6F6",
          }}
        >
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={100000}
            color="green"
            className="new-coll-alert"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={() => setSnackbarOpen(false)}
          >
            <Alert
              onClose={() => setSnackbarOpen(false)}
              severity={snackbarSeverity}
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <Grid item sx={{ height: "100%" }}>
            <Grid
              container
              sx={{
                position: "sticky",
                top: 0,
                // zIndex: 1,
                background: "white",
                height: "60px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
              }}
            >
              <Grid item sm={7}>
                <Typography>
                  <b>New Collection</b>
                </Typography>
              </Grid>
              <Grid item sm={5} sx={{ display: "flex", justifyContent: "end" }}>
                <img
                  onClick={onClose}
                  src={Close}
                  alt="Close"
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{ overflowY: "auto", height: "80%", padding: "20px" }}
              className="discrepancy_card"
            >
              <Card
                sx={{
                  border: "1px solid #DCDCDE",
                  padding: "15px",
                  borderRadius: "6px",
                  marginBottom: "65px",
                }}
              >
                <Grid container sx={{ alignItems: "center" }}>
                  <Grid item sm={6}>
                    <Grid item>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: "13px",
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                        }}
                      >
                        {requestData?.searchValue}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    sx={{ display: "flex", justifyContent: "end" }}
                  >
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "13px",
                        fontFamily: "Montserrat",
                        fontWeight: 900,
                      }}
                    >
                      Outstanding Amount: ₹{totalOutStandingAmount?.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography
                    sx={{
                      color: "#44464C",
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Enter Amount
                    <span style={{ color: "red" }}>&nbsp;*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} mt={1}>
                  <InputBase
                    sx={{
                      border: `1px solid #DCDCDE`,
                      width: "100%",
                      height: "38px",
                      paddingLeft: "10px",
                      color: "#505259",
                      fontSize: "10px",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      borderRadius: "4px",
                    }}
                    inputProps={{
                      maxLength: 10,
                    }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      // Allow digits (0-9) and only one decimal point
                      target.value = target.value
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*?)\./g, "$1");
                    }}
                    onChange={handleAmountChange}
                  />
                  {amountError && (
                    <Typography
                      sx={{
                        color: "red",
                        fontSize: "12px",
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                        mt: 1,
                      }}
                    >
                      {amountError}{" "}
                      {/* Display the appropriate error message */}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} mt={1}>
                  <Collapse
                    bordered={false}
                    expandIconPosition="end"
                    activeKey={collapseOpen ? "1" : ""} // Control Collapse visibility
                    onChange={handleCollapseToggle}
                    items={[
                      {
                        key: "1",
                        label: `Pending (${InvoiceDetails?.length || 0})`,
                        children: (
                          <>
                            {InvoiceDetails?.map((invoice) => (
                              <Grid
                                container
                                spacing={2}
                                key={invoice?.invoiceNumber}
                                sx={{ padding: "10px" }}
                              >
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      margin: 0,
                                      color: "#000000",
                                      fontSize: "13px",
                                      fontFamily: "Montserrat",
                                      fontWeight: 900,
                                    }}
                                  >
                                    Invoice No: {invoice?.invoiceNumber}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      margin: 0,
                                      color: "#FFA500",
                                      fontWeight: "bold",
                                      fontSize: "11px",
                                    }}
                                  >
                                    Outstanding Amount: ₹{" "}
                                    {invoice?.outStandingAmount?.toFixed(2)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <InputBase
                                    sx={{
                                      border: `1px solid ${InvoiceAmtErrors[invoice?.invoiceNumber]
                                        ? "#E12B1D"
                                        : "#DCDCDE"
                                        }`,
                                      width: "100%",
                                      height: "38px",
                                      paddingLeft: "10px",
                                      color: "#505259",
                                      fontSize: "10px",
                                      fontFamily: "Montserrat",
                                      fontWeight: 500,
                                      borderRadius: "4px",
                                    }}
                                    onChange={(e) =>
                                      handleInvoiceInput(
                                        invoice?.invoiceNumber,
                                        e as ChangeEvent<HTMLInputElement>
                                      )
                                    }
                                    value={
                                      inputValues[invoice?.invoiceNumber]
                                        ? `₹${inputValues[invoice?.invoiceNumber]
                                        }` // Add ₹ symbol before the value
                                        : ""
                                    }
                                    inputProps={{
                                      maxLength: 10,
                                    }}
                                  />

                                  {InvoiceAmtErrors[invoice?.invoiceNumber] && (
                                    <Typography
                                      variant="caption"
                                      color="#E12B1D"
                                      sx={{
                                        marginTop: "5px",
                                        fontSize: "12px",
                                        fontFamily: "Montserrat",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <ReportProblemIcon
                                          sx={{
                                            width: "13.33px",
                                            height: "12.64px",
                                            opacity: 1,
                                            marginRight: "5px",
                                          }}
                                        />
                                        Please enter an amount below ₹{" "}
                                        {invoice?.outStandingAmount?.toFixed(2)}
                                      </Box>
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>
                            ))}
                            {amtEqualError && (
                              <Typography
                                sx={{
                                  color: "red",
                                  fontFamily: "Montserrat",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  marginTop: "8px",
                                }}
                              >
                                {amtEqualError}
                              </Typography>
                            )}
                          </>
                        ),
                      },
                    ]}
                    style={{
                      // Use `style` instead of `sx` if `sx` is not supported
                      color: "#44464C",
                      fontFamily: "Montserrat",
                      fontSize: "13px",
                      fontWeight: 900,
                      background: "white",
                      border: "1px solid #d9d9d9",
                      borderRadius: "4px",
                    }}
                  />
                </Grid>
                <Grid item mt={2}>
                  <Typography
                    sx={{
                      color: "#44464C",
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Select Salesman
                    <span style={{ color: "red" }}>&nbsp;*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} mt={1}>
                  <FormControl
                    fullWidth
                    sx={{ marginTop: "9px", marginBottom: "2px" }}
                  >
                    <Select
                      className="Quentity_select_box"
                      showSearch
                      placeholder="Select a salesman"
                      filterOption={(input, option) => {
                        const optionText =
                          option?.children as unknown as string;
                        return optionText
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                      dropdownStyle={{ zIndex: 2000 }}
                      style={{ width: "100%", fontSize: "12px" }}
                      onChange={handleSalesmanChange}
                    >
                      {Array.isArray(Options) &&
                        Options?.length > 0 &&
                        Options?.map((option) => (
                          <Option
                            key={option?.smUserId}
                            value={option.smUserId}
                          >
                            {option.userName}
                          </Option>
                        ))}
                    </Select>
                    {salesmanError && (
                      <Typography
                        variant="caption"
                        color="error"
                        sx={{
                          marginTop: "5px",
                          fontSize: "12px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {salesmanError}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item mt={2}>
                  <Typography
                    sx={{
                      color: "#44464C",
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Select Payment Method
                    <span style={{ color: "red" }}>&nbsp;*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    sx={{ marginTop: "9px", marginBottom: "2px" }}
                  >
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={paymentMethod}
                      onChange={handlePaymentChange}
                    >
                      <FormControlLabel
                        value="upi"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 18,
                                color: "#6750A4",
                              },
                            }}
                          />
                        }
                        label="UPI"
                      />
                      <FormControlLabel
                        value="cash"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 18,
                                color: "#6750A4",
                              },
                            }}
                          />
                        }
                        label="Cash"
                      />
                      <FormControlLabel
                        value="cheque"
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 18,
                                color: "#6750A4",
                              },
                            }}
                          />
                        }
                        label="Cheque"
                      />
                    </RadioGroup>
                    {paymentError && (
                      <Typography
                        sx={{
                          color: "red",
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 500,
                          marginTop: "8px",
                        }}
                      >
                        {paymentError}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                {paymentMethod === "upi" && (
                  <>
                    <Grid item mt={2}>
                      <Typography
                        sx={{
                          color: "#44464C",
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Upload UPI Image (Optional)
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={1}>
                      <FormControl
                        fullWidth
                        sx={{ marginTop: "9px", marginBottom: "2px" }}
                      >
                        <Dragger
                          name="file"
                          multiple
                          onChange={handleImageUpload}
                          beforeUpload={(file) => {
                            const isAllowedType =
                              file.type === "image/svg+xml" ||
                              file.type === "image/png" ||
                              file.type === "image/jpeg" ||
                              file.type === "image/gif";

                            if (!isAllowedType) {
                              setUploadError(
                                "You can only upload SVG, PNG, JPG, or GIF files."
                              );
                              return Upload.LIST_IGNORE;
                            }

                            setUploadError(null);
                            return false;
                          }}
                          customRequest={() => { }} // Custom request to handle image upload
                        >
                          <p className="ant-upload-drag-icon">
                            <img src={Warn} alt="search icon" />
                          </p>
                          <p
                            className="ant-upload-text"
                            style={{
                              color: "#44464C",
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            <span className="uploadGraditent">
                              Click to upload
                            </span>
                            or drag and drop
                          </p>
                          <p
                            className="ant-upload-hint"
                            style={{
                              color: "#44464C",
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            SVG, PNG, JPG or GIF (max. 800x400px)
                          </p>
                        </Dragger>
                      </FormControl>
                      {uploadError && (
                        <Typography
                          sx={{
                            color: "red",
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            fontWeight: 500,
                            marginTop: "8px",
                          }}
                        >
                          {uploadError}
                        </Typography>
                      )}
                      {maxImageError && (
                        <Typography
                          sx={{
                            color: "red",
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            fontWeight: 500,
                            marginTop: "8px",
                          }}
                        >
                          {maxImageError}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item mt={2}>
                      <Typography
                        sx={{
                          color: "#44464C",
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Enter Remark (Optional)
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={1}>
                      <InputBase
                        sx={{
                          border: "1px solid #DCDCDE",
                          width: "100%",
                          height: "38px",
                          paddingLeft: "10px",
                          color: "#505259",
                          fontSize: "10px",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                          borderRadius: "4px",
                        }}
                        inputProps={{
                          maxLength: 200,
                        }}
                      // onInput={(e) => {
                      //   const target = e.target as HTMLInputElement;
                      //   // Allow digits (0-9) and only one decimal point
                      //   target.value = target.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\./g, "$1");
                      // }}
                      />
                    </Grid>
                  </>
                )}
                {paymentMethod === "cash" && (
                  <>
                    <Grid item mt={2}>
                      <Typography
                        sx={{
                          color: "#44464C",
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Enter Payment Denomination
                      </Typography>
                    </Grid>

                    {/* Loop over denominations to avoid repetitive code */}
                    {[500, 200, 100, 50, 20, 10, 5, 2, 1].map((val) => (
                      <React.Fragment key={val}>
                        <Grid
                          container
                          spacing={3}
                          sx={{
                            marginTop: "1px",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Grid item xs={2}>
                            <Typography
                              sx={{
                                color: "#44464C",
                                fontFamily: "Montserrat",
                                fontSize: "12px",
                                fontWeight: 500,
                              }}
                            >
                              ₹ {val}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              sx={{
                                color: "#44464C",
                                fontFamily: "Montserrat",
                                fontSize: "12px",
                                fontWeight: 500,
                                textAlign: "center",
                              }}
                            >
                              X
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <InputBase
                              sx={{
                                border: "1px solid #DCDCDE",
                                width: "100%",
                                height: "38px",
                                paddingLeft: "10px",
                                color: "#505259",
                                fontSize: "10px",
                                fontFamily: "Montserrat",
                                fontWeight: 500,
                                borderRadius: "4px",
                              }}
                              inputProps={{
                                maxLength: 10,
                              }}
                              value={
                                denomination[
                                val.toString() as DenominationKeys
                                ] || 0
                              }
                              onChange={(e) =>
                                handleCountOfRupees(e, val.toString())
                              }
                              onInput={(e) => {
                                const target = e.target as HTMLInputElement;
                                // Allow digits (0-9) and only one decimal point
                                target.value = target.value
                                  .replace(/[^0-9.]/g, "")
                                  .replace(/(\..*?)\./g, "$1");
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Typography
                              sx={{
                                color: "#44464C",
                                fontFamily: "Montserrat",
                                fontSize: "12px",
                                fontWeight: 500,
                                textAlign: "center",
                              }}
                            >
                              ₹{" "}
                              {denomination[
                                val.toString() as DenominationKeys
                              ] * val}
                            </Typography>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    ))}

                    {/* Display total amount */}
                    <>
                      <Grid item xs={12} mt={2}>
                        <Typography
                          sx={{
                            color: "#44464C",
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}
                        >
                          Total Amount
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: "#44464C",
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            fontWeight: 500,
                            textAlign: "right",
                          }}
                        >
                          ₹ {totalAmount}
                        </Typography>
                      </Grid>
                      {enterAmtCashEqualError && (
                        <Typography
                          sx={{
                            color: "red",
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            fontWeight: 500,
                            marginTop: "8px",
                          }}
                        >
                          {enterAmtCashEqualError}
                        </Typography>
                      )}
                    </>
                  </>
                )}
                {paymentMethod === "cheque" && (
                  <>
                    <Grid item mt={2}>
                      <Typography
                        sx={{
                          color: "#44464C",
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Select Bank Name
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={1}>
                      <FormControl
                        fullWidth
                        sx={{ marginTop: "9px", marginBottom: "2px" }}
                      >
                        <Select
                          className="Quentity_select_box"
                          showSearch
                          placeholder="Select Bank Name"
                          filterOption={(input, option) => {
                            const optionText =
                              option?.children?.toString().toLowerCase() || "";
                            return optionText.includes(input.toLowerCase());
                          }}
                          dropdownStyle={{ zIndex: 2000 }}
                          style={{ width: "100%", fontSize: "12px" }} // Adjust the width as necessary
                          value={selectedBank}
                          onChange={(value) => {
                            setSelectedBank(value);
                            if (value) {
                              setBankError(null); // Clear the error once a bank is selected
                            }
                          }}
                        >
                          {Array.isArray(BankDetailsData) &&
                            BankDetailsData?.length > 0 &&
                            BankDetailsData?.map((bank) => (
                              <Option key={bank?.id} value={bank.bankName}>
                                {bank.bankName}
                              </Option>
                            ))}
                        </Select>
                      </FormControl>
                      {bankError && (
                        <Typography
                          sx={{
                            color: "red",
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            fontWeight: 500,
                            marginTop: "8px",
                          }}
                        >
                          {bankError}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item mt={2}>
                      <Typography
                        sx={{
                          color: "#44464C",
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Select Cheque Date
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={1}>
                      <FormControl
                        fullWidth
                        sx={{ marginTop: "9px", marginBottom: "2px" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={chequeDate ? dayjs(chequeDate) : null}
                            onChange={(date) => {
                              const selectedDate = dayjs(date).startOf('day').toDate();
                              setChequeDate(selectedDate);
                              if (date) {
                                setChequeDateError(null); // Clear the error once a date is selected
                              }
                            }}
                            minDate={dayjs().startOf('day')}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      {chequeDateError && (
                        <Typography
                          sx={{
                            color: "red",
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            fontWeight: 500,
                            marginTop: "8px",
                          }}
                        >
                          {chequeDateError}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item mt={2}>
                      <Typography
                        sx={{
                          color: "#44464C",
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Enter Cheque Number
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={1}>
                      <InputBase
                        sx={{
                          border: "1px solid #DCDCDE",
                          width: "100%",
                          height: "38px",
                          paddingLeft: "10px",
                          color: "#505259",
                          fontSize: "10px",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                          borderRadius: "4px",
                        }}
                        // inputProps={{
                        //   maxLength: 10,
                        // }}
                        onInput={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.value = target.value.replace(/[^0-9]/g, "");
                        }}
                        value={chequeNumber}
                        onChange={(e) => {
                          const number = e.target.value.replace(/[^0-9]/g, "");
                          setChequeNumber(number);

                          if (number?.length >= 5 && number?.length <= 17) {
                            setChequeNumberError(null);
                          } else {
                            setChequeNumberError(
                              "Cheque number must be between 5 and 17 digits."
                            );
                          }
                        }}
                      />
                      {chequeNumberError && (
                        <Typography
                          sx={{
                            color: "red",
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            fontWeight: 500,
                            marginTop: "8px",
                          }}
                        >
                          {chequeNumberError}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item mt={2}>
                      <Typography
                        sx={{
                          color: "#44464C",
                          fontFamily: "Montserrat",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Upload cheque Photo (Optional)
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={1}>
                      <FormControl
                        fullWidth
                        sx={{ marginTop: "9px", marginBottom: "2px" }}
                      >
                        <Dragger
                          name="file"
                          multiple
                          onChange={handleImageUpload}
                          beforeUpload={(file) => {
                            const isAllowedType =
                              file.type === "image/svg+xml" ||
                              file.type === "image/png" ||
                              file.type === "image/jpeg" ||
                              file.type === "image/gif";

                            if (!isAllowedType) {
                              setUploadError(
                                "You can only upload SVG, PNG, JPG, or GIF files."
                              );
                              return Upload.LIST_IGNORE;
                            }

                            setUploadError(null);
                            return false;
                          }}
                          customRequest={() => { }} // Custom request to handle image upload
                        >
                          <p className="ant-upload-drag-icon">
                            <img src={Warn} alt="search icon" />
                          </p>
                          <p
                            className="ant-upload-text"
                            style={{
                              color: "#44464C",
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            <span className="uploadGraditent">
                              Click to upload
                            </span>
                            or drag and drop
                          </p>
                          <p
                            className="ant-upload-hint"
                            style={{
                              color: "#44464C",
                              fontFamily: "Montserrat",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            SVG, PNG, JPG or GIF (max. 800x400px)
                          </p>
                        </Dragger>
                      </FormControl>
                      {uploadError && (
                        <Typography
                          sx={{
                            color: "red",
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            fontWeight: 500,
                            marginTop: "8px",
                          }}
                        >
                          {uploadError}
                        </Typography>
                      )}
                      {maxImageError && (
                        <Typography
                          sx={{
                            color: "red",
                            fontFamily: "Montserrat",
                            fontSize: "12px",
                            fontWeight: 500,
                            marginTop: "8px",
                          }}
                        >
                          {maxImageError}
                        </Typography>
                      )}
                    </Grid>
                  </>
                )}
              </Card>
            </Grid>
            <Grid
              container
              sx={{
                position: "sticky",
                bottom: 85,
                height: "60px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
                background: "white",
              }}
            >
              <Grid item sm={6}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "13px",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                  }}
                >
                  Pending Amount: ₹{" "}
                  {totalPendingAmount ? totalPendingAmount?.toFixed(2) : "0.00"}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Button className="Cancle_btn_des" onClick={onClose}>
                  Cancel
                </Button>
                <LoadingButton
                  onClick={handleSaveCollection}
                  loading={buttonLoading}
                  className={`Save_btn_des ${isDisabled ? "disabled_btn_collection" : ""}`}
                  disabled={isDisabled} // Disable button when isDisabled is true
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </Box>
  );
}
