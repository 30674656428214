import React from "react";
import {
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Grid,
  InputLabel,
} from "@mui/material";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import SalesLevelValueForm from "./SalesLevelValueForm";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  LOBName: string;
  ReportingLevel: string;
  ReportingTo: string;
}

interface ViewSalesLevelValueProps {
  onBack: (event: React.MouseEvent<unknown>) => void;
}

const LobMapping: React.FC<ViewSalesLevelValueProps> = ({ onBack }) => {
  const handleBackClick = (event: React.MouseEvent<unknown>) => {
    onBack(event); // Pass the event argument to onBack function
  };
  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    //search function logic here
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "LOBName",
      title: "LOB Name",
      sorter: (a, b) => a.LOBName.length - b.LOBName.length,
    },
    {
      dataIndex: "ReportingLevel",
      title: "Reporting Level",
      sorter: (a, b) => a.ReportingLevel.length - b.ReportingLevel.length,
    },
    {
      dataIndex: "ReportingTo",
      title: "Reporting To",
      sorter: (a, b) => a.ReportingTo.length - b.ReportingTo.length,
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      LOBName: "PR SO HUBLI",
      ReportingTo: "Territory Sales Officer",
      ReportingLevel: "PR AM BANGALORE",
    },
    {
      key: 2,
      LOBName: "AD AM CHENNAI-ADSTNAM001",
      ReportingTo: "Regional Head",
      ReportingLevel: "PR AM BANGALORE",
    },
    {
      key: 3,
      LOBName: "PR SO HUBLI",
      ReportingTo: "	Regional Head",
      ReportingLevel: "PR AM BANGALORE",
    },
    {
      key: 4,
      LOBName: "PR SO HUBLI",
      ReportingTo: "Territory Sales Officer",
      ReportingLevel: "PR AM BANGALORE",
    },
    {
      key: 5,
      LOBName: "AD AM CHENNAI-ADSTNAM001",
      ReportingTo: "Area Sales Manager",
      ReportingLevel: "PR AM BANGALORE",
    },
    {
      key: 6,
      LOBName: "PR SO HUBLI",
      ReportingTo: "Zonal Sales Manager",
      ReportingLevel: "PR AM BANGALORE",
    },
    {
      key: 7,
      LOBName: "PR SO HUBLI",
      ReportingTo: "Zonal Sales Manager",
      ReportingLevel: "PR AM BANGALORE",
    },
    {
      key: 8,
      LOBName: "AD AM CHENNAI-ADSTNAM001",
      ReportingTo: "Territory Sales Officer",
      ReportingLevel: "PR AM BANGALORE",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Box className="card-form">
        <form noValidate>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }}>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">Level Name </InputLabel>
              <TextField placeholder="Level Name" className="Txt-box" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">Code</InputLabel>
              <TextField placeholder="Code" className="Txt-box" />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InputLabel size="normal" className="fnt-lable">Value(Name)</InputLabel>
              <TextField placeholder="Name" className="Txt-box" />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Box>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "end", mb: 2 }}
                >
                  <SearchBar
                    className="search-table"
                    value={textFieldValue}
                    onChange={(newValue) => {
                      setTextFieldValue(newValue);
                      handleSearch();
                    }}
                  />
                </Grid>
                <CommonTable
                  // rowSelection={rowSelection}
                  data={data}
                  columns={columns}
                />
              </Box>
            </Grid>
            <Grid item xs={12} mt={2} display="flex" justifyContent="center">
              <Button
                variant="contained"
                className="btn-colr"
                style={{ marginLeft: "10px" }}
                onClick={handleBackClick}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};
export default LobMapping;
