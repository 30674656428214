import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Grid, Paper, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import CommonTable from "../../../components/Tables/CommonTable";
import ViewSchemeControlForm from "./ViewSchemeControl";
import SchemeControlForm from "./SchemeControlForm";

interface DataType {
  key: React.Key;
  code: number;
  refNumber: string;
  createDate: string;
  actionvalue: string;
  action: string;
}

const SchemeMasterControl = () => {
  const [showForm, setShowForm] = React.useState(false);
  const [isViewing, setIsViewing] = React.useState(false);

  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // search function logic here
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "code",
      title: "Scheme Code",
    },
    {
      dataIndex: "refNumber",
      title: "Reference Number",
      sorter: (a, b) => a.refNumber.length - b.refNumber.length,
    },
    {
      dataIndex: "createDate",
      title: "Created Date",
      sorter: (a, b) => a.createDate.length - b.createDate.length,
    },
    {
      dataIndex: "actionvalue",
      title: "Action ",
      sorter: (a, b) => a.actionvalue.length - b.actionvalue.length,
    },
    {
      dataIndex: "action",
      title: "Actions",
      render: (_: any, record: DataType) => (
        <>
          <VisibilityIcon
            style={{ cursor: "pointer", fontSize: "1rem", color: "#928282de" }}
            onClick={(event) => handleViewClick(event)}
          />
        </>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      code: 55,
      refNumber: "33veena",
      createDate: "29/2/2024",
      actionvalue: "Comapny1",
      action: "",
    },
    {
      key: "2",
      code: 55445,
      refNumber: "33alpha",
      createDate: "29/2/2024",
      actionvalue: "Comapny2",
      action: "",
    },
    {
      key: "3",
      code: 5565,
      refNumber: "33hellbey",
      createDate: "29/2/2024",
      actionvalue: "Comapny3",
      action: "",
    },
    {
      key: "4",
      code: 5548,
      refNumber: "33Supplier4",
      createDate: "29/2/2024",
      actionvalue: "Comapny4",
      action: "",
    },
    {
      key: "5",
      code: 55121,
      refNumber: "33Supplier5",
      createDate: "29/2/2024",
      actionvalue: "Comapny5",
      action: "",
    },
    {
      key: "6",
      code: 5565,
      refNumber: "33Supplier6",
      createDate: "29/2/2024",
      actionvalue: "Comapny6",
      action: "",
    },
    {
      key: "7",
      code: 55789,
      refNumber: "33Supplier7",
      createDate: "29/2/2024",
      actionvalue: "Comapny7",
      action: "",
    },
    {
      key: "8",
      code: 55656,
      refNumber: "33Supplier8",
      createDate: "29/2/2024",
      actionvalue: "Comapny8",
      action: "",
    },
    {
      key: "9",
      code: 55,
      refNumber: "33Supplier1",
      createDate: "29/2/2024",
      actionvalue: "Comapny1",
      action: "",
    },
    {
      key: "10",
      code: 55445,
      refNumber: "33Supplier2",
      createDate: "29/2/2024",
      actionvalue: "Comapny2",
      action: "",
    },
    {
      key: "11",
      code: 5565,
      refNumber: "33Supplier3",
      createDate: "29/2/2024",
      actionvalue: "Comapny3",
      action: "",
    },
    {
      key: "12",
      code: 5548,
      refNumber: "33Supplier4",
      createDate: "29/2/2024",
      actionvalue: "Comapny4",
      action: "",
    },
    {
      key: "13",
      code: 55121,
      refNumber: "33Supplier5",
      createDate: "29/2/2024",
      actionvalue: "Comapny5",
      action: "",
    },
    {
      key: "14",
      code: 5565,
      refNumber: "33Supplier6",
      createDate: "29/2/2024",
      actionvalue: "Comapny6",
      action: "",
    },
    {
      key: "15",
      code: 55789,
      refNumber: "33Supplier7",
      createDate: "29/2/2024",
      actionvalue: "Comapny7",
      action: "",
    },
    {
      key: "16",
      code: 55656,
      refNumber: "33Supplier8",
      createDate: "29/2/2024",
      actionvalue: "Comapny8",
      action: "",
    },
  ];

  const handleCreateNew = () => {
    setShowForm(!showForm);
  };

  const handleViewClick = (event: React.MouseEvent<unknown>) => {
    setIsViewing(!isViewing);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className="mar-tb">
          <Typography>
            <b>Scheme Master Control</b>
          </Typography>
        </Grid>

        {showForm ? (
          <SchemeControlForm onBack={handleCreateNew} />
        ) : isViewing ? (
          <ViewSchemeControlForm onBack={(event) => handleViewClick(event)} />
        ) : (
          <Box>
            <Button
              variant="contained"
              className="btn-colr"
              onClick={handleCreateNew}
            >
              {" "}
              <AddIcon /> Create new
            </Button>
            <Grid item sx={{ mt: 2 }}>
              <Box>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "end", mb: 2 }}
                >
                  <SearchBar
                    className="search-table"
                    value={textFieldValue}
                    onChange={(newValue) => {
                      setTextFieldValue(newValue);
                      handleSearch();
                    }}
                  />
                </Grid>
                <CommonTable
                  // rowSelection={rowSelection}
                  data={data}
                  columns={columns}
                />
              </Box>
            </Grid>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SchemeMasterControl;
