import { useState, startTransition } from "react";
// material-ui
import {
  CardHeader,
  Card,
  CardContent,
  CardActions,
  Avatar,
  AvatarGroup,
  TextField,
  InputLabel,
  Paper,
  Box,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const SalesmanTarget = () => {
  const navigator = useNavigate();
  return (
    <Grid item sx={{ p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
      <Box sx={{ marginTop: "2px" }}>
        <Paper elevation={0} className="pad-10 mar-tb">
          <Grid item xs={12}>
            <Typography className="cursor_pointer">
              <span onClick={() => navigator("/MainDistribution")}>
                Distribution
              </span>{" "}
              &nbsp;/
              <span>
                <b>&nbsp;SalesmanTarget</b>
              </span>
            </Typography>
          </Grid>
        </Paper>
      </Box>
      <Paper elevation={0} className="pad-10 mar-tb">
        <Grid container spacing={2}>
          <Grid item xs={6} md={4}>
            <InputLabel size="normal">Distributor *</InputLabel>
            <TextField placeholder="Distributor" fullWidth />
          </Grid>

          <Grid item xs={6} md={1} mt={3}>
            <Button className="btn-apply-color" variant="contained">
              ...
            </Button>
          </Grid>
          <Grid item xs={6} md={4}>
            <InputLabel size="normal">Distributor Branch*</InputLabel>
            <TextField placeholder="DistributorBranch" fullWidth />
          </Grid>

          <Grid item xs={6} md={1} mt={3}>
            <Button className="btn-apply-color" variant="contained">
              ...
            </Button>
          </Grid>
          <Grid item xs={6} md={4}>
            <InputLabel size="normal">SalesMan*</InputLabel>
            <TextField placeholder="SalesMan" fullWidth />
          </Grid>
          <Grid item xs={6} md={1} mt={3}>
            <Button className="btn-apply-color" variant="contained">
              ...
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{ marginTop: "2px" }}>
        <Paper elevation={0} className="pad-10 mar-tb">
          <Button variant="contained" className="btn-apply">
            Download Format
          </Button>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <TextField fullWidth />
            </Grid>
            <Grid item xs={6} md={1}>
              <Button className="btn-apply-color" variant="contained">
                Browse
              </Button>
            </Grid>
            <Grid item xs={6} md={1}>
              <Button className="btn-apply-color" variant="contained">
                Import
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Grid>
  );
};
export default SalesmanTarget;
