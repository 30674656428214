import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  Drawer,
  Grid,
  InputBase,
  Typography,
  Snackbar,
  Alert,
  FormControl,
} from "@mui/material";

import { Select } from "antd";
import Close from "../../../../../assets/images/icons/Close_icon.png";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useFetch";
import {
  getPendingPaymentList,
  setCollectionData,
  updateChequeDetails,
  getBankList,
} from "../../../../../Redux/XDM/Sales/collectionReducer";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Cheque_edit_img_bank from "../../../../../assets/images/icons/cheque_edit_bank.png";
import Accept_img from "../../../../../assets/images/icons/cheque_accept.png";
import Reject_img from "../../../../../assets/images/icons/cheque_reject.png";
import dayjs, { Dayjs } from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import InvoiceDetails from "../../InvoiceReviewOrder";

interface RejectedProps {
  onClose: () => void;
}

interface DatePickerProps {
  value: Dayjs | null;
  lable: string;
}

export default function PendingChequeEdit(props: RejectedProps) {
  const { onClose } = props; // Destructure the props
  const dispatch = useAppDispatch();

  const abortControllerRef = useRef<AbortController | null>(null);

  const [error, setError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [chequeNumber, setChequeNumber] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [chequeDate, setChequeDate] = useState<Dayjs | null>(null);
  const [inputchequeNumber, setInputChequeNumber] = useState("");
  const [inputselectedBank, setInputSelectedBank] = useState("");
  const [inputchequeDate, setInputChequeDate] = useState<Dayjs | null>(null);
  const [AcceptedchequeNumber, setAcceptedChequeNumber] = useState("");
  const [AcceptedselectedBank, setAcceptedSelectedBank] = useState("");
  const [AcceptedchequeDate, setAcceptedChequeDate] = useState<Dayjs | null>(
    null
  );
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const [chequeNumberError, setChequeNumberError] = useState<string | null>(
    null
  );
  const [isShowDetails, setIsShowDetails] = React.useState(false);
  const [isDisabled, setIsDisabled] = useState(false); // New state for disabling the button
  const [iconVisibility, setIconVisibility] = useState({
    icon1: false,
    icon2: false,
    icon3: false,
  });

  const [formValidationError, setFormValidationError] = useState({
    chequeNo: "",
    bankName: "",
    chequeDate: "",
  });

  const [invoiceRequestData, setInvoiceRequestData] = React.useState({
    invoiceNumber: "",
    orderId: "",
    date: "",
    outlet: "",
    route: "",
    salesman: "",
    netAmount: 0,
    salesmanMobile: "",
  });

  // Function to toggle visibility of a specific icon
  const toggleIconVisibility = (icon: "icon1" | "icon2" | "icon3") => {
    setIconVisibility((prevState) => ({
      ...prevState,
      [icon]: true,
    }));
  };

  const toggleIconVisibilityOff = (icon: "icon1" | "icon2" | "icon3") => {
    setIconVisibility((prevState) => ({
      ...prevState,
      [icon]: false,
    }));
  };

  const updateValuesBasedOnIconVisibility = (
    icon: "icon1" | "icon2" | "icon3"
  ) => {
    if (icon === "icon1") {
      setChequeNumber(inputchequeNumber);
      setAcceptedChequeNumber(inputchequeNumber);
      if (inputchequeNumber?.length >= 5 && inputchequeNumber?.length <= 17) {
        setChequeNumberError(null);
      } else {
        setChequeNumberError("Cheque number must be between 5 and 17 digits.");
      }
    }

    if (icon === "icon2") {
      setAcceptedSelectedBank(inputselectedBank);
      setSelectedBank(inputselectedBank);
    }

    if (icon === "icon3") {
      setAcceptedChequeDate(inputchequeDate);
      setChequeDate(inputchequeDate);
    }
    toggleIconVisibilityOff(icon);
  };

  let selectedData = useAppSelector(
    (state) => state.collectionReducerData.selectedCollectionData
  );
  const OutletInvoiceDetailsData = useAppSelector(
    (state) => state.collectionReducerData
  );
  const BankDetailsData = OutletInvoiceDetailsData.bankListData;

  const filterData = {
    paymentType: "Cheque",
    page: 0,
    size: 10,
    dateType: selectedData?.dateType,
    fromDate: selectedData?.fromDate,
    toDate: selectedData?.toDate,
    search: selectedData?.search,
    salesmanId: selectedData?.salesManId,
    outletId: selectedData?.outletId,
  };

  const { Option } = Select;

  useEffect(() => {
    dispatch(getBankList());
    if (selectedData) {
      setChequeNumber(selectedData.chequeNumber);
      setInputChequeNumber(selectedData.chequeNumber);
      setAcceptedChequeNumber(selectedData.chequeNumber);
      setSelectedBank(selectedData.bankName);
      setInputSelectedBank(selectedData.bankName);
      setAcceptedSelectedBank(selectedData.bankName);
      const dateString = selectedData.chequeDate;
      let date = new Date(dateString);
      setChequeDate(dayjs(date));
      setInputChequeDate(dayjs(date));
      setAcceptedChequeDate(dayjs(date));
    }
  }, [selectedData]);

  const handleSubmit = async () => {
    // Validate form fields
    if (!AcceptedchequeNumber.trim()) {
      setFormValidationError((prevState) => ({
        ...prevState,
        chequeNo: "Cheque number is required.",
      }));
    } else if (chequeNumberError !== null) {
      setFormValidationError((prevState) => ({
        ...prevState,
        chequeNo: "Please enter a valid cheque number.",
      }));
    } else if (!AcceptedselectedBank.trim()) {
      setFormValidationError((prevState) => ({
        ...prevState,
        bankName: "Bank name is required.",
      }));
    } else if (!AcceptedchequeDate) {
      setFormValidationError((prevState) => ({
        ...prevState,
        chequeDate: "Cheque date is required.",
      }));
    } else {
      setIsLoading(true);
      setError(null);
      setChequeNumberError(null);
      setFormValidationError({
        chequeNo: "",
        bankName: "",
        chequeDate: "",
      });

      if (abortControllerRef.current) {
        abortControllerRef.current.abort(); // Abort the previous request
      }

      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      const requestPayload = {
        chequeNumber: AcceptedchequeNumber,
        collectionId: selectedData?.collectionId,
        bankName: AcceptedselectedBank,
        chequeDate: AcceptedchequeDate.format("YYYY-MM-DD"),
      };
      try {
        const response = await dispatch(
          updateChequeDetails({
            params: requestPayload,
            signal: abortController.signal,
          })
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          setSnackbarMessage("Cheque Details Updated Successfully");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          dispatch(setCollectionData(true));
          setIsLoading(false);
          setIsDisabled(true); // Disable the button when fulfilled
          setTimeout(() => {
            dispatch(
              getPendingPaymentList({
                params: { ...filterData },
                signal: abortController.signal,
              })
            );
            onClose();
          }, 2000);
        }
      } catch (error) {
        console.error("Submission error", error);
        setIsLoading(false);
      }
    }
  };
  const handleInvoiceClick = (invoiceNo: string) => {
    setInvoiceRequestData({
      invoiceNumber: invoiceNo,
      orderId: "",
      date: "",
      outlet: "",
      route: "",
      salesman: "",
      netAmount: 0,
      salesmanMobile: "",
    });
    setIsShowDetails(!isShowDetails);
  };

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={100000}
        color="green"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Drawer
        anchor="right"
        open={true}
        className="pen_drawer_stl"
      // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 500,
              md: 460,
              sm: 400,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
            backgroundColor: "#F6F6F6",
          }}
        >
          <Grid item sx={{ height: "100%" }}>
            <Grid
              container
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                background: "white",
                height: "60px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
              }}
            >
              <Grid item sm={7}>
                <Typography>
                  <b>Edit Cheque</b>
                </Typography>
              </Grid>
              <Grid item sm={5} sx={{ display: "flex", justifyContent: "end" }}>
                <img
                  onClick={onClose}
                  src={Close}
                  alt="Close"
                  style={{ width: "20px", height: "20px" }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              sx={{ overflowY: "auto", height: "70%", padding: "20px" }}
              className="discrepancy_card"
            >
              <Card
                sx={{
                  border: "1px solid #DCDCDE",
                  padding: "15px",
                  borderRadius: "6px",
                  marginBottom: "10px",
                }}
              >
                <Grid container sx={{ alignItems: "center" }}>
                  <Grid item>
                    <Grid item>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: "11px",
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                        }}
                      >
                        Collection ID : {selectedData?.collectionId}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{
                          color: "#f28500",
                          fontSize: "10px",
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                        }}
                      >
                        {"Pending"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container mt={2} sx={{ gap: "10px" }}>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Invoice No:
                      {selectedData?.invoiceNumbers[0]?.split(",").map((value: string, index: number) => (
                        <React.Fragment key={index}>
                          <span
                            style={{
                              textDecoration: "underline",
                              fontWeight: 600,
                              color: "#27538D",
                              cursor: "pointer", // Make it look clickable
                            }}
                            onClick={() => handleInvoiceClick(selectedData.invoiceNumbers[0].split(",")[0])} // Always send the first invoice
                          >
                            {value}
                          </span>
                          {index < selectedData?.invoiceNumbers[0]?.split(",").length - 1 && (
                            <span style={{ color: "#27538D" }}>, </span> // Add comma between invoices
                          )}
                        </React.Fragment>
                      ))}
                    </Typography>


                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Date : {selectedData?.collectionDate}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Outlet:{" "}
                      {selectedData?.outletName ? selectedData.outletName : "-"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Salesman Name : {selectedData?.salesmanName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Pending Bills : {selectedData?.invoiceNumbers?.length}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Outstanding Amount : ₹{" "}
                      {selectedData?.pendingAmount?.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "10px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Amount : ₹ {selectedData?.collectedAmt?.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography
                    sx={{
                      color: "#44464C",
                      fontFamily: "Montserrat",
                      fontSize: "10px",
                      fontWeight: 500,
                      marginRight: "4px", // add some space between the text and the *
                    }}
                  >
                    Cheque No
                  </Typography>
                </Grid>

                <Grid item>
                  <Grid container>
                    {/* before edit */}
                    {!iconVisibility.icon1 && (
                      <>
                        <Grid item xs={8} mt={1}>
                          {!iconVisibility.icon1
                            ? chequeNumber
                            : AcceptedchequeNumber}
                        </Grid>
                        <Grid item xs={4} mt={1}>
                          <Grid
                            container
                            sx={{
                              justifyContent: "end",
                              //   pointerEvents: isSelected ? "none" : "auto",
                              //   opacity: isSelected ? 0.5 : 1,
                            }}
                          >
                            <Grid
                              item
                              onClick={() => toggleIconVisibility("icon1")}
                            >
                              <img
                                src={Cheque_edit_img_bank}
                                alt="Edit"
                                style={{ width: "20px", height: "20px" }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {/* after edit */}
                    {iconVisibility.icon1 && (
                      <>
                        <Grid item xs={10} mt={1}>
                          <InputBase
                            sx={{
                              border: "1px solid #DCDCDE",
                              width: "100%",
                              height: "38px",
                              paddingLeft: "10px",
                              color: "#505259",
                              fontSize: "10px",
                              fontFamily: "Montserrat",
                              fontWeight: 500,
                              borderRadius: "4px",
                            }}
                            value={inputchequeNumber}
                            // inputProps={{
                            //   maxLength: 10,
                            // }}
                            onInput={(e) => {
                              const target = e.target as HTMLInputElement;
                              target.value = target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                            onChange={(e) => {
                              const number = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                              setInputChequeNumber(number);

                              if (number?.length >= 5 && number?.length <= 17) {
                                setChequeNumberError(null);
                              } else {
                                setChequeNumberError(
                                  "Cheque number must be between 5 and 17 digits."
                                );
                              }
                            }}
                          />
                          {chequeNumberError && (
                            <Typography
                              sx={{
                                color: "red",
                                fontFamily: "Montserrat",
                                fontSize: "12px",
                                fontWeight: 500,
                                marginTop: "8px",
                              }}
                            >
                              {chequeNumberError}
                            </Typography>
                          )}
                        </Grid>

                        <Grid
                          item
                          xs={2}
                          mt={1}
                          className="text-center justify-content-center"
                        >
                          <Grid
                            container
                            sx={{
                              justifyContent: "center",
                              // pointerEvents: isSelected ? "none" : "auto",
                              // opacity: isSelected ? 0.5 : 1,
                            }}
                            className="text-center"
                          >
                            <Grid
                              item
                              ml={1}
                              onClick={() => toggleIconVisibilityOff("icon1")}
                            >
                              <img
                                src={Reject_img}
                                alt="Reject"
                                style={{ width: "20px", height: "20px" }}
                              />
                            </Grid>
                            <Grid
                              item
                              ml={1}
                              onClick={() =>
                                updateValuesBasedOnIconVisibility("icon1")
                              }
                            >
                              <img
                                src={Accept_img}
                                alt="Accept"
                                style={{ width: "20px", height: "20px" }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid item>
                      {formValidationError.chequeNo && (
                        <Typography
                          sx={{
                            color: "red",
                            fontSize: "10px",
                            marginTop: "5px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {formValidationError.chequeNo}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography
                    sx={{
                      color: "#44464C",
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Bank Name
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container>
                    {/* before edit */}
                    {!iconVisibility.icon2 && (
                      <>
                        <Grid item xs={8} mt={1}>
                          {!iconVisibility.icon2
                            ? selectedBank
                            : AcceptedselectedBank}
                        </Grid>
                        <Grid item xs={4} mt={1}>
                          <Grid
                            container
                            sx={{
                              justifyContent: "end",
                              //   pointerEvents: isSelected ? "none" : "auto",
                              //   opacity: isSelected ? 0.5 : 1,
                            }}
                          >
                            <Grid
                              item
                              onClick={() => toggleIconVisibility("icon2")}
                            >
                              <img
                                src={Cheque_edit_img_bank}
                                alt="Edit"
                                style={{ width: "20px", height: "20px" }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {/* after edit */}
                    {iconVisibility.icon2 && (
                      <>
                        <Grid item xs={10} mt={1}>
                          <FormControl
                            fullWidth
                            sx={{ marginTop: "9px", marginBottom: "2px" }}
                          >
                            <Select
                              className="Quentity_select_box"
                              showSearch
                              placeholder="Select Bank Name"
                              filterOption={(input, option) => {
                                const optionText =
                                  option?.children?.toString().toLowerCase() ||
                                  "";
                                return optionText.includes(input.toLowerCase());
                              }}
                              dropdownStyle={{ zIndex: 2000 }}
                              style={{ width: "100%", fontSize: "12px" }} // Adjust the width as necessary
                              value={inputselectedBank}
                              onChange={(value) => setInputSelectedBank(value)}
                            >
                              {Array.isArray(BankDetailsData) &&
                                BankDetailsData?.length > 0 &&
                                BankDetailsData?.map((bank) => (
                                  <Option key={bank?.id} value={bank.bankName}>
                                    {bank.bankName}
                                  </Option>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          mt={1}
                          className="text-center justify-content-center"
                        >
                          <Grid
                            container
                            sx={{
                              justifyContent: "center",
                              //   pointerEvents: isSelected ? "none" : "auto",
                              //   opacity: isSelected ? 0.5 : 1,
                            }}
                            className="text-center"
                          >
                            <Grid
                              item
                              ml={1}
                              onClick={() => toggleIconVisibilityOff("icon2")}
                            >
                              <img
                                src={Reject_img}
                                alt="Reject"
                                style={{ width: "20px", height: "20px" }}
                              />
                            </Grid>
                            <Grid
                              item
                              ml={1}
                              onClick={() =>
                                updateValuesBasedOnIconVisibility("icon2")
                              }
                            >
                              <img
                                src={Accept_img}
                                alt="Accept"
                                style={{ width: "20px", height: "20px" }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid item>
                      {formValidationError.bankName && (
                        <Typography
                          sx={{
                            color: "red",
                            fontSize: "10px",
                            marginTop: "5px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {formValidationError.bankName}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item mt={2}>
                  <Typography
                    sx={{
                      color: "#44464C",
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Cheque Date
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container>
                    {/* before edit */}
                    {!iconVisibility.icon3 && (
                      <>
                        <Grid item xs={8} mt={1}>
                          {!iconVisibility.icon3
                            ? chequeDate?.format("MM/DD/YYYY")
                            : AcceptedchequeDate?.format("MM/DD/YYYY")}
                        </Grid>
                        <Grid item xs={4} mt={1}>
                          <Grid
                            container
                            sx={{
                              justifyContent: "end",
                              //   pointerEvents: isSelected ? "none" : "auto",
                              //   opacity: isSelected ? 0.5 : 1,
                            }}
                          >
                            <Grid
                              item
                              onClick={() => toggleIconVisibility("icon3")}
                            >
                              <img
                                src={Cheque_edit_img_bank}
                                alt="Edit"
                                style={{ width: "20px", height: "20px" }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {/* after edit */}
                    {iconVisibility.icon3 && (
                      <>
                        <Grid item xs={10} mt={1}>
                          <FormControl
                            fullWidth
                            sx={{ marginTop: "9px", marginBottom: "2px" }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="Cheque Date"
                                value={inputchequeDate}
                                onChange={(newValue: Dayjs | null) =>
                                  setInputChequeDate(newValue)
                                }
                              />
                            </LocalizationProvider>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          mt={1}
                          className="text-center justify-content-center"
                        >
                          <Grid
                            container
                            sx={{
                              justifyContent: "center",
                              //   pointerEvents: isSelected ? "none" : "auto",
                              //   opacity: isSelected ? 0.5 : 1,
                            }}
                            className="text-center"
                          >
                            <Grid
                              item
                              ml={1}
                              onClick={() => toggleIconVisibilityOff("icon3")}
                            >
                              <img
                                src={Reject_img}
                                alt="Reject"
                                style={{ width: "20px", height: "20px" }}
                              />
                            </Grid>
                            <Grid
                              item
                              ml={1}
                              onClick={() =>
                                updateValuesBasedOnIconVisibility("icon3")
                              }
                            >
                              <img
                                src={Accept_img}
                                alt="Accept"
                                style={{ width: "20px", height: "20px" }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid item>
                      {formValidationError.chequeDate && (
                        <Typography
                          sx={{
                            color: "red",
                            fontSize: "10px",
                            marginTop: "5px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {formValidationError.chequeDate}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <div className="image_Container">
                    {selectedData?.images?.map(
                      (thumbnail: any, index: number) => (
                        <div className="Image_thumbnail" key={index}>
                          <img
                            src={thumbnail}
                            alt={`Thumbnail ${index + 1}`}
                            className="image_thumbnail_img"
                          />
                        </div>
                      )
                    )}
                  </div>
                </Grid>
              </Card>
            </Grid>
            <Grid
              container
              sx={{
                position: "sticky",
                bottom: 85,
                height: "60px",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "15px",
                background: "white",
              }}
            >
              <Grid item sm={6}>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "13px",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                  }}
                >
                  Rejected Value : ₹ {selectedData?.collectedAmt}
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <Button className="Cancle_btn_des" onClick={onClose}>
                  Cancel
                </Button>
                <LoadingButton
                  className={`Save_btn_des ${isDisabled ? "disabled_btn_collection" : ""}`}
                  onClick={handleSubmit}
                  loading={isLoading}
                  loadingPosition="start"
                  disabled={isDisabled} // Disable button when isDisabled is true
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {isShowDetails && (
          <InvoiceDetails
            onClose={() => setIsShowDetails(false)}
            requestData={invoiceRequestData}
          />
        )}
      </Drawer>
    </Box>
  );
}
