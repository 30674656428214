// material-ui
import { styled, Theme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import { CSSObject } from "@mui/system";

// project import
import { drawerWidth } from "../../../config";
import { Padding } from "@mui/icons-material";

// Types
interface MiniDrawerStyledProps {
  theme: Theme;
  open: boolean;
}
// const drawerWidth=250
// Mixins
// const openedMixin = (theme: Theme): CSSObject => ({
//   width: drawerWidth,
//   top: "93px",
//   backgroundColor: "#eee",
//   borderRight: `1px solid ${theme.palette.divider}`,
//   transition: theme.transitions.create("width", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.enteringScreen,
//   }),
//   overflowX: "hidden",
//   boxShadow: "none",
//   overflowY: "auto",

// });

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  top: "93px",
  backgroundColor: "#eee",
  borderRight: `1px solid ${theme.palette.divider}`,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  boxShadow: "none",
  overflowY: "auto",
  // scrollbarWidth: "thin",
  // "&::-webkit-scrollbar": {
  //   width: "12px", // Adjust scrollbar width here for WebKit browsers
  //   backgroundColor: "#eee", // Background color for WebKit browsers
  // },
  // "&::-webkit-scrollbar-thumb": {
  //   backgroundColor: "pink", // Adjust thumb color here for WebKit browsers
  // },
  // "&::-webkit-scrollbar-track": {
  //   backgroundColor: "#eee", // Adjust track color here for WebKit browsers
  // },
  // "& scrollbar-color": {
  //   scrollbarColor: "pink #eee", // Adjust thumb and track color here for Firefox
  // },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  top: "95px",
  borderRight: "none",
  // boxShadow: theme.customShadows.z1
});

// Component
const MiniDrawerStyled = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})<MiniDrawerStyledProps>(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default MiniDrawerStyled;
