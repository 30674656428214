import * as React from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  Box,
  FormControl,
} from "@mui/material";
import { InputLabel, Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import type { TableColumnsType } from "antd";
import SearchBar from "@mkyy/mui-search-bar";
import SearchIcon from "@mui/icons-material/Search";
import CommonTable from "../../../components/Tables/CommonTable";

interface DataType {
  key: React.Key;
  screen: string;
  prefix: string;
  numbers: string;
}

const KeyGenerator: React.FC = () => {
  const navigator = useNavigate();

  const [textFieldValue, setTextFieldValue] = React.useState("");

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

  const handleSearch = () => {
    // search function logic
  };

  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: "screen",
      title: "Screen",
      sorter: (a, b) => a.screen.length - b.screen.length,
    },
    {
      dataIndex: "prefix",
      title: "Prefix",
      render: (_: any, record: DataType) => (
        <TextField
          size="small"
          variant="outlined"
          defaultValue={record.prefix}
          onChange={(e) => handlePrefixChange(record.key, e.target.value)}
        />
      ),
    },
    {
      dataIndex: "numbers",
      title: "Running Numbers",

      render: (_: any, record: DataType) => (
        <TextField
          size="small"
          variant="outlined"
          defaultValue={record.numbers}
          onChange={(e) => handleNumbersChange(record.key, e.target.value)}
        />
      ),
    },
  ];

  const data: DataType[] = [
    { key: 1, screen: "Supplier1", prefix: " ", numbers: " " },
    { key: 2, screen: "Supplier2", prefix: " ", numbers: " " },
    { key: 3, screen: "Supplier3", prefix: " ", numbers: " " },
    { key: 4, screen: "Supplier4", prefix: " ", numbers: " " },
    { key: 5, screen: "Supplier5", prefix: " ", numbers: " " },
    { key: 6, screen: "Supplier6", prefix: " ", numbers: " " },
    { key: 7, screen: "Supplier7", prefix: " ", numbers: " " },
    { key: 8, screen: "Supplier8", prefix: " ", numbers: " " },
  ];

  const handlePrefixChange = (key: React.Key, value: string) => {
    // Implement your logic to handle changes in the Prefix field
  };

  const handleNumbersChange = (key: React.Key, value: string) => {
    // Implement your logic to handle changes in the Running Numbers field
  };
  const Options = ["tcs group", "geo level", "geo level value"];
  const SearchInputOptions = ["tcs group", "geo level", "geo level value"];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
      <Grid item xs={12} className=" mar-tb">
        <Typography>
          <b className="header-lable">Key Generator </b>
        </Typography>
      </Grid>

      <form>
        <Grid container rowGap={3} columnGap={{ xs: 3, sm: 3 }}>
          <Grid item xs={12} sm={4} md={3}>
            <InputLabel size="normal" className="fnt-lable">
              Search By
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <FormControl fullWidth>
              <Select className="selct-box">
                {Options.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container rowGap={3} columnGap={{ xs: 3, sm: 3 }} mt={3}>
          <Grid item xs={12} sm={4} md={3}>
            <InputLabel size="normal" className="fnt-lable">
              Search Input
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <FormControl fullWidth>
              <Select className="selct-box">
                {SearchInputOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Button variant="contained" className="btn-apply btn-hgt">
            <SearchIcon className="fn-icon" /> Go
          </Button>
        </Grid>

        <Box className="mar-tb">
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "end", mb: 2 }}
          >
            <SearchBar
              className="search-table"
              value={textFieldValue}
              onChange={(newValue) => {
                setTextFieldValue(newValue);
                handleSearch();
              }}
            />
          </Grid>
          <CommonTable
            // rowSelection={rowSelection}
            data={data}
            columns={columns}
          />
        </Box>
        <Grid item xs={12} mt={2} display="flex" justifyContent="center">
          <Button variant="outlined" color="primary" className="button-lable">
            Cancel
          </Button>
          <Button
            variant="contained"
            className="btn-colr"
            style={{ marginLeft: "10px" }}
          >
            Update
          </Button>
        </Grid>
      </form>
    </Box>
  );
};
export default KeyGenerator;
