type ListItem = {
  outletName: string;
  outletCode: string;
  // address: string;
  [key: string]: any;
};

export const SalesReturnSearchFilter = (
  searchValue: string,
  list: ListItem[],
  searchBy: string = "outletName"
): ListItem[] => {
  let lowerCaseQuery = searchValue.toLowerCase();
  let filteredList = searchValue
    ? list.filter(
        (item) =>
          item.outletName.toLowerCase().includes(lowerCaseQuery) ||
          item.outletCode.toLowerCase().includes(lowerCaseQuery)
      )
    : list;
  return filteredList;
};
