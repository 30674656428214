import {
  PRODUCT_MASTER_SUBMIT_FORM,
  PRODUCT_MASTER_SUBMIT_ERROR,
  PRODUCT_MASTER_FETCH_DATA,
  // PRODUCT_MASTER_ACTIVE_STATUS ,
  PRODUCT_MASTER_EDIT_DATA,
  PRODUCT_MASTER_UPDATE_DATA,
  PRODUCT_MASTER_UPDATE_ERROR,
} from "../../../actionType";
import { Dispatch } from "redux";
import createAxiosInstance from "../../../../hooks/useFetch";
import { ProductMasterFormValues } from "../../../../typescript/product";
const axiosInstance = createAxiosInstance();

export interface ProductMasterSubmitAction {
  type: typeof PRODUCT_MASTER_SUBMIT_FORM;
  payload: any;
}

export interface ProductMasterSubmitErrorAction {
  type: typeof PRODUCT_MASTER_SUBMIT_ERROR;
  payload: string;
}
export interface ProductMasterGetAction {
  type: typeof PRODUCT_MASTER_FETCH_DATA;
  payload: any;
}
// export interface ProductMasterActiveStatus{
//   type: typeof PRODUCT_MASTER_ACTIVE_STATUS;
//   payload: any;
// }
export interface ProductMasterEdit {
  type: typeof PRODUCT_MASTER_EDIT_DATA;
  payload: any;
}
export interface ProductMasterUpdate {
  type: typeof PRODUCT_MASTER_UPDATE_DATA;
  payload: any;
}
export interface ProductMasterUpdateErrorAction {
  type: typeof PRODUCT_MASTER_UPDATE_ERROR;
  payload: string;
}

export const ProductMasterSubmitForm =
  (formData: ProductMasterFormValues) =>
  async (
    dispatch: Dispatch<
      ProductMasterSubmitAction | ProductMasterSubmitErrorAction
    >
  ) => {
    try {
      const response = await axiosInstance.post(
        `product-master/create`,
        formData
      );
      dispatch({
        type: PRODUCT_MASTER_SUBMIT_FORM,
        payload: response.data, // Assuming response contains data
      });
      return response.data;
    } catch (error: any) {
      dispatch({
        type: PRODUCT_MASTER_SUBMIT_ERROR,
        payload: error.response.data.data,
      });
      throw error;
    }
  };

export const ProductMasterFetchData = (requestData: {
  businessLevel: string;
  categoryLevel: string;
  productLevel: string;
  date: string;
  newProducts: string;
  search: string;
  page: number;
  size: number;
}) => {
  return (dispatch: Dispatch<ProductMasterGetAction>) => {
    axiosInstance
      .get(`product-master/get`, {
        params: requestData, // Pass requestData as query parameters
      })
      .then((res) => {
        dispatch({
          type: PRODUCT_MASTER_FETCH_DATA, // Change this to COMPANY_FETCH_DATA
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};

// export const ProductMasterActiveStatus = ({ id, value }: { id: string; value: boolean }) => {
//   return (dispatch: Dispatch<ProductMasterActiveStatus>) => {
//     axiosInstance.put(`ProductMaster/active-in-active`,
//     { id, value }  // Pass requestData as query parameters
//     )
//       .then((res) => {
//         dispatch({
//           type: PRODUCT_MASTER_ACTIVE_STATUS, // Change this to COMPANY_FETCH_DATA
//           payload: res.data.data,
//         });
//       })
//       .catch((error) => {
//       });
//   };
// };

export const ProductMasterEdit = (id: string) => {
  return (dispatch: Dispatch<ProductMasterEdit>) => {
    axiosInstance
      .get(`product-master/get-byid/${id}`)
      .then((res) => {
        dispatch({
          type: PRODUCT_MASTER_EDIT_DATA, // Dispatch the type of action
          payload: res.data.data, // Pass any data received from the API response
        });
      })
      .catch((error) => {
        // Handle errors if any
      });
  };
};

export const ProductMasterUpdate =
  ({ id, formData }: { id: string; formData: ProductMasterFormValues }) =>
  async (
    dispatch: Dispatch<ProductMasterUpdate | ProductMasterUpdateErrorAction>
  ) => {
    try {
      const response = await axiosInstance.put(
        `product-master/update/${id}`,
        formData
      );
      dispatch({
        type: PRODUCT_MASTER_UPDATE_DATA,
        payload: response.data, // Assuming response contains data
      });
      return response.data;
    } catch (error: any) {
      dispatch({
        type: PRODUCT_MASTER_UPDATE_ERROR,
        payload: error.response.data.data,
      });
      throw error;
    }
  };
