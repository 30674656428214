import React, { useRef } from "react";

import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import * as XLSX from "xlsx";

import Distributorbranchmodal from "../../../components/Modal/Distributorbranchmodal";

const PrimaryUpload: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [downloadopen, setDownloadOpen] = React.useState(false);
  const handledownloadOpen = () => setDownloadOpen(true);
  const handledownloadClose = () => setDownloadOpen(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const model = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "25%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  function convertHeadersToXLS(headers: any) {
    const worksheet = XLSX.utils.aoa_to_sheet([headers]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    return blob;
  }

  // Function to download XLS file with headers only
  function downloadXLSWithHeaders(headers: any) {
    const xlsBlob = convertHeadersToXLS(headers);
    const url = URL.createObjectURL(xlsBlob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "exported_headers.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const [fileName, setFileName] = React.useState();
  const [hideCard, setHideCard] = React.useState(true);
  const [downloadhidecard, setDownloadhidecard] = React.useState(true);

  // Your data
  const headers = ["ID", "Commodity", "Price", "Quantity"];
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const hideandshow = () => {
    setHideCard(!hideCard);
  };
  const hidenshowdown = () => {
    setDownloadhidecard(!downloadhidecard);
  };
  return (
    <Box sx={{ p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
      <Grid item xs={12} className="pad-10 mar-tb">
        <Typography>
          <b> Primary Discount Upload</b>
        </Typography>
      </Grid>
      <form>
        <Card sx={{ mt: 3 }} className="card-form">
          <Grid item>
            <Grid item container columnGap={2} ml={2} mt={3}>
              <Grid item xs={3}>
                <Grid item>Distributor </Grid>
                <TextField variant="outlined" />
                <Button
                  sx={{ ml: 1, color: "#fff" }}
                  className="btn-colr"
                  onClick={handleOpen}
                >
                  ....
                </Button>

                <Grid item></Grid>
              </Grid>
              <Grid item>
                <Modal
                  open={open}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Distributorbranchmodal handleClose={handleClose} />
                  </Box>
                </Modal>
              </Grid>
            </Grid>
            <Grid item container columnGap={2} ml={2} mt={3}>
              <Grid item xs={3}>
                <Grid item>Product Hierarchy Level </Grid>
                <TextField variant="outlined" />
                <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
                  ....
                </Button>

                <Grid item></Grid>
              </Grid>
              <Grid item></Grid>
            </Grid>
            <Grid item container columnGap={2} ml={2} mt={3}>
              <Grid item xs={3}>
                <Grid item>Product Hierarchy Level Value </Grid>
                <TextField variant="outlined" />
                <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
                  ....
                </Button>

                <Grid item></Grid>
              </Grid>
            </Grid>
            <Grid item container columnGap={2} ml={2} mt={3}>
              <Grid item xs={3}>
                <Grid item>Product </Grid>
                <TextField variant="outlined" />
                <Button sx={{ ml: 1, color: "#fff" }} className="btn-colr">
                  ....
                </Button>

                <Grid item></Grid>
              </Grid>
            </Grid>

            <Grid container columnGap={2} ml={2} mt={2}>
              <Grid item>
                <Button
                  variant="contained"
                  className="btn-apply-color"
                  onClick={() => downloadXLSWithHeaders(headers)}
                >
                  Download Format
                </Button>
              </Grid>

              <Grid item xs={2}>
                <TextField fullWidth size="small" value={fileName}></TextField>
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  className="btn-apply-color"
                  onClick={handleButtonClick}
                >
                  + Browse
                </Button>
                <Button
                  variant="contained"
                  className="btn-apply-color"
                  sx={{ marginLeft: "5px" }}
                  onClick={handleButtonClick}
                >
                  Import
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </form>
    </Box>
  );
};
export default PrimaryUpload;
