import { Box, Card, Grid, Snackbar, Alert, Link } from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import SearchBar from "@mkyy/mui-search-bar";
import { Spin, Table, TableColumnsType, Tooltip } from "antd";
import debounce from "lodash.debounce";
import {
  getOrderBillingInvoice,
  getStatusOrderBilling,
} from "../../../Redux/XDM/Sales/orderBillingReducer";
import moment from "moment";
import { getInvoiceCounts } from "../../../Redux/XDM/Sales/InvoiceReducer";
import OrderBillingStatusDetails from "./OrderBillingStatusDetails";
interface OrderBillingStatusDatatype {
  orderId: string;
  invoiceNo: string;
  date: string;
  outletName: string;
  route: string;
  salesMan: string;
  noOfLineItem: number;
  orderValue: string;
  billedValue: string;
  status: string;
}

type TableRowType = OrderBillingStatusDatatype | { noMoreData: boolean };
const roundValue = (label: string, val: number | string): string => {
  if (
    typeof val === "number" &&
    (label === "stockValue" || label === "marginValue")
  ) {
    return val.toFixed(2);
  }
  return val?.toString();
};
interface orderBillingProps {
  tabName: string;
  searchVal: string;
}
export default function OrderBillingStatus(props: orderBillingProps) {
  const { tabName, searchVal } = props; // Destructure the props

  const [isShow, setIsShow] = React.useState(false);
  const [isGrnShow, setIsGrnShow] = React.useState(false);
  const [orders, setOrders] = React.useState<OrderBillingStatusDatatype[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [commonLoader, setCommonLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const tableHeightRef = useRef(420);
  let orderBillingData = useAppSelector((state) => state.orderBillingData);
  let filterTagsout = useAppSelector((state) => state.outletSideBar);
  const filterTags = useAppSelector((state) => state.orderBilling);
  const dispatch = useAppDispatch();
  const filterData = {
    page: 0,
    size: 0,
    salesMan: filterTags.salesmanSelected.toString(),
    route: filterTags.routeSelected.toString(),
    outlet: filterTags.outletSelected.toString(),
    date: filterTags.dateSelected ? filterTags.dateSelected : "",
    fromDate: filterTags.fromDateSelected ? filterTags.fromDateSelected : "",
    toDate: filterTags.toDateSelected ? filterTags.toDateSelected : "",
    search: searchVal.toString(),
    status: tabName,
    division: filterTagsout.divisionSelected.toString(),
    outletGroup: filterTagsout.outletGroupSelected.toString(),
  };

  useEffect(() => {
    setPage(0);
    setHasMore(true);
    setOrders([]);
    loadMoreData(true);
  }, [
    searchVal,
    filterTags.salesmanSelected,
    filterTags.routeSelected,
    filterTagsout.outletGroupSelected,
    filterTags.outletSelected,
    filterTagsout.divisionSelected,
    filterTags.dateSelected,
    filterTags.fromDateSelected,
    filterTags.toDateSelected,
    tabName,
  ]);

  const abortControllerRef = useRef<AbortController | null>(null);

  const tableBodyRef = useRef<HTMLElement | null>(null);

  const updateTableHeight = () => {
    const headerHeight = 0;
    const footerHeight = 213;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    tableHeightRef.current = availableHeight;
  };

  const loadMoreData = (resetPage = false) => {
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // Abort the previous request
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    let nextPage = resetPage ? 0 : page;

    filterData.size = tableHeightRef.current > 550 ? 25 : 15;
    setCommonLoader(true);

    dispatch(
      getStatusOrderBilling({
        params: { ...filterData, page: nextPage },
        signal: abortController.signal,
      })
    )
      .then((response) => {
        if (response.payload) {
          const newOrders = response.payload || [];
          setOrders((prevOrders) =>
            resetPage ? newOrders : [...prevOrders, ...newOrders]
          );
          setPage(nextPage + 1);
          if (newOrders.length < 15) {
            setHasMore(false);
          }
          setCommonLoader(false);
        } else {
          // setCommonLoader(false);
          // setHasMore(false);
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Failed to fetch data:", error);
        }
        setCommonLoader(false);
      });
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  };

  const handleRowClick = (record: OrderBillingStatusDatatype) => {
    setRequestData(record);

    setIsShow(!isShow);
  };
  const [requestData, setRequestData] = React.useState({
    orderId: "",
    invoiceNo: "",
    date: "",
    outletName: "",
    route: "",
    salesMan: "",
    noOfLineItem: 0,
    orderValue: "",
    billedValue: "",
    status: "",
  });
  const columns: TableColumnsType<TableRowType> = [
    {
      dataIndex: "orderId",
      title: "Order Id",
      width: "10%",
      ellipsis: true,
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <span
          // onClick={() => handleGRNClick(record as OrderBillingStatusDatatype)}
          >
            {record.orderId ? record.orderId : "--"}
          </span>
        );
      },
    },

    {
      dataIndex: "date",
      width: "10%",
      title: "Date",
      ellipsis: true,

      //   sorter: (a, b) => {
      //     if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
      //     return a.date.localeCompare(b.date);
      //   },
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        if (record.date) {
          const dateObj = moment(record.date, "YYYY-MM-DD");

          const formattedDate = dateObj.format("DD MMM YYYY");

          return <div>{formattedDate}</div>;
        }
        return "-";
      },
    },

    {
      dataIndex: "outletName",
      width: "10%",
      ellipsis: true,
      title: "Outlet",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        const data = record.outletName ? record.outletName : "--";

        return data;
      },
    },
    {
      dataIndex: "route",
      width: "10%",
      ellipsis: true,
      title: "Route",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        const data = record.route ? record.route : "--";

        return data;
      },
    },
    {
      dataIndex: "salesMan",
      width: "10%",
      ellipsis: true,
      title: "Sales Man",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        const data = record.salesMan ? record.salesMan : "--";

        return data;
      },
    },
    {
      dataIndex: "noOfLineItem",
      width: "10%",
      ellipsis: true,

      title: "No of Line Item",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        const data = record.noOfLineItem ? record.noOfLineItem : "0";
        return record.noOfLineItem;
      },
    },
    {
      dataIndex: "orderValue",
      width: "10%",
      title: "Order Value",
      ellipsis: true,

      //   sorter: (a, b) => {
      //     if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
      //     return a.orderValue;
      //   },
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <span>
            <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
            {record.orderValue ? Number(record.orderValue).toFixed(2) : "0.00"}
          </span>
        );
      },
    },
    {
      dataIndex: "status",
      width: "10%",
      ellipsis: true,
      title: "Status",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        const color =
          record.status.toLowerCase() === "confirmed"
            ? "green"
            : record.status.toLowerCase() === "partially confirmed"
            ? "orange"
            : record.status.toLowerCase() === "rejected"
            ? "red"
            : record.status.toLowerCase() === "cancelled"
            ? "red"
            : "black";
        return <b style={{ color }}>{record.status}</b>;
      },
    },
  ];
  if (tabName === "Confirmed") {
    columns.splice(1, 0, {
      dataIndex: "invoiceNo",
      title: "Invoice No",
      ellipsis: true,
      width: "10%",
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <span
          // onClick={() => handleInvoiceClick(record as OrderBillingStatusDatatype)}
          >
            {record.invoiceNo ? record.invoiceNo : "--"}
          </span>
        );
      },
    });
    columns.splice(7, 0, {
      dataIndex: "billedValue",
      ellipsis: true,
      width: "10%",
      title: "Billed Value",
      //   sorter: (a, b) => {
      //     if ("noMoreData" in a || "noMoreData" in b) return 0; // Skip sorting for special row
      //     return a.orderValue;
      //   },
      render: (_: any, record: TableRowType) => {
        if ("noMoreData" in record)
          return { children: null, props: { colSpan: 0 } };
        return (
          <span>
            <CurrencyRupeeIcon sx={{ fontSize: 10 }} />
            {record.billedValue
              ? Number(record.billedValue).toFixed(2)
              : "0.00"}
          </span>
        );
      },
    });
  }
  const dataToShow: TableRowType[] =
    orders.length > 0
      ? hasMore
        ? orders
        : [...orders, { noMoreData: true }]
      : [];
  const handleScroll = useCallback(
    debounce(() => {
      if (!tableBodyRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        if (hasMore && !commonLoader) {
          loadMoreData(false);
        }
      }
    }, 100),
    [hasMore, commonLoader]
  );

  useEffect(() => {
    const tableBody = document.querySelector(
      ".saleable-table-scroll .ant-table-body"
    ) as HTMLElement | null;
    if (tableBody) {
      tableBodyRef.current = tableBody;
      tableBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);
  return (
    <Box>
      <Box flex={8} sx={{ overflowY: "auto", height: "100vh", mt: -5.3 }}>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={100000}
          color="green"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Card
          className="b-radius-0"
          style={{ marginTop: "10px", boxShadow: "none" }}
        >
          <Grid container spacing={3} justifyContent="start" p={2}>
            <Grid container mt={4} m={2}>
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <h3
                  style={{
                    marginTop: "0px",
                    marginLeft: "8px",
                    marginBottom: "16px",
                  }}
                ></h3>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={12} md={12} lg={12} className="pt-0">
              {/* {(filterTags.salesmanSelected.length !== 0 ||
                                filterTags.routeSelected.length !== 0 ||
                                filterTags.outletSelected.length !== 0 ||
                                textFieldValue !== "") && (
                            <p style={{ marginTop: "0px", marginBottom: "10px" }}>
                                Showing Results based on selected filters{" "}
                                <b>({filteredCount} Products)</b>
                            </p>
                             )} */}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} className="pad-top-0px">
              <div className="saleable-table-scroll order-bill">
                <Table
                  className={`Header-table saleable-table sale_last_hide scroll_cus_table  ${
                    commonLoader ? " nodataloader" : ""
                  }`}
                  columns={columns}
                  dataSource={dataToShow}
                  pagination={false}
                  loading={page === 0 && commonLoader}
                  showSorterTooltip={false}
                  summary={() => {
                    return (
                      <>
                        {page !== 0 && commonLoader ? (
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              index={1}
                              colSpan={tabName === "Confirmed" ? 10 : 8}
                              align="center"
                            >
                              <Spin />
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        ) : (
                          !hasMore && (
                            <Table.Summary.Row>
                              <Table.Summary.Cell
                                index={1}
                                colSpan={tabName === "Confirmed" ? 10 : 8}
                                align="center"
                                className="fs-12 noMore btm_boreder"
                              >
                                <b>No more data found...</b>
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          )
                        )}
                      </>
                    );
                  }}
                  onRow={(record: TableRowType) => {
                    if ("noMoreData" in record) return {};
                    return {
                      onClick: () =>
                        handleRowClick(record as OrderBillingStatusDatatype),
                    };
                  }}
                  scroll={{ y: tableHeightRef.current }}
                />
              </div>
            </Grid>
          </Grid>
        </Card>
        {isShow ? (
          <>
            <OrderBillingStatusDetails
              onClose={() => setIsShow(false)}
              requestData={requestData}
              status={tabName}
            />
          </>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}
