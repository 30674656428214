// material-ui
import { Grid, Typography, TextField } from "@mui/material";
import { FormControl, InputLabel, Button, Card, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Reset: React.FC = () => {
  const navigator = useNavigate();
  return (
    <Grid item sx={{ p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
      <Grid item xs={12} className=" mar-tb">
        <Typography>
          <b className="header-lable">PassWord Reset </b>
        </Typography>
      </Grid>

      <form>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 3, sm: 3 }} mt={2}>
          <Grid item xs={12} sm={4} md={3} mt={1}>
            <InputLabel className="fnt-lable">
              Enter Login UserCode :
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField className="Txt-box" />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={3} mt={3}>
          <Button variant="contained" className="btn-colr">
            Reset
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};
export default Reset;
