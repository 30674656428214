import React, { useRef } from "react";
import { Button, Grid, TextField, Card, Box, Typography } from "@mui/material";
import * as XLSX from "xlsx";
import AddIcon from "@mui/icons-material/Add";

function convertHeadersToXLS(headers: any) {
  const worksheet = XLSX.utils.aoa_to_sheet([headers]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  return blob;
}

// Function to download XLS file with headers only
function downloadXLSWithHeaders(headers: any) {
  const xlsBlob = convertHeadersToXLS(headers);
  const url = URL.createObjectURL(xlsBlob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "exported_headers.xlsx");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const ProductPriceUpload = () => {
  const [fileName, setFileName] = React.useState();
  const [hideCard, setHideCard] = React.useState(true);
  const [hideImportCard, setHideImportCard] = React.useState(true);
  // Your data
  const headers = ["ID", "Commodity", "Price", "Quantity"];
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      // Do something with the selected file
      // console.log(selectedFile.name);
    }
  };
  const hideExportshow = () => {
    setHideCard(!hideCard);
  };
  const hideImportshow = () => {
    setHideImportCard(!hideImportCard);
  };

  return (
    <>
      <Box sx={{ marginTop: "2px", p: { xs: 2, sm: 3 }, pt: { xs: 1, sm: 1 } }}>
        <Grid item xs={12} className="pad-10 mar-tb">
          <Typography>
            <b>Product Price Upload</b>
          </Typography>
        </Grid>
        <Grid item sx={{ display: "flex", flexDirection: "column" }}>
          <Button
            variant="contained"
            className="btn-apply export-btn"
            onClick={hideExportshow}
          >
            {" "}
            - Export
          </Button>
          {hideCard && (
            <Card className="card-form" sx={{ border: "1px solid #EEE" }}>
              <Button
                variant="contained"
                className="btn-apply"
                onClick={() => downloadXLSWithHeaders(headers)}
              >
                Download Format
              </Button>
              <input
                ref={fileInputRef}
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </Card>
          )}
          <Button
            variant="contained"
            className="btn-apply export-btn"
            onClick={hideImportshow}
          >
            {" "}
            - import
          </Button>
          {hideImportCard && (
            <Card className="card-form" sx={{ border: "1px solid #EEE" }}>
              <Grid container sx={{ marginTop: "16px" }}>
                <Grid item xs={2}>
                  <TextField size="small" value={fileName}></TextField>
                </Grid>
                <Grid item xs={5}>
                  <Button
                    variant="contained"
                    className="btn-apply-color"
                    sx={{ marginLeft: "5px" }}
                    onClick={handleButtonClick}
                  >
                    <AddIcon />
                    Browse
                  </Button>
                  <Button
                    variant="contained"
                    className="btn-apply-color"
                    sx={{ marginLeft: "5px" }}
                    onClick={handleButtonClick}
                  >
                    Import
                  </Button>
                </Grid>
              </Grid>
            </Card>
          )}
        </Grid>
        <Grid item mt={3} sx={{ display: "flex", justifyContent: "center" }}>
          <Grid item mr={2}>
            <Button className="btn-colr " variant="contained">
              cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default ProductPriceUpload;
