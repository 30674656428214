// @ts-ignore
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import * as React from 'react';
import { Box, Divider, IconButton, Button, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { Close } from '@mui/icons-material';
import { Input, Space, Table } from 'antd';
// import Highlighter from 'react-highlight-words'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    border: 'none',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,

};

interface TableSearchDataTableProps {
    data: any[];
    columns: any[];
    isModalOpen: boolean;
    // rowSelection: {
    //     selectedRowKeys: React.Key[];
    //     onChange: (selectedRowKeys: React.Key[]) => void;
    // };
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;

}

export default function TableSearchDataTable({ data, columns, isModalOpen, setIsModalOpen,
    //  rowSelection
     }: TableSearchDataTableProps) {
    const handleClose = () => setIsModalOpen(false);

    return (
        <div>
            <Modal open={isModalOpen} aria-describedby="modal-modal-description">
                <Box sx={{ ...style, width: 700 }} display="flex" flexDirection="column">
                    {/* <Typography sx={{margin:'3px'}}>{}</Typography> */}

                    <Box sx={{ flex: 'none', marginBottom: 2 }}>
                        <IconButton
                            aria-label="close"
                            style={{ position: 'absolute', top: 10, right: 10 }}
                            onClick={handleClose}
                        >
                            <Close />
                        </IconButton>
                    </Box>

                    <Divider />
                    <Box sx={{ flex: 1, mt: 1 }}>
                        <Table
                            className='Header-table'
                            columns={columns} dataSource={data}
                            // rowSelection={rowSelection}
                            pagination={false}
                        />;
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <Button variant="outlined" sx={{ mr: 2 }} onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" className='btn-colr' >
                            OK
                        </Button>
                    </Box>
                </Box>
            </Modal>

        </div>
    );
}
