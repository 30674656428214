import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Drawer, Grid, Skeleton, Stack, Typography } from "@mui/material";
import Close from "../../../../../assets/images/icons/Close_icon.png";
import Divider from "@mui/material/Divider";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useFetch";
import MainCard from "../../../../../components/MainCard";
import { getSingleCollectionHistory } from "../../../../../Redux/XDM/Sales/collectionReducer";
import InvoiceDetails from "../../InvoiceReviewOrder";

interface InvoiceDataDataType {
  invoiceNumber: string;
  invoiceDate: string;
}
interface RequestData {
  outlet: string;
  invoiceNumber: string[];
  salesMan: string;
  collectionDate: string;
  status: string;
  collectionNumber: string;
  amountCollected: string;
  outstandingAmount: number;
  paymentMode: string;
  checkDate: string;
  isAcknowledged: boolean;
}

interface InvoiceRequest {
  invoiceNumber: string;
  orderId: string;
  date: string;
  outlet: string;
  route: string;
  salesman: string;
  netAmount: number;
  salesmanMobile: string;
}

interface CollectionHistoryDetailsProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
  collectionId: string;
  invoiceRequestData: InvoiceRequest;
}

export default function CollectionHistoryDetails(
  props: CollectionHistoryDetailsProps
) {
  const { onClose, requestData, collectionId, invoiceRequestData } = props; // Destructure the props
  const dispatch = useAppDispatch();
  const [isInvoice, setIsInvoice] = useState(false);
  const collectionReducerData = useAppSelector(
    (state) => state.collectionReducerData
  );
  const singlecollectionHistoryData =
    collectionReducerData.singlecollectionHistory;

  const loading = collectionReducerData.loading;

  useEffect(() => {
    const params = {
      collectionId: collectionId,
    };
    dispatch(
      getSingleCollectionHistory({
        params: params,
      })
    );
  }, [collectionId]);

  const changeDateFormate = (date: string) => {
    const dateObj = moment(date, "YYYY-MM-DD");
    const formattedDate = dateObj.format("DD MMM YYYY");
    return date ? formattedDate : "--";
  };

  const handleInvoiceClick = (value: string) => {
    invoiceRequestData.invoiceNumber = value;
    setIsInvoice(!isInvoice);
  };

  const statusContent = () => {
    let statusContent = "";
    let color = "";
    let paymentType = requestData.paymentMode;
    let date = requestData.collectionDate;
    let isAcknowledged = requestData.isAcknowledged;
    let text = requestData.status;
    if (
      paymentType === "Cheque" &&
      (text === "Approved" || text === "Rejected")
    ) {
      date = requestData.collectionDate;
    } else {
      date = changeDateFormate(singlecollectionHistoryData.receivedDate);
    }
    if (
      (paymentType === "Cash" || paymentType === "UPI") &&
      text === "Approved"
    ) {
      statusContent = "Payment Received on " + date;
      color = "#52A13D";
    } else if (
      (paymentType === "Cash" || paymentType === "UPI") &&
      text === "Rejected"
    ) {
      statusContent = "Payment Rejected on " + date;
      color = "#E12B1D";
    } else if (paymentType === "Cheque" && text === "Bounced") {
      statusContent = "Cheque Bounced on " + date;
      color = "#E12B1D";
    } else if (
      paymentType === "Cheque" &&
      text === "Approved" &&
      isAcknowledged === true
    ) {
      statusContent = "Payment Received on " + date;
      color = "#52A13D";
    } else if (
      paymentType === "Cheque" &&
      text === "Approved" &&
      isAcknowledged === false
    ) {
      statusContent = "Cheque Received on " + date;
      color = "#F28500";
    } else if (paymentType === "Cheque" && text === "Rejected") {
      statusContent = "Cheque Rejected on " + date;
      color = "#E12B1D";
    }
    return { statusContent, color };
  };

  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_stl"
        // variant="persistent"
      >
        <Box
          sx={{
            width: {
              lg: 700,
              md: 536,
              sm: 600,
              xs: 400,
            },
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "100%" }} role="presentation">
            <Grid
              container
              className="crt-header"
              // mt={1}
              sx={{ padding: "16px 16px 0px 16px", alignItems: "center" }} // Adjust the alignment to center vertically
            >
              <Grid
                item
                xs={10}
                md={10}
                lg={10}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Typography
                  gutterBottom
                  className="crt-header-lable-2"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  title={singlecollectionHistoryData.collectionNumber}
                >
                  {loading ? (
                    <Skeleton variant="rectangular" width="30%" />
                  ) : (
                    <span>
                      Collection ID:{" "}
                      {singlecollectionHistoryData.collectionNumber}
                    </span>
                  )}
                </Typography>
                <Typography
                  gutterBottom
                  className="crt-header-lable-2"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                  title={singlecollectionHistoryData.collectionDate}
                >
                  {loading ? (
                    <Skeleton variant="rectangular" width="30%" />
                  ) : (
                    <div
                      className="ellipseclass"
                      style={{
                        color: statusContent().color,
                        fontWeight: "700",
                      }}
                    >
                      {statusContent().statusContent}
                    </div>
                  )}
                </Typography>
              </Grid>

              <Grid item xs={2} md={2} lg={2} sx={{ textAlign: "right" }}>
                <img
                  src={Close}
                  onClick={onClose}
                  style={{ width: "19px", height: "19px", cursor: "pointer" }}
                  alt="Close Icon"
                />
              </Grid>
            </Grid>
            <Divider />
          </Grid>

          {/* Content */}
          <Box
            mt={2}
            sx={{
              overflowY: "auto",
              height: "70%",
            }}
            className="create-form saleable-products-box"
          >
            <Grid
              item
              sx={{
                width: "95%",
                margin: "auto",
                marginBottom: "10px",
              }}
            >
              <Grid container mt={1}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Outlet Name
                  </Typography>
                  <Typography
                    gutterBottom
                    className="crt-lable-input-2 ellipseclass"
                    mt={1}
                  >
                    {loading ? (
                      <Skeleton variant="rectangular" width="30%" />
                    ) : (
                      <span>
                        {singlecollectionHistoryData.outletName
                          ? singlecollectionHistoryData.outletName
                          : "-"}
                      </span>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Salesman Name
                  </Typography>
                  <Typography
                    gutterBottom
                    className="crt-lable-input-2 ellipseclass"
                    mt={1}
                  >
                    {loading ? (
                      <Skeleton variant="rectangular" width="30%" />
                    ) : (
                      singlecollectionHistoryData.salesmanName
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Invoice No
                  </Typography>
                  <Typography
                    gutterBottom
                    className="crt-lable-input-2 ellipseclass"
                    mt={1}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {loading ? (
                      <Skeleton variant="rectangular" width="30%" />
                    ) : (
                      singlecollectionHistoryData?.invoiceDetailsCollected?.map(
                        (value: any, index: number) => (
                          <React.Fragment key={index}>
                            <div
                              style={{
                                textDecoration: "underline",
                                fontWeight: 600,
                                color: "#27538D",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleInvoiceClick(value.invoiceNumber)
                              }
                            >
                              {value.invoiceNumber}{" "}
                            </div>
                            <span
                              style={{
                                color: "#27538D",
                              }}
                            >
                              {singlecollectionHistoryData
                                .invoiceDetailsCollected.length > 1 && ", "}
                            </span>
                          </React.Fragment>
                        )
                      )
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    {singlecollectionHistoryData.collectionType === "Cash"
                      ? "Cash"
                      : singlecollectionHistoryData.collectionType === "Cheque"
                      ? "Cheque"
                      : "UPI"}{" "}
                    Collected Date
                  </Typography>
                  <Typography
                    gutterBottom
                    className="crt-lable-input-2 ellipseclass"
                    mt={1}
                  >
                    {loading ? (
                      <Skeleton variant="rectangular" width="30%" />
                    ) : (
                      changeDateFormate(
                        singlecollectionHistoryData.collectionDate
                      )
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Payment Received Date
                  </Typography>
                  <Typography
                    gutterBottom
                    className="crt-lable-input-2 ellipseclass"
                    mt={1}
                  >
                    {loading ? (
                      <Skeleton variant="rectangular" width="30%" />
                    ) : (
                      changeDateFormate(
                        singlecollectionHistoryData.receivedDate
                      )
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Amount Collected
                  </Typography>
                  <Typography
                    gutterBottom
                    className="crt-lable-input-2 ellipseclass"
                    mt={1}
                  >
                    {loading ? (
                      <Skeleton variant="rectangular" width="30%" />
                    ) : (
                      <>
                        <CurrencyRupeeIcon sx={{ fontSize: 12 }} />
                        {singlecollectionHistoryData.totalCollectedAmount
                          ? singlecollectionHistoryData?.totalCollectedAmount?.toFixed(
                              2
                            )
                          : "0.00"}
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Outlets Outstanding Amount
                  </Typography>
                  <Typography
                    gutterBottom
                    className="crt-lable-input-2 ellipseclass"
                    mt={1}
                  >
                    {loading ? (
                      <Skeleton variant="rectangular" width="30%" />
                    ) : (
                      <>
                        <CurrencyRupeeIcon sx={{ fontSize: 12 }} />
                        {singlecollectionHistoryData?.outstandingAmt
                          ? singlecollectionHistoryData?.outstandingAmt?.toFixed(
                              2
                            )
                          : "0.00"}
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography className="crt-header-lable-2" my={1}>
                    Invoices
                  </Typography>
                  <Divider />
                </Grid>
              </Grid>
              <Grid container mt={1}>
                {loading ? (
                  <Skeleton variant="rectangular" width="30%" height={70} />
                ) : (
                  <>
                    {singlecollectionHistoryData?.invoiceDetailsCollected?.map(
                      (key: any, value: number) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={4}
                          mr={1}
                          key={value}
                          style={{ paddingTop: "4px" }}
                        >
                          <MainCard border={false} boxShadow>
                            <Stack
                              spacing={1}
                              justifyContent="center"
                              alignItems="center"
                              className={"card-box-counts invoice-card"}
                            >
                              <Typography
                                variant="h6"
                                className="crt-header-lable-2"
                              >
                                Collected Amount:{" "}
                                <CurrencyRupeeIcon sx={{ fontSize: 12 }} />{" "}
                                {key?.collectedAmount?.toFixed(2)}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                className="crt-fnt-lable-light"
                              >
                                Invoice No:{key.invoiceNumber}
                              </Typography>
                            </Stack>
                          </MainCard>
                        </Grid>
                      )
                    )}
                  </>
                )}
              </Grid>
              {!loading &&
                singlecollectionHistoryData.collectionType !== "Cash" && (
                  <Grid container mt={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography className="crt-header-lable-2" my={1}>
                        {singlecollectionHistoryData.collectionType === "Cheque"
                          ? "Cheque Details"
                          : "UPI Screenshot"}
                      </Typography>
                      <Divider />
                    </Grid>
                    {singlecollectionHistoryData.collectionType ===
                      "Cheque" && (
                      <>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Typography className="crt-fnt-lable-light" mt={1}>
                            Cheque No
                          </Typography>
                          <Typography
                            gutterBottom
                            className="crt-lable-input-2 ellipseclass"
                            mt={1}
                          >
                            {
                              singlecollectionHistoryData?.chequeDetails
                                ?.chequeNumber
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Typography className="crt-fnt-lable-light" mt={1}>
                            Bank Name
                          </Typography>
                          <Typography
                            gutterBottom
                            className="crt-lable-input-2 ellipseclass"
                            mt={1}
                          >
                            {
                              singlecollectionHistoryData?.chequeDetails
                                ?.bankName
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                          <Typography className="crt-fnt-lable-light" mt={1}>
                            Cheque Date
                          </Typography>
                          <Typography
                            gutterBottom
                            className="crt-lable-input-2 ellipseclass"
                            mt={1}
                          >
                            {changeDateFormate(
                              singlecollectionHistoryData?.chequeDetails
                                ?.chequeDate
                            )}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    <div className="image_Container">
                      {" "}
                      {singlecollectionHistoryData?.img?.map(
                        (thumbnail: any, index: number) => (
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div className="Image_thumbnail" key={index}>
                              <img
                                src={thumbnail}
                                alt={`Thumbnail ${index + 1}`}
                                className={`${
                                  singlecollectionHistoryData.collectionType ===
                                  "Cheque"
                                    ? "image_thumbnail_img"
                                    : "image_thumbnail_img"
                                }`}
                              />
                            </div>
                          </Grid>
                        )
                      )}
                    </div>
                  </Grid>
                )}
              {isInvoice && (
                <InvoiceDetails
                  onClose={() => setIsInvoice(false)}
                  requestData={invoiceRequestData}
                />
              )}
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
