import { Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useFetch";
import {
  sidebarFilter,
  subHeaderHide,
} from "../../../../../Redux/AuthLogin/action";
import {
  updateBasedOnStock,
  updateBrand,
  updateBrandPop,
  updateBrandTemp,
  updateDivision,
  updateDivisionPop,
  updateDivisionTemp,
  updatePackagingType,
  updatePackagingTypePop,
  updatePackagingTypeTemp,
  updateReason,
  updateSegment,
  updateSegmentPop,
  updateSegmentTemp,
  updateSize,
  updateSizePop,
  updateSizeTemp,
  updateSubVariant,
  updateSubVariantPop,
  updateSubVariantTemp,
  updateVariant,
  updateVariantPop,
  updateVariantTemp,
} from "../../../../../Redux/XDM/Inventory/filterReducer";
import {
  activeMainTab,
  activeSubTab,
} from "../../../../../Redux/XDM/Header/tabReducer";
import {
  updateDate,
  updateFromDate,
  updateInvoiceType,
  updateOutlet,
  updateOutletPop,
  updateOutletTemp,
  updateRoute,
  updateRoutePop,
  updateRouteTemp,
  updateSalesman,
  updateSalesmanPop,
  updateSalesmanTemp,
  updateStatusGrn,
  updateStocks,
  updateToDate,
} from "../../../../../Redux/XDM/Sales/billingReducer";
import {
  updateMaxValue,
  updateMinValue,
  updateOutletDivision,
  updateOutletDivisionPop,
  updateOutletDivisiontemp,
  updateOutletGroup,
  updateOutletGroupPop,
  updateOutletGrouptemp,
  updateStatus,
} from "../../../../../Redux/XDM/Sales/outletSideBarReducer";
interface HeaderProps {
  classes: any;
  tabs: any;
}

const MainHeaderData: React.FC<HeaderProps> = ({ classes, tabs }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const dispatch = useAppDispatch();

  const activeTab = useAppSelector((state) => state.activeTab.activeTab);

  useEffect(() => {
    if (currentPath.includes("inventory"))
      dispatch(activeMainTab("/xdm/inventory/saleable"));
    else if (currentPath.includes("master"))
      dispatch(activeMainTab("/master/company_dashboard"));
    else if (currentPath.includes("sales"))
      dispatch(activeMainTab("/xdm/sales/order-billing"));
    else if (currentPath.includes("purchase"))
      dispatch(activeMainTab("/xdm/purchase/invoices-grn"));
    else dispatch(activeMainTab(currentPath));
  }, [currentPath]);

  const changeTab = (tab: any) => {
    switch (tab) {
      case "Inventory":
        dispatch(activeSubTab("/xdm/inventory/saleable"));
        break;
      case "Sales":
        dispatch(activeSubTab("/xdm/sales/order-billing"));
        break;
        case "Purchase":
          dispatch(activeSubTab("/xdm/purchase/invoices-grn"));
          break;
      default:
        break;
    }
  };

  const sideBarChange = () => {
    dispatch(sidebarFilter(false, ""));
    dispatch(subHeaderHide(false));
  };

  const clearAll = () => {
    dispatch(updateBasedOnStock([]));
    dispatch(updateDivision([]));
    dispatch(updateReason([]));
    dispatch(updateSegment([]));
    dispatch(updateBrand([]));
    dispatch(updateVariant([]));
    dispatch(updateSubVariant([]));
    dispatch(updatePackagingType([]));
    dispatch(updateSize([]));
    dispatch(updateDivisionPop([]));
    dispatch(updateSegmentPop([]));
    dispatch(updateBrandPop([]));
    dispatch(updateVariantPop([]));
    dispatch(updateSubVariantPop([]));
    dispatch(updatePackagingTypePop([]));
    dispatch(updateSizePop([]));
    dispatch(updateDivisionTemp([]));
    dispatch(updateSegmentTemp([]));
    dispatch(updateBrandTemp([]));
    dispatch(updateVariantTemp([]));
    dispatch(updateSubVariantTemp([]));
    dispatch(updatePackagingTypeTemp([]));
    dispatch(updateSizeTemp([]));
    dispatch(updateStocks([]));
    dispatch(updateSalesman([]));
    dispatch(updateRoute([]));
    dispatch(updateOutlet([]));
    dispatch(updateSalesmanPop([]));
    dispatch(updateRoutePop([]));
    dispatch(updateOutletPop([]));
    dispatch(updateSalesmanTemp([]));
    dispatch(updateRouteTemp([]));
    dispatch(updateOutletTemp([]));
    dispatch(updateStatus([]));
    dispatch(updateDate([]));
    dispatch(updateToDate([]));
    dispatch(updateStatusGrn([]));
    dispatch(updateInvoiceType([]));
    dispatch(updateFromDate([]));
    dispatch(updateOutletGroup([]));
    dispatch(updateOutletDivision([]));
    dispatch(updateOutletGrouptemp([]));
    dispatch(updateOutletDivisiontemp([]));
    dispatch(updateOutletGroupPop([]));
    dispatch(updateOutletDivisionPop([]));
    dispatch(updateMaxValue(""));
    dispatch(updateMinValue(""));
  };

  return (
    <Tabs
      value={activeTab}
      className="tabsize"
      TabIndicatorProps={{
        style: { display: "none", transition: "none" },
      }}
    >
      {tabs.map((tab: any) => (
        <Tab
          value={tab.path}
          className="tab_hover"
          label={
            <Typography
              className={`${
                activeTab === tab.path ? classes.gradientText : ""
              } ${"fs_13"}  ${tab.path}`}
            >
              {tab.label}
            </Typography>
          }
          onClick={() => {
            navigate(`${tab.path}`);
            if (tab.sidebar) {
              sideBarChange();
              dispatch(activeMainTab(tab.path));
              changeTab(tab.label);
            }
            if (tab.label === "Purchase" ||tab.label === "Inventory" || tab.label === "Sales") {
              clearAll();
            }
          }}
          sx={
            activeTab === tab.path
              ? { backgroundColor: "#FFF", height: "50px" }
              : { color: "#FFF", height: "50px" }
          }
        />
      ))}
    </Tabs>
  );
};

export default MainHeaderData;
