import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { Button, Drawer, Grid, Typography } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import type { TableColumnsType } from "antd";
import {  Table, Tooltip } from "antd";
import { useAppDispatch, useAppSelector } from "../../../hooks/useFetch";
import moment from "moment";
import { ArrowBackIos } from "@mui/icons-material";
import SearchBar from "@mkyy/mui-search-bar";
import {
  getOutletDetails,
  getOutletInvoiceDetails,
  getRecentInvoiceDetails,
} from "../../../Redux/XDM/Sales/outletReducer";
import alertsquare from "../../../assets/images/icons/alert_square.png";
import GmReviewView from "./GmReviewView";
import discrepancyInfo from '../../../assets/images//icons/discrepancyInfo_img.png'
import ViewDiscrepancyInfo from "./ViewDiscrepancyInfo";
import { getGrnHistoryDeials, getGrnProductDetails, getProductDetailsGrn } from "../../../Redux/XDM/Purchase/GrnReducer";
import InvoiceDetails from "./PrimaryInvoiceReviewOrder";



interface GrnProductDetails {
  productCode: string;
  matGrpDesc: string;
  basicMaterial: string;
  uom: string;
  mrp: number;
  invoicedQuantity: number;
  invoicedValue: string;
  acceptedQty: number;
  acceptedValue: number;
  batchCode: string;
  status: string;
  discrepancyInfo: boolean;
}
interface RequestData {
  grnDate: string;
  grnNo: string;
  invoiceNo: string;
  orderId: string;
  value: number;
  noOfLineItems: number;
  date: string;
  outlet: string;
  route: string;
  salesman: string;
  status: string;
}
interface GrnDetailsProps {
  onClose: () => void; // Define the onClose prop
  requestData: RequestData;
}
export default function GrnReviewDetails(props: GrnDetailsProps) {
  const { onClose, requestData } = props; 

  const onCloseHandler = () => {
    onClose();
  };
  let outletDatas = useAppSelector((state) => state.outletData);
  let outletDetailsData = outletDatas.outletDetailsData;
  let grnData=useAppSelector((state) => state.pendingGrnData);
  let grnHistoryDetails = grnData?.grnHistoryDetails
    const formatDate = (date: string | undefined) => {
      if (date) {
        const dateObj = moment(date, "YYYY-MM-DD HH:mm:ss");
        return dateObj.format("DD MMM YYYY");
      }
      return "-";
    };

  const dispatch = useAppDispatch();

    useEffect(() => {
    dispatch(getGrnHistoryDeials(requestData.grnNo))
    dispatch(getGrnProductDetails(requestData.grnNo));
    }, [dispatch, requestData]);

  const [isShow, setIsShow] = React.useState(false);
  const [batchCode, setBatchCode] = React.useState("");
  const [isShowInvoice, setIsShowInvoice] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const[filteredRecords,setFilteredRecords]=useState<GrnProductDetails[]>([]);
  const handleRowClick = (record: GrnProductDetails) => {
    // setRequestData(record);
    setIsShow(!isShow);
  };
  const [isViewDiscrepancyInfo, setIsViewDiscrepancyInfo] = React.useState(false);
 const handleInvoiceClick = () => {

   setIsShowInvoice(!isShowInvoice);
 };
  const ViewdiscrepancyClick = (batchCode: string) => {
    setIsViewDiscrepancyInfo(!isViewDiscrepancyInfo);
    setBatchCode(batchCode);
  };
  const getStatusClass = (status: any) => {
    switch (status) {
      case 'Rejected':
        return 'rejected_button';
      case 'Accepted':
        return 'accepted_button';
      case 'Partially Accepted':
        return 'pending_button';
      default:
        return '';
    }
  };
  const columns1: TableColumnsType<GrnProductDetails> = [
    {
      dataIndex: "matGrpDesc",
      title: "Product Name",
      width: "18%",
      ellipsis: true,
      render: (text: string, record: GrnProductDetails) => {
        const prdname = record.matGrpDesc
          ? record.matGrpDesc
          : record.basicMaterial;
        return (
          <Tooltip placement="top" title={prdname} zIndex={999999}>
            <span>{prdname ? prdname : "--"}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "productCode",
      title: "Product Code",
      width: "18%",
      render: (text: string) => {
        return (
          <Tooltip placement="top" title={text} zIndex={999999}>
            <span>{text ? text : "--"}</span>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "uom",
      width: "12%",
      title: "UOM",
      render: (text: string) => {
        return <span title={text}>{text ? text : "--"}</span>;
      },
    },
    {
      dataIndex: "mrp",
      width: "12%",
      title: "MRP / PC",
      align: "end",
      ellipsis: true,
      render: (text: number) => {
        return (
          <span title={String(text)}>₹{text ? text.toFixed(2) : "0.00"}</span>
        );
      },
    },
    {
      dataIndex: "invoicedQuantity",
      width: "17%",
      title: " Invoice Quantity ",
      align: "center",
      render: (text: number) => {
        return <span title={String(text)}><b>{text ? text : "0"}</b></span>;
      },
    },
    {
      dataIndex: "invoicedValue",
      width: "15%",
      title: " Invoice Value ",
      align: "end",
      render: (text: number) => {
        return (
          <span title={String(text)}>₹{text ? text.toFixed(2) : "0.00"}</span>
        );
      },
    },
    {
      dataIndex: "acceptedQty",
      width: "18%",
      title: "Accepted Quantity",
      align: "center",
      render: (text: number) => {
        return <span title={String(text)}>{text ? text : "0"}</span>;
      },
    },

    {
      dataIndex: "acceptedValue",
      width: "18%",
      align: "end",
      title: "Accepted Value ",
      render: (text: number) => {
        return (
          <span title={String(text)}>₹{text ? text.toFixed(2) : "0.00"}</span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "18%",
      align: "center",
      key: "invoiceStatus",
      render: (text) => (
        <Button className={getStatusClass(text)}>{text}</Button>
      ),
    },
    {
      dataIndex: "discrepancyInfo",
      width: "18%",
      title: "Discrepancy Info ",
       render: (text: boolean, record: GrnProductDetails) => {
        if(record.discrepancyInfo===true){ return (
          <Grid container justifyContent="center" component="div">
            <Button onClick={() => ViewdiscrepancyClick(record.batchCode)}>
              <img
                src={discrepancyInfo}
                alt="alert square"
                style={{ width: "22px", height: "22px" }}
              />
            </Button>
          </Grid>
        );}else
        return "";
       
      },
    },
  ];
 const hasDiscrepancy = grnData.grnProductDetails?.some(
   (record: any) => record.discrepancyInfo === true
 );
  useEffect(() => {
    const filteredData = grnData.grnProductDetails?.filter((order) => {
      const searchTermLower = searchValue.toLowerCase();
      return (
        order.productCode.toLowerCase().includes(searchTermLower) ||
        order.basicMaterial.toLowerCase().includes(searchTermLower)||
        order.matGrpDesc.toLowerCase().includes(searchTermLower)
      );
    });

    setFilteredRecords(filteredData);
  }, [grnData.grnProductDetails, searchValue]);
 
  const [isView, setIsView] = React.useState(false);

  const handleViewClick = () => {
    setIsView(!isView);
  };
  const handleSearch = () => {
    setSearchValue(searchValue);
  };
  return (
    <Box sx={{ marginTop: "2px" }}>
      <Drawer
        anchor="right"
        open={true}
        className="drawer_outlet grn_drawer_outlet w-100px"
        // variant="persistent"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Heading */}
          <Grid item sx={{ width: "100%" }}>
            <Grid
              container
              sx={{
                borderBottom: "1px solid #0000000D",
                padding: "12px 24px",
                paddingBottom: "10px",
              }}
            >
              <Grid
                item
                sm={0.2}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ArrowBackIos
                  sx={{
                    fontSize: "10px",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                  onClick={onCloseHandler}
                />
              </Grid>
              <Grid item xs={6.7} sm={7.7} md={8.7} lg={8.7}>
                <Grid item>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#000000",
                      fontFamily: "Montserrat",
                      fontSize: "13px",
                    }}
                  >
                    GRN
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      color:
                        requestData.status === "Accepted"
                          ? "green"
                          : requestData.status === "Partially Accepted"
                          ? "orange"
                          : "red",
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    {requestData.status}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={5}
                sm={4}
                md={3}
                lg={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <SearchBar
                  className="search_box_pending w-100px"
                  placeholder="Search Product Name, product Code...."
                     value={searchValue}
                   onChange={(newValue) => {
                      setSearchValue(newValue);
                    }}
                    onCancelResearch={() => setSearchValue("")}
                    onSearch={(newValue) => {
                      handleSearch();
                    }}
                />
              </Grid>
              {/* <SearchBar
                  className="search_box_pending w-100px"
                  placeholder="Search Invoice Number, ID...."
                /> */}
            </Grid>
          </Grid>

          <Box
            mt={2}
            sx={{
              overflowY: "auto",
            }}
            className="create-form saleable-products-box"
          >
            {" "}
            <Grid
              item
              sx={{
                width: "95%",
                margin: "auto",
                marginBottom: "90px",
              }}
            >
              {" "}
              <Grid container mt={2}>
                 <Grid item xs={12} sm={6} md={4} lg={2.4}>
                  <Typography className="crt-fnt-lable-light">
                    GRN Number
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={0.8}>
                    {requestData?.grnNo
                      ? requestData?.grnNo
                      : "--"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2.4}>
                  <Typography className="crt-fnt-lable-light">
                    Invoice Number
                  </Typography>
                  <Typography className="crt-lable-input" mt={0.8}>
                    <div
                      style={{
                        textDecoration: "underline",
                        color: "#27538D",
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                      onClick={handleInvoiceClick}
                    >
                      {grnHistoryDetails?.invoiceNo
                        ? grnHistoryDetails?.invoiceNo
                        : "--"}
                    </div>
                  </Typography>
                </Grid>

               
                <Grid item xs={12} sm={6} md={4} lg={2.4}>
                  <Typography className="crt-fnt-lable-light">
                    GRN Date
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={0.8}>
                    {grnHistoryDetails?.grnDate
                      ? formatDate(grnHistoryDetails?.grnDate)
                      : "--"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2.4}>
                  <Typography className="crt-fnt-lable-light">
                    No of Line item
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={0.8}>
                    {grnHistoryDetails?.noOfLineItems
                      ? grnHistoryDetails?.noOfLineItems
                      : "0"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2.4}>
                  <Typography className="crt-fnt-lable-light">Value</Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={1}>
                    ₹
                    {grnHistoryDetails?.value
                      ? grnHistoryDetails?.value.toFixed(2)
                      : "0.00"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2.4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Accepted Items
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={0.8}>
                    {grnHistoryDetails?.acceptedItems
                      ? grnHistoryDetails?.acceptedItems
                      : "0"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2.4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Accepted Value
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={0.8}>
                    ₹{" "}
                    {grnHistoryDetails?.acceptedValue
                      ? grnHistoryDetails?.acceptedValue.toFixed(2)
                      : "0.00"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2.4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Discrepancy Items
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={0.8}>
                    {grnHistoryDetails?.discrepancyItems
                      ? grnHistoryDetails?.discrepancyItems
                      : "0"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2.4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Discrepancy Value
                  </Typography>
                  <Typography gutterBottom className="crt-lable-input" mt={0.8}>
                    ₹{" "}
                    {grnHistoryDetails?.discrepancyValue
                      ? grnHistoryDetails?.discrepancyValue.toFixed(2)
                      : "0.0"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2.4}>
                  <Typography className="crt-fnt-lable-light" mt={1}>
                    Discrepancy
                  </Typography>
                  <Grid item mt={0.8}>
                    {hasDiscrepancy ?(<Button
                      sx={{
                        borderRadius: "4px",
                        backgroundColor: "#FDF2E5",
                        height: "30px",
                        color: "#F28500",
                        fontSize: "11px",
                        fontWeight: 600,
                        fontFamily: "Montserrat",
                        padding: "6px, 12px, 6px, 12px",
                      }}
                      onClick={handleViewClick}
                    >
                      <img
                        src={alertsquare}
                        alt="alert_square"
                        style={{
                          width: "16px",
                          height: "16px",
                          marginRight: "4px",
                        }}
                      />{" "}
                      View All
                    </Button>):("--")}
                    
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="pad-top-0px"
                >
                  <div
                    className="saleable-table-scroll pad_tabRt sale_last_hide"
                    id="tableScrollFull"
                    // style={{ height: "400px", overflowY: "auto" }}
                  >
                    <Table
                      className="Header-table saleable-table scroll_cus_table elipsTable"
                      // rowSelection={rowSelection}
                      columns={columns1}
                      dataSource={filteredRecords}
                      pagination={false}
                      // scroll={{ x: true }}
                      loading={grnData?.grnProductDetailsLoading}
                      showSorterTooltip={false}
                      onRow={(record) => ({
                        onClick: () => handleRowClick(record),
                      })}
                      scroll={{ y: 270 }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
      {isView ? (
        <GmReviewView
          onClose={() => setIsView(false)}
          grnNo={requestData.grnNo}
        />
      ) : (
        <></>
      )}
      {isViewDiscrepancyInfo ? (
        <ViewDiscrepancyInfo
          onClose={() => setIsViewDiscrepancyInfo(false)}
          grnNo={requestData.grnNo}
          batchCode={batchCode}
        />
      ) : (
        <></>
      )}
      {isShowInvoice && (
        <InvoiceDetails
          onClose={() => setIsShowInvoice(false)}
          requestData={requestData}
        />
      )}
    </Box>
  );
}
