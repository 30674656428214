import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 200, // Adjust as needed
    height: 110, // Adjust as needed
    backgroundColor: "transparent", // Set background color to transparent
    margin: "5px",
    border: "1px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "none",

    "&:hover": {
      backgroundColor: "rgba(148, 73, 134, 0.1)",
      boxShadow: "0 0px 3px rgba(0, 0, 0, 0.3)", // Add box shadow
      transition: "box-shadow 0.3s ease", // Change background color on hover
      cursor: "pointer",
      "& $title1": {
        color: "rgba(148, 73, 134, 1.1)", // Change title1 color on hover
      },
    },
  },
  hoverCard: {
    backgroundColor: "rgba(148, 73, 134, 0.1)",
    boxShadow: "0 0px 3px rgba(0, 0, 0, 0.3)", // Add box shadow
    transition: "box-shadow 0.3s ease", // Change background color on hover
  },
  title: {
    color: "rgba(148, 73, 134, 1.1)", // Default title color
  },
  title1: {
    "&:hover": {
      color: "rgba(148, 73, 134, 1.1)", // Change color on hover
    }, // Default title color
  },
  hiddenCard: {
    visibility: "hidden",
  },
}));

interface MasterProductDashboardProps {
  title: string;
  description: string;
  index: number;
  hidden?: boolean;
}

const MasterProductDashboard: React.FC<MasterProductDashboardProps> = ({
  title,
  description,
  index,
}) => {
  const classes = useStyles();
  return (
    // <Card className={`${classes.card} ${index === 0 ? classes.hoverCard : ''}`}>
    <CardContent
      style={{ padding: "12px" }}
      className={`${classes.card} ${index === 0 ? classes.hoverCard : ""}`}
    >
      <span
        className={`${classes.title1} ${index === 0 ? classes.title : ""}`}
        style={{
          fontSize: "13px",
          fontWeight: "600",
          padding: "0px 0px 8px 0px",
        }}
      >
        {title}
      </span>
      <span style={{ fontSize: "10px", fontWeight: "600" }}>{description}</span>
    </CardContent>
    // </Card>
  );
};

const ConfigurationGrid: React.FC = () => {
  const navigator = useNavigate();
  return (
    <Grid
      container
      spacing={3}
      sx={{ pl: { xs: 2, sm: 3 } }}
      className="company_card_prd"
    >
      {/* First line with 6 cards */}
      <Grid
        item
        xs={6}
        md={4}
        lg={2}
        sm={6}
        onClick={() => navigator("/master/master_configuration")}
      >
        <MasterProductDashboard
          index={0}
          title="Geo Hierarchy"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
      <Grid
        item
        xs={6}
        md={4}
        lg={2}
        sm={6}
        onClick={() => navigator("/master/configuration_salesHierarchy")}
      >
        <MasterProductDashboard
          index={1}
          title="Sales Hierarchy "
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
      <Grid item xs={6} md={4} lg={2} sm={6}>
        <MasterProductDashboard
          index={2}
          title="Customer Migration"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
      <Grid item xs={6} md={4} lg={2} sm={6}>
        <MasterProductDashboard
          index={3}
          title="Customer Sharing"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
      <Grid item xs={6} md={4} lg={2} sm={6}>
        <MasterProductDashboard
          index={4}
          title="LOB Master Creation"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
      <Grid item xs={6} md={4} lg={2} sm={6}>
        <MasterProductDashboard
          index={5}
          title="PJP Calendar Gantt Chart"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
      {/* Second line with 4 cards, excluding first and last */}
      <Grid
        item
        xs={6}
        md={4}
        lg={2}
        sm={6}
        sx={{ display: { md: "none", sm: "none", lg: "flex" } }}
      ></Grid>
      <Grid
        item
        xs={6}
        md={4}
        lg={2}
        sm={6}
        sx={{ display: { md: "none", sm: "none", lg: "flex" } }}
      ></Grid>
      <Grid item xs={6} md={4} lg={2} sm={6}>
        <MasterProductDashboard
          index={6}
          title="Scheme Mapping"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
      <Grid item xs={6} md={4} lg={2} sm={6}>
        <MasterProductDashboard
          index={7}
          title="Purchase Settings"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        />
      </Grid>
      <Grid item xs={6} md={4} lg={2} sm={6} />
      <Grid item xs={6} md={4} lg={2} sm={6} />
    </Grid>
  );
};

export default ConfigurationGrid;
