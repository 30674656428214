import {
  SUPPLIER_SUBMIT_FORM,
  SUPPLIER_Submit_ERROR,
  SUPPLIER_FETCH_DATA,
  SUPPLIER_ACTIVE_STATUS,
  SUPPLIER_EDIT_DATA,
  SUPPLIER_UPDATE_DATA,
  SUPPLIER_UPDATE_ERROR,
  GET_DEFAULT_COMPANY,
} from "../../actionType";
import { Dispatch } from "redux";
import createAxiosInstance from "../../../hooks/useFetch";
import { SupplierFormValues } from "../../../typescript/Supplier";
const axiosInstance = createAxiosInstance();

export interface SupplierSubmitAction {
  type: typeof SUPPLIER_SUBMIT_FORM;
  payload: any;
}

export interface SupplierSubmitErrorAction {
  type: typeof SUPPLIER_Submit_ERROR;
  payload: string;
}
export interface supplierGetAction {
  type: typeof SUPPLIER_FETCH_DATA;
  payload: any;
}
export interface supplierActiveStatus {
  type: typeof SUPPLIER_ACTIVE_STATUS;
  payload: any;
}
export interface supplierEdit {
  type: typeof SUPPLIER_EDIT_DATA;
  payload: any;
}
export interface SupplierUpdate {
  type: typeof SUPPLIER_UPDATE_DATA;
  payload: any;
}
export interface SupplierUpdateErrorAction {
  type: typeof SUPPLIER_UPDATE_ERROR;
  payload: string;
}
export interface DefaultCompany {
  type: typeof GET_DEFAULT_COMPANY;
  payload: string;
}
export const supplierSubmitForm =
  (formData: SupplierFormValues) =>
  async (
    dispatch: Dispatch<SupplierSubmitAction | SupplierSubmitErrorAction>
  ) => {
    try {
      const response = await axiosInstance.post(`supplier/create`, formData);
      dispatch({
        type: SUPPLIER_SUBMIT_FORM,
        payload: response.data, // Assuming response contains data
      });
      return response.data;
    } catch (error: any) {
      dispatch({
        type: SUPPLIER_Submit_ERROR,
        payload: error.response.data.data,
      });
      throw error;
    }
  };

export const supplierFetchData = (requestData: {
  page: number;
  size: number;
}) => {
  return (dispatch: Dispatch<supplierGetAction>) => {
    axiosInstance
      .get(`supplier/get`, {
        params: requestData, // Pass requestData as query parameters
      })
      .then((res) => {
        dispatch({
          type: SUPPLIER_FETCH_DATA, // Change this to COMPANY_FETCH_DATA
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};

export const supplierActiveStatus = ({
  id,
  value,
}: {
  id: string;
  value: boolean;
}) => {
  return (dispatch: Dispatch<supplierActiveStatus>) => {
    axiosInstance
      .put(
        `supplier/active-in-active`,
        { id, value } // Pass requestData as query parameters
      )
      .then((res) => {
        dispatch({
          type: SUPPLIER_ACTIVE_STATUS, // Change this to COMPANY_FETCH_DATA
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};

export const SupplierEdit = (id: string) => {
  return (dispatch: Dispatch<supplierEdit>) => {
    axiosInstance
      .get(`supplier/get-byid/${id}`)
      .then((res) => {
        dispatch({
          type: SUPPLIER_EDIT_DATA, // Dispatch the type of action
          payload: res.data.data, // Pass any data received from the API response
        });
      })
      .catch((error) => {
        // Handle errors if any
      });
  };
};

export const SupplierUpdate =
  ({ id, formData }: { id: string; formData: SupplierFormValues }) =>
  async (dispatch: Dispatch<SupplierUpdate | SupplierUpdateErrorAction>) => {
    try {
      const response = await axiosInstance.put(
        `supplier/update/${id}`,
        formData
      );
      dispatch({
        type: SUPPLIER_UPDATE_DATA,
        payload: response.data, // Assuming response contains data
      });
      return response.data;
    } catch (error: any) {
      dispatch({
        type: SUPPLIER_UPDATE_ERROR,
        payload: error.response.data.data,
      });
      throw error;
    }
  };
export const DefaultCompanyFetch = () => {
  return (dispatch: Dispatch<DefaultCompany>) => {
    axiosInstance
      .get(`company/get-default-company`)
      .then((res) => {
        dispatch({
          type: GET_DEFAULT_COMPANY, // Change this to COMPANY_FETCH_DATA
          payload: res.data.data,
        });
      })
      .catch((error) => {});
  };
};
