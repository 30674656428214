import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import createAxiosInstance from "../../../hooks/useFetch";
import { get } from "http";
import { log } from "console";

interface OutletData {
  outletName: string;
  outletId: string;
  outletGroup: string;
  contactInfo: string;
  status: boolean;
  outstandingAmt: number;
}
interface outletInvoiceData {
  totalSalesValue: string;
  financialYear: string;
  invoiceCount: string;
}
interface RecentInvoiceData {
  outletCode: string;
  orderNumber: string;
  invoiceNumber: string;
  invoiceDate: string;
  invoiceStatus: string;
  invoiceValue: string;
  noOfLineItems: string;
  outstandingAmt: string;
  paymentSts: string;
  isCollection: boolean;
}

interface RecentInvoiceRequest {
  page: number;
  size: number;
  outletCode: string;
  search: string;
}
interface outletTableData {
  outlets: OutletData[];
  filteredCount: number;
}
interface invoiceTableData {
  invoicedetails: invoiceDetailsData[];
  grossAmount: number;
  schemeApplied: number;
  gstAndTaxes: number;
  netAmount: number;
}
interface countData {
  outletCount: number;
  routeCount: number;
  outstandingAmount: number;
}

interface InvoiceCollectionData {
  collectionId: string;
  collectionDate: string;
  amtCollected: number;
  paymentMode: string;
  status: string;
  receivedDate: string | null;
}

interface OutletInvoiceCollectionData {
  noOfCollection: number;
  collectedAmount: number;
}

interface outletState {
  outlet: outletTableData;
  outletLoading: boolean;
  outletError: string | null;
  loading: boolean;
  error: string | null;
  dashCounts: countData[];
  dashCountsLoading: boolean;
  dashCountsError: string | null;
  outletDetailsData: outletDetailsData | null;
  outletDetailsDataLoading: boolean;
  outletDetailsDataError: string | null;
  outletInvoiceData: outletInvoiceData | null;
  outletInvoiceDataLoading: boolean;
  outletInvoiceDataError: string | null;
  recentInvoiceData: RecentInvoiceData[] | null;
  recentInvoiceDataLoading: boolean;
  recentInvoiceDataError: string | null;
  invoiceDetailsData: invoiceTableData;
  invoiceDetailsDataLoading: boolean;
  invoiceDetailsDataError: string | null;

  invoiceCollectionData: InvoiceCollectionData[] | null;
  invoiceCollectionDataLoading: boolean;
  invoiceCollectionDataError: string | null;

  outletInvoiceCollectionData: OutletInvoiceCollectionData | null;
  outletInvoiceCollectionDataLoading: boolean;
  outletInvoiceCollectionDataError: string | null;

  ouletdetailsInfoData: OuletdetailsInfo;
  ouletdetailsInfoDataLoading: boolean;
  ouletdetailsInfoDataError: string | null;
}
interface FilterDataRequest {
  page: number;
  size: number;
  status: boolean;
  division: string;
  category: string;
  search: string;
  min: string;
  max: string;
}
interface invoiceDataRequest {
  page: number;
  size: number;
  invoiceNumber: string;
}
interface outletDetailsData {
  outletCode: string;
  outletName: string;
  outletGroup: string;
  contactPerson: string;
  contactMobile: string;
  address: string;
  city: string;
  state: string;
  outletOwnerName: string;
  gstNumber: string;
  outletClass: string;
}
interface invoiceDetailsData {
  productName: string;
  hsnCode: number;
  mrp: number;
  billed: number;
  free: number;
  total: number;
  rate: number;
  schemeDiscount: number;
  grossAmount: number;
  cgst: number;
  sgst: number;
  igst: number;
  amount: number;
}

interface OuletdetailsInfo {
  invoiceCount: number;
  totalNetAmount: number;
  balanceAmount: number;
}

interface ViewCollectionRequest {
  invoiceNo: string;
  search: string;
}

const axiosInstance = createAxiosInstance();

export const getOutletData = createAsyncThunk(
  "outlet/outlet-data-fetch",
  async (req: { params: FilterDataRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get("outlet/get-outlet-data", {
      params,
      signal,
    });
    return response.data.data;
  }
);
export const getOutletDashCounts = createAsyncThunk(
  "outlet/getOutletCount",
  async (status: string | null) => {
    const response = await axiosInstance.get(
      `outlet/getOutletCount?status=${status}`
    );
    return response.data.data;
  }
);
export const getOutletDetails = createAsyncThunk(
  "outlet/outlet-fetch",
  async (outletCode: string) => {
    const response = await axiosInstance.get(
      `outlet/get-outlet-details?outletCode=${outletCode}`
    );
    return response.data.data;
  }
);
export const getOutletInvoiceDetails = createAsyncThunk(
  "outlet/outlet-invoice-fetch",
  async (outletCode: string) => {
    const response = await axiosInstance.get(
      `outlet/outlet-details-invoice?outletCode=${outletCode}`
    );
    return response.data.data;
  }
);
export const getRecentInvoiceDetails = createAsyncThunk(
  "outlet/outlet-recent-invoice-fetch",
  async (req: { params: RecentInvoiceRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    console.log(req);

    const response = await axiosInstance.get("outlet/get-recent-invoice", {
      params,
      signal,
    });
    return response.data.data;
  }
);
export const getinvoiceProductsDetails = createAsyncThunk(
  "outlet/invoice-details-fetch",
  async (req: invoiceDataRequest) => {
    const response = await axiosInstance.get("outlet/get-invoice-detail", {
      params: req,
    });
    return response.data.data;
  }
);
export const getInvoiceCollectionDetails = createAsyncThunk(
  "outlet/view-collection",
  async (req: { params: ViewCollectionRequest; signal: AbortSignal }) => {
    const { params, signal } = req;
    const response = await axiosInstance.get(`outlet/view-collection`, {
      params,
      signal,
    });
    return response.data.data;
  }
);

export const getOutletInvoiceCollectionDetails = createAsyncThunk(
  "outlet/outlet-invoice-collection-details",
  async (invoiceNumber: string) => {
    const response = await axiosInstance.get(
      `outlet/outlet-invoice-collection-details?invoiceNumber=${invoiceNumber}`
    );
    return response.data.data;
  }
);

export const getOutletdetailsInfo = createAsyncThunk(
  "outlet/get-outlets-info",
  async (outleCode: string) => {
    const response = await axiosInstance.get(
      `outlet/get-outlets-info?outleCode=${outleCode}`
    );
    console.log("responseresponse " + response.data.data);
    return response.data.data;
  }
);

export const emptyOutletDetails = createAsyncThunk(
  "outlet/empty-outlet-details",
  async (req: any) => {
    return req;
  }
);

const initialState: outletState = {
  outlet: { outlets: [], filteredCount: 0 },
  outletLoading: false,
  outletError: null,
  dashCounts: [],
  dashCountsLoading: false,
  dashCountsError: null,
  outletDetailsData: null,
  outletDetailsDataLoading: false,
  outletDetailsDataError: null,
  outletInvoiceData: null,
  outletInvoiceDataLoading: false,
  outletInvoiceDataError: null,
  invoiceCollectionData: [],
  invoiceCollectionDataLoading: false,
  invoiceCollectionDataError: null,
  recentInvoiceData: null,
  recentInvoiceDataLoading: false,
  recentInvoiceDataError: null,
  outletInvoiceCollectionData: null,
  outletInvoiceCollectionDataLoading: false,
  outletInvoiceCollectionDataError: null,
  invoiceDetailsData: {
    invoicedetails: [],
    grossAmount: 0,
    schemeApplied: 0,
    gstAndTaxes: 0,
    netAmount: 0,
  },
  invoiceDetailsDataLoading: false,
  invoiceDetailsDataError: null,
  loading: false,
  error: null,

  ouletdetailsInfoData: {
    invoiceCount: 0,
    totalNetAmount: 0,
    balanceAmount: 0,
  },
  ouletdetailsInfoDataLoading: false,
  ouletdetailsInfoDataError: null,
};

const outletSlice = createSlice({
  name: "outletData",
  initialState,
  reducers: {
    outletFilterFetch: (state, action: PayloadAction<Partial<outletState>>) => {
      state.outlet = action.payload.outlet || state.outlet;
      state.dashCounts = action.payload.dashCounts || state.dashCounts;
      state.invoiceDetailsData =
        action.payload.invoiceDetailsData || state.invoiceDetailsData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOutletData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.outletLoading = false;
      state.outletError = action.error.message ?? null;
    });
    builder.addCase(getOutletData.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.outletLoading = true;
      state.outletError = null;
    });
    builder.addCase(
      getOutletData.fulfilled,
      (state, action: PayloadAction<outletTableData>) => {
        state.outlet = action.payload;
        state.loading = false;
        state.error = null;
        state.outletLoading = false;
        state.outletError = null;
      }
    );
    builder.addCase(getOutletDashCounts.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.dashCountsLoading = true;
      state.dashCountsError = null;
    });
    builder.addCase(
      getOutletDashCounts.fulfilled,
      (state, action: PayloadAction<countData[]>) => {
        state.dashCounts = action.payload ?? [];
        state.dashCountsLoading = false;
        state.dashCountsError = null;
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(getOutletDashCounts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.dashCountsLoading = false;
      state.dashCountsError = action.error.message ?? null;
    });
    builder.addCase(getOutletDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.outletDetailsDataLoading = true;
      state.outletDetailsDataError = null;
    });
    builder.addCase(
      getOutletDetails.fulfilled,
      (state, action: PayloadAction<outletDetailsData>) => {
        state.outletDetailsData = action.payload;
        state.loading = false;
        state.error = null;
        state.outletDetailsDataLoading = false;
        state.outletDetailsDataError = null;
      }
    );
    builder.addCase(getOutletDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.outletDetailsDataLoading = false;
      state.outletDetailsDataError = action.error.message ?? null;
    });
    builder.addCase(getOutletInvoiceDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.outletInvoiceDataLoading = true;
      state.outletInvoiceDataError = null;
    });
    builder.addCase(
      getOutletInvoiceDetails.fulfilled,
      (state, action: PayloadAction<outletInvoiceData>) => {
        state.outletInvoiceData = action.payload;
        state.loading = false;
        state.error = null;
        state.outletInvoiceDataLoading = false;
        state.outletInvoiceDataError = null;
      }
    );
    builder.addCase(getOutletInvoiceDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.outletInvoiceDataLoading = false;
      state.outletInvoiceDataError = action.error.message ?? null;
    });
    // Invoice Collection
    builder.addCase(getInvoiceCollectionDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.outletInvoiceDataLoading = true;
      state.outletInvoiceDataError = null;
    });
    builder.addCase(
      getInvoiceCollectionDetails.fulfilled,
      (state, action: PayloadAction<InvoiceCollectionData[]>) => {
        state.invoiceCollectionData = action.payload;
        state.loading = false;
        state.error = null;
        state.invoiceCollectionDataLoading = false;
        state.invoiceCollectionDataError = null;
      }
    );
    builder.addCase(getInvoiceCollectionDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.invoiceCollectionDataLoading = false;
      state.invoiceCollectionDataError = action.error.message ?? null;
    });

    //Outlet Invoice Collection Details
    builder.addCase(getOutletInvoiceCollectionDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.outletInvoiceCollectionDataLoading = true;
      state.outletInvoiceCollectionDataError = null;
    });
    builder.addCase(
      getOutletInvoiceCollectionDetails.fulfilled,
      (state, action: PayloadAction<OutletInvoiceCollectionData>) => {
        state.outletInvoiceCollectionData = action.payload;
        state.loading = false;
        state.error = null;
        state.outletInvoiceCollectionDataLoading = false;
        state.outletInvoiceCollectionDataError = null;
      }
    );
    builder.addCase(
      getOutletInvoiceCollectionDetails.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
        state.outletInvoiceCollectionDataLoading = false;
        state.outletInvoiceCollectionDataError = action.error.message ?? null;
      }
    );

    //
    builder.addCase(getRecentInvoiceDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.recentInvoiceDataLoading = false;
      state.recentInvoiceDataError = action.error.message ?? null;
    });
    builder.addCase(getRecentInvoiceDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.recentInvoiceDataLoading = true;
      state.recentInvoiceDataError = null;
    });
    builder.addCase(
      getRecentInvoiceDetails.fulfilled,
      (state, action: PayloadAction<RecentInvoiceData[]>) => {
        state.recentInvoiceData = action.payload;
        state.loading = false;
        state.error = null;
        state.recentInvoiceDataLoading = false;
        state.recentInvoiceDataError = null;
      }
    );
    builder.addCase(getinvoiceProductsDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.invoiceDetailsDataLoading = false;
      state.invoiceDetailsDataError = action.error.message ?? null;
    });
    builder.addCase(getinvoiceProductsDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.invoiceDetailsDataLoading = true;
      state.invoiceDetailsDataError = null;
    });
    builder.addCase(
      getinvoiceProductsDetails.fulfilled,
      (state, action: PayloadAction<invoiceTableData>) => {
        state.invoiceDetailsData = action.payload;
        state.loading = false;
        state.error = null;
        state.invoiceDetailsDataLoading = false;
        state.invoiceDetailsDataError = null;
      }
    );
    //outlet-details-info

    builder.addCase(getOutletdetailsInfo.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.ouletdetailsInfoDataLoading = true;
      state.ouletdetailsInfoDataError = null;
    });
    builder.addCase(
      getOutletdetailsInfo.fulfilled,
      (state, action: PayloadAction<OuletdetailsInfo>) => {
        state.ouletdetailsInfoData = action.payload;
        state.loading = false;
        state.error = null;
        state.ouletdetailsInfoDataLoading = false;
        state.ouletdetailsInfoDataError = null;
      }
    );

    builder.addCase(getOutletdetailsInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
      state.ouletdetailsInfoDataLoading = false;
      state.ouletdetailsInfoDataError = action.error.message ?? null;
    });
    builder.addCase(
      emptyOutletDetails.fulfilled,
      (state, action: PayloadAction<RecentInvoiceData[]>) => {
        state.recentInvoiceData = action.payload;
      }
    );
  },
});
export const { outletFilterFetch } = outletSlice.actions;
export default outletSlice.reducer;
