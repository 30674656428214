import React from "react";

import { Box } from "@mui/material";

const PurchaseAccount: React.FC = () => {

  return <Box>
    <p>Purchase Account</p>
  </Box>;
};
export default PurchaseAccount;
