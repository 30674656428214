import { Outlet } from 'react-router-dom';
import Card from '@mui/material/Card';

import { Box, Grid, Toolbar, Typography, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { ReactNode, useState } from 'react';
import type { TableColumnsType } from 'antd';
import SearchBar from '@mkyy/mui-search-bar';
import CommonTable from '../../../components/Tables/CommonTable';
import add from '../../../assets/images/icons/add.png';

interface DataType {
  key: React.Key;
  code: number;
  date: string;
  productCount: string;
  orderStatus: string;
  value: string;
  paymentStatus: string;
  action: string;
}


const MiniLayout: React.FC = () => {

  const [textFieldValue, setTextFieldValue] = useState('');

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const handleSearch = () => {
    // search function logic here
  };


  const columns: TableColumnsType<DataType> = [

    {
      dataIndex: "code",
      title: "Invoice Number",

    },
    {
      dataIndex: "date",
      title: "Invoice date",
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      dataIndex: "productCount",
      title: "Product count",
      sorter: (a, b) => a.productCount.length - b.productCount.length,
    },
    {
      dataIndex: "orderStatus",
      title: "Order Status",
      sorter: (a, b) => a.orderStatus.length - b.orderStatus.length,
    },
    {
      dataIndex: "value",
      title: "Value",
      sorter: (a, b) => a.value.length - b.value.length,
    },
    {
      dataIndex: "paymentStatus",
      title: "Payment Status",
      sorter: (a, b) => a.paymentStatus.length - b.paymentStatus.length,
    },
    {
      dataIndex: "action",
      title: "Actions",

    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      code: 55,
      date: "28/02/2024",
      productCount: "10",
      orderStatus: "Out for Delivery",
      value: "10000",
      paymentStatus: "Pending",
      action: "",
    },
    {
      key: 2,
      code: 55445,
      date: "28/02/2024",
      productCount: "20",
      orderStatus: "Delivered",
      value: "15000",
      paymentStatus: "Pending",
      action: "",
    },
    {
      key: 3,
      code: 5565,
      date: "28/02/2024",
      productCount: "30",
      orderStatus: "Out for Delivery",
      value: "320000",
      paymentStatus: "Pending",
      action: "",
    },
    {
      key: 4,
      code: 5548,
      date: "28/02/2024",
      productCount: "40",
      orderStatus: "Out for Delivery",
      value: "450000",
      paymentStatus: "Pending",
      action: "",
    },
    {
      key: 5,
      code: 55121,
      date: "28/02/2024",
      productCount: "50",
      orderStatus: "Delivered",
      value: "20000",
      paymentStatus: "Pending",
      action: "",
    },
    {
      key: 6,
      code: 5565,
      date: "28/02/2024",
      productCount: "80",
      orderStatus: "Out for Delivery",
      value: "36000",
      paymentStatus: "Pending",
      action: "",
    },
    {
      key: 7,
      code: 55789,
      date: "28/02/2024",
      productCount: "100",
      orderStatus: "Delivered",
      value: "60000",
      paymentStatus: "Pending",
      action: "",
    },
    {
      key: 8,
      code: 55656,
      date: "28/02/2024",
      productCount: "90",
      orderStatus: "Out for Delivery",
      value: "70000",
      paymentStatus: "Pending",
      action: "",
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <Box sx={{ marginTop: "2px" }}>

        <Grid item xs={12} className="mar-tb">
          <Typography >
            <b className="header-lable">Invoices&nbsp;<span className='clr'>(12)</span></b>
          </Typography>
        </Grid>
        <Grid item xs={12} spacing={4} sx={{ display: 'flex', justifyContent: 'end', mb: 3 }} >
          <Grid>
            <SearchBar
              className='search-table'
              value={textFieldValue}
              onChange={newValue => {
                setTextFieldValue(newValue);
                handleSearch();
              }}
            />
          </Grid>
          <Grid item xs={12} ml={1.4} display="flex" justifyContent="end">
            <Button variant="contained" className='btn_color' startIcon={<img src={add} alt="Add" />} >
              Add  Invoice
            </Button>
          </Grid>
          <Grid item xs={12} ml={1.4} display="flex" justifyContent="end">
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 44 44" fill="none">
                <circle cx="23" cy="14" r="1.75" fill="black" />
                <circle cx="23" cy="21" r="1.75" fill="black" />
                <circle cx="23" cy="28" r="1.75" fill="black" />
              </svg>
            </Grid>
        </Grid>
        <CommonTable 
        // rowSelection={rowSelection}
         data={data} columns={columns} />
      </Box>

    </>
  );
}

export default MiniLayout;
